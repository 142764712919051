import React from "react";

export default function DotdotdotIcon({ ...props }: React.SVGProps<any>) {
  return (
    <svg viewBox="0 0 384 384" width="1em" height="1em" {...props}>
      <circle fill="currentColor" cx={42.667} cy={192} r={42.667} />
      <circle fill="currentColor" cx={192} cy={192} r={42.667} />
      <circle fill="currentColor" cx={341.333} cy={192} r={42.667} />
    </svg>
  );
}
