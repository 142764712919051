import React from "react";
import { Query } from "react-apollo";
import GetStoriesByVendor from "../../../queries/GetStoriesByVendor";
import {
  IGetStoriesByVendorQuery,
  IGetStoriesByVendorQueryVariables,
} from "../../../queries/__generated__/GetStoriesByVendor.generated";
import { LoadingIndicator } from "@govlaunch/core";
import StoriesTable from "../../stories/paginated/StoriesTable";
import { IStoryFieldsAdminFragment } from "../../../queries/fragments/__generated__/StoryFields.generated";

interface IVendorStoriesTabProps {
  vendorId: string;
}

export default function VendorStoriesTab({ vendorId }: IVendorStoriesTabProps) {
  return (
    <Query<IGetStoriesByVendorQuery, IGetStoriesByVendorQueryVariables>
      query={GetStoriesByVendor}
      variables={{
        vendorId: vendorId,
        sort: null,
      }}
      fetchPolicy="network-only"
      notifyOnNetworkStatusChange={true}
    >
      {({ data, loading, refetch }) => {
        if (loading || !data) {
          return (
            <LoadingIndicator
              css={{
                padding: 20,
              }}
            />
          );
        }

        const storiesData = (data && data.storiesByVendor && data.storiesByVendor.items) || [];

        return (
          <div
            css={{
              padding: 20,
            }}
          >
            {/*
              2020-01-06 *Stoyan Prokopov*: the StoriesTable is a shared component used in here and on the Government Details
              Also it has no pagination capability.
              Once pagination has been implemented ot a separate component has been used, then the dirty cast to TStory[] can be removed

              2020-04-03 *Stoyan Prokopov*: switch to using StoriesWithSearchWithRouter component from tags/profile/tabs/stories
              since that component has pagination implemented
             */}
            <StoriesTable stories={storiesData as IStoryFieldsAdminFragment[]} refetch={refetch} />
          </div>
        );
      }}
    </Query>
  );
}
