import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import { parseState } from "../../utils/tablesUtils";
import TablePagination, { ITablePaginationState } from "../../components/table/TablePagination";
import { useQuery } from "react-apollo";
import {
  IGetAllTimezonesQuery,
  IGetAllTimezonesQueryVariables,
} from "../../queries/__generated__/GetAllTimezonesQuery.generated";
import GetAllTimezonesQuery from "../../queries/GetAllTimezonesQuery";
import { calculateAndEncodeCursor } from "../../utils/encodeCursor";
import TableAddStateToQueryString from "../../components/table/TableAddStateToQueryString";
import TableOnSearch from "../../components/table/TableOnSearch";
import { Table } from "antd";
import * as palette from "@govlaunch/palette";
import TableTitle from "../../components/table/TableTitle";
import MissingTimezoneSettings from "./MissingTimezoneSettings";
import { Link } from "react-router-dom";

interface IManageTimezonesProps {
  search: string;
  historyOrigin: string;
}

interface ITimezonesTableState {
  cursor: ITablePaginationState;
}

type TCountryTimezone = NonNullable<NonNullable<IGetAllTimezonesQuery["getAllTimezones"]>["items"]>[0];

export default function ManageTimezones({ search, historyOrigin }: IManageTimezonesProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const initialFiltersState = parseState(query.state as string, {
    cursor: {
      page: 1,
      size: 50,
    },
  });

  const [state, setState] = useState<ITimezonesTableState>(initialFiltersState);

  const { data, loading } = useQuery<IGetAllTimezonesQuery, IGetAllTimezonesQueryVariables>(GetAllTimezonesQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      filters: {
        search: search || "",
      },
      cursor: calculateAndEncodeCursor(state.cursor),
    },
  });

  const timezones = (data && data.getAllTimezones && data.getAllTimezones.items) || [];
  const totalCount =
    data && data.getAllTimezones && data.getAllTimezones.pageInfo ? data.getAllTimezones.pageInfo.totalCount || 0 : 0;

  return (
    <div
      css={{
        maxWidth: 700,
      }}
    >
      {typeof history !== "undefined" && history && (
        <TableAddStateToQueryString tableState={state} historyOrigin={historyOrigin} query={query} />
      )}
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
                page: 1,
                size: state.cursor.size,
              },
            });
          }
        }}
      />

      <MissingTimezoneSettings />

      <Table<TCountryTimezone>
        bordered={true}
        rowKey="_id"
        loading={loading}
        pagination={false}
        style={{
          backgroundColor: palette.white,
        }}
        dataSource={timezones}
        title={() => (
          <TableTitle
            title="Country Timezones"
            count={totalCount}
            loading={loading}
            showResetButton={false}
            onResetFilters={() => {
              setState({
                cursor: {
                  page: 1,
                  size: state.cursor.size,
                },
              });

              navigate(`${historyOrigin}?search=${search}`);
            }}
          />
        )}
        footer={() => (
          <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
        )}
        columns={[
          {
            title: "Name",
            dataIndex: "countryName",
          },
          {
            title: "Country",
            dataIndex: "countryCode",
            render: (countryCode: string) => <Link to={`/newsletter/timezones/${countryCode}`}>{countryCode}</Link>,
          },
          {
            title: "UTC Offset",
            dataIndex: "timezone",
            render: (timezone: string) => <div>{timezone}</div>,
          },
        ]}
      />
    </div>
  );
}
