import React from "react";
import { Route, Routes } from "react-router";
import { Layout } from "antd";
import Government from "../governments/Government";
import Governments from "../governments/Governments";
import ProjectVerification from "../governments/ProjectVerification";
import EditGroup from "../groups/EditGroup";
import GroupRequests from "../groups/GroupRequests";
import Groups from "../groups/Groups";
import NewGroup from "../groups/NewGroup";
import Posts from "../posts/Posts";
import NewProduct from "../products/NewProduct";
import Product from "../products/Product";
import AllProducts from "../products/Products";
import Suggestions from "../products/Suggestions";
import Projects from "../projects/Projects";
import { EditReport, NewReport, Report, Reports } from "../reports";
import ComposeStory from "../stories/ComposeStory";
import EditStory from "../stories/EditStory";
import Stories from "../stories/Stories";
import Story from "../stories/Story";
import Tags from "../tags/Tags";
import Users from "../users/Users";
import AddVendor from "../vendors/AddVendor";
import Vendor from "../vendors/Vendor";
import Vendors from "../vendors/Vendors";
import ProjectBySlug from "../projects/routes/ProjectBySlug";
import UserBySlug from "../users/routes/UserBySlug";
import Plans from "../billing/Plans";
import VendorProductStackRequests from "../products/VendorProductStackRequests";
import AddGovernment from "../governments/AddGovernment";
import TagProfile from "../tags/profile/TagProfile";
import AllSponsorships from "../sponsorships/AllSponsorships";
import NewSponsorship from "../sponsorships/NewSponsorship";
import Sponsorship from "../sponsorships/Sponsorship";
import PodcastPageConfig from "../podcast/PodcastPageConfig";
import Solutions from "../solutions/Solutions";
import AboutPageConfigsList from "../aboutpage/AboutPageConfigsList";
import NewAboutPageConfigPage from "../aboutpage/NewAboutPageConfigPage";
import Collections from "../collections/Collections";
import CollectionProfile from "../collections/CollectionProfile";
import CollectionSponsorships from "../collections/CollectionSponsorships";
import CreateNewsletterCampaign from "../campaigns/CreateNewsletterCampaign";
import ListCampaigns from "../campaigns/ListCampaigns";
import NewsletterSettingsForm from "../newsletter/NewsletterSettingsForm";
import NewsletterReceivers from "../newsletter/NewsletterReceivers";
import Onboardings from "../onboarding/Onboardings";
import GlobalSettings from "../settings/GlobalSettings";
import ScheduledJobs from "../settings/ScheduledJobs";
import GovernmentsUpload from "../governments/GovernmentsUpload";
import EditNewsletterCampaign from "../campaigns/EditNewsletterCampaign";
import CampaignContentPreviewPage from "../campaigns/CampaignContentPreviewPage";
import DashboardIndex from "./DashboardIndex";
import NewTagPage from "../tags/NewTagPage";
import NewProjectPage from "../projects/NewProjectPage";
import NewPlanPage from "../billing/NewPlanPage";
import EditPlanPage from "../billing/EditPlanPage";
import NewCollectionPage from "../collections/NewCollectionPage";
import { useSelfie } from "../auth/Selfie";
import ProjectsBulkActionsImport from "../projects/bulk/ProjectsBulkActionsImport";
import TimezonesPage from "../newsletter/TimezonesPage";
import EditCountryTimezonePage from "../newsletter/EditCountryTimezonePage";
import NewCountryTimezonePage from "../newsletter/NewCountryTimezonePage";
import EditAboutPageConfigPage from "../aboutpage/EditAboutPageConfigPage";
import ImagesPage from "../images/ImagesPage";
import DuplicatedUsersPage from "../users/duplicates/DuplicatedUsersPage";
import NewLandingPage from "../landingpage/NewLandingPage";
import LandingPages from "../landingpage/LandingPages";
import EditLandingPage from "../landingpage/EditLandingPage";
import ExternalLinksPage from "../landingpage/externallinks/ExternalLinksPage";
import NewExternalLinkPage from "../landingpage/externallinks/NewExternalLinkPage";
import EditExternalLinkPage from "../landingpage/externallinks/EditExternalLinkPage";
import NewEventPage from "../landingpage/events/NewEventPage";
import EventsPage from "../landingpage/events/EventsPage";
import EditLandingPageEventsPage from "../landingpage/events/EditLandingPageEventPage";

const { Content } = Layout;

interface IDashboardContentProps {
  menuCollapsed: boolean;
}

export default function DashboardContent({ menuCollapsed }: IDashboardContentProps) {
  const selfie = useSelfie();

  return (
    <Content
      style={{
        marginLeft: menuCollapsed ? 50 : 250,
      }}
    >
      {selfie && selfie.isLimitedSiteAdmin && (
        <Routes>
          <Route path="/" element={<DashboardIndex />} />

          <Route path="/users" element={<Users />} />

          <Route path="/products/new" element={<NewProduct />} />
          <Route path="/products/solutions" element={<Solutions />} />
          <Route path="/products/:slug" element={<Product />} />
          <Route path="/products/:slug/:tab" element={<Product />} />

          <Route path="/suggestions" element={<Suggestions />} />

          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/new" element={<NewProjectPage />} />
          <Route path="/projects/:slug" element={<ProjectBySlug />} />
          <Route path="/projects/bulkActions" element={<ProjectsBulkActionsImport />} />

          <Route path="/vendors" element={<Vendors />} />
          <Route path="/vendors/new" element={<AddVendor />} />
          <Route path="/vendors/:slug" element={<Vendor />} />
          <Route path="/vendors/:slug/:tab" element={<Vendor />} />
          <Route path="/vendors/:slug/products/new" element={<NewProduct />} />
          <Route path="/vendors/:slug/products/:slug" element={<Product />} />

          <Route path="/governments" element={<Governments />} />
          <Route path="/governments/:slug" element={<Government />} />
          <Route path="/governments/:slug/:tab" element={<Government />} />
          <Route path="/governments/:slug/stories/:slug" element={<Story />} />
          <Route path="/governments/:slug/products/:slug" element={<Product />} />
        </Routes>
      )}

      {selfie && selfie.isSiteAdmin && (
        <Routes>
          <Route path="/" element={<DashboardIndex />} />

          <Route path="/users" element={<Users />} />
          <Route path="/users/:slug" element={<UserBySlug />} />
          <Route path="/users/:slug/:tab" element={<UserBySlug />} />
          <Route path="/duplicatedUsers" element={<DuplicatedUsersPage />} />

          <Route path="/products/new" element={<NewProduct />} />
          <Route path="/products/solutions" element={<Solutions />} />
          <Route path="/products/invitations" element={<VendorProductStackRequests />} />
          <Route path="/products/:slug" element={<Product />} />
          <Route path="/products/:slug/:tab" element={<Product />} />
          <Route path="/products/:slug/stories/:slug" element={<Story />} />

          <Route path="/products" element={<AllProducts />} />
          <Route path="/suggestions" element={<Suggestions />} />

          <Route path="/onboardings" element={<Onboardings />} />

          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/new" element={<NewProjectPage />} />
          <Route path="/projects/:slug" element={<ProjectBySlug />} />
          <Route path="/projects/bulkActions" element={<ProjectsBulkActionsImport />} />

          <Route path="/vendors" element={<Vendors />} />
          <Route path="/vendors/new" element={<AddVendor />} />
          <Route path="/vendors/:slug" element={<Vendor />} />
          <Route path="/vendors/:slug/:tab" element={<Vendor />} />
          <Route path="/vendors/:slug/products/new" element={<NewProduct />} />
          <Route path="/vendors/:slug/products/:slug" element={<Product />} />

          <Route path="/stories" element={<Stories />} />
          <Route path="/stories/compose" element={<ComposeStory />} />
          <Route path="/stories/:slug" element={<Story />} />
          <Route path="/stories/:slug/edit" element={<EditStory />} />

          <Route path="/governments" element={<Governments />} />
          <Route path="/governments/new" element={<AddGovernment />} />
          <Route path="/governments/projectVerification" element={<ProjectVerification />} />
          <Route path="/governments/upload" element={<GovernmentsUpload />} />
          <Route path="/governments/:slug" element={<Government />} />
          <Route path="/governments/:slug/:tab" element={<Government />} />
          <Route path="/governments/:slug/stories/:slug" element={<Story />} />
          <Route path="/governments/:slug/products/:slug" element={<Product />} />

          <Route path="/reports/new" element={<NewReport />} />
          <Route path="/reports/:slug/edit" element={<EditReport />} />
          <Route path="/reports/:slug" element={<Report />} />
          <Route path="/reports" element={<Reports />} />

          <Route path="/groups" element={<Groups />} />
          <Route path="/groups/invitations" element={<GroupRequests />} />
          <Route path="/groups/new" element={<NewGroup />} />
          <Route path="/groups/:slug" element={<EditGroup />} />
          <Route path="/groups/:slug/:tab" element={<EditGroup />} />
          <Route path="/groups/:slug/products/:slug" element={<Product />} />

          <Route path="/posts" element={<Posts />} />

          <Route path="/tags" element={<Tags />} />
          <Route path="/tags/new" element={<NewTagPage />} />
          <Route path="/tags/:slug" element={<TagProfile />} />
          <Route path="/tags/:slug/:tab" element={<TagProfile />} />

          <Route path="/collections" element={<Collections />} />
          <Route path="/collections/new" element={<NewCollectionPage />} />
          <Route path="/collections/sponsorships" element={<CollectionSponsorships />} />
          <Route path="/collections/:slug" element={<CollectionProfile />} />
          <Route path="/collections/:slug/:tab" element={<CollectionProfile />} />

          <Route path="/billing/plans" element={<Plans />} />
          <Route path="/billing/plans/new" element={<NewPlanPage />} />
          <Route path="/billing/plans/:id" element={<EditPlanPage />} />

          <Route path="/landingPage" element={<LandingPages />} />
          <Route path="/landingPage/new" element={<NewLandingPage />} />
          <Route path="/landingPage/:configId" element={<EditLandingPage />} />

          <Route path="/externalLinks" element={<ExternalLinksPage />} />
          <Route path="/externalLinks/new" element={<NewExternalLinkPage />} />
          <Route path="/externalLinks/:id" element={<EditExternalLinkPage />} />

          <Route path="/events" element={<EventsPage />} />
          <Route path="/events/new" element={<NewEventPage />} />
          <Route path="/events/:id" element={<EditLandingPageEventsPage />} />

          <Route path="/aboutPage" element={<AboutPageConfigsList />} />
          <Route path="/aboutPage/new" element={<NewAboutPageConfigPage />} />
          <Route path="/aboutPage/:configId/edit" element={<EditAboutPageConfigPage />} />

          <Route path="/sponsorships" element={<AllSponsorships />} />
          <Route path="/sponsorships/new" element={<NewSponsorship />} />
          <Route path="/sponsorships/:slug" element={<Sponsorship />} />
          <Route path="/sponsorships/:slug/:tab" element={<Sponsorship />} />

          <Route path="/podcast" element={<PodcastPageConfig />} />

          <Route path="/campaigns/new" element={<CreateNewsletterCampaign />} />
          <Route path="/campaigns/list" element={<ListCampaigns />} />
          <Route path="/campaigns/:id" element={<EditNewsletterCampaign />} />
          <Route path="/campaigns/preview/:id" element={<CampaignContentPreviewPage />} />

          <Route path="/newsletter/settings" element={<NewsletterSettingsForm />} />
          <Route path="/newsletter/receivers" element={<NewsletterReceivers />} />
          <Route path="/newsletter/timezones" element={<TimezonesPage />} />
          <Route path="/newsletter/timezones/:countryCode" element={<EditCountryTimezonePage />} />
          <Route path="/newsletter/timezones/new" element={<NewCountryTimezonePage />} />
          <Route path="/newsletter/timezones/new/:countryCode" element={<NewCountryTimezonePage />} />

          <Route path="/images" element={<ImagesPage />} />

          <Route path="/globalSettings" element={<GlobalSettings />} />

          <Route path="/scheduledJobs" element={<ScheduledJobs />} />
        </Routes>
      )}
    </Content>
  );
}
