import gql from "graphql-tag";

export default gql`
  mutation UpdateUserAdditionalInformation(
    $userId: ObjectId!
    $additionalInformation: UserAdditionalInformationInput!
  ) {
    updateUserAdditionalInformation(userId: $userId, additionalInformation: $additionalInformation) {
      ... on PendingUser {
        _id
        jobTitle
        notes
        department {
          _id
          name
        }
        interests {
          _id
          name
        }
        notificationEmail
        socialMedia {
          linkedin
          twitter
          facebook
          instagram
          youtube
        }
        country
        language
        timeZone
        newsletterFrequency
        subscribedToNewsletter
        subscribedToCampaign
      }
      ... on VendorUser {
        _id
        jobTitle
        notes
        department {
          _id
          name
        }
        interests {
          _id
          name
        }
        notificationEmail
        socialMedia {
          linkedin
          twitter
          facebook
          instagram
          youtube
        }
        country
        language
        timeZone
        newsletterFrequency
        subscribedToNewsletter
        subscribedToCampaign
      }
      ... on GovernmentUser {
        _id
        jobTitle
        notes
        department {
          _id
          name
        }
        interests {
          _id
          name
        }
        notificationEmail
        socialMedia {
          linkedin
          twitter
          facebook
          instagram
          youtube
        }
        country
        language
        timeZone
        newsletterFrequency
        subscribedToNewsletter
        subscribedToCampaign
      }
    }
  }
`;
