import React, { ReactElement, ReactNode, Children } from "react";
import mediaQueries from "./mediaQueries";

type ResponsiveCSSNumber = number | number[] | string | null | undefined;

interface IMarginProps {
  mt?: ResponsiveCSSNumber;
  mb?: ResponsiveCSSNumber;
  ml?: ResponsiveCSSNumber;
  mr?: ResponsiveCSSNumber;
  mx?: ResponsiveCSSNumber;
  my?: ResponsiveCSSNumber;
  css?: {
    [key: string]: string | number | null;
  } | null;
  style?: React.CSSProperties;
  children: ReactNode;
}

export const Margin = ({ mt, mb, ml, mr, mx, my, children, css, ...props }: IMarginProps): ReactElement => {
  return (
    <div
      {...props}
      css={mediaQueries({
        marginTop: mt,
        marginBottom: mb,
        marginLeft: ml,
        marginRight: mr,
        ...xAxis("margin", mx),
        ...yAxis("margin", my),
        ...css,
      })}
    >
      {children}
    </div>
  );
};

interface IPaddingProps {
  pt?: ResponsiveCSSNumber;
  pb?: ResponsiveCSSNumber;
  pl?: ResponsiveCSSNumber;
  pr?: ResponsiveCSSNumber;
  px?: ResponsiveCSSNumber;
  py?: ResponsiveCSSNumber;
  css?: {
    [key: string]: string | number | null;
  } | null;
  children: ReactNode;
}

const Padding = ({ pt, pb, pl, pr, px, py, children, css, ...props }: IPaddingProps): ReactElement => {
  return (
    <div
      {...props}
      css={mediaQueries({
        paddingTop: pt,
        paddingBottom: pb,
        paddingLeft: pl,
        paddingRight: pr,
        ...xAxis("padding", px),
        ...yAxis("padding", py),
        ...css,
      })}
    >
      {children}
    </div>
  );
};

Padding.defaultProps = {
  pt: null,
  pb: null,
  pl: null,
  pr: null,
  px: null,
  py: null,
  css: null,
};

interface IXAxis {
  marginLeft?: ResponsiveCSSNumber;
  marginRight?: ResponsiveCSSNumber;
  paddingLeft?: ResponsiveCSSNumber;
  paddingRight?: ResponsiveCSSNumber;
}

function xAxis(prefix: string, value: ResponsiveCSSNumber): IXAxis {
  if (!value) {
    return {};
  }

  if (prefix === "margin") {
    return {
      marginLeft: value,
      marginRight: value,
    };
  } else if (prefix === "padding") {
    return {
      paddingLeft: value,
      paddingRight: value,
    };
  }

  return {};
}

interface IYAxis {
  marginTop?: ResponsiveCSSNumber;
  marginBottom?: ResponsiveCSSNumber;
  paddingTop?: ResponsiveCSSNumber;
  paddingBottom?: ResponsiveCSSNumber;
}

function yAxis(prefix: string, value: ResponsiveCSSNumber): IYAxis {
  if (!value) {
    return {};
  }

  if (prefix === "margin") {
    return {
      marginTop: value,
      marginBottom: value,
    };
  } else if (prefix === "padding") {
    return {
      paddingTop: value,
      paddingBottom: value,
    };
  }

  return {};
}

interface ISpacingBetweenProps {
  top?: ResponsiveCSSNumber;
  right?: ResponsiveCSSNumber;
  children: ReactNode;
  style?: React.CSSProperties;
}

export const SpacingBetween = ({ top, right, children, ...props }: ISpacingBetweenProps): ReactElement => {
  const actualChildren = Children.toArray(children);
  const childrenCount = actualChildren.length;

  return (
    <>
      {actualChildren.map((child, index) => {
        if (top && top > 0 && index > 0) {
          return (
            <Margin key={index} mt={top} {...props}>
              {child}
            </Margin>
          );
        } else if (right && right > 0 && index < childrenCount - 1) {
          return (
            <Margin key={index} mr={right} {...props}>
              {child}
            </Margin>
          );
        }

        return child;
      })}
    </>
  );
};

SpacingBetween.defaultProps = {
  top: 0,
  right: 0,
};
