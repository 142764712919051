import gql from "graphql-tag";

export default gql`
  mutation ChangeCollectionPosition($collectionId: ObjectId!, $position: Int!) {
    changeCollectionPosition(collectionId: $collectionId, position: $position) {
      _id
      position
    }
  }
`;
