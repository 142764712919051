import { useLocation } from "react-router";
import { parseState } from "../../../utils/tablesUtils";
import { useQuery } from "react-apollo";
import queryString from "query-string";
import TablePagination, { DEFAULT_CURSOR, ITablePaginationState } from "../../../components/table/TablePagination";
import React, { useState } from "react";
import {
  IListAllCollectionsQuery,
  IListAllCollectionsQueryVariables,
} from "../graphql/queries/__generated__/ListAllCollections.generated";
import ListAllCollections from "../graphql/queries/ListAllCollections";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import {
  ICollectionFeaturedStatus,
  ICollectionPackaging,
  ICollectionPublishedStatus,
  ICollectionSponsoring,
  ICollectionStatus,
} from "../../../../types/types";
import TableAddStateToQueryString from "../../../components/table/TableAddStateToQueryString";
import CollectionsTable from "./CollectionsTable";
import TableTitle from "../../../components/table/TableTitle";
import TableOnSearch from "../../../components/table/TableOnSearch";

export interface ICollectionsTableState {
  cursor: ITablePaginationState;
}

interface IManageCollectionsProps {
  searchText: string;
  tab: string;
  refetchCounters: any;
  historyOrigin: string;
}

export default function ManageCollections({
  searchText,
  tab,
  refetchCounters,
  historyOrigin,
}: IManageCollectionsProps) {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<ICollectionsTableState>(initialFiltersState);

  const { data, loading, refetch } = useQuery<IListAllCollectionsQuery, IListAllCollectionsQueryVariables>(
    ListAllCollections,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        searchQuery: searchText,
        cursor: calculateAndEncodeCursor(state.cursor),
        filterByPackaging:
          tab === "single" ? ICollectionPackaging.Single : tab === "bundles" ? ICollectionPackaging.Bundle : null,
        filterByPublishedStatus:
          tab === "unpublished" ? ICollectionPublishedStatus.Unpublished : ICollectionPublishedStatus.Published,
        filterBySponsoring:
          tab === "curated"
            ? ICollectionSponsoring.VendorCurated
            : tab === "sponsored"
            ? ICollectionSponsoring.VendorSponsored
            : null,
        filterByFeaturedStatus: tab === "featured" ? ICollectionFeaturedStatus.FeaturedOnly : null,
        filterByStatus: ["active", "archived", "inactive"].includes(tab)
          ? (tab.toUpperCase() as ICollectionStatus)
          : null,
      },
    },
  );

  const collections = (data && data.listAllCollections && data.listAllCollections.items) || [];
  const totalCount = data?.listAllCollections.pageInfo ? data.listAllCollections.totalCount || 0 : 0;

  return (
    <>
      <TableAddStateToQueryString tableState={state} historyOrigin={historyOrigin} query={query} />
      <TableOnSearch
        search={searchText}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
 page: 1, size: state.cursor.size 
},
            });
          }
        }}
      />
      <CollectionsTable
        refetch={refetch}
        title={() => (
          <TableTitle
            title="Collections"
            count={totalCount}
            loading={loading}
            onResetFilters={() => {}}
            showResetButton={false}
          />
        )}
        footer={() => {
          return (
            <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
          );
        }}
        loading={loading}
        collections={collections}
        state={state}
        refetchCounters={refetchCounters}
      />
    </>
  );
}
