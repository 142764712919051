import React from "react";
import { IListAllTagsQuery, IListAllTagsQueryVariables } from "../../queries/__generated__/ListAllTags.generated";
import { Query } from "react-apollo";
import ListAllTags from "../../queries/ListAllTags";
import { ITagType } from "../../../types/types";
import { LoadingIndicator } from "@govlaunch/core";
import { Link } from "react-router-dom";
import TagActions from "./TagActions";
import { Table } from "antd";
import moment from "moment";

interface ITagsTableProps {
  search: string;
  archivedOnly: boolean;
  filterByTagType?: ITagType;
}

type TTag = IListAllTagsQuery["listAllTags"][0];

const TagsTable = ({ search, filterByTagType, archivedOnly }: ITagsTableProps) => (
  <Query<IListAllTagsQuery, IListAllTagsQueryVariables>
    query={ListAllTags}
    fetchPolicy="network-only"
    variables={{
      search: search,
      filterByTagType: filterByTagType || null,
      archivedOnly,
    }}
  >
    {({ data, loading }) => {
      if (loading) {
        return <LoadingIndicator />;
      }

      const tags = (data && data.listAllTags) || [];
      const totalCount = tags.length;

      return (
        <>
          {archivedOnly ? (
            <Table<TTag>
              rowKey="_id"
              scroll={{
                x: true,
              }}
              bordered={true}
              pagination={false}
              dataSource={tags}
              title={() => {
                return <strong>{loading ? "Tags" : `Tags (${totalCount})`}</strong>;
              }}
              columns={[
                {
                  title: "Name",
                  sorter: (a, b) => a.name.localeCompare(b.name),
                  render: (tag: TTag) => {
                    return <Link to={`/tags/${tag.slug}`}>{tag.name}</Link>;
                  },
                },
                {
                  title: "Archived At",
                  render: (tag: TTag) => {
                    return moment(tag.deletedAt).format("MM/DD/YYYY");
                  },
                },
                {
                  title: "Archived By",
                  render: (tag: TTag) => {
                    if (tag.deletedBy) {
                      return <Link to={`/users/${tag.deletedBy.slug}`}>{tag.deletedBy.fullName}</Link>;
                    } else {
                      return "N/A";
                    }
                  },
                },
                {
                  title: "Actions",
                  render: (tag: any) => <TagActions tag={tag} />,
                },
              ]}
            />
          ) : (
            <Table<TTag>
              rowKey="_id"
              bordered={true}
              pagination={{
                defaultPageSize: 30,
              }}
              scroll={{
                x: true,
              }}
              dataSource={tags}
              title={() => {
                return <strong>{loading ? "Tags" : `Tags (${totalCount})`}</strong>;
              }}
              columns={[
                {
                  title: "Name",
                  sorter: (a, b) => a.name.localeCompare(b.name),
                  render: (tag: TTag) => {
                    return <Link to={`/tags/${tag.slug}`}>{tag.name}</Link>;
                  },
                },
                {
                  title: "Total",
                  dataIndex: ["aggregations", "mentionsCount"],
                  sorter: (a, b) => a.aggregations.mentionsCount - b.aggregations.mentionsCount,
                },
                {
                  title: "Users",
                  dataIndex: ["aggregations", "usersCount"],
                  sorter: (a, b) => a.aggregations.usersCount - b.aggregations.usersCount,
                },
                {
                  title: "Products",
                  dataIndex: ["aggregations", "productsCount"],
                  sorter: (a, b) => a.aggregations.productsCount - b.aggregations.productsCount,
                },
                {
                  title: "Vendors",
                  dataIndex: ["aggregations", "vendorsCount"],
                  sorter: (a, b) => a.aggregations.vendorsCount - b.aggregations.vendorsCount,
                },
                {
                  title: "Stories",
                  dataIndex: ["aggregations", "storiesCount"],
                  sorter: (a, b) => a.aggregations.storiesCount - b.aggregations.storiesCount,
                },
                {
                  title: "Projects",
                  dataIndex: ["aggregations", "projectsCount"],
                  sorter: (a, b) => a.aggregations.projectsCount - b.aggregations.projectsCount,
                },
                {
                  title: "Awards",
                  dataIndex: ["aggregations", "awardsCount"],
                  sorter: (a, b) => a.aggregations.awardsCount - b.aggregations.awardsCount,
                },
                {
                  title: "Groups",
                  dataIndex: ["aggregations", "groupsCount"],
                  sorter: (a, b) => a.aggregations.awardsCount - b.aggregations.awardsCount,
                },
                {
                  title: "Actions",
                  render: (tag: any) => <TagActions tag={tag} />,
                },
              ]}
            />
          )}
        </>
      );
    }}
  </Query>
);

export default TagsTable;
