import { COUNTRIES } from "../governments/Countries";
import { IGetNewsletterCampaignQuery } from "./graphql/__generated__/GetNewsletterCampaign.generated";

export function convertCampaignToIntialVariables(
  campaign: NonNullable<IGetNewsletterCampaignQuery["getNewsletterCampaign"]>,
) {
  return {
    name: campaign.name,
    storiesCount: campaign.dynamicContent.storiesCount,
    projectsCount: campaign.dynamicContent.projectsCount,
    topCollections: campaign.dynamicContent.topCollections,
    otherCollections: campaign.dynamicContent.otherCollections,
    projectsPerCountry: campaign.countries
      .filter((countryAmounts) => countryAmounts.amount > 0)
      .map((countryAmounts) => {
        return {
          id: Math.random() * 10000,
          countryCode: countryAmounts.countryCode,
          name: COUNTRIES.find((country) => country.code === countryAmounts.countryCode)?.name,
          amount: countryAmounts.amount,
        };
      }),
  };
}
