import * as palette from "@govlaunch/palette";
import { Select } from "@govlaunch/select";
import React from "react";
import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";

export interface IOutcomeOption {
  value: "failed" | "success" | null;
  label: string;
}

const OutcomeInput = () => {
  return (
    <Field allowNull={true} name="outcome">
      {({ input }) => {
        const {value,}: {
          value: IOutcomeOption;
        } = input;

        const items: any = [
          {
            value: "failed",
            label: "Failed",
          },
          {
            value: "success",
            label: "Succeeded",
          },
        ];

        if (value) {
          items.unshift({
            value: null,
            label: "Not Specified",
          });
        }

        return (
          <div
            css={{
              display: "grid",
              gridRowGap: 12,
            }}
          >
            <Select {...input} size="large" placeholder="Select an outcome" items={items} />

            {value && value.value !== null && (
              <Field name="outcomeStatement">
                {({ input }) => (
                  <div>
                    <TextareaAutosize
                      {...input}
                      autoFocus={true}
                      maxLength={480}
                      placeholder={
                        value.value === "failed"
                          ? "Can you tell us a little bit about why this project failed? Any lessons learned? (optional)"
                          : "Can you tell us a little bit about why this project succeeded? Any lessons learned? (optional)"
                      }
                      css={{
                        padding: 10,
                        outline: 0,
                        minHeight: 80,
                        fontSize: 14,
                        borderRadius: 4,
                        resize: "none",
                        width: "100%",
                        border: `solid 1px ${palette.lightestGray}`,
                        "&:focus": {
                          border: `solid 1px ${palette.primary}`,
                        },
                        "&::placeholder": {
                          color: palette.sealBlue,
                          userSelect: "none",
                        },
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                )}
              </Field>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default OutcomeInput;
