import React from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import TableSearch from "../../../components/table/TableSearch";
import ManageEvents from "./ManageEvents";

export default function EventsPageWithSearch() {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div
        css={{
          marginBottom: 20,
        }}
      >
        <TableSearch historyOrigin="/events" query={query} inputPlaceholder="Search for events..." />
      </div>
      <ManageEvents search={query.search as string} />
    </>
  );
}
