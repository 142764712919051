import React, { ReactElement, ReactNode, useState } from "react";
import { Modal, Button, Filestack } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import BodyField from "./fields/BodyField";
import { Form, Field } from "react-final-form";
import Popover from "@govlaunch/popover";
import { Hover } from "react-powerplug";
import LinksToolbarIcon from "./icons/LinksToolbarIcon";
import ImageToolbarIcon from "./icons/ImageToolbarIcon";
import DocumentToolbarIcon from "./icons/DocumentToolbarIcon";
import LinkField from "./fields/LinkField";
import * as Yup from "yup";
import { Mutation } from "react-apollo";
import { convertValuesToVariables } from "./utils";
import LinkCard from "./LinkCard";
import RemoveButton from "./RemoveButton";
import DocumentCard from "./DocumentCard";
import validate from "../../validate";
import { Margin } from "../../spacings";
import EditPost from "../../mutations/EditPost";

interface IToolbarItem {
  active?: boolean;
  tooltip: string;
  onClick?: () => any;
  children: ReactNode;
}

interface IPostFormModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  initialValues?: any;
  onDelete?: () => void;
}

const POST_INITIAL_STATE = {
  links: [],
  documents: [],
  images: [],
  audience: "public",
};

export default function PostFormModal({
  isOpen,
  onRequestClose,
  initialValues = POST_INITIAL_STATE,
}: IPostFormModalProps) {
  return (
    <Mutation mutation={EditPost}>
      {(editPost: any) => (
        <>
          <Modal
            isOpen={isOpen}
            style={{
              overflow: "visible",
            }}
            onRequestClose={() => onRequestClose()}
            appElement={document.body}
          >
            <span
              role="button"
              css={{
                color: palette.darkGray,
                fontSize: 24,
                fontWeight: 500,
                position: "absolute",
                top: 12,
                right: 12,
                width: 12,
                height: 12,
                cursor: "pointer",
              }}
              onClick={() => onRequestClose()}
            >
              &times;
            </span>
            <div
              css={{
                width: 555,
                padding: 16,
              }}
            >
              <PostForm
                initialValues={initialValues}
                onSubmit={(values) => {
                  return editPost({
                    variables: {
                      id: initialValues._id,
                      post: {
                        ...convertValuesToVariables(values),
                        audience: values.group ? "community" : values.audience.toLowerCase(),
                      },
                    },
                  }).then(() => onRequestClose());
                }}
              />
            </div>
          </Modal>
        </>
      )}
    </Mutation>
  );
}

interface IPostFormProps {
  onSubmit: (args?: any) => void;
  initialValues: any;
}

function PostForm({ onSubmit, initialValues }: IPostFormProps): ReactElement {
  const [isAddingLink, setIsAddingLink] = useState<boolean>(false);
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate(validationSchema)}>
      {({ handleSubmit, submitting, values, form }) => {
        const hasLinks = values.links && Array.isArray(values.links) && values.links.length > 0;

        return (
          <form onSubmit={handleSubmit}>
            <Margin mt={16}>
              <BodyField />
            </Margin>

            {values.images.map((image: any) => (
              <div
                key={image.url}
                css={{
                  marginTop: 12,
                  position: "relative",
                }}
              >
                <img
                  src={image.url}
                  css={{
                    maxHeight: 250,
                    maxWidth: "100%",
                    objectFit: "contain",
                    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.39)",
                  }}
                />

                <RemoveButton
                  onClick={() => form.change("images", [])}
                  css={{
                    position: "absolute",
                    top: 10,
                    left: 10,
                  }}
                />
              </div>
            ))}

            {values.documents.map((document: any) => {
              const { filename, mimeType, sizeInBytes, url } = document;

              return (
                <Margin mt={12} key={url}>
                  <DocumentCard
                    url={url}
                    filename={filename}
                    mimeType={mimeType}
                    sizeInBytes={sizeInBytes}
                    onRemove={() => form.change("documents", [])}
                  />
                </Margin>
              );
            })}

            {isAddingLink && (
              <Margin mt={12}>
                <LinkField onCancel={() => setIsAddingLink(false)} />
              </Margin>
            )}

            {hasLinks && (
              <div
                css={{
                  marginTop: 15,
                }}
              >
                {values.links.map(({ url, ...meta }: any) => {
                  if (!meta) {
                    return null;
                  }

                  return (
                    <div key={url || ""}>
                      <LinkCard
                        url={url || ""}
                        title={meta.title || ""}
                        siteName={meta.siteName || ""}
                        description={meta.description || ""}
                        favicon={meta.favicon || ""}
                        onRemove={() => {
                          form.change("links", []);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}

            <Margin mt={16}>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {values.links.length === 0 &&
                    values.images.length === 0 &&
                    values.documents.length === 0 &&
                    !isAddingLink && (
                      <div
                        css={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gridColumnGap: 12,
                          maxWidth: 121,
                        }}
                      >
                        <ToolbarItem tooltip="Link" onClick={() => setIsAddingLink(true)}>
                          <LinksToolbarIcon />
                        </ToolbarItem>

                        <Field name="images">
                          {({ input }) => (
                            <Filestack
                              options={{
                                accept: ["image/jpeg", "image/jpg", "image/png", "image/gif"],
                              }}
                              onSuccess={({ filesUploaded }: any) => {
                                if (filesUploaded.length > 0) {
                                  const file = filesUploaded[0];

                                  input.onChange([
                                    {
                                      filename: file.filename,
                                      mimeType: file.mimetype,
                                      sizeInBytes: file.size,
                                      url: file.url,
                                    },
                                  ]);

                                  form.change("documents", []);
                                  form.change("links", []);
                                }
                              }}
                              customRender={({ onPick }: any) => (
                                <ToolbarItem tooltip="Image" onClick={onPick}>
                                  <ImageToolbarIcon />
                                </ToolbarItem>
                              )}
                            />
                          )}
                        </Field>

                        <Field name="documents">
                          {({ input }) => (
                            <Filestack
                              options={{
                                accept: [
                                  "application/msword",
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                  "application/vnd.ms-excel",
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                  "application/pdf",
                                ],
                              }}
                              onSuccess={({ filesUploaded }: any) => {
                                if (filesUploaded.length > 0) {
                                  const file = filesUploaded[0];

                                  input.onChange([
                                    {
                                      filename: file.filename,
                                      mimeType: file.mimetype,
                                      sizeInBytes: file.size,
                                      url: file.url,
                                    },
                                  ]);

                                  form.change("links", []);
                                  form.change("images", []);
                                }
                              }}
                              customRender={({ onPick }: any) => (
                                <ToolbarItem tooltip="Document" onClick={onPick}>
                                  <DocumentToolbarIcon />
                                </ToolbarItem>
                              )}
                            />
                          )}
                        </Field>
                      </div>
                    )}
                </div>

                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="xsmall"
                    color={submitting ? "disabled" : palette.innovatorBlue}
                    disabled={submitting}
                    type="submit"
                    css={{
                      userSelect: "none",
                    }}
                  >
                    {submitting ? "Saving..." : initialValues._id ? "Edit" : "Save"}
                  </Button>
                </div>
              </div>
            </Margin>
          </form>
        );
      }}
    </Form>
  );
}

const ToolbarItem = ({ active = false, tooltip, ...props }: IToolbarItem): ReactElement => {
  return (
    <Hover>
      {({ hovered, bind }) => (
        <Popover
          isOpen={hovered}
          placement="top"
          backgroundColor={palette.innovatorBlue}
          render={() => {
            return (
              <div
                css={{
                  padding: 8,
                  color: palette.white,
                  fontSize: 12,
                  fontWeight: "bold",
                }}
              >
                {tooltip}
              </div>
            );
          }}
          borderColor="transparent"
          boxStyle={{
            boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.2)",
            zIndex: 9999,
          }}
        >
          {({ innerRef }: any) => {
            return (
              <div
                {...props}
                {...bind}
                ref={innerRef}
                css={{
                  height: 35,
                  width: 35,
                  borderRadius: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: active ? "#b9e2fd" : palette.lightestBlue,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: active ? "#b9e2fd" : palette.lightBlue,
                  },
                }}
              />
            );
          }}
        </Popover>
      )}
    </Hover>
  );
};

const validationSchema = Yup.object().shape({
  body: Yup.string()
    .required("Can't be blank")
    .min(3, "Write more than 3 characters")
    .max(280, "At most 280 characters"),
});
