import React from "react";
import { Margin } from "../../../spacings";
import ColorPickerField from "../../form/fields/ColorPickerField";
import FilestackUploadField from "../../form/fields/FilestackUploadField";
import TextField from "../../form/fields/TextField";

interface ITextSideContentFieldsProps {
  side: "leftBox" | "rightBox";
}

export default function TextSideContentFields({ side }: ITextSideContentFieldsProps) {
  return (
    <div css={{
 marginTop: 16 
}}
    >
      <FilestackUploadField fieldName={`${side}.iconSrc`} uploadButtonText="Change image" />

      <Margin mt={16}>
        <TextField
          fieldName={`${side}.iconDescription`}
          label={side === "leftBox" ? "Left box image description" : "Right box image description"}
          placeholder={side === "leftBox" ? "Type left box image description" : "Type right box image description"}
        />
      </Margin>

      <Margin mt={8}>
        Image dimensions (Leave empty for auto)
        <div
          css={{
            display: "flex",
            alignItems: "center",
            maxWidth: 220,
          }}
        >
          <TextField fieldName={`${side}.width`} placeholder="Type width..." />
          <span
            css={{
              fontSize: 20,
              margin: "0 4px",
            }}
          >
            x
          </span>
          <TextField fieldName={`${side}.height`} placeholder="Type height..." />
        </div>
      </Margin>

      <Margin mt={8}>
        <TextField fieldName={`${side}.title`} label="Title" placeholder="Type title..." />
      </Margin>

      <Margin mt={8}>
        <ColorPickerField
          fieldName={`${side}.titleFontColor`}
          label="Title font color"
          placeholder="Type title font color..."
        />
      </Margin>

      <Margin mt={8}>
        <TextField fieldName={`${side}.text`} label="Text" placeholder="Type text..." />
      </Margin>

      <Margin mt={8}>
        <ColorPickerField
          fieldName={`${side}.textFontColor`}
          label="Text font color"
          placeholder="Type text font color..."
        />
      </Margin>

      <Margin mt={8}>
        <TextField fieldName={`${side}.textHref`} label="Text URL" placeholder="Type text URL..." />
      </Margin>

      <Margin mt={8}>
        <ColorPickerField
          fieldName={`${side}.textHrefHoverColor`}
          label="Text link hover color"
          placeholder="Type link hover color..."
        />
      </Margin>
    </div>
  );
}
