import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router";

export default function AddPlanButton() {
  const navigate = useNavigate();

  return (
    <>
      <Button size="large" type="dashed" onClick={() => navigate("/billing/plans/new")}>
        Add Plan
      </Button>
    </>
  );
}
