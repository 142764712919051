import React from "react";
import PropTypes from "prop-types";
import * as palette from "@govlaunch/palette";

export default function RemoveResourceButton({ onClick }: { onClick: React.MouseEventHandler<HTMLButtonElement> }) {
  return (
    <button
      css={{
        cursor: "pointer",
        top: "50%",
        transform: "translateY(-50%)",
        right: 2,
        position: "absolute",
        width: 25,
        height: 25,
        lineHeight: "20px",
        color: palette.red,
        fontWeight: 500,
        fontSize: 22,
        border: 0,
        borderRadius: "100%",
      }}
      type="button"
      onClick={onClick}
    >
      <div
        css={{
          position: "absolute",
          right: 5,
          bottom: 5,
        }}
      >
        &times;
      </div>
    </button>
  );
}

RemoveResourceButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
