import React from "react";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import TableSearch from "../../../components/table/TableSearch";
import ManageGovernments, { IGovernmentsFilter } from "./ManageGovernments";

interface IGovernmentsWithSearchProps {
  filter: IGovernmentsFilter;
  filterByTag?: any;
  filterByCountry?: string;
  historyOrigin?: string;
  [K: string]: any;
}

export default function GovernmentsWithSearch({
  filter,
  filterByTag = null,
  historyOrigin = "/governments",
  ...props
}: IGovernmentsWithSearchProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div css={{
 marginBottom: 20 
}}
      >
        <TableSearch historyOrigin={historyOrigin} query={query} inputPlaceholder="Search for governments..." />
      </div>

      <ManageGovernments
        filter={filter}
        search={query.search as string}
        filterByTag={filterByTag}
        {...props}
        navigate={navigate}
        historyOrigin={historyOrigin}
      />
    </>
  );
}
