import * as palette from "@govlaunch/palette";
import React from "react";
import { Mutation } from "react-apollo";
import { useToggle } from "react-use";
import { IMutationToggleUsersCanAccessAccountPageArgs } from "../../../../types/types";
import ToggleUsersCanAccessAccountPage from "../../../mutations/ToggleUsersCanAccessAccountPage";
import { IToggleUsersCanAccessAccountPageMutation } from "../../../mutations/__generated__/ToggleUsersCanAccessAccountPage.generated";
import Switch from "react-switch";
import styled from "@emotion/styled";

interface IUsersCanAccessAccountPageProps {
  usersCanAccessAccountPage: boolean;
  vendorId: any;
}

export function UsersCanAccessAccountPageToggleRow({
  usersCanAccessAccountPage,
  vendorId,
}: IUsersCanAccessAccountPageProps) {
  return (
    <div
      css={{
        paddingTop: 20,
      }}
    >
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "max-content 1fr",
          gridColumnGap: 40,
          gridRowGap: 30,
        }}
      >
        <ItemTitle>Enable Users to Access Account Page</ItemTitle>
        <div>
          <ToggleUsersCanAccessAccountPageMutation
            usersCanAccessAccountPage={usersCanAccessAccountPage}
            vendorId={vendorId}
          />
        </div>
      </div>
    </div>
  );
}

export const ToggleUsersCanAccessAccountPageMutation = ({
  usersCanAccessAccountPage,
  vendorId,
}: IUsersCanAccessAccountPageProps) => {
  const [usersAllowedToViewPage, toggleUsersAllowedToViewPage] = useToggle(usersCanAccessAccountPage);

  return (
    <Mutation<IToggleUsersCanAccessAccountPageMutation, IMutationToggleUsersCanAccessAccountPageArgs>
      onCompleted={() => {}}
      variables={{
        vendorId: vendorId,
        isAccessAllowed: usersAllowedToViewPage,
      }}
      mutation={ToggleUsersCanAccessAccountPage}
    >
      {(toggleAccess) => (
        <Switch
          uncheckedIcon={
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: palette.darkGray,
                paddingRight: 10,
              }}
            >
              No
            </div>
          }
          checkedIcon={
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "#fff",
                paddingLeft: 10,
              }}
            >
              Yes
            </div>
          }
          handleDiameter={22}
          height={26}
          width={65}
          onChange={async (checked) => {
            toggleUsersAllowedToViewPage(checked);
            await toggleAccess({
              variables: {
                vendorId: vendorId,
                isAccessAllowed: checked,
              },
            });
          }}
          checked={usersAllowedToViewPage}
          onColor={palette.greenSuccess}
          offColor={palette.lightGray}
        />
      )}
    </Mutation>
  );
};

export const ItemTitle = styled.div({
  fontSize: 16,
  fontWeight: 600,
  color: "#949195",
  minWidth: 350,
});
