import React from "react";
import { ITagType } from "../../../../types/types";
import GroupsField from "../../../components/form/GroupsField";
import { TitleField } from "./TitleField";
import { TagsField } from "@govlaunch/web";
import SubtitleField from "./fields/SubtitleField";
import ProjectTypeField from "./fields/ProjectTypeField";
import DescriptionField from "./fields/DescriptionField";
import OutcomeField from "./fields/OutcomeField";
import AwardsField from "./fields/AwardsFIeld";
import ProductsField from "./fields/ProductsField";
import NewsworthyField from "./fields/NewsworthyField";
import VendorsField from "./fields/VendorsField";
import AttachmentsField from "./fields/AttachmentsField";
import LinksField from "./fields/LinksField";

interface IMainFieldsProps {
  withGovernment: boolean;
}

export default function MainFields({ withGovernment }: IMainFieldsProps) {
  return (
    <>
      {withGovernment && <ProjectTypeField />}
      <TitleField />
      <SubtitleField />
      <DescriptionField />
      <TagsField fieldName="tags" type={[ITagType.Project, ITagType.Custom]} />
      <AwardsField />
      <OutcomeField />
      <ProductsField />
      <NewsworthyField />
      <VendorsField />
      <GroupsField fieldName="groups" />
      <LinksField />
      <AttachmentsField />
    </>
  );
}
