import { ObservableQuery } from "apollo-client";
import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { IUserBySlugQuery, IUserBySlugQueryVariables } from "../../../queries/__generated__/UserBySlugQuery.generated";
import ProjectsTable from "../../projects/tables/ProjectsTable";

interface IUserProjectsTabProps
  extends Pick<ObservableQuery<IUserBySlugQuery, IUserBySlugQueryVariables>, "fetchMore" | "refetch"> {
  user: NonNullable<IUserBySlugQuery["user"]>;
  loading: boolean;
}

export default function UserProjectsTab({ user, fetchMore, loading }: IUserProjectsTabProps) {
  if (!user.projects || !user.projects.pageInfo || !user.projects.items) {
    return null;
  }

  return (
    <div
      css={{
        padding: "0 15px",
      }}
    >
      <ProjectsTable projects={user.projects.items} onDeleteProject={() => {}} onChange={null} loading={loading} />

      <VisibilitySensor
        active={Boolean(!loading && user.projects.pageInfo.hasNextPage)}
        partialVisibility={true}
        onChange={isActive => {
          if (!user.projects || !user.projects.pageInfo) {
            return;
          }

          if (!isActive) {
            return;
          }

          fetchMore({
            variables: {
              projectsCursor: user.projects.pageInfo.endCursor,
            },
            updateQuery: (current, { fetchMoreResult }) => {
              if (!current.user || !current.user.projects || !current.user.projects.items) {
                return current;
              }

              if (
                !fetchMoreResult ||
                !fetchMoreResult.user ||
                !fetchMoreResult.user.projects ||
                !fetchMoreResult.user.projects.items
              ) {
                return current;
              }

              return {
                __typename: "Query",
                user: {
                  ...current.user,
                  projects: {
                    ...fetchMoreResult.user.projects,
                    items: [...current.user.projects.items, ...fetchMoreResult.user.projects.items],
                  },
                },
              };
            },
          });
        }}
      />
    </div>
  );
}
