import React from "react";
import { RowWrap, Col } from "@govlaunch/core";
import Section from "../../form/Section";
import SectionBody from "../../form/SectionBody";
import SectionHeader from "../../form/SectionHeader";
import EmployeesNumberField from "./fields/EmployeesNumberField";
import NewsworthyField from "../../form/fields/NewsworthyField";
import LogoField from "../../form/fields/LogoField";
import SocialMediaFields from "../../form/fields/SocialMediaFields";
import { FormSpy } from "react-final-form";
import SponsorshipsField from "../../../components/form/SponsorshipsField";
import UrlField from "../../form/fields/UrlField";
import TextareaField from "../../form/fields/TextareaField";
import TextField from "../../form/fields/TextField";
import FormSubmitSection from "../../form/FormSubmitSection";
import FormGrid from "../../form/FormGrid";
import SectionSplitter from "../../form/SectionSplitter";
import HighlightsInputField from "../../form/fields/HighlightsInputField";
import ResourcesField from "../../form/fields/ResourcesField";

interface IVendorFormProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  submitting: boolean;
  submitButtonText?: string;
  initialVendorName?: string | null;
  submitError?: string;
}

export default function VendorForm({ onSubmit, submitting, submitButtonText = "Save", submitError }: IVendorFormProps) {
  return (
    <form onSubmit={onSubmit}>
      <FormGrid>
        <FormSubmitSection
          backButtonText="All Vendors"
          backButtonLocation="/vendors"
          discardChangesLocation="/vendors"
          submitButtonText={submitButtonText}
          submitting={submitting}
          submitError={submitError}
        />

        <RowWrap>
          <Col width={[1, 1, 1, 8 / 12]}>
            <SectionSplitter>
              <Section>
                <SectionHeader>The Basics</SectionHeader>

                <SectionBody>
                  <TextField fieldName="name" label="Name" placeholder="Type vendor title..." />
                  <TextField fieldName="billingName" label="Billing Name" placeholder="Billing name..." />
                  <TextareaField
                    fieldName="description"
                    label="Description"
                    placeholder="Vendor description"
                    ariaLabel="Vendor description"
                    characterLimit={240}
                  />
                  <TextField label="Year Founded" fieldName="yearFounded" placeholder="Type year founded" />
                  <UrlField label="Site" fieldName="site" placeholder="Type vendor site (https://)" />
                </SectionBody>
              </Section>

              <Section>
                <SectionHeader>More Info</SectionHeader>

                <SectionBody>
                  <EmployeesNumberField />
                  <TextField
                    label="Headquarters"
                    fieldName="headquarters"
                    placeholder="Type vendor headquarters"
                    characterLimit={240}
                  />
                  <TextField
                    label="Slogan"
                    fieldName="slogan"
                    placeholder="Type your vendor slogan"
                    characterLimit={180}
                  />
                  <TextField label="Contact" fieldName="contact" placeholder="Type a URL or email for contact" />
                  <SponsorshipsField />
                </SectionBody>
              </Section>

              <Section>
                <SectionHeader>Highlights</SectionHeader>
                <SectionBody>
                  <HighlightsInputField />
                </SectionBody>
              </Section>

              <Section>
                <SectionHeader>Newsworthy</SectionHeader>
                <SectionBody>
                  <NewsworthyField fieldName="newsworthy" />
                </SectionBody>
              </Section>
            </SectionSplitter>
          </Col>

          <Col width={[1, 1, 1, 4 / 12]}>
            <SectionSplitter>
              <Section>
                <SectionHeader>Logo</SectionHeader>
                <SectionBody>
                  <FormSpy
                    subscription={{
                      values: true,
                    }}
                  >
                    {({ values }) => <LogoField name={values.name || "A B"} />}
                  </FormSpy>
                </SectionBody>
              </Section>

              <Section>
                <SectionHeader>Social Media</SectionHeader>
                <SectionBody>
                  <SocialMediaFields />
                </SectionBody>
              </Section>

              <Section>
                <SectionHeader>Resources</SectionHeader>

                <SectionBody>
                  <ResourcesField />
                </SectionBody>
              </Section>
            </SectionSplitter>
          </Col>
        </RowWrap>
      </FormGrid>
    </form>
  );
}
