import React from "react";

interface ISectionSplitterProps {
  children: any;
}

export default function SectionSplitter({ children }: ISectionSplitterProps) {
  return (
    <div
      css={{
        display: "grid",
        gridRowGap: 20,
      }}
    >
      {children}
    </div>
  );
}
