import { Mutation } from "react-apollo";
import ProductForm from "./ProductForm";
import React from "react";
import { convertValuesToVariables } from "./utils";
import * as palette from "@govlaunch/palette";
import AddProductMutation from "../../mutations/AddProduct";
import { IAddProductMutation, IAddProductMutationVariables } from "../../mutations/__generated__/AddProduct.generated";
import ScrollToTopOnMount from "../../ScrollToTopOnMount";
import { FORM_ERROR } from "final-form";
import { notification } from "antd";

interface IAddNewProductProps {
  onAdd: (slug?: string | null) => void;
  outsideProducts?: string[];
  initialValues?: any;
  defaultVendorSearch?: any;
}

export default function AddNewProduct({
  onAdd,
  outsideProducts,
  initialValues,
  defaultVendorSearch,
}: IAddNewProductProps) {
  return (
    <ScrollToTopOnMount>
      <div
        css={{
          background: palette.washGray,
        }}
      >
        <Mutation<IAddProductMutation, IAddProductMutationVariables>
          mutation={AddProductMutation}
          refetchQueries={["Vendor"]}
        >
          {(addProduct) => (
            <ProductForm
              initialValues={{
                resources: [],
                screenshots: [],
                links: [],
                tags: [],
                integrations: [],
                solutions: [],
                browsers: [],
                platforms: [],
                mobileResponsive: false,
                certifications: [],
                highlights: [],
                videos: [],
                assistiveTechnologiesCompatibility: [],
                languagesOffered: null,
                allowRequestTranslation: true,
                newsworthy: [],
                ...initialValues,
              }}
              defaultVendorSearch={defaultVendorSearch}
              onSubmit={async (values: any) => {
                const { company } = values;

                try {
                  const result = await addProduct({
                    variables: {
                      product: {
                        ...convertValuesToVariables(values),
                        outsideProducts: outsideProducts || [],
                      },
                      company,
                    },
                  });

                  if (result && result.data && result.data.addProduct && result.data.addProduct.slug) {
                    notification.success({
                      message: "Success!",
                      description: "Product saved successfully",
                    });

                    onAdd(result.data.addProduct.slug);
                  }
                } catch (e) {
                  if (/name already taken/i.test(e.message)) {
                    return {
                      [FORM_ERROR]: "Name already taken",
                    };
                  }

                  if (/you can select up/i.test(e.message)) {
                    return {
                      [FORM_ERROR]: e.message.replace("GraphQL error: ", ""),
                    };
                  }

                  return {
                    [FORM_ERROR]: e.message,
                  };
                }

                return null;
              }}
              submitButtonText="Create Product"
            />
          )}
        </Mutation>
      </div>
    </ScrollToTopOnMount>
  );
}
