import gql from "graphql-tag";

export default gql`
  mutation RestoreProduct($slug: String!) {
    restoreProduct(slug: $slug) {
      _id
      slug
      archivedAt
    }
  }
`;
