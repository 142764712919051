import React from "react";
import * as palette from "@govlaunch/palette";
import { useQuery } from "react-apollo";
import {
  IGetMissingTimezonesQuery,
  IGetMissingTimezonesQueryVariables,
} from "../../queries/__generated__/GetMissingTimezones.generated";
import GetMissingTimezones from "../../queries/GetMissingTimezones";
import { Link } from "react-router-dom";

export default function MissingTimezoneSettings() {
  const { data: missingData } = useQuery<IGetMissingTimezonesQuery, IGetMissingTimezonesQueryVariables>(
    GetMissingTimezones,
    {
 fetchPolicy: "network-only", notifyOnNetworkStatusChange: true 
},
  );

  const missingTimezones = (missingData && missingData.getMissingTimezones) || [];

  if (missingTimezones.length === 0) {
    return null;
  }

  return (
    <div
      css={{
        marginBottom: 20,
      }}
    >
      <div
        css={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: 8,
          color: palette.red,
          fontWeight: 700,
        }}
      >
        Missing Timezones:
      </div>
      {missingTimezones.map((missingTimezone) => (
        <Link to={`/newsletter/timezones/new/${missingTimezone}`} key={missingTimezone}>
          <div
            css={{
              display: "inline-flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              background: palette.lightBlue,
              color: palette.red,
              fontWeight: 700,
              padding: 4,
              marginRight: 4,
              borderRadius: 25,
            }}
          >
            {missingTimezone}
          </div>
        </Link>
      ))}
    </div>
  );
}
