import gql from "graphql-tag";

export default gql`
  mutation SetDynamicConfigByKey($key: String!, $value: JSON!) {
    setDynamicConfigByKey(key: $key, value: $value) {
      key
      value
    }
  }
`;
