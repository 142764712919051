import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import PageIcon from "../../icons/PageIcon";
import MetaBaseLink, { MetaBaseQuestion } from "../MetaBaseLink";

interface ITablePageHeaderProps {
  title: string;
  titleIcon?: IconDefinition;
  subTitle?: string;
  metabaseQuestion?: MetaBaseQuestion;
}

export default function TablePageHeader({ title, titleIcon, subTitle, metabaseQuestion }: ITablePageHeaderProps) {
  return (
    <div
      css={{
        padding: 20,
        borderBottom: "1px solid rgb(232, 232, 232)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <div>
        <h3
          css={{
            fontWeight: 700,
            fontSize: 24,
            margin: 0,
            overflow: "hidden",
          }}
        >
          {titleIcon && <PageIcon icon={titleIcon} />}
          {title}
        </h3>
        {subTitle && <p css={{
 margin: 0 
}}
                     >{subTitle}</p>}
      </div>

      {metabaseQuestion && (
        <div>
          <MetaBaseLink question={metabaseQuestion} />
        </div>
      )}
    </div>
  );
}
