import React, { useState } from "react";
import { createBlockSettings, IBlockLayout, TBlockLayoutData } from "../blocks/blockProps";
import EditBlockButton from "./EditBlockButton";
import * as palette from "@govlaunch/palette";
import { Checkbox, Modal } from "antd";
import { IBlockLayoutType } from "../../../../types/types";
import CommonBlockSettings from "./CommonBlockSettings";
import TabTitlesSettings from "./TabTitlesSettings";
import WidgetCheckboxSettings from "../blocks/statcounters/WidgetCheckboxSettings";
import EditBlockColorPicker from "./EditBlockColorPicker";
import ReactTextareaAutosize from "react-textarea-autosize";
import PaddingsControl from "./PaddingsControl";
import { Button } from "@govlaunch/core";

interface IBlockLayoutHeaderSettingsProps {
  block: IBlockLayout;
  saveBlockData: (block: IBlockLayout, data: TBlockLayoutData) => void;
}

export default function BlockLayoutHeaderSettings({ block, saveBlockData }: IBlockLayoutHeaderSettingsProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      css={{
        background: palette.white,
        borderRadius: 4,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <EditBlockButton onClick={() => setIsOpen(true)} />
      <SettingsModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        block={block}
        saveBlockData={saveBlockData}
        key={JSON.stringify(block) + new Date().toString()}
      />
    </div>
  );
}

interface ISettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  block: IBlockLayout;
  saveBlockData: (block: IBlockLayout, blockData: TBlockLayoutData) => void;
}

function SettingsModal({ isOpen, onClose, block, saveBlockData }: ISettingsModalProps) {
  const [data, setData] = useState<TBlockLayoutData>(createBlockSettings(block.blockData));

  return (
    <div
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
    >
      <Modal title="Edit Block" visible={isOpen} onCancel={onClose} footer={null} width={600}>
        <div
          css={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <SettingsByType data={data} setData={setData} />

          <div
            css={{
              marginTop: "32px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button
              color={palette.primary}
              textColor="#fff"
              onClick={() => {
                saveBlockData(block, data);
                onClose();
              }}
              css={{
                height: "32px",
              }}
            >
              Save
            </Button>
            <Button
              color={palette.text}
              theme="secondary"
              css={{
                marginLeft: "8px",
                height: "32px",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

interface ISettingsByTypeProps {
  data: any;
  setData: any;
}

function SettingsByType({ data, setData }: ISettingsByTypeProps) {
  if (data.type === IBlockLayoutType.ExternalLinks) {
    return <CommonBlockSettings data={data} setData={setData} />;
  }

  if (data.type === IBlockLayoutType.StatCounters) {
    return (
      <>
        <CommonBlockSettings
          data={data}
          setData={setData}
          showItemLimit={false}
          showColorPicker={false}
          showPaddingsControl={false}
        />

        <WidgetCheckboxSettings data={data} setData={setData} />

        <EditBlockColorPicker
          data={data}
          setData={setData}
          fieldName="backgroundColor"
          label="Background Color"
          placeholder="Set Background color"
        />

        <EditBlockColorPicker
          data={data}
          setData={setData}
          fieldName="labelsColor"
          label="Label Color"
          placeholder="Set label color"
        />

        <EditBlockColorPicker
          data={data}
          setData={setData}
          fieldName="countersColor"
          label="Counters Color"
          placeholder="Set counters color"
        />

        <EditBlockColorPicker
          data={data}
          setData={setData}
          fieldName="widgetColor"
          label="Box Color"
          placeholder="Set box color"
        />

        <PaddingsControl data={data} setData={setData} />
      </>
    );
  }

  if (data.type === IBlockLayoutType.TrendingGovernments) {
    return (
      <>
        <TabTitlesSettings data={data} setData={setData} />

        <CommonBlockSettings data={data} setData={setData} showBlockName={false} />
      </>
    );
  }

  if (data.type === IBlockLayoutType.TrendingCollections) {
    return (
      <>
        <TabTitlesSettings data={data} setData={setData} />

        <CommonBlockSettings data={data} setData={setData} showBlockName={false} />

        <Checkbox
          onChange={(event) =>
            setData({
              ...data,
              displayAsTags: event.target.checked,
            })
          }
          checked={data["displayAsTags"]}
          css={{
            marginTop: "16px",
            fontWeight: 500,
            fontSize: 14,
            color: palette.mediumGray,
          }}
        >
          Display as tags
        </Checkbox>
      </>
    );
  }

  if (data.type === IBlockLayoutType.Image) {
    return <CommonBlockSettings data={data} setData={setData} showItemLimit={false} />;
  }

  if (data.type === IBlockLayoutType.Advertisement) {
    return <CommonBlockSettings data={data} setData={setData} showItemLimit={false} />;
  }

  if (data.type === IBlockLayoutType.Podcast) {
    return (
      <>
        <CommonBlockSettings data={data} setData={setData} showItemLimit={false} />

        <ReactTextareaAutosize
          css={{
            marginTop: "16px",
            "::placeholder": {
              color: palette.sealBlue,
            },
            ":focus": {
              borderColor: palette.primary,
            },
            fontFamily: "Monaco,Menlo,Consolas,'Courier New',monospace!important",
            fontSize: 12,
            border: `1px solid ${palette.lightestGray}`,
            borderRadius: 5,
            padding: "10px 10px",
            width: "100%",
            outline: 0,
            resize: "none",
          }}
          minRows={4}
          maxRows={6}
          placeholder="Add your code here..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onChange={(event) => {
            setData({
              ...data,
              podcastSrc: event.target.value,
            });
          }}
          value={data.podcastSrc}
        />
      </>
    );
  }

  if (data.type === IBlockLayoutType.NewProjects) {
    return <CommonBlockSettings data={data} setData={setData} />;
  }

  if (data.type === IBlockLayoutType.FeaturedStories) {
    return <CommonBlockSettings data={data} setData={setData} showItemLimit={false} />;
  }

  if (data.type === IBlockLayoutType.Events) {
    return <CommonBlockSettings data={data} setData={setData} />;
  }

  return null;
}
