import React from "react";
import { Input } from "@govlaunch/core/form";
import { Field } from "react-final-form";
import { Margin } from "../../../../spacings";
import FieldValidationError from "../../../../components/form/FieldValidationError";
import CharacterLimitIndicator from "../../../../components/form/CharacterLimitIndicator";

export default function SubtitleField() {
  return (
    <Field name="subtitle">
      {({ input, meta }) => (
        <div>
          <Input
            {...input}
            maxLength={61}
            placeholder="Type a subtitle for your project (optional)…"
            size="small"
            padding={10}
          />

          <Margin mt={10}>
            <div
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <FieldValidationError meta={meta} />
              </div>

              <CharacterLimitIndicator
                maxLength={60}
                description={input.value}
                css={{
                  marginLeft: "auto",
                }}
              />
            </div>
          </Margin>
        </div>
      )}
    </Field>
  );
}
