import React from "react";
import { Field } from "react-final-form";
import HeaderBannerFieldModalButton from "./HeaderBannerFieldModalButton";
import * as palette from "@govlaunch/palette";
import HeaderBannerFieldResetButton from "./HeaderBannerFieldResetButton";
import HeaderBannerFieldContent from "./HeaderBannerFieldContent";
import { IHeaderBannerData } from "./headerBannerProps";

export default function HeaderBannerField() {
  return (
    <Field name="headerBanner">
      {({ input }) => (
        <div>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: `1px solid ${palette.lightestGray}`,
            }}
          >
            <div
              css={{
                borderBottom: `1px solid ${palette.lightestGray}`,
                padding: 15,
                textTransform: "uppercase",
                fontSize: 12,
                color: palette.darkGray,
                letterSpacing: 0.5,
                fontWeight: 500,
              }}
            >
              Header Banner
            </div>
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <HeaderBannerFieldModalButton
                onSave={(data: IHeaderBannerData) => input.onChange(data)}
                currentData={input.value}
                toolbar={true}
              />
              <HeaderBannerFieldResetButton onClick={() => input.onChange(null)} />
            </div>
          </div>

          <div
            css={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "180px",
            }}
          >
            {!input.value && (
              <HeaderBannerFieldModalButton
                onSave={(data: IHeaderBannerData) => input.onChange(data)}
                currentData={input.value}
              />
            )}
            {input.value && <HeaderBannerFieldContent data={input.value} />}
          </div>
        </div>
      )}
    </Field>
  );
}
