import * as palette from "@govlaunch/palette";
import React from "react";
import { Field } from "react-final-form";
import { Margin } from "../../../spacings";

export default function UserNotesField() {
  return (
    <Field name="notes">
      {({ input }) => (
        <div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: palette.mediumGray,
                margin: 0,
              }}
            >
              Notes
            </label>
          </div>

          <Margin mt={10}>
            <textarea
              placeholder="Type notes here..."
              css={{
                width: "100%",
                height: 84,
                padding: "6px 12px",
                fontSize: 14,
                border: `solid 1px ${palette.lightestGray}`,
                borderRadius: 6,
                outline: 0,
                resize: "none",
                ":focus": {
                  borderColor: palette.primary,
                },
                "::placeholder": {
                  color: palette.sealBlue,
                },
              }}
              {...input}
            />
          </Margin>
        </div>
      )}
    </Field>
  );
}
