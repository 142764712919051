import PropTypes from "prop-types";
import { Query } from "react-apollo";
import React from "react";
import { Table } from "antd";
import fp from "lodash/fp";
import SearchUsersQuery from "../../queries/SearchUsersQuery";
import { ISearchUsersQuery, ISearchUsersQueryVariables } from "../../queries/__generated__/SearchUsersQuery.generated";

interface ISyntheticUsersTableProps {
  limit: number;
  search: string;
  filter?: string;
  [key: string]: any;
}

export default function SyntheticUsersTable({ limit, search, filter, ...props }: ISyntheticUsersTableProps) {
  return (
    <Query<ISearchUsersQuery, ISearchUsersQueryVariables>
      fetchPolicy="network-only"
      variables={{
        search,
        filter: filter || "",
        limit,
      }}
      query={SearchUsersQuery}
    >
      {({ data, loading }) => (
        <Table
          rowKey="_id"
          loading={loading}
          size="small"
          dataSource={fp.getOr([], "allUsers.items", data)}
          {...props}
        />
      )}
    </Query>
  );
}

SyntheticUsersTable.propTypes = {
  limit: PropTypes.number,
  search: PropTypes.string,
  filter: PropTypes.string,
  columns: PropTypes.array.isRequired,
};

SyntheticUsersTable.defaultProps = {
  limit: 10,
  search: "",
  filter: "",
};
