import React from "react";
import GovlaunchLogo from "../../GovlaunchLogo";
import * as palette from "@govlaunch/palette";

export default function DashboardIndex() {
  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        flexWrap: "wrap",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <GovlaunchLogo color={palette.primary} />
      <div
        css={{
          marginLeft: 10,
        }}
      >
        <span
          css={{
            fontSize: 32,
            color: palette.mediumGray,
          }}
        >
          Admin
        </span>
      </div>
    </div>
  );
}
