import React from "react";
import { Input } from "antd";
import { Field } from "react-final-form";
import FieldError from "./FieldError";
import FieldTitle from "./FieldTitle";
import { FieldWrapper } from "./FieldWrapper";
import { FieldValue } from "./FieldValue";

interface IFormRowProps {
  isEditing: boolean;
  title: string;
  value: string | null | undefined;
  fieldName: string;
  icon?: JSX.Element | null;
  isUrl?: boolean;
}

export default function FormRow({ isEditing, title, value, fieldName, isUrl, icon = null }: IFormRowProps) {
  return (
    <>
      <FieldTitle title={title} icon={icon} />
      {isEditing ? (
        <Field name={fieldName}>
          {({ input, meta }) => (
            <FieldWrapper>
              <Input placeholder={title} type="text" {...input} />
              <FieldError meta={meta} />
            </FieldWrapper>
          )}
        </Field>
      ) : (
        <FieldValue value={value} isUrl={isUrl} />
      )}
    </>
  );
}
