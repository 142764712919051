import React, { useState } from "react";
import BlockLayoutContainer from "../../blockslayout/BlockLayoutContainer";
import BlockLayoutHeader from "../../blockslayout/BlockLayoutHeader";
import { IBlockLayout, ICollectionsBlockData, TBlockLayoutData } from "../blockProps";
import * as palette from "@govlaunch/palette";
import TrendingCollectionsBlockData from "./TrendingCollectionsBlockData";
import TopCollectionsBlockData from "./TopCollectionsBlockData";
import { Box, SimpleGrid } from "@govlaunch/web";
import BlockLayoutHeaderSettings from "../../blockslayout/BlockLayoutHeaderSettings";

interface ITrendingCollectionsBlockProps {
  block: IBlockLayout;
  onRemoveBlock: () => void;
  saveBlockData: (block: IBlockLayout, blockData: TBlockLayoutData) => void;
}

export default function TrendingCollectionsBlock({
  block,
  onRemoveBlock,
  saveBlockData,
}: ITrendingCollectionsBlockProps) {
  return (
    <BlockLayoutContainer block={block}>
      <BlockLayoutHeader title="Trending/Top Collections" block={block} onRemoveBlock={onRemoveBlock}>
        <BlockLayoutHeaderSettings block={block} saveBlockData={saveBlockData} />
      </BlockLayoutHeader>
      <TrendingCollectionsTabs block={block} />
    </BlockLayoutContainer>
  );
}

interface ITrendingCollectionsTabsProps {
  block: IBlockLayout;
}

enum CollectionsTab {
  TRENDING = "TRENDING",
  TOP = "TOP",
}

function TrendingCollectionsTabs({ block }: ITrendingCollectionsTabsProps) {
  const [tab, setTab] = useState<CollectionsTab>(CollectionsTab.TRENDING);

  return (
    <>
      <SimpleGrid
        width="100%"
        columns={2}
        fontSize="24px"
        fontWeight={600}
        lineHeight="32px"
        alignItems="end"
        justifyItems="center"
      >
        <Box
          width="100%"
          cursor="pointer"
          onClick={() => setTab(CollectionsTab.TRENDING)}
          pb={tab === CollectionsTab.TRENDING ? "5px" : "6px"}
          borderBottom={tab === CollectionsTab.TRENDING ? `2px solid ${palette.primary}` : "1px solid #ADB6C3"}
          textAlign="center"
          fontSize={["18px", "24px", "24px", "24px"]}
        >
          {block.blockData.title}
        </Box>
        <Box
          width="100%"
          cursor="pointer"
          onClick={() => setTab(CollectionsTab.TOP)}
          pb={tab === CollectionsTab.TOP ? "5px" : "6px"}
          borderBottom={tab === CollectionsTab.TOP ? `2px solid ${palette.primary}` : "1px solid #ADB6C3"}
          textAlign="center"
          fontSize={["18px", "24px", "24px", "24px"]}
        >
          {(block.blockData as ICollectionsBlockData).secondTitle}
        </Box>
      </SimpleGrid>
      <Box mt="40px" width="100%" px="12px">
        {tab === CollectionsTab.TRENDING && <TrendingCollectionsBlockData block={block} />}
        {tab === CollectionsTab.TOP && <TopCollectionsBlockData block={block} />}
      </Box>
    </>
  );
}
