import { LoadingIndicator } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { Tabs } from "antd";
import { NetworkStatus } from "apollo-client";
import React from "react";
import { Query } from "react-apollo";
import DocumentTitle from "react-document-title";
import GroupBySlugQuery from "../../queries/GroupBySlugQuery";
import { IGroupBySlugQuery, IGroupBySlugQueryVariables } from "../../queries/__generated__/GroupBySlugQuery.generated";
import GroupDetailsTab from "./tabs/GroupDetailsTab";
import GroupMembersTab from "./tabs/GroupMembersTab";
import GroupPostsTab from "./tabs/GroupPostsTab";
import GroupProductsTab from "./tabs/GroupProductsTab";
import GroupProjectsTab from "./tabs/GroupProjectsTab";
import TabContent from "../../components/TabContent";
import { Navigate, useNavigate, useParams } from "react-router";

export default function EditGroup() {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <DocumentTitle title="Edit Group | Admin">
      <Query<IGroupBySlugQuery, IGroupBySlugQueryVariables>
        query={GroupBySlugQuery}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange={true}
        variables={{
          slug: params.slug || "",
          postsCursor: null,
          projectsCursor: null,
          membersCursor: null,
        }}
      >
        {({ data, networkStatus, refetch, fetchMore }) => {
          const loading = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables;
          const fetchingMore = networkStatus === NetworkStatus.fetchMore;

          if (!data) {
            return null;
          }

          if (loading) {
            return (
              <div
                css={{
                  padding: 20,
                }}
              >
                <LoadingIndicator />
              </div>
            );
          }

          if (!data.groupBySlug) {
            return <Navigate to="/groups" />;
          }

          const group = data.groupBySlug;

          return (
            <div
              css={{
                backgroundColor: palette.washGray,
                minHeight: "100vh",
              }}
            >
              <Tabs
                tabBarStyle={{
                  backgroundColor: palette.white,
                  paddingLeft: 20,
                  paddingRight: 20,
                  margin: 0,
                }}
                activeKey={params.tab || "details"}
                onChange={(tab) => navigate(`/groups/${group.slug}${tab !== "details" ? `/${tab}` : ""}`)}
              >
                <Tabs.TabPane key="details" tab="Details">
                  <GroupDetailsTab group={group} />
                </Tabs.TabPane>

                <Tabs.TabPane key="members" tab="Members">
                  <TabContent>
                    <GroupMembersTab group={group} refetch={refetch} loading={fetchingMore} fetchMore={fetchMore} />
                  </TabContent>
                </Tabs.TabPane>

                <Tabs.TabPane key="projects" tab="Projects">
                  <TabContent>
                    <GroupProjectsTab group={group} refetch={refetch} loading={fetchingMore} fetchMore={fetchMore} />
                  </TabContent>
                </Tabs.TabPane>

                <Tabs.TabPane key="products" tab="Products">
                  <TabContent>
                    <GroupProductsTab group={group} />
                  </TabContent>
                </Tabs.TabPane>

                <Tabs.TabPane key="posts" tab="Posts">
                  <TabContent>
                    <GroupPostsTab group={group} refetch={refetch} loading={fetchingMore} fetchMore={fetchMore} />
                  </TabContent>
                </Tabs.TabPane>
              </Tabs>
            </div>
          );
        }}
      </Query>
    </DocumentTitle>
  );
}
