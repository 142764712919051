import * as Yup from "yup";

const storyValidationSchema = Yup.object({
  government: Yup.mixed().nullable(),
  title: Yup.string().required("can't be blank").max(60, "Title must be at most 60 characters"),
  author: Yup.mixed().required("can't be blank"),
  collaborators: Yup.array().of(Yup.mixed().required()).nullable(),
  introduction: Yup.string().required("can't be blank"),
  body: Yup.mixed().nullable(),
  highlights: Yup.array().of(Yup.string().required()).nullable(),
  locations: Yup.array().of(Yup.mixed().required()).nullable(),
  medias: Yup.array().of(Yup.object().required()).nullable(),
  resources: Yup.array().of(Yup.object().required()).nullable(),
  newsworthy: Yup.array().of(Yup.mixed().required()).nullable(),
  outsideProducts: Yup.array().of(Yup.object().required()).nullable(),
  products: Yup.array().of(Yup.mixed().required()).nullable(),
  tags: Yup.array().min(1, "Please select at least one tag").max(6, "You can select up to 6 tags"),
  audience: Yup.string().nullable(),
  awards: Yup.array().of(Yup.mixed().required()).nullable(),
});

export default storyValidationSchema;
