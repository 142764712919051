import { Field } from "react-final-form";
import React from "react";
import { SearchAlgoliaIndex } from "@govlaunch/algolia";
import { StoryProductSelect } from "@govlaunch/select";

export default function ProductsField() {
  return (
    <Field name="products" allowNull={true}>
      {({ input }) => (
        <SearchAlgoliaIndex index="products">
          {({ results: products, searchValue: inputValue, onChange: onInputValueChange }: any) => (
            <StoryProductSelect
              autoFocus={false}
              products={Array.isArray(input.value) ? input.value : []}
              inputValue={inputValue}
              onInputValueChange={onInputValueChange}
              selectableProducts={products}
              onChange={input.onChange}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              css={{
                minWidth: "auto !important",
                zIndex: "999 !important",
              }}
            />
          )}
        </SearchAlgoliaIndex>
      )}
    </Field>
  );
}
