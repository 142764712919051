import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface IEditBlockButtonProps {
  onClick: () => void;
}

export default function EditBlockButton({ onClick }: IEditBlockButtonProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "32px",
        height: "32px",
        "&:hover": {
          opacity: 0.8,
        },
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faCog}
        color="#aaaaaa"
        style={{
          width: "16px",
          height: "16px",
        }}
      />
    </div>
  );
}
