import { IProcessStatusType, IWorkPlaceType } from "../../../../types/types";

export function getProcessStatusFriendlyName(status: IProcessStatusType | null | undefined): string {
    if (!status) {
      return "";
    }
  
    switch (status) {
      case IProcessStatusType.AdditionalInformationRequired:
        return "Additional Information Required";
      case IProcessStatusType.PendingVerification:
        return "Pending Verification";
      case IProcessStatusType.Required:
        return "Required";
      case IProcessStatusType.Verified:
        return "Finalized";
      default:
        return status;
    }
  }
  
  export function getWorkplaceTypeFriendlyName(workplace: IWorkPlaceType | null | undefined): string {
    if (!workplace) {
      return "";
    }
  
    switch (workplace) {
      case IWorkPlaceType.Government:
        return "Government";
      case IWorkPlaceType.None:
        return "None (Interested Citizen)";
      case IWorkPlaceType.NonprofitOrNgo:
        return "Nonprofit or NGO";
      case IWorkPlaceType.Other:
        return "Other";
      case IWorkPlaceType.StateOrFederalAgency:
        return "State/Federal Agency";
      case IWorkPlaceType.Vendor:
        return "Vendor";
      default:
        return workplace;
    }
  }
  