import React from "react";
import { IProductQuery } from "../../../queries/__generated__/ProductQuery.generated";
import StoriesTable from "../../stories/paginated/StoriesTable";

/**
 * TODO 2020-04-03 *Stoyan Prokopov*: switch to using StoriesTable component from /stories/paginated
 * since that component has pagination implemented
 */

interface IProductStoriesTabProps {
  product: IProductQuery["product"];
  refetch: (arg: any) => any;
}

const ProductStoriesTab = ({ product, refetch }: IProductStoriesTabProps) => {
  if (!product) {
    return null;
  }

  return (
    <div
      css={{
        padding: "0 15px",
      }}
    >
      <StoriesTable
        stories={product.stories || []}
        refetch={refetch}
        renderStoryLink={(story) => {
          return `/products/${product.slug}/stories/${story.slug}`;
        }}
      />
    </div>
  );
};

export default ProductStoriesTab;
