import * as Yup from "yup";
import { ensureFilledString, ensureFilledArray } from "../../ensureFills";

export default Yup.object({
  name: Yup.string().required("can't be blank"),
  headingText: Yup.string().required("can't be blank"),
  subtitleText: Yup.string().required("can't be blank"),
  leftButtonText: Yup.string().required("can't be blank"),
  leftButtonHref: Yup.string().required("can't be blank"),
  rightButtonText: Yup.string().required("can't be blank"),
  rightButtonHref: Yup.string().required("can't be blank"),
  leftBox: Yup.object({
    text: Yup.string().required("can't be blank"),
    textFontColor: Yup.string().when("type", {
      is: "text",
      then: Yup.string().required("can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
    title: Yup.string().when("type", {
      is: "text",
      then: Yup.string().required("can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
    titleFontColor: Yup.string().when("type", {
      is: "text",
      then: Yup.string().required("can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
    buttonBackgroundColor: Yup.string().when("type", {
      is: "carousel",
      then: Yup.string().required("can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
    buttonFontColor: Yup.string().when("type", {
      is: "carousel",
      then: Yup.string().required("can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
    buttonHref: Yup.string().when("type", {
      is: "carousel",
      then: Yup.string().transform(ensureFilledString).required("can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
    images: Yup.array().when("type", {
      is: "carousel",
      then: Yup.array().transform(ensureFilledArray).min(1, "Upload at least one image"),
      otherwise: Yup.array().nullable(),
    }),
  }),
  rightBox: Yup.object({
    text: Yup.string().required("can't be blank"),
    textFontColor: Yup.string().when("type", {
      is: "text",
      then: Yup.string().required("can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
    title: Yup.string().when("type", {
      is: "text",
      then: Yup.string().required("can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
    titleFontColor: Yup.string().when("type", {
      is: "text",
      then: Yup.string().required("can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
    buttonBackgroundColor: Yup.string().when("type", {
      is: "carousel",
      then: Yup.string().required("can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
    buttonFontColor: Yup.string().when("type", {
      is: "carousel",
      then: Yup.string().required("can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
    buttonHref: Yup.string().when("type", {
      is: "carousel",
      then: Yup.string().transform(ensureFilledString).required("can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
    images: Yup.array().when("type", {
      is: "carousel",
      then: Yup.array().transform(ensureFilledArray).min(1, "Upload at least one image"),
      otherwise: Yup.array().nullable(),
    }),
  }),
});
