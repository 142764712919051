import gql from "graphql-tag";

export default gql`
  mutation unpublishStory($storyId: ObjectId!) {
    unpublishStory(storyId: $storyId) {
      _id
      status
    }
  }
`;
