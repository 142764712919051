import * as palette from "@govlaunch/palette";
import DocumentTitle from "react-document-title";
import React from "react";
import AddVendorForm from "./form/AddVendorForm";

export default function AddVendor() {
  return (
    <DocumentTitle title="New Vendor | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
        }}
      >
        <AddVendorForm />
      </div>
    </DocumentTitle>
  );
}
