import React, { useState } from "react";
import { Modal, Tabs } from "antd";
import * as palette from "@govlaunch/palette";
import { Button } from "@govlaunch/core";
import { IHeaderBannerData } from "./headerBannerProps";
import HeaderBannerMainSettings from "./HeaderBannerMainSettings";
import HeaderBannerButtonSettings from "./HeaderBannerButtonSettings";

interface IHeaderBannerFieldModalProps {
  isOpen: boolean;
  onClose: () => any;
  onSave: (arg: IHeaderBannerData) => void;
  currentData: IHeaderBannerData;
}

export default function HeaderBannerFieldModal({ isOpen, onClose, onSave, currentData }: IHeaderBannerFieldModalProps) {
  const [data, setData] = useState<IHeaderBannerData>(prepareData(currentData));

  return (
    <Modal title="Header Banner Content" visible={isOpen} onCancel={onClose} footer={null} width={600}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/**<Collapse
          expandIconPosition="right"
          defaultActiveKey="main_settings"
          style={{
            background: palette.white,
          }}
        >
          <Collapse.Panel header="Main" key="main_settings">
            <HeaderBannerMainSettings data={data} setData={setData} />
          </Collapse.Panel>

          <Collapse.Panel header="Left Button" key="left_button">
            <HeaderBannerButtonSettings
              data={data}
              setData={setData}
              buttonField="leftButton"
              buttonLabel="Left button"
            />
          </Collapse.Panel>

          <Collapse.Panel header="Right Button" key="right_button">
            <HeaderBannerButtonSettings
              data={data}
              setData={setData}
              buttonField="rightButton"
              buttonLabel="Right button"
            />
          </Collapse.Panel>
        </Collapse> */}

        <Tabs defaultActiveKey="main_details" size="small" type="card">
          <Tabs.TabPane tab="Main Details" key="main_details">
            <HeaderBannerMainSettings data={data} setData={setData} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Left Button" key="left_button">
            <HeaderBannerButtonSettings data={data} setData={setData} buttonField="leftButton" />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Right Button" key="right_button">
            <HeaderBannerButtonSettings data={data} setData={setData} buttonField="rightButton" />
          </Tabs.TabPane>
        </Tabs>

        <div
          css={{
            marginTop: "24px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            color={palette.primary}
            textColor="#fff"
            onClick={() => {
              onSave(data);
              onClose();
            }}
            css={{
              height: "32px",
            }}
          >
            Save
          </Button>
          <Button
            color={palette.text}
            theme="secondary"
            css={{
              marginLeft: "8px",
              height: "32px",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function prepareData(currentData: IHeaderBannerData | null | undefined) {
  if (!currentData) {
    return {
      title: "",
      titleColor: "#ffffff",
      subtitle: "",
      subtitleColor: "#ffffff",
      backgroundImage: {
        url: "",
        alt: "",
      },
      foregroundImage: {
        url: "",
        alt: "",
      },
      leftButtonText: null,
      leftButtonHref: null,
      leftButtonBackgroundColor: "#086BE5",
      leftButtonTextColor: "#FFFFFF",
      leftButtonBorderColor: "#086BE5",
      leftButtonHoverColor: "#2382F7",
      rightButtonText: null,
      rightButtonHref: null,
      rightButtonBackgroundColor: "#086BE5",
      rightButtonTextColor: "#FFFFFF",
      rightButtonBorderColor: "#086BE5",
      rightButtonHoverColor: "#2382F7",
    };
  }

  return currentData;
}
