import gql from "graphql-tag";

export default gql`
  query GovernmentsAdminMetadata {
    governmentTypes

    availableStatesWithCountries {
      state
      friendlyStateName
      country
    }
  }
`;
