import React from "react";
import EditBlockColorPicker from "./EditBlockColorPicker";
import EditBlockNameAndVisibility from "./EditBlockNameAndVisibility";
import PaddingsControl from "./PaddingsControl";
import ItemLimitInput from "./ItemLimitInput";

interface ICommonBlockSettingsProps {
  data: any;
  setData: any;
  showItemLimit?: boolean;
  showBlockName?: boolean;
  showColorPicker?: boolean;
  showPaddingsControl?: boolean;
}

export default function CommonBlockSettings({
  data,
  setData,
  showItemLimit = true,
  showBlockName = true,
  showColorPicker = true,
  showPaddingsControl = true,
}: ICommonBlockSettingsProps) {
  return (
    <>
      {showBlockName && <EditBlockNameAndVisibility data={data} setData={setData} />}

      {showItemLimit && (
        <ItemLimitInput
          fieldName="itemLimit"
          label="Item limit"
          subTitle="Displays only a limited number of items"
          placeholder="Type item limit"
          data={data}
          setData={setData}
          css={{
            marginTop: "16px",
            maxWidth: "280px",
          }}
        />
      )}

      {showColorPicker && (
        <EditBlockColorPicker
          data={data}
          setData={setData}
          fieldName="backgroundColor"
          label="Background Color"
          placeholder="Set Background color"
        />
      )}

      {showPaddingsControl && <PaddingsControl data={data} setData={setData} />}
    </>
  );
}
