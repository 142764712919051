import { Input, LoadingIndicator } from "@govlaunch/core";
import React, { useRef, useState } from "react";

import PropTypes from "prop-types";
import useUnfurl from "../../../hooks/useUnfurl";

interface IUnfurl {
  title: string;
  description: string;
  siteName: string;
  favicon: string;
  url: string;
}

interface ILinkInputProps {
  onChange: (value: any) => any;
}

export default function LinkInput({ onChange }: ILinkInputProps) {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const linkInputRef = useRef<HTMLInputElement>();

  const [loading, unfurl] = useUnfurl(
    url,
    (unfurl) => {
      setTitle("");
      (linkInputRef.current as HTMLInputElement).value = "";

      onChange({
        ...unfurl,
        displayTitle: title || null,
      });
    },
    () => {
      setTitle("");
      (linkInputRef.current as HTMLInputElement).value = "";
    },
  );

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: 10,
      }}
    >
      <Input
        value={title}
        placeholder="Title (optional)"
        onChange={(event: React.SyntheticEvent) => setTitle((event.target as HTMLInputElement).value)}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === "Enter") {
            // otherwise it will attempt to submit the whole form
            event.preventDefault();
            event.stopPropagation();

            if (url) {
              setUrl(url);
            }
          }
        }}
      />

      <div
        css={{
          position: "relative",
        }}
      >
        <Input
          innerRef={linkInputRef}
          disabled={loading}
          onKeyDown={(event: React.KeyboardEvent) => {
            if (event.key === "Enter") {
              event.preventDefault();
              event.stopPropagation();

              setUrl((event.target as HTMLInputElement).value);

              if (unfurl && (unfurl as IUnfurl).url === (event.target as HTMLInputElement).value) {
                setTitle("");
                (linkInputRef.current as HTMLInputElement).value = "";

                onChange({
                  ...(unfurl as IUnfurl),
                  displayTitle: title || null,
                });
              }
            }
          }}
          placeholder="Enter a URL... https://"
          css={{
            paddingRight: loading ? 40 : 10,
          }}
        />

        {loading && (
          <div
            css={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: 10,
            }}
          >
            <LoadingIndicator />
          </div>
        )}
      </div>
    </div>
  );
}

LinkInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};
