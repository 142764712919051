import gql from "graphql-tag";
import GovernmentProjectFields from "./fragments/GovernmentProjectFields";
import GroupProjectFields from "./fragments/GroupProjectFields";
import UserFields from "./fragments/UserFields";
import VendorProjectFields from "./fragments/VendorProjectFields";

export default gql`
  query UserBySlug($slug: String!, $projectsCursor: Cursor) {
    user(slug: $slug) {
      ... on PendingUser {
        _id
        ...UserFields
        projects(cursor: $projectsCursor) {
          items {
            _id
            ...GovernmentProjectFields
            ...GroupProjectFields
            ...VendorProjectFields
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
      ... on VendorUser {
        _id
        ...UserFields
        projects(cursor: $projectsCursor) {
          items {
            _id
            ...GovernmentProjectFields
            ...GroupProjectFields
            ...VendorProjectFields
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
      ... on GovernmentUser {
        _id
        ...UserFields
        projects(cursor: $projectsCursor) {
          items {
            _id
            ...GovernmentProjectFields
            ...GroupProjectFields
            ...VendorProjectFields
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }

  ${UserFields}
  ${GovernmentProjectFields}
  ${GroupProjectFields}
  ${VendorProjectFields}
`;
