import gql from "graphql-tag";
import LandingPageConfigFields from "../queries/fragments/LandingPageConfigFields";

export default gql`
  mutation CreateLandingPageConfig($landingPageConfig: LandingPageConfigInput!) {
    createLandingPageConfig(landingPageConfig: $landingPageConfig) {
      ...LandingPageConfigFields
    }
  }

  ${LandingPageConfigFields}
`;
