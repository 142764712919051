import { Button } from "antd";
import React from "react";
interface ITableTitleProps {
  title: string;
  count?: number;
  onResetFilters: () => void;
  loading: boolean;
  showResetButton?: boolean;
}
export default function TableTitle({
  title,
  count,
  onResetFilters,
  loading,
  showResetButton = true,
}: ITableTitleProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <strong>{loading || typeof count === "undefined" ? title : `${title} (${count})`}</strong>
      {showResetButton && <Button onClick={onResetFilters}>Reset Filters</Button>}
    </div>
  );
}
