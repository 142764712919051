import gql from "graphql-tag";
import { ViewerSpanQueryFields } from "../../../users/ViewerSpan";

export default gql`
  query ListAllCollections(
    $searchQuery: String
    $filterByStatus: CollectionStatus
    $filterBySponsoring: CollectionSponsoring
    $filterByPublishedStatus: CollectionPublishedStatus = PUBLISHED
    $filterByPackaging: CollectionPackaging
    $filterByFeaturedStatus: CollectionFeaturedStatus
    $cursor: String
  ) {
    listAllCollections(
      searchQuery: $searchQuery
      filterByStatus: $filterByStatus
      filterBySponsoring: $filterBySponsoring
      filterByPackaging: $filterByPackaging
      filterByPublishedStatus: $filterByPublishedStatus
      filterByFeatured: $filterByFeaturedStatus
      cursor: $cursor
    ) {
      items {
        _id
        name
        status
        slug
        description
        featuredAt
        tags {
          _id
          name
          slug
        }
        publicContentAvailable
        publishedAt
        isSponsoringSaleActive
        position
        packaging
        isSponsoringSaleActive
        sponsoring
        sponsoringVendors {
          _id
          name
          slug
        }
        sponsoringProducts {
          _id
          name
          slug
        }
        displayOptions {
          cardBackgroundColorHex
          cardTitleFontColorHex
        }
        adminStats {
          usersSavedCount
        }
        createdAt
        updatedAt
        createdBy {
          ...ViewerSpanFields
        }
        lastUpdatedBy {
          ...ViewerSpanFields
        }
      }

      pageInfo {
        cursor
        hasNextPage
      }
      totalCount
    }
  }

  ${ViewerSpanQueryFields}
`;
