import React from "react";

export default function IntercomIcon(props: React.SVGProps<any>) {
  return (
    <svg
      width={2500}
      height={2500}
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <path
        d="M221.867 140.748a8.534 8.534 0 01-17.067 0V64a8.534 8.534 0 0117.067 0v76.748zm-2.978 53.413c-1.319 1.129-32.93 27.655-90.889 27.655-57.958 0-89.568-26.527-90.887-27.656a8.535 8.535 0 01-.925-12.033 8.53 8.53 0 0112.013-.942c.501.42 28.729 23.563 79.8 23.563 51.712 0 79.503-23.31 79.778-23.545 3.571-3.067 8.968-2.655 12.033.925a8.534 8.534 0 01-.923 12.033zM34.133 64A8.534 8.534 0 0151.2 64v76.748a8.534 8.534 0 01-17.067 0V64zm42.668-17.067a8.534 8.534 0 0117.066 0v114.001a8.534 8.534 0 01-17.066 0v-114zm42.666-4.318A8.532 8.532 0 01128 34.082a8.532 8.532 0 018.534 8.533v123.733a8.534 8.534 0 01-17.067 0V42.615zm42.667 4.318a8.534 8.534 0 0117.066 0v114.001a8.534 8.534 0 01-17.066 0v-114zM224 0H32C14.327 0 0 14.327 0 32v192c0 17.672 14.327 32 32 32h192c17.673 0 32-14.328 32-32V32c0-17.673-14.327-32-32-32z"
        fill="#1F8DED"
      />
    </svg>
  );
}
