import gql from "graphql-tag";
import VendorMembersFragmentFields from "../fragments/VendorMembersFragmentFields";

export default gql`
  mutation EditVendorMembers($vendorId: ObjectId!, $members: [UserWithRoleInput!]!) {
    editVendorMembers(vendorId: $vendorId, members: $members) {
      _id
      members {
        ...VendorMemberFragmentFields
      }
      owner {
        ...VendorMemberFragmentFields
      }
    }
  }

  ${VendorMembersFragmentFields}
`;
