import { Table, TableProps } from "antd";
import React from "react";
import { ITablePaginationState } from "../../../components/table/TablePagination";
import { IDuplicatedUsersQuery } from "../../../queries/__generated__/GetDuplicatedUsers.generated";
import * as palette from "@govlaunch/palette";
import UserTableName from "../tables/UserTableName";
import DuplicatedUsersTableActions from "./DuplicatedUsersTableActions";

export interface IDuplicatedUsersTableState {
  cursor: ITablePaginationState;
}

type TUser = NonNullable<NonNullable<IDuplicatedUsersQuery["duplicatedUsers"]>["items"]>[0];

interface IDuplicatedUsersTableProps {
  users: TUser[];
  loading?: boolean;
  state?: IDuplicatedUsersTableState;
  footer?: TableProps<any>["footer"];
  title?: () => any;
}

export default function DuplicatedUsersTable({ users, loading, footer, title }: IDuplicatedUsersTableProps) {
  return (
    <Table<TUser>
      pagination={false}
      loading={loading}
      rowKey="_id"
      bordered={true}
      dataSource={users || []}
      style={{
        backgroundColor: palette.white,
      }}
      scroll={{
        x: true,
      }}
      title={title}
      footer={footer}
      columns={[
        {
          key: "_id",
          title: "Name",
          dataIndex: "fullName",
          render: (_, user) => (
            <UserTableName
              user={duplicatedUserToUser(user)}
              filter={null}
              showEmail={true}
              showIntercomLinks={true}
              showUserType={true}
            />
          ),
        },
        {
          title: "Possible duplicates",
          dataIndex: "duplicatedUsers",
          render: (duplicatedUsers) => {
            return (
              <>
                {(duplicatedUsers || []).map((user: TUser, index: number) => {
                  return (
                    <div
                      key={`${user.fullName}_${index}`}
                      css={{
                        padding: "5px 10px 10px 5px",
                        border: `1px solid ${palette.lightGray}`,
                        borderRadius: 5,
                        "&:not(:first-of-type)": {
                          marginTop: 4,
                        },
                      }}
                    >
                      <UserTableName
                        user={duplicatedUserToUser(user)}
                        filter={null}
                        showEmail={true}
                        showIntercomLinks={true}
                      />
                    </div>
                  );
                })}
              </>
            );
          },
        },
        {
          title: "Actions",
          render: (user: TUser) => {
            return <DuplicatedUsersTableActions userId={user.userId} fullName={user.fullName} />;
          },
        },
      ]}
    />
  );
}

function duplicatedUserToUser(user: TUser) {
  return {
    __typename: "GovernmentUser",
    _id: user.userId,
    slug: user.slug,
    avatar: user.avatar,
    fullName: user.fullName,
    email: user.email,
    verified: user.verified || false,
    role: user.role,
    isInnovator: false,
    isDisruptor: false,
    intercomId: user.intercomId,
    onboardingStatus: null,
  };
}
