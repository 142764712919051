import React from "react";
import { Input } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { FieldInputProps } from "react-final-form";
import prefixWithHttpIfNecessary from "../../../utils/prefixWithHttpIfNecessary";

interface ISocialMedialUrlInputProps {
  input: FieldInputProps<any, HTMLElement>;
  placeholder?: string;
  onClick?: (event: React.MouseEvent) => any;
}

export default function SocialMedialUrlInput({ input, placeholder, onClick, ...props }: ISocialMedialUrlInputProps) {
  return (
    <Input
      css={{
        height: 42,
        borderRadius: 5,
        border: `1px solid ${palette.lightestGray}`,
        fontSize: 14,
        padding: "5px 10px",
        width: "100%",
        outline: 0,
        "::placeholder": {
          color: palette.sealBlue,
        },
        ":focus": {
          borderColor: palette.primary,
        },
      }}
      placeholder={placeholder}
      onClick={onClick}
      {...input}
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
        const url = event.target.value.trim();
        input.onChange(prefixWithHttpIfNecessary(url, true) || "");
      }}
      {...props}
    />
  );
}
