import gql from "graphql-tag";

export default gql`
  mutation SetGovernmentLogo($governmentId: ObjectId!, $logo: String) {
    setGovernmentLogo(governmentId: $governmentId, logo: $logo) {
      _id
      logo
    }
  }
`;
