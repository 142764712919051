export function extractSrc(value: string) {
  const parser = new DOMParser();
  const code = parser.parseFromString(value, "text/html");
  const iframe = code.querySelector("iframe");

  if (!iframe) {
    return value;
  }

  const iframeSrc = iframe.getAttribute("src");

  if (!iframeSrc || !iframeSrc.includes("player.simplecast.com")) {
    return "";
  }

  return iframeSrc;
}
