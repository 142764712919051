import React, { ChangeEvent } from "react";
import * as palette from "@govlaunch/palette";
import { Input } from "@govlaunch/core";

interface ITabTitlesSettingsProps {
  data: any;
  setData: any;
}

export default function TabTitlesSettings({ data, setData }: ITabTitlesSettingsProps) {
  return (
    <>
      <label
        css={{
          fontWeight: 500,
          fontSize: 14,
          color: palette.mediumGray,
        }}
      >
        Left Tab Title
      </label>
      <Input
        css={{
          marginTop: "8px",
          height: 42,
          borderRadius: 5,
          border: `1px solid ${palette.lightestGray}`,
          fontSize: 14,
          letterSpacing: 0.5,
          fontWeight: 500,
          padding: "5px 10px",
          width: "100%",
          outline: 0,
          "::placeholder": {
            color: palette.sealBlue,
          },
          ":focus": {
            borderColor: palette.primary,
          },
          textTransform: "uppercase",
        }}
        placeholder="Enter title..."
        value={data.title}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setData({
            ...data,
            title: event.target.value,
          });
        }}
      />
      <label
        css={{
          marginTop: "16px",
          fontWeight: 500,
          fontSize: 14,
          color: palette.mediumGray,
        }}
      >
        Right Tab Title
      </label>
      <Input
        css={{
          marginTop: "8px",
          height: 42,
          borderRadius: 5,
          border: `1px solid ${palette.lightestGray}`,
          fontSize: 14,
          letterSpacing: 0.5,
          fontWeight: 500,
          padding: "5px 10px",
          width: "100%",
          outline: 0,
          "::placeholder": {
            color: palette.sealBlue,
          },
          ":focus": {
            borderColor: palette.primary,
          },
          textTransform: "uppercase",
        }}
        placeholder="Enter title..."
        value={data["secondTitle"] || ""}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setData({
            ...data,
            secondTitle: event.target.value,
          });
        }}
      />
    </>
  );
}
