import gql from "graphql-tag";

export default gql`
  mutation UpdateCollection($collectionId: ObjectId!, $input: UpdateCollectionInput!) {
    updateCollection(collectionId: $collectionId, input: $input) {
      _id
      name
      slug
      tags {
        _id
        name
        slug
      }
      createdAt
      updatedAt
    }
  }
`;
