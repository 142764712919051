import React, { FunctionComponent, ReactElement } from "react";
import { IProductFilter } from "../../../../types/types";
import TableSearch from "../../../components/table/TableSearch";
import ManageProducts from "../tables/ManageProducts";
import { useLocation } from "react-router";
import queryString from "query-string";

interface IProductsWithSearchProps {
  filter?: IProductFilter;
  filterByTag?: any;
  historyOrigin?: string;
  [K: string]: any;
}

export const ProductsWithSearch: FunctionComponent<IProductsWithSearchProps> = ({
  filter = IProductFilter.All,
  historyOrigin = "/products",
  filterByTag,
  ...props
}): ReactElement => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div css={{
 marginBottom: 20 
}}
      >
        <TableSearch historyOrigin={historyOrigin} query={query} inputPlaceholder="Search for products..." />
      </div>

      <ManageProducts
        filter={filter}
        search={query.search as string}
        filterByTag={filterByTag}
        historyOrigin={historyOrigin}
        {...props}
      />
    </>
  );
};
