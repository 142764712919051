import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import DocumentTitle from "react-document-title";
import TablePageHeader from "../../../components/table/TablePageHeader";
import { ExternalLinksWithSearch } from "./ExternalLinksWithSearch";

export default function ExternalLinksPage() {
  return (
    <DocumentTitle title="External Links | Admin">
      <>
        <TablePageHeader title="External Links" titleIcon={faExternalLinkAlt} subTitle="All external links" />
        <div
          css={{
            padding: 20,
          }}
        >
          <ExternalLinksWithSearch />
        </div>
      </>
    </DocumentTitle>
  );
}
