import { notification } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import { Form } from "react-final-form";
import { useNavigate } from "react-router";
import { IAboutContentType } from "../../../types/types";
import validate from "../../validate";
import CreateAboutPageConfigMutation from "./graphql/mutations/CreateAboutPageConfigMutation";
import {
  ICreateAboutPageConfigMutation,
  ICreateAboutPageConfigMutationVariables,
} from "./graphql/mutations/__generated__/CreateAboutPageConfigMutation.generated";
import AllAboutPageConfigsQuery from "./graphql/queries/AllAboutPageConfigsQuery";
import AboutPageConfigForm from "./AboutPageConfigForm";
import { convertValuesToVariables } from "./utils";
import validationSchema from "./validationSchema";

const INITIAL_FORM_VALUES: any = {
  headingTextFontColor: "#ffffff",
  subtitleTextFontColor: "#ffffff",

  bannerContentType: IAboutContentType.Image,
  bannerContentText: "https://cdn.filestackcontent.com/MoOy7KTSuAAf7sAyBllA",

  leftButtonText: "Sign Up",
  leftButtonBackgroundColor: "#28D094",
  leftButtonTextColor: "#ffffff",
  leftButtonHref: "/signin",

  rightButtonBackgroundColor: "#086BE5",
  rightButtonTextColor: "#ffffff",
  rightButtonHref: "https://help.govlaunch.com",

  boxesSectionBackgroundColor: "#ffffff",
  leftBox: {
    type: "text",
    iconDescription: "",
    title: "",
    text: "",
    iconSrc: "https://cdn.filestackcontent.com/cPvn83YcSsm9Cpu8Cy2E",
    titleFontColor: "#000000",
    textFontColor: "#000000",
    buttonHref: "https://www.govlaunch.com/projects",
    images: [],
  },

  rightBox: {
    type: "text",
    iconDescription: "",
    title: "",
    text: "",
    iconSrc: "https://cdn.filestackcontent.com/T4PCkZBTrunCihyhqiwA",
    titleFontColor: "#000000",
    textFontColor: "#000000",
    buttonHref: "https://www.govlaunch.com/stories",
    images: [],
  },

  joinSectionBackground: "#000000",
  joinSectionTitleFontColor: "#000000",
  joinSectionSubtitleFontColor: "#000000",
  joinSectionButtonColor: "#000000",
  joinSectionButtonFontColor: "#000000",
  joinSectionButtonHref: "/signup",
  logoSectionBackgroundColor: "#ffffff",

  videoSectionFontColor: "#2F3844",
  videoSectionBackground: "#FAFAF9",
  videoSectionContentType: IAboutContentType.Image,

  logoUrls: [],

  active: false,
};

export default function NewAboutPageConfigForm() {
  const navigate = useNavigate();
  const [createConfig] = useMutation<ICreateAboutPageConfigMutation, ICreateAboutPageConfigMutationVariables>(
    CreateAboutPageConfigMutation,
    {
      refetchQueries: [
        {
          query: AllAboutPageConfigsQuery,
        },
      ],
    },
  );

  return (
    <Form
      keepDirtyOnReinitialize={true}
      subscription={{
        submitting: true,
        submitError: true,
      }}
      initialValues={INITIAL_FORM_VALUES}
      validate={validate(validationSchema)}
      onSubmit={async (values) => {
        const result = await createConfig({
          variables: convertValuesToVariables(values),
        });

        if (result && result.data && result.data.createAboutPageConfig && result.data.createAboutPageConfig._id) {
          notification.success({
            message: "About Page added!",
            description: `About Page added successfully!`,
          });

          navigate(`/aboutPage/${result.data.createAboutPageConfig._id}/edit`);
        }
      }}
    >
      {({ handleSubmit, submitting, submitError }) => (
        <AboutPageConfigForm
          submitting={submitting}
          onSubmit={handleSubmit}
          submitButtonText="Create Template"
          submitError={submitError}
        />
      )}
    </Form>
  );
}
