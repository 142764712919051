import gql from "graphql-tag";

export default gql`
  query ProjectsCount {
    projectsCount {
      allCount
      groupProjectsCount
      vendorProjectsCount
      governmentProjectsCount
      userCreatedCount
      archivedCount
    }
  }
`;
