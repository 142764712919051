import React from "react";
import { IGetTagBySlugQuery } from "../../../../queries/__generated__/GetTagBySlug.generated";
import GroupsWithSearchWithRouter from "../../../groups/tables/GroupsWithSearchWithRouter";

interface ITagGroupsTab {
  tag: IGetTagBySlugQuery["tag"];
}

export default function TagGroupsTab({ tag }: ITagGroupsTab) {
  if (!tag) {
    return null;
  }

  return (
    <div
      css={{
        padding: 20,
      }}
    >
      <GroupsWithSearchWithRouter filterByTag={tag._id} historyOrigin={`/tags/${tag.slug}/groups`} />
    </div>
  );
}
