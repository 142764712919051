// GovernmentCountriesBreakdownQuery.ts
import gql from "graphql-tag";

export default gql`
  query GovernmentCountriesBreakdown {
    governmentCountriesBreakdown {
      isoCountryCode
      totalOfGovernments
      totalOfActiveGovernments
      totalOfInactiveGovernments
    }
  }
`;
