import { Query } from "react-apollo";
import React, { ReactElement } from "react";
import UsersCountQuery from "../../queries/UsersCountQuery";
import { IUsersCountQuery } from "../../queries/__generated__/UsersCountQuery.generated";

type IUsersCountChildProps = IUsersCountQuery["usersCount"] & {
  loading: boolean;
  refetch: () => void;
};

interface IUsersCountProps {
  children: (props: IUsersCountChildProps) => ReactElement | null;
}

export default function UsersCount({ children }: IUsersCountProps) {
  return (
    <Query<IUsersCountQuery> query={UsersCountQuery}>
      {({ data, loading, refetch }) => {
        if (!data || !data.usersCount) {
          return null;
        }

        return children({
          ...data.usersCount,
          loading,
          refetch,
        });
      }}
    </Query>
  );
}
