import gql from "graphql-tag";

export default gql`
  query GetAllTimezones($filters: GetAllTimezonesInput!, $limit: Int = 20, $cursor: Cursor) {
    getAllTimezones(filters: $filters, limit: $limit, cursor: $cursor) {
      items {
        _id
        countryCode
        countryName
        timezone
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor
      }
    }
  }
`;
