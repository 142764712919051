import gql from "graphql-tag";

export default gql`
  fragment UserFields on User {
    ... on PendingUser {
      _id
      intercomId
      slug
      email
      avatar
      firstName
      fullName
      jobTitle
      notificationEmail
      createdAt
      bannedAt
      linkedinUrl
      linkedinId
      lastSignInProvider
      onboarding {
        _id
        processStatus
      }
      department {
        _id
        name
      }
      interests {
        _id
        name
      }
      groups {
        ...UserGroupFields
      }
      role
      verified
      lastSignInAt
      previousCompany {
        _id
        slug
        name
        logo
      }
      productStackRequest {
        _id
        inviter {
          ... on PendingUser {
            _id
            fullName
            email
          }
          ... on GovernmentUser {
            _id
            fullName
            email
          }
          ... on VendorUser {
            _id
            fullName
            email
          }
        }
        product {
          _id
          name
          slug
          logo
        }
        vendor {
          _id
          name
          slug
          logo
        }
        possibleGovernments {
          _id
          name
          slug
          logo
        }
      }
      groupRequest {
        _id
        group {
          _id
          name
        }
        ... on GovernmentGroupRequest {
          government {
            _id
            name
          }
          possibleGovernments {
            _id
            name
          }
        }

        ... on VendorGroupRequest {
          vendor {
            _id
            name
          }
          possibleVendors {
            _id
            name
          }
        }

        inviter {
          ... on PendingUser {
            _id
            fullName
            email
          }
          ... on GovernmentUser {
            _id
            fullName
            email
          }
          ... on VendorUser {
            _id
            fullName
            email
          }
        }
      }
      signInAttempts {
        registeredAt
        outcome
        ip
        userAgent
        lookup
        whitelisted
      }
      notes
      socialMedia {
        linkedin
        twitter
        facebook
        instagram
        youtube
      }
      latestNewsletterHtml
      newsletterFrequency
      subscribedToNewsletter
      subscribedToCampaign
      isUnverifiable
      country
      language
      timeZone
      onboardingStatus
      searchable
      isSiteAdmin
      preferences {
        showAsAuthorOnProjects
      }
      isDuplicated
    }
    ... on VendorUser {
      _id
      intercomId
      slug
      role
      avatar
      firstName
      fullName
      title
      jobTitle
      notificationEmail
      email
      createdAt
      bannedAt
      lastSignInAt
      isDisruptor
      linkedinUrl
      linkedinId
      lastSignInProvider
      department {
        _id
        name
      }
      interests {
        _id
        name
      }
      groups {
        ...UserGroupFields
      }
      verified
      company {
        _id
        slug
        name
        logo
        isDisruptor
        plan {
          _id
          name
        }
      }
      signInAttempts {
        registeredAt
        outcome
        ip
        userAgent
        lookup
        whitelisted
      }
      notes
      socialMedia {
        linkedin
        twitter
        facebook
        instagram
        youtube
      }
      latestNewsletterHtml
      newsletterFrequency
      subscribedToNewsletter
      subscribedToCampaign
      country
      language
      timeZone
      onboardingStatus
      searchable
      isSiteAdmin
      preferences {
        showAsAuthorOnProjects
      }
    }
    ... on GovernmentUser {
      _id
      intercomId
      slug
      role
      avatar
      firstName
      fullName
      title
      jobTitle
      notificationEmail
      email
      createdAt
      bannedAt
      lastSignInAt
      isInnovator
      linkedinUrl
      linkedinId
      lastSignInProvider
      department {
        _id
        name
      }
      interests {
        _id
        name
      }
      groups {
        ...UserGroupFields
      }
      verified
      government {
        _id
        slug
        name
        logo
        isInnovator
      }
      signInAttempts {
        registeredAt
        outcome
        ip
        userAgent
        lookup
        whitelisted
      }
      notes
      socialMedia {
        linkedin
        twitter
        facebook
        instagram
        youtube
      }
      latestNewsletterHtml
      newsletterFrequency
      subscribedToNewsletter
      subscribedToCampaign
      country
      language
      timeZone
      onboardingStatus
      searchable
      isSiteAdmin
      preferences {
        showAsAuthorOnProjects
      }
    }
  }

  fragment UserGroupFields on Group {
    _id
    slug
    logo
    name
    membersCount
    vendors {
      _id
      slug
      name
      logo
      viewerIsMember
    }
    governments {
      _id
      slug
      name
      logo
      viewerIsMember
      viewerIsOwner
    }
  }
`;
