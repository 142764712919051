import { LoadingSpinner } from "@govlaunch/web";
import React from "react";

export default function CenteredLoadingSpinner() {
  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingSpinner />
    </div>
  );
}
