import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Table } from "antd";
import { TableProps } from "antd/lib/table";
import uniqBy from "lodash/fp/uniqBy";
import startCase from "lodash/fp/startCase";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { ITag } from "../../../../types/types";
import { IGovernmentProjectFieldsFragment } from "../../../queries/fragments/__generated__/GovernmentProjectFields.generated";
import { IGroupProjectFieldsFragment } from "../../../queries/fragments/__generated__/GroupProjectFields.generated";
import { IVendorProjectFieldsFragment } from "../../../queries/fragments/__generated__/VendorProjectFields.generated";
import { Margin } from "../../../spacings";
import notUndefinedOrNull from "../../../utils/notUndefinedOrNull";
import { useSelfie } from "../../auth/Selfie";
import Countries from "../../governments/Countries";
import FlagWithTooltip from "../../governments/FlagWithTooltip";
import ProjectActions from "../ProjectActions";
import { getBooleanFilteredValue, getFilteredValue } from "../../../utils/tablesUtils";
import { IProjectsTableState } from "./ManageProjects";
import { arrayUnique } from "../../../utils/arrayUtils";
import TagPill from "../../tags/TagPill";

export type TProjectFields =
  | IGovernmentProjectFieldsFragment
  | IGroupProjectFieldsFragment
  | IVendorProjectFieldsFragment;

export interface IProjectsTableProps {
  projects: Array<IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment>;
  hideGovernmentColumn?: boolean;
  enableSorting?: boolean;
  onChange?: any;
  footer?: TableProps<any>["footer"];
  onDeleteProject?: any;
  title?: () => any;
  loading?: boolean;
  [key: string]: any;
  noFilters?: boolean;
  showingArchived?: boolean;
  userCreated?: boolean;
  state?: IProjectsTableState;
  selection?: Array<IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment>;
  setSelection?: React.Dispatch<
    React.SetStateAction<
      (IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment)[]
    >
  >;
}

export default function ProjectsTable({
  projects,
  footer,
  hideGovernmentColumn = false,
  enableSorting = true,
  onChange,
  onDeleteProject,
  title,
  loading = false,
  noFilters = false,
  showingArchived = false,
  userCreated = false,
  state,
  selection,
  setSelection,
  ...props
}: IProjectsTableProps) {
  const selfie = useSelfie();

  const checker = (arr: string | any[], target: any[]) => target.every((v) => arr.includes(v));

  return (
    <Table
      {...props}
      pagination={false}
      rowKey="_id"
      bordered={true}
      dataSource={projects}
      onChange={(pagination, filters, sorter, extra) => {
        if (typeof setSelection !== "undefined") {
          setSelection([]);
        }
        onChange(pagination, filters, sorter, extra);
      }}
      title={title}
      loading={loading}
      footer={footer}
      scroll={{
        x: true,
      }}
      style={{
        backgroundColor: palette.white,
      }}
      columns={[
        selection && setSelection
          ? {
              dataIndex: "_id",
              title: () => {
                return (
                  <span>
                    <input
                      type="checkbox"
                      checked={checker(
                        selection.map((project) => project._id),
                        projects.map((project) => project._id),
                      )}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelection(arrayUnique([...selection].concat(projects)));
                        } else {
                          setSelection(
                            selection.filter((el) => !projects.map((project) => project._id).includes(el._id)),
                          );
                        }
                      }}
                    />
                  </span>
                );
              },
              render: (_id: string) => (
                <span>
                  <input
                    type="checkbox"
                    checked={selection.map((p) => p._id).includes(_id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const newArr = [...selection];
                        const currentProject = projects.find((p) => p._id === _id);
                        if (currentProject) {
                          newArr.push(currentProject);
                          setSelection(newArr);
                        }
                      } else {
                        const newArr = [...selection];
                        setSelection(newArr.filter((p) => p._id !== _id));
                      }
                    }}
                  />
                </span>
              ),
            }
          : null,
        {
          title: "Title",
          dataIndex: "title",
          render: (title: string, project: TProjectFields) => (
            <div
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                css={{
                  width: 24,
                  height: 24,
                  backgroundImage: `url(${project.icon})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                }}
              />

              <Link
                to={`/projects/${project.slug}`}
                css={{
                  marginLeft: 8,
                  color: palette.primary,
                  cursor: "pointer",
                }}
              >
                {title}
              </Link>
            </div>
          ),
        },
        {
          title: "Audience",
          filters: !noFilters
            ? [
                {
                  text: "Public",
                  value: "public",
                },
                {
                  text: "Community",
                  value: "community",
                },
                {
                  text: "Private",
                  value: "private",
                },
              ]
            : undefined,
          filterMultiple: false,
          filteredValue: getFilteredValue(state?.audience),
          dataIndex: "audience",
          render: (audience: string) => startCase(audience),
        },
        {
          title: "Status",
          dataIndex: "status",
          filters: !noFilters
            ? [
                {
                  text: "Ideation",
                  value: "ideation",
                },
                {
                  text: "In Progress",
                  value: "inProgress",
                },
                {
                  text: "Planning",
                  value: "planning",
                },
                {
                  text: "Complete",
                  value: "complete",
                },
              ]
            : undefined,
          filterMultiple: true,
          filteredValue: state?.status,
          render: (status: any) => (status ? <ProjectStatus status={status} /> : "No Status"),
        },
        {
          title: "Author",
          dataIndex: "author",
          columnKey: "author",
          key: "author",
          sorter: false,
          render: (author: any) => {
            if (!author) {
              return "N/A";
            }

            return <Link to={`/users/${author.slug}`}>{author ? author.fullName || "N/A" : "N/A"}</Link>;
          },
        },
        {
          title: "Country",
          dataIndex: "government",
          key: "country",
          filterMultiple: false,
          filters: !noFilters
            ? Countries.map((country) => {
                return {
                  text: country.name,
                  value: country.code,
                };
              })
            : undefined,
          filteredValue: getFilteredValue(state?.country),
          render: (government: IGovernmentProjectFieldsFragment["government"]) => {
            if (!government) {
              return <span>N/A</span>;
            }

            return <FlagWithTooltip country={government.city.country} width={24} height={18} />;
          },
        },
        hideGovernmentColumn
          ? null
          : {
              title: "By",
              sorter: enableSorting,
              sortOrder: state && state.sortColumnKey === "government" ? state.sortColumnOrder : undefined,
              key: "government",
              render: (_: any, project: TProjectFields) => {
                return (
                  <ProjectOwnerColumn
                    project={project}
                    isLimitedAdmin={selfie && selfie.isLimitedSiteAdmin ? true : false}
                  />
                );
              },
            },
        {
          title: "Tags",
          dataIndex: "tags",
          render: (tags: ITag[]) => {
            return (
              <div
                css={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {uniqBy((tag) => tag._id, tags).map((tag) => {
                  return <TagPill key={tag._id} name={tag.name} />;
                })}
              </div>
            );
          },
        },
        {
          title: "Created",
          sorter: enableSorting,
          sortOrder: state && state.sortColumnKey === "createdAt" ? state.sortColumnOrder : undefined,
          key: "createdAt",
          columnKey: "createdAt",
          render: ({ createdAt }: { createdAt: Date }) => moment(createdAt).format("MM/DD/YYYY"),
        },
        showingArchived
          ? {
              title: "Archived At",
              render: (project: TProjectFields) => moment(project.archivedAt).format("MM/DD/YYYY"),
            }
          : null,
        showingArchived
          ? {
              title: "Archived By",
              render: (project: TProjectFields) => {
                if (project.archivedBy) {
                  return <Link to={`/users/${project.archivedBy.slug}`}>{project.archivedBy.fullName}</Link>;
                }

                return "N/A";
              },
            }
          : null,
        userCreated
          ? {
              title: "Verified",
              columnKey: "verified",
              key: "verified",
              sorter: enableSorting,
              filters: !noFilters
                ? [
                    {
                      text: "Verified",
                      value: true,
                    },
                    {
                      text: "Pending",
                      value: false,
                    },
                  ]
                : undefined,
              filteredValue: getBooleanFilteredValue(state?.verified),
              filterMultiple: false,
              render: (project: TProjectFields) => {
                return (
                  <span
                    css={{
                      fontWeight: "bold",
                      fontSize: 14,
                      color: project.verified ? palette.greenSuccess : palette.red,
                    }}
                  >
                    {project.verified ? "Yes" : "No"}
                  </span>
                );
              },
            }
          : null,
        userCreated
          ? {
              title: "Verified By",
              render: (project: TProjectFields) => {
                if (project.verifiedBy) {
                  return <Link to={`/users/${project.verifiedBy.slug}`}>{project.verifiedBy.fullName}</Link>;
                }

                return "N/A";
              },
            }
          : null,
        {
          title: "Actions",
          render: (project: TProjectFields) => <ProjectActions project={project} onDeleteProject={onDeleteProject} />,
        },
      ].filter(notUndefinedOrNull)}
    />
  );
}

ProjectsTable.propTypes = {
  projects: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  search: PropTypes.string,
  onDeleteProject: PropTypes.func,
  onChange: PropTypes.func,
};

ProjectsTable.defaultProps = {
  loading: false,
  search: "",
  onDeleteProject: null,
  onChange: null,
};

interface IProjectStatusProps {
  status: "ideation" | "planning" | "inProgress" | "complete";
}

const ProjectStatus = ({ status }: IProjectStatusProps) => {
  let statusText, color;

  if (status === "ideation") {
    statusText = "Ideation";
    color = "#609FD4";
  }

  if (status === "planning" || status === "inProgress") {
    statusText = status === "planning" ? "Planning" : "In Progress";
    color = "#E6AC5F";
  }

  if (status === "complete") {
    statusText = "Complete";
    color = "#5FBD8C";
  }

  return (
    <div
      css={{
        fontWeight: "bold",
        fontSize: 14,
        display: "inline-flex",
        borderRadius: 16,
        color,
        whiteSpace: "nowrap",
      }}
    >
      {statusText}
    </div>
  );
};

ProjectStatus.propTypes = {
  status: PropTypes.oneOf(["ideation", "planning", "inProgress", "complete"]).isRequired,
};

interface IProjectOwnerColumnProps {
  project: TProjectFields;
  isLimitedAdmin: boolean;
}

function ProjectOwnerColumn({ project, isLimitedAdmin }: IProjectOwnerColumnProps) {
  if (isLimitedAdmin) {
    if (project.__typename === "GovernmentProject") {
      return <ProjectOwner name={project.government.name} logo={project.government.logo} />;
    }

    if (project.__typename === "GroupProject") {
      return <ProjectOwner name={project.group.name} logo={project.group.logo} />;
    }

    if (project.__typename === "VendorProject") {
      return <ProjectOwner name={project.vendor.name} logo={project.vendor.logo} />;
    }

    return <span>N/A</span>;
  }

  if (project.__typename === "GovernmentProject") {
    return (
      <ProjectOwner
        name={project.government.name}
        logo={project.government.logo}
        link={`/governments/${project.government.slug}`}
      />
    );
  }

  if (project.__typename === "GroupProject") {
    return <ProjectOwner name={project.group.name} logo={project.group.logo} link={`/groups/${project.group.slug}`} />;
  }

  if (project.__typename === "VendorProject") {
    return (
      <ProjectOwner name={project.vendor.name} logo={project.vendor.logo} link={`/vendors/${project.vendor.slug}`} />
    );
  }

  return <span>N/A</span>;
}

interface IProjectOwnerProps {
  name: string;
  logo: string | null;
  link?: string;
}

function ProjectOwner({ name, logo, link }: IProjectOwnerProps) {
  if (link) {
    return (
      <Link
        to={link}
        css={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ProfilePicture name={name} image={logo} size={24} />
        <Margin ml={8}>{name}</Margin>
      </Link>
    );
  }

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <ProfilePicture name={name} image={logo} size={24} />
      <Margin ml={8}>{name}</Margin>
    </div>
  );
}
