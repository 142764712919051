import gql from "graphql-tag";
import CompanyPlanFields from "../queries/fragments/CompanyPlanFields";

export default gql`
  mutation EditVendorCustomLimits($vendorId: ObjectId!, $customLimits: VendorCustomLimitsInput!) {
    editVendorCustomLimits(vendorId: $vendorId, customLimits: $customLimits) {
      _id
      plan {
        ...CompanyPlanFields
      }
    }
  }

  ${CompanyPlanFields}
`;
