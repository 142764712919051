import { useEffect, useRef } from "react";

interface ITableOnSearchProps {
  search: string | null;
  onSearch: () => void;
}

export default function TableOnSearch({ search, onSearch }: ITableOnSearchProps) {
  const previousSearchValue = usePrevious(search);
  useEffect(() => {
    if (previousSearchValue !== search) {
      onSearch();
    }
  }, [search]);

  return null;
}

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
