import { Spacing } from "@govlaunch/spacers";
import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { Value } from "react-powerplug";
import { Query } from "react-apollo";
import {
  IAllVendorsQueryQuery,
  IAllVendorsQueryQueryVariables,
} from "../../../queries/__generated__/AllVendorsQuery.generated";
import AllVendorsQuery from "../../../queries/AllVendorsQuery";
import { Select } from "antd";

interface IVendorSelectFieldProps {
  initialVendor?: string;
}

export default function VendorSelectField({ initialVendor }: IVendorSelectFieldProps) {
  return (
    <Field name="company">
      {({ input, meta }) => (
        <div>
          <Spacing bottom={8}>
            <div
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <label
                css={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: palette.mediumGray,
                  margin: 0,
                }}
              >
                Vendor
              </label>

              <FieldValidationError meta={meta} />
            </div>
          </Spacing>

          <Value initial={initialVendor || ""}>
            {({ value: searchValue, set: onSearchValueChange }) => (
              <Query<IAllVendorsQueryQuery, IAllVendorsQueryQueryVariables>
                variables={{
                  filter: {
                    search: searchValue,
                    tab: null,
                    plan: null,
                    planId: null,
                    stripeStatus: null,
                    hasOwner: null,
                    active: null,
                    inTiers: null,
                    sponsorships: null,
                    hasCustomLimits: null,
                  },
                }}
                query={AllVendorsQuery}
              >
                {({ data, loading }) => {
                  if (!data || (!data.allVendors && loading)) {
                    return null;
                  }

                  if (!data.allVendors) {
                    return null;
                  }

                  return (
                    <Select
                      showSearch={true}
                      value={input.value}
                      onChange={input.onChange}
                      onFocus={input.onFocus}
                      onSearch={onSearchValueChange}
                      defaultActiveFirstOption={false}
                      showArrow={true}
                      filterOption={false}
                      onBlur={() => {
                        onSearchValueChange("");
                        input.onBlur();
                      }}
                      size="large"
                    >
                      {data.allVendors.items.filter(Boolean).map((vendor) => {
                        return (
                          <Select.Option key={vendor._id} value={vendor._id}>
                            {vendor.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  );
                }}
              </Query>
            )}
          </Value>
        </div>
      )}
    </Field>
  );
}
