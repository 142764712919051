import React, { ReactElement } from "react";
import * as palette from "@govlaunch/palette";
import { IBlockLayout } from "../blocks/blockProps";

interface IBlockLayoutContainerProps {
  block?: IBlockLayout;
  children: ReactElement | ReactElement[] | any;
}

export default function BlockLayoutContainer({ block, children }: IBlockLayoutContainerProps) {
  return (
    <div
      css={{
        borderRadius: 5,
        border: `1px solid ${palette.lightestGray}`,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
        background: getBackgroundColor(block?.blockData.backgroundColor),
        paddingTop: block?.blockData.paddingTop || 0,
        paddingBottom: block?.blockData.paddingBottom || 0,
        paddingLeft: block?.blockData.paddingLeft || 0,
        paddingRight: block?.blockData.paddingRight || 0,
      }}
    >
      {children}
    </div>
  );
}

function getBackgroundColor(backgroundColor?: string) {
  if (!backgroundColor || backgroundColor === "transparent") {
    return palette.white;
  }

  return backgroundColor;
}
