import React from "react";
import { IVendorQuery } from "../../../queries/__generated__/VendorQuery.generated";
import { Query } from "react-apollo";
import CompanyAccountBillingInfoQuery from "../../../queries/CompanyAccountBillingInfoQuery";
import {
  ICompanyAccountBillingInfoQuery,
  ICompanyAccountBillingInfoQueryVariables,
} from "../../../queries/__generated__/CompanyAccountBillingInfoQuery.generated";
import CurrentPlanAndUsage from "../makers/CurrentPlanAndUsage";
import CurrentBilling from "../makers/CurrentBilling";
import PaymentHistory from "../makers/PaymentHistory";
import { LoadingIndicator } from "@govlaunch/core";

interface IVendorAccountTabProps {
  vendor: NonNullable<IVendorQuery["company"]>;
}

export default function VendorSubscriptionTab({ vendor }: IVendorAccountTabProps) {
  return (
    <Query<ICompanyAccountBillingInfoQuery, ICompanyAccountBillingInfoQueryVariables>
      query={CompanyAccountBillingInfoQuery}
      variables={{
        slug: vendor.slug,
      }}
    >
      {({ data, loading, refetch }) => {
        if (!data || !data.company) {
          return null;
        }

        const { company } = data;

        return (
          <div
            css={{
              display: "grid",
              gridRowGap: 20,
              width: "100%",
              paddingTop: 20,
            }}
          >
            {loading && <LoadingIndicator />}

            <CurrentPlanAndUsage company={company} refetch={refetch} />

            <CurrentBilling company={company} />

            <PaymentHistory company={company} />
          </div>
        );
      }}
    </Query>
  );
}
