import * as Yup from "yup";
import { ITagType } from "../../../types/types";

const TagValidationSchema = Yup.object({
  name: Yup.string().required("can't be blank"),
  types: Yup.array()
    .of(Yup.string().oneOf(["PRODUCT", "PROJECT", "STORY", "CUSTOM", "DEPARTMENT", "AWARD"]).required())
    .required("You must select type"),
  relatedTags: Yup.array().when("types", {
    is: (value: ITagType[]) => {
      return value.includes(ITagType.Department);
    },
    then: Yup.array().required().min(1, "Can't be blank").max(5, "You can select up to five tags"),
    otherwise: Yup.array().nullable(),
  }),
});

export default TagValidationSchema;
