import React from "react";
import { Field } from "react-final-form";
import { ITagType } from "../../../../types/types";
import FieldValidationError from "../../../components/form/FieldValidationError";
import TagsSelectContainer from "../TagsSelect";

interface IRelatedTagsFieldProps {
  tagTypes: ITagType[] | null | undefined;
}

export default function RelatedTagsField({ tagTypes }: IRelatedTagsFieldProps) {
  if (!tagTypes) {
    return null;
  }

  const isDepartmentTag = tagTypes.some((type) => type === ITagType.Department);

  if (!isDepartmentTag) {
    return null;
  }

  return (
    <Field name="relatedTags">
      {({ input, meta }) => {
        return (
          <div>
            <div
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                css={{
                  fontSize: 12,
                  fontWeight: 500,
                  letterSpacing: 0.5,
                  textTransform: "uppercase",
                  display: "flex",
                }}
              >
                Related Tags
              </span>

              <FieldValidationError meta={meta} />
            </div>

            <div
              css={{
                marginTop: 10,
                maxWidth: 560,
              }}
            >
              <TagsSelectContainer
                {...input}
                type={["PRODUCT", "STORY", "PROJECT"]}
                placeholder="Pick a maximum of five..."
              />
            </div>
          </div>
        );
      }}
    </Field>
  );
}
