import React from "react";
import { useQuery } from "react-apollo";
import { DEFAULT_PROJECTS_ITEM_LIMIT, IBlockLayout, TBlockLayoutData } from "../blockProps";
import BlockLayoutContainer from "../../blockslayout/BlockLayoutContainer";
import BlockLayoutHeader from "../../blockslayout/BlockLayoutHeader";
import CenteredLoadingSpinner from "../CenteredLoadingSpinner";
import GetNewProjects from "../../../../queries/GetNewProjects";
import {
  IGetNewProjectQuery,
  IGetNewProjectQueryVariables,
} from "../../../../queries/__generated__/GetNewProjects.generated";
import BlockContentTitle from "../BlockContentTitle";
import BlockLayoutHeaderSettings from "../../blockslayout/BlockLayoutHeaderSettings";

interface INewProjectsBlockProps {
  block: IBlockLayout;
  onRemoveBlock: () => void;
  saveBlockData: (block: IBlockLayout, blockData: TBlockLayoutData) => void;
}

export default function NewProjectsBlock({ block, onRemoveBlock, saveBlockData }: INewProjectsBlockProps) {
  return (
    <BlockLayoutContainer block={block}>
      <BlockLayoutHeader block={block} onRemoveBlock={onRemoveBlock}>
        <BlockLayoutHeaderSettings block={block} saveBlockData={saveBlockData} />
      </BlockLayoutHeader>
      {block.blockData["titleVisible"] && <BlockContentTitle block={block} />}
      <NewProjectsBlockData block={block} />
    </BlockLayoutContainer>
  );
}

interface INewProjectsBlockDataProps {
  block: IBlockLayout;
}

function NewProjectsBlockData({ block }: INewProjectsBlockDataProps) {
  const { data, loading } = useQuery<IGetNewProjectQuery, IGetNewProjectQueryVariables>(GetNewProjects, {
    variables: {
      limit: block.blockData.itemLimit || DEFAULT_PROJECTS_ITEM_LIMIT,
      cursor: null,
    },
    fetchPolicy: "network-only",
  });

  if (!data || loading) {
    return <CenteredLoadingSpinner />;
  }

  const projects = data.getNewProjects?.items || [];

  return (
    <div
      css={{
        width: "100%",
      }}
    >
      {projects.length > 0 &&
        projects.map((project) => (
          <div
            key={project._id}
            css={{
              "&:not(:first-of-type)": {
                marginTop: "20px",
              },
            }}
          >
            <ProjectRow project={project} />
          </div>
        ))}
      {projects.length === 0 && <div>No data...</div>}
    </div>
  );
}

interface IProjectRowProps {
  project: NonNullable<NonNullable<IGetNewProjectQuery["getNewProjects"]>["items"]>[0];
}

function ProjectRow({ project }: IProjectRowProps) {
  const country = project.title.match(/([A-zÀ-ú-/&'., 0-9]+, (?:[A-Z]{2,3}))\s(.+)/);
  const title = country ? project.title.replace(country[1], "") : project.title;

  return (
    <div
      css={{
        width: "100%",
        fontFamily: "'proxima-nova', Helvetica, Arial, sans-serif",
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
      }}
    >
      <img
        src={`https://static.govlaunch.com/flags/squared/${project.countryCode || ""}.svg`}
        width="30px"
        alt={project.countryCode || ""}
        title={project.countryCode || ""}
        style={{
          border: 0,
          outline: "none",
          textDecoration: "none",
          margin: 0,
          padding: 0,
          marginTop: "6px",
          marginRight: "10px",
        }}
      />

      <div>
        {country && country[1] && (
          <span
            style={{
              color: "#086bef",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "normal",
            }}
          >
            {country[1]}
          </span>
        )}
        <span
          css={{
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "24px",
          }}
        >
          {title}
        </span>
      </div>
    </div>
  );
}
