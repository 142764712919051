import React from "react";
import AddBlockButton from "./AddBlockButton";
import ImageBlock from "../blocks/ImageBlock";
import PodcastBlock from "../blocks/podcast/PodacastBlock";
import AdvertisementBlock from "../blocks/AdvertisementBlock";
import EmptySpaceBlock from "../blocks/EmptySpaceBlock";
import NewProjectsBlock from "../blocks/newprojects/NewProjectsBlock";
import FeaturedStoriesBlock from "../blocks/featuredstories/FeaturedStoriesBlock";
import ExternalLinksBlock from "../blocks/externallinks/ExternalLinksBlock";
import StatCountersBlock from "../blocks/statcounters/StatCountersBlock";
import TrendingGovernmentsBlock from "../blocks/trendinggovernments/TrendingGovernmentsBlock";
import TrendingCollectionsBlock from "../blocks/trendingcollections/TrendingCollectionsBlock";
import EventsBlock from "../blocks/events/EventsBlock";
import { IBlockLayout, IBlockLayoutDataBase } from "../blocks/blockProps";
import { IBlockLayoutType } from "../../../../types/types";

interface IBlockLayoutProps {
  block: IBlockLayout;
  onRemoveBlock: (i: string) => void;
  onAddBlock: (block: IBlockLayout) => void;
  saveBlockData: (block: IBlockLayout, blockData: IBlockLayoutDataBase) => void;
}

export default function BlockLayout({ block, onRemoveBlock, onAddBlock, saveBlockData }: IBlockLayoutProps) {
  return (
    <>
      {block.blockData.type === IBlockLayoutType.ExternalLinks && (
        <ExternalLinksBlock block={block} onRemoveBlock={() => onRemoveBlock(block.i)} saveBlockData={saveBlockData} />
      )}
      {block.blockData.type === IBlockLayoutType.StatCounters && (
        <StatCountersBlock block={block} onRemoveBlock={() => onRemoveBlock(block.i)} saveBlockData={saveBlockData} />
      )}
      {block.blockData.type === IBlockLayoutType.TrendingGovernments && (
        <TrendingGovernmentsBlock
          block={block}
          onRemoveBlock={() => onRemoveBlock(block.i)}
          saveBlockData={saveBlockData}
        />
      )}
      {block.blockData.type === IBlockLayoutType.TrendingCollections && (
        <TrendingCollectionsBlock
          block={block}
          onRemoveBlock={() => onRemoveBlock(block.i)}
          saveBlockData={saveBlockData}
        />
      )}
      {block.blockData.type === IBlockLayoutType.Events && (
        <EventsBlock block={block} onRemoveBlock={() => onRemoveBlock(block.i)} saveBlockData={saveBlockData} />
      )}
      {block.blockData.type === IBlockLayoutType.Image && (
        <ImageBlock block={block} saveBlockData={saveBlockData} onRemoveBlock={() => onRemoveBlock(block.i)} />
      )}
      {block.blockData.type === IBlockLayoutType.Podcast && (
        <PodcastBlock block={block} saveBlockData={saveBlockData} onRemoveBlock={() => onRemoveBlock(block.i)} />
      )}
      {block.blockData.type === IBlockLayoutType.Advertisement && (
        <AdvertisementBlock block={block} saveBlockData={saveBlockData} onRemoveBlock={() => onRemoveBlock(block.i)} />
      )}
      {block.blockData.type === IBlockLayoutType.NewProjects && (
        <NewProjectsBlock block={block} onRemoveBlock={() => onRemoveBlock(block.i)} saveBlockData={saveBlockData} />
      )}
      {block.blockData.type === IBlockLayoutType.EmptySpaceBlock && (
        <EmptySpaceBlock block={block} onRemoveBlock={() => onRemoveBlock(block.i)} />
      )}
      {block.blockData.type === IBlockLayoutType.FeaturedStories && (
        <FeaturedStoriesBlock
          block={block}
          saveBlockData={saveBlockData}
          onRemoveBlock={() => onRemoveBlock(block.i)}
        />
      )}
      {block.blockData.type === IBlockLayoutType.PlusBlock && <AddBlockButton block={block} onAddBlock={onAddBlock} />}
    </>
  );
}
