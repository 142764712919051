import gql from "graphql-tag";

export default gql`
  query UsersCount {
    usersCount {
      governmentUsersCount
      vendorUsersCount
      pendingUsersCount
      verifiedCommunityUsersCount
      innovatorsCount
      disruptorsCount
      citizensCount
      blockedCount
      pendingVerificationOnboardingCount: onboardingCount(processStatus: PENDING_VERIFICATION)
    }
  }
`;
