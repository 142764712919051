import { Tabs } from "antd";
import queryString from "query-string";
import React, { useCallback } from "react";
import { useQuery } from "react-apollo";
import { useLocation, useNavigate } from "react-router";
import { useTitle } from "react-use";
import TabContent from "../../components/TabContent";
import CollectionSponsorshipsTabContent from "./CollectionSponsorshipsTabContent";
import ListCollectionSponsoringSales from "./graphql/queries/ListCollectionSponsoringSales";
import {
  IListCollectionSponsoringSalesQuery,
  IListCollectionSponsoringSalesQueryVariables,
} from "./graphql/queries/__generated__/ListCollectionSponsoringSales.generated";

export default function CollectionSponsorships() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = Array.isArray(query.tab) ? query.tab[0] : query.tab || "all";

  const { data, networkStatus } = useQuery<
    IListCollectionSponsoringSalesQuery,
    IListCollectionSponsoringSalesQueryVariables
  >(ListCollectionSponsoringSales, {
    fetchPolicy: "network-only",
  });

  useTitle("Collections Sponsorships | Admin");

  const changeTab = useCallback((tab: string) => {
    if (tab === "all") {
      navigate("/collections/sponsorships");
    } else {
      navigate(`/collections/sponsorships?tab=${tab}`);
    }
  }, []);

  const allSponsorings = data?.listAllCollectionSponsoringSales.filter((sponsoring) => !sponsoring.archivedAt) || [];
  const activeSponsorings = data?.listAllCollectionSponsoringSales.filter((sponsoring) => sponsoring.isActive) || [];
  const pendingSponsorings =
    data?.listAllCollectionSponsoringSales.filter(
      (sponsoring) =>
        sponsoring.stripeState.latestInvoiceStatus === "open" &&
        !sponsoring.archivedAt &&
        !sponsoring.isActive &&
        !(
          sponsoring.__typename === "LumpSumCollectionSponsoringSale" &&
          new Date(sponsoring.expirationDate) < new Date()
        ),
    ) || [];
  const expiredSponsorings =
    data?.listAllCollectionSponsoringSales.filter(
      (sponsoring) =>
        sponsoring.__typename === "LumpSumCollectionSponsoringSale" && new Date(sponsoring.expirationDate) < new Date(),
    ) || [];
  const archivedSponsorings =
    data?.listAllCollectionSponsoringSales.filter((sponsoring) => sponsoring.archivedAt) || [];

  const allCount = allSponsorings.length;
  const activeCount = activeSponsorings.length;
  const pendingCount = pendingSponsorings.length;
  const expiredCount = expiredSponsorings.length;
  const archivedCount = archivedSponsorings.length;

  return (
    <div>
      <div
        css={{
          padding: 24,
          borderBottom: "1px solid rgb(232, 232, 232)",
        }}
      >
        <h3
          css={{
            margin: 0,
            fontWeight: 700,
            fontSize: 24,
            lineHeight: 1.33,
          }}
        >
          Collection Sponsorships
        </h3>
      </div>

      <Tabs
        animated={false}
        onChange={(tab) => changeTab(tab)}
        activeKey={tab || "all"}
        size="middle"
        tabBarStyle={{
          margin: 0,
          paddingRight: 24,
          paddingLeft: 24,
          userSelect: "none",
        }}
      >
        <Tabs.TabPane tab={`All (${allCount})`} key="all">
          {tab === "all" && (
            <TabContent>
              <CollectionSponsorshipsTabContent
                sponsorings={allSponsorings}
                networkStatus={networkStatus}
                tab={tab}
                navigate={navigate}
              />
            </TabContent>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Active (${activeCount})`} key="active">
          {tab === "active" && (
            <TabContent>
              <CollectionSponsorshipsTabContent
                sponsorings={activeSponsorings}
                networkStatus={networkStatus}
                tab={tab}
                navigate={navigate}
              />
            </TabContent>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Pending (${pendingCount})`} key="pending">
          {tab === "pending" && (
            <TabContent>
              <CollectionSponsorshipsTabContent
                sponsorings={pendingSponsorings}
                networkStatus={networkStatus}
                tab={tab}
                navigate={navigate}
              />
            </TabContent>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Expired (${expiredCount})`} key="expired">
          {tab === "expired" && (
            <TabContent>
              <CollectionSponsorshipsTabContent
                sponsorings={expiredSponsorings}
                networkStatus={networkStatus}
                tab={tab}
                navigate={navigate}
              />
            </TabContent>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Archived (${archivedCount})`} key="archived">
          {tab === "archived" && (
            <TabContent>
              <CollectionSponsorshipsTabContent
                sponsorings={archivedSponsorings}
                networkStatus={networkStatus}
                tab={tab}
                navigate={navigate}
              />
            </TabContent>
          )}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
