import set from "lodash/fp/set";
import { BaseSchema, ValidationError } from "yup";

export default function validate(schema: BaseSchema<any>): (values: any) => object {
  return async (values) => {
    try {
      await schema.validate(values, {
        abortEarly: false,
      });

      return {};
    } catch ({ inner }) {
      return (inner as any).reduce((errors: object, error: ValidationError) => {
        return set(error.path as any, error.message, errors);
      }, {});
    }
  };
}
