import React from "react";
import { Mutation } from "react-apollo";
import { IMutationForceOnboardingArgs } from "../../../../../types/types";
import ForceOnboarding from "../../../../mutations/ForceOnboarding";
import { IForceOnboardingMutation } from "../../../../mutations/__generated__/ForceOnboarding.generated";
import { IViewOnboardingControlMutationsProps } from "../Interfaces";
import { notification, Popconfirm } from "antd";
import { Button } from "@govlaunch/core";

export default function ForceOnboardingMutation({ userId, refetch }: IViewOnboardingControlMutationsProps) {
  return (
    <Mutation<IForceOnboardingMutation, IMutationForceOnboardingArgs>
      onCompleted={() => {}}
      variables={{
        userId: userId,
      }}
      mutation={ForceOnboarding}
      onError={(err) => {
        if (/Can not create onboarding for non existing user/.test(err.message)) {
          notification.open({
            type: "error",
            message: "Can not create onboarding for non existing user",
            description: `Please refresh the page and try again`,
          });
        }
      }}
      refetchQueries={["OnboardingsCount"]}
      awaitRefetchQueries={true}
    >
      {(forceOnboarding) => (
        <Popconfirm
          title="Do you want to force the onboarding process to this user?"
          onConfirm={async () => {
            await forceOnboarding();
            refetch();
          }}
        >
          <Button
            type="button"
            css={{
              marginRight: 5,
              fontWeight: 500,
            }}
          >
            Force Onboarding
          </Button>
        </Popconfirm>
      )}
    </Mutation>
  );
}
