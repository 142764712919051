import * as Yup from "yup";

export default Yup.object().shape({
  name: Yup.string().required("Can't be blank"),
  projectsPerCountry: Yup.array()
    .of(
      Yup.object().shape({
        countryCode: Yup.string().nullable(),
        amount: Yup.number().nullable(),
      }),
    )
    .min(1, "Minimum of one country must be set"),
  storiesCount: Yup.number().required().min(0, "Min 0 stories").max(20, "Max 20 stories"),
  projectsCount: Yup.number().required().min(0, "Min 0 projects").max(20, "Max 20 projects"),
  topCollections: Yup.number().required().min(0, "Min 0 collections").max(20, "Max 20 collections"),
  otherCollections: Yup.number().required().min(0, "Min 0 collections").max(20, "Max 20 collections"),
});
