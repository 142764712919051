import gql from "graphql-tag";
import GovernmentProjectFields from "../queries/fragments/GovernmentProjectFields";
import GroupProjectFields from "../queries/fragments/GroupProjectFields";
import VendorProjectFields from "../queries/fragments/VendorProjectFields";

export default gql`
  mutation EditProject($projectId: ObjectId!, $project: ProjectInput!, $verified: Boolean) {
    editProject(projectId: $projectId, project: $project, verified: $verified) {
      _id
      ...GovernmentProjectFields
      ...GroupProjectFields
      ...VendorProjectFields
    }
  }

  ${GovernmentProjectFields}
  ${GroupProjectFields}
  ${VendorProjectFields}
`;
