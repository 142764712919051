import React, { useState } from "react";
import { Query } from "react-apollo";
import GetGovernmentQuery from "../../queries/GetGovernmentQuery";
import VendorQuery from "../../queries/VendorQuery";
import {
  IGetGovernmentQuery,
  IGetGovernmentQueryVariables,
} from "../../queries/__generated__/GetGovernmentQuery.generated";
import { IVendorQuery, IVendorQueryVariables } from "../../queries/__generated__/VendorQuery.generated";
import BatchVendorAndGovernmentSearch from "./modals/BatchVendorAndGovernmentSearch";
import EditGovernmentMembersModal from "./modals/EditGovernmentMembersModal";
import EditVendorMembersModal from "./modals/EditVendorMembersModal";
import ErrorStateModal from "./modals/ErrorStateModal";
import LoadingStateModal from "./modals/LoadingStateModal";
import { TGovernmentMember, TVendorMember } from "./types";

type TSelectedEntity = {
  entitySlug: string;
  entityType: "GOVERNMENT" | "VENDOR";
};

interface IAssignUserFlowProps {
  isVisible: boolean;
  initialSelectedEntity?: TSelectedEntity | null;
  initialMemberAdditions?: (TGovernmentMember | TVendorMember)[];
  onRequestClose: () => void;
  onSaveChanges?: () => void;
  defaultSearchRefinement?: string;
}

export default function AssignUserFlow({
  isVisible,
  initialSelectedEntity = null,
  initialMemberAdditions = [],
  onRequestClose,
  onSaveChanges,
  defaultSearchRefinement,
}: IAssignUserFlowProps) {
  const [selectedEntity, setSelectedEntity] = useState<null | TSelectedEntity>(initialSelectedEntity);

  if (!selectedEntity) {
    let title = null;

    if (initialMemberAdditions && initialMemberAdditions.length === 1) {
      title = (
        <>
          Search a government or vendor to assign <strong>{initialMemberAdditions[0].fullName}</strong>
        </>
      );
    }

    return (
      <BatchVendorAndGovernmentSearch
        title={title}
        visible={isVisible}
        onSelectVendor={({ slug }) =>
          setSelectedEntity({
            entitySlug: slug,
            entityType: "VENDOR",
          })
        }
        onSelectGovernment={({ slug }) =>
          setSelectedEntity({
            entitySlug: slug,
            entityType: "GOVERNMENT",
          })
        }
        onRequestClose={onRequestClose}
        defaultSearchRefinement={defaultSearchRefinement}
      />
    );
  }

  const deselectEntityAndClose = () => {
    setSelectedEntity(null);
    onRequestClose();
  };

  if (selectedEntity.entityType === "VENDOR") {
    return (
      <Query<IVendorQuery, IVendorQueryVariables>
        query={VendorQuery}
        variables={{
          slug: selectedEntity.entitySlug,
        }}
      >
        {({ loading, data }) => {
          if (loading) {
            return (
              <LoadingStateModal title="Team management" visible={loading} onRequestClose={deselectEntityAndClose} />
            );
          }

          if (!data || !data.company) {
            return (
              <ErrorStateModal
                title="Team management"
                errorDescription="Couldn't retrieve vendor information from the API."
                visible={true}
                onRequestClose={deselectEntityAndClose}
              />
            );
          }

          return (
            <EditVendorMembersModal
              visible={isVisible}
              vendor={data.company}
              initialMemberAdditions={initialMemberAdditions}
              onRequestClose={deselectEntityAndClose}
              onRequestBack={() => setSelectedEntity(null)}
              displayBackButton={true}
              onSaveChanges={onSaveChanges}
            />
          );
        }}
      </Query>
    );
  }

  if (selectedEntity.entityType === "GOVERNMENT") {
    return (
      <Query<IGetGovernmentQuery, IGetGovernmentQueryVariables>
        query={GetGovernmentQuery}
        variables={{
          slug: selectedEntity.entitySlug,
          projectsCursor: null,
          storiesCursor: null,
        }}
      >
        {({ loading, data }) => {
          if (loading) {
            return (
              <LoadingStateModal title="Team management" visible={loading} onRequestClose={deselectEntityAndClose} />
            );
          }

          if (!data || !data.government) {
            return (
              <ErrorStateModal
                title="Team management"
                errorDescription="Couldn't retrieve government information from the API."
                visible={true}
                onRequestClose={deselectEntityAndClose}
              />
            );
          }

          return (
            <EditGovernmentMembersModal
              visible={isVisible}
              government={data.government}
              initialMemberAdditions={initialMemberAdditions}
              onRequestClose={deselectEntityAndClose}
              onRequestBack={() => setSelectedEntity(null)}
              displayBackButton={true}
              onSaveChanges={onSaveChanges}
            />
          );
        }}
      </Query>
    );
  }

  return null;
}
