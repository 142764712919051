import React from "react";
import DocumentTitle from "react-document-title";
import * as palette from "@govlaunch/palette";
import NewLandingPageForm from "./NewLandingPageForm";

export default function NewLandingPage() {
  return (
    <DocumentTitle title="New Landing Page Template | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
        }}
      >
        <NewLandingPageForm />
      </div>
    </DocumentTitle>
  );
}
