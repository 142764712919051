import React from "react";
import { IGetGovernmentQuery } from "../../../queries/__generated__/GetGovernmentQuery.generated";
import ProductsTable from "../../products/tables/ProductsTable";

interface IProductsTabProps {
  government: IGetGovernmentQuery["government"];
}

const ProductsTab = ({ government }: IProductsTabProps) => {
  if (!government) {
    return null;
  }

  return (
    <div>
      {government.products && (
        <ProductsTable
          search={null}
          products={government.products}
          renderProductLink={(product) => `/governments/${government.slug}/products/${product.slug}`}
          sorter={false}
        />
      )}
    </div>
  );
};

export default ProductsTab;
