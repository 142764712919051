import AddNewProduct from "./AddNewProduct";
import DocumentTitle from "react-document-title";
import { useNavigate } from "react-router";
import React from "react";

export default function NewProduct() {
  const navigate = useNavigate();

  return (
    <DocumentTitle title="Add Product | Admin">
      <div>
        <AddNewProduct
          onAdd={(productSlug?: string | null) => {
            if (productSlug) {
              navigate(`/products/${productSlug}`);
            }
          }}
        />
      </div>
    </DocumentTitle>
  );
}
