import gql from "graphql-tag";

export default gql`
  query GetStoriesByVendor($vendorId: ObjectId!, $sort: SortType, $limit: Int = 120) {
    storiesByVendor(vendorId: $vendorId, sort: $sort, limit: $limit) {
      items {
        _id
        title
        slug
        status
        audience
        featured
        horizontalCover
        verticalCover
        lastPublishedAt
        modifiedAt
        createdAt
        archivedAt
        archivedBy {
          ... on PendingUser {
            _id
            slug
            fullName
          }
          ... on VendorUser {
            _id
            slug
            fullName
          }
          ... on GovernmentUser {
            _id
            slug
            fullName
          }
        }
        government {
          _id
          state
          city {
            _id
            name
          }
        }
        author {
          ... on PendingUser {
            _id
            fullName
            jobTitle
            avatar
          }
          ... on VendorUser {
            _id
            fullName
            title
            avatar
            jobTitle
            company {
              _id
              slug
              name
              logo
            }
          }
          ... on GovernmentUser {
            _id
            fullName
            title
            avatar
            isInnovator
            jobTitle
            government {
              _id
              slug
              name
              logo
            }
          }
        }
        tags {
          _id
          slug
          name
        }
      }
    }
  }
`;
