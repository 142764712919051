import React from "react";
import * as palette from "@govlaunch/palette";
import { Margin } from "../../../spacings";
import { Field } from "react-final-form";
import TagsSelectContainer from "../../tags/TagsSelect";
import { ITagType } from "../../../../types/types";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { OnChange } from "react-final-form-listeners";
import { useApolloClient } from "react-apollo";
import GetDepartmentRelatedTags from "../../../queries/GetDepartmentRelatedTags";
import {
  IGetDepartmentRelatedTagsQuery,
  IGetDepartmentRelatedTagsQueryVariables,
} from "../../../queries/__generated__/GetDepartmentRelatedTags.generated";

export default function InterestsField() {
  const apolloClient = useApolloClient();

  const queryRelatedTags = async (departmentId: any) => {
    const queryData = await apolloClient.query<IGetDepartmentRelatedTagsQuery, IGetDepartmentRelatedTagsQueryVariables>(
      {
        query: GetDepartmentRelatedTags,
        variables: {
          departmentId,
        },
      },
    );

    return queryData.data.getDepartmentRelatedTags || [];
  };

  return (
    <Field name="interests">
      {({ input, meta }) => (
        <>
          <OnChange name="department">
            {async (value: any) => {
              if (value) {
                const relatedTags = await queryRelatedTags(value.objectID);
                const interests = relatedTags
                  .map((interest) => {
                    return {
                      _id: interest._id,
                      objectID: interest._id,
                      name: interest.name,
                    };
                  })
                  .filter(
                    (interest) =>
                      !(input.value || []).find(
                        (value: any) => value._id === interest._id || value.objectID === interest._id,
                      ),
                  );

                input.onChange((input.value || []).concat(interests));
              }
            }}
          </OnChange>
          <div>
            <div>
              <label
                css={{
                  fontWeight: "bold",
                  fontSize: 14,
                  color: palette.mediumGray,
                  margin: 0,
                }}
              >
                Interests
              </label>
            </div>

            <Margin mt={10}>
              <div
                css={{
                  width: "100%",
                }}
              >
                <TagsSelectContainer
                  {...input}
                  type={[ITagType.Product, ITagType.Story, ITagType.Project]}
                  placeholder="Pick a maximum of twenty..."
                />
                <div>
                  <FieldValidationError meta={meta} />
                </div>
              </div>
            </Margin>
          </div>
        </>
      )}
    </Field>
  );
}
