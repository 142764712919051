import gql from "graphql-tag";

export default gql`
  query AllProducts(
    $tags: [ObjectId!]
    $search: String
    $filter: ProductFilter
    $sort: String
    $inTiers: [Int!]
    $cursor: Cursor
  ) {
    products: allProducts(
      tags: $tags
      filter: $filter
      sort: $sort
      search: $search
      limit: 25
      inTiers: $inTiers
      cursor: $cursor
    ) {
      items {
        _id
        slug
        name
        logo
        isDisruptor
        status
        storiesCount
        projectsCount
        governmentsUsingCount
        canBeDisruptor
        tier
        archivedAt
        archivedBy {
          ... on PendingUser {
            _id
            slug
            fullName
          }
          ... on VendorUser {
            _id
            slug
            fullName
          }
          ... on GovernmentUser {
            _id
            slug
            fullName
          }
        }
        featuredAt
        createdAt
        updatedAt
        company {
          _id
          slug
          name
          logo
          isDisruptor
          archivedAt
        }
        tags {
          _id
          name
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
