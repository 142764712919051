import React from "react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import IntercomIcon from "../../../components/icons/IntercomIcon";
import { IProcessStatusType } from "../../../../types/types";
import OnboardingProfileIcon from "../../../components/icons/OnboardingProfileIcon";
import * as palette from "@govlaunch/palette";
import { getUserType } from "../utils/getUserType";

type TUserTableNameData = {
  __typename: string;
  _id: any;
  slug: string;
  avatar: string | null | undefined;
  fullName: string | null | undefined;
  email: string | null | undefined;
  role: string | null;
  verified: boolean;
  isInnovator?: boolean | null;
  isDisruptor?: boolean | null | undefined;
  intercomId: any;
  onboardingStatus: IProcessStatusType | null | undefined;
};

interface IUserTableNameProps {
  user: TUserTableNameData;
  filter: string | null;
  showEmail?: boolean;
  showIntercomLinks?: boolean;
  showUserType?: boolean;
}

export default function UserTableName({
  user,
  filter,
  showEmail,
  showIntercomLinks,
  showUserType,
}: IUserTableNameProps) {
  return (
    <>
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link to={`/users/${user.slug}`}>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ProgressiveImage src={user.avatar!} placeholder="">
              {(src: string, loading: boolean) => (
                <ProfilePicture
                  image={loading ? null : src}
                  innovator={user.__typename === "GovernmentUser" ? user.isInnovator : false}
                  disruptor={user.__typename === "VendorUser" ? user.isDisruptor : false}
                  name={user.fullName}
                  size={32}
                  css={{
                    whiteSpace: "nowrap",
                  }}
                />
              )}
            </ProgressiveImage>

            <Spacing left={10}>{user.fullName}</Spacing>

            {user.__typename === "PendingUser" && user.verified && (
              <Spacing
                left={5}
                inline={true}
                flex={true}
                style={{
                  color: "#48A0D9",
                }}
              >
                <CheckIcon />
              </Spacing>
            )}
          </div>
        </Link>

        {user.intercomId && (["production"].includes(process.env.GOVLAUNCH_ENV as string) || showIntercomLinks) && (
          <Spacing
            left={12}
            inline={true}
            flex={true}
            style={{
              color: "#48A0D9",
            }}
          >
            <a
              href={`https://app.intercom.com/a/apps/${process.env.INTERCOM_APP_ID}/users/${user.intercomId}/all-conversations`}
              target="_blank"
              rel="noopener noreferrer"
              css={{
                display: "inline-flex",
              }}
            >
              <IntercomIcon width={16} height={16} />
            </a>
          </Spacing>
        )}
        {filter === "PENDING_USERS" &&
          (user.onboardingStatus === IProcessStatusType.PendingVerification ||
            user.onboardingStatus === IProcessStatusType.AdditionalInformationRequired) && (
            <div
              css={{
                justifySelf: "flex-end",
                paddingTop: "2px",
              }}
            >
              <Link to={`/users/${user.slug}/onboarding`} title="Onboarding profile">
                <OnboardingProfileIcon fillColor="#1890ff" />
              </Link>
            </div>
          )}
      </div>
      {showUserType && (
        <div
          css={{
            display: "flex",
            justifyContent: "flex-start",
            fontSize: 12,
            fontStyle: "italic",
            color: palette.mediumGray,
            marginTop: 5,
          }}
        >
          {getUserType(user.role || "", user.verified)}
        </div>
      )}
      <div
        css={{
          display: "flex",
          justifyContent: "flex-start",
          fontSize: 12,
          fontStyle: "italic",
          color: palette.mediumGray,
          marginTop: 5,
        }}
      >
        {user._id}
      </div>
      {showEmail && (
        <div
          css={{
            display: "flex",
            justifyContent: "flex-start",
            fontSize: 12,
            fontStyle: "italic",
            color: palette.mediumGray,
            marginTop: 5,
          }}
        >
          {user.email}
        </div>
      )}
    </>
  );
}

const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
