import gql from "graphql-tag";

export default gql`
  mutation ToggleArchiveOnCollection($collectionId: ObjectId!) {
    toggleArchiveOnCollection(collectionId: $collectionId) {
      _id
      status
    }
  }
`;
