import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, notification } from "antd";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Mutation } from "react-apollo";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import { IUserSearchScope } from "../../../../types/types";
import EditableGroupMembers from "../EditableGroupMembers";
import EditGroupMembers from "../graphql/mutations/EditGroupMembers";
import {
  IEditGroupMembersMutation,
  IEditGroupMembersMutationVariables,
} from "../graphql/mutations/__generated__/EditGroupMembers.generated";
import { TGroup, TGroupMember } from "../types";
import UserAutoComplete from "../UserAutoComplete";

interface IEditGroupMembersModalProps {
  visible: boolean;
  group: TGroup;
  initialMemberAdditions?: TGroupMember[];
  displayBackButton?: boolean;
  onSaveChanges?: () => void;
  onRequestClose?: () => void;
  onRequestBack?: () => void;
}

export default function EditGroupMembersModal({
  visible,
  group,
  displayBackButton,
  initialMemberAdditions = [],
  onSaveChanges,
  onRequestClose,
  onRequestBack,
}: IEditGroupMembersModalProps) {
  const linkToGroup = (
    <Link to={`/groups/${group.slug}/members`}>
      <strong>{group.name}</strong>
    </Link>
  );

  const title = <>Manage {linkToGroup} members</>;

  const members: TGroupMember[] = group.members ? group.members.items || [] : [];

  const safeInitialMembers = members.concat(
    initialMemberAdditions.filter((candidate) => {
      return !members.some((member) => {
        return member._id === candidate._id;
      });
    }),
  );

  return (
    <Mutation<IEditGroupMembersMutation, IEditGroupMembersMutationVariables>
      mutation={EditGroupMembers}
      refetchQueries={[]}
      awaitRefetchQueries={true}
    >
      {(editGroupMembers) => (
        <Form
          initialValues={{
            members: safeInitialMembers,
          }}
          onSubmit={async ({ members }) => {
            try {
              await editGroupMembers({
                variables: {
                  groupId: group._id,
                  members: members.map((member: any) => member._id),
                },
              });

              if (onSaveChanges) {
                onSaveChanges();
              }

              notification.open({
                type: "success",
                message: "Success!",
                description: <>Members of <strong>{group.name}</strong> has been modified</>,
              });
            } catch (err) {
              return {
                [FORM_ERROR]: err.message,
              };
            }
          }}
        >
          {({ submitting, submitError, handleSubmit }) => (
            <Modal width={720} title={title} visible={visible} onCancel={onRequestClose} footer={null}>
              <form
                onSubmit={handleSubmit}
                css={{
                  display: "grid",
                  gridRowGap: 24,
                }}
              >
                <Field<TGroupMember[]> name="members">
                  {({ input }) => (
                    <UserAutoComplete
                      scopes={[
                        IUserSearchScope.Community,
                        IUserSearchScope.Vendor,
                        IUserSearchScope.Government,
                        IUserSearchScope.Citizen,
                      ]}
                      onSelect={(selectedUser) => {
                        const alreadyInMembers = input.value.some((candidate) => {
                          return candidate._id === selectedUser._id;
                        });

                        if (!alreadyInMembers) {
                          input.onChange(input.value.concat(selectedUser));
                        }
                      }}
                    />
                  )}
                </Field>

                <EditableGroupMembers group={group} />

                {submitError && (
                  <Alert
                    message={<strong>Couldn't save changes</strong>}
                    description={`There was an error when saving the changes: ${submitError
                      .replace("GraphQL error:", "")
                      .trim()}`}
                    type="error"
                    closable={true}
                  />
                )}

                <div
                  css={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  {displayBackButton ? (
                    <div
                      css={{
                        textAlign: "left",
                      }}
                    >
                      <Button
                        key="submit"
                        type="dashed"
                        size="large"
                        icon={<ArrowLeftOutlined />}
                        onClick={() => {
                          if (onRequestBack) {
                            onRequestBack();
                          } else if (onRequestClose) {
                            onRequestClose();
                          }
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  ) : (
                    <div />
                  )}

                  <div
                    css={{
                      textAlign: "right",
                    }}
                  >
                    <Button
                      key="submit"
                      type="primary"
                      size="large"
                      icon={<SaveOutlined />}
                      htmlType="submit"
                      loading={submitting}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Modal>
          )}
        </Form>
      )}
    </Mutation>
  );
}
