import gql from "graphql-tag";

export default gql`
  query getTagBySlug($slug: String!) {
    tag(slug: $slug) {
      _id
      name
      slug
      types
      relatedTags {
        _id
        name
      }
      aggregations {
        productsCount
        projectsCount
        storiesCount
        mentionsCount
        awardsCount
        usersCount
      }
    }
  }
`;
