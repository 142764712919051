import gql from "graphql-tag";

export default gql`
  mutation ToggleFeaturedProduct($targetId: ObjectId!) {
    toggleFeatured(targetType: PRODUCT, targetId: $targetId) {
      ... on Product {
        _id
        featuredAt
      }
    }
  }
`;
