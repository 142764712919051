import { CSSReset, ThemeProvider, theme } from "@govlaunch/web";
import { notification } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import { Form } from "react-final-form";
import EditLandingPageConfig from "../../mutations/EditLandingPageConfig";
import {
  IEditLandingPageConfigMutation,
  IEditLandingPageConfigMutationVariables,
} from "../../mutations/__generated__/EditLandingPageConfig.generated";
import validate from "../../validate";
import LandingPageForm from "./LandingPageForm";
import landingPageValidationSchema from "./landingPageValidationSchema";
import { convertValuesToInitialVariables, convertValuesToVariables, TDbTemplate } from "./utils";

interface IEditLandingPageFormProps {
  landingPageConfig: TDbTemplate;
}

export default function EditLandingPageForm({ landingPageConfig }: IEditLandingPageFormProps) {
  const [editLandingPageForm] = useMutation<IEditLandingPageConfigMutation, IEditLandingPageConfigMutationVariables>(
    EditLandingPageConfig,
  );

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Form
        keepDirtyOnReinitialize={true}
        initialValues={convertValuesToInitialVariables(landingPageConfig)}
        validate={validate(landingPageValidationSchema)}
        onSubmit={async (values) => {
          const sanitizedValues = convertValuesToVariables(values);
          const result = await editLandingPageForm({
            variables: {
              id: landingPageConfig._id,
              landingPageConfig: sanitizedValues,
            },
          });

          if (result && result.data && result.data.editLandingPageConfig && result.data.editLandingPageConfig._id) {
            notification.success({
              message: "Success!",
              description: "Landing page config saved successfully",
            });
          }
        }}
        subscription={{
          submitting: true,
          submitError: true,
          values: true,
        }}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <LandingPageForm
            onSubmit={handleSubmit}
            submitting={submitting}
            submitError={submitError}
            configId={landingPageConfig._id}
          />
        )}
      </Form>
    </ThemeProvider>
  );
}
