import { DatePicker, Input, Modal, Radio, Select, Typography } from "antd";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import {
  ICollectionSponsoringPaymentSchedule,
  ICollectionSponsoringSponsorshipLevel,
  ICollectionSponsoringType,
  ICreateSaleInput,
} from "../../../types/types";
import uniqBy from "lodash/fp/uniqBy";
import CurrencyInput from "../billing/CurrencyInput";
import AutoCompleteVendorsQuery from "./graphql/queries/AutoCompleteVendorsQuery";
import { ICollectionSponsoringSaleFieldsFragment } from "./graphql/queries/fragments/__generated__/CollectionSponsoringSaleFields.generated";
import AutoCompleteCollectionsQuery from "./graphql/queries/__generated__/AutoCompleteCollectionsQuery";
import {
  IAutoCompletePaidVendorsQuery,
  IAutoCompletePaidVendorsQueryVariables,
} from "./graphql/queries/__generated__/AutoCompleteVendorsQuery.generated";
import {
  IAutoCompleteCollectionsQuery,
  IAutoCompleteCollectionsQueryVariables,
} from "./graphql/queries/__generated__/__generated__/AutoCompleteCollectionsQuery.generated";

interface ICollectionFormProps {
  sponsoring?: Partial<ICollectionSponsoringSaleFieldsFragment>;
  isOpen: boolean;
  onSubmit: (sponsoring: any) => void;
  onClose: () => void;
  error?: string;
  submitting?: boolean;
}

export default function CollectionSponsoringForm({
  sponsoring,
  onSubmit,
  isOpen,
  onClose,
  error,
  submitting = false,
}: ICollectionFormProps) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const onSearch = debounce((searchText) => setSearchQuery(searchText), 300);
  const { data } = useQuery<IAutoCompletePaidVendorsQuery, IAutoCompletePaidVendorsQueryVariables>(
    AutoCompleteVendorsQuery,
    {
      variables: {
        searchQuery,
      },
    },
  );

  const isEditing = !!sponsoring?._id;

  const [form] = useForm();
  const [visible, setVisible] = useState(false);
  const initialValues = getInitialValues(sponsoring);

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      form.setFieldsValue(initialValues);
    } else {
      setVisible(false);
    }
  }, [isOpen]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={sponsoring ? "Update Sponsorship" : "New Sponsorship"}
      destroyOnClose={true}
      okText="Save"
      okButtonProps={{
        onClick: form.submit,
        disabled: submitting,
      }}
      afterClose={() => form.resetFields()}
    >
      <Form
        onFinish={() => {
          const values = form.getFieldsValue(true);

          onSubmit(values);
        }}
        form={form}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 14,
        }}
      >
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="vendorId"
          label="Vendor"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select showSearch={true} filterOption={false} onSearch={onSearch} allowClear={true} disabled={isEditing}>
            {(data?.autoCompletePaidOrDisruptorVendors || [])
              .concat(sponsoring?.vendor || [])
              .map(({ _id, name }) => ({
                _id,
                name,
              }))
              .map((vendor) => (
                <Select.Option value={vendor._id} title={vendor.name} key={vendor._id}>
                  {vendor.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name="type" label="Sponsoring Type">
          <Radio.Group>
            <Radio.Button value={ICollectionSponsoringType.Curated}>Curated</Radio.Button>
            <Radio.Button value={ICollectionSponsoringType.Sponsored}>Custom Collection</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="sponsorshipLevel"
          label="Level"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group>
            <Radio.Button value={ICollectionSponsoringSponsorshipLevel.Vendor}>Vendor</Radio.Button>
            <Radio.Button value={ICollectionSponsoringSponsorshipLevel.Product}>Product</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="paymentSchedule" label="Payment">
          <Select disabled={isEditing}>
            <Select.Option value={ICollectionSponsoringPaymentSchedule.LumpSum}>Lump Sum</Select.Option>
            <Select.Option value={ICollectionSponsoringPaymentSchedule.Monthly}>Monthly</Select.Option>
            <Select.Option value={ICollectionSponsoringPaymentSchedule.Quarterly}>Quarterly</Select.Option>
            <Select.Option value={ICollectionSponsoringPaymentSchedule.Yearly}>Yearly</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Price" shouldUpdate={(prevValues, nextValues) => prevValues.price !== nextValues.price}>
          {({ getFieldValue, setFieldsValue }) => (
            <CurrencyInput
              disabled={isEditing}
              value={getFieldValue("price")}
              onValueChange={(amount) => {
                setFieldsValue({
                  price: amount,
                });
              }}
            />
          )}
        </Form.Item>

        <Form.Item
          shouldUpdate={(prevValues, nextValues) => prevValues.paymentSchedule !== nextValues.paymentSchedule}
          noStyle={true}
        >
          {({ getFieldValue }) => {
            if (getFieldValue("paymentSchedule") !== ICollectionSponsoringPaymentSchedule.LumpSum) {
              return null;
            }

            return (
              <Form.Item
                name="expirationDate"
                label="Expiration"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabled={isEditing} format="YYYY-MM-DD" />
              </Form.Item>
            );
          }}
        </Form.Item>

        <IncludedCollectionsField sponsoring={sponsoring} />
      </Form>

      {error && <Typography.Text type="danger">{error}</Typography.Text>}
    </Modal>
  );
}

interface IIncludedCollectionsFieldProps {
  sponsoring?: Partial<ICollectionSponsoringSaleFieldsFragment>;
}

function IncludedCollectionsField({ sponsoring }: IIncludedCollectionsFieldProps) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const onSearch = debounce((searchText) => setSearchQuery(searchText), 300);
  const { data: collectionsData } = useQuery<IAutoCompleteCollectionsQuery, IAutoCompleteCollectionsQueryVariables>(
    AutoCompleteCollectionsQuery,
    {
      fetchPolicy: "network-only",
      variables: {
        searchQuery,
      },
    },
  );

  return (
    <Form.Item
      name="includedCollections"
      label="Collection"
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Select filterOption={false} onSearch={onSearch} showSearch={true}>
        {uniqBy(
          "_id",
          (collectionsData?.autoCompleteCollections || [])
            .map(({ _id, name }) => ({
              _id: _id,
              name,
            }))
            .concat(sponsoring?.includedCollections || []),
        ).map((collection) => (
          <Select.Option value={collection._id} title={collection.name} key={collection._id}>
            {collection.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

type FormInitialValues = Omit<ICreateSaleInput, "includedCollections"> & {
  includedCollections: string | null;
};

function getInitialValues(sponsoring: ICollectionFormProps["sponsoring"]): Partial<FormInitialValues> {
  if (!sponsoring) {
    return {
      description: "",
      includedCollections: null,
      type: ICollectionSponsoringType.Curated,
      paymentSchedule: undefined,
      price: 0,
    };
  }

  return {
    description: sponsoring.description,
    includedCollections: (sponsoring?.includedCollections || [])[0]?._id || null,
    type: sponsoring.type,
    vendorId: sponsoring?.vendor?._id,
    price: sponsoring.price,
    sponsorshipLevel: sponsoring.sponsorshipLevel,
    paymentSchedule: sponsoring.paymentSchedule,
    expirationDate: sponsoring.__typename === "LumpSumCollectionSponsoringSale" ? sponsoring.expirationDate : null,
  };
}
