import gql from "graphql-tag";

export default gql`
  query GetSponsorship($slug: String!) {
    sponsorship(slug: $slug) {
      _id
      slug
      name
      logo
      link
    }
  }
`;
