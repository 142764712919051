import gql from "graphql-tag";
import { StoryFieldsFragment } from "@govlaunch/story";

export default gql`
  query StoryBySlug($slug: String!) {
    storyBySlug(slug: $slug) {
      ...StoryFields
      audience
      viewerCanComment
      collaborators {
        ... on PendingUser {
          _id
          firstName
          fullName
          slug
          avatar
        }
        ... on GovernmentUser {
          _id
          firstName
          fullName
          slug
          avatar
          government {
            _id
            name
            slug
            logo
          }
        }
        ... on VendorUser {
          _id
          firstName
          fullName
          slug
          avatar
          company {
            _id
            name
            slug
            logo
          }
        }
      }
    }
  }

  ${StoryFieldsFragment}
`;
