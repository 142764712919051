import { faClock } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import DocumentTitle from "react-document-title";
import { useLocation } from "react-router";
import TablePageHeader from "../../components/table/TablePageHeader";
import TableSearch from "../../components/table/TableSearch";
import queryString from "query-string";
import ManageTimezones from "./ManageTimezones";

export default function Timezones() {
  return (
    <DocumentTitle title="Timezones | Admin">
      <>
        <TablePageHeader title="Timezones" titleIcon={faClock} subTitle="All Timezones" />
        <div
          css={{
            padding: 20,
          }}
        >
          <TimezonesWithSearch />
        </div>
      </>
    </DocumentTitle>
  );
}

function TimezonesWithSearch() {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div css={{
 marginBottom: 20 
}}
      >
        <TableSearch historyOrigin="/newsletter/timezones" query={query} inputPlaceholder="Search for timezones..." />
      </div>

      <ManageTimezones historyOrigin="/newsletter/timezones" search={query.search as string} />
    </>
  );
}
