import { Button, notification } from "antd";
import gql from "graphql-tag";
import React from "react";
import { useMutation } from "react-apollo";
import {
  IRunCollectionContentBreakdownUpdateMutation,
  IRunCollectionContentBreakdownUpdateMutationVariables,
} from "../../mutations/__generated__/RunCollectionContentBreakdownUpdate.generated";

const enrchMakersVendorProductsMutation = gql`
  mutation EnrchMakersVendorProductsMutation {
    enrichMakersVendorProducts
  }
`;

export default function EnrichMakersProductsButton() {
  const [enrichMakersVendorProducts, { loading }] = useMutation<
    IRunCollectionContentBreakdownUpdateMutation,
    IRunCollectionContentBreakdownUpdateMutationVariables
  >(enrchMakersVendorProductsMutation);

  return (
    <Button
      loading={loading}
      onClick={() =>
        enrichMakersVendorProducts().then(() =>
          notification.success({
            message: "Success!",
            description: "Makers products enriched successfully!",
          }),
        )
      }
    >
      Enrich Makers Products
    </Button>
  );
}
