import PropTypes from "prop-types";
import { Query } from "react-apollo";
import React from "react";
import ProjectsCountQuery from "../../queries/ProjectsCount";
import { IProjectsCountQuery, IProjectsCountQueryVariables } from "../../queries/__generated__/ProjectsCount.generated";
import mapCounts from "../../utils/mapCounts";

interface IProjectsCountProps {
  children: any;
}

export default function ProjectsCount({ children }: IProjectsCountProps) {
  return (
    <Query<IProjectsCountQuery, IProjectsCountQueryVariables> query={ProjectsCountQuery} fetchPolicy="network-only">
      {({ data, loading, refetch }) => {
        if (!data || loading) {
          return null;
        }

        return children({
          ...mapCounts(data.projectsCount, loading),
          loading,
          refetch,
        });
      }}
    </Query>
  );
}

ProjectsCount.propTypes = {
  children: PropTypes.func.isRequired,
};
