import { Spacing } from "@govlaunch/spacers";
import { Button, Modal, Select } from "antd";
import { Strong } from "glamorous";
import React, { ReactElement, useState } from "react";
import { Mutation } from "react-apollo";
import { Form as FinalForm } from "react-final-form";
import { Toggle } from "react-powerplug";
import { IUser } from "../../../types/types";
import VerifyCitizenUser from "../../mutations/VerifyCitizenUser";
import VerifyUserMutation from "../../mutations/VerifyUser";
import {
  IVerifyCitizenMutation,
  IVerifyCitizenMutationVariables,
} from "../../mutations/__generated__/VerifyCitizenUser.generated";
import { IVerifyMutation, IVerifyMutationVariables } from "../../mutations/__generated__/VerifyUser.generated";
import VerificationForm from "./VerificationForm";

interface IVerifyUserProps {
  user: Pick<IUser, "_id" | "email" | "fullName" | "linkedinUrl" | "linkedinId">;
  onVerificationComplete?: () => void;
  children: (toggle: Function) => ReactElement | null;
}

export enum VerificationType {
  Community = "COMMUNITY",
  Citizen = "CITIZEN",
}

export default function VerifyUser({ user, onVerificationComplete, children }: IVerifyUserProps) {
  const [verificationType, setVerificationType] = useState(VerificationType.Community);

  return (
    <Mutation<IVerifyMutation, IVerifyMutationVariables> mutation={VerifyUserMutation}>
      {(verify) => (
        <Toggle initial={false}>
          {({ on, toggle }) => (
            <>
              <FinalForm
                onSubmit={async (values) => {
                  await verify({
                    variables: {
                      userId: user._id,
                      explanation: values.explanation,
                      attachments: values.attachments,
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: ["AllUsers", "UsersCount", "ListOnboardings", "OnboardingsCount"],
                  });

                  if (onVerificationComplete && typeof onVerificationComplete === "function") {
                    onVerificationComplete();
                  }

                  //toggle();
                }}
                initialValues={{
                  explanation: "",
                  attachments: [],
                }}
              >
                {({ submitting, handleSubmit }) => (
                  <Modal
                    title={`Verify ${user.fullName}`}
                    visible={on}
                    onCancel={toggle}
                    footer={[
                      <Button key="back" onClick={toggle}>
                        Cancel
                      </Button>,

                      verificationType !== VerificationType.Citizen && (
                        <Button key="submit" type="primary" loading={submitting} onClick={handleSubmit}>
                          {submitting ? "Please wait..." : "Verify"}
                        </Button>
                      ),
                      verificationType === VerificationType.Citizen && (
                        <Mutation<IVerifyCitizenMutation, IVerifyCitizenMutationVariables>
                          mutation={VerifyCitizenUser}
                          variables={{
                            userId: user._id,
                          }}
                          onCompleted={() => {
                            if (onVerificationComplete) {
                              onVerificationComplete();
                            }

                            toggle();
                          }}
                          key="markAsCitizenMutation"
                        >
                          {(verifyCitizen) => (
                            <Button
                              key="markAsCitizen"
                              type="primary"
                              loading={submitting}
                              onClick={async () => {
                                await verifyCitizen({
                                  variables: {
                                    userId: user._id,
                                  },
                                  awaitRefetchQueries: true,
                                  refetchQueries: ["AllUsers", "UsersCount", "ListOnboardings", "OnboardingsCount"],
                                });
                              }}
                            >
                              {submitting ? "Please wait..." : "Mark as Citizen"}
                            </Button>
                          )}
                        </Mutation>
                      ),
                    ]}
                  >
                    <VerificationForm user={user} verificationType={verificationType} onSubmit={handleSubmit} />

                    <Spacing top={10}>
                      <Strong>Verification Type</Strong>
                      <div
                        style={{
                          marginTop: 10,
                        }}
                      >
                        <Select
                          style={{
                            width: 200,
                          }}
                          value={verificationType}
                          defaultValue={verificationType}
                          onChange={(value) => {
                            setVerificationType(value as VerificationType);
                          }}
                          key="VerificationTypeSelect"
                        >
                          <Select.Option
                            value={VerificationType.Community}
                            title="Community"
                            key={VerificationType.Community}
                          >
                            Community verification
                          </Select.Option>
                          <Select.Option
                            value={VerificationType.Citizen}
                            title="Citizen"
                            key={VerificationType.Citizen}
                          >
                            Citizen verification
                          </Select.Option>
                        </Select>
                      </div>
                    </Spacing>
                  </Modal>
                )}
              </FinalForm>

              {children(toggle)}
            </>
          )}
        </Toggle>
      )}
    </Mutation>
  );
}
