import { Button, message, notification, Select } from "antd";
import React, { useState } from "react";
import { ICollectionMethod } from "../../../../types/types";
import * as palette from "@govlaunch/palette";
import { useMutation } from "react-apollo";
import {
  IUpdateCollectionMethodMutation,
  IUpdateCollectionMethodMutationVariables,
} from "../../../mutations/__generated__/UpdateCollectionMethod.generated";
import UpdateCollectionMethod from "../../../mutations/UpdateCollectionMethod";

interface ICollectionMethodComboProps {
  subscriptionId: any;
  collectionMethod: string;
}

const SELECT_OPTIONS = [
  {
    label: "N/A",
    value: "unknown",
  },
  {
    label: "Charge Automatically",
    value: ICollectionMethod.ChargeAutomatically,
  },
  {
    label: "Send Invoice",
    value: ICollectionMethod.SendInvoice,
  },
];

export default function CollectionMethodCombo({ subscriptionId, collectionMethod }: ICollectionMethodComboProps) {
  const [state, setState] = useState<string>(collectionMethod);

  const [updateCollectionMethod, { loading }] = useMutation<
    IUpdateCollectionMethodMutation,
    IUpdateCollectionMethodMutationVariables
  >(UpdateCollectionMethod, {
    variables: {
      subscriptionId: subscriptionId,
      collectionMethod: state === "unknown" ? null : state,
    },
    refetchQueries: ["CompanyAccountBillingInfo"],
  });

  const showButton = state !== collectionMethod && state !== null && state !== "unknown";

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Select
        value={state}
        options={SELECT_OPTIONS}
        style={{
          borderRadius: 5,
          border: `1px solid ${palette.lightestGray}`,
          maxWidth: 200,
          width: "100%",
        }}
        onChange={(value) => {
          setState(value);
        }}
      />
      {showButton && (
        <Button
          loading={loading}
          style={{
            marginLeft: 8,
          }}
          onClick={async () => {
            if (state === "unknown" || state === null) {
              message.warning("Cannot set collection method to N/A");
            }

            const result = await updateCollectionMethod();

            if (result && result.data && result.data.updateCollectionMethod === true) {
              notification.success({
                message: "Success!",
                description: "Collection method changed successfully",
              });
            } else {
              notification.error({
                message: "Operation failed!",
                description: "Unable to change collection method",
              });
            }
          }}
        >
          Update
        </Button>
      )}
    </div>
  );
}
