import React, { ReactElement } from "react";

interface IFormGridProps {
  children: ReactElement | ReactElement[];
}

export default function FormGrid({ children }: IFormGridProps) {
  return (
    <div
      css={{
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 30,
        maxWidth: 1140,
      }}
    >
      {children}
    </div>
  );
}
