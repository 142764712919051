import React from "react";
import { Field } from "react-final-form";
import { ISelectFieldProps } from "./FormFieldProps";
import * as palette from "@govlaunch/palette";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { Select } from "antd";

export default function SelectField({
  fieldName,
  label,
  placeholder,
  headerColor,
  disabled,
  options,
  defaultValue,
  ...props
}: ISelectFieldProps) {
  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {label && (
              <label
                css={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: headerColor || palette.mediumGray,
                  margin: 0,
                }}
              >
                {label}
              </label>
            )}

            <FieldValidationError meta={meta} />
          </div>

          <div
            css={{
              marginTop: 8,
              borderRadius: 5,
              border: `1px solid ${palette.lightestGray}`,
              ":focus-within": {
                borderColor: palette.primary,
              },
            }}
          >
            <Select
              defaultValue={input.value || defaultValue}
              value={input.value}
              style={{
                borderRadius: 5,
                fontSize: 14,
              }}
              onChange={input.onChange}
              size="large"
              bordered={false}
              placeholder={placeholder}
              {...props}
              disabled={disabled}
            >
              {options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      )}
    </Field>
  );
}
