import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import * as palette from "@govlaunch/palette";
import HeaderBannerFieldModal from "./HeaderBannerFieldModal";
import EditBlockButton from "../blockslayout/EditBlockButton";
import { IHeaderBannerData } from "./headerBannerProps";

interface IHeaderBannerFieldModalButtonProps {
  onSave: (arg: IHeaderBannerData) => void;
  currentData: IHeaderBannerData;
  toolbar?: boolean;
}

export default function HeaderBannerFieldModalButton({
  onSave,
  currentData,
  toolbar,
}: IHeaderBannerFieldModalButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      {toolbar && <EditBlockButton onClick={() => setIsOpen(true)} />}
      {!toolbar && <ButtonImage onClick={() => setIsOpen(true)} />}
      <HeaderBannerFieldModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSave={onSave}
        currentData={currentData}
        key={JSON.stringify(currentData) + new Date().toString()}
      />
    </div>
  );
}

interface IButtonImageProps {
  onClick: any;
}

function ButtonImage({ onClick }: IButtonImageProps) {
  return (
    <div
      css={{
        borderRadius: 4,
        height: "100%",
        width: "100%",
      }}
    >
      <div
        css={{
          width: "100px",
          height: "100px",
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            opacity: 0.8,
          },
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={faFileAlt}
          color={palette.primary}
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <div
          css={{
            marginTop: 8,
            fontSize: 12,
            color: palette.primary,
          }}
        >
          Create Content
        </div>
      </div>
    </div>
  );
}
