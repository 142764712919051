import ProfilePicture from "@govlaunch/profile-picture";
import { AutoComplete, Input } from "antd";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import { Query } from "react-apollo";
import AutoCompleteVendors from "./graphql/queries/AutoCompleteVendors";
import {
  IAutoCompleteVendorsQuery,
  IAutoCompleteVendorsQueryVariables,
} from "./graphql/queries/__generated__/AutoCompleteVendors.generated";

export type TSelectedVendor = NonNullable<IAutoCompleteVendorsQuery["autoCompleteVendors"]>[0];

interface IVendorAutoCompleteProps {
  onSelect: (selectedItem: TSelectedVendor) => void;
}

export default function VendorAutoComplete({ onSelect }: IVendorAutoCompleteProps) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");

  const onSearch = debounce((searchText) => setSearchQuery(searchText), 300);

  return (
    <Query<IAutoCompleteVendorsQuery, IAutoCompleteVendorsQueryVariables>
      query={AutoCompleteVendors}
      notifyOnNetworkStatusChange={true}
      fetchPolicy="network-only"
      skip={searchQuery === ""}
      variables={{
        searchQuery,
        limit: 8,
      }}
    >
      {({ loading, data }) => {
        const searchResultsArray = !data || !data.autoCompleteVendors ? [] : data.autoCompleteVendors;

        const options = searchResultsArray.map((item) => {
          return {
            value: item._id,
            label: (
              <div
                css={{
                  display: "inline-grid",
                  gridColumnGap: 12,
                  gridTemplateColumns: "repeat(2, max-content)",
                  alignItems: "center",
                }}
              >
                <ProfilePicture size={32} image={item.logo} name={item.name} />

                <div>
                  <div>
                    <strong>{item.name}</strong>
                  </div>
                </div>
              </div>
            ),
          };
        });

        return (
          <AutoComplete
            options={searchQuery === "" ? [] : options}
            onSearch={onSearch}
            onSelect={(selectedItem: any) => {
              const match = searchResultsArray.find((candidate) => {
                return candidate._id === selectedItem;
              });

              if (match) {
                onSelect(match);
                setSearchQuery("");
                setInputValue("");
              }
            }}
            value={inputValue}
          >
            <Input.Search
              size="large"
              spellCheck={false}
              loading={loading}
              placeholder="Type to search vendors..."
              onChange={(e) => setInputValue(e.target.value)}
            />
          </AutoComplete>
        );
      }}
    </Query>
  );
}
