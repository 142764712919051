import ProfilePicture from "@govlaunch/profile-picture";
import gql from "graphql-tag";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { IViewerSpanFieldsFragment } from "./__generated__/ViewerSpan.generated";

export const ViewerSpanQueryFields = gql`
  fragment ViewerSpanFields on UserProfile {
    _id
    firstName
    lastName
    fullName
    avatar
    slug
  }
`;

interface IViewerSpanProps {
  viewer: IViewerSpanFieldsFragment;
}

export default function ViewerSpan({ viewer }: IViewerSpanProps) {
  return (
    <Link to={`/users/${viewer.slug}`}>
      <div
        key={viewer._id}
        css={{
          display: "grid",
          gridRowGap: 0,
          gridColumnGap: 10,
          gridTemplateAreas: viewer.avatar ? '"logo name"' : '"name"',
          gridTemplateColumns: "auto 1fr",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {viewer.avatar && (
          <div
            css={{
              display: "inline-flex",
              gridArea: "logo",
              alignItems: "center",
            }}
          >
            <ProgressiveImage src={viewer.avatar || ""} placeholder="">
              {(src: string) => <ProfilePicture name={viewer.avatar} image={src} size={32} />}
            </ProgressiveImage>
          </div>
        )}

        <a>{viewer.fullName}</a>
      </div>
    </Link>
  );
}
