import React from "react";
import fp from "lodash/fp";
import * as palette from "@govlaunch/palette";
import { Table } from "antd";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import moment from "moment";
import { ISignInAttempt } from "../../../types/types";

interface ISignInAttemptsTableProps {
  attempts: ISignInAttempt[];
}

const SignInAttemptsTable = ({ attempts }: ISignInAttemptsTableProps) => (
  <Table
    rowKey="registeredAt"
    dataSource={attempts}
    pagination={false}
    size="middle"
    style={{
      backgroundColor: palette.white,
    }}
    columns={[
      {
        title: "Outcome",
        dataIndex: "outcome",
        render: (outcome) => {
          if (outcome === "succeeded") {
            return <span>✅ Succeeded</span>;
          } else if (outcome === "signedUp") {
            return <span>🎉 Signed Up</span>;
          } else if (outcome === "failed") {
            return <span>⁉️ Failed</span>;
          } else {
            return "Unknown";
          }
        },
      },
      {
        title: "Browser",
        dataIndex: "userAgent",
        render: (userAgent) => {
          if (fp.has("browser.name", userAgent)) {
            return `${userAgent.browser.name} ${userAgent.browser.version}`;
          } else {
            return "Unknown";
          }
        },
      },
      {
        title: "Location",
        render: (attempt) => {
          const hasFancyLocationData = fp.has("lookup.location.country_flag", attempt);

          if (hasFancyLocationData) {
            return (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ProfilePicture
                  squared={true}
                  size={32}
                  name={attempt.lookup.country_code}
                  image={attempt.lookup.location.country_flag}
                />

                <Spacing left={10}>
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      css={{
                        fontSize: 14,
                      }}
                    >
                      {attempt.lookup.city}, {attempt.lookup.region_name} &mdash; {attempt.lookup.country_name}
                    </span>
                    <span
                      css={{
                        fontSize: 10,
                      }}
                    >
                      {attempt.ip}
                    </span>
                  </div>
                </Spacing>
              </div>
            );
          } else {
            return attempt.ip || "Unknown";
          }
        },
      },
      {
        title: "When",
        dataIndex: "registeredAt",
        render: (registeredAt) => moment(registeredAt).format("MM/DD/Y"),
      },
    ]}
  />
);

export default SignInAttemptsTable;
