import React from "react";
import DocumentTitle from "react-document-title";
import * as palette from "@govlaunch/palette";
import NewCollectionForm from "./NewCollectionForm";

export default function NewCollectionPage() {
  return (
    <DocumentTitle title="New Collection | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
          overflow: "auto"
        }}
      >
        <NewCollectionForm />
      </div>
    </DocumentTitle>
  );
}
