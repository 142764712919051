import { IProcessStatusType } from "../../../../types/types";
/* eslint-disable react/display-name */
import queryString from "query-string";
import React, { useState } from "react";
import { useQuery } from "react-apollo";
import ListOnboardingsQuery from "../../../queries/ListOnboardingsQuery";
import {
  IListOnboardingsQuery,
  IListOnboardingsQueryVariables,
} from "../../../queries/__generated__/ListOnboardingsQuery.generated";
import OnboardingsTable from "../OnboardingsTable";
import TableAddStateToQueryString from "../../../components/table/TableAddStateToQueryString";
import { parseState } from "../../../utils/tablesUtils";
import { useLocation, useNavigate } from "react-router";
import { SorterResult } from "antd/lib/table/interface";
import TablePagination, { DEFAULT_CURSOR, ITablePaginationState } from "../../../components/table/TablePagination";
import TableTitle from "../../../components/table/TableTitle";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import TableOnSearch from "../../../components/table/TableOnSearch";

export interface IOnboardingsTableState {
  sorter?: SorterResult<any>;
  cursor: ITablePaginationState;
}

interface IManageOnboardingsProps {
  filterByProcessStatus?: IProcessStatusType | null;
  search: string | null;
}

export default function ManageOnboardings({ filterByProcessStatus, search }: IManageOnboardingsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<IOnboardingsTableState>(initialFiltersState);

  const { data, loading, refetch } = useQuery<IListOnboardingsQuery, IListOnboardingsQueryVariables>(
    ListOnboardingsQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        filterByProcessStatus,
        search,
        sortBy: initialFiltersState.sorter ? getSortBy(initialFiltersState.sorter) : "",
        cursor: calculateAndEncodeCursor(state.cursor),
      },
    },
  );

  const users = (data && data.listOnboardings && data.listOnboardings.items) || [];
  const totalCount =
    data && data.listOnboardings && data.listOnboardings.pageInfo ? data.listOnboardings.pageInfo.totalCount || 0 : 0;
  const historyOrigin = "/onboardings";

  return (
    <>
      <TableAddStateToQueryString tableState={state} historyOrigin={historyOrigin} query={query} />
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
 page: 1, size: state.cursor.size 
},
            });
          }
        }}
      />
      <OnboardingsTable
        loading={loading}
        onboardings={users}
        title={() => (
          <TableTitle
            title="Onboardings"
            count={totalCount}
            loading={loading}
            onResetFilters={() => {
              setState({
                sorter: undefined,
                cursor: {
 page: 1, size: state.cursor.size 
},
              });

              navigate(`${historyOrigin}?tab=${query.tab || ""}&search=${search}`);
            }}
          />
        )}
        footer={() => (
          <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
        )}
        filterByProcessStatus={filterByProcessStatus}
        onChange={(_, _filters: any, sorter) => {
          if (Array.isArray(sorter)) {
            return;
          }

          setState({
            sorter,
            cursor: {
 page: 1, size: state.cursor.size 
},
          });
        }}
        refetch={refetch}
        state={state}
        sorter={true}
      />
    </>
  );
}

function getSortBy(sorter: any) {
  if (sorter && sorter.columnKey) {
    let direction = sorter.order === "descend" ? "descending" : "ascending";

    if (sorter.columnKey === "fullName") {
      return `slug:${direction}`;
    }

    // When sorting by last sign in we are sorting by the elapsed time, not the date
    // therefore as we are sorting in descending mode, on top should be the oldest sign in as date
    // the opposite applies when sorting in ascending mode, on top will be the newest sign in, which is a descending date sort
    if (sorter.columnKey === "lastSignInAt") {
      direction = sorter.order === "descend" ? "ascending" : "descending";
    }

    return `${sorter.columnKey}:${direction}`;
  }

  return null;
}
