import React from "react";
import * as palette from "@govlaunch/palette";

interface IDocumentIconProps extends React.SVGProps<any> {
  fillColor?: string | undefined;
}
export default function DocumentIcon({ fillColor, ...props }: IDocumentIconProps) {
  if (!fillColor) {
    fillColor = palette.primary;
  }

  return (
    <svg viewBox="0 0 16 22" width={16} height={22} {...props}>
      <g fill={fillColor}>
        <path d="M12.643 10H3.357C3.143 10 3 9.8 3 9.5s.143-.5.357-.5h9.286c.214 0 .357.2.357.5s-.143.5-.357.5zM12.643 7H8.357C8.143 7 8 6.8 8 6.5s.143-.5.357-.5h4.286c.214 0 .357.2.357.5s-.143.5-.357.5z" />
        <path d="M15.636 22H.364C.145 22 0 21.853 0 21.633v-15.4c0-.073.073-.22.073-.293L5.89.073c.073 0 .218-.073.29-.073h9.455c.219 0 .364.147.364.367v21.266c0 .22-.145.367-.364.367zM.727 21.267h14.546V.733H6.327L.727 6.38v14.887z" />
        <path d="M6.611 7H.39C.156 7 0 6.844 0 6.611s.156-.389.389-.389h5.833V.39c0-.233.156-.389.39-.389C6.843 0 7 .156 7 .389V6.61C7 6.844 6.844 7 6.611 7zM12.643 13H3.357C3.143 13 3 12.8 3 12.5s.143-.5.357-.5h9.286c.214 0 .357.2.357.5s-.143.5-.357.5zM12.643 16H3.357C3.143 16 3 15.8 3 15.5s.143-.5.357-.5h9.286c.214 0 .357.2.357.5s-.143.5-.357.5zM12.643 19H3.357C3.143 19 3 18.8 3 18.5s.143-.5.357-.5h9.286c.214 0 .357.2.357.5s-.143.5-.357.5z" />
      </g>
    </svg>
  );
}
