import { Div } from "glamorous";
import GovlaunchLogo from "./GovlaunchLogo";
import React from "react";

export default function Noop() {
  return (
    <Div
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      backgroundColor="#f5f8fa"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <GovlaunchLogo />
    </Div>
  );
}
