export default function getSortBy(sorter: any) {
  if (sorter && sorter.columnKey) {
    let direction = sorter.order === "descend" ? "descending" : "ascending";

    if (sorter.columnKey === "fullName") {
      return `slug:${direction}`;
    }

    // When sorting by last sign in we are sorting by the elapsed time, not the date
    // therefore as we are sorting in descending mode, on top should be the oldest sign in as date
    // the opposite applies when sorting in ascending mode, on top will be the newest sign in, which is a descending date sort
    if (sorter.columnKey === "lastSignInAt") {
      direction = sorter.order === "descend" ? "ascending" : "descending";
    }

    return `${sorter.columnKey}:${direction}`;
  }

  return null;
}
