import React from "react";
import { IGetTagBySlugQuery } from "../../../queries/__generated__/GetTagBySlug.generated";
import DocumentTitle from "react-document-title";
import { Tabs } from "antd";
import * as palette from "@govlaunch/palette";
import TagDetailsTab from "./tabs/TagDetailsTab";
import TagProjectsTab from "./tabs/TagProjectsTab";
import TagProductsTab from "./tabs/TagProductsTab";
import TagStoriesTab from "./tabs/TagStoriesTab";
import TagUsersTab from "./tabs/TagUsersTab";
import TagGovernmentsTab from "./tabs/TagGovernmentsTab";
import TagGroupsTab from "./tabs/TagGroupsTab";
import { useNavigate } from "react-router";

interface ITagProfileTabsProps {
  tag: IGetTagBySlugQuery["tag"];
  tab?: string;
}

export default function TagProfileTabs({ tag, tab }: ITagProfileTabsProps) {
  const navigate = useNavigate();

  if (!tag) {
    return null;
  }

  return (
    <DocumentTitle title={`Tag | Edit • ${tag.name}`}>
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
        }}
      >
        <Tabs
          tabBarStyle={{
            backgroundColor: palette.white,
            paddingLeft: 20,
            paddingRight: 20,
            margin: 0,
          }}
          activeKey={tab || "details"}
          onChange={(tab) => navigate(`/tags/${tag.slug}${tab !== "details" ? `/${tab}` : ""}`)}
        >
          <Tabs.TabPane tab="Details" key="details">
            <TagDetailsTab tag={tag} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Projects" key="projects">
            <TagProjectsTab tag={tag} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Products" key="products">
            <TagProductsTab tag={tag} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Stories" key="stories">
            <TagStoriesTab tag={tag} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Users" key="users">
            <TagUsersTab tag={tag} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Governments" key="governments">
            <TagGovernmentsTab tag={tag} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Groups" key="groups">
            <TagGroupsTab tag={tag} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </DocumentTitle>
  );
}
