import { theme } from "@govlaunch/web";
import { Select } from "antd";
import React from "react";
import { Field } from "react-final-form";
import notUndefinedOrNull from "../../../utils/notUndefinedOrNull";
import * as palette from "@govlaunch/palette";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { IFormFieldProps } from "./FormFieldProps";

export default function ColorPickerField({ fieldName, label, placeholder, headerColor }: IFormFieldProps) {
  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: headerColor || palette.mediumGray,
                margin: 0,
              }}
            >
              {label}
            </label>

            <FieldValidationError meta={meta} />
          </div>

          <div
            css={{
              marginTop: 8,
              borderRadius: 5,
              border: `1px solid ${palette.lightestGray}`,
              ":focus-within": {
                borderColor: palette.primary,
              },
            }}
          >
            <Select
              {...input}
              style={{
                borderRadius: 5,
                fontSize: 14,
              }}
              bordered={false}
              placeholder={placeholder}
            >
              {Object.keys(theme.colors)
                .map((colorKey) => {
                  if (typeof (theme.colors as any)[colorKey] !== "string") {
                    return null;
                  }

                  const colorHex = (theme.colors as any)[colorKey];

                  if (colorHex === "currentColor") {
                    return null;
                  }

                  return (
                    <Select.Option value={colorHex} key={colorKey}>
                      <div
                        css={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          css={{
                            marginRight: 8,
                            display: "inline-block",
                            width: 25,
                            height: 25,
                            background: colorHex,
                            border: `1px solid ${palette.lightestGray}`,
                            borderRadius: 5,
                          }}
                        />

                        <span>{colorHex}</span>
                      </div>
                    </Select.Option>
                  );
                })
                .filter(notUndefinedOrNull)}

              {Object.keys(theme.colors)
                .filter((colorKey) => {
                  return !["blackAlpha", "whiteAlpha", "currentColor"].includes(colorKey);
                })
                .map((colorKey) => {
                  if (typeof (theme.colors as any)[colorKey] === "string") {
                    return null;
                  }

                  return (
                    <Select.OptGroup key={colorKey} label={colorKey}>
                      {Object.keys((theme.colors as any)[colorKey]).map((colorShade) => {
                        const colorHex = (theme.colors as any)[colorKey][colorShade];

                        return (
                          <Select.Option value={colorHex} key={colorHex}>
                            <div
                              css={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                css={{
                                  marginRight: 8,
                                  display: "inline-block",
                                  width: 25,
                                  height: 25,
                                  background: colorHex,
                                  border: `1px solid ${palette.lightestGray}`,
                                  borderRadius: 5,
                                }}
                              />
                              <span>
                                {colorKey}.{colorShade}
                              </span>
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select.OptGroup>
                  );
                })
                .filter(notUndefinedOrNull)}
            </Select>
          </div>
        </div>
      )}
    </Field>
  );
}
