import React from "react";
import * as palette from "@govlaunch/palette";
import { Field } from "react-final-form";
import { Filestack, Button } from "@govlaunch/core";
import ProfilePicture from "@govlaunch/profile-picture";

interface IFileUploaded {
  filename: string;
  mimetype: string;
  size: string | number;
  url: string;
}

interface ILogoFieldProps {
  name?: string;
}

export default function LogoField({ name }: ILogoFieldProps) {
  return (
    <Field name="logo">
      {({ input }) => (
        <Filestack
          options={{
            accept: "image/*",
          }}
          onSuccess={({ filesUploaded }: { filesUploaded: IFileUploaded[] }) => input.onChange(filesUploaded[0].url)}
          customRender={({ onPick }: { onPick: React.SyntheticEvent }) => (
            <div>
              <div
                css={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ProfilePicture xlarge={true} image={input.value} name={name || "A B"} />
              </div>

              <div
                css={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                <Button type="button" size="xsmall" color={palette.primary} onClick={onPick}>
                  Upload
                </Button>

                {input.value && (
                  <span
                    css={{
                      marginLeft: 5,
                    }}
                  >
                    <Button
                      type="button"
                      size="xsmall"
                      color={palette.red}
                      theme="secondary"
                      onClick={() => input.onChange(null)}
                    >
                      Remove
                    </Button>
                  </span>
                )}
              </div>
            </div>
          )}
        />
      )}
    </Field>
  );
}

LogoField.defaultProps = {
  name: "",
};
