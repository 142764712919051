import * as palette from "@govlaunch/palette";
import { Button, Modal } from "@govlaunch/core";
import { NameColumn, PlainEmailColumn, CurrentlyAssignedToColumn } from "../../components/table/UsersTableColumns";
import { Value } from "react-powerplug";
import { Input } from "antd";
import ProfilePicture from "@govlaunch/profile-picture";
import React from "react";
import { Spacing } from "@govlaunch/spacers";
import SyntheticUsersTable from "../governments/SyntheticUsersTable";
import { TStoryAuthor } from "./form/StoryAuthorCard";

interface IChangeStoryAuthor {
  isOpen: boolean;
  onRequestClose: () => any;
  author: TStoryAuthor | null;
  onStoryAuthorChange: (props: { author: TStoryAuthor }) => any;
}

export default function ChangeStoryAuthor({ author, isOpen, onRequestClose, onStoryAuthorChange }: IChangeStoryAuthor) {
  return (
    <Modal ariaHideApp={false} isOpen={isOpen} onRequestClose={onRequestClose}>
      <div
        css={{
          minWidth: 900,
        }}
      >
        <div
          css={{
            padding: 20,
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <h2
              css={{
                margin: 0,
                fontWeight: 700,
                display: "flex",
                alignItems: "center",
              }}
            >
              Change Story Author
            </h2>

            <span
              role="button"
              css={{
                cursor: "pointer",
                marginLeft: "auto",
                color: palette.sealBlue,
                fontSize: 21,
                fontWeight: "lighter",
              }}
              onClick={onRequestClose}
            >
              &times;
            </span>
          </div>

          <p
            css={{
              fontSize: 14,
              fontStyle: "italic",
              color: palette.sealBlue,
              margin: 0,
            }}
          >
            Select an author below
          </p>

          {author && (
            <div
              css={{
                borderTop: `solid 1px ${palette.lightestGray}`,
                borderBottom: `solid 1px ${palette.lightestGray}`,
                marginTop: 10,
                marginBottom: 10,
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ProfilePicture size={60} image={author.avatar} name={author.fullName} />

                <div
                  css={{
                    marginLeft: 10,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    css={{
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    {author.fullName}
                  </span>

                  <span
                    css={{
                      fontSize: 12,
                    }}
                  >
                    {author.__typename === "PendingUser" ? author.jobTitle : author.title || author.jobTitle}
                  </span>
                </div>
              </div>
            </div>
          )}

          <Value<string> initial="">
            {({ value: searchTerm, set: setSearchTerm }) => (
              <>
                <Spacing top={10}>
                  <Input.Search
                    size="middle"
                    placeholder="Search for users..."
                    autoComplete="off"
                    onSearch={(search) => {
                      setSearchTerm(search);
                    }}
                    style={{
                      fontSize: 14,
                    }}
                  />
                </Spacing>

                <div
                  css={{
                    marginTop: 10,
                  }}
                >
                  <SyntheticUsersTable
                    limit={5}
                    pagination={false}
                    search={searchTerm}
                    columns={[
                      NameColumn,
                      PlainEmailColumn,
                      CurrentlyAssignedToColumn,
                      {
                        title: "#",
                        render: (user: TStoryAuthor) => {
                          if (author && author._id === user._id) {
                            return (
                              <span
                                css={{
                                  color: palette.innovatorBlue,
                                  fontWeight: 500,
                                }}
                              >
                                Current Author
                              </span>
                            );
                          }

                          return (
                            <Button
                              size="xsmall"
                              theme="secondary"
                              onClick={() => {
                                onStoryAuthorChange({
                                  author: user,
                                });
                              }}
                            >
                              Set
                            </Button>
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </>
            )}
          </Value>
        </div>
      </div>
    </Modal>
  );
}
