import * as palette from "@govlaunch/palette";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { useToggle } from "react-use";
import TableRowActions from "../../components/table/TableRowActions";
import { ToggleInnovator } from "../../mutations";
import Impersonate from "../debugging/Impersonate";
import EditGovernmentMembersModal from "../teamManagement/modals/EditGovernmentMembersModal";
import { TGovernment } from "./tables/GovernmentsTable";

interface IGovernmentActionsProps {
  government: TGovernment;
}

export default function GovernmentActions({ government }: IGovernmentActionsProps) {
  const [showTeamBuilder, toggleTeamBuilder] = useToggle(false);

  return (
    <>
      <EditGovernmentMembersModal
        visible={showTeamBuilder}
        government={government as any}
        onRequestClose={toggleTeamBuilder}
      />

      <TableRowActions title="Government Actions">
        {({ onClick }: { onClick: (arg?: any) => any }) => (
          <>
            <li>
              <a href="#" onClick={onClick(toggleTeamBuilder)}>
                Team Builder
              </a>
            </li>

            <ToggleInnovator entityId={government._id} entityType="government">
              {(toggleInnovator: any) => (
                <li>
                  <a
                    href="#"
                    onClick={onClick(toggleInnovator)}
                    css={{
                      color: government.isInnovator ? palette.red : undefined,
                    }}
                  >
                    {government.isInnovator ? "Remove Innovator" : "Set Innovator"}
                  </a>
                </li>
              )}
            </ToggleInnovator>

            {government.members && government.members.length > 0 && (
              <li>
                <Link to={`/users?tab=governments&government=${government._id}`}>Search Users</Link>
              </li>
            )}

            {government.owner && (
              <Impersonate>
                {(impersonate) => (
                  <li>
                    <a href="#" onClick={onClick(() => impersonate(government.owner!))}>
                      Impersonate
                    </a>
                  </li>
                )}
              </Impersonate>
            )}

            <li>
              <Link to={`/governments/${government.slug}`}>Edit</Link>
            </li>
          </>
        )}
      </TableRowActions>
    </>
  );
}

GovernmentActions.propTypes = {
  government: PropTypes.object.isRequired,
};
