import styled from "@emotion/styled";
import React from "react";
import { Field } from "react-final-form";

export default function SubscribedToNewsletter({ ...props }) {
  return (
    <Field name="subscribedToNewsletter" type="checkbox">
      {({ input }) => (
        <div
          css={{
            display: "flex",
            alignItems: "flex-start",
            margin: 4,
          }}
        >
          <div
            css={{
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox id="subscribeToNewsletter" type="checkbox" {...props} {...input} />
          </div>
        </div>
      )}
    </Field>
  );
}

export const Checkbox = styled("input")({
  margin: "0 !important",
});
