import React from "react";
import CollectionIcon from "../../../../components/icons/CollectionIcon";

export type TCollectionRow = {
  _id: any;
  name: string;
  contentCounters: {
    productsCount: number;
    projectsCount: number;
    storiesCount: number;
  };
};

interface ICollectionRowProps {
  collection: TCollectionRow;
}

export default function CollectionRow({ collection }: ICollectionRowProps) {
  const itemCount =
    collection.contentCounters.productsCount +
    collection.contentCounters.projectsCount +
    collection.contentCounters.storiesCount;

  return (
    <div
      css={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontFamily: "'proxima-nova', Helvetica, Arial, sans-serif",
      }}
    >
      <CollectionIcon />
      <div
        css={{
          marginLeft: "16px",
          fontWeight: 600,
          fontSize: "16px",
          width: "300px",
        }}
      >
        {collection.name}
      </div>
      <div
        css={{
          fontSize: "16px",
          fontWeight: 400,
          minWidth: "100px",
          textAlign: "right",
        }}
      >
        {itemCount > 1 ? `${itemCount} items` : `${itemCount} item`}
      </div>
    </div>
  );
}
