import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { IUserBySlugQuery } from "../../../queries/__generated__/UserBySlugQuery.generated";

interface IUserGroupsTabProps {
  user: NonNullable<IUserBySlugQuery["user"]>;
}

export default function UserGroupsTab({ user }: IUserGroupsTabProps) {
  if (!user.groups) {
    return null;
  }

  return (
    <div
      css={{
        padding: "0 15px",
      }}
    >
      <Table
        pagination={false}
        rowKey="_id"
        bordered={true}
        dataSource={user.groups}
        style={{
          backgroundColor: palette.white,
        }}
        columns={[
          {
            title: "Name",
            dataIndex: "slug",
            render: (_, group) => {
              return (
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture
                    size={32}
                    image={group.logo}
                    name={group.name}
                    css={{
                      whiteSpace: "nowrap",
                    }}
                  />

                  <Spacing left={10}>
                    <Link to={`/groups/${group.slug}`}>{group.name}</Link>
                  </Spacing>
                </div>
              );
            },
          },
          {
            title: "Entity",
            dataIndex: "_id",
            render: (_, group) => {
              const vendor = group.vendors.find(vendor => vendor.viewerIsMember);

              if (vendor) {
                return (
                  <div
                    css={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ProfilePicture
                      size={32}
                      image={vendor.logo}
                      name={vendor.name}
                      css={{
                        whiteSpace: "nowrap",
                      }}
                    />

                    <Spacing left={10}>
                      <Link to={`/vendors/${vendor.name}`}>{vendor.name}</Link>
                    </Spacing>
                  </div>
                );
              }

              const government = group.governments.find(
                government => government.viewerIsMember || government.viewerIsOwner,
              );

              if (government) {
                return (
                  <div
                    css={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ProfilePicture
                      size={32}
                      image={government.logo}
                      name={government.name}
                      css={{
                        whiteSpace: "nowrap",
                      }}
                    />

                    <Spacing left={10}>
                      <Link to={`/governments/${government.slug}`}>{government.name}</Link>
                    </Spacing>
                  </div>
                );
              }

              return "None";
            },
          },
          {
            title: "Is Individual Member?",
            dataIndex: "name",
            render: (_, group) => {
              const vendor = group.vendors.find(vendor => vendor.viewerIsMember);

              const government = group.governments.find(
                government => government.viewerIsMember || government.viewerIsOwner,
              );

              return vendor || government ? "No" : "Yes";
            },
          },
          {
            title: "# of Members",
            dataIndex: "membersCount",
          },
        ]}
      />
    </div>
  );
}
