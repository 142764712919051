import React from "react";
import { Mutation } from "react-apollo";
import EditVendorMutation from "../../../mutations/EditVendor";
import {
  IEditVendorMutation,
  IEditVendorMutationVariables,
} from "../../../mutations/__generated__/EditVendor.generated";
import { Form } from "react-final-form";
import validate from "../../../validate";
import VendorForm from "./VendorForm";
import convertValuesToVariables from "./convertValuesToVariables";
import convertVendorToInitialValues from "./convertVendorToInitialValues";
import validationSchema from "./validationSchema";
import { IVendorQuery } from "../../../queries/__generated__/VendorQuery.generated";
import { notification } from "antd";

interface IEditVendorProps {
  vendor: IVendorQuery["company"];
}

export default function EditVendorForm({ vendor }: IEditVendorProps) {
  if (!vendor) {
    return null;
  }

  const initialValues = convertVendorToInitialValues(vendor);

  return (
    <Mutation<IEditVendorMutation, IEditVendorMutationVariables> mutation={EditVendorMutation}>
      {(editVendor) => {
        return (
          <Form
            subscription={{
              submitting: true,
              submitError: true,
            }}
            initialValues={initialValues}
            keepDirtyOnReinitialize={true}
            validate={validate(validationSchema)}
            onSubmit={(values) => {
              return editVendor({
                variables: {
                  id: vendor._id,
                  vendor: convertValuesToVariables(values),
                },
              }).then((mutationResult) => {
                if (
                  mutationResult &&
                  mutationResult.data &&
                  mutationResult.data.editVendor &&
                  mutationResult.data.editVendor.slug
                ) {
                  notification.open({
                    type: "success",
                    message: "Vendor saved!",
                    description: `Vendor saved successfully!`,
                  });
                }
              });
            }}
          >
            {({ handleSubmit, submitting, submitError }) => (
              <VendorForm
                submitting={submitting}
                onSubmit={handleSubmit}
                submitButtonText="Save Changes"
                submitError={submitError}
              />
            )}
          </Form>
        );
      }}
    </Mutation>
  );
}
