import gql from "graphql-tag";
import VendorProjectFields from "../queries/fragments/VendorProjectFields";

export default gql`
  mutation CreateProjectForVendor($project: ProjectInput!, $vendor: ObjectId!) {
    createProjectForVendor(project: $project, vendor: $vendor) {
      _id
      ...VendorProjectFields
    }
  }

  ${VendorProjectFields}
`;
