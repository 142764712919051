import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";

export default function MobileResponsiveField() {
  return (
    <Field name="mobileResponsive" type="checkbox">
      {({ input }) => (
        <div>
          <label>
            <input type="checkbox" {...input} />

            <span
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: palette.mediumGray,
                marginLeft: 8,
              }}
            >
              Mobile Responsive
            </span>
          </label>
        </div>
      )}
    </Field>
  );
}
