import { Alert, Modal } from "antd";
import React from "react";

interface IErrorStateModalProps {
  title: string;
  errorDescription: string;
  visible: boolean;
  onRequestClose?: () => void;
}

export default function ErrorStateModal({ title, errorDescription, visible, onRequestClose }: IErrorStateModalProps) {
  return (
    <Modal width={720} title={title} visible={visible} onCancel={onRequestClose} footer={null}>
      <Alert message="Something isn't right..." description={errorDescription} type="error" />
    </Modal>
  );
}
