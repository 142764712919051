import React from "react";
import * as Types from "../../../../types/types";
import * as palette from "@govlaunch/palette";
import { IVendorQuery } from "../../../queries/__generated__/VendorQuery.generated";

interface IVendorCurrentPlanProps {
  plan: { __typename: "Plan" } & Pick<Types.IPlan, "_id" | "name" | "tier" | "stripePlanId" | "isSelectable">;
  subscription: NonNullable<IVendorQuery["company"]>["subscription"];
}

export default function VendorCurrentPlan({ plan, subscription }: IVendorCurrentPlanProps) {
  const free = subscription && subscription.freeSubscription;
  const trial = subscription && !!subscription.trialPeriod && subscription.trialPeriod > 0;
  return (
    <div
      css={{
        fontWeight: 500,
        fontSize: 12,
        fontStyle: "italic",
        color: palette.mediumGray,
        margin: 0,
      }}
    >
      Vendor is currently subscribed as{" "}
      <span
        css={{
          color: palette.greenSuccess,
          fontWeight: "bold",
        }}
      >
        {plan.name} {free && <span>Free</span>}
      </span>
      {trial && <span> with trial period of {subscription!.trialPeriod} days</span>}
    </div>
  );
}
