import React from "react";
import { Query } from "react-apollo";
import GetProjectsByVendor from "../../../queries/GetProjectsByVendor";
import {
  IGetProjectsByVendorQuery,
  IGetProjectsByVendorQueryVariables,
} from "../../../queries/__generated__/GetProjectsByVendor.generated";
import getOr from "lodash/fp/getOr";
import ProjectsTable from "../../projects/tables/ProjectsTable";
import { NetworkStatus } from "apollo-client";
import { LoadingIndicator } from "@govlaunch/core";
import VisibilitySensor from "react-visibility-sensor";
import { IVerificationInput } from "../../../../types/types";

interface IMentionedProjectsTabProps {
  vendorSlug: string;
  verification: IVerificationInput;
}

export default function MentionedProjectsTab({ vendorSlug, verification }: IMentionedProjectsTabProps) {
  return (
    <Query<IGetProjectsByVendorQuery, IGetProjectsByVendorQueryVariables>
      fetchPolicy="network-only"
      notifyOnNetworkStatusChange={true}
      variables={{
        slug: vendorSlug,
        sort: null,
        mentionedOnly: true,
        verification,
        cursor: null,
      }}
      query={GetProjectsByVendor}
    >
      {({ data, fetchMore, refetch, networkStatus }) => {
        const loading = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables;
        const fetchingMore = networkStatus === NetworkStatus.fetchMore;

        return (
          <div
            css={{
              padding: 20,
            }}
          >
            <ProjectsTable
              projects={getOr([], "projectsByVendor.items", data)}
              hideGovernmentColumn={true}
              enableSorting={false}
              onChange={(_pagination: any, _filter: any, sorter: any) => {
                if (!sorter) {
                  return;
                }

                if (!sorter.column) {
                  refetch({
                    slug: vendorSlug,
                    sort: null,
                    mentionedOnly: true,
                    verification,
                    cursor: null,
                  });
                }

                if (["createdAt", "author"].includes(sorter.columnKey)) {
                  const sortExpression = {
                    sortField: sorter.columnKey,
                    sortOrder: sorter.order === "ascend" ? "asc" : "desc",
                  };

                  refetch({
                    slug: vendorSlug,
                    sort: sortExpression,
                    cursor: null,
                    mentionedOnly: true,
                    verification: verification,
                  });
                }
              }}
            />

            {data && data.projectsByVendor && (
              <>
                <VisibilitySensor
                  active={
                    loading === false && data.projectsByVendor.pageInfo && data.projectsByVendor.pageInfo.hasNextPage
                      ? data.projectsByVendor.pageInfo.hasNextPage
                      : false
                  }
                  partialVisibility={true}
                  onChange={(isActive) => {
                    return (
                      isActive &&
                      fetchMore({
                        variables: {
                          cursor:
                            data.projectsByVendor &&
                            data.projectsByVendor.pageInfo &&
                            data.projectsByVendor.pageInfo.endCursor
                              ? data.projectsByVendor.pageInfo.endCursor
                              : null,
                          slug: vendorSlug,
                        },
                        updateQuery: (current, { fetchMoreResult }) => {
                          if (
                            !current.projectsByVendor ||
                            !current.projectsByVendor.items ||
                            !fetchMoreResult ||
                            !fetchMoreResult.projectsByVendor ||
                            !fetchMoreResult.projectsByVendor.items
                          ) {
                            return current;
                          }

                          return {
                            __typename: "Query",
                            projectsByVendor: {
                              ...fetchMoreResult.projectsByVendor,
                              items: current.projectsByVendor.items.concat(fetchMoreResult.projectsByVendor.items),
                            },
                          };
                        },
                      })
                    );
                  }}
                />

                {fetchingMore && <LoadingIndicator />}
              </>
            )}
          </div>
        );
      }}
    </Query>
  );
}
