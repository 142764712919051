import { IEditStoryMutationVariables } from "../../mutations/__generated__/EditStory.generated";
import { IProposeStoryMutationVariables } from "../../mutations/__generated__/ProposeStory.generated";
import { IStoryFormValues } from "./StoryComposer";

export function convertValuesToEditStoryVariables(values: IStoryFormValues): IEditStoryMutationVariables {
  if (!values._id) {
    throw new Error("Missing _id");
  }

  return {
    ...convertValuesToVariables(values),
    projectAwards: [],
    storyId: values._id,
  };
}

export function convertValuesToProposeStoryVariables(values: IStoryFormValues): IProposeStoryMutationVariables {
  return convertValuesToVariables(values);
}

function convertValuesToVariables(values: IStoryFormValues) {
  return {
    government: values.government?.value || null,
    author: getId(values.author),
    coauthors: (values.coauthors || []).map((coauthor) => coauthor._id),
    vendorCoauthors: (values.vendorCoauthors || []).map((coauthor) => coauthor._id),
    collaborators: values.collaborators.map((collaborator) => collaborator._id),
    title: values.title,
    introduction: values.introduction,
    body: values.body,
    highlights: values.highlights,
    locations: values.locations.map((location) => location.value),
    medias: values.medias.map((media) => {
      return {
        url: media.url,
        type: media.type,
        alt: media.alt || "",
      };
    }),
    resources: values.resources.map((resource) => {
      return {
        type: resource.type,
        url: resource.type !== "text" ? resource.url : null,
        title: resource.title,
        body: resource.type === "text" ? resource.body : null,
        filename: resource.type === "download" ? resource.filename : null,
        addedAt: resource.addedAt,
      };
    }),
    awards: values.awards.map((award) => award.value),
    newsworthy: values.newsworthy ? values.newsworthy.filter(({ url }) => url) : [],
    outsideProducts: [],
    products: values.products.map((product) => product.value),
    tags: values.tags.map((tag) => tag.value),
    audience: values.audience || "community",
  };
}

const getId = (entity: any) => entity._id || entity.objectID;
