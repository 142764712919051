/* eslint-disable @typescript-eslint/no-unused-vars */
import { InboxOutlined } from "@ant-design/icons";
import { palette } from "@govlaunch/web";
import { Alert, AutoComplete, Button, Input, notification, Table, Tag, Select, Collapse } from "antd";
import Column from "antd/lib/table/Column";
import { chunk } from "lodash";
import { sampleSize } from "lodash/fp";
import * as PapaParse from "papaparse";
import React, { useState } from "react";
import { useMutation } from "react-apollo";
import DocumentTitle from "react-document-title";
import { ulid } from "ulid";
import { COUNTRIES, getCountryNameByIsoCode } from "./Countries";
import { COUNTRY_CODE_TO_CURRENCY } from "./Currencies";
import UploadGovernmentsMutation from "./graphql/mutations/UploadGovernmentsMutation";
import {
  IUploadGovernmentsMutation,
  IUploadGovernmentsMutationVariables,
} from "./graphql/mutations/__generated__/UploadGovernmentsMutation.generated";
import notUndefinedOrNull from "../../utils/notUndefinedOrNull";
import PageIcon from "../../icons/PageIcon";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";

const EXPECTED_HEADER_COLUMNS = [
  "State Name",
  "State Abbreviation",
  "Government Name",
  "County",
  "Government Type",
  "Postal Code",
  "Budget",
  "Population",
];
const OPTIONAL_VALUE_COLUMNS = ["State Name", "State Abbreviation", "County", "Postal Code", "Budget", "Population"];

type TDeduplicateKeyFields = Exclude<keyof TParsedGovernmentRecord, "id" | "lineInFile">;
const AvailableDeduplicateKeyFields: TDeduplicateKeyFields[] = [
  "governmentName",
  "county",
  "governmentType",
  "stateName",
  "stateAbbreviation",
  "postalCode",
  "budget",
  "population",
];
const AvailableDeduplicateKeyFieldsToColumnMap: Record<TDeduplicateKeyFields, string> = {
  stateName: "State Name",
  stateAbbreviation: "State Abbreviation",
  governmentName: "Government Name",
  county: "County",
  governmentType: "Government Type",
  postalCode: "Postal Code",
  budget: "Budget",
  population: "Population",
};

// const HeaderColumns = {
//   STATE_NAME: "State Name",
//   STATE_ABBREVIATION: "State Abbreviation",
//   GOVERNMENT_NAME: "Government Name",
//   COUNTY: "County",
//   GOVERNMENT_TYPE: "Government Type",
//   POSTAL_CODE: "Postal Code",
//   BUDGET: "Budget",
//   POPULATION: "Population",
// };

export default function GovernmentsUpload() {
  const [selectedCountryIsoCode, setSelectedCountryIsoCode] = useState<string | null>(null);
  const [uploadId, setUploadId] = useState<string | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [parsedGovernmentRecords, setParsedGovernmentRecords] = useState<TParsedGovernmentRecord[]>([]);
  const [deduplicateThroughFields, setDeduplicateThroughFields] = useState<TDeduplicateKeyFields[]>([
    "governmentName",
    "governmentType",
    "county",
  ]);
  const [uploadGovernments, { loading: isUploadingGovernments }] = useMutation<
    IUploadGovernmentsMutation,
    IUploadGovernmentsMutationVariables
  >(UploadGovernmentsMutation, {
    onCompleted: ({ uploadGovernments }) => {
      if (uploadGovernments) {
        notification.success({
          message: "Uploaded successfully!",
          description: `Total: ${uploadGovernments.totalCount}. Added: ${uploadGovernments.addedCount}. Skipped: ${uploadGovernments.skippedCount}`,
        });

        setSelectedCountryIsoCode(null);
        setUploadError(null);
        setParsedGovernmentRecords([]);
      }
    },
    onError: (error) => {
      notification.error({
        message: "Uploaded failed!",
        description: `Couldn't process the uploaded file: ${error.message}`,
      });

      if (error.message) {
        setUploadError(error.message);
      }
    },
  });

  const possiblyDuplicatedGovernments = findPossiblyDuplicatedGovernments(
    parsedGovernmentRecords,
    deduplicateThroughFields,
  );
  const governmentsWithoutDuplicates = parsedGovernmentRecords.filter((record) => {
    // Skip duplicates.
    return !possiblyDuplicatedGovernments.some((duplicate) => {
      return record.lineInFile === duplicate.lineInFile;
    });
  });

  return (
    <DocumentTitle title="Governments Upload | Admin">
      <div
        css={{
          overflow: "auto",
        }}
      >
        <div
          css={{
            padding: 24,
            borderBottom: "1px solid rgb(232, 232, 232)",
          }}
        >
          <h3
            css={{
              margin: 0,
              fontWeight: 700,
              fontSize: 24,
              lineHeight: 1.33,
            }}
          >
            <PageIcon icon={faArrowAltCircleUp} /> Governments Upload
          </h3>

          <p
            css={{
              margin: 0,
              lineHeight: 1.33,
            }}
          >
            Bulk upload Governments.
          </p>
        </div>

        <div
          css={{
            padding: 24,
          }}
        >
          <Collapse>
            <Collapse.Panel header="Upload Instructions/Requirements" key="1">
              <Alert
                type="info"
                showIcon={true}
                message={<strong>Details</strong>}
                description={
                  <div>
                    <ol>
                      <li>
                        Only <strong>.csv</strong> files are supported. You can always export those from standard
                        Spreadsheet apps;
                      </li>
                      <li>If a Government with the same name already exists—it will be skipped uploading;</li>
                      <li>
                        The uploaded file <strong>has to match</strong>{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/spreadsheets/d/15fLMEy0digQrQ6l4D3wzrwoIAcPUxQLwq90vveDNvts/edit?usp=sharing"
                        >
                          this Template
                        </a>
                        .
                      </li>
                      <li>
                        Expected columns are:
                        <ul>
                          {EXPECTED_HEADER_COLUMNS.map((column) => {
                            const isOptional = OPTIONAL_VALUE_COLUMNS.includes(column);

                            return (
                              <li key={column}>
                                <code
                                  css={{
                                    marginRight: 12,
                                  }}
                                >
                                  {column}
                                </code>
                                {isOptional ? <Tag>Optional Value</Tag> : <Tag color="red">Required Value</Tag>}
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                      <li>
                        All columns <strong>must be present</strong> in the file. Values can be optional as specified
                        above;
                      </li>
                      <li>
                        <strong>Population</strong> and <strong>Budget</strong> can have commas in them or be a whole
                        number. Valid values are: <code>1,500,000</code> or <code>1500000</code>. If invalid, will be
                        imported as <code>0</code>;
                      </li>
                      <li>Duplicated Governments will be filtered out prior to import.</li>
                    </ol>
                  </div>
                }
              />
            </Collapse.Panel>
          </Collapse>
        </div>

        {selectedCountryIsoCode ? (
          <div
            css={{
              padding: 24,
              paddingTop: 0,
            }}
          >
            <div
              css={{
                maxWidth: 480,
              }}
            >
              <div
                css={{
                  marginBottom: 24,
                }}
              >
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h1
                    css={{
                      margin: 0,
                    }}
                  >
                    Selected Country: <strong>{getCountryNameByIsoCode(selectedCountryIsoCode)}</strong>
                  </h1>

                  <img
                    src={`https://static.govlaunch.com/flags/squared/${selectedCountryIsoCode}.svg`}
                    width={18}
                    height={18}
                    style={{
                      width: 18,
                      height: 18,
                      marginLeft: 8,
                    }}
                  />
                </div>

                <div
                  css={{
                    marginTop: 12,
                  }}
                >
                  <Button
                    onClick={() => {
                      setSelectedCountryIsoCode(null);
                      setUploadId(null);
                      setParsedGovernmentRecords([]);
                      setUploadError(null);
                    }}
                  >
                    Change Country
                  </Button>
                </div>
              </div>
            </div>

            {parsedGovernmentRecords.length === 0 && (
              <label
                css={{
                  maxWidth: 480,
                  backgroundColor: palette.gray[50],
                  display: "flex",
                  padding: 24,
                  borderRadius: 6,
                  border: `dashed 1px ${palette.gray[200]}`,
                  cursor: "pointer",
                  justifyContent: "center",
                  "&:hover > strong": {
                    borderBottom: "solid 1px",
                  },
                }}
              >
                <div
                  css={{
                    textAlign: "center",
                  }}
                >
                  <div
                    css={{
                      fontSize: 48,
                      color: palette.blue[500],
                    }}
                  >
                    <InboxOutlined />
                  </div>

                  <strong>Click here to upload Governments file</strong>
                </div>

                <input
                  type="file"
                  accept=".csv, text/csv"
                  onChange={async (event) => {
                    const fileContents = await readFileAsText(event.target);
                    if (fileContents) {
                      try {
                        const records = parseGovernmentsFileContents(fileContents);
                        setParsedGovernmentRecords(records);
                        setUploadError(null);
                      } catch (err) {
                        if (err instanceof Error) {
                          setUploadError(err.message);
                        }
                      }
                    }
                  }}
                  style={{
                    display: "none",
                  }}
                />
              </label>
            )}

            {uploadId && (
              <div
                css={{
                  display: "grid",
                  maxWidth: 480,
                  marginTop: 24,
                  marginBottom: 24,
                  padding: 12,
                  backgroundColor: palette.blue[50],
                  border: `dashed 1px ${palette.gray[200]}`,
                  borderRadius: 4,
                  rowGap: 4,
                }}
              >
                <code
                  css={{
                    fontSize: 18,
                  }}
                >
                  {uploadId}
                </code>
                <strong>Upload ID</strong>
              </div>
            )}

            {uploadError && (
              <div
                css={{
                  marginTop: 24,
                  maxWidth: 480,
                }}
              >
                <Alert message="Import Issues" description={uploadError} type="error" showIcon={true} />
              </div>
            )}

            {parsedGovernmentRecords.length > 0 && (
              <div>
                <div
                  css={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, max-content)",
                    columnGap: 24,
                  }}
                >
                  <div
                    css={{
                      width: 240,
                      padding: 12,
                      borderRadius: 4,
                      display: "grid",
                      rowGap: 4,
                      backgroundColor: palette.teal[50],
                      border: `dashed 1px ${palette.gray[200]}`,
                      marginBottom: 24,
                    }}
                  >
                    <h1
                      css={{
                        margin: 0,
                        fontWeight: "bold",
                        fontSize: 18,
                      }}
                    >
                      {parsedGovernmentRecords.length}
                    </h1>
                    <strong>
                      {parsedGovernmentRecords.length === 1 ? "Government Uploaded" : "Governments Uploaded"}
                    </strong>
                  </div>

                  <div
                    css={{
                      width: 160,
                      padding: 12,
                      borderRadius: 4,
                      display: "grid",
                      rowGap: 4,
                      backgroundColor: palette.red[50],
                      border: `dashed 1px ${palette.red[500]}`,
                      marginBottom: 24,
                    }}
                  >
                    <h1
                      css={{
                        margin: 0,
                        fontWeight: "bold",
                        fontSize: 18,
                      }}
                    >
                      {possiblyDuplicatedGovernments.length}
                    </h1>
                    <strong>{possiblyDuplicatedGovernments.length === 1 ? "Duplicate" : "Duplicates"}</strong>
                  </div>

                  <div
                    css={{
                      width: 160,
                      padding: 12,
                      borderRadius: 4,
                      display: "grid",
                      rowGap: 4,
                      backgroundColor: palette.blue[50],
                      border: `dashed 1px ${palette.blue[500]}`,
                      marginBottom: 24,
                    }}
                  >
                    <h1
                      css={{
                        margin: 0,
                        fontWeight: "bold",
                        fontSize: 18,
                      }}
                    >
                      {parsedGovernmentRecords.length - possiblyDuplicatedGovernments.length}
                    </h1>
                    <strong>Being Imported</strong>
                  </div>
                </div>

                <div
                  css={{
                    marginBottom: 24,
                  }}
                >
                  <Button
                    type="primary"
                    loading={isUploadingGovernments}
                    onClick={async () => {
                      if (!uploadId) {
                        return;
                      }

                      if (!confirm("Do you confirm the upload of those Governments?")) {
                        return;
                      }

                      // Send in chunks of 5k to be safe
                      const chunks = chunk(governmentsWithoutDuplicates, 5000);
                      const totalGovernments = governmentsWithoutDuplicates.length;
                      let chunkId = 1;
                      let totalUploadedSoFar = 0;
                      for (const governments of chunks) {
                        totalUploadedSoFar += governments.length;
                        notification.info({
                          message: `Chunk ${chunkId} of ${chunks.length}`,
                          description: `Uploading ${governments.length} governments. Progress is ${totalUploadedSoFar}/${totalGovernments}`,
                        });

                        await uploadGovernments({
                          variables: {
                            uploadId,
                            isoCountryCode: selectedCountryIsoCode,
                            isoCurrencyCode: COUNTRY_CODE_TO_CURRENCY[selectedCountryIsoCode] || "USD",
                            payload: governments.map((record) => {
                              return {
                                stateName: record.stateName,
                                stateAbbreviation: record.stateAbbreviation,
                                governmentName: record.governmentName,
                                county: record.county,
                                governmentType: record.governmentType,
                                postalCode: record.postalCode,
                                budget: record.budget,
                                population: record.population,
                              };
                            }),
                          },
                        });
                        chunkId++;
                      }
                    }}
                  >
                    {`Confirm Upload of ${governmentsWithoutDuplicates.length} `}
                    {governmentsWithoutDuplicates.length === 1 ? "Government" : "Governments"}
                  </Button>
                </div>

                <div
                  css={{
                    marginBottom: 24,
                  }}
                >
                  <Select
                    mode="multiple"
                    size="large"
                    disabled={false}
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select fields for deduplication"
                    value={deduplicateThroughFields}
                    onChange={(values) => {
                      setDeduplicateThroughFields(values);
                    }}
                  >
                    {AvailableDeduplicateKeyFields.map((field) => {
                      return (
                        <Select.Option key={field} value={field}>
                          {AvailableDeduplicateKeyFieldsToColumnMap[field]}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>

                {possiblyDuplicatedGovernments.length > 0 && (
                  <div
                    css={{
                      marginBottom: 24,
                      backgroundColor: palette.red[50],
                      padding: 12,
                      borderRadius: 12,
                      border: `dashed 1px ${palette.red[500]}`,
                    }}
                  >
                    <h1
                      css={{
                        fontWeight: "bold",
                        color: palette.red[500],
                        fontSize: 18,
                      }}
                    >
                      Possibly duplicated Governments
                    </h1>

                    <div>
                      <Table<TParsedGovernmentRecord>
                        rowKey="id"
                        loading={false}
                        bordered={true}
                        size="small"
                        dataSource={possiblyDuplicatedGovernments}
                        scroll={{
                          x: true,
                        }}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                        }}
                      >
                        <Column dataIndex="lineInFile" title="Line" />
                        <Column dataIndex="governmentName" title="Name" />
                        <Column dataIndex="stateName" title="State Name" />
                        <Column dataIndex="stateAbbreviation" title="State Abbreviation" />
                        <Column dataIndex="county" title="County" />
                        <Column dataIndex="governmentType" title="Government Type" />
                        <Column dataIndex="postalCode" title="Postal Code" />
                        <Column dataIndex="budget" title="Budget" />
                        <Column dataIndex="population" title="Population" />
                      </Table>
                    </div>
                  </div>
                )}

                <Table<TParsedGovernmentRecord>
                  rowKey="id"
                  loading={false}
                  bordered={true}
                  pagination={false}
                  size="small"
                  dataSource={sampleSize(20, governmentsWithoutDuplicates)}
                  scroll={{
                    x: true,
                  }}
                  title={() => {
                    return <strong>Samples (Up to 20 sampled governments)</strong>;
                  }}
                >
                  <Column dataIndex="governmentName" title="Name" />
                  <Column dataIndex="stateName" title="State Name" />
                  <Column dataIndex="stateAbbreviation" title="State Abbreviation" />
                  <Column dataIndex="county" title="County" />
                  <Column dataIndex="governmentType" title="Government Type" />
                  <Column dataIndex="postalCode" title="Postal Code" />
                  <Column dataIndex="budget" title="Budget" />
                  <Column dataIndex="population" title="Population" />
                </Table>
              </div>
            )}
          </div>
        ) : (
          <div
            css={{
              padding: 24,
              paddingTop: 0,
            }}
          >
            <SelectCountry
              onSelectCountry={(isoCountryCode) => {
                setSelectedCountryIsoCode(isoCountryCode);
                setUploadId(ulid());
              }}
            />

            {uploadId && (
              <div
                css={{
                  display: "grid",
                  maxWidth: 480,
                  marginTop: 24,
                  padding: 12,
                  backgroundColor: palette.blue[50],
                  border: `dashed 1px ${palette.gray[200]}`,
                  borderRadius: 4,
                  rowGap: 4,
                }}
              >
                <code
                  css={{
                    fontSize: 18,
                  }}
                >
                  {uploadId}
                </code>
                <strong>Upload ID</strong>
              </div>
            )}
          </div>
        )}
      </div>
    </DocumentTitle>
  );
}

interface ISelectCountryProps {
  onSelectCountry: (isoCountryCode: string) => void;
}

function SelectCountry({ onSelectCountry }: ISelectCountryProps) {
  const [inputValue, setInputValue] = useState<string>("");

  const options = COUNTRIES.filter((country) => {
    if (!inputValue) {
      return true;
    }
    if (inputValue.length === 2) {
      return country.code === inputValue.toUpperCase();
    }
    return country.name.toLowerCase().includes(inputValue.toLowerCase());
  }).map((country) => {
    return {
      value: country.code,
      label: (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "18px max-content 1fr",
            columnGap: 18,
          }}
        >
          <img
            src={`https://static.govlaunch.com/flags/squared/${country.code}.svg`}
            width={18}
            height={18}
            style={{
              width: 18,
              height: 18,
            }}
          />

          <strong>{country.code}</strong>

          <span>{country.name}</span>
        </div>
      ),
    };
  });

  return (
    <AutoComplete
      options={options}
      onSelect={(isoCountryCode: string) => onSelectCountry(isoCountryCode)}
      style={{
        width: 480,
      }}
    >
      <Input.Search
        size="large"
        spellCheck={false}
        loading={false}
        placeholder="Select a Country"
        onChange={(event) => setInputValue(event.target.value)}
      />
    </AutoComplete>
  );
}

type TParsedGovernmentRecord = {
  id: string;
  lineInFile: number;
  stateName: string | null;
  stateAbbreviation: string | null;
  governmentName: string;
  county: string | null;
  governmentType: string;
  postalCode: string;
  budget: number | null;
  population: number | null;
};

function findPossiblyDuplicatedGovernments(
  parsedGovernmentRecords: TParsedGovernmentRecord[],
  deduplicateThroughFields: TDeduplicateKeyFields[],
): TParsedGovernmentRecord[] {
  const state = parsedGovernmentRecords.reduce<{
    uniqueKeys: string[];
    duplicatedIndexes: number[];
  }>(
    (accumulated, record, index) => {
      // Bypass.
      if (deduplicateThroughFields.length === 0) {
        return accumulated;
      }

      const key = deduplicateThroughFields
        .map((field) => {
          const fieldValue = record[field];
          if (!fieldValue) {
            return "";
          }
          if (typeof fieldValue === "string") {
            return fieldValue.trim().toLowerCase();
          }
          return fieldValue;
        })
        .filter(Boolean)
        .join("-");
      if (accumulated.uniqueKeys.includes(key)) {
        return {
          ...accumulated,
          duplicatedIndexes: [...accumulated.duplicatedIndexes, index],
        };
      }

      return {
        ...accumulated,
        uniqueKeys: [...accumulated.uniqueKeys, key],
      };
    },
    {
      uniqueKeys: [],
      duplicatedIndexes: [],
    },
  );

  return parsedGovernmentRecords.filter((_, index) => {
    return state.duplicatedIndexes.includes(index);
  });
}

function parseGovernmentsFileContents(fileContents: string): TParsedGovernmentRecord[] {
  const parseResult = PapaParse.parse<{
    "State Name": string;
    "State Abbreviation": string;
    "Government Name": string;
    County: string;
    "Government Type": string;
    "Postal Code": string;
    Budget: string;
    Population: string;
  }>(fileContents, {
    header: true,
    //trimHeaders: false,
    skipEmptyLines: false,
    //encoding: "UTF-8",
  });

  if (!parseResult.meta.fields) {
    throw new Error("Missing fields definition");
  }
  const missingFields = EXPECTED_HEADER_COLUMNS.filter((field) => {
    return !parseResult.meta.fields?.includes(field);
  });
  if (missingFields.length > 0) {
    throw new Error(`Missing the following fields: ${missingFields.join(", ")}`);
  }
  return parseResult.data
    .map((record, index) => {
      return {
        ...record,
        lineInFile: index + 1 /* Index */ + 1 /* Header */,
      };
    })
    .map((row) => {
      const rowKeys = Object.keys(row);
      const missingFields = EXPECTED_HEADER_COLUMNS.filter((field) => {
        return !rowKeys.includes(field);
      });
      if (missingFields.length > 0) {
        return null;
      }
      const budget = parseInt(row["Budget"].replace(/,/g, ""), 10);
      const population = parseInt(row["Population"].replace(/,/g, ""), 10);

      return {
        id: ulid(),
        lineInFile: row.lineInFile,
        stateName: (row["State Name"] || "").trim(),
        stateAbbreviation: (row["State Abbreviation"] || "").trim(),
        governmentName: (row["Government Name"] || "").trim(),
        county: (row["County"] || "").trim(),
        governmentType: (row["Government Type"] || "").trim(),
        postalCode: (row["Postal Code"] || "").trim(),
        budget: isNaN(budget) ? null : budget,
        population: isNaN(population) ? null : population,
      };
    })
    .filter(notUndefinedOrNull)
    .filter((row) => {
      return row.governmentName.length > 0 && row.governmentType.length > 0;
    });
}

function readFileAsText(input: HTMLInputElement): Promise<string | null> {
  if (!input.files) {
    return Promise.resolve(null);
  }

  const file = input.files[0];
  if (!file) {
    return Promise.resolve(null);
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsText(file);

    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
        return;
      }
      resolve(null);
    };
    reader.onerror = () => reject(reader.error);
  });
}
