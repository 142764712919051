import gql from "graphql-tag";
import GroupFields from "./fragments/GroupFields";

export default gql`
  query GroupBySlug($slug: String!, $postsCursor: Cursor, $projectsCursor: Cursor, $membersCursor: Cursor) {
    groupBySlug(slug: $slug) {
      _id
      ...GroupFields
    }
  }

  ${GroupFields}
`;
