import React, { useState } from "react";
import { Mutation, Query } from "react-apollo";
import { IMutationChangeOnboardingStatusArgs, IProcessStatusType, IWorkPlaceType } from "../../../../../types/types";
import ChangeOnboardingStatus from "../../../../mutations/ChangeOnboardingStatus";
import { IChangeOnboardingStatusMutation } from "../../../../mutations/__generated__/ChangeOnboardingStatus.generated";
import { IUserFieldsFragment } from "../../../../queries/fragments/__generated__/UserFields.generated";
import VerifyUser from "../../VerifyUser";
import { Button } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import RemoveVerification, { RemoveCitizenVerification } from "../../RemoveUserVerification";
import { Toggle } from "react-powerplug";
import AssignUserFlow from "../../../teamManagement/AssignUserFlow";
import { IOnboardingDetails } from "../Interfaces";
import {
  IGetGovernmentByIdQuery,
  IGetGovernmentByIdQueryVariables,
} from "../../../../queries/__generated__/GetGovernmentById.generated";
import GetGovernmentById from "../../../../queries/GetGovernmentById";

interface IFinalizeOnboardingMutationsProps {
  user: IUserFieldsFragment;
  onboarding: IOnboardingDetails | null;
  refetch: () => any;
}

export default function FinalizeOnboardingMutation({ user, onboarding, refetch }: IFinalizeOnboardingMutationsProps) {
  const [assignFlowKey, setAssignFlowKey] = useState("INITIAL");

  return (
    <Mutation<IChangeOnboardingStatusMutation, IMutationChangeOnboardingStatusArgs>
      onCompleted={() => {}}
      variables={{
        userId: user._id,
        newStatus: IProcessStatusType.Verified,
      }}
      mutation={ChangeOnboardingStatus}
      refetchQueries={["UserBySlugQuery", "OnboardingsCount"]}
      awaitRefetchQueries={true}
    >
      {(changeOnboardingStatus) => (
        <>
          {!user.verified && user.role !== "CITIZEN" && (
            <VerifyUser
              user={user}
              onVerificationComplete={() => {
                changeOnboardingStatus();
                refetch();
              }}
            >
              {(toggleVerify) => (
                <Button
                  css={{
                    marginRight: 5,
                    background: palette.greenSuccess,
                    fontWeight: 500,
                  }}
                  type="button"
                  onClick={(event: { preventDefault: () => void }) => {
                    event.preventDefault();
                    toggleVerify();
                  }}
                >
                  Verify User
                </Button>
              )}
            </VerifyUser>
          )}

          {!user.verified && user.role === "CITIZEN" && (
            <RemoveCitizenVerification user={user} onComplete={refetch}>
              {(toggleRemoveCitizenVerification) => (
                <Button
                  css={{
                    marginRight: 5,
                    background: palette.red,
                    fontWeight: 500,
                  }}
                  type="button"
                  onClick={(event: { preventDefault: () => void }) => {
                    event.preventDefault();
                    toggleRemoveCitizenVerification();
                    refetch();
                  }}
                >
                  Remove Citizen Verification
                </Button>
              )}
            </RemoveCitizenVerification>
          )}

          {user.verified && user.__typename === "PendingUser" && (
            <RemoveVerification user={user}>
              {(toggleRemoveVerification) => (
                <Button
                  css={{
                    marginRight: 5,
                    background: palette.red,
                    fontWeight: 500,
                  }}
                  type="button"
                  onClick={(event: { preventDefault: () => void }) => {
                    event.preventDefault();
                    toggleRemoveVerification();
                    refetch();
                  }}
                >
                  Remove Verification
                </Button>
              )}
            </RemoveVerification>
          )}

          <Toggle>
            {({ on: isOpen, toggle: toggleAssignFlow }) => (
              <>
                {onboarding?.workingFor === IWorkPlaceType.Government && onboarding?.governmentId ? (
                  <Query<IGetGovernmentByIdQuery, IGetGovernmentByIdQueryVariables>
                    query={GetGovernmentById}
                    variables={{
                      governmentId: onboarding?.governmentId,
                    }}
                  >
                    {({ data, loading }) => {
                      return (
                        <>
                          <Button
                            disabled={loading}
                            css={{
                              marginRight: 5,
                              background: palette.greenSuccess,
                              fontWeight: 500,
                            }}
                            type="button"
                            onClick={() => {
                              toggleAssignFlow();
                            }}
                          >
                            {loading ? "Loading..." : "Assign User"}
                          </Button>

                          {data && data.governmentById && (
                            <AssignUserFlow
                              key={assignFlowKey}
                              isVisible={isOpen}
                              initialMemberAdditions={[user]}
                              initialSelectedEntity={{
                                entityType: "GOVERNMENT",
                                entitySlug: data.governmentById.slug,
                              }}
                              onRequestClose={() => {
                                setAssignFlowKey(Math.random().toString(36));
                                toggleAssignFlow();
                                refetch();
                              }}
                              onSaveChanges={() => {
                                changeOnboardingStatus();
                                refetch();
                              }}
                            />
                          )}
                        </>
                      );
                    }}
                  </Query>
                ) : (
                  <>
                    <Button
                      css={{
                        marginRight: 5,
                        background: palette.greenSuccess,
                        fontWeight: 500,
                      }}
                      type="button"
                      onClick={() => {
                        toggleAssignFlow();
                      }}
                    >
                      Assign User
                    </Button>

                    <AssignUserFlow
                      isVisible={isOpen}
                      initialMemberAdditions={[user]}
                      onRequestClose={() => {
                        toggleAssignFlow();
                        refetch();
                      }}
                      onSaveChanges={() => {
                        changeOnboardingStatus();
                        refetch();
                      }}
                      defaultSearchRefinement={onboarding?.vendorName || undefined}
                    />
                  </>
                )}
              </>
            )}
          </Toggle>
        </>
      )}
    </Mutation>
  );
}
