import * as palette from "@govlaunch/palette";
import React from "react";
import DocumentTitle from "react-document-title";
import NewAboutPageConfigForm from "./NewAboutPageConfigForm";

export default function NewAboutPageConfigPage() {
  return (
    <DocumentTitle title="New About Page Template | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
        }}
      >
        <NewAboutPageConfigForm />
      </div>
    </DocumentTitle>
  );
}
