import React from "react";
import ReactVisibilitySensor from "react-visibility-sensor";
import ProjectsTable from "../../projects/tables/ProjectsTable";
import { IGroupFieldsFragment } from "../../../queries/fragments/__generated__/GroupFields.generated";

interface IGroupProjectsTab {
  group: IGroupFieldsFragment;
  refetch: () => any;
  loading: boolean;
  fetchMore: (props: any) => any;
}

const GroupProjectsTab: React.FunctionComponent<IGroupProjectsTab> = ({ group, refetch, loading, fetchMore }) => {
  if (!group.projects || !group.projects.pageInfo || !group.projects.items) {
    return null;
  }

  return (
    <div
      css={{
        padding: "0 15px",
      }}
    >
      <ProjectsTable projects={group.projects.items} onDeleteProject={refetch} onChange={null} loading={loading} />

      <ReactVisibilitySensor
        active={Boolean(!loading && group.projects.pageInfo.hasNextPage)}
        partialVisibility={true}
        onChange={isActive => {
          if (!group.projects || !group.projects.pageInfo) {
            return null;
          }

          if (isActive) {
            fetchMore({
              variables: {
                projectsCursor: group.projects.pageInfo.endCursor,
              },
              updateQuery: (current: any, { fetchMoreResult }: any) => {
                return {
                  groupBySlug: {
                    ...current.groupBySlug,
                    projects: {
                      ...fetchMoreResult.groupBySlug.projects,
                      items: [...current.groupBySlug.projects.items, ...fetchMoreResult.groupBySlug.projects.items],
                    },
                  },
                };
              },
            });
          }
        }}
      />
    </div>
  );
};

export default GroupProjectsTab;
