import React from "react";
import { ICompanyAccountBillingInfoQuery } from "../../../queries/__generated__/CompanyAccountBillingInfoQuery.generated";
import * as palette from "@govlaunch/palette";

interface ICurrentBillingPriceProps {
  stripePlan: NonNullable<ICompanyAccountBillingInfoQuery["company"]>["stripePlan"];
  freeSubscription: boolean | null;
}

export default function CurrentBillingPirce({ stripePlan, freeSubscription }: ICurrentBillingPriceProps) {
  if (freeSubscription) {
    return (
      <span
        css={{
          color: palette.greenSuccess,
        }}
      >
        Free
      </span>
    );
  }

  if (!stripePlan) {
    return <span>N/A</span>;
  }

  return <span>${(stripePlan.price / 100).toFixed(2)}</span>;
}
