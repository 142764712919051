import React from "react";
import { Field } from "react-final-form";
import { Margin } from "../../../../spacings";
import MissionAccomplishedIcon from "../../../../components/icons/MissionAccomplishedIcon";
import SadFaceIcon from "../../../../components/icons/SadFaceIcon";

export default function OutcomeField() {
  return (
    <Field name="outcome">
      {({ input }) => {
        if (!input.value) {
          return null;
        }

        if (input.value.value === null) {
          return null;
        }

        const outcome = input.value.value;

        return (
          <Field name="outcomeStatement">
            {({ input }) => {
              const outcomeStatement = input.value;

              return (
                <div
                  css={{
                    display: "grid",
                    gridRowGap: 12,
                  }}
                >
                  {outcome && outcomeStatement ? (
                    <Margin mt={16}>
                      {outcome === "success" && (
                        <div
                          css={{
                            padding: 12,
                            borderRadius: 4,
                            position: "relative",
                            backgroundColor: "#E8FBF4",
                          }}
                        >
                          <div
                            css={{
                              position: "absolute",
                              top: -13,
                            }}
                          >
                            <MissionAccomplishedIcon width={26} height={26} />
                          </div>

                          <p
                            css={{
                              fontSize: 14,
                              lineHeight: `${14 * 1.33}px`,
                              marginTop: 8,
                              marginBottom: 8,
                              wordBreak: "break-word",
                            }}
                          >
                            {outcomeStatement}
                          </p>

                          <strong
                            css={{
                              fontSize: 13,
                              lineHeight: `${13 * 1.33}px`,
                              color: "#178C62",
                            }}
                          >
                            &mdash; Project Succeeded
                          </strong>
                        </div>
                      )}

                      {outcome === "failed" && (
                        <div>
                          <div
                            css={{
                              backgroundColor: "#FFF0F0",
                              padding: 12,
                              borderRadius: 4,
                              position: "relative",
                            }}
                          >
                            <div
                              css={{
                                position: "absolute",
                                top: -13,
                              }}
                            >
                              <SadFaceIcon width={26} height={26} />
                            </div>

                            <p
                              css={{
                                margin: 0,
                                fontSize: 14,
                                lineHeight: `${14 * 1.33}px`,
                                marginTop: 8,
                                marginBottom: 8,
                              }}
                            >
                              {outcomeStatement}
                            </p>

                            <strong
                              css={{
                                fontSize: 13,
                                lineHeight: `${13 * 1.33}px`,
                                color: "#8F2020",
                              }}
                            >
                              &mdash; Project Failed
                            </strong>
                          </div>
                        </div>
                      )}
                    </Margin>
                  ) : (
                    <>
                      {outcome === "success" && (
                        <div
                          css={{
                            display: "grid",
                            gridAutoColumns: "max-content",
                            gridAutoFlow: "column",
                            gridColumnGap: 12,
                            alignItems: "center",
                          }}
                        >
                          <MissionAccomplishedIcon width={26} height={26} />

                          <strong
                            css={{
                              fontSize: 13,
                              lineHeight: `${13 * 1.33}px`,
                              color: "#178C62",
                            }}
                          >
                            Project Succeeded
                          </strong>
                        </div>
                      )}

                      {outcome === "failed" && (
                        <div
                          css={{
                            display: "grid",
                            gridAutoColumns: "max-content",
                            gridAutoFlow: "column",
                            gridColumnGap: 12,
                            alignItems: "center",
                          }}
                        >
                          <SadFaceIcon width={26} height={26} />

                          <strong
                            css={{
                              fontSize: 14,
                              lineHeight: `${13 * 1.33}px`,
                              color: "#8F2020",
                            }}
                          >
                            Project Failed
                          </strong>
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            }}
          </Field>
        );
      }}
    </Field>
  );
}
