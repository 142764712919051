import React from "react";
import EditVendorForm from "../form/EditVendorForm";
import { IVendorQuery } from "../../../queries/__generated__/VendorQuery.generated";

interface IVendorDetailsabProps {
  vendor: IVendorQuery["company"];
}

export default function VendorDetailsTab({ vendor }: IVendorDetailsabProps) {
  return <EditVendorForm vendor={vendor} />;
}
