import { PoweroffOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import ToggleLandingPageActivation from "../../../mutations/ToggleLandingPageActivation";
import {
  IToggleActivationMutation,
  IToggleActivationMutationVariables,
} from "../../../mutations/__generated__/ToggleLandingPageActivation.generated";

interface IToggleActivationButtonProps {
  configId: string;
  onClick: any;
  active: boolean;
}

export default function ToggleActivationButton({ configId, onClick, active }: IToggleActivationButtonProps) {
  const [toggleActivation] = useMutation<IToggleActivationMutation, IToggleActivationMutationVariables>(
    ToggleLandingPageActivation,
  );

  return (
    <li>
      <Popconfirm
        title="Are you sure?"
        onConfirm={onClick(() => {
          toggleActivation({
            variables: {
              id: configId,
            },
            refetchQueries: ["GetAllLandingPages"],
          });
        })}
      >
        <Button
          size="middle"
          css={{
            color: active ? "orange" : "green",
          }}
          type="link"
          icon={<PoweroffOutlined />}
        >
          {active ? "Set Inactive" : "Set Active"}
        </Button>
      </Popconfirm>
    </li>
  );
}
