import PropTypes from "prop-types";
import { Query } from "react-apollo";
import React from "react";
import fp from "lodash/fp";
import SuggestionsCountQuery from "../../queries/SuggestionsCountQuery";
import {
  ISuggestionsCountQuery,
  ISuggestionsCountQueryVariables,
} from "../../queries/__generated__/SuggestionsCountQuery.generated";

export default function SuggestionsCount({ children }: any) {
  return (
    <Query<ISuggestionsCountQuery, ISuggestionsCountQueryVariables> query={SuggestionsCountQuery}>
      {({ data, loading, refetch }) => {
        if (!data) {
          return null;
        }

        return children({
          ...mapCounts(data.outsideProductsCount, loading),
          loading,
          refetch,
        });
      }}
    </Query>
  );
}

const mapCounts = (counts: any, loading: boolean) => {
  return fp.mapValues(count => {
    if (typeof count === "number") {
      return count;
    }

    if (loading) {
      return null;
    } else {
      return count;
    }
  })(counts);
};

SuggestionsCount.propTypes = {
  children: PropTypes.func.isRequired,
};
