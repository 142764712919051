import gql from "graphql-tag";

export default gql`
  mutation RestoreSponsorship($id: ObjectId!) {
    restoreSponsorship(id: $id) {
      _id
      slug
      archivedAt
    }
  }
`;
