import { palette } from "@govlaunch/web";
import React from "react";
import { INewsletterCollection } from "../../../../types/types";

interface ICampaignPreviewCollectionCardProps {
  collection: INewsletterCollection;
}

export default function CampaignPreviewCollectionCard({ collection }: ICampaignPreviewCollectionCardProps) {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: "inline-block",
        WebkitTextSizeAdjust: "none",
        //@ts-ignore
        msTextSizeAdjust: "none",
        textDecoration: "none",
        height: "100%",
        width: "100%",
        marginRight: "10px",
        marginTop: "10px",
      }}
      href={collection.url}
    >
      <table
        cellPadding="0"
        cellSpacing="0"
        style={{
          width: "182px",
          minWidth: "182px",
          height: "100%",
          borderRadius: "4px",
          border: "1px solid #73849d",
          borderCollapse: "collapse",
          borderSpacing: 0,
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                margin: 0,
                padding: 0,
                background: "#086bef",
                height: "28px",
              }}
            >
              <img
                src="https://mcusercontent.com/88b300557f9ef581c3b0d7bf5/images/ec38c845-aed7-27b6-b855-afd9c5cfbe33.png"
                height="24"
                style={{
                  display: "inline-block !important",
                  border: 0,
                  outline: "none",
                  textDecoration: "none",
                  verticalAlign: "middle",
                  marginLeft: "4px",
                  marginTop: "4px",
                }}
              />
            </td>
          </tr>
          <tr>
            <td
              align="center"
              style={{
                margin: 0,
                padding: 0,
                background: "#086bef",
                paddingTop: "2px",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingBottom: "10px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "20px",
                  lineHeight: "24px",
                  fontWeight: 700,
                  color: palette.white,
                  textAlign: "center",
                  height: "52px",
                  overflow: "hidden",
                }}
              >
                {collection.name}
              </p>
            </td>
          </tr>
          <tr>
            <td
              align="center"
              style={{
                margin: 0,
                padding: 0,
                height: "40px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "16px",
                  lineHeight: "21.3px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  color: "#3F4A5A",
                  textAlign: "center",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                {collection.itemsCount} {collection.itemsCount > 1 ? "items" : "item"}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </a>
  );
}
