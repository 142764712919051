import React from "react";
import { Box } from "@govlaunch/web";
import { IBlockLayout } from "./blockProps";

interface IBlockContenTitleProps {
  block: IBlockLayout;
}

export default function BlockContentTitle({ block }: IBlockContenTitleProps) {
  return (
    <Box width="100%" mb="20px" fontWeight={600} fontSize="24px" lineHeight="32px" color="#000000">
      {block.blockData["title"]}
    </Box>
  );
}
