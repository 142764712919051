import React from "react";
import * as palette from "@govlaunch/palette";

export default function Section({ children, ...props }: any) {
  return (
    <div
      css={{
        marginLeft: 15,
        marginRight: 15,
        borderRadius: 5,
        border: `1px solid ${palette.lightestGray}`,
        background: "#fff",
      }}
      {...props}
    >
      {children}
    </div>
  );
}
