import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, LoadingIndicator } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { Popconfirm } from "antd";
import React, { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router";

interface IFormSubmitSectionProps {
  backButtonText?: string;
  backButtonLocation?: string;
  discardChangesText?: string;
  discardChangesLocation?: string;
  submitButtonText?: string;
  submitting?: boolean;
  submitError?: string;
  previewButton?: ReactNode;
  renderExtraButtons?: () => ReactNode;
  sideMargin?: number;
}

export default function FormSubmitSection({
  backButtonText = "Go Back",
  backButtonLocation,
  discardChangesText = "Are you sure?",
  discardChangesLocation,
  submitButtonText = "Save",
  submitting,
  submitError,
  previewButton,
  renderExtraButtons,
  sideMargin,
}: IFormSubmitSectionProps) {
  const navigate = useNavigate();
  // ant-tabs class used in the antd tabs has an overflow property set to hidden
  // which breaks the position sticky setting
  useEffect(() => {
    const tabs = document.getElementsByClassName("ant-tabs");

    if (tabs[0]) {
      //@ts-ignore
      tabs[0].style.overflow = "unset";
    }
  }, []);

  return (
    <div
      css={{
        marginLeft: sideMargin || 15,
        marginRight: sideMargin || 15,
        paddingBottom: 8,
        paddingTop: 8,
        display: "flex",
        flexDireciton: "row",
        alignItems: "center",
        justifyContent: "space-between",
        position: "sticky",
        top: "0px",
        alignSelf: "flex-start",
        zIndex: 998,
        background: palette.washGray,
      }}
    >
      <div
        css={{
          fontSize: 16,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <a
          css={{
            color: palette.primary,
            cursor: "pointer",
            height: 42,
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            if (backButtonLocation) {
              navigate(backButtonLocation);
            } else {
              history.back();
            }
          }}
        >
          <span
            css={{
              marginRight: 5,
            }}
          >
            <FontAwesomeIcon icon={faLessThan} size="sm" />
          </span>
          <span
            css={{
              whiteSpace: "nowrap",
            }}
          >
            {backButtonText}
          </span>
        </a>

        {renderExtraButtons && renderExtraButtons()}
      </div>

      {submitError && (
        <div
          css={{
            color: palette.red,
            fontSize: 12,
            fontWeight: 700,
            justifySelf: "center",
            maxWidth: "500px",
          }}
        >
          {submitError}
        </div>
      )}

      <div
        css={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Popconfirm
          title={discardChangesText}
          onConfirm={() => {
            if (discardChangesLocation) {
              navigate(discardChangesLocation);
            } else {
              history.back();
            }
          }}
          placement="bottom"
        >
          <div>
            <Button
              color={palette.text}
              theme="secondary"
              type="button"
              flex={1}
              css={{
                width: 150,
                height: 36,
                borderColor: palette.mediumGray,
                margin: 3,
              }}
            >
              Discard Changes
            </Button>
          </div>
        </Popconfirm>

        {previewButton}

        <Button
          color={palette.primary}
          textColor="#fff"
          type="submit"
          disabled={submitting}
          css={{
            marginLeft: 18,
            width: 150,
            height: 36,
            ":disabled": {
              background: "rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          {submitButtonText}
          {submitting && (
            <div
              css={{
                marginLeft: 10,
              }}
            >
              <LoadingIndicator />
            </div>
          )}
        </Button>
      </div>
    </div>
  );
}
