import React from "react";
import { IGetAllSponsorshipsQuery } from "../../queries/__generated__/GetAllSponsorships.generated";
import Table, { TableProps } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import { Link } from "react-router-dom";
import SponsorshipsActions from "./SponsorshipsActions";
import { IAllSponsorshipsFilter } from "../../../types/types";
import moment from "moment";
import notUndefinedOrNull from "../../utils/notUndefinedOrNull";

export type TSponsorship = NonNullable<NonNullable<IGetAllSponsorshipsQuery["allSponsorships"]>["items"]>[0];

export interface ISponsorshipsTableState {
  sortColumnKey: string | null;
  sortColumnOrder: SortOrder | null;
  limit: number | null;
  page: number | null;
}

interface ISponsorshipsTableProps {
  sponsorships: TSponsorship[];
  loading: boolean;
  state: ISponsorshipsTableState;
  refetch: () => any;
  filter?: IAllSponsorshipsFilter;
  onChange?: (pagination: any, filters: any, sorter: any) => any;
  footer?: TableProps<any>["footer"];
}

export default function SponsorshipsTable({
  sponsorships,
  loading,
  state,
  refetch,
  filter = IAllSponsorshipsFilter.All,
  onChange,
  footer,
}: ISponsorshipsTableProps) {
  return (
    <Table<TSponsorship>
      bordered={true}
      dataSource={sponsorships}
      rowKey="_id"
      loading={loading}
      pagination={false}
      style={{
        backgroundColor: palette.white,
      }}
      onChange={onChange}
      footer={footer}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          sorter: true,
          sortOrder:
            state.sortColumnKey && state.sortColumnKey === "name" && state.sortColumnOrder
              ? state.sortColumnOrder
              : undefined,
          render: (_: string, sponsorship: TSponsorship) => {
            return (
              <Link to={`/sponsorships/${sponsorship.slug}`}>
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture
                    size={32}
                    image={sponsorship.logo}
                    name={sponsorship.name}
                    css={{
                      whiteSpace: "nowrap",
                    }}
                  />

                  <Spacing left={4}>{sponsorship.name}</Spacing>
                </div>
              </Link>
            );
          },
        },
        {
          title: "Link",
          dataIndex: "link",
          render: (link: string) => {
            return link ? (
              <a href={link} target="_blank" rel="noreferrer noopener">
                {link}
              </a>
            ) : (
              "N/A"
            );
          },
        },
        {
          title: "# of Vendors",
          dataIndex: "vendorsCount",
          key: "vendorsCount",
          sorter: true,
          sortOrder:
            state.sortColumnKey && state.sortColumnKey === "vendorsCount" && state.sortColumnOrder
              ? state.sortColumnOrder
              : undefined,
        },
        filter === IAllSponsorshipsFilter.Archived
          ? {
              title: "Archived At",
              render: (sponsorship: TSponsorship) => moment(sponsorship.archivedAt).format("MM/DD/YYYY"),
            }
          : null,
        {
          title: "Actions",
          render: (sponsorship: TSponsorship) => {
            return <SponsorshipsActions sponsorship={sponsorship} refetch={refetch} />;
          },
        },
      ].filter(notUndefinedOrNull)}
    />
  );
}
