import gql from "graphql-tag";

export default gql`
  query AutoCompleteUsers($searchQuery: String!, $limit: Int!, $scopes: [UserSearchScope!]!) {
    autoCompleteUsers(searchQuery: $searchQuery, limit: $limit, scopes: $scopes) {
      ... on PendingUser {
        _id
        slug
        fullName
        avatar
        jobTitle
        email
        notificationEmail
        isSiteAdmin
      }
      ... on VendorUser {
        _id
        slug
        fullName
        avatar
        jobTitle
        email
        notificationEmail
        title
        isSiteAdmin
      }
      ... on GovernmentUser {
        _id
        slug
        fullName
        avatar
        jobTitle
        email
        notificationEmail
        title
        isSiteAdmin
      }
    }
  }
`;
