import React from "react";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Link } from "react-router-dom";
import { Button } from "@govlaunch/core";
import { message, Modal } from "antd";
import UserAutoComplete from "../../teamManagement/UserAutoComplete";
import { Value } from "react-powerplug";
import { Margin } from "../../../spacings";
import { TStoryAuthor } from "./StoryAuthorCard";

interface IEditCoauthorsModalProps {
  visible: boolean;
  onCloseModal: any;
  onAddCoauthors: any;
  currentCoauthors: TStoryAuthor[] | null;
  vendorsCount: number;
}

export default function EditCoauthorsModal({
  visible,
  onAddCoauthors,
  onCloseModal,
  currentCoauthors,
  vendorsCount,
}: IEditCoauthorsModalProps) {
  return (
    <Modal width={480} title="Add co-author" visible={visible} onCancel={onCloseModal} footer={null}>
      <Value<TStoryAuthor[] | null> initial={currentCoauthors}>
        {({ value: coauthors, set: setCoauthors }) => {
          return (
            <div
              css={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <UserAutoComplete
                onSelect={(selectedUser) => {
                  if ((coauthors || []).length + vendorsCount >= 5) {
                    message.warning("You cannot select more than five co-authors...");
                    return;
                  }

                  const alreadyInCoauthors = (coauthors || []).some((coauthor) => {
                    return coauthor._id === selectedUser._id;
                  });

                  if (!alreadyInCoauthors) {
                    setCoauthors((coauthors || []).concat(selectedUser));
                  }
                }}
              />

              <EditableCoauthorsList coauthors={coauthors} onChange={setCoauthors} />

              <Margin
                mt={8}
                css={{
                  alignSelf: "center",
                }}
              >
                <Button
                  color={palette.primary}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    onAddCoauthors(coauthors);
                    onCloseModal();
                  }}
                >
                  Confirm
                </Button>
              </Margin>
            </div>
          );
        }}
      </Value>
    </Modal>
  );
}

interface IEditableCoauthorsListProps {
  coauthors: TStoryAuthor[] | null;
  onChange: any;
}

function EditableCoauthorsList({ coauthors, onChange }: IEditableCoauthorsListProps) {
  if (!coauthors || !Array.isArray(coauthors) || coauthors.length === 0) {
    return null;
  }

  return (
    <div
      css={{
        padding: 8,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {coauthors.map((coauthor: TStoryAuthor, index: number) => {
        return (
          <div
            key={`${index}`}
            css={{
              marginTop: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Link to={`/users/${coauthor.slug}`}>
              <div
                css={{
                  marginTop: 8,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ProfilePicture size={48} image={coauthor.avatar} name={coauthor.fullName} title={coauthor.fullName} />
                <Margin ml={8}>{coauthor.fullName}</Margin>
              </div>
            </Link>

            <button
              type="button"
              css={{
                cursor: "pointer",
                type: "button",
                width: 40,
                height: 40,
                lineHeight: "20px",
                background: palette.red,
                color: "#fff",
                border: 0,
                borderRadius: "100%",
              }}
              onClick={() =>
                onChange((coauthors || []).filter((currentCoauthor) => currentCoauthor._id !== coauthor._id))
              }
            >
              &times;
            </button>
          </div>
        );
      })}
    </div>
  );
}
