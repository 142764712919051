import * as palette from "@govlaunch/palette";
import React, { createRef } from "react";
import { Value } from "react-powerplug";
import { Margin } from "../../../spacings";

interface ICertificationsInputProps {
  value: string[];
  maxItems: number;
  characterLimit: number;
  onChange: (highlights: any) => void;
  [key: string]: any;
}

export default function CertificationsInput({
  value,
  onChange,
  maxItems,
  characterLimit,
  ...props
}: ICertificationsInputProps) {
  const addInputRef = createRef<HTMLInputElement>();
  const certificationsRefs = value.map(() => createRef<HTMLInputElement>());

  return (
    <div
      css={{
        height: "100%",
        backgroundColor: "rgba(37, 192, 136, 0.05)",
      }}
      {...props}
    >
      <ul
        css={{
          listStyle: "none",
          margin: 0,
          padding: 0,
        }}
      >
        {value.map((certification, index) => (
          <li
            // eslint-disable-next-line
            key={index}
            css={{
              display: "flex",
              alignItems: "center",
              "&:not(:first-of-type)": {
                marginTop: 10,
              },
            }}
          >
            <div
              css={{
                marginRight: 10,
                display: "inline",
              }}
            >
              <div
                css={{
                  width: 13,
                  textAlign: "center",
                }}
              >
                <HighlightBullet />
              </div>
            </div>

            <input
              ref={certificationsRefs[index]}
              maxLength={characterLimit}
              placeholder={certification === "" ? "Press Backspace to remove" : "Type another certification..."}
              css={{
                backgroundColor: palette.white,
                border: `solid 1px ${palette.lightestGray}`,
                fontSize: 14,
                height: 48,
                paddingLeft: 20,
                paddingRight: 20,
                letterSpacing: 0.5,
                color: palette.mediumGray,
                lineHeight: 1.14,
                width: "95%",
                outline: "none",
                borderRadius: 4,
                "&:focus": {
                  border: `solid 1px ${palette.primary}`,
                },
              }}
              value={certification}
              onChange={(e) => {
                onChange(
                  value.map((value, maybeIndex) => {
                    return index === maybeIndex ? e.target.value : value;
                  }),
                );
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();

                  if (e.currentTarget.value !== "" && addInputRef.current) {
                    addInputRef.current.focus();
                  }
                }

                if (e.key === "Backspace" && e.currentTarget.value === "") {
                  e.preventDefault();

                  const nextItems = value.filter((_, maybeIndex) => {
                    return maybeIndex !== index;
                  });

                  onChange(nextItems);

                  if (nextItems.length === 0 && addInputRef.current) {
                    addInputRef.current.focus();
                  }

                  if (certificationsRefs[index - 1] && certificationsRefs[index - 1].current !== null) {
                    (certificationsRefs[index - 1].current as HTMLInputElement).focus();
                  }
                }
              }}
            />

            <Margin ml={10}>
              <div
                css={{
                  width: 30,
                  textAlign: "left",
                }}
              >
                <CharacterCount>{characterLimit - certification.length}</CharacterCount>
              </div>
            </Margin>
          </li>
        ))}

        {value.length < maxItems && (
          <Value initial="">
            {({ value: certification, set: setValue }) => (
              <li
                css={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: value.length > 0 ? 10 : undefined,
                }}
              >
                <div
                  css={{
                    display: "inline",
                    marginRight: 10,
                  }}
                >
                  <div
                    css={{
                      width: 13,
                      height: 13,
                      textAlign: "center",
                      color: palette.sealBlue,
                      fontSize: 13,
                      cursor: "pointer",
                    }}
                    role="button"
                    aria-label="Add certification"
                    onClick={(event) => {
                      event.preventDefault();

                      if (certification.trim() !== "") {
                        setValue("");
                        onChange([...value, certification.trim()]);
                      }

                      if (addInputRef.current) {
                        addInputRef.current.focus();
                      }
                    }}
                  >
                    <svg width={15} height={15}>
                      <path
                        d="M13.929 6.429H8.57V1.07a1.072 1.072 0 0 0-2.142 0V6.43H1.07a1.072 1.072 0 0 0 0 2.142H6.43v5.358a1.072 1.072 0 0 0 2.142 0V8.57h5.358a1.072 1.072 0 0 0 0-2.142z"
                        fill="currentColor"
                        fillRule="nonzero"
                      />
                    </svg>
                  </div>
                </div>

                <input
                  ref={addInputRef}
                  maxLength={characterLimit}
                  placeholder="Type another certification..."
                  css={{
                    backgroundColor: palette.white,
                    border: `solid 1px ${palette.lightestGray}`,
                    fontSize: 14,
                    height: 48,
                    paddingLeft: 20,
                    paddingRight: 20,
                    letterSpacing: 0.5,
                    color: palette.mediumGray,
                    lineHeight: 1.14,
                    width: "95%",
                    outline: "none",
                    borderRadius: 4,
                    "&:focus": {
                      border: `solid 1px ${palette.primary}`,
                    },
                  }}
                  value={certification}
                  onChange={(e) => setValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();

                      if (certification.trim() !== "") {
                        setValue("");
                        onChange([...value, certification.trim()]);
                      }
                    } else if (e.key === "Backspace" && certification.trim() === "" && certificationsRefs.length > 1) {
                      (certificationsRefs[certificationsRefs.length - 1].current as HTMLInputElement).focus();
                    }
                  }}
                />

                <Margin ml={10}>
                  <div
                    css={{
                      width: 30,
                      textAlign: "left",
                    }}
                  >
                    <CharacterCount>{characterLimit - certification.length}</CharacterCount>
                  </div>
                </Margin>
              </li>
            )}
          </Value>
        )}
      </ul>
    </div>
  );
}

function CharacterCount(props: any) {
  return (
    <span
      css={{
        fontSize: 14,
        lineHeight: 1.71,
        color: palette.sealBlue,
        marginLeft: "auto",
      }}
      {...props}
    />
  );
}

function HighlightBullet(props: any) {
  return (
    <span
      css={{
        backgroundColor: palette.primary,
        width: 10,
        height: 10,
        display: "inline-block",
        borderRadius: "100%",
      }}
      {...props}
    />
  );
}
