import React from "react";
import { Query } from "react-apollo";
import fp from "lodash/fp";
import GetGroupsCount from "../../queries/GetGroupsCount";
import {
  IGetGroupsCountQuery,
  IGetGroupsCountQueryVariables,
} from "../../queries/__generated__/GetGroupsCount.generated";

export default function GroupsCount({ children }: any) {
  return (
    <Query<IGetGroupsCountQuery, IGetGroupsCountQueryVariables> query={GetGroupsCount}>
      {({ data, loading, refetch }) => {
        if (!data) {
          return null;
        }

        return children({
          ...mapCounts(data.groupsCount, loading),
          refetch,
        });
      }}
    </Query>
  );
}

const mapCounts = (counts: any, loading: boolean) => {
  return fp.mapValues(count => {
    if (typeof count === "number") {
      return count;
    }

    if (loading) {
      return null;
    } else {
      return count;
    }
  })(counts);
};
