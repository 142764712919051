import React from "react";
import { Mutation } from "react-apollo";
import RestoreSponsorship from "../../../mutations/RestoreSponsorship";
import {
  IRestoreSponsorshipMutation,
  IRestoreSponsorshipMutationVariables,
} from "../../../mutations/__generated__/RestoreSponsorship.generated";

interface IRestoreProps {
  id: any;
  refetch: () => any;
}

export default function Restore({ id, refetch }: IRestoreProps) {
  return (
    <Mutation<IRestoreSponsorshipMutation, IRestoreSponsorshipMutationVariables>
      mutation={RestoreSponsorship}
      variables={{
        id: id,
      }}
      refetchQueries={["SponsorshipsCount"]}
    >
      {restore => (
        <li>
          <a
            href="#"
            onClick={() =>
              restore().then(() => {
                return refetch();
              })
            }
          >
            Restore
          </a>
        </li>
      )}
    </Mutation>
  );
}
