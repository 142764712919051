import React from "react";
import { IGetTagBySlugQuery } from "../../../../queries/__generated__/GetTagBySlug.generated";
import TagEdit from "../TagEdit";

interface ITagDetailsTabProps {
  tag: IGetTagBySlugQuery["tag"];
}

export default function TagDetailsTab({ tag }: ITagDetailsTabProps) {
  if (!tag) {
    return null;
  }

  return <TagEdit tag={tag} />;
}
