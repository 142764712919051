import Modal from "antd/lib/modal/Modal";
import notification from "antd/lib/notification";
import React from "react";
import { IGovernmentProjectFieldsFragment } from "../../../queries/fragments/__generated__/GovernmentProjectFields.generated";
import { IGroupProjectFieldsFragment } from "../../../queries/fragments/__generated__/GroupProjectFields.generated";
import { IVendorProjectFieldsFragment } from "../../../queries/fragments/__generated__/VendorProjectFields.generated";
import BulkTaggingForm from "./BulkTaggingForm";

interface IProjectsAssignTagsModalProps {
  projects: Array<IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment>;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export function ProjectsAssignTagsModal({ projects, isOpen, onClose, onSuccess }: IProjectsAssignTagsModalProps) {
  return (
    <Modal title="Manage tags" visible={isOpen} onCancel={onClose} footer={null} width={600} destroyOnClose={true}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BulkTaggingForm
          projects={projects}
          submitDisabled={false}
          onSuccess={() => {
            onSuccess();
            notification.success({
              message: "Success!",
              description: `Projects modified!`,
            });
          }}
          onError={() => {
            notification.error({
              message: "Error!",
              description: "Something went wrong!",
            });
          }}
        />
      </div>
    </Modal>
  );
}
