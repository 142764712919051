import gql from "graphql-tag";

export default gql`
  mutation EditGroup($id: ObjectId!, $group: GroupInput!) {
    updateGroup(id: $id, group: $group) {
      _id
      name
      slug
    }
  }
`;
