import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { Margin } from "../../../spacings";
import { Filestack } from "@govlaunch/core";
import { Button, Typography } from "antd";
import { IFormFieldProps } from "./FormFieldProps";

interface IFileUploaded {
  filename: string;
  mimetype: string;
  size: string | number;
  url: string;
}

interface IFilestackUploadField extends IFormFieldProps {
  recommendedDimensions?: string;
  uploadMultiple?: boolean;
  showImage?: boolean;
  uploadButtonText?: string;
  imageWidth?: number;
  imageHeight?: number;
  rounded?: boolean;
  centered?: boolean;
  removeButtonOnImage?: boolean;
}

export default function FilestackUploadField({
  fieldName,
  label,
  headerColor,
  recommendedDimensions,
  uploadMultiple,
  showImage = true,
  uploadButtonText = "Upload",
  imageWidth,
  imageHeight,
  rounded,
  centered,
  removeButtonOnImage,
}: IFilestackUploadField) {
  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {label && (
              <label
                css={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: headerColor || palette.mediumGray,
                  margin: 0,
                }}
              >
                {label}
              </label>
            )}

            <FieldValidationError meta={meta} />
          </div>

          {showImage && (
            <div
              css={
                centered
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: imageHeight ? imageHeight : undefined,
                    }
                  : {
                      width: imageWidth ? imageWidth : undefined,
                      height: imageHeight ? imageHeight : undefined,
                    }
              }
            >
              {input.value && (
                <div
                  css={{
                    position: "relative",
                  }}
                >
                  <img
                    src={input.value}
                    css={{
                      border: `1px solid ${palette.lightestGray}`,
                      borderRadius: rounded ? 25 : 5,
                      maxWidth: imageWidth ? imageWidth : "100%",
                      height: imageHeight ? imageHeight : undefined,
                      objectFit: "contain",
                    }}
                  />
                  {removeButtonOnImage && (
                    <div
                      css={{
                        position: "absolute",
                        top: -4,
                        right: -4,
                        width: imageWidth ? imageWidth / 8 : 20,
                        height: imageWidth ? imageWidth / 8 : 20,
                        borderRadius: 25,
                        background: palette.red,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 600,
                        cursor: "pointer",
                        "&:hover": {
                          top: -5,
                          right: -5,
                          width: imageWidth ? imageWidth / 8 + 2 : 20,
                          height: imageWidth ? imageWidth / 8 + 2 : 20,
                        },
                      }}
                      onClick={() => {
                        input.onChange(null);
                      }}
                    >
                      X
                    </div>
                  )}
                </div>
              )}
              {!input.value && (
                <div
                  css={{
                    marginTop: 8,
                  }}
                >
                  Upload an image...
                </div>
              )}
            </div>
          )}

          <Margin mt={8}>
            <Filestack
              onSuccess={({ filesUploaded }: { filesUploaded: IFileUploaded[] }) => {
                if (uploadMultiple) {
                  input.onChange(
                    (input.value || []).concat({
                      src: filesUploaded[0].url,
                      alt: "",
                    }),
                  );
                } else {
                  input.onChange(filesUploaded[0].url);
                }
              }}
              customRender={({ onPick }: any) => (
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: 8,
                  }}
                >
                  <div>
                    <Button
                      htmlType="button"
                      onClick={onPick}
                      css={{
                        marginRight: 8,
                      }}
                    >
                      {uploadButtonText}
                    </Button>

                    {input.value && !removeButtonOnImage && (
                      <Button
                        danger={true}
                        type="primary"
                        onClick={() => {
                          input.onChange(null);
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                  {recommendedDimensions && (
                    <Typography.Text type="warning">Recommended dimensions: {recommendedDimensions}</Typography.Text>
                  )}
                </div>
              )}
            />
          </Margin>
        </div>
      )}
    </Field>
  );
}
