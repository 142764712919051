import React from "react";
import { IGetSponsorshipQuery } from "../../../queries/__generated__/GetSponsorship.generated";

import DocumentTitle from "react-document-title";
import { Tabs } from "antd";
import SponsorshipDetailsTab from "./SponsorshipDetailsTab";
import * as palette from "@govlaunch/palette";
import SponsorshipVendorsTab from "./SponsorshipVendorsTab";
import { useNavigate, useParams } from "react-router";

interface ISponsorshipTabsProps {
  sponsorship: NonNullable<IGetSponsorshipQuery["sponsorship"]>;
}

export default function SponsorshipTabs({ sponsorship }: ISponsorshipTabsProps) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <DocumentTitle title={`Admin | Edit • ${sponsorship.name}`}>
      <Tabs
        tabBarStyle={{
          backgroundColor: palette.white,
          paddingLeft: 20,
          paddingRight: 20,
          margin: 0,
        }}
        activeKey={params.tab || "details"}
        onChange={(tab) => navigate(`/sponsorships/${sponsorship.slug}${tab !== "details" ? `/${tab}` : ""}`)}
      >
        <Tabs.TabPane tab="Details" key="details">
          <SponsorshipDetailsTab sponsorship={sponsorship} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Vendors" key="vendors">
          <SponsorshipVendorsTab sponsorship={sponsorship} />
        </Tabs.TabPane>
      </Tabs>
    </DocumentTitle>
  );
}
