import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, notification } from "antd";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Mutation } from "react-apollo";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import { IUserRole, IUserSearchScope } from "../../../../types/types";
import EditableGovernmentMembers from "../EditableGovernmentMembers";
import EditGovernmentMembers from "../graphql/mutations/EditGovernmentMembers";
import {
  IEditGovernmentMembersMutation,
  IEditGovernmentMembersMutationVariables,
} from "../graphql/mutations/__generated__/EditGovernmentMembers.generated";
import { TGovernment, TGovernmentMember } from "../types";
import UserAutoComplete from "../UserAutoComplete";

interface IEditGovernmentMembersModalProps {
  visible: boolean;
  government: TGovernment;
  initialMemberAdditions?: TGovernmentMember[];
  displayBackButton?: boolean;
  onSaveChanges?: () => void;
  onRequestClose?: () => void;
  onRequestBack?: () => void;
}

export default function EditGovernmentMembersModal({
  visible,
  government,
  displayBackButton,
  initialMemberAdditions = [],
  onSaveChanges,
  onRequestClose,
  onRequestBack,
}: IEditGovernmentMembersModalProps) {
  const linkToGovernment = (
    <Link to={`/governments/${government.slug}/members`}>
      <strong>{government.city.name}</strong>
    </Link>
  );

  const title = <>Manage {linkToGovernment} members</>;

  const members: TGovernmentMember[] = government.members;

  const safeInitialMembers = members.concat(
    initialMemberAdditions
      .filter((candidate) => {
        return !members.some((member) => {
          return member._id === candidate._id;
        });
      })
      .map((member) => {
        return {
          ...member,
          role: "GOV_USER",
        };
      }),
  );

  return (
    <Mutation<IEditGovernmentMembersMutation, IEditGovernmentMembersMutationVariables>
      mutation={EditGovernmentMembers}
      refetchQueries={["AllUsers", "UsersCount", "OnboardingsCount", "ListOnboardings"]}
      awaitRefetchQueries={true}
    >
      {(editGovernmentMembers) => (
        <Form
          initialValues={{
            members: safeInitialMembers,
          }}
          onSubmit={async ({ members }) => {
            try {
              await editGovernmentMembers({
                variables: {
                  governmentId: government._id,
                  members: members.map((member: TGovernmentMember) => {
                    return {
                      userId: member._id,
                      role: member.role === "GOV_OWNER" ? IUserRole.Owner : IUserRole.User,
                    };
                  }),
                },
              });

              if (onSaveChanges) {
                onSaveChanges();
              }

              notification.open({
                type: "success",
                message: "Success!",
                description: (
                  <>
                    Team of <strong>{government.city.name}</strong> has been modified
                  </>
                ),
              });
            } catch (err) {
              return {
                [FORM_ERROR]: err.message,
              };
            }
          }}
        >
          {({ submitting, submitError, handleSubmit }) => (
            <Modal width={720} title={title} visible={visible} onCancel={onRequestClose} footer={null}>
              <form onSubmit={handleSubmit}>
                <Field<TGovernmentMember[]> name="members">
                  {({ input }) => (
                    <UserAutoComplete
                      scopes={[IUserSearchScope.Pending, IUserSearchScope.Government, IUserSearchScope.Citizen]}
                      onSelect={(selectedUser) => {
                        const alreadyInMembers = input.value.some((candidate) => {
                          return candidate._id === selectedUser._id;
                        });

                        if (!alreadyInMembers) {
                          input.onChange(
                            input.value.concat({
                              ...selectedUser,
                              role: "GOV_USER",
                            }),
                          );
                        }
                      }}
                    />
                  )}
                </Field>

                <div
                  css={{
                    margin: "24px 0",
                  }}
                >
                  <EditableGovernmentMembers government={government} />
                </div>

                <Field<TGovernmentMember[]> name="members">
                  {({ input }) => {
                    const hasOwner = input.value.some((member) => member.role === "GOV_OWNER");
                    const currentOwner = government.members.find((member) => member.role === "GOV_OWNER");

                    if (currentOwner && !hasOwner) {
                      const message = (
                        <>
                          Previously <strong>{currentOwner.fullName}</strong> was the owner. If you confirm,{" "}
                          <strong>{government.city.name}</strong> will be ownerless.
                        </>
                      );

                      return <Alert message={message} type="warning" showIcon={true} />;
                    }

                    return null;
                  }}
                </Field>

                {submitError && (
                  <Alert
                    message={<strong>Couldn't save changes</strong>}
                    description={`There was an error when saving the changes: ${submitError
                      .replace("GraphQL error:", "")
                      .trim()}`}
                    type="error"
                    closable={true}
                  />
                )}

                <div
                  css={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  {displayBackButton ? (
                    <div
                      css={{
                        textAlign: "left",
                      }}
                    >
                      <Button
                        key="submit"
                        type="dashed"
                        size="large"
                        icon={<ArrowLeftOutlined />}
                        onClick={() => {
                          if (onRequestBack) {
                            onRequestBack();
                          } else if (onRequestClose) {
                            onRequestClose();
                          }
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  ) : (
                    <div />
                  )}

                  <div
                    css={{
                      textAlign: "right",
                    }}
                  >
                    <Button
                      key="submit"
                      type="primary"
                      size="large"
                      icon={<SaveOutlined />}
                      htmlType="submit"
                      loading={submitting}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Modal>
          )}
        </Form>
      )}
    </Mutation>
  );
}
