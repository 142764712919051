import React from "react";
import { Field } from "react-final-form";
import { ICompany } from "../../../../../types/types";
import VendorItem from "../../rich/VendorItem";
import * as palette from "@govlaunch/palette";
import nullthrows from "../../nullthrows";

export default function VendorsField() {
  return (
    <Field name="vendors">
      {({ input }) => {
        const vendors = nullthrows(input.value);

        if (vendors.length === 0) {
          return null;
        }

        return (
          <>
            <h5
              css={{
                margin: 0,
                fontWeight: 600,
                fontSize: 16,
                color: palette.darkGray,
              }}
            >
              Vendors
            </h5>

            <div
              css={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: 10,
                rowGap: 10,
              }}
            >
              {input.value.map((vendor: Pick<ICompany, "_id" | "name" | "logo" | "description">) => {
                return (
                  <VendorItem
                    key={vendor._id}
                    vendor={vendor}
                    onRemove={(vendor: Pick<ICompany, "_id" | "name" | "logo" | "description">) => {
                      input.onChange(
                        input.value.filter((current: ICompany) => {
                          return current._id !== vendor._id;
                        }),
                      );
                    }}
                  />
                );
              })}
            </div>
          </>
        );
      }}
    </Field>
  );
}
