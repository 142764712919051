import fp from "lodash/fp";
import React from "react";
import { Field } from "react-final-form";
import { IProjectLinkItem } from "../../../../../types/types";
import nullthrows from "../../nullthrows";
import LinkItem from "../../rich/LinkItem";
import * as palette from "@govlaunch/palette";

export default function LinksField() {
  return (
    <Field name="links">
      {({ input }) => {
        const links = nullthrows(input.value);

        if (links.length === 0) {
          return null;
        }

        return (
          <>
            <h5
              css={{
                margin: 0,
                fontWeight: 600,
                fontSize: 16,
                color: palette.darkGray,
              }}
            >
              Links
            </h5>

            <div
              css={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: 10,
                rowGap: 10,
              }}
            >
              {input.value.map((link: IProjectLinkItem) => {
                return (
                  <LinkItem
                    key={link.url!}
                    link={link}
                    onRemove={(link: IProjectLinkItem) => input.onChange(fp.reject(link, input.value))}
                  />
                );
              })}
            </div>
          </>
        );
      }}
    </Field>
  );
}
