import { IAboutPageConfigFieldsFragment } from "./graphql/fragments/__generated__/AboutPageConfigFields.generated";
import { ICreateAboutPageConfigMutationVariables } from "./graphql/mutations/__generated__/CreateAboutPageConfigMutation.generated";
import { IUpdateAboutPageConfigMutationVariables } from "./graphql/mutations/__generated__/UpdateAboutPageConfigMutation.generated";
import omit from "lodash/fp/omit";

export function convertAboutPageConfigToInitialValues(aboutPage: IAboutPageConfigFieldsFragment) {
  const { config, active } = aboutPage;
  return {
    name: aboutPage.name,

    bannerBackgroundDescription: config.bannerBackgroundDescription,
    bannerBackgroundSrc: config.bannerBackgroundSrc,
    bannerContentType: config.bannerContentType,
    bannerContentText: config.bannerContentText,
    bannerContentSize: config.bannerContentSize,
    bannerContentDescription: config.bannerContentDescription,

    headingText: config.headingText,

    headingTextFontColor: config.headingTextFontColor,

    subtitleText: config.subtitleText,

    subtitleTextFontColor: config.subtitleTextFontColor,

    leftButtonText: config.leftButtonText,
    leftButtonBackgroundColor: config.leftButtonBackgroundColor,
    leftButtonHoverColor: config.leftButtonHoverColor,
    leftButtonBorderColor: config.leftButtonBorderColor,
    leftButtonTextColor: config.leftButtonTextColor,
    leftButtonHref: config.leftButtonHref,

    rightButtonText: config.rightButtonText,
    rightButtonBackgroundColor: config.rightButtonBackgroundColor,
    rightButtonHoverColor: config.rightButtonHoverColor,
    rightButtonBorderColor: config.rightButtonBorderColor,
    rightButtonTextColor: config.rightButtonTextColor,
    rightButtonHref: config.rightButtonHref,

    boxesSectionBackgroundColor: config.boxesSectionBackgroundColor,
    leftBox: {
      ...config.leftBox,
      type: config.leftBox?.__typename === "CarouselSideContent" ? "carousel" : "text",
      images: config.leftBox?.__typename === "CarouselSideContent" ? config.leftBox?.images || [] : [],
    },
    rightBox: {
      ...config.rightBox,
      type: config.rightBox?.__typename === "CarouselSideContent" ? "carousel" : "text",
      images: config.rightBox?.__typename === "CarouselSideContent" ? config.rightBox?.images || [] : [],
    },
    videoSectionBackground: config.videoSectionBackground,
    videoSectionTitle: config.videoSectionTitle,
    videoSectionFontColor: config.videoSectionFontColor,

    videoSectionContentType: config.videoSectionContentType,
    videoSectionContentText: config.videoSectionContentText,

    joinSectionBackground: config.joinSectionBackground,
    joinSectionTitle: config.joinSectionTitle,
    joinSectionTitleFontColor: config.joinSectionTitleFontColor,
    joinSectionSubtitle: config.joinSectionSubtitle,
    joinSectionSubtitleFontColor: config.joinSectionSubtitleFontColor,
    joinSectionButtonText: config.joinSectionButtonText,
    joinSectionButtonHref: config.joinSectionButtonHref,
    joinSectionButtonColor: config.joinSectionButtonColor,
    joinSectionButtonFontColor: config.joinSectionButtonFontColor,

    logoSectionBackgroundColor: config.logoSectionBackgroundColor,
    logoUrls: config.logoUrls.map(({ imageUrl, url, description }) => ({
      imageUrl,
      url,
      description,
    })),
    active,
  };
}

export function convertValuesToVariables({
  active,
  ...values
}: any): ICreateAboutPageConfigMutationVariables | IUpdateAboutPageConfigMutationVariables {
  return {
    config: {
      ...values,
      leftBox: {
        ...omit(["__typename", "type"], values.leftBox),
        images: values.leftBox.type === "carousel" ? (values.leftBox.images || []).map(omit(["__typename"])) : [],
        width: values.leftBox.width ? parseInt(values.leftBox.width, 10) : null,
        height: values.leftBox.width ? parseInt(values.leftBox.width, 10) : null,
      },
      rightBox: {
        ...omit(["__typename", "type"], values.rightBox),
        images: values.rightBox.type === "carousel" ? (values.rightBox.images || []).map(omit(["__typename"])) : [],
        width: values.rightBox.width ? parseInt(values.rightBox.width, 10) : null,
        height: values.rightBox.width ? parseInt(values.rightBox.width, 10) : null,
      },
      logoUrls: values.logoUrls || [],
    },
    active,
  };
}
