import gql from "graphql-tag";

export default gql`
  mutation EditCountryTimezone($countryTimezoneId: ObjectId!, $data: CountryTimezoneInput!) {
    editCountryTimezone(countryTimezoneId: $countryTimezoneId, data: $data) {
      _id
      countryCode
      countryName
      timezone
    }
  }
`;
