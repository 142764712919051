import { CSSReset, theme, ThemeProvider } from "@govlaunch/web";
import { notification } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import { Form } from "react-final-form";
import { useNavigate } from "react-router";
import EditLandingPageEvent from "../../../mutations/EditLandingPageEvent";
import {
  IEditLandingPageEventMutation,
  IEditLandingPageEventMutationVariables,
} from "../../../mutations/__generated__/EditLandingPageEvent.generated";
import { IGetLandingPageEventByIdQuery } from "../../../queries/__generated__/GetLandingPageEventById.generated";
import validate from "../../../validate";
import eventFormValidationSchema from "./eventFormValidationSchema";
import LandingPageEventForm from "./LandingPageEventForm";

type TEvent = NonNullable<IGetLandingPageEventByIdQuery["getLandingPageEventById"]>;

interface IEditLandingPageEventFormProps {
  event: TEvent;
}

export default function EditLandingPageEventForm({ event }: IEditLandingPageEventFormProps) {
  const navigate = useNavigate();
  const [editLandingPageEvent] = useMutation<IEditLandingPageEventMutation, IEditLandingPageEventMutationVariables>(
    EditLandingPageEvent,
  );

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Form
        keepDirtyOnReinitialize={true}
        initialValues={{
          title: event.title,
          url: event.url,
          location: event.location,
          startDate: event.startDate,
          endDate: event.endDate,
        }}
        validate={validate(eventFormValidationSchema)}
        onSubmit={async (values) => {
          const result = await editLandingPageEvent({
            variables: {
              id: event._id,
              data: {
                title: values.title,
                url: values.url,
                location: values.location,
                startDate: values.startDate,
                endDate: values.endDate,
              },
            },
          });

          if (result && result.data && result.data.editLandingPageEvent) {
            notification.success({
              message: "Success!",
              description: "Event saved successfully",
            });

            navigate("/events");
          }
        }}
        subscription={{
          submitting: true,
          submitError: true,
        }}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <LandingPageEventForm onSubmit={handleSubmit} submitting={submitting} submitError={submitError} />
        )}
      </Form>
    </ThemeProvider>
  );
}
