import * as palette from "@govlaunch/palette";
import React from "react";
import { Field } from "react-final-form";
import HighlightsInput from "../../../components/form/HighlightsInput";

export default function HighlightsInputField() {
  return (
    <Field name="highlights">
      {({ input }) => (
        <HighlightsInput
          {...input}
          maxItems={5}
          characterLimit={200}
          bulletColor={palette.primary}
          inputBorderColor={palette.primary}
          style={{
            backgroundColor: "#FFFFFF",
          }}
        />
      )}
    </Field>
  );
}
