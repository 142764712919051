import * as palette from "@govlaunch/palette";
import PropTypes from "prop-types";
import React from "react";
import { Field } from "react-final-form";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";
import FieldValidationError from "../../../components/form/FieldValidationError";
import InstagramIcon from "../../../components/icons/InstagramIcon";
import YoutubeIcon from "../../../components/icons/YoutubeIcon";
import { Margin } from "../../../spacings";
import SocialMedialUrlInput from "../../form/fields/SocialMedialUrlInput";

export default function UserSocialMediaFields() {
  const medias = ["linkedin", "twitter", "facebook", "instagram", "youtube"];

  return (
    <div
      css={{
        display: "grid",
        gridRowGap: 24,
      }}
    >
      {medias.map((media, index) => (
        <Field key={index} name={`socialMedia.${media}`}>
          {({ input, meta }) => (
            <div>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  css={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gridColumnGap: 8,
                    alignItems: "center",
                  }}
                >
                  <Icon media={media} />

                  <label
                    css={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: palette.mediumGray,
                      margin: 0,
                      textTransform: "capitalize",
                    }}
                  >
                    {media}
                  </label>
                </div>

                <FieldValidationError meta={meta} />
              </div>

              <Margin mt={10}>
                <SocialMedialUrlInput
                  placeholder={`${media} URL`}
                  onClick={event => {
                    if (!event.ctrlKey) {
                      return;
                    }

                    let link = (event.target as HTMLInputElement).value;

                    if (link) {
                      if (!link.toLowerCase().startsWith("http://") && !link.toLowerCase().startsWith("https://")) {
                        link = "https://" + link;
                      }

                      window.location.href = link;
                    }
                  }}
                  input={input}
                />
              </Margin>

              {input.value && /^https?/.test(input.value.toLowerCase()) && (
                <Margin mt={12}>
                  <a href={input.value} target="_blank" rel="noopener noreferrer">
                    {input.value}
                  </a>
                </Margin>
              )}
            </div>
          )}
        </Field>
      ))}
    </div>
  );
}

const Icon = ({ media }: { media: string }) => {
  if (media === "linkedin") {
    return <LinkedinIcon size={20} round={true} />;
  }

  if (media === "twitter") {
    return <TwitterIcon size={20} round={true} />;
  }

  if (media === "facebook") {
    return <FacebookIcon size={20} round={true} />;
  }

  if (media === "instagram") {
    return <InstagramIcon height={18} width={18} />;
  }

  if (media === "youtube") {
    return <YoutubeIcon height={21} width={21} />;
  }

  return null;
};

Icon.propTypes = {
  media: PropTypes.string.isRequired,
};
