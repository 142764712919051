import { omit } from "lodash/fp";
import { IBlockLayoutType } from "../../../types/types";
import { ILandingPageConfigQuery } from "../../queries/__generated__/LandingPageConfig.generated";
import { appendPlusBlock, IBlockLayout } from "./blocks/blockProps";
import { IHeaderBannerData } from "./headerbanner/headerBannerProps";

export function convertValuesToVariables(values: any) {
  return {
    name: values.name,
    active: values.active,
    allowOverlap: values.allowOverlap,
    headerBanner: headerBannerExists(values.headerBanner) ? values.headerBanner : null,
    blocks: (values.blocks || [])
      .filter((block: IBlockLayout) => block.blockData.type !== IBlockLayoutType.PlusBlock)
      .map((block: IBlockLayout) => {
        return {
          ...omit(["moved", "__typename"], block),
          blockData: omit("__typename", block.blockData),
        };
      }),
  };
}

export type TDbTemplate = NonNullable<ILandingPageConfigQuery["landingPageConfig"]>;

export function convertValuesToInitialVariables(values: TDbTemplate) {
  const sanitizedBlocks = values.blocks.map((block) => {
    return {
      ...block,
      blockData: omit("__typename", block.blockData),
    };
  });

  return {
    name: values.name,
    active: values.active,
    headerBanner: headerBannerExists(values.headerBanner)
      ? {
          title: values.headerBanner?.title,
          titleColor: values.headerBanner?.titleColor,
          subtitle: values.headerBanner?.subtitle,
          subtitleColor: values.headerBanner?.subtitleColor,
          backgroundImage: {
            url: values.headerBanner?.backgroundImage?.url,
            alt: values.headerBanner?.backgroundImage?.alt,
          },
          foregroundImage: {
            url: values.headerBanner?.foregroundImage?.url,
            alt: values.headerBanner?.foregroundImage?.alt,
          },
          leftButtonText: values.headerBanner?.leftButtonText,
          leftButtonHoverColor: values.headerBanner?.leftButtonHoverColor,
          leftButtonBorderColor: values.headerBanner?.leftButtonBorderColor,
          leftButtonBackgroundColor: values.headerBanner?.leftButtonBackgroundColor,
          leftButtonTextColor: values.headerBanner?.leftButtonTextColor,
          leftButtonHref: values.headerBanner?.leftButtonHref,
          rightButtonText: values.headerBanner?.rightButtonText,
          rightButtonHoverColor: values.headerBanner?.rightButtonHoverColor,
          rightButtonBorderColor: values.headerBanner?.rightButtonBorderColor,
          rightButtonBackgroundColor: values.headerBanner?.rightButtonBackgroundColor,
          rightButtonTextColor: values.headerBanner?.rightButtonTextColor,
          rightButtonHref: values.headerBanner?.rightButtonHref,
        }
      : null,
    blocks: appendPlusBlock(sanitizedBlocks as IBlockLayout[]),
    compact: false,
    allowOverlap: values.allowOverlap,
  };
}

function headerBannerExists(headerBannerData: IHeaderBannerData | null | undefined) {
  if (!headerBannerData) {
    return null;
  }

  if (
    headerBannerData.title ||
    headerBannerData.subtitle ||
    headerBannerData.backgroundImage?.url ||
    headerBannerData.foregroundImage?.url ||
    headerBannerData.leftButtonText ||
    headerBannerData.rightButtonText
  ) {
    return true;
  }

  return false;
}
