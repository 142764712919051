import gql from "graphql-tag";

export default gql`
  mutation ExtendVendorCreditsEligibilityDate($vendorId: ObjectId!) {
    extendVendorCreditsEligibilityDate(vendorId: $vendorId) {
      _id
      canEarnCreditsUntil
    }
  }
`;
