import React from "react";
import { TSponsorship } from "./SponsorshipsTable";
import { Popover } from "antd";
import DotdotdotIcon from "../../components/icons/DotdotdotIcon";
import { Toggle } from "react-powerplug";
import ViewDetails from "./actions/ViewDetails";
import Archive from "./actions/Archive";
import Restore from "./actions/Restore";

interface ISponsorshipsActionsProps {
  sponsorship: TSponsorship;
  refetch: () => any;
}

export default function SponsorshipsActions({ sponsorship, refetch }: ISponsorshipsActionsProps) {
  return (
    <Toggle>
      {({ on, toggle }) => (
        <Popover
          visible={on}
          trigger="click"
          title="Actions"
          placement="rightBottom"
          onVisibleChange={toggle}
          content={
            <ul
              css={{
                margin: 0,
                padding: 0,
                listStyle: "none",
                "& > li:not(:first-of-type)": {
                  marginTop: 10,
                },
              }}
            >
              {sponsorship.archivedAt ? (
                <Restore id={sponsorship._id} refetch={refetch} />
              ) : (
                <>
                  <ViewDetails slug={sponsorship.slug} />
                  <Archive id={sponsorship._id} name={sponsorship.name} refetch={refetch} />
                </>
              )}
            </ul>
          }
        >
          <span
            role="button"
            css={{
              cursor: "pointer",
            }}
          >
            <DotdotdotIcon />
          </span>
        </Popover>
      )}
    </Toggle>
  );
}
