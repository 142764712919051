import { useSlate, insertEditorBlock } from "@govlaunch/editor";
import { Modal } from "antd";
import React from "react";
import ProjectAutoComplete from "../../../projects/ProjectAutoComplete";

interface IEmbedProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function EmbedProjectModal({ isOpen, onClose }: IEmbedProjectModalProps) {
  const editor = useSlate();

  return (
    <Modal title="Embed a Project" visible={isOpen} onCancel={onClose} footer={null} width={600}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ProjectAutoComplete
          onSelect={(project) => {
            const govlaunchProject = [
              {
                type: "govlaunch-project",
                _id: project._id,
                slug: project.slug,
                title: project.title,
                countryCode: project.countryCode || "US",
                children: [
                  {
                    text: "",
                  },
                ],
              },
              {
                type: "paragraph",
                children: [
                  {
                    text: "",
                  },
                ],
              },
            ];

            insertEditorBlock(editor, govlaunchProject);

            onClose();
          }}
        />
      </div>
    </Modal>
  );
}
