import ProfilePicture from "@govlaunch/profile-picture";
import React from "react";
import { Spacing } from "@govlaunch/spacers";
import { IUser } from "../../../types/types";

export const NameColumn = {
  title: "Name",
  dataIndex: "fullName",
  render: (_: string, user: IUser) => (
    <div
      css={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <ProfilePicture
        size={24}
        name={user.fullName}
        image={user.avatar}
        innovator={user.__typename == "PendingUser" || user.__typename == "GovernmentUser" ? user.isInnovator : false}
        disruptor={user.__typename == "VendorUser" ? user.isDisruptor : false}
        css={{
          whiteSpace: "nowrap",
        }}
      />

      <Spacing left={5}>{user.fullName}</Spacing>
    </div>
  ),
};

export const PlainEmailColumn = {
  title: "✉️",
  dataIndex: "email",
};

export const CurrentlyAssignedToColumn = {
  title: "Assigned To",
  render: (user: any) => {
    if (user.__typename === "PendingUser") {
      return "Not assigned";
    } else if (user.__typename === "VendorUser") {
      return (
        <div
          css={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ProfilePicture
            size={24}
            image={user.company.logo}
            name={user.company.name}
            disruptor={user.company.isDisruptor}
            css={{
              whiteSpace: "nowrap",
            }}
          />

          <Spacing left={10}>{user.company.name}</Spacing>
        </div>
      );
    } else if (user.__typename === "GovernmentUser") {
      return (
        <div
          css={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ProfilePicture
            size={24}
            image={user.government.logo}
            name={user.government.name}
            innovator={user.government.isInnovator}
            css={{
              whiteSpace: "nowrap",
            }}
          />

          <Spacing left={10}>{user.government.name}</Spacing>
        </div>
      );
    }
  },
};
