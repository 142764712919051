import gql from "graphql-tag";

export default gql`
  query AutoCompletePaidVendors($searchQuery: String!) {
    autoCompletePaidOrDisruptorVendors(searchQuery: $searchQuery, limit: 10) {
      _id
      name
      logo
    }
  }
`;
