import React, { useState } from "react";
import { Mutation } from "react-apollo";
import EditOnboarding from "../../../mutations/EditOnboarding";
import {
  IEditOnboardingMutation,
  IEditOnboardingMutationVariables,
} from "../../../mutations/__generated__/EditOnboarding.generated";
import { IViewOnboardingFormProps } from "./Interfaces";
import { notification, Select } from "antd";
import { Field, Form } from "react-final-form";
import { Button, Col, Grid, RowWrap } from "@govlaunch/core";
import Section from "../../form/Section";
import SectionBody from "../../form/SectionBody";
import SectionHeader from "../../form/SectionHeader";
import FieldTitle from "./fields/FieldTitle";
import { FieldWrapper } from "./fields/FieldWrapper";
import { IProcessStatusType, IWorkPlaceType } from "../../../../types/types";
import FieldError from "./fields/FieldError";
import { FieldValue } from "./fields/FieldValue";
import { Toggle } from "react-powerplug";
import GovernmentSearch from "../../teamManagement/modals/GovernmentSearch";
import COUNTRIES from "../../governments/Countries";
import FormRow from "./fields/FormRow";
import TextArea from "antd/lib/input/TextArea";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";
import OnboardingControls from "./OnboardingControls";
import { getProcessStatusFriendlyName, getWorkplaceTypeFriendlyName } from "./utils";
import GovernmentById from "./fields/GovernmentById";

export default function OnboardingForm({ user, onboarding, refetch }: IViewOnboardingFormProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [workingFor, setWorkingFor] = useState(onboarding?.workingFor);
  const [originalWorkingFor, setOriginalWorkingFor] = useState(workingFor);

  const [selectedGovernment, setSelectedGovernment] = useState(onboarding?.governmentId);
  const [originalSelectedGovernment, setOriginalSelectedGovernment] = useState(selectedGovernment);

  return (
    <>
      <Mutation<IEditOnboardingMutation, IEditOnboardingMutationVariables>
        mutation={EditOnboarding}
        onError={(err) => {
          if (/Can not create onboarding for non existing user/.test(err.message)) {
            notification.open({
              type: "error",
              message: "Can not create onboarding for non existing user",
              description: `Please refresh the page and try again`,
            });
          }
        }}
        refetchQueries={["OnboardingsCount"]}
        awaitRefetchQueries={true}
      >
        {(editOnboarding) => (
          <Form
            initialValues={{
              country: onboarding?.country,
              state: onboarding?.state,
              city: onboarding?.city,
              facebookUrl: onboarding?.facebookUrl,
              twitterUrl: onboarding?.twitterUrl,
              linkedInUrl: onboarding?.linkedInUrl,
              processStatus: onboarding?.processStatus,
              vendorName: onboarding?.vendorName,
              vendorWebsite: onboarding?.vendorWebsite,
              workingFor: workingFor,
              workplaceName: onboarding?.workplaceName,
              workplaceWebsite: onboarding?.workplaceWebsite,
              whyYouWantToJoin: onboarding?.whyYouWantToJoin,
              governmentId: selectedGovernment,
            }}
            onSubmit={async (values: {
              country: any;
              state: any;
              city: any;
              facebookUrl: any;
              twitterUrl: any;
              linkedInUrl: any;
              processStatus: any;
              vendorName: any;
              vendorWebsite: any;
              workingFor: any;
              workplaceName: any;
              workplaceWebsite: any;
              whyYouWantToJoin: any;
              governmentId: any;
            }) => {
              await editOnboarding({
                variables: {
                  userId: user._id,
                  data: {
                    country: values.country,
                    state: values.state,
                    city: values.city,
                    facebookUrl: values.facebookUrl,
                    twitterUrl: values.twitterUrl,
                    linkedInUrl: values.linkedInUrl,
                    processStatus: values.processStatus,
                    vendorName: values.vendorName,
                    vendorWebsite: values.vendorWebsite,
                    workingFor: values.workingFor,
                    workplaceName: values.workplaceName,
                    workplaceWebsite: values.workplaceWebsite,
                    whyYouWantToJoin: values.whyYouWantToJoin,
                    governmentId: values.governmentId === "" ? null : values.governmentId,
                  },
                },
              });

              refetch();

              setIsEditing(false);
            }}
          >
            {({ handleSubmit }) => (
              <form
                css={{
                  maxWidth: 900,
                }}
                onSubmit={handleSubmit}
              >
                <Grid
                  css={{
                    marginBottom: 10,
                    marginLeft: 0,
                    maxWidth: 900,
                  }}
                >
                  <Section>
                    <SectionHeader>
                      <div
                        css={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          css={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <h1
                            css={{
                              margin: 0,
                              color: "#212121",
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            Onboarding Data
                          </h1>
                        </div>

                        <div
                          css={{
                            display: "grid",
                            gridAutoFlow: "column",
                            columnGap: 12,
                          }}
                        >
                          {isEditing ? (
                            <>
                              <Button size="xsmall" type="submit">
                                Save
                              </Button>
                              <Button
                                size="xsmall"
                                color="gray"
                                textColor="#fff"
                                type="button"
                                onClick={() => {
                                  setIsEditing(false);
                                  setWorkingFor(originalWorkingFor);
                                  setSelectedGovernment(originalSelectedGovernment);
                                }}
                              >
                                Cancel
                              </Button>
                            </>
                          ) : (
                            <Button
                              size="xsmall"
                              onClick={(e: React.SyntheticEvent) => {
                                e.preventDefault();
                                setIsEditing(true);
                                setOriginalWorkingFor(workingFor);
                                setOriginalSelectedGovernment(selectedGovernment);
                              }}
                            >
                              Edit
                            </Button>
                          )}
                        </div>
                      </div>
                    </SectionHeader>
                    <SectionBody>
                      <Grid
                        css={{
                          marginBottom: 10,
                          marginLeft: 0,
                        }}
                      >
                        <RowWrap>
                          <Col
                            css={{
                              minWidth: 400,
                            }}
                            width={1 / 2}
                          >
                            <FieldTitle title="Onboarding status" />
                            {isEditing ? (
                              <Field
                                name="processStatus"
                                validate={(value: any) => (!value || value.length === 0 ? "Can't be blank" : null)}
                              >
                                {({ input, meta }) => (
                                  <FieldWrapper>
                                    <Select {...input}>
                                      <Select.Option
                                        value={IProcessStatusType.Required}
                                        title="Required"
                                        key={IProcessStatusType.Required}
                                      >
                                        Required
                                      </Select.Option>
                                      <Select.Option
                                        value={IProcessStatusType.AdditionalInformationRequired}
                                        title="Additional Information Required"
                                        key={IProcessStatusType.AdditionalInformationRequired}
                                      >
                                        Additional Information Required
                                      </Select.Option>
                                      <Select.Option
                                        value={IProcessStatusType.PendingVerification}
                                        title="Pending Verification"
                                        key={IProcessStatusType.PendingVerification}
                                      >
                                        Pending Verification
                                      </Select.Option>
                                      <Select.Option
                                        value={IProcessStatusType.Verified}
                                        title="Finalized"
                                        key={IProcessStatusType.Verified}
                                      >
                                        Finalized
                                      </Select.Option>
                                    </Select>
                                    <FieldError meta={meta} />
                                  </FieldWrapper>
                                )}
                              </Field>
                            ) : (
                              <FieldValue value={getProcessStatusFriendlyName(onboarding?.processStatus)} />
                            )}
                            <FieldTitle title="Working For" />
                            {isEditing ? (
                              <Field name="workingFor">
                                {({ input, meta }) => (
                                  <FieldWrapper>
                                    <Select
                                      {...input}
                                      onChange={(value) => {
                                        setWorkingFor(value);
                                      }}
                                      placeholder="Select workplace"
                                    >
                                      {Object.values(IWorkPlaceType).map((val) => {
                                        const visibleName = getWorkplaceTypeFriendlyName(val);
                                        return (
                                          <Select.Option value={val} title={visibleName} key={val}>
                                            {visibleName}
                                          </Select.Option>
                                        );
                                      })}
                                    </Select>
                                    <FieldError meta={meta} />
                                  </FieldWrapper>
                                )}
                              </Field>
                            ) : (
                              <FieldValue value={getWorkplaceTypeFriendlyName(workingFor)} />
                            )}

                            {workingFor === IWorkPlaceType.Government && (
                              <>
                                <FieldTitle title="Government" />
                                {isEditing ? (
                                  <FieldWrapper>
                                    <GovernmentById governmentId={selectedGovernment} displayNA={false} />
                                    <Toggle>
                                      {({ on: isOpen, toggle: toggle }) => (
                                        <>
                                          <a
                                            href="#"
                                            onClick={() => {
                                              toggle();
                                            }}
                                          >
                                            Select government
                                          </a>
                                          {selectedGovernment && (
                                            <a
                                              href="#"
                                              style={{
                                                marginLeft: 10,
                                              }}
                                              onClick={() => {
                                                setSelectedGovernment(null);
                                              }}
                                            >
                                              Remove selected government
                                            </a>
                                          )}
                                          <GovernmentSearch
                                            onRequestClose={toggle}
                                            visible={isOpen}
                                            onSelectGovernment={(selectedGovernment) => {
                                              setSelectedGovernment(selectedGovernment._id);
                                              toggle();
                                            }}
                                          />
                                        </>
                                      )}
                                    </Toggle>
                                  </FieldWrapper>
                                ) : (
                                  <GovernmentById governmentId={selectedGovernment} displayNA={true} />
                                )}
                              </>
                            )}

                            {workingFor && workingFor !== IWorkPlaceType.Vendor && workingFor !== IWorkPlaceType.None && (
                              <>
                                <FieldTitle title="Country" />
                                {isEditing ? (
                                  <Field name="country">
                                    {({ input, meta }) => (
                                      <FieldWrapper>
                                        <Select {...input} defaultValue={onboarding?.country} placeholder="Country">
                                          {COUNTRIES.map((itemData) => {
                                            return (
                                              <Select.Option
                                                value={itemData.code}
                                                title={itemData.name}
                                                key={itemData.name}
                                              >
                                                {itemData.name}
                                              </Select.Option>
                                            );
                                          })}
                                        </Select>
                                        <FieldError meta={meta} />
                                      </FieldWrapper>
                                    )}
                                  </Field>
                                ) : (
                                  <FieldValue
                                    value={
                                      COUNTRIES.find((item) => {
                                        return item.code === onboarding?.country;
                                      })?.name
                                    }
                                  />
                                )}
                              </>
                            )}

                            {workingFor === IWorkPlaceType.Government && (
                              <>
                                <FormRow
                                  isEditing={isEditing}
                                  title="State"
                                  value={onboarding?.state}
                                  fieldName="state"
                                />
                                <FormRow isEditing={isEditing} title="City" value={onboarding?.city} fieldName="city" />
                              </>
                            )}

                            {workingFor === IWorkPlaceType.Vendor && (
                              <FormRow
                                isEditing={isEditing}
                                title="Vendor"
                                value={onboarding?.vendorName}
                                fieldName="vendorName"
                              />
                            )}

                            {workingFor === IWorkPlaceType.Vendor && (
                              <FormRow
                                isEditing={isEditing}
                                title="Vendor Website"
                                isUrl={true}
                                value={onboarding?.vendorWebsite}
                                fieldName="vendorWebsite"
                              />
                            )}

                            {(workingFor === IWorkPlaceType.Other ||
                              workingFor === IWorkPlaceType.NonprofitOrNgo ||
                              workingFor === IWorkPlaceType.StateOrFederalAgency) && (
                              <FormRow
                                isEditing={isEditing}
                                title="Workplace"
                                value={onboarding?.workplaceName}
                                fieldName="workplaceName"
                              />
                            )}

                            {(workingFor === IWorkPlaceType.Other ||
                              workingFor === IWorkPlaceType.NonprofitOrNgo ||
                              workingFor === IWorkPlaceType.StateOrFederalAgency) && (
                              <FormRow
                                isEditing={isEditing}
                                title="Workplace Website"
                                isUrl={true}
                                value={onboarding?.workplaceWebsite}
                                fieldName="workplaceWebsite"
                              />
                            )}

                            {workingFor === IWorkPlaceType.Other && (
                              <>
                                <FieldTitle title="Why you want to join" />
                                {isEditing ? (
                                  <Field name="whyYouWantToJoin">
                                    {({ input, meta }) => (
                                      <FieldWrapper>
                                        <TextArea
                                          placeholder="Why you want to join"
                                          {...input}
                                          style={{
                                            minHeight: 170,
                                          }}
                                        />
                                        <FieldError meta={meta} />
                                      </FieldWrapper>
                                    )}
                                  </Field>
                                ) : (
                                  <FieldValue value={onboarding?.whyYouWantToJoin} display="contents" />
                                )}
                              </>
                            )}
                          </Col>
                          <Col
                            css={{
                              minWidth: 400,
                            }}
                            width={1 / 2}
                          >
                            <FormRow
                              isEditing={isEditing}
                              title="LinkedIn URL"
                              value={onboarding?.linkedInUrl}
                              isUrl={true}
                              icon={<LinkedinIcon size={20} round={true} />}
                              fieldName="linkedInUrl"
                            />

                            <FormRow
                              isEditing={isEditing}
                              title="Twitter URL"
                              value={onboarding?.twitterUrl}
                              isUrl={true}
                              icon={<TwitterIcon size={20} round={true} />}
                              fieldName="twitterUrl"
                            />

                            <FormRow
                              isEditing={isEditing}
                              title="Facebook URL"
                              value={onboarding?.facebookUrl}
                              isUrl={true}
                              icon={<FacebookIcon size={20} round={true} />}
                              fieldName="facebookUrl"
                            />
                          </Col>
                        </RowWrap>
                      </Grid>
                    </SectionBody>
                  </Section>
                </Grid>
              </form>
            )}
          </Form>
        )}
      </Mutation>
      {!isEditing && (
        <OnboardingControls
          user={user}
          onboarding={onboarding}
          refetch={refetch}
          updateState={(newWorkingFor: IWorkPlaceType | null | undefined) => {
            setWorkingFor(newWorkingFor);
          }}
        />
      )}
    </>
  );
}
