import gql from "graphql-tag";
import CollectionSponsoringSaleFields from "../queries/fragments/CollectionSponsoringSaleFields";

export default gql`
  mutation CreateCollectionSponsoringSale($input: CreateSaleInput!) {
    createCollectionSponsoringSale(input: $input) {
      ...CollectionSponsoringSaleFields
    }
  }

  ${CollectionSponsoringSaleFields}
`;
