import React from "react";

interface ICampaignPreviewFollowButtonsProps {
  preview?: boolean;
}

export default function CampaignPreviewFollowButtons({ preview = true }: ICampaignPreviewFollowButtonsProps) {
  return (
    <>
      <tr>
        <td
          align="left"
          style={{
            padding: 0,
            margin: 0,
          }}
        >
          <table
            cellPadding="0"
            cellSpacing="0"
            style={{
              borderCollapse: "collapse",
              borderSpacing: 0,
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td
                  align="center"
                  valign="top"
                  style={{
                    padding: 0,
                    margin: 0,
                    width: "600px",
                  }}
                >
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    role="presentation"
                    style={{
                      borderCollapse: "collapse",
                      borderSpacing: "0px",
                      width: "100%",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          align="left"
                          className="es-m-p15t es-m-p0b"
                          style={{
                            margin: 0,
                            paddingBottom: "5px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingTop: "25px",
                          }}
                        >
                          <h1
                            style={{
                              margin: 0,
                              lineHeight: "24px",
                              fontSize: "20px",
                              fontStyle: "normal",
                              fontWeight: "bold",
                              color: "#444444",
                              textAlign: "center",
                            }}
                          >
                            Follow us!
                          </h1>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td
          align="left"
          style={{
            padding: 0,
            margin: 0,
            paddingBottom: "15px",
          }}
        >
          {!preview &&
            `<!--[if mso]><table style="width:600px" cellpadding="0" cellspacing="0"><tr><td style="width:203px" valign="top"><![endif]--> `}
          <FollowButton
            text="Follow on Twitter"
            imgSrc="https://lchjnb.stripocdn.email/content/guids/CABINET_f58f6a7604aedc7d0bbb77dbb5977418/images/92861628863247493.png"
            url="https://twitter.com/govlaunch"
          />
          <FollowButton
            text="Follow on LinkedIn"
            imgSrc="https://lchjnb.stripocdn.email/content/guids/CABINET_f58f6a7604aedc7d0bbb77dbb5977418/images/42891628863066636.png"
            url="https://www.linkedin.com/company/govlaunch/"
          />
          <FollowButton
            text="Follow on Facebook"
            imgSrc="https://lchjnb.stripocdn.email/content/guids/CABINET_f58f6a7604aedc7d0bbb77dbb5977418/images/41331628863212123.png"
            url="https://www.facebook.com/teamgovlaunch"
          />
          {!preview && `<!--[if mso]></td></tr></table><![endif]-->`}
        </td>
      </tr>
    </>
  );
}

interface IFollowButtonProps {
  imgSrc: string;
  url: string;
  text: string;
}

function FollowButton({ imgSrc, url, text }: IFollowButtonProps) {
  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      className="es-left"
      style={{
        borderCollapse: "collapse",
        borderSpacing: "0px",
        float: "left",
      }}
    >
      <tbody>
        <tr>
          <td
            align="center"
            className="es-m-p0r"
            style={{
              padding: 0,
              margin: 0,
              width: "200px",
            }}
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              role="presentation"
              style={{
                borderCollapse: "collapse",
                borderSpacing: "0px",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td
                    align="center"
                    className="es-m-p15t"
                    style={{
                      padding: 0,
                      margin: 0,
                      paddingTop: "10px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `
                      <!--[if mso]>
                        <a href="${url}" target="_blank">
                          <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" esdevVmlButton href="${url}" style="height:43px;v-text-anchor:middle;width:160px;" arcsize="7%" strokecolor="#333333" strokeweight="1px" fillcolor="#ffffff">
                            <w:anchorlock>
                            </w:anchorlock>
                            <center style='color:#333333;font-family:helvetica, "helvetica neue", arial, verdana, sans-serif;font-size:14px;font-weight:400;'>${text}</center>
                          </v:roundrect>
                        </a>
                      <![endif]--> 
                      <!--[if !mso]>
                        <!-- -->
                        <span className="msohide es-button-border" style="border-style:solid;border-color:#333333;background:#ffffff;border-width:1px;display:inline-block;border-radius:3px;width:auto;mso-hide:all;width:180px">
                          <a href="${url}" className="es-button es-button-3" target="_blank" style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#333333;font-size:14px;border-style:solid;border-color:#ffffff;border-width:10px 10px;display:inline-block;background:#ffffff;border-radius:3px;font-family:helvetica, 'helvetica neue', arial, verdana, sans-serif;font-weight:normal;font-style:normal;line-height:17px;width:auto;text-align:center">
                            <img src="${imgSrc}" alt="icon" width="16" style="display:inline-block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;vertical-align:middle;margin-right:6px" align="absmiddle">
                            ${text}
                          </a>
                        </span> 
                      <!--<![endif]-->`,
                    }}
                  />
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
