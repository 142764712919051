import gql from "graphql-tag";
import LandingPageConfigFields from "../queries/fragments/LandingPageConfigFields";

export default gql`
  mutation EditLandingPageConfig($id: ObjectId!, $landingPageConfig: LandingPageConfigInput!) {
    editLandingPageConfig(id: $id, landingPageConfig: $landingPageConfig) {
      ...LandingPageConfigFields
    }
  }

  ${LandingPageConfigFields}
`;
