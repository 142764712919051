import gql from "graphql-tag";

export default gql`
  fragment GovernmentPostFields on GovernmentPost {
    _id
    body
    audience
    featuredAt
    createdAt
    deletedAt
    deletedBy {
      ... on PendingUser {
        _id
        slug
        fullName
      }
      ... on GovernmentUser {
        _id
        slug
        fullName
      }
      ... on VendorUser {
        _id
        slug
        fullName
      }
    }
    author {
      ... on PendingUser {
        _id
        fullName
      }
      ... on GovernmentUser {
        _id
        fullName
      }
      ... on VendorUser {
        _id
        fullName
      }
    }
    government {
      _id
      name
    }
    attachments {
      mimeType
      filename
      sizeInBytes
      url
    }
    links {
      addedAt
      description
      title
      favicon
      siteName
      url
    }
  }
`;
