import { useEffect } from "react";
import base64 from "base64-url";
import queryString from "query-string";
import { useNavigate } from "react-router";

interface ITableAddStateToQueryStringProps {
  historyOrigin: string;
  tableState: any;
  query: queryString.ParsedQuery<string>;
}

export default function TableAddStateToQueryString({
  historyOrigin,
  tableState,
  query,
}: ITableAddStateToQueryStringProps) {
  const navigate = useNavigate();

  useEffect(() => {
    const nextQuery = queryString.stringify({
      ...query,
      state: base64.encode(JSON.stringify(tableState)) || undefined,
    });

    navigate(`${historyOrigin}?${nextQuery}`);
  }, [tableState]);

  return null;
}
