import React from "react";
import { Field } from "react-final-form";
import { IProduct } from "../../../../../types/types";
import ProductItem from "../../rich/ProductItem";
import * as palette from "@govlaunch/palette";
import nullthrows from "../../nullthrows";
import fp from "lodash/fp";

export default function ProductsField() {
  return (
    <Field name="products">
      {({ input }) => {
        const products = nullthrows(input.value);

        if (products.length === 0) {
          return null;
        }

        return (
          <>
            <h5
              css={{
                margin: 0,
                fontWeight: 600,
                fontSize: 16,
                color: palette.darkGray,
              }}
            >
              Products
            </h5>

            <div
              css={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: 10,
                rowGap: 10,
              }}
            >
              {input.value.map((product: IProduct) => {
                return (
                  <ProductItem
                    key={product._id}
                    product={product}
                    onRemove={(product: IProduct) => input.onChange(fp.reject(product, input.value))}
                  />
                );
              })}
            </div>
          </>
        );
      }}
    </Field>
  );
}
