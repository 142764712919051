import gql from "graphql-tag";
import OnboardingFields from "./fragments/OnboardingFields";

export default gql`
  query Onboarding($userId: ObjectId!) {
    onboarding(userId: $userId) {
      ...OnboardingFields
    }
  }

  ${OnboardingFields}
`;
