import React from "react";
import { useMutation } from "react-apollo";
import ForceSendNewsletterMutation from "../../mutations/ForceSendNewsletterMutation";
import {
  IForceSendNewsletterMutation,
  IForceSendNewsletterMutationVariables,
} from "../../mutations/__generated__/ForceSendNewsletterMutation.generated";
import { notification } from "antd";

interface IForceSendNewsletterButtonProps {
  userId: any;
}

export function ForceSendNewsletterLink({ userId }: IForceSendNewsletterButtonProps) {
  const [forceSendNewsletter, { loading }] = useMutation<
    IForceSendNewsletterMutation,
    IForceSendNewsletterMutationVariables
  >(ForceSendNewsletterMutation);

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        forceSendNewsletter({
          variables: {
            userId: userId,
          },
        }).then(() =>
          notification.success({
            message: "Success!",
            description: "Newsletter email sent successfully!",
          }),
        );
      }}
    >
      {loading ? "Sending..." : "Send"}
    </a>
  );
}
