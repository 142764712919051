import React from "react";
import queryString from "query-string";
import { Spacing } from "@govlaunch/spacers";
import { Input } from "antd";
import Sponsorships from "./Sponsorships";
import { IAllSponsorshipsFilter } from "../../../types/types";
import { useLocation, useNavigate } from "react-router";

interface ISponsorshipsWithSearchProps {
  filter?: IAllSponsorshipsFilter;
}

export default function SponsorshipsWithSearch({ filter = IAllSponsorshipsFilter.All }: ISponsorshipsWithSearchProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const setSearchTerm = (searchTerm: string) => {
    const nextQuery = queryString.stringify({
      ...query,
      search: searchTerm.trim() || undefined,
    });

    navigate(`/sponsorships?${nextQuery}`);
  };

  return (
    <>
      <Spacing bottom={20}>
        <Input.Search
          size="large"
          placeholder="Search for sponsorships..."
          autoComplete="off"
          onSearch={setSearchTerm}
          style={{
            fontSize: 14,
          }}
        />
      </Spacing>

      <Sponsorships search={query.search as string} filter={filter} />
    </>
  );
}
