import gql from "graphql-tag";

export default gql`
  query TopInnovatorGovernments($limit: Int = 10) {
    topInnovatorGovernments(limit: $limit) {
      _id
      slug
      name
      logo
      city {
        _id
        name
        state
        country
        stateName: state(full: true)
        type
        county
      }
    }
  }
`;
