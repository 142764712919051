import React from "react";
import * as palette from "@govlaunch/palette";
import { Field } from "react-final-form";
import { Link } from "react-router-dom";
import { Button } from "@govlaunch/core";
import { useToggle } from "react-use";
import StoryAuthorCard from "./StoryAuthorCard";
import EditCoauthorsModal from "./EditCoauthorsModal";
import { IStoryFieldsFragment } from "@govlaunch/story/src/fragments/__generated__/StoryFieldsFragment.generated";
import { TCoauthor, constructCoauthors } from "@govlaunch/story/src/components/StoryAuthor";
import EditVendorCoauthorsModal from "./EditVendorCoauthorsModal";

interface IEditableStoryCoauthors {
  editMode?: boolean;
}

export default function EditableStoryCoauthors({ editMode = false }: IEditableStoryCoauthors) {
  const [showAddUser, toggleShowAddUser] = useToggle(false);
  const [showAddVendor, toggleShowAddVendor] = useToggle(false);

  return (
    <Field name="coauthors">
      {({ input: coauthorsInput }) => {
        return (
          <Field name="vendorCoauthors">
            {({ input: vendorCoauthorsInput }) => {
              return (
                <>
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "column",
                      background: "#fff",
                      height: "100%",
                    }}
                  >
                    <Coauthors userCoauthors={coauthorsInput.value} vendorCoauthors={vendorCoauthorsInput.value} />

                    {editMode && (
                      <>
                        <div
                          css={{
                            padding: 16,
                            display: "flex",
                            alignSelf: "center",
                          }}
                        >
                          <Button
                            size="xsmall"
                            color={palette.primary}
                            onClick={(e: React.MouseEvent) => {
                              e.preventDefault();
                              toggleShowAddUser();
                            }}
                          >
                            Edit co-authors
                          </Button>
                        </div>
                        <div
                          css={{
                            padding: "0px 16px 16px 16px",
                            display: "flex",
                            alignSelf: "center",
                          }}
                        >
                          <Button
                            size="xsmall"
                            color={palette.primary}
                            onClick={(e: React.MouseEvent) => {
                              e.preventDefault();
                              toggleShowAddVendor();
                            }}
                          >
                            Edit vendor co-authors
                          </Button>
                        </div>
                      </>
                    )}
                  </div>

                  {showAddUser && editMode && (
                    <EditCoauthorsModal
                      visible={showAddUser}
                      onCloseModal={toggleShowAddUser}
                      onAddCoauthors={coauthorsInput.onChange}
                      currentCoauthors={coauthorsInput.value}
                      vendorsCount={(vendorCoauthorsInput.value || []).length}
                    />
                  )}

                  {showAddVendor && editMode && (
                    <EditVendorCoauthorsModal
                      visible={showAddVendor}
                      onCloseModal={toggleShowAddVendor}
                      onAddVendorCoauthors={vendorCoauthorsInput.onChange}
                      currentVendorCoauthors={vendorCoauthorsInput.value}
                      usersCount={(coauthorsInput.value || []).length}
                    />
                  )}
                </>
              );
            }}
          </Field>
        );
      }}
    </Field>
  );
}

interface ICoauthorsProps {
  userCoauthors: IStoryFieldsFragment["coauthors"];
  vendorCoauthors: IStoryFieldsFragment["vendorCoauthors"];
}

function Coauthors({ userCoauthors, vendorCoauthors }: ICoauthorsProps) {
  const coauthors = constructCoauthors(userCoauthors, vendorCoauthors);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {coauthors.map((coauthor: TCoauthor) => {
        return (
          <Link
            key={coauthor.slug}
            to={coauthor.__typename !== "Company" ? `/users/${coauthor.slug}` : `/vendors/${coauthor.slug}`}
          >
            <StoryAuthorCard author={coauthor} />
          </Link>
        );
      })}
    </div>
  );
}
