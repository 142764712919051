import React from "react";
import ProjectsTable from "../../projects/tables/ProjectsTable";
import { IProductQuery } from "../../../queries/__generated__/ProductQuery.generated";

interface IProductProjectsTabProps {
  product: IProductQuery["product"];
  refetch: (arg: any) => any;
}

const ProductProjectsTab = ({ product, refetch }: IProductProjectsTabProps) => {
  if (!product) {
    return null;
  }

  return (
    <div
      css={{
        padding: "0 15px",
      }}
    >
      <ProjectsTable projects={product.projects} onDeleteProject={refetch} />
    </div>
  );
};

export default ProductProjectsTab;
