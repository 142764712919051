import React from "react";
import * as palette from "@govlaunch/palette";
import moment from "moment";
import ProfilePicture from "@govlaunch/profile-picture";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { Margin } from "../../../spacings";
import ProductActions from "../../products/ProductActions";
import { IVendorQuery } from "../../../queries/__generated__/VendorQuery.generated";
import { ITag } from "../../../../types/types";

interface IVendorProductsTabProps {
  vendor: IVendorQuery["company"];
}

export default function VendorProductsTab({ vendor }: IVendorProductsTabProps) {
  if (!vendor) {
    return null;
  }

  return (
    <div
      css={{
        padding: 20,
      }}
    >
      <Link
        css={{
          marginBottom: 12,
          display: "block",
        }}
        to={`/vendors/${vendor.slug}/products/new`}
      >
        Add New
      </Link>

      {vendor.products && (
        <Table
          style={{
            backgroundColor: palette.white,
          }}
          rowKey="_id"
          loading={false}
          dataSource={vendor.products.filter(({ archivedAt }) => !archivedAt)}
          bordered={true}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              render: (name, product) => {
                return (
                  <div
                    css={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Margin mr={10}>
                      <ProfilePicture
                        image={product.logo}
                        size={25}
                        disruptor={product.isDisruptor}
                        cursor="pointer"
                        name={product.name}
                      />
                    </Margin>

                    <Link to={`/vendors/${vendor.slug}/products/${product.slug}`}>
                      {product.featuredAt ? <>{name} 🌟</> : name}
                    </Link>
                  </div>
                );
              },
            },
            {
              title: "# of Govs",
              dataIndex: "governmentsUsingCount",
            },
            {
              title: "Tags",
              dataIndex: "tags",
              render: (tags) => (
                <div
                  css={{
                    display: "grid",
                    rowGap: 8,
                    justifyContent: "center",
                  }}
                >
                  {tags.map((tag: ITag) => {
                    return (
                      <span
                        key={tag._id}
                        css={{
                          display: "flex",
                          justifySelf: "center",
                          padding: "5px 8px",
                          backgroundColor: "rgba(219, 236, 247, 0.5)",
                          borderRadius: 10,
                          fontSize: 12,
                          color: palette.darkGray,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {tag.name}
                      </span>
                    );
                  })}
                </div>
              ),
            },
            {
              title: "Created",
              key: "createdAt",
              render: (product) => {
                return moment(product.createdAt).format("MM/DD/YYYY");
              },
            },
            {
              title: "Actions",
              render: (product) => {
                return (
                  <ProductActions
                    product={product}
                    variables={{
                      search: null,
                      filter: null,
                      sort: null,
                      cursor: null,
                      inTiers: null,
                      tags: null,
                    }}
                    vendorSlug={vendor.slug}
                    editUrl={`/vendors/${vendor.slug}/products/${product.slug}`}
                  />
                );
              },
            },
          ]}
          pagination={false}
        />
      )}
    </div>
  );
}
