import React from "react";
import { ITagType } from "../../../types/types";
import queryString from "query-string";
import { Spacing } from "@govlaunch/spacers";
import TagsTable from "./TagsTable";
import AutopopulateInterestsButton from "./AutopopulateInterestsButton";
import TableSearch from "../../components/table/TableSearch";
import { useLocation } from "react-router";

interface ITagsWIthSearchProps {
  archivedOnly: boolean;
  filterByTagType?: ITagType;
}

export default function TagsWithSearch({ archivedOnly, filterByTagType }: ITagsWIthSearchProps) {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div
        css={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          css={{
            flexGrow: 1,
          }}
        >
          <Spacing bottom={20}>
            <TableSearch historyOrigin="/tags" query={query} inputPlaceholder="Search for tags..." />
          </Spacing>
        </div>

        {filterByTagType === ITagType.Department && <AutopopulateInterestsButton />}
      </div>

      <TagsTable
        search={(query.search as string) || ""}
        archivedOnly={archivedOnly}
        filterByTagType={filterByTagType}
      />
    </>
  );
}
