import gql from "graphql-tag";
import GovernmentPostFields from "./fragments/GovernmentPostFields";
import GovernmentProjectFields from "./fragments/GovernmentProjectFields";
import GroupProjectFields from "./fragments/GroupProjectFields";
import StoryFields from "./fragments/StoryFields";
import GovernmentUserFields from "./fragments/GovernmentUserFields";
import VendorProjectFields from "./fragments/VendorProjectFields";

export default gql`
  query GetGovernment($slug: String!, $projectsCursor: Cursor, $storiesCursor: Cursor) {
    government(slug: $slug) {
      _id
      slug
      name
      logo
      currency
      budget
      site
      cover {
        original
        repositioned
      }
      city {
        _id
        name
        population
        type
        state
        fullState: state(full: true)
        stateName
        governmentType
        county
        country
        zip
      }
      awards {
        _id
        name
      }
      resources {
        ... on LinkResource {
          title
          url
        }
        ... on TextResource {
          title
          body
        }
        ... on DownloadResource {
          title
          url
          filename
        }
      }
      members {
        _id
        ...GovernmentUserFields
      }
      projects(cursor: $projectsCursor) {
        items {
          _id
          ...GovernmentProjectFields
          ...GroupProjectFields
          ...VendorProjectFields
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      verifiedProjects {
        items {
          _id
          ...GovernmentProjectFields
          ...GroupProjectFields
          ...VendorProjectFields
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      stories(cursor: $storiesCursor) {
        items {
          _id
          ...StoryFieldsAdmin
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      products {
        _id
        slug
        name
        logo
        isDisruptor
        canBeDisruptor
        status
        storiesCount
        projectsCount
        governmentsUsingCount
        tier
        archivedAt
        archivedBy {
          ... on PendingUser {
            _id
            slug
            fullName
          }
          ... on VendorUser {
            _id
            slug
            fullName
          }
          ... on GovernmentUser {
            _id
            slug
            fullName
          }
        }
        featuredAt
        createdAt
        updatedAt
        company {
          _id
          name
          slug
          logo
          isDisruptor
          archivedAt
        }
        tags {
          _id
          name
        }
      }
      posts {
        _id
        ...GovernmentPostFields
      }
    }
  }

  ${StoryFields}
  ${GovernmentProjectFields}
  ${GroupProjectFields}
  ${GovernmentPostFields}
  ${GovernmentUserFields}
  ${VendorProjectFields}
`;
