import { TCampaignCountry } from "./ProjectsPerCountryField";

export function validateProjectsPerCountry(projectsPerCountry: TCampaignCountry[]) {
  return projectsPerCountry.some((countryAmount) => {
    return !countryAmount.amount || !countryAmount.countryCode;
  });
}

export function checkForDuplicates(projectsPerCountry: TCampaignCountry[]) {
  const uniquesArray = new Set(projectsPerCountry.map((item) => item.name));
  return uniquesArray.size < projectsPerCountry.length;
}
