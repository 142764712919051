import React from "react";
import * as palette from "@govlaunch/palette";

interface ITagPillProps {
  name: string;
}

export default function TagPill({ name }: ITagPillProps) {
  return (
    <span
      css={{
        display: "flex",
        justifySelf: "center",
        padding: "5px 8px",
        margin: 2,
        color: palette.darkGray,
        fontSize: 12,
        whiteSpace: "nowrap",
        backgroundColor: "rgba(219, 236, 247, 0.5)",
        borderRadius: 10,
      }}
    >
      {name}
    </span>
  );
}
