import gql from "graphql-tag";
import UserFields from "./UserFields";

export default gql`
  fragment VendorFields on Company {
    _id
    logo
    slug
    name
    billingName
    logo
    description
    yearFounded
    createdAt
    businessEmail
    site
    viewerIsMember
    isDisruptor
    canBeDisruptor
    address
    contactPage
    highlights
    socialMedia {
      linkedin
      twitter
      facebook
      instagram
      youtube
    }
    slogan
    headquarters
    numberOfEmployees
    tier
    newsworthy {
      description
      title
      favicon
      siteName
      url
    }
    products {
      _id
      status
      slug
      logo
      name
      description
      link
      governmentsUsingCount
      isDisruptor
      canBeDisruptor
      tags {
        _id
        name
      }
      updatedAt
      createdAt
      archivedAt
      featuredAt
    }
    hasSponsoredCollections
    hasActiveSponsorships
    members {
      _id
      slug
      role
      avatar
      fullName
      jobTitle
      email
      createdAt
      bannedAt
      lastSignInAt
      isDisruptor
      linkedinUrl
      linkedinId
      signInAttempts {
        registeredAt
        outcome
        ip
        userAgent
        lookup
        whitelisted
      }
    }
    resources {
      ... on LinkResource {
        url
        title
        addedAt
      }
      ... on DownloadResource {
        url
        title
        filename
        addedAt
      }
      ... on TextResource {
        title
        body
        addedAt
      }
    }
    groups {
      _id
      slug
      name
      about
      website
      location
      kind
      membersCount
      kind
      owner {
        _id
        slug
        name
        logo
        isDisruptor
      }
      previousOwner {
        _id
        slug
        name
        logo
        isDisruptor
      }
      socialMedia {
        instagram
        youtube
        facebook
        linkedin
        twitter
      }
      deletedAt
      deletedBy {
        ... on PendingUser {
          _id
          slug
          fullName
        }
        ... on VendorUser {
          _id
          slug
          fullName
        }
        ... on GovernmentUser {
          _id
          slug
          fullName
        }
      }
      resources {
        ... on LinkResource {
          url
          title
          addedAt
          type
        }
        ... on DownloadResource {
          url
          title
          filename
          addedAt
          type
        }
        ... on TextResource {
          title
          body
          addedAt
          type
        }
      }
      governments {
        _id
        name
        slug
        logo
      }
      vendors {
        _id
        name
        slug
        logo
      }
      logo
      visibility
      members {
        items {
          ...UserFields
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
    owner {
      _id
      slug
      avatar
      fullName
      isDisruptor
      email
    }
    plan {
      _id
      name
      tier
      stripePlanId
      isSelectable
    }
    subscription {
      ... on ActiveSubscription {
        _id
        nextPaymentDate
        nextPaymentAmount
        subscribedAt
        freeSubscription
        trialPeriod
        pausePaymentCollection
        stripeStatus
      }
      ... on OverdueSubscription {
        _id
        willCancelAt
        nextPaymentAttempt
        freeSubscription
        trialPeriod
        pausePaymentCollection
        stripeStatus
      }
      ... on ScheduledToCancelSubscription {
        _id
        requestedCancellationAt
        willCancelAt
        freeSubscription
        trialPeriod
        pausePaymentCollection
        stripeStatus
      }
    }
    canEarnCreditsUntil
    sponsorships {
      _id
    }
    hasCustomLimits
    stripeCustomerId
  }

  ${UserFields}
`;
