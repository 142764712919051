import { Pagination } from "antd";
import React from "react";

export const DEFAULT_CURSOR = {
 page: 1, size: 10 
};

export interface ITablePaginationState {
  page: number;
  size: number;
}

interface ITablePaginationProps {
  loading: boolean;
  totalCount: number;
  tableState: any;
  setTableState: (arg0: any) => void;
}

export default function TablePagination({ tableState, setTableState, totalCount, loading }: ITablePaginationProps) {
  return (
    <div css={{
 display: "flex" 
}}
    >
      <Pagination
        css={{
          marginLeft: "auto",
        }}
        disabled={loading || totalCount === 0}
        showSizeChanger={true}
        showQuickJumper={true}
        total={totalCount}
        // range variable shows incorrect values when changing tabs
        showTotal={(total) => {
          const page = tableState.cursor.page;
          const limit = tableState.cursor.size;

          let current = (page - 1) * limit;
          if (current > total) {
            current = Math.max(total - limit, 0);
          }
          return `${current}-${Math.min((page - 1) * limit + limit, total)} of ${total} items`;
        }}
        current={tableState.cursor.page}
        onChange={(page, pageSize) => {
          if (tableState.cursor.page !== page || tableState.cursor.size !== pageSize) {
            setTableState({
              ...tableState,
              cursor: {
                page: tableState.cursor.size !== pageSize ? 1 : page,
                size: pageSize,
              },
            });

            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        }}
        pageSizeOptions={["10", "20", "50"]}
        pageSize={tableState.cursor.size}
      />
    </div>
  );
}
