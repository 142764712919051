import ProfilePicture from "@govlaunch/profile-picture";
import { Button, Table, TableProps, Tag, Tooltip } from "antd";
import moment from "moment";
import { Spacing } from "@govlaunch/spacers";
import { IAllGovernmentVerificationRequestsQuery } from "../../../queries/__generated__/AllGovernmentVerificationRequests.generated";
import { TProjectVerificationRequestsTableState } from "./types";
import {
  IGovernmentVerificationRequestSortBy,
  IGovernmentVerificationRequestSortByDirection,
  IGovernmentVerificationRequestStatus,
} from "../../../../types/types";
import { Link } from "react-router-dom";
import React from "react";
import VerifyOrDecline from "./VerifyOrDecline";

interface IProjectVerificationRequestsTableProps {
  loading?: boolean;
  items: NonNullable<IAllGovernmentVerificationRequestsQuery["allGovernmentVerificationRequests"]>["items"];
  onChange: (state: TProjectVerificationRequestsTableState) => void;
  footer?: TableProps<any>["footer"];
}

export default function ProjectVerificationRequestsTable({
  loading = false,
  items,
  onChange,
  footer,
}: IProjectVerificationRequestsTableProps) {
  return (
    <div>
      <Table
        scroll={{
          x: true,
        }}
        bordered={true}
        pagination={false}
        loading={loading}
        rowKey={(item) => item._id}
        footer={footer}
        onChange={(_pagination, _filters, sorter) => {
          if (Array.isArray(sorter)) {
            return;
          }

          if (sorter.columnKey === "government") {
            onChange({
              sorter: {
                sortBy: IGovernmentVerificationRequestSortBy.Government,
                sortDirection:
                  sorter.order === "ascend"
                    ? IGovernmentVerificationRequestSortByDirection.Ascending
                    : IGovernmentVerificationRequestSortByDirection.Descending,
              },
            });

            return;
          }

          if (sorter.columnKey === "status") {
            onChange({
              sorter: {
                sortBy: IGovernmentVerificationRequestSortBy.Status,
                sortDirection:
                  sorter.order === "ascend"
                    ? IGovernmentVerificationRequestSortByDirection.Ascending
                    : IGovernmentVerificationRequestSortByDirection.Descending,
              },
            });

            return;
          }

          if (sorter.columnKey === "created") {
            onChange({
              sorter: {
                sortBy: IGovernmentVerificationRequestSortBy.Created,
                sortDirection:
                  sorter.order === "ascend"
                    ? IGovernmentVerificationRequestSortByDirection.Ascending
                    : IGovernmentVerificationRequestSortByDirection.Descending,
              },
            });

            return;
          }

          onChange({
            sorter: null,
          });
        }}
        columns={[
          {
            title: "Project",
            key: "project",
            render: (_, item) => <Link to={`/projects/${item.project.slug}`}>{item.project.title}</Link>,
          },

          {
            title: "Origin",
            key: "origin",
            sorter: false,
            render: (_, item) => {
              if (item.project.__typename === "GroupProject") {
                const groupHref = `/groups/${item.project.group.slug}`;

                return (
                  <div>
                    <strong>Group:</strong> <Link to={groupHref}>{item.project.group.name}</Link>
                  </div>
                );
              }

              if (item.project.__typename === "VendorProject") {
                const vendorHref = `/vendors/${item.project.vendor.slug}`;

                return (
                  <div>
                    <strong>Vendor:</strong> <Link to={vendorHref}>{item.project.vendor.name}</Link>
                  </div>
                );
              }

              return "N/A";
            },
          },
          {
            title: "Author",
            key: "author",
            sorter: false,
            render: (_, item) => {
              const user = item.project.author;
              if (!user) {
                return "N/A";
              }

              return (
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture
                    size={24}
                    name={user.fullName}
                    image={user.avatar}
                    css={{
                      whiteSpace: "nowrap",
                    }}
                  />

                  <Spacing left={5}>
                    <Link to={`/users/${user.slug}`}>{user.fullName}</Link>
                  </Spacing>
                </div>
              );
            },
          },

          {
            title: "Government",
            key: "government",
            sorter: true,
            render: (_, item) => {
              const governmentHref = `/governments/${item.government._id}`;

              return (
                <Link
                  to={governmentHref}
                  css={{
                    display: "grid",
                    gridColumnGap: 8,
                    gridTemplateColumns: "repeat(2, max-content)",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture size={24} image={item.government.logo || ""} name={item.government.name} />

                  <span>{item.government.name}</span>
                </Link>
              );
            },
          },

          {
            title: "Status",
            key: "status",
            sorter: true,
            render: (_, item) => {
              if (item.status === IGovernmentVerificationRequestStatus.Pending) {
                return <Tag color="orange">Pending</Tag>;
              }

              if (item.status === IGovernmentVerificationRequestStatus.Verified) {
                return <Tag color="green">Verified</Tag>;
              }

              if (item.status === IGovernmentVerificationRequestStatus.Declined) {
                return <Tag color="magenta">Declined</Tag>;
              }

              if (item.status === IGovernmentVerificationRequestStatus.Cancelled) {
                return <Tag color="red">Cancelled</Tag>;
              }

              return item.status;
            },
          },

          {
            title: "Responded By",
            key: "respondedBy",
            render: (_, item) => {
              if (!item.respondedBy) {
                return null;
              }

              return (
                <div
                  css={{
                    display: "grid",
                    gridColumnGap: 8,
                    gridTemplateColumns: "repeat(2, max-content)",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture size={24} image={item.respondedBy.avatar || ""} name={item.respondedBy.fullName} />

                  <span>{item.respondedBy.fullName}</span>
                </div>
              );
            },
          },

          {
            title: "Created",
            key: "created",
            sorter: true,
            defaultSortOrder: "descend",
            render: (_, item) => {
              const createdAt = moment(item.createdAt);

              if (moment().diff(createdAt, "days") <= 30) {
                return (
                  <Tooltip title={createdAt.format("MM/DD/YYYY")}>
                    <span>{createdAt.fromNow()}</span>
                  </Tooltip>
                );
              }

              return createdAt.format("MM/DD/YYYY");
            },
          },

          {
            title: "Actions",
            key: "actions",
            render: (_, item) => {
              if (item.status === IGovernmentVerificationRequestStatus.Pending) {
                return (
                  <VerifyOrDecline
                    projectId={item.project._id}
                    governmentId={item.government._id}
                    onCompleted={() => {}}
                  >
                    {({ verify, decline, isVerifying, isDeclining }) => (
                      <Button.Group size="small">
                        <Button type="primary" loading={isVerifying} onClick={() => verify()}>
                          Verify
                        </Button>

                        <Button danger={true} loading={isDeclining} onClick={() => decline()}>
                          Decline
                        </Button>
                      </Button.Group>
                    )}
                  </VerifyOrDecline>
                );
              }

              return null;
            },
          },
        ]}
        dataSource={items}
      />
    </div>
  );
}
