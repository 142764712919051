import React from "react";
import { IGetTimezoneByCountryCodeQuery } from "../../queries/__generated__/GetTimezoneByCountryCode.generated";
import * as palette from "@govlaunch/palette";
import { useNavigate } from "react-router";
import { useMutation } from "react-apollo";
import {
  IEditCountryTimezoneMutation,
  IEditCountryTimezoneMutationVariables,
} from "../../mutations/__generated__/EditCountryTimezone.generated";
import EditCountryTimezone from "../../mutations/EditCountryTimezone";
import validate from "../../validate";
import countryTimezoneSchema from "./countryTimezoneSchema";
import { FORM_ERROR } from "final-form";
import { notification } from "antd";
import { Form } from "react-final-form";
import CountryTimezoneForm from "./CountryTimezoneForm";

type TCountryTimezone = NonNullable<NonNullable<IGetTimezoneByCountryCodeQuery["getTimezoneByCountryCode"]>>;

interface IEditCountryTimezoneFormProps {
  countryTimezone: TCountryTimezone;
}

export default function EditCountryTimezoneForm({ countryTimezone }: IEditCountryTimezoneFormProps) {
  const navigate = useNavigate();
  const [editCountryTimezone] = useMutation<IEditCountryTimezoneMutation, IEditCountryTimezoneMutationVariables>(
    EditCountryTimezone,
  );

  return (
    <div
      css={{
        maxWidth: 600,
        border: `1px solid ${palette.lightestGray}`,
        backgroundColor: palette.white,
        padding: 16,
      }}
    >
      <Form
        keepDirtyOnReinitialize={true}
        initialValues={{
          countryCode: countryTimezone.countryCode,
          countryName: countryTimezone.countryName,
          timezone: countryTimezone.timezone,
        }}
        validate={validate(countryTimezoneSchema)}
        onSubmit={async (values) => {
          try {
            const result = await editCountryTimezone({
              variables: {
                countryTimezoneId: countryTimezone._id,
                data: {
                  countryCode: values.countryCode,
                  countryName: values.countryName,
                  timezone: values.timezone,
                },
              },
            });

            if (result && result.data && result.data.editCountryTimezone) {
              notification.success({
                message: "Success!",
                description: "Country timezone saved successfully",
              });

              navigate("/newsletter/timezones");
            }
          } catch (e) {
            return {
              [FORM_ERROR]: "Could not save timezone setting",
            };
          }
        }}
      >
        {({ submitting, handleSubmit, submitError }) => (
          <CountryTimezoneForm
            submitting={submitting}
            handleSubmit={handleSubmit}
            submitError={submitError}
            isEditting={true}
          />
        )}
      </Form>
    </div>
  );
}
