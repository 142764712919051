import gql from "graphql-tag";
import VendorFields from "../queries/fragments/VendorFields";

export default gql`
  mutation AddVendor($vendor: VendorInput!) {
    addVendor(vendor: $vendor) {
      _id
      ...VendorFields
    }
  }

  ${VendorFields}
`;
