import React from "react";
import { INewsletterProject } from "../../../../types/types";

interface ICampaignPreviewProjectCardProps {
  project: INewsletterProject;
}

export default function CampaignPreviewProjectCard({ project }: ICampaignPreviewProjectCardProps) {
  const country = project.rawTitle.match(/([A-zÀ-ú-/&'., 0-9]+, (?:[A-Z]{2,3}))\s(.+)/);
  const title = country ? project.rawTitle.replace(country[1], "") : project.rawTitle;

  return (
    <td
      align="left"
      style={{
        padding: "5px",
        margin: 0,
      }}
    >
      <span
        style={{
          margin: 0,
          WebkitTextSizeAdjust: "none",
          //@ts-ignore
          msTextSizeAdjust: "none",
          color: "#333333",
          fontSize: "16px",
        }}
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "inline-block",
            WebkitTextSizeAdjust: "none",
            //@ts-ignore
            msTextSizeAdjust: "none",
            textDecoration: "none",
            color: "#333333",
            fontSize: "16px",
          }}
          href={project.url}
        >
          <EmailFlag countryCode={project.countryCode || ""} />
          {country && country[1] && (
            <span
              style={{
                color: "#086bef",
              }}
            >
              <b>{country[1]}</b>
            </span>
          )}
          {title}
        </a>
      </span>
    </td>
  );
}

interface IEmailFlagProps {
  countryCode: string;
}

export function EmailFlag({ countryCode }: IEmailFlagProps) {
  return (
    <img
      src={`https://static.govlaunch.com/flags/png/48x48/${countryCode}.png`}
      height="16"
      width="22"
      alt={countryCode}
      title={countryCode}
      style={{
        display: "inline-block",
        border: 0,
        outline: "none",
        textDecoration: "none",
        verticalAlign: "middle",
        margin: 0,
        padding: 0,
        marginRight: "4px",
      }}
    />
  );
}
