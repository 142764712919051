import React, { useState } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import { parseState } from "../../../utils/tablesUtils";
import TablePagination, { DEFAULT_CURSOR, ITablePaginationState } from "../../../components/table/TablePagination";
import { useQuery } from "react-apollo";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import TableAddStateToQueryString from "../../../components/table/TableAddStateToQueryString";
import TableOnSearch from "../../../components/table/TableOnSearch";
import ExternalLinksTable from "./ExternalLinksTable";
import GetExternalLinks from "../../../queries/GetExternalLinks";
import {
  IGetExternalLinksQuery,
  IGetExternalLinksQueryVariables,
} from "../../../queries/__generated__/GetExternalLinks.generated";

interface IManageExternalLinksProps {
  search: string;
}

interface IManageExternalLinksState {
  cursor: ITablePaginationState;
}

export default function ManageExternalLinks({ search }: IManageExternalLinksProps) {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<IManageExternalLinksState>(initialFiltersState);

  const { data, loading } = useQuery<IGetExternalLinksQuery, IGetExternalLinksQueryVariables>(GetExternalLinks, {
    variables: {
      search,
      limit: null,
      cursor: calculateAndEncodeCursor(state.cursor),
    },
    fetchPolicy: "network-only",
  });

  const externalLinks = (data && data.externalLinks && data.externalLinks.items) || [];
  const totalCount =
    data && data.externalLinks && data.externalLinks.pageInfo ? data.externalLinks.pageInfo.totalCount || 0 : 0;

  return (
    <div>
      {typeof history !== "undefined" && history && (
        <TableAddStateToQueryString tableState={state} historyOrigin="/externalLinks" query={query} />
      )}
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
                page: 1,
                size: state.cursor.size,
              },
            });
          }
        }}
      />
      <ExternalLinksTable
        externalLinks={externalLinks}
        loading={loading}
        footer={() => (
          <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
        )}
      />
    </div>
  );
}
