import React from "react";
import { IGroup } from "../../../types/types";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";

interface IGroupsFieldItemProps {
  group: Pick<IGroup, "_id" | "slug" | "name" | "logo">;
  onRemove: (vendor: Pick<IGroup, "_id" | "slug" | "name" | "logo">) => any;
}

export default function GroupsFieldItem({ group, onRemove, ...props }: IGroupsFieldItemProps) {
  return (
    <div
      {...props}
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "rgba(219, 236, 247, 0.5)",
        borderRadius: 60,
        padding: "10px 15px",
        "&:focus": {
          outline: 0,
        },
      }}
    >
      <ProfilePicture
        image={group.logo || ""}
        name={group.name}
        size={28}
        css={{
          userSelect: "none",
        }}
      />

      <div
        css={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          fontSize: 14,
          fontWeight: 600,
          color: palette.primary,
          lineHeight: 2,
          marginLeft: 8,
        }}
      >
        {group.name}
      </div>

      {onRemove && <RemoveItemIcon group={group} onRemove={onRemove} />}
    </div>
  );
}

interface IMinusIconProps {
  group: Pick<IGroup, "_id" | "slug" | "name" | "logo">;
  onRemove: (vendor: Pick<IGroup, "_id" | "slug" | "name" | "logo">) => any;
}

function RemoveItemIcon({ group, onRemove }: IMinusIconProps) {
  return (
    <svg
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onRemove(group);
      }}
      viewBox="0 0 117 117"
      width={28}
      height={28}
      css={{
        cursor: "pointer",
        marginLeft: "auto",
        "&:hover": {
          opacity: 0.8,
        },
      }}
    >
      <g fill="#FF7171" fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M58.5 116.4c31.9 0 57.9-26 57.9-57.9S90.4.6 58.5.6.6 26.6.6 58.5s26 57.9 57.9 57.9zm0-107.7c27.4 0 49.8 22.3 49.8 49.8 0 27.5-22.3 49.8-49.8 49.8-27.5 0-49.8-22.4-49.8-49.8S31.1 8.7 58.5 8.7z"
        />
        <path d="M31.8 62h53.3c2.3 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1H31.8c-2.3 0-4.1 1.8-4.1 4.1s1.9 4.1 4.1 4.1z" />
      </g>
    </svg>
  );
}
