import { Filestack, Loader } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing, SpacingBetween } from "@govlaunch/spacers";
// import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input, InputNumber, Select, notification } from "antd";
import ntw from "number-to-words";
import React from "react";
import { Mutation, useQuery } from "react-apollo";
import DocumentTitle from "react-document-title";
import { Field, Form as FinalForm } from "react-final-form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { default as AddGovernmentMutation } from "../../mutations/AddGovernment";
import {
  IAddGovernmentMutation,
  IAddGovernmentMutationVariables,
} from "../../mutations/__generated__/AddGovernment.generated";
import validate from "../../validate";
import COUNTRIES from "./Countries";
import Currencies, { getSupportedCurrencyByCountry } from "./Currencies";
import {
  IGovernmentTypesQuery,
  IGovernmentTypesQueryVariables,
} from "./graphql/queries/__generated__/GovernmentTypesQuery.generated";
import GovernmentTypesQuery from "./graphql/queries/GovernmentTypesQuery";

export default function AddGovernment() {
  const navigate = useNavigate();
  const { data: governmentTypesQueryData, loading: isGovernmentTypesLoading } = useQuery<
    IGovernmentTypesQuery,
    IGovernmentTypesQueryVariables
  >(GovernmentTypesQuery);

  const validationSchema = Yup.object({
    name: Yup.string().required("can't be blank"),
    // type: Yup.string().required("can't be blank"),
    governmentType: Yup.string().required("can't be blank"),
    country: Yup.string().required("can't be blank"),
    state: Yup.string().required("can't be blank"),
    stateName: Yup.string().required("can't be blank"),
    zipCode: Yup.number().required("can't be blank"),
    site: Yup.string().url("invalid url").required("can't be blank"),
    budget: Yup.number().moreThan(-1, "should be at least 0").lessThan(1000000000000000, "too many dollars"),
    population: Yup.number().moreThan(-1, "should be at least 0").lessThan(1000000000000000, "too many people"),
  });

  if (isGovernmentTypesLoading) {
    return (
      <div
        css={{
          padding: 20,
        }}
      >
        <Loader centered={false} />
      </div>
    );
  }

  return (
    <DocumentTitle title="Admin | Add Government">
      <div>
        <div
          css={{
            borderBottom: "1px solid rgb(232, 232, 232)",
            padding: 20,
          }}
        >
          <h3
            css={{
              fontWeight: 700,
              fontSize: 24,
              margin: 0,
            }}
          >
            Add Government 📝
          </h3>

          <Link to="/governments">All Governments</Link>
        </div>

        <Mutation<IAddGovernmentMutation, IAddGovernmentMutationVariables> mutation={AddGovernmentMutation}>
          {(addGovernment) => (
            <FinalForm
              keepDirtyOnReinitialize={true}
              validate={validate(validationSchema)}
              onSubmit={async (values) => {
                try {
                  const addGovernmentMutationResult = await addGovernment({
                    variables: {
                      payload: {
                        name: values.name,
                        type: values.type,
                        governmentType: values.governmentType,
                        state: values.state,
                        stateName: values.stateName,
                        country: values.country,
                        site: values.site,
                        budget: values.budget || 0,
                        currency: getSupportedCurrencyByCountry(values.country) || "USD",
                        population: values.population || 0,
                        county: values.county,
                        logo: values.logo,
                        zipCode: values.zipCode,
                        resources: [],
                        awards: [],
                      },
                    },
                  });

                  if (
                    addGovernmentMutationResult &&
                    addGovernmentMutationResult.data &&
                    addGovernmentMutationResult.data.addGovernment
                  ) {
                    notification.open({
                      type: "success",
                      message: "Government added!",
                      description: `Government added successfully!`,
                    });

                    navigate(`/governments/${addGovernmentMutationResult.data.addGovernment.slug}`);
                  }
                } catch (err) {
                  notification.open({
                    type: "error",
                    message: "Couldn't save",
                    description: `Couldn't add the government ${values.name}`,
                  });
                }
              }}
              initialValues={{
                name: "",
                type: "",
                governmentType: "",
                country: "",
                state: "",
                stateName: "",
                county: "",
                zipCode: "",
                site: "",
                logo: null,
                budget: 0,
                population: 0,
                awards: [],
                resources: [],
              }}
            >
              {({ handleSubmit, values, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <SpacingBetween top={10}>
                      <Field name="logo">
                        {({ input }) => (
                          <div>
                            <span
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: 0.5,
                                textTransform: "uppercase",
                                width: "100%",
                                display: "flex",
                              }}
                            >
                              Logo
                            </span>

                            <Spacing top={10}>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <ProfilePicture
                                  size={120}
                                  name={values.name}
                                  image={input.value || null}
                                  border={`solid 1px ${palette.lightestGray}`}
                                  style={{
                                    borderRadius: input.value ? null : 12,
                                  }}
                                />

                                <Spacing left={10}>
                                  <div
                                    css={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Filestack
                                      onSuccess={({ filesUploaded }: any) => {
                                        if (
                                          filesUploaded &&
                                          Array.isArray(filesUploaded) &&
                                          filesUploaded.length > 0 &&
                                          filesUploaded[0].mimetype.startsWith("image")
                                        ) {
                                          input.onChange(filesUploaded[0].url);
                                        }
                                      }}
                                      customRender={({ onPick }: any) => (
                                        <div
                                          css={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <SpacingBetween top={10}>
                                            <Button onClick={onPick} htmlType="button" size="small">
                                              Change
                                            </Button>

                                            {input.value && (
                                              <Button
                                                onClick={() => input.onChange(null)}
                                                htmlType="button"
                                                danger={true}
                                                size="small"
                                              >
                                                Remove
                                              </Button>
                                            )}
                                          </SpacingBetween>
                                        </div>
                                      )}
                                    />
                                  </div>
                                </Spacing>
                              </div>
                            </Spacing>
                          </div>
                        )}
                      </Field>

                      <SpacingBetween top={10}>
                        <Field name="name">
                          {({ input, meta }) => (
                            <div>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    display: "flex",
                                  }}
                                >
                                  Name
                                </span>

                                <FieldError meta={meta} />
                              </div>

                              <Spacing top={10}>
                                <Input size="large" {...input} defaultValue="" />
                              </Spacing>
                            </div>
                          )}
                        </Field>

                        {false && (
                          <Field name="type">
                            {({ input, meta }) => (
                              <div>
                                <div
                                  css={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    css={{
                                      fontSize: 12,
                                      fontWeight: 500,
                                      letterSpacing: 0.5,
                                      textTransform: "uppercase",
                                      display: "flex",
                                    }}
                                  >
                                    Type
                                  </span>

                                  <FieldError meta={meta} />
                                </div>

                                <Spacing top={10}>
                                  <Input size="large" {...input} defaultValue="" placeholder="Borough, Village, City" />
                                </Spacing>
                              </div>
                            )}
                          </Field>
                        )}

                        <Field name="governmentType">
                          {({ input, meta }) => (
                            <div>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    display: "flex",
                                  }}
                                >
                                  Government Type
                                </span>

                                <FieldError meta={meta} />
                              </div>

                              <Spacing top={10}>
                                <Select {...input} placeholder="Select a government type">
                                  {governmentTypesQueryData?.governmentTypes.map((governmentType) => {
                                    return (
                                      <Select.Option key={governmentType} value={governmentType}>
                                        {governmentType}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Spacing>
                            </div>
                          )}
                        </Field>

                        <Field name="country">
                          {({ input, meta }) => (
                            <div>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    display: "flex",
                                  }}
                                >
                                  Country
                                </span>

                                <FieldError meta={meta} />
                              </div>

                              <Spacing top={10}>
                                <Select {...input} placeholder="Select a country">
                                  {COUNTRIES.map(({ code, name }) => {
                                    return (
                                      <Select.Option key={code} value={code}>
                                        {name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Spacing>
                            </div>
                          )}
                        </Field>

                        <Field name="state">
                          {({ input, meta }) => (
                            <div>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    display: "flex",
                                  }}
                                >
                                  State
                                </span>

                                <FieldError meta={meta} />
                              </div>

                              <Spacing top={10}>
                                <Input size="large" {...input} defaultValue="" placeholder="CA" />
                              </Spacing>
                            </div>
                          )}
                        </Field>

                        <Field name="stateName">
                          {({ input, meta }) => (
                            <div>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    display: "flex",
                                  }}
                                >
                                  State Name
                                </span>

                                <FieldError meta={meta} />
                              </div>

                              <Spacing top={10}>
                                <Input size="large" {...input} defaultValue="" placeholder="California" />
                              </Spacing>
                            </div>
                          )}
                        </Field>

                        <Field name="county">
                          {({ input, meta }) => (
                            <div>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    display: "flex",
                                  }}
                                >
                                  County
                                </span>

                                <FieldError meta={meta} />
                              </div>

                              <Spacing top={10}>
                                <Input size="large" {...input} defaultValue="" />
                              </Spacing>
                            </div>
                          )}
                        </Field>

                        <Field name="zipCode">
                          {({ input, meta }) => (
                            <div>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    display: "flex",
                                  }}
                                >
                                  Zip / Postal Code
                                </span>

                                <FieldError meta={meta} />
                              </div>

                              <Spacing top={10}>
                                <Input size="large" {...input} defaultValue="" placeholder="00000" />
                              </Spacing>
                            </div>
                          )}
                        </Field>

                        <Field name="site">
                          {({ input, meta }) => (
                            <div>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    display: "flex",
                                  }}
                                >
                                  Site
                                </span>

                                <FieldError meta={meta} />
                              </div>

                              <Spacing top={10}>
                                <Input {...input} size="large" defaultValue="" />
                              </Spacing>
                            </div>
                          )}
                        </Field>

                        <Field name="country">
                          {({ input, meta }) => (
                            <div>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    display: "flex",
                                  }}
                                >
                                  Currency
                                </span>

                                <FieldError meta={meta} />
                              </div>

                              <Spacing top={10}>
                                <Select
                                  value={getSupportedCurrencyByCountry(input.value) || ""}
                                  disabled={true}
                                  placeholder="Select a currency"
                                >
                                  {Currencies.map(({ code, name }) => {
                                    return (
                                      <Select.Option key={code} value={code}>
                                        {name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Spacing>
                            </div>
                          )}
                        </Field>

                        <Field name="budget">
                          {({ input, meta }) => (
                            <div>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    display: "flex",
                                  }}
                                >
                                  Budget
                                </span>

                                <FieldError meta={meta} />
                              </div>

                              <Spacing top={10}>
                                <InputNumber
                                  {...input}
                                  size="large"
                                  style={{
                                    width: "100%",
                                  }}
                                  formatter={(value) => `💵 ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  parser={(value) => parseFloat(value ? value.replace(/([^\d]+)/g, "") : "")}
                                />
                              </Spacing>

                              <Spacing top={5}>
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontStyle: "italic",
                                  }}
                                >
                                  {spellDollars(input.value)}
                                </span>
                              </Spacing>
                            </div>
                          )}
                        </Field>

                        <Field name="population">
                          {({ input, meta }) => (
                            <div>
                              <div
                                css={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    letterSpacing: 0.5,
                                    textTransform: "uppercase",
                                    display: "flex",
                                  }}
                                >
                                  Population
                                </span>

                                <FieldError meta={meta} />
                              </div>

                              <Spacing top={10}>
                                <InputNumber
                                  {...input}
                                  size="large"
                                  style={{
                                    width: "100%",
                                  }}
                                  defaultValue={0}
                                  formatter={(value) => `👪 ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                  parser={(value) => parseInt(value ? value.replace(/([^\d]+)/g, "") : "")}
                                />
                              </Spacing>

                              <Spacing top={5}>
                                <span
                                  css={{
                                    fontSize: 12,
                                    fontStyle: "italic",
                                  }}
                                >
                                  {spellPopulation(input.value)}
                                </span>
                              </Spacing>
                            </div>
                          )}
                        </Field>
                      </SpacingBetween>
                    </SpacingBetween>
                  </div>

                  <div
                    css={{
                      padding: 20,
                      borderTop: "1px solid rgb(232, 232, 232)",
                    }}
                  >
                    <SpacingBetween right={10}>
                      <Button htmlType="submit" loading={submitting} disabled={submitting} size="large" type="primary">
                        Save
                      </Button>
                    </SpacingBetween>
                  </div>
                </form>
              )}
            </FinalForm>
          )}
        </Mutation>
      </div>
    </DocumentTitle>
  );
}

function spellPopulation(population: any) {
  if (parseFloat(population) > 1000000000000000) {
    return "too many people";
  } else if (!population) {
    return "knock, knock! who's there?";
  }

  return parseFloat(population) === 1 ? `${ntw.toWords(population)} guy/gal` : `${ntw.toWords(population)} people`;
}

function spellDollars(budget: any) {
  if (parseFloat(budget) > 1000000000000000) {
    return "big money";
  } else if (!budget) {
    return "no money at all";
  }

  return parseFloat(budget) === 1 ? `${ntw.toWords(budget)} dollar` : `${ntw.toWords(budget)} dollars`;
}

interface IFieldErrorProps {
  meta: Partial<{
    touched: boolean;
    error: any;
    submitError: any;
  }>;
}

function FieldError({ meta }: IFieldErrorProps) {
  if (meta.touched && (meta.error || meta.submitError)) {
    return (
      <div
        css={{
          marginLeft: 10,
          color: palette.red,
          textTransform: "uppercase",
          fontSize: 12,
        }}
      >
        {meta.error || meta.submitError}
      </div>
    );
  } else {
    return null;
  }
}
