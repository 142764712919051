import gql from "graphql-tag";

export default gql`
  mutation ArchiveStory($storyId: ObjectId!) {
    archiveStory(storyId: $storyId) {
      _id
      status
      archivedAt
    }
  }
`;
