import React from "react";
import { Field } from "react-final-form";
import { Toggle } from "react-powerplug";
import Popover from "@govlaunch/popover";
import * as palette from "@govlaunch/palette";
import Resources from "../../resources/Resources";
import ResourceForm from "../../resources/ResourceForm";

export default function ResourcesField() {
  return (
    <Field name="resources">
      {({ input }) => (
        <>
          <Resources
            resources={input.value}
            onRemove={(resource: any) => {
              const nextResources = input.value.filter((maybeResource: any) => maybeResource !== resource);

              input.onChange(nextResources);
            }}
          />
          <Toggle>
            {({ on: isOpen, toggle }) => (
              <Popover
                boxStyle={{
                  boxShadow: "rgba(34, 35, 40, 0.2) 0px 10px 49px 0px",
                }}
                onClickOutside={() => {
                  const filestackIsOpen = document.getElementById("__filestack-picker");

                  if (!filestackIsOpen) {
                    toggle();
                  }
                }}
                placement="top"
                isOpen={isOpen}
                render={() => (
                  <ResourceForm
                    onSubmit={(resource: any) => {
                      input.onChange(input.value.concat(resource));

                      toggle();
                    }}
                    onClose={toggle}
                    allowText={true}
                  />
                )}
              >
                {({ innerRef }: { innerRef: React.RefObject<HTMLSpanElement> }) => (
                  <span
                    css={{
                      color: palette.primary,
                      textTransform: "uppercase",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: 14,
                      cursor: "pointer",
                      border: `solid 1px ${palette.lightestGray}`,
                      minHeight: 40,
                      "&:hover": {
                        opacity: 0.8,
                      },
                    }}
                    ref={innerRef}
                    role="button"
                    onClick={toggle}
                  >
                    Add Resource
                  </span>
                )}
              </Popover>
            )}
          </Toggle>
        </>
      )}
    </Field>
  );
}
