import gql from "graphql-tag";

export default gql`
  fragment GovernmentUserFields on GovernmentUser {
    _id
    intercomId
    slug
    role
    avatar
    firstName
    fullName
    title
    jobTitle
    notificationEmail
    email
    createdAt
    bannedAt
    lastSignInAt
    isInnovator
    linkedinUrl
    linkedinId
    lastSignInProvider
    verified
    government {
      _id
      slug
      name
      logo
      isInnovator
    }
    department {
      _id
      name
    }
    interests {
      _id
      name
    }
    groups {
      _id
      slug
      logo
      name
      membersCount
      vendors {
        _id
        slug
        name
        logo
        viewerIsMember
      }
      governments {
        _id
        slug
        name
        logo
        viewerIsMember
        viewerIsOwner
      }
    }
    signInAttempts {
      registeredAt
      outcome
      ip
      userAgent
      lookup
      whitelisted
    }
    notes
    socialMedia {
      linkedin
      twitter
      facebook
      instagram
      youtube
    }
    latestNewsletterHtml
    newsletterFrequency
    subscribedToNewsletter
    subscribedToCampaign
    country
    language
    timeZone
    onboardingStatus
    searchable
    isSiteAdmin
    preferences {
      showAsAuthorOnProjects
    }
  }
`;
