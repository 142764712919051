import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { Tabs } from "antd";
import queryString from "query-string";
import React from "react";
import DocumentTitle from "react-document-title";
import { useLocation, useNavigate } from "react-router";
import { IGroupsFilter, IGroupKind } from "../../../types/types";
import { MetaBaseQuestion } from "../../components/MetaBaseLink";
import TablePageHeader from "../../components/table/TablePageHeader";
import GroupsCount from "./GroupsCount";
import GroupsWithSearchWithRouter from "./tables/GroupsWithSearchWithRouter";

export default function Groups() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = Array.isArray(query.tab) ? query.tab[0] : query.tab || "all";

  return (
    <DocumentTitle title="Groups | Admin">
      <>
        <TablePageHeader
          title="Groups"
          titleIcon={faUsers}
          subTitle="All Groups"
          metabaseQuestion={MetaBaseQuestion.Groups}
        />
        <GroupsCount>
          {({
            allCount,
            archivedCount,
            vendorCount,
            discussionCount,
            communityCount,
          }: {
            allCount: number;
            archivedCount: number;
            vendorCount: number;
            discussionCount: number;
            communityCount: number;
          }) => (
            <Tabs
              animated={false}
              onChange={(tab) => {
                navigate(`/groups?tab=${tab}`);
              }}
              activeKey={tab || "all"}
              size="middle"
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
            >
              <Tabs.TabPane tab={allCount ? `All (${allCount})` : "All"} key="all">
                {tab === "all" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <GroupsWithSearchWithRouter groupsFilter={IGroupsFilter.All} />
                  </div>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={vendorCount ? `Vendor (${vendorCount})` : `Vendor`} key="vendor">
                {tab === "vendor" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <GroupsWithSearchWithRouter filterByKind={IGroupKind.Vendor} />
                  </div>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={discussionCount ? `Discussion (${discussionCount})` : `Discussion`} key="discussion">
                {tab === "discussion" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <GroupsWithSearchWithRouter filterByKind={IGroupKind.Discussion} />
                  </div>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={communityCount ? `Community (${communityCount})` : `Community`} key="community">
                {tab === "community" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <GroupsWithSearchWithRouter filterByKind={IGroupKind.Community} />
                  </div>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={archivedCount ? `Archived (${archivedCount})` : `Archived`} key="archived">
                {tab === "archived" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <GroupsWithSearchWithRouter groupsFilter={IGroupsFilter.Archived} />
                  </div>
                )}
              </Tabs.TabPane>
            </Tabs>
          )}
        </GroupsCount>
      </>
    </DocumentTitle>
  );
}
