import React, { ReactElement } from "react";

export default function TabContent(props: any): ReactElement {
  return (
    <div
      css={{
        padding: 20,
      }}
      {...props}
    />
  );
}
