import * as palette from "@govlaunch/palette";
import { Popconfirm, Popover } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import { useToggle } from "react-use";
import { ICollectionStatus } from "../../../types/types";
import DotdotdotIcon from "../../components/icons/DotdotdotIcon";
import { IListAllCollectionsQuery } from "./graphql/queries/__generated__/ListAllCollections.generated";
import ToggleArchiveOnCollectionMutation from "./graphql/mutations/ToggleArchiveOnCollectionMutation";
import ToggleCollectionPublicContentAvailable from "./graphql/mutations/ToggleCollectionPublicContentAvailable";
import TogglePublishedOnCollection from "./graphql/mutations/TogglePublishedOnCollection";
import {
  IToggleArchiveOnCollectionMutation,
  IToggleArchiveOnCollectionMutationVariables,
} from "./graphql/mutations/__generated__/ToggleArchiveOnCollectionMutation.generated";
import {
  IToggleCollectionPublicContentAvailableMutation,
  IToggleCollectionPublicContentAvailableMutationVariables,
} from "./graphql/mutations/__generated__/ToggleCollectionPublicContentAvailable.generated";

interface ICollectionsTableActionsProps {
  collection: IListAllCollectionsQuery["listAllCollections"]["items"][0];
  refetchCounters: any;
}

export default function CollectionsTableActions({ collection, refetchCounters }: ICollectionsTableActionsProps) {
  const [showingMenu, toggleShowingMenu] = useToggle(false);

  const [togglePublished] = useMutation(TogglePublishedOnCollection, {
    refetchQueries: ["ListAllCollections"],
  });

  const [toggleArchiveOnCollection] = useMutation<
    IToggleArchiveOnCollectionMutation,
    IToggleArchiveOnCollectionMutationVariables
  >(ToggleArchiveOnCollectionMutation, {
    refetchQueries: ["ListAllCollections"],
  });

  const [togglePublicContentAvailable] = useMutation<
    IToggleCollectionPublicContentAvailableMutation,
    IToggleCollectionPublicContentAvailableMutationVariables
  >(ToggleCollectionPublicContentAvailable);

  return (
    <Popover
      visible={showingMenu}
      onVisibleChange={toggleShowingMenu}
      trigger="click"
      content={
        <ul
          css={{
            margin: 0,
            padding: 0,
            listStyle: "none",
            "> li:not(:first-of-type)": {
              marginTop: 10,
            },
          }}
        >
          {collection.status === ICollectionStatus.Archived ? (
            <li>
              <span
                onClick={async () => {
                  await toggleArchiveOnCollection({
                    variables: {
                      collectionId: collection._id,
                    },
                  });

                  refetchCounters();
                }}
                css={{
                  color: palette.blue,
                  cursor: "pointer",
                }}
              >
                Restore
              </span>
            </li>
          ) : (
            <>
              <li>
                <span
                  onClick={async () => {
                    await togglePublished({
                      variables: {
                        collectionId: collection._id,
                      },
                    });

                    refetchCounters();
                  }}
                  css={{
                    color: palette.blue,
                    cursor: "pointer",
                  }}
                >
                  {collection.publishedAt ? "Unpublish" : "Publish"}
                </span>
              </li>
              <li>
                <span
                  onClick={async () => {
                    await togglePublicContentAvailable({
                      variables: {
                        collectionId: collection._id,
                      },
                    });
                  }}
                  css={{
                    color: collection.publicContentAvailable ? palette.orange : palette.lightGreen,
                    cursor: "pointer",
                  }}
                >
                  {collection.publicContentAvailable ? "Gate content" : "Ungate content"}
                </span>
              </li>
              <li>
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={async () => {
                    await toggleArchiveOnCollection({
                      variables: {
                        collectionId: collection._id,
                      },
                    });

                    refetchCounters();
                  }}
                >
                  <span
                    css={{
                      color: palette.red,
                      fontSize: 14,
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        opacity: 0.8,
                      },
                    }}
                  >
                    Archive
                  </span>
                </Popconfirm>
              </li>
            </>
          )}
        </ul>
      }
    >
      <span
        role="button"
        css={{
          cursor: "pointer",
        }}
      >
        <DotdotdotIcon />
      </span>
    </Popover>
  );
}
