import { Button, Col, Grid, RowWrap } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { notification, Popconfirm } from "antd";
import React from "react";
import { Mutation } from "react-apollo";
import ToggleIsOnboardingProcessActive from "../../mutations/ToggleIsOnboardingProcessActive";
import {
  IToggleIsOnboardingProcessActiveMutation,
  IToggleIsOnboardingProcessActiveMutationVariables,
} from "../../mutations/__generated__/ToggleIsOnboardingProcessActive.generated";
import Section from "../form/Section";
import SectionBody from "../form/SectionBody";
import SectionHeader from "../form/SectionHeader";

interface IOnboardingSettingsProps {
  isOnboardingProcessActive: boolean;
  refetchSettings: () => any;
}

export default function OnboardingSettings({ isOnboardingProcessActive, refetchSettings }: IOnboardingSettingsProps) {
  return (
    <div
      css={{
        marginBottom: 10,
        marginLeft: 0,
        maxWidth: 900,
      }}
    >
      <Section>
        <SectionHeader>
          <h1
            css={{
              margin: 0,
              color: "#212121",
              fontWeight: 600,
              fontSize: 16,
            }}
          >
            Onboarding Settings
          </h1>
        </SectionHeader>
        <SectionBody>
          <Grid
            css={{
              marginBottom: 10,
              marginLeft: 0,
            }}
          >
            <RowWrap>
              <Col width={2 / 3}>
                <div
                  css={{
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  Onboarding Process Status:
                </div>
              </Col>
              <Col width={1 / 3}>
                <div
                  css={{
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  {isOnboardingProcessActive ? "Active" : "Not Active"}
                </div>
              </Col>
            </RowWrap>
            <RowWrap>
              <span
                style={{
                  fontStyle: "italic",
                  marginTop: 15,
                  marginLeft: 15,
                  fontSize: 14,
                  fontWeight: 500,
                  color: palette.mediumGray,
                }}
              >
                * If status is set to 'Not Active' new users will NOT be forced to go through the Onboarding process.
              </span>
            </RowWrap>
            <RowWrap>
              <ToggleIsOnboardingActiveMutation currentStatus={isOnboardingProcessActive} refetch={refetchSettings} />
            </RowWrap>
          </Grid>
        </SectionBody>
      </Section>
    </div>
  );
}

interface IToggleOnboardingMutationProps {
  currentStatus: boolean;
  refetch: () => any;
}

function ToggleIsOnboardingActiveMutation({ currentStatus, refetch }: IToggleOnboardingMutationProps) {
  return (
    <Mutation<IToggleIsOnboardingProcessActiveMutation, IToggleIsOnboardingProcessActiveMutationVariables>
      mutation={ToggleIsOnboardingProcessActive}
      variables={{
        isActive: !currentStatus,
      }}
      onCompleted={({ toggleIsOnboardingActive }) => {
        notification.open({
          type: "success",
          message: "Successfully updated Global settings",
          description: `Successfully ${
            toggleIsOnboardingActive?.isOnboardingActive ? "activated" : "deactivated"
          } the Onboarding process!`,
        });
        refetch();
      }}
      onError={() => {
        notification.open({
          type: "error",
          message: "Unable to toggle the Onboarding process",
        });
      }}
    >
      {(toggleOnboarding) => (
        <Popconfirm
          title={`Are you sure you want to ${!currentStatus ? "Activate" : "Deactivate"} the Onboarding process?`}
          onConfirm={async () => {
            await toggleOnboarding();
          }}
        >
          <Button
            css={{
              marginTop: 15,
              marginLeft: 15,
              border: "2px solid",
              borderColor: !currentStatus ? palette.innovatorBlue : palette.red,
              color: !currentStatus ? palette.white : palette.red,
              background: !currentStatus ? palette.innovatorBlue : palette.lightestGray,
              fontWeight: 500,
            }}
            type="button"
          >
            {!currentStatus ? "Activate" : "Deactivate"}
          </Button>
        </Popconfirm>
      )}
    </Mutation>
  );
}
