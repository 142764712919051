import React from "react";
import { IGetGovernmentQuery } from "../../../queries/__generated__/GetGovernmentQuery.generated";
import ReactVisibilitySensor from "react-visibility-sensor";
import StoriesTable from "../../stories/paginated/StoriesTable";

/**
 * TODO 2020-04-03 *Stoyan Prokopov*: switch to using StoriesTable component from /stories/paginated
 * since that component has pagination implemented
 */

interface IStoriesTabProps {
  government: IGetGovernmentQuery["government"];
  refetch: () => any;
  loading: boolean;
  fetchMore: any;
}

const StoriesTab = ({ government, refetch, loading, fetchMore }: IStoriesTabProps) => {
  if (!government) {
    return null;
  }

  const stories = (government && government.stories && government.stories.items) || [];

  return (
    <div>
      <StoriesTable
        stories={stories}
        refetch={refetch}
        renderStoryLink={(story) => {
          return `/governments/${government.slug}/stories/${story.slug}`;
        }}
      />

      <ReactVisibilitySensor
        partialVisibility={true}
        active={
          !loading && government.stories && government.stories.pageInfo && government.stories.pageInfo.hasNextPage
            ? government.stories.pageInfo.hasNextPage
            : false
        }
        onChange={(isActive) => {
          if (isActive) {
            fetchMore({
              variables: {
                storiesCursor:
                  government.stories && government.stories.pageInfo && government.stories.pageInfo.endCursor,
              },
              updateQuery: (current: any, { fetchMoreResult }: any) => {
                return {
                  government: {
                    ...current.government,
                    stories: {
                      ...fetchMoreResult.government.stories,
                      items: [...current.government.stories.items, ...fetchMoreResult.government.stories.items],
                    },
                  },
                };
              },
            });
          }
        }}
      />
    </div>
  );
};

export default StoriesTab;
