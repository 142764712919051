import React from "react";
import { INewsletterStory } from "../../../../types/types";
import CampaignPreviewStoryCard from "./CampaignPreviewStoryCard";

interface ICampaignPreviewStoriesProps {
  stories: Array<INewsletterStory>;
}

export default function CampaignPreviewStories({ stories }: ICampaignPreviewStoriesProps) {
  return (
    <>
      {stories.map((story, index) => (
        <tr key={`story_${index}`}>
          <td
            align="left"
            style={{
              padding: 0,
              margin: 0,
              paddingBottom: "20px",
              paddingTop: "35px",
            }}
          >
            <CampaignPreviewStoryCard story={story} />
          </td>
        </tr>
      ))}
    </>
  );
}
