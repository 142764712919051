import gql from "graphql-tag";

export default gql`
  mutation RemoveCitizenVerification($userId: ObjectId!) {
    removeCitizenVerification(userId: $userId) {
      ... on PendingUser {
        _id
        verified
      }
      ... on VendorUser {
        _id
        verified
      }
      ... on GovernmentUser {
        _id
        verified
      }
    }
  }
`;
