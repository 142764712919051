import React from "react";
import PostsTable from "../../posts/PostsTable";
import { Query } from "react-apollo";
import GetPostsByVendor from "../../../queries/GetPostsByVendor";
import {
  IGetPostsByVendorQuery,
  IGetPostsByVendorQueryVariables,
} from "../../../queries/__generated__/GetPostsByVendor.generated";
import { NetworkStatus } from "apollo-client";
import { LoadingIndicator } from "@govlaunch/core";
import getOr from "lodash/fp/getOr";
import VisibilitySensor from "react-visibility-sensor";

interface IVendorPostsTabProps {
  vendorId: string;
}

export default function VendorPostsTab({ vendorId }: IVendorPostsTabProps) {
  return (
    <Query<IGetPostsByVendorQuery, IGetPostsByVendorQueryVariables>
      fetchPolicy="network-only"
      notifyOnNetworkStatusChange={true}
      query={GetPostsByVendor}
      variables={{
        vendorId: vendorId,
        sort: null,
        cursor: null,
      }}
    >
      {({ data, fetchMore, refetch, networkStatus }) => {
        const loading = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables;
        const fetchingMore = networkStatus === NetworkStatus.fetchMore;

        return (
          <div
            css={{
              padding: 20,
            }}
          >
            <PostsTable
              posts={getOr([], "postsByVendor.items", data)}
              onRemovePost={refetch}
              onChange={(_pagination: any, _filter: any, sorter: any) => {
                if (!sorter) {
                  return;
                }

                if (!sorter.column) {
                  refetch({
                    vendorId: vendorId,
                    sort: null,
                    cursor: null,
                  });
                }

                if (["body"].includes(sorter.columnKey)) {
                  refetch({
                    vendorId: vendorId,
                    sort: parseSorter(sorter),
                    cursor: null,
                  });
                }
              }}
            />

            {data && data.postsByVendor && data.postsByVendor.pageInfo && (
              <>
                <VisibilitySensor
                  active={
                    loading === false && data.postsByVendor.pageInfo.hasNextPage
                      ? data.postsByVendor.pageInfo.hasNextPage
                      : false
                  }
                  partialVisibility={true}
                  onChange={isActive => {
                    return (
                      isActive &&
                      fetchMore({
                        variables: {
                          cursor:
                            data.postsByVendor && data.postsByVendor.pageInfo && data.postsByVendor.pageInfo.endCursor
                              ? data.postsByVendor.pageInfo.endCursor
                              : null,
                          vendorId: vendorId,
                        },
                        updateQuery: (current, { fetchMoreResult }) => {
                          if (
                            !current.postsByVendor ||
                            !current.postsByVendor.items ||
                            !fetchMoreResult ||
                            !fetchMoreResult.postsByVendor ||
                            !fetchMoreResult.postsByVendor.items
                          ) {
                            return current;
                          }

                          return {
                            __typename: "Query",
                            postsByVendor: {
                              ...fetchMoreResult.postsByVendor,
                              items: current.postsByVendor.items.concat(fetchMoreResult.postsByVendor.items),
                            },
                          };
                        },
                      })
                    );
                  }}
                />

                {fetchingMore && <LoadingIndicator />}
              </>
            )}
          </div>
        );
      }}
    </Query>
  );
}

function parseSorter(sorter: any): string | null {
  if (sorter && sorter.columnKey) {
    return `${sorter.columnKey}:${sorter.order}`;
  }

  return null;
}
