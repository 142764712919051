import React from "react";
import * as palette from "@govlaunch/palette";

interface IFontIconProps extends React.SVGProps<any> {
  fillColor?: string | undefined;
}

export default function FontIcon({ fillColor, ...props }: IFontIconProps) {
  if (!fillColor) {
    fillColor = palette.primary;
  }

  return (
    <svg viewBox="0 0 340.111 340.111" width="1em" height="1em" {...props}>
      <path
        fill={fillColor}
        d="M340.111 76.316V65.175h-47.905v11.141h18.382v187.478h-18.382v11.141h47.905v-11.141h-18.382V76.316z"
      />
      <g fill={fillColor}>
        <path d="M2.067 229.59l56.068-126.615c3.909-8.731 11.03-14.018 20.684-14.018h2.068c9.648 0 16.544 5.286 20.449 14.018l56.07 126.615c1.149 2.528 1.84 4.825 1.84 7.124 0 9.421-7.354 17.004-16.776 17.004-8.272 0-13.788-4.825-17.004-12.18l-10.799-25.275H43.891l-11.26 26.426c-2.988 6.893-8.961 11.029-16.315 11.029C7.121 253.718 0 246.365 0 237.173c0-2.528.918-5.055 2.067-7.583zm99.501-44.579l-22.291-53.082-22.289 53.082h44.58zM176.011 216.951v-.46c0-26.885 20.452-39.294 49.635-39.294 12.41 0 21.373 2.068 30.105 5.056v-2.068c0-14.478-8.963-22.519-26.427-22.519-9.651 0-17.464 1.378-24.128 3.447-2.067.689-3.447.918-5.058.918-8.04 0-14.474-6.204-14.474-14.246 0-6.205 3.905-11.49 9.419-13.559 11.03-4.136 22.981-6.434 39.296-6.434 19.071 0 32.86 5.055 41.593 13.787 9.191 9.191 13.327 22.75 13.327 39.295v56.068c0 9.423-7.583 16.775-17.005 16.775-10.111 0-16.774-7.123-16.774-14.477v-.23c-8.502 9.421-20.224 15.625-37.226 15.625-23.211.002-42.283-13.324-42.283-37.684zm80.197-8.043v-6.204c-5.974-2.757-13.787-4.596-22.289-4.596-14.938 0-24.128 5.975-24.128 17.004v.46c0 9.422 7.813 14.936 19.072 14.936 16.315.001 27.345-8.96 27.345-21.6z" />
      </g>
    </svg>
  );
}
