import gql from "graphql-tag";

export default gql`
  mutation ResetOnboarding($userId: ObjectId!) {
    resetOnboarding(userId: $userId) {
      _id
      workingFor
    }
  }
`;
