import React from "react";
import { IGovernmentProjectFieldsFragment } from "../../queries/fragments/__generated__/GovernmentProjectFields.generated";
import { IGroupProjectFieldsFragment } from "../../queries/fragments/__generated__/GroupProjectFields.generated";
import { IVendorProjectFieldsFragment } from "../../queries/fragments/__generated__/VendorProjectFields.generated";
import DotdotdotIcon from "../../components/icons/DotdotdotIcon";
import { Toggle } from "react-powerplug";
import { Link } from "react-router-dom";
import { Popover as AntdPopover } from "antd";
import ArchiveProjectAction from "./actions/ArchiveProjectAction";
import RestoreProjectAction from "./actions/RestoreProjectAction";
import ToggleVerifyProjectAction from "./actions/ToggleVerifyProjectAction";

type TProjectFields = IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment;

interface IProjectActionsProps {
  project: TProjectFields;
  onDeleteProject?: any;
}

export default function ProjectActions({ project, onDeleteProject = null }: IProjectActionsProps) {
  return (
    <Toggle>
      {({ on, toggle }) => (
        <AntdPopover
          visible={on}
          trigger="click"
          title="Actions"
          placement="rightBottom"
          onVisibleChange={toggle}
          content={
            <ul
              css={{
                margin: 0,
                padding: 0,
                listStyle: "none",
                "> li:not(:first-of-type)": {
                  marginTop: 10,
                },
              }}
            >
              <li>
                <Link to={`/projects/${project.slug}`}>Edit project</Link>
              </li>

              {!project.archivedAt && (
                <li>
                  <ArchiveProjectAction projectId={project._id} onDeleteProject={onDeleteProject} />
                </li>
              )}

              {project.archivedAt && (
                <li>
                  <RestoreProjectAction projectId={project._id} />
                </li>
              )}

              {!project.archivedAt && (
                <li>
                  <ToggleVerifyProjectAction projectId={project._id} verified={project.verified} />
                </li>
              )}
            </ul>
          }
        >
          <span
            role="button"
            css={{
              cursor: "pointer",
            }}
          >
            <DotdotdotIcon />
          </span>
        </AntdPopover>
      )}
    </Toggle>
  );
}
