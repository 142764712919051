import { CSSReset, MultiTagSelector, theme, ThemeProvider } from "@govlaunch/web";
import React from "react";
import { Field } from "react-final-form";
import { useQuery } from "react-apollo";
import AllSolutionsQuery from "../../solutions/graphql/queries/AllSolutionsQuery";
import { ISolutionStatus } from "../../../../types/types";
import {
  IAllSolutionsQuery,
  IAllSolutionsQueryVariables,
} from "../../solutions/graphql/queries/__generated__/AllSolutionsQuery.generated";

export default function SolutionsField() {
  const { data } = useQuery<IAllSolutionsQuery, IAllSolutionsQueryVariables>(AllSolutionsQuery, {
    fetchPolicy: "network-only",
    variables: {
      searchText: null,
      filterByStatus: ISolutionStatus.Approved,
    },
  });

  const tags = (data?.allSolutions || []).map((solution) => {
    return {
      label: solution.name,
      value: solution._id,
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />

      <Field name="solutions">
        {({ input, meta }) => {
          return (
            <MultiTagSelector
              id="tags"
              selectedTags={input.value}
              tags={tags}
              onChange={input.onChange}
              onBlur={() => input.onBlur()}
              placeholder="Type a solution name... "
              tagProps={{
                variant: "rounded",
              }}
              inputProps={{
                invalid: meta.error && meta.touched,
              }}
              bg="teal.100"
            />
          );
        }}
      </Field>
    </ThemeProvider>
  );
}
