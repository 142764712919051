/* eslint-disable */
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { Tabs } from "antd";
import queryString from "query-string";
import React from "react";
import DocumentTitle from "react-document-title";
import { useLocation, useNavigate } from "react-router";
import { ITagType } from "../../../types/types";
import { MetaBaseQuestion } from "../../components/MetaBaseLink";
import TablePageHeader from "../../components/table/TablePageHeader";
import TagsCount from "./TagsCount";
import TagsWithSearch from "./TagsWithSearchWithRouter";

export default function AllTags() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = Array.isArray(query.tab) ? query.tab[0] : query.tab || "all";

  return (
    <DocumentTitle title="Tags | Admin">
      <>
        <TablePageHeader title="Tags" titleIcon={faTags} subTitle="All Tags" metabaseQuestion={MetaBaseQuestion.Tags} />
        <TagsCount>
          {({
            allCount,
            projectsCount,
            productsCount,
            storiesCount,
            awardsCount,
            departmentsCount,
            archivedCount,
            customCount,
            refetch,
          }) => (
            <Tabs
              animated={false}
              onChange={(tab) => {
                navigate(`/tags?tab=${tab}`);
                refetch();
              }}
              activeKey={tab || "all"}
              size="middle"
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
            >
              <Tabs.TabPane tab={`All (${allCount})`} key="all">
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  {tab === "all" && <TagsWithSearch key={tab} archivedOnly={false} />}
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={`Projects (${projectsCount})`} key="projects">
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  {tab === "projects" && (
                    <TagsWithSearch key={tab} filterByTagType={ITagType.Project} archivedOnly={false} />
                  )}
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={`Products (${productsCount})`} key="products">
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  {tab === "products" && (
                    <TagsWithSearch key={tab} filterByTagType={ITagType.Product} archivedOnly={false} />
                  )}
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={`Stories (${storiesCount})`} key="stories">
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  {tab === "stories" && (
                    <TagsWithSearch key={tab} filterByTagType={ITagType.Story} archivedOnly={false} />
                  )}
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={`Award (${awardsCount})`} key="award">
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  {tab === "award" && (
                    <TagsWithSearch key={tab} filterByTagType={ITagType.Award} archivedOnly={false} />
                  )}
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={`Department (${departmentsCount})`} key="department">
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  {tab === "department" && (
                    <TagsWithSearch key={tab} filterByTagType={ITagType.Department} archivedOnly={false} />
                  )}
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={`Custom (${customCount})`} key="custom">
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  {tab === "custom" && (
                    <TagsWithSearch key={tab} filterByTagType={ITagType.Custom} archivedOnly={false} />
                  )}
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={`Archived (${archivedCount})`} key="archived">
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  {tab === "archived" && <TagsWithSearch key={tab} archivedOnly={true} />}
                </div>
              </Tabs.TabPane>
            </Tabs>
          )}
        </TagsCount>
      </>
    </DocumentTitle>
  );
}
