import gql from "graphql-tag";
import GovernmentProjectFields from "./fragments/GovernmentProjectFields";
import GroupProjectFields from "./fragments/GroupProjectFields";
import VendorProjectFields from "./fragments/VendorProjectFields";

export default gql`
  query ProjectBySlug($slug: String!) {
    projectBySlug(slug: $slug) {
      _id
      ... on GovernmentProject {
        _id
        ...GovernmentProjectFields
      }
      ... on GroupProject {
        _id
        ...GroupProjectFields
      }
      ... on VendorProject {
        _id
        ...VendorProjectFields
      }
    }
  }
  ${GroupProjectFields}
  ${GovernmentProjectFields}
  ${VendorProjectFields}
`;
