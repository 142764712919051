import gql from "graphql-tag";

export default gql`
  mutation EditPlan($planId: ObjectId!, $plan: AddPlanInput!) {
    editPlan(planId: $planId, plan: $plan) {
      _id
      name
      displayName
      stripePlanId
      tier
      limits {
        products
        enrichedProducts
        productTags
        groups
        groupInvitations
        teamMembers
        projectsPerMonth
        enrichedProfile
        sharingPage
        searchAccess
        inviteGovernments
      }
    }
  }
`;
