import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { IGroup } from "../../../types/types";
import GroupsFieldItem from "./GroupsFieldItem";

interface IGroupsFieldProps {
  fieldName: string;
}

export default function GroupsField({ fieldName }: IGroupsFieldProps) {
  return (
    <Field name={fieldName}>
      {({ input }) => {
        const groups = input.value || [];

        if (groups.length === 0) {
          return null;
        }

        return (
          <>
            <h5
              css={{
                margin: 0,
                fontWeight: 600,
                fontSize: 16,
                color: palette.darkGray,
              }}
            >
              Groups
            </h5>

            <div
              css={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: 10,
                rowGap: 10,
              }}
            >
              {groups.map((group: Pick<IGroup, "_id" | "slug" | "name" | "logo">) => {
                return (
                  <GroupsFieldItem
                    key={group._id}
                    group={group}
                    onRemove={(group: Pick<IGroup, "_id" | "slug" | "name" | "logo">) => {
                      input.onChange(
                        input.value.filter((current: IGroup) => {
                          return current._id !== group._id;
                        }),
                      );
                    }}
                  />
                );
              })}
            </div>
          </>
        );
      }}
    </Field>
  );
}
