export default function getGovlaunchBaseUrl(app = "portal") {
  const environment = process.env.GOVLAUNCH_ENV || process.env.NODE_ENV;

  if (["site", "main", "govlaunch_frontend", "frontend", "portal"].includes(app)) {
    if (environment === "development") {
      return `http://localhost:3000`;
    } else if (environment === "production") {
      return `https://govlaunch.com`;
    } else if (environment === "stage") {
      return `https://stage.govlaunch.com`;
    } else if (environment === "qa") {
      return `https://qa.govlaunch.com`;
    }
  } else if (["disruptors-frontend", "disruptors"].includes(app)) {
    if (environment === "development") {
      return `http://localhost:3001`;
    } else if (environment === "production") {
      return `https://disruptors.govlaunch.com`;
    }
  } else if (["innovators-frontend", "innovators"].includes(app)) {
    if (environment === "development") {
      return `http://localhost:3002`;
    } else if (environment === "production") {
      return `https://innovators.govlaunch.com`;
    } else if (environment === "stage") {
      return `https://innovators.stage.govlaunch.com`;
    } else if (environment === "qa") {
      return `https://innovators.qa.govlaunch.com`;
    }
  } else if (app === "api") {
    if (environment === "development") {
      return `http://localhost:4000`;
    } else if (environment === "production") {
      return `https://api.production.govlaunch.com`;
    } else if (environment === "stage") {
      return `https://api.stage.govlaunch.com`;
    }
  }

  throw new Error("Invalid app value");
}
