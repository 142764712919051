import React from "react";
import { useNavigate } from "react-router";
import * as palette from "@govlaunch/palette";
import { useMutation } from "react-apollo";
import {
  IAddCollectionMutation,
  IAddCollectionMutationVariables,
} from "./graphql/mutations/__generated__/AddCollectionMutation.generated";
import AddCollectionMutation from "./graphql/mutations/AddCollectionMutation";
import CollectionForm from "./CollectionForm";
import { ICollectionSponsoring } from "../../../types/types";
import notUndefinedOrNull from "../../utils/notUndefinedOrNull";
import { notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

export default function NewCollectionForm() {
  const navigate = useNavigate();

  const [addCollection, { error }] = useMutation<IAddCollectionMutation, IAddCollectionMutationVariables>(
    AddCollectionMutation,
    {
      refetchQueries: ["ListAllCollections"],
    },
  );

  return (
    <div
      css={{
        padding: 15,
      }}
    >
      <div
        css={{
          paddingBottom: 8,
        }}
      >
        <a
          css={{
            color: palette.primary,
            cursor: "pointer",
          }}
          onClick={() => navigate("/collections")}
        >
          <span css={{
 marginRight: 5 
}}
          >
            <FontAwesomeIcon icon={faLessThan} size="sm" />
          </span>
          All Collections
        </a>
      </div>

      <CollectionForm
        error={error?.message.replace("GraphQL error: ", "")}
        onSubmit={async (collection) => {
          const createdCollection = await addCollection({
            variables: {
              input: {
                name: collection.name,
                description: collection.description || "",
                tags: collection.tags,
                sponsoring: collection.sponsoring || ICollectionSponsoring.None,
                sponsoringVendors: [collection.sponsoringVendor].filter(notUndefinedOrNull),
                sponsoringProducts: [collection.sponsoringProduct].filter(notUndefinedOrNull),
                isFeatured: collection.isFeatured,
                displayOptions: {
                  cardBackgroundColorHex: collection.cardBackground,
                },
              },
            },
          });

          if (createdCollection && createdCollection.data && createdCollection.data.addCollection.slug) {
            notification.success({
              message: "Success!",
              description: "Collection saved successfully",
            });

            navigate(`/collections/${createdCollection.data.addCollection.slug}`);
          }
        }}
      />
    </div>
  );
}
