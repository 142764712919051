import gql from "graphql-tag";

export default gql`
  mutation SetPodcastPageConfig($config: PodcastPageConfigInput!) {
    setPodcastPageConfig(config: $config) {
      headerText
      headerTextColor
      backgroundColor
      description
      descriptionColor
      podcastSrc
      podcastHeight

      twitter
      facebook
      instagram
      youtube
      apple
      spotify
      googleplay
      soundcloud
      deezer
      iheartradio
      overcast
      stitcher
      pandora
      amazonmusic
    }
  }
`;
