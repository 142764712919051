/* eslint-disable react/display-name */
import * as palette from "@govlaunch/palette";
import { Mutation, Query } from "react-apollo";
import { Popover, Table, Tabs, Tag, Tooltip, Alert } from "antd";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { Toggle } from "react-powerplug";
import moment from "moment";
import AllGroupRequests from "../../queries/AllGroupRequests";
import ProfilePicture from "@govlaunch/profile-picture";
import AcceptGroupRequest from "../../mutations/AcceptGroupRequest";
import DeclineGroupRequest from "../../mutations/DeclineGroupRequest";
import TabContent from "../../components/TabContent";
import queryString from "query-string";
import {
  IAllGroupRequestsQuery,
  IAllGroupRequestsQueryVariables,
} from "../../queries/__generated__/AllGroupRequests.generated";
import { Margin } from "../../spacings";
import {
  IDeclineGroupRequestMutation,
  IDeclineGroupRequestMutationVariables,
} from "../../mutations/__generated__/DeclineGroupRequest.generated";
import {
  IAcceptGroupRequestMutation,
  IAcceptGroupRequestMutationVariables,
} from "../../mutations/__generated__/AcceptGroupRequest.generated";
import DotdotdotIcon from "../../components/icons/DotdotdotIcon";
import { useLocation, useNavigate } from "react-router";
import PageIcon from "../../icons/PageIcon";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function GroupRequests() {
  const location = useLocation();
  const navigate = useNavigate();
  const query = queryString.parse(location.search);
  const tab = query.tab || "pending";

  return (
    <DocumentTitle title="Admin | Group Requests">
      <div>
        <div
          css={{
            padding: 15,
          }}
        >
          <h3
            css={{
              fontWeight: 700,
              fontSize: 24,
              margin: 0,
            }}
          >
            <PageIcon icon={faEnvelope} /> Group Requests
          </h3>
        </div>

        <Query<IAllGroupRequestsQuery, IAllGroupRequestsQueryVariables>
          fetchPolicy="network-only"
          query={AllGroupRequests}
        >
          {({ data, loading, refetch }) => {
            if (loading || !data || !data.allGroupRequests) {
              return null;
            }

            const pendingRequests = data.allGroupRequests.filter(({ status }) => status === "PENDING");
            const acceptedRequests = data.allGroupRequests.filter(({ status }) => status === "ACCEPTED");
            const declinedRequests = data.allGroupRequests.filter(({ status }) => status === "DECLINED");
            const canceledRequests = data.allGroupRequests.filter(({ status }) => status === "CANCELED");

            return (
              <Tabs
                animated={false}
                onChange={(tab) => {
                  navigate(`/groups/invitations?tab=${tab}`);
                  refetch();
                }}
                activeKey={tab as string}
                size="middle"
                tabBarStyle={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  margin: 0,
                }}
              >
                <Tabs.TabPane
                  tab={pendingRequests.length ? `Pending (${pendingRequests.length})` : "Pending"}
                  key="pending"
                >
                  {tab === "pending" && (
                    <TabContent>
                      <GroupRequestsTable loading={loading} requests={pendingRequests} />
                    </TabContent>
                  )}
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={acceptedRequests.length ? `Accepted (${acceptedRequests.length})` : "Accepted"}
                  key="accepted"
                >
                  {tab === "accepted" && (
                    <TabContent>
                      <GroupRequestsTable loading={loading} requests={acceptedRequests} />
                    </TabContent>
                  )}
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={declinedRequests.length ? `Declined (${declinedRequests.length})` : "Declined"}
                  key="declined"
                >
                  {tab === "declined" && (
                    <TabContent>
                      <GroupRequestsTable loading={loading} requests={declinedRequests} />
                    </TabContent>
                  )}
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={canceledRequests.length ? `Cancelled (${canceledRequests.length})` : "Cancelled"}
                  key="cancelled"
                >
                  {tab === "cancelled" && (
                    <TabContent>
                      <div>
                        <Alert
                          message="Reminder"
                          description={
                            <div>
                              <p>
                                Below is one of the reasons for a Group Invitation to be <strong>Cancelled</strong>:
                              </p>

                              <ul>
                                <li>The Group was removed</li>
                                <li>The Government was unassigned from the Group</li>
                                <li>A site Administrator has added the Government to the Group</li>
                              </ul>
                            </div>
                          }
                          type="info"
                          showIcon={true}
                        />

                        <Margin mt={24}>
                          <GroupRequestsTable loading={loading} requests={canceledRequests} />
                        </Margin>
                      </div>
                    </TabContent>
                  )}
                </Tabs.TabPane>
              </Tabs>
            );
          }}
        </Query>
      </div>
    </DocumentTitle>
  );
}

interface IGroupRequestsTableProps {
  requests: IAllGroupRequestsQuery["allGroupRequests"];
  loading: boolean;
}

function GroupRequestsTable({ requests, loading }: IGroupRequestsTableProps) {
  return (
    <Table
      bordered={true}
      rowKey="_id"
      loading={loading}
      dataSource={requests}
      scroll={{
        x: true,
      }}
      columns={[
        {
          title: "Group",
          dataIndex: "group",
          sorter: (a, b) => a.group.name.localeCompare(b.group.name),
          render: (group) => {
            if (group.deletedAt !== null) {
              const deletedAt = moment(group.deletedAt);

              return (
                <Tooltip title={`Group was archived on ${deletedAt.format("MM/DD/YYYY")}`}>
                  <span>{`${group.name} ❌`}</span>
                </Tooltip>
              );
            }
            return <div>{group.name}</div>;
          },
        },
        {
          title: "Government",
          dataIndex: "government",
          sorter: (a, b) => {
            if (
              a.__typename === "GovernmentGroupRequest" &&
              a.government &&
              b.__typename === "GovernmentGroupRequest" &&
              b.government
            ) {
              return a.government.name.localeCompare(b.government.name);
            }

            return 0;
          },
          render: (government) => (government ? government.name : "N/A"),
        },
        {
          title: "Vendor",
          dataIndex: "vendor",
          sorter: (a, b) =>
            a.__typename === "VendorGroupRequest" && b.__typename === "VendorGroupRequest"
              ? a.vendor.name.localeCompare(b.vendor.name)
              : 0,
          render: (vendor) => (vendor ? vendor.name : "N/A"),
        },
        {
          title: "Inviter",
          dataIndex: "inviter",
          filterMultiple: false,
          render: (inviter) => {
            return (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ProfilePicture
                  size={32}
                  image={inviter.avatar}
                  innovator={inviter.isInnovator}
                  name={inviter.fullName}
                  css={{
                    whiteSpace: "nowrap",
                  }}
                />

                <Margin ml={5}>
                  <Link to={`/users/${inviter.slug}`}>{inviter.fullName}</Link>
                </Margin>
              </div>
            );
          },
        },
        {
          title: "Status",
          key: "status",
          render: (_, item) => {
            if (item.status === "PENDING") {
              return <Tag color="orange">Pending</Tag>;
            }

            if (item.status === "ACCEPTED") {
              return <Tag color="green">Verified</Tag>;
            }

            if (item.status === "DECLINED") {
              return <Tag color="magenta">Declined</Tag>;
            }

            if (item.status === "CANCELED") {
              return <Tag color="red">Cancelled</Tag>;
            }

            return item.status;
          },
        },

        {
          title: "Responded By",
          key: "respondedBy",
          render: (_, groupRequest) => {
            if (!groupRequest.respondedBy) {
              return null;
            }

            return (
              <div
                css={{
                  display: "grid",
                  gridColumnGap: 8,
                  gridTemplateColumns: "repeat(2, max-content)",
                  alignItems: "center",
                }}
              >
                <ProfilePicture
                  size={24}
                  image={groupRequest.respondedBy.avatar || ""}
                  name={groupRequest.respondedBy.fullName}
                />

                <span>{groupRequest.respondedBy.fullName}</span>
              </div>
            );
          },
        },
        {
          title: "Created",
          key: "created",
          sorter: true,
          defaultSortOrder: "descend",
          render: (_, item) => {
            const createdAt = moment(item.createdAt);

            if (moment().diff(createdAt, "days") <= 30) {
              return (
                <Tooltip title={createdAt.format("MM/DD/YYYY")}>
                  <span>{createdAt.fromNow()}</span>
                </Tooltip>
              );
            }

            return createdAt.format("MM/DD/YYYY");
          },
        },
        {
          title: "Actions",
          render: (_, groupRequest) => {
            if (groupRequest.status === "PENDING" && groupRequest.group.deletedAt === null) {
              return <GroupRequestActions groupRequest={groupRequest} />;
            }

            return "N/A";
          },
        },
      ]}
      pagination={false}
    />
  );
}

function GroupRequestActions({ groupRequest }: any) {
  return (
    <Toggle>
      {({ on, toggle }) => (
        <Popover
          visible={on}
          trigger="click"
          title="Group Request Actions"
          placement="rightBottom"
          onVisibleChange={toggle}
          content={
            <ul
              css={{
                margin: 0,
                padding: 0,
                listStyle: "none",
                "> li:not(:first-of-type)": {
                  marginTop: 10,
                },
              }}
            >
              <Mutation<IAcceptGroupRequestMutation, IAcceptGroupRequestMutationVariables>
                mutation={AcceptGroupRequest}
                variables={{
                  requestId: groupRequest._id,
                }}
                update={(proxy) => {
                  const query = proxy.readQuery<IAllGroupRequestsQuery>({
                    query: AllGroupRequests,
                  });

                  if (!query) {
                    return;
                  }

                  proxy.writeQuery({
                    query: AllGroupRequests,
                    data: {
                      allGroupRequests: query.allGroupRequests.map((maybe) => {
                        if (maybe._id === groupRequest._id) {
                          return {
                            ...groupRequest,
                            status: "ACCEPTED",
                          };
                        }

                        return maybe;
                      }),
                    },
                  });
                }}
              >
                {(accept) => (
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        accept();
                      }}
                    >
                      Accept
                    </a>
                  </li>
                )}
              </Mutation>

              <Mutation<IDeclineGroupRequestMutation, IDeclineGroupRequestMutationVariables>
                mutation={DeclineGroupRequest}
                variables={{
                  requestId: groupRequest._id,
                }}
                update={(proxy) => {
                  const query = proxy.readQuery<IAllGroupRequestsQuery>({
                    query: AllGroupRequests,
                  });

                  if (!query) {
                    return;
                  }

                  proxy.writeQuery({
                    query: AllGroupRequests,
                    data: {
                      allGroupRequests: query.allGroupRequests.map((maybe) => {
                        if (maybe._id === groupRequest._id) {
                          return {
                            ...groupRequest,
                            status: "DECLINED",
                          };
                        }

                        return maybe;
                      }),
                    },
                  });
                }}
              >
                {(decline) => (
                  <li>
                    <a
                      css={{
                        color: palette.red,
                      }}
                      href="#"
                      onClick={() => {
                        decline();
                      }}
                    >
                      Decline
                    </a>
                  </li>
                )}
              </Mutation>
            </ul>
          }
        >
          <span
            role="button"
            css={{
              cursor: "pointer",
            }}
          >
            <DotdotdotIcon />
          </span>
        </Popover>
      )}
    </Toggle>
  );
}

GroupRequestActions.propTypes = {
  groupRequest: PropTypes.object.isRequired,
};
