import React from "react";
import { Form, Field, FormSpy } from "react-final-form";
import Section from "../form/Section";
import SectionHeader from "../form/SectionHeader";
import SectionBody from "../form/SectionBody";
import TextField from "../form/fields/TextField";
import { RowWrap, Col } from "@govlaunch/core";
import SocialMediaFields from "../form/fields/SocialMediaFields";
import LogoField from "../form/fields/LogoField";
import NewsworthyField from "../form/fields/NewsworthyField";
import ResourcesField from "../form/fields/ResourcesField";
import ProductsField from "../form/fields/ProductsField";
import * as Yup from "yup";
import VisibilityField from "./fields/VisibilityField";
import validate from "../../validate";
import VendorInput from "./VendorInput";
import TagsSelectContainer from "../tags/TagsSelect";
import { isValidUrlYup } from "../../utils/isValidUrl";
import { ThemeProvider, theme, CSSReset, TagsField } from "@govlaunch/web";
import KindField from "./fields/KindField";
import { ITagType } from "../../../types/types";
import FormGrid from "../form/FormGrid";
import FormSubmitSection from "../form/FormSubmitSection";
import TextareaField from "../form/fields/TextareaField";
import SectionSplitter from "../form/SectionSplitter";
import UrlField from "../form/fields/UrlField";

interface IGroupFormProps {
  onSubmit: (values: any) => void;
  initialValues: { [key: string]: any };
  error?: string | null;
  submitButtonText: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Can't be blank"),
  location: Yup.string().required("Can't be blank"),
  about: Yup.string()
    .min(20, "Minimum of 20 characters")
    .max(800, "Maximum of 800 characters")
    .required("Can't be blank"),
  website: Yup.string().test("url", "invalid url", isValidUrlYup).required("Can't be blank"),
  socialMedia: Yup.object().shape({
    twitter: Yup.string().url("Invalid URL").nullable(),
    youtube: Yup.string().url("Invalid URL").nullable(),
    facebook: Yup.string().url("Invalid URL").nullable(),
    linkedin: Yup.string().url("Invalid URL").nullable(),
    instagram: Yup.string().url("Invalid URL").nullable(),
  }),
});

function getErrorMessage(error: string | null | undefined): string | null {
  if (!error) {
    return null;
  }

  if (/Vendor plan doesn't support groups/i.test(error)) {
    return "Vendor plan doesn't support groups";
  }

  if (/Vendor plan doesn't support more groups/i.test(error)) {
    return "Vendor plan doesn't support more groups";
  }

  return error.replace("GraphQL error: ", "");
}

export default function GroupForm({ onSubmit, initialValues, error, submitButtonText }: IGroupFormProps) {
  const errorMessage = getErrorMessage(error);

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />

      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate(validationSchema)}
        keepDirtyOnReinitialize={true}
        subscription={{
          submitting: true,
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <FormGrid>
              <FormSubmitSection
                backButtonText="All Groups"
                backButtonLocation="/groups"
                discardChangesLocation="/groups"
                submitButtonText={submitButtonText}
                submitting={submitting}
                submitError={errorMessage || ""}
              />
              <RowWrap>
                <Col width={[1, 1, 1, 8 / 12]}>
                  <SectionSplitter>
                    <Section>
                      <SectionHeader>The Basics</SectionHeader>
                      <SectionBody>
                        <TextField fieldName="name" placeholder="What's the group name?..." label="Name" />
                        <UrlField fieldName="website" placeholder="Paste Website URL?..." label="Website" />
                        <TextField fieldName="location" placeholder="What's the group location?" label="Location" />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>About the Group</SectionHeader>
                      <SectionBody>
                        <TextareaField fieldName="about" placeholder="Tell a bit more about the group" />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Newsworthy</SectionHeader>
                      <SectionBody>
                        <NewsworthyField fieldName="newsworthy" />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Products</SectionHeader>
                      <SectionBody>
                        <ProductsField />
                      </SectionBody>
                    </Section>
                  </SectionSplitter>
                </Col>
                <Col width={[1, 1, 1, 4 / 12]}>
                  <SectionSplitter>
                    <Section>
                      <SectionHeader>Logo</SectionHeader>
                      <SectionBody>
                        <FormSpy
                          subscription={{
                            values: true,
                          }}
                        >
                          {({ values }) => <LogoField name={values.name || "A B"} />}
                        </FormSpy>
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Tags</SectionHeader>

                      <SectionBody>
                        <TagsField
                          fieldName="tags"
                          type={[ITagType.Product, ITagType.Project, ITagType.Story, ITagType.Custom]}
                        />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Vendor Owner</SectionHeader>
                      <SectionBody>
                        <Field name="vendor">
                          {({ input, meta }) => <VendorInput vendor={input.value} meta={meta} {...input} />}
                        </Field>
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Social Media</SectionHeader>
                      <SectionBody>
                        <SocialMediaFields />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Resources</SectionHeader>

                      <div>
                        <ResourcesField />
                      </div>
                    </Section>

                    <Section>
                      <SectionHeader>🏅 Awards</SectionHeader>

                      <SectionBody>
                        <Field name="awards">{({ input }) => <TagsSelectContainer {...input} type="AWARD" />}</Field>
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Visibility</SectionHeader>
                      <SectionBody>
                        <VisibilityField />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Type / Purpose</SectionHeader>
                      <SectionBody>
                        <KindField />
                      </SectionBody>
                    </Section>
                  </SectionSplitter>
                </Col>
              </RowWrap>
            </FormGrid>
          </form>
        )}
      </Form>
    </ThemeProvider>
  );
}
