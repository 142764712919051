import * as palette from "@govlaunch/palette";
import React from "react";
import { Mutation } from "react-apollo";
import DocumentTitle from "react-document-title";
import CreateGroup from "../../mutations/CreateGroup";
import {
  ICreateGroupMutation,
  ICreateGroupMutationVariables,
} from "../../mutations/__generated__/CreateGroup.generated";
import GetGroupsCount from "../../queries/GetGroupsCount";
import GroupForm from "./GroupForm";
import { convertValuesToVariables } from "./utils";
import GroupsQuery from "../../queries/GroupsQuery";
import { useNavigate } from "react-router";

export default function NewGroup() {
  const navigate = useNavigate();

  return (
    <DocumentTitle title="New Group | Admin">
      <div
        css={{
          background: palette.washGray,
          height: "100%",
        }}
      >
        <Mutation<ICreateGroupMutation, ICreateGroupMutationVariables>
          refetchQueries={[
            {
              query: GroupsQuery,
            },
            {
              query: GetGroupsCount,
            },
          ]}
          mutation={CreateGroup}
        >
          {(createGroup, { error }) => (
            <GroupForm
              onSubmit={async (values) => {
                await createGroup({
                  variables: {
                    group: convertValuesToVariables(values),
                  },
                }).then((mutationResult) => {
                  if (mutationResult && mutationResult.data && mutationResult.data.createGroup) {
                    navigate(`/groups/${mutationResult.data.createGroup.slug}`);
                  }
                });

                return null;
              }}
              initialValues={GROUP_FORM_INITIAL_STATE}
              submitButtonText="Create Group"
              error={error ? error.message : null}
            />
          )}
        </Mutation>
      </div>
    </DocumentTitle>
  );
}

const GROUP_FORM_INITIAL_STATE = {
  products: [],
  resources: [],
  newsworthy: [],
  visibility: "PRIVATE",
  awards: [],
  kind: "COMMUNITY",
};
