import * as Yup from "yup";
import { ensureFilledString } from "../../../ensureFills";
import { isValidUrlYup } from "../../../utils/isValidUrl";

export default Yup.object({
  title: Yup.string().transform(ensureFilledString).required("can't be blank"),
  url: Yup.string().transform(ensureFilledString).required("can't be blank").test("url", "invalid url", isValidUrlYup),
  location: Yup.string().required("can't be blank"),
  startDate: Yup.date().typeError("can't be blank").required("can't be blank"),
});
