import { Box, Flex } from "@govlaunch/web";
import React from "react";
import CollectionIcon from "../../../../components/icons/CollectionIcon";

type TCollectionTag = {
  _id: any;
  name: string;
  contentCounters: {
    productsCount: number;
    projectsCount: number;
    storiesCount: number;
  };
};

interface ICollectionsAsTagsProps {
  collections: TCollectionTag[];
}

export default function CollectionsAsTags({ collections }: ICollectionsAsTagsProps) {
  return (
    <Flex flexDirection="row" flexWrap="wrap">
      {collections.map((collection) => (
        <div key={collection._id}>
          <CollectionAsTag collection={collection} />
        </div>
      ))}
    </Flex>
  );
}

interface ICollectionAsTagProps {
  collection: TCollectionTag;
}

function CollectionAsTag({ collection }: ICollectionAsTagProps) {
  const itemCount =
    collection.contentCounters.productsCount +
    collection.contentCounters.projectsCount +
    collection.contentCounters.storiesCount;

  return (
    <Flex
      border="1px solid #ADB6C3"
      borderRadius="2px"
      flexDirection="row"
      alignItems="center"
      justifyContent="left"
      px="8px"
      py="4px"
      mb="8px"
      mr="8px"
      cursor="pointer"
      fontFamily="'proxima-nova', Helvetica, Arial, sans-serif"
    >
      <CollectionIcon fillColor="#2f3844" width="18px" height="18px" />
      <Box ml="12px" fontWeight={400} fontSize="16px" color="#20252d">
        {collection.name}
      </Box>
      <Box fontSize="14px" fontWeight={400} color="gray.300" ml="16px">
        {itemCount > 1 ? `${itemCount} items` : `${itemCount} item`}
      </Box>
    </Flex>
  );
}
