import React from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import TableSearch from "../../../components/table/TableSearch";
import ManageDuplicatedUsers from "./ManageDuplicatedUsers";

export default function DuplicatedUsersSearch() {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div
        css={{
          marginBottom: 20,
        }}
      >
        <TableSearch historyOrigin="/duplicatedUsers" query={query} inputPlaceholder="Search for users..." />
      </div>
      <ManageDuplicatedUsers search={query.search as string} />
    </>
  );
}
