import React, { ReactNode } from "react";
import * as palette from "@govlaunch/palette";

interface IPlanFormSectionProps {
  title?: string;
  subtitle?: string;
  children: ReactNode;
}

export default function PlanFormSection({ title, subtitle, children, ...props }: IPlanFormSectionProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
      }}
      {...props}
    >
      {title && (
        <div
          css={{
            fontWeight: 600,
            fontSize: 16,
            color: palette.mediumGray,
            borderBottom: `1px solid ${palette.lightestGray}`,
            paddingBottom: 8,
            marginBottom: 16,
          }}
        >
          <div>{title}</div>
          {subtitle && (
            <div
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: palette.mediumGray,
              }}
            >
              {subtitle}
            </div>
          )}
        </div>
      )}

      <div>{children}</div>
    </div>
  );
}
