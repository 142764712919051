import gql from "graphql-tag";

export default gql`
  mutation AddTag($name: String!, $types: [TagType!]!, $relatedTags: [ObjectId!]) {
    addTag(name: $name, types: $types, relatedTags: $relatedTags) {
      _id
      name
      types
      relatedTags {
        _id
        name
      }
    }
  }
`;
