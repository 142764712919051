import { faScroll } from "@fortawesome/free-solid-svg-icons";
import { Tabs } from "antd";
import queryString from "query-string";
import React from "react";
import DocumentTitle from "react-document-title";
import { useLocation, useNavigate } from "react-router";
import { MetaBaseQuestion } from "../../components/MetaBaseLink";
import TablePageHeader from "../../components/table/TablePageHeader";
import StoriesCount from "./StoriesCount";
import StoriesWithSearch from "./tables/StoriesWithSearchWithRouter";

export default function Stories() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = query.tab || "all";

  return (
    <DocumentTitle title="Stories | Admin">
      <>
        <TablePageHeader
          title="Stories"
          titleIcon={faScroll}
          subTitle="All Stories"
          metabaseQuestion={MetaBaseQuestion.Stories}
        />
        <StoriesCount>
          {({ allStories, archivedStories, refetch }) => (
            <Tabs
              activeKey={tab.toString()}
              animated={false}
              size="middle"
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
              onChange={(tab) => {
                navigate(`?tab=${tab}`);
                refetch();
              }}
            >
              <Tabs.TabPane tab={allStories ? `All (${allStories})` : "All"} key="all">
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  <StoriesWithSearch />
                </div>
              </Tabs.TabPane>

              <Tabs.TabPane tab={archivedStories ? `Archived (${archivedStories})` : "Archived"} key="archived">
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  <StoriesWithSearch tab="ARCHIVED" />
                </div>
              </Tabs.TabPane>
            </Tabs>
          )}
        </StoriesCount>
      </>
    </DocumentTitle>
  );
}
