import gql from "graphql-tag";

export default gql`
  query GetAllSponsorships(
    $search: String
    $multiSearch: [String]
    $filter: AllSponsorshipsFilter
    $sortBy: String
    $limit: Int = 10
    $cursor: Cursor
  ) {
    allSponsorships(
      search: $search
      multiSearch: $multiSearch
      filter: $filter
      sortBy: $sortBy
      limit: $limit
      cursor: $cursor
    ) {
      items {
        _id
        name
        slug
        logo
        link
        archivedAt
        vendorsCount
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor
      }
    }
  }
`;
