import React, { useCallback, useState } from "react";
import debounce from "lodash/debounce";
import GetAllSponsorships from "../../queries/GetAllSponsorships";
import {
  IGetAllSponsorshipsQuery,
  IGetAllSponsorshipsQueryVariables,
} from "../../queries/__generated__/GetAllSponsorships.generated";
import { IAllSponsorshipsFilter } from "../../../types/types";
import { Select } from "antd";
import { Query } from "react-apollo";

interface ISponsorshipsFieldSelectProps {
  sponsorships: any[];
  onChange: (event: React.ChangeEvent<HTMLElement> | any) => void;
  onFocus: (event?: React.FocusEvent<HTMLElement>) => void;
  onBlur: (event?: React.FocusEvent<HTMLElement>) => void;
}

export default function SponsorshipsFieldSelect({
  sponsorships,
  onChange,
  onFocus,
  onBlur,
}: ISponsorshipsFieldSelectProps) {
  const [searchValue, setSearchValue] = useState("");
  const performSearch = useCallback(
    debounce((searchQuery: string) => {
      setSearchValue(searchQuery);
    }, 200),
    [],
  );

  return (
    <Query<IGetAllSponsorshipsQuery, IGetAllSponsorshipsQueryVariables>
      query={GetAllSponsorships}
      variables={{
        search: searchValue,
        multiSearch: sponsorships || [],
        filter: IAllSponsorshipsFilter.All,
        sortBy: null,
        limit: 20,
        cursor: null,
      }}
      fetchPolicy="network-only"
      notifyOnNetworkStatusChange={true}
    >
      {({ data, loading, refetch }) => {
        return (
          <Select
            mode="multiple"
            placeholder="Select sponsorship..."
            size="large"
            css={{
              fontSize: 14,
            }}
            // TODO: when selecting a New Vendor, the initial field value is empty string ""
            // and the Select component in multiple mode requires an array
            // find out how to remove ([] as any)
            value={Array.isArray(sponsorships) ? sponsorships : ([] as any)}
            onSearch={(value) => {
              performSearch(value);
            }}
            onChange={(value: any) => {
              performSearch("");
              onChange(value);
            }}
            onFocus={onFocus}
            onBlur={() => {
              performSearch("");
              onBlur();
            }}
            onDropdownVisibleChange={() => refetch()}
            defaultActiveFirstOption={false}
            showArrow={true}
            showSearch={true}
            filterOption={false}
            allowClear={false}
          >
            {!loading &&
              data &&
              data.allSponsorships &&
              data.allSponsorships.items &&
              data.allSponsorships.items.filter(Boolean).map((sponsor) => {
                return (
                  <Select.Option
                    key={sponsor._id}
                    value={sponsor._id}
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {sponsor.name}
                  </Select.Option>
                );
              })}
          </Select>
        );
      }}
    </Query>
  );
}
