import { AutoComplete, Input } from "antd";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import { Query } from "react-apollo";
import AutoCompleteProjects from "./queries/AutoCompleteProjects";
import {
  IAutoCompleteProjectsQuery,
  IAutoCompleteProjectsQueryVariables,
} from "./queries/__generated__/AutoCompleteProjects.generated";

export type TSelectedProject = NonNullable<IAutoCompleteProjectsQuery["autoCompleteProjects"]>[0];

interface IProjectAutoCompleteProps {
  onSelect: (selectedItem: TSelectedProject) => void;
}

export default function ProjectAutoComplete({ onSelect }: IProjectAutoCompleteProps) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");

  const onSearch = debounce((searchText) => setSearchQuery(searchText), 300);

  return (
    <Query<IAutoCompleteProjectsQuery, IAutoCompleteProjectsQueryVariables>
      query={AutoCompleteProjects}
      notifyOnNetworkStatusChange={true}
      fetchPolicy="network-only"
      skip={searchQuery === ""}
      variables={{
        searchQuery,
        limit: 10,
      }}
    >
      {({ loading, data }) => {
        const searchResultsArray = !data || !data.autoCompleteProjects ? [] : data.autoCompleteProjects;

        const options = searchResultsArray.map((item) => {
          return {
            value: item._id,
            label: (
              <div>
                <strong>{item.title}</strong>
              </div>
            ),
          };
        });

        return (
          <AutoComplete
            options={searchQuery === "" ? [] : options}
            onSearch={onSearch}
            onSelect={(selectedItem: any) => {
              const match = searchResultsArray.find((candidate) => {
                return candidate._id === selectedItem;
              });

              if (match) {
                onSelect(match);
                setSearchQuery("");
                setInputValue("");
              }
            }}
            value={inputValue}
            bordered={false}
          >
            <Input.Search
              size="large"
              spellCheck={false}
              loading={loading}
              placeholder="Type to search projects..."
              onChange={(e) => setInputValue(e.target.value)}
            />
          </AutoComplete>
        );
      }}
    </Query>
  );
}
