import React from "react";
import CollectionRow, { TCollectionRow } from "./CollectionRow";

interface ICollectionsAsRowsProps {
  collections: TCollectionRow[];
}

export default function CollectionsAsRows({ collections }: ICollectionsAsRowsProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      {collections.map((collection) => (
        <div
          key={collection._id}
          css={{
            "&:not(:first-of-type)": {
              marginTop: 20,
            },
          }}
        >
          <CollectionRow collection={collection} />
        </div>
      ))}
    </div>
  );
}
