import gql from "graphql-tag";
import VendorFields from "../queries/fragments/VendorFields";

export default gql`
  mutation ToggleDisruptorMutation($entityId: ObjectId!, $entityType: String!) {
    toggleDisruptor(entityId: $entityId, entityType: $entityType) {
      ... on Company {
        _id
        ...VendorFields
      }
      ... on VendorUser {
        _id
        isDisruptor
      }
      ... on Product {
        _id
        isDisruptor
        company {
          _id
          ...VendorFields
        }
      }
    }
  }

  ${VendorFields}
`;
