import gql from "graphql-tag";

export default gql`
  query VendorCredits {
    allVendorCredits {
      vendor {
        _id
        slug
        name
        logo
      }
      government {
        _id
        slug
        name
        logo
        isInnovator
        city {
          _id
          name
          state
          country
          stateName: state(full: true)
          type
          county
        }
        activatedAt
      }
      amount
      createdAt
    }
  }
`;
