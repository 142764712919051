import gql from "graphql-tag";

export default gql`
  query GetDepartmentRelatedTags($departmentId: ObjectId!) {
    getDepartmentRelatedTags(departmentId: $departmentId) {
      _id
      name
    }
  }
`;
