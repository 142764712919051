import React from "react";
import queryString from "query-string";
import DocumentTitle from "react-document-title";
import { Tabs } from "antd";
import SponsorshipsCount from "./SponsorshipsCount";
import SponsorshipsWithSearchWithRouter from "./SponsorshipsWithSearchWithRouter";
import { IAllSponsorshipsFilter } from "../../../types/types";
import { useLocation, useNavigate } from "react-router";
import PageIcon from "../../icons/PageIcon";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";

export default function AllSponsorships() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = query.tab || "all";

  return (
    <DocumentTitle title="Sponsorships | Admin">
      <>
        <div
          css={{
            padding: 20,
            borderBottom: "1px solid rgb(232, 232, 232)",
          }}
        >
          <h3
            css={{
              fontWeight: 700,
              fontSize: 24,
              margin: 0,
            }}
          >
            <PageIcon icon={faHandshake} /> Sponsorships
          </h3>

          <p
            css={{
              margin: 0,
            }}
          >
            All Sponsorships
          </p>
        </div>

        <SponsorshipsCount>
          {({ allCount, archivedCount, refetch }: { allCount: number; archivedCount: number; refetch: any }) => (
            <Tabs
              animated={false}
              onChange={(tab) => {
                navigate(`/sponsorships?tab=${tab}`);
                refetch();
              }}
              activeKey={tab as string}
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
            >
              <Tabs.TabPane tab={allCount ? `All (${allCount})` : "All"} key="all">
                {tab === "all" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <SponsorshipsWithSearchWithRouter />
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab={archivedCount ? `Archived (${archivedCount})` : "Archived"} key="archived">
                {tab === "archived" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <SponsorshipsWithSearchWithRouter filter={IAllSponsorshipsFilter.Archived} />
                  </div>
                )}
              </Tabs.TabPane>
            </Tabs>
          )}
        </SponsorshipsCount>
      </>
    </DocumentTitle>
  );
}
