import React from "react";
import { Flex, Box, Grid } from "@govlaunch/web";
import { Typography, Form, Input } from "antd";
import { FormInstance } from "antd/lib/form";
import prefixWithHttpIfNecessary from "../../utils/prefixWithHttpIfNecessary";

interface IPodcastPageLinksProps {
  form: FormInstance;
}

export default function PodcastPageLinks({ form }: IPodcastPageLinksProps) {
  return (
    <Flex flexDirection="column" alignItems="left">
      <Typography.Title level={3}>Links</Typography.Title>

      <Grid gridTemplateColumns="100px minmax(240px, 480px)" alignItems="center" gridColumnGap={2} gridRowGap={2}>
        {LINKS.map((link) => (
          <LinkInput key={link.key} label={link.label} fieldName={link.key} form={form} />
        ))}
      </Grid>
    </Flex>
  );
}

interface ILinkInputProps {
  label: string;
  fieldName: string;
  form: FormInstance;
}

function LinkInput({ label, fieldName, form }: ILinkInputProps) {
  return (
    <>
      <Typography.Text>{label}</Typography.Text>
      <Box>
        <Form.Item
          noStyle={true}
          name={fieldName}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input
            placeholder="What's the URL?"
            onChange={(event) => {
              let url = event.target.value.trim();
              url = prefixWithHttpIfNecessary(url, true) || "";
              form.setFieldsValue({
                [fieldName]: url,
              });
            }}
          />
        </Form.Item>
      </Box>
    </>
  );
}

const LINKS = [
  {
    label: "Twitter",
    key: "twitter",
  },
  {
    label: "Facebook",
    key: "facebook",
  },
  {
    label: "Instagram",
    key: "instagram",
  },
  {
    label: "Youtube",
    key: "youtube",
  },
  {
    label: "Apple Podcast",
    key: "apple",
  },
  {
    label: "Spotify",
    key: "spotify",
  },
  {
    label: "Google Play",
    key: "googleplay",
  },
  {
    label: "SoundCloud",
    key: "soundcloud",
  },
  {
    label: "Deezer",
    key: "deezer",
  },
  {
    label: "iHeartRadio",
    key: "iheartradio",
  },
  {
    label: "Overcast",
    key: "overcast",
  },
  {
    label: "Stitcher",
    key: "stitcher",
  },
  {
    label: "Pandora",
    key: "pandora",
  },
  {
    label: "Amazon Music",
    key: "amazonmusic",
  },
];
