import React, { ChangeEvent, useState, FC } from "react";
import { Modal, Button, Input } from "@govlaunch/core";
import { InstantSearch, connectSearchBox, Index } from "react-instantsearch-dom";
import { SearchBoxProvided, connectHits, Configure, Hit, HitsProvided } from "react-instantsearch-core";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import algoliaClient from "../../algoliaClient";
import getIndexName from "../../getIndexName";
import { ICompany, IGovernment, IUser } from "../../../types/types";
import { Table } from "antd";

type Vendor = Pick<ICompany, "_id" | "name"> & Hit;
type Government = Pick<IGovernment, "_id" | "name"> & Hit;

interface ISubmitProps {
  vendorId?: string | null;
  governmentId?: string | null;
  type: string;
}

interface IAssignUserModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  user: Pick<IUser, "_id" | "fullName">;
  onSubmit: (props: ISubmitProps) => void;
  submitting: boolean;
  error?: string | null;
}

export default function AssignUserModal({
  isOpen,
  onRequestClose,
  user,
  onSubmit,
  submitting,
  error,
}: IAssignUserModalProps) {
  const [vendor, setVendor] = useState<Vendor | null>(null);
  const [government, setGovernment] = useState<Government | null>(null);
  const [type, setType] = useState<string>("MEMBER");

  return (
    <Modal isOpen={isOpen}>
      <div
        css={{
          padding: 24,
          width: 700,
        }}
      >
        <div
          css={{
            marginBottom: 16,
            paddingBottom: 16,
            borderBottom: `1px solid ${palette.lightestGray}`,
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <h2
              css={{
                margin: 0,
                fontWeight: 700,
                display: "flex",
                alignItems: "center",
              }}
            >
              Assign To... 🏦️️🏛️
            </h2>

            <span
              role="button"
              onClick={onRequestClose}
              css={{
                marginLeft: "auto",
                color: palette.sealBlue,
                fontSize: 21,
                fontWeight: "lighter",
                cursor: "pointer",
              }}
            >
              &times;
            </span>
          </div>

          <p
            css={{
              fontSize: 14,
              fontStyle: "italic",
              color: palette.sealBlue,
              margin: 0,
            }}
          >
            {"Assign to a team for "}
            <span
              css={{
                textDecoration: "underline",
              }}
            >
              {user.fullName}
            </span>
          </p>
        </div>

        <div>
          {!vendor && !government && (
            <InstantSearch searchClient={algoliaClient} indexName={getIndexName("companies")}>
              <SearchInput placeholder="Search for vendor or governments" />
              <div
                css={{
                  marginTop: 8,
                }}
              >
                <Index indexName={getIndexName("companies")}>
                  <VendorList
                    onClick={(vendor, type) => {
                      setVendor(vendor);
                      setType(type);
                    }}
                  />
                </Index>

                <Index indexName={getIndexName("governments")}>
                  <div
                    css={{
                      marginTop: 16,
                    }}
                  >
                    <GovernmentList
                      onClick={(government, type) => {
                        setGovernment(government);
                        setType(type);
                      }}
                    />
                  </div>
                </Index>
              </div>

              <Configure hitsPerPage={6} />
            </InstantSearch>
          )}

          {(vendor || government) && (
            <div>
              <p>
                Will be assigned to <strong>{vendor ? vendor.name : government ? government.name : null}</strong>
              </p>

              <div
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridColumnGap: 8,
                }}
              >
                <Button
                  color={palette.greenSuccess}
                  textColor={palette.white}
                  disabled={submitting}
                  onClick={() =>
                    onSubmit({
                      vendorId: vendor ? vendor.objectID : null,
                      governmentId: government ? government.objectID : null,
                      type,
                    })
                  }
                >
                  {submitting ? "Assigning..." : "Confirm"}
                </Button>
                <Button
                  color={palette.mediumGray}
                  textColor={palette.white}
                  onClick={() => {
                    setVendor(null);
                    setGovernment(null);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}

          {error && (
            <div
              css={{
                marginTop: 8,
                color: palette.red,
                fontSize: 14,
              }}
            >
              {error.replace("GraphQL error:", "")}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const SearchInput = connectSearchBox(
  ({ currentRefinement, refine, ...props }: SearchBoxProvided & { placeholder: string }) => {
    return (
      <Input
        value={currentRefinement}
        onChange={(e: ChangeEvent<HTMLInputElement>) => refine(e.target.value)}
        {...props}
      />
    );
  },
);

interface IVendorListProps extends Partial<HitsProvided<Vendor>> {
  onClick: (vendor: Vendor, type: string) => void;
}

function StatelessVendorList({ hits, onClick }: IVendorListProps) {
  return (
    <div>
      <h3
        css={{
          margin: "0 0 8px",
          color: palette.darkGray,
        }}
      >
        Vendors →
      </h3>
      <Table
        dataSource={hits}
        size="small"
        pagination={false}
        columns={[
          {
            title: "Vendor",
            dataIndex: "name",
            width: 300,
            render: (_, hit) => (
              <div
                key={hit.objectID}
                css={{
                  display: "flex",
                  alignItems: "center",
                  padding: "4px 0",
                  cursor: "pointer",
                }}
              >
                <ProfilePicture image={hit.logo} size={24} name={hit.name} />{" "}
                <span
                  css={{
                    marginLeft: 8,
                  }}
                >
                  {hit.name}
                </span>
              </div>
            ),
          },
          {
            title: "Actions",
            dataIndex: "_id",
            render: (_, hit) => (
              <div
                css={{
                  display: "grid",
                  gridAutoFlow: "column",
                  gridGap: 4,
                }}
              >
                <Button
                  theme="secondary"
                  size="xsmall"
                  color={palette.greenSuccess}
                  onClick={() => onClick(hit, "OWNER")}
                  disabled={!!hit.owner}
                  css={{
                    ":disabled": {
                      opacity: 0.6,
                    },
                  }}
                >
                  Assign as Owner
                </Button>
                <Button theme="secondary" size="xsmall" color={palette.primary} onClick={() => onClick(hit, "MEMBER")}>
                  Assign as Member
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

const VendorList: FC<IVendorListProps> = connectHits(StatelessVendorList as any) as any;

interface IGovernmentListProps extends Partial<HitsProvided<Government>> {
  onClick: (government: Government, type: string) => void;
}

function StatelessGovernmentList({ hits, onClick }: IGovernmentListProps) {
  return (
    <div>
      <h3
        css={{
          margin: "0 0 8px",
          color: palette.darkGray,
        }}
      >
        Governments →
      </h3>

      <Table
        dataSource={hits}
        size="small"
        pagination={false}
        columns={[
          {
            title: "Government",
            dataIndex: "name",
            width: 300,
            render: (_, hit) => (
              <div
                key={hit.objectID}
                css={{
                  padding: "4px 0",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ProfilePicture image={hit.logo} size={24} name={hit.name} />{" "}
                <span
                  css={{
                    marginLeft: 8,
                  }}
                >
                  {hit.name}
                </span>
              </div>
            ),
          },
          {
            title: "Actions",
            dataIndex: "_id",
            render: (_, hit) => (
              <div
                css={{
                  display: "grid",
                  gridAutoFlow: "column",
                  gridGap: 4,
                }}
              >
                <Button
                  theme="secondary"
                  size="xsmall"
                  color={palette.greenSuccess}
                  onClick={() => onClick(hit, "OWNER")}
                  disabled={!!hit.owner}
                  css={{
                    ":disabled": {
                      opacity: 0.6,
                    },
                  }}
                >
                  Assign as Owner
                </Button>
                <Button theme="secondary" size="xsmall" color={palette.primary} onClick={() => onClick(hit, "MEMBER")}>
                  Assign as Member
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}

const GovernmentList: FC<IGovernmentListProps> = connectHits(StatelessGovernmentList as any) as any;
