import React, { useState } from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { Mobile } from "@govlaunch/core";
import { CloseButton, ImageUploadModal } from "@govlaunch/web";

type TImage = {
  url: string;
  alt: string | null | undefined;
};

export default function ScreenshotsField() {
  const [state, setState] = useState(0);

  return (
    <Field name="screenshots" type="">
      {({ input }) => (
        <div>
          <Mobile>
            {(isMobile: boolean) => (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: isMobile ? "column" : "row",
                  padding: input.value.length > 0 ? "0 15px" : 0,
                }}
              >
                <ImageUploadModal
                  onSave={(imageData) => input.onChange((input.value || []).concat(imageData))}
                  uploadButtonText="Add Screenshots"
                />
              </div>
            )}
          </Mobile>

          {input.value.length > 0 && (
            <div
              css={{
                padding: "15px 5px 5px 15px",
              }}
            >
              {input.value.map((media: TImage, index: number) => {
                return (
                  <div
                    key={index}
                    css={{
                      width: 93,
                      height: 93,
                      display: "inline-block",
                      marginBottom: 10,
                      marginRight: 10,
                      borderRadius: 5,
                      position: "relative",
                    }}
                  >
                    <CloseButton
                      size="sm"
                      position="absolute"
                      right={-5}
                      top={-5}
                      color="white"
                      bg="red.500"
                      borderRadius={25}
                      _hover={{
                        bg: "red.600",
                      }}
                      onClick={() => input.onChange(input.value.filter((maybeMedia: TImage) => maybeMedia !== media))}
                    />
                    <ImageUploadModal
                      imageData={{
                        url: media.url || "",
                        alt: media.alt || "",
                      }}
                      onSave={(imageData) => {
                        const screenshots = input.value || [];
                        screenshots[index] = imageData;
                        input.onChange(screenshots);
                        // stupid hack to make it redraw the image map, since changing an individual element doesn't trigger that
                        setState(state + 1);
                      }}
                    >
                      {({ setOpen }) => (
                        <img
                          css={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: 5,
                            border: `1px solid ${palette.lightestGray}`,
                            cursor: "pointer",
                          }}
                          src={media.url}
                          onClick={() => setOpen(true)}
                        />
                      )}
                    </ImageUploadModal>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </Field>
  );
}
