import React from "react";
import DocumentTitle from "react-document-title";
import * as palette from "@govlaunch/palette";
import { useParams } from "react-router";
import { useQuery } from "react-apollo";
import {
  IGetExternalLinkByIdQuery,
  IGetExternalLinkByIdQueryVariables,
} from "../../../queries/__generated__/GetExternalLinkById.generated";
import GetExternalLinkById from "../../../queries/GetExternalLinkById";
import { LoadingIndicator } from "@govlaunch/core";
import EditExternalLinkForm from "./EditExternalLinkForm";

export default function EditExternalLinkPage() {
  const params = useParams();

  const { data, loading } = useQuery<IGetExternalLinkByIdQuery, IGetExternalLinkByIdQueryVariables>(
    GetExternalLinkById,
    {
      variables: {
        id: params.id,
      },
      fetchPolicy: "network-only",
    },
  );

  return (
    <DocumentTitle title="External Link | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",

          overflow: "auto",
        }}
      >
        {loading && (
          <div>
            <LoadingIndicator />
          </div>
        )}

        {data && data.getExternalLinkById && <EditExternalLinkForm externalLink={data.getExternalLinkById} />}
      </div>
    </DocumentTitle>
  );
}
