import * as palette from "@govlaunch/palette";
import { CSSReset, theme, ThemeProvider } from "@govlaunch/web";
import { Alert, notification } from "antd";
import { EditorState } from "draft-js";
import React from "react";
import { useMutation } from "react-apollo";
import DocumentTitle from "react-document-title";
import { useNavigate } from "react-router";
import ProposeStory from "../../mutations/ProposeStory";
import {
  IProposeStoryMutation,
  IProposeStoryMutationVariables,
} from "../../mutations/__generated__/ProposeStory.generated";
import ScrollToTopOnMount from "../../ScrollToTopOnMount";
import { useSelfie } from "../auth/Selfie";
import { convertValuesToProposeStoryVariables } from "./convertValuesToVariables";
import StoryComposer from "./StoryComposer";

export default function ComposeStory() {
  const navigate = useNavigate();
  const author = useSelfie();

  const [proposeStory] = useMutation<IProposeStoryMutation, IProposeStoryMutationVariables>(ProposeStory);

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />

      <DocumentTitle title="New Story | Admin">
        <ScrollToTopOnMount>
          {author ? (
            <div
              css={{
                //paddingBottom: 120,
                backgroundColor: palette.washGray,
                borderBottom: "1px solid rgb(232, 232, 232)",
              }}
            >
              <StoryComposer
                initialValues={{
                  author,
                  coauthors: [],
                  vendorCoauthors: [],
                  title: "",
                  introduction: "",
                  locations: [],
                  medias: [],
                  government: null,
                  tags: [],
                  products: [],
                  newsworthy: [],
                  highlights: [],
                  body: EditorState.createEmpty(),
                  awards: [],
                  resources: [],
                  collaborators: [],
                  audience: "community",
                }}
                onSubmit={async (values) => {
                  const { data } = await proposeStory({
                    variables: convertValuesToProposeStoryVariables(values),
                  });

                  if (data) {
                    notification.open({
                      type: "success",
                      message: "Story successfully created!",
                      description: `${data.proposeStory.title} was created!`,
                    });

                    navigate(`/stories/${data.proposeStory.slug}`);
                  }
                }}
                submitButtonText="Create Story"
              />
            </div>
          ) : (
            <div
              css={{
                padding: 20,
              }}
            >
              <Alert type="error" message="You must be logged in to write Stories." />
            </div>
          )}
        </ScrollToTopOnMount>
      </DocumentTitle>
    </ThemeProvider>
  );
}
