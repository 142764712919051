import PropTypes from "prop-types";
import { Query } from "react-apollo";
import React from "react";
import fp from "lodash/fp";
import VendorsCountQuery from "../../queries/VendorsCountQuery";
import {
  IVendorsCountQuery,
  IVendorsCountQueryVariables,
} from "../../queries/__generated__/VendorsCountQuery.generated";

interface IVendorsCountProps {
  children: any;
}

export default function VendorsCount({ children }: IVendorsCountProps) {
  return (
    <Query<IVendorsCountQuery, IVendorsCountQueryVariables> query={VendorsCountQuery}>
      {({ data, loading, refetch }) => {
        if (!data || loading) {
          return null;
        }

        return children({
          ...mapCounts(data.vendorsCount, loading),
          loading,
          refetch,
        });
      }}
    </Query>
  );
}

const mapCounts = (counts: any, loading: boolean) => {
  return fp.mapValues(count => {
    if (typeof count === "number") {
      return count;
    }

    if (loading) {
      return null;
    } else {
      return count;
    }
  })(counts);
};

VendorsCount.propTypes = {
  children: PropTypes.func.isRequired,
};
