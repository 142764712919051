import gql from "graphql-tag";
import React from "react";
import { Query } from "react-apollo";

interface ITagsCountState {
  loading: boolean;
  refetch: () => void;
  allCount: number;
  archivedCount: number;
  projectsCount: number;
  productsCount: number;
  storiesCount: number;
  awardsCount: number;
  departmentsCount: number;
  customCount: number;
}

interface ITagsCountProps {
  children: (state: ITagsCountState) => any;
}

export default function TagsCount({ children }: ITagsCountProps) {
  return (
    <Query
      query={gql`
        query TagsCount {
          tagsCount {
            allCount
            projectsCount
            productsCount
            storiesCount
            awardsCount
            departmentsCount
            archivedCount
            customCount
          }
        }
      `}
    >
      {({ data, loading, refetch }: any) => {
        return children({
          loading,
          refetch,
          projectsCount: data && data.tagsCount ? data.tagsCount.projectsCount : 0,
          productsCount: data && data.tagsCount ? data.tagsCount.productsCount : 0,
          storiesCount: data && data.tagsCount ? data.tagsCount.storiesCount : 0,
          awardsCount: data && data.tagsCount ? data.tagsCount.awardsCount : 0,
          departmentsCount: data && data.tagsCount ? data.tagsCount.departmentsCount : 0,
          archivedCount: data && data.tagsCount ? data.tagsCount.archivedCount : 0,
          allCount: data && data.tagsCount ? data.tagsCount.allCount : 0,
          customCount: data && data.tagsCount ? data.tagsCount.customCount : 0,
        });
      }}
    </Query>
  );
}
