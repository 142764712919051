import { Mutation } from "react-apollo";
import PropTypes from "prop-types";
import React from "react";
import ToggleFeaturedReport from "../../mutations/ToggleFeaturedReport";
import {
  IToggleFeaturedReportMutation,
  IToggleFeaturedReportMutationVariables,
} from "../../mutations/__generated__/ToggleFeaturedReport.generated";
import ToggleReportArchived from "../../mutations/ToggleReportArchived";
import {
  IToggleReportArchivedMutation,
  IToggleReportArchivedMutationVariables,
} from "../../mutations/__generated__/ToggleReportArchived.generated";
import ToggleReportPublished from "../../mutations/ToggleReportPublished";
import {
  IToggleReportPublishedMutation,
  IToggleReportPublishedMutationVariables,
} from "../../mutations/__generated__/ToggleReportPublished.generated";

interface IToggleFeaturedProps {
  children: (arg: any) => any;
}

export function ToggleFeatured({ children }: IToggleFeaturedProps) {
  return (
    <Mutation<IToggleFeaturedReportMutation, IToggleFeaturedReportMutationVariables> mutation={ToggleFeaturedReport}>
      {toggleReportFeatured =>
        children((report: any) =>
          toggleReportFeatured({
            variables: {
              id: report._id,
            },
            optimisticResponse: {
              __typename: "Mutation",
              toggleFeatured: {
                __typename: "Report",
                _id: report._id,
                featured: !report.featured,
              },
            },
          }),
        )
      }
    </Mutation>
  );
}

ToggleFeatured.propTypes = {
  children: PropTypes.func.isRequired,
};

interface IToggleArchivedProps {
  children: (arg: any) => any;
}

export function ToggleArchived({ children }: IToggleArchivedProps) {
  return (
    <Mutation<IToggleReportArchivedMutation, IToggleReportArchivedMutationVariables> mutation={ToggleReportArchived}>
      {toggleReportArchived =>
        children((report: any) =>
          toggleReportArchived({
            variables: {
              id: report._id,
            },
            optimisticResponse: {
              __typename: "Mutation",
              toggleReportArchived: {
                __typename: "Report",
                _id: report._id,
                archivedAt: report.archivedAt ? null : new Date().toISOString(),
              },
            },
          }),
        )
      }
    </Mutation>
  );
}

ToggleArchived.propTypes = {
  children: PropTypes.func.isRequired,
};

interface ITogglePublishedProps {
  children: (arg: any) => any;
}

export function TogglePublished({ children }: ITogglePublishedProps) {
  return (
    <Mutation<IToggleReportPublishedMutation, IToggleReportPublishedMutationVariables> mutation={ToggleReportPublished}>
      {toggleReportPublished =>
        children((report: any) =>
          toggleReportPublished({
            variables: {
              id: report._id,
            },
            optimisticResponse: {
              __typename: "Mutation",
              toggleReportPublished: {
                __typename: "Report",
                _id: report._id,
                unpublishedAt: report.unpublishedAt ? null : new Date().toISOString(),
              },
            },
          }),
        )
      }
    </Mutation>
  );
}

TogglePublished.propTypes = {
  children: PropTypes.func.isRequired,
};
