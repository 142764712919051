import gql from "graphql-tag";

export default gql`
  mutation EditSponsorship($id: ObjectId!, $sponsorship: SponsorshipInput!) {
    editSponsorship(id: $id, sponsorship: $sponsorship) {
      _id
      name
      slug
      logo
      link
    }
  }
`;
