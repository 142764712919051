import React from "react";
import { useToggle } from "react-use";
import { IGetGovernmentQuery } from "../../../queries/__generated__/GetGovernmentQuery.generated";
import { Margin } from "../../../spacings";
import EditGovernmentMembersModal from "../../teamManagement/modals/EditGovernmentMembersModal";
import UsersTable from "../../users/tables/UsersTable";

interface IGovernmentMembersTabProps {
  government: IGetGovernmentQuery["government"];
  refetch: () => any;
}
const GovernmentMembersTab = ({ government, refetch }: IGovernmentMembersTabProps) => {
  const [showTeamBuilder, toggleTeamBuilder] = useToggle(false);

  if (!government) {
    return null;
  }

  return (
    <div>
      <EditGovernmentMembersModal
        visible={showTeamBuilder}
        government={government}
        onRequestClose={toggleTeamBuilder}
        onSaveChanges={toggleTeamBuilder}
      />

      <Margin mb={12}>
        <a
          href="#"
          onClick={(event) => {
            event.preventDefault();
            toggleTeamBuilder();
          }}
        >
          Team Builder
        </a>
      </Margin>

      <UsersTable
        users={government.members}
        onDeleteUser={() => {
          refetch();
        }}
        onBanUser={() => {
          refetch();
        }}
      />
    </div>
  );
};

export default GovernmentMembersTab;
