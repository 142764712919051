import React from "react";
import * as palette from "@govlaunch/palette";
import Tooltip from "../../../components/Tooltip";
import ProfilePicture from "@govlaunch/profile-picture";
import { IStoryBySlugQuery } from "../../../queries/__generated__/StoryBySlugQuery.generated";

type TCollaborator = NonNullable<IStoryBySlugQuery["storyBySlug"]>["collaborators"][0];

export default function Collaborator({ collaborator }: { collaborator: TCollaborator }) {
  return (
    <Tooltip
      backgroundColor={palette.innovatorBlue}
      borderColor={palette.innovatorBlue}
      render={() => (
        <div
          css={{
            padding: 6,
            color: palette.white,
          }}
        >
          <p
            css={{
              margin: 0,
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            {collaborator.fullName}
          </p>
        </div>
      )}
    >
      {({ bind, innerRef }) => (
        <ProfilePicture
          image={collaborator.avatar}
          name={collaborator.fullName || collaborator.firstName}
          size={54}
          {...bind}
          innerRef={innerRef}
        />
      )}
    </Tooltip>
  );
}
