import { notification } from "antd";
import moment from "moment";
import React from "react";
import { useMutation } from "react-apollo";
import { IEditSaleInput } from "../../../types/types";
import CollectionSponsoringForm from "./CollectionSponsoringForm";
import ListCollectionSponsoringSales from "./graphql/queries/ListCollectionSponsoringSales";
import { IListCollectionSponsoringSalesQuery } from "./graphql/queries/__generated__/ListCollectionSponsoringSales.generated";
import EditCollectionSponsoringSale from "./graphql/mutations/EditCollectionSponsoringSale";
import {
  IEditCollectionSponsoringSaleMutation,
  IEditCollectionSponsoringSaleMutationVariables,
} from "./graphql/mutations/__generated__/EditCollectionSponsoringSale.generated";

interface IUpdateCollectionProps {
  sponsoring: IListCollectionSponsoringSalesQuery["listAllCollectionSponsoringSales"][0];
  onClose: () => void;
}

export default function UpdateCollectionSponsoring({ sponsoring, onClose }: IUpdateCollectionProps) {
  const [updateCollectionSponsoring, { error, loading }] = useMutation<
    IEditCollectionSponsoringSaleMutation,
    IEditCollectionSponsoringSaleMutationVariables
  >(EditCollectionSponsoringSale, {
    refetchQueries: [
      {
        query: ListCollectionSponsoringSales,
      },
    ],
  });

  return (
    <CollectionSponsoringForm
      submitting={loading}
      onSubmit={async (values: IEditSaleInput) => {
        await updateCollectionSponsoring({
          variables: {
            saleId: sponsoring._id,
            input: {
              description: values.description,
              includedCollections: values.includedCollections,
              sponsorshipLevel: values.sponsorshipLevel,
              type: values.type,
            },
          },
        }).then((result) => {
          if (result && result.data && result.data.editCollectionSponsoringSale) {
            notification.open({
              type: "success",
              message: "Collection sponsoring saved!",
              description: `Collection sponsoring ${result.data.editCollectionSponsoringSale.description} saved successfully!`,
            });
          }
        });

        onClose();
      }}
      error={error?.message.replace("GraphQL error: ", "")}
      onClose={onClose}
      isOpen={true}
      sponsoring={{
        __typename: sponsoring.__typename,
        _id: sponsoring._id,
        description: sponsoring.description,
        includedCollections: sponsoring.includedCollections,
        type: sponsoring.type,
        price: sponsoring.price,
        sponsorshipLevel: sponsoring.sponsorshipLevel,
        paymentSchedule: sponsoring.paymentSchedule,
        vendor: sponsoring.vendor,
        expirationDate:
          sponsoring.__typename === "LumpSumCollectionSponsoringSale" ? moment(sponsoring.expirationDate) : null,
      }}
    />
  );
}
