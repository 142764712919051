import { Table, TableProps } from "antd";
import React from "react";
import { ITablePaginationState } from "../../components/table/TablePagination";
import { IGetAllImagesQuery } from "../../queries/__generated__/GetAllImages.generated";
import * as palette from "@govlaunch/palette";
import { Link } from "react-router-dom";
import { IImageSourceType } from "../../../types/types";
import ImageAltColumn from "./ImageAltColumn";

type TImage = NonNullable<NonNullable<IGetAllImagesQuery["images"]>["items"]>[0];

export interface IImagesTableState {
  cursor: ITablePaginationState;
}

interface IImagesTableProps {
  images: TImage[];
  loading?: boolean;
  state?: IImagesTableState;
  footer?: TableProps<any>["footer"];
  title?: () => any;
}

export default function ImagesTable({ images, loading, footer, title }: IImagesTableProps) {
  return (
    <Table
      pagination={false}
      loading={loading}
      rowKey="_id"
      bordered={true}
      dataSource={images || []}
      style={{
        backgroundColor: palette.white,
      }}
      scroll={{
        x: true,
      }}
      title={title}
      footer={footer}
      columns={[
        {
          title: "Url",
          dataIndex: "url",
          width: 240,
          render: (url: string) => {
            return (
              <img
                src={url}
                css={{
                  objectFit: "contain",
                  maxHeight: "80px",
                }}
              />
            );
          },
        },
        {
          title: "Alt text",
          dataIndex: "alt",
          render: (_, image: TImage) => {
            return <ImageAltColumn url={image.url} alt={image.alt} imageId={image._id} />;
          },
        },
        {
          title: "Source",
          dataIndex: "source",
          width: "440px",
          render: (_, image: TImage) => {
            if (!image.source) {
              return null;
            }

            return (
              <Link
                to={
                  image.sourceType === IImageSourceType.Product
                    ? `/products/${image.source}`
                    : `/stories/${image.source}`
                }
              >
                {getSourceLink(image.source, image.sourceType)}
              </Link>
            );
          },
        },
      ]}
    />
  );
}

function getSourceLink(source: string | null, sourceType: IImageSourceType | null) {
  if (!source || !sourceType) {
    return null;
  }

  if (sourceType === IImageSourceType.Product) {
    if (process.env.GOVLAUNCH_ENV === "production") {
      return `https://admin.govlaunch.com/products/${source}`;
    }

    if (process.env.GOVLAUNCH_ENV === "qa") {
      return `https://admin.qa.govlaunch.com/products/${source}`;
    }

    return `localhost:3003/products/${source}`;
  }

  if (process.env.GOVLAUNCH_ENV === "production") {
    return `https://admin.govlaunch.com/stories/${source}`;
  }

  if (process.env.GOVLAUNCH_ENV === "qa") {
    return `https://admin.qa.govlaunch.com/stories/${source}`;
  }

  return `localhost:3003/stories/${source}`;
}
