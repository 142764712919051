import React from "react";

interface ICampaignPreviewFooterProps {
  preview?: boolean;
}

export default function CampaignPreviewFooter({ preview = true }: ICampaignPreviewFooterProps) {
  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      className="es-footer"
      style={{
        margin: 0,
        padding: 0,
        borderCollapse: "collapse",
        borderSpacing: "0px",
        tableLayout: "fixed",
        width: "100%",
        backgroundColor: "transparent",
        backgroundRepeat: "repeat",
        backgroundPosition: "center top",
      }}
    >
      <tbody>
        <tr>
          <td
            align="center"
            style={{
              padding: 0,
              margin: 0,
              backgroundColor: "#ffffff",
            }}
          >
            <table
              className="es-footer-body"
              style={{
                borderCollapse: "collapse",
                borderSpacing: "0px",
                backgroundColor: "#f9f9f9",
                width: "600px",
              }}
              cellSpacing="0"
              cellPadding="0"
            >
              <tbody>
                <tr>
                  <td
                    align="left"
                    style={{
                      padding: 0,
                      margin: 0,
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: "0px",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            align="left"
                            style={{
                              padding: 0,
                              margin: 0,
                              width: "580px",
                            }}
                          >
                            <table
                              cellSpacing="0"
                              cellPadding="0"
                              role="presentation"
                              style={{
                                borderCollapse: "collapse",
                                borderSpacing: "0px",
                                width: "100%",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="center"
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      paddingBottom: "10px",
                                      fontSize: 0,
                                    }}
                                  >
                                    <table
                                      cellPadding="0"
                                      cellSpacing="0"
                                      role="presentation"
                                      style={{
                                        borderCollapse: "collapse",
                                        borderSpacing: "0px",
                                        width: "100%",
                                        height: "100%",
                                        border: 0,
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              padding: 0,
                                              margin: 0,
                                              borderBottom: "1px solid #eeeeee",
                                              background: "none",
                                              height: "1px",
                                              width: "100%",
                                            }}
                                          />
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <table
                                      cellPadding="0"
                                      cellSpacing="0"
                                      role="presentation"
                                      className="es-menu"
                                      style={{
                                        borderCollapse: "collapse",
                                        borderSpacing: "0px",
                                        width: "100%",
                                      }}
                                    >
                                      <tbody>
                                        <tr className="links-images-left">
                                          <td
                                            id="esd-menu-id-0"
                                            align="center"
                                            valign="top"
                                            style={{
                                              margin: 0,
                                              padding: "5px",
                                              border: 0,
                                              width: "33.33%",
                                            }}
                                          >
                                            <a
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              href="https://*|UPDATE_PROFILE|*"
                                              style={{
                                                WebkitTextSizeAdjust: "none",
                                                //@ts-ignore
                                                msTextSizeAdjust: "none",
                                                textDecoration: "none",
                                                display: "block",
                                                color: "#086bef",
                                                fontSize: "12px",
                                              }}
                                            >
                                              <img
                                                src="https://lchjnb.stripocdn.email/content/guids/CABINET_f58f6a7604aedc7d0bbb77dbb5977418/images/2361628854912843.png"
                                                alt="Manage Preferences"
                                                title="Manage Preferences"
                                                height="14"
                                                style={{
                                                  display: "inline-block",
                                                  border: 0,
                                                  outline: "none",
                                                  textDecoration: "none",
                                                  paddingRight: "5px",
                                                  verticalAlign: "middle",
                                                }}
                                              />
                                              Manage Preferences
                                            </a>
                                          </td>
                                          <td
                                            id="esd-menu-id-1"
                                            align="center"
                                            valign="top"
                                            style={{
                                              margin: 0,
                                              padding: "5px",
                                              border: 0,
                                              borderLeft: "1px solid #eeeeee",
                                              width: "33.33%",
                                            }}
                                          >
                                            <a
                                              target="_blank"
                                              href="*|UNSUB|*"
                                              style={{
                                                WebkitTextSizeAdjust: "none",
                                                //@ts-ignore
                                                msTextSizeAdjust: "none",
                                                textDecoration: "none",
                                                display: "block",
                                                color: "#086bef",
                                                fontSize: "12px",
                                              }}
                                            >
                                              <img
                                                src="https://lchjnb.stripocdn.email/content/guids/CABINET_f58f6a7604aedc7d0bbb77dbb5977418/images/45801628854810219.png"
                                                alt="Unsubscribe"
                                                title="Unsubscribe"
                                                height="14"
                                                style={{
                                                  display: "inline-block",
                                                  border: 0,
                                                  outline: "none",
                                                  textDecoration: "none",
                                                  paddingRight: "5px",
                                                  verticalAlign: "middle",
                                                }}
                                              />
                                              Unsubscribe
                                            </a>
                                          </td>
                                          <td
                                            id="esd-menu-id-2"
                                            align="center"
                                            valign="top"
                                            style={{
                                              margin: 0,
                                              padding: "5px",
                                              border: 0,
                                              borderLeft: "1px solid #eeeeee",
                                              width: "33.33%",
                                            }}
                                          >
                                            <a
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              href="mailto:hello@govlaunch.com?subject=Hello%20Team%20Govlaunch"
                                              style={{
                                                WebkitTextSizeAdjust: "none",
                                                //@ts-ignore
                                                msTextSizeAdjust: "none",
                                                textDecoration: "none",
                                                display: "block",
                                                color: "#086bef",
                                                fontSize: "12px",
                                              }}
                                            >
                                              <img
                                                src="https://lchjnb.stripocdn.email/content/guids/CABINET_f58f6a7604aedc7d0bbb77dbb5977418/images/72361628855217312.png"
                                                alt="Contact Us"
                                                title="Contact Us"
                                                height="14"
                                                style={{
                                                  display: "inline-block",
                                                  border: 0,
                                                  outline: "none",
                                                  textDecoration: "none",
                                                  paddingRight: "5px",
                                                  verticalAlign: "middle",
                                                }}
                                              />
                                              Contact Us
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="center"
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      paddingTop: "10px",
                                      paddingBottom: "15px",
                                      fontSize: 0,
                                    }}
                                  >
                                    <table
                                      cellPadding="0"
                                      cellSpacing="0"
                                      role="presentation"
                                      style={{
                                        borderCollapse: "collapse",
                                        borderSpacing: "0px",
                                        width: "100%",
                                        height: "100%",
                                        border: 0,
                                      }}
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              padding: 0,
                                              margin: 0,
                                              borderBottom: "1px solid #eeeeee",
                                              background: "none",
                                              height: "1px",
                                              width: "100%",
                                            }}
                                          />
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    align="left"
                    style={{
                      padding: 0,
                      margin: 0,
                      paddingBottom: "35px",
                    }}
                  >
                    {!preview &&
                      `<!--[if mso]><table style="width:600px" cellpadding="0" cellspacing="0"><tr><td style="width:375px" valign="top"><![endif]--> `}
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      className="es-left"
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: "0px",
                        float: "left",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            align="left"
                            style={{
                              padding: 0,
                              margin: 0,
                              width: "375px",
                            }}
                          >
                            <table
                              cellPadding="0"
                              cellSpacing="0"
                              role="presentation"
                              style={{
                                borderCollapse: "collapse",
                                borderSpacing: "0px",
                                width: "100%",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="left"
                                    className="es-m-txt-c"
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      paddingLeft: "30px",
                                      paddingRight: "30px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        margin: 0,
                                        WebkitTextSizeAdjust: "none",
                                        //@ts-ignore
                                        msTextSizeAdjust: "none",
                                        lineHeight: "18px",
                                        color: "#888888",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Govlaunch is the&nbsp;wiki for local government innovation.You are receiving this
                                      email because you have either opted in or were referred to us by a friend or peer.
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {!preview &&
                      `<!--[if mso]></td><td style="width:20px"></td><td style="width:205px" valign="top"><![endif]--> `}

                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      className="es-right"
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: "0px",
                        float: "right",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            align="left"
                            style={{
                              padding: 0,
                              margin: 0,
                              width: "205px",
                            }}
                          >
                            <table
                              cellPadding="0"
                              cellSpacing="0"
                              role="presentation"
                              style={{
                                borderCollapse: "collapse",
                                borderSpacing: "0px",
                                width: "100%",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="left"
                                    className="es-m-p30 es-m-txt-c"
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <p
                                      style={{
                                        margin: 0,
                                        WebkitTextSizeAdjust: "none",
                                        //@ts-ignore
                                        msTextSizeAdjust: "none",
                                        lineHeight: "18px",
                                        color: "#3f3c3c",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#888888",
                                        }}
                                      >
                                        1707 L Street NW / Suite 560
                                        <br />
                                        Washington, DC 20036
                                      </span>
                                      <br />
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          WebkitTextSizeAdjust: "none",
                                          //@ts-ignore
                                          msTextSizeAdjust: "none",
                                          textDecoration: "none",
                                          color: "#086bef",
                                          fontSize: "12px",
                                          lineHeight: "18px",
                                        }}
                                        href="mailto:hello@govlaunch.com?subject=Hello%20Team%20Govlaunch"
                                      >
                                        hello@govlaunch.com
                                      </a>
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {!preview && `<!--[if mso]></td></tr></table><![endif]-->`}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
