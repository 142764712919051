import { faImages } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import DocumentTitle from "react-document-title";
import { useLocation, useNavigate } from "react-router";
import TablePageHeader from "../../components/table/TablePageHeader";
import ImagesCount from "./ImagesCount";
import queryString from "query-string";
import { Tabs } from "antd";
import { IImagesQueryTab } from "../../../types/types";
import ImagesWithSearch from "./ImagesWithSearch";

export default function ImagesPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = query.tab || IImagesQueryTab.All;

  return (
    <DocumentTitle title="Images | Admin">
      <div>
        <TablePageHeader title="Images" titleIcon={faImages} subTitle="All Images" />

        <ImagesCount>
          {({ allCount, noAltCount, withAltCount, placeholderCount, archivedCount, refetch }) => (
            <Tabs
              activeKey={tab.toString()}
              animated={false}
              size="middle"
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
              onChange={(tab) => {
                navigate(`?tab=${tab}`);
                refetch();
              }}
            >
              <Tabs.TabPane tab={allCount ? `All (${allCount})` : "All"} key={IImagesQueryTab.All}>
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  <ImagesWithSearch tab={IImagesQueryTab.All} />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={withAltCount ? `With Alt (${withAltCount})` : "With Alt"}
                key={IImagesQueryTab.WithAlt}
              >
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  <ImagesWithSearch tab={IImagesQueryTab.WithAlt} />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab={noAltCount ? `No Alt (${noAltCount})` : "No Alt"} key={IImagesQueryTab.NoAlt}>
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  <ImagesWithSearch tab={IImagesQueryTab.NoAlt} />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={placeholderCount ? `Placeholder Alt (${placeholderCount})` : "Placeholder Alt"}
                key={IImagesQueryTab.Placeholder}
              >
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  <ImagesWithSearch tab={IImagesQueryTab.Placeholder} />
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={archivedCount ? `Archived (${archivedCount})` : "Archived"}
                key={IImagesQueryTab.Archived}
              >
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  <ImagesWithSearch tab={IImagesQueryTab.Archived} />
                </div>
              </Tabs.TabPane>
            </Tabs>
          )}
        </ImagesCount>
      </div>
    </DocumentTitle>
  );
}
