import React, { useState } from "react";
import * as palette from "@govlaunch/palette";
import { Button } from "@govlaunch/core";
import { useToggle } from "react-use";
import ChangeStoryAuthor from "../ChangeStoryAuthor";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { Field } from "react-final-form";
import { Margin } from "../../../spacings";
import StoryAuthorCard, { parseTCoauthorFromTStoryAuthor, TStoryAuthor } from "./StoryAuthorCard";

export default function EditableStoryAuthor({ initialAuthor }: { initialAuthor: TStoryAuthor }) {
  const [showChangeAuthor, toggleShowChangeAuthor] = useToggle(false);
  const [author, setAuthor] = useState(initialAuthor);

  return (
    <Field name="author">
      {({ input, meta }) => {
        return (
          <>
            <div
              css={{
                background: "#fff",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <StoryAuthorCard author={parseTCoauthorFromTStoryAuthor(author)} />

              <Margin mt={20}>
                <Button
                  size="xsmall"
                  color={palette.primary}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();

                    toggleShowChangeAuthor();
                  }}
                >
                  Change Author
                </Button>
              </Margin>

              <FieldValidationError meta={meta} />
            </div>

            <ChangeStoryAuthor
              author={author}
              isOpen={showChangeAuthor}
              onRequestClose={toggleShowChangeAuthor}
              onStoryAuthorChange={({ author }) => {
                setAuthor(author);
                input.onChange(author);
                toggleShowChangeAuthor();
              }}
            />
          </>
        );
      }}
    </Field>
  );
}
