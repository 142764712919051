import gql from "graphql-tag";
import UserFields from "../../../../queries/fragments/UserFields";

export default gql`
  mutation EditGroupMembers($groupId: ObjectId!, $members: [ObjectId!]!) {
    editGroupMembers(groupId: $groupId, members: $members) {
      _id
      members {
        items {
          ...UserFields
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }

  ${UserFields}
`;
