import { Button, Col, Grid, RowWrap } from "@govlaunch/core";
import React from "react";
import { Margin } from "../../spacings";
import Section from "../form/Section";
import SectionBody from "../form/SectionBody";
import SectionHeader from "../form/SectionHeader";
import * as palette from "@govlaunch/palette";
import HorizontalNumberField from "../form/fields/HorizontalNumberField";
import HorizontalTextField from "../form/fields/HorizontalTextField";
import ProjectsPerCountryField from "./ProjectsPerCountryField";

interface INewsletterCampaignFormProps {
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  submitting?: boolean;
  submitError?: string;
}

export default function NewsletterCampaignForm({
  handleSubmit,
  submitting,
  submitError,
}: INewsletterCampaignFormProps) {
  return (
    <form
      onSubmit={handleSubmit}
      css={{
        paddingBottom: 40,
      }}
    >
      <Grid>
        <RowWrap>
          <Col width={[1, 1, 8 / 12, 8 / 12]}>
            <Section>
              <SectionHeader>General</SectionHeader>
              <SectionBody>
                <HorizontalTextField fieldName="name" label="Name" placeholder="Type name..." labelWidth={120} />
                <HorizontalNumberField fieldName="storiesCount" label="Stories Count" labelWidth={120} minValue={0} />
                <HorizontalNumberField fieldName="projectsCount" label="Projects Count" labelWidth={120} minValue={0} />
                <HorizontalNumberField
                  fieldName="topCollections"
                  label="Top Collections"
                  labelWidth={120}
                  minValue={0}
                />
                <HorizontalNumberField
                  fieldName="otherCollections"
                  label="Other Collections"
                  labelWidth={120}
                  minValue={0}
                />
              </SectionBody>
            </Section>

            <Margin mt={24}>
              <Section>
                <SectionHeader>Projects per countries</SectionHeader>
                <SectionBody>
                  <ProjectsPerCountryField />
                </SectionBody>
              </Section>
            </Margin>

            {submitError && (
              <Margin mt={24}>
                <span
                  css={{
                    color: palette.red,
                    fontSize: 16,
                  }}
                >
                  {submitError}
                </span>
              </Margin>
            )}

            <Margin mt={12}>
              <Button
                type="submit"
                color={palette.primary}
                size="large"
                disabled={submitting}
                style={{
                  width: "100%",
                  maxWidth: "240px",
                }}
              >
                {submitting ? "Submitting..." : "Submit"}
              </Button>
            </Margin>
          </Col>
        </RowWrap>
      </Grid>
    </form>
  );
}
