import React from "react";
import { FormSpy } from "react-final-form";
import { IPlanTier } from "../../../types/types";
import { Margin } from "../../spacings";
import HorizontalSelectField from "../form/fields/HorizontalSelectField";
import HorizontalNumberField from "../form/fields/HorizontalNumberField";
import HorizontalTextField from "../form/fields/HorizontalTextField";
import CheckboxField from "../form/fields/CheckboxField";
import { Button } from "antd";
import PlanFormSection from "./PlanFormSection";
import * as palette from "@govlaunch/palette";

interface IPlanFormProps {
  submitting: boolean;
  handleSubmit: () => any;
  submitError: any;
  isEditting?: boolean;
  addMode?: boolean;
}

export default function PlanForm({ submitting, handleSubmit, submitError, isEditting, addMode }: IPlanFormProps) {
  return (
    <form onSubmit={handleSubmit}>
      <PlanFormSection>
        <HorizontalTextField
          fieldName="name"
          label="Name"
          placeholder="Makers plan, price, etc..."
          inputWidth={420}
          tooltip="For internal use only"
        />

        <Margin mt={12}>
          <HorizontalTextField
            fieldName="displayName"
            label="Frontend Title"
            placeholder="Type plan name..."
            inputWidth={420}
            tooltip="That name appears at the vendor account page"
          />
        </Margin>

        <Margin mt={12}>
          <HorizontalSelectField
            fieldName="tier"
            label="Tier"
            options={
              addMode
                ? [
                    {
                      value: IPlanTier.Makers,
                      label: "Makers",
                    },
                    {
                      value: IPlanTier.Makerspro,
                      label: "Makers Pro",
                    },
                  ]
                : [
                    {
                      value: IPlanTier.Basic,
                      label: "Basic",
                    },
                    {
                      value: IPlanTier.Makers,
                      label: "Makers",
                    },
                    {
                      value: IPlanTier.Makerspro,
                      label: "Makers Pro",
                    },
                    {
                      value: IPlanTier.Disruptor,
                      label: "Disruptor",
                    },
                  ]
            }
            inputWidth={420}
            disabled={isEditting}
          />
        </Margin>

        <FormSpy
          subscription={{
            values: true,
          }}
        >
          {({ values }) => (
            <div>
              <Margin mt={12}>
                <HorizontalTextField
                  fieldName="stripePlanId"
                  label="Stripe Plan ID"
                  placeholder={
                    values.tier === IPlanTier.Basic
                      ? "Basic plans do not have a Stripe Plan ID"
                      : "Example: price_1IIYvtGVXgiBQ9eB7fFf8XG9"
                  }
                  disabled={values.tier === IPlanTier.Basic || values.tier === IPlanTier.Disruptor || isEditting}
                  inputWidth={420}
                />
              </Margin>
            </div>
          )}
        </FormSpy>
      </PlanFormSection>

      <PlanFormSection
        title="Limits"
        subtitle="Here you can see the limits for the custom plan"
        css={{
          marginTop: 24,
        }}
      >
        <HorizontalNumberField fieldName="products" label="Max # Products" labelWidth={170} />

        <div
          css={{
            marginTop: 8,
          }}
        >
          <HorizontalNumberField fieldName="enrichedProducts" label="Max # Enriched Products" labelWidth={170} />
        </div>
        <div
          css={{
            marginTop: 8,
          }}
        >
          <HorizontalNumberField fieldName="productTags" label="Max # Product Tags" labelWidth={170} />
        </div>
        <div
          css={{
            marginTop: 8,
          }}
        >
          <HorizontalNumberField fieldName="projectsPerMonth" label="Max # Projects Per Month" labelWidth={170} />
        </div>
        <div
          css={{
            marginTop: 8,
          }}
        >
          <HorizontalNumberField fieldName="groups" label="Max # Groups" labelWidth={170} />
        </div>
        <div
          css={{
            marginTop: 8,
          }}
        >
          <HorizontalNumberField fieldName="groupInvitations" label="Max # Group Invitations" labelWidth={170} />
        </div>
        <div
          css={{
            marginTop: 8,
          }}
        >
          <HorizontalNumberField fieldName="teamMembers" label="Max # Team Members" labelWidth={170} />
        </div>
      </PlanFormSection>

      <PlanFormSection
        title="User Permissions"
        css={{
          marginTop: 24,
        }}
      >
        <div>
          <CheckboxField fieldName="enrichedProfile" label="Enriched Profile" />
        </div>
        <div>
          <CheckboxField fieldName="sharingPage" label="Enable Sharing Page" />
        </div>
        <div>
          <CheckboxField fieldName="searchAccess" label="Enable Search Access" />
        </div>
        <div>
          <CheckboxField fieldName="inviteGovernments" label="Enable Invite Governments" />
        </div>
      </PlanFormSection>

      <div
        css={{
          marginTop: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          disabled={submitting}
          css={{
            userSelect: "none",
          }}
        >
          {submitting ? "Please wait..." : "Submit"}
        </Button>
      </div>
      {submitError && (
        <div
          css={{
            marginTop: 24,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            color: palette.red,
          }}
        >
          {submitError}
        </div>
      )}
    </form>
  );
}
