import { Divider, notification, Table } from "antd";
import Column from "antd/lib/table/Column";
import React from "react";
import { useMutation, useQuery } from "react-apollo";
import { Link } from "react-router-dom";
import ArchiveNewsletterCampaignMutation from "./graphql/ArchiveNewsletterCampaignMutation";
import ListNewsletterCampaignsQuery from "./graphql/ListNewsletterCampaignsQuery";
import {
  IArchiveNewsletterCampaignMutation,
  IArchiveNewsletterCampaignMutationVariables,
} from "./graphql/__generated__/ArchiveNewsletterCampaignMutation.generated";
import {
  IListNewsletterCampaignsQuery,
  IListNewsletterCampaignsQueryVariables,
} from "./graphql/__generated__/ListNewsletterCampaignsQuery.generated";
import SetCountriesOnEntitiesButton from "../newsletter/SetCountriesOnEntitesButton";
import * as palette from "@govlaunch/palette";
import PageIcon from "../../icons/PageIcon";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";

type TNewsletterCampaigns = IListNewsletterCampaignsQuery["listNewsletterCampaigns"][0];

export default function ListCampaigns() {
  const [archiveNewsletterCampaign] = useMutation<
    IArchiveNewsletterCampaignMutation,
    IArchiveNewsletterCampaignMutationVariables
  >(ArchiveNewsletterCampaignMutation, {
    refetchQueries: [
      {
        query: ListNewsletterCampaignsQuery,
      },
    ],
    awaitRefetchQueries: true,
  });

  const { loading, data } = useQuery<IListNewsletterCampaignsQuery, IListNewsletterCampaignsQueryVariables>(
    ListNewsletterCampaignsQuery,
    {
      fetchPolicy: "network-only",
    },
  );

  return (
    <div>
      <div
        css={{
          padding: 20,
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h1
            css={{
              fontWeight: "bold",
              fontSize: 21,
              margin: 0,
            }}
          >
            <PageIcon icon={faEnvelopeOpen} />Campaigns List 
          </h1>
          <div
            css={{
              marginLeft: "auto",
            }}
          >
            <SetCountriesOnEntitiesButton />
          </div>
        </div>

        <Divider />

        <Table
          dataSource={data?.listNewsletterCampaigns || []}
          loading={loading}
          bordered={true}
          size="middle"
          rowKey="_id"
          scroll={{
            x: true,
          }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
          }}
        >
          <Column<TNewsletterCampaigns>
            title="Name"
            dataIndex="name"
            render={(name, campaign) => {
              return <Link to={`/campaigns/${campaign._id}`}>{name}</Link>;
            }}
          />

          <Column<TNewsletterCampaigns>
            title="Stories Count"
            dataIndex="dynamicContent.storiesCount"
            render={(_, campaign) => {
              return campaign.dynamicContent.storiesCount;
            }}
          />

          <Column<TNewsletterCampaigns>
            title="Projects Count"
            dataIndex="dynamicContent.projectsCount"
            render={(_, campaign) => {
              return campaign.dynamicContent.projectsCount;
            }}
          />

          <Column<TNewsletterCampaigns>
            title="Top Collections"
            dataIndex="dynamicContent.topCollections"
            render={(_, campaign) => {
              return campaign.dynamicContent.topCollections;
            }}
          />

          <Column<TNewsletterCampaigns>
            title="Other Collections"
            dataIndex="dynamicContent.otherCollections"
            render={(_, campaign) => {
              return campaign.dynamicContent.otherCollections;
            }}
          />

          <Column<TNewsletterCampaigns>
            title="Actions"
            dataIndex="actions"
            render={(_, campaign) => {
              return (
                <div
                  css={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, max-content)",
                    columnGap: 20,
                  }}
                >
                  <div>
                    <Link to={`/campaigns/preview/${campaign._id}`}>Preview</Link>
                  </div>

                  <div
                    css={{
                      color: palette.red,
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      if (!confirm(`Do you want to archive: ${campaign.name}?`)) {
                        return;
                      }

                      try {
                        await archiveNewsletterCampaign({
                          variables: {
                            id: campaign._id,
                          },
                        });

                        notification.success({
                          message: "Ok—campaign archived!",
                        });
                      } catch (err) {
                        console.log(err);

                        notification.error({
                          message: "Couldn't archive this campaign...",
                        });
                      }
                    }}
                  >
                    Archive
                  </div>
                </div>
              );
            }}
          />
        </Table>
      </div>
    </div>
  );
}
