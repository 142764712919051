import React from "react";
import { IGetSponsorshipQuery } from "../../../queries/__generated__/GetSponsorship.generated";
import { Mutation } from "react-apollo";
import EditSponsorship from "../../../mutations/EditSponsorship";
import {
  IEditSponsorshipMutation,
  IEditSponsorshipMutationVariables,
} from "../../../mutations/__generated__/EditSponsorship.generated";
import { Form } from "react-final-form";
import convertValuesToInitialVariables from "./convertValuesToInitialVariables";
import validate from "../../../validate";
import validationSchema from "./validationSchema";
import convertValuesToVariables from "./convertValuesToVariables";
import SponsorshipForm from "./SponsorshipForm";
import { notification } from "antd";
import { useNavigate } from "react-router";

interface IEditSponsorshipFormProps {
  sponsorship: NonNullable<IGetSponsorshipQuery["sponsorship"]>;
}

export default function EditSponsorshipForm({ sponsorship }: IEditSponsorshipFormProps) {
  const navigate = useNavigate();

  return (
    <Mutation<IEditSponsorshipMutation, IEditSponsorshipMutationVariables> mutation={EditSponsorship}>
      {(editSponsorship) => {
        return (
          <Form
            subscription={{
              submitting: true,
            }}
            initialValues={convertValuesToInitialVariables(sponsorship)}
            keepDirtyOnReinitialize={true}
            validate={validate(validationSchema)}
            onSubmit={(values) => {
              return editSponsorship({
                variables: {
                  id: sponsorship._id,
                  sponsorship: convertValuesToVariables(values),
                },
              }).then(() => {
                notification.open({
                  type: "success",
                  message: "Sponsorship editted!",
                  description: `Sponsorship editted successfully!`,
                });

                navigate(`/sponsorships`);
              });
            }}
          >
            {({ handleSubmit, submitting }) => (
              <SponsorshipForm submitting={submitting} onSubmit={handleSubmit} submitButtonText="Edit Sponsorship" />
            )}
          </Form>
        );
      }}
    </Mutation>
  );
}
