import React from "react";
import { useToggle } from "react-use";
import VisibilitySensor from "react-visibility-sensor";
import { IGroupFieldsFragment } from "../../../queries/fragments/__generated__/GroupFields.generated";
import { Margin } from "../../../spacings";
import EditGroupMembersModal from "../../teamManagement/modals/EditGroupMembersModal";
import UsersTable from "../../users/tables/UsersTable";

interface IGroupMembersTab {
  group: IGroupFieldsFragment;
  refetch: () => any;
  loading: boolean;
  fetchMore: (props: any) => any;
}

const GroupMembersTab: React.FunctionComponent<IGroupMembersTab> = ({ group, refetch, loading, fetchMore }) => {
  const [showTeamBuilder, toggleTeamBuilder] = useToggle(false);

  return (
    <div
      css={{
        padding: "0 15px",
      }}
    >
      <EditGroupMembersModal
        group={group}
        visible={showTeamBuilder}
        onRequestClose={toggleTeamBuilder}
        onSaveChanges={() => {
          toggleTeamBuilder();
          refetch();
        }}
      />

      <Margin mb={12}>
        <a
          href="#"
          onClick={(event) => {
            event.preventDefault();
            toggleTeamBuilder();
          }}
        >
          Edit members
        </a>
      </Margin>

      <UsersTable
        users={group.members && group.members.items ? group.members.items : []}
        onDeleteUser={() => {
          refetch();
        }}
        onBanUser={() => {
          refetch();
        }}
        loading={loading}
      />

      {group.members && group.members.pageInfo && (
        <VisibilitySensor
          active={loading === false && group.members.pageInfo.hasNextPage!}
          partialVisibility={true}
          onChange={(isActive) => {
            return (
              isActive &&
              fetchMore({
                variables: {
                  membersCursor: group.members.pageInfo!.endCursor,
                },
                updateQuery: (current: any, { fetchMoreResult }: any) => {
                  return {
                    groupBySlug: {
                      ...current.groupBySlug,
                      members: {
                        ...fetchMoreResult.groupBySlug.members,
                        items: [...current.groupBySlug.members.items, ...fetchMoreResult.groupBySlug.members.items],
                      },
                    },
                  };
                },
              })
            );
          }}
        />
      )}
    </div>
  );
};

export default GroupMembersTab;
