import React from "react";
import { Mutation } from "react-apollo";
import DeclineProjectVerification from "../../../mutations/DeclineProjectVerification";
import {
  IDeclineProjectVerificationMutation,
  IDeclineProjectVerificationMutationVariables,
} from "../../../mutations/__generated__/DeclineProjectVerification.generated";
import VerifyProject from "../../../mutations/VerifyProject";
import {
  IVerifyProjectMutation,
  IVerifyProjectMutationVariables,
} from "../../../mutations/__generated__/VerifyProject.generated";

type TVerifyOrDeclineChildrenState = {
    isVerifying: boolean;
    verify: () => void;
    isDeclining: boolean;
    decline: () => void;
  };
  
  interface IVerifyOrDeclineProps {
    projectId: string;
    governmentId: string;
    onCompleted: () => void;
    children: (state: TVerifyOrDeclineChildrenState) => any;
  }
  
export default  function VerifyOrDecline({ projectId, governmentId, onCompleted, children }: IVerifyOrDeclineProps) {
    return (
      <Mutation<IVerifyProjectMutation, IVerifyProjectMutationVariables>
        mutation={VerifyProject}
        onCompleted={() => onCompleted()}
        refetchQueries={["AllGovernmentVerificationRequests"]}
        awaitRefetchQueries={true}
      >
        {(verifyProject, { loading: isVerifying }) => (
          <Mutation<IDeclineProjectVerificationMutation, IDeclineProjectVerificationMutationVariables>
            mutation={DeclineProjectVerification}
            onCompleted={() => onCompleted()}
            refetchQueries={["AllGovernmentVerificationRequests"]}
            awaitRefetchQueries={true}
          >
            {(declineProjectVerification, { loading: isDeclining }) => {
              return children({
                isVerifying,
  
                verify: () => {
                  verifyProject({
                    variables: {
                      projectId,
                      governmentId,
                    },
                  });
                },
  
                isDeclining,
  
                decline: () => {
                  return declineProjectVerification({
                    variables: {
                      projectId,
                      governmentId,
                    },
                  });
                },
              });
            }}
          </Mutation>
        )}
      </Mutation>
    );
  }
  