import { useEffect, useState } from "react";
import useWindowDimensions from "./useWindowDimensions";

export default function useIsDesktop() {
  const resolutionThreshold = 800;
  const { width } = useWindowDimensions();
  const check = width ? width > resolutionThreshold : true;
  const [isDesktop, setIsDesktop] = useState(check);

  useEffect(() => {
    if (!isDesktop && width && width > resolutionThreshold) {
      setIsDesktop(true);
    }

    if (isDesktop && width && width <= resolutionThreshold) {
      setIsDesktop(false);
    }
  }, [width]);

  return isDesktop;
}
