import { Button, notification } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import { useToggle } from "react-use";
import { ICreateSaleInput } from "../../../types/types";
import CollectionSponsoringForm from "./CollectionSponsoringForm";
import CreateCollectionSponsoringSale from "./graphql/mutations/CreateCollectionSponsoringSale";
import {
  ICreateCollectionSponsoringSaleMutation,
  ICreateCollectionSponsoringSaleMutationVariables,
} from "./graphql/mutations/__generated__/CreateCollectionSponsoringSale.generated";

export default function AddCollectionSponsoring() {
  const [isOpen, toggle] = useToggle(false);
  const [addSponsorship, { error, loading }] = useMutation<
    ICreateCollectionSponsoringSaleMutation,
    ICreateCollectionSponsoringSaleMutationVariables
  >(CreateCollectionSponsoringSale, {
    refetchQueries: ["ListCollectionSponsoringSales", "AllCollectionSponsoringSalesCounts"],
  });

  return (
    <>
      <CollectionSponsoringForm
        isOpen={isOpen}
        onClose={toggle}
        error={error?.message.replace("GraphQL error: ", "")}
        submitting={loading}
        onSubmit={async (sponsoring: ICreateSaleInput) => {
          await addSponsorship({
            variables: {
              input: {
                description: sponsoring.description,
                includedCollections: sponsoring.includedCollections,
                expirationDate: sponsoring.expirationDate ? sponsoring.expirationDate.format("YYYY-MM-DD") : null,
                paymentSchedule: sponsoring.paymentSchedule,
                price: sponsoring.price,
                sponsorshipLevel: sponsoring.sponsorshipLevel,
                type: sponsoring.type,
                vendorId: sponsoring.vendorId,
              },
            },
          }).then((result) => {
            if (result && result.data && result.data.createCollectionSponsoringSale) {
              notification.open({
                type: "success",
                message: "Collection sponsoring added!",
                description: `Collection sponsoring ${result.data.createCollectionSponsoringSale.description} added successfully!`,
              });
            }
          });

          toggle();
        }}
      />

      <Button onClick={toggle}>Add Collection Sponsorship</Button>
    </>
  );
}
