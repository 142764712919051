import gql from "graphql-tag";

export default gql`
  query GetReport($slug: String!) {
    reportBySlug(slug: $slug) {
      _id
      title
      slug
      description
      images
      details
      governments {
        _id
        name
        logo
        isInnovator
      }
      unpublishedAt
      archivedAt
      createdAt
      updatedAt
      featured
      locations
    }
  }
`;
