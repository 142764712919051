import { Typography } from "antd";
import { CarouselContext, CarouselProvider, Slide, Slider, Image, Dot } from "pure-react-carousel";
import React, { useContext, useEffect, useState } from "react";
import { useField } from "react-final-form";
import { Margin } from "../../../spacings";
import FilestackUploadField from "../../form/fields/FilestackUploadField";
import TextField from "../../form/fields/TextField";
import ColorPickerField from "../../form/fields/ColorPickerField";

interface ICarouselSideContentFieldsProps {
  side: "leftBox" | "rightBox";
}

export default function CarouselSideContentFields({ side }: ICarouselSideContentFieldsProps) {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const { input: images } = useField(`${side}.images`);

  return (
    <div css={{
 marginTop: 16 
}}
    >
      {(images.value || []).length > 0 && (
        <TextField fieldName={`${side}.images[${currentSlide}].alt`} label="Image alt text" />
      )}

      <div>
        <Margin mt={8}>
          <CarouselProvider
            isIntrinsicHeight={true}
            naturalSlideHeight={0}
            naturalSlideWidth={0}
            totalSlides={(images.value || []).length}
          >
            <CarouselSlides images={images.value || []} onChangeSlide={setCurrentSlide} />

            <div
              css={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {(images.value || []).map((_: any, index: number) => (
                <Dot
                  slide={index}
                  key={index}
                  css={{
                    marginTop: 12,
                    marginRight: 8,
                    borderRadius: "100%",
                    width: 16,
                    height: 16,
                    border: 0,
                    background: "#E3E6EB",
                    "&:disabled": {
                      background: "#96A3B5",
                    },
                  }}
                />
              ))}
            </div>
          </CarouselProvider>
        </Margin>

        <Margin mt={8}>
          <FilestackUploadField
            fieldName={`${side}.images`}
            uploadButtonText="Add Image to carousel"
            recommendedDimensions="375x460px"
            uploadMultiple={true}
            showImage={false}
          />
        </Margin>

        <Margin mt={8}>
          <TextField fieldName={`${side}.text`} label="Text" placeholder="Type text..." />
        </Margin>

        <Margin mt={8}>
          <TextField fieldName={`${side}.textHref`} label="Text URL" placeholder="Type text URL..." />
        </Margin>

        <Margin mt={8}>
          <ColorPickerField
            fieldName={`${side}.textHrefHoverColor`}
            label="Text link hover color"
            placeholder="Type text link hover color..."
          />
        </Margin>

        <Margin mt={8}>
          <TextField fieldName={`${side}.buttonText`} label="Button text" placeholder="Type button text..." />
          <Typography.Text
            type="warning"
            css={{
              display: "block",
            }}
          >
            Leave empty to hide the button
          </Typography.Text>
        </Margin>

        <Margin mt={8}>
          <TextField fieldName={`${side}.buttonHref`} label="Button URL" placeholder="Type button URL..." />
        </Margin>

        <Margin mt={8}>
          <ColorPickerField
            fieldName={`${side}.buttonBackgroundColor`}
            label="Button background color"
            placeholder="Type button background color..."
          />
        </Margin>

        <Margin mt={8}>
          <ColorPickerField
            fieldName={`${side}.buttonFontColor`}
            label="Button font color"
            placeholder="Type button font color..."
          />
        </Margin>
      </div>
    </div>
  );
}

interface ICarouselSlidesProps {
  images: { src: string; alt: string }[];
  onChangeSlide: (slide: number) => void;
}

function CarouselSlides({ images, onChangeSlide }: ICarouselSlidesProps) {
  const carouselContext = useContext(CarouselContext);

  useEffect(() => {
    carouselContext.subscribe(() => {
      onChangeSlide(carouselContext.state.currentSlide);
    });
  }, [carouselContext]);

  return (
    <div>
      <Slider>
        {images.map((image, index) => (
          <Slide index={index} key={image.src}>
            <div
              css={{
                position: "relative",
                display: "flex",
              }}
            >
              <Image
                src={image.src}
                hasMasterSpinner={true}
                css={{
                  maxHeight: 460,
                  objectFit: "cover",
                }}
              />
            </div>
          </Slide>
        ))}
      </Slider>
    </div>
  );
}
