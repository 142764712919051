import React, { ReactElement } from "react";

interface IFrontendSizedGridProps {
  children: ReactElement | ReactElement[];
}

export default function FrontendSizedGrid({ children }: IFrontendSizedGridProps) {
  return (
    <div
      css={{
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 128,
        //width: "100%",
        maxWidth: "1224px", // 1224px is the frontend page width
      }}
    >
      {children}
    </div>
  );
}
