import React from "react";
import { Button, notification } from "antd";
import { Mutation } from "react-apollo";
import {
  IAutopopulateInterestsMutation,
  IAutopopulateInterestsMutationVariables,
} from "../../mutations/__generated__/AutopopulateInterests.generated";
import AutopopulateInterests from "../../mutations/AutopopulateInterests";

export default function AutopopulateInterestsButton() {
  return (
    <Mutation<IAutopopulateInterestsMutation, IAutopopulateInterestsMutationVariables> mutation={AutopopulateInterests}>
      {(autopopulateInterests) => (
        <Button
          size="large"
          type="dashed"
          onClick={() =>
            autopopulateInterests().then((result) => {
              const updatedUsersCount =
                result.data && result.data.autopopulateInterests ? result.data.autopopulateInterests : 0;

              notification.open({
                type: "success",
                message: "Interests updated!",
                description: `${updatedUsersCount} users interests updated!`,
              });
            })
          }
          css={{
            marginLeft: 10,
          }}
        >
          Populate Interests
        </Button>
      )}
    </Mutation>
  );
}
