import React, { ReactElement } from "react";
import * as palette from "@govlaunch/palette";
import RemoveBlockButton from "./RemoveBlockButton";
import { IBlockLayout } from "../blocks/blockProps";
import { DragOutlined } from "@ant-design/icons";

interface IBlockLayoutHeaderProps {
  title?: string;
  block: IBlockLayout;
  onRemoveBlock: () => void;
  showDragnDrop?: boolean;
  children?: ReactElement | ReactElement[];
}

export default function BlockLayoutHeader({ showDragnDrop, onRemoveBlock, children }: IBlockLayoutHeaderProps) {
  return (
    <div
      css={{
        position: "absolute",
        right: "4px",
        top: "4px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        background: palette.white,
        borderRadius: "2px",
        zIndex: 1,
      }}
    >
      {showDragnDrop && (
        <DragOutlined
          style={{
            fontSize: "22px",
            color: "#aaaaaa",
            marginLeft: "4px",
            marginRight: "2px",
          }}
          css={{
            "&:hover": {
              opacity: 0.6,
            },
          }}
        />
      )}

      <div
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
      >
        {children}
      </div>

      <div
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
      >
        <RemoveBlockButton onRemoveBlock={onRemoveBlock} />
      </div>
    </div>
  );
}
