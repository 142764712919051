import React from "react";
import { useQuery } from "react-apollo";
import GetExternalLinks from "../../../../queries/GetExternalLinks";
import {
  IGetExternalLinksQuery,
  IGetExternalLinksQueryVariables,
} from "../../../../queries/__generated__/GetExternalLinks.generated";
import BlockLayoutContainer from "../../blockslayout/BlockLayoutContainer";
import BlockLayoutHeader from "../../blockslayout/BlockLayoutHeader";
import { IBlockLayout, TBlockLayoutData } from "../blockProps";
import CenteredLoadingSpinner from "../CenteredLoadingSpinner";
import * as palette from "@govlaunch/palette";
import { Box } from "@govlaunch/web";
import BlockContentTitle from "../BlockContentTitle";
import BlockLayoutHeaderSettings from "../../blockslayout/BlockLayoutHeaderSettings";

interface IExternalLinksBlockProps {
  block: IBlockLayout;
  onRemoveBlock: () => void;
  saveBlockData: (block: IBlockLayout, blockData: TBlockLayoutData) => void;
}

export default function ExternalLinksBlock({ block, onRemoveBlock, saveBlockData }: IExternalLinksBlockProps) {
  return (
    <BlockLayoutContainer block={block}>
      <BlockLayoutHeader block={block} onRemoveBlock={onRemoveBlock}>
        <BlockLayoutHeaderSettings block={block} saveBlockData={saveBlockData} />
      </BlockLayoutHeader>
      {block.blockData["titleVisible"] && <BlockContentTitle block={block} />}
      <ExternalLinksBlockData block={block} />
    </BlockLayoutContainer>
  );
}

interface IExternalLinksBlockDataProps {
  block: IBlockLayout;
}

function ExternalLinksBlockData({ block }: IExternalLinksBlockDataProps) {
  const { data, loading } = useQuery<IGetExternalLinksQuery, IGetExternalLinksQueryVariables>(GetExternalLinks, {
    variables: {
      search: null,
      limit: block.blockData.itemLimit || 10,
      cursor: null,
    },
    fetchPolicy: "network-only",
  });

  if (!data || loading) {
    return <CenteredLoadingSpinner />;
  }

  const externalLinks = data.externalLinks?.items || [];

  return (
    <div
      css={{
        width: "100%",
      }}
    >
      {externalLinks.length > 0 &&
        externalLinks.map((externalLink) => (
          <div
            key={externalLink._id}
            css={{
              "&:not(:first-of-type)": {
                marginTop: "10px",
              },
            }}
          >
            <ExternalLinkRow externalLink={externalLink} />
          </div>
        ))}
      {externalLinks.length === 0 && <div>No data...</div>}
    </div>
  );
}

type TExternalLink = NonNullable<NonNullable<IGetExternalLinksQuery["externalLinks"]>["items"]>[0];

interface IExternalLinkRowProps {
  externalLink: TExternalLink;
}

function ExternalLinkRow({ externalLink }: IExternalLinkRowProps) {
  return (
    <Box
      lineHeight="24px"
      fontSize="16px"
      fontWeight={[400, 400, 600, 600]}
      color={palette.primary}
      fontFamily="'proxima-nova', Helvetica, Arial, sans-serif"
    >
      {externalLink.title}{" "}
      <Box as="span" fontSize="12px" fontWeight={400} color="gray.900">
        {externalLink.source}
      </Box>
    </Box>
  );
}
