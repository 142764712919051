import gql from "graphql-tag";
import GovernmentProjectFields from "./GovernmentProjectFields";
import GroupProjectFields from "./GroupProjectFields";
import UserFields from "./UserFields";
import VendorProjectFields from "./VendorProjectFields";

export default gql`
  fragment GroupFields on Group {
    _id
    slug
    name
    about
    website
    location
    kind
    membersCount
    owner {
      _id
      slug
      name
      logo
      isDisruptor
    }
    previousOwner {
      _id
      slug
      name
      logo
      isDisruptor
    }
    socialMedia {
      instagram
      youtube
      facebook
      linkedin
      twitter
    }
    deletedAt
    deletedBy {
      ... on PendingUser {
        _id
        slug
        fullName
      }
      ... on VendorUser {
        _id
        slug
        fullName
      }
      ... on GovernmentUser {
        _id
        slug
        fullName
      }
    }
    products {
      _id
      slug
      name
      logo
      isDisruptor
      canBeDisruptor
      status
      storiesCount
      projectsCount
      governmentsUsingCount
      tier
      archivedAt
      archivedBy {
        ... on PendingUser {
          _id
          slug
          fullName
        }
        ... on VendorUser {
          _id
          slug
          fullName
        }
        ... on GovernmentUser {
          _id
          slug
          fullName
        }
      }
      featuredAt
      createdAt
      updatedAt
      company {
        _id
        name
        slug
        logo
        isDisruptor
        archivedAt
      }
      tags {
        _id
        name
      }
    }
    outsideProducts {
      _id
      name
      url
    }
    projects(cursor: $projectsCursor) {
      items {
        _id
        ...GovernmentProjectFields
        ...GroupProjectFields
        ...VendorProjectFields
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    newsworthy {
      title
      siteName
      favicon
      url
      description
      addedAt
    }
    resources {
      ... on LinkResource {
        url
        title
        addedAt
        type
      }
      ... on DownloadResource {
        url
        title
        filename
        addedAt
        type
      }
      ... on TextResource {
        title
        body
        addedAt
        type
      }
    }
    governments {
      _id
      name
      slug
      logo
    }
    vendors {
      _id
      name
      slug
      logo
    }
    awards {
      _id
      name
    }
    tags {
      _id
      name
    }
    logo
    visibility
    members(cursor: $membersCursor) {
      items {
        ...UserFields
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    posts(cursor: $postsCursor) {
      items {
        _id
        body
        audience
        featuredAt
        createdAt
        deletedAt
        author {
          ... on PendingUser {
            _id
            fullName
          }
          ... on GovernmentUser {
            _id
            fullName
          }
          ... on VendorUser {
            _id
            fullName
          }
        }
        group {
          _id
          name
        }
        attachments {
          mimeType
          filename
          sizeInBytes
          url
        }
        links {
          addedAt
          description
          title
          favicon
          siteName
          url
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  ${GovernmentProjectFields}
  ${GroupProjectFields}
  ${UserFields}
  ${VendorProjectFields}
`;
