import { CSSReset, theme, ThemeProvider } from "@govlaunch/web";
import { notification } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import { Form } from "react-final-form";
import CreateLandingPageConfig from "../../mutations/CreateLandingPageConfig";
import {
  ICreateLandingPageConfigMutation,
  ICreateLandingPageConfigMutationVariables,
} from "../../mutations/__generated__/CreateLandingPageConfig.generated";
import { appendPlusBlock } from "./blocks/blockProps";
import LandingPageForm from "./LandingPageForm";
import { convertValuesToVariables } from "./utils";
import landingPageValidationSchema from "./landingPageValidationSchema";
import validate from "../../validate";
import { useNavigate } from "react-router";
import getDefaultBlocksLayout from "./blockslayout/defaultBlocksLayout";

export default function NewLandingPageForm() {
  const navigate = useNavigate();
  const [createLandingPageConfig] = useMutation<
    ICreateLandingPageConfigMutation,
    ICreateLandingPageConfigMutationVariables
  >(CreateLandingPageConfig);

  const submitHandler = async (values: any) => {
    const sanitizedValues = convertValuesToVariables(values);
    const result = await createLandingPageConfig({
      variables: {
        landingPageConfig: sanitizedValues,
      },
    });

    if (result && result.data && result.data.createLandingPageConfig && result.data.createLandingPageConfig._id) {
      notification.success({
        message: "Success!",
        description: "Landing page config saved successfully",
      });

      navigate(`/landingPage/${result.data.createLandingPageConfig._id}`);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Form
        keepDirtyOnReinitialize={true}
        initialValues={{
          name: "",
          active: false,
          headerBanner: null,
          blocks: appendPlusBlock(getDefaultBlocksLayout()),
          compact: false,
          allowOverlap: false,
        }}
        validate={validate(landingPageValidationSchema)}
        onSubmit={async (values) => {
          await submitHandler(values);
        }}
        subscription={{
          submitting: true,
          submitError: true,
          values: true,
        }}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <LandingPageForm onSubmit={handleSubmit} submitting={submitting} submitError={submitError} />
        )}
      </Form>
    </ThemeProvider>
  );
}
