import React, { FunctionComponent } from "react";
import { IProcessStatusType } from "../../../../types/types";
import { useLocation } from "react-router";
import queryString from "query-string";
import ManageOnboardings from "./ManageOnboardings";
import TableSearch from "../../../components/table/TableSearch";

interface IAllOnboardingsWithSearchProps {
  filterByProcessStatus?: IProcessStatusType | null;
  historyOrigin?: string;
}

const OnboardingsWithSearch: FunctionComponent<IAllOnboardingsWithSearchProps> = ({
  filterByProcessStatus,
  historyOrigin = "/onboardings",
  ...props
}) => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div css={{
 marginBottom: 20 
}}
      >
        <TableSearch historyOrigin={historyOrigin} query={query} inputPlaceholder="Search for onboarding users..." />
      </div>

      <ManageOnboardings
        filterByProcessStatus={filterByProcessStatus}
        search={(query.search as string) || ""}
        {...props}
      />
    </>
  );
};

export default OnboardingsWithSearch;
