import React from "react";
import { IGetTagBySlugQuery } from "../../../queries/__generated__/GetTagBySlug.generated";
import { notification } from "antd";
import UpdateTagById from "../../../mutations/UpdateTagById";
import {
  IUpdateTagByIdMutation,
  IUpdateTagByIdMutationVariables,
} from "../../../mutations/__generated__/UpdateTagById.generated";
import { FORM_ERROR } from "final-form";
import * as palette from "@govlaunch/palette";
import { convertTagFormValuesToVariables } from "../convertTagFormValuesToVariables";
import TagForm, { ITagFormValues } from "./TagForm";
import { useNavigate } from "react-router";
import { useMutation } from "react-apollo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

interface ITagEditProps {
  tag: NonNullable<IGetTagBySlugQuery["tag"]>;
}

export default function TagEdit({ tag }: ITagEditProps) {
  const navigate = useNavigate();

  const [updateTagById] = useMutation<IUpdateTagByIdMutation, IUpdateTagByIdMutationVariables>(UpdateTagById);

  return (
    <div
      css={{
        padding: 15,
        overflow: "auto",
      }}
    >
      <div
        css={{
          paddingBottom: 8,
        }}
      >
        <a
          css={{
            color: palette.primary,
            cursor: "pointer",
          }}
          onClick={() => navigate("/tags")}
        >
          <span css={{
 marginRight: 5 
}}
          >
            <FontAwesomeIcon icon={faLessThan} size="sm" />
          </span>
          All Tags
        </a>
      </div>

      <div
        css={{
          maxWidth: 600,
          border: `1px solid ${palette.lightestGray}`,
          borderRadius: 5,
          backgroundColor: palette.white,
        }}
      >
        <TagForm
          onSubmit={async (values: ITagFormValues) => {
            try {
              const result = await updateTagById({
                variables: {
                  tagId: tag._id,
                  ...convertTagFormValuesToVariables(values),
                },
              });

              if (result && result.data && result.data.updateTagById) {
                notification.success({
                  message: "Tag saved!",
                  description: `Tag saved successfully!`,
                });

                navigate(`/tags?search=${result.data.updateTagById.name}`);
              }
            } catch (err) {
              if (/You're not allowed to delete or update this/i.test(err.message)) {
                return {
                  [FORM_ERROR]: err.message.replace("GraphQL error: ", ""),
                };
              }

              notification.error({
                type: "error",
                message: "Could not save tag",
                description: err.message,
              });
            }
          }}
          initialValues={{
            name: tag.name,
            types: tag.types,
            relatedTags: tag.relatedTags,
            publish: false,
          }}
          showPublishField={false}
          submitLabel="Save"
        />
      </div>
    </div>
  );
}
