import queryString from "query-string";
import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { IStoryAudience, IStoryStatus } from "../../../../types/types";
import GetStoriesQuery from "../../../queries/GetStoriesQuery";
import { IGetStoriesQuery, IGetStoriesQueryVariables } from "../../../queries/__generated__/GetStoriesQuery.generated";
import StoriesTable from "../paginated/StoriesTable";
import TableAddStateToQueryString from "../../../components/table/TableAddStateToQueryString";
import { useNavigate } from "react-router";
import { parseState } from "../../../utils/tablesUtils";
import { SortOrder } from "antd/lib/table/interface";
import TablePagination, { DEFAULT_CURSOR, ITablePaginationState } from "../../../components/table/TablePagination";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import TableTitle from "../../../components/table/TableTitle";
import TableOnSearch from "../../../components/table/TableOnSearch";

export interface IStoriesTableState {
  sortColumnKey: string;
  sortColumnOrder: SortOrder;
  featured: boolean | null | undefined;
  audience: IStoryAudience | null | undefined;
  status: IStoryStatus | null | undefined;
  country: string | null;
  cursor: ITablePaginationState;
}

interface IManageStoriesProps {
  search: string | null;
  tab: string | null;
  filterByTag?: any;
  historyOrigin?: string;
  props: {
    children?: React.ReactNode;
  };
}

export default function ManageStories({
  search,
  tab,
  filterByTag = null,
  historyOrigin = "/stories",
  props,
}: IManageStoriesProps) {
  const navigate = useNavigate();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    sortColumnKey: "",
    sortColumnOrder: "ascend",
    featured: null,
    audience: null,
    status: null,
    country: null,
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<IStoriesTableState>(initialFiltersState);

  const { data, loading, refetch } = useQuery<IGetStoriesQuery, IGetStoriesQueryVariables>(GetStoriesQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      search: (query.search || "").toString(),
      tab,
      tags: filterByTag ? [filterByTag] : null,
      featured: initialFiltersState.featured || null,
      audience: initialFiltersState.audience || null,
      status: initialFiltersState.status || null,
      sort:
        getSortBy({
          order: initialFiltersState.sortColumnOrder,
          columnKey: initialFiltersState.sortColumnKey,
        }) || null,
      country: null,
      cursor: calculateAndEncodeCursor(state.cursor),
    },
  });

  const stories = (data && data.allStories && data.allStories.items) || [];
  const totalCount = data && data.allStories && data.allStories.pageInfo ? data.allStories.pageInfo.totalCount || 0 : 0;

  return (
    <>
      <TableAddStateToQueryString tableState={state} historyOrigin={historyOrigin} query={query} />
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
 page: 1, size: state.cursor.size 
},
            });
          }
        }}
      />
      <StoriesTable
        stories={stories}
        refetch={refetch}
        loading={loading}
        state={state}
        title={() => (
          <TableTitle
            title="Stories"
            count={totalCount}
            loading={loading}
            onResetFilters={() => {
              setState({
                sortColumnKey: "",
                sortColumnOrder: "ascend",
                featured: null,
                audience: null,
                status: null,
                country: null,
                cursor: {
 page: 1, size: state.cursor.size 
},
              });

              navigate(`${historyOrigin}?tab=${query.tab || ""}&search=${search}`);
            }}
          />
        )}
        footer={() => (
          <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
        )}
        onChange={(_: any, filters: any, sorter: any) => {
          let featured: boolean | null = null;
          let audience: IStoryAudience | null = null;
          let status: IStoryStatus | null = null;
          let country: string | null = null;

          if (filters.title && filters.title[0] === "featured") {
            featured = true;
          }

          if (filters.audience && [IStoryAudience.Public, IStoryAudience.Community].includes(filters.audience[0])) {
            audience = filters.audience[0];
          }

          if (
            filters.status &&
            [IStoryStatus.Approved, IStoryStatus.Proposed, IStoryStatus.Published, IStoryStatus.Unpublished].includes(
              filters.status[0],
            )
          ) {
            status = filters.status[0];
          }

          if (filters.country) {
            country = filters.country[0];
          }

          setState({
            sortColumnKey: sorter.columnKey,
            sortColumnOrder: sorter.order,
            featured: featured,
            audience: audience,
            status: status,
            country: country,
            cursor: {
 page: 1, size: state.cursor.size 
},
          });
        }}
        {...props}
      />
    </>
  );
}

function getSortBy(sorter: any) {
  if (sorter && sorter.columnKey) {
    const direction = sorter.order === "descend" ? "descending" : "ascending";

    if (sorter.columnKey === "createdAt") {
      return `_id:${direction}`;
    }

    if (sorter.columnKey === "status") {
      return `status:${direction}`;
    }
  }

  return null;
}
