import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing, SpacingBetween } from "@govlaunch/spacers";
import { Popover, Table } from "antd";
import { TableProps } from "antd/lib/table";
import { SorterResult, TableCurrentDataSource, TablePaginationConfig } from "antd/lib/table/interface";
import React, { SVGProps } from "react";
import { Toggle } from "react-powerplug";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { IOnboarding, IProcessStatusType, IWorkPlaceType } from "../../../types/types";
import DotdotdotIcon from "../../components/icons/DotdotdotIcon";
import Impersonate from "../debugging/Impersonate";
import { COUNTRIES } from "../governments/Countries";
import AssignUserFlow from "../teamManagement/AssignUserFlow";
import GovernmentById from "../users/onboarding/fields/GovernmentById";
import { getWorkplaceTypeFriendlyName } from "../users/onboarding/utils";
import RemoveVerification from "../users/RemoveUserVerification";
import VerifyUser from "../users/VerifyUser";
import { IOnboardingsTableState } from "./tables/ManageOnboardings";

interface IOnboardingsTableProps<T> {
  loading?: boolean | null;
  onboardings: T[];
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, any[] | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>,
  ) => void;
  filterByProcessStatus?: IProcessStatusType | null;
  sorter?: boolean;
  state?: IOnboardingsTableState;
  footer?: TableProps<any>["footer"];
  title?: TableProps<any>["title"];
  refetch?: () => any;
}

export default function OnboardingsTable({
  loading = null,
  onboardings,
  title,
  footer,
  onChange = () => {},
  state,
  sorter = false,
  refetch,
}: IOnboardingsTableProps<any>) {
  return (
    <Table<IOnboarding>
      rowKey="_id"
      loading={loading || false}
      onChange={onChange}
      style={{
        backgroundColor: palette.white,
      }}
      scroll={{
        x: true,
      }}
      footer={footer}
      title={title}
      dataSource={onboardings}
      columns={[
        {
          title: "Name",
          dataIndex: "user",
          sorter,
          key: "user.fullName",
          sortOrder:
            state && state.sorter && state.sorter.columnKey === "user.fullName" ? state.sorter.order : undefined,
          render: (user) => {
            return (
              <Link
                to={`/users/${user.slug}/onboarding`}
                css={{
                  width: "1%",
                }}
              >
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ProgressiveImage src={user.avatar!} placeholder="">
                    {(src: string, loading: boolean) => (
                      <ProfilePicture
                        image={loading ? null : src}
                        innovator={user.__typename === "GovernmentUser" ? user.isInnovator : false}
                        disruptor={user.__typename === "VendorUser" ? user.isDisruptor : false}
                        name={user.fullName}
                        size={32}
                        css={{
                          whiteSpace: "nowrap",
                        }}
                      />
                    )}
                  </ProgressiveImage>

                  <Spacing left={10}>{user.fullName}</Spacing>

                  {user.__typename === "PendingUser" && user.verified && (
                    <Spacing
                      left={5}
                      inline={true}
                      flex={true}
                      style={{
                        color: "#48A0D9",
                      }}
                    >
                      <CheckIcon />
                    </Spacing>
                  )}
                </div>
              </Link>
            );
          },
        },
        {
          title: "Workplace Type",
          dataIndex: "workingFor",
          sorter,
          key: "workingFor",
          sortOrder: state && state.sorter && state.sorter.columnKey === "workingFor" ? state.sorter.order : undefined,
          render: (value) => {
            return <Spacing left={10}>{getWorkplaceTypeFriendlyName(value as IWorkPlaceType)}</Spacing>;
          },
        },
        {
          title: "Country",
          dataIndex: "country",
          sorter,
          key: "country",
          sortOrder: state && state.sorter && state.sorter.columnKey === "country" ? state.sorter.order : undefined,
          render: (value) => {
            return (
              <Spacing left={10}>
                {
                  COUNTRIES.find((item) => {
                    return item.code === value;
                  })?.name
                }
              </Spacing>
            );
          },
        },
        {
          title: "State",
          dataIndex: "state",
          sorter,
          key: "state",
          sortOrder: state && state.sorter && state.sorter.columnKey === "state" ? state.sorter.order : undefined,
          render: (value) => {
            return <Spacing left={10}>{value}</Spacing>;
          },
        },
        {
          title: "City",
          dataIndex: "city",
          sorter,
          key: "city",
          sortOrder: state && state.sorter && state.sorter.columnKey === "city" ? state.sorter.order : undefined,
          render: (value) => {
            return <Spacing left={10}>{value}</Spacing>;
          },
        },
        {
          title: "LinkedIn",
          dataIndex: "linkedInUrl",
          key: "linkedInUrl",
          render: (value) => {
            return <Spacing left={10}>{value}</Spacing>;
          },
        },
        {
          title: "Twitter",
          dataIndex: "twitterUrl",
          key: "twitterUrl",
          render: (value) => {
            return <Spacing left={10}>{value}</Spacing>;
          },
        },
        {
          title: "Facebook",
          dataIndex: "facebookUrl",
          key: "facebookUrl",
          render: (value) => {
            return <Spacing left={10}>{value}</Spacing>;
          },
        },

        {
          title: "Actions",
          dataIndex: "user",
          render: (user) => {
            return (
              <Toggle>
                {({ on, toggle }) => (
                  <Popover
                    visible={on}
                    trigger="click"
                    title="User Actions"
                    placement="rightBottom"
                    onVisibleChange={toggle}
                    content={
                      <ul
                        css={{
                          margin: 0,
                          padding: 0,
                          listStyle: "none",
                          "> li:not(:first-of-type)": {
                            marginTop: 10,
                          },
                        }}
                      >
                        <li>
                          <Link
                            to={`/users/${user.slug}`}
                            onClick={() => {
                              toggle();
                            }}
                          >
                            Profile
                          </Link>
                        </li>

                        {user.__typename === "PendingUser" && !user.verified && (
                          <li>
                            <VerifyUser
                              user={user}
                              onVerificationComplete={() => {
                                if (refetch && typeof refetch === "function") {
                                  refetch();
                                }
                              }}
                            >
                              {(toggleVerify) => (
                                <a
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    toggleVerify();
                                    toggle();
                                  }}
                                >
                                  Verify
                                </a>
                              )}
                            </VerifyUser>
                          </li>
                        )}

                        {user.__typename === "PendingUser" && user.verified && (
                          <li>
                            <RemoveVerification user={user}>
                              {(toggleRemoveVerification) => (
                                <a
                                  href="#"
                                  css={{
                                    color: palette.red,
                                  }}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    toggleRemoveVerification();
                                    toggle();
                                  }}
                                >
                                  Remove Verification
                                </a>
                              )}
                            </RemoveVerification>
                          </li>
                        )}

                        {user.__typename === "PendingUser" && (
                          <Toggle>
                            {({ on: isOpen, toggle: toggleAssignFlow }) => (
                              <>
                                <li>
                                  <a
                                    href="#"
                                    onClick={() => {
                                      toggleAssignFlow();
                                      toggle();
                                    }}
                                  >
                                    Assign
                                  </a>
                                </li>

                                <AssignUserFlow
                                  isVisible={isOpen}
                                  initialMemberAdditions={[user]}
                                  onRequestClose={() => {
                                    toggleAssignFlow();
                                    if (refetch && typeof refetch === "function") {
                                      refetch();
                                    }
                                  }}
                                />
                              </>
                            )}
                          </Toggle>
                        )}

                        <Impersonate>
                          {(impersonate) => (
                            <li>
                              <a
                                href="#"
                                onClick={(event) => {
                                  event.preventDefault();
                                  impersonate(user);
                                }}
                              >
                                Impersonate
                              </a>
                            </li>
                          )}
                        </Impersonate>
                      </ul>
                    }
                  >
                    <span
                      role="button"
                      css={{
                        cursor: "pointer",
                      }}
                    >
                      <DotdotdotIcon />
                    </span>
                  </Popover>
                )}
              </Toggle>
            );
          },
        },
      ]}
      pagination={false}
      bordered={true}
      expandedRowRender={(onboarding) => {
        return (
          <div
            css={{
              padding: 10,
            }}
          >
            <SpacingBetween top={20}>
              <h3
                css={{
                  fontWeight: 600,
                }}
              >
                Details:
              </h3>

              {onboarding.workingFor === IWorkPlaceType.Government && (
                <div
                  style={{
                    fontSize: 16,
                    color: palette.text,
                    fontWeight: 600,
                    display: "flex",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 400,
                      color: palette.darkGray,
                      marginRight: 10,
                    }}
                  >
                    Government:
                  </span>
                  <GovernmentById governmentId={onboarding.governmentId} displayNA={true} />
                </div>
              )}

              {onboarding.workingFor === IWorkPlaceType.None && (
                <>
                  <div
                    style={{
                      fontSize: 16,
                      color: palette.text,
                      fontWeight: 600,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 400,
                        color: palette.darkGray,
                        marginRight: 10,
                      }}
                    >
                      Why you want to join:
                    </span>
                    {onboarding.whyYouWantToJoin ? onboarding.whyYouWantToJoin : "N/A"}
                  </div>
                </>
              )}

              {onboarding.workingFor === IWorkPlaceType.Vendor && (
                <>
                  <div
                    style={{
                      fontSize: 16,
                      color: palette.text,
                      fontWeight: 600,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 400,
                        color: palette.darkGray,
                        marginRight: 10,
                      }}
                    >
                      Vendor name:
                    </span>
                    {onboarding.vendorName ? onboarding.vendorName : "N/A"}
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      color: palette.text,
                      fontWeight: 600,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 400,
                        color: palette.darkGray,
                        marginRight: 10,
                      }}
                    >
                      Vendor website:
                    </span>
                    {onboarding.vendorWebsite ? (
                      <a href={onboarding.vendorWebsite} target="_blank" rel="noopener noreferrer">
                        {onboarding.vendorWebsite}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </div>
                </>
              )}
              {(onboarding.workingFor === IWorkPlaceType.NonprofitOrNgo ||
                onboarding.workingFor === IWorkPlaceType.Other ||
                onboarding.workingFor === IWorkPlaceType.StateOrFederalAgency) && (
                <>
                  <div
                    style={{
                      fontSize: 16,
                      color: palette.text,
                      fontWeight: 600,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 400,
                        color: palette.darkGray,
                        marginRight: 10,
                      }}
                    >
                      Workplace name:
                    </span>
                    {onboarding.workplaceName ? onboarding.workplaceName : "N/A"}
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      color: palette.text,
                      fontWeight: 600,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 400,
                        color: palette.darkGray,
                        marginRight: 10,
                      }}
                    >
                      Worplace website:
                    </span>
                    {onboarding.workplaceWebsite ? (
                      <a href={onboarding.workplaceWebsite} target="_blank" rel="noopener noreferrer">
                        {onboarding.workplaceWebsite}
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </div>
                </>
              )}
            </SpacingBetween>
          </div>
        );
      }}
    />
  );
}

const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
