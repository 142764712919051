import React from "react";
import TableSearch from "../../../components/table/TableSearch";
import { useLocation } from "react-router";
import queryString from "query-string";
import ManageUsers from "./ManageUsers";

interface IUsersWithSearchProps {
  filter?: string | null;
  government?: string | null;
  company?: string | null;
  historyOrigin?: string;
  filterByTag?: any;
}

export default function UsersWithSearch({
  filter,
  government,
  company,
  historyOrigin = "/users",
  filterByTag = null,
  ...props
}: IUsersWithSearchProps) {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div css={{
 marginBottom: 20 
}}
      >
        <TableSearch historyOrigin={historyOrigin} query={query} inputPlaceholder="Search for users..." />
      </div>

      <ManageUsers
        filter={filter}
        government={government}
        company={company}
        filterByTag={filterByTag}
        search={(query.search as string) || ""}
        {...props}
        historyOrigin={historyOrigin}
      />
    </>
  );
}
