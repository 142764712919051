import React from "react";
import * as palette from "@govlaunch/palette";

interface IFieldTitleProps {
  title: string;
  icon?: JSX.Element | null;
}

export default function FieldTitle({ title, icon }: IFieldTitleProps) {
  return (
    <div
      css={{
        marginBottom: 10,
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          css={{
            display: "grid",
            gridAutoFlow: "column",
            gridColumnGap: 8,
            alignItems: "center",
          }}
        >
          {icon ? icon : null}

          <label
            css={{
              fontWeight: "bold",
              fontSize: 14,
              color: palette.mediumGray,
              margin: 0,
            }}
          >
            {title}
          </label>
        </div>
      </div>
    </div>
  );
}
