import { Button } from "antd";
import React from "react";
import { Margin } from "../../spacings";
import PlanFormSection from "../billing/PlanFormSection";
import HorizontalTextField from "../form/fields/HorizontalTextField";
import * as palette from "@govlaunch/palette";

interface ICountryTimezoneForm {
  submitting: boolean;
  handleSubmit: () => any;
  submitError: any;
  isEditting?: boolean;
}

export default function CountryTimezoneForm({
  submitting,
  handleSubmit,
  submitError,
  isEditting,
}: ICountryTimezoneForm) {
  return (
    <form onSubmit={handleSubmit}>
      <PlanFormSection>
        <HorizontalTextField
          fieldName="countryCode"
          label="Country Code"
          placeholder="Type two-letter country code (e.g. US)"
          inputWidth={420}
          disabled={isEditting}
          uppercase={true}
        />

        <Margin mt={12}>
          <HorizontalTextField
            fieldName="countryName"
            label="Country"
            placeholder="Type full country name (e.g. +01:00)"
            inputWidth={420}
          />
        </Margin>

        <Margin mt={12}>
          <HorizontalTextField
            fieldName="timezone"
            label="Timezone Offset"
            placeholder="Type timezone offset (e.g. +01:00)"
            inputWidth={420}
          />
        </Margin>
      </PlanFormSection>
      <div
        css={{
          marginTop: 24,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          disabled={submitting}
          css={{
            userSelect: "none",
          }}
        >
          {submitting ? "Please wait..." : "Submit"}
        </Button>
      </div>
      {submitError && (
        <div
          css={{
            marginTop: 24,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            color: palette.red,
          }}
        >
          {submitError}
        </div>
      )}
    </form>
  );
}
