import React from "react";
import DocumentTitle from "react-document-title";
import AddSponsorshipForm from "./form/AddSponsorshipForm";

export default function NewSponsorship() {
  return (
    <DocumentTitle title="New Sponsorship | Admin">
      <div css={{
 display: "flex", overflow: "auto", flexDirection: "column" 
}}
      >
        <AddSponsorshipForm />
      </div>
    </DocumentTitle>
  );
}
