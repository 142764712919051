import { List, Select, Table, TableProps, Tooltip, Typography } from "antd";
import Column from "antd/lib/table/Column";
import range from "lodash/fp/range";
import moment from "moment";
import React from "react";
import { useMutation } from "react-apollo";
import { Link } from "react-router-dom";
import { ICollectionSponsoring } from "../../../../types/types";
import ViewerSpan from "../../users/ViewerSpan";
import CollectionsTableActions from "../CollectionsTableActions";
import { IListAllCollectionsQuery } from "../graphql/queries/__generated__/ListAllCollections.generated";
import ChangeCollectionPosition from "../graphql/mutations/ChangeCollectionPosition";
import {
  IChangeCollectionPositionMutation,
  IChangeCollectionPositionMutationVariables,
} from "../graphql/mutations/__generated__/ChangeCollectionPosition.generated";
import { ICollectionsTableState } from "./ManageCollections";

interface ICollectionsTableProps<T> {
  refetchCounters: any;
  loading: boolean;
  collections: T[];
  footer?: TableProps<any>["footer"];
  title?: TableProps<any>["title"];
  state?: ICollectionsTableState;
  refetch: () => void;
}

type TCollection = NonNullable<NonNullable<IListAllCollectionsQuery["listAllCollections"]>["items"]>[0];

export default function CollectionsTable({
  refetchCounters,
  loading,
  collections,
  title,
  footer,
  refetch,
}: ICollectionsTableProps<TCollection>) {
  const [changePosition] = useMutation<IChangeCollectionPositionMutation, IChangeCollectionPositionMutationVariables>(
    ChangeCollectionPosition,
  );

  return (
    <Table
      dataSource={collections || []}
      bordered={true}
      loading={loading}
      scroll={{
        x: true,
      }}
      size="middle"
      rowKey="_id"
      pagination={false}
      title={title}
      footer={footer}
      expandedRowRender={(collection) => (
        <List
          header={<strong>Tags</strong>}
          bordered={true}
          rowKey="_id"
          css={{
            background: "#fff",
          }}
          dataSource={collection.tags}
          renderItem={(tag) => <List.Item>{tag.name}</List.Item>}
        />
      )}
    >
      <Column
        title="Name"
        dataIndex="name"
        render={(name, collection: IListAllCollectionsQuery["listAllCollections"]["items"][0]) => (
          <div>
            <Link to={`/collections/${collection.slug}`}>{name}</Link>
          </div>
        )}
      />

      <Column
        width="1%"
        title="Position"
        dataIndex="position"
        render={(position, collection: IListAllCollectionsQuery["listAllCollections"]["items"][0]) => (
          <div>
            <Select
              css={{
                minWidth: 80,
              }}
              defaultValue={position}
              onChange={(value) => {
                changePosition({
                  variables: {
                    collectionId: collection._id,
                    position: value,
                  },
                }).then(() => refetch());
              }}
            >
              {range(0, 13)
                .reverse()
                .map((index) => (
                  <Select.Option key={index} value={index}>
                    {index === 0 ? "Auto" : Math.abs(index - 12) + 1}
                  </Select.Option>
                ))}
            </Select>
          </div>
        )}
      />

      <Column
        title="Collection Type"
        width={120}
        dataIndex="tags"
        render={(tags) => <span>{tags.length > 1 ? "Bundle" : "Single"}</span>}
      />

      <Column
        title="Featured"
        dataIndex="featuredAt"
        render={(featuredAt) => {
          if (featuredAt) {
            return <Typography.Text type="success">Yes</Typography.Text>;
          }

          return <Typography.Text type="warning">No</Typography.Text>;
        }}
      />

      <Column
        title="Gated content?"
        dataIndex="publicContentAvailable"
        render={(publicContentAvailable) => {
          if (publicContentAvailable) {
            return <Typography.Text type="success">No</Typography.Text>;
          }

          return <Typography.Text type="warning">Yes</Typography.Text>;
        }}
      />

      <Column
        width={100}
        title="User Saves"
        dataIndex="adminStats"
        render={(adminStats) => adminStats.usersSavedCount}
      />

      <Column
        width={120}
        title="Active Sponsorship?"
        align="center"
        dataIndex="isSponsoringSaleActive"
        render={(sponsoring) => (
          <Typography.Text type={sponsoring ? "success" : "danger"}>{sponsoring ? "Yes" : "No"}</Typography.Text>
        )}
      />

      <Column
        title="Sponsoring"
        dataIndex="sponsoring"
        render={(sponsoring) => {
          if (sponsoring === ICollectionSponsoring.VendorCurated) {
            return "Curated";
          }

          if (sponsoring === ICollectionSponsoring.VendorSponsored) {
            return "Custom Collection";
          }

          return "N/A";
        }}
      />

      <Column
        title="Vendor"
        dataIndex="sponsoringVendors"
        render={(sponsoringVendors) => {
          if (sponsoringVendors.length === 0) {
            return "N/A";
          }

          const vendor = sponsoringVendors[0];

          return <Link to={`/vendors/${vendor.slug}`}>{vendor.name}</Link>;
        }}
      />

      <Column
        title="Created By"
        dataIndex="createdBy"
        render={(createdBy) => {
          if (!createdBy) {
            return (
              <Tooltip
                title={
                  <div>
                    It will be <strong>"Not Available"</strong> for legacy created collections. Created By field weren't
                    tracked in the past.
                  </div>
                }
              >
                <u>Not Available</u>
              </Tooltip>
            );
          }

          return <ViewerSpan viewer={createdBy} />;
        }}
      />

      <Column
        title="Updated at"
        dataIndex="updatedAt"
        render={(updatedAt) => {
          return moment(updatedAt).format("MM/DD/YYYY");
        }}
      />

      <Column<IListAllCollectionsQuery["listAllCollections"]["items"][0]>
        title="Actions"
        dataIndex="_id"
        align="center"
        render={(_, collection) => {
          return <CollectionsTableActions collection={collection} refetchCounters={refetchCounters} />;
        }}
      />
    </Table>
  );
}
