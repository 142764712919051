import React from "react";

export default function TypeOptions({ input }: any) {
  return (
    <div
      css={{
        display: "inline-grid",
        gridColumnGap: 16,
        gridAutoFlow: "column",
        justifyContent: "flex-start",
      }}
    >
      <label
        css={{
          cursor: "pointer",
          fontWeight: 600,
        }}
      >
        <input
          type="radio"
          onChange={() => input.onChange("government")}
          checked={input.value === "government"}
          name="type"
        />

        <span
          css={{
            marginLeft: 3,
          }}
        >
          Government
        </span>
      </label>

      <label
        css={{
          cursor: "pointer",
          fontWeight: 600,
        }}
      >
        <input type="radio" onChange={() => input.onChange("group")} checked={input.value === "group"} name="type" />

        <span
          css={{
            marginLeft: 3,
          }}
        >
          Group
        </span>
      </label>

      <label
        css={{
          cursor: "pointer",
          fontWeight: 600,
        }}
      >
        <input type="radio" onChange={() => input.onChange("vendor")} checked={input.value === "vendor"} name="type" />

        <span
          css={{
            marginLeft: 3,
          }}
        >
          Vendor
        </span>
      </label>
    </div>
  );
}
