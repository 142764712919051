import * as palette from "@govlaunch/palette";
import { State } from "react-powerplug";
import { Spacing, SpacingBetween } from "@govlaunch/spacers";
import ProgressiveImage from "react-progressive-image";
import PropTypes from "prop-types";
import React, { RefObject } from "react";
import get from "lodash/fp/get";
import { Button, Filestack, LoadingIndicator } from "@govlaunch/core";

export const INNOVATORS_PLACEHOLDER_COVER = "https://s3.amazonaws.com/static.govlaunch.com/covers/innovators.png";
export const DISRUPTORS_PLACEHOLDER_COVER = "https://s3.amazonaws.com/static.govlaunch.com/covers/disruptors.png";
export const VENDORS_PLACEHOLDER_COVER = "https://s3.amazonaws.com/static.govlaunch.com/covers/vendors.jpg";
export const GOVERNMENTS_PLACEHOLDER_COVER = "https://s3.amazonaws.com/static.govlaunch.com/covers/governments.jpg";

interface ICoverProps {
  alt: string;
  width?: number;
  height?: number;
  readOnly?: boolean;
  originalSource?: string;
  repositionedSource?: string;
  placeholderSource: string;
  containerProps?: any;
  imageProps?: any;
  onChange: (url: string) => any;
  onReposition: (originalSource: string, cropState: any) => any;
  onClick?: (e: React.SyntheticEvent) => any;
  onRemove: (e: React.SyntheticEvent) => any;
}

interface IRepositioning {
  isRepositioning: boolean;
  lastCropState: any | null;
}

export default function Cover({
  originalSource,
  repositionedSource,
  placeholderSource,
  alt,
  readOnly,
  width,
  height,
  onChange,
  onReposition,
  onRemove,
  containerProps,
  imageProps,
}: ICoverProps) {
  if (!originalSource) {
    return (
      <div
        css={{
          display: "flex",
          position: "relative",
          maxWidth: width,
          height: height,
        }}
        {...containerProps}
      >
        <img
          src={placeholderSource}
          alt={alt}
          css={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "top",
            userSelect: "none",
          }}
          {...imageProps}
        />

        {readOnly === false && (
          <div
            css={{
              bottom: 10,
              right: 10,
              position: "absolute",
              pointerEvents: "all",
            }}
          >
            <div>
              <Filestack
                options={{
                  transformations: {
                    rotate: false,
                    circle: false,
                    crop: false,
                  },
                  storeTo: {
                    location: "s3",
                    access: "public",
                  },
                }}
                onSuccess={(result: any) => {
                  if (result.filesUploaded.length > 0) {
                    onChange(result.filesUploaded[0].url);
                  }
                }}
                customRender={({ onPick }: any) => (
                  <Button onClick={onPick} theme="secondary" color={palette.white} display="flex">
                    <CameraIcon />
                    <Spacing left={7.5}>Edit Cover</Spacing>
                  </Button>
                )}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <State<IRepositioning>
      initial={{
        isRepositioning: false,
        lastCropState: null,
      }}
    >
      {({ state, setState }) => {
        if (state.isRepositioning) {
          return (
            <DraggableCover disabled={false}>
              {({ parentRef, coverRef, events, style, cropState }) => (
                <div
                  ref={parentRef}
                  {...containerProps}
                  style={{
                    maxWidth: width,
                    height,
                    position: "relative",
                    overflow: "hidden",
                    ...get("style", containerProps),
                  }}
                >
                  <img
                    {...events}
                    ref={coverRef}
                    src={originalSource}
                    alt={alt}
                    draggable={false}
                    width="100%"
                    {...imageProps}
                    style={{
                      ...style,
                      ...get("style", imageProps),
                    }}
                  />

                  <div
                    css={{
                      display: "flex",
                      flexDirection: "column",
                      position: "absolute",
                      top: 0,
                      width: "100%",
                      height: "100%",
                      pointerEvents: "none",
                    }}
                  >
                    <div
                      css={{
                        width: "100%",
                        height: 50,
                        background: "rgba(33, 33, 33, 0.5)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#ffffff",
                        fontWeight: 500,
                        fontSize: 12,
                        textTransform: "uppercase",
                      }}
                    >
                      Drag to Reposition
                    </div>

                    <div
                      css={{
                        display: "flex",
                        marginLeft: "auto",
                        marginTop: "auto",
                        marginBottom: 10,
                        marginRight: 10,
                        pointerEvents: "all",
                      }}
                    >
                      <SpacingBetween flex={true} right={10}>
                        <Button
                          theme="secondary"
                          color={palette.primary}
                          backgroundColor={palette.white}
                          onClick={() => {
                            if (cropState === null) {
                              setState({
                                isRepositioning: false,
                                lastCropState: null,
                              });

                              return;
                            }

                            onReposition(originalSource, cropState);

                            setState({
                              isRepositioning: false,
                              lastCropState: cropState,
                            });
                          }}
                        >
                          Save
                        </Button>

                        <Button
                          theme="secondary"
                          color="danger"
                          backgroundColor={palette.white}
                          onClick={() => {
                            setState({
                              isRepositioning: false,
                            });
                          }}
                        >
                          Cancel
                        </Button>
                      </SpacingBetween>
                    </div>
                  </div>
                </div>
              )}
            </DraggableCover>
          );
        } else {
          let source, placeholder;

          if (repositionedSource) {
            // if we have a `repositionedSource` we also have `originalSource`
            source = repositionedSource;
            placeholder = originalSource;
          } else if (originalSource) {
            // if there's a `originalSource` we should have the placeholder as fallback
            source = originalSource;
            placeholder = placeholderSource;
          }

          return (
            <ProgressiveImage src={source ? source : ""} placeholder={placeholder ? placeholder : ""}>
              {(src: string, loading: boolean) => {
                const didCrop = state.lastCropState !== null;

                if (loading) {
                  return (
                    <div
                      css={{
                        position: "relative",
                        overflow: "hidden",
                        maxWidth: width,
                        height: height,
                      }}
                      {...containerProps}
                    >
                      {didCrop ? (
                        <img
                          src={originalSource}
                          alt={alt}
                          css={{
                            width: "100%",
                            userSelect: "none",
                            position: "absolute",
                            top: state.lastCropState.y * -1,
                          }}
                          {...imageProps}
                        />
                      ) : (
                        <img
                          src={src}
                          alt={alt}
                          css={{
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                          {...imageProps}
                        />
                      )}

                      <div
                        css={{
                          top: 0,
                          display: "flex",
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          pointerEvents: "none",
                        }}
                      >
                        <div
                          css={{
                            width: "100%",
                            height: 50,
                            background: "rgba(33, 33, 33, 0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#ffffff",
                            fontWeight: 500,
                            fontSize: 14,
                            textTransform: "uppercase",
                          }}
                        >
                          <LoadingIndicator />
                          <Spacing left={7.5}>{didCrop ? "Saving" : "Loading"}</Spacing>
                        </div>
                      </div>
                    </div>
                  );
                }

                return (
                  <div
                    css={{
                      position: "relative",
                      maxWidth: width,
                      height: height,
                    }}
                    {...containerProps}
                  >
                    <img
                      src={src}
                      alt={alt}
                      css={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "top",
                        userSelect: "none",
                      }}
                      {...imageProps}
                    />

                    <div
                      css={{
                        bottom: 10,
                        right: 10,
                        display: "flex",
                        position: "absolute",
                        pointerEvents: "all",
                      }}
                    >
                      <div
                        css={{
                          display: "flex",
                        }}
                      >
                        <SpacingBetween right={10}>
                          <Button
                            display="flex"
                            theme="secondary"
                            color={palette.primary}
                            backgroundColor={palette.white}
                            onClick={() => {
                              setState({
                                isRepositioning: true,
                              });
                            }}
                          >
                            <RepositionIcon />
                            <Spacing left={7.5}>Reposition</Spacing>
                          </Button>

                          <Button
                            theme="secondary"
                            backgroundColor={palette.white}
                            color="warning"
                            display="flex"
                            onClick={onRemove}
                          >
                            <RemoveIcon />
                            <Spacing left={7.5}>Remove</Spacing>
                          </Button>
                        </SpacingBetween>
                      </div>
                    </div>
                  </div>
                );
              }}
            </ProgressiveImage>
          );
        }
      }}
    </State>
  );
}

Cover.propTypes = {
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  readOnly: PropTypes.bool,
  originalSource: PropTypes.string,
  repositionedSource: PropTypes.string,
  placeholderSource: PropTypes.string.isRequired,
  containerProps: PropTypes.any,
  imageProps: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onReposition: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  children: PropTypes.any,
};

Cover.defaultProps = {
  alt: null,
  width: 1140,
  height: 245,
  children: null,
  originalSource: null,
  repositionedSource: null,
  containerProps: null,
  imageProps: null,
  readOnly: true,
};

interface IDraggableCoverProps {
  onDragStop?: (data: any) => any;
  disabled: boolean;
  children: (props: IDraggableCoverChildren) => any;
}

interface IDraggableCoverChildren {
  top: number;
  isDragging: boolean;
  coverRef: RefObject<HTMLImageElement>;
  parentRef: RefObject<HTMLElement>;
  events: IEvents;
  style: any;
  cropState: (y: number) => any;
}

interface IEvents {
  onMouseDown: (event: Event) => any;
}

interface IDraggrableCoverState {
  y: number;
  isDragging: boolean;
  startingX: number | null;
  startingY: number | null;
}

class DraggableCover extends React.Component<IDraggableCoverProps, IDraggrableCoverState> {
  static defaultProps = {
    onDragStop: null,
  };

  state = {
    y: 0,
    isDragging: false,
    startingY: null,
    startingX: null,
  };

  coverRef = React.createRef<HTMLImageElement>();
  parentRef = React.createRef<HTMLElement>();
  coverRect: any = null;
  parentRect: any = null;

  handleMouseDown = (event: Event) => {
    if (this.props.disabled) {
      return;
    }

    this.coverRect = (this.coverRef.current as HTMLImageElement).getBoundingClientRect();
    this.parentRect = (this.parentRef.current as HTMLElement).getBoundingClientRect();

    const { x, y } = this.getCoordinates(event);

    this.setState({
      startingX: x - (this.coverRect.left - this.parentRect.left),
      startingY: y - (this.coverRect.top - this.parentRect.top),
    });

    document.addEventListener("mouseup", this.handleStopDragging);
    document.addEventListener("mousemove", this.handleStartDragging);
  };

  handleStartDragging: EventListener = (event) => {
    const { x, y } = this.getCoordinates(event);
    const { startingX, startingY } = this.state;

    const a = Math.abs(Number(startingX) - x);
    const b = Math.abs(Number(startingY) - y);

    const distance = Math.round(Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2)));

    if (distance >= 10) {
      this.handleStopDragging();
      this.handleDrag();
    }
  };

  handleStopDragging = () => {
    document.removeEventListener("mouseup", this.handleStopDragging);
    document.removeEventListener("mousemove", this.handleStartDragging);
  };

  handleDrag = () => {
    this.setState({
      isDragging: true,
    });

    window.addEventListener("mouseup", this.handleStopDrag);
    window.addEventListener("mousemove", this.handleMouseMove);
  };

  handleStopDrag = () => {
    this.setState({
      startingY: null,
      isDragging: false,
    });

    window.removeEventListener("mouseup", this.handleStopDrag);
    window.removeEventListener("mousemove", this.handleMouseMove);
  };

  calculateCropState = (y: number): any | null => {
    if (this.coverRef.current && this.parentRef.current) {
      return {
        x: 0,
        y: Math.abs(y),
        original: {
          width: this.coverRef.current.naturalWidth,
          height: this.coverRef.current.naturalHeight,
        },
        proportional: {
          width: this.parentRef.current.clientWidth,
          height: Math.round(
            (this.coverRef.current.naturalHeight / this.coverRef.current.naturalWidth) *
              this.parentRef.current.clientWidth,
          ),
        },
        rect: {
          width: this.parentRef.current.clientWidth,
          height: this.parentRef.current.clientHeight,
        },
      };
    } else {
      return null;
    }
  };

  handleMouseMove: EventListener = (event) => {
    const { y } = this.getCoordinates(event);
    const { onDragStop } = this.props;

    this.setState(
      ({ startingY }) => ({
        y: this.calculateY(y, Number(startingY)),
      }),
      () => {
        if (onDragStop) {
          onDragStop(this.calculateCropState(this.state.y));
        }
      },
    );
  };

  calculateY = (clientY: number, startingY: number) => {
    const y = clientY - startingY;
    const y1 = Math.round((this.parentRef.current as HTMLElement).clientHeight);
    const y2 = Math.round((this.coverRef.current as HTMLImageElement).height);

    if (y >= 0) {
      return 0;
    } else if (y <= y1 - y2) {
      return y1 - y2;
    } else {
      return y;
    }
  };

  getCoordinates = (event: Event) => {
    let x, y;

    if ("ontouchstart" in window && (event as TouchEvent).touches) {
      x = (event as TouchEvent).touches[0].clientX;
      y = (event as TouchEvent).touches[0].clientY;
    } else {
      x = (event as MouseEvent).clientX;
      y = (event as MouseEvent).clientY;
    }

    return {
      x,
      y,
    };
  };

  getStyleForRender = (y: number, disabled: boolean) => {
    if (disabled) {
      return {
        pointerEvents: "none",
        userSelect: "none",
        cursor: "default",
      };
    } else {
      return {
        top: y,
        userSelect: "none",
        position: "absolute",
        cursor: "row-resize",
      };
    }
  };

  render() {
    const { disabled, children } = this.props;
    const { y, isDragging } = this.state;

    return children({
      top: y,
      isDragging,
      coverRef: this.coverRef,
      parentRef: this.parentRef,
      events: {
        onMouseDown: this.handleMouseDown,
      },
      style: this.getStyleForRender(y, disabled),
      cropState: this.calculateCropState(y),
    });
  }
}

export function makeImaginaryUrls(src: string, crop: any) {
  const imaginary = "https://imaginary-ssl.govlaunch.com";

  const { rect, proportional, x, y } = crop;
  const proportionalUrl = `${imaginary}/resize?width=${proportional.width}&height=${proportional.height}&url=${src}&force=true`;

  const croppedUrl = [
    `${imaginary}/extract?`,
    `&areawidth=${rect.width}&areaheight=${rect.height}`,
    `&top=${y}&left=${x}`,
    `&url=${encodeURIComponent(proportionalUrl)}`,
  ].join("");

  return {
    original: src,
    proportional: proportionalUrl,
    cropped: croppedUrl,
  };
}

const RemoveIcon: React.FunctionComponent = (props) => (
  <svg viewBox="0 0 19 24" width="1em" height="1em" {...props}>
    <g fill="currentColor">
      <path d="M14.48 24H4.72C2.8 24 1.2 22.48 1.2 20.56V5.2c0-.24.16-.4.4-.4h16c.24 0 .4.16.4.4v15.36c0 1.92-1.6 3.44-3.52 3.44zM2 5.6v14.96C2 22 3.2 23.2 4.72 23.2h9.76c1.52 0 2.72-1.2 2.72-2.64V5.6H2z" />
      <path d="M8 20.8c-.24 0-.4-.16-.4-.4v-12c0-.24.16-.4.4-.4.24 0 .4.16.4.4v12c0 .24-.16.4-.4.4zm3.2 0c-.24 0-.4-.16-.4-.4v-12c0-.24.16-.4.4-.4.24 0 .4.16.4.4v12c0 .24-.16.4-.4.4zm7.2-15.2H.8c-.24 0-.4-.16-.4-.4v-.8c0-1.52 1.28-2.8 2.8-2.8h2.24l.8-.8c.56-.56 1.28-.8 2-.8h2.8c.72 0 1.44.32 2 .8l.8.8H16c1.52 0 2.8 1.28 2.8 2.8v.8c0 .24-.16.4-.4.4zM1.2 4.8H18v-.4c0-1.12-.88-2-2-2h-2.4c-.08 0-.24-.08-.32-.08l-.88-.88c-.32-.4-.88-.64-1.36-.64h-2.8c-.56 0-1.04.24-1.44.56l-.88.88c-.08.08-.24.16-.32.16H3.2c-1.12 0-2 .88-2 2v.4zm3.6 16c-.24 0-.4-.16-.4-.4v-12c0-.24.16-.4.4-.4.24 0 .4.16.4.4v12c0 .24-.16.4-.4.4zm9.6 0c-.24 0-.4-.16-.4-.4v-12c0-.24.16-.4.4-.4.24 0 .4.16.4.4v12c0 .24-.16.4-.4.4z" />
    </g>
  </svg>
);

const RepositionIcon: React.FunctionComponent = (props) => (
  <svg viewBox="0 0 64 64" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      d="M0 44h31v16.586l-5.293-5.293-1.414 1.561 7 7.146h1.414l7-7.146-1.414-1.488L33 60.586V44h31v-2H0zM33 3.414l5.293 5.293 1.414-1.561-7-7.146h-1.414l-7 7.146 1.414 1.488L31 3.414V20H0v2h64v-2H33z"
    />
  </svg>
);

const CameraIcon: React.FunctionComponent = (props) => (
  <svg viewBox="0 0 24 20" width="1em" height="1em" {...props}>
    <path
      d="M21.5 20h-19A2.503 2.503 0 0 1 0 17.5v-13C0 3.122 1.122 2 2.5 2h3.789L7.971.163A.496.496 0 0 1 8.339 0h7.321a.5.5 0 0 1 .369.163L17.711 2H21.5C22.878 2 24 3.122 24 4.5v13c0 1.378-1.122 2.5-2.5 2.5zM2.5 3C1.673 3 1 3.673 1 4.5v13c0 .827.673 1.5 1.5 1.5h19c.827 0 1.5-.673 1.5-1.5v-13c0-.827-.673-1.5-1.5-1.5h-4.009a.5.5 0 0 1-.369-.163L15.44 1H8.56L6.878 2.837A.5.5 0 0 1 6.509 3H2.5zM12 17c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zm0-11c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
