/* eslint-disable react/display-name */
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import { Table } from "antd";
import { TableProps } from "antd/lib/table";
import moment from "moment";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import * as Types from "../../../../types/types";
import { IAllProductsQuery } from "../../../queries/__generated__/GetProductsQuery.generated";
import notUndefinedOrNull from "../../../utils/notUndefinedOrNull";
import ProductActions from "../ProductActions";
import { SortOrder } from "antd/lib/table/interface";
import { getFilteredValueFromNumbers } from "../../../utils/tablesUtils";
import TagPill from "../../tags/TagPill";

interface IProductsTableState {
  sortColumnKey: string;
  sortColumnOrder: SortOrder;
  filter: Types.IProductFilter;
  inTiers: number[] | null;
}

type TProduct = NonNullable<NonNullable<IAllProductsQuery["products"]>["items"]>[0];

interface IProductsTableProps {
  products: NonNullable<NonNullable<IAllProductsQuery["products"]>["items"]>;
  onChange?: (pagination: any, filters: any, sorter: any) => any;
  loading?: boolean;
  filter?: Types.Maybe<Types.IProductFilter>;
  state?: IProductsTableState;
  search: Types.Maybe<string>;
  sortBy?: string;
  sorter?: boolean;
  title?: TableProps<any>["title"];
  footer?: TableProps<any>["footer"];
  noFilters?: boolean;
  renderProductLink?: (product: any) => any;
}

const ProductsTable = ({
  products,
  onChange,
  loading,
  filter,
  state,
  search,
  sortBy,
  sorter,
  title,
  footer,
  noFilters = false,
  renderProductLink,
}: IProductsTableProps): ReactElement => {
  const allArchived = products.every((product) => {
    return product.archivedAt !== null;
  });

  return (
    <Table<TProduct>
      bordered={true}
      onChange={onChange}
      rowKey="_id"
      loading={loading}
      pagination={false}
      style={{
        backgroundColor: palette.white,
      }}
      dataSource={products.filter((product) => {
        if (filter === "ARCHIVED") {
          return product.archivedAt;
        }

        return true;
      })}
      scroll={{
        x: true,
      }}
      title={title}
      footer={footer}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          sorter,
          sortOrder: state && state.sortColumnKey === "name" ? state.sortColumnOrder : undefined,
          render: (name: string, product: TProduct) => {
            return (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Spacing inline={true} right={10} flex={true}>
                  <ProfilePicture
                    image={product.logo}
                    size={25}
                    disruptor={product.isDisruptor}
                    cursor="pointer"
                    name={product.name}
                  />
                </Spacing>

                <Link to={renderProductLink ? renderProductLink(product) : `/products/${product.slug}`}>
                  {product.featuredAt ? <>{name} 🌟</> : name}
                </Link>
              </div>
            );
          },
        },
        {
          title: "Vendor",
          dataIndex: ["company", "name"],
          sorter,
          sortOrder: state && state.sortColumnKey === "company.name" ? state.sortColumnOrder : undefined,
          render: (vendorName: string, product: TProduct) => {
            if (!product.company) {
              return null;
            }

            return (
              <Link to={`/vendors/${product.company.slug}`}>
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Spacing right={10}>
                    <span>{vendorName}</span>
                  </Spacing>

                  <ProfilePicture
                    size={24}
                    disruptor={product.isDisruptor}
                    image={product.company.logo}
                    name={product.company.name}
                  />
                </div>
              </Link>
            );
          },
        },
        {
          title: "# of Projects",
          dataIndex: "projectsCount",
        },
        {
          title: "# of Stories",
          dataIndex: "storiesCount",
        },
        {
          title: "# of Governments",
          dataIndex: "governmentsUsingCount",
        },
        {
          title: "Tags",
          dataIndex: "tags",
          render: (tags: TProduct["tags"]) => (
            <div
              css={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {tags.map((tag) => {
                return <TagPill key={tag._id} name={tag.name} />;
              })}
            </div>
          ),
        },
        {
          title: "Tier",
          key: "tier",
          dataIndex: "tier",
          sorter,
          filters: !noFilters
            ? [
                {
                  text: "Tier 4",
                  value: "4",
                },
                {
                  text: "Tier 3",
                  value: "3",
                },
                {
                  text: "Tier 2",
                  value: "2",
                },
                {
                  text: "Tier 1",
                  value: "1",
                },
                {
                  text: "No tier",
                  value: "0",
                },
              ]
            : undefined,
          filteredValue: getFilteredValueFromNumbers(state?.inTiers),
          sortOrder: state && state.sortColumnKey === "tier" ? state.sortColumnOrder : undefined,
        },
        {
          title: "Created",
          key: "createdAt",
          sorter,
          sortOrder: state && state.sortColumnKey === "createdAt" ? state.sortColumnOrder : undefined,
          render: (product: TProduct) => {
            return moment(product.createdAt).format("MM/DD/YYYY");
          },
        },
        allArchived
          ? {
              title: "Archived At",
              render: (product: TProduct) => moment(product.archivedAt).format("MM/DD/YYYY"),
            }
          : null,
        allArchived
          ? {
              title: "Archived By",
              render: (product: TProduct) => {
                if (product.archivedBy) {
                  return <Link to={`/users/${product.archivedBy.slug}`}>{product.archivedBy.fullName}</Link>;
                }

                return "N/A";
              },
            }
          : null,
        {
          title: "Actions",
          render: (product: TProduct) => {
            return (
              <ProductActions
                product={product}
                variables={{
                  search,
                  filter: filter || null,
                  sort: sortBy || null,
                  cursor: null,
                  inTiers: null,
                  tags: null,
                }}
              />
            );
          },
        },
      ].filter(notUndefinedOrNull)}
    />
  );
};

export default ProductsTable;
