import gql from "graphql-tag";

export default gql`
  mutation EditStory(
    $storyId: ObjectId!
    $title: String!
    $author: ObjectId!
    $coauthors: [ObjectId!]
    $vendorCoauthors: [ObjectId!]
    $tags: [ObjectId!]
    $collaborators: [ObjectId!]
    $introduction: String!
    $body: JSON!
    $locations: [ObjectId!]
    $government: ObjectId
    $highlights: [String!]
    $medias: [MediaInput!]
    $resources: [ResourceInput!]
    $projectAwards: [String!]
    $newsworthy: [JSON!]
    $outsideProducts: [OutsideProductInput!]
    $products: [ObjectId!]
    $audience: String
    $awards: [ObjectId!]!
  ) {
    editStory(
      storyId: $storyId
      title: $title
      author: $author
      coauthors: $coauthors
      vendorCoauthors: $vendorCoauthors
      tags: $tags
      collaborators: $collaborators
      government: $government
      introduction: $introduction
      body: $body
      locations: $locations
      highlights: $highlights
      medias: $medias
      resources: $resources
      projectAwards: $projectAwards
      newsworthy: $newsworthy
      outsideProducts: $outsideProducts
      products: $products
      audience: $audience
      awards: $awards
    ) {
      _id
      slug

      title
      introduction

      author {
        ... on VendorUser {
          _id
          slug
          fullName
          jobTitle
          title
          avatar
          company {
            _id
            name
          }
        }
        ... on GovernmentUser {
          _id
          slug
          fullName
          jobTitle
          title
          avatar
          government {
            _id
            name
          }
        }
        ... on PendingUser {
          _id
          slug
          fullName
          jobTitle
          avatar
        }
      }

      coauthors {
        ... on VendorUser {
          _id
          slug
          fullName
          jobTitle
          title
          avatar
          company {
            _id
            name
          }
        }
        ... on GovernmentUser {
          _id
          slug
          fullName
          jobTitle
          title
          avatar
          government {
            _id
            name
          }
        }
        ... on PendingUser {
          _id
          slug
          fullName
          jobTitle
          avatar
        }
      }

      vendorCoauthors {
        _id
        name
        logo
        slug
      }

      medias {
        type
        url
      }

      locations {
        _id
        name
        slug
        logo
        ownerless
        city {
          _id
          name
          state
        }
      }

      tags {
        _id
        name
      }

      awards {
        _id
        name
      }

      government {
        _id
        name
        logo
        city {
          _id
          name
          state
        }
      }

      products {
        _id
        name
        slug
        status
        link
        logo
        isDisruptor
      }

      outsideProducts {
        _id
        name
        url
      }

      newsworthy {
        url
        title
        description
        favicon
        siteName
      }

      body
      highlights

      collaborators {
        ... on PendingUser {
          _id
          role
          avatar
          slug
          firstName
          lastName
          email
          jobTitle
        }
        ... on VendorUser {
          _id
          role
          avatar
          slug
          firstName
          lastName
          email
          jobTitle
          title
          company {
            _id
            name
          }
        }
        ... on GovernmentUser {
          _id
          role
          avatar
          slug
          firstName
          lastName
          email
          jobTitle
          title
          government {
            _id
            name
            city {
              _id
              name
              state
            }
          }
        }
      }
    }
  }
`;
