import gql from "graphql-tag";

export default gql`
  mutation AddProduct($product: ProductInput!, $company: ObjectId) {
    addProduct(product: $product, company: $company) {
      _id
      slug
    }
  }
`;
