import queryString from "query-string";
import React, { FunctionComponent, ReactElement } from "react";
import TableSearch from "../../../components/table/TableSearch";
import { useLocation } from "react-router";
import ManageStories from "./ManageStories";

interface IStoriesWithSearchProps {
  tab?: string | null;
  filterByTag?: any;
  historyOrigin?: string;
}

const StoriesWithSearch: FunctionComponent<IStoriesWithSearchProps> = ({
  tab = "ALL",
  historyOrigin = "/stories",
  filterByTag = null,
  ...props
}): ReactElement => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div css={{
 marginBottom: 20 
}}
      >
        <TableSearch historyOrigin={historyOrigin} query={query} inputPlaceholder="Search for stories..." />
      </div>

      <ManageStories
        search={query.search as string}
        tab={tab}
        filterByTag={filterByTag}
        historyOrigin={historyOrigin}
        props={props}
      />
    </>
  );
};

export default StoriesWithSearch;
