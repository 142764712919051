import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import { Table, TableProps } from "antd";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import FlagWithTooltip from "./FlagWithTooltip";
import { ICompactGovernmentsTableQueryFieldsFragment } from "./__generated__/CompactGovernmentsTable.generated";

export const CompactGovernmentsTableQueryFields = gql`
  fragment CompactGovernmentsTableQueryFields on Government {
    _id
    slug
    name
    logo
    isInnovator
    city {
      _id
      name
      state
      country
      stateName: state(full: true)
      type
      county
    }
  }
`;

interface ICompactGovernmentsTableProps {
  governments: ICompactGovernmentsTableQueryFieldsFragment[];
  footer?: TableProps<any>["footer"];
}

export default function CompactGovernmentsTable({ governments, footer }: ICompactGovernmentsTableProps) {
  return (
    <Table<ICompactGovernmentsTableQueryFieldsFragment>
      rowKey="_id"
      loading={false}
      bordered={true}
      pagination={false}
      dataSource={governments}
      scroll={{
        x: true,
      }}
      title={() => {
        return <strong>Governments ({governments.length})</strong>;
      }}
      footer={footer}
      columns={[
        {
          key: "name",
          title: "Name",
          dataIndex: "name",
          render: (_, government) => (
            <div
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ProfilePicture
                size={32}
                image={government.logo}
                innovator={government.isInnovator}
                name={government.name}
                css={{
                  whiteSpace: "nowrap",
                }}
              />

              <Spacing left={5}>
                <Link to={`/governments/${government.slug}`}>{government.city.name}</Link>
              </Spacing>
            </div>
          ),
        },
        {
          title: "Country",
          key: "country",
          render: (_, government) => {
            return <FlagWithTooltip country={government.city.country} width={24} height={18} />;
          },
        },
        {
          key: "state",
          title: "State",
          render: (_, government) => government.city.stateName,
        },
        {
          key: "county",
          title: "County",
          render: (_, government) => government.city.county,
        },
        {
          key: "type",
          title: "Type",
          render: (_, government) => government.city.type,
        },
      ]}
    />
  );
}
