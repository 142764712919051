const CURRENCIES = [
  {
    code: "AED",
    name: "United Arab Emirates Dirham",
  },
  {
    code: "AFN",
    name: "Afghan Afghani",
  },
  {
    code: "ALL",
    name: "Albanian Lek",
  },
  {
    code: "AMD",
    name: "Armenian Dram",
  },
  {
    code: "ANG",
    name: "Netherlands Antillean Guilder",
  },
  {
    code: "AOA",
    name: "Angolan Kwanza",
  },
  {
    code: "ARS",
    name: "Argentine Peso",
  },
  {
    code: "AUD",
    name: "Australian Dollar",
  },
  {
    code: "AWG",
    name: "Aruban Florin",
  },
  {
    code: "AZN",
    name: "Azerbaijani Manat",
  },
  {
    code: "BAM",
    name: "Bosnia-Herzegovina Convertible Mark",
  },
  {
    code: "BBD",
    name: "Barbadian Dollar",
  },
  {
    code: "BDT",
    name: "Bangladeshi Taka",
  },
  {
    code: "BGN",
    name: "Bulgarian Lev",
  },
  {
    code: "BHD",
    name: "Bahraini Dinar",
  },
  {
    code: "BIF",
    name: "Burundian Franc",
  },
  {
    code: "BMD",
    name: "Bermudan Dollar",
  },
  {
    code: "BND",
    name: "Brunei Dollar",
  },
  {
    code: "BOB",
    name: "Bolivian Boliviano",
  },
  {
    code: "BRL",
    name: "Brazilian Real",
  },
  {
    code: "BSD",
    name: "Bahamian Dollar",
  },
  {
    code: "BTC",
    name: "Bitcoin",
  },
  {
    code: "BTN",
    name: "Bhutanese Ngultrum",
  },
  {
    code: "BWP",
    name: "Botswanan Pula",
  },
  {
    code: "BYN",
    name: "Belarusian Ruble",
  },
  {
    code: "BZD",
    name: "Belize Dollar",
  },
  {
    code: "CAD",
    name: "Canadian Dollar",
  },
  {
    code: "CDF",
    name: "Congolese Franc",
  },
  {
    code: "CHF",
    name: "Swiss Franc",
  },
  {
    code: "CLF",
    name: "Chilean Unit of Account (UF)",
  },
  {
    code: "CLP",
    name: "Chilean Peso",
  },
  {
    code: "CNH",
    name: "Chinese Yuan (Offshore)",
  },
  {
    code: "CNY",
    name: "Chinese Yuan",
  },
  {
    code: "COP",
    name: "Colombian Peso",
  },
  {
    code: "CRC",
    name: "Costa Rican Colón",
  },
  {
    code: "CUC",
    name: "Cuban Convertible Peso",
  },
  {
    code: "CUP",
    name: "Cuban Peso",
  },
  {
    code: "CVE",
    name: "Cape Verdean Escudo",
  },
  {
    code: "CZK",
    name: "Czech Republic Koruna",
  },
  {
    code: "DJF",
    name: "Djiboutian Franc",
  },
  {
    code: "DKK",
    name: "Danish Krone",
  },
  {
    code: "DOP",
    name: "Dominican Peso",
  },
  {
    code: "DZD",
    name: "Algerian Dinar",
  },
  {
    code: "EGP",
    name: "Egyptian Pound",
  },
  {
    code: "ERN",
    name: "Eritrean Nakfa",
  },
  {
    code: "ETB",
    name: "Ethiopian Birr",
  },
  {
    code: "EUR",
    name: "Euro",
  },
  {
    code: "FJD",
    name: "Fijian Dollar",
  },
  {
    code: "FKP",
    name: "Falkland Islands Pound",
  },
  {
    code: "GBP",
    name: "British Pound Sterling",
  },
  {
    code: "GEL",
    name: "Georgian Lari",
  },
  {
    code: "GGP",
    name: "Guernsey Pound",
  },
  {
    code: "GHS",
    name: "Ghanaian Cedi",
  },
  {
    code: "GIP",
    name: "Gibraltar Pound",
  },
  {
    code: "GMD",
    name: "Gambian Dalasi",
  },
  {
    code: "GNF",
    name: "Guinean Franc",
  },
  {
    code: "GTQ",
    name: "Guatemalan Quetzal",
  },
  {
    code: "GYD",
    name: "Guyanaese Dollar",
  },
  {
    code: "HKD",
    name: "Hong Kong Dollar",
  },
  {
    code: "HNL",
    name: "Honduran Lempira",
  },
  {
    code: "HRK",
    name: "Croatian Kuna",
  },
  {
    code: "HTG",
    name: "Haitian Gourde",
  },
  {
    code: "HUF",
    name: "Hungarian Forint",
  },
  {
    code: "IDR",
    name: "Indonesian Rupiah",
  },
  {
    code: "ILS",
    name: "Israeli New Sheqel",
  },
  {
    code: "IMP",
    name: "Manx pound",
  },
  {
    code: "INR",
    name: "Indian Rupee",
  },
  {
    code: "IQD",
    name: "Iraqi Dinar",
  },
  {
    code: "IRR",
    name: "Iranian Rial",
  },
  {
    code: "ISK",
    name: "Icelandic Króna",
  },
  {
    code: "JEP",
    name: "Jersey Pound",
  },
  {
    code: "JMD",
    name: "Jamaican Dollar",
  },
  {
    code: "JOD",
    name: "Jordanian Dinar",
  },
  {
    code: "JPY",
    name: "Japanese Yen",
  },
  {
    code: "KES",
    name: "Kenyan Shilling",
  },
  {
    code: "KGS",
    name: "Kyrgystani Som",
  },
  {
    code: "KHR",
    name: "Cambodian Riel",
  },
  {
    code: "KMF",
    name: "Comorian Franc",
  },
  {
    code: "KPW",
    name: "North Korean Won",
  },
  {
    code: "KRW",
    name: "South Korean Won",
  },
  {
    code: "KWD",
    name: "Kuwaiti Dinar",
  },
  {
    code: "KYD",
    name: "Cayman Islands Dollar",
  },
  {
    code: "KZT",
    name: "Kazakhstani Tenge",
  },
  {
    code: "LAK",
    name: "Laotian Kip",
  },
  {
    code: "LBP",
    name: "Lebanese Pound",
  },
  {
    code: "LKR",
    name: "Sri Lankan Rupee",
  },
  {
    code: "LRD",
    name: "Liberian Dollar",
  },
  {
    code: "LSL",
    name: "Lesotho Loti",
  },
  {
    code: "LYD",
    name: "Libyan Dinar",
  },
  {
    code: "MAD",
    name: "Moroccan Dirham",
  },
  {
    code: "MDL",
    name: "Moldovan Leu",
  },
  {
    code: "MGA",
    name: "Malagasy Ariary",
  },
  {
    code: "MKD",
    name: "Macedonian Denar",
  },
  {
    code: "MMK",
    name: "Myanma Kyat",
  },
  {
    code: "MNT",
    name: "Mongolian Tugrik",
  },
  {
    code: "MOP",
    name: "Macanese Pataca",
  },
  {
    code: "MRO",
    name: "Mauritanian Ouguiya (pre-2018)",
  },
  {
    code: "MRU",
    name: "Mauritanian Ouguiya",
  },
  {
    code: "MUR",
    name: "Mauritian Rupee",
  },
  {
    code: "MVR",
    name: "Maldivian Rufiyaa",
  },
  {
    code: "MWK",
    name: "Malawian Kwacha",
  },
  {
    code: "MXN",
    name: "Mexican Peso",
  },
  {
    code: "MYR",
    name: "Malaysian Ringgit",
  },
  {
    code: "MZN",
    name: "Mozambican Metical",
  },
  {
    code: "NAD",
    name: "Namibian Dollar",
  },
  {
    code: "NGN",
    name: "Nigerian Naira",
  },
  {
    code: "NIO",
    name: "Nicaraguan Córdoba",
  },
  {
    code: "NOK",
    name: "Norwegian Krone",
  },
  {
    code: "NPR",
    name: "Nepalese Rupee",
  },
  {
    code: "NZD",
    name: "New Zealand Dollar",
  },
  {
    code: "OMR",
    name: "Omani Rial",
  },
  {
    code: "PAB",
    name: "Panamanian Balboa",
  },
  {
    code: "PEN",
    name: "Peruvian Nuevo Sol",
  },
  {
    code: "PGK",
    name: "Papua New Guinean Kina",
  },
  {
    code: "PHP",
    name: "Philippine Peso",
  },
  {
    code: "PKR",
    name: "Pakistani Rupee",
  },
  {
    code: "PLN",
    name: "Polish Zloty",
  },
  {
    code: "PYG",
    name: "Paraguayan Guarani",
  },
  {
    code: "QAR",
    name: "Qatari Rial",
  },
  {
    code: "RON",
    name: "Romanian Leu",
  },
  {
    code: "RSD",
    name: "Serbian Dinar",
  },
  {
    code: "RUB",
    name: "Russian Ruble",
  },
  {
    code: "RWF",
    name: "Rwandan Franc",
  },
  {
    code: "SAR",
    name: "Saudi Riyal",
  },
  {
    code: "SBD",
    name: "Solomon Islands Dollar",
  },
  {
    code: "SCR",
    name: "Seychellois Rupee",
  },
  {
    code: "SDG",
    name: "Sudanese Pound",
  },
  {
    code: "SEK",
    name: "Swedish Krona",
  },
  {
    code: "SGD",
    name: "Singapore Dollar",
  },
  {
    code: "SHP",
    name: "Saint Helena Pound",
  },
  {
    code: "SLL",
    name: "Sierra Leonean Leone",
  },
  {
    code: "SOS",
    name: "Somali Shilling",
  },
  {
    code: "SRD",
    name: "Surinamese Dollar",
  },
  {
    code: "SSP",
    name: "South Sudanese Pound",
  },
  {
    code: "STD",
    name: "São Tomé and Príncipe Dobra (pre-2018)",
  },
  {
    code: "STN",
    name: "São Tomé and Príncipe Dobra",
  },
  {
    code: "SVC",
    name: "Salvadoran Colón",
  },
  {
    code: "SYP",
    name: "Syrian Pound",
  },
  {
    code: "SZL",
    name: "Swazi Lilangeni",
  },
  {
    code: "THB",
    name: "Thai Baht",
  },
  {
    code: "TJS",
    name: "Tajikistani Somoni",
  },
  {
    code: "TMT",
    name: "Turkmenistani Manat",
  },
  {
    code: "TND",
    name: "Tunisian Dinar",
  },
  {
    code: "TOP",
    name: "Tongan Pa'anga",
  },
  {
    code: "TRY",
    name: "Turkish Lira",
  },
  {
    code: "TTD",
    name: "Trinidad and Tobago Dollar",
  },
  {
    code: "TWD",
    name: "New Taiwan Dollar",
  },
  {
    code: "TZS",
    name: "Tanzanian Shilling",
  },
  {
    code: "UAH",
    name: "Ukrainian Hryvnia",
  },
  {
    code: "UGX",
    name: "Ugandan Shilling",
  },
  {
    code: "USD",
    name: "United States Dollar",
  },
  {
    code: "UYU",
    name: "Uruguayan Peso",
  },
  {
    code: "UZS",
    name: "Uzbekistan Som",
  },
  {
    code: "VEF",
    name: "Venezuelan Bolívar Fuerte (Old)",
  },
  {
    code: "VES",
    name: "Venezuelan Bolívar Soberano",
  },
  {
    code: "VND",
    name: "Vietnamese Dong",
  },
  {
    code: "VUV",
    name: "Vanuatu Vatu",
  },
  {
    code: "WST",
    name: "Samoan Tala",
  },
  {
    code: "XAF",
    name: "CFA Franc BEAC",
  },
  {
    code: "XAG",
    name: "Silver Ounce",
  },
  {
    code: "XAU",
    name: "Gold Ounce",
  },
  {
    code: "XCD",
    name: "East Caribbean Dollar",
  },
  {
    code: "XDR",
    name: "Special Drawing Rights",
  },
  {
    code: "XOF",
    name: "CFA Franc BCEAO",
  },
  {
    code: "XPD",
    name: "Palladium Ounce",
  },
  {
    code: "XPF",
    name: "CFP Franc",
  },
  {
    code: "XPT",
    name: "Platinum Ounce",
  },
  {
    code: "YER",
    name: "Yemeni Rial",
  },
  {
    code: "ZAR",
    name: "South African Rand",
  },
  {
    code: "ZMW",
    name: "Zambian Kwacha",
  },
  {
    code: "ZWL",
    name: "Zimbabwean Dollar",
  },
];

export function getSupportedCurrencyByCountry(country: string): string | null {
  switch (country) {
    case "US":
      return "USD";
    case "CA":
      return "CAD";
    case "UK":
    case "GB":
      return "GBP";
    case "NZ":
      return "NZD";
    case "AU":
      return "AUD";
    case "ZA":
      return "ZAR";
    case "IE":
    case "DE":
    case "FI":
    case "IT":
    case "NL":
      return "EUR";
    case "BR":
      return "BRL";
    case "MX":
      return "MXN";
    case "AR":
      return "ARS";
    case "SE":
      return "SEK";
    case "DK":
      return "DKK";
  }

  return null;
}

export default CURRENCIES.filter((currency) => {
  return ["USD", "GBP", "AUD", "CAD", "ZAR", "NZD", "EUR", "BRL", "MXN", "ARS", "SEK", "DKK"].includes(currency.code);
});

export const COUNTRY_CODE_TO_CURRENCY: Record<string, string> = {
  BD: "BDT",
  BE: "EUR",
  BF: "XOF",
  BG: "BGN",
  BA: "BAM",
  BB: "BBD",
  WF: "XPF",
  BL: "EUR",
  BM: "BMD",
  BN: "BND",
  BO: "BOB",
  BH: "BHD",
  BI: "BIF",
  BJ: "XOF",
  BT: "BTN",
  JM: "JMD",
  BV: "NOK",
  BW: "BWP",
  WS: "WST",
  BQ: "USD",
  BR: "BRL",
  BS: "BSD",
  JE: "GBP",
  BY: "BYR",
  BZ: "BZD",
  RU: "RUB",
  RW: "RWF",
  RS: "RSD",
  TL: "USD",
  RE: "EUR",
  TM: "TMT",
  TJ: "TJS",
  RO: "RON",
  TK: "NZD",
  GW: "XOF",
  GU: "USD",
  GT: "GTQ",
  GS: "GBP",
  GR: "EUR",
  GQ: "XAF",
  GP: "EUR",
  JP: "JPY",
  GY: "GYD",
  GG: "GBP",
  GF: "EUR",
  GE: "GEL",
  GD: "XCD",
  GB: "GBP",
  GA: "XAF",
  SV: "USD",
  GN: "GNF",
  GM: "GMD",
  GL: "DKK",
  GI: "GIP",
  GH: "GHS",
  OM: "OMR",
  TN: "TND",
  JO: "JOD",
  HR: "HRK",
  HT: "HTG",
  HU: "HUF",
  HK: "HKD",
  HN: "HNL",
  HM: "AUD",
  VE: "VEF",
  PR: "USD",
  PS: "ILS",
  PW: "USD",
  PT: "EUR",
  SJ: "NOK",
  PY: "PYG",
  IQ: "IQD",
  PA: "PAB",
  PF: "XPF",
  PG: "PGK",
  PE: "PEN",
  PK: "PKR",
  PH: "PHP",
  PN: "NZD",
  PL: "PLN",
  PM: "EUR",
  ZM: "ZMK",
  EH: "MAD",
  EE: "EUR",
  EG: "EGP",
  ZA: "ZAR",
  EC: "USD",
  IT: "EUR",
  VN: "VND",
  SB: "SBD",
  ET: "ETB",
  SO: "SOS",
  ZW: "ZWL",
  SA: "SAR",
  ES: "EUR",
  ER: "ERN",
  ME: "EUR",
  MD: "MDL",
  MG: "MGA",
  MF: "EUR",
  MA: "MAD",
  MC: "EUR",
  UZ: "UZS",
  MM: "MMK",
  ML: "XOF",
  MO: "MOP",
  MN: "MNT",
  MH: "USD",
  MK: "MKD",
  MU: "MUR",
  MT: "EUR",
  MW: "MWK",
  MV: "MVR",
  MQ: "EUR",
  MP: "USD",
  MS: "XCD",
  MR: "MRO",
  IM: "GBP",
  UG: "UGX",
  TZ: "TZS",
  MY: "MYR",
  MX: "MXN",
  IL: "ILS",
  FR: "EUR",
  IO: "USD",
  SH: "SHP",
  FI: "EUR",
  FJ: "FJD",
  FK: "FKP",
  FM: "USD",
  FO: "DKK",
  NI: "NIO",
  NL: "EUR",
  NO: "NOK",
  NA: "NAD",
  VU: "VUV",
  NC: "XPF",
  NE: "XOF",
  NF: "AUD",
  NG: "NGN",
  NZ: "NZD",
  NP: "NPR",
  NR: "AUD",
  NU: "NZD",
  CK: "NZD",
  XK: "EUR",
  CI: "XOF",
  CH: "CHF",
  CO: "COP",
  CN: "CNY",
  CM: "XAF",
  CL: "CLP",
  CC: "AUD",
  CA: "CAD",
  CG: "XAF",
  CF: "XAF",
  CD: "CDF",
  CZ: "CZK",
  CY: "EUR",
  CX: "AUD",
  CR: "CRC",
  CW: "ANG",
  CV: "CVE",
  CU: "CUP",
  SZ: "SZL",
  SY: "SYP",
  SX: "ANG",
  KG: "KGS",
  KE: "KES",
  SS: "SSP",
  SR: "SRD",
  KI: "AUD",
  KH: "KHR",
  KN: "XCD",
  KM: "KMF",
  ST: "STD",
  SK: "EUR",
  KR: "KRW",
  SI: "EUR",
  KP: "KPW",
  KW: "KWD",
  SN: "XOF",
  SM: "EUR",
  SL: "SLL",
  SC: "SCR",
  KZ: "KZT",
  KY: "KYD",
  SG: "SGD",
  SE: "SEK",
  SD: "SDG",
  DO: "DOP",
  DM: "XCD",
  DJ: "DJF",
  DK: "DKK",
  VG: "USD",
  DE: "EUR",
  YE: "YER",
  DZ: "DZD",
  US: "USD",
  UY: "UYU",
  YT: "EUR",
  UM: "USD",
  LB: "LBP",
  LC: "XCD",
  LA: "LAK",
  TV: "AUD",
  TW: "TWD",
  TT: "TTD",
  TR: "TRY",
  LK: "LKR",
  LI: "CHF",
  LV: "EUR",
  TO: "TOP",
  LT: "LTL",
  LU: "EUR",
  LR: "LRD",
  LS: "LSL",
  TH: "THB",
  TF: "EUR",
  TG: "XOF",
  TD: "XAF",
  TC: "USD",
  LY: "LYD",
  VA: "EUR",
  VC: "XCD",
  AE: "AED",
  AD: "EUR",
  AG: "XCD",
  AF: "AFN",
  AI: "XCD",
  VI: "USD",
  IS: "ISK",
  IR: "IRR",
  AM: "AMD",
  AL: "ALL",
  AO: "AOA",
  AS: "USD",
  AR: "ARS",
  AU: "AUD",
  AT: "EUR",
  AW: "AWG",
  IN: "INR",
  AX: "EUR",
  AZ: "AZN",
  IE: "EUR",
  ID: "IDR",
  UA: "UAH",
  QA: "QAR",
  MZ: "MZN",
};
