import gql from "graphql-tag";
import VendorProjectFields from "./fragments/VendorProjectFields";
import GovernmentProjectFields from "./fragments/GovernmentProjectFields";
import GroupProjectFields from "./fragments/GroupProjectFields";

export default gql`
  query GetProjectsByVendor(
    $slug: String!
    $sort: FieldOrderSortInput
    $verification: VerificationInput
    $mentionedOnly: Boolean = false
    $cursor: Cursor
    $limit: Int = 20
  ) {
    projectsByVendor(
      slug: $slug
      sort: $sort
      verification: $verification
      mentionedOnly: $mentionedOnly
      cursor: $cursor
      limit: $limit
    ) {
      items {
        _id
        ...VendorProjectFields
        ...GovernmentProjectFields
        ...GroupProjectFields
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  ${VendorProjectFields}
  ${GovernmentProjectFields}
  ${GroupProjectFields}
`;
