import "antd/dist/antd.min.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import React from "react";
import { render } from "react-dom";
import Modal from "react-modal";
import GovlaunchAdmin from "./GovlaunchAdmin";

const DOM_NODE = document.querySelector<HTMLElement>("#app");

Modal.setAppElement(DOM_NODE || "");

render(<GovlaunchAdmin />, DOM_NODE);
