import base64 from "base64-url";

export function getFilteredValue(value: string | null | undefined) {
  if (typeof value !== "undefined" && value) {
    return [value];
  }

  return null;
}

export function getFilteredValueFromNumbers(value: number[] | null | undefined) {
  if (typeof value !== "undefined" && value) {
    return value.map((val) => val.toString());
  }

  return null;
}

export function getBooleanFilteredValue(value: boolean | null | undefined) {
  if (typeof value !== "undefined" && value !== null) {
    return [value.toString()];
  }

  return null;
}

export function parseState(state: string | string[] | null | undefined, defaultState: Record<string, any> | null) {
  if (typeof state !== "undefined" && state && !Array.isArray(state)) {
    const initialFiltersState = JSON.parse(base64.decode(state));
    if (initialFiltersState) return initialFiltersState;
  }
  return defaultState;
}
