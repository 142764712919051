import gql from "graphql-tag";
import StripeChargeFields from "./fragments/StripeChargeFields";
import StripeInvoiceFields from "./fragments/StripeInvoiceFields";
import CompanySubscription from "./fragments/CompanySubscription";

export default gql`
  query CompanyAccountBillingInfo($slug: String!) {
    company(slug: $slug) {
      _id
      slug
      canEarnCreditsUntil
      earnableCreditAmount
      stripeCustomerId
      stripePlan {
        name
        recurrency
        price
        viewerIsSubscribed
        upgradeToPlan {
          planId
          name
        }
      }
      planUsage {
        teamMembers
        products
        enrichedProducts
        groups
        sharingPage
      }
      hasCustomLimits
      customLimits {
        products
      }
      plan {
        _id
        name
        limits {
          teamMembers
          products
          enrichedProducts
          groups
          sharingPage
          inviteGovernments
          searchAccess
        }
      }
      originalPlan {
        limits {
          teamMembers
          products
          enrichedProducts
          groups
          sharingPage
          inviteGovernments
          searchAccess
        }
      }
      charges {
        ...StripeChargeFields
      }
      invoices {
        ...StripeInvoiceFields
      }
      billing {
        card {
          brand
          lastFourDigits
          expirationMonth
          expirationYear
        }
      }
      balanceTransactions {
        type
        amount
        government {
          _id
          name
        }
        inviter {
          _id
          fullName
        }
        endingBalance
        description
        createdAt
      }
      usersCanAccessAccountPage
      usersCanRequestContentTranslation
      ...CompanySubscription
    }
  }

  ${StripeChargeFields}
  ${StripeInvoiceFields}
  ${CompanySubscription}
`;
