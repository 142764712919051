import React, { SVGProps } from "react";

const ImageToolbarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={18} {...props}>
    <g fill="none">
      <path fill="#F25A78" d="M1.1 6.6H22v6.6H1.1z" />
      <path
        d="M1.1 13.2v2.475c0 .456.38.825.85.825h19.2c.47 0 .85-.37.85-.825V13.2H1.1zm20.05-9.9h-4.927L14.758.456a.853.853 0 00-.76-.456H9.102a.853.853 0 00-.76.456L6.878 3.3h-1.53v-.825a.837.837 0 00-.85-.825h-.85a.838.838 0 00-.849.825V3.3h-.85a.838.838 0 00-.849.825V6.6H22V4.125a.837.837 0 00-.85-.825z"
        fill="#CEF"
      />
      <path fill="#57555C" d="M16.5 10.45a4.95 4.95 0 11-9.902-.002 4.95 4.95 0 019.902.002z" />
      <path
        fill="#FFF"
        d="M11 6.6c1.82 0 3.3 1.48 3.3 3.3 0 1.82-1.48 3.3-3.3 3.3-1.82 0-3.3-1.48-3.3-3.3 0-1.82 1.48-3.3 3.3-3.3z"
      />
      <path fill="#000" d="M17.05 6.6a.55.55 0 100-1.1.55.55 0 000 1.1z" />
      <path
        fill="#000"
        d="M20.71 3.352h-4.718L14.63.695A1.286 1.286 0 0013.477 0H8.523C8.032 0 7.59.266 7.37.695L6.008 3.352h-.852v-.419c0-.693-.578-1.257-1.289-1.257h-.86c-.71 0-1.288.564-1.288 1.257v.42h-.43C.58 3.352 0 3.915 0 4.61v11.733c0 .693.578 1.257 1.29 1.257h19.42c.712 0 1.29-.564 1.29-1.257V4.61c0-.694-.578-1.258-1.29-1.258zM.86 7.54h5.433a5.338 5.338 0 000 5.87H.859V7.54zm5.413 2.937c0-2.565 2.14-4.61 4.727-4.61 2.584 0 4.727 2.042 4.727 4.61 0 2.565-2.14 4.61-4.727 4.61-2.59 0-4.727-2.047-4.727-4.61zm10.313 0a5.338 5.338 0 00-.879-2.933h5.434v5.867h-5.434a5.34 5.34 0 00.879-2.934zM8.139 1.07a.429.429 0 01.384-.232h4.954c.164 0 .31.089.384.232l1.17 2.282H6.97L8.14 1.07zm-5.56 1.863c0-.23.192-.419.429-.419h.86c.236 0 .429.188.429.42v.418H2.578v-.419zM1.288 4.19H20.71c.237 0 .43.188.43.42v2.095h-6.112A5.659 5.659 0 0011 5.029c-1.522 0-2.98.609-4.03 1.676H.86V4.61c0-.232.192-.42.43-.42zM20.71 16.762H1.289a.425.425 0 01-.43-.42v-2.094h6.112A5.66 5.66 0 0011 15.924c1.522 0 2.98-.61 4.03-1.676h6.11v2.095a.425.425 0 01-.43.419z"
      />
      <path
        fill="#000"
        d="M12.787 2.2h-2.475c-.227 0-.412.246-.412.55 0 .304.185.55.412.55h2.476c.227 0 .412-.246.412-.55 0-.304-.185-.55-.412-.55zM11.55 14.3a3.854 3.854 0 003.85-3.85 3.854 3.854 0 00-3.85-3.85 3.854 3.854 0 00-3.85 3.85 3.854 3.854 0 003.85 3.85zm0-6.844a2.998 2.998 0 012.994 2.994 2.998 2.998 0 01-2.994 2.994 2.998 2.998 0 01-2.994-2.994 2.998 2.998 0 012.994-2.994z"
      />
      <path
        fill="#000"
        d="M9.167 11a.367.367 0 00.366-.367c0-.606.494-1.1 1.1-1.1a.367.367 0 000-.733c-1.01 0-1.833.822-1.833 1.833 0 .203.164.367.367.367zM19.25 6.6h1.1a.55.55 0 000-1.1h-1.1a.55.55 0 000 1.1z"
      />
    </g>
  </svg>
);

export default ImageToolbarIcon;
