import { Tag, Table, TableProps } from "antd";
import React from "react";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import moment from "moment";
import { Link } from "react-router-dom";
import { IGovernment, IGovernmentUser } from "../../../../types/types";
import { IAllGovernmentsQuery } from "../../../queries/__generated__/AllGovernmentsQuery.generated";
import notUndefinedOrNull from "../../../utils/notUndefinedOrNull";
import GovernmentActions from ".././GovernmentActions";
import { useQuery } from "react-apollo";
import CenteredLoader from "../../../CenteredLoader";
import GovernmentsAdminMetadata from "../../../queries/GovernmentsAdminMetadata";
import {
  IGovernmentsAdminMetadataQuery,
  IGovernmentsAdminMetadataQueryVariables,
} from "../../../queries/__generated__/GovernmentsAdminMetadata.generated";
import { IGovernmentsTableState, IGovernmentTableFilters } from "./ManageGovernments";

interface IGovernmentsTableProps<T> {
  loading: boolean;
  governments: T[];
  title?: TableProps<any>["title"];
  footer?: TableProps<any>["footer"];
  onChange: (_: any, filters: any, sorter: any) => void;
  state: IGovernmentsTableState | null;
  filterByCountry?: string;
}

export type TGovernment = NonNullable<NonNullable<IAllGovernmentsQuery["allGovernments"]>["items"]>[0];

export default function GovernmentsTable({
  loading,
  governments,
  title,
  footer,
  onChange,
  state,
  filterByCountry,
}: IGovernmentsTableProps<TGovernment>) {
  const { data: governmentsAdminMetadataData, loading: isGovernmentsAdminMetadataLoading } = useQuery<
    IGovernmentsAdminMetadataQuery,
    IGovernmentsAdminMetadataQueryVariables
  >(GovernmentsAdminMetadata);

  if (isGovernmentsAdminMetadataLoading) {
    return <CenteredLoader />;
  }

  if (!governmentsAdminMetadataData) {
    return <div>Failed to load metadata</div>;
  }

  const { availableStatesWithCountries, governmentTypes } = governmentsAdminMetadataData;

  const getSortOrder = (columnKey: string) => {
    if (state && state.sorter && state.sorter.columnKey === columnKey) {
      return state.sorter.order;
    }
    return undefined;
  };

  const getFilterValue = (filterKey: keyof IGovernmentTableFilters) => {
    if (state && state.filters) {
      return state.filters[filterKey];
    }
    return null;
  };

  return (
    <Table
      rowKey="_id"
      loading={loading}
      bordered={true}
      pagination={false}
      dataSource={governments}
      scroll={{
        x: true,
      }}
      title={title}
      footer={footer}
      onChange={onChange}
      columns={[
        {
          key: "name",
          title: "Name",
          dataIndex: "name",
          sorter: true,
          sortOrder: getSortOrder("name"),
          render: (_: string, government: TGovernment) => (
            <div
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ProfilePicture
                size={32}
                image={government.logo}
                innovator={government.isInnovator}
                name={government.name}
                css={{
                  whiteSpace: "nowrap",
                }}
              />

              <Spacing left={5}>
                <Link to={`/governments/${government.slug}`}>{government.city.name}</Link>
              </Spacing>
            </div>
          ),
        },
        {
          key: "state",
          title: "State",
          filters: availableStatesWithCountries
            .filter(({ country }) => country === filterByCountry)
            .map((row) => {
              return {
                text: row.friendlyStateName,
                value: row.state,
              };
            })
            .sort((a, b) => a.text.localeCompare(b.text)),
          filteredValue: getFilterValue("state"),
          sortOrder: getSortOrder("state"),
          render: (government: TGovernment) => government.city.stateName,
          sorter: true,
        },
        {
          key: "county",
          title: "County",
          render: (government: TGovernment) => government.city.county,
          sorter: false,
        },
        {
          // We don't have `Type` anymore. Should always use `governmentType`
          key: "governmentType",
          title: "Type",
          filters: governmentTypes
            .map((type) => {
              return {
                text: type,
                value: type,
              };
            })
            .sort((a, b) => a.text.localeCompare(b.text)),
          filteredValue: getFilterValue("governmentType"),
          render: (government: IGovernment) => government.city.type,
        },
        {
          key: "tags",
          title: "Tags",
          dataIndex: "tags",
          filters: [
            {
              text: "Sharing",
              value: "SHARING",
            },
            {
              text: "Ownerless",
              value: "NOT_OWNED",
            },
            {
              text: "Innovators",
              value: "INNOVATORS",
            },
          ],
          filterMultiple: false,
          filteredValue: getFilterValue("tags"),
          render: (_: boolean, government: TGovernment) => (
            <span>
              {government.isInnovator && <Tag color="geekblue">Innovator</Tag>}
              {!government.owner && <Tag color="red">Ownerless</Tag>}
              {government.enableSharing && <Tag color="blue">Sharing</Tag>}
            </span>
          ),
        },
        {
          title: "Owner",
          dataIndex: "owner",
          filters: [
            {
              text: "Owned",
              value: "OWNED",
            },
            {
              text: "Not Owned",
              value: "NOT_OWNED",
            },
          ],
          filterMultiple: false,
          filteredValue: getFilterValue("owner"),
          render: (owner: IGovernmentUser) => {
            if (!owner) {
              return "Not owned";
            }
            return (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ProfilePicture
                  size={32}
                  image={owner.avatar}
                  innovator={owner.isInnovator}
                  name={owner.fullName}
                  css={{
                    whiteSpace: "nowrap",
                  }}
                />

                <Spacing left={5}>
                  <Link to={`/users/${owner._id}`}>{owner.fullName}</Link>
                </Spacing>
              </div>
            );
          },
        },
        {
          title: "# of Projects",
          dataIndex: "projectsCount",
        },
        {
          title: "# of Stories",
          dataIndex: "storiesCount",
        },
        {
          title: "Activated",
          key: "activatedAt",
          sorter: true,
          sortOrder: getSortOrder("activatedAt"),
          render: (government: TGovernment) => {
            return government.activatedAt ? moment(government.activatedAt).format("MM/DD/YYYY") : "N/A";
          },
        },
        {
          title: "Actions",
          render: (government: TGovernment) => {
            return <GovernmentActions government={government} />;
          },
        },
      ].filter(notUndefinedOrNull)}
    />
  );
}
