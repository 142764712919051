import React, { useEffect, useState } from "react";
import { AnyObject } from "react-final-form";
import { IBulkTaggingModifyStrategy, ITagType } from "../../../../types/types";
import TagsField from "@govlaunch/web/src/tag/TagsField";
import * as palette from "@govlaunch/palette";
import { startCase } from "lodash";
import { IGovernmentProjectFieldsFragment } from "../../../queries/fragments/__generated__/GovernmentProjectFields.generated";
import { IGroupProjectFieldsFragment } from "../../../queries/fragments/__generated__/GroupProjectFields.generated";
import { IVendorProjectFieldsFragment } from "../../../queries/fragments/__generated__/VendorProjectFields.generated";
import BulkTaggingFormValidationMessage, {
  IValidateProjectsWithTagsResult,
  validateProjectsWithTags,
} from "./BulkTaggingFormValidationMessage";
import SubmitButton from "./SubmitButton";

interface IBulkTaggingInnerFormProps {
  projects: Array<IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment>;
  submitDisabled: boolean;
  submitting: boolean;
  submitError: any;
  values: any;
  handleSubmit: (
    event?: Partial<Pick<React.SyntheticEvent<Element, Event>, "preventDefault" | "stopPropagation">> | undefined,
  ) => Promise<AnyObject | undefined> | undefined;
}

export default function BulkTaggingInnerForm({
  handleSubmit,
  submitting,
  submitError,
  values,
  submitDisabled,
  projects,
}: IBulkTaggingInnerFormProps) {
  const [actionValid, setActionValid] = useState<IValidateProjectsWithTagsResult>(
    validateProjectsWithTags(convertValueToEnum(values.action), projects, values.tags),
  );

  useEffect(() => {
    setActionValid(validateProjectsWithTags(convertValueToEnum(values.action), projects, values.tags));
  }, [values, projects]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <div css={{
 display: "flex", flexDirection: "column", minHeight: 360, justifyContent: "space-between" 
}}
      >
        <div>
          <label>Tags</label>
          <TagsField fieldName="tags" type={[ITagType.Product, ITagType.Custom]} />
        </div>
        <div>
          <SubmitButton
            disabled={submitDisabled || submitting || values.tags.length === 0 || !actionValid.isValid}
            submitting={submitting}
            submitText={`${startCase(values.action)} tags`}
          />
          {!actionValid.isValid && (
            <BulkTaggingFormValidationMessage
              invalidProjects={actionValid.invalidProjects}
              action={convertValueToEnum(values.action)}
            />
          )}
        </div>

        {submitError && (
          <div
            css={{
              marginTop: 24,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              color: palette.red,
            }}
          >
            {submitError}
          </div>
        )}
      </div>
    </form>
  );
}

export function convertValueToEnum(action: string): IBulkTaggingModifyStrategy {
  switch (action) {
    case "add":
      return IBulkTaggingModifyStrategy.Add;
    case "overwrite":
      return IBulkTaggingModifyStrategy.Overwrite;
    case "remove":
      return IBulkTaggingModifyStrategy.Remove;
    default:
      return IBulkTaggingModifyStrategy.Add;
  }
}
