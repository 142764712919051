import React, { useState } from "react";
import StoriesAutoComplete, { TAutoCompleteStory } from "../stories/StoriesAutoComplete";
import { Modal } from "antd";
import { Button, CloseButton } from "@govlaunch/web";
import { IBlockLayout, IFeaturedStoriesData, TBlockLayoutData } from "./blocks/blockProps";
import { useQuery } from "react-apollo";
import StoriesByIds from "../../queries/StoriesByIds";
import { IStoriesByIdsQuery, IStoriesByIdsQueryVariables } from "../../queries/__generated__/StoriesByIds.generated";

interface IStoriesAutoCompleteModalProps {
  block: IBlockLayout;
  saveBlockData: (block: IBlockLayout, data: TBlockLayoutData) => void;
  isOpen: boolean;
  onClose: () => void;
}

export default function StoriesAutoCompleteModal({
  isOpen,
  onClose,
  block,
  saveBlockData,
}: IStoriesAutoCompleteModalProps) {
  const { data } = useQuery<IStoriesByIdsQuery, IStoriesByIdsQueryVariables>(StoriesByIds, {
    variables: {
      ids: (block.blockData as IFeaturedStoriesData).storyIds || [],
    },
  });

  const existingStoriesData = data && data.storiesByIds ? data.storiesByIds : [];

  const [stories, setStories] = useState<TAutoCompleteStory[]>(existingStoriesData as TAutoCompleteStory[]);

  const handleRemove = (storyId: any) => {
    setStories((stories || []).filter((story) => story._id !== storyId));
  };

  return (
    <Modal title="Find a story..." visible={isOpen} onCancel={onClose} footer={null} width={600}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <StoriesAutoComplete
          onSelect={(story: TAutoCompleteStory) => {
            setStories((stories || []).concat(story));
          }}
        />
        {stories.map((story) => (
          <div
            key={story.slug}
            css={{
              marginTop: "12px",
            }}
          >
            <StoryRow title={story.title} handleRemove={() => handleRemove(story._id)} />
          </div>
        ))}
        <Button
          mt={3}
          variant="primary"
          width={100}
          size="sm"
          onClick={() => {
            saveBlockData(block, {
              ...block.blockData,
              storyIds: stories.length > 0 ? stories.map((story) => story._id) : [],
            });
            onClose();
          }}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}

interface IStoryRowProps {
  title: string;
  handleRemove: () => void;
}

function StoryRow({ title, handleRemove }: IStoryRowProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        css={{
          color: "#086bef",
          fontWeight: 700,
          fontSize: 16,
          lineHeight: "24px",
        }}
      >
        {title}
      </div>
      <CloseButton
        size="sm"
        color="white"
        bg="red.500"
        borderRadius={25}
        _hover={{
          bg: "red.600",
        }}
        onClick={() => handleRemove()}
      />
    </div>
  );
}
