import { Component } from "react";
import PropTypes from "prop-types";

interface IScrolToTopOnMountProps {
  children: any;
}

export default class ScrollToTopOnMount extends Component<IScrolToTopOnMountProps> {
  static propTypes = {
    children: PropTypes.any.isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return this.props.children;
  }
}
