import gql from "graphql-tag";

export default gql`
  query ListCollectionSponsoringSales($description: String) {
    listAllCollectionSponsoringSales(description: $description) {
      _id
      vendor {
        _id
        name
        slug
        logo
      }
      description
      type
      sponsorshipLevel
      includedCollections {
        _id
        name
        slug
      }
      price
      isActive
      stripeState {
        latestInvoiceId
        latestInvoiceStatus
        latestInvoiceHostedPayUrl
      }
      paymentSchedule
      createdAt
      updatedAt
      archivedAt
      archivedBy {
        ... on VendorUser {
          _id
          fullName
        }
        ... on GovernmentUser {
          _id
          fullName
        }
        ... on PendingUser {
          _id
          fullName
        }
      }
      ... on RecurringCollectionSponsoringSale {
        _id
        renewsAt
        cancelsAt
      }
      ... on LumpSumCollectionSponsoringSale {
        _id
        expirationDate
      }
    }
  }
`;
