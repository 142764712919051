import React from "react";
import * as palette from "@govlaunch/palette";

export default function ShowMoreButton({ ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      role="button"
      aria-label="Show more"
      css={{
        cursor: "pointer",
        minHeight: 40,
        textTransform: "uppercase",
        color: palette.sealBlue,
        fontSize: 12,
        textAlign: "center",
        "&:hover": {
          color: palette.darkGray,
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      {...props}
    >
      Show more
    </div>
  );
}
