import gql from "graphql-tag";

export default gql`
  mutation TogglePostFeatured($id: ObjectId!) {
    togglePostFeatured(id: $id) {
      ... on GovernmentPost {
        _id
        featuredAt
      }
      ... on GroupPost {
        _id
        featuredAt
      }
    }
  }
`;
