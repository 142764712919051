import React from "react";
import { IGetTagBySlugQuery } from "../../../../queries/__generated__/GetTagBySlug.generated";
import UsersWithSearch from "../../../users/tables/UsersWithSearch";

interface ITagUsersTabProps {
  tag: IGetTagBySlugQuery["tag"];
}

export default function TagUsersTab({ tag }: ITagUsersTabProps) {
  if (!tag) {
    return null;
  }

  return (
    <div
      css={{
        padding: 20,
      }}
    >
      <UsersWithSearch filterByTag={tag._id} historyOrigin={`/tags/${tag.slug}/users`} />
    </div>
  );
}
