import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import { useNavigate } from "react-router";
import TableRowActions from "../../../components/table/TableRowActions";
import DeleteLandingPageEvent from "../../../mutations/DeleteLandingPageEvent";
import {
  IDeleteLandingPageEventMutation,
  IDeleteLandingPageEventMutationVariables,
} from "../../../mutations/__generated__/DeleteLandingPageEvent.generated";

interface IEventsTableActions {
  eventId: string;
}

export default function EventsTableActions({ eventId }: IEventsTableActions) {
  const navigate = useNavigate();

  const [deleteEvent] = useMutation<IDeleteLandingPageEventMutation, IDeleteLandingPageEventMutationVariables>(
    DeleteLandingPageEvent,
    {
      refetchQueries: ["GetLandingPageEvents"],
    },
  );

  return (
    <TableRowActions>
      {({ onClick }: any) => (
        <>
          <li>
            <Button size="middle" type="link" icon={<EditOutlined />} onClick={() => navigate(`/events/${eventId}`)}>
              Edit
            </Button>
          </li>
          <li>
            <Popconfirm
              title="Are you sure?"
              onConfirm={onClick(() => {
                deleteEvent({
                  variables: {
                    id: eventId,
                  },
                  refetchQueries: ["GetLandingPageEvents"],
                });
              })}
            >
              <Button
                size="middle"
                css={{
                  color: "red",
                }}
                type="link"
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Popconfirm>
          </li>
        </>
      )}
    </TableRowActions>
  );
}
