import { Col, Grid, RowWrap, LoadingIndicator } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { Button as AntdButton, Checkbox, DatePicker, notification, Alert } from "antd";
import upperFirst from "lodash/fp/upperFirst";
import moment from "moment";
import React from "react";
import { Mutation, Query } from "react-apollo";
import { Field, Form } from "react-final-form";
import { ICollectionMethod, IPlanRecurrency } from "../../../../types/types";
import ExtendVendorCreditsEligibilityDate from "../../../mutations/ExtendVendorCreditsEligibilityDate";
import SetVendorCreditAmount from "../../../mutations/SetVendorCreditAmount";
import SetVendorCreditsEligibilityDate from "../../../mutations/SetVendorCreditsEligibilityDate";
import ToggleVendorCreditsEligibility from "../../../mutations/ToggleVendorCreditsEligibility";
import {
  IExtendVendorCreditsEligibilityDateMutation,
  IExtendVendorCreditsEligibilityDateMutationVariables,
} from "../../../mutations/__generated__/ExtendVendorCreditsEligibilityDate.generated";
import {
  ISetVendorCreditAmountMutation,
  ISetVendorCreditAmountMutationVariables,
} from "../../../mutations/__generated__/SetVendorCreditAmount.generated";
import {
  ISetVendorCreditsEligibilityDateMutation,
  ISetVendorCreditsEligibilityDateMutationVariables,
} from "../../../mutations/__generated__/SetVendorCreditsEligibilityDate.generated";
import {
  IToggleVendorCreditsEligibilityMutation,
  IToggleVendorCreditsEligibilityMutationVariables,
} from "../../../mutations/__generated__/ToggleVendorCreditsEligibility.generated";
import { ICompanyAccountBillingInfoQuery } from "../../../queries/__generated__/CompanyAccountBillingInfoQuery.generated";
import CurrencyInput from "../../billing/CurrencyInput";
import BalanceTransactions from "./BalanceTransactions";
import {
  IGetDynamicConfigsQueryVariables,
  IGetDynamicConfigsQuery,
} from "../../../queries/__generated__/GetDynamicConfigs.generated";
import GetDynamicConfigs from "../../../queries/GetDynamicConfigs";
import pluralize from "pluralize";
import CurrentBillingPirce from "./CurrentBillingPrice";
import CollectionMethodCombo from "./CollectionMethodCombo";
import StripeLnk from "./StripeLink";

interface ICurrentBillingProps {
  company: NonNullable<ICompanyAccountBillingInfoQuery["company"]>;
}

export default function CurrentBilling({ company }: ICurrentBillingProps) {
  if (
    company.subscription &&
    company.stripePlan &&
    company.subscription.__typename === "ScheduledToCancelSubscription"
  ) {
    return (
      <div>
        <Grid>
          <RowWrap>
            <Col width={[1, 1, 1, 8 / 12]} mx="auto">
              <div
                css={{
                  padding: 24,
                  backgroundColor: palette.white,
                  border: "solid 0.5px #e6e8ea",
                }}
              >
                <div
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h1
                    css={{
                      margin: 0,
                      color: "#212121",
                      fontWeight: 600,
                      fontSize: 20,
                    }}
                  >
                    Billing
                  </h1>

                  {company.stripeCustomerId && <StripeLnk stripeCustomerId={company.stripeCustomerId} />}
                </div>

                <hr
                  css={{
                    width: "100%",
                    marginTop: 20,
                    border: "none",
                    borderBottom: `solid 1px #e6e8ea`,
                  }}
                />

                <div
                  css={{
                    paddingTop: 10,
                  }}
                >
                  <p
                    css={{
                      margin: 0,
                      fontSize: 16,
                      lineHeight: 1.33,
                      color: "#949195",

                      "& > strong": {
                        color: palette.text,
                        fontWeight: 600,
                      },
                    }}
                  >
                    Your <strong>{company.stripePlan.name}</strong> plan is in cancellation period and will cancel in{" "}
                    <strong>{moment(company.subscription.willCancelAt).format("MMMM DD, YYYY")}</strong>.
                  </p>
                </div>
              </div>
            </Col>
          </RowWrap>
        </Grid>
      </div>
    );
  }

  return (
    <div>
      <Grid>
        <RowWrap>
          <Col width={[1, 1, 1, 8 / 12]} mx="auto">
            <div
              css={{
                padding: 24,
                backgroundColor: palette.white,
                border: "solid 0.5px #e6e8ea",
              }}
            >
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1
                  css={{
                    margin: 0,
                    color: "#212121",
                    fontWeight: 600,
                    fontSize: 20,
                  }}
                >
                  Billing
                </h1>

                {company.stripeCustomerId && <StripeLnk stripeCustomerId={company.stripeCustomerId} />}
              </div>

              <hr
                css={{
                  width: "100%",
                  marginTop: 20,
                  border: "none",
                  borderBottom: `solid 1px #e6e8ea`,
                }}
              />

              <div
                css={{
                  paddingTop: 20,
                }}
              >
                <div
                  css={{
                    display: "grid",
                    gridTemplateColumns: "max-content 1fr",
                    gridColumnGap: 40,
                    gridRowGap: 30,
                  }}
                >
                  <div
                    css={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#949195",
                    }}
                  >
                    {company.stripePlan ? (
                      <span>
                        Current {company.stripePlan.recurrency === IPlanRecurrency.Monthly ? "Monthly" : "Yearly"} Bill
                      </span>
                    ) : (
                      <span>Current Bill</span>
                    )}
                  </div>

                  <div
                    css={{
                      display: "grid",
                      gridRowGap: 8,
                    }}
                  >
                    <strong
                      css={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: palette.text,
                      }}
                    >
                      <CurrentBillingPirce
                        stripePlan={company.stripePlan}
                        freeSubscription={
                          company.subscription &&
                          company.subscription.__typename === "ActiveSubscription" &&
                          company.subscription.freeSubscription
                        }
                      />
                    </strong>
                  </div>

                  <div
                    css={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#949195",
                    }}
                  >
                    Next Payment Due
                  </div>

                  {company.subscription && company.subscription.__typename === "ActiveSubscription" && (
                    <div
                      css={{
                        display: "grid",
                        gridRowGap: 8,
                      }}
                    >
                      <strong
                        css={{
                          fontSize: 16,
                          fontWeight: 600,
                          color: palette.text,
                        }}
                      >
                        {company.subscription.nextPaymentDate && !company.subscription.freeSubscription
                          ? moment(company.subscription.nextPaymentDate).format("MMMM DD, YYYY")
                          : "Not available"}
                      </strong>

                      {company.subscription.nextPaymentAmount !== null &&
                        !company.subscription.freeSubscription &&
                        company.subscription.nextPaymentAmount > 0 && (
                          <div>
                            <span
                              css={{
                                fontSize: 13,
                                color: "#797979",
                              }}
                            >
                              {company.subscription.collectionMethod &&
                              company.subscription.collectionMethod === ICollectionMethod.ChargeAutomatically
                                ? `We'll charge $${(company.subscription.nextPaymentAmount / 100).toFixed(
                                    2,
                                  )} by this date`
                                : "We'll email you an invoice by this date"}
                            </span>
                          </div>
                        )}
                    </div>
                  )}

                  {company.subscription && company.subscription.__typename === "OverdueSubscription" && (
                    <div
                      css={{
                        display: "grid",
                        gridRowGap: 8,
                      }}
                    >
                      <strong
                        css={{
                          fontSize: 16,
                          fontWeight: 600,
                          color: palette.text,
                        }}
                      >
                        {company.subscription.nextPaymentAttempt
                          ? moment(company.subscription.nextPaymentAttempt).format("MMMM DD")
                          : "Not available"}
                      </strong>

                      {company.subscription.nextPaymentAmount && (
                        <div>
                          <span
                            css={{
                              fontSize: 13,
                              color: "#ff7171",
                            }}
                          >
                            Your payment of ${(company.subscription.nextPaymentAmount / 100).toFixed(2)} is overdue.
                          </span>
                        </div>
                      )}
                    </div>
                  )}

                  {!company.subscription && (
                    <span
                      css={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: palette.text,
                      }}
                    >
                      N/A
                    </span>
                  )}

                  {company.subscription &&
                    company.subscription.__typename === "ActiveSubscription" &&
                    company.subscription.trialPeriod !== null &&
                    company.subscription.trialPeriod > 0 && (
                      <>
                        <div
                          css={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#949195",
                          }}
                        >
                          Trial Period
                        </div>

                        <div
                          css={{
                            display: "grid",
                            gridRowGap: 8,
                          }}
                        >
                          <strong
                            css={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: palette.text,
                            }}
                          >
                            {company.subscription.trialPeriod}{" "}
                            {pluralize("day", company.subscription.trialPeriod || 0, false)}, started on{" "}
                            {moment(company.subscription.subscribedAt).format("MM/DD/YYYY")}
                          </strong>
                        </div>
                      </>
                    )}

                  <div
                    css={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#949195",
                    }}
                  >
                    Payment Method
                  </div>

                  <div
                    css={{
                      display: "grid",
                      gridRowGap: 10,
                    }}
                  >
                    {company && company.subscription && company.billing && company.billing.card ? (
                      <div>
                        {company.subscription.__typename === "ActiveSubscription" && (
                          <strong
                            css={{
                              fontSize: 16,
                              fontWeight: 600,
                              color: palette.text,
                            }}
                          >
                            {upperFirst(company.billing.card.brand)} ending **** {company.billing.card.lastFourDigits}
                          </strong>
                        )}
                      </div>
                    ) : (
                      <span
                        css={{
                          fontSize: 16,
                          fontWeight: 600,
                          color: palette.text,
                        }}
                      >
                        N/A
                      </span>
                    )}
                  </div>

                  {company &&
                    company.subscription &&
                    company.subscription.__typename === "ActiveSubscription" &&
                    company.billing &&
                    company.billing.card && (
                      <>
                        <div
                          css={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: "#949195",
                          }}
                        >
                          Collection Method
                        </div>

                        <div
                          css={{
                            display: "grid",
                            gridRowGap: 10,
                          }}
                        >
                          {/** 
                         * <span
                          css={{
                            fontSize: 16,
                            fontWeight: 600,
                            color: palette.text,
                          }}
                        >
                          {company.subscription.collectionMethod === ICollectionMethod.ChargeAutomatically
                            ? "Charge Automatically"
                            : "Send Invoice"}
                        </span>*/}

                          <CollectionMethodCombo
                            subscriptionId={company.subscription._id}
                            collectionMethod={company.subscription.collectionMethod || "unknown"}
                          />
                        </div>
                      </>
                    )}

                  <div
                    css={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#949195",
                    }}
                  >
                    Current Credits
                  </div>

                  <Query<IGetDynamicConfigsQuery, IGetDynamicConfigsQueryVariables> query={GetDynamicConfigs}>
                    {({ data, loading }) => {
                      if (loading) {
                        return <LoadingIndicator />;
                      }

                      if (!data) {
                        return (
                          <div
                            css={{
                              padding: "10px 20px",
                            }}
                          >
                            <Alert
                              message="Whoops"
                              description="Missing configuration data"
                              type="error"
                              showIcon={true}
                            />
                          </div>
                        );
                      }

                      const vendorCreditAmountConfig = data.getDynamicConfigs.find((candidate) => {
                        return candidate.key === "vendorCreditAmount";
                      });

                      const vendorCreditDaysActiveConfig = data.getDynamicConfigs.find((candidate) => {
                        return candidate.key === "vendorCreditDaysActive";
                      });

                      if (!vendorCreditAmountConfig || !vendorCreditDaysActiveConfig) {
                        return (
                          <div
                            css={{
                              padding: "10px 20px",
                            }}
                          >
                            <Alert
                              message="Whoops"
                              description="Missing configuration key"
                              type="error"
                              showIcon={true}
                            />
                          </div>
                        );
                      }

                      return (
                        <div
                          css={{
                            display: "grid",
                            gridRowGap: 10,
                          }}
                        >
                          <div
                            css={{
                              display: "grid",
                              gridTemplateColumns: "repeat(2, max-content)",
                              gridColumnGap: 6,
                            }}
                          >
                            <BalanceTransactions transactions={company.balanceTransactions}>
                              {(toggleOpen) => (
                                <button
                                  type="button"
                                  onClick={toggleOpen}
                                  css={{
                                    margin: 0,
                                    padding: 0,
                                    textDecoration: "underline",
                                    backgroundColor: "transparent",
                                    border: "none",
                                    outline: "none",
                                    cursor: "pointer",
                                    fontSize: 16,
                                    fontWeight: 600,
                                    color: palette.innovatorBlue,
                                  }}
                                >
                                  {formatDollarAmount(
                                    company.balanceTransactions.length > 0
                                      ? company.balanceTransactions[0].endingBalance
                                      : 0,
                                  )}
                                </button>
                              )}
                            </BalanceTransactions>
                          </div>

                          {company.canEarnCreditsUntil &&
                            vendorCreditDaysActiveConfig.value > 0 &&
                            vendorCreditAmountConfig.value > 0 && (
                              <div>
                                <span
                                  css={{
                                    fontSize: 13,
                                    color: "#797979",
                                  }}
                                >
                                  Credits can be earned until{" "}
                                  {moment(company.canEarnCreditsUntil).format("MMMM DD, YYYY")}.
                                </span>

                                <div
                                  css={{
                                    display: "grid",
                                    gridAutoColumns: "max-content",
                                    gridAutoFlow: "column",
                                    gridColumnGap: 12,
                                    alignItems: "center",
                                    marginTop: 8,
                                  }}
                                >
                                  <Mutation<
                                    IExtendVendorCreditsEligibilityDateMutation,
                                    IExtendVendorCreditsEligibilityDateMutationVariables
                                  >
                                    mutation={ExtendVendorCreditsEligibilityDate}
                                    variables={{
                                      vendorId: company._id,
                                    }}
                                  >
                                    {(extendVendorCreditsEligibilityDate, { loading }) => (
                                      <AntdButton
                                        loading={loading}
                                        onClick={() => extendVendorCreditsEligibilityDate()}
                                      >
                                        Extend for {vendorCreditDaysActiveConfig.value} days
                                      </AntdButton>
                                    )}
                                  </Mutation>

                                  <strong>OR</strong>

                                  <div>
                                    <Mutation<
                                      ISetVendorCreditsEligibilityDateMutation,
                                      ISetVendorCreditsEligibilityDateMutationVariables
                                    >
                                      mutation={SetVendorCreditsEligibilityDate}
                                    >
                                      {(setVendorCreditsEligibilityDate) => (
                                        <DatePicker
                                          defaultValue={
                                            company.canEarnCreditsUntil
                                              ? moment(company.canEarnCreditsUntil)
                                              : undefined
                                          }
                                          onChange={(nextDate) => {
                                            if (nextDate) {
                                              setVendorCreditsEligibilityDate({
                                                variables: {
                                                  vendorId: company._id,
                                                  canEarnCreditsUntil: nextDate.format("YYYY-MM-DD"),
                                                },
                                              });
                                            }
                                          }}
                                        />
                                      )}
                                    </Mutation>
                                  </div>
                                </div>
                              </div>
                            )}

                          {company.canEarnCreditsUntil &&
                            vendorCreditDaysActiveConfig.value > 0 &&
                            vendorCreditAmountConfig.value > 0 && (
                              <div>
                                <Mutation<ISetVendorCreditAmountMutation, ISetVendorCreditAmountMutationVariables>
                                  mutation={SetVendorCreditAmount}
                                >
                                  {(setVendorCreditAmount) => (
                                    <Form
                                      initialValues={{
                                        vendorCredits: company.earnableCreditAmount || 0,
                                      }}
                                      onSubmit={async (values) => {
                                        await setVendorCreditAmount({
                                          variables: {
                                            vendorId: company._id,
                                            amount: values.vendorCredits,
                                          },
                                        });

                                        notification.open({
                                          type: "success",
                                          message: "Saved!",
                                          description: "All set...",
                                        });
                                      }}
                                    >
                                      {({ handleSubmit, submitting }) => (
                                        <form onSubmit={handleSubmit}>
                                          <div
                                            css={{
                                              display: "grid",
                                              gridAutoColumns: "max-content",
                                              gridAutoFlow: "column",
                                              gridColumnGap: 12,
                                              alignItems: "center",
                                            }}
                                          >
                                            <Field name="vendorCredits">
                                              {({ input }) => (
                                                <CurrencyInput value={input.value} onValueChange={input.onChange} />
                                              )}
                                            </Field>

                                            <AntdButton htmlType="submit" loading={submitting}>
                                              Set Credit Amount
                                            </AntdButton>
                                          </div>
                                        </form>
                                      )}
                                    </Form>
                                  )}
                                </Mutation>

                                <p
                                  css={{
                                    marginTop: 4,
                                    fontSize: 12,
                                    color: palette.sealBlue,
                                  }}
                                >
                                  Override default credit amount of ${vendorCreditAmountConfig.value / 100}
                                </p>
                              </div>
                            )}

                          {company.plan.limits.inviteGovernments && (
                            <div>
                              <span
                                css={{
                                  fontSize: 13,
                                  color: "#797979",
                                }}
                              >
                                Credit will automatically be applied to next bill
                              </span>
                            </div>
                          )}

                          {vendorCreditDaysActiveConfig.value > 0 && vendorCreditAmountConfig.value > 0 && (
                            <Mutation<
                              IToggleVendorCreditsEligibilityMutation,
                              IToggleVendorCreditsEligibilityMutationVariables
                            >
                              mutation={ToggleVendorCreditsEligibility}
                              variables={{
                                vendorId: company._id,
                              }}
                            >
                              {(toggleVendorCreditsEligibility, { loading }) => (
                                <div
                                  css={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Checkbox
                                    disabled={loading || !company.plan.limits.inviteGovernments}
                                    checked={Boolean(company.canEarnCreditsUntil)}
                                    onChange={() => toggleVendorCreditsEligibility()}
                                  >
                                    {loading ? "Please wait..." : "Enable Vendor Credits"}
                                  </Checkbox>

                                  {!company.plan.limits.inviteGovernments && (
                                    <span
                                      css={{
                                        marginTop: 4,
                                        fontSize: 13,
                                        color: "#797979",
                                      }}
                                    >
                                      Only vendors with the <strong>Invite Governments</strong> enabled can earn credits
                                    </span>
                                  )}
                                </div>
                              )}
                            </Mutation>
                          )}
                        </div>
                      );
                    }}
                  </Query>
                </div>
              </div>
            </div>
          </Col>
        </RowWrap>
      </Grid>
    </div>
  );
}

function formatDollarAmount(amount: number) {
  if (amount > 0) {
    return `-$${Math.abs(amount / 100).toFixed(2)}`;
  }

  return `$${Math.abs(amount / 100).toFixed(2)}`;
}
