import React, { useState, useRef } from "react";
import { Field } from "react-final-form";
import { Input, LoadingIndicator } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import LinkCard from "../LinkCard";
import { IMetadataLink } from "../../../../types/types";
import useUnfurl from "../../../hooks/useUnfurl";

interface ILinkFieldProps {
  disabled?: boolean;
  onCancel: () => void;
}

export default function LinkField({ disabled = false, onCancel }: ILinkFieldProps) {
  return (
    <Field name="links">
      {({ input }) => {
        const hasImages = input.value && Array.isArray(input.value) && input.value.length > 0;

        return (
          <div>
            {input.value.length === 0 && (
              <div>
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <NewsworthyInput
                    disabled={disabled}
                    placeholder="Enter a URL... Limit of 1 link"
                    onChange={(item) => {
                      input.onChange([item].concat(input.value));
                      onCancel();
                    }}
                  />

                  <span
                    role="button"
                    onClick={onCancel}
                    css={{
                      fontWeight: 600,
                      color: palette.darkGray,
                      fontSize: 12,
                      textTransform: "uppercase",
                      textAlign: "center",
                      cursor: "pointer",
                      marginLeft: 16,
                    }}
                  >
                    Cancel
                  </span>
                </div>

                <p
                  css={{
                    fontSize: 12,
                    margin: "4px 0 0 0",
                    color: palette.darkGray,
                  }}
                >
                  Press Enter to save Link
                </p>
              </div>
            )}

            {hasImages && (
              <div
                css={{
                  marginTop: 15,
                }}
              >
                {input.value.map(({ url, ...meta }: any) => {
                  if (!meta) {
                    return null;
                  }

                  return (
                    <div key={url || ""}>
                      <LinkCard
                        url={url || ""}
                        title={meta.title || ""}
                        siteName={meta.siteName || ""}
                        description={meta.description || ""}
                        favicon={meta.favicon || ""}
                        onRemove={() => {
                          input.onChange(
                            input.value.filter(({ url: maybe }: IMetadataLink) => {
                              return url !== maybe;
                            }),
                          );
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
}

interface INewsworthyInputProps {
  onChange: (value: any) => any;
  disabled?: boolean;
  placeholder?: string;
}

const NewsworthyInput: React.FunctionComponent<INewsworthyInputProps> = ({
  disabled = false,
  placeholder = "Enter a URL...",
  onChange,
}) => {
  const [url, setUrl] = useState("");
  const inputRef = useRef<HTMLInputElement>();

  const [loading] = useUnfurl(
    url,
    (unfurl) => {
      (inputRef.current as HTMLInputElement).value = "";
      onChange(unfurl);
    },
    () => {
      (inputRef.current as HTMLInputElement).value = "";
    },
  );

  return (
    <div
      css={{
        position: "relative",
        width: "100%",
      }}
    >
      <Input
        innerRef={inputRef}
        disabled={loading || disabled}
        autoFocus={true}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            setUrl((event.target as HTMLInputElement).value);
          }
        }}
        placeholder={placeholder}
        size="small"
        css={{
          paddingRight: loading ? 40 : 20,
        }}
      />

      {loading && (
        <div
          css={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: 20,
          }}
        >
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
};
