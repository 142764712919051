import React, { useState } from "react";
import DocumentTitle from "react-document-title";
import { Query, Mutation } from "react-apollo";
import { Table, Tabs, Button, Alert, notification, Input } from "antd";
import { IGetAllPlansQuery, IGetAllPlansQueryVariables } from "../../queries/__generated__/GetAllPlans.generated";
import { Form, Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import CurrencyInput from "./CurrencyInput";
import SetDynamicConfigByKey from "../../mutations/SetDynamicConfigByKey";
import {
  ISetDynamicConfigByKeyMutationVariables,
  ISetDynamicConfigByKeyMutation,
} from "../../mutations/__generated__/SetDynamicConfigByKey.generated";
import GetDynamicConfigs from "../../queries/GetDynamicConfigs";
import {
  IGetDynamicConfigsQueryVariables,
  IGetDynamicConfigsQuery,
} from "../../queries/__generated__/GetDynamicConfigs.generated";
import { LoadingIndicator } from "@govlaunch/core";
import GetAllPlans from "../../queries/GetAllPlans";
import AddPlanButton from "./AddPlanButton";
import { Link } from "react-router-dom";
import { IPlanTier } from "../../../types/types";
import PlanActions from "./PlanActions";
import { useNavigate } from "react-router";
import PageIcon from "../../icons/PageIcon";
import { faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";
import MetaBaseLink, { MetaBaseQuestion } from "../../components/MetaBaseLink";

const { Column, ColumnGroup } = Table;

export default function Plans() {
  const [tab, setTab] = useState("plans");

  return (
    <DocumentTitle title="Plans | Admin">
      <>
        <div
          css={{
            padding: 20,
            borderBottom: "1px solid rgb(232, 232, 232)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap"
          }}
        >
          <div>
            <h3
              css={{
                fontWeight: 700,
                fontSize: 24,
                margin: 0,
              }}
            >
              <PageIcon icon={faMoneyBillAlt} /> Plans
            </h3>

            <p
              css={{
                margin: 0,
              }}
            >
              All Plans
            </p>
          </div>

          <div css={{
 display: "flex", alignItems: "center" 
}}
          >
            <AddPlanButton />
            <div css={{
marginLeft: 10
}}
            >
              <MetaBaseLink question={MetaBaseQuestion.Plans} />
            </div>
          </div>
        </div>

        <Tabs
          tabBarStyle={{
            backgroundColor: palette.white,
            margin: 0,
            paddingRight: 24,
            paddingLeft: 24,
            userSelect: "none",
          }}
          activeKey={tab}
          onChange={(tab) => setTab(tab)}
        >
          <Tabs.TabPane tab="Plans" key="plans">
            <ManagePlans />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Vendor Credits" key="credits">
            <ManageVendorCredits />
          </Tabs.TabPane>
        </Tabs>
      </>
    </DocumentTitle>
  );
}

function ManageVendorCredits() {
  return (
    <Query<IGetDynamicConfigsQuery, IGetDynamicConfigsQueryVariables> query={GetDynamicConfigs}>
      {({ data, loading }) => {
        if (loading) {
          return (
            <div
              css={{
                padding: "10px 20px",
              }}
            >
              <LoadingIndicator />
            </div>
          );
        }

        if (!data) {
          return (
            <div
              css={{
                padding: "10px 20px",
              }}
            >
              <Alert message="Whoops" description="Missing configuration data" type="error" showIcon={true} />
            </div>
          );
        }

        const vendorCreditAmountConfig = data.getDynamicConfigs.find((candidate) => {
          return candidate.key === "vendorCreditAmount";
        });

        const vendorCreditDaysActiveConfig = data.getDynamicConfigs.find((candidate) => {
          return candidate.key === "vendorCreditDaysActive";
        });

        if (!vendorCreditAmountConfig || !vendorCreditDaysActiveConfig) {
          return (
            <div
              css={{
                padding: "10px 20px",
              }}
            >
              <Alert message="Whoops" description="Missing configuration key" type="error" showIcon={true} />
            </div>
          );
        }

        return (
          <Mutation<ISetDynamicConfigByKeyMutation, ISetDynamicConfigByKeyMutationVariables>
            mutation={SetDynamicConfigByKey}
            refetchQueries={["GetDynamicConfigs"]}
          >
            {(setDynamicConfigByKey) => (
              <Form
                initialValues={{
                  vendorCredits: vendorCreditAmountConfig.value,
                  daysActive: vendorCreditDaysActiveConfig.value,
                }}
                onSubmit={async (values) => {
                  await Promise.all([
                    setDynamicConfigByKey({
                      variables: {
                        key: "vendorCreditAmount",
                        value: parseFloat(values.vendorCredits),
                      },
                    }),
                    setDynamicConfigByKey({
                      variables: {
                        key: "vendorCreditDaysActive",
                        value: parseInt(values.daysActive, 10),
                      },
                    }),
                  ]);

                  notification.success({
                    message: "All set!",
                    description: "Saved successfully",
                  });
                }}
              >
                {({ handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    <div
                      css={{
                        padding: "10px 20px",
                      }}
                    >
                      <div
                        css={{
                          display: "grid",
                          gridRowGap: 10,
                        }}
                      >
                        <div
                          css={{
                            display: "grid",
                            gridRowGap: 10,
                          }}
                        >
                          <div>
                            <h1
                              css={{
                                margin: 0,
                                fontSize: 21,
                                lineHeight: 1.33,
                              }}
                            >
                              Vendor Credits
                            </h1>

                            <p
                              css={{
                                margin: 0,
                              }}
                            >
                              Amount of credits given for each government that a vendor invites
                            </p>
                          </div>

                          <Field name="vendorCredits">
                            {({ input }) => <CurrencyInput value={input.value} onValueChange={input.onChange} />}
                          </Field>
                        </div>

                        <div
                          css={{
                            display: "grid",
                            gridRowGap: 10,
                          }}
                        >
                          <div>
                            <h1
                              css={{
                                margin: 0,
                                fontSize: 21,
                                lineHeight: 1.33,
                              }}
                            >
                              Days Active
                            </h1>

                            <p
                              css={{
                                margin: 0,
                              }}
                            >
                              Number of days a vendor has to earn credits
                            </p>
                          </div>

                          <Field name="daysActive">{({ input }) => <Input type="number" {...input} />}</Field>
                        </div>

                        <div>
                          <Button size="large" htmlType="submit" loading={submitting} disabled={submitting}>
                            {submitting ? "Please wait..." : "Save"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Form>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
}

type TPlan = NonNullable<IGetAllPlansQuery["allPlans"]>[0];

function ManagePlans() {
  const navigate = useNavigate();

  return (
    <Query<IGetAllPlansQuery, IGetAllPlansQueryVariables> query={GetAllPlans} fetchPolicy="network-only">
      {({ data, loading }) => {
        const plans = data && data.allPlans ? data.allPlans : [];

        return (
          <div
            css={{
              padding: 20,
              overflowX: "auto",
            }}
          >
            <Table<TPlan>
              dataSource={plans}
              loading={loading}
              bordered={true}
              rowKey="_id"
              scroll={{
                x: true,
              }}
              pagination={{
                defaultPageSize: 50,
                showSizeChanger: true,
              }}
            >
              <Column
                title="Name"
                dataIndex="name"
                render={(_name, plan: TPlan) => {
                  return <a onClick={() => navigate(`/billing/plans/${plan._id}`)}>{plan.name}</a>;
                }}
              />

              <Column title="Display Name" dataIndex="displayName" />

              <Column title="Tier" dataIndex="tier" />

              <Column
                title="Stripe Plan ID"
                dataIndex="stripePlanId"
                render={(stripePlanId) => stripePlanId || "N/A"}
              />

              <Column
                title="Vendor #"
                dataIndex="vendorCount"
                render={(value, record) => {
                  if (value === 0) {
                    return value;
                  }

                  const plan = record as TPlan;

                  if (plan.tier === IPlanTier.Basic) {
                    return <Link to="/vendors?plan=Basic">{value}</Link>;
                  }

                  if (plan.tier === IPlanTier.Disruptor) {
                    return <Link to="/vendors?tab=disruptors">{value}</Link>;
                  }

                  if (plan.tier === IPlanTier.Makers || plan.tier === IPlanTier.Makerspro) {
                    return <Link to={`/vendors?planId=${plan._id}`}>{value}</Link>;
                  }

                  return value;
                }}
              />

              <ColumnGroup title="Limits">
                <Column
                  title="Products"
                  dataIndex={["limits", "products"]}
                  render={(limit) => {
                    return limit;
                  }}
                />
                <Column
                  title="Product Tags"
                  dataIndex={["limits", "productTags"]}
                  render={(limit) => {
                    return limit;
                  }}
                />
                <Column
                  title="Enriched products"
                  dataIndex={["limits", "enrichedProducts"]}
                  render={(limit) => {
                    return limit;
                  }}
                />
                <Column
                  title="Projects Per Month"
                  dataIndex={["limits", "projectsPerMonth"]}
                  render={(limit) => {
                    return limit;
                  }}
                />
                <Column
                  title="Vendor Groups"
                  dataIndex={["limits", "groups"]}
                  render={(limit) => {
                    return limit;
                  }}
                />
                <Column
                  title="Other Groups"
                  dataIndex={["limits", "groupInvitations"]}
                  render={(limit) => {
                    return limit;
                  }}
                />
                <Column
                  title="Team Members"
                  dataIndex={["limits", "teamMembers"]}
                  render={(limit) => {
                    return limit;
                  }}
                />
                <Column
                  title="Enriched Profile"
                  dataIndex={["limits", "enrichedProfile"]}
                  render={(enrichedProfile) => {
                    return enrichedProfile ? "Yes" : "No";
                  }}
                />
                <Column
                  title="Sharing Page"
                  dataIndex={["limits", "sharingPage"]}
                  render={(sharingPage) => {
                    return sharingPage ? "Yes" : "No";
                  }}
                />
                <Column
                  title="Search Access"
                  dataIndex={["limits", "searchAccess"]}
                  render={(searchAccess) => {
                    return searchAccess ? "Yes" : "No";
                  }}
                />
                <Column
                  title="Invite Governments"
                  dataIndex={["limits", "inviteGovernments"]}
                  render={(inviteGovernments) => {
                    return inviteGovernments ? "Yes" : "No";
                  }}
                />
              </ColumnGroup>
              <Column title="Actions" render={(plan) => <PlanActions plan={plan} />} />
            </Table>
          </div>
        );
      }}
    </Query>
  );
}
