import { Button, Modal, Select } from "antd";
import React, { useState } from "react";
import * as palette from "@govlaunch/palette";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BLOCK_TYPE_SELECT_OPTIONS,
  getBlockKey,
  getDefaultBlockContentByType,
  IBlockLayout,
} from "../blocks/blockProps";
import { IBlockLayoutType } from "../../../../types/types";

interface IAddBlockModalProps {
  isOpen: boolean;
  onClose: () => void;
  block: IBlockLayout;
  onAddBlock: (block: IBlockLayout) => void;
}

export default function AddBlockModal({ isOpen, onClose, block, onAddBlock }: IAddBlockModalProps) {
  const [type, setType] = useState<IBlockLayoutType>(IBlockLayoutType.EmptySpaceBlock);

  return (
    <Modal title="Add Block" visible={isOpen} onCancel={onClose} footer={null} width={400}>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: `80px 1fr 32px`,
          rowGap: 8,
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <label
            css={{
              fontWeight: 500,
              fontSize: 14,
              color: palette.mediumGray,
            }}
          >
            Pick type:
          </label>
        </div>

        <div
          css={{
            borderRadius: 5,
            border: `1px solid ${palette.lightestGray}`,
            ":focus-within": {
              borderColor: palette.primary,
            },
          }}
        >
          <Select
            defaultValue={type}
            value={type}
            style={{
              borderRadius: 5,
              fontSize: 14,
              width: "100%",
            }}
            onChange={(value) => setType(value)}
            size="large"
            bordered={false}
          >
            {BLOCK_TYPE_SELECT_OPTIONS.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Button
          style={{
            height: "100%",
            marginLeft: 8,
            borderRadius: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            onAddBlock({
              i: getBlockKey(),
              x: block.x,
              y: block.y,
              w: 8,
              h: 12,
              blockData: getDefaultBlockContentByType(type),
            });
            onClose();
          }}
        >
          <FontAwesomeIcon
            icon={faPlus}
            color={palette.primary}
            style={{
              width: "24px",
              height: "24px",
            }}
          />
        </Button>
      </div>
    </Modal>
  );
}
