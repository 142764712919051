export function byTypename<T extends { __typename: string }, V extends string & T["__typename"]>(
  lookingFor: V,
): (value: T) => value is Extract<T, Record<"__typename", V>> {
  return (value: T): value is Extract<T, Record<"__typename", V>> => {
    return value.__typename === lookingFor;
  };
}

export function filterInUnionByTypename<T extends { __typename: string }, V extends string & T["__typename"]>(
  union: T[],
  lookingFor: V,
): Extract<T, { __typename: V }>[] {
  return union.filter(byTypename(lookingFor));
}

export function filterInUnionByTypenames<T extends { __typename: string }, V extends string & T["__typename"]>(
  union: T[],
  lookingFor: V[],
): Extract<T, { __typename: V }>[] {
  return union.filter((node): node is Extract<T, { __typename: V }> => {
    return lookingFor.some((typename) => {
      return byTypename(typename)(node);
    });
  });
}
