import React from "react";
import { Query } from "react-apollo";
import GetAllSponsorships from "../../queries/GetAllSponsorships";
import {
  IGetAllSponsorshipsQuery,
  IGetAllSponsorshipsQueryVariables,
} from "../../queries/__generated__/GetAllSponsorships.generated";
import { State } from "react-powerplug";
import SponsorshipsTable, { ISponsorshipsTableState } from "./SponsorshipsTable";
import { NetworkStatus } from "apollo-client";
import { Pagination } from "antd";
import encodeCursor from "../../utils/encodeCursor";
import { IAllSponsorshipsFilter } from "../../../types/types";

interface ISponsorshipsProps {
  search?: string;
  filter?: IAllSponsorshipsFilter;
}

export default function Sponsorships({ search, filter }: ISponsorshipsProps) {
  return (
    <Query<IGetAllSponsorshipsQuery, IGetAllSponsorshipsQueryVariables>
      query={GetAllSponsorships}
      variables={{
        search: search || null,
        multiSearch: null,
        filter: filter || null,
        sortBy: null,
        cursor: null,
      }}
      fetchPolicy="network-only"
      notifyOnNetworkStatusChange={true}
    >
      {({ data, refetch, networkStatus }) => {
        const loading = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables;
        const fetchingMore = networkStatus === NetworkStatus.fetchMore;

        const sponsorships = (data && data.allSponsorships && data.allSponsorships.items) || [];
        const totalCount =
          data && data.allSponsorships && data.allSponsorships.pageInfo
            ? data.allSponsorships.pageInfo.totalCount || 0
            : 0;

        return (
          <State<ISponsorshipsTableState>
            initial={{
              sortColumnKey: null,
              sortColumnOrder: null,
              limit: 10,
              page: 1,
            }}
          >
            {({ state, setState }) => {
              return (
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 10,
                  }}
                >
                  <SponsorshipsTable
                    sponsorships={sponsorships}
                    loading={loading || fetchingMore}
                    state={state}
                    refetch={refetch}
                    filter={filter}
                    onChange={(_: any, _filters: any, sorter: any) => {
                      setState({
                        sortColumnKey: sorter.columnKey,
                        sortColumnOrder: sorter.order,
                      });

                      refetch({
                        search: search || null,
                        multiSearch: null,
                        filter: filter || null,
                        sortBy: getSortBy(sorter),
                        cursor: null,
                      });
                    }}
                  />
                  <Pagination
                    showSizeChanger={true}
                    showQuickJumper={true}
                    total={totalCount}
                    // range variable shows incorrect values when changing tabs
                    showTotal={(total) => {
                      const page = state.page || 1;
                      const limit = state.limit || 0;

                      return `${(page - 1) * limit}-${Math.min(page * limit + limit, total)} of ${total} items`;
                    }}
                    current={state.page || 1}
                    onChange={(page, pageSize) => {
                      setState({
                        limit: pageSize,
                        page,
                      });

                      refetch({
                        search: search || null,
                        multiSearch: null,
                        filter: filter || null,
                        sortBy: getSortBy({
                          columnKey: state.sortColumnKey,
                          order: state.sortColumnOrder,
                        }),
                        cursor: encodeCursor((page - 1) * (pageSize || 0)),
                        limit: pageSize,
                      });
                    }}
                    onShowSizeChange={(_current: number, size: number) => {
                      setState({
                        limit: size,
                        page: 0,
                      });

                      refetch({
                        search: search || null,
                        multiSearch: null,
                        filter: filter || null,
                        sortBy: getSortBy({
                          columnKey: state.sortColumnKey,
                          order: state.sortColumnOrder,
                        }),
                        cursor: encodeCursor(0 * (size || 0)),
                        limit: size,
                      });
                    }}
                  />
                </div>
              );
            }}
          </State>
        );
      }}
    </Query>
  );
}

function getSortBy(sorter: any) {
  if (sorter && sorter.columnKey && sorter.order) {
    const direction = sorter.order === "descend" ? "descending" : "ascending";

    if (sorter.columnKey === "name") {
      return `name:${direction}`;
    }

    if (sorter.columnKey === "vendorsCount") {
      return `vendorsCount:${direction}`;
    }
  }

  return null;
}
