import React from "react";
import DocumentTitle from "react-document-title";
import { useParams } from "react-router";
import * as palette from "@govlaunch/palette";
import { LoadingIndicator } from "@govlaunch/core";
import { useQuery } from "react-apollo";
import {
  ILandingPageConfigQuery,
  ILandingPageConfigQueryVariables,
} from "../../queries/__generated__/LandingPageConfig.generated";
import LandingPageConfig from "../../queries/LandingPageConfig";
import EditLandingPageForm from "./EditLandingPageForm";

export default function EditLandingPage() {
  const params = useParams();

  const { data, loading } = useQuery<ILandingPageConfigQuery, ILandingPageConfigQueryVariables>(LandingPageConfig, {
    variables: {
      id: params.configId,
    },
  });

  return (
    <DocumentTitle title="Landing Page Template | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",

          overflow: "auto",
        }}
      >
        {loading && (
          <div>
            <LoadingIndicator />
          </div>
        )}

        {data && data.landingPageConfig && <EditLandingPageForm landingPageConfig={data.landingPageConfig} />}
      </div>
    </DocumentTitle>
  );
}
