import gql from "graphql-tag";

export default gql`
  query GetNewProject($cursor: Cursor, $limit: Int = 30) {
    getNewProjects(limit: $limit, cursor: $cursor) {
      items {
        _id
        ... on VendorProject {
          title
          countryCode
        }
        ... on GovernmentProject {
          title
          countryCode
        }
        ... on GroupProject {
          title
          countryCode
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor
      }
    }
  }
`;
