import { Query } from "react-apollo";
import React, { ReactElement } from "react";
import OnboardingsCountQuery from "../../queries/OnboardingsCountQuery";
import { IOnboardingsCountQuery } from "../../queries/__generated__/OnboardingsCountQuery.generated";

interface IOnboardingsCountChildProps {
  requiredCount: number;
  additionalInformationRequiredCount: number;
  pendingVerificationCount: number;
  verifiedCount: number;
  loading: boolean;
  refetch: Function;
}

interface IOnboardingsCountProps {
  children: (props: IOnboardingsCountChildProps) => ReactElement | null;
}

export default function OnboardingsCount({ children }: IOnboardingsCountProps) {
  return (
    <Query<IOnboardingsCountQuery> query={OnboardingsCountQuery} fetchPolicy="network-only">
      {({ data, loading, refetch }) => {
        if (!data || !data.onboardingProcessStatusCounts) {
          return null;
        }

        return children({
          ...data.onboardingProcessStatusCounts,
          loading,
          refetch,
        });
      }}
    </Query>
  );
}
