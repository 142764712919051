import gql from "graphql-tag";

export default gql`
  mutation VerifyProject($projectId: ObjectId!, $governmentId: ObjectId!) {
    verifyProject(projectId: $projectId, governmentId: $governmentId) {
      _id
      status
      respondedAt
      respondedBy {
        ... on GovernmentUser {
          _id
          slug
          fullName
          avatar
        }
        ... on VendorUser {
          _id
          slug
          fullName
          avatar
        }
        ... on PendingUser {
          _id
          slug
          fullName
          avatar
        }
      }
    }
  }
`;
