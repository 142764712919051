import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { Margin } from "../../../spacings";
import { Input } from "@govlaunch/core";
import CharacterLimitIndicator from "../../../components/form/CharacterLimitIndicator";
import { IFormFieldProps } from "./FormFieldProps";
import FieldValidationError from "../../../components/form/FieldValidationError";

export default function TextField({
  fieldName,
  label,
  placeholder,
  characterLimit,
  showCharacterCount,
  headerColor,
  disabled,
  ...props
}: IFormFieldProps) {
  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: headerColor || palette.mediumGray,
                margin: 0,
              }}
            >
              {label}
            </label>

            <FieldValidationError meta={meta} />
          </div>
          <Margin mt={8}>
            <Input
              css={{
                height: 42,
                borderRadius: 5,
                border: `1px solid ${palette.lightestGray}`,
                fontSize: 14,
                padding: "5px 10px",
                width: "100%",
                outline: 0,
                "::placeholder": {
                  color: palette.sealBlue,
                },
                ":focus": {
                  borderColor: palette.primary,
                },
              }}
              placeholder={placeholder}
              {...input}
              {...props}
              disabled={disabled}
            />
          </Margin>

          {characterLimit && showCharacterCount && (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 8,
              }}
            >
              <CharacterLimitIndicator
                maxLength={characterLimit}
                description={input.value}
                css={{
                  marginLeft: "auto",
                }}
              />
            </div>
          )}
        </div>
      )}
    </Field>
  );
}
