import React from "react";
import DocumentTitle from "react-document-title";
import { Query } from "react-apollo";
import GetTagBySlug from "../../../queries/GetTagBySlug";
import { IGetTagBySlugQuery, IGetTagBySlugQueryVariables } from "../../../queries/__generated__/GetTagBySlug.generated";
import { LoadingIndicator } from "@govlaunch/core";
import TagProfileTabs from "./TagProfileTabs";
import { useParams } from "react-router";

export default function TagProfile() {
  const params = useParams();

  return (
    <DocumentTitle title="Tag | Admin">
      <Query<IGetTagBySlugQuery, IGetTagBySlugQueryVariables>
        query={GetTagBySlug}
        variables={{
          slug: params.slug || "",
        }}
      >
        {({ data, loading }) => {
          if (loading || !data) {
            return (
              <LoadingIndicator
                css={{
                  padding: 8,
                }}
              />
            );
          }

          const tag = data.tag;

          if (!tag) {
            return (
              <LoadingIndicator
                css={{
                  padding: 8,
                }}
              />
            );
          }

          return <TagProfileTabs tag={tag} tab={params.tab} />;
        }}
      </Query>
    </DocumentTitle>
  );
}
