import React, { ReactElement } from "react";
import { Mutation } from "react-apollo";
import ImpersonateMutation from "./ImpersonateMutation";
import { IImpersonateMutation, IImpersonateMutationVariables } from "./__generated__/ImpersonateMutation.generated";

interface IImpersonateProps {
  children: (fn: (userId: string) => Promise<string | null>, loading: boolean) => ReactElement;
  [key: string]: any;
}

export default function Impersonate({ children, ...props }: IImpersonateProps) {
  return (
    <Mutation<IImpersonateMutation, IImpersonateMutationVariables> {...props} mutation={ImpersonateMutation}>
      {(impersonate, { loading }) => {
        return children(async (userId: string) => {
          const impersonateResult = await impersonate({
            variables: {
              userId,
            },
          });

          if (impersonateResult && impersonateResult.data && impersonateResult.data.impersonate) {
            return impersonateResult.data.impersonate.token;
          }

          return null;
        }, loading);
      }}
    </Mutation>
  );
}
