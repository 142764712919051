import gql from "graphql-tag";
import SolutionFieldsFragment from "../fragments/SolutionFieldsFragment";

export default gql`
  mutation AddSolution($name: String!) {
    addSolution(name: $name) {
      ...SolutionFields
    }
  }

  ${SolutionFieldsFragment}
`;
