import React, { FunctionComponent } from "react";
import queryString from "query-string";
import { IGroupsFilter, IGroupKind } from "../../../../types/types";
import TableSearch from "../../../components/table/TableSearch";
import { useLocation } from "react-router";
import ManageGroups from "./ManageGroups";

// TODO: add tabs for "All" / "Archived" instead of "archivedOnly" prop
interface IGroupsWithSearchProps {
  groupsFilter?: IGroupsFilter;
  filterByKind?: IGroupKind;
  filterByTag?: any;
  historyOrigin?: string;
}

const GroupsWithSearch: FunctionComponent<IGroupsWithSearchProps> = ({
  groupsFilter = IGroupsFilter.All,
  filterByKind,
  filterByTag = null,
  historyOrigin = "/groups",
  ...props
}) => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div css={{
 marginBottom: 20 
}}
      >
        <TableSearch historyOrigin={historyOrigin} query={query} inputPlaceholder="Search for groups..." />
      </div>
      <ManageGroups
        search={query.search as string}
        groupsFilter={groupsFilter}
        filterByKind={filterByKind}
        filterByTag={filterByTag}
        historyOrigin={historyOrigin}
        props={props}
      />
    </>
  );
};

export default GroupsWithSearch;
