import React from "react";
import { useNavigate } from "react-router";
import { ICollectionBySlugQuery } from "./graphql/queries/__generated__/CollectionBySlug.generated";
import * as palette from "@govlaunch/palette";
import { useMutation } from "react-apollo";
import {
  IUpdateCollectionMutation,
  IUpdateCollectionMutationVariables,
} from "./graphql/mutations/__generated__/UpdateCollectionMutation.generated";
import UpdateCollectionMutation from "./graphql/mutations/UpdateCollectionMutation";
import CollectionForm from "./CollectionForm";
import notUndefinedOrNull from "../../utils/notUndefinedOrNull";
import { notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

interface ICollectionDetailsTabProps {
  collection: Omit<NonNullable<ICollectionBySlugQuery["collectionBySlug"]>, "__typename">;
}

export default function CollectionDetailsTab({ collection }: ICollectionDetailsTabProps) {
  const navigate = useNavigate();

  const [updateCollection] = useMutation<IUpdateCollectionMutation, IUpdateCollectionMutationVariables>(
    UpdateCollectionMutation,
    {
      refetchQueries: ["ListAllCollections", "CollectionBySlug"],
    },
  );

  return (
    <div
      css={{
        padding: 15,
      }}
    >
      <div
        css={{
          paddingBottom: 8,
        }}
      >
        <a
          css={{
            color: palette.primary,
            cursor: "pointer",
          }}
          onClick={() => navigate("/collections")}
        >
          <span css={{
 marginRight: 5 
}}
          >
            <FontAwesomeIcon icon={faLessThan} size="sm" />
          </span>
          All Collections
        </a>
      </div>

      <CollectionForm
        onSubmit={async (values) => {
          const { data } = await updateCollection({
            variables: {
              collectionId: collection._id,
              input: {
                name: values.name,
                description: values.description || "",
                tags: values.tags,
                displayOptions: {
                  cardBackgroundColorHex: values.cardBackground,
                },
                isFeatured: values.isFeatured,
                sponsoring: values.sponsoring,
                sponsoringVendors: [values.sponsoringVendor].filter(notUndefinedOrNull),
                sponsoringProducts: [values.sponsoringProduct].filter(notUndefinedOrNull),
              },
            },
          });

          if (data && data.updateCollection) {
            notification.success({
              message: "Success!",
              description: "Collection saved successfully",
            });

            navigate(`/collections/${data.updateCollection.slug}`);
          }
        }}
        collection={{
          name: collection.name,
          tags: collection.tags,
          description: collection.description,
          featuredAt: collection.featuredAt,
          sponsoring: collection.sponsoring,
          sponsoringVendors: collection.sponsoringVendors,
          sponsoringProducts: collection.sponsoringProducts,
          displayOptions: collection.displayOptions,
        }}
        title="Edit Collection"
      />
    </div>
  );
}
