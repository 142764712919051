import React from "react";
import { IGovernmentVerificationRequestSearchTarget } from "../../../../types/types";
import Search from "antd/lib/input/Search";
import { Select } from "antd";

interface IProjectVerificationRequestsSearchProps {
  searchQuery: string;
  searchTarget: IGovernmentVerificationRequestSearchTarget;
  onSearch: (searchQuery: string) => void;
  onChangeSearchTarget: (searchTarget: IGovernmentVerificationRequestSearchTarget) => void;
}

export default function ProjectVerificationRequestsSearch({
  searchQuery,
  searchTarget,
  onSearch,
  onChangeSearchTarget,
}: IProjectVerificationRequestsSearchProps) {
  return (
    <div css={{
 display: "flex", alignItems: "center" 
}}
    >
      <Search
        size="large"
        defaultValue={searchQuery}
        placeholder="Type your search and press Enter..."
        allowClear={true}
        onChange={(event) => {
          if (event.target.value === "" && searchQuery !== "") {
            onSearch("");
          }
        }}
        css={{
 maxWidth: 650 
}}
        onSearch={(searchValue) => onSearch(searchValue || "")}
      />
      <Select<IGovernmentVerificationRequestSearchTarget>
        value={searchTarget}
        onChange={(searchTarget) => onChangeSearchTarget(searchTarget)}
        size="large"
        css={{
marginLeft: 5, width: 130
}}
      >
        <Select.Option value={IGovernmentVerificationRequestSearchTarget.Government}>Government</Select.Option>
        <Select.Option value={IGovernmentVerificationRequestSearchTarget.Project}>Project</Select.Option>
        <Select.Option value={IGovernmentVerificationRequestSearchTarget.Group}>Group</Select.Option>
      </Select>
    </div>
  );
}
