import * as palette from "@govlaunch/palette";
import { Button as ButtonAnt, Popconfirm } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";
import { useToggle } from "react-use";
import TableRowActions from "../../components/table/TableRowActions";
import { ToggleDisruptor } from "../../mutations";
import ArchiveVendor from "../../mutations/ArchiveVendor";
import RestoreVendor from "../../mutations/RestoreVendor";
import {
  IArchiveVendorMutation,
  IArchiveVendorMutationVariables,
} from "../../mutations/__generated__/ArchiveVendor.generated";
import {
  IRestoreVendorMutation,
  IRestoreVendorMutationVariables,
} from "../../mutations/__generated__/RestoreVendor.generated";
import { IAllVendorsQuery, IAllVendorsQueryVariables } from "../../queries/__generated__/GetAllVendors.generated";
import { useSelfie } from "../auth/Selfie";
import Impersonate from "../debugging/Impersonate";
import EditVendorMembersModal from "../teamManagement/modals/EditVendorMembersModal";

type TVendor = NonNullable<NonNullable<IAllVendorsQuery["allVendors"]>["items"]>[0];

interface IVendorActionsProps {
  vendor: TVendor;
  refetch: (variables?: IAllVendorsQueryVariables) => any;
}

export default function VendorActions({ vendor, refetch }: IVendorActionsProps) {
  const selfie = useSelfie();
  const [showTeamBuilder, toggleTeamBuilder] = useToggle(false);

  if (vendor.archivedAt) {
    return (
      <>
        <TableRowActions title="Vendor Actions">
          {() => (
            <>
              <li>
                <Mutation<IRestoreVendorMutation, IRestoreVendorMutationVariables>
                  mutation={RestoreVendor}
                  variables={{
                    vendorId: vendor._id,
                  }}
                  refetchQueries={["VendorsCount"]}
                >
                  {(restore) => (
                    <a
                      href="#"
                      onClick={() =>
                        restore().then(() => {
                          return refetch();
                        })
                      }
                    >
                      Restore
                    </a>
                  )}
                </Mutation>
              </li>
            </>
          )}
        </TableRowActions>
      </>
    );
  }

  return (
    <>
      <EditVendorMembersModal visible={showTeamBuilder} vendor={vendor} onRequestClose={toggleTeamBuilder} />

      <TableRowActions title="Vendor Actions">
        {({ onClick }: any) => (
          <>
            {selfie && selfie.isSiteAdmin && (
              <>
                <ToggleDisruptor entityId={vendor._id} entityType="vendor">
                  {(toggleDisruptor: any) => (
                    <li>
                      <ButtonAnt
                        type="link"
                        css={{
                          padding: 0,
                          border: 0,
                          height: "auto",
                          color: vendor.isDisruptor ? palette.red : undefined,
                        }}
                        disabled={!vendor.canBeDisruptor}
                        onClick={onClick(() => toggleDisruptor(vendor))}
                      >
                        {vendor.isDisruptor ? "Remove Disruptor" : "Set Disruptor"}
                      </ButtonAnt>

                      {!vendor.canBeDisruptor && (
                        <p
                          css={{
                            fontSize: 12,
                          }}
                        >
                          Only basic vendors can become a Disruptor
                        </p>
                      )}
                    </li>
                  )}
                </ToggleDisruptor>

                <li>
                  <a href="#" onClick={onClick(toggleTeamBuilder)}>
                    Team Builder
                  </a>
                </li>

                {vendor.members && vendor.members.length > 0 && (
                  <li>
                    <Link to={`/users?tab=vendors&company=${vendor._id}`}>Search Users</Link>
                  </li>
                )}

                {vendor.owner && (
                  <Impersonate>
                    {(impersonate) => (
                      <li>
                        <a
                          href="#"
                          onClick={onClick(() => {
                            if (vendor.owner) {
                              impersonate(vendor.owner);
                            }
                          })}
                        >
                          Impersonate
                        </a>
                      </li>
                    )}
                  </Impersonate>
                )}
              </>
            )}

            <li>
              <Link to={`/vendors/${vendor.slug}`}>Edit</Link>
            </li>

            <Mutation<IArchiveVendorMutation, IArchiveVendorMutationVariables>
              mutation={ArchiveVendor}
              refetchQueries={["VendorsCount"]}
            >
              {(archive) => (
                <li>
                  <Popconfirm
                    title={`Are you sure you want to archive ${vendor.name}?`}
                    onConfirm={() => {
                      archive({
                        variables: {
                          vendorId: vendor._id,
                        },
                      }).then(() => {
                        return refetch();
                      });
                    }}
                  >
                    <span
                      css={{
                        cursor: "pointer",
                        color: palette.red,
                      }}
                      role="button"
                    >
                      Archive
                    </span>
                  </Popconfirm>
                </li>
              )}
            </Mutation>
          </>
        )}
      </TableRowActions>
    </>
  );
}

VendorActions.propTypes = {
  vendor: PropTypes.object.isRequired,
};
