import { Col, RowWrap } from "@govlaunch/core";
import React from "react";
import { FormSpy, useForm } from "react-final-form";
import { IAboutContentType } from "../../../types/types";
import ColorPickerField from "../form/fields/ColorPickerField";
import FilestackUploadField from "../form/fields/FilestackUploadField";
import SelectField from "../form/fields/SelectField";
import TextField from "../form/fields/TextField";
import FormGrid from "../form/FormGrid";
import FormSubmitSection from "../form/FormSubmitSection";
import Section from "../form/Section";
import SectionBody from "../form/SectionBody";
import SectionHeader from "../form/SectionHeader";
import SectionSplitter from "../form/SectionSplitter";
import VideoContentField from "./form/VideoContentField";
import * as palette from "@govlaunch/palette";
import SideContentFormFields from "./form/SideContentFormFields";
import { Button, Typography } from "antd";
import LogosField from "./form/LogosField";
import CheckboxField from "../form/fields/CheckboxField";
import getGovlaunchBaseUrl from "../../utils/getGovlaunchBaseUrl";
import { SendOutlined } from "@ant-design/icons";

interface IAboutPageConfigFormProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  submitting: boolean;
  submitError?: string;
  submitButtonText?: string;
  configId?: string;
}

export default function AboutPageConfigForm({
  onSubmit,
  submitting,
  submitError,
  submitButtonText = "Save",
  configId,
}: IAboutPageConfigFormProps) {
  const form = useForm();

  return (
    <form onSubmit={onSubmit}>
      <FormGrid>
        <FormSubmitSection
          backButtonText="All Templates"
          backButtonLocation="/aboutPage"
          discardChangesLocation="/aboutPage"
          submitButtonText={submitButtonText}
          submitting={submitting}
          submitError={submitError}
          previewButton={
            <Button
              disabled={!configId}
              size="middle"
              type="link"
              icon={<SendOutlined />}
              htmlType="button"
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid ${palette.darkGray}`,
                borderRadius: 5,
                height: "36px",
                background: palette.white,
                marginLeft: "12px",
              }}
              onClick={async () => {
                await form.submit();

                window.open(`${getGovlaunchBaseUrl()}/about?previewId=${configId}`, "_blank");
              }}
              target="_blank"
              rel="noreferrer noopener"
            >
              Save & Preview
            </Button>
          }
        />

        <RowWrap>
          <Col width={[1, 1, 1, 1]}>
            <SectionSplitter>
              <Section>
                <SectionHeader>Template name</SectionHeader>

                <SectionBody>
                  <TextField
                    fieldName="name"
                    label="Template name"
                    placeholder="Use this field to help identifiying your templates"
                  />
                  <div
                    css={{
                      display: "flex",
                      flexDireciton: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <CheckboxField fieldName="active" label="Make this template active?" />
                  </div>
                </SectionBody>
              </Section>

              <Section>
                <SectionHeader>Heading section</SectionHeader>

                <SectionBody>
                  <TextField fieldName="headingText" label="Heading text" placeholder="Type heading text..." />
                  <ColorPickerField
                    fieldName="headingTextFontColor"
                    label="Heading text color"
                    placeholder="Pick heading color..."
                  />

                  <TextField fieldName="subtitleText" label="Subtitle text" placeholder="Type subtitle text..." />
                  <ColorPickerField
                    fieldName="subtitleTextFontColor"
                    label="Subtitle text color"
                    placeholder="Pick subtitle color..."
                  />
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      css={{
                        width: "100%",
                        marginRight: 8,
                        borderRadius: 5,
                        border: `1px solid ${palette.lightestGray}`,
                        padding: 12,
                      }}
                    >
                      <TextField
                        fieldName="leftButtonText"
                        label="Left button text"
                        placeholder="Type left button text..."
                      />
                      <ColorPickerField
                        fieldName="leftButtonBackgroundColor"
                        label="Left button background color"
                        placeholder="Pick left button background color..."
                      />
                      <ColorPickerField
                        fieldName="leftButtonTextColor"
                        label="Left button text color"
                        placeholder="Pick left button text color..."
                      />
                      <ColorPickerField
                        fieldName="leftButtonBorderColor"
                        label="Left button border color"
                        placeholder="Pick left button border color..."
                      />
                      <ColorPickerField
                        fieldName="leftButtonHoverColor"
                        label="Left button hover color"
                        placeholder="Pick left button hover color..."
                      />
                      <TextField
                        fieldName="leftButtonHref"
                        label="Left button URL"
                        placeholder="Type left button url..."
                      />
                    </div>

                    <div
                      css={{
                        width: "100%",
                        marginLeft: 8,
                        borderRadius: 5,
                        border: `1px solid ${palette.lightestGray}`,
                        padding: 12,
                      }}
                    >
                      <TextField
                        fieldName="rightButtonText"
                        label="Right button text"
                        placeholder="Type right button text..."
                      />
                      <ColorPickerField
                        fieldName="rightButtonBackgroundColor"
                        label="Right button background color"
                        placeholder="Pick right button background color..."
                      />
                      <ColorPickerField
                        fieldName="rightButtonTextColor"
                        label="Right button text color"
                        placeholder="Pick right button text color..."
                      />
                      <ColorPickerField
                        fieldName="rightButtonBorderColor"
                        label="Right button border color"
                        placeholder="Pick right button border color..."
                      />
                      <ColorPickerField
                        fieldName="rightButtonHoverColor"
                        label="Right button hover color"
                        placeholder="Pick right button hover color..."
                      />
                      <TextField
                        fieldName="rightButtonHref"
                        label="Right button URL"
                        placeholder="Type right button url..."
                      />
                    </div>
                  </div>
                  <FilestackUploadField
                    fieldName="bannerBackgroundSrc"
                    label="Heading Banner Background"
                    recommendedDimensions="1700x500px"
                  />
                  <TextField
                    fieldName="bannerBackgroundDescription"
                    label="Banner image description"
                    placeholder="Type banner image description..."
                  />
                </SectionBody>
              </Section>
              <Section>
                <SectionHeader>Right side content (image, video or embedded podcast)</SectionHeader>
                <SectionBody>
                  <SelectField
                    fieldName="bannerContentType"
                    label="Content type"
                    options={[
                      {
                        value: IAboutContentType.Image,
                        label: "Image",
                      },
                      {
                        value: IAboutContentType.Video,
                        label: "Video",
                      },
                      {
                        value: IAboutContentType.Podcast,
                        label: "Podcast",
                      },
                    ]}
                    defaultValue="IMAGE"
                  />
                  <FormSpy
                    subscription={{
                      values: true,
                    }}
                  >
                    {({ values }) => (
                      <VideoContentField
                        videoContentType={values.bannerContentType}
                        fieldName="bannerContentText"
                        showSizeField={true}
                        sizeFieldName="bannerContentSize"
                        recommendedImageDimensions="590x405px"
                      />
                    )}
                  </FormSpy>
                </SectionBody>
              </Section>
              <Section>
                <SectionHeader>Left/Right Box</SectionHeader>
                <SectionBody>
                  <ColorPickerField fieldName="boxesSectionBackgroundColor" label="Boxes background color" />
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      css={{
                        width: "100%",
                        marginRight: 8,
                        borderRadius: 5,
                        border: `1px solid ${palette.lightestGray}`,
                        padding: 12,
                      }}
                    >
                      <SideContentFormFields side="leftBox" />
                    </div>

                    <div
                      css={{
                        width: "100%",
                        marginLeft: 8,
                        borderRadius: 5,
                        border: `1px solid ${palette.lightestGray}`,
                        padding: 12,
                      }}
                    >
                      <SideContentFormFields side="rightBox" />
                    </div>
                  </div>
                </SectionBody>
              </Section>
              <Section>
                <SectionHeader>Video Section</SectionHeader>
                <SectionBody>
                  <TextField fieldName="videoSectionTitle" label="Title" placeholder="Type title..." />
                  <ColorPickerField
                    fieldName="videoSectionFontColor"
                    label="Font color"
                    placeholder="Pick font color..."
                  />

                  <SelectField
                    fieldName="videoSectionContentType"
                    label="Video Section content type"
                    options={[
                      {
                        value: IAboutContentType.Image,
                        label: "Image",
                      },
                      {
                        value: IAboutContentType.Video,
                        label: "Video",
                      },
                      {
                        value: IAboutContentType.Podcast,
                        label: "Podcast",
                      },
                    ]}
                    defaultValue="IMAGE"
                  />

                  <FormSpy
                    subscription={{
                      values: true,
                    }}
                  >
                    {({ values }) => (
                      <VideoContentField
                        videoContentType={values.videoSectionContentType}
                        fieldName="videoSectionContentText"
                        recommendedImageDimensions="750x400px"
                      />
                    )}
                  </FormSpy>

                  <ColorPickerField fieldName="videoSectionBackground" label="Background" />
                </SectionBody>
              </Section>
              <Section>
                <SectionHeader>Join Section</SectionHeader>
                <SectionBody>
                  <TextField fieldName="joinSectionTitle" label="Title" placeholder="Type title..." />
                  <ColorPickerField fieldName="joinSectionTitleFontColor" label="Title font color" />
                  <TextField fieldName="joinSectionSubtitle" label="Subtitle" placeholder="Type subtitle..." />
                  <ColorPickerField fieldName="joinSectionSubtitleFontColor" label="Subtitle font color" />
                  <div>
                    <TextField
                      fieldName="joinSectionButtonText"
                      label="Button text"
                      placeholder="Type button text..."
                    />
                    <Typography.Text
                      type="warning"
                      css={{
                        display: "block",
                      }}
                    >
                      Leave empty to hide the button
                    </Typography.Text>
                  </div>
                  <ColorPickerField fieldName="joinSectionButtonColor" label="Button background color" />
                  <ColorPickerField fieldName="joinSectionButtonFontColor" label="Button font color" />
                </SectionBody>
              </Section>
              <Section>
                <SectionHeader>Logos</SectionHeader>
                <SectionBody>
                  <ColorPickerField fieldName="logoSectionBackgroundColor" label="Background color" />
                  <LogosField />
                </SectionBody>
              </Section>
            </SectionSplitter>
          </Col>
        </RowWrap>
      </FormGrid>
    </form>
  );
}
