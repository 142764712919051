import { Mutation } from "react-apollo";
import React, { ReactElement } from "react";
import ToggleDisruptorMutation from "./ToggleDisruptorMutation";
import {
  IToggleDisruptorMutationMutation,
  IToggleDisruptorMutationMutationVariables,
} from "./__generated__/ToggleDisruptorMutation.generated";

interface IToggleDisruptorProps {
  entityId: string;
  entityType: string;
  children: (toggle: Function) => ReactElement;
  [K: string]: any;
}

export default function ToggleDisruptor({ entityId, entityType, children, ...props }: IToggleDisruptorProps) {
  return (
    <Mutation<IToggleDisruptorMutationMutation, IToggleDisruptorMutationMutationVariables>
      variables={{
        entityId,
        entityType,
      }}
      mutation={ToggleDisruptorMutation}
      {...props}
    >
      {toggle =>
        children((target: any) => {
          toggle({
            variables: {
              entityId: target._id,
              entityType,
            },
          });
        })
      }
    </Mutation>
  );
}
