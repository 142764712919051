import React from "react";
import { Link } from "react-router-dom";

interface IMainMenuItemProps {
  title: string;
  href: string;
  icon?: React.ReactElement;
  collapsed: boolean;
}

export default function MainMenuItem({ title, icon, collapsed, href }: IMainMenuItemProps) {
  return (
    <li
      css={{
 height: 40, paddingLeft: collapsed ? 0 : 24 
}} key={`main-menu-li-${title}`}
    >
      <Link
        key={`main-menu-link-${title}`}
        to={href}
        css={{
          display: "flex",
          flexDirection: collapsed ? "column" : "row",
          alignItems: "center",
          height: "100%",
          width: "100%",
          color: "#000",
        }}
      >
        <span>{icon}</span> {!collapsed && <span>{title}</span>}
      </Link>
    </li>
  );
}
