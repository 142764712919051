import React, { useState } from "react";
import queryString from "query-string";
import { useQuery } from "react-apollo";
import { IGroupsQuery, IGroupsQueryVariables } from "../../../queries/__generated__/GroupsQuery.generated";
import GroupsQuery from "../../../queries/GroupsQuery";
import GroupsTable from "../paginated/GroupsTable";
import { IGroupsFilter, IGroupKind } from "../../../../types/types";
import TableAddStateToQueryString from "../../../components/table/TableAddStateToQueryString";
import { parseState } from "../../../utils/tablesUtils";
import { useLocation, useNavigate } from "react-router";
import TablePagination, { DEFAULT_CURSOR, ITablePaginationState } from "../../../components/table/TablePagination";
import { SortOrder } from "antd/lib/table/interface";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import TableTitle from "../../../components/table/TableTitle";
import TableOnSearch from "../../../components/table/TableOnSearch";

export interface IGroupsTableState {
  sortColumnKey: string;
  sortColumnOrder: SortOrder;
  cursor: ITablePaginationState;
}

interface IManageGroupsProps {
  search: string | null;
  groupsFilter: IGroupsFilter;
  filterByKind?: IGroupKind;
  filterByTag: any;
  historyOrigin: string;
  props: {
    children?: React.ReactNode;
  };
}

export default function ManageGroups({
  search,
  groupsFilter,
  filterByKind,
  filterByTag,
  historyOrigin,
  props,
}: IManageGroupsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    sortColumnKey: "",
    sortColumnOrder: "ascend",
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<IGroupsTableState>(initialFiltersState);

  const { data, loading } = useQuery<IGroupsQuery, IGroupsQueryVariables>(GroupsQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      search: (query.search || "").toString(),
      tags: filterByTag ? [filterByTag] : null,
      filterByKind: filterByKind || null,
      filter: groupsFilter,
      sort: getSortBy({
        order: initialFiltersState.sortColumnOrder,
        columnKey: initialFiltersState.sortColumnKey,
      }),
      cursor: calculateAndEncodeCursor(state.cursor),
    },
  });

  const groups = data && data.groups && data.groups.items ? data.groups.items : [];
  const totalCount = data?.groups?.pageInfo ? data.groups.pageInfo.totalCount || 0 : 0;

  return (
    <>
      <TableAddStateToQueryString tableState={state} historyOrigin={historyOrigin} query={query} />
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
 page: 1, size: state.cursor.size 
},
            });
          }
        }}
      />
      <GroupsTable
        loading={loading}
        groups={groups}
        state={state}
        archivedOnly={groupsFilter === IGroupsFilter.Archived}
        title={() => (
          <TableTitle
            title="Groups"
            count={totalCount}
            loading={loading}
            onResetFilters={() => {
              setState({
                sortColumnKey: "",
                sortColumnOrder: "ascend",
                cursor: {
 page: 1, size: state.cursor.size 
},
              });

              navigate(`${historyOrigin}?tab=${query.tab || ""}&search=${search}`);
            }}
          />
        )}
        footer={() => (
          <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
        )}
        onChange={(_pagination: any, _filters: any, sorter: any) => {
          setState({
            sortColumnKey: sorter.columnKey,
            sortColumnOrder: sorter.order,
            cursor: {
 page: 1, size: state.cursor.size 
},
          });
        }}
        {...props}
      />
    </>
  );
}

function getSortBy(sorter: any) {
  if (sorter && sorter.columnKey && sorter.order) {
    const direction = sorter.order === "descend" ? "descending" : "ascending";

    if (sorter.columnKey === "name") {
      return `name:${direction}`;
    }

    if (sorter.columnKey === "governments") {
      return `governmentsCount:${direction}`;
    }

    if (sorter.columnKey === "vendors") {
      return `vendorsCount:${direction}`;
    }

    if (sorter.columnKey === "visibility") {
      return `visibility:${direction}`;
    }
  }

  return null;
}
