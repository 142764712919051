import { Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import React, { FC } from "react";
import { Configure, connectInfiniteHits, Hit, HitsProvided } from "react-instantsearch-core";
import { Index, InstantSearch } from "react-instantsearch-dom";
import { IGovernment } from "../../../../types/types";
import algoliaClient from "../../../algoliaClient";
import getIndexName from "../../../getIndexName";
import { SearchInput, StatelessSearchResults } from "./BatchVendorAndGovernmentSearch";

type TSelectedEntity = {
  _id: string;
  slug: string;
};

interface IGovernmentSearchProps {
  title?: ModalProps["title"] | null;
  visible: boolean;
  onSelectGovernment: (object: TSelectedEntity) => void;
  onRequestClose?: () => void;
}

export default function GovernmentSearch({
  title,
  visible,
  onSelectGovernment,
  onRequestClose,
}: IGovernmentSearchProps) {
  return (
    <Modal width={720} title={title || "Search Governments"} visible={visible} onCancel={onRequestClose} footer={null}>
      <InstantSearch searchClient={algoliaClient} indexName={getIndexName("companies")}>
        <Configure hitsPerPage={3} />

        <div>
          <SearchInput />
          <Index indexName={getIndexName("governments")}>
            <div>
              <h3
                css={{
                  margin: 0,
                  marginBottom: 12,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Governments
              </h3>

              <SearchResults
                onClick={(government) => {
                  onSelectGovernment({
                    _id: government._id || government.objectID,
                    slug: government.slug,
                  });
                }}
              />
            </div>
          </Index>
        </div>
      </InstantSearch>
    </Modal>
  );
}

type TGovernment = Pick<IGovernment, "_id" | "name" | "slug" | "logo" | "city"> & Hit;

interface ISearchResultsProps extends Partial<HitsProvided<TGovernment>> {
  onClick: (entity: TGovernment) => void;
  refineNext?: () => void;
  hasMore?: boolean;
}

const SearchResults: FC<ISearchResultsProps> = connectInfiniteHits(StatelessSearchResults as any) as any;
