import { SearchAlgoliaIndex } from "@govlaunch/algolia";
import { MultiGovernmentSelect } from "@govlaunch/select";
import fp from "lodash/fp";
import React, { ReactEventHandler } from "react";
import { Field } from "react-final-form";
import { IGovernment } from "../../../../types/types";
import GroupsInput from "../../../components/form/GroupsInput";
import AttachmentInput from "../rich/AttachmentInput";
import LinkInput from "../rich/LinkInput";
import NewsworthyInput from "../rich/NewsworthyInput";
import OutcomeInput from "../rich/OutcomeInput";
import ProductsInput from "../rich/ProductsInput";
import StatusInput from "../rich/StatusInput";
import VendorsInput from "../rich/VendorsInput";
import * as palette from "@govlaunch/palette";

export enum ToolbarItems {
  Products,
  Newsworthy,
  Links,
  Attachments,
  Outcome,
  Vendors,
  Groups,
  Status,
  Locations,
}

interface IToolbarFieldsProps {
  activeItem: ToolbarItems | null;
}

export default function ToolbarFields({ activeItem }: IToolbarFieldsProps) {
  return (
    <>
      {activeItem === ToolbarItems.Products && (
        <Field name="products">{({ input }) => <ProductsInput {...input} />}</Field>
      )}

      {activeItem === ToolbarItems.Newsworthy && (
        <Field name="newsworthy">
          {({ input }) => (
            <NewsworthyInput
              onChange={(value) => {
                const newsworthy = fp.uniqBy(
                  (item) => item.url,
                  [
                    ...input.value,
                    {
                      ...value,
                      addedAt: Date.now(),
                    },
                  ],
                );

                input.onChange(newsworthy);
              }}
            />
          )}
        </Field>
      )}

      {activeItem === ToolbarItems.Links && (
        <Field name="links">
          {({ input }) => (
            <LinkInput
              {...input}
              onChange={(value) => {
                const link = fp.uniqBy(
                  (link) => link.url,
                  [
                    ...input.value,
                    {
                      ...value,
                      addedAt: Date.now(),
                    },
                  ],
                );

                input.onChange(link);
              }}
            />
          )}
        </Field>
      )}

      {activeItem === ToolbarItems.Attachments && (
        <Field name="attachments">
          {({ input }) => (
            <AttachmentInput
              {...input}
              onChange={(value) => {
                const attachment = fp.uniqBy(
                  (item) => item.url,
                  [
                    ...input.value,
                    {
                      ...value,
                      addedAt: Date.now(),
                    },
                  ],
                );

                input.onChange(attachment);
              }}
            />
          )}
        </Field>
      )}

      {activeItem === ToolbarItems.Outcome && <OutcomeInput />}

      {activeItem === ToolbarItems.Vendors && (
        <Field name="vendors">{({ input }) => <VendorsInput {...input} />}</Field>
      )}

      {activeItem === ToolbarItems.Groups && <GroupsInput fieldName="groups" />}

      {activeItem === ToolbarItems.Status && (
        <Field allowNull={true} name="status">
          {({ input }) => <StatusInput {...input} />}
        </Field>
      )}

      {activeItem === ToolbarItems.Locations && (
        <Field name="locations">
          {({ input }) => (
            <SearchAlgoliaIndex index="governments">
              {({
                results: governments,
                searchValue: inputValue,
                onChange: onInputValueChange,
              }: {
                results: IGovernment[];
                searchValue: string;
                onChange: ReactEventHandler;
                onInputValueChange: ReactEventHandler;
              }) => (
                <MultiGovernmentSelect
                  zIndex={1000}
                  borderColor={palette.lightestGray}
                  placeholder="Add other governments to your project"
                  governments={Array.isArray(input.value) ? input.value : []}
                  selectableGovernments={governments}
                  inputValue={inputValue}
                  onInputValueChange={onInputValueChange}
                  onBlur={input.onBlur}
                  onFocus={input.onFocus}
                  onChange={input.onChange}
                  minWidth="auto"
                />
              )}
            </SearchAlgoliaIndex>
          )}
        </Field>
      )}
    </>
  );
}
