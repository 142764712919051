import gql from "graphql-tag";

export default gql`
  query AllProductStackRequests {
    allProductStackRequests {
      _id
      status
      createdAt
      product {
        _id
        name
        slug
        logo
      }
      vendor {
        _id
        name
        slug
        logo
      }
      government {
        _id
        name
        slug
        logo
      }
      inviter {
        ... on PendingUser {
          _id
          slug
          fullName
          avatar
          email
        }
        ... on GovernmentUser {
          _id
          slug
          fullName
          avatar
          email
        }
        ... on VendorUser {
          _id
          slug
          fullName
          avatar
          email
        }
      }
      respondedBy {
        ... on PendingUser {
          _id
          fullName
          avatar
          email
        }
        ... on GovernmentUser {
          _id
          fullName
          avatar
          email
        }
        ... on VendorUser {
          _id
          fullName
          avatar
          email
        }
      }
    }
  }
`;
