import createDecorator from "final-form-calculate";
import { IPlanTier } from "../../../types/types";

const defaultPlanLimits = [
  {
    tier: IPlanTier.Basic,
    limits: {
      products: 20,
      enrichedProducts: 0,
      productTags: 1,
      projectsPerMonth: 20,
      groups: 0,
      groupInvitations: 0,
      teamMembers: 3,
      enrichedProfile: false,
      sharingPage: false,
      searchAccess: false,
      inviteGovernments: false,
    },
  },
  {
    tier: IPlanTier.Makers,
    limits: {
      products: 20,
      enrichedProducts: 3,
      productTags: 5,
      projectsPerMonth: 10,
      groups: 3,
      groupInvitations: 3,
      teamMembers: 5,
      enrichedProfile: true,
      sharingPage: false,
      searchAccess: false,
      inviteGovernments: true,
    },
  },
  {
    tier: IPlanTier.Makerspro,
    limits: {
      products: 20,
      enrichedProducts: 10,
      productTags: 5,
      projectsPerMonth: 20,
      groups: 3,
      groupInvitations: 10,
      teamMembers: 10,
      enrichedProfile: true,
      sharingPage: true,
      searchAccess: true,
      inviteGovernments: true,
    },
  },
  {
    tier: IPlanTier.Disruptor,
    limits: {
      products: 20,
      enrichedProducts: 10,
      productTags: 5,
      projectsPerMonth: 20,
      groups: 3,
      groupInvitations: 10,
      teamMembers: 10,
      enrichedProfile: true,
      sharingPage: true,
      searchAccess: true,
      inviteGovernments: true,
    },
  },
];

export const defaultPlanLimitsDecorator = createDecorator({
  field: "tier",
  updates: {
    products: (tierValue) => {
      return defaultPlanLimits.find((limit) => limit.tier === tierValue)?.limits.products;
    },
    enrichedProducts: (tierValue) => {
      return defaultPlanLimits.find((limit) => limit.tier === tierValue)?.limits.enrichedProducts;
    },
    productTags: (tierValue) => {
      return defaultPlanLimits.find((limit) => limit.tier === tierValue)?.limits.productTags;
    },
    projectsPerMonth: (tierValue) => {
      return defaultPlanLimits.find((limit) => limit.tier === tierValue)?.limits.projectsPerMonth;
    },
    groups: (tierValue) => {
      return defaultPlanLimits.find((limit) => limit.tier === tierValue)?.limits.groups;
    },
    groupInvitations: (tierValue) => {
      return defaultPlanLimits.find((limit) => limit.tier === tierValue)?.limits.groupInvitations;
    },
    teamMembers: (tierValue) => {
      return defaultPlanLimits.find((limit) => limit.tier === tierValue)?.limits.teamMembers;
    },
    enrichedProfile: (tierValue) => {
      return defaultPlanLimits.find((limit) => limit.tier === tierValue)?.limits.enrichedProfile;
    },
    sharingPage: (tierValue) => {
      return defaultPlanLimits.find((limit) => limit.tier === tierValue)?.limits.sharingPage;
    },
    searchAccess: (tierValue) => {
      return defaultPlanLimits.find((limit) => limit.tier === tierValue)?.limits.searchAccess;
    },
    inviteGovernments: (tierValue) => {
      return defaultPlanLimits.find((limit) => limit.tier === tierValue)?.limits.inviteGovernments;
    },
  },
});
