import gql from "graphql-tag";
import CompanyPlanFields from "../queries/fragments/CompanyPlanFields";

export default gql`
  mutation ResetVendorCustomLimits($vendorId: ObjectId!) {
    resetVendorCustomLimits(vendorId: $vendorId) {
      _id
      plan {
        ...CompanyPlanFields
      }
    }
  }

  ${CompanyPlanFields}
`;
