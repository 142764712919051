import { Col, RowWrap } from "@govlaunch/core";
import React from "react";
import { FormSpy } from "react-final-form";
import DateField from "../../form/fields/DateField";
import TextField from "../../form/fields/TextField";
import UrlField from "../../form/fields/UrlField";
import FormGrid from "../../form/FormGrid";
import FormSubmitSection from "../../form/FormSubmitSection";
import Section from "../../form/Section";
import SectionBody from "../../form/SectionBody";
import SectionHeader from "../../form/SectionHeader";
import SectionSplitter from "../../form/SectionSplitter";

interface ILandingPageEventFormProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  submitting: boolean;
  submitError?: string;
  submitButtonText?: string;
}

export default function LandingPageEventForm({
  onSubmit,
  submitting,
  submitError,
  submitButtonText = "Save",
}: ILandingPageEventFormProps) {
  return (
    <form autoComplete="off" onSubmit={onSubmit}>
      <FormGrid>
        <FormSubmitSection
          backButtonText="All Events"
          backButtonLocation="/events"
          discardChangesLocation="/events"
          submitButtonText={submitButtonText}
          submitting={submitting}
          submitError={submitError}
        />

        <RowWrap>
          <Col width={[1, 1, 1, 1]}>
            <SectionSplitter>
              <Section>
                <SectionHeader>The Basics</SectionHeader>

                <SectionBody>
                  <TextField fieldName="title" label="Title" placeholder="Type title..." />
                  <UrlField fieldName="url" label="Link URL" placeholder="https://www.sample.com" />
                  <TextField fieldName="location" label="Location" placeholder="е.g. San Francisco CA, US" />
                </SectionBody>
              </Section>

              <Section>
                <SectionHeader>Details</SectionHeader>

                <SectionBody>
                  <DateField fieldName="startDate" label="Start Date" />
                  <FormSpy
                    subscription={{
                      values: true,
                    }}
                  >
                    {({ values }) => <DateField fieldName="endDate" label="End Date" dateLimit={values.startDate} />}
                  </FormSpy>
                </SectionBody>
              </Section>
            </SectionSplitter>
          </Col>
        </RowWrap>
      </FormGrid>
    </form>
  );
}
