import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { IFormFieldProps } from "./FormFieldProps";
import { Checkbox } from "antd";

interface ILabelWithCheckboxFieldProps extends IFormFieldProps {
  colorChecked?: string;
  labelChecked?: string;
  labelWidth?: number;
}

export default function LabelWithCheckboxField({
  fieldName,
  label,
  colorChecked,
  labelChecked,
  labelWidth,
  ...props
}: ILabelWithCheckboxFieldProps) {
  if (!labelChecked) {
    labelChecked = label;
  }

  if (!colorChecked) {
    colorChecked = palette.mediumGray;
  }

  return (
    <Field name={fieldName}>
      {({ input }) => (
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            css={{
              color: input.value ? colorChecked : palette.mediumGray,
              fontWeight: input.value ? 700 : 500,
              width: labelWidth ? labelWidth : undefined,
              fontSize: 14,
            }}
          >
            {input.value ? labelChecked : label}
          </div>
          <Checkbox onChange={input.onChange} checked={input.value} {...props} />
        </div>
      )}
    </Field>
  );
}
