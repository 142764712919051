import { useEffect, useState } from "react";
import isValidUrl from "../utils/isValidUrl";

export interface IUnfurl {
  title: string;
  description: string;
  siteName: string;
  favicon: string;
  url: string;
}

interface IState {
  loading: boolean;
  meta: IUnfurl | null;
}

export default function useUnfurl(url: string, onUnfurl: (unfurl: IUnfurl) => any, onError: (err: Error) => any) {
  const [state, setState] = useState<IState>({
    loading: false,
    meta: null,
  });

  const unfurl = () => {
    let mounted = true;

    if (isValidUrl(url)) {
      setState({
        loading: true,
        meta: null,
      });

      fetch(`https://unfurl.govlaunch.com?url=${encodeURIComponent(url)}`)
        .then((response) => {
          if (response.ok) {
            return response.json().then(({ govlaunch }) => {
              if (mounted) {
                setState({
                  loading: false,
                  meta: {
                    ...govlaunch,
                    url,
                  },
                });

                if (typeof onUnfurl === "function") {
                  onUnfurl({
                    ...govlaunch,
                    url,
                  });
                }
              }
            });
          } else if (mounted) {
            setState({
              loading: false,
              meta: null,
            });

            onError(new Error(`Got unexpected status code: ${response.status}`));
          }
        })
        .catch((err) => {
          if (mounted) {
            setState({
              loading: false,
              meta: null,
            });

            if (typeof onError === "function") {
              onError(err);
            }
          }
        });
    }

    return () => {
      mounted = false;
    };
  };

  useEffect(unfurl, [url]);

  return [state.loading, state.meta];
}
