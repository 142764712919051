import { Alert, Button, Checkbox, Input, Radio, Select, Typography } from "antd";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import { useQuery } from "react-apollo";
import {
  ICollection,
  ICollectionSponsoring,
  ICollectionSponsoringSponsorshipLevel,
  ICompany,
  IProduct,
  ITag,
} from "../../../types/types";
import ColorPicker from "../../components/ColorPicker";
import AutoCompleteProductsQuery from "./graphql/queries/AutoCompleteProductsQuery";
import AutoCompleteVendorsQuery from "./graphql/queries/AutoCompleteVendorsQuery";
import {
  IAutoCompletePaidProductsQuery,
  IAutoCompletePaidProductsQueryVariables,
} from "./graphql/queries/__generated__/AutoCompleteProductsQuery.generated";
import {
  IAutoCompletePaidVendorsQuery,
  IAutoCompletePaidVendorsQueryVariables,
} from "./graphql/queries/__generated__/AutoCompleteVendorsQuery.generated";
import uniqBy from "lodash/fp/uniqBy";
import CollectionTagSelect from "./CollectionTagSelect";
import SectionHeader from "../form/SectionHeader";
import Section from "../form/Section";
import SectionBody from "../form/SectionBody";

type TCollectionFormValues = {
  name: string;
  tags: string[];
  description: string;
  sponsoring: ICollectionSponsoring;
  sponsoringVendor: string;
  sponsoringProduct: string;
  sponsoringLevel: string;
  cardBackground: string;
  isFeatured: boolean;
};

type TCollection = Pick<ICollection, "name" | "description" | "sponsoring" | "featuredAt" | "displayOptions"> & {
  tags: Pick<ITag, "_id" | "name" | "slug">[];
} & {
  sponsoringVendors: Pick<ICompany, "_id" | "name">[];
  sponsoringProducts: Pick<IProduct, "_id" | "name">[];
};

interface ICollectionFormProps {
  collection?: TCollection;
  onSubmit: (collection: TCollectionFormValues) => void;
  error?: string;
  title?: string;
}

export default function CollectionForm({
  title = "New Collection",
  collection,
  onSubmit,
  error,
}: ICollectionFormProps) {
  const [form] = useForm();
  const initialValues = getInitialValues(collection);

  form.setFieldsValue(initialValues);

  return (
    <Section
      css={{
        maxWidth: 600,
        marginLeft: 0,
        marginRight: 15,
      }}
    >
      <SectionHeader>{title}</SectionHeader>
      <SectionBody
        css={{
          paddingBottom: 0,
        }}
      >
        <Form
          onFinish={(values: any) => {
            onSubmit(values);
          }}
          form={form}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 24,
          }}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input.TextArea autoSize={true} />
          </Form.Item>

          <CollectionTagSelect initialTags={collection?.tags || []} />

          <Form.Item name="sponsoring" label="Sponsor type">
            <Radio.Group>
              <Radio.Button value={ICollectionSponsoring.VendorCurated}>Curated</Radio.Button>
              <Radio.Button value={ICollectionSponsoring.VendorSponsored}>Custom</Radio.Button>
              <Radio.Button value={ICollectionSponsoring.None}>None</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            noStyle={true}
            shouldUpdate={(prevValues, nextValues) => {
              return (
                prevValues.sponsoringVendor !== nextValues.sponsoringVendor ||
                prevValues.sponsoring !== nextValues.sponsoring
              );
            }}
          >
            {({ getFieldValue }) => {
              const vendor = getFieldValue("sponsoringVendor");
              const sponsoringType = getFieldValue("sponsoring");

              if (sponsoringType === ICollectionSponsoring.None) {
                return null;
              }

              return (
                <>
                  <VendorField collection={collection} />

                  <ProductField disabled={!vendor} vendor={vendor} collection={collection} />
                </>
              );
            }}
          </Form.Item>

          <ColorPicker
            name="cardBackground"
            label="Card color"
            labelCol={{
              span: 6,
            }}
          />

          <Form.Item name="isFeatured" label="Featured?" valuePropName="checked">
            <Checkbox>Should appear with Bundles</Checkbox>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 6,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
          {error && (
            <Form.Item
              wrapperCol={{
                offset: 6,
              }}
            >
              <Typography.Text type="danger">{error}</Typography.Text>
            </Form.Item>
          )}
        </Form>
      </SectionBody>
    </Section>
  );
}

function VendorField({ collection }: { collection?: TCollection }) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const onSearch = debounce((searchText) => setSearchQuery(searchText), 300);
  const { data } = useQuery<IAutoCompletePaidVendorsQuery, IAutoCompletePaidVendorsQueryVariables>(
    AutoCompleteVendorsQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        searchQuery,
      },
    },
  );

  return (
    <Form.Item
      name="sponsoringVendor"
      label="Sponsoring Vendor"
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Select showSearch={true} filterOption={false} onSearch={onSearch} allowClear={true}>
        {uniqBy(
          "_id",
          (data?.autoCompletePaidOrDisruptorVendors || [])
            .map(({ _id, name }) => ({
              _id,
              name,
            }))
            .concat(collection?.sponsoringVendors[0] || []),
        ).map((vendor) => (
          <Select.Option value={vendor._id} title={vendor.name} key={vendor._id}>
            {vendor.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

function ProductField({
  disabled,
  vendor,
  collection,
}: {
  disabled?: boolean;
  vendor: string;
  collection?: TCollection;
}) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const onSearch = debounce((searchText) => setSearchQuery(searchText), 300);
  const { data } = useQuery<IAutoCompletePaidProductsQuery, IAutoCompletePaidProductsQueryVariables>(
    AutoCompleteProductsQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        searchQuery,
        filterByVendor: vendor,
      },
    },
  );

  return (
    <Form.Item
      noStyle={true}
      shouldUpdate={(prevValues, nextValues) => prevValues.sponsoringProduct !== nextValues.sponsoringProduct}
    >
      {({ getFieldValue }) => {
        const product = getFieldValue("sponsoringProduct");

        return (
          <Form.Item
            name="sponsoringProduct"
            label="Sponsoring Product"
            help={
              product ? (
                <Alert
                  message="If you select a Product, it will show up instead of the vendor on Govlaunch"
                  type="warning"
                  css={{
                    fontSize: 13,
                    padding: 4,
                    marginTop: 4,
                  }}
                />
              ) : null
            }
            css={{
              marginBottom: 12,
            }}
          >
            <Select showSearch={true} filterOption={false} onSearch={onSearch} allowClear={true} disabled={disabled}>
              {uniqBy(
                "_id",
                (data?.autoCompleteProducts || [])
                  .map(({ _id, name }) => ({
                    _id,
                    name,
                  }))
                  .concat(collection?.sponsoringProducts[0] || []),
              ).map((product) => (
                <Select.Option value={product._id} title={product.name} key={product._id}>
                  {product.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}

function getInitialValues(collection: ICollectionFormProps["collection"]): Partial<TCollectionFormValues> {
  if (!collection) {
    return {
      name: "",
      tags: [],
      description: "",
      cardBackground: "#086BE5",
      sponsoring: ICollectionSponsoring.None,
      sponsoringLevel: ICollectionSponsoringSponsorshipLevel.Vendor,
      isFeatured: false,
    };
  }

  return {
    name: collection.name,
    tags: collection.tags.map((tag: any) => tag._id),
    sponsoring: collection.sponsoring || undefined,
    description: collection.description,
    sponsoringVendor: collection.sponsoringVendors[0]?._id,
    sponsoringProduct: collection.sponsoringProducts[0]?._id,
    cardBackground: collection.displayOptions?.cardBackgroundColorHex,
    isFeatured: !!collection.featuredAt,
  };
}
