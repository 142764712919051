import gql from "graphql-tag";

export default gql`
  mutation proposeStory(
    $government: ObjectId
    $title: String!
    $author: ObjectId!
    $coauthors: [ObjectId!]
    $vendorCoauthors: [ObjectId!]
    $collaborators: [ObjectId!]
    $introduction: String!
    $body: JSON!
    $highlights: [String!]
    $locations: [ObjectId!]
    $medias: [MediaInput!]
    $resources: [ResourceInput!]
    $newsworthy: [JSON!]
    $outsideProducts: [OutsideProductInput!]
    $products: [ObjectId!]
    $tags: [ObjectId]
    $audience: String
    $awards: [ObjectId!]!
  ) {
    proposeStory(
      government: $government
      title: $title
      author: $author
      coauthors: $coauthors
      vendorCoauthors: $vendorCoauthors
      collaborators: $collaborators
      introduction: $introduction
      locations: $locations
      body: $body
      highlights: $highlights
      medias: $medias
      resources: $resources
      newsworthy: $newsworthy
      outsideProducts: $outsideProducts
      products: $products
      tags: $tags
      audience: $audience
      awards: $awards
    ) {
      _id
      slug
      title
    }
  }
`;
