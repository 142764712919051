import React from "react";
import { INewsletterProject } from "../../../../types/types";
import { getSearchProjectsLink } from "./CampaignPreviewHeaderLinks";
import CampaignPreviewProjectCard from "./CampaignPreviewProjectCard";

interface ICampaignPreviewProjectsProps {
  projects: Array<INewsletterProject>;
  totalCount: number;
}

export default function CampaignPreviewProjects({ projects, totalCount }: ICampaignPreviewProjectsProps) {
  return (
    <>
      <tr>
        <td
          align="left"
          style={{
            background: "#e9f3fe",
            margin: 0,
            paddingLeft: "15px",
            paddingTop: "20px",
          }}
        >
          <table
            cellPadding="0"
            cellSpacing="0"
            style={{
              borderCollapse: "collapse",
              borderSpacing: "0px",
              width: "100%",
            }}
          >
            <tbody>
              <tr>
                <td
                  align="center"
                  valign="top"
                  style={{
                    padding: "0px",
                    margin: "0px",
                    width: "570px",
                  }}
                >
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    style={{
                      borderCollapse: "separate",
                      borderSpacing: "0px",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                    role="presentation"
                  >
                    <tbody>
                      <tr>
                        <td
                          align="left"
                          style={{
                            padding: 0,
                            margin: 0,
                            paddingTop: "5px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <h1
                            style={{
                              margin: 0,
                              lineHeight: "24px",
                              fontSize: "20px",
                              fontStyle: "normal",
                              fontWeight: "bold",
                              color: "#444444",
                              textAlign: "center",
                            }}
                          >
                            Innovation Projects
                          </h1>
                        </td>
                      </tr>
                      <tr>
                        <td
                          align="center"
                          style={{
                            padding: 0,
                            margin: 0,
                            paddingBottom: "10px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              WebkitTextSizeAdjust: "none",
                              //@ts-ignore
                              msTextSizeAdjust: "none",
                              lineHeight: "17px",
                              color: "#666666",
                              fontSize: "11px",
                            }}
                          >
                            <em>Powered by the international Govlaunch community</em>
                          </p>
                        </td>
                      </tr>
                      {projects.map((project, index) => (
                        <tr key={`project_${index}`}>
                          <CampaignPreviewProjectCard project={project} />
                        </tr>
                      ))}
                      <tr>
                        <td
                          align="center"
                          style={{
                            padding: 0,
                            margin: 0,
                            paddingBottom: "5px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingTop: "20px",
                          }}
                        >
                          <p
                            style={{
                              margin: 0,
                              WebkitTextSizeAdjust: "none",
                              //@ts-ignore
                              msTextSizeAdjust: "none",
                              lineHeight: "18px",
                              color: "#666666",
                              fontSize: "12px",
                            }}
                          >
                            <em>
                              Search our library of over {totalCount - (totalCount % 500)} crowdsourced innovation
                              projects from around the world...
                            </em>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <SearchProjectsButton />
      </tr>
    </>
  );
}

function SearchProjectsButton() {
  const searchUrl = getSearchProjectsLink();

  return (
    <td
      align="center"
      style={{
        padding: 0,
        margin: 0,
        paddingTop: "10px",
        paddingBottom: "20px",
        background: "#e9f3fe",
      }}
      dangerouslySetInnerHTML={{
        __html: `
          <!--[if mso]>
            <a href="${searchUrl}" target="_blank">
              <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" esdevVmlButton href="${searchUrl}" style="height:43px;v-text-anchor:middle;width:169px;" arcsize="7%" strokecolor="#086bef" strokeweight="1px" fillcolor="#086bef">
                <w:anchorlock></w:anchorlock>
                <center style='color:#ffffff;font-family:helvetica, "helvetica neue", arial, verdana, sans-serif;font-size:14px;font-weight:400;'>
                  Search Projects
                </center>
              </v:roundrect></a>
          <![endif]--> 
          <!--[if !mso]><!-- -->
            <span className="msohide es-button-border" style="border-style:solid;border-color:#086BEF;background:#086bef;border-width:1px;display:inline-block;border-radius:3px;width:auto;mso-hide:all">
              <a href="${searchUrl}" class="es-button es-button-1 msohide" target="_blank" style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:14px;border-style:solid;border-color:#086BEF;border-width:10px 30px;display:inline-block;background:#086BEF;border-radius:3px;font-family:helvetica, 'helvetica neue', arial, verdana, sans-serif;font-weight:normal;font-style:normal;line-height:17px;width:auto;text-align:center;mso-hide:all">
                <img src="https://lchjnb.stripocdn.email/content/guids/CABINET_f58f6a7604aedc7d0bbb77dbb5977418/images/92381628853855841.png" alt="icon" width="16" align="absmiddle" style="display:inline-block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;vertical-align:middle;margin-right:6px">
                  Search Projects
              </a>
            </span> 
          <!--<![endif]-->`,
      }}
    />
  );
}
