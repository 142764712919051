import algoliaClient from "./algoliaClient";

const env = process.env.GOVLAUNCH_ENV || process.env.NODE_ENV;

/**
 * @return {String}
 */
export function getAlgoliaEnvironment() {
  if (env === "production" || env === "mirror") {
    return "production";
  }

  if (env === "qa") {
    return "qa";
  }

  return "development";
}

/**
 * @param  {String} indexName
 * @return {Object}
 */
export default function getInstantSearchProps(indexName: string) {
  const algoliaSearchClient = algoliaClient;

  return {
    appId: process.env.ALGOLIA_CLIENT_ID || "EKEGGUH63H",
    apiKey: process.env.ALGOLIA_CLIENT_SECRET || "87534695a083036fa4334d278ce45de0",
    indexName: `${indexName}_${getAlgoliaEnvironment()}`,
    searchClient: algoliaSearchClient,
  };
}
