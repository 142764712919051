import { useEffect, useState } from "react";
import useWindowDimensions from "./useWindowDimensions";

export default function useIsMobile() {
  const resolutionThreshold = 430;
  const { width } = useWindowDimensions();
  const check = width ? width <= resolutionThreshold : false;
  const [isMobile, setIsMobile] = useState(check);

  useEffect(() => {
    if (!isMobile && width && width <= resolutionThreshold) {
      setIsMobile(true);
    }

    if (isMobile && width && width >= resolutionThreshold) {
      setIsMobile(false);
    }
  }, [width]);

  return isMobile;
}
