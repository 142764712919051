import React from "react";
import { Link } from "react-router-dom";

interface IViewDetailsProps {
  slug: string;
}

export default function ViewDetails({ slug }: IViewDetailsProps) {
  return (
    <li>
      <Link to={`/sponsorships/${slug}`}>View Details</Link>
    </li>
  );
}
