import React, { useState } from "react";
import AddPlanModal from "./AddPlanModal";

export default function AddPlanLink() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <a onClick={() => setIsOpen(!isOpen)}>Add New Plan</a>

      <AddPlanModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
}
