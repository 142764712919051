import React from "react";
import * as palette from "@govlaunch/palette";
import { IBlockLayout } from "./blockProps";
import BlockLayoutContainer from "../blockslayout/BlockLayoutContainer";
import BlockLayoutHeader from "../blockslayout/BlockLayoutHeader";

interface IEmptySpaceBlockProps {
  block: IBlockLayout;
  onRemoveBlock: () => void;
}

export default function EmptySpaceBlock({ block, onRemoveBlock }: IEmptySpaceBlockProps) {
  return (
    <BlockLayoutContainer block={block}>
      <BlockLayoutHeader block={block} onRemoveBlock={onRemoveBlock} />
      <div
        css={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 12,
          color: palette.darkGray,
          letterSpacing: 0.5,
          fontWeight: 500,
        }}
      >
        &nbsp;
      </div>
    </BlockLayoutContainer>
  );
}
