import gql from "graphql-tag";

export default gql`
  mutation ToggleUsersCanRequestContentTranslation($vendorId: ObjectId!, $requestContentTranslationAllowed: Boolean!) {
    toggleUsersCanRequestContentTranslation(
      vendorId: $vendorId
      requestContentTranslationAllowed: $requestContentTranslationAllowed
    )
  }
`;
