import React from "react";
import * as palette from "@govlaunch/palette";
import { Field } from "react-final-form";
import FieldValidationError from "../../../../components/form/FieldValidationError";
import { Select } from "@govlaunch/select";
import { Margin } from "../../../../spacings";

const selectOptions = [
  {
    label: "Not specified",
    value: null,
  },
  ...["1 - 10", "11 - 50", "51 - 100", "101 - 500", "500+"].map(value => ({
    label: value,
    value,
  })),
];

export default function EmployeesNumberField() {
  return (
    <Field name="numberOfEmployees">
      {({ input, meta }) => (
        <div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: palette.mediumGray,
                margin: 0,
              }}
            >
              Number of Employees
            </label>

            <FieldValidationError meta={meta} />
          </div>

          <Margin mt={10}>
            <Select {...input} size="large" items={selectOptions} placeholder="Select the Number of Employees" />
          </Margin>
        </div>
      )}
    </Field>
  );
}
