import React, { ChangeEvent } from "react";
import * as palette from "@govlaunch/palette";
import { Checkbox } from "antd";
import { Input } from "@govlaunch/core";
import { TBlockLayoutData } from "../blocks/blockProps";

interface IEditBlockNameAndVisibilityProps {
  data: TBlockLayoutData;
  setData: React.Dispatch<React.SetStateAction<TBlockLayoutData>>;
}

export default function EditBlockNameAndVisibility({ data, setData }: IEditBlockNameAndVisibilityProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <label
        css={{
          fontWeight: 500,
          fontSize: 14,
          color: palette.mediumGray,
        }}
      >
        Block Name
      </label>
      <Input
        css={{
          marginTop: "8px",
          height: 42,
          borderRadius: 5,
          border: `1px solid ${palette.lightestGray}`,
          fontSize: 14,
          letterSpacing: 0.5,
          fontWeight: 500,
          padding: "5px 10px",
          width: "100%",
          outline: 0,
          "::placeholder": {
            color: palette.sealBlue,
          },
          ":focus": {
            borderColor: palette.primary,
          },
        }}
        placeholder="Enter block name..."
        value={data.title}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setData({
            ...data,
            title: event.target.value,
          });
        }}
      />

      <Checkbox
        onChange={(event) =>
          setData({
            ...data,
            titleVisible: event.target.checked,
          })
        }
        checked={data.titleVisible}
        style={{
          marginTop: "8px",
          fontWeight: 500,
          fontSize: 14,
          color: palette.mediumGray,
        }}
      >
        {data.titleVisible ? "Display section title" : "Display section title"}
      </Checkbox>
    </div>
  );
}
