import gql from "graphql-tag";

export default gql`
  query CollectionsCounters {
    collectionsCounters {
      allCount
      unpublishedCount
      activeCount
      inactiveCount
      singleCount
      bundlesCount
      featuredCount
      curatedCount
      sponsoredCount
      archivedCount
    }
  }
`;
