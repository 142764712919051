import gql from "graphql-tag";

export default gql`
  mutation ToggleReportPublished($id: ObjectId!) {
    toggleReportPublished(id: $id) {
      _id
      unpublishedAt
    }
  }
`;
