import gql from "graphql-tag";

export default gql`
  query GetReports {
    allReports {
      items {
        _id
        slug
        title
        description
        featured
        locations
        unpublishedAt
        createdAt
        updatedAt
        archivedAt
      }
    }
  }
`;
