import gql from "graphql-tag";

export default gql`
  mutation ToggleFeaturedStory($targetType: FeaturedTargetType!, $targetId: ObjectId!) {
    toggleFeatured(targetType: $targetType, targetId: $targetId) {
      ... on Story {
        _id
        featured
      }
    }
  }
`;
