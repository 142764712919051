import React from "react";
import { Field } from "react-final-form";
import { Margin } from "../../../spacings";

export default function VisibilityField() {
  return (
    <Field name="visibility">
      {({ input }) => (
        <div>
          <label
            css={{
              cursor: "pointer",
            }}
          >
            <div
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                onChange={() => input.onChange("PUBLIC")}
                checked={input.value === "PUBLIC"}
                name="PUBLIC"
                css={{
                  verticalAlign: "middle",
                  marginTop: -3,
                }}
              />

              <div
                css={{
                  marginLeft: 5,
                }}
              >
                <span
                  css={{
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  Public
                </span>

                <span
                  css={{
                    display: "flex",
                    fontSize: 12,
                  }}
                >
                  Anybody can view this Group
                </span>
              </div>
            </div>
          </label>

          <Margin mt={10}>
            <label
              css={{
                cursor: "pointer",
              }}
            >
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  onChange={() => input.onChange("PRIVATE")}
                  checked={input.value === "PRIVATE"}
                  css={{
                    verticalAlign: "middle",
                  }}
                />

                <div
                  css={{
                    marginLeft: 5,
                  }}
                >
                  <span
                    css={{
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    Private
                  </span>

                  <span
                    css={{
                      display: "flex",
                      fontSize: 12,
                    }}
                  >
                    Only the Govlaunch members can view this Group
                  </span>
                </div>
              </div>
            </label>
          </Margin>
        </div>
      )}
    </Field>
  );
}
