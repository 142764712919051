import React, { useState } from "react";
import { useLocation } from "react-router";
import TablePagination, { DEFAULT_CURSOR } from "../../../components/table/TablePagination";
import { parseState } from "../../../utils/tablesUtils";
import queryString from "query-string";
import { useQuery } from "react-apollo";
import {
  IGetLandingPageEventsQuery,
  IGetLandingPageEventsQueryVariables,
} from "../../../queries/__generated__/GetLandingPageEvents.generated";
import GetLandingPageEvents from "../../../queries/GetLandingPageEvents";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import TableAddStateToQueryString from "../../../components/table/TableAddStateToQueryString";
import TableOnSearch from "../../../components/table/TableOnSearch";
import LandingPageEventsTable, { ILandingPageEventsTableState } from "./LandingPageEventsTable";

interface IManageEventsProps {
  search: string;
}

export default function ManageEvents({ search }: IManageEventsProps) {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    sortColumnKey: "",
    sortColumnOrder: "ascend",
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<ILandingPageEventsTableState>(initialFiltersState);

  const { data, loading } = useQuery<IGetLandingPageEventsQuery, IGetLandingPageEventsQueryVariables>(
    GetLandingPageEvents,
    {
      variables: {
        search,
        limit: null,
        cursor: calculateAndEncodeCursor(state.cursor),
        sort:
          state.sortColumnKey && state.sortColumnOrder
            ? {
                sortField: state.sortColumnKey,
                sortOrder: state.sortColumnOrder === "ascend" ? "asc" : "desc",
              }
            : null,
      },
      fetchPolicy: "network-only",
    },
  );

  const events = (data && data.landingPageEvents && data.landingPageEvents.items) || [];
  const totalCount =
    data && data.landingPageEvents && data.landingPageEvents.pageInfo
      ? data.landingPageEvents.pageInfo.totalCount || 0
      : 0;

  return (
    <>
      {typeof history !== "undefined" && history && (
        <TableAddStateToQueryString tableState={state} historyOrigin="/events" query={query} />
      )}
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
                page: 1,
                size: state.cursor.size,
              },
            });
          }
        }}
      />
      <LandingPageEventsTable
        events={events}
        loading={loading}
        footer={() => (
          <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
        )}
        onChange={(_pagination: any, _filter: any, sorter: any) => {
          console.log(sorter);
          setState({
            sortColumnKey: sorter.columnKey,
            sortColumnOrder: sorter.order,
            cursor: {
              page: 1,
              size: state.cursor.size,
            },
          });
        }}
        state={state}
      />
    </>
  );
}
