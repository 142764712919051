import React from "react";

export default function SadFaceIcon(props: React.SVGProps<any>) {
  return (
    <svg viewBox="0 0 66.381 66.381" {...props}>
      <path
        d="M66.381 35.351c0 16.616-13.473 30.086-30.09 30.086-16.618 0-30.09-13.47-30.09-30.086S19.673 5.265 36.291 5.265c16.617 0 30.09 13.47 30.09 30.086z"
        fill="#edc951"
      />
      <path
        d="M26.382 15.549c.488 4.159-1.541 6.038-5.73 6.038-4.188 0-6.001-1.891-5.421-6.038.618-4.416 5.421-9.437 5.421-9.437s5.24 5.252 5.73 9.437z"
        fill="#53ecf4"
      />
      <path d="M45.405 51.966a1.5 1.5 0 0 1-1.5-1.499c0-4.71-3.863-8.541-8.611-8.541h-5.055c-4.829 0-8.611 3.721-8.611 8.472a1.5 1.5 0 0 1-3 0c0-6.326 5.209-11.471 11.611-11.471h5.055c6.402 0 11.611 5.177 11.611 11.54a1.5 1.5 0 0 1-1.5 1.499zM15.95 20.266c-2.631 0-4.49-.667-5.683-2.039-1.156-1.328-1.567-3.248-1.223-5.706.678-4.84 5.612-10.047 5.822-10.266.28-.292.664-.459 1.069-.464a1.51 1.51 0 0 1 1.078.441c.573.575 5.621 5.752 6.158 10.321.281 2.4-.176 4.291-1.359 5.621-1.236 1.388-3.207 2.092-5.862 2.092zm.032-14.728c-1.359 1.653-3.592 4.718-3.967 7.398-.218 1.561-.045 2.678.514 3.322.583.669 1.734 1.009 3.421 1.009 1.761 0 2.979-.366 3.62-1.086.589-.663.798-1.766.621-3.278-.279-2.373-2.586-5.491-4.209-7.365z" />
      <path d="M31.589 64.116C14.171 64.116 0 49.947 0 32.53c0-8.667 3.441-16.751 9.688-22.762a1.5 1.5 0 0 1 2.08 2.162C6.113 17.37 3 24.686 3 32.53c0 15.763 12.825 28.586 28.589 28.586 15.765 0 28.59-12.823 28.59-28.586 0-15.762-12.825-28.586-28.59-28.586-4.31 0-8.451.935-12.31 2.778a1.5 1.5 0 1 1-1.293-2.707A31.32 31.32 0 0 1 31.589.945c17.419 0 31.59 14.169 31.59 31.585 0 17.417-14.171 31.586-31.59 31.586z" />
      <path d="M44.381 32.279c-3.593 0-6.629-3.108-6.629-6.785a1.5 1.5 0 1 1 3 0c0 2.052 1.662 3.785 3.629 3.785a1.5 1.5 0 0 1 0 3zM18.465 32.201a1.5 1.5 0 1 1 0-3c2.053 0 3.786-1.662 3.786-3.629a1.5 1.5 0 0 1 3 0c0 3.593-3.107 6.629-6.786 6.629z" />
    </svg>
  );
}
