import * as Yup from "yup";
import { SubscriptionType } from "./ManualSubscriptionForm";

export default Yup.object().shape({
  subscriptionType: Yup.string()
    .oneOf([
      SubscriptionType.NoStripeCustomer,
      SubscriptionType.StripeCustomerExists,
      SubscriptionType.StripeCustomerExistsWithSubscription,
      SubscriptionType.ChangePlan,
    ])
    .required("Cant'be blank!"),
  stripePlanId: Yup.string().required("Cant'be blank!"),
  customerId: Yup.string()
    .nullable()
    .when("subscriptionType", {
      is: (value: SubscriptionType | null) =>
        value !== SubscriptionType.NoStripeCustomer && value !== SubscriptionType.ChangePlan,
      then: Yup.string().required("Cant'be blank!"),
      otherwise: Yup.string().nullable(),
    }),
  stripeSubscriptionId: Yup.string()
    .nullable()
    .when("subscriptionType", {
      is: (value: SubscriptionType | null) => value === SubscriptionType.StripeCustomerExistsWithSubscription,
      then: Yup.string().required("Cant'be blank!"),
      otherwise: Yup.string().nullable(),
    }),
  billingName: Yup.string()
    .nullable()
    .when("subscriptionType", {
      is: (value: SubscriptionType | null) => value === SubscriptionType.NoStripeCustomer,
      then: Yup.string().required("Cant'be blank!"),
      otherwise: Yup.string().nullable(),
    }),
  email: Yup.string()
    .nullable()
    .when("subscriptionType", {
      is: (value: SubscriptionType | null) => value === SubscriptionType.NoStripeCustomer,
      then: Yup.string().required("Cant'be blank!"),
      otherwise: Yup.string().nullable(),
    }),
  freeSubscription: Yup.boolean().required(),
  sendInvoice: Yup.boolean().required(),
});
