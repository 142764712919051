import { Button, Divider, Form, Input, InputNumber, notification, TimePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React from "react";
import { useMutation, useQuery } from "react-apollo";
import DocumentTitle from "react-document-title";
import GetNewsletterSettingsQuery from "./graphql/GetNewsletterSettingsQuery";
import UpdateNewsletterSettingsMutation from "./graphql/UpdateNewsletterSettingsMutation";
import {
  INewsletterSettingsQuery,
  INewsletterSettingsQueryVariables,
} from "./graphql/__generated__/GetNewsletterSettingsQuery.generated";
import {
  IUpdateNewsletterSettingsMutation,
  IUpdateNewsletterSettingsMutationVariables,
} from "./graphql/__generated__/UpdateNewsletterSettingsMutation.generated";
import * as palette from "@govlaunch/palette";

export default function NewsletterSettingsForm() {
  const [form] = useForm();
  const { data } = useQuery<INewsletterSettingsQuery, INewsletterSettingsQueryVariables>(GetNewsletterSettingsQuery, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      form.setFieldsValue({
        subject: data?.getNewsletterSettings.subject,
        collections: data?.getNewsletterSettings.collections,
        storiesPerCollection: data?.getNewsletterSettings.storiesPerCollection,
        projectsPerCollection: data?.getNewsletterSettings.projectsPerCollection,
        timeToSend: moment(`${data.getNewsletterSettings.hourToSend}:00`, "HH:mm"),
        maxProjects: data.getNewsletterSettings.maxProjects || 10,
      });
    },
  });

  function reset() {
    form.setFieldsValue({
      subject: data?.getNewsletterSettings.subject,
      collections: data?.getNewsletterSettings.collections,
      storiesPerCollection: data?.getNewsletterSettings.storiesPerCollection,
      projectsPerCollection: data?.getNewsletterSettings.projectsPerCollection,
      timeToSend: moment(`${data?.getNewsletterSettings.hourToSend}:00`, "HH:mm"),
      maxProjects: data?.getNewsletterSettings.maxProjects || 10,
    });
  }

  const [updateNewsletterSettings] = useMutation<
    IUpdateNewsletterSettingsMutation,
    IUpdateNewsletterSettingsMutationVariables
  >(UpdateNewsletterSettingsMutation, {
    awaitRefetchQueries: true,
  });

  return (
    <DocumentTitle title="Newsletter | Settings">
      <div
        css={{
          overflow: "auto",
        }}
      >
        <div
          css={{
            padding: 20,
          }}
        >
          <h1
            css={{
              fontWeight: "bold",
              fontSize: 21,
              margin: 0,
            }}
          >
            Curated Newsletter Settings
          </h1>
          <h3
            css={{
              fontWeight: "normal",
              fontsize: 21,
              margin: 0,
            }}
          >
            Goes through MailJet
          </h3>

          <Divider />

          <div
            css={{
              width: 1024,
            }}
          >
            <Form
              initialValues={{
                subject: data?.getNewsletterSettings.subject,
                collections: data?.getNewsletterSettings.collections,
                storiesPerCollection: data?.getNewsletterSettings.storiesPerCollection,
                projectsPerCollection: data?.getNewsletterSettings.projectsPerCollection,
                timeToSend: moment(`${data?.getNewsletterSettings.hourToSend}:00`, "HH:mm"),
                maxProjects: data?.getNewsletterSettings.maxProjects || 10,
              }}
              onFinish={async (values: any) => {
                try {
                  await updateNewsletterSettings({
                    variables: {
                      input: {
                        subject: values.subject,
                        collections: data?.getNewsletterSettings.collections || 3,
                        storiesPerCollection: data?.getNewsletterSettings.storiesPerCollection || 3,
                        projectsPerCollection: data?.getNewsletterSettings.projectsPerCollection || 3,
                        hourToSend: moment(values.timeToSend).hours(),
                        maxProjects: values.maxProjects || 10,
                      },
                    },
                  });

                  notification.success({
                    message: "All set!",
                    description: "Newsletter saved successfully",
                  });
                } catch (err) {
                  notification.error({
                    message: "Hmm...",
                    description: "Something failed when saving this campaign",
                  });
                }
              }}
              form={form}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 14,
              }}
            >
              <div
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: 20,
                }}
              >
                <div>
                  <h3>General</h3>

                  <div>
                    <Form.Item
                      name="subject"
                      label="Subject"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>

                  <h3>Amount of Content</h3>

                  <Divider />

                  <Form.Item
                    name="maxProjects"
                    label="Max Projects"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber min={1} />
                  </Form.Item>

                  <Divider />

                  <h4
                    css={{
                      color: palette.red,
                      textAlign: "center",
                    }}
                  >
                    We strongly recommend to not exceed a total of 20 projects, otherwise your newsletter content might
                    get cut by email clients
                  </h4>
                </div>

                <div>
                  <h3>Schedule</h3>

                  <Divider />

                  <Form.Item
                    name="timeToSend"
                    label="Time to Send"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <TimePicker allowClear={true} showSecond={false} minuteStep={60} showNow={false} format="HH:mm" />
                  </Form.Item>

                  <div
                    css={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      columnGap: 20,
                    }}
                  >
                    <Button htmlType="reset" onClick={reset}>
                      Cancel
                    </Button>

                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
}
