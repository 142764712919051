import React from "react";
import DocumentTitle from "react-document-title";
import { Query } from "react-apollo";
import GetSponsorship from "../../queries/GetSponsorship";
import {
  IGetSponsorshipQuery,
  IGetSponsorshipQueryVariables,
} from "../../queries/__generated__/GetSponsorship.generated";
import { LoadingIndicator } from "@govlaunch/core";
import SponsorshipTabs from "./profile/SponsorshipTabs";
import { useParams } from "react-router";

export default function Sponsorship() {
  const params = useParams();

  return (
    <DocumentTitle title="Edit Sponsorship | Admin">
      <Query<IGetSponsorshipQuery, IGetSponsorshipQueryVariables>
        query={GetSponsorship}
        variables={{
          slug: params.slug || "",
        }}
      >
        {({ data, loading }) => {
          if (!data || loading) {
            return (
              <div
                css={{
                  padding: 20,
                }}
              >
                <LoadingIndicator />
              </div>
            );
          }

          const sponsorship = data.sponsorship;

          if (!sponsorship) {
            return null;
          }

          return <SponsorshipTabs sponsorship={sponsorship} />;
        }}
      </Query>
    </DocumentTitle>
  );
}
