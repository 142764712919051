import get from "lodash/fp/get";
import pick from "lodash/fp/pick";
import uniqueId from "lodash/uniqueId";
import { IGroupInput, IGroupKind } from "../../../types/types";
import { IGroupFieldsFragment } from "../../queries/fragments/__generated__/GroupFields.generated";
import prefixWithHttpIfNecessary from "../../utils/prefixWithHttpIfNecessary";

export function convertValuesToVariables(values: any): IGroupInput {
  const products = values.products.filter((product: any) => product._id).map(get("_id"));
  const outsideProducts = values.products.filter((product: any) => product.id).map(pick(["name", "url"]));

  return {
    logo: values.logo,
    name: values.name,
    about: values.about,
    vendor: values.vendor ? values.vendor._id : null,
    website: prefixWithHttpIfNecessary(values.website) || "",
    newsworthy: values.newsworthy.map((item: any) => {
      return {
        url: item.url,
        addedAt: item.addedAt,
        description: item.description,
        title: item.title,
        favicon: item.favicon,
        siteName: item.siteName,
      };
    }),
    products,
    outsideProducts,
    resources: values.resources.map(pick(["type", "url", "title", "body"])),
    socialMedia: pick(["facebook", "instagram", "linkedin", "twitter", "youtube"], values.socialMedia) || null,
    visibility: values.visibility,
    location: values.location,
    members: values.members || [],
    awards: values.awards.map(({ _id, objectID }: any) => _id || objectID),
    tags: (values.tags || []).map(({ _id, objectID, value }: any) => _id || objectID || value),
    kind: (values.kind as IGroupKind) || IGroupKind.Community,
  };
}

export function convertGroupToInitialValues(group: IGroupFieldsFragment) {
  const products = (group.products || []).map(pick(["_id", "name", "logo"]));
  const outsideProducts = (group.outsideProducts || []).map((outsideProduct) => {
    return {
      id: `@${uniqueId("")}`,
      name: outsideProduct.name,
      url: outsideProduct.url,
    };
  });

  return {
    logo: group.logo,
    name: group.name,
    location: group.location,
    about: group.about,
    vendor: group.owner,
    website: group.website,
    tags: group.tags.map(({ _id, name }) => ({
      value: _id,
      label: name,
    })),
    newsworthy: group.newsworthy.map((item: any) => {
      return {
        url: item.url,
        addedAt: item.addedAt,
        description: item.description,
        title: item.title,
        favicon: item.favicon,
        siteName: item.siteName,
      };
    }),
    products: [...products, ...outsideProducts].map(addObjectId),
    outsideProducts,
    resources: group.resources,
    socialMedia: group.socialMedia,
    visibility: group.visibility,
    awards: group.awards,
    kind: group.kind,
  };
}

const addObjectId = (entity: any) => ({
  ...entity,
  objectID: entity._id,
});
