import { notification } from "antd";
import { detect } from "detect-browser";
import React from "react";
import { IUser } from "../../../types/types";
import ImpersonateMutation from "../../mutations/Impersonate";
import getGovlaunchBaseUrl from "../../utils/getGovlaunchBaseUrl";

type TImpersonateable = Pick<IUser, "_id" | "fullName">;

interface IImpersonateProps {
  children: (fn: (user: TImpersonateable) => void) => any;
}

const browser = detect();

export default function Impersonate({ children }: IImpersonateProps) {
  return (
    <ImpersonateMutation>
      {(impersonate) => {
        return children(async (user: TImpersonateable) => {
          try {
            notification.info({
              message: `Impersonating...`,
              description: `You'll be redirected to the app logged in as ${user.fullName}`,
              placement: "topRight",
              duration: 3,
            });

            const token = await impersonate(user._id);

            if (token) {
              const portalHref = `${getGovlaunchBaseUrl("portal")}?impersonate=${token}`;

              // Safari doesn't allow opening pop-ups, so we'll use the same tab
              if (browser?.name === "safari" || browser?.name === "ios") {
                window.location.assign(portalHref);
              } else {
                window.open(portalHref, "_blank");
              }
            }
          } catch (err) {
            notification.info({
              message: `Couldn't impersonate`,
              description: `There was an error impersonating ${user.fullName}`,
              placement: "topRight",
            });
          }
        });
      }}
    </ImpersonateMutation>
  );
}
