import gql from "graphql-tag";
import StoryFields from "./fragments/StoryFields";

export default gql`
  query GetStories(
    $search: String
    $tab: String = "ALL"
    $tags: [ObjectId!]
    $featured: Boolean
    $audience: StoryAudience
    $status: StoryStatus
    $country: String
    $limit: Int = 15
    $sort: String
    $cursor: Cursor
  ) {
    allStories(
      search: $search
      tab: $tab
      tags: $tags
      featured: $featured
      audience: $audience
      status: $status
      country: $country
      limit: $limit
      sort: $sort
      cursor: $cursor
    ) {
      items {
        _id
        ...StoryFieldsAdmin
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor
      }
    }
  }

  ${StoryFields}
`;
