import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popconfirm } from "antd";
import React from "react";

interface IRemoveBlockButtonProps {
  onRemoveBlock: () => void;
}

export default function RemoveBlockButton({ onRemoveBlock }: IRemoveBlockButtonProps) {
  return (
    <Popconfirm title="Are you sure?" onConfirm={() => onRemoveBlock()}>
      <div
        css={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "32px",
          height: "32px",
          "&:hover": {
            opacity: 0.8,
          },
        }}
      >
        <FontAwesomeIcon
          icon={faTimes}
          color="#aaaaaa"
          style={{
            width: "18px",
            height: "18px",
          }}
        />
      </div>
    </Popconfirm>
  );
}
