import { Select } from "@govlaunch/web";
import React from "react";
import { Field } from "react-final-form";

export default function AudienceField() {
  return (
    <Field name="audience">
      {({ input }) => (
        <Select {...input}>
          <option value="public">Public</option>
          <option value="community">Community</option>
        </Select>
      )}
    </Field>
  );
}
