import { Mutation } from "react-apollo";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import ToggleInnovatorMutation from "./ToggleInnovatorMutation";
import {
  IToggleInnovatorMutation,
  IToggleInnovatorMutationVariables,
} from "./__generated__/ToggleInnovatorMutation.generated";

interface IToggleInnovatorProps {
  entityId: string;
  entityType: string;
  children: (toggle: Function) => ReactElement;
}

export default function ToggleInnovator({ entityId, entityType, children, ...props }: IToggleInnovatorProps) {
  return (
    <Mutation<IToggleInnovatorMutation, IToggleInnovatorMutationVariables>
      variables={{
        entityId,
        entityType,
      }}
      mutation={ToggleInnovatorMutation}
      {...props}
    >
      {toggle => children(toggle)}
    </Mutation>
  );
}

ToggleInnovator.propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
};
