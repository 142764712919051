import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";

export default function RequestTranslationSettings() {
  return (
    <div
      css={{
        display: "grid",
        gridRowGap: 16,
      }}
    >
      <Field name="allowRequestTranslation" type="checkbox">
        {({ input }) => (
          <div>
            <label>
              <input type="checkbox" {...input} />

              <span
                css={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: palette.mediumGray,
                  marginLeft: 8,
                }}
              >
                Users can request translation for this content
              </span>
            </label>
          </div>
        )}
      </Field>
    </div>
  );
}
