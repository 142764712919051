import React from "react";
import { Field } from "react-final-form";

export default function KindField() {
  return (
    <Field name="kind">
      {({ input }) => (
        <div
          css={{
            display: "grid",
            gridRowGap: 12,
          }}
        >
          {[
            {
              kind: "COMMUNITY",
              label: "Community group",
            },
            {
              kind: "DISCUSSION",
              label: "Discussion-related group",
            },
            {
              kind: "VENDOR",
              label: "Vendor group",
            },
          ].map(({ label, kind }) => {
            return (
              <label
                key={kind}
                css={{
                  cursor: "pointer",
                }}
              >
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="radio"
                    onChange={() => input.onChange(kind)}
                    checked={input.value === kind}
                    name={kind}
                    css={{
                      verticalAlign: "middle",
                      marginTop: -3,
                    }}
                  />

                  <div
                    css={{
                      marginLeft: 5,
                    }}
                  >
                    <span
                      css={{
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {label}
                    </span>

                    <span
                      css={{
                        display: "flex",
                        fontSize: 12,
                      }}
                    >
                      {label}
                    </span>
                  </div>
                </div>
              </label>
            );
          })}
        </div>
      )}
    </Field>
  );
}
