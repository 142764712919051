import gql from "graphql-tag";
import SolutionFieldsFragment from "../fragments/SolutionFieldsFragment";

export default gql`
  query AllSolutions($searchText: String, $filterByStatus: SolutionStatus) {
    allSolutions(searchText: $searchText, filterByStatus: $filterByStatus) {
      ...SolutionFields
    }
  }

  ${SolutionFieldsFragment}
`;
