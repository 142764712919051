import React, { useState } from "react";
import { Button, notification, Popconfirm, Popover as AntdPopover } from "antd";
import * as palette from "@govlaunch/palette";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { useToggle } from "react-use";
import {
  IArchiveProjectsByIdsMutation,
  IArchiveProjectsByIdsMutationVariables,
} from "../__generated__/ProjectsBulkActions.generated";
import { ProjectsAssignTagsModal } from "./ProjectsAssignTagsModal";
import { IVendorProjectFieldsFragment } from "../../../queries/fragments/__generated__/VendorProjectFields.generated";
import { IGovernmentProjectFieldsFragment } from "../../../queries/fragments/__generated__/GovernmentProjectFields.generated";
import { IGroupProjectFieldsFragment } from "../../../queries/fragments/__generated__/GroupProjectFields.generated";

interface IProjectsBulkActionsButtonProps {
  projects: Array<IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment>;
  onArchiveProjects: () => void;
  refetch: () => void;
}

const AchiveProjectMutation = gql`
  mutation ArchiveProjectsByIds($projectIds: [ObjectId!]!) {
    archiveProjectsByIds(projectIds: $projectIds)
  }
`;

export default function ProjectsBulkActionsButton({
  projects,
  onArchiveProjects,
  refetch,
}: IProjectsBulkActionsButtonProps) {
  const [on, toggle] = useToggle(false);
  const [bulkTaggingOpen, setBulkTaggingOpen] = useState(false);

  const [archiveProjectByIds] = useMutation<IArchiveProjectsByIdsMutation, IArchiveProjectsByIdsMutationVariables>(
    AchiveProjectMutation,
    {
      onCompleted: () => {
        notification.success({
          message: "Success!",
          description: `${projects.length} projects archived`,
        });
        toggle(false);
        onArchiveProjects();
      },
      onError: () => {
        notification.error({
          message: "Error!",
          description: "Something went wrong!",
        });
      },
    },
  );

  return (
    <>
      <AntdPopover
        visible={on}
        trigger="click"
        placement="rightBottom"
        onVisibleChange={toggle}
        content={
          <ul
            css={{
              margin: 0,
              padding: 0,
              listStyle: "none",
              "> li:not(:first-of-type)": {
                marginTop: 10,
              },
            }}
          >
            <li>
              <a
                href="#"
                css={{
                  color: palette.blue,
                }}
                onClick={() => {
                  toggle();
                  setBulkTaggingOpen(!bulkTaggingOpen);
                }}
              >
                Manage Tags
              </a>
            </li>
            <li css={{
 borderTop: "1px solid", borderColor: palette.lightGray 
}}
            >
              <Popconfirm
                title={`Are you sure, you want to archive ${projects.length} projects?`}
                onConfirm={() => {
                  archiveProjectByIds({
 variables: {
 projectIds: projects.map((project) => project._id) 
} 
});
                }}
              >
                <Button
                  type="link" danger={true} css={{
 paddingLeft: 0 
}}
                >
                  Archive
                </Button>
              </Popconfirm>
            </li>
          </ul>
        }
      >
        <span
          role="button"
          css={{
            cursor: "pointer",
          }}
        >
          <Button>Bulk Actions</Button>
        </span>
      </AntdPopover>
      <ProjectsAssignTagsModal
        projects={projects}
        isOpen={bulkTaggingOpen}
        onClose={() => {
          setBulkTaggingOpen(false);
        }}
        onSuccess={() => {
          setBulkTaggingOpen(false);
          refetch();
        }}
      />
    </>
  );
}
