import gql from "graphql-tag";

export default gql`
  mutation SetProfileCover($targetType: ProfileCoverTarget!, $targetId: ObjectId!, $cover: CoverInput) {
    setProfileCover(targetType: $targetType, targetId: $targetId, cover: $cover) {
      __typename
      ... on Government {
        _id
        cover {
          ...CoverFragment
        }
      }
      ... on Company {
        _id
        cover {
          ...CoverFragment
        }
      }
      ... on VendorUser {
        _id
        cover {
          ...CoverFragment
        }
      }
      ... on GovernmentUser {
        _id
        cover {
          ...CoverFragment
        }
      }
      ... on PendingUser {
        _id
        cover {
          ...CoverFragment
        }
      }
    }
  }

  fragment CoverFragment on Cover {
    __typename
    original
    repositioned
  }
`;
