import React, { useState } from "react";
import * as palette from "@govlaunch/palette";
import ToolbarFields, { ToolbarItems } from "./ToolbarFields";
import ToolbarControls from "./ToolbarControls";
import { Margin } from "../../../spacings";
import { Button } from "@govlaunch/core";
import PrivacySelect, { IValue } from "../PrivacySelect";
import { Field } from "react-final-form";

interface IToolbarProps {
  submitting: boolean;
  submitButtonText: string;
  submitError?: any;
}

export default function Toolbar({ submitting, submitButtonText, submitError }: IToolbarProps) {
  const [activeToolbarItem, setActiveToolbarItem] = useState<ToolbarItems | null>(null);
  const [projectVisibility, setProjectVisibility] = useState<IValue>("public");

  return (
    <>
      <div
        css={{
          height: 1,
          width: "100%",
          backgroundColor: palette.lightestSealBlue,
        }}
      />

      <ToolbarFields activeItem={activeToolbarItem} />

      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ToolbarControls onChange={setActiveToolbarItem} />
        <div
          css={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label
            css={{
              cursor: "pointer",
            }}
          >
            <Margin
              mr={12}
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Margin
                ml={6}
                css={{
                  display: "inline-flex",
                }}
              >
                <Field name="audience">
                  {({ input }) => {
                    if (projectVisibility !== input.value) {
                      setProjectVisibility(input.value);
                    }
                    return (
                      <PrivacySelect
                        value={input.value}
                        onChange={(e) => {
                          input.onChange(e);
                          setProjectVisibility(e);
                        }}
                      />
                    );
                  }}
                </Field>
              </Margin>
            </Margin>
          </label>

          <Button
            size="xsmall"
            color={submitting ? "disabled" : palette.innovatorBlue}
            disabled={submitting}
            type="submit"
            css={{
              userSelect: "none",
              padding: projectVisibility === "private" ? "0 8px" : "0 14px",
            }}
          >
            {submitting ? "Please wait..." : projectVisibility === "private" ? "Save Draft" : submitButtonText}
          </Button>
        </div>
      </div>
      {submitError && (
        <div
          css={{
            color: palette.red,
            fontSize: 14,
            marginTop: -5,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {submitError}
        </div>
      )}
    </>
  );
}
