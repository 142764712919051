import { Mutation } from "react-apollo";
import React from "react";
import ToggleFeaturedProduct from "../../mutations/ToggleFeaturedProduct";
import { IProduct } from "../../../types/types";
import {
  IToggleFeaturedProductMutation,
  IToggleFeaturedProductMutationVariables,
} from "../../mutations/__generated__/ToggleFeaturedProduct.generated";

export function ToggleFeatured({ children }: any) {
  return (
    <Mutation<IToggleFeaturedProductMutation, IToggleFeaturedProductMutationVariables> mutation={ToggleFeaturedProduct}>
      {(toggle) =>
        children((product: IProduct) => {
          toggle({
            variables: {
              targetId: product._id,
            },
          });
        })
      }
    </Mutation>
  );
}
