import React, { useState } from "react";
import { useQuery } from "react-apollo";
import AutoCompleteStories from "../../queries/AutoCompleteStories";
import {
  IAutoCompleteStoriesQuery,
  IAutoCompleteStoriesQueryVariables,
} from "../../queries/__generated__/AutoCompleteStories.generated";
import debounce from "lodash/debounce";
import { AutoComplete, Input } from "antd";

// It's 16:24 on Friday and I'm fucking pissed off I could be writing this whole stuff from home!
// If God says it could be any, if not, it will be a void
export type avoid = any | void;

export type TAutoCompleteStory = IAutoCompleteStoriesQuery["autoCompleteStories"][0];

interface IStoriesAutoCompleteProps {
  onSelect: (selectedItem: TAutoCompleteStory) => avoid;
}

export default function StoriesAutoComplete({ onSelect }: IStoriesAutoCompleteProps) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");

  const onSearch = debounce((searchText) => setSearchQuery(searchText), 300);

  const { loading, data } = useQuery<IAutoCompleteStoriesQuery, IAutoCompleteStoriesQueryVariables>(
    AutoCompleteStories,
    {
      variables: {
        searchQuery,
        limit: 10,
      },
    },
  );

  const searchResultsArray = !data || !data.autoCompleteStories ? [] : data.autoCompleteStories;

  const options = searchResultsArray.map((item) => {
    return {
      value: item._id,
      label: (
        <div>
          <strong>{item.title}</strong>
        </div>
      ),
    };
  });

  return (
    <AutoComplete
      options={searchQuery === "" ? [] : options}
      onSearch={onSearch}
      onSelect={(selectedItem: any) => {
        const match = searchResultsArray.find((candidate) => {
          return candidate._id === selectedItem;
        });

        if (match) {
          onSelect(match);
          setSearchQuery("");
          setInputValue("");
        }
      }}
      value={inputValue}
      bordered={false}
    >
      <Input.Search
        size="large"
        spellCheck={false}
        loading={loading}
        placeholder="Type to search stories..."
        onChange={(e) => setInputValue(e.target.value)}
      />
    </AutoComplete>
  );
}
