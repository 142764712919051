import gql from "graphql-tag";

export default gql`
  mutation CreateExternalLink($data: ExternalLinkInput!) {
    createExternalLink(data: $data) {
      _id
      title
      url
      source
    }
  }
`;
