import React from "react";
import { IGetAllPlansQuery } from "../../queries/__generated__/GetAllPlans.generated";
import TableRowActions from "../../components/table/TableRowActions";
import { Link } from "react-router-dom";

interface IPlanActionsProps {
  plan: IGetAllPlansQuery["allPlans"][0];
}

export default function PlanActions({ plan }: IPlanActionsProps) {
  return (
    <TableRowActions title="Plan Actions">
      {() => (
        <li>
          <Link to={`/billing/plans/${plan._id}`}>Edit</Link>
        </li>
      )}
    </TableRowActions>
  );
}
