import { Button, notification, Popconfirm } from "antd";
import React, { useState } from "react";
import { useMutation } from "react-apollo";
import ForceCancelSubscription from "../../../mutations/ForceCancelSubscription";
import {
  IForceCancelSubscriptionMutation,
  IForceCancelSubscriptionMutationVariables,
} from "../../../mutations/__generated__/ForceCancelSubscription.generated";

interface ICancelSubscriptionButtonProps {
  subscriptionId: string;
  refetch: () => void;
}

export default function CancelSubscriptionButton({
  subscriptionId,
  refetch,
  ...props
}: ICancelSubscriptionButtonProps) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [cancelSubscription] = useMutation<IForceCancelSubscriptionMutation, IForceCancelSubscriptionMutationVariables>(
    ForceCancelSubscription,
    {
      variables: {
        subscriptionId,
      },
    },
  );

  return (
    <Popconfirm
      title="This will cancel the current subscription immediately. This action cannot be undone!"
      visible={visible}
      onCancel={() => setVisible(false)}
      okButtonProps={{
        loading,
      }}
      onConfirm={async () => {
        setLoading(true);
        const result = await cancelSubscription();

        if (result && result.data && result.data.forceCancelSubscription) {
          notification.success({
            message: "Vendor unsubscribed!",
            description: "Vendor subscription cancelled successfully!",
          });

          setLoading(false);
          setVisible(false);
          refetch();
        }
      }}
      okText="Yes, Continue"
      cancelText="Don’t Cancel"
    >
      <Button danger={true} size="large" onClick={() => setVisible(true)} {...props}>
        Cancel Subscription
      </Button>
    </Popconfirm>
  );
}
