import { Component } from "react";
import PropTypes from "prop-types";

interface IChildrenFunc {
  hover: boolean;
  onMouseEnter: React.MouseEventHandler;
  onMouseLeave: React.MouseEventHandler;
}

interface IDelayedHoverProps {
  ms: number;
  children: (arg: IChildrenFunc) => any;
}

export default class DelayedHover extends Component<IDelayedHoverProps> {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  state = {
    hover: false,
  };

  timeout!: NodeJS.Timeout;

  handleMouseEnter = () => {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({
        hover: true,
      });
    }, 1000);
  };

  handleMouseLeave = () => {
    clearTimeout(this.timeout);

    this.setState({
      hover: false,
    });
  };

  render() {
    return this.props.children({
      hover: this.state.hover,
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
    });
  }
}
