import * as palette from "@govlaunch/palette";
import Downshift from "downshift";
import React, { createRef, FocusEvent, useState } from "react";
import { HitsProvided } from "react-instantsearch-core";
import { Configure, connectHits, connectSearchBox, InstantSearch } from "react-instantsearch-dom";
import { Spring } from "react-spring";
import { ITag } from "../../../types/types";
import getInstantSearchProps from "../../getInstantSearchProps";
import { Margin } from "../../spacings";

type AlgoliaTag = Pick<ITag, "name" | "_id"> & { objectID?: string };

type ITagType = "PRODUCT" | "STORY" | "PROJECT" | "DEPARTMENT" | "AWARD";

interface ITagsSelectProps {
  hits: (AlgoliaTag & { objectID: string })[];
  placeholder?: string;
  value: AlgoliaTag[];
  type?: ITagType | ITagType[];
  onChange: (selectedTag: any) => any;
  onFocus: (event?: FocusEvent<HTMLElement> | undefined) => void;
  onBlur: (event?: FocusEvent<HTMLElement> | undefined) => void;
}

function parseFacetFilters(type: ITagType | ITagType[] | null | undefined) {
  if (!type) {
    return [[]];
  }

  let includedTypes = null;

  if (!Array.isArray(type)) {
    includedTypes = [`types:${type}`, `types:CUSTOM`];
  }

  if (Array.isArray(type) && type.length > 0) {
    includedTypes = [...type.map((value) => `types:${value}`), `types:CUSTOM`];
  }

  return [includedTypes];
}

function TagsSelectContainer(props: Omit<ITagsSelectProps, "hits">) {
  return (
    <InstantSearch {...getInstantSearchProps("tags")}>
      <ConnectedTagsSelect {...props} />
    </InstantSearch>
  );
}

function TagsSelect({
  hits: tags,
  placeholder,
  value,
  onFocus,
  onBlur,
  type,
  onChange,
}: ITagsSelectProps & HitsProvided<ITag>) {
  const inputRef = createRef<HTMLInputElement>();
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const selectableTags = skipTags(value, tags).slice(0, 5);

  return (
    <>
      <SearchBox defaultRefinement={inputValue} />
      {type && <Configure facetFilters={parseFacetFilters(type)} />}

      <Downshift<any>
        id="tags-input-memo"
        selectedItem={null}
        inputValue={inputValue}
        itemToString={(item) => (item ? item._id : null)}
        stateReducer={(_, changes) => {
          switch (changes.type) {
            case Downshift.stateChangeTypes.changeInput:
              return {
                ...changes,
                highlightedIndex: null,
              };
            case Downshift.stateChangeTypes.keyDownEnter:
              return {
                ...changes,
                isOpen: true,
                highlightedIndex: 0,
              };
            case Downshift.stateChangeTypes.clickItem:
              return {
                ...changes,
                isOpen: true,
                highlightedIndex: 0,
              };
          }

          return changes;
        }}
        onChange={(selectedTag) => {
          setInputValue("");
          onChange([...value, selectedTag]);
        }}
        onInputValueChange={(value, changes) => {
          if ((changes as any).type === Downshift.stateChangeTypes.changeInput) {
            setInputValue(value);
          }
        }}
      >
        {({ getInputProps, getItemProps, getMenuProps, highlightedIndex, isOpen, openMenu }) => (
          <div>
            <div
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.focus();
                }
              }}
              css={{
                minHeight: 42,
                border: `solid 1px ${isFocused ? palette.primary : palette.lightestGray}`,
                borderRadius: 5,
                padding: 5,
                display: "flex",
                alignItems: "center",
                cursor: "text",
              }}
            >
              <div
                css={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {value.map((tag) => {
                  return <Tag key={tag.objectID || tag._id}>{tag.name}</Tag>;
                })}

                <input
                  {...getInputProps({
                    ref: inputRef,
                    onFocus: (event) => {
                      setIsFocused(true);

                      if (onFocus) {
                        onFocus(event);
                      }

                      openMenu();
                    },
                    onBlur: (event) => {
                      setIsFocused(false);

                      if (onBlur) {
                        onBlur(event);
                      }
                    },
                    onKeyDown: (event) => {
                      if (event.key === "Backspace" && !(event.target as HTMLInputElement).value) {
                        onChange([...value.slice(0, -1)]);
                      } else if (event.key === "Enter") {
                        event.preventDefault();
                      }
                    },
                  })}
                  placeholder={placeholder || "Type to select tags..."}
                  css={{
                    flexGrow: 1,
                    outline: 0,
                    fontSize: 14,
                    margin: 5,
                    border: 0,
                    "&::placeholder": {
                      fontSize: 14,
                      color: palette.sealBlue,
                    },
                  }}
                />
              </div>
            </div>

            {isOpen && selectableTags.length > 0 && (
              <Margin mt={10}>
                <div
                  {...getMenuProps()}
                  css={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginLeft: -5,
                  }}
                >
                  {selectableTags.map((tag, index) => {
                    return (
                      <Spring
                        key={tag.objectID || tag._id}
                        config={{
                          duration: 50,
                        }}
                        from={{
                          transform: "scale(1)",
                        }}
                        to={{
                          transform: highlightedIndex === index ? "scale(1.05)" : "scale(1)",
                        }}
                      >
                        {(style) => (
                          <SelectableTag
                            {...getItemProps({
                              item: tag,
                              index,
                            })}
                            style={style}
                            css={{
                              cursor: "pointer",
                              backgroundColor: highlightedIndex === index ? palette.lightBlue : null,
                              "&:hover": {
                                backgroundColor: palette.lightestBlue,
                              },
                            }}
                          >
                            {tag.name}
                          </SelectableTag>
                        )}
                      </Spring>
                    );
                  })}
                </div>
              </Margin>
            )}
          </div>
        )}
      </Downshift>
    </>
  );
}

interface ISelectableTagProps {
  [key: string]: any;
}

function SelectableTag(props: ISelectableTagProps) {
  return (
    <span
      {...props}
      css={{
        padding: "5px 10px",
        backgroundColor: palette.lightestGray,
        borderRadius: 10,
        margin: 5,
        fontSize: 12,
        color: palette.darkGray,
      }}
    />
  );
}

function Tag(props: ISelectableTagProps) {
  return (
    <span
      {...props}
      css={{
        padding: "5px 5px",
        backgroundColor: "rgba(219, 236, 247, 0.5)",
        borderRadius: 10,
        margin: 5,
        fontSize: 12,
        color: palette.darkGray,
      }}
    />
  );
}

function skipTags(tagsToSkip: AlgoliaTag[], tags: AlgoliaTag[]): AlgoliaTag[] {
  const idsToSkip = tagsToSkip.map(({ objectID, _id }) => objectID || _id);

  return tags.filter((tag) => {
    return !idsToSkip.includes(tag.objectID || tag._id);
  });
}

const SearchBox = connectSearchBox(() => null);

const ConnectedTagsSelect = connectHits(TagsSelect as any);

export default TagsSelectContainer;
