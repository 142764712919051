import { LoadingIndicator } from "@govlaunch/core";
import React from "react";
import DocumentTitle from "react-document-title";
import { useParams } from "react-router";
import * as palette from "@govlaunch/palette";

import { useQuery } from "react-apollo";
import GetLandingPageEventById from "../../../queries/GetLandingPageEventById";
import {
  IGetLandingPageEventByIdQuery,
  IGetLandingPageEventByIdQueryVariables,
} from "../../../queries/__generated__/GetLandingPageEventById.generated";
import EditLandingPageEventForm from "./EditLandingPageEventForm";

export default function EditLandingPageEventsPage() {
  const params = useParams();

  const { data, loading } = useQuery<IGetLandingPageEventByIdQuery, IGetLandingPageEventByIdQueryVariables>(
    GetLandingPageEventById,
    {
      variables: {
        id: params.id,
      },
    },
  );

  return (
    <DocumentTitle title="Landing Page Events | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",

          overflow: "auto",
        }}
      >
        {loading && (
          <div>
            <LoadingIndicator />
          </div>
        )}

        {data && data.getLandingPageEventById && <EditLandingPageEventForm event={data.getLandingPageEventById} />}
      </div>
    </DocumentTitle>
  );
}
