import React from "react";
import DocumentTitle from "react-document-title";
import * as palette from "@govlaunch/palette";
import NewEventPageForm from "./NewEventPageForm";

export default function NewEventPage() {
  return (
    <DocumentTitle title="New Event Page | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          height: "100vh",
        }}
      >
        <NewEventPageForm />
      </div>
    </DocumentTitle>
  );
}
