import React, { Fragment } from "react";
import * as palette from "@govlaunch/palette";
import Dotdotdot from "react-dotdotdot";
import { Toggle } from "react-powerplug";
import Popover from "@govlaunch/popover";
import useShowMoreCounter from "../../hooks/useShowMoreCounter";
import { IFlatResource, IDownloadResource, ILinkResource, ITextResource } from "../../../types/types";
import LinkIcon from "../../components/icons/LinkIcon";
import FontIcon from "../../components/icons/FontIcon";
import DocumentIcon from "../../components/icons/DocumentIcon";
import Resource from "./Resource";
import RemoveResourceButton from "./RemoveResourceButton";
import ShowMoreButton from "./ShowMoreButton";

interface IResourcesProps {
  resources: IFlatResource[];
  onRemove: (resource: IFlatResource) => any;
}

export default function Resources({ resources, onRemove }: IResourcesProps) {
  const { offset, hasMore, showMore } = useShowMoreCounter(resources.length, 4);
  const resourcesSlice = resources.slice(0, offset);

  return (
    <>
      <div>
        {resourcesSlice.map((resource, index) => {
          return (
            // eslint-disable-next-line
            <Fragment key={index}>
              {resource.type === "download" && (
                <Resource
                  style={{
                    paddingRight: 30,
                  }}
                >
                  <RemoveResourceButton onClick={() => onRemove(resource)} />
                  <div
                    css={{
                      width: 24,
                    }}
                  >
                    <DocumentIcon
                      width={24}
                      height={24}
                      style={{
                        minWidth: 24,
                        minHeight: 24,
                      }}
                      fillColor="#28D094"
                    />
                  </div>

                  <div
                    css={{
                      marginLeft: 10,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a
                      css={{
                        fontSize: 14,
                        color: palette.darkGray,
                        textDecoration: "none",
                        fontWeight: 500,
                        lineHeight: 1.2,
                        wordBreak: "break-word",
                        "&:hover": {
                          color: palette.darkGray,
                        },
                      }}
                      href={(resource as IDownloadResource).url || ""}
                      download={(resource as IDownloadResource).filename}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Dotdotdot clamp={2}>{resource.title}</Dotdotdot>
                    </a>
                  </div>
                </Resource>
              )}

              {resource.type === "link" && (
                <Resource
                  style={{
                    paddingRight: 30,
                  }}
                >
                  <RemoveResourceButton onClick={() => onRemove(resource)} />
                  <LinkIcon
                    width={24}
                    height={24}
                    style={{
                      minWidth: 24,
                      minHeight: 24,
                    }}
                    fillColor="#28D094"
                  />

                  <div
                    css={{
                      marginLeft: 10,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a
                      css={{
                        fontSize: 14,
                        color: palette.darkGray,
                        fontWeight: 500,
                        textDecoration: "none",
                        lineHeight: 1.2,
                        wordBreak: "break-word",
                        "&:hover": {
                          color: palette.darkGray,
                        },
                      }}
                      href={(resource as ILinkResource).url || ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Dotdotdot clamp={2}>{resource.title}</Dotdotdot>
                    </a>
                  </div>
                </Resource>
              )}

              {resource.type === "text" && (
                <Toggle>
                  {({ on, toggle }) => (
                    <Popover
                      placement="top"
                      isOpen={on}
                      onClickOutside={toggle}
                      boxStyle={{
                        boxShadow: "0 5px 30px 0 rgba(34, 35, 40, 0.2)",
                      }}
                      render={() => (
                        <div
                          css={{
                            padding: 15,
                            maxWidth: 320,
                            wordBreak: "break-all",
                            wordWrap: "break-word",
                          }}
                        >
                          {(resource as ITextResource).body}
                        </div>
                      )}
                    >
                      {({ innerRef }: { innerRef: any }) => (
                        <div
                          ref={innerRef}
                          css={{
                            position: "relative",
                            padding: "15px 30px 15px 15px",
                          }}
                        >
                          <RemoveResourceButton onClick={() => onRemove(resource)} />
                          <Resource
                            onClick={toggle}
                            style={{
                              padding: 0,
                              border: 0,
                            }}
                          >
                            <FontIcon
                              width={24}
                              height={24}
                              style={{
                                minWidth: 24,
                                minHeight: 24,
                              }}
                              fillColor="#28D094"
                            />

                            <div
                              css={{
                                marginLeft: 10,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span
                                css={{
                                  fontSize: 14,
                                  color: palette.darkGray,
                                  lineHeight: 1.2,
                                  fontWeight: 500,
                                  wordBreak: "break-word",
                                }}
                              >
                                <Dotdotdot clamp={2}>{resource.title}</Dotdotdot>
                              </span>
                            </div>
                          </Resource>
                        </div>
                      )}
                    </Popover>
                  )}
                </Toggle>
              )}
            </Fragment>
          );
        })}
      </div>

      {hasMore && <ShowMoreButton onClick={showMore} />}
    </>
  );
}
