import React from "react";
import { Toggle } from "react-powerplug";
import { Popover, notification, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { Mutation } from "react-apollo";
import RemoveTag from "../../mutations/RemoveTag";
import { IRemoveTagMutation, IRemoveTagMutationVariables } from "../../mutations/__generated__/RemoveTag.generated";
import * as palette from "@govlaunch/palette";
import RestoreTagById from "../../mutations/RestoreTagById";
import {
  IRestoreTagByIdMutation,
  IRestoreTagByIdMutationVariables,
} from "../../mutations/__generated__/RestoreTagById.generated";
import DotdotdotIcon from "../../components/icons/DotdotdotIcon";

export default function TagActions({ tag }: any) {
  return (
    <>
      <Toggle>
        {({ on, toggle }) => (
          <Popover
            visible={on}
            trigger="click"
            title="Actions"
            placement="rightBottom"
            onVisibleChange={toggle}
            content={
              <ul
                css={{
                  margin: 0,
                  padding: 0,
                  listStyle: "none",
                  "& > li:not(:first-of-type)": {
                    marginTop: 10,
                  },
                }}
              >
                <li>
                  <Link to={`/tags/${tag.slug}`}>View Details</Link>
                </li>

                {tag.deletedAt === null ? (
                  <Mutation<IRemoveTagMutation, IRemoveTagMutationVariables>
                    mutation={RemoveTag}
                    refetchQueries={["ListAllTags", "TagsCount"]}
                    onError={(err) => {
                      if (/You're not allowed to/.test(err.message)) {
                        notification.open({
                          type: "error",
                          message: "Couldn't remove",
                          description: err.message.replace("GraphQL error:", ""),
                        });
                      }
                    }}
                  >
                    {(removeTag) => (
                      <li>
                        <Popconfirm
                          title={`Are you sure you want to archive the tag ${tag.name}?`}
                          onConfirm={() => {
                            removeTag({
                              variables: {
                                tagId: tag._id,
                              },
                            });
                          }}
                        >
                          <a
                            href="#"
                            css={{
                              color: palette.red,
                            }}
                          >
                            Archive
                          </a>
                        </Popconfirm>
                      </li>
                    )}
                  </Mutation>
                ) : (
                  <Mutation<IRestoreTagByIdMutation, IRestoreTagByIdMutationVariables>
                    mutation={RestoreTagById}
                    refetchQueries={["ListAllTags", "TagsCount"]}
                  >
                    {(restoreTagById) => (
                      <li>
                        <Popconfirm
                          title={`Are you sure you want to restore the tag ${tag.name}?`}
                          onConfirm={() => {
                            restoreTagById({
                              variables: {
                                tagId: tag._id,
                              },
                            });
                          }}
                        >
                          <a
                            href="#"
                            css={{
                              color: palette.blue,
                            }}
                          >
                            Restore
                          </a>
                        </Popconfirm>
                      </li>
                    )}
                  </Mutation>
                )}
              </ul>
            }
          >
            <span
              role="button"
              css={{
                cursor: "pointer",
              }}
            >
              <DotdotdotIcon />
            </span>
          </Popover>
        )}
      </Toggle>
    </>
  );
}
