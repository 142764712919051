import React from "react";
import * as palette from "@govlaunch/palette";

interface ICharacterLimitIndicatorProps extends React.HTMLAttributes<HTMLDivElement> {
  description: string;
  maxLength: number;
  css: {
    [key: string]: string | number | null;
  } | null;
}

export default function CharacterLimitIndicator({
  description,
  maxLength,
  css,
  ...props
}: ICharacterLimitIndicatorProps) {
  return (
    <span
      {...props}
      css={{
        ...css,
        color: description.length > maxLength ? palette.red : palette.sealBlue,
        fontSize: 12,
        userSelect: "none",
      }}
    >
      {description.length}/{maxLength}
    </span>
  );
}
