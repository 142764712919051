import gql from "graphql-tag";
import LandingPageEventFields from "./fragments/LandingPageEventFields";

export default gql`
  query GetLandingPageEventById($id: ObjectId!) {
    getLandingPageEventById(id: $id) {
      ...LandingPageEventFields
    }
  }

  ${LandingPageEventFields}
`;
