import React from "react";
import { Alert, Collapse } from "antd";

export default function BulkTaggingInformationSection() {
  return (
    <Collapse css={{
 marginLeft: 5 
}}
    >
      <Collapse.Panel header="Bulk Tagging Instructions" key="1">
        <Alert
          type="info"
          showIcon={true}
          message={<strong>Details</strong>}
          description={
            <div>
              <ol>
                <li>
                  Paste project IDs in the text area. (One ID per row; no other delimiters)
                  <p css={{
 fontStyle: "italic" 
}}
                  >
                    * Each ID must be 24 characters long. If there are invalid IDs in the list, the table is not going
                    to populate
                  </p>
                </li>
                <li>Select the tag(s)</li>
                <li>Review the IDs and selected tag(s)</li>
                <li>
                  Click “Add tags” to complete the action
                  <p css={{
 fontStyle: "italic" 
}}
                  >* Other actions can be selected from the dropdown menu</p>
                </li>
              </ol>
            </div>
          }
        />
      </Collapse.Panel>
    </Collapse>
  );
}
