import { CloseButton, ImageUploadModal, TImageData } from "@govlaunch/web";
import React from "react";
import ImageSelectButton from "../ImageSelectButton";
import * as palette from "@govlaunch/palette";

interface IHeaderBannerFieldModalImageProps {
  data: TImageData;
  onSave: any;
  field: string;
  label: string;
}

export default function HeaderBannerFieldModalImage({ data, onSave, label }: IHeaderBannerFieldModalImageProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <label
        css={{
          fontWeight: 500,
          fontSize: 14,
          color: palette.mediumGray,
        }}
      >
        {label}
      </label>
      <div
        css={{
          marginTop: "8px",
          width: "100px",
          height: "100px",
          position: "relative",
        }}
      >
        {data.url && (
          <CloseButton
            size="sm"
            position="absolute"
            right={-6}
            top={-4}
            color="white"
            bg="red.500"
            borderRadius={25}
            _hover={{
              bg: "red.600",
            }}
            onClick={() => {
              onSave({
                url: "",
                alt: "",
              });
            }}
          />
        )}
        <ImageUploadModal
          imageData={
            data || {
              url: "",
              alt: "",
            }
          }
          onSave={(imageData) => {
            onSave({
              url: imageData.url,
              alt: imageData.alt,
            });
          }}
        >
          {({ setOpen }) => (
            <div css={{}} onClick={() => setOpen(true)}>
              {data.url && (
                <img
                  css={{
                    height: "100px",
                    objectFit: "cover",
                    border: `1px solid ${palette.lightestGray}`,
                    cursor: "pointer",
                  }}
                  src={data.url}
                  alt={data.alt}
                />
              )}
              {!data.url && (
                <div
                  css={{
                    width: "100px",
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    border: `1px dashed ${palette.mediumGray}`,
                  }}
                >
                  <ImageSelectButton />
                </div>
              )}
            </div>
          )}
        </ImageUploadModal>
      </div>
    </div>
  );
}
