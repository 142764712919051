import React, { ReactElement, ButtonHTMLAttributes } from "react";

export default function RemoveButton(props: ButtonHTMLAttributes<HTMLButtonElement>): ReactElement {
  return (
    <button
      type="button"
      css={{
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 5,
        outline: 0,
        border: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: 2,
      }}
      {...props}
    >
      <svg width={10} height={10}>
        <path
          fill="#FFF"
          d="M9.4 1.48L8.52.6 5 4.12 1.48.6l-.88.88L4.12 5 .6 8.52l.88.88L5 5.88 8.52 9.4l.88-.88L5.88 5z"
        />
      </svg>
    </button>
  );
}
