import React from "react";
import { Field } from "react-final-form";
import { DragDropContext, DropResult, Droppable, Draggable, DraggableProvided } from "react-beautiful-dnd";
import * as palette from "@govlaunch/palette";
import { CloseCircleOutlined, DragOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import COUNTRIES, { getCountryNameByIsoCode } from "../governments/Countries";
import { useQuery } from "react-apollo";
import GovernmentCountriesBreakdownQuery from "../governments/graphql/queries/GovernmentCountriesBreakdownQuery";
import {
  IGovernmentCountriesBreakdownQuery,
  IGovernmentCountriesBreakdownQueryVariables,
} from "../governments/graphql/queries/__generated__/GovernmentCountriesBreakdownQuery.generated";
import { LoadingIndicator } from "@govlaunch/core";

export type TCampaignCountry = {
  id: number;
  countryCode: string | null;
  name: string | null;
  amount: number | null;
};

export default function ProjectsPerCountryField() {
  const { data, loading } = useQuery<IGovernmentCountriesBreakdownQuery, IGovernmentCountriesBreakdownQueryVariables>(
    GovernmentCountriesBreakdownQuery,
  );

  if (loading || !data || !data.governmentCountriesBreakdown) {
    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          padding: "16px",
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  const AVAILABLE_COUNTRIES = data.governmentCountriesBreakdown.map((country) => {
    return {
      code: country.isoCountryCode,
      name: getCountryNameByIsoCode(country.isoCountryCode),
    };
  });

  return (
    <Field name="projectsPerCountry">
      {({ input }) => {
        function onDragEnd(result: DropResult) {
          if (!result.destination) {
            return;
          }

          if (result.destination.index === result.source.index) {
            return;
          }

          const projectsPerCountry = reorder(input.value, result.source.index, result.destination.index);

          input.onChange(projectsPerCountry);
        }

        function onRemoveItem(index: number) {
          const currentElements = Array.from(input.value);
          currentElements.splice(index, 1);

          input.onChange(currentElements);
        }

        function onSelectCountry(index: number, countryCode: string, countryName: string) {
          const currentElements: TCampaignCountry[] = Array.from(input.value);
          currentElements[index].countryCode = countryCode;
          currentElements[index].name = countryName;

          input.onChange(currentElements);
        }

        function onChangeAmount(index: number, amount: number) {
          const currentElements: TCampaignCountry[] = Array.from(input.value);
          currentElements[index].amount = amount;

          input.onChange(currentElements);
        }

        function onAddNewCountry() {
          const currentElements: TCampaignCountry[] = Array.from(input.value);
          currentElements.push({
            id: new Date().getTime(),
            countryCode: null,
            name: null,
            amount: 1,
          });

          input.onChange(currentElements);
        }

        const countriesCount = (input.value || []).length;

        return (
          <div
            css={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <ProjectsPerCountryList
                      countries={input.value || []}
                      onRemoveItem={onRemoveItem}
                      onSelectCountry={onSelectCountry}
                      onChangeAmount={onChangeAmount}
                      totalCount={(input.value || []).length}
                      availableCountries={AVAILABLE_COUNTRIES}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {countriesCount < COUNTRIES.length && <AddNewCountryButton onClick={() => onAddNewCountry()} />}
            </div>
          </div>
        );
      }}
    </Field>
  );
}

function reorder(list: any, startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

type TCountry = {
  code: string;
  name: string;
};

interface IProjectsPerCountryListProps {
  countries: TCampaignCountry[];
  onRemoveItem: (index: number) => void;
  onSelectCountry: (index: number, countryCode: string, countryName: string) => void;
  onChangeAmount: (index: number, amount: number) => void;
  totalCount: number;
  availableCountries: TCountry[];
}

function ProjectsPerCountryList({
  countries,
  onRemoveItem,
  onSelectCountry,
  onChangeAmount,
  totalCount,
  availableCountries,
}: IProjectsPerCountryListProps) {
  return (
    <>
      {countries.map((country: TCampaignCountry, index: number) => (
        <ProjectsPerCountryDraggable
          country={country}
          index={index}
          key={country.id}
          onRemoveItem={onRemoveItem}
          onSelectCountry={onSelectCountry}
          totalCount={totalCount}
          onChangeAmount={onChangeAmount}
          availableCountries={availableCountries}
        />
      ))}
    </>
  );
}

// Original react-beautiful-dnd example uses memoized list
//const MemoizedProjectsPerCountryList = React.memo(ProjectsPerCountryList);

interface IProjectsPerCountryDraggableProps {
  country: TCampaignCountry;
  index: number;
  onRemoveItem: (index: number) => void;
  onSelectCountry: (index: number, countryCode: string, countryName: string) => void;
  onChangeAmount: (index: number, amount: number) => void;
  totalCount: number;
  availableCountries: TCountry[];
}

function ProjectsPerCountryDraggable({
  country,
  index,
  onRemoveItem,
  onSelectCountry,
  onChangeAmount,
  totalCount,
  availableCountries,
}: IProjectsPerCountryDraggableProps) {
  return (
    <Draggable draggableId={country.id.toString()} index={index}>
      {(provided) => (
        <ProjectsPerCountryItem
          provided={provided}
          country={country}
          index={index}
          onRemoveItem={() => onRemoveItem(index)}
          onSelectCountry={onSelectCountry}
          onChangeAmount={onChangeAmount}
          totalCount={totalCount}
          availableCountries={availableCountries}
        />
      )}
    </Draggable>
  );
}

interface IProjectsPerCountryItemProps {
  provided: DraggableProvided;
  country: TCampaignCountry;
  index: number;
  onRemoveItem: () => void;
  onSelectCountry: (index: number, countryCode: string, countryName: string) => void;
  onChangeAmount: (index: number, amount: number) => void;
  totalCount: number;
  availableCountries: TCountry[];
}

function ProjectsPerCountryItem({
  provided,
  country,
  onRemoveItem,
  index,
  onSelectCountry,
  onChangeAmount,
  totalCount,
  availableCountries,
}: IProjectsPerCountryItemProps) {
  return (
    //@ts-ignore
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "8px",
        ...provided.draggableProps.style,
        width: "492px",
      }}
    >
      {totalCount > 1 && (
        <DragOutlined
          style={{
            fontSize: "24px",
            color: palette.darkGray,
            marginRight: "8px",
          }}
          css={{
            "&:hover": {
              opacity: 0.6,
            },
          }}
        />
      )}
      <GovernmentDropdown
        selectedCountryCode={country.countryCode}
        index={index}
        onSelectCountry={onSelectCountry}
        availableCountries={availableCountries}
      />
      <Input
        placeholder="# Projects"
        value={country.amount || undefined}
        onChange={(event) => {
          onChangeAmount(index, Number.parseInt(event.currentTarget.value));
        }}
        size="large"
        css={{
          marginRight: "8px",
          width: "120px",
        }}
      />
      {totalCount > 1 && (
        <CloseCircleOutlined
          style={{
            fontSize: "24px",
            color: palette.darkGray,
          }}
          css={{
            "&:hover": {
              opacity: 0.6,
            },
          }}
          onClick={onRemoveItem}
        />
      )}
    </div>
  );
}

interface IGovernmentDropdownProps {
  selectedCountryCode?: string | null;
  index: number;
  onSelectCountry: (index: number, countryCode: string, countryName: string) => void;
  availableCountries: TCountry[];
}

function GovernmentDropdown({
  selectedCountryCode,
  index,
  onSelectCountry,
  availableCountries,
}: IGovernmentDropdownProps) {
  return (
    <Select
      value={selectedCountryCode || undefined}
      style={{
        borderRadius: 5,
        fontSize: 14,
        marginRight: "8px",
        width: "280px",
      }}
      size="large"
      placeholder="Select a country"
      onChange={(_: string, option: any) => {
        onSelectCountry(index, option.value, option.children);
      }}
    >
      {availableCountries.map((option) => (
        <Select.Option key={option.code} value={option.code}>
          {option.name}
        </Select.Option>
      ))}
    </Select>
  );
}

interface IAddNewCountryButtonProps {
  onClick: () => void;
}

function AddNewCountryButton({ onClick }: IAddNewCountryButtonProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: 14,
        fontWeight: 600,
        color: palette.blue,
        cursor: "pointer",
        "&:hover": {
          opacity: 0.6,
        },
      }}
      onClick={onClick}
    >
      <PlusOutlined
        style={{
          fontSize: "20px",
          marginRight: "8px",
        }}
      />
      <div>Add new country</div>
    </div>
  );
}
