import React from "react";
import { Field } from "react-final-form";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { Margin } from "../../../spacings";
import { Input } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import prefixWithHttpIfNecessary from "../../../utils/prefixWithHttpIfNecessary";
import { IFormFieldProps } from "./FormFieldProps";

export default function UrlField({ label, fieldName, placeholder = "Product URL (http://)" }: IFormFieldProps) {
  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: palette.mediumGray,
                margin: 0,
              }}
            >
              {label}
            </label>

            <FieldValidationError meta={meta} />
          </div>
          <Margin mt={8}>
            <Input
              css={{
                height: 42,
                borderRadius: 5,
                border: `1px solid ${palette.lightestGray}`,
                fontSize: 14,
                padding: "5px 10px",
                width: "100%",
                outline: 0,
                "::placeholder": {
                  color: palette.sealBlue,
                },
                ":focus": {
                  borderColor: palette.primary,
                },
              }}
              placeholder={placeholder}
              {...input}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                let url = event.target.value.trim();
                url = prefixWithHttpIfNecessary(url, true) || "";
                input.onChange(url);
              }}
            />
          </Margin>
        </div>
      )}
    </Field>
  );
}
