import React from "react";
import EditSponsorshipForm from "../form/EditSponsorshipForm";
import { IGetSponsorshipQuery } from "../../../queries/__generated__/GetSponsorship.generated";

interface ISponsorshipDetailsTabProps {
  sponsorship: NonNullable<IGetSponsorshipQuery["sponsorship"]>;
}

export default function SponsorshipDetailsTab({ sponsorship }: ISponsorshipDetailsTabProps) {
  return <EditSponsorshipForm sponsorship={sponsorship} />;
}
