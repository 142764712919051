import { Input, Table, Tabs } from "antd";
import DocumentTitle from "react-document-title";
import GetOutsideProductsQuery from "../../queries/GetOutsideProductsQuery";
import {
  IGetOutsideProductsQuery,
  IGetOutsideProductsQueryVariables,
} from "../../queries/__generated__/GetOutsideProductsQuery.generated";
import { Query } from "react-apollo";
import React, { FunctionComponent, ReactElement } from "react";
import { Spacing } from "@govlaunch/spacers";
import SuggestionActions from "./SuggestionActions";
import SuggestionsCount from "./SuggestionsCount";
import { Value } from "react-powerplug";
import debounce from "lodash/debounce";
import getGovlaunchBaseUrl from "../../utils/getGovlaunchBaseUrl";
import queryString from "query-string";
import { IOutsideProductStatus } from "../../../types/types";
import first from "lodash/fp/first";
import TabContent from "../../components/TabContent";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";

interface ISuggestionVariables {
  search: string;
  status: IOutsideProductStatus;
}

function Suggestions({ search, status }: ISuggestionVariables) {
  return (
    <DocumentTitle title="Product Suggestions | Admin">
      <div>
        <Query<IGetOutsideProductsQuery, IGetOutsideProductsQueryVariables>
          fetchPolicy="network-only"
          notifyOnNetworkStatusChange={false}
          variables={{
            search,
            status,
          }}
          query={GetOutsideProductsQuery}
        >
          {({ data, loading }) => {
            if (loading) {
              return null;
            }

            if (!data || !data.products) {
              return null;
            }

            const products = data.products;

            return (
              <Table
                pagination={false}
                bordered={true}
                rowKey="_id"
                scroll={{
                  x: true,
                }}
                dataSource={
                  products.items
                    ? products.items.filter(({ status: productStatus }) => !status || productStatus === status)
                    : undefined
                }
                showHeader={true}
                expandedRowRender={(suggestion) => (
                  <div>
                    <h3
                      css={{
                        margin: "0 0 5px 0",
                      }}
                    >
                      Added by
                    </h3>

                    {suggestion.addedBy &&
                      suggestion.addedBy.map((user) => (
                        <p
                          css={{
                            margin: "5px 0 0 0",
                          }}
                          key={user._id}
                        >
                          <a
                            href={`${getGovlaunchBaseUrl("portal")}/@${user.slug}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {user.fullName} {user.__typename !== "PendingUser" ? `- ${user.title}` : ""}
                          </a>
                        </p>
                      ))}

                    {suggestion.product && (
                      <div>
                        <h3
                          css={{
                            margin: "0 0 5px 0",
                          }}
                        >
                          Assigned to product
                        </h3>

                        <a href={`${getGovlaunchBaseUrl("portal")}/products/${suggestion.product.slug}`}>
                          {suggestion.product.name}
                        </a>
                      </div>
                    )}

                    {suggestion.addedOn && suggestion.addedOn.length > 0 && (
                      <>
                        <h3
                          css={{
                            margin: "0 0 5px 0",
                          }}
                        >
                          Added on
                        </h3>

                        {suggestion.addedOn.map((added) => {
                          if (added.__typename === "Government") {
                            return (
                              <p
                                key={added._id}
                                css={{
                                  margin: "0 0 5px 0",
                                }}
                              >
                                <a
                                  href={`${getGovlaunchBaseUrl("portal")}/governments/${added.slug}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <strong>Government:</strong> {added.name}
                                </a>
                              </p>
                            );
                          }

                          if (added.__typename === "Story") {
                            return (
                              <p
                                key={added._id}
                                css={{
                                  margin: "0 0 5px 0",
                                }}
                              >
                                <a
                                  href={`${getGovlaunchBaseUrl("portal")}/stories/${added.slug}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <strong>Story:</strong> {added.title}
                                </a>
                              </p>
                            );
                          }

                          if (added.__typename === "Product") {
                            return (
                              <p
                                key={added._id}
                                css={{
                                  margin: "0 0 5px 0",
                                }}
                              >
                                <a
                                  href={`${getGovlaunchBaseUrl("portal")}/products/${added.slug}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <strong>Product:</strong> {added.name}
                                </a>
                              </p>
                            );
                          }

                          return null;
                        })}
                      </>
                    )}
                  </div>
                )}
                columns={[
                  {
                    dataIndex: "name",
                    title: "Name",
                    sorter: (a, b) => a.name.localeCompare(b.name),
                  },
                  {
                    dataIndex: "url",
                    title: "URL",
                    render: (url) =>
                      url ? (
                        <a href={url} target="_blank" rel="noreferrer noopener">
                          {url}
                        </a>
                      ) : (
                        "Not provided"
                      ),
                  },
                  {
                    dataIndex: "createdAt",
                    title: "Added at",
                    render: (createdAt) => moment(createdAt).format("MM/DD/YYYY"),
                  },
                  {
                    title: "Actions",
                    render: (_, suggestion) =>
                      suggestion.status === "PENDING" ? (
                        <SuggestionActions
                          suggestion={suggestion}
                          variables={{
                            search,
                            status,
                          }}
                        />
                      ) : (
                        "N/A"
                      ),
                  },
                ]}
              />
            );
          }}
        </Query>
      </div>
    </DocumentTitle>
  );
}

export default function AllSuggestions() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = query.tab || "all";

  return (
    <div>
      <div
        css={{
          padding: 20,
          borderBottom: "1px solid rgb(232, 232, 232)",
        }}
      >
        <h3
          css={{
            fontWeight: 700,
            fontSize: 24,
            margin: 0,
          }}
        >
          Suggestions
        </h3>

        <p
          css={{
            margin: 0,
          }}
        >
          All Suggestions
        </p>
      </div>
      <SuggestionsCount>
        {({ pendingCount, addedCount, ignoredCount, deniedCount, refetch }) => (
          <Tabs
            animated={false}
            onChange={(tab) => {
              navigate(`/suggestions?tab=${tab}`);
              refetch();
            }}
            activeKey={tab as string}
            size="middle"
            tabBarStyle={{
              paddingLeft: 20,
              paddingRight: 20,
              margin: 0,
            }}
          >
            <Tabs.TabPane tab={pendingCount ? `All (${pendingCount})` : "All"} key="all">
              {tab === "all" && (
                <TabContent>
                  <SuggestionsWithSearch status={IOutsideProductStatus.Pending} />
                </TabContent>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab={addedCount ? `Added (${addedCount})` : "Added"} key="added">
              {tab === "added" && (
                <TabContent>
                  <SuggestionsWithSearch status={IOutsideProductStatus.Added} />
                </TabContent>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab={ignoredCount ? `Ignored (${ignoredCount})` : "Ignored"} key="ignored">
              {tab === "ignored" && (
                <TabContent>
                  <SuggestionsWithSearch status={IOutsideProductStatus.Ignored} />
                </TabContent>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab={deniedCount ? `Rejected (${deniedCount})` : "Rejected"} key="denied">
              {tab === "denied" && (
                <TabContent>
                  <SuggestionsWithSearch status={IOutsideProductStatus.Denied} />
                </TabContent>
              )}
            </Tabs.TabPane>
          </Tabs>
        )}
      </SuggestionsCount>
    </div>
  );
}

interface ISuggestionsWithSearchProps {
  status: IOutsideProductStatus;
}

export const SuggestionsWithSearch: FunctionComponent<ISuggestionsWithSearchProps> = ({ status }): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const setSearchTerm = (searchTerm: string) => {
    const nextQuery = queryString.stringify({
      ...query,
      search: searchTerm.trim() || undefined,
    });

    navigate(`/suggestions?${nextQuery}`);
  };

  return (
    <>
      <Value initial={first(query.search) || ""} onChange={debounce(setSearchTerm, 1200)}>
        {({ value: searchTerm, set }) => (
          <Spacing bottom={20}>
            <Input.Search
              size="large"
              placeholder="Search for suggestions..."
              autoComplete="off"
              value={searchTerm}
              onChange={(event) => set(event.target.value)}
              onSearch={(search) => setSearchTerm(search)}
              style={{
                fontSize: 14,
              }}
            />
          </Spacing>
        )}
      </Value>

      <Suggestions status={status} search={first(query.search) || ""} />
    </>
  );
};
