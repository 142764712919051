import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { Tabs } from "antd";
import queryString from "query-string";
import React, { useCallback } from "react";
import { useQuery } from "react-apollo";
import DocumentTitle from "react-document-title";
import { useLocation, useNavigate } from "react-router";
import TabContent from "../../components/TabContent";
import CollectionsWithSearch from "./tables/CollectionsWithSearch";
import CollectionsCountersQuery from "./graphql/queries/CollectionsCountersQuery";
import { ICollectionsCountersQuery } from "./graphql/queries/__generated__/CollectionsCountersQuery.generated";
import TablePageHeader from "../../components/table/TablePageHeader";
import { MetaBaseQuestion } from "../../components/MetaBaseLink";

export default function Collections() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = Array.isArray(query.tab) ? query.tab[0] : query.tab || "active";

  const { data: counters, refetch: refetchCounters } = useQuery<ICollectionsCountersQuery>(CollectionsCountersQuery, {
    fetchPolicy: "network-only",
  });

  const changeTab = useCallback((tab: string) => {
    navigate(`/collections?tab=${tab}`);
  }, []);

  return (
    <DocumentTitle title="Collections | Admin">
      <>
        <TablePageHeader
          title="Collections"
          titleIcon={faBoxes}
          subTitle="Manage all Collections"
          metabaseQuestion={MetaBaseQuestion.Collections}
        />
        <div>
          <Tabs
            animated={false}
            onChange={(tab) => changeTab(tab)}
            activeKey={tab || "active"}
            size="middle"
            tabBarStyle={{
              margin: 0,
              paddingRight: 24,
              paddingLeft: 24,
              userSelect: "none",
            }}
          >
            <Tabs.TabPane tab={`Active (${counters?.collectionsCounters.activeCount || 0})`} key="active">
              {tab === "active" && (
                <TabContent>
                  <CollectionsWithSearch tab={tab} refetchCounters={refetchCounters} />
                </TabContent>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab={`Single (${counters?.collectionsCounters.singleCount || 0})`} key="single">
              {tab === "single" && (
                <TabContent>
                  <CollectionsWithSearch tab={tab} refetchCounters={refetchCounters} />
                </TabContent>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab={`Bundles (${counters?.collectionsCounters.bundlesCount || 0})`} key="bundles">
              {tab === "bundles" && (
                <TabContent>
                  <CollectionsWithSearch tab={tab} refetchCounters={refetchCounters} />
                </TabContent>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab={`Featured (${counters?.collectionsCounters.featuredCount || 0})`} key="featured">
              {tab === "featured" && (
                <TabContent>
                  <CollectionsWithSearch tab={tab} refetchCounters={refetchCounters} />
                </TabContent>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab={`Vendor Curated (${counters?.collectionsCounters.curatedCount || 0})`} key="curated">
              {tab === "curated" && (
                <TabContent>
                  <CollectionsWithSearch tab={tab} refetchCounters={refetchCounters} />
                </TabContent>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab={`Vendor Custom (${counters?.collectionsCounters.sponsoredCount || 0})`} key="sponsored">
              {tab === "sponsored" && (
                <TabContent>
                  <CollectionsWithSearch tab={tab} refetchCounters={refetchCounters} />
                </TabContent>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane
              tab={`Unpublished (${counters?.collectionsCounters.unpublishedCount || 0})`}
              key="unpublished"
            >
              {tab === "unpublished" && (
                <TabContent>
                  <CollectionsWithSearch tab={tab} refetchCounters={refetchCounters} />
                </TabContent>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab={`Inactive (${counters?.collectionsCounters.inactiveCount || 0})`} key="inactive">
              {tab === "inactive" && (
                <TabContent>
                  <CollectionsWithSearch tab={tab} refetchCounters={refetchCounters} />
                </TabContent>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab={`Archived (${counters?.collectionsCounters.archivedCount || 0})`} key="archived">
              {tab === "archived" && (
                <TabContent>
                  <CollectionsWithSearch tab={tab} refetchCounters={refetchCounters} />
                </TabContent>
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
      </>
    </DocumentTitle>
  );
}
