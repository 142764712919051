import React from "react";
import { IGetTagBySlugQuery } from "../../../../queries/__generated__/GetTagBySlug.generated";
import StoriesWithSearchWithRouter from "../../../stories/tables/StoriesWithSearchWithRouter";

interface ITagStoriesTabProps {
  tag: IGetTagBySlugQuery["tag"];
}

export default function TagStoriesTab({ tag }: ITagStoriesTabProps) {
  if (!tag) {
    return null;
  }

  return (
    <div
      css={{
        padding: 20,
      }}
    >
      <StoriesWithSearchWithRouter filterByTag={tag._id} historyOrigin={`/tags/${tag.slug}/stories`} />
    </div>
  );
}
