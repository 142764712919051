import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { Checkbox } from "../subscriptions/SubscribedToNewsletter";

export default function AdditionalUserSettings() {
  return (
    <>
      <div
        css={{
          paddingBottom: 16,
          borderBottom: `1px solid ${palette.lightestGray}`,
        }}
      >
        <Field name="hideSearch" type="checkbox">
          {({ input }) => (
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                verticalAlign: "center",
              }}
            >
              <label
                css={{
                  fontWeight: "bold",
                  fontSize: 14,
                  color: palette.mediumGray,
                  margin: 0,
                }}
              >
                Hide Profile from Google and other search engines
              </label>

              <div
                css={{
                  alignSelf: "flex-end",
                  marginBottom: 4,
                  marginRight: 4,
                }}
              >
                <Checkbox id="hideSearch" type="checkbox" {...input} />
              </div>
            </div>
          )}
        </Field>
      </div>
      <div>
        <Field name="hideAuthor" type="checkbox">
          {({ input }) => (
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                verticalAlign: "center",
              }}
            >
              <label
                css={{
                  fontWeight: "bold",
                  fontSize: 14,
                  color: palette.mediumGray,
                  margin: 0,
                }}
              >
                Do not show my information on projects I create
              </label>

              <div
                css={{
                  alignSelf: "flex-end",
                  marginBottom: 4,
                  marginRight: 4,
                }}
              >
                <Checkbox id="hideAuthor" type="checkbox" {...input} />
              </div>
            </div>
          )}
        </Field>
      </div>
    </>
  );
}
