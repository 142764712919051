import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { INumberFieldProps } from "./FormFieldProps";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { InputNumber } from "antd";

export default function HorizontalNumberField({
  fieldName,
  label,
  placeholder,
  postTitle,
  labelWidth,
  headerColor,
  disabled,
  minValue,
  maxValue,
  ...props
}: INumberFieldProps) {
  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <label
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: headerColor || palette.mediumGray,
                margin: 0,
                width: labelWidth || undefined,
              }}
            >
              {label}
            </label>

            <InputNumber
              size="large"
              placeholder={placeholder}
              {...input}
              {...props}
              disabled={disabled}
              min={minValue}
              max={maxValue}
            />

            <div
              css={{
                marginLeft: 8,
                fontWeight: 500,
                fontSize: 14,
                color: palette.mediumGray,
              }}
            >
              {postTitle}
            </div>
          </div>

          <FieldValidationError meta={meta} />
        </div>
      )}
    </Field>
  );
}
