export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Cursor: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `ObjectId` scalar type represents a mongodb unique ID */
  ObjectId: any;
  /** The URL scalar type represents URL addresses. */
  URL: any;
};

export enum IAboutContentType {
  Image = 'IMAGE',
  Podcast = 'PODCAST',
  Video = 'VIDEO'
}

export type IAboutPage = {
  __typename: 'AboutPage';
  sections: Array<IAboutPageSection>;
};

export type IAboutPageConfig = {
  __typename: 'AboutPageConfig';
  _id: Scalars['ObjectId'];
  active: Scalars['Boolean'];
  config: IAboutPageConfigFields;
  createdAt: Scalars['DateTime'];
  createdBy: Maybe<IUser>;
  name: Maybe<Scalars['String']>;
};

export type IAboutPageConfigFields = {
  __typename: 'AboutPageConfigFields';
  bannerBackgroundDescription: Maybe<Scalars['String']>;
  bannerBackgroundSrc: Maybe<Scalars['String']>;
  bannerContentDescription: Maybe<Scalars['String']>;
  bannerContentSize: Maybe<Scalars['String']>;
  bannerContentText: Scalars['String'];
  bannerContentType: IAboutContentType;
  boxesSectionBackgroundColor: Scalars['String'];
  headingText: Scalars['String'];
  headingTextFontColor: Scalars['String'];
  joinSectionBackground: Scalars['String'];
  joinSectionButtonColor: Scalars['String'];
  joinSectionButtonFontColor: Scalars['String'];
  joinSectionButtonHref: Scalars['String'];
  joinSectionButtonText: Maybe<Scalars['String']>;
  joinSectionSubtitle: Maybe<Scalars['String']>;
  joinSectionSubtitleFontColor: Scalars['String'];
  joinSectionTitle: Maybe<Scalars['String']>;
  joinSectionTitleFontColor: Scalars['String'];
  leftBox: ISideContent;
  leftBoxIconDescription: Maybe<Scalars['String']>;
  leftButtonBackgroundColor: Scalars['String'];
  leftButtonBorderColor: Maybe<Scalars['String']>;
  leftButtonHoverColor: Maybe<Scalars['String']>;
  leftButtonHref: Scalars['String'];
  leftButtonText: Scalars['String'];
  leftButtonTextColor: Scalars['String'];
  logoSectionBackgroundColor: Scalars['String'];
  logoUrls: Array<IAboutPageLogo>;
  rightBox: ISideContent;
  rightBoxIconDescription: Maybe<Scalars['String']>;
  rightButtonBackgroundColor: Scalars['String'];
  rightButtonBorderColor: Maybe<Scalars['String']>;
  rightButtonHoverColor: Maybe<Scalars['String']>;
  rightButtonHref: Scalars['String'];
  rightButtonText: Scalars['String'];
  rightButtonTextColor: Scalars['String'];
  subtitleText: Scalars['String'];
  subtitleTextFontColor: Scalars['String'];
  videoSectionBackground: Scalars['String'];
  videoSectionContentText: Maybe<Scalars['String']>;
  videoSectionContentType: IAboutContentType;
  videoSectionFontColor: Scalars['String'];
  videoSectionTitle: Maybe<Scalars['String']>;
};

export type IAboutPageConfigInput = {
  bannerBackgroundDescription: InputMaybe<Scalars['String']>;
  bannerBackgroundSrc: InputMaybe<Scalars['String']>;
  bannerContentDescription: InputMaybe<Scalars['String']>;
  bannerContentSize: InputMaybe<Scalars['String']>;
  bannerContentText: Scalars['String'];
  bannerContentType: IAboutContentType;
  boxesSectionBackgroundColor: Scalars['String'];
  headingText: Scalars['String'];
  headingTextFontColor: Scalars['String'];
  joinSectionBackground: Scalars['String'];
  joinSectionButtonColor: Scalars['String'];
  joinSectionButtonFontColor: Scalars['String'];
  joinSectionButtonHref: Scalars['String'];
  joinSectionButtonText: InputMaybe<Scalars['String']>;
  joinSectionSubtitle: InputMaybe<Scalars['String']>;
  joinSectionSubtitleFontColor: Scalars['String'];
  joinSectionTitle: InputMaybe<Scalars['String']>;
  joinSectionTitleFontColor: Scalars['String'];
  leftBox: ISideContentInput;
  leftButtonBackgroundColor: Scalars['String'];
  leftButtonBorderColor: InputMaybe<Scalars['String']>;
  leftButtonHoverColor: InputMaybe<Scalars['String']>;
  leftButtonHref: Scalars['String'];
  leftButtonText: Scalars['String'];
  leftButtonTextColor: Scalars['String'];
  logoSectionBackgroundColor: Scalars['String'];
  logoUrls: Array<IAboutPageLogoInput>;
  name: InputMaybe<Scalars['String']>;
  rightBox: ISideContentInput;
  rightButtonBackgroundColor: Scalars['String'];
  rightButtonBorderColor: InputMaybe<Scalars['String']>;
  rightButtonHoverColor: InputMaybe<Scalars['String']>;
  rightButtonHref: Scalars['String'];
  rightButtonText: Scalars['String'];
  rightButtonTextColor: Scalars['String'];
  subtitleText: Scalars['String'];
  subtitleTextFontColor: Scalars['String'];
  videoSectionBackground: Scalars['String'];
  videoSectionContentText: InputMaybe<Scalars['String']>;
  videoSectionContentType: IAboutContentType;
  videoSectionFontColor: Scalars['String'];
  videoSectionTitle: InputMaybe<Scalars['String']>;
};

export type IAboutPageLogo = {
  __typename: 'AboutPageLogo';
  description: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  url: Scalars['String'];
};

export type IAboutPageLogoInput = {
  description: InputMaybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  url: Scalars['String'];
};

export type IAboutPageSection = IDisruptorProductsAboutPageSection | ILatestGovernmentsAboutPageSection | IMostPopularProject | IProductsAboutPageSection | IProjectsAboutPageSection | IReportsAboutPageSection | IStoriesAboutPageSection | ITopFeaturedReport | ITopFeaturedStories;

export type IActiveSubscription = {
  __typename: 'ActiveSubscription';
  _id: Scalars['ObjectId'];
  collectionMethod: Maybe<ICollectionMethod>;
  freeSubscription: Maybe<Scalars['Boolean']>;
  lastCharge: Maybe<ICharge>;
  nextPaymentAmount: Maybe<Scalars['Int']>;
  nextPaymentDate: Maybe<Scalars['DateTime']>;
  pausePaymentCollection: Maybe<Scalars['Boolean']>;
  stripeStatus: Maybe<Scalars['String']>;
  subscribedAt: Scalars['DateTime'];
  trialPeriod: Maybe<Scalars['Int']>;
};

export type IAddCollectionInput = {
  description: Scalars['String'];
  displayOptions: InputMaybe<ICollectionDisplayOptionsInput>;
  isFeatured: Scalars['Boolean'];
  name: Scalars['String'];
  sponsoring: ICollectionSponsoring;
  sponsoringProducts: InputMaybe<Array<Scalars['ObjectId']>>;
  sponsoringVendors: Array<Scalars['ObjectId']>;
  tags: Array<Scalars['ObjectId']>;
};

export type IAddGovernmentInput = {
  awards: Array<Scalars['ObjectId']>;
  budget: InputMaybe<Scalars['Float']>;
  country: Scalars['String'];
  county: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  governmentType: Scalars['String'];
  logo: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  population: Scalars['Float'];
  resources: Array<IResourceInput>;
  site: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  stateName: Scalars['String'];
  type: Scalars['String'];
  zipCode: Scalars['String'];
};

export type IAddPlanInput = {
  displayName: Scalars['String'];
  limits: ILimitsInput;
  name: Scalars['String'];
  stripePlanId: InputMaybe<Scalars['String']>;
  tier: IPlanTier;
};

export type IAlgoliaKeys = {
  __typename: 'AlgoliaKeys';
  default: Scalars['String'];
  projects: Scalars['String'];
};

export enum IAllSponsorshipsFilter {
  All = 'ALL',
  Archived = 'ARCHIVED'
}

export type IAllVendorsFilterInput = {
  active: InputMaybe<Scalars['Boolean']>;
  hasCustomLimits: InputMaybe<Scalars['Boolean']>;
  hasOwner: InputMaybe<Scalars['Boolean']>;
  inTiers: InputMaybe<Array<Scalars['Int']>>;
  plan: InputMaybe<Scalars['String']>;
  planId: InputMaybe<Scalars['String']>;
  search: InputMaybe<Scalars['String']>;
  sponsorships: InputMaybe<Array<Scalars['ObjectId']>>;
  stripeStatus: InputMaybe<Scalars['String']>;
  tab: InputMaybe<Scalars['String']>;
};

export type IAppStoreProductDeployment = {
  __typename: 'AppStoreProductDeployment';
  linkHref: Scalars['String'];
};

export enum IAssistiveTechnology {
  AlternativeInputDevice = 'ALTERNATIVE_INPUT_DEVICE',
  ScreenMagnification = 'SCREEN_MAGNIFICATION',
  ScreenReader = 'SCREEN_READER',
  SpeechInput = 'SPEECH_INPUT',
  TextReader = 'TEXT_READER'
}

export type IAttachmentInput = {
  filename: Scalars['String'];
  size: Scalars['Int'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type IBatchGovernmentsUploadResult = {
  __typename: 'BatchGovernmentsUploadResult';
  /** The number of Governments that were added to the system. */
  addedCount: Scalars['Int'];
  /** The number of Governments that were already in the system and were not added. */
  skippedCount: Scalars['Int'];
  /** The number of Governments that were submitted for addition. */
  totalCount: Scalars['Int'];
};

export type IBilling = {
  __typename: 'Billing';
  card: Maybe<ICardSource>;
};

export type IBillingInformation = {
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  postalCode: Scalars['String'];
  region: InputMaybe<Scalars['String']>;
  state: InputMaybe<Scalars['String']>;
};

export type IBlockData = {
  backgroundColor: Maybe<Scalars['String']>;
  itemLimit: Maybe<Scalars['Int']>;
  paddingBottom: Maybe<Scalars['Int']>;
  paddingLeft: Maybe<Scalars['Int']>;
  paddingRight: Maybe<Scalars['Int']>;
  paddingTop: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  titleVisible: Scalars['Boolean'];
  type: IBlockLayoutType;
};

export enum IBlockLayoutType {
  Advertisement = 'ADVERTISEMENT',
  EmptySpaceBlock = 'EMPTY_SPACE_BLOCK',
  Events = 'EVENTS',
  ExternalLinks = 'EXTERNAL_LINKS',
  FeaturedStories = 'FEATURED_STORIES',
  Image = 'IMAGE',
  NewProjects = 'NEW_PROJECTS',
  PlusBlock = 'PLUS_BLOCK',
  Podcast = 'PODCAST',
  StatCounters = 'STAT_COUNTERS',
  TrendingCollections = 'TRENDING_COLLECTIONS',
  TrendingGovernments = 'TRENDING_GOVERNMENTS'
}

export type IBookmarkTarget = ICollection | IGovernmentPost | IGovernmentProject | IGroupPost | IGroupProject | IProduct | IStory | IVendorPost | IVendorProject;

export enum IBookmarkType {
  Collection = 'COLLECTION',
  Post = 'POST',
  Product = 'PRODUCT',
  Project = 'PROJECT',
  Story = 'STORY'
}

export enum IBulkTaggingEntityType {
  Project = 'PROJECT'
}

export enum IBulkTaggingModifyStrategy {
  Add = 'ADD',
  Overwrite = 'OVERWRITE',
  Remove = 'REMOVE'
}

export type ICardSource = {
  __typename: 'CardSource';
  brand: Scalars['String'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  lastFourDigits: Scalars['String'];
  name: Scalars['String'];
};

export type ICarouselImage = {
  __typename: 'CarouselImage';
  alt: Maybe<Scalars['String']>;
  src: Scalars['String'];
};

export type ICarouselImageInput = {
  alt: InputMaybe<Scalars['String']>;
  src: Scalars['String'];
};

export type ICarouselSideContent = {
  __typename: 'CarouselSideContent';
  buttonBackgroundColor: Maybe<Scalars['String']>;
  buttonFontColor: Maybe<Scalars['String']>;
  buttonHref: Maybe<Scalars['String']>;
  buttonText: Maybe<Scalars['String']>;
  images: Array<ICarouselImage>;
  text: Scalars['String'];
  textHref: Maybe<Scalars['String']>;
  textHrefHoverColor: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type IChangeDetails = {
  __typename: 'ChangeDetails';
  firstInvoiceAfterProrateDate: Maybe<Scalars['Date']>;
  prorateCost: Maybe<Scalars['Int']>;
  proratePaymentDueDate: Maybe<Scalars['Date']>;
};

export type ICharge = {
  __typename: 'Charge';
  amount: Scalars['Int'];
  amountRefunded: Scalars['Int'];
  card: Maybe<ICardSource>;
  currency: Scalars['String'];
  friendlyId: Scalars['String'];
  id: Scalars['String'];
  paidAt: Maybe<Scalars['DateTime']>;
  receiptUrl: Maybe<Scalars['String']>;
  status: IChargeStatus;
};

export enum IChargeStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export type ICity = {
  __typename: 'City';
  _id: Scalars['ObjectId'];
  /** ISO 3166-2 country code */
  country: Scalars['String'];
  countryName: Scalars['String'];
  county: Maybe<Scalars['String']>;
  governmentType: Scalars['String'];
  name: Scalars['String'];
  population: Maybe<Scalars['Int']>;
  state: Scalars['String'];
  stateName: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  zip: Maybe<Scalars['String']>;
};


export type ICityStateArgs = {
  full: InputMaybe<Scalars['Boolean']>;
};

export type ICollection = {
  __typename: 'Collection';
  _id: Scalars['ObjectId'];
  adminStats: ICollectionAdminStats;
  archivedAt: Maybe<Scalars['DateTime']>;
  content: ICollectionContent;
  contentCounters: ICollectionContentCounters;
  createdAt: Scalars['DateTime'];
  /**
   * The User who created the collection. It will be an Admin most likely. It also can be "null" since legacy
   * collections didn't have this field tracked in the database.
   */
  createdBy: Maybe<IUser>;
  description: Scalars['String'];
  displayOptions: Maybe<ICollectionDisplayOptions>;
  featuredAt: Maybe<Scalars['DateTime']>;
  /** This will be true if a Collection Sponsoring Sale exists for that collection. */
  hasSponsoringSale: Scalars['Boolean'];
  /** This will be true if a Collection Sponsoring Sale is active for that collection. */
  isSponsoringSaleActive: Scalars['Boolean'];
  /** The User who last updated certain properties of the collection */
  lastUpdatedBy: Maybe<IUser>;
  name: Scalars['String'];
  newContentCounters: ICollectionContentCounters;
  packaging: ICollectionPackaging;
  position: Scalars['Int'];
  publicContentAvailable: Maybe<Scalars['Boolean']>;
  publishedAt: Maybe<Scalars['DateTime']>;
  slug: Scalars['String'];
  sponsoring: ICollectionSponsoring;
  sponsoringProducts: Array<IProduct>;
  sponsoringVendors: Array<ICompany>;
  status: ICollectionStatus;
  tags: Array<ITag>;
  updatedAt: Scalars['DateTime'];
  viewerDidBookmark: Scalars['Boolean'];
};


export type ICollectionContentArgs = {
  cursor: InputMaybe<Scalars['String']>;
  filterByCountry?: InputMaybe<Array<Scalars['String']>>;
  filterByItemType: ICollectionItemType;
  filterByTagSlugs?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type ICollectionContentCountersArgs = {
  filterByCountry?: InputMaybe<Array<Scalars['String']>>;
  filterByTagSlugs?: InputMaybe<Array<Scalars['String']>>;
};


export type ICollectionNewContentCountersArgs = {
  filterByCountry?: InputMaybe<Array<Scalars['String']>>;
  filterByTagSlugs?: InputMaybe<Array<Scalars['String']>>;
  lastVisitAnchor?: InputMaybe<ICollectionNewContentLastVisitAnchor>;
  lastVisitDate: InputMaybe<Scalars['DateTime']>;
};

export type ICollectionAdminStats = {
  __typename: 'CollectionAdminStats';
  usersSavedCount: Scalars['Int'];
};

export enum ICollectionBookmarkStatus {
  Saved = 'SAVED',
  Unsaved = 'UNSAVED'
}

export type ICollectionContent = {
  __typename: 'CollectionContent';
  items: Array<ICollectionContentItem>;
  pageInfo: IForwardOnlyPageInfo;
  totalCount: Scalars['Int'];
};

export type ICollectionContentCounters = {
  __typename: 'CollectionContentCounters';
  governmentsCount: Scalars['Int'];
  groupsCount: Scalars['Int'];
  productsCount: Scalars['Int'];
  projectsCount: Scalars['Int'];
  storiesCount: Scalars['Int'];
};

export type ICollectionContentItem = IGovernment | IGovernmentProject | IGroup | IGroupProject | IProduct | IStory | IVendorProject;

export type ICollectionDisplayOptions = {
  __typename: 'CollectionDisplayOptions';
  cardBackgroundColorHex: Scalars['String'];
  cardTitleFontColorHex: Scalars['String'];
};

export type ICollectionDisplayOptionsInput = {
  cardBackgroundColorHex: Scalars['String'];
};

export enum ICollectionFeaturedStatus {
  All = 'ALL',
  FeaturedOnly = 'FEATURED_ONLY',
  NotFeatured = 'NOT_FEATURED'
}

export enum ICollectionItemType {
  Government = 'GOVERNMENT',
  Group = 'GROUP',
  Product = 'PRODUCT',
  Project = 'PROJECT',
  Story = 'STORY'
}

export enum ICollectionMethod {
  ChargeAutomatically = 'CHARGE_AUTOMATICALLY',
  SendInvoice = 'SEND_INVOICE'
}

export enum ICollectionNewContentLastVisitAnchor {
  LastTrackedViewerVisit = 'LAST_TRACKED_VIEWER_VISIT',
  LastVisitArgument = 'LAST_VISIT_ARGUMENT'
}

export enum ICollectionPackaging {
  /** When a collection have more than two tags. */
  Bundle = 'BUNDLE',
  /** When a collection is made of a single tag. */
  Single = 'SINGLE'
}

export enum ICollectionPublishedStatus {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export enum ICollectionSponsoring {
  None = 'NONE',
  VendorCurated = 'VENDOR_CURATED',
  VendorSponsored = 'VENDOR_SPONSORED'
}

export enum ICollectionSponsoringPaymentSchedule {
  LumpSum = 'LUMP_SUM',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

export type ICollectionSponsoringSale = {
  _id: Scalars['ObjectId'];
  archivedAt: Maybe<Scalars['DateTime']>;
  archivedBy: Maybe<IUser>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  includedCollections: Array<ICollection>;
  isActive: Scalars['Boolean'];
  paymentSchedule: ICollectionSponsoringPaymentSchedule;
  price: Scalars['Int'];
  sponsorshipLevel: ICollectionSponsoringSponsorshipLevel;
  stripeState: ICollectionSponsoringStripeState;
  type: ICollectionSponsoringType;
  updatedAt: Scalars['DateTime'];
  vendor: ICompany;
};

export type ICollectionSponsoringSaleCounts = {
  __typename: 'CollectionSponsoringSaleCounts';
  activeCount: Scalars['Int'];
  allCounts: Scalars['Int'];
  archivedCount: Scalars['Int'];
  expiredCount: Scalars['Int'];
  pendingCount: Scalars['Int'];
};

export enum ICollectionSponsoringSponsorshipLevel {
  Product = 'PRODUCT',
  Vendor = 'VENDOR'
}

export type ICollectionSponsoringStripeState = {
  __typename: 'CollectionSponsoringStripeState';
  latestInvoiceHostedPayUrl: Maybe<Scalars['String']>;
  latestInvoiceId: Scalars['String'];
  latestInvoiceStatus: Scalars['String'];
};

export enum ICollectionSponsoringType {
  Curated = 'CURATED',
  Sponsored = 'SPONSORED'
}

export enum ICollectionStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Inactive = 'INACTIVE'
}

export type ICollectionsBlockData = IBlockData & {
  __typename: 'CollectionsBlockData';
  backgroundColor: Maybe<Scalars['String']>;
  displayAsTags: Scalars['Boolean'];
  itemLimit: Maybe<Scalars['Int']>;
  paddingBottom: Maybe<Scalars['Int']>;
  paddingLeft: Maybe<Scalars['Int']>;
  paddingRight: Maybe<Scalars['Int']>;
  paddingTop: Maybe<Scalars['Int']>;
  secondTitle: Scalars['String'];
  title: Scalars['String'];
  titleVisible: Scalars['Boolean'];
  type: IBlockLayoutType;
};

export type ICollectionsCounters = {
  __typename: 'CollectionsCounters';
  activeCount: Scalars['Int'];
  allCount: Scalars['Int'];
  archivedCount: Scalars['Int'];
  bundlesCount: Scalars['Int'];
  curatedCount: Scalars['Int'];
  featuredCount: Scalars['Int'];
  inactiveCount: Scalars['Int'];
  singleCount: Scalars['Int'];
  sponsoredCount: Scalars['Int'];
  unpublishedCount: Scalars['Int'];
};

export type ICollectionsPagination = {
  __typename: 'CollectionsPagination';
  items: Array<ICollection>;
  pageInfo: IForwardOnlyPageInfo;
  totalCount: Scalars['Int'];
};

export type IComment = {
  __typename: 'Comment';
  _id: Scalars['ObjectId'];
  author: IUser;
  body: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  isReply: Scalars['Boolean'];
  lastUpdatedAt: Scalars['DateTime'];
  replies: Array<IComment>;
  repliesCount: Scalars['Int'];
  upvotesCount: Scalars['Int'];
  viewerDidReply: Scalars['Boolean'];
  viewerDidUpvote: Scalars['Boolean'];
};


export type ICommentRepliesArgs = {
  filter?: InputMaybe<ICommentRepliesFilter>;
};

export enum ICommentRepliesFilter {
  All = 'ALL',
  Viewer = 'VIEWER'
}

export enum ICommentTargetType {
  Post = 'POST',
  Project = 'PROJECT',
  Report = 'REPORT',
  Story = 'STORY'
}

export type IComments = {
  __typename: 'Comments';
  items: Array<IComment>;
  pageInfo: Maybe<IPageInfo>;
};

export type ICompany = {
  __typename: 'Company';
  _id: Scalars['ObjectId'];
  actions: Array<IVendorGroupRequest>;
  actionsCount: Scalars['Int'];
  activatedAt: Maybe<Scalars['DateTime']>;
  address: Maybe<Scalars['String']>;
  allProjectsCount: Maybe<Scalars['Int']>;
  allStoriesCount: Maybe<Scalars['Int']>;
  archivedAt: Maybe<Scalars['DateTime']>;
  archivedBy: Maybe<IUser>;
  balanceTransactions: Array<IVendorBalanceTransaction>;
  billing: IBilling;
  billingName: Maybe<Scalars['String']>;
  businessEmail: Maybe<Scalars['String']>;
  canAddGroups: Scalars['Boolean'];
  canAddProducts: Scalars['Boolean'];
  canAddProjects: Scalars['Boolean'];
  canBeDisruptor: Scalars['Boolean'];
  canEarnCreditsUntil: Maybe<Scalars['Date']>;
  canInviteTeamMembers: Scalars['Boolean'];
  charges: Array<ICharge>;
  contactPage: Maybe<Scalars['URL']>;
  cover: Maybe<ICover>;
  createdAt: Maybe<Scalars['DateTime']>;
  customLimits: Maybe<IVendorCustomLimits>;
  description: Maybe<Scalars['String']>;
  disruptorLogo: Maybe<Scalars['String']>;
  disruptorMembers: Maybe<Array<IVendorUser>>;
  earnableCreditAmount: Maybe<Scalars['Float']>;
  enabledSharing: Scalars['Boolean'];
  enrichedProfile: Scalars['Boolean'];
  feedCount: Scalars['Int'];
  governmentConnections: Maybe<IGovernments>;
  governmentConnectionsCount: Scalars['Int'];
  groups: Array<IGroup>;
  hasActiveSponsorships: Scalars['Boolean'];
  hasCustomLimits: Scalars['Boolean'];
  hasSponsoredCollections: Scalars['Boolean'];
  headquarters: Maybe<Scalars['String']>;
  highlights: Array<Scalars['String']>;
  highlightsCount: Scalars['Int'];
  invoices: Array<IInvoice>;
  isActive: Maybe<Scalars['Boolean']>;
  isDisruptor: Maybe<Scalars['Boolean']>;
  logo: Maybe<Scalars['String']>;
  members: Maybe<Array<IVendorUser>>;
  membersCount: Scalars['Int'];
  name: Scalars['String'];
  newsworthy: Array<IVendorNewsworthyItem>;
  newsworthyCount: Scalars['Int'];
  numberOfEmployees: Maybe<Scalars['String']>;
  originalPlan: IPlan;
  owner: Maybe<IVendorUser>;
  ownerless: Maybe<Scalars['Boolean']>;
  pendingProductStackRequests: Array<IProductStackRequest>;
  plan: IPlan;
  planUsage: IPlanUsage;
  products: Maybe<Array<IProduct>>;
  productsCount: Maybe<Scalars['Int']>;
  projects: IProjects;
  projectsCount: Maybe<Scalars['Int']>;
  projectsPendingVerification: IProjects;
  publicProjects: Array<IProject>;
  relatedProjects: Array<IProject>;
  resources: Maybe<Array<IFlatResource>>;
  sharing: ICompanySharingOptions;
  site: Maybe<Scalars['String']>;
  slogan: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  socialMedia: Maybe<IVendorSocialMediaLinks>;
  sponsoredCollections: Array<ICollection>;
  sponsoringSales: Array<ICollectionSponsoringSale>;
  sponsorships: Array<ISponsorship>;
  stories: IStories;
  storiesCount: Maybe<Scalars['Int']>;
  stripeCustomerId: Maybe<Scalars['String']>;
  stripePlan: Maybe<IStripePlan>;
  subscription: Maybe<IVendorSubscription>;
  tier: Scalars['Int'];
  usersCanAccessAccountPage: Scalars['Boolean'];
  usersCanRequestContentTranslation: Scalars['Boolean'];
  viewerCanCancelPlan: Scalars['Boolean'];
  viewerCanInviteMembers: Scalars['Boolean'];
  viewerCanRemoveMembers: Maybe<Scalars['Boolean']>;
  viewerIsMember: Maybe<Scalars['Boolean']>;
  yearFounded: Maybe<Scalars['Int']>;
};


export type ICompanyGovernmentConnectionsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  product: InputMaybe<Scalars['ObjectId']>;
};


export type ICompanyLogoArgs = {
  height: InputMaybe<Scalars['Int']>;
  width: InputMaybe<Scalars['Int']>;
};


export type ICompanyProductsArgs = {
  status?: InputMaybe<IProductStatusFilter>;
};


export type ICompanyProjectsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type ICompanyProjectsPendingVerificationArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type ICompanySharingInput = {
  governments: Scalars['Boolean'];
  groups: Scalars['Boolean'];
  highlights: Scalars['Boolean'];
  newsworthy: Scalars['Boolean'];
  products: Scalars['Boolean'];
  projects: Scalars['Boolean'];
  resources: Scalars['Boolean'];
  stories: Scalars['Boolean'];
  team: Scalars['Boolean'];
};

export type ICompanySharingOptions = {
  __typename: 'CompanySharingOptions';
  governments: Scalars['Boolean'];
  groups: Scalars['Boolean'];
  highlights: Scalars['Boolean'];
  newsworthy: Scalars['Boolean'];
  products: Scalars['Boolean'];
  projects: Scalars['Boolean'];
  resources: Scalars['Boolean'];
  stories: Scalars['Boolean'];
  team: Scalars['Boolean'];
};

export type IConnect = {
  __typename: 'Connect';
  items: Array<IConnectItem>;
  totalCount: Scalars['Int'];
};

export type IConnectItem = {
  __typename: 'ConnectItem';
  _id: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  target: IConnectTarget;
};

export type IConnectTarget = ICompany | IGovernment | IGovernmentPost | IGovernmentProject | IGovernmentUser | IGroupPost | IGroupProject | IPendingUser | IReport | IStory | IVendorPost | IVendorProject | IVendorUser;

export enum IConnectTargetType {
  Government = 'GOVERNMENT',
  Group = 'GROUP',
  User = 'USER',
  Vendor = 'VENDOR'
}

export type ICountryStateTuple = {
  __typename: 'CountryStateTuple';
  country: Scalars['String'];
  friendlyStateName: Scalars['String'];
  state: Scalars['String'];
};

export type ICountryTimezone = {
  __typename: 'CountryTimezone';
  _id: Scalars['ObjectId'];
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  regionCode: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
};

export type ICountryTimezoneInput = {
  countryCode: Scalars['String'];
  countryName: Scalars['String'];
  timezone: Scalars['String'];
};

export type ICountryTimezones = {
  __typename: 'CountryTimezones';
  items: Maybe<Array<ICountryTimezone>>;
  pageInfo: Maybe<IPageInfo>;
};

export type ICover = {
  __typename: 'Cover';
  original: Maybe<Scalars['String']>;
  reposition: Maybe<IRepositionState>;
  repositioned: Maybe<Scalars['String']>;
};

export type ICoverImageDimensions = {
  __typename: 'CoverImageDimensions';
  height: Maybe<Scalars['Float']>;
  width: Maybe<Scalars['Float']>;
};

export type ICoverImageMetadataInput = {
  height: Scalars['Float'];
  width: Scalars['Float'];
};

export type ICoverInput = {
  original: InputMaybe<Scalars['String']>;
  reposition: InputMaybe<ICoverRepositionStateInput>;
  repositioned: InputMaybe<Scalars['String']>;
};

export type ICoverRepositionStateInput = {
  original: ICoverImageMetadataInput;
  proportional: ICoverImageMetadataInput;
  rect: ICoverImageMetadataInput;
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type ICreateSaleInput = {
  description: Scalars['String'];
  expirationDate: InputMaybe<Scalars['Date']>;
  includedCollections: Array<Scalars['ObjectId']>;
  paymentSchedule: ICollectionSponsoringPaymentSchedule;
  price: Scalars['Int'];
  sponsorshipLevel: ICollectionSponsoringSponsorshipLevel;
  type: ICollectionSponsoringType;
  vendorId: Scalars['ObjectId'];
};

export type IDesktopProductDeployment = {
  __typename: 'DesktopProductDeployment';
  platforms: Array<Scalars['String']>;
};

export type IDisruptor = ICompany | IProduct | IVendorUser;

export type IDisruptorProductsAboutPageSection = {
  __typename: 'DisruptorProductsAboutPageSection';
  products: Array<IProduct>;
};

export type IDownloadResource = {
  __typename: 'DownloadResource';
  addedAt: Maybe<Scalars['DateTime']>;
  filename: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type IDuplicatedUser = {
  __typename: 'DuplicatedUser';
  _id: Scalars['ObjectId'];
  avatar: Maybe<Scalars['String']>;
  duplicatedUsers: Array<IDuplicatedUserBase>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  intercomId: Maybe<Scalars['String']>;
  role: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  userId: Scalars['ObjectId'];
  verified: Maybe<Scalars['Boolean']>;
};

export type IDuplicatedUserBase = {
  __typename: 'DuplicatedUserBase';
  avatar: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  intercomId: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  userId: Scalars['ObjectId'];
};

export type IDuplicatedUsers = {
  __typename: 'DuplicatedUsers';
  items: Maybe<Array<IDuplicatedUser>>;
  pageInfo: Maybe<IPageInfo>;
};

export type IDynamicConfig = {
  __typename: 'DynamicConfig';
  key: Scalars['String'];
  value: Scalars['JSON'];
};

export type IEditGovernmentInput = {
  awards: Array<Scalars['ObjectId']>;
  budget: InputMaybe<Scalars['Float']>;
  country: Scalars['String'];
  county: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  governmentType: Scalars['String'];
  name: Scalars['String'];
  population: InputMaybe<Scalars['Float']>;
  resources: InputMaybe<Array<IResourceInput>>;
  site: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  stateName: Scalars['String'];
  type: Scalars['String'];
  zipCode: InputMaybe<Scalars['String']>;
};

export type IEditSaleInput = {
  description: Scalars['String'];
  includedCollections: Array<Scalars['ObjectId']>;
  sponsorshipLevel: ICollectionSponsoringSponsorshipLevel;
  type: ICollectionSponsoringType;
};

export type IEditableOnboardingInput = {
  city: InputMaybe<Scalars['String']>;
  country: InputMaybe<Scalars['String']>;
  facebookUrl: InputMaybe<Scalars['String']>;
  governmentId: InputMaybe<Scalars['ObjectId']>;
  linkedInUrl: InputMaybe<Scalars['String']>;
  processStatus: IProcessStatusType;
  state: InputMaybe<Scalars['String']>;
  twitterUrl: InputMaybe<Scalars['String']>;
  vendorName: InputMaybe<Scalars['String']>;
  vendorWebsite: InputMaybe<Scalars['String']>;
  whyYouWantToJoin: InputMaybe<Scalars['String']>;
  workingFor: InputMaybe<IWorkPlaceType>;
  workplaceName: InputMaybe<Scalars['String']>;
  workplaceWebsite: InputMaybe<Scalars['String']>;
};

export type IEmptySpaceBlockData = IBlockData & {
  __typename: 'EmptySpaceBlockData';
  backgroundColor: Maybe<Scalars['String']>;
  itemLimit: Maybe<Scalars['Int']>;
  paddingBottom: Maybe<Scalars['Int']>;
  paddingLeft: Maybe<Scalars['Int']>;
  paddingRight: Maybe<Scalars['Int']>;
  paddingTop: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  titleVisible: Scalars['Boolean'];
  type: IBlockLayoutType;
};

export type IEntity = ICompany | IGovernment | IGovernmentUser | IPendingUser | IVendorUser;

export enum IEntityType {
  Company = 'COMPANY',
  Government = 'GOVERNMENT'
}

export type IEventsBlockData = IBlockData & {
  __typename: 'EventsBlockData';
  backgroundColor: Maybe<Scalars['String']>;
  itemLimit: Maybe<Scalars['Int']>;
  paddingBottom: Maybe<Scalars['Int']>;
  paddingLeft: Maybe<Scalars['Int']>;
  paddingRight: Maybe<Scalars['Int']>;
  paddingTop: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  titleVisible: Scalars['Boolean'];
  type: IBlockLayoutType;
};

export type IExternalLink = {
  __typename: 'ExternalLink';
  _id: Scalars['ObjectId'];
  createdAt: Maybe<Scalars['DateTime']>;
  source: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type IExternalLinkInput = {
  source: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type IExternalLinks = {
  __typename: 'ExternalLinks';
  items: Maybe<Array<IExternalLink>>;
  pageInfo: Maybe<IPageInfo>;
};

export type IFeaturedStoriesBlockData = IBlockData & {
  __typename: 'FeaturedStoriesBlockData';
  backgroundColor: Maybe<Scalars['String']>;
  itemLimit: Maybe<Scalars['Int']>;
  paddingBottom: Maybe<Scalars['Int']>;
  paddingLeft: Maybe<Scalars['Int']>;
  paddingRight: Maybe<Scalars['Int']>;
  paddingTop: Maybe<Scalars['Int']>;
  storyIds: Array<Scalars['ObjectId']>;
  title: Scalars['String'];
  titleVisible: Scalars['Boolean'];
  type: IBlockLayoutType;
};

export type IFeaturedTarget = IProduct | IReport | IStory;

export enum IFeaturedTargetType {
  Product = 'PRODUCT',
  Report = 'REPORT',
  Story = 'STORY'
}

export type IFieldOrderSortInput = {
  sortField: Scalars['String'];
  sortOrder: Scalars['String'];
};

export type IFlatResource = IDownloadResource | ILinkResource | ITextResource;

export type IFollowTarget = IGovernment | IGovernmentUser | IPendingUser | IVendorUser;

export enum IFollowType {
  Government = 'GOVERNMENT',
  User = 'USER'
}

export type IForceSubscribeToPlanInput = {
  billingName: Scalars['String'];
  customerId: InputMaybe<Scalars['String']>;
  daysUntilDue: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  freeSubscription: InputMaybe<Scalars['Boolean']>;
  sendInvoice: InputMaybe<Scalars['Boolean']>;
  stripePlanId: Scalars['String'];
  stripeSubscriptionId: InputMaybe<Scalars['String']>;
  trialPeriodDays: InputMaybe<Scalars['Int']>;
  vendorId: Scalars['ObjectId'];
};

export type IForwardOnlyPageInfo = {
  __typename: 'ForwardOnlyPageInfo';
  cursor: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type IGetAllTimezonesInput = {
  search: Scalars['String'];
};

export type IGlobalSettings = {
  __typename: 'GlobalSettings';
  _id: Scalars['ObjectId'];
  isOnboardingActive: Scalars['Boolean'];
};

export type IGovernment = {
  __typename: 'Government';
  _id: Scalars['ObjectId'];
  actions: Array<IGovernmentAction>;
  actionsCount: Scalars['Int'];
  activatedAt: Maybe<Scalars['DateTime']>;
  awards: Array<ITag>;
  budget: Maybe<Scalars['Float']>;
  city: ICity;
  cover: Maybe<ICover>;
  createdAt: Maybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  enableSharing: Scalars['Boolean'];
  feedCount: Scalars['Int'];
  followers: Array<IUser>;
  groups: Array<IGroup>;
  invitedBy: Maybe<Scalars['ObjectId']>;
  isFollowing: Scalars['Boolean'];
  isInnovator: Maybe<Scalars['Boolean']>;
  logo: Maybe<Scalars['String']>;
  members: Array<IGovernmentUser>;
  membersCount: Scalars['Int'];
  name: Scalars['String'];
  outsideProducts: Maybe<Array<IOutsideProduct>>;
  owner: Maybe<IGovernmentUser>;
  ownerless: Maybe<Scalars['Boolean']>;
  posts: Array<IGovernmentPost>;
  postsCount: Scalars['Int'];
  productTags: Array<ITag>;
  products: Maybe<Array<IProduct>>;
  productsCount: Scalars['Int'];
  projects: Maybe<IProjects>;
  projectsCount: Scalars['Int'];
  relatedGovernments: Array<IGovernment>;
  relatedProjects: Array<IProject>;
  resources: Maybe<Array<IFlatResource>>;
  reviewsCount: Scalars['Int'];
  settings: IGovernmentSettings;
  sharing: IGovernmentSharingOptions;
  site: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  state: Maybe<Scalars['String']>;
  stories: Maybe<IStories>;
  storiesCount: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  verifiedProjects: IProjects;
  viewerCanInviteMembers: Maybe<Scalars['Boolean']>;
  viewerCanRemoveMembers: Maybe<Scalars['Boolean']>;
  viewerCanRequestAccess: Maybe<Scalars['Boolean']>;
  viewerCanSeeProductStack: Scalars['Boolean'];
  viewerCanSeeTeam: Scalars['Boolean'];
  viewerIsMember: Maybe<Scalars['Boolean']>;
  viewerIsOwner: Maybe<Scalars['Boolean']>;
  viewerRequestedMembership: Maybe<Scalars['Boolean']>;
  viewerRequestedOwnership: Maybe<Scalars['Boolean']>;
};


export type IGovernmentMembersArgs = {
  fromSharingPage: InputMaybe<Scalars['Boolean']>;
  token: InputMaybe<Scalars['String']>;
};


export type IGovernmentPostsArgs = {
  audience: InputMaybe<IPostAudience>;
};


export type IGovernmentProductsArgs = {
  fromSharingPage: InputMaybe<Scalars['Boolean']>;
};


export type IGovernmentProjectsArgs = {
  audience: InputMaybe<IProjectAudience>;
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type IGovernmentStoriesArgs = {
  audience?: InputMaybe<IStoryAudience>;
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
};


export type IGovernmentStoriesCountArgs = {
  audience?: InputMaybe<IStoryAudience>;
};


export type IGovernmentVerifiedProjectsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type IGovernmentAction = IGovernmentGroupRequest | IProductStackRequest | IProjectGovernmentVerificationRequest;

export type IGovernmentCountriesBreakdownItem = {
  __typename: 'GovernmentCountriesBreakdownItem';
  /** The ISO 3166-1 alpha-2 country code. */
  isoCountryCode: Scalars['String'];
  /** The number of Governments on that country that are Active in the system. */
  totalOfActiveGovernments: Scalars['Int'];
  /** The number of Governments on that country. */
  totalOfGovernments: Scalars['Int'];
  /** The number of Governments on that country that are Inactive in the system. */
  totalOfInactiveGovernments: Scalars['Int'];
};

export type IGovernmentGroupRequest = IGroupRequest & {
  __typename: 'GovernmentGroupRequest';
  _id: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  government: IGovernment;
  group: IGroup;
  inviter: IUser;
  possibleGovernments: Array<IGovernment>;
  respondedBy: Maybe<IUser>;
  status: IGroupRequestStatus;
};

export type IGovernmentPost = IPostInterface & {
  __typename: 'GovernmentPost';
  _id: Scalars['ObjectId'];
  attachments: Array<IPostAttachment>;
  audience: Scalars['String'];
  author: IUser;
  body: Scalars['String'];
  commentsCount: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deletedAt: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<IUser>;
  featuredAt: Maybe<Scalars['DateTime']>;
  government: IGovernment;
  links: Array<IPostLink>;
  upvotesCount: Scalars['Int'];
  viewerCanComment: Scalars['Boolean'];
  viewerCanDelete: Scalars['Boolean'];
  viewerCanEdit: Scalars['Boolean'];
  viewerDidBookmark: Scalars['Boolean'];
  viewerDidComment: Scalars['Boolean'];
  viewerDidUpvote: Scalars['Boolean'];
  viewerIsAuthor: Scalars['Boolean'];
};

export type IGovernmentProject = IProject & {
  __typename: 'GovernmentProject';
  _id: Scalars['ObjectId'];
  archivedAt: Maybe<Scalars['DateTime']>;
  archivedBy: Maybe<IUser>;
  attachments: Array<IProjectAttachmentItem>;
  audience: Scalars['String'];
  author: Maybe<IUser>;
  awards: Array<ITag>;
  collaborators: Array<IUser>;
  commentsCount: Maybe<Scalars['Int']>;
  countryCode: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  government: IGovernment;
  groups: Array<IGroup>;
  icon: Maybe<Scalars['String']>;
  itemsCount: IProjectItemsCount;
  links: Array<IProjectLinkItem>;
  locations: Array<IGovernment>;
  locationsPendingVerification: Array<IGovernment>;
  moreFromGovernment: Array<IGovernmentProject>;
  newsworthy: Array<IProjectNewsworthyItem>;
  outcome: Maybe<Scalars['String']>;
  outcomeStatement: Maybe<Scalars['String']>;
  outsideProducts: Array<IOutsideProduct>;
  products: Array<IProduct>;
  relatedProjects: Array<IProject>;
  slug: Scalars['String'];
  status: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  tags: Array<ITag>;
  title: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
  upvotesCount: Scalars['Int'];
  vendors: Array<ICompany>;
  verified: Scalars['Boolean'];
  verifiedBy: Maybe<IUser>;
  viewerCanComment: Scalars['Boolean'];
  viewerCanDelete: Scalars['Boolean'];
  viewerCanEdit: Scalars['Boolean'];
  viewerCanInviteCollaborators: Scalars['Boolean'];
  viewerCanRead: Scalars['Boolean'];
  viewerCanRemoveCollaborators: Scalars['Boolean'];
  viewerCanView: Scalars['Boolean'];
  viewerDidBookmark: Scalars['Boolean'];
  viewerDidComment: Scalars['Boolean'];
  viewerDidUpvote: Scalars['Boolean'];
  viewerIsAuthor: Scalars['Boolean'];
};


export type IGovernmentProjectCollaboratorsArgs = {
  token: InputMaybe<Scalars['String']>;
};


export type IGovernmentProjectDescriptionArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IGovernmentProjectNewsworthyArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IGovernmentProjectSubtitleArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IGovernmentProjectTitleArgs = {
  language: InputMaybe<Scalars['String']>;
};

export enum IGovernmentSearchScope {
  Innovator = 'INNOVATOR'
}

export type IGovernmentSettings = {
  __typename: 'GovernmentSettings';
  projectSharingWithGroups: Array<IGroup>;
};

export type IGovernmentSettingsInput = {
  projectSharingWithGroups: Array<Scalars['ObjectId']>;
};

export type IGovernmentSharingInput = {
  awards: InputMaybe<Scalars['Boolean']>;
  productStack: InputMaybe<Scalars['Boolean']>;
  projects: InputMaybe<Scalars['Boolean']>;
  resources: InputMaybe<Scalars['Boolean']>;
  stories: InputMaybe<Scalars['Boolean']>;
  team: InputMaybe<Scalars['Boolean']>;
};

export type IGovernmentSharingOptions = {
  __typename: 'GovernmentSharingOptions';
  awards: Maybe<Scalars['Boolean']>;
  productStack: Maybe<Scalars['Boolean']>;
  projects: Maybe<Scalars['Boolean']>;
  resources: Maybe<Scalars['Boolean']>;
  stories: Maybe<Scalars['Boolean']>;
  team: Maybe<Scalars['Boolean']>;
};

export type IGovernmentTuple = {
  __typename: 'GovernmentTuple';
  governmentId: Scalars['ObjectId'];
  governmentName: Scalars['String'];
};

export type IGovernmentUser = IUserInterface & IUserProfile & {
  __typename: 'GovernmentUser';
  _id: Scalars['ObjectId'];
  algoliaKeys: Maybe<IAlgoliaKeys>;
  avatar: Maybe<Scalars['String']>;
  bannedAt: Maybe<Scalars['DateTime']>;
  bookmarks: Array<IBookmarkTarget>;
  canSeeActiveGovernments: Scalars['Boolean'];
  collections: Array<ICollection>;
  country: Maybe<Scalars['String']>;
  cover: Maybe<ICover>;
  createdAt: Maybe<Scalars['DateTime']>;
  department: Maybe<ITag>;
  email: Maybe<Scalars['String']>;
  enabledSharing: Scalars['Boolean'];
  feedCount: Scalars['Int'];
  firstName: Maybe<Scalars['String']>;
  followers: Array<IUser>;
  following: Array<IFollowTarget>;
  fullName: Maybe<Scalars['String']>;
  government: IGovernment;
  groups: Array<IGroup>;
  intercomId: Maybe<Scalars['ID']>;
  interests: Array<ITag>;
  /** Pulls the Geo data from the request IP */
  ipLocation: Maybe<IIpLocation>;
  isFollowing: Scalars['Boolean'];
  isInnovator: Maybe<Scalars['Boolean']>;
  isLimitedSiteAdmin: Maybe<Scalars['Boolean']>;
  isSelfie: Maybe<Scalars['Boolean']>;
  isSiteAdmin: Maybe<Scalars['Boolean']>;
  isUnverifiable: Maybe<Scalars['Boolean']>;
  jobTitle: Maybe<Scalars['String']>;
  language: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  lastSeen: Maybe<Scalars['DateTime']>;
  lastSignInAt: Maybe<Scalars['DateTime']>;
  lastSignInProvider: Scalars['String'];
  latestNewsletterHtml: Maybe<Scalars['String']>;
  linkedinId: Maybe<Scalars['String']>;
  linkedinUrl: Maybe<Scalars['String']>;
  newsletterFrequency: Maybe<INewsletterFrequency>;
  newsletterLastSentDate: Maybe<Scalars['Date']>;
  notes: Scalars['String'];
  notificationEmail: Maybe<Scalars['String']>;
  onboarding: Maybe<IOnboarding>;
  onboardingStatus: Maybe<IProcessStatusType>;
  posts: Array<IPost>;
  postsCount: Scalars['Int'];
  preferences: IUserPreferences;
  primaryGroup: Maybe<IGroup>;
  projects: Maybe<IProjects>;
  projectsCount: Scalars['Int'];
  projectsFromGovernment: Array<IProject>;
  relatedProjects: Array<IProject>;
  resources: Maybe<Array<IFlatResource>>;
  role: Maybe<Scalars['String']>;
  savedCollectionsCount: Scalars['Int'];
  savedCount: Scalars['Int'];
  savedPostsCount: Scalars['Int'];
  savedProductsCount: Scalars['Int'];
  savedProjectsCount: Scalars['Int'];
  savedStoriesCount: Scalars['Int'];
  searchable: Maybe<Scalars['Boolean']>;
  sharing: IUserSharingOptions;
  showActionCenterBanner: Scalars['Boolean'];
  showCompleteGovernmentProfileBanner: Scalars['Boolean'];
  showOnboardingBanner: Scalars['Boolean'];
  signInAttempts: Maybe<Array<ISignInAttempt>>;
  slug: Scalars['String'];
  socialMedia: Maybe<IUserSocialMediaLinks>;
  stories: Maybe<IStories>;
  storiesCount: Maybe<Scalars['Int']>;
  subscribedToCampaign: Maybe<Scalars['Boolean']>;
  subscribedToNewsletter: Maybe<Scalars['Boolean']>;
  timeZone: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  upvotes: Maybe<IUpvotes>;
  upvotesCount: Maybe<Scalars['Int']>;
  verified: Scalars['Boolean'];
};


export type IGovernmentUserAvatarArgs = {
  height: InputMaybe<Scalars['Int']>;
  width: InputMaybe<Scalars['Int']>;
};


export type IGovernmentUserPostsArgs = {
  audience: InputMaybe<IPostAudience>;
};


export type IGovernmentUserPostsCountArgs = {
  audience: InputMaybe<IPostAudience>;
};


export type IGovernmentUserProjectsArgs = {
  audience: InputMaybe<IProjectAudience>;
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
};


export type IGovernmentUserStoriesArgs = {
  audience: InputMaybe<IStoryAudience>;
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
};


export type IGovernmentUserStoriesCountArgs = {
  audience: InputMaybe<IStoryAudience>;
};


export type IGovernmentUserUpvotesArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
};

export type IGovernmentVerificationRequest = {
  _id: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  government: IGovernment;
  respondedAt: Maybe<Scalars['DateTime']>;
  respondedBy: Maybe<IUser>;
  status: IGovernmentVerificationRequestStatus;
};

export enum IGovernmentVerificationRequestSearchTarget {
  Government = 'GOVERNMENT',
  Group = 'GROUP',
  Project = 'PROJECT'
}

export enum IGovernmentVerificationRequestSortBy {
  Created = 'CREATED',
  Government = 'GOVERNMENT',
  Status = 'STATUS'
}

export enum IGovernmentVerificationRequestSortByDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export enum IGovernmentVerificationRequestStatus {
  Cancelled = 'CANCELLED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
  Verified = 'VERIFIED'
}

export type IGovernmentVerificationRequests = {
  __typename: 'GovernmentVerificationRequests';
  items: Array<IGovernmentVerificationRequest>;
  metadata: IGovernmentVerificationRequestsMetadata;
  pageInfo: Maybe<IPageInfo>;
};

export type IGovernmentVerificationRequestsMetadata = {
  __typename: 'GovernmentVerificationRequestsMetadata';
  cancelledCount: Scalars['Int'];
  declinedCount: Scalars['Int'];
  pendingCount: Scalars['Int'];
  totalCount: Scalars['Int'];
  verifiedCount: Scalars['Int'];
};

export type IGovernmentWhitelistInput = {
  email: InputMaybe<Scalars['String']>;
  innovator: InputMaybe<Scalars['Boolean']>;
  linkedinUrl: InputMaybe<Scalars['String']>;
  owner: InputMaybe<Scalars['Boolean']>;
};

export type IGovernments = {
  __typename: 'Governments';
  items: Maybe<Array<IGovernment>>;
  pageInfo: Maybe<IPageInfo>;
};

export type IGovernmentsBlockData = IBlockData & {
  __typename: 'GovernmentsBlockData';
  backgroundColor: Maybe<Scalars['String']>;
  itemLimit: Maybe<Scalars['Int']>;
  paddingBottom: Maybe<Scalars['Int']>;
  paddingLeft: Maybe<Scalars['Int']>;
  paddingRight: Maybe<Scalars['Int']>;
  paddingTop: Maybe<Scalars['Int']>;
  secondTitle: Scalars['String'];
  title: Scalars['String'];
  titleVisible: Scalars['Boolean'];
  type: IBlockLayoutType;
};

export type IGovernmentsCount = {
  __typename: 'GovernmentsCount';
  activeCount: Maybe<Scalars['Int']>;
  allCount: Maybe<Scalars['Int']>;
  inactiveCount: Maybe<Scalars['Int']>;
  innovatorsCount: Maybe<Scalars['Int']>;
  referralCount: Maybe<Scalars['Int']>;
  sharingCount: Maybe<Scalars['Int']>;
};

export enum IGovlaunchEnvironment {
  Production = 'PRODUCTION',
  Qa = 'QA'
}

export type IGovlaunchStatsCountData = {
  __typename: 'GovlaunchStatsCountData';
  governmentsCount: Scalars['Int'];
  productsCount: Scalars['Int'];
  projectsCount: Scalars['Int'];
  storiesCount: Scalars['Int'];
  vendorsCount: Scalars['Int'];
};

export type IGovlaunchStatsCountInput = {
  countGovernments: Scalars['Boolean'];
  countProducts: Scalars['Boolean'];
  countProjects: Scalars['Boolean'];
  countStories: Scalars['Boolean'];
  countVendors: Scalars['Boolean'];
};

export type IGroup = {
  __typename: 'Group';
  _id: Scalars['ObjectId'];
  about: Scalars['String'];
  awards: Array<ITag>;
  createdAt: Maybe<Scalars['DateTime']>;
  deletedAt: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<IUser>;
  feedCount: Scalars['Int'];
  governments: Array<IGovernment>;
  headedByAdmins: Scalars['Boolean'];
  isLockedForViewer: Scalars['Boolean'];
  kind: Maybe<IGroupKind>;
  location: Scalars['String'];
  logo: Maybe<Scalars['String']>;
  members: IUsers;
  membersCount: Scalars['Int'];
  name: Scalars['String'];
  newsworthy: Array<IGroupNewsworthyItem>;
  outsideProducts: Maybe<Array<IOutsideProduct>>;
  owner: Maybe<ICompany>;
  pendingRequests: Array<IGroupRequest>;
  posts: Maybe<IGroupPosts>;
  previousOwner: Maybe<ICompany>;
  products: Array<IProduct>;
  productsCount: Scalars['Int'];
  projects: IProjects;
  projectsCount: Scalars['Int'];
  projectsFromGovernments: IProjects;
  projectsPendingVerification: IProjects;
  resources: Array<IFlatResource>;
  slug: Scalars['String'];
  socialMedia: Maybe<IGroupSocialMediaLinks>;
  tags: Array<ITag>;
  updatedAt: Maybe<Scalars['DateTime']>;
  vendors: Array<ICompany>;
  viewerCanDelete: Scalars['Boolean'];
  viewerCanEdit: Scalars['Boolean'];
  viewerCanHeadContent: Scalars['Boolean'];
  viewerCanRemoveMembers: Scalars['Boolean'];
  viewerIsMember: Scalars['Boolean'];
  viewerIsOwner: Scalars['Boolean'];
  visibility: Maybe<IGroupVisibility>;
  website: Scalars['String'];
};


export type IGroupMembersArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
};


export type IGroupPostsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type IGroupProjectsArgs = {
  audience: InputMaybe<IProjectAudience>;
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
};


export type IGroupProjectsFromGovernmentsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type IGroupProjectsPendingVerificationArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type IGroupInput = {
  about: Scalars['String'];
  awards: Array<Scalars['ObjectId']>;
  kind: InputMaybe<IGroupKind>;
  location: Scalars['String'];
  logo: InputMaybe<Scalars['String']>;
  members: Array<Scalars['ObjectId']>;
  name: Scalars['String'];
  newsworthy: Array<IGroupNewsworthyItemInput>;
  outsideProducts: InputMaybe<Array<IOutsideProductInput>>;
  products: Array<Scalars['ObjectId']>;
  resources: Array<IResourceInput>;
  socialMedia: InputMaybe<IGroupSocialMediaLinksInput>;
  tags: InputMaybe<Array<Scalars['ObjectId']>>;
  vendor: InputMaybe<Scalars['ObjectId']>;
  visibility: IGroupVisibility;
  website: Scalars['String'];
};

export enum IGroupKind {
  /** Everything that doesn't fall in the above buckets will be "community" */
  Community = 'COMMUNITY',
  /** A group will be considered a Discussion if there's a Collection for it */
  Discussion = 'DISCUSSION',
  /** A group will be considered a Vendor if the owner is a Vendor */
  Vendor = 'VENDOR'
}

export type IGroupNewsworthyItem = {
  __typename: 'GroupNewsworthyItem';
  addedAt: Maybe<Scalars['Float']>;
  description: Maybe<Scalars['String']>;
  favicon: Maybe<Scalars['String']>;
  siteName: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type IGroupNewsworthyItemInput = {
  addedAt: InputMaybe<Scalars['Float']>;
  description: InputMaybe<Scalars['String']>;
  favicon: InputMaybe<Scalars['String']>;
  siteName: InputMaybe<Scalars['String']>;
  title: InputMaybe<Scalars['String']>;
  url: InputMaybe<Scalars['String']>;
};

export type IGroupPost = IPostInterface & {
  __typename: 'GroupPost';
  _id: Scalars['ObjectId'];
  attachments: Array<IPostAttachment>;
  audience: Scalars['String'];
  author: IUser;
  body: Scalars['String'];
  commentsCount: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deletedAt: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<IUser>;
  featuredAt: Maybe<Scalars['DateTime']>;
  group: IGroup;
  links: Array<IPostLink>;
  upvotesCount: Scalars['Int'];
  viewerCanComment: Scalars['Boolean'];
  viewerCanDelete: Scalars['Boolean'];
  viewerCanEdit: Scalars['Boolean'];
  viewerDidBookmark: Scalars['Boolean'];
  viewerDidComment: Scalars['Boolean'];
  viewerDidUpvote: Scalars['Boolean'];
  viewerIsAuthor: Scalars['Boolean'];
};

export type IGroupPosts = {
  __typename: 'GroupPosts';
  items: Array<IGroupPost>;
  pageInfo: Maybe<IPageInfo>;
};

export type IGroupProject = IProject & {
  __typename: 'GroupProject';
  _id: Scalars['ObjectId'];
  archivedAt: Maybe<Scalars['DateTime']>;
  archivedBy: Maybe<IUser>;
  attachments: Array<IProjectAttachmentItem>;
  audience: Scalars['String'];
  author: Maybe<IUser>;
  awards: Array<ITag>;
  collaborators: Array<IUser>;
  commentsCount: Maybe<Scalars['Int']>;
  countryCode: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  group: IGroup;
  groups: Array<IGroup>;
  icon: Maybe<Scalars['String']>;
  itemsCount: IProjectItemsCount;
  links: Array<IProjectLinkItem>;
  locations: Array<IGovernment>;
  locationsPendingVerification: Array<IGovernment>;
  moreFromGovernment: Array<IGovernmentProject>;
  newsworthy: Array<IProjectNewsworthyItem>;
  outcome: Maybe<Scalars['String']>;
  outcomeStatement: Maybe<Scalars['String']>;
  outsideProducts: Array<IOutsideProduct>;
  products: Array<IProduct>;
  relatedProjects: Array<IProject>;
  slug: Scalars['String'];
  status: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  tags: Array<ITag>;
  title: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
  upvotesCount: Scalars['Int'];
  vendors: Array<ICompany>;
  verified: Scalars['Boolean'];
  verifiedBy: Maybe<IUser>;
  viewerCanComment: Scalars['Boolean'];
  viewerCanDelete: Scalars['Boolean'];
  viewerCanEdit: Scalars['Boolean'];
  viewerCanInviteCollaborators: Scalars['Boolean'];
  viewerCanRead: Scalars['Boolean'];
  viewerCanRemoveCollaborators: Scalars['Boolean'];
  viewerCanView: Scalars['Boolean'];
  viewerDidBookmark: Scalars['Boolean'];
  viewerDidComment: Scalars['Boolean'];
  viewerDidUpvote: Scalars['Boolean'];
  viewerIsAuthor: Scalars['Boolean'];
};


export type IGroupProjectCollaboratorsArgs = {
  token: InputMaybe<Scalars['String']>;
};


export type IGroupProjectDescriptionArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IGroupProjectNewsworthyArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IGroupProjectSubtitleArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IGroupProjectTitleArgs = {
  language: InputMaybe<Scalars['String']>;
};

export type IGroupRequest = {
  _id: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  group: IGroup;
  inviter: IUser;
  respondedBy: Maybe<IUser>;
  status: IGroupRequestStatus;
};

export enum IGroupRequestStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type IGroupSocialMediaLinks = {
  __typename: 'GroupSocialMediaLinks';
  facebook: Maybe<Scalars['URL']>;
  instagram: Maybe<Scalars['URL']>;
  linkedin: Maybe<Scalars['URL']>;
  twitter: Maybe<Scalars['URL']>;
  youtube: Maybe<Scalars['URL']>;
};

export type IGroupSocialMediaLinksInput = {
  facebook: InputMaybe<Scalars['URL']>;
  instagram: InputMaybe<Scalars['URL']>;
  linkedin: InputMaybe<Scalars['URL']>;
  twitter: InputMaybe<Scalars['URL']>;
  youtube: InputMaybe<Scalars['URL']>;
};

export enum IGroupVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type IGroups = {
  __typename: 'Groups';
  items: Maybe<Array<IGroup>>;
  pageInfo: Maybe<IPageInfo>;
};

export type IGroupsCount = {
  __typename: 'GroupsCount';
  allCount: Maybe<Scalars['Int']>;
  archivedCount: Maybe<Scalars['Int']>;
  communityCount: Scalars['Int'];
  discussionCount: Scalars['Int'];
  vendorCount: Scalars['Int'];
};

export enum IGroupsFilter {
  All = 'ALL',
  Archived = 'ARCHIVED'
}

export type IHeaderBannerButton = {
  __typename: 'HeaderBannerButton';
  buttonBackgroundColor: Maybe<Scalars['String']>;
  buttonBorderColor: Maybe<Scalars['String']>;
  buttonHoverColor: Maybe<Scalars['String']>;
  buttonHref: Scalars['String'];
  buttonText: Scalars['String'];
  buttonTextColor: Maybe<Scalars['String']>;
};

export type IHeaderBannerButtonInput = {
  buttonBackgroundColor: InputMaybe<Scalars['String']>;
  buttonBorderColor: InputMaybe<Scalars['String']>;
  buttonHoverColor: InputMaybe<Scalars['String']>;
  buttonHref: Scalars['String'];
  buttonText: Scalars['String'];
  buttonTextColor: InputMaybe<Scalars['String']>;
};

export type IHeaderBannerContent = {
  __typename: 'HeaderBannerContent';
  backgroundImage: Maybe<IHeaderBannerImage>;
  foregroundImage: Maybe<IHeaderBannerImage>;
  leftButtonBackgroundColor: Maybe<Scalars['String']>;
  leftButtonBorderColor: Maybe<Scalars['String']>;
  leftButtonHoverColor: Maybe<Scalars['String']>;
  leftButtonHref: Maybe<Scalars['String']>;
  leftButtonText: Maybe<Scalars['String']>;
  leftButtonTextColor: Maybe<Scalars['String']>;
  rightButtonBackgroundColor: Maybe<Scalars['String']>;
  rightButtonBorderColor: Maybe<Scalars['String']>;
  rightButtonHoverColor: Maybe<Scalars['String']>;
  rightButtonHref: Maybe<Scalars['String']>;
  rightButtonText: Maybe<Scalars['String']>;
  rightButtonTextColor: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  subtitleColor: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  titleColor: Maybe<Scalars['String']>;
};

export type IHeaderBannerContentInput = {
  backgroundImage: InputMaybe<IHeaderBannerImageInput>;
  foregroundImage: InputMaybe<IHeaderBannerImageInput>;
  leftButtonBackgroundColor: InputMaybe<Scalars['String']>;
  leftButtonBorderColor: InputMaybe<Scalars['String']>;
  leftButtonHoverColor: InputMaybe<Scalars['String']>;
  leftButtonHref: InputMaybe<Scalars['String']>;
  leftButtonText: InputMaybe<Scalars['String']>;
  leftButtonTextColor: InputMaybe<Scalars['String']>;
  rightButtonBackgroundColor: InputMaybe<Scalars['String']>;
  rightButtonBorderColor: InputMaybe<Scalars['String']>;
  rightButtonHoverColor: InputMaybe<Scalars['String']>;
  rightButtonHref: InputMaybe<Scalars['String']>;
  rightButtonText: InputMaybe<Scalars['String']>;
  rightButtonTextColor: InputMaybe<Scalars['String']>;
  subtitle: InputMaybe<Scalars['String']>;
  subtitleColor: InputMaybe<Scalars['String']>;
  title: InputMaybe<Scalars['String']>;
  titleColor: InputMaybe<Scalars['String']>;
};

export type IHeaderBannerImage = {
  __typename: 'HeaderBannerImage';
  alt: Scalars['String'];
  url: Scalars['String'];
};

export type IHeaderBannerImageInput = {
  alt: Scalars['String'];
  url: Scalars['String'];
};

export type IImage = {
  __typename: 'Image';
  _id: Scalars['ObjectId'];
  alt: Maybe<Scalars['String']>;
  isPlceholder: Maybe<Scalars['Boolean']>;
  source: Maybe<Scalars['String']>;
  sourceType: Maybe<IImageSourceType>;
  url: Scalars['String'];
};

export type IImageBlockData = IBlockData & {
  __typename: 'ImageBlockData';
  alt: Scalars['String'];
  backgroundColor: Maybe<Scalars['String']>;
  itemLimit: Maybe<Scalars['Int']>;
  paddingBottom: Maybe<Scalars['Int']>;
  paddingLeft: Maybe<Scalars['Int']>;
  paddingRight: Maybe<Scalars['Int']>;
  paddingTop: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  titleVisible: Scalars['Boolean'];
  type: IBlockLayoutType;
  url: Scalars['String'];
};

export type IImageInput = {
  alt: InputMaybe<Scalars['String']>;
  isPlaceholder: InputMaybe<Scalars['Boolean']>;
  url: Scalars['String'];
};

export type IImageLinkBlockData = IBlockData & {
  __typename: 'ImageLinkBlockData';
  alt: Scalars['String'];
  backgroundColor: Maybe<Scalars['String']>;
  href: Scalars['String'];
  itemLimit: Maybe<Scalars['Int']>;
  paddingBottom: Maybe<Scalars['Int']>;
  paddingLeft: Maybe<Scalars['Int']>;
  paddingRight: Maybe<Scalars['Int']>;
  paddingTop: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  titleVisible: Scalars['Boolean'];
  type: IBlockLayoutType;
  url: Scalars['String'];
};

export enum IImageSourceType {
  Product = 'PRODUCT',
  Story = 'STORY'
}

export type IImages = {
  __typename: 'Images';
  items: Array<IImage>;
  pageInfo: Maybe<IPageInfo>;
};

export type IImagesCount = {
  __typename: 'ImagesCount';
  allCount: Scalars['Int'];
  archivedCount: Scalars['Int'];
  noAltCount: Scalars['Int'];
  placeholderCount: Scalars['Int'];
  withAltCount: Scalars['Int'];
};

export type IImagesFilterInput = {
  search: InputMaybe<Scalars['String']>;
  tab: InputMaybe<IImagesQueryTab>;
};

export enum IImagesQueryTab {
  All = 'ALL',
  Archived = 'ARCHIVED',
  NoAlt = 'NO_ALT',
  Placeholder = 'PLACEHOLDER',
  WithAlt = 'WITH_ALT'
}

export type IInnovator = IGovernment | IGovernmentUser;

export type IInvoice = {
  __typename: 'Invoice';
  amount: Scalars['Int'];
  amountRefunded: Scalars['Int'];
  card: Maybe<ICardSource>;
  currency: Scalars['String'];
  friendlyId: Scalars['String'];
  id: Scalars['String'];
  items: Array<IInvoiceItem>;
  paidAt: Maybe<Scalars['DateTime']>;
  receiptUrl: Maybe<Scalars['String']>;
  status: IInvoiceStatus;
};

export type IInvoiceItem = {
  __typename: 'InvoiceItem';
  amount: Scalars['Int'];
  description: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export enum IInvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Uncollectible = 'UNCOLLECTIBLE',
  Void = 'VOID'
}

export type IIpLocation = {
  __typename: 'IpLocation';
  /** ISO 2-digit country code. For example: US, BR, BG, etc */
  countryCode: Scalars['String'];
  /** Country name in English */
  countryName: Scalars['String'];
};

export type ILandingPageConfig = {
  __typename: 'LandingPageConfig';
  _id: Scalars['ObjectId'];
  active: Scalars['Boolean'];
  allowOverlap: Maybe<Scalars['Boolean']>;
  blocks: Array<ILandingPageConfigBlock>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<IUser>;
  deactivatedAt: Maybe<Scalars['DateTime']>;
  deactivatedBy: Maybe<Scalars['ObjectId']>;
  headerBanner: Maybe<IHeaderBannerContent>;
  name: Scalars['String'];
  updatedAt: Maybe<Scalars['Date']>;
};

export type ILandingPageConfigBlock = {
  __typename: 'LandingPageConfigBlock';
  blockData: IBlockData;
  h: Scalars['Int'];
  i: Scalars['String'];
  isBounded: Maybe<Scalars['Boolean']>;
  isDraggable: Maybe<Scalars['Boolean']>;
  isResizable: Maybe<Scalars['Boolean']>;
  static: Maybe<Scalars['Boolean']>;
  w: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type ILandingPageConfigBlockDataInput = {
  alt: InputMaybe<Scalars['String']>;
  backgroundColor: InputMaybe<Scalars['String']>;
  countersColor: InputMaybe<Scalars['String']>;
  displayAsTags: InputMaybe<Scalars['Boolean']>;
  href: InputMaybe<Scalars['String']>;
  itemLimit: InputMaybe<Scalars['Int']>;
  labelsColor: InputMaybe<Scalars['String']>;
  paddingBottom: InputMaybe<Scalars['Int']>;
  paddingLeft: InputMaybe<Scalars['Int']>;
  paddingRight: InputMaybe<Scalars['Int']>;
  paddingTop: InputMaybe<Scalars['Int']>;
  podcastSrc: InputMaybe<Scalars['String']>;
  secondTitle: InputMaybe<Scalars['String']>;
  showGovernments: InputMaybe<Scalars['Boolean']>;
  showProducts: InputMaybe<Scalars['Boolean']>;
  showProjects: InputMaybe<Scalars['Boolean']>;
  showStories: InputMaybe<Scalars['Boolean']>;
  showVendors: InputMaybe<Scalars['Boolean']>;
  storyIds: InputMaybe<Array<Scalars['ObjectId']>>;
  title: Scalars['String'];
  titleVisible: Scalars['Boolean'];
  type: IBlockLayoutType;
  url: InputMaybe<Scalars['String']>;
  widgetColor: InputMaybe<Scalars['String']>;
};

export type ILandingPageConfigBlockInput = {
  blockData: ILandingPageConfigBlockDataInput;
  h: Scalars['Int'];
  i: Scalars['String'];
  isBounded: InputMaybe<Scalars['Boolean']>;
  isDraggable: InputMaybe<Scalars['Boolean']>;
  isResizable: InputMaybe<Scalars['Boolean']>;
  static: InputMaybe<Scalars['Boolean']>;
  w: Scalars['Int'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type ILandingPageConfigInput = {
  active: Scalars['Boolean'];
  allowOverlap: InputMaybe<Scalars['Boolean']>;
  blocks: Array<ILandingPageConfigBlockInput>;
  headerBanner: InputMaybe<IHeaderBannerContentInput>;
  name: Scalars['String'];
};

export type ILandingPageConfigs = {
  __typename: 'LandingPageConfigs';
  items: Maybe<Array<ILandingPageConfig>>;
  pageInfo: Maybe<IPageInfo>;
};

export type ILandingPageEvent = {
  __typename: 'LandingPageEvent';
  _id: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  endDate: Maybe<Scalars['DateTime']>;
  location: Scalars['String'];
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type ILandingPageEventInput = {
  endDate: InputMaybe<Scalars['DateTime']>;
  location: Scalars['String'];
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type ILandingPageEvents = {
  __typename: 'LandingPageEvents';
  items: Maybe<Array<ILandingPageEvent>>;
  pageInfo: Maybe<IPageInfo>;
};

export type ILatestGovernmentsAboutPageSection = {
  __typename: 'LatestGovernmentsAboutPageSection';
  governments: Array<IGovernment>;
};

export type ILimitsInput = {
  enrichedProducts: Scalars['Int'];
  enrichedProfile: Scalars['Boolean'];
  groupInvitations: Scalars['Int'];
  groups: Scalars['Int'];
  inviteGovernments: Scalars['Boolean'];
  productTags: Scalars['Int'];
  products: Scalars['Int'];
  projectsPerMonth: Scalars['Int'];
  searchAccess: Scalars['Boolean'];
  sharingPage: Scalars['Boolean'];
  teamMembers: Scalars['Int'];
};

export type ILinkResource = {
  __typename: 'LinkResource';
  addedAt: Maybe<Scalars['DateTime']>;
  title: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type ILinkUnfurlMeta = {
  __typename: 'LinkUnfurlMeta';
  description: Maybe<Scalars['String']>;
  favicon: Maybe<Scalars['String']>;
  siteName: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type ILumpSumCollectionSponsoringSale = ICollectionSponsoringSale & {
  __typename: 'LumpSumCollectionSponsoringSale';
  _id: Scalars['ObjectId'];
  archivedAt: Maybe<Scalars['DateTime']>;
  archivedBy: Maybe<IUser>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  expirationDate: Scalars['Date'];
  includedCollections: Array<ICollection>;
  isActive: Scalars['Boolean'];
  paymentSchedule: ICollectionSponsoringPaymentSchedule;
  price: Scalars['Int'];
  sponsorshipLevel: ICollectionSponsoringSponsorshipLevel;
  stripeState: ICollectionSponsoringStripeState;
  type: ICollectionSponsoringType;
  updatedAt: Scalars['DateTime'];
  vendor: ICompany;
};

export type IMedia = {
  __typename: 'Media';
  alt: Maybe<Scalars['String']>;
  type: Maybe<IMediaType>;
  url: Maybe<Scalars['URL']>;
};

export type IMediaInput = {
  alt: InputMaybe<Scalars['String']>;
  type: IMediaType;
  url: Scalars['URL'];
};

export enum IMediaType {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Youtube = 'YOUTUBE'
}

export type IMetadataLink = {
  __typename: 'MetadataLink';
  meta: Maybe<ILinkUnfurlMeta>;
  url: Maybe<Scalars['String']>;
};

export type IMostPopularProject = {
  __typename: 'MostPopularProject';
  project: Maybe<IProject>;
};

export type IMutation = {
  __typename: 'Mutation';
  acceptGroupRequest: Scalars['Boolean'];
  acceptProductStackRequest: Scalars['Boolean'];
  addCollection: ICollection;
  addCountryTimezone: Maybe<ICountryTimezone>;
  addGovernment: IGovernment;
  addPlan: Maybe<Scalars['Boolean']>;
  addProduct: Maybe<IProduct>;
  addProductsToGovernments: Maybe<Scalars['Boolean']>;
  addReport: Maybe<IReport>;
  addSolution: ISolution;
  addSponsorship: Maybe<ISponsorship>;
  addTag: ITag;
  addVendor: Maybe<ICompany>;
  adminSignIn: Maybe<Scalars['String']>;
  approveSolution: ISolution;
  archiveCollectionSponsoringSale: ICollectionSponsoringSale;
  archiveNewsletterCampaign: INewsletterCampaign;
  archiveProduct: Maybe<Scalars['Boolean']>;
  archiveProject: Scalars['Boolean'];
  archiveProjectsByIds: Scalars['Boolean'];
  archiveSponsorship: Maybe<Scalars['Boolean']>;
  archiveStory: Maybe<IStory>;
  archiveVendor: Maybe<Scalars['Boolean']>;
  assignUser: Maybe<IUser>;
  autopopulateInterests: Scalars['Int'];
  bulkTagByObjectIds: Maybe<Scalars['Boolean']>;
  cancelGroupRequest: Scalars['Boolean'];
  cancelProductStackRequest: Scalars['Boolean'];
  cancelSubscription: ICompany;
  cancelVerificationRequestFromProjectByGovernmentId: IProject;
  changeCollectionPosition: ICollection;
  changeCreditCard: ICompany;
  changeGroupMembers: Maybe<IGroup>;
  /** This mutation is for changing the Onboarding process status */
  changeOnboardingStatus: IOnboarding;
  changeStoryAuthor: Maybe<IStory>;
  changeSubscriptionPlan: ICompany;
  createAboutPageConfig: IAboutPageConfig;
  createCollectionSponsoringSale: ICollectionSponsoringSale;
  createComment: IComment;
  createExternalLink: Maybe<IExternalLink>;
  createGroup: Maybe<IGroup>;
  createLandingPageConfig: Maybe<ILandingPageConfig>;
  createLandingPageEvent: Maybe<ILandingPageEvent>;
  createNewsletterCampaign: INewsletterCampaign;
  createProjectForGovernment: IProject;
  createProjectForGroup: IProject;
  createProjectForVendor: IProject;
  declineGroupRequest: Scalars['Boolean'];
  declineProductStackRequest: Scalars['Boolean'];
  declineProjectVerification: IProjectGovernmentVerificationRequest;
  deleteExternalLink: Maybe<Scalars['Boolean']>;
  deleteLandingPageConfig: Maybe<Scalars['Boolean']>;
  deleteLandingPageEvent: Maybe<Scalars['Boolean']>;
  deletePost: Scalars['Boolean'];
  deleteProfile: Maybe<Scalars['Boolean']>;
  deleteUser: Maybe<Scalars['Boolean']>;
  denyOutsideProduct: Maybe<Scalars['Boolean']>;
  dismissActionCenter: Scalars['Boolean'];
  dismissCompleteGovernmentProfileBanner: Scalars['Boolean'];
  dismissOnboardingBanner: Scalars['Boolean'];
  duplicateAboutPageConfig: Maybe<Scalars['Boolean']>;
  duplicateLandingPageConfig: Maybe<Scalars['Boolean']>;
  editCollectionSponsoringSale: ICollectionSponsoringSale;
  editComment: IComment;
  editCountryTimezone: Maybe<ICountryTimezone>;
  editExternalLink: Maybe<IExternalLink>;
  editGovernment: IGovernment;
  editGovernmentMembers: IGovernment;
  editGroupGovernments: IGroup;
  editGroupMembers: IGroup;
  editGroupVendors: IGroup;
  editLandingPageConfig: Maybe<ILandingPageConfig>;
  editLandingPageEvent: Maybe<ILandingPageEvent>;
  editNewsletterCampaign: INewsletterCampaign;
  /** This mutation is for allowing a Site admin to edit all onboarding details provided by an User */
  editOnboarding: Maybe<IOnboarding>;
  editPlan: IPlan;
  editPost: Maybe<IPost>;
  editProduct: Maybe<IProduct>;
  editProject: IProject;
  editReport: Maybe<IReport>;
  editSponsorship: Maybe<ISponsorship>;
  editStory: IStory;
  editTier: Maybe<Scalars['Boolean']>;
  editVendor: Maybe<ICompany>;
  editVendorCustomLimits: ICompany;
  editVendorMembers: ICompany;
  enrichMakersVendorProducts: Maybe<Scalars['Boolean']>;
  extendVendorCreditsEligibilityDate: ICompany;
  forceCancelSubscription: Scalars['Boolean'];
  /**
   * This allow for existing Users to be prompted to Onboard when the sign in again. This mutation will throw an error
   * if the provided User has already onboarded or is in the process of onboarding
   */
  forceOnboarding: IOnboarding;
  forceSendNewsletterToUser: Scalars['Boolean'];
  forceSubscribeToPlan: ICompany;
  genStorybookMockData: Array<IStorybookMockDataObject>;
  generateToken: Maybe<Scalars['String']>;
  getInviteToken: Maybe<Scalars['String']>;
  ignoreOutsideProduct: Maybe<Scalars['Boolean']>;
  impersonate: Maybe<ITokens>;
  inviteGovernmentsToAddProduct: Array<IProductStackRequest>;
  inviteGovernmentsToGroup: Maybe<Array<Maybe<IGovernmentGroupRequest>>>;
  inviteVendorsToGroup: Maybe<Array<Maybe<IVendorGroupRequest>>>;
  joinGroup: IUser;
  leaveGroup: Maybe<IUser>;
  leaveGroupAsGovernment: Maybe<Scalars['Boolean']>;
  leaveGroupAsVendor: Maybe<Scalars['Boolean']>;
  ping: Maybe<Scalars['String']>;
  postToGovernment: Maybe<IPost>;
  postToGroup: Maybe<IPost>;
  postToVendor: Maybe<IPost>;
  proposeStory: IStory;
  publishStory: IStory;
  reactivateSubscription: ICompany;
  reassembleGroupGovernments: Maybe<IGroup>;
  reassembleGroupVendors: Maybe<IGroup>;
  removeAboutPageConfig: Maybe<Scalars['Boolean']>;
  removeCitizenVerification: Maybe<IUser>;
  removeComment: Scalars['Boolean'];
  removeGroupById: Maybe<Scalars['Boolean']>;
  removeGroupGovernment: IGroup;
  removeGroupVendor: IGroup;
  removeInvitee: Maybe<Scalars['Boolean']>;
  removeTagById: Scalars['Boolean'];
  removeVerification: Maybe<IUser>;
  renameTagById: ITag;
  replyComment: IComment;
  requestGovernmentOwnership: Maybe<Scalars['Boolean']>;
  requestVendorOwnership: Maybe<Scalars['Boolean']>;
  /**
   * This allow for a Site Admin to reset the whole onboarding state of a specific user. This mutation wipes all
   * onboarding data previously collected and prompt the User to provide it all again
   */
  resetOnboarding: Maybe<IOnboarding>;
  resetPodcastPageConfig: IPodcastPageConfig;
  resetVendorCustomLimits: ICompany;
  restoreGroupById: Maybe<Scalars['Boolean']>;
  restoreProduct: Maybe<IProduct>;
  restoreProject: IProject;
  restoreSponsorship: Maybe<ISponsorship>;
  restoreStory: Maybe<IStory>;
  restoreTagById: Scalars['Boolean'];
  restoreVendor: Maybe<ICompany>;
  runCollectionContentBreakdownUpdate: Maybe<Scalars['Boolean']>;
  runSetCountriesOnEntities: Maybe<Scalars['Boolean']>;
  saveCompanySharing: ICompany;
  saveGovernmentSharing: Maybe<IGovernment>;
  saveUserSharing: Maybe<IUser>;
  sendInvites: Maybe<Scalars['Boolean']>;
  setCompanyLogo: ICompany;
  setDynamicConfigByKey: IDynamicConfig;
  setGovernmentAwards: Maybe<IGovernment>;
  setGovernmentLogo: Maybe<IGovernment>;
  setGovernmentProductStack: Maybe<IGovernment>;
  setGovernmentProductStackAsAdmin: Maybe<IGovernment>;
  setGovernmentResources: IGovernment;
  setGroupAwards: Maybe<IGroup>;
  setGroupNewsworthy: Maybe<IGroup>;
  setGroupProducts: Maybe<IGroup>;
  setGroupResources: Maybe<IGroup>;
  setPodcastPageConfig: IPodcastPageConfig;
  setPrimaryGroup: Maybe<IUser>;
  setProductOnGovernmentStack: Maybe<IProduct>;
  setProfileCover: Maybe<IEntity>;
  setProfilePicture: Maybe<IUser>;
  setStoryCoverImages: IStory;
  setSubscriptionAsOverdue: ICompany;
  setUserResources: IUser;
  setVendorCreditAmount: ICompany;
  setVendorCreditsEligibilityDate: ICompany;
  signIn: Maybe<ISignInResult>;
  /**
   * This mutation is for allowing a logged User to submit its onboarding details. It is meant to be used only in the
   * frontend in the context of a logged user (viewer)
   */
  submitOnboardingDetails: IOnboarding;
  subscribeForNewsletter: Maybe<Scalars['Boolean']>;
  subscribeToPlan: ICompany;
  suggestProduct: Maybe<IOutsideProduct>;
  suggestSolution: ISolution;
  toggleAboutPageConfigActivation: IAboutPageConfig;
  toggleActivation: Maybe<Scalars['Boolean']>;
  toggleArchiveOnCollection: ICollection;
  toggleArchiveOnSolution: ISolution;
  toggleBookmark: Maybe<IBookmarkTarget>;
  toggleCollectionFeatured: ICollection;
  toggleCollectionPublicContentAvailable: ICollection;
  toggleDisruptor: Maybe<IDisruptor>;
  toggleEnrichedProduct: IProduct;
  toggleFeatured: Maybe<IFeaturedTarget>;
  toggleFollow: Maybe<IFollowTarget>;
  toggleInnovator: Maybe<IInnovator>;
  /** This mutation is used to turn on/off the onboarding process */
  toggleIsOnboardingActive: Maybe<IGlobalSettings>;
  togglePauseCollectionMethod: Scalars['Boolean'];
  togglePostFeatured: Maybe<IPost>;
  toggleProductStack: Maybe<IProduct>;
  togglePublishedOnCollection: ICollection;
  toggleReportArchived: Maybe<IReport>;
  toggleReportPublished: Maybe<IReport>;
  toggleUpvote: Maybe<IUpvoteTarget>;
  toggleUserBan: Maybe<IUser>;
  toggleUsersCanAccessAccountPage: Maybe<Scalars['Boolean']>;
  toggleUsersCanRequestContentTranslation: Maybe<Scalars['Boolean']>;
  toggleVendorCreditsEligibility: ICompany;
  toggleVerified: IProject;
  trackLastVisitOnCollections: Maybe<Scalars['Boolean']>;
  transferOwnership: ICompany;
  unflagDuplicatedUser: Maybe<Scalars['Boolean']>;
  unpublishStory: IStory;
  unsubscribeFromNewsletter: Maybe<Scalars['Boolean']>;
  unsubscribeFromNewsletterByUserId: Maybe<Scalars['Boolean']>;
  updateAboutPageConfig: IAboutPageConfig;
  updateCollection: ICollection;
  updateCollectionMethod: Scalars['Boolean'];
  updateGovernmentProfile: Maybe<IGovernment>;
  updateGovernmentSettings: IGovernment;
  updateGroup: Maybe<IGroup>;
  updateImageAlt: IImage;
  updateNewsletterSettings: INewsletterSettings;
  updateProductSolutions: IProduct;
  updateProfile: IUser;
  updateProfileEmailSubscription: IUser;
  updateSolutionName: ISolution;
  updateTagById: ITag;
  updateTagTranslations: ITag;
  updateUserAdditionalInformation: IUser;
  uploadGovernments: IBatchGovernmentsUploadResult;
  uploadProductScreenshots: Maybe<IProduct>;
  verify: Maybe<IUser>;
  verifyCitizen: Maybe<IUser>;
  verifyProject: IProjectGovernmentVerificationRequest;
};


export type IMutationAcceptGroupRequestArgs = {
  requestId: Scalars['ObjectId'];
};


export type IMutationAcceptProductStackRequestArgs = {
  requestId: Scalars['ObjectId'];
};


export type IMutationAddCollectionArgs = {
  input: IAddCollectionInput;
};


export type IMutationAddCountryTimezoneArgs = {
  data: ICountryTimezoneInput;
};


export type IMutationAddGovernmentArgs = {
  payload: IAddGovernmentInput;
};


export type IMutationAddPlanArgs = {
  plan: IAddPlanInput;
};


export type IMutationAddProductArgs = {
  company: InputMaybe<Scalars['ObjectId']>;
  product: IProductInput;
};


export type IMutationAddProductsToGovernmentsArgs = {
  governments: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
  products: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};


export type IMutationAddReportArgs = {
  report: IReportInput;
};


export type IMutationAddSolutionArgs = {
  name: Scalars['String'];
};


export type IMutationAddSponsorshipArgs = {
  sponsorship: ISponsorshipInput;
};


export type IMutationAddTagArgs = {
  name: Scalars['String'];
  relatedTags: InputMaybe<Array<Scalars['ObjectId']>>;
  types: Array<ITagType>;
};


export type IMutationAddVendorArgs = {
  vendor: IVendorInput;
};


export type IMutationAdminSignInArgs = {
  oauthToken: InputMaybe<Scalars['String']>;
};


export type IMutationApproveSolutionArgs = {
  solutionId: Scalars['ObjectId'];
};


export type IMutationArchiveCollectionSponsoringSaleArgs = {
  saleId: Scalars['ObjectId'];
};


export type IMutationArchiveNewsletterCampaignArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationArchiveProductArgs = {
  productId: Scalars['ObjectId'];
};


export type IMutationArchiveProjectArgs = {
  projectId: Scalars['ObjectId'];
};


export type IMutationArchiveProjectsByIdsArgs = {
  projectIds: Array<Scalars['ObjectId']>;
};


export type IMutationArchiveSponsorshipArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationArchiveStoryArgs = {
  storyId: Scalars['ObjectId'];
};


export type IMutationArchiveVendorArgs = {
  vendorId: Scalars['ObjectId'];
};


export type IMutationAssignUserArgs = {
  governmentId: InputMaybe<Scalars['ObjectId']>;
  type: Scalars['String'];
  userId: Scalars['ObjectId'];
  vendorId: InputMaybe<Scalars['ObjectId']>;
};


export type IMutationBulkTagByObjectIdsArgs = {
  entityIds: Array<Scalars['ObjectId']>;
  entityType: InputMaybe<IBulkTaggingEntityType>;
  modifyStrategy: IBulkTaggingModifyStrategy;
  tagIds: Array<Scalars['ObjectId']>;
};


export type IMutationCancelGroupRequestArgs = {
  requestId: Scalars['ObjectId'];
};


export type IMutationCancelProductStackRequestArgs = {
  requestId: Scalars['ObjectId'];
};


export type IMutationCancelVerificationRequestFromProjectByGovernmentIdArgs = {
  governmentId: Scalars['ObjectId'];
  projectId: Scalars['ObjectId'];
};


export type IMutationChangeCollectionPositionArgs = {
  collectionId: Scalars['ObjectId'];
  position: Scalars['Int'];
};


export type IMutationChangeCreditCardArgs = {
  billingInformation: IBillingInformation;
  stripeSourceToken: Scalars['String'];
};


export type IMutationChangeGroupMembersArgs = {
  id: Scalars['ObjectId'];
  members: Array<Scalars['ObjectId']>;
};


export type IMutationChangeOnboardingStatusArgs = {
  newStatus: IProcessStatusType;
  userId: Scalars['ObjectId'];
};


export type IMutationChangeStoryAuthorArgs = {
  authorId: InputMaybe<Scalars['ObjectId']>;
  storyId: InputMaybe<Scalars['ObjectId']>;
};


export type IMutationChangeSubscriptionPlanArgs = {
  stripePlanId: Scalars['String'];
};


export type IMutationCreateAboutPageConfigArgs = {
  active: Scalars['Boolean'];
  config: IAboutPageConfigInput;
};


export type IMutationCreateCollectionSponsoringSaleArgs = {
  input: ICreateSaleInput;
};


export type IMutationCreateCommentArgs = {
  body: Scalars['JSON'];
  targetId: Scalars['ObjectId'];
  targetType: ICommentTargetType;
};


export type IMutationCreateExternalLinkArgs = {
  data: IExternalLinkInput;
};


export type IMutationCreateGroupArgs = {
  group: IGroupInput;
};


export type IMutationCreateLandingPageConfigArgs = {
  landingPageConfig: ILandingPageConfigInput;
};


export type IMutationCreateLandingPageEventArgs = {
  data: ILandingPageEventInput;
};


export type IMutationCreateNewsletterCampaignArgs = {
  input: INewsletterCampaignInput;
};


export type IMutationCreateProjectForGovernmentArgs = {
  government: Scalars['ObjectId'];
  project: IProjectInput;
};


export type IMutationCreateProjectForGroupArgs = {
  group: Scalars['ObjectId'];
  project: IProjectInput;
};


export type IMutationCreateProjectForVendorArgs = {
  project: IProjectInput;
  vendor: Scalars['ObjectId'];
};


export type IMutationDeclineGroupRequestArgs = {
  requestId: Scalars['ObjectId'];
};


export type IMutationDeclineProductStackRequestArgs = {
  requestId: Scalars['ObjectId'];
};


export type IMutationDeclineProjectVerificationArgs = {
  governmentId: Scalars['ObjectId'];
  projectId: Scalars['ObjectId'];
};


export type IMutationDeleteExternalLinkArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationDeleteLandingPageConfigArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationDeleteLandingPageEventArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationDeletePostArgs = {
  id: InputMaybe<Scalars['ObjectId']>;
};


export type IMutationDeleteProfileArgs = {
  userId: Scalars['ObjectId'];
};


export type IMutationDeleteUserArgs = {
  userId: Scalars['ObjectId'];
};


export type IMutationDenyOutsideProductArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationDuplicateAboutPageConfigArgs = {
  configId: Scalars['ObjectId'];
};


export type IMutationDuplicateLandingPageConfigArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationEditCollectionSponsoringSaleArgs = {
  input: IEditSaleInput;
  saleId: Scalars['ObjectId'];
};


export type IMutationEditCommentArgs = {
  body: Scalars['JSON'];
  id: Scalars['ObjectId'];
};


export type IMutationEditCountryTimezoneArgs = {
  countryTimezoneId: Scalars['ObjectId'];
  data: ICountryTimezoneInput;
};


export type IMutationEditExternalLinkArgs = {
  data: IExternalLinkInput;
  id: Scalars['ObjectId'];
};


export type IMutationEditGovernmentArgs = {
  governmentId: Scalars['ObjectId'];
  payload: IEditGovernmentInput;
};


export type IMutationEditGovernmentMembersArgs = {
  governmentId: Scalars['ObjectId'];
  members: Array<IUserWithRoleInput>;
};


export type IMutationEditGroupGovernmentsArgs = {
  governments: Array<Scalars['ObjectId']>;
  groupId: Scalars['ObjectId'];
};


export type IMutationEditGroupMembersArgs = {
  groupId: Scalars['ObjectId'];
  members: Array<Scalars['ObjectId']>;
};


export type IMutationEditGroupVendorsArgs = {
  groupId: Scalars['ObjectId'];
  vendors: Array<Scalars['ObjectId']>;
};


export type IMutationEditLandingPageConfigArgs = {
  id: Scalars['ObjectId'];
  landingPageConfig: ILandingPageConfigInput;
};


export type IMutationEditLandingPageEventArgs = {
  data: ILandingPageEventInput;
  id: Scalars['ObjectId'];
};


export type IMutationEditNewsletterCampaignArgs = {
  id: Scalars['ObjectId'];
  input: INewsletterCampaignInput;
};


export type IMutationEditOnboardingArgs = {
  data: IEditableOnboardingInput;
  userId: Scalars['ObjectId'];
};


export type IMutationEditPlanArgs = {
  plan: IAddPlanInput;
  planId: Scalars['ObjectId'];
};


export type IMutationEditPostArgs = {
  id: Scalars['ObjectId'];
  post: IPostInput;
};


export type IMutationEditProductArgs = {
  company: InputMaybe<Scalars['ObjectId']>;
  id: Scalars['ObjectId'];
  product: IProductInput;
};


export type IMutationEditProjectArgs = {
  project: IProjectInput;
  projectId: Scalars['ObjectId'];
  verified: InputMaybe<Scalars['Boolean']>;
};


export type IMutationEditReportArgs = {
  id: InputMaybe<Scalars['ObjectId']>;
  report: InputMaybe<IReportInput>;
};


export type IMutationEditSponsorshipArgs = {
  id: Scalars['ObjectId'];
  sponsorship: ISponsorshipInput;
};


export type IMutationEditStoryArgs = {
  audience: InputMaybe<Scalars['String']>;
  author: InputMaybe<Scalars['ObjectId']>;
  awards: Array<Scalars['ObjectId']>;
  body: Scalars['JSON'];
  coauthors: InputMaybe<Array<Scalars['ObjectId']>>;
  collaborators: InputMaybe<Array<Scalars['ObjectId']>>;
  government: InputMaybe<Scalars['ObjectId']>;
  highlights: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introduction: Scalars['String'];
  locations: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
  medias: InputMaybe<Array<InputMaybe<IMediaInput>>>;
  newsworthy: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  outsideProducts: InputMaybe<Array<InputMaybe<IOutsideProductInput>>>;
  products: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
  projectAwards: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resources: InputMaybe<Array<InputMaybe<IResourceInput>>>;
  storyId: Scalars['ObjectId'];
  tags: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
  title: Scalars['String'];
  vendorCoauthors: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type IMutationEditTierArgs = {
  limits: ILimitsInput;
  tier: Scalars['String'];
};


export type IMutationEditVendorArgs = {
  id: Scalars['ObjectId'];
  vendor: IVendorInput;
};


export type IMutationEditVendorCustomLimitsArgs = {
  customLimits: IVendorCustomLimitsInput;
  vendorId: Scalars['ObjectId'];
};


export type IMutationEditVendorMembersArgs = {
  members: Array<IUserWithRoleInput>;
  vendorId: Scalars['ObjectId'];
};


export type IMutationExtendVendorCreditsEligibilityDateArgs = {
  vendorId: Scalars['ObjectId'];
};


export type IMutationForceCancelSubscriptionArgs = {
  subscriptionId: Scalars['ObjectId'];
};


export type IMutationForceOnboardingArgs = {
  userId: Scalars['ObjectId'];
};


export type IMutationForceSendNewsletterToUserArgs = {
  userId: Scalars['ObjectId'];
};


export type IMutationForceSubscribeToPlanArgs = {
  data: IForceSubscribeToPlanInput;
};


export type IMutationGenStorybookMockDataArgs = {
  aggregateOn: Scalars['String'];
  aggregatePipeline: Scalars['String'];
};


export type IMutationGenerateTokenArgs = {
  slug: InputMaybe<Scalars['String']>;
};


export type IMutationGetInviteTokenArgs = {
  targetId: Scalars['String'];
  targetType: Scalars['String'];
};


export type IMutationIgnoreOutsideProductArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationImpersonateArgs = {
  userId: InputMaybe<Scalars['ObjectId']>;
};


export type IMutationInviteGovernmentsToAddProductArgs = {
  governments: Array<Scalars['ObjectId']>;
  productId: Scalars['ObjectId'];
};


export type IMutationInviteGovernmentsToGroupArgs = {
  governmentIds: Array<Scalars['ObjectId']>;
  groupId: Scalars['ObjectId'];
};


export type IMutationInviteVendorsToGroupArgs = {
  groupId: Scalars['ObjectId'];
  vendorIds: Array<Scalars['ObjectId']>;
};


export type IMutationJoinGroupArgs = {
  groupId: Scalars['ObjectId'];
};


export type IMutationLeaveGroupArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationLeaveGroupAsGovernmentArgs = {
  groupId: Scalars['ObjectId'];
};


export type IMutationLeaveGroupAsVendorArgs = {
  groupId: Scalars['ObjectId'];
};


export type IMutationPostToGovernmentArgs = {
  government: Scalars['ObjectId'];
  post: IPostInput;
};


export type IMutationPostToGroupArgs = {
  group: Scalars['ObjectId'];
  post: IPostInput;
};


export type IMutationPostToVendorArgs = {
  post: IPostInput;
  vendor: Scalars['ObjectId'];
};


export type IMutationProposeStoryArgs = {
  audience: InputMaybe<Scalars['String']>;
  author: InputMaybe<Scalars['ObjectId']>;
  awards: Array<Scalars['ObjectId']>;
  body: Scalars['JSON'];
  coauthors: InputMaybe<Array<Scalars['ObjectId']>>;
  collaborators: InputMaybe<Array<Scalars['ObjectId']>>;
  government: InputMaybe<Scalars['ObjectId']>;
  highlights: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  introduction: Scalars['String'];
  locations: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
  medias: InputMaybe<Array<InputMaybe<IMediaInput>>>;
  newsworthy: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  outsideProducts: InputMaybe<Array<InputMaybe<IOutsideProductInput>>>;
  products: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
  projectAwards: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  resources: InputMaybe<Array<InputMaybe<IResourceInput>>>;
  tags: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
  title: Scalars['String'];
  vendorCoauthors: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type IMutationPublishStoryArgs = {
  storyId: Scalars['ObjectId'];
};


export type IMutationReassembleGroupGovernmentsArgs = {
  addedGovernments: Array<Scalars['ObjectId']>;
  groupId: Scalars['ObjectId'];
  removedGovernments: Array<Scalars['ObjectId']>;
};


export type IMutationReassembleGroupVendorsArgs = {
  addedVendors: Array<Scalars['ObjectId']>;
  groupId: Scalars['ObjectId'];
  removedVendors: Array<Scalars['ObjectId']>;
};


export type IMutationRemoveAboutPageConfigArgs = {
  configId: Scalars['ObjectId'];
};


export type IMutationRemoveCitizenVerificationArgs = {
  userId: Scalars['ObjectId'];
};


export type IMutationRemoveCommentArgs = {
  id: Scalars['ObjectId'];
  targetId: InputMaybe<Scalars['ObjectId']>;
};


export type IMutationRemoveGroupByIdArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationRemoveGroupGovernmentArgs = {
  governmentId: Scalars['ObjectId'];
  groupId: Scalars['ObjectId'];
};


export type IMutationRemoveGroupVendorArgs = {
  groupId: Scalars['ObjectId'];
  vendorId: Scalars['ObjectId'];
};


export type IMutationRemoveInviteeArgs = {
  inviteeId: InputMaybe<Scalars['ObjectId']>;
  targetId: Scalars['String'];
  targetType: Scalars['String'];
};


export type IMutationRemoveTagByIdArgs = {
  tagId: Scalars['ObjectId'];
};


export type IMutationRemoveVerificationArgs = {
  attachments: InputMaybe<Array<IAttachmentInput>>;
  explanation: InputMaybe<Scalars['String']>;
  userId: Scalars['ObjectId'];
};


export type IMutationRenameTagByIdArgs = {
  name: Scalars['String'];
  tagId: Scalars['ObjectId'];
};


export type IMutationReplyCommentArgs = {
  body: Scalars['JSON'];
  replyTo: Scalars['ObjectId'];
};


export type IMutationRequestGovernmentOwnershipArgs = {
  government: InputMaybe<Scalars['ObjectId']>;
};


export type IMutationRequestVendorOwnershipArgs = {
  vendor: InputMaybe<Scalars['ObjectId']>;
};


export type IMutationResetOnboardingArgs = {
  userId: Scalars['ObjectId'];
};


export type IMutationResetVendorCustomLimitsArgs = {
  vendorId: Scalars['ObjectId'];
};


export type IMutationRestoreGroupByIdArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationRestoreProductArgs = {
  slug: Scalars['String'];
};


export type IMutationRestoreProjectArgs = {
  projectId: Scalars['ObjectId'];
};


export type IMutationRestoreSponsorshipArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationRestoreStoryArgs = {
  storyId: Scalars['ObjectId'];
};


export type IMutationRestoreTagByIdArgs = {
  tagId: Scalars['ObjectId'];
};


export type IMutationRestoreVendorArgs = {
  vendorId: Scalars['ObjectId'];
};


export type IMutationSaveCompanySharingArgs = {
  companyId: Scalars['ObjectId'];
  enabledSharing: Scalars['Boolean'];
  sharing: ICompanySharingInput;
};


export type IMutationSaveGovernmentSharingArgs = {
  enableSharing: Scalars['Boolean'];
  governmentSlug: Scalars['String'];
  sharing: InputMaybe<IGovernmentSharingInput>;
};


export type IMutationSaveUserSharingArgs = {
  enableSharing: Scalars['Boolean'];
  sharing: InputMaybe<IUserSharingInput>;
  userSlug: Scalars['String'];
};


export type IMutationSendInvitesArgs = {
  emails: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targetId: Scalars['String'];
  targetType: Scalars['String'];
};


export type IMutationSetCompanyLogoArgs = {
  companyId: Scalars['ObjectId'];
  logo: InputMaybe<Scalars['String']>;
};


export type IMutationSetDynamicConfigByKeyArgs = {
  key: Scalars['String'];
  value: Scalars['JSON'];
};


export type IMutationSetGovernmentAwardsArgs = {
  awards: Array<Scalars['ObjectId']>;
  governmentId: Scalars['ObjectId'];
};


export type IMutationSetGovernmentLogoArgs = {
  governmentId: Scalars['ObjectId'];
  logo: InputMaybe<Scalars['String']>;
};


export type IMutationSetGovernmentProductStackArgs = {
  outsideProducts: InputMaybe<Array<InputMaybe<IOutsideProductInput>>>;
  productIds: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};


export type IMutationSetGovernmentProductStackAsAdminArgs = {
  governmentId: InputMaybe<Scalars['ObjectId']>;
  productIds: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};


export type IMutationSetGovernmentResourcesArgs = {
  governmentId: Scalars['ObjectId'];
  resources: InputMaybe<Array<InputMaybe<IResourceInput>>>;
};


export type IMutationSetGroupAwardsArgs = {
  awards: Array<Scalars['ObjectId']>;
  groupId: Scalars['ObjectId'];
};


export type IMutationSetGroupNewsworthyArgs = {
  id: InputMaybe<Scalars['ObjectId']>;
  newsworthy: Array<IGroupNewsworthyItemInput>;
};


export type IMutationSetGroupProductsArgs = {
  id: InputMaybe<Scalars['ObjectId']>;
  outsideProducts: Array<IOutsideProductInput>;
  products: Array<Scalars['ObjectId']>;
};


export type IMutationSetGroupResourcesArgs = {
  id: InputMaybe<Scalars['ObjectId']>;
  resources: Array<IResourceInput>;
};


export type IMutationSetPodcastPageConfigArgs = {
  config: IPodcastPageConfigInput;
};


export type IMutationSetPrimaryGroupArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationSetProductOnGovernmentStackArgs = {
  addingGovernments: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
  productId: InputMaybe<Scalars['ObjectId']>;
  removingGovernments: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};


export type IMutationSetProfileCoverArgs = {
  cover: InputMaybe<ICoverInput>;
  targetId: Scalars['ObjectId'];
  targetType: IProfileCoverTarget;
};


export type IMutationSetProfilePictureArgs = {
  picture: Scalars['String'];
  userId: Scalars['ObjectId'];
};


export type IMutationSetStoryCoverImagesArgs = {
  horizontalCover: InputMaybe<Scalars['String']>;
  story: Scalars['ObjectId'];
  verticalCover: InputMaybe<Scalars['String']>;
};


export type IMutationSetSubscriptionAsOverdueArgs = {
  vendorSlug: Scalars['String'];
};


export type IMutationSetUserResourcesArgs = {
  resources: Array<IResourceInput>;
};


export type IMutationSetVendorCreditAmountArgs = {
  amount: Scalars['Float'];
  vendorId: Scalars['ObjectId'];
};


export type IMutationSetVendorCreditsEligibilityDateArgs = {
  canEarnCreditsUntil: Scalars['String'];
  vendorId: Scalars['ObjectId'];
};


export type IMutationSignInArgs = {
  inviteToken: InputMaybe<Scalars['String']>;
  oauthToken: InputMaybe<Scalars['String']>;
  referralToken: InputMaybe<Scalars['String']>;
  storyCollaboratorToken: InputMaybe<Scalars['String']>;
};


export type IMutationSubmitOnboardingDetailsArgs = {
  data: IOnboardingDetailsInput;
};


export type IMutationSubscribeForNewsletterArgs = {
  email: InputMaybe<Scalars['String']>;
};


export type IMutationSubscribeToPlanArgs = {
  billingInformation: IBillingInformation;
  stripePlanId: Scalars['String'];
  stripeSourceToken: Scalars['String'];
};


export type IMutationSuggestProductArgs = {
  name: Scalars['String'];
  url: InputMaybe<Scalars['String']>;
};


export type IMutationSuggestSolutionArgs = {
  input: ISuggestSolutionInput;
};


export type IMutationToggleAboutPageConfigActivationArgs = {
  configId: Scalars['ObjectId'];
};


export type IMutationToggleActivationArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationToggleArchiveOnCollectionArgs = {
  collectionId: Scalars['ObjectId'];
};


export type IMutationToggleArchiveOnSolutionArgs = {
  solutionId: Scalars['ObjectId'];
};


export type IMutationToggleBookmarkArgs = {
  targetId: Scalars['ObjectId'];
  targetType: IBookmarkType;
};


export type IMutationToggleCollectionFeaturedArgs = {
  collectionId: Scalars['ObjectId'];
};


export type IMutationToggleCollectionPublicContentAvailableArgs = {
  collectionId: Scalars['ObjectId'];
};


export type IMutationToggleDisruptorArgs = {
  entityId: Scalars['ObjectId'];
  entityType: Scalars['String'];
};


export type IMutationToggleEnrichedProductArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationToggleFeaturedArgs = {
  targetId: Scalars['ObjectId'];
  targetType: IFeaturedTargetType;
};


export type IMutationToggleFollowArgs = {
  targetId: Scalars['ObjectId'];
  targetType: IFollowType;
};


export type IMutationToggleInnovatorArgs = {
  entityId: InputMaybe<Scalars['ObjectId']>;
  entityType: InputMaybe<Scalars['String']>;
};


export type IMutationToggleIsOnboardingActiveArgs = {
  isActive: Scalars['Boolean'];
};


export type IMutationTogglePauseCollectionMethodArgs = {
  subscriptionId: Scalars['ObjectId'];
};


export type IMutationTogglePostFeaturedArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationToggleProductStackArgs = {
  governmentId: InputMaybe<Scalars['ObjectId']>;
  productId: InputMaybe<Scalars['ObjectId']>;
};


export type IMutationTogglePublishedOnCollectionArgs = {
  collectionId: Scalars['ObjectId'];
};


export type IMutationToggleReportArchivedArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationToggleReportPublishedArgs = {
  id: Scalars['ObjectId'];
};


export type IMutationToggleUpvoteArgs = {
  targetId: Scalars['ObjectId'];
  targetType: IUpvoteTargetType;
};


export type IMutationToggleUserBanArgs = {
  userId: InputMaybe<Scalars['ObjectId']>;
};


export type IMutationToggleUsersCanAccessAccountPageArgs = {
  isAccessAllowed: Scalars['Boolean'];
  vendorId: Scalars['ObjectId'];
};


export type IMutationToggleUsersCanRequestContentTranslationArgs = {
  requestContentTranslationAllowed: Scalars['Boolean'];
  vendorId: Scalars['ObjectId'];
};


export type IMutationToggleVendorCreditsEligibilityArgs = {
  vendorId: Scalars['ObjectId'];
};


export type IMutationToggleVerifiedArgs = {
  projectId: Scalars['ObjectId'];
};


export type IMutationTrackLastVisitOnCollectionsArgs = {
  collectionIds: Array<Scalars['ObjectId']>;
};


export type IMutationTransferOwnershipArgs = {
  newOwnerId: Scalars['ObjectId'];
  vendorId: Scalars['ObjectId'];
};


export type IMutationUnflagDuplicatedUserArgs = {
  userId: Scalars['ObjectId'];
};


export type IMutationUnpublishStoryArgs = {
  storyId: Scalars['ObjectId'];
};


export type IMutationUnsubscribeFromNewsletterArgs = {
  email: InputMaybe<Scalars['String']>;
};


export type IMutationUnsubscribeFromNewsletterByUserIdArgs = {
  userId: Scalars['ObjectId'];
};


export type IMutationUpdateAboutPageConfigArgs = {
  active: Scalars['Boolean'];
  config: IAboutPageConfigInput;
  configId: Scalars['ObjectId'];
};


export type IMutationUpdateCollectionArgs = {
  collectionId: Scalars['ObjectId'];
  input: IUpdateCollectionInput;
};


export type IMutationUpdateCollectionMethodArgs = {
  collectionMethod: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['ObjectId'];
};


export type IMutationUpdateGovernmentProfileArgs = {
  government: IUpdateGovernmentProfileInput;
  governmentId: Scalars['ObjectId'];
};


export type IMutationUpdateGovernmentSettingsArgs = {
  governmentId: Scalars['ObjectId'];
  settings: IGovernmentSettingsInput;
};


export type IMutationUpdateGroupArgs = {
  group: IGroupInput;
  id: Scalars['ObjectId'];
};


export type IMutationUpdateImageAltArgs = {
  alt: Scalars['String'];
  imageId: Scalars['ObjectId'];
};


export type IMutationUpdateNewsletterSettingsArgs = {
  input: INewsletterInput;
};


export type IMutationUpdateProductSolutionsArgs = {
  productId: Scalars['ObjectId'];
  solutionIds: Array<Scalars['ObjectId']>;
};


export type IMutationUpdateProfileArgs = {
  user: IUserProfileInput;
};


export type IMutationUpdateProfileEmailSubscriptionArgs = {
  user: IUserProfileSubscriptionInput;
};


export type IMutationUpdateSolutionNameArgs = {
  name: Scalars['String'];
  solutionId: Scalars['ObjectId'];
};


export type IMutationUpdateTagByIdArgs = {
  name: Scalars['String'];
  relatedTags: InputMaybe<Array<Scalars['ObjectId']>>;
  tagId: Scalars['ObjectId'];
  types: Array<ITagType>;
};


export type IMutationUpdateTagTranslationsArgs = {
  input: Array<ITagTranslationInput>;
  tagId: Scalars['ObjectId'];
};


export type IMutationUpdateUserAdditionalInformationArgs = {
  additionalInformation: IUserAdditionalInformationInput;
  userId: Scalars['ObjectId'];
};


export type IMutationUploadGovernmentsArgs = {
  isoCountryCode: Scalars['String'];
  isoCurrencyCode: Scalars['String'];
  payload: Array<IUploadGovernmentInput>;
  uploadId: Scalars['ID'];
};


export type IMutationUploadProductScreenshotsArgs = {
  productId: Scalars['ObjectId'];
  screenshots: InputMaybe<Array<Scalars['String']>>;
};


export type IMutationVerifyArgs = {
  attachments: InputMaybe<Array<IAttachmentInput>>;
  explanation: InputMaybe<Scalars['String']>;
  userId: Scalars['ObjectId'];
};


export type IMutationVerifyCitizenArgs = {
  userId: Scalars['ObjectId'];
};


export type IMutationVerifyProjectArgs = {
  governmentId: Scalars['ObjectId'];
  projectId: Scalars['ObjectId'];
};

export type INewsletterCampaign = {
  __typename: 'NewsletterCampaign';
  _id: Scalars['String'];
  archivedAt: Maybe<Scalars['Date']>;
  countries: Array<INewsletterCampaignCountryAmount>;
  dynamicContent: INewsletterCampaignContent;
  name: Scalars['String'];
};

export type INewsletterCampaignContent = {
  __typename: 'NewsletterCampaignContent';
  otherCollections: Scalars['Int'];
  projectsCount: Scalars['Int'];
  storiesCount: Scalars['Int'];
  topCollections: Scalars['Int'];
};

export type INewsletterCampaignContentInput = {
  otherCollections: Scalars['Int'];
  projectsCount: Scalars['Int'];
  storiesCount: Scalars['Int'];
  topCollections: Scalars['Int'];
};

export type INewsletterCampaignCountryAmount = {
  __typename: 'NewsletterCampaignCountryAmount';
  amount: Scalars['Int'];
  countryCode: Scalars['String'];
};

export type INewsletterCampaignCountryAmountInput = {
  amount: Scalars['Int'];
  countryCode: Scalars['String'];
};

export type INewsletterCampaignData = {
  __typename: 'NewsletterCampaignData';
  collections: Array<INewsletterCollection>;
  projects: Array<INewsletterProject>;
  stories: Array<INewsletterStory>;
  totalProjectsCount: Scalars['Int'];
  viewOnlineLink: Scalars['String'];
};

export enum INewsletterCampaignFrequency {
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type INewsletterCampaignInput = {
  countriesAmount: Array<INewsletterCampaignCountryAmountInput>;
  dynamicContent: INewsletterCampaignContentInput;
  name: Scalars['String'];
};

export type INewsletterCollection = {
  __typename: 'NewsletterCollection';
  itemsCount: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  tags: Scalars['String'];
  url: Scalars['String'];
};

export enum INewsletterFrequency {
  Biweekly = 'BIWEEKLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export type INewsletterInput = {
  collections: Scalars['Int'];
  hourToSend: Scalars['Int'];
  maxProjects: Scalars['Int'];
  projectsPerCollection: Scalars['Int'];
  storiesPerCollection: Scalars['Int'];
  subject: Scalars['String'];
};

export type INewsletterProject = {
  __typename: 'NewsletterProject';
  countryCode: Maybe<Scalars['String']>;
  flag: Maybe<Scalars['String']>;
  rawTitle: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type INewsletterSettings = {
  __typename: 'NewsletterSettings';
  collections: Scalars['Int'];
  hourToSend: Scalars['Int'];
  maxProjects: Scalars['Int'];
  projectsPerCollection: Scalars['Int'];
  storiesPerCollection: Scalars['Int'];
  subject: Scalars['String'];
};

export type INewsletterStory = {
  __typename: 'NewsletterStory';
  coverImage: Maybe<IStoryCoverImage>;
  introduction: Scalars['String'];
  podcastUrl: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Maybe<Scalars['String']>;
};

export type INewsworthyItem = {
  __typename: 'NewsworthyItem';
  description: Maybe<Scalars['String']>;
  favicon: Maybe<Scalars['String']>;
  siteName: Scalars['String'];
  title: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type IOnboarding = {
  __typename: 'Onboarding';
  _id: Scalars['ObjectId'];
  city: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  facebookUrl: Maybe<Scalars['URL']>;
  governmentId: Maybe<Scalars['ObjectId']>;
  linkedInUrl: Maybe<Scalars['URL']>;
  processStatus: IProcessStatusType;
  state: Maybe<Scalars['String']>;
  twitterUrl: Maybe<Scalars['URL']>;
  user: IUser;
  vendorName: Maybe<Scalars['String']>;
  vendorWebsite: Maybe<Scalars['URL']>;
  whyYouWantToJoin: Maybe<Scalars['String']>;
  workingFor: Maybe<IWorkPlaceType>;
  workplaceName: Maybe<Scalars['String']>;
  workplaceWebsite: Maybe<Scalars['URL']>;
};

export type IOnboardingDetailsInput = {
  city: InputMaybe<Scalars['String']>;
  country: InputMaybe<Scalars['String']>;
  facebookUrl: InputMaybe<Scalars['String']>;
  governmentId: InputMaybe<Scalars['ObjectId']>;
  linkedInUrl: InputMaybe<Scalars['String']>;
  state: InputMaybe<Scalars['String']>;
  twitterUrl: InputMaybe<Scalars['String']>;
  vendorName: InputMaybe<Scalars['String']>;
  vendorWebsite: InputMaybe<Scalars['String']>;
  whyYouWantToJoin: InputMaybe<Scalars['String']>;
  workingFor: InputMaybe<IWorkPlaceType>;
  workplaceName: InputMaybe<Scalars['String']>;
  workplaceWebsite: InputMaybe<Scalars['String']>;
};

export type IOnboardingProcessStatusCounts = {
  __typename: 'OnboardingProcessStatusCounts';
  additionalInformationRequiredCount: Scalars['Int'];
  pendingVerificationCount: Scalars['Int'];
  requiredCount: Scalars['Int'];
  verifiedCount: Scalars['Int'];
};

export type IOnboardings = {
  __typename: 'Onboardings';
  items: Array<IOnboarding>;
  pageInfo: Maybe<IPageInfo>;
};

export type IOutsideCollaborator = {
  __typename: 'OutsideCollaborator';
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  linkedinUrl: Maybe<Scalars['String']>;
};

export type IOutsideProduct = {
  __typename: 'OutsideProduct';
  _id: Scalars['ObjectId'];
  addedBy: Maybe<Array<IUser>>;
  addedOn: Maybe<Array<IOutsideProductAddedOn>>;
  createdAt: Scalars['Date'];
  logo: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product: Maybe<IProduct>;
  status: Maybe<IOutsideProductStatus>;
  url: Maybe<Scalars['URL']>;
};

export type IOutsideProductAddedOn = IGovernment | IProduct | IStory;

export type IOutsideProductInput = {
  _id: InputMaybe<Scalars['ObjectId']>;
  name: Scalars['String'];
  url: InputMaybe<Scalars['URL']>;
};

export enum IOutsideProductStatus {
  Added = 'ADDED',
  Denied = 'DENIED',
  Ignored = 'IGNORED',
  Pending = 'PENDING'
}

export type IOutsideProducts = {
  __typename: 'OutsideProducts';
  items: Maybe<Array<IOutsideProduct>>;
  pageInfo: Maybe<IPageInfo>;
};

export type IOutsideProductsCount = {
  __typename: 'OutsideProductsCount';
  addedCount: Maybe<Scalars['Int']>;
  deniedCount: Maybe<Scalars['Int']>;
  ignoredCount: Maybe<Scalars['Int']>;
  pendingCount: Maybe<Scalars['Int']>;
};

export type IOverdueSubscription = {
  __typename: 'OverdueSubscription';
  _id: Scalars['ObjectId'];
  freeSubscription: Maybe<Scalars['Boolean']>;
  lastCharge: Maybe<ICharge>;
  nextPaymentAmount: Maybe<Scalars['Int']>;
  nextPaymentAttempt: Maybe<Scalars['DateTime']>;
  pausePaymentCollection: Maybe<Scalars['Boolean']>;
  stripeStatus: Maybe<Scalars['String']>;
  trialPeriod: Maybe<Scalars['Int']>;
  willCancelAt: Scalars['DateTime'];
};

export type IPageInfo = {
  __typename: 'PageInfo';
  endCursor: Maybe<Scalars['Cursor']>;
  hasNextPage: Maybe<Scalars['Boolean']>;
  hasPreviousPage: Maybe<Scalars['Boolean']>;
  startCursor: Maybe<Scalars['Cursor']>;
  totalCount: Maybe<Scalars['Int']>;
};

export type IPendingUser = IUserInterface & IUserProfile & {
  __typename: 'PendingUser';
  _id: Scalars['ObjectId'];
  algoliaKeys: Maybe<IAlgoliaKeys>;
  avatar: Maybe<Scalars['String']>;
  bannedAt: Maybe<Scalars['DateTime']>;
  bookmarks: Array<IBookmarkTarget>;
  canSeeActiveGovernments: Scalars['Boolean'];
  collections: Array<ICollection>;
  country: Maybe<Scalars['String']>;
  cover: Maybe<ICover>;
  createdAt: Maybe<Scalars['DateTime']>;
  department: Maybe<ITag>;
  email: Maybe<Scalars['String']>;
  feedCount: Scalars['Int'];
  firstName: Maybe<Scalars['String']>;
  followers: Array<IUser>;
  following: Array<IFollowTarget>;
  fullName: Maybe<Scalars['String']>;
  groupRequest: Maybe<IGroupRequest>;
  groups: Array<IGroup>;
  intercomId: Maybe<Scalars['ID']>;
  interests: Array<ITag>;
  /** Pulls the Geo data from the request IP */
  ipLocation: Maybe<IIpLocation>;
  isDuplicated: Maybe<Scalars['Boolean']>;
  isFollowing: Scalars['Boolean'];
  isInnovator: Maybe<Scalars['Boolean']>;
  isLimitedSiteAdmin: Maybe<Scalars['Boolean']>;
  isSelfie: Maybe<Scalars['Boolean']>;
  isSiteAdmin: Maybe<Scalars['Boolean']>;
  isUnverifiable: Maybe<Scalars['Boolean']>;
  jobTitle: Maybe<Scalars['String']>;
  language: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  lastSeen: Maybe<Scalars['DateTime']>;
  lastSignInAt: Maybe<Scalars['DateTime']>;
  lastSignInProvider: Scalars['String'];
  latestNewsletterHtml: Maybe<Scalars['String']>;
  linkedinId: Maybe<Scalars['String']>;
  linkedinUrl: Maybe<Scalars['String']>;
  newsletterFrequency: Maybe<INewsletterFrequency>;
  newsletterLastSentDate: Maybe<Scalars['Date']>;
  notes: Scalars['String'];
  notificationEmail: Maybe<Scalars['String']>;
  onboarding: Maybe<IOnboarding>;
  onboardingStatus: Maybe<IProcessStatusType>;
  posts: Array<IPost>;
  postsCount: Scalars['Int'];
  preferences: IUserPreferences;
  previousCompany: Maybe<ICompany>;
  primaryGroup: Maybe<IGroup>;
  productStackRequest: Maybe<IProductStackRequest>;
  projects: Maybe<IProjects>;
  projectsCount: Scalars['Int'];
  role: Maybe<Scalars['String']>;
  savedCollectionsCount: Scalars['Int'];
  savedCount: Scalars['Int'];
  savedPostsCount: Scalars['Int'];
  savedProductsCount: Scalars['Int'];
  savedProjectsCount: Scalars['Int'];
  savedStoriesCount: Scalars['Int'];
  searchable: Maybe<Scalars['Boolean']>;
  showOnboardingBanner: Scalars['Boolean'];
  signInAttempts: Maybe<Array<ISignInAttempt>>;
  slug: Scalars['String'];
  socialMedia: Maybe<IUserSocialMediaLinks>;
  stories: Maybe<IStories>;
  storiesCount: Maybe<Scalars['Int']>;
  subscribedToCampaign: Maybe<Scalars['Boolean']>;
  subscribedToNewsletter: Maybe<Scalars['Boolean']>;
  timeZone: Maybe<Scalars['String']>;
  upvotes: Maybe<IUpvotes>;
  upvotesCount: Maybe<Scalars['Int']>;
  verification: Maybe<IVerification>;
  verified: Scalars['Boolean'];
  viewerCouldNotJoinReason: Maybe<Scalars['String']>;
};


export type IPendingUserAvatarArgs = {
  height: InputMaybe<Scalars['Int']>;
  width: InputMaybe<Scalars['Int']>;
};


export type IPendingUserPostsArgs = {
  audience: InputMaybe<IPostAudience>;
};


export type IPendingUserPostsCountArgs = {
  audience: InputMaybe<IPostAudience>;
};


export type IPendingUserProjectsArgs = {
  audience: InputMaybe<IProjectAudience>;
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
};


export type IPendingUserStoriesArgs = {
  audience: InputMaybe<IStoryAudience>;
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
};


export type IPendingUserStoriesCountArgs = {
  audience: InputMaybe<IStoryAudience>;
};


export type IPendingUserUpvotesArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
};

export type IPlan = {
  __typename: 'Plan';
  _id: Scalars['ObjectId'];
  displayName: Scalars['String'];
  isSelectable: Scalars['Boolean'];
  limits: IPlanLimits;
  name: Scalars['String'];
  stripePlan: Maybe<IStripePlan>;
  stripePlanId: Maybe<Scalars['String']>;
  tier: IPlanTier;
  vendorCount: Scalars['Int'];
};

export type IPlanLimits = {
  __typename: 'PlanLimits';
  enrichedProducts: Scalars['Int'];
  enrichedProfile: Scalars['Boolean'];
  groupInvitations: Scalars['Int'];
  groups: Scalars['Int'];
  inviteGovernments: Scalars['Boolean'];
  productTags: Scalars['Int'];
  products: Scalars['Int'];
  projectsPerMonth: Scalars['Int'];
  searchAccess: Scalars['Boolean'];
  sharingPage: Scalars['Boolean'];
  teamMembers: Scalars['Int'];
};

export enum IPlanRecurrency {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export enum IPlanTier {
  Basic = 'BASIC',
  Disruptor = 'DISRUPTOR',
  Makers = 'MAKERS',
  Makerspro = 'MAKERSPRO'
}

export type IPlanUsage = {
  __typename: 'PlanUsage';
  enrichedProducts: Scalars['Int'];
  groupInvitations: Scalars['Int'];
  groups: Scalars['Int'];
  products: Scalars['Int'];
  productsWithMultipleTags: Scalars['Int'];
  projectsThisMonth: Scalars['Int'];
  sharingPage: Scalars['Boolean'];
  teamMembers: Scalars['Int'];
};

export type IPlayStoreProductDeployment = {
  __typename: 'PlayStoreProductDeployment';
  linkHref: Scalars['String'];
};

export type IPodcastBlockData = IBlockData & {
  __typename: 'PodcastBlockData';
  backgroundColor: Maybe<Scalars['String']>;
  itemLimit: Maybe<Scalars['Int']>;
  paddingBottom: Maybe<Scalars['Int']>;
  paddingLeft: Maybe<Scalars['Int']>;
  paddingRight: Maybe<Scalars['Int']>;
  paddingTop: Maybe<Scalars['Int']>;
  podcastSrc: Scalars['String'];
  title: Scalars['String'];
  titleVisible: Scalars['Boolean'];
  type: IBlockLayoutType;
};

export type IPodcastPageConfig = {
  __typename: 'PodcastPageConfig';
  amazonmusic: Maybe<Scalars['URL']>;
  apple: Maybe<Scalars['URL']>;
  backgroundColor: Scalars['String'];
  deezer: Maybe<Scalars['URL']>;
  description: Scalars['String'];
  descriptionColor: Scalars['String'];
  facebook: Maybe<Scalars['URL']>;
  googleplay: Maybe<Scalars['URL']>;
  headerText: Scalars['String'];
  headerTextColor: Scalars['String'];
  iheartradio: Maybe<Scalars['URL']>;
  instagram: Maybe<Scalars['URL']>;
  overcast: Maybe<Scalars['URL']>;
  pandora: Maybe<Scalars['URL']>;
  podcastHeight: Scalars['Int'];
  podcastSrc: Scalars['URL'];
  soundcloud: Maybe<Scalars['URL']>;
  spotify: Maybe<Scalars['URL']>;
  stitcher: Maybe<Scalars['URL']>;
  twitter: Maybe<Scalars['URL']>;
  youtube: Maybe<Scalars['URL']>;
};

export type IPodcastPageConfigInput = {
  amazonmusic: InputMaybe<Scalars['URL']>;
  apple: InputMaybe<Scalars['URL']>;
  backgroundColor: Scalars['String'];
  deezer: InputMaybe<Scalars['URL']>;
  description: Scalars['String'];
  descriptionColor: Scalars['String'];
  facebook: InputMaybe<Scalars['URL']>;
  googleplay: InputMaybe<Scalars['URL']>;
  headerText: Scalars['String'];
  headerTextColor: Scalars['String'];
  iheartradio: InputMaybe<Scalars['URL']>;
  instagram: InputMaybe<Scalars['URL']>;
  overcast: InputMaybe<Scalars['URL']>;
  pandora: InputMaybe<Scalars['URL']>;
  podcastHeight: Scalars['Int'];
  podcastSrc: Scalars['URL'];
  soundcloud: InputMaybe<Scalars['URL']>;
  spotify: InputMaybe<Scalars['URL']>;
  stitcher: InputMaybe<Scalars['URL']>;
  twitter: InputMaybe<Scalars['URL']>;
  youtube: InputMaybe<Scalars['URL']>;
};

export type IPost = IGovernmentPost | IGroupPost | IVendorPost;

export type IPostAttachment = {
  __typename: 'PostAttachment';
  filename: Scalars['String'];
  mimeType: Scalars['String'];
  sizeInBytes: Scalars['Int'];
  url: Scalars['String'];
};

export type IPostAttachmentInput = {
  filename: Scalars['String'];
  mimeType: Scalars['String'];
  sizeInBytes: Scalars['Int'];
  url: Scalars['String'];
};

export enum IPostAudience {
  Community = 'COMMUNITY',
  Public = 'PUBLIC'
}

export type IPostInput = {
  attachments: Array<IPostAttachmentInput>;
  audience: Scalars['String'];
  body: Scalars['String'];
  links: Array<IPostLinkInput>;
};

export type IPostInterface = {
  _id: Scalars['ObjectId'];
  attachments: Array<IPostAttachment>;
  audience: Scalars['String'];
  author: IUser;
  body: Scalars['String'];
  commentsCount: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deletedAt: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<IUser>;
  featuredAt: Maybe<Scalars['DateTime']>;
  links: Array<IPostLink>;
  upvotesCount: Scalars['Int'];
  viewerCanComment: Scalars['Boolean'];
  viewerCanDelete: Scalars['Boolean'];
  viewerCanEdit: Scalars['Boolean'];
  viewerDidBookmark: Scalars['Boolean'];
  viewerDidComment: Scalars['Boolean'];
  viewerDidUpvote: Scalars['Boolean'];
  viewerIsAuthor: Scalars['Boolean'];
};

export type IPostLink = {
  __typename: 'PostLink';
  addedAt: Maybe<Scalars['Float']>;
  description: Maybe<Scalars['String']>;
  favicon: Maybe<Scalars['String']>;
  siteName: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type IPostLinkInput = {
  addedAt: InputMaybe<Scalars['Float']>;
  description: InputMaybe<Scalars['String']>;
  favicon: InputMaybe<Scalars['String']>;
  siteName: InputMaybe<Scalars['String']>;
  title: InputMaybe<Scalars['String']>;
  url: InputMaybe<Scalars['String']>;
};

export type IPosts = {
  __typename: 'Posts';
  items: Maybe<Array<IPosts>>;
  pageInfo: Maybe<IPageInfo>;
};

export enum IProcessStatusType {
  AdditionalInformationRequired = 'ADDITIONAL_INFORMATION_REQUIRED',
  PendingVerification = 'PENDING_VERIFICATION',
  Required = 'REQUIRED',
  Verified = 'VERIFIED'
}

export type IProduct = {
  __typename: 'Product';
  _id: Scalars['ObjectId'];
  about: Maybe<Scalars['String']>;
  allowRequestTranslation: Maybe<Scalars['Boolean']>;
  alternatives: Maybe<Array<IProduct>>;
  archivedAt: Maybe<Scalars['DateTime']>;
  archivedBy: Maybe<IUser>;
  assistiveTechnologiesCompatibility: Array<IAssistiveTechnology>;
  canBeDisruptor: Scalars['Boolean'];
  canBeEnrichedProduct: Scalars['Boolean'];
  certifications: Array<Scalars['String']>;
  commentsCount: Maybe<Scalars['Int']>;
  company: Maybe<ICompany>;
  contactPage: Maybe<Scalars['URL']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deployment: Array<IProductDeployment>;
  description: Maybe<Scalars['String']>;
  enrichedProfile: Scalars['Boolean'];
  featuredAt: Maybe<Scalars['DateTime']>;
  freeTrial: Maybe<IProductFreeTrial>;
  governments: Maybe<IGovernments>;
  governmentsUsingCount: Maybe<Scalars['Int']>;
  highlights: Array<Scalars['String']>;
  humanReadableReleasedYear: Maybe<Scalars['String']>;
  inViewerStack: Maybe<Scalars['Boolean']>;
  integrations: Maybe<Array<IProduct>>;
  isDisruptor: Maybe<Scalars['Boolean']>;
  languagesOffered: Maybe<Scalars['String']>;
  link: Maybe<Scalars['String']>;
  logo: Maybe<Scalars['String']>;
  name: Scalars['String'];
  newsworthy: Array<INewsworthyItem>;
  openSource: Maybe<IProductOpenSource>;
  outsideIntegrations: Maybe<Array<IOutsideProduct>>;
  ownerless: Maybe<Scalars['Boolean']>;
  pricingPage: Maybe<Scalars['URL']>;
  projects: Array<IProject>;
  projectsCount: Scalars['Int'];
  projectsUsingCount: Maybe<Scalars['Int']>;
  releasedYear: Maybe<Scalars['String']>;
  releasedYearTimeAgo: Maybe<Scalars['String']>;
  resources: Maybe<Array<IFlatResource>>;
  screenshots: Maybe<Array<IImage>>;
  siblings: Maybe<Array<IProduct>>;
  slug: Scalars['String'];
  socialMedia: Maybe<IProductSocialMediaLinks>;
  solutions: Array<ISolution>;
  specialDesignations: Maybe<Scalars['String']>;
  status: Maybe<IProductStatus>;
  stories: Maybe<Array<IStory>>;
  storiesCount: Scalars['Int'];
  tags: Array<ITag>;
  thumbnail: Maybe<Scalars['String']>;
  tier: Scalars['Int'];
  updatedAt: Maybe<Scalars['DateTime']>;
  upvotesCount: Scalars['Int'];
  user: Maybe<IVendorUser>;
  videos: Maybe<Array<Scalars['String']>>;
  viewerDidBookmark: Scalars['Boolean'];
  viewerDidUpvote: Scalars['Boolean'];
};


export type IProductCompanyArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IProductGovernmentsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<ISortType>;
};


export type IProductThumbnailArgs = {
  height: InputMaybe<Scalars['Int']>;
  width: InputMaybe<Scalars['Int']>;
};

export type IProductDeployment = IAppStoreProductDeployment | IDesktopProductDeployment | IPlayStoreProductDeployment | IWebProductDeployment;

export type IProductDeploymentInput = {
  linkHref: InputMaybe<Scalars['String']>;
  mobileResponsive: InputMaybe<Scalars['Boolean']>;
  platforms: InputMaybe<Array<Scalars['String']>>;
  supportedBrowsers: InputMaybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};

export enum IProductFilter {
  All = 'ALL',
  Archived = 'ARCHIVED',
  Disruptors = 'DISRUPTORS',
  Regular = 'REGULAR',
  Submitted = 'SUBMITTED',
  Untagged = 'UNTAGGED'
}

export type IProductFreeTrial = {
  __typename: 'ProductFreeTrial';
  linkHref: Scalars['URL'];
};

export type IProductInput = {
  about: InputMaybe<Scalars['String']>;
  allowRequestTranslation: Scalars['Boolean'];
  assistiveTechnologiesCompatibility: InputMaybe<Array<IAssistiveTechnology>>;
  certifications: InputMaybe<Array<Scalars['String']>>;
  contactPage: InputMaybe<Scalars['URL']>;
  deployment: InputMaybe<Array<IProductDeploymentInput>>;
  description: Scalars['String'];
  freeTrial: InputMaybe<Scalars['URL']>;
  highlights: InputMaybe<Array<Scalars['String']>>;
  integrations: InputMaybe<Array<Scalars['ObjectId']>>;
  languagesOffered: InputMaybe<Scalars['String']>;
  link: Scalars['String'];
  logo: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  newsworthy: InputMaybe<Array<Scalars['JSON']>>;
  openSource: InputMaybe<Scalars['URL']>;
  outsideIntegrations: InputMaybe<Array<IOutsideProductInput>>;
  outsideProducts: InputMaybe<Array<Scalars['ObjectId']>>;
  pricingPage: InputMaybe<Scalars['URL']>;
  releasedYear: InputMaybe<Scalars['String']>;
  resources: InputMaybe<Array<IResourceInput>>;
  screenshots: InputMaybe<Array<IImageInput>>;
  socialMedia: InputMaybe<IProductSocialMediaLinksInput>;
  solutions: Array<Scalars['ObjectId']>;
  tags: InputMaybe<Array<Scalars['ObjectId']>>;
  videos: InputMaybe<Array<Scalars['String']>>;
};

export type IProductOpenSource = {
  __typename: 'ProductOpenSource';
  linkHref: Scalars['String'];
};

export enum IProductSearchScope {
  Basic = 'BASIC',
  Disruptor = 'DISRUPTOR',
  Makers = 'MAKERS',
  VendorIsDisruptor = 'VENDOR_IS_DISRUPTOR'
}

export type IProductSocialMediaLinks = {
  __typename: 'ProductSocialMediaLinks';
  facebook: Maybe<Scalars['URL']>;
  instagram: Maybe<Scalars['URL']>;
  linkedin: Maybe<Scalars['URL']>;
  twitter: Maybe<Scalars['URL']>;
  youtube: Maybe<Scalars['URL']>;
};

export type IProductSocialMediaLinksInput = {
  facebook: InputMaybe<Scalars['URL']>;
  instagram: InputMaybe<Scalars['URL']>;
  linkedin: InputMaybe<Scalars['URL']>;
  twitter: InputMaybe<Scalars['URL']>;
  youtube: InputMaybe<Scalars['URL']>;
};

export type IProductStackRequest = {
  __typename: 'ProductStackRequest';
  _id: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  government: IGovernment;
  inviter: IUser;
  possibleGovernments: Array<IGovernment>;
  product: IProduct;
  respondedBy: Maybe<IUser>;
  status: IProductStackRequestStatus;
  vendor: ICompany;
};

export enum IProductStackRequestStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export enum IProductStatus {
  Approved = 'APPROVED',
  Submitted = 'SUBMITTED'
}

export enum IProductStatusFilter {
  All = 'ALL',
  Approved = 'APPROVED',
  Submitted = 'SUBMITTED'
}

export type IProducts = {
  __typename: 'Products';
  items: Maybe<Array<IProduct>>;
  pageInfo: Maybe<IPageInfo>;
};

export type IProductsAboutPageSection = {
  __typename: 'ProductsAboutPageSection';
  products: Array<IProduct>;
};

export type IProductsCount = {
  __typename: 'ProductsCount';
  allCount: Maybe<Scalars['Int']>;
  archivedCount: Maybe<Scalars['Int']>;
  disruptorsCount: Maybe<Scalars['Int']>;
  submittedCount: Maybe<Scalars['Int']>;
  untaggedCount: Maybe<Scalars['Int']>;
};

export enum IProfileCoverTarget {
  Company = 'COMPANY',
  Government = 'GOVERNMENT',
  User = 'USER'
}

export type IProject = {
  _id: Scalars['ObjectId'];
  archivedAt: Maybe<Scalars['DateTime']>;
  archivedBy: Maybe<IUser>;
  attachments: Array<IProjectAttachmentItem>;
  audience: Scalars['String'];
  author: Maybe<IUser>;
  awards: Array<ITag>;
  collaborators: Array<IUser>;
  commentsCount: Maybe<Scalars['Int']>;
  countryCode: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  groups: Array<IGroup>;
  icon: Maybe<Scalars['String']>;
  itemsCount: IProjectItemsCount;
  links: Array<IProjectLinkItem>;
  locations: Array<IGovernment>;
  locationsPendingVerification: Array<IGovernment>;
  moreFromGovernment: Array<IGovernmentProject>;
  newsworthy: Array<IProjectNewsworthyItem>;
  outcome: Maybe<Scalars['String']>;
  outcomeStatement: Maybe<Scalars['String']>;
  outsideProducts: Array<IOutsideProduct>;
  products: Array<IProduct>;
  relatedProjects: Array<IProject>;
  slug: Scalars['String'];
  status: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  tags: Array<ITag>;
  title: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
  upvotesCount: Scalars['Int'];
  vendors: Array<ICompany>;
  verified: Scalars['Boolean'];
  verifiedBy: Maybe<IUser>;
  viewerCanComment: Scalars['Boolean'];
  viewerCanDelete: Scalars['Boolean'];
  viewerCanEdit: Scalars['Boolean'];
  viewerCanInviteCollaborators: Scalars['Boolean'];
  viewerCanRead: Scalars['Boolean'];
  viewerCanRemoveCollaborators: Scalars['Boolean'];
  viewerCanView: Scalars['Boolean'];
  viewerDidBookmark: Scalars['Boolean'];
  viewerDidComment: Scalars['Boolean'];
  viewerDidUpvote: Scalars['Boolean'];
  viewerIsAuthor: Scalars['Boolean'];
};


export type IProjectCollaboratorsArgs = {
  token: InputMaybe<Scalars['String']>;
};


export type IProjectDescriptionArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IProjectNewsworthyArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IProjectSubtitleArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IProjectTitleArgs = {
  language: InputMaybe<Scalars['String']>;
};

export type IProjectAttachmentItem = {
  __typename: 'ProjectAttachmentItem';
  addedAt: Maybe<Scalars['Float']>;
  mimeType: Maybe<Scalars['String']>;
  size: Maybe<Scalars['Float']>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export enum IProjectAudience {
  Community = 'COMMUNITY',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type IProjectGovernmentVerificationRequest = IGovernmentVerificationRequest & {
  __typename: 'ProjectGovernmentVerificationRequest';
  _id: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  government: IGovernment;
  project: IProject;
  respondedAt: Maybe<Scalars['DateTime']>;
  respondedBy: Maybe<IUser>;
  status: IGovernmentVerificationRequestStatus;
};

export type IProjectInput = {
  attachments: Array<Scalars['JSON']>;
  audience: IProjectAudience;
  awards: Array<Scalars['ObjectId']>;
  description: Scalars['String'];
  groups: Array<Scalars['ObjectId']>;
  links: Array<Scalars['JSON']>;
  locations: Array<Scalars['ObjectId']>;
  newsworthy: Array<Scalars['JSON']>;
  outcome: InputMaybe<Scalars['String']>;
  outcomeStatement: InputMaybe<Scalars['String']>;
  outsideProducts: Array<IOutsideProductInput>;
  products: Array<Scalars['JSON']>;
  status: InputMaybe<Scalars['String']>;
  subtitle: InputMaybe<Scalars['String']>;
  tags: Array<Scalars['ObjectId']>;
  title: Scalars['String'];
  vendors: Array<Scalars['JSON']>;
};

export type IProjectItemsCount = {
  __typename: 'ProjectItemsCount';
  attachments: Scalars['Int'];
  links: Scalars['Int'];
  locations: Scalars['Int'];
  newsworthy: Scalars['Int'];
  outsideProducts: Scalars['Int'];
  products: Scalars['Int'];
  vendors: Scalars['Int'];
};

export type IProjectLinkItem = {
  __typename: 'ProjectLinkItem';
  addedAt: Maybe<Scalars['Float']>;
  description: Maybe<Scalars['String']>;
  displayTitle: Maybe<Scalars['String']>;
  favicon: Maybe<Scalars['String']>;
  siteName: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type IProjectNewsworthyItem = {
  __typename: 'ProjectNewsworthyItem';
  addedAt: Maybe<Scalars['Float']>;
  description: Maybe<Scalars['String']>;
  favicon: Maybe<Scalars['String']>;
  siteName: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export enum IProjectType {
  GovernmentProject = 'GOVERNMENT_PROJECT',
  GroupProject = 'GROUP_PROJECT',
  VendorProject = 'VENDOR_PROJECT'
}

export type IProjects = {
  __typename: 'Projects';
  items: Maybe<Array<IProject>>;
  pageInfo: Maybe<IPageInfo>;
};

export type IProjectsAboutPageSection = {
  __typename: 'ProjectsAboutPageSection';
  projects: Array<IProject>;
};

export type IProjectsCount = {
  __typename: 'ProjectsCount';
  allCount: Scalars['Int'];
  archivedCount: Scalars['Int'];
  governmentProjectsCount: Scalars['Int'];
  groupProjectsCount: Scalars['Int'];
  userCreatedCount: Scalars['Int'];
  vendorProjectsCount: Scalars['Int'];
};

export type IQuery = {
  __typename: 'Query';
  aboutPage: Maybe<IAboutPage>;
  activeProductsCount: Scalars['Int'];
  allAboutPageConfigs: Array<IAboutPageConfig>;
  allCollectionSponsoringSalesCounts: ICollectionSponsoringSaleCounts;
  allGovernmentVerificationRequests: Maybe<IGovernmentVerificationRequests>;
  allGovernments: Maybe<IGovernments>;
  allGroupRequests: Array<IGroupRequest>;
  allLandingPageConfigs: Maybe<ILandingPageConfigs>;
  allPlans: Array<IPlan>;
  allPosts: Array<IPost>;
  allProductStackRequests: Array<IProductStackRequest>;
  allProducts: Maybe<IProducts>;
  allReports: Maybe<IReports>;
  /** If `filterByStatus` is not specified, then only SUBMITTED and APPROVED solutions will be returned. */
  allSolutions: Array<ISolution>;
  allSponsorships: Maybe<ISponsorships>;
  allStories: Maybe<IStories>;
  allUsers: Maybe<IUsers>;
  allVendorCredits: Array<IVendorCredit>;
  allVendors: Maybe<IVendors>;
  autoCompleteAvailableGovernments: Array<IGovernmentTuple>;
  autoCompleteCollections: Array<ICollection>;
  autoCompleteGovernments: Array<IGovernment>;
  autoCompleteGroups: Array<IGroup>;
  autoCompletePaidOrDisruptorVendors: Array<ICompany>;
  autoCompleteProducts: Array<IProduct>;
  autoCompleteProjects: Array<IProject>;
  autoCompleteStories: Array<IStory>;
  autoCompleteUsers: Array<IUser>;
  autoCompleteVendors: Array<ICompany>;
  availablePlans: Array<IPlan>;
  availableStatesWithCountries: Array<ICountryStateTuple>;
  buildNewsletterCampaignData: Maybe<INewsletterCampaignData>;
  cities: Maybe<Array<ICity>>;
  collectionBySlug: Maybe<ICollection>;
  collectionsCounters: ICollectionsCounters;
  comments: Maybe<IComments>;
  company: Maybe<ICompany>;
  connect: IConnect;
  duplicatedUsers: IDuplicatedUsers;
  externalLinks: Maybe<IExternalLinks>;
  getAboutPageById: Maybe<IAboutPageConfig>;
  getActiveAboutPageConfig: IAboutPageConfig;
  getActiveLandingPageConfig: Maybe<ILandingPageConfig>;
  getAllTimezones: Maybe<ICountryTimezones>;
  getDepartmentRelatedTags: Array<ITag>;
  getDynamicConfigs: Array<IDynamicConfig>;
  getExternalLinkById: Maybe<IExternalLink>;
  getIsOnboardingActive: Scalars['Boolean'];
  getLandingPageEventById: Maybe<ILandingPageEvent>;
  getMissingTimezones: Maybe<Array<Scalars['String']>>;
  getNewProjects: Maybe<IProjects>;
  getNewsletterCampaign: Maybe<INewsletterCampaign>;
  getNewsletterSettings: INewsletterSettings;
  getPlanById: Maybe<IPlan>;
  getTimezoneByCountryCode: Maybe<ICountryTimezone>;
  getUpcomingEvents: Array<ILandingPageEvent>;
  globalSettings: IGlobalSettings;
  government: Maybe<IGovernment>;
  governmentById: Maybe<IGovernment>;
  /** Is for retrieving a breakdown of the number of Governments by Country. */
  governmentCountriesBreakdown: Array<IGovernmentCountriesBreakdownItem>;
  governmentTypes: Array<Scalars['String']>;
  governmentsCount: Maybe<IGovernmentsCount>;
  govlaunchStatsCount: IGovlaunchStatsCountData;
  groupBySlug: Maybe<IGroup>;
  groups: Maybe<IGroups>;
  groupsCount: Maybe<IGroupsCount>;
  images: Maybe<IImages>;
  imagesCount: Maybe<IImagesCount>;
  innovationProjectsCount: Scalars['Int'];
  isTranslationEnabled: Maybe<Scalars['Boolean']>;
  landingPageConfig: Maybe<ILandingPageConfig>;
  landingPageEvents: Maybe<ILandingPageEvents>;
  listAllCollectionSponsoringSales: Array<ICollectionSponsoringSale>;
  listAllCollections: ICollectionsPagination;
  listAllTags: Array<ITag>;
  listNewsletterCampaigns: Array<INewsletterCampaign>;
  listOnboardings: Maybe<IOnboardings>;
  listTagsBySlugs: Array<ITag>;
  newsletterReceivers: Maybe<IUsers>;
  onboarding: Maybe<IOnboarding>;
  onboardingProcessStatusCounts: IOnboardingProcessStatusCounts;
  outsideProducts: Maybe<IOutsideProducts>;
  outsideProductsCount: Maybe<IOutsideProductsCount>;
  ping: Maybe<Scalars['String']>;
  plan: IPlan;
  plans: Array<IPlan>;
  podcastBySlug: ISimplecast;
  podcastPage: IPodcastPageConfig;
  postById: Maybe<IPost>;
  postsByVendor: Maybe<IVendorPosts>;
  product: Maybe<IProduct>;
  products: Maybe<IProducts>;
  productsCount: Maybe<IProductsCount>;
  projectBySlug: Maybe<IProject>;
  projectsByGovernment: Maybe<IProjects>;
  projectsByIds: Maybe<IProjects>;
  projectsByVendor: Maybe<IProjects>;
  projectsCount: IProjectsCount;
  recentlyJoined: Array<IUser>;
  recentlyJoinedGovernments: Array<IGovernment>;
  reportById: Maybe<IReport>;
  reportBySlug: Maybe<IReport>;
  searchProducts: Maybe<IProducts>;
  searchProjects: Maybe<IProjects>;
  searchStories: Maybe<IStories>;
  selfie: Maybe<IUser>;
  sponsorship: Maybe<ISponsorship>;
  sponsorshipsCount: ISponsorshipsCount;
  storiesByIds: Maybe<Array<IStory>>;
  storiesByVendor: IStories;
  storiesCount: IStoriesCount;
  storyBySlug: Maybe<IStory>;
  stripePlans: Array<IStripePlan>;
  tag: Maybe<ITag>;
  tagsCount: Maybe<ITagsCount>;
  topCollections: Array<ICollection>;
  topInnovatorGovernments: Array<IGovernment>;
  trendingCollections: Array<ICollection>;
  trendingGovernments: Array<IGovernment>;
  user: Maybe<IUser>;
  usersCount: IUsersCount;
  vendorsCount: Maybe<IVendorsCount>;
};


export type IQueryAllGovernmentVerificationRequestsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  filterByGovernment: InputMaybe<Scalars['ObjectId']>;
  filterByStatus: InputMaybe<IGovernmentVerificationRequestStatus>;
  limit?: InputMaybe<Scalars['Int']>;
  searchQuery: InputMaybe<Scalars['String']>;
  searchTarget: InputMaybe<IGovernmentVerificationRequestSearchTarget>;
  sortBy?: InputMaybe<IGovernmentVerificationRequestSortBy>;
  sortDirection?: InputMaybe<IGovernmentVerificationRequestSortByDirection>;
};


export type IQueryAllGovernmentsArgs = {
  country: InputMaybe<Scalars['String']>;
  cursor: InputMaybe<Scalars['Cursor']>;
  filter: InputMaybe<Scalars['String']>;
  hasOwner: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  sortBy: InputMaybe<Scalars['String']>;
  state: InputMaybe<Array<Scalars['String']>>;
  tags: InputMaybe<Array<Scalars['ObjectId']>>;
  type: InputMaybe<Array<Scalars['String']>>;
};


export type IQueryAllLandingPageConfigsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
};


export type IQueryAllProductsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<IProductFilter>;
  inTiers: InputMaybe<Array<Scalars['Int']>>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  sort: InputMaybe<Scalars['String']>;
  tags: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type IQueryAllReportsArgs = {
  filter: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<ISortType>;
};


export type IQueryAllSolutionsArgs = {
  filterByStatus: InputMaybe<ISolutionStatus>;
  searchText: InputMaybe<Scalars['String']>;
};


export type IQueryAllSponsorshipsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<IAllSponsorshipsFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  multiSearch: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search: InputMaybe<Scalars['String']>;
  sortBy: InputMaybe<Scalars['String']>;
};


export type IQueryAllStoriesArgs = {
  audience: InputMaybe<IStoryAudience>;
  country: InputMaybe<Scalars['String']>;
  cursor: InputMaybe<Scalars['Cursor']>;
  featured: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  sort: InputMaybe<Scalars['String']>;
  status: InputMaybe<IStoryStatus>;
  tab: InputMaybe<Scalars['String']>;
  tags: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type IQueryAllUsersArgs = {
  company: InputMaybe<Scalars['ObjectId']>;
  cursor: InputMaybe<Scalars['Cursor']>;
  filter: InputMaybe<Scalars['String']>;
  government: InputMaybe<Scalars['ObjectId']>;
  limit?: InputMaybe<Scalars['Int']>;
  plan: InputMaybe<Scalars['String']>;
  search: InputMaybe<Scalars['String']>;
  sortBy: InputMaybe<Scalars['String']>;
  tags: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type IQueryAllVendorsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  filter: IAllVendorsFilterInput;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy: InputMaybe<Scalars['String']>;
};


export type IQueryAutoCompleteAvailableGovernmentsArgs = {
  country: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  searchQuery: Scalars['String'];
  state: Scalars['String'];
};


export type IQueryAutoCompleteCollectionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  searchQuery: Scalars['String'];
};


export type IQueryAutoCompleteGovernmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  scopes: Array<IGovernmentSearchScope>;
  searchQuery: Scalars['String'];
};


export type IQueryAutoCompleteGroupsArgs = {
  groupKinds: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  searchQuery: Scalars['String'];
};


export type IQueryAutoCompletePaidOrDisruptorVendorsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  searchQuery: Scalars['String'];
};


export type IQueryAutoCompleteProductsArgs = {
  filterByVendor: InputMaybe<Scalars['ObjectId']>;
  limit?: InputMaybe<Scalars['Int']>;
  scopes: Array<IProductSearchScope>;
  searchQuery: Scalars['String'];
};


export type IQueryAutoCompleteProjectsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  searchQuery: Scalars['String'];
};


export type IQueryAutoCompleteStoriesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  searchQuery: Scalars['String'];
};


export type IQueryAutoCompleteUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  scopes: Array<IUserSearchScope>;
  searchQuery: Scalars['String'];
};


export type IQueryAutoCompleteVendorsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  scopes: Array<IVendorSearchScope>;
  searchQuery: Scalars['String'];
};


export type IQueryBuildNewsletterCampaignDataArgs = {
  campaignId: Scalars['ObjectId'];
};


export type IQueryCitiesArgs = {
  state: Scalars['String'];
};


export type IQueryCollectionBySlugArgs = {
  slug: Scalars['String'];
};


export type IQueryCommentsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
  targetId: Scalars['ObjectId'];
  targetType: ICommentTargetType;
};


export type IQueryCompanyArgs = {
  language: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};


export type IQueryConnectArgs = {
  beforeThisId: InputMaybe<Scalars['ObjectId']>;
  excludeUser: InputMaybe<Scalars['ObjectId']>;
  limit?: Scalars['Int'];
  targetId: InputMaybe<Scalars['ObjectId']>;
  targetType: InputMaybe<IConnectTargetType>;
};


export type IQueryDuplicatedUsersArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
};


export type IQueryExternalLinksArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
};


export type IQueryGetAboutPageByIdArgs = {
  configId: Scalars['ObjectId'];
};


export type IQueryGetActiveAboutPageConfigArgs = {
  configId: InputMaybe<Scalars['ObjectId']>;
};


export type IQueryGetActiveLandingPageConfigArgs = {
  id: InputMaybe<Scalars['ObjectId']>;
};


export type IQueryGetAllTimezonesArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  filters: IGetAllTimezonesInput;
  limit?: InputMaybe<Scalars['Int']>;
};


export type IQueryGetDepartmentRelatedTagsArgs = {
  departmentId: Scalars['ObjectId'];
};


export type IQueryGetExternalLinkByIdArgs = {
  id: Scalars['ObjectId'];
};


export type IQueryGetLandingPageEventByIdArgs = {
  id: Scalars['ObjectId'];
};


export type IQueryGetNewProjectsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type IQueryGetNewsletterCampaignArgs = {
  campaignId: Scalars['ObjectId'];
};


export type IQueryGetPlanByIdArgs = {
  planId: Scalars['ObjectId'];
};


export type IQueryGetTimezoneByCountryCodeArgs = {
  countryCode: Scalars['String'];
};


export type IQueryGetUpcomingEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type IQueryGovernmentArgs = {
  slug: Scalars['String'];
};


export type IQueryGovernmentByIdArgs = {
  governmentId: InputMaybe<Scalars['ObjectId']>;
};


export type IQueryGovernmentsCountArgs = {
  filterByCountry: InputMaybe<Scalars['String']>;
};


export type IQueryGovlaunchStatsCountArgs = {
  settings: IGovlaunchStatsCountInput;
};


export type IQueryGroupBySlugArgs = {
  slug: Scalars['String'];
};


export type IQueryGroupsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<IGroupsFilter>;
  filterByKind: InputMaybe<IGroupKind>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  sort: InputMaybe<Scalars['String']>;
  tags: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type IQueryImagesArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  filter: IImagesFilterInput;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy: InputMaybe<Scalars['String']>;
};


export type IQueryIsTranslationEnabledArgs = {
  slug: Scalars['String'];
};


export type IQueryLandingPageConfigArgs = {
  id: Scalars['ObjectId'];
};


export type IQueryLandingPageEventsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  sort: InputMaybe<IFieldOrderSortInput>;
};


export type IQueryListAllCollectionSponsoringSalesArgs = {
  description: InputMaybe<Scalars['String']>;
};


export type IQueryListAllCollectionsArgs = {
  cursor: InputMaybe<Scalars['String']>;
  filterByFeatured?: InputMaybe<ICollectionFeaturedStatus>;
  filterByPackaging: InputMaybe<ICollectionPackaging>;
  filterByPublishedStatus?: InputMaybe<ICollectionPublishedStatus>;
  filterBySponsoring: InputMaybe<ICollectionSponsoring>;
  filterByStatus?: InputMaybe<ICollectionStatus>;
  filterByViewerBookmarkStatus: InputMaybe<ICollectionBookmarkStatus>;
  limit?: InputMaybe<Scalars['Int']>;
  searchQuery: InputMaybe<Scalars['String']>;
};


export type IQueryListAllTagsArgs = {
  archivedOnly?: InputMaybe<Scalars['Boolean']>;
  filterByTagType: InputMaybe<ITagType>;
  search: InputMaybe<Scalars['String']>;
};


export type IQueryListOnboardingsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  filterByProcessStatus: IProcessStatusType;
  search: InputMaybe<Scalars['String']>;
  sortBy: InputMaybe<Scalars['String']>;
};


export type IQueryListTagsBySlugsArgs = {
  slugs: InputMaybe<Array<Scalars['String']>>;
};


export type IQueryNewsletterReceiversArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  mode?: InputMaybe<IGovlaunchEnvironment>;
  search: InputMaybe<Scalars['String']>;
};


export type IQueryOnboardingArgs = {
  userId: Scalars['ObjectId'];
};


export type IQueryOutsideProductsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  sort: InputMaybe<Scalars['String']>;
  status: InputMaybe<IOutsideProductStatus>;
};


export type IQueryPlanArgs = {
  name: Scalars['String'];
};


export type IQueryPodcastBySlugArgs = {
  slug: Scalars['String'];
};


export type IQueryPostByIdArgs = {
  id: Scalars['ObjectId'];
};


export type IQueryPostsByVendorArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ObjectId'];
};


export type IQueryProductArgs = {
  language: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};


export type IQueryProductsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<IProductFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  sort: InputMaybe<Scalars['String']>;
  tags: InputMaybe<Array<Scalars['ObjectId']>>;
};


export type IQueryProjectBySlugArgs = {
  language: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};


export type IQueryProjectsByGovernmentArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
};


export type IQueryProjectsByIdsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  ids: Array<Scalars['ObjectId']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type IQueryProjectsByVendorArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  mentionedOnly: InputMaybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  sort: InputMaybe<IFieldOrderSortInput>;
  verification: InputMaybe<IVerificationInput>;
};


export type IQueryReportByIdArgs = {
  id: Scalars['String'];
};


export type IQueryReportBySlugArgs = {
  slug: Scalars['String'];
};


export type IQuerySearchProductsArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  name: InputMaybe<Scalars['String']>;
  tags: InputMaybe<Array<Scalars['String']>>;
};


export type IQuerySearchProjectsArgs = {
  archivedOnly?: InputMaybe<Scalars['Boolean']>;
  audience: InputMaybe<Scalars['String']>;
  country: InputMaybe<Scalars['String']>;
  cursor: InputMaybe<Scalars['Cursor']>;
  filterByType: InputMaybe<IProjectType>;
  limit?: InputMaybe<Scalars['Int']>;
  sort: InputMaybe<IFieldOrderSortInput>;
  state: InputMaybe<Scalars['String']>;
  status: InputMaybe<Array<Scalars['String']>>;
  tags: InputMaybe<Array<Scalars['String']>>;
  title: InputMaybe<Scalars['String']>;
  userCreated?: InputMaybe<Scalars['Boolean']>;
  verified: InputMaybe<Scalars['Boolean']>;
};


export type IQuerySearchStoriesArgs = {
  country: InputMaybe<Scalars['String']>;
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  state: InputMaybe<Scalars['String']>;
  tags: InputMaybe<Array<Scalars['String']>>;
  title: InputMaybe<Scalars['String']>;
};


export type IQuerySelfieArgs = {
  token: InputMaybe<Scalars['String']>;
};


export type IQuerySponsorshipArgs = {
  slug: Scalars['String'];
};


export type IQueryStoriesByIdsArgs = {
  ids: Array<Scalars['ObjectId']>;
};


export type IQueryStoriesByVendorArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
  vendorId: Scalars['ObjectId'];
};


export type IQueryStoryBySlugArgs = {
  language: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  token: InputMaybe<Scalars['String']>;
};


export type IQueryTagArgs = {
  slug: Scalars['String'];
};


export type IQueryTopCollectionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type IQueryTopInnovatorGovernmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type IQueryTrendingCollectionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type IQueryTrendingGovernmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type IQueryUserArgs = {
  slug: Scalars['String'];
};

export type IRecurringCollectionSponsoringSale = ICollectionSponsoringSale & {
  __typename: 'RecurringCollectionSponsoringSale';
  _id: Scalars['ObjectId'];
  archivedAt: Maybe<Scalars['DateTime']>;
  archivedBy: Maybe<IUser>;
  cancelsAt: Maybe<Scalars['Date']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  includedCollections: Array<ICollection>;
  isActive: Scalars['Boolean'];
  paymentSchedule: ICollectionSponsoringPaymentSchedule;
  price: Scalars['Int'];
  renewsAt: Maybe<Scalars['Date']>;
  sponsorshipLevel: ICollectionSponsoringSponsorshipLevel;
  stripeState: ICollectionSponsoringStripeState;
  type: ICollectionSponsoringType;
  updatedAt: Scalars['DateTime'];
  vendor: ICompany;
};

export type IReport = {
  __typename: 'Report';
  _id: Scalars['ObjectId'];
  archivedAt: Maybe<Scalars['DateTime']>;
  commentsCount: Maybe<Scalars['Int']>;
  cover: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  details: Maybe<Scalars['JSON']>;
  featured: Maybe<Scalars['Boolean']>;
  governments: Maybe<Array<IGovernment>>;
  images: Maybe<Array<Scalars['String']>>;
  locations: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
  title: Scalars['String'];
  unpublishedAt: Maybe<Scalars['DateTime']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  viewerDidComment: Scalars['Boolean'];
};

export type IReportInput = {
  description: InputMaybe<Scalars['String']>;
  details: InputMaybe<Scalars['JSON']>;
  governments: InputMaybe<Array<Scalars['String']>>;
  images: InputMaybe<Array<Scalars['String']>>;
  locations: InputMaybe<Array<Scalars['String']>>;
  title: InputMaybe<Scalars['String']>;
};

export type IReports = {
  __typename: 'Reports';
  items: Maybe<Array<IReport>>;
  pageInfo: Maybe<IPageInfo>;
};

export type IReportsAboutPageSection = {
  __typename: 'ReportsAboutPageSection';
  reports: Array<IReport>;
};

export type IRepositionState = {
  __typename: 'RepositionState';
  original: Maybe<ICoverImageDimensions>;
  proportional: Maybe<ICoverImageDimensions>;
  rect: Maybe<ICoverImageDimensions>;
  x: Maybe<Scalars['Float']>;
  y: Maybe<Scalars['Float']>;
};

export type IResourceInput = {
  addedAt: InputMaybe<Scalars['DateTime']>;
  body: InputMaybe<Scalars['String']>;
  filename: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
  url: InputMaybe<Scalars['String']>;
};

export type IScheduledToCancelSubscription = {
  __typename: 'ScheduledToCancelSubscription';
  _id: Scalars['ObjectId'];
  freeSubscription: Maybe<Scalars['Boolean']>;
  lastCharge: Maybe<ICharge>;
  pausePaymentCollection: Maybe<Scalars['Boolean']>;
  requestedCancellationAt: Scalars['DateTime'];
  stripeStatus: Maybe<Scalars['String']>;
  trialPeriod: Maybe<Scalars['Int']>;
  willCancelAt: Scalars['DateTime'];
};

export type ISideContent = ICarouselSideContent | ITextSideContent;

export type ISideContentInput = {
  buttonBackgroundColor: InputMaybe<Scalars['String']>;
  buttonFontColor: InputMaybe<Scalars['String']>;
  buttonHref: InputMaybe<Scalars['String']>;
  buttonText: InputMaybe<Scalars['String']>;
  height: InputMaybe<Scalars['Int']>;
  iconDescription: InputMaybe<Scalars['String']>;
  iconSrc: InputMaybe<Scalars['String']>;
  images: Array<ICarouselImageInput>;
  text: InputMaybe<Scalars['String']>;
  textFontColor: InputMaybe<Scalars['String']>;
  textHref: InputMaybe<Scalars['String']>;
  textHrefHoverColor: InputMaybe<Scalars['String']>;
  title: InputMaybe<Scalars['String']>;
  titleFontColor: InputMaybe<Scalars['String']>;
  width: InputMaybe<Scalars['Int']>;
};

export type ISignInAttempt = {
  __typename: 'SignInAttempt';
  ip: Maybe<Scalars['String']>;
  lookup: Maybe<Scalars['JSON']>;
  outcome: Maybe<Scalars['String']>;
  registeredAt: Maybe<Scalars['DateTime']>;
  userAgent: Maybe<Scalars['JSON']>;
  whitelisted: Maybe<Scalars['Boolean']>;
};

export type ISignInResult = {
  __typename: 'SignInResult';
  tokens: Maybe<ITokens>;
  user: Maybe<IUser>;
};

export type ISignedInUser = {
  __typename: 'SignedInUser';
  _id: Scalars['String'];
  lastSeen: Maybe<Scalars['DateTime']>;
  role: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type ISimplecast = {
  __typename: 'Simplecast';
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ISolution = {
  __typename: 'Solution';
  _id: Scalars['ObjectId'];
  archivedAt: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  originProduct: Maybe<IProduct>;
  slug: Scalars['String'];
  status: ISolutionStatus;
  submittedBy: IUser;
  updatedAt: Scalars['DateTime'];
};

export enum ISolutionStatus {
  Approved = 'APPROVED',
  Archived = 'ARCHIVED',
  Submitted = 'SUBMITTED'
}

export enum ISortType {
  Newest = 'NEWEST',
  Oldest = 'OLDEST',
  Top = 'TOP'
}

export type ISponsorship = {
  __typename: 'Sponsorship';
  _id: Scalars['ObjectId'];
  archivedAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  link: Maybe<Scalars['String']>;
  logo: Maybe<Scalars['String']>;
  modifiedAt: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  vendorsCount: Maybe<Scalars['Int']>;
};

export type ISponsorshipInput = {
  link: InputMaybe<Scalars['String']>;
  logo: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ISponsorships = {
  __typename: 'Sponsorships';
  items: Array<ISponsorship>;
  pageInfo: Maybe<IPageInfo>;
};

export type ISponsorshipsCount = {
  __typename: 'SponsorshipsCount';
  allCount: Maybe<Scalars['Int']>;
  archivedCount: Maybe<Scalars['Int']>;
};

export type IStatCountersBlockData = IBlockData & {
  __typename: 'StatCountersBlockData';
  backgroundColor: Scalars['String'];
  countersColor: Scalars['String'];
  itemLimit: Maybe<Scalars['Int']>;
  labelsColor: Scalars['String'];
  paddingBottom: Maybe<Scalars['Int']>;
  paddingLeft: Maybe<Scalars['Int']>;
  paddingRight: Maybe<Scalars['Int']>;
  paddingTop: Maybe<Scalars['Int']>;
  showGovernments: Scalars['Boolean'];
  showProducts: Scalars['Boolean'];
  showProjects: Scalars['Boolean'];
  showStories: Scalars['Boolean'];
  showVendors: Scalars['Boolean'];
  title: Scalars['String'];
  titleVisible: Scalars['Boolean'];
  type: IBlockLayoutType;
  widgetColor: Scalars['String'];
};

export type IStories = {
  __typename: 'Stories';
  items: Maybe<Array<IStory>>;
  pageInfo: Maybe<IPageInfo>;
};

export type IStoriesAboutPageSection = {
  __typename: 'StoriesAboutPageSection';
  stories: Array<IStory>;
};

export type IStoriesCount = {
  __typename: 'StoriesCount';
  allStories: Scalars['Int'];
  archivedStories: Scalars['Int'];
};

export type IStory = {
  __typename: 'Story';
  _id: Scalars['ObjectId'];
  archivedAt: Maybe<Scalars['DateTime']>;
  archivedBy: Maybe<IUser>;
  audience: Scalars['String'];
  author: Maybe<IUser>;
  awards: Array<ITag>;
  body: Maybe<Scalars['JSON']>;
  coauthors: Maybe<Array<IUser>>;
  collaborators: Array<IUser>;
  commentsCount: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['DateTime']>;
  featured: Maybe<Scalars['Boolean']>;
  government: Maybe<IGovernment>;
  hasPodcast: Scalars['Boolean'];
  highlights: Maybe<Array<Scalars['String']>>;
  horizontalCover: Maybe<Scalars['String']>;
  introduction: Maybe<Scalars['String']>;
  lastPublishedAt: Maybe<Scalars['DateTime']>;
  lastUpvoters: Maybe<Array<IUser>>;
  locations: Maybe<Array<IGovernment>>;
  medias: Maybe<Array<IMedia>>;
  modifiedAt: Maybe<Scalars['DateTime']>;
  newsworthy: Array<INewsworthyItem>;
  outsideProducts: Maybe<Array<IOutsideProduct>>;
  products: Maybe<Array<IProduct>>;
  /** @deprecated Not used anymore */
  projectAwards: Maybe<Array<IMetadataLink>>;
  relatedStories: Maybe<Array<IStory>>;
  resources: Maybe<Array<IFlatResource>>;
  slug: Scalars['String'];
  status: Maybe<IStoryStatus>;
  tags: Array<ITag>;
  title: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
  upvotesCount: Scalars['Int'];
  vendorCoauthors: Array<ICompany>;
  verticalCover: Maybe<Scalars['String']>;
  viewerCanComment: Scalars['Boolean'];
  viewerCanEdit: Scalars['Boolean'];
  viewerCanInviteCollaborators: Scalars['Boolean'];
  viewerCanRead: Scalars['Boolean'];
  viewerCanRemoveCollaborators: Scalars['Boolean'];
  viewerCanUnpublish: Scalars['Boolean'];
  viewerDidBookmark: Scalars['Boolean'];
  viewerDidComment: Scalars['Boolean'];
  viewerDidUpvote: Scalars['Boolean'];
  viewerIsAuthor: Scalars['Boolean'];
  viewerIsCollaborator: Maybe<Scalars['Boolean']>;
};


export type IStoryCollaboratorsArgs = {
  token: InputMaybe<Scalars['String']>;
};

export enum IStoryAudience {
  All = 'ALL',
  Community = 'COMMUNITY',
  Public = 'PUBLIC'
}

export type IStoryCoverImage = {
  __typename: 'StoryCoverImage';
  type: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export enum IStoryFilter {
  All = 'ALL',
  Featured = 'FEATURED'
}

export enum IStoryStatus {
  Approved = 'APPROVED',
  Proposed = 'PROPOSED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type IStorybookMockDataObject = ICompany | IGovernment | IGovernmentProject | IGovernmentUser | IGroup | IGroupProject | IPendingUser | IProduct | IStory | IVendorProject | IVendorUser;

export type IStripePlan = {
  __typename: 'StripePlan';
  changeDetails: IChangeDetails;
  displayName: Scalars['String'];
  limits: IPlanLimits;
  name: Scalars['String'];
  planId: Scalars['String'];
  price: Scalars['Int'];
  productId: Scalars['String'];
  recurrency: IPlanRecurrency;
  tier: IPlanTier;
  upgradeToPlan: Maybe<IStripePlan>;
  viewerIsSubscribed: Scalars['Boolean'];
};

export enum ISubscriptionStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type ISuggestSolutionInput = {
  name: Scalars['String'];
  /** If `originProductId` is specified, it will track it as the root source for that suggestion. */
  originProductId: InputMaybe<Scalars['ObjectId']>;
};

export type ITag = {
  __typename: 'Tag';
  _id: Scalars['ObjectId'];
  aggregations: ITagAggregations;
  createdAt: Scalars['DateTime'];
  deletedAt: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<IUser>;
  description: Maybe<Scalars['String']>;
  name: Scalars['String'];
  relatedTags: Array<ITag>;
  slug: Scalars['String'];
  translations: Array<ITranslatedTag>;
  types: Array<ITagType>;
};

export type ITagAggregations = {
  __typename: 'TagAggregations';
  awardsCount: Scalars['Int'];
  groupsCount: Scalars['Int'];
  mentionsCount: Scalars['Int'];
  productsCount: Scalars['Int'];
  projectsCount: Scalars['Int'];
  storiesCount: Scalars['Int'];
  usersCount: Scalars['Int'];
  vendorsCount: Scalars['Int'];
};

export type ITagTranslationInput = {
  language: Scalars['String'];
  name: Scalars['String'];
};

export enum ITagType {
  Award = 'AWARD',
  Custom = 'CUSTOM',
  Department = 'DEPARTMENT',
  Product = 'PRODUCT',
  Project = 'PROJECT',
  Story = 'STORY'
}

export type ITagsCount = {
  __typename: 'TagsCount';
  allCount: Scalars['Int'];
  archivedCount: Scalars['Int'];
  awardsCount: Scalars['Int'];
  customCount: Scalars['Int'];
  departmentsCount: Scalars['Int'];
  productsCount: Scalars['Int'];
  projectsCount: Scalars['Int'];
  storiesCount: Scalars['Int'];
  vendorsCount: Scalars['Int'];
};

export type ITextResource = {
  __typename: 'TextResource';
  addedAt: Maybe<Scalars['DateTime']>;
  body: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type ITextSideContent = {
  __typename: 'TextSideContent';
  height: Maybe<Scalars['Int']>;
  iconDescription: Maybe<Scalars['String']>;
  iconSrc: Maybe<Scalars['String']>;
  text: Scalars['String'];
  textFontColor: Scalars['String'];
  textHref: Maybe<Scalars['String']>;
  textHrefHoverColor: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleFontColor: Scalars['String'];
  type: Scalars['String'];
  width: Maybe<Scalars['Int']>;
};

export type ITokens = {
  __typename: 'Tokens';
  token: Maybe<Scalars['String']>;
};

export type ITopFeaturedReport = {
  __typename: 'TopFeaturedReport';
  report: Maybe<IReport>;
};

export type ITopFeaturedStories = {
  __typename: 'TopFeaturedStories';
  stories: Array<IStory>;
};

export type ITranslatedTag = {
  __typename: 'TranslatedTag';
  language: Scalars['String'];
  name: Scalars['String'];
};

export type IUpdateCollectionInput = {
  description: Scalars['String'];
  displayOptions: InputMaybe<ICollectionDisplayOptionsInput>;
  isFeatured: Scalars['Boolean'];
  name: Scalars['String'];
  sponsoring: ICollectionSponsoring;
  sponsoringProducts: InputMaybe<Array<Scalars['ObjectId']>>;
  sponsoringVendors: Array<Scalars['ObjectId']>;
  tags: Array<Scalars['ObjectId']>;
};

export type IUpdateGovernmentProfileInput = {
  budget: InputMaybe<Scalars['Float']>;
  logo: InputMaybe<Scalars['URL']>;
  population: InputMaybe<Scalars['Int']>;
  site: InputMaybe<Scalars['URL']>;
};

export type IUploadGovernmentInput = {
  budget: InputMaybe<Scalars['Int']>;
  county: InputMaybe<Scalars['String']>;
  governmentName: Scalars['String'];
  governmentType: Scalars['String'];
  population: InputMaybe<Scalars['Int']>;
  postalCode: InputMaybe<Scalars['String']>;
  stateAbbreviation: InputMaybe<Scalars['String']>;
  stateName: InputMaybe<Scalars['String']>;
};

export type IUpvote = {
  __typename: 'Upvote';
  _id: Scalars['ObjectId'];
  entity: Maybe<IUser>;
  target: Maybe<IUpvoteTarget>;
  upvotedAt: Maybe<Scalars['DateTime']>;
};

export type IUpvoteTarget = IComment | IGovernmentPost | IGovernmentProject | IGroupPost | IGroupProject | IProduct | IStory | IVendorPost | IVendorProject;

export enum IUpvoteTargetType {
  Comment = 'COMMENT',
  Post = 'POST',
  Product = 'PRODUCT',
  Project = 'PROJECT',
  Report = 'REPORT',
  Story = 'STORY'
}

export type IUpvotes = {
  __typename: 'Upvotes';
  items: Maybe<Array<IUpvote>>;
  pageInfo: Maybe<IPageInfo>;
};

export type IUser = IGovernmentUser | IPendingUser | IVendorUser;

export type IUserAdditionalInformationInput = {
  country: InputMaybe<Scalars['String']>;
  department: InputMaybe<Scalars['ObjectId']>;
  interests: Array<Scalars['ObjectId']>;
  jobTitle: Scalars['String'];
  language: InputMaybe<Scalars['String']>;
  newsletterFrequency: InputMaybe<INewsletterFrequency>;
  notes: Scalars['String'];
  notificationEmail: InputMaybe<Scalars['String']>;
  searchable: InputMaybe<Scalars['Boolean']>;
  showAsAuthorOnProjects: InputMaybe<Scalars['Boolean']>;
  socialMedia: IUserSocialMediaLinksInput;
  subscribedToCampaign: InputMaybe<Scalars['Boolean']>;
  subscribedToNewsletter: InputMaybe<Scalars['Boolean']>;
  timeZone: InputMaybe<Scalars['String']>;
};

export type IUserInterface = {
  collections: Array<ICollection>;
  lastSignInProvider: Scalars['String'];
  projectsCount: Scalars['Int'];
  savedCollectionsCount: Scalars['Int'];
  savedCount: Scalars['Int'];
  savedProductsCount: Scalars['Int'];
  savedProjectsCount: Scalars['Int'];
  savedStoriesCount: Scalars['Int'];
  upvotesCount: Maybe<Scalars['Int']>;
};

export type IUserPreferences = {
  __typename: 'UserPreferences';
  showAsAuthorOnProjects: Scalars['Boolean'];
};

export type IUserPreferencesInput = {
  showAsAuthorOnProjects: Scalars['Boolean'];
};

/** Defines an interface for a standard set of fields that should be available for a User profile. */
export type IUserProfile = {
  _id: Scalars['ObjectId'];
  avatar: Maybe<Scalars['String']>;
  country: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  fullName: Maybe<Scalars['String']>;
  jobTitle: Maybe<Scalars['String']>;
  language: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  notificationEmail: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  timeZone: Maybe<Scalars['String']>;
};


/** Defines an interface for a standard set of fields that should be available for a User profile. */
export type IUserProfileAvatarArgs = {
  height: InputMaybe<Scalars['Int']>;
  width: InputMaybe<Scalars['Int']>;
};

export type IUserProfileInput = {
  country: InputMaybe<Scalars['String']>;
  department: InputMaybe<Scalars['ObjectId']>;
  firstName: Scalars['String'];
  interests: Array<Scalars['ObjectId']>;
  jobTitle: InputMaybe<Scalars['String']>;
  language: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  notificationEmail: InputMaybe<Scalars['String']>;
  preferences: IUserPreferencesInput;
  searchable: InputMaybe<Scalars['Boolean']>;
  socialMedia: IUserSocialMediaLinksInput;
  timeZone: InputMaybe<Scalars['String']>;
};

export type IUserProfileSubscriptionInput = {
  newsletterFrequency: InputMaybe<INewsletterFrequency>;
  subscribedToCampaign: InputMaybe<Scalars['Boolean']>;
  subscribedToNewsletter: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ObjectId'];
};

export enum IUserRole {
  Owner = 'OWNER',
  User = 'USER'
}

export enum IUserSearchScope {
  Citizen = 'CITIZEN',
  Community = 'COMMUNITY',
  Disruptor = 'DISRUPTOR',
  Government = 'GOVERNMENT',
  Innovator = 'INNOVATOR',
  Pending = 'PENDING',
  Vendor = 'VENDOR'
}

export type IUserSharingInput = {
  posts: Scalars['Boolean'];
  projects: Scalars['Boolean'];
  resources: Scalars['Boolean'];
  stories: Scalars['Boolean'];
};

export type IUserSharingOptions = {
  __typename: 'UserSharingOptions';
  posts: Scalars['Boolean'];
  projects: Scalars['Boolean'];
  resources: Scalars['Boolean'];
  stories: Scalars['Boolean'];
};

export type IUserSocialMediaLinks = {
  __typename: 'UserSocialMediaLinks';
  facebook: Maybe<Scalars['URL']>;
  instagram: Maybe<Scalars['URL']>;
  linkedin: Maybe<Scalars['URL']>;
  twitter: Maybe<Scalars['URL']>;
  youtube: Maybe<Scalars['URL']>;
};

export type IUserSocialMediaLinksInput = {
  facebook: InputMaybe<Scalars['URL']>;
  instagram: InputMaybe<Scalars['URL']>;
  linkedin: InputMaybe<Scalars['URL']>;
  twitter: InputMaybe<Scalars['URL']>;
  youtube: InputMaybe<Scalars['URL']>;
};

export enum IUserType {
  Disruptor = 'DISRUPTOR',
  Innovator = 'INNOVATOR',
  Regular = 'REGULAR'
}

export type IUserWithRoleInput = {
  role: IUserRole;
  userId: Scalars['ObjectId'];
};

export type IUsers = {
  __typename: 'Users';
  items: Maybe<Array<IUser>>;
  pageInfo: Maybe<IPageInfo>;
};

export type IUsersCount = {
  __typename: 'UsersCount';
  blockedCount: Scalars['Int'];
  citizensCount: Scalars['Int'];
  communityUsersCount: Scalars['Int'];
  disruptorsCount: Scalars['Int'];
  governmentUsersCount: Scalars['Int'];
  innovatorsCount: Scalars['Int'];
  onboardingCount: Scalars['Int'];
  pendingUsersCount: Scalars['Int'];
  sharingCount: Scalars['Int'];
  vendorUsersCount: Scalars['Int'];
  verifiedCommunityUsersCount: Scalars['Int'];
};


export type IUsersCountOnboardingCountArgs = {
  processStatus: InputMaybe<IProcessStatusType>;
};

export type IVendorBalanceTransaction = {
  __typename: 'VendorBalanceTransaction';
  amount: Scalars['Int'];
  createdAt: Scalars['Date'];
  description: Maybe<Scalars['String']>;
  endingBalance: Scalars['Int'];
  government: Maybe<IGovernment>;
  inviter: Maybe<IVendorUser>;
  type: Scalars['String'];
};

export type IVendorCredit = {
  __typename: 'VendorCredit';
  amount: Scalars['Int'];
  createdAt: Scalars['Date'];
  government: IGovernment;
  vendor: ICompany;
};

export type IVendorCustomLimits = {
  __typename: 'VendorCustomLimits';
  enrichedProducts: Scalars['Int'];
  groups: Scalars['Int'];
  inviteGovernments: Scalars['Boolean'];
  products: Scalars['Int'];
  searchAccess: Scalars['Boolean'];
  sharingPage: Scalars['Boolean'];
  teamMembers: Scalars['Int'];
};

export type IVendorCustomLimitsInput = {
  enrichedProducts: Scalars['Int'];
  groups: Scalars['Int'];
  inviteGovernments: Scalars['Boolean'];
  products: Scalars['Int'];
  searchAccess: Scalars['Boolean'];
  teamMembers: Scalars['Int'];
};

export type IVendorGroupRequest = IGroupRequest & {
  __typename: 'VendorGroupRequest';
  _id: Scalars['ObjectId'];
  createdAt: Scalars['DateTime'];
  group: IGroup;
  inviter: IUser;
  possibleVendors: Array<ICompany>;
  respondedBy: Maybe<IUser>;
  status: IGroupRequestStatus;
  vendor: ICompany;
};

export type IVendorInput = {
  address: InputMaybe<Scalars['String']>;
  billingName: InputMaybe<Scalars['String']>;
  businessEmail: InputMaybe<Scalars['String']>;
  contactPage: InputMaybe<Scalars['URL']>;
  cover: InputMaybe<ICoverInput>;
  description: InputMaybe<Scalars['String']>;
  headquarters: InputMaybe<Scalars['String']>;
  highlights: Array<Scalars['String']>;
  logo: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  newsworthy: Array<InputMaybe<IVendorNewsworthyItemInput>>;
  numberOfEmployees: InputMaybe<Scalars['String']>;
  resources: InputMaybe<Array<IResourceInput>>;
  site: Scalars['String'];
  slogan: InputMaybe<Scalars['String']>;
  socialMedia: InputMaybe<IVendorSocialMediaLinksInput>;
  sponsorships: InputMaybe<Array<Scalars['ObjectId']>>;
  yearFounded: InputMaybe<Scalars['Int']>;
};

export type IVendorNewsworthyItem = {
  __typename: 'VendorNewsworthyItem';
  description: Maybe<Scalars['String']>;
  favicon: Maybe<Scalars['String']>;
  siteName: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type IVendorNewsworthyItemInput = {
  description: InputMaybe<Scalars['String']>;
  favicon: InputMaybe<Scalars['String']>;
  siteName: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type IVendorPost = IPostInterface & {
  __typename: 'VendorPost';
  _id: Scalars['ObjectId'];
  attachments: Array<IPostAttachment>;
  audience: Scalars['String'];
  author: IUser;
  body: Scalars['String'];
  commentsCount: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deletedAt: Maybe<Scalars['DateTime']>;
  deletedBy: Maybe<IUser>;
  featuredAt: Maybe<Scalars['DateTime']>;
  links: Array<IPostLink>;
  upvotesCount: Scalars['Int'];
  vendor: ICompany;
  viewerCanComment: Scalars['Boolean'];
  viewerCanDelete: Scalars['Boolean'];
  viewerCanEdit: Scalars['Boolean'];
  viewerDidBookmark: Scalars['Boolean'];
  viewerDidComment: Scalars['Boolean'];
  viewerDidUpvote: Scalars['Boolean'];
  viewerIsAuthor: Scalars['Boolean'];
};

export type IVendorPosts = {
  __typename: 'VendorPosts';
  items: Maybe<Array<IVendorPost>>;
  pageInfo: Maybe<IPageInfo>;
};

export type IVendorProject = IProject & {
  __typename: 'VendorProject';
  _id: Scalars['ObjectId'];
  archivedAt: Maybe<Scalars['DateTime']>;
  archivedBy: Maybe<IUser>;
  attachments: Array<IProjectAttachmentItem>;
  audience: Scalars['String'];
  author: Maybe<IUser>;
  awards: Array<ITag>;
  collaborators: Array<IUser>;
  commentsCount: Maybe<Scalars['Int']>;
  countryCode: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  groups: Array<IGroup>;
  icon: Maybe<Scalars['String']>;
  itemsCount: IProjectItemsCount;
  links: Array<IProjectLinkItem>;
  locations: Array<IGovernment>;
  locationsPendingVerification: Array<IGovernment>;
  moreFromGovernment: Array<IGovernmentProject>;
  moreFromVendor: Array<IVendorProject>;
  newsworthy: Array<IProjectNewsworthyItem>;
  outcome: Maybe<Scalars['String']>;
  outcomeStatement: Maybe<Scalars['String']>;
  outsideProducts: Array<IOutsideProduct>;
  products: Array<IProduct>;
  relatedProjects: Array<IProject>;
  slug: Scalars['String'];
  status: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  tags: Array<ITag>;
  title: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
  upvotesCount: Scalars['Int'];
  vendor: ICompany;
  vendors: Array<ICompany>;
  verified: Scalars['Boolean'];
  verifiedBy: Maybe<IUser>;
  viewerCanComment: Scalars['Boolean'];
  viewerCanDelete: Scalars['Boolean'];
  viewerCanEdit: Scalars['Boolean'];
  viewerCanInviteCollaborators: Scalars['Boolean'];
  viewerCanRead: Scalars['Boolean'];
  viewerCanRemoveCollaborators: Scalars['Boolean'];
  viewerCanView: Scalars['Boolean'];
  viewerDidBookmark: Scalars['Boolean'];
  viewerDidComment: Scalars['Boolean'];
  viewerDidUpvote: Scalars['Boolean'];
  viewerIsAuthor: Scalars['Boolean'];
};


export type IVendorProjectCollaboratorsArgs = {
  token: InputMaybe<Scalars['String']>;
};


export type IVendorProjectDescriptionArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IVendorProjectNewsworthyArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IVendorProjectSubtitleArgs = {
  language: InputMaybe<Scalars['String']>;
};


export type IVendorProjectTitleArgs = {
  language: InputMaybe<Scalars['String']>;
};

export enum IVendorSearchScope {
  Disruptor = 'DISRUPTOR'
}

export type IVendorSocialMediaLinks = {
  __typename: 'VendorSocialMediaLinks';
  facebook: Maybe<Scalars['URL']>;
  instagram: Maybe<Scalars['URL']>;
  linkedin: Maybe<Scalars['URL']>;
  twitter: Maybe<Scalars['URL']>;
  youtube: Maybe<Scalars['URL']>;
};

export type IVendorSocialMediaLinksInput = {
  facebook: InputMaybe<Scalars['URL']>;
  instagram: InputMaybe<Scalars['URL']>;
  linkedin: InputMaybe<Scalars['URL']>;
  twitter: InputMaybe<Scalars['URL']>;
  youtube: InputMaybe<Scalars['URL']>;
};

export type IVendorSubscription = IActiveSubscription | IOverdueSubscription | IScheduledToCancelSubscription;

export type IVendorUser = IUserInterface & IUserProfile & {
  __typename: 'VendorUser';
  _id: Scalars['ObjectId'];
  algoliaKeys: Maybe<IAlgoliaKeys>;
  avatar: Maybe<Scalars['String']>;
  bannedAt: Maybe<Scalars['DateTime']>;
  bookmarks: Array<IBookmarkTarget>;
  canSeeActiveGovernments: Scalars['Boolean'];
  collections: Array<ICollection>;
  company: Maybe<ICompany>;
  country: Maybe<Scalars['String']>;
  cover: Maybe<ICover>;
  createdAt: Maybe<Scalars['DateTime']>;
  department: Maybe<ITag>;
  email: Maybe<Scalars['String']>;
  feedCount: Scalars['Int'];
  firstName: Maybe<Scalars['String']>;
  followers: Array<IUser>;
  following: Array<IFollowTarget>;
  fullName: Maybe<Scalars['String']>;
  groups: Array<IGroup>;
  intercomId: Maybe<Scalars['ID']>;
  interests: Array<ITag>;
  /** Pulls the Geo data from the request IP */
  ipLocation: Maybe<IIpLocation>;
  isDisruptor: Maybe<Scalars['Boolean']>;
  isFollowing: Scalars['Boolean'];
  isLimitedSiteAdmin: Maybe<Scalars['Boolean']>;
  isLocked: Scalars['Boolean'];
  isSelfie: Maybe<Scalars['Boolean']>;
  isSiteAdmin: Maybe<Scalars['Boolean']>;
  isUnverifiable: Maybe<Scalars['Boolean']>;
  jobTitle: Maybe<Scalars['String']>;
  language: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  lastSeen: Maybe<Scalars['DateTime']>;
  lastSignInAt: Maybe<Scalars['DateTime']>;
  lastSignInProvider: Scalars['String'];
  latestNewsletterHtml: Maybe<Scalars['String']>;
  linkedinId: Maybe<Scalars['String']>;
  linkedinUrl: Maybe<Scalars['String']>;
  newsletterFrequency: Maybe<INewsletterFrequency>;
  newsletterLastSentDate: Maybe<Scalars['Date']>;
  notes: Scalars['String'];
  notificationEmail: Maybe<Scalars['String']>;
  onboarding: Maybe<IOnboarding>;
  onboardingStatus: Maybe<IProcessStatusType>;
  posts: Array<IPost>;
  postsCount: Scalars['Int'];
  preferences: IUserPreferences;
  primaryGroup: Maybe<IGroup>;
  projects: Maybe<IProjects>;
  projectsCount: Scalars['Int'];
  role: Maybe<Scalars['String']>;
  savedCollectionsCount: Scalars['Int'];
  savedCount: Scalars['Int'];
  savedPostsCount: Scalars['Int'];
  savedProductsCount: Scalars['Int'];
  savedProjectsCount: Scalars['Int'];
  savedStoriesCount: Scalars['Int'];
  searchable: Maybe<Scalars['Boolean']>;
  showActionCenterBanner: Scalars['Boolean'];
  showDeleteUserWarning: Maybe<Scalars['Boolean']>;
  showOnboardingBanner: Scalars['Boolean'];
  signInAttempts: Maybe<Array<ISignInAttempt>>;
  slug: Scalars['String'];
  socialMedia: Maybe<IUserSocialMediaLinks>;
  stories: Maybe<IStories>;
  storiesCount: Maybe<Scalars['Int']>;
  subscribedToCampaign: Maybe<Scalars['Boolean']>;
  subscribedToNewsletter: Maybe<Scalars['Boolean']>;
  subscription: Maybe<IVendorSubscription>;
  timeZone: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  upvotes: Maybe<IUpvotes>;
  upvotesCount: Maybe<Scalars['Int']>;
  verified: Scalars['Boolean'];
};


export type IVendorUserAvatarArgs = {
  height: InputMaybe<Scalars['Int']>;
  width: InputMaybe<Scalars['Int']>;
};


export type IVendorUserPostsArgs = {
  audience: InputMaybe<IPostAudience>;
};


export type IVendorUserPostsCountArgs = {
  audience: InputMaybe<IPostAudience>;
};


export type IVendorUserProjectsArgs = {
  audience: InputMaybe<IProjectAudience>;
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
};


export type IVendorUserStoriesArgs = {
  audience: InputMaybe<IStoryAudience>;
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
};


export type IVendorUserStoriesCountArgs = {
  audience: InputMaybe<IStoryAudience>;
};


export type IVendorUserUpvotesArgs = {
  cursor: InputMaybe<Scalars['Cursor']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ISortType>;
};

export type IVendors = {
  __typename: 'Vendors';
  items: Array<ICompany>;
  pageInfo: Maybe<IPageInfo>;
};

export type IVendorsCount = {
  __typename: 'VendorsCount';
  activeCount: Scalars['Int'];
  allCount: Scalars['Int'];
  archivedCount: Scalars['Int'];
  disruptorsCount: Scalars['Int'];
  inactiveCount: Scalars['Int'];
};

export type IVerification = {
  __typename: 'Verification';
  attachments: Maybe<Array<Scalars['String']>>;
  decision: Maybe<Scalars['String']>;
  explanation: Maybe<Scalars['String']>;
  renderedAt: Maybe<Scalars['DateTime']>;
  renderedBy: Maybe<IUser>;
};

export enum IVerificationInput {
  None = 'NONE',
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED'
}

export type IWebProductDeployment = {
  __typename: 'WebProductDeployment';
  mobileResponsive: Scalars['Boolean'];
  supportedBrowsers: Array<Scalars['String']>;
};

export enum IWorkPlaceType {
  Government = 'GOVERNMENT',
  None = 'NONE',
  NonprofitOrNgo = 'NONPROFIT_OR_NGO',
  Other = 'OTHER',
  StateOrFederalAgency = 'STATE_OR_FEDERAL_AGENCY',
  Vendor = 'VENDOR'
}
