import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { LoadingIndicator } from "@govlaunch/core";
import React from "react";
import { Query } from "react-apollo";
import DocumentTitle from "react-document-title";
import ConfigPageHeader from "../../components/ConfigPageHeader";
import PageIcon from "../../icons/PageIcon";
import GetGlobalSettings from "../../queries/GetGlobalSettings";
import {
  IGlobalSettingsQuery,
  IGlobalSettingsQueryVariables,
} from "../../queries/__generated__/GetGlobalSettings.generated";
import OnboardingSettings from "./OnboardingSettings";

export default function GlobalSettings() {
  return (
    <DocumentTitle title="Global Settings Page | Admin">
      <>
        <ConfigPageHeader title={<><PageIcon icon={faCogs} />Global Settings</>} subtitle="Manage Global Settings" />
        <div
          css={{
            padding: 16,
          }}
        >
          <Query<IGlobalSettingsQuery, IGlobalSettingsQueryVariables> query={GetGlobalSettings}>
            {({ data, loading, refetch }) => {
              if (loading) {
                return <LoadingIndicator />;
              }

              if (!data || !data.globalSettings) {
                return <span>Opps... Something went wrong!</span>;
              }

              return (
                <OnboardingSettings
                  isOnboardingProcessActive={data.globalSettings.isOnboardingActive}
                  refetchSettings={refetch}
                />
              );
            }}
          </Query>
        </div>
      </>
    </DocumentTitle>
  );
}
