import * as palette from "@govlaunch/palette";
import { SlateEditor } from "@govlaunch/editor";
import {
  StoryActions,
  StoryAuthor,
  StoryCoauthors,
  StoryAwards,
  StoryCollaborators,
  StoryGallery,
  StoryHeader,
  StoryNewsworthy,
  StoryProducts,
  StoryHighlights,
  StoryGovernments,
  StoryResources,
  StoryRelatedStoriesSmall,
} from "@govlaunch/story";
import { constructCoauthors } from "@govlaunch/story/src/components/StoryAuthor";
import { Box, PageGridContainer, Stack, Tag, TagText } from "@govlaunch/web";
import React, { cloneElement, ReactElement, useCallback, useState } from "react";
import { useMutation } from "react-apollo";
import { Link } from "react-router-dom";
import { IBookmarkType, ICity, IGovernment, ITag, IUpvoteTargetType, Maybe } from "../../../types/types";
import useIsMobile from "../../hooks/useIsMobile";
import ToggleBookmarkMutation from "../../mutations/ToggleBookmarkMutation";
import ToggleUpvoteMutation from "../../mutations/ToggleUpvoteMutation";
import {
  IToggleBookmarkMutation,
  IToggleBookmarkMutationVariables,
} from "../../mutations/__generated__/ToggleBookmarkMutation.generated";
import {
  IToggleUpvoteMutation,
  IToggleUpvoteMutationVariables,
} from "../../mutations/__generated__/ToggleUpvoteMutation.generated";
import { IStoryBySlugQuery } from "../../queries/__generated__/StoryBySlugQuery.generated";
import uniqBy from "lodash/fp/uniqBy";
import { IStoryFieldsFragment } from "@govlaunch/story/src/fragments/__generated__/StoryFieldsFragment.generated";
import { message } from "antd";

const AnchorLink = Box.withComponent(Link);

interface IStoryPageProps {
  story: NonNullable<IStoryBySlugQuery["storyBySlug"]>;
}

export default function StoryPage({ story }: IStoryPageProps) {
  const [toggleUpvote] = useMutation<IToggleUpvoteMutation, IToggleUpvoteMutationVariables>(ToggleUpvoteMutation);
  const [toggleBookmark] = useMutation<IToggleBookmarkMutation, IToggleBookmarkMutationVariables>(
    ToggleBookmarkMutation,
  );

  const isMobile = useIsMobile();

  const [mainTitleHeight, setMainTitleHeight] = useState(0);
  const headerRef = useCallback((node) => {
    if (node !== null) {
      // eslint-disable-next-line
      function handleResize() {
        setMainTitleHeight(node.getBoundingClientRect().height);
      }

      handleResize();

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const [mobileReadMoreSummaryOpened, setMobileReadMoreSummaryOpened] = useState(false);
  const editorContainerRef = useCallback((node) => {
    if (node !== null) {
      if (node.getBoundingClientRect().height < 3000) {
        setMobileReadMoreSummaryOpened(true);
      }
    }
  }, []);

  const locations = construnctLocations(story);

  const coauthors = constructCoauthors(story.coauthors, story.vendorCoauthors);

  return (
    <div
      css={{
        padding: 20,
      }}
    >
      {isMobile && (
        <Box mb={3}>
          <PageGridContainer>
            <StoryActions
              iconsArrangement="space-around"
              story={story}
              onToggleUpvote={() => {
                toggleUpvote({
                  variables: {
                    targetType: IUpvoteTargetType.Story,
                    targetId: story._id,
                  },
                });
              }}
              onToggleBookmark={() => {
                toggleBookmark({
                  variables: {
                    targetType: IBookmarkType.Story,
                    targetId: story._id,
                  },
                });
              }}
            />
          </PageGridContainer>
        </Box>
      )}
      <article>
        <PageGridContainer gridTemplateColumns={["1fr", "1fr", "3fr 1fr"]} mt={3}>
          <div id="main-content">
            <Stack shouldWrapChildren={true} spacing={6}>
              {/* Header */}
              <StoryHeader story={hidePodcastTag(story)} headerRef={headerRef} />

              {/* Author */}
              <StoryAuthor
                story={story}
                isSmall={true}
                renderLinkWrap={(story, children) => {
                  if (!story.author) {
                    return children;
                  }

                  if (story.author.isSiteAdmin || story.author.isLimitedSiteAdmin) {
                    return <AnchorLink to="/users/lindsay-pica">{children}</AnchorLink>;
                  }

                  return <AnchorLink to={`/users/${story.author.slug}`}>{children}</AnchorLink>;
                }}
              />

              <div
                css={{
                  maxWidth: isMobile ? "100%" : "90%",
                }}
              >
                {/* Gallery */}
                {story.medias && story.medias.length > 0 && <StoryGallery story={story} />}

                {/* Newsworthy */}
                {story.newsworthy && story.newsworthy.length > 0 && (
                  <div
                    css={{
                      borderBottom: "1px solid",
                      borderColor: palette.lightGray,
                      paddingBottom: 20,
                      marginBottom: 25,
                    }}
                  >
                    <StoryNewsworthy story={story} stacked={isMobile} />
                  </div>
                )}

                {/* Highlights */}
                {story.highlights && story.highlights.length > 0 && (
                  <StoryHighlights highlights={story.highlights.filter((highlight) => highlight)} />
                )}

                {/* Body */}
                <div
                  css={{
                    marginBottom: 30,
                  }}
                >
                  <div
                    id="summaryWrapper"
                    css={
                      isMobile && !mobileReadMoreSummaryOpened
                        ? {
                            height: 2200,
                            overflow: "hidden",
                            position: "relative",
                          }
                        : // eslint-disable-next-line
                          {}
                    }
                  >
                    <div ref={editorContainerRef}>
                      <SlateEditor
                        editorKey="editor"
                        value={story.body}
                        readOnly={true}
                        onChange={() => {}}
                        onEmailSubscribe={() => {
                          message.warning("You cannot subscribe for a newsletter from the Admin");
                        }}
                      />
                    </div>
                    {isMobile && !mobileReadMoreSummaryOpened && (
                      <div
                        css={{
                          width: "100%",
                          height: 150,
                          position: "absolute",
                          bottom: 0,
                          backgroundImage: "linear-gradient(to bottom, rgb(255 255 255 / 0%), rgb(250 250 250))",
                          zIndex: 100,
                        }}
                      />
                    )}
                  </div>
                  {isMobile && !mobileReadMoreSummaryOpened && (
                    <button
                      type="button"
                      css={{
                        type: "button",
                        width: "90%",
                        height: 45,
                        fontWeight: 400,
                        fontSize: 20,
                        border: "2px solid",
                        borderColor: palette.lightGray,
                        borderRadius: 5,
                        margin: 20,
                        color: palette.darkGray,
                      }}
                      onClick={() => {
                        setMobileReadMoreSummaryOpened(true);
                      }}
                    >
                      Read More
                    </button>
                  )}
                </div>
              </div>
            </Stack>
          </div>

          {/* Side bar */}
          <div
            css={{
              position: "relative",
              paddingTop: isMobile ? 0 : mainTitleHeight,
            }}
          >
            <Box position="sticky" top={0}>
              {!isMobile && (
                <StoryActions
                  iconsArrangement="flex-start"
                  story={story}
                  onToggleUpvote={() => {
                    toggleUpvote({
                      variables: {
                        targetType: IUpvoteTargetType.Story,
                        targetId: story._id,
                      },
                    });
                  }}
                  onToggleBookmark={() => {
                    toggleBookmark({
                      variables: {
                        targetType: IBookmarkType.Story,
                        targetId: story._id,
                      },
                    });
                  }}
                />
              )}

              {/* Tags */}
              {story.tags.length > 0 && (
                <div
                  css={{
                    marginTop: "3rem",
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                  }}
                >
                  {story.tags.map((tag) => (
                    <AnchorLink to={`/tags/${tag.slug}`} key={tag._id}>
                      <Tag mr={2} mb={2} key={tag._id} color="black" title={tag.name}>
                        <TagText>{tag.name}</TagText>
                      </Tag>
                    </AnchorLink>
                  ))}
                </div>
              )}

              <div
                css={{
                  borderLeft: isMobile ? "none" : "1px solid",
                  borderColor: palette.lightGray,
                  marginTop: 50,
                }}
              >
                {/* Coauthors */}
                {coauthors.length > 0 && (
                  <Box mt={4} pl={3} pt={5}>
                    <StoryCoauthors
                      coauthors={coauthors}
                      isMobile={false}
                      renderLinkWrap={(author, children) => {
                        if (!author) {
                          return <span />;
                        }

                        if (author.isSiteAdmin || author.isLimitedSiteAdmin) {
                          return <AnchorLink to="/users/lindsay-pica">{children}</AnchorLink>;
                        }

                        return <AnchorLink to={`/users/${author.slug}`}>{children}</AnchorLink>;
                      }}
                      renderLinkWrapVendor={(vendor, children) => {
                        if (!vendor) {
                          return children;
                        }

                        return <AnchorLink to={`/vendors/${vendor.slug}`}>{children}</AnchorLink>;
                      }}
                    />
                  </Box>
                )}

                {/* Collaborators */}
                {story.collaborators.length > 0 && (
                  <Box mt={8} pl={3}>
                    <StoryCollaborators
                      collaborators={story.collaborators}
                      renderLinkWrap={(user, children) => {
                        if (!user) {
                          return children;
                        }

                        if (user.isSiteAdmin || user.isLimitedSiteAdmin) {
                          return <AnchorLink to="/users/lindsay-pica">{children}</AnchorLink>;
                        }

                        return <AnchorLink to={`/users/${user.slug}`}>{children}</AnchorLink>;
                      }}
                    />
                  </Box>
                )}

                {/* Governments */}
                {locations.length > 0 && (
                  <Box mt={8} pl={3}>
                    <StoryGovernments
                      locations={locations}
                      renderLinkWrap={(location, children) => {
                        if (!location) {
                          return children;
                        }

                        return (
                          <AnchorLink to={`/governments/${location.slug}`} key={location._id}>
                            {children}
                          </AnchorLink>
                        );
                      }}
                    />
                  </Box>
                )}

                {/* Products */}
                {story.products && story.products.length > 0 && (
                  <Box mt={8} pl={3}>
                    <StoryProducts
                      products={story.products}
                      renderLinkWrap={(product, children) => {
                        return <AnchorLink to={`/products/${product.slug}`}>{children}</AnchorLink>;
                      }}
                    />
                  </Box>
                )}

                {/* Resources */}
                {story.resources &&
                  story.resources.filter(({ __typename }) => __typename !== "TextResource").length > 0 && (
                    <Box mt={8} pl={3}>
                      <StoryResources resources={story.resources} />
                    </Box>
                  )}

                {/* Awards */}
                {story.awards && story.awards.length > 0 && (
                  <Box mt={8} pl={3}>
                    <StoryAwards mt={4} story={story} />
                  </Box>
                )}

                <Box height="5px" bg="blue.600" mx={3} my={5} />

                {/* Related Stories */}
                <Box mt={8} pl={3}>
                  <StoryRelatedStoriesSmall
                    relatedStories={story.relatedStories || []}
                    mt={4}
                    renderLinkWrap={(story, children) => {
                      return cloneElement(children as ReactElement, {
                        href: `/stories/${story.slug}`,
                      });
                    }}
                    placeholderImageSrc="/static/story/story-card-placeholder.png"
                    renderTagLinkWrap={(tag, children) => {
                      return (
                        <AnchorLink to={`/tags/${tag.slug}`} key={tag._id}>
                          {children}
                        </AnchorLink>
                      );
                    }}
                    renderAuthorLinkWrap={(story, children) => {
                      if (!story.author) {
                        return children;
                      }

                      if (story.author.isSiteAdmin || story.author.isLimitedSiteAdmin) {
                        return <AnchorLink to="/users/lindsay-pica">{children}</AnchorLink>;
                      }

                      return <AnchorLink to={`/users/${story.author.slug}`}>{children}</AnchorLink>;
                    }}
                  />
                </Box>
              </div>
            </Box>
          </div>
        </PageGridContainer>
      </article>
    </div>
  );
}

function hidePodcastTag<T extends Record<"tags", Pick<ITag, "name">[]>>(object: T): T {
  return {
    ...object,
    tags: object.tags.filter((candidate) => {
      return candidate.name !== "Podcast";
    }),
  };
}

function notUndefinedOrNull<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

type LocationType = Maybe<
  {
    __typename: "Government";
  } & Pick<IGovernment, "_id" | "slug" | "name" | "logo"> & {
      city: {
        __typename: "City";
      } & Pick<ICity, "_id" | "country">;
    }
>;

function construnctLocations(story: NonNullable<IStoryFieldsFragment>) {
  const locations = (story.locations || new Array<LocationType>()).filter(notUndefinedOrNull);
  if (story.government) {
    locations.unshift(story.government);
  }

  return uniqBy((location) => location._id, locations);
}
