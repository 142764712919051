import React from "react";
import AdminProfile from "../../AdminProfile";
import { Layout } from "antd";
import * as palette from "@govlaunch/palette";
import { Link } from "react-router-dom";
import { PoweroffOutlined } from "@ant-design/icons";
import useIsMobile from "../../hooks/useIsMobile";

const { Header } = Layout;

interface IDashboardHeaderProps {
  isDesktop: boolean;
}

export default function DashboardHeader({ isDesktop }: IDashboardHeaderProps) {
  const isMobile = useIsMobile();

  return (
    <Header
      style={{
        background: palette.white,
        borderBottom: "1px solid #e8e8e8",
        maxHeight: isDesktop ? 64 : 47,
      }}
    >
      <AdminProfile>
        {({ fullName, email }) => (
          <>
            <div
              css={{
                margin: 0,
                color: palette.text,
                maxHeight: isDesktop ? 64 : 47,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {isDesktop ? (
                <span
                  css={{
                    marginRight: 5,
                  }}
                >
                  Logged in as
                </span>
              ) : (
                ""
              )}
              {!isMobile && (
                <>
                  <strong>{fullName}</strong>
                  <span
                    css={{
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    -
                  </span>
                </>
              )}
              <span
                css={{
                  color: palette.primary,
                  fontWeight: 600,
                }}
              >
                {email}
              </span>
              <Link
                to="/logout"
                style={{
                  marginLeft: 15,
                }}
              >
                <PoweroffOutlined
                  title="Log out"
                  style={{
                    color: palette.red,
                  }}
                />
              </Link>
            </div>
          </>
        )}
      </AdminProfile>
    </Header>
  );
}
