import * as palette from "@govlaunch/palette";
import { TagsField } from "@govlaunch/web";
import arrayMutators from "final-form-arrays";
import React, { useCallback, useState } from "react";
import { Form } from "react-final-form";
import { ITagType } from "../../../types/types";
import validate from "../../validate";
import HighlightsInputField from "../form/fields/HighlightsInputField";
import NewsworthyField from "../form/fields/NewsworthyField";
import ResourcesField from "../form/fields/ResourcesField";
import TextareaField from "../form/fields/TextareaField";
import TextField from "../form/fields/TextField";
import FormSubmitSection from "../form/FormSubmitSection";
import SectionHeader from "../form/SectionHeader";
import EditableStoryAuthor from "./form/EditableStoryAuthor";
import EditableStoryCoauthors from "./form/EditableStoryCoauthors";
import {
  AudienceField,
  AwardsField,
  GovernmentField,
  GovernmentsInvolvedField,
  MediasField,
  ProductsInvolvedField,
  ProjectSummaryField,
} from "./form/fields";
import StoryCollaborators from "./form/StoryCollaborators";
import storyValidationSchema from "./form/storyValidationSchema";

type TResource =
  | {
      type: "text";
      title: string;
      body: string;
      addedAt: any;
    }
  | {
      type: "download";
      title: string;
      url: string;
      filename: string;
      addedAt: any;
    }
  | {
      type: "link";
      title: string;
      url: string;
      addedAt: any;
    };

export type IStoryFormValues = {
  title: string;
  introduction: string;
  tags: {
    label: string;
    value: string;
  }[];
  awards: {
    label: string;
    value: string;
  }[];
  audience: string;
  author: any;
  coauthors: any[] | null;
  vendorCoauthors: any[] | null;
  body: any;
  collaborators: any[];
  resources: TResource[];
  medias: any[];
  government: {
    label: string;
    logo: string | null;
    value: string;
  } | null;
  locations: {
    label: string;
    logo: string | null;
    value: string;
  }[];
  products: {
    label: string;
    logo: string | null;
    value: string;
  }[];
  newsworthy: {
    description: string | null;
    favicon: string | null;
    siteName: string | null;
    title: string | null;
    url: string | null;
  }[];
  highlights: string[];
  _id?: string;
};

interface IStoryComposerProps {
  initialValues: IStoryFormValues;
  onSubmit: (story: IStoryFormValues) => void;
  submitButtonText: string;
  storySlug?: string;
}

export default function StoryComposer({ initialValues, onSubmit, submitButtonText, storySlug }: IStoryComposerProps) {
  const [containerWidth, setContainerWidth] = useState(0);
  const headerRef = useCallback((node) => {
    if (node !== null) {
      // eslint-disable-next-line
      function handleResize() {
        setContainerWidth(node.getBoundingClientRect().width);
      }

      handleResize();

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return (
    <Form
      keepDirtyOnReinitialize={true}
      mutators={{
        ...arrayMutators,
      }}
      validate={validate(storyValidationSchema)}
      initialValues={initialValues}
      onSubmit={onSubmit}
      subscription={{
        submitting: true,
        submitError: true,
      }}
    >
      {({ handleSubmit, submitting, submitError }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div
              css={{
                maxWidth: 1200,
              }}
            >
              <FormSubmitSection
                backButtonText="All Stories"
                backButtonLocation="/stories"
                discardChangesLocation={storySlug ? `/stories/${storySlug}` : "/stories"}
                submitButtonText={submitButtonText}
                submitting={submitting}
                submitError={submitError}
              />
              <div
                ref={headerRef}
                css={{
                  display: "flex",
                  flexDirection: containerWidth > 800 ? "row" : "column",
                  flexWrap: "nowrap",
                }}
              >
                <div
                  css={{
                    width: containerWidth > 800 ? (containerWidth > 1000 ? "70%" : "65%") : "100%",
                  }}
                >
                  <Section>
                    <SectionHeader>The Basics</SectionHeader>
                    <SectionBody>
                      <TextField
                        fieldName="title"
                        label="Title"
                        placeholder="Type story title..."
                        characterLimit={60}
                        showCharacterCount={true}
                      />
                      <TextareaField
                        fieldName="introduction"
                        label="Description"
                        placeholder="Type story description..."
                      />
                    </SectionBody>
                  </Section>

                  <Section>
                    <SectionHeader>Author</SectionHeader>

                    <SectionBody>
                      <EditableStoryAuthor initialAuthor={initialValues.author} />
                    </SectionBody>
                  </Section>

                  <Section>
                    <MediasField />
                  </Section>

                  <Section>
                    <SectionHeader>Newsworthy</SectionHeader>
                    <SectionBody>
                      <NewsworthyField fieldName="newsworthy" />
                    </SectionBody>
                  </Section>

                  <Section>
                    <SectionHeader>Project Highlights</SectionHeader>
                    <SectionBody>
                      <HighlightsInputField />
                    </SectionBody>
                  </Section>

                  <Section>
                    <SectionHeader>Project Summary</SectionHeader>
                    <SectionBody>
                      <ProjectSummaryField />
                    </SectionBody>
                  </Section>
                </div>
                <div
                  css={{
                    width: containerWidth > 800 ? (containerWidth > 1000 ? "30%" : "35%") : "100%",
                  }}
                >
                  <Section>
                    <SectionHeader>Story Tags</SectionHeader>

                    <SectionBody>
                      <TagsField fieldName="tags" type={[ITagType.Story, ITagType.Custom]} />
                    </SectionBody>
                  </Section>

                  <Section>
                    <SectionHeader>Co-authors</SectionHeader>

                    <SectionBody>
                      <EditableStoryCoauthors editMode={true} />
                    </SectionBody>
                  </Section>

                  <Section>
                    <SectionHeader>Collaborators</SectionHeader>

                    <SectionBody>
                      <StoryCollaborators initialCollaborators={initialValues.collaborators} />
                    </SectionBody>
                  </Section>

                  <Section>
                    <SectionHeader>Government</SectionHeader>

                    <SectionBody>
                      <GovernmentField />
                    </SectionBody>
                  </Section>

                  <Section>
                    <SectionHeader>Governments Involved</SectionHeader>

                    <SectionBody>
                      <GovernmentsInvolvedField />
                    </SectionBody>
                  </Section>

                  <Section>
                    <SectionHeader>Products Involved</SectionHeader>

                    <SectionBody>
                      <ProductsInvolvedField />
                    </SectionBody>
                  </Section>

                  <Section>
                    <SectionHeader>Resources</SectionHeader>

                    <SectionBody>
                      <ResourcesField />
                    </SectionBody>
                  </Section>

                  <Section>
                    <SectionHeader>Awards</SectionHeader>

                    <SectionBody>
                      <AwardsField />
                    </SectionBody>
                  </Section>

                  <Section>
                    <SectionHeader>Audience</SectionHeader>

                    <SectionBody>
                      <AudienceField />
                    </SectionBody>
                  </Section>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Form>
  );
}

function Section({ children, ...props }: any) {
  return (
    <div
      css={{
        marginLeft: 15,
        marginRight: 15,
        marginBottom: 20,
        borderRadius: 5,
        border: `1px solid ${palette.lightestGray}`,
        background: "#fff",
      }}
      {...props}
    >
      {children}
    </div>
  );
}

const SectionBody = ({ children, ...props }: any) => {
  return (
    <div
      css={{
        padding: 16,
        display: "flex",
        flexDirection: "column",
      }}
      {...props}
    >
      {children}
    </div>
  );
};
