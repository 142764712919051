import { IVendorFieldsFragment } from "../../../queries/fragments/__generated__/VendorFields.generated";

export default function convertVendorToInitialValues(vendor: IVendorFieldsFragment): any {
  const values = {
    _id: vendor._id,
    name: vendor.name,
    billingName: vendor.billingName,
    description: vendor.description,
    yearFounded: vendor.yearFounded,
    site: vendor.site,
    headquarters: vendor.headquarters,
    slogan: vendor.slogan,
    contact: getContact(vendor),
    highlights: vendor.highlights,
    newsworthy: vendor.newsworthy.map((newsworthy) => ({
      url: newsworthy.url,
      title: newsworthy.title,
      siteName: newsworthy.siteName,
      description: newsworthy.description,
      favicon: newsworthy.favicon,
    })),
    logo: vendor.logo,
    socialMedia: vendor.socialMedia,
    numberOfEmployees: vendor.numberOfEmployees
      ? {
          value: vendor.numberOfEmployees,
          label: vendor.numberOfEmployees,
        }
      : null,
    resources: (vendor.resources || []).map((resource: any) => {
      if (resource.__typename === "TextResource") {
        return {
          ...resource,
          type: "text",
        };
      }

      if (resource.__typename === "DownloadResource") {
        return {
          ...resource,
          type: "download",
        };
      }

      return {
        ...resource,
        type: "link",
      };
    }),
    sponsorships: vendor.sponsorships.map((sponsorship) => sponsorship._id),
  };

  return values;
}

function getContact(vendor: IVendorFieldsFragment) {
  if (vendor.contactPage) {
    return vendor.contactPage;
  }

  if (vendor.businessEmail) {
    return vendor.businessEmail;
  }

  return "";
}
