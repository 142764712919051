import { notification, Popconfirm } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import UnflagDuplicatedUser from "../../../mutations/UnflagDuplicatedUsers";
import {
  IUnflagDuplicatedUserMutation,
  IUnflagDuplicatedUserMutationVariables,
} from "../../../mutations/__generated__/UnflagDuplicatedUsers.generated";
import * as palette from "@govlaunch/palette";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

interface IDuplicatedUsersTableActionsProps {
  userId: string;
  fullName: string;
}

export default function DuplicatedUsersTableActions({ userId, fullName }: IDuplicatedUsersTableActionsProps) {
  const [unflagDuplicatedUser] = useMutation<IUnflagDuplicatedUserMutation, IUnflagDuplicatedUserMutationVariables>(
    UnflagDuplicatedUser,
    {
      variables: {
        userId: userId,
      },
      refetchQueries: ["DuplicatedUsers"],
    },
  );

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Popconfirm
        title={`Are you sure you want to unflag ${fullName} as duplicate?`}
        onConfirm={async () => {
          const result = await unflagDuplicatedUser();

          if (result && result.data && result.data.unflagDuplicatedUser) {
            notification.success({
              message: "Success!",
              description: "User unflagged from duplicates",
            });
          }
        }}
      >
        <div
          css={{
            height: "42px",
            width: "42px",
            cursor: "pointer",
            border: `1px solid ${palette.orange}`,
            borderRadius: 4,
            padding: "8px 12px 8px 12px",
            "&:hover": {
              opacity: 0.8,
            },
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FontAwesomeIcon icon={faWindowClose} color={palette.orange} size="lg" />
        </div>
      </Popconfirm>
    </div>
  );
}
