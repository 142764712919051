import React from "react";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as palette from "@govlaunch/palette";

interface ICollapseTriggerProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MenuCollapseTrigger({ collapsed, setCollapsed }: ICollapseTriggerProps) {
  return (
    <div
      css={{
        borderRight: "1px solid",
        borderTop: "1px solid",
        borderColor: palette.lightGray,
      }}
      onClick={() => {
        setCollapsed(!collapsed);
      }}
    >
      {!collapsed && (
        <FontAwesomeIcon
          icon={faLessThan}
          style={{
            width: 15,
            height: 15,
            marginTop: 12,
          }}
        />
      )}
      {collapsed && (
        <FontAwesomeIcon
          icon={faLessThan}
          style={{
            width: 15,
            height: 15,
            marginTop: 12,
          }}
          flip="horizontal"
        />
      )}
    </div>
  );
}
