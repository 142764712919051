import React from "react";
import DocumentTitle from "react-document-title";
import EventsPageWithSearch from "./EventsPageWithSearch";
import TablePageHeader from "../../../components/table/TablePageHeader";
import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons";

export default function EventsPage() {
  return (
    <DocumentTitle title="Events Page | Admin">
      <>
        <TablePageHeader title="Events" titleIcon={faCalendarPlus} subTitle="All Events" />
        <div
          css={{
            padding: 20,
          }}
        >
          <EventsPageWithSearch />
        </div>
      </>
    </DocumentTitle>
  );
}
