import React from "react";
import * as palette from "@govlaunch/palette";
import { Field, FieldInputProps } from "react-final-form";
import { Filestack, Button } from "@govlaunch/core";
import ProfilePicture from "@govlaunch/profile-picture";
import SponsorshipBadges from "./SponsorshipBadges";
import { ISponsorship } from "../../../../types/types";

interface IFileUploaded {
  filename: string;
  mimetype: string;
  size: string | number;
  url: string;
}

interface ILogoFieldProps {
  name?: string;
  link?: string | null;
}

export default function SponsorshipLogoPreviewField({ name, link }: ILogoFieldProps) {
  return (
    <Field name="logo">
      {({ input }) => (
        <Filestack
          options={{
            accept: "image/*",
          }}
          onSuccess={({ filesUploaded }: { filesUploaded: IFileUploaded[] }) => input.onChange(filesUploaded[0].url)}
          customRender={({ onPick }: { onPick: React.SyntheticEvent }) => (
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                rowGap: 10,
                alignItems: "flex-start",
              }}
            >
              <LogoHeader />

              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 10,
                }}
              >
                <div
                  css={{
                    borderRight: `1px solid ${palette.lightestGray}`,
                    padding: 16,
                  }}
                >
                  <ProfilePicture xlarge={true} image={input.value} name={name || "X"} />
                </div>

                <PreviewHeader />

                <PreviewBadges input={input} name={name} link={link} />
              </div>

              <SaveCancelButtons input={input} onPick={onPick} />
            </div>
          )}
        />
      )}
    </Field>
  );
}

function LogoHeader() {
  return (
    <div
      css={{
        textTransform: "uppercase",
        fontSize: 16,
        color: palette.darkGray,
        letterSpacing: 0.5,
        fontWeight: 500,
      }}
    >
      Logo
    </div>
  );
}

interface ISaveCancelButtonsProps {
  input: FieldInputProps<any, HTMLElement>;
  onPick: React.SyntheticEvent;
}

function SaveCancelButtons({ input, onPick }: ISaveCancelButtonsProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
        alignItems: "flex-start",
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button type="button" size="xsmall" color={palette.primary} onClick={onPick}>
          Upload
        </Button>

        {input.value && (
          <span
            css={{
              marginLeft: 5,
            }}
          >
            <Button
              type="button"
              size="xsmall"
              color={palette.red}
              theme="secondary"
              onClick={() => input.onChange(null)}
            >
              Remove
            </Button>
          </span>
        )}
      </div>
      {!input.value && (
        <div
          css={{
            fontSize: 12,
            color: palette.darkGray,
            letterSpacing: 0.5,
            fontWeight: 500,
            fontStyle: "italic",
          }}
        >
          For best results, please use a square png image with equal height and width!
        </div>
      )}
    </div>
  );
}

function PreviewHeader() {
  return (
    <div
      css={{
        textTransform: "uppercase",
        fontSize: 14,
        color: palette.darkGray,
        letterSpacing: 0.5,
        fontWeight: 500,
        textOrientation: "upright",
        writingMode: "vertical-lr",
        alignSelf: "center",
      }}
    >
      Preview
    </div>
  );
}

interface IPreviewBadgesProps {
  input: FieldInputProps<any, HTMLElement>;
  name?: string;
  link?: string | null;
}

function PreviewBadges({ input, name, link }: IPreviewBadgesProps) {
  const badgeData = [
    {
      name: name || "X",
      link: link,
      logo: input.value,
    },
  ];

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        columnGap: 20,
        alignItems: "flex-end",
        alignSelf: "center",
      }}
    >
      <div
        css={{
          position: "relative",
        }}
      >
        <ProfilePicture size={100} image="" name="X" />
        <SponsorshipBadges sponsorships={badgeData as ISponsorship[]} vendorLogoSize={100} />
      </div>
      <div
        css={{
          position: "relative",
        }}
      >
        <ProfilePicture size={80} image="" name="X" />
        <SponsorshipBadges sponsorships={badgeData as ISponsorship[]} vendorLogoSize={80} />
      </div>
      <div
        css={{
          position: "relative",
        }}
      >
        <ProfilePicture size={48} image="" name="X" />
        <SponsorshipBadges sponsorships={badgeData as ISponsorship[]} vendorLogoSize={40} />
      </div>
    </div>
  );
}
