import { IStoryBySlugQuery } from "../../queries/__generated__/StoryBySlugQuery.generated";
import { IStoryFormValues } from "./StoryComposer";

export default function convertStoryToInitialValues(
  story: NonNullable<IStoryBySlugQuery["storyBySlug"]>,
): IStoryFormValues {
  return {
    _id: story._id,
    author: story.author,
    coauthors: story.coauthors,
    vendorCoauthors: story.vendorCoauthors,
    title: story.title,
    introduction: story.introduction || "",
    audience: story.audience,
    collaborators: story.collaborators,
    locations: (story.locations || []).map((location) => {
      return {
        label: location.name,
        logo: location.logo || null,
        value: location._id,
      };
    }),
    products: (story.products || []).map((product) => {
      return {
        label: product.name,
        logo: product.logo,
        value: product._id,
      };
    }),
    medias: story.medias || [],
    awards: story.awards.map((award) => {
      return {
        value: award._id,
        label: award.name,
      };
    }),
    tags: story.tags.map((tag) => {
      return {
        value: tag._id,
        label: tag.name,
      };
    }),
    government: story.government
      ? {
          label: story.government.name,
          logo: story.government.logo,
          value: story.government._id,
        }
      : null,
    newsworthy: story.newsworthy || [],
    highlights: story.highlights || [],
    body: story.body,
    resources: (story.resources || []).map((resource) => {
      if (resource.__typename === "TextResource") {
        return {
          type: "text",
          title: resource.title || "",
          body: resource.body || "",
          addedAt: resource.addedAt,
        };
      }

      if (resource.__typename === "DownloadResource") {
        return {
          type: "download",
          title: resource.title || "",
          filename: resource.filename || "",
          url: resource.url || "",
          addedAt: resource.addedAt,
        };
      }

      return {
        type: "link",
        title: resource.title || "",
        url: resource.url || "",
        addedAt: resource.addedAt,
      };
    }),
  };
}
