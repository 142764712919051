import React, { useState } from "react";
import * as palette from "@govlaunch/palette";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddBlockModal from "./AddBlockModal";
import { IBlockLayout } from "../blocks/blockProps";

interface IAddBlockButonProps {
  block: IBlockLayout;
  onAddBlock: (block: IBlockLayout) => void;
}

export default function AddBlockButton({ block, onAddBlock }: IAddBlockButonProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        border: `2px dashed ${palette.lightGray}`,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        cursor: "pointer",
      }}
    >
      <div
        css={{
          "&:hover": {
            opacity: 0.8,
          },
        }}
        onClickCapture={() => setIsOpen(true)}
      >
        <FontAwesomeIcon
          icon={faPlus}
          color={palette.primary}
          style={{
            width: "40px",
            height: "40px",
          }}
        />
      </div>
      <div
        css={{
          marginTop: 8,
          color: palette.primary,
        }}
      >
        Add Block
      </div>
      <AddBlockModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        block={block}
        onAddBlock={onAddBlock}
      />
    </div>
  );
}
