import { Button, Col, Grid, LoadingIndicator, RowWrap } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import React from "react";
import { Mutation } from "react-apollo";
import { Form } from "react-final-form";
import * as Yup from "yup";
import { IGovernment, INewsletterFrequency, IUserAdditionalInformationInput } from "../../../types/types";
import UpdateUserAdditionalInformation from "../../mutations/UpdateUserAdditionalInformation";
import {
  IUpdateUserAdditionalInformationMutation,
  IUpdateUserAdditionalInformationMutationVariables,
} from "../../mutations/__generated__/UpdateUserAdditionalInformation.generated";
import { IUserFieldsFragment } from "../../queries/fragments/__generated__/UserFields.generated";
import getGovlaunchBaseUrl from "../../utils/getGovlaunchBaseUrl";
import prefixWithHttpIfNecessary from "../../utils/prefixWithHttpIfNecessary";
import validate from "../../validate";
import UserSocialMediaFields from "./fields/UserSocialMediaFields";
import UserNotesField from "./fields/UserNotesField";
import { notification } from "antd";
import DepartmentField from "./fields/DepartmentField";
import InterestsField from "./fields/InterestsField";
import { COUNTRIES } from "../governments/Countries";
import { TIMEZONES } from "../../utils/timezones";
import { LANGUAGES } from "../../utils/languages";
import EditableField from "../form/fields/EditableField";
import EditableDropdownField from "../form/fields/EditableDropdownField";
import ReadOnlyField from "../form/fields/ReadOnlyField";
import { Link } from "react-router-dom";
import { Margin } from "../../spacings";
import UserEmailSubscriptionSettings from "./subscriptions/UserEmailSubscriptionSettings";
import AdditionalUserSettings from "./fields/AdditionalUserSettings";
import Section from "../form/Section";
import SectionBody from "../form/SectionBody";
import SectionHeader from "../form/SectionHeader";
import Impersonate from "../debugging/Impersonate";
import { getUserType } from "./utils/getUserType";

interface IViewUserProps {
  user: IUserFieldsFragment;
}

const validationSchema = Yup.object().shape({
  notificationEmail: Yup.string().email("Invalid E-mail"),
  department: Yup.mixed().nullable(),
  interests: Yup.array().when("department", {
    is: (value: any) => {
      return value !== null;
    },
    then: Yup.array().required().min(1, "Please select an interest").max(20, "You can select up to twenty tags"),
    otherwise: Yup.array().nullable().max(20, "You can select up to twenty tags"),
  }),
});

export default function ViewUser({ user }: IViewUserProps) {
  return (
    <div>
      <div
        css={{
          minWidth: 900,
          marginBottom: 10,
        }}
      >
        <div
          css={{
            padding: 20,
            backgroundColor: palette.white,
            borderTop: `solid 1px ${palette.lightSealBlue}`,
            borderBottom: `solid 1px ${palette.lightSealBlue}`,
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Spacing inline={true} flex={true} right={10}>
              <ProfilePicture
                image={user.avatar}
                size={48}
                name={user.fullName}
                innovator={user.__typename === "GovernmentUser" ? user.isInnovator : false}
                disruptor={user.__typename === "VendorUser" ? user.isDisruptor : false}
              />
            </Spacing>

            <div
              css={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
              }}
            >
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "end",
                  lineHeight: "28px",
                }}
              >
                <div
                  css={{
                    margin: 0,
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  {user.fullName}
                </div>

                <div
                  css={{
                    marginLeft: "8px",
                    fontSize: "14px",
                    color: palette.primary,
                    fontWeight: 700,
                  }}
                >
                  / {getUserType(user.role || "", user.verified)}
                </div>
              </div>

              <a href={`${getGovlaunchBaseUrl("portal")}/@${user.slug}`} target="_blank" rel="noopener noreferrer">
                View on Govlaunch
              </a>

              {user.intercomId && (
                <a
                  href={`https://app.intercom.com/a/apps/${process.env.INTERCOM_APP_ID}/users/${user.intercomId}/all-conversations`}
                  target="_blank"
                  rel="noopener noreferrer"
                  css={{
                    display: "block",
                  }}
                >
                  View on Intercom
                </a>
              )}

              {user.latestNewsletterHtml && (
                <a
                  href={user.latestNewsletterHtml}
                  target="_blank"
                  rel="noopener noreferrer"
                  css={{
                    display: "block",
                  }}
                >
                  View latest sent Newsletter
                </a>
              )}

              <Impersonate>
                {(impersonate) => (
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      impersonate(user);
                    }}
                  >
                    Impersonate
                  </a>
                )}
              </Impersonate>
            </div>
          </div>
        </div>
      </div>

      <Mutation<IUpdateUserAdditionalInformationMutation, IUpdateUserAdditionalInformationMutationVariables>
        mutation={UpdateUserAdditionalInformation}
      >
        {(updateUser) => {
          return (
            <Form
              keepDirtyOnReinitialize={true}
              initialValues={convertUserToInitialValues(user)}
              validate={validate(validationSchema)}
              onSubmit={async (values) => {
                await updateUser({
                  variables: {
                    userId: user._id,
                    additionalInformation: convertFormResultToVariables(values),
                  },
                }).then(() => {
                  notification.open({
                    type: "success",
                    message: "User saved!",
                    description: `User saved successfully!`,
                  });
                });
              }}
            >
              {({ handleSubmit, submitting }) => (
                <form
                  onSubmit={handleSubmit}
                  css={{
                    maxWidth: 1140,
                  }}
                >
                  <Grid
                    css={{
                      marginBottom: 10,
                    }}
                  >
                    <RowWrap>
                      <Col width={[1, 1, 1, 1 / 2]}>
                        <Section>
                          <SectionBody>
                            <ReadOnlyField label="User ID" value={user._id} />

                            <ReadOnlyField label="SSO Provider (Last Used)" value={user.lastSignInProvider} />

                            <ReadOnlyField label="Name" value={user.fullName || "N/A"} />

                            <EditableField label="Job Title" name="jobTitle" />

                            <ReadOnlyField label="Email" value={user.email || "N/A"} />

                            <EditableField label="Notification Email" name="notificationEmail" />

                            <DepartmentField />

                            <InterestsField />

                            {user.__typename === "GovernmentUser" && <GovernmentRow government={user.government} />}

                            <EditableDropdownField
                              label="Country"
                              name="country"
                              selectOptions={COUNTRIES.map((country) => {
                                return {
                                  label: country.name,
                                  value: country.name,
                                };
                              })}
                              showSearch={true}
                            />

                            <EditableDropdownField
                              label="Language"
                              name="language"
                              selectOptions={LANGUAGES.map((language) => {
                                return {
                                  label: language.language,
                                  value: language.code,
                                };
                              })}
                            />

                            <EditableDropdownField
                              label="Time Zone"
                              name="timeZone"
                              selectOptions={TIMEZONES.map((timeZone) => {
                                return {
                                  label: timeZone.description,
                                  value: timeZone.code,
                                };
                              })}
                            />
                          </SectionBody>
                        </Section>
                      </Col>

                      <Col width={[1, 1 / 2]}>
                        <div
                          css={{
                            display: "grid",
                            gridRowGap: 10,
                          }}
                        >
                          <Section>
                            <SectionHeader>Social Media</SectionHeader>

                            <SectionBody>
                              <UserSocialMediaFields />
                            </SectionBody>
                          </Section>
                          <Section>
                            <SectionHeader>Newsletter Preferences</SectionHeader>

                            <SectionBody>
                              <UserEmailSubscriptionSettings />
                            </SectionBody>
                          </Section>
                          <Section>
                            <SectionBody>
                              <AdditionalUserSettings />
                            </SectionBody>
                          </Section>
                        </div>
                      </Col>
                    </RowWrap>

                    <RowWrap mt={[20]}>
                      <Col width={[1]}>
                        <Section>
                          <SectionBody>
                            <UserNotesField />
                          </SectionBody>
                        </Section>
                      </Col>
                    </RowWrap>
                  </Grid>

                  <div
                    css={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      size="large"
                      color={palette.greenSuccess}
                      textColor="#fff"
                      type="submit"
                      flex={1}
                      width="90%"
                      disabled={submitting}
                      onClick={handleSubmit}
                      css={{
                        margin: 10,
                        ":disabled": {
                          background: "rgba(0, 0, 0, 0.2)",
                        },
                      }}
                    >
                      {submitting ? (
                        <div
                          css={{
                            marginLeft: 10,
                          }}
                        >
                          <LoadingIndicator />
                        </div>
                      ) : (
                        <span>Save Changes</span>
                      )}
                    </Button>

                    <Button
                      type="button"
                      size="large"
                      theme="secondary"
                      color={palette.innovatorBlue}
                      textColor={palette.innovatorBlue}
                      onClick={() => history.back()}
                      flex={1}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              )}
            </Form>
          );
        }}
      </Mutation>
    </div>
  );
}

function convertUserToInitialValues(user: IUserFieldsFragment): any {
  const socialMedia = {
    linkedin: user.socialMedia ? user.socialMedia.linkedin : null,
    twitter: user.socialMedia ? user.socialMedia.twitter : null,
    facebook: user.socialMedia ? user.socialMedia.facebook : null,
    instagram: user.socialMedia ? user.socialMedia.instagram : null,
    youtube: user.socialMedia ? user.socialMedia.youtube : null,
  };

  return {
    jobTitle: user.jobTitle || "",
    notificationEmail: user.notificationEmail || "",
    socialMedia: socialMedia,
    notes: user.notes ? user.notes || "" : "",
    department: user.department
      ? {
          _id: user.department._id,
          objectID: user.department._id,
          name: user.department.name,
        }
      : null,
    interests: user.interests,
    country: convertValueToCountry(user.country),
    language: user.language,
    timeZone: user.timeZone,
    hideSearch: !user.searchable,
    hideAuthor: user.isSiteAdmin ? true : !user.preferences.showAsAuthorOnProjects,
    newsletterFrequency: user.newsletterFrequency || INewsletterFrequency.Biweekly,
    subscribedToNewsletter: user.subscribedToNewsletter || false,
    subscribedToCampaign: user.subscribedToCampaign,
  };
}

function convertFormResultToVariables(values: any): IUserAdditionalInformationInput {
  return {
    department: values.department ? values.department.objectID : null,
    interests: values.interests.map(({ _id, objectID }: any) => _id || objectID),
    jobTitle: values.jobTitle || "",
    notificationEmail: values.notificationEmail || null,
    notes: values.notes || "",
    socialMedia: sanitizeSocialMediaInput(values.socialMedia),
    country: convertCountryToValue(values.country),
    language: values.language || "",
    timeZone: values.timeZone || "",
    searchable: !values.hideSearch,
    showAsAuthorOnProjects: !values.hideAuthor,
    newsletterFrequency: values.newsletterFrequency || INewsletterFrequency.Biweekly,
    subscribedToNewsletter: values.subscribedToNewsletter,
    subscribedToCampaign: values.subscribedToCampaign,
  };
}

function convertCountryToValue(country: string): string {
  const foundCountry = COUNTRIES.find((c) => c.name === country);

  if (!foundCountry) {
    return "";
  }

  return foundCountry.code;
}

function convertValueToCountry(country: string | null): string {
  if (!country) {
    return "";
  }

  const foundCountry = COUNTRIES.find((c) => c.code === country);

  if (!foundCountry) {
    return "";
  }

  return foundCountry.name;
}

function sanitizeSocialMediaInput(socialMedia: any): any {
  if (!socialMedia) {
    return {
      linkedin: null,
      twitter: null,
      facebook: null,
      instagram: null,
      youtube: null,
    };
  }

  return {
    linkedin: prefixWithHttpIfNecessary(socialMedia.linkedin),
    twitter: prefixWithHttpIfNecessary(socialMedia.twitter),
    facebook: prefixWithHttpIfNecessary(socialMedia.facebook),
    instagram: prefixWithHttpIfNecessary(socialMedia.instagram),
    youtube: prefixWithHttpIfNecessary(socialMedia.youtube),
  };
}

interface IGovernmentRowProps {
  government: {
    __typename: "Government";
  } & Pick<IGovernment, "_id" | "slug" | "isInnovator" | "name" | "logo">;
}
export function GovernmentRow({ government }: IGovernmentRowProps) {
  return (
    <div>
      <div>
        <label
          css={{
            fontWeight: "bold",
            fontSize: 14,
            color: palette.mediumGray,
            margin: 0,
          }}
        >
          Government
        </label>
      </div>

      <Margin mt={10}>
        <div
          css={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ProfilePicture
            size={32}
            image={government.logo}
            name={government.name}
            innovator={government.isInnovator}
            css={{
              whiteSpace: "nowrap",
            }}
          />

          <Spacing left={10}>
            <Link to={`/governments/${government.slug}`}>{government.name}</Link>
          </Spacing>
        </div>
      </Margin>
    </div>
  );
}
