import * as palette from "@govlaunch/palette";

import React, { memo } from "react";
import { pick, uniqBy } from "lodash/fp";

import Downshift from "downshift";
import { Input } from "@govlaunch/core/form";
import { LoadingIndicator } from "@govlaunch/core";
import { Margin } from "../../../spacings";
import ProfilePicture from "@govlaunch/profile-picture";
import ProgressiveImage from "react-progressive-image";
import { SearchAlgoliaIndex } from "@govlaunch/algolia";
import { useSelfie } from "../../auth/Selfie";
import { IProduct } from "../../../../types/types";

interface IProductsInputProps {
  onChange: (event: any) => any;
  onBlur?: (event: any) => any;
  onFocus?: (event: any) => any;
  value: any;
}

interface ISearchAlgoliaChildren {
  searchValue: string;
  onChange: (value: string) => any;
  loading: boolean;
  results?: IAlgoliaProduct[];
}

interface IAlgoliaProduct {
  name: string;
  objectID: string;
  _id: string;
  company: IAlgoliaProductCompany;
  slug: string;
  link?: string;
  logo?: string;
  description: string;
  governmentsUsingCount?: number;
  screenshots: string[];
  status: string;
  updatedAt: string;
  createdAtTs?: number;
  isDisruptor?: boolean;
}

interface IAlgoliaProductCompany {
  _id: string;
  name: string;
  slug: string;
  owner?: string;
  description?: string;
  logo: string;
  site: string;
  yearFunded: number;
  approvedProducts: string[];
  businessEmail?: string;
  createdAt?: string;
  updatedAt?: string;
  membersCount?: number;
}

function ProductsInput({ value, onChange, onBlur, onFocus }: IProductsInputProps) {
  const user = useSelfie();

  return (
    <SearchAlgoliaIndex
      fetchOnMount={false}
      index="products"
      params={{
        hitsPerPage: 5,
        restrictSearchableAttributes: ["name"],
        ...buildIgnoreFilters(value),
      }}
      user={user}
    >
      {({ searchValue, onChange: setSearchQuery, loading, results }: ISearchAlgoliaChildren) => {
        return (
          <Downshift
            itemToString={product => {
              if (product) {
                return product._id;
              } else {
                return null;
              }
            }}
            inputValue={searchValue}
            onChange={selectedProduct => {
              setSearchQuery("");

              const nextProducts = uniqBy(product => product._id, [
                ...value,
                {
                  ...pick(["_id", "name", "logo", "description"], selectedProduct),
                  addedAt: Date.now(),
                },
              ]);

              onChange(nextProducts as any);
            }}
            stateReducer={(_, changes) => {
              switch (changes.type) {
                case Downshift.stateChangeTypes.changeInput:
                  return {
                    ...changes,
                    highlightedIndex: null,
                  };
                default:
                  return changes;
              }
            }}
            onInputValueChange={(inputValue, changes) => {
              if ((changes as any).type === Downshift.stateChangeTypes.changeInput) {
                setSearchQuery(inputValue);
              }
            }}
          >
            {({ getInputProps, getItemProps, highlightedIndex, isOpen }) => (
              <div>
                <div
                  css={{
                    position: "relative",
                  }}
                >
                  <Input
                    {...getInputProps({
                      onBlur,
                      onFocus,
                    })}
                    size="small"
                    placeholder="Type a product name…"
                  />

                  {loading && (
                    <div
                      css={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        right: 10,
                      }}
                    >
                      <LoadingIndicator />
                    </div>
                  )}
                </div>

                {isOpen && results && results.length > 0 && (
                  <Margin mt={10}>
                    <div
                      css={{
                        boxShadow: "0 6px 10px 0 rgba(225, 225, 225, 0.5)",
                        border: `solid 1px ${palette.lightestSealBlue}`,
                        backgroundColor: palette.white,
                        borderRadius: 5,
                        padding: 10,
                      }}
                    >
                      <div
                        css={{
                          display: "grid",
                          gridRowGap: 10,
                        }}
                      >
                        {results.map((product, index) => {
                          const isSelected = highlightedIndex === index;

                          return (
                            <div
                              key={product._id}
                              css={{
                                display: "grid",
                                gridTemplateAreas: '"logo name" "logo description"',
                                gridTemplateColumns: "auto 1fr",
                                gridColumnGap: 10,
                                gridRowGap: 0,
                                padding: 5,
                                cursor: "pointer",
                              }}
                              {...getItemProps({
                                item: product,
                                index,
                              })}
                            >
                              <div
                                css={{
                                  gridArea: "logo",
                                  display: "inline-flex",
                                }}
                              >
                                <ProgressiveImage src={product.logo as string} placeholder="">
                                  {(src: string) => <ProfilePicture name={product.name} image={src} size={32} />}
                                </ProgressiveImage>
                              </div>

                              <span
                                css={{
                                  gridArea: "name",
                                  color: isSelected ? palette.primary : palette.sealBlue,
                                  fontSize: 14,
                                  fontWeight: 600,
                                }}
                              >
                                {product.name}
                              </span>

                              <span
                                css={{
                                  gridArea: "description",
                                  color: palette.sealBlue,
                                  fontSize: 12,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {product.description}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Margin>
                )}
              </div>
            )}
          </Downshift>
        );
      }}
    </SearchAlgoliaIndex>
  );
}

export default memo(ProductsInput);

function buildIgnoreFilters(products: IProduct[]) {
  const query = products
    .map(product => product._id)
    .map(_id => {
      return `objectID:${_id}`;
    })
    .join(" AND NOT ");

  if (products.length > 0) {
    return {
      facets: ["objectID"],
      filters: `NOT ${query}`,
    };
  } else {
    return null;
  }
}
