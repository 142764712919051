import fp from "lodash/fp";
import { Dictionary } from "ts-essentials";

export default function mapCounts(counts: any, loading: boolean): Dictionary<any> {
  return fp.mapValues(count => {
    if (typeof count === "number") {
      return count;
    }

    if (loading) {
      return null;
    } else {
      return count;
    }
  })(counts);
}
