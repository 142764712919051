import { Popconfirm } from "antd";
import React from "react";
import { Mutation } from "react-apollo";
import { IMutationResetOnboardingArgs } from "../../../../../types/types";
import ResetOnboarding from "../../../../mutations/ResetOnboarding";
import { IResetOnboardingMutation } from "../../../../mutations/__generated__/ResetOnboarding.generated";
import { IViewOnboardingControlMutationsProps } from "../Interfaces";
import { Button } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";

export default function ResetOnboardingMutation({
  userId,
  refetch,
  updateState,
}: IViewOnboardingControlMutationsProps) {
  return (
    <Mutation<IResetOnboardingMutation, IMutationResetOnboardingArgs>
      onCompleted={({ resetOnboarding }) => {
        if (updateState) {
          updateState(resetOnboarding?.workingFor);
        }
      }}
      variables={{
        userId: userId,
      }}
      mutation={ResetOnboarding}
      refetchQueries={["OnboardingsCount"]}
      awaitRefetchQueries={true}
    >
      {(resetOnboarding) => (
        <Popconfirm
          title="Do you want to reset the onboarding process?"
          onConfirm={async () => {
            await resetOnboarding();
            refetch();
          }}
        >
          <Button
            css={{
              marginRight: 5,
              backgroundColor: palette.lightestGray,
              border: "2px solid",
              borderColor: palette.lightGray,
              color: palette.text,
              fontWeight: 500,
            }}
            type="button"
          >
            Reset Onboarding
          </Button>
        </Popconfirm>
      )}
    </Mutation>
  );
}
