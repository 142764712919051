import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import { Popconfirm, Popover, Table, Tag } from "antd";
import moment from "moment";
import React from "react";
import { Mutation } from "react-apollo";
import { Toggle } from "react-powerplug";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { useToggle } from "react-use";
import DotdotdotIcon from "../../../components/icons/DotdotdotIcon";
import { ToggleDisruptor, ToggleInnovator } from "../../../mutations";
import AssignUser from "../../../mutations/AssignUser";
import DeleteUser from "../../../mutations/DeleteUser";
import ToggleUserBan from "../../../mutations/ToggleUserBan";
import {
  IAssignUserMutation,
  IAssignUserMutationVariables,
} from "../../../mutations/__generated__/AssignUser.generated";
import {
  IDeleteUserMutation,
  IDeleteUserMutationVariables,
} from "../../../mutations/__generated__/DeleteUser.generated";
import {
  IToggleUserBanMutation,
  IToggleUserBanMutationVariables,
} from "../../../mutations/__generated__/ToggleUserBan.generated";
import { IVendorQuery } from "../../../queries/__generated__/VendorQuery.generated";
import { Margin, SpacingBetween } from "../../../spacings";
import Impersonate from "../../debugging/Impersonate";
import EditVendorMembersModal from "../../teamManagement/modals/EditVendorMembersModal";
import AssignUserModal from "../../users/AssignUserModal";
import SignInAttemptsTable from "../../users/SignInAttemptsTable";

interface IVendorMembersTabProps {
  vendor: IVendorQuery["company"];
  refetch: () => any;
}

function VendorMembersTab({ vendor, refetch }: IVendorMembersTabProps) {
  const [showTeamBuilder, toggleTeamBuilder] = useToggle(false);

  if (!vendor) {
    return null;
  }

  return (
    <>
      <div
        css={{
          padding: 20,
        }}
      >
        <EditVendorMembersModal
          visible={showTeamBuilder}
          vendor={vendor}
          onRequestClose={toggleTeamBuilder}
          onSaveChanges={toggleTeamBuilder}
        />

        <Margin mb={12}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              toggleTeamBuilder();
            }}
          >
            Team Builder
          </a>
        </Margin>

        <Table
          rowKey="_id"
          dataSource={vendor.members!}
          style={{
            backgroundColor: palette.white,
          }}
          scroll={{
            x: true,
          }}
          columns={[
            {
              title: "Name",
              dataIndex: "fullName",
              sorter: (a, b) => String(a.fullName).localeCompare(String(b.fullName)),
              render: (fullName, user) => {
                return (
                  <Link
                    to={`/users/${user.slug}`}
                    css={{
                      display: "flex",
                    }}
                  >
                    <div
                      css={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ProgressiveImage src={user.avatar || ""} placeholder="">
                        {(src: string, loading: boolean) => (
                          <ProfilePicture
                            image={loading ? null : src}
                            innovator={false}
                            disruptor={user.isDisruptor}
                            name={fullName}
                            size={32}
                            css={{
                              whiteSpace: "nowrap",
                            }}
                          />
                        )}
                      </ProgressiveImage>

                      <Spacing left={10}>{fullName}</Spacing>
                    </div>
                  </Link>
                );
              },
            },
            {
              title: "Job Title",
              dataIndex: "jobTitle",
            },
            {
              title: "Tags",
              dataIndex: "role",
              render: (_, user) => (
                <span>
                  {user.role === "COMPANY_USER" && <Tag color="blue">Company User</Tag>}
                  {user.role === "COMPANY_OWNER" && <Tag color="geekblue">Company Owner</Tag>}
                  {user.isDisruptor && <Tag color="geekblue">Disruptor</Tag>}
                </span>
              ),
            },
            {
              title: "Joined",
              dataIndex: "createdAt",
              render: (createdAt) => {
                const diffInDays = moment().diff(moment(createdAt), "days");

                if (diffInDays >= 180) {
                  return moment(createdAt).format("MMMM DD, YYYY");
                }

                return moment(createdAt).fromNow();
              },
            },
            {
              title: "Seen",
              dataIndex: "lastSignInAt",
              render: (lastSignInAt) => {
                if (!lastSignInAt) {
                  return "N/A";
                }

                return moment(lastSignInAt).fromNow();
              },
            },
            {
              title: "Actions",
              render: (user) => {
                return (
                  <Toggle>
                    {({ on, toggle }) => (
                      <Popover
                        visible={on}
                        trigger="click"
                        title="User Actions"
                        placement="rightBottom"
                        onVisibleChange={toggle}
                        content={
                          <ul
                            css={{
                              margin: 0,
                              padding: 0,
                              listStyle: "none",
                              "> li:not(:first-of-type)": {
                                marginTop: 10,
                              },
                            }}
                          >
                            {user.__typename === "GovernmentUser" && (
                              <li>
                                <Link to={`/users?government=${user.government._id}&tab=governments`} onClick={toggle}>
                                  Relationships
                                </Link>
                              </li>
                            )}

                            {user.__typename === "GovernmentUser" && (
                              <ToggleInnovator entityId={user._id} entityType="user">
                                {(toggle) => (
                                  <li>
                                    <a
                                      href="#"
                                      color={user.isInnovator ? palette.red : undefined}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        toggle();
                                      }}
                                    >
                                      {user.isInnovator ? "Remove Innovator" : "Set Innovator"}
                                    </a>
                                  </li>
                                )}
                              </ToggleInnovator>
                            )}

                            {user.__typename === "GovernmentUser" && (
                              <li>
                                <Link to={`/governments/${user.government.slug}`} onClick={toggle}>
                                  View Government
                                </Link>
                              </li>
                            )}

                            {user.__typename === "VendorUser" && (
                              <ToggleDisruptor entityId={user._id} entityType="user">
                                {(toggle) => (
                                  <li>
                                    <a
                                      href="#"
                                      css={{
                                        color: user.isDisruptor ? palette.red : undefined,
                                      }}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        toggle(user);
                                      }}
                                    >
                                      {user.isDisruptor ? "Remove Disruptor" : "Set Disruptor"}
                                    </a>
                                  </li>
                                )}
                              </ToggleDisruptor>
                            )}

                            {user.__typename === "PendingUser" && (
                              <Toggle>
                                {({ on: isOpen, toggle }) => (
                                  <>
                                    <li>
                                      <a href="#" onClick={toggle}>
                                        Assign
                                      </a>
                                    </li>

                                    <Mutation<IAssignUserMutation, IAssignUserMutationVariables>
                                      mutation={AssignUser}
                                      refetchQueries={["AllUsers", "UsersCount"]}
                                    >
                                      {(assign, { loading, error }) => (
                                        <AssignUserModal
                                          isOpen={isOpen}
                                          user={user}
                                          submitting={loading}
                                          onRequestClose={toggle}
                                          error={error ? error.message : null}
                                          onSubmit={({ governmentId, vendorId, type }) => {
                                            assign({
                                              variables: {
                                                userId: user._id,
                                                vendorId,
                                                governmentId,
                                                type,
                                              },
                                            }).then(toggle);
                                          }}
                                        />
                                      )}
                                    </Mutation>
                                  </>
                                )}
                              </Toggle>
                            )}

                            <Impersonate>
                              {(impersonate) => (
                                <li>
                                  <a
                                    href="#"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      impersonate(user);
                                    }}
                                  >
                                    Impersonate
                                  </a>
                                </li>
                              )}
                            </Impersonate>

                            <li>
                              <Link to={`/users/${user.slug}`}>View</Link>
                            </li>

                            <Mutation<IDeleteUserMutation, IDeleteUserMutationVariables>
                              onCompleted={() => {
                                refetch();
                              }}
                              variables={{
                                userId: user._id,
                              }}
                              mutation={DeleteUser}
                              refetchQueries={["AllVendorsQuery"]}
                            >
                              {(deleteUser) => (
                                <li>
                                  <Popconfirm
                                    placement="topRight"
                                    title={`Are sure you want to archive ${user.fullName}?`}
                                    onConfirm={() => {
                                      deleteUser();
                                      toggle();
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <a
                                      href="#"
                                      css={{
                                        color: palette.red,
                                      }}
                                    >
                                      Archive
                                    </a>
                                  </Popconfirm>
                                </li>
                              )}
                            </Mutation>

                            <Mutation<IToggleUserBanMutation, IToggleUserBanMutationVariables>
                              onCompleted={() => {
                                refetch();
                              }}
                              variables={{
                                userId: user._id,
                              }}
                              mutation={ToggleUserBan}
                            >
                              {(banUser) => (
                                <li>
                                  {user.bannedAt ? (
                                    <Popconfirm
                                      placement="topRight"
                                      title={`Are sure you want to unblock ${user.fullName}?`}
                                      onConfirm={() => {
                                        banUser();
                                        toggle();
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <a
                                        href="#"
                                        css={{
                                          color: palette.red,
                                        }}
                                      >
                                        Unblock
                                      </a>
                                    </Popconfirm>
                                  ) : (
                                    <Popconfirm
                                      placement="topRight"
                                      title={`Are sure you want to block ${user.fullName}?`}
                                      onConfirm={() => {
                                        banUser();
                                        toggle();
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <a
                                        href="#"
                                        css={{
                                          color: palette.red,
                                        }}
                                      >
                                        Block
                                      </a>
                                    </Popconfirm>
                                  )}
                                </li>
                              )}
                            </Mutation>
                          </ul>
                        }
                      >
                        <span
                          css={{
                            role: "button",
                            cursor: "pointer",
                          }}
                        >
                          <DotdotdotIcon />
                        </span>
                      </Popover>
                    )}
                  </Toggle>
                );
              },
            },
          ]}
          pagination={false}
          bordered={true}
          expandedRowRender={(user) => {
            return (
              <div
                css={{
                  padding: 10,
                }}
              >
                <SpacingBetween top={20}>
                  {user.signInAttempts && user.signInAttempts.length > 0 && (
                    <>
                      <h3
                        css={{
                          fontWeight: 600,
                        }}
                      >
                        📌 Sign In Attempts ({user.signInAttempts.length})
                      </h3>
                      <SignInAttemptsTable attempts={user.signInAttempts} />
                    </>
                  )}
                </SpacingBetween>
              </div>
            );
          }}
        />
      </div>
    </>
  );
}

export default VendorMembersTab;
