import gql from "graphql-tag";
import CollectionSponsorsingSaleFields from "../queries/fragments/CollectionSponsoringSaleFields";

export default gql`
  mutation EditCollectionSponsoringSale($saleId: ObjectId!, $input: EditSaleInput!) {
    editCollectionSponsoringSale(saleId: $saleId, input: $input) {
      ...CollectionSponsoringSaleFields
    }
  }

  ${CollectionSponsorsingSaleFields}
`;
