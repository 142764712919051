import gql from "graphql-tag";

export default gql`
  fragment VendorMemberFragmentFields on VendorUser {
    _id
    slug
    role
    avatar
    fullName
    jobTitle
    email
    createdAt
    bannedAt
    lastSignInAt
    isDisruptor
    linkedinUrl
    linkedinId
    signInAttempts {
      registeredAt
      outcome
      ip
      userAgent
      lookup
      whitelisted
    }
  }
`;
