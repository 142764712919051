import { DeleteOutlined } from "@ant-design/icons";
import { Button, notification, Popconfirm } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import DeleteLandingPageConfig from "../../../mutations/DeleteLandingPageConfig";
import {
  IDeleteLandingPageConfigMutation,
  IDeleteLandingPageConfigMutationVariables,
} from "../../../mutations/__generated__/DeleteLandingPageConfig.generated";

interface IDeleteButtonProps {
  configId: string;
  onClick: (args: any) => any;
}

export default function DeleteButton({ configId, onClick }: IDeleteButtonProps) {
  const [deleteConfig] = useMutation<IDeleteLandingPageConfigMutation, IDeleteLandingPageConfigMutationVariables>(
    DeleteLandingPageConfig,
  );

  return (
    <li>
      <Popconfirm
        title="Are you sure?"
        onConfirm={onClick(() => {
          deleteConfig({
            variables: {
              id: configId,
            },
            refetchQueries: ["GetAllLandingPages"],
          }).then(
            () => {
              notification.open({
                type: "success",
                message: "Template deleted!",
                description: `Template deleted successfully!`,
              });
            },
            () => {
              notification.error({
                type: "error",
                message: "Deletion failed!",
                description: `Template deletion was not successful!`,
              });
            },
          );
        })}
      >
        <Button
          size="middle"
          type="link"
          icon={<DeleteOutlined />}
          css={{
            color: "red",
          }}
        >
          Delete
        </Button>
      </Popconfirm>
    </li>
  );
}
