import gql from "graphql-tag";
import GovernmentProjectFields from "../queries/fragments/GovernmentProjectFields";
import GroupProjectFields from "../queries/fragments/GroupProjectFields";

export default gql`
  mutation CreateProjectForGovernment($project: ProjectInput!, $government: ObjectId!) {
    createProjectForGovernment(project: $project, government: $government) {
      _id
      ...GovernmentProjectFields
      ...GroupProjectFields
    }
  }

  ${GovernmentProjectFields}
  ${GroupProjectFields}
`;
