import gql from "graphql-tag";

export default gql`
  mutation RestoreVendor($vendorId: ObjectId!) {
    restoreVendor(vendorId: $vendorId) {
      _id
      slug
      archivedAt
    }
  }
`;
