import React, { useState } from "react";
import DocumentTitle from "react-document-title";
import { notification } from "antd";
import PageIcon from "../../../icons/PageIcon";
import { faList } from "@fortawesome/free-solid-svg-icons";
import TextArea from "antd/lib/input/TextArea";
import BulkTaggingForm from "./BulkTaggingForm";
import * as palette from "@govlaunch/palette";
import useIsDesktop from "../../../hooks/useIsDesktop";
import GovernmentProjectFields from "../../../queries/fragments/GovernmentProjectFields";
import GroupProjectFields from "../../../queries/fragments/GroupProjectFields";
import VendorProjectFields from "../../../queries/fragments/VendorProjectFields";
import BasicProjectsTable from "../tables/BasicProjectsTable";
import { useQuery } from "react-apollo";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import { IAllProjectsByIdsQuery, IAllProjectsByIdsQueryVariables } from "../__generated__/AllProjectsByIds.generated";
import gql from "graphql-tag";
import BulkTaggingInformationSection from "./BulkTaggingInformationSection";

interface IProjectsState {
  projectsIds: string[];
  invalidIds: string[];
}

const AllProjectsByIdsQuery = gql`
  query AllProjectsByIds($ids: [ObjectId!]!, $cursor: Cursor, $limit: Int) {
    projectsByIds(ids: $ids, cursor: $cursor, limit: $limit) {
      items {
        _id
        ...GovernmentProjectFields
        ...GroupProjectFields
        ...VendorProjectFields
      }
    }
  }
  ${GovernmentProjectFields}
  ${GroupProjectFields}
  ${VendorProjectFields}
`;

export default function ProjectsBulkActionsImport() {
  const [projectIdsInput, setProjectIdsInput] = useState<string>();
  const [parsedProjectIds, setParsedProjectIds] = useState<IProjectsState>({
 projectsIds: [], invalidIds: [] 
});
  const isDesktop = useIsDesktop();

  const { data, loading } = useQuery<IAllProjectsByIdsQuery, IAllProjectsByIdsQueryVariables>(AllProjectsByIdsQuery, {
    variables: {
      ids: parsedProjectIds.projectsIds,
      limit: 500,
      cursor: calculateAndEncodeCursor({
 page: 1, size: 1000 
}),
    },
  });

  const queriedProjects = data?.projectsByIds?.items || [];

  return (
    <DocumentTitle title="Projects Bulk Actions | Admin">
      <div css={{
 overflow: "auto" 
}}
      >
        <div
          css={{
            padding: 24,
            borderBottom: "1px solid rgb(232, 232, 232)",
          }}
        >
          <h3
            css={{
              margin: 0,
              fontWeight: 700,
              fontSize: 24,
              lineHeight: 1.33,
            }}
          >
            <PageIcon icon={faList} /> Projects Bulk Tagging
          </h3>

          <p
            css={{
              margin: 0,
              lineHeight: 1.33,
            }}
          >
            Bulk actions for projects.
          </p>
        </div>

        <div
          css={{
            padding: 24,
          }}
        >
          <BulkTaggingInformationSection />
          <div
            css={{
              display: "flex",
              marginTop: 20,
              marginBottom: 20,
              flexDirection: isDesktop ? "row" : "column-reverse",
            }}
          >
            <div
              css={{
                width: isDesktop ? "50%" : "100%",
                margin: 5,
                padding: 10,
                border: "1px solid",
                borderColor: palette.lightGray,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <label>
                  Project IDs
                  <div>
                    <TextArea
                      value={projectIdsInput}
                      css={{
                        borderRadius: 5,
                        borderColor: parsedProjectIds.invalidIds.length > 0 ? "#FE5F51" : palette.lightGray,
                        fontSize: 12,
                        color: palette.mediumGray,
                      }}
                      rows={17}
                      onChange={(e: { target: { value: string } }) => {
                        const inputValue = e.target.value;
                        setProjectIdsInput(inputValue);

                        if (inputValue.length === 0) {
                          setParsedProjectIds({
 projectsIds: [], invalidIds: [] 
});
                          return;
                        }

                        const ids = inputValue.trim().replace(/\r\n/g, "\n").split("\n");
                        const invalid = ids.filter((id) => id.length !== 24 && id !== "");
                        if (ids.length > 0 && invalid.length === 0) {
                          setParsedProjectIds({
 projectsIds: ids, invalidIds: [] 
});
                        } else {
                          setParsedProjectIds({
 projectsIds: [], invalidIds: invalid 
});
                        }
                      }}
                    />
                    {parsedProjectIds.invalidIds.length > 0 && (
                      <div
                        css={{
                          marginLeft: "auto",
                          color: "#FE5F51",
                          fontSize: "0.875rem",
                          fontFamily: '"proxima-nova",Helvetica,Arial,sans-serif',
                        }}
                      >
                        {`The following IDs are invalid: ${parsedProjectIds.invalidIds.join(", ")}`}
                      </div>
                    )}
                  </div>
                </label>
              </div>
            </div>
            <div
              css={{
                width: isDesktop ? "50%" : "100%",
                margin: 5,
                padding: 10,
                border: "1px solid",
                borderColor: palette.lightGray,
              }}
            >
              <BulkTaggingForm
                projects={queriedProjects}
                submitDisabled={parsedProjectIds.projectsIds.length === 0}
                onSuccess={() => {
                  notification.success({
                    message: "Success!",
                    description: `Projects modified`,
                  });
                }}
                onError={() => {
                  notification.error({
                    message: "Error!",
                    description: "Something went wrong!",
                  });
                }}
              />
            </div>
          </div>
          <div css={{
 marginTop: 10 
}}
          >
            <BasicProjectsTable
              loading={loading}
              projects={queriedProjects}
              title={() => (
                <div
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div css={{
 display: "flex", flexDirection: "column" 
}}
                  >
                    <strong>{`Projects (${queriedProjects.length})`}</strong>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
}
