import gql from "graphql-tag";

export default gql`
  query GetDynamicConfigs {
    getDynamicConfigs {
      key
      value
    }
  }
`;
