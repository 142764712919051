import gql from "graphql-tag";

export default gql`
  mutation CreateNewsletterCampaign($input: NewsletterCampaignInput!) {
    createNewsletterCampaign(input: $input) {
      _id
      name
      dynamicContent {
        storiesCount
        projectsCount
      }
      countries {
        countryCode
        amount
      }
      archivedAt
    }
  }
`;
