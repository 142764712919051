import React from "react";
import { INewsletterStory } from "../../../../types/types";

interface ICampaignPreviewStoryCardProps {
  story: INewsletterStory;
}

export default function CampaignPreviewStoryCard({ story }: ICampaignPreviewStoryCardProps) {
  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      style={{
        borderCollapse: "collapse",
        borderSpacing: 0,
        width: "100%",
      }}
    >
      <tbody>
        <tr>
          <td
            align="center"
            valign="top"
            style={{
              padding: 0,
              margin: 0,
              width: "600px",
            }}
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              role="presentation"
              style={{
                borderCollapse: "collapse",
                borderSpacing: 0,
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td
                    align="left"
                    style={{
                      padding: 0,
                      margin: 0,
                      paddingBottom: "5px",
                    }}
                  >
                    <h1
                      style={{
                        margin: 0,
                        lineHeight: "24px",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        color: "#444444",
                        textAlign: "center",
                      }}
                    >
                      {story.title}
                    </h1>
                  </td>
                </tr>
                {story.coverImage && story.coverImage.url && (
                  <tr>
                    <td
                      align="center"
                      style={{
                        padding: 0,
                        margin: 0,
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        fontSize: 0,
                      }}
                    >
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={story.url || ""}
                        style={{
                          WebkitTextSizeAdjust: "none",
                          //@ts-ignore
                          msTextSizeAdjust: "none",
                          textDecoration: "none",
                          color: "#086BEF",
                          fontSize: "14px",
                        }}
                      >
                        <img
                          className="adapt-img"
                          src={story.coverImage?.url || ""}
                          alt="Read the full article..."
                          style={{
                            display: "block",
                            border: 0,
                            outline: "none",
                            textDecoration: "none",
                            width: "600px",
                          }}
                          title="Read the full article..."
                        />
                      </a>
                    </td>
                  </tr>
                )}

                <tr>
                  <td
                    align="left"
                    style={{
                      padding: 0,
                      margin: 0,
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        WebkitTextSizeAdjust: "none",
                        //@ts-ignore
                        msTextSizeAdjust: "none",
                        lineHeight: "24px",
                        color: "#333333",
                        fontSize: "16px",
                      }}
                    >
                      {story.introduction}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    align="center"
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <ReadArticleButton url={story.url || ""} podcastUrl={story.podcastUrl} />
                    {story.podcastUrl && <PodcastButton url={story.podcastUrl} />}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

interface IReadArticleButtonProps {
  url: string;
  podcastUrl: string | null;
}

function ReadArticleButton({ url, podcastUrl }: IReadArticleButtonProps) {
  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      className="es-left"
      style={{
        borderCollapse: "collapse",
        borderSpacing: "0px",
        float: "left",
        width: podcastUrl ? "50%" : "100%",
      }}
    >
      <tbody>
        <tr>
          <td
            align="center"
            className="es-m-p0r"
            style={{
              padding: 0,
              margin: 0,
              width: "240px",
            }}
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              role="presentation"
              style={{
                borderCollapse: "collapse",
                borderSpacing: "0px",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td
                    align="center"
                    className="es-m-p15t"
                    style={{
                      padding: 0,
                      margin: 0,
                      paddingTop: "10px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `
                      <!--[if mso]>
                        <a href="${url}" target="_blank">
                          <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" esdevVmlButton href="${url}" style="height:43px;v-text-anchor:middle;width:188px;" arcsize="7%" strokecolor="#086bef" strokeweight="1px" fillcolor="#086bef">
                            <w:anchorlock>
                            </w:anchorlock>
                            <center style='color:#ffffff;font-family:helvetica, "helvetica neue", arial, verdana, sans-serif;font-size:14px;font-weight:400;'>
                              Read Article
                            </center>
                          </v:roundrect>
                        </a>
                      <![endif]--> 
                      <!--[if !mso]><!-- -->
                        <span className="msohide es-button-border" style="border-style:solid;border-color:#086bef;background:#086bef;border-width:1px;display:inline-block;border-radius:3px;width:200px;mso-hide:all">
                          <a href="${url}" className="es-button es-button-1619142320352" target="_blank" style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:14px;border-style:solid;border-color:#086bef;border-width:10px 40px;display:inline-block;background:#086bef;border-radius:3px;font-family:helvetica, 'helvetica neue', arial, verdana, sans-serif;font-weight:normal;font-style:normal;line-height:17px;width:auto;text-align:center">
                            <img src="https://mcusercontent.com/88b300557f9ef581c3b0d7bf5/images/0408e3b5-e02d-255a-a58a-6690bb7a13d8.png" alt="icon" style="display:inline-block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;vertical-align:middle;margin-right:10px" width="20" align="absmiddle">
                              Read Article
                          </a>
                        </span> 
                      <!--<![endif]-->`,
                    }}
                  />
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

interface IPodcastButtonProps {
  url: string;
}

function PodcastButton({ url }: IPodcastButtonProps) {
  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      className="es-left"
      style={{
        borderCollapse: "collapse",
        borderSpacing: "0px",
        float: "left",
        width: "50%",
      }}
    >
      <tbody>
        <tr>
          <td
            align="center"
            className="es-m-p0r"
            style={{
              padding: 0,
              margin: 0,
              width: "240px",
            }}
          >
            <table
              cellPadding="0"
              cellSpacing="0"
              role="presentation"
              style={{
                borderCollapse: "collapse",
                borderSpacing: "0px",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td
                    align="center"
                    className="es-m-p15t"
                    style={{
                      padding: 0,
                      margin: 0,
                      paddingTop: "10px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `
                      <!--[if mso]>
                        <a href="${url}" target="_blank">
                          <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" esdevVmlButton href="${url}" style="height:43px;v-text-anchor:middle;width:198px;" arcsize="7%" strokecolor="#333333" strokeweight="1px" fillcolor="#ffffff">
                            <w:anchorlock></w:anchorlock>
                            <center style='color:#333333;font-family:helvetica, "helvetica neue", arial, verdana, sans-serif;font-size:14px;font-weight:400;'>
                              Listen to Podcast
                            </center>
                          </v:roundrect>
                        </a>
                      <![endif]--> 
                      <!--[if !mso]><!-- -->
                        <span class="msohide es-button-border" style="border-style:solid;border-color:#333333;background:#ffffff;border-width:1px;display:inline-block;border-radius:3px;width:200px;mso-hide:all">
                          <a href="${url}" class="es-button es-button-1628849566534" target="_blank" style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#333333;font-size:14px;border-style:solid;border-color:#ffffff;border-width:10px 30px;display:inline-block;background:#ffffff;border-radius:3px;font-family:helvetica, 'helvetica neue', arial, verdana, sans-serif;font-weight:normal;font-style:normal;line-height:17px;width:auto;text-align:center">
                            <img src="https://lchjnb.stripocdn.email/content/guids/CABINET_f58f6a7604aedc7d0bbb77dbb5977418/images/4851628850501920.png" alt="icon" width="16" style="display:inline-block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;vertical-align:middle;margin-right:10px" align="absmiddle">
                              Listen to Podcast
                          </a>
                        </span> 
                      <!--<![endif]-->`,
                    }}
                  />
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
