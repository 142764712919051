// UploadGovernmentsMutation.ts
import gql from "graphql-tag";

export default gql`
  mutation UploadGovernments(
    $uploadId: ID!
    $isoCountryCode: String!
    $isoCurrencyCode: String!
    $payload: [UploadGovernmentInput!]!
  ) {
    uploadGovernments(
      uploadId: $uploadId
      isoCountryCode: $isoCountryCode
      isoCurrencyCode: $isoCurrencyCode
      payload: $payload
    ) {
      totalCount
      addedCount
      skippedCount
    }
  }
`;
