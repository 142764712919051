import * as palette from "@govlaunch/palette";
import { LoginOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import React from "react";
import GovlaunchLogo from "./GovlaunchLogo";
import { useTitle } from "react-use";

interface ILoginProps {
  error: Error | null;
  loggingIn?: boolean;
  onLogin: () => any;
}

export default function Login({ error, loggingIn, onLogin }: ILoginProps) {
  useTitle("Login | Admin");
  return (
    <div
      css={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#f5f8fa",
      }}
    >
      <div
        css={{
          marginTop: 150,
        }}
      />

      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <GovlaunchLogo color={palette.primary} />
      </div>

      <div
        css={{
          marginTop: 25,
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            size="large"
            icon={<LoginOutlined />}
            danger={true}
            onClick={onLogin}
            loading={loggingIn}
            disabled={loggingIn}
          >
            {loggingIn ? "Please wait..." : "Log in with Google"}
          </Button>
        </div>
      </div>

      <div
        css={{
          marginTop: 25,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          css={{
            width: 400,
          }}
        >
          © {new Date().getFullYear()} <strong>Govlaunch, Inc.</strong> If you aren't a Govlaunch employee or authorized
          person, please do not attempt to use this site.
        </div>
      </div>

      {error && (
        <div
          css={{
            marginTop: 25,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            css={{
              width: 400,
            }}
          >
            <Alert message="Whoops" description={getErrorMessage(error)} type="error" showIcon={true} />
          </div>
        </div>
      )}
    </div>
  );
}

function getErrorMessage(error: Error) {
  if (!error) {
    return "Couldn't process the login";
  }

  if (/User cancelled/i.test(error.message)) {
    return "You've closed the authentication popup";
  }

  if (/You cannot access this site/.test(error.message)) {
    return "You're not authorized to access this site";
  }

  return "Couldn't process the login";
}
