import React, { useState } from "react";
import EmbedProjectModal from "./EmbedProjectModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDraftingCompass } from "@fortawesome/free-solid-svg-icons";

export default function EmbedProjectModalButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        onMouseDown={(event) => {
          event.preventDefault();
          if (event.button === 0) {
            setIsModalOpen(!isModalOpen);
          }
        }}
        css={{
          borderRadius: 5,
          cursor: "pointer",
          "&:hover": {
            background: "#f3f3f3",
          },
          padding: "4px 4px 4px 4px",
        }}
      >
        <FontAwesomeIcon
          icon={faDraftingCompass}
          style={{
            width: "24px",
            height: "24px",
          }}
          color="#ccc"
        />
      </div>

      <EmbedProjectModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
}
