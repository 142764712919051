import gql from "graphql-tag";

export default gql`
  mutation EditGroupGovernments($groupId: ObjectId!, $governments: [ObjectId!]!) {
    editGroupGovernments(groupId: $groupId, governments: $governments) {
      _id
      governments {
        _id
        slug
        name
      }
    }
  }
`;
