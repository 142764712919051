import PropTypes from "prop-types";
import React from "react";
import { Select } from "@govlaunch/select";

export default function StatusInput(props: any) {
  const { value } = props;

  const items: any = [
    {
      value: "ideation",
      label: "Ideation",
    },
    {
      value: "planning",
      label: "Planning",
    },
    {
      value: "inProgress",
      label: "In Progress",
    },
    {
      value: "complete",
      label: "Complete",
    },
  ];

  // if there's a status...let's allow the user to remove the current status by setting it to "No Status"
  if (value) {
    items.unshift({
      value: null,
      label: "No Status",
    });
  }

  return (
    <div>
      <Select {...props} size="large" placeholder="Select a status" items={items} />
    </div>
  );
}

StatusInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};
