import React from "react";
import { Field } from "react-final-form";
import { IGroup } from "../../../types/types";
import GroupAutoComplete from "../../features/teamManagement/GroupAutoComplete";

interface IGroupsInputProps {
  fieldName: string;
}

export default function GroupsInput({ fieldName }: IGroupsInputProps) {
  return (
    <Field name={fieldName}>
      {({ input }) => (
        <GroupAutoComplete
          onSelect={(selectedItem) => {
            const alreadyInGroups = (input.value || []).some(
              (candidate: Pick<IGroup, "_id" | "slug" | "name" | "logo">) => {
                return candidate._id === selectedItem._id;
              },
            );

            if (!alreadyInGroups) {
              input.onChange((input.value || []).concat(selectedItem));
            }
          }}
        />
      )}
    </Field>
  );
}
