import React from "react";
import GroupForm from "../GroupForm";
import * as palette from "@govlaunch/palette";
import { Mutation } from "react-apollo";
import EditGroupMutation from "../../../mutations/EditGroup";
import { convertValuesToVariables, convertGroupToInitialValues } from "../utils";
import { IEditGroupMutation, IEditGroupMutationVariables } from "../../../mutations/__generated__/EditGroup.generated";
import { IGroupFieldsFragment } from "../../../queries/fragments/__generated__/GroupFields.generated";
import GetGroupsCount from "../../../queries/GetGroupsCount";
import GroupsQuery from "../../../queries/GroupsQuery";
import { notification } from "antd";
import { useNavigate } from "react-router";

interface IGroupDetailsTabProps {
  group: IGroupFieldsFragment;
}

const GroupDetailsTab = ({ group }: IGroupDetailsTabProps) => {
  const navigate = useNavigate();

  return (
    <div
      css={{
        background: palette.washGray,
        height: "100%",
      }}
    >
      <Mutation<IEditGroupMutation, IEditGroupMutationVariables>
        refetchQueries={[
          {
            query: GroupsQuery,
          },
          {
            query: GetGroupsCount,
          },
        ]}
        mutation={EditGroupMutation}
      >
        {(editGroup, { error }) => (
          <GroupForm
            initialValues={convertGroupToInitialValues(group)}
            onSubmit={async (values) => {
              const result = await editGroup({
                variables: {
                  id: group._id,
                  group: convertValuesToVariables(values),
                },
              });

              if (result && result.data && result.data.updateGroup) {
                notification.open({
                  type: "success",
                  message: "Changes saved!",
                  description: `All your changes on ${result.data.updateGroup.name} were saved!`,
                });

                navigate(`/groups/${result.data.updateGroup.slug}`);
              }
            }}
            error={error ? error.message : null}
            submitButtonText="Save Changes"
          />
        )}
      </Mutation>
    </div>
  );
};

export default GroupDetailsTab;
