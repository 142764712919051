import React from "react";
import { ThemeProvider, theme, CSSReset } from "@govlaunch/web";
import { Form } from "react-final-form";
import * as Yup from "yup";
import { IBulkTaggingEntityType } from "../../../../types/types";
import validateFinalFormWithSchema from "@govlaunch/project/src/utils/validateFinalFormWithYupSchema";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import {
  IBulkTagByObjectIdsMutation,
  IBulkTagByObjectIdsMutationVariables,
} from "../form/__generated__/BulkTaggingForm.generated";
import { ApolloError } from "apollo-client";
import { IGovernmentProjectFieldsFragment } from "../../../queries/fragments/__generated__/GovernmentProjectFields.generated";
import { IGroupProjectFieldsFragment } from "../../../queries/fragments/__generated__/GroupProjectFields.generated";
import { IVendorProjectFieldsFragment } from "../../../queries/fragments/__generated__/VendorProjectFields.generated";
import BulkTaggingInnerForm, { convertValueToEnum } from "./BulkTaggingInnerForm";

interface IBulkTaggingFormProps {
  projects: Array<IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment>;
  onSuccess: () => void;
  onError: (err: ApolloError) => void;
  submitDisabled: boolean;
}

const BulkTagMutation = gql`
  mutation BulkTagByObjectIds(
    $entityType: BulkTaggingEntityType!
    $entityIds: [ObjectId!]!
    $tagIds: [ObjectId!]!
    $modifyStrategy: BulkTaggingModifyStrategy!
  ) {
    bulkTagByObjectIds(entityType: $entityType, entityIds: $entityIds, tagIds: $tagIds, modifyStrategy: $modifyStrategy)
  }
`;

export default function BulkTaggingForm({ projects, submitDisabled, onSuccess, onError }: IBulkTaggingFormProps) {
  const [bulkTagByObjectIds] = useMutation<IBulkTagByObjectIdsMutation, IBulkTagByObjectIdsMutationVariables>(
    BulkTagMutation,
    {
      refetchQueries: ["AllProjectsByIds"],
      onCompleted: onSuccess,
      onError: onError,
    },
  );

  const validationSchema = Yup.object().shape({
    tags: Yup.array().min(1, "Please select at least one tag"),
  });

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Form
        keepDirtyOnReinitialize={true}
        subscription={{
          values: true,
          submitting: true,
          submitError: true,
        }}
        initialValues={{
          tags: [],
          action: "add",
        }}
        validate={validateFinalFormWithSchema(validationSchema)}
        onSubmit={async (values) => {
          const variables = {
            entityType: IBulkTaggingEntityType.Project,
            entityIds: projects.map((project) => project._id),
            tagIds: values.tags.map((tag: { value: any }) => tag.value),
            modifyStrategy: convertValueToEnum(values.action),
          };

          await bulkTagByObjectIds({
            variables,
          });
        }}
      >
        {({ submitting, handleSubmit, submitError, values }) => (
          <BulkTaggingInnerForm
            projects={projects}
            handleSubmit={handleSubmit}
            submitting={submitting}
            submitDisabled={submitDisabled}
            submitError={submitError}
            values={values}
          />
        )}
      </Form>
    </ThemeProvider>
  );
}
