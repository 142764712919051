import gql from "graphql-tag";

export default gql`
  mutation CreateGroup($group: GroupInput!) {
    createGroup(group: $group) {
      _id
      slug
    }
  }
`;
