import React from "react";
import { useMutation } from "react-apollo";
import DocumentTitle from "react-document-title";
import { useNavigate } from "react-router";
import CreateNewsletterCampaignMutation from "./graphql/CreateNewsletterCampaignMutation";
import ListNewsletterCampaignsQuery from "./graphql/ListNewsletterCampaignsQuery";
import {
  ICreateNewsletterCampaignMutation,
  ICreateNewsletterCampaignMutationVariables,
} from "./graphql/__generated__/CreateNewsletterCampaignMutation.generated";
import * as palette from "@govlaunch/palette";
import { Form } from "react-final-form";
import NewsletterCampaignForm from "./NewsletterCampaignForm";
import validate from "../../validate";
import campaignValidationSchema from "./campaignValidationSchema";
import { TCampaignCountry } from "./ProjectsPerCountryField";
import { notification } from "antd";
import { FORM_ERROR } from "final-form";
import { checkForDuplicates, validateProjectsPerCountry } from "./validateProjectsPerCountry";

export default function CreateNewsletterCampaign() {
  const navigate = useNavigate();
  const [createNewsletterCampaign] = useMutation<
    ICreateNewsletterCampaignMutation,
    ICreateNewsletterCampaignMutationVariables
  >(CreateNewsletterCampaignMutation, {
    refetchQueries: [
      {
        query: ListNewsletterCampaignsQuery,
      },
    ],
    awaitRefetchQueries: true,
  });

  return (
    <DocumentTitle title="New Campaign">
      <div css={{
overflow: "auto"
}}
      >
        <div
          css={{
            borderBottom: "1px solid rgb(232, 232, 232)",
            padding: 20,
          }}
        >
          <h3
            css={{
              fontWeight: 700,
              fontSize: 24,
              margin: 0,
            }}
          >
            New Campaign
          </h3>
        </div>

        <div
          css={{
            background: palette.white,
            paddingTop: "30px",
            minHeight: "100vh",
            width: 1024,
          }}
        >
          <Form
            initialValues={{
              name: "",
              storiesCount: 3,
              projectsCount: 10,
              topCollections: 0,
              otherCollections: 0,
              projectsPerCountry: [
                {
                  id: new Date().getTime(),
                  countryCode: "US",
                  name: "United States",
                  amount: 1,
                },
              ],
            }}
            onSubmit={async (values) => {
              if (validateProjectsPerCountry(values.projectsPerCountry)) {
                return {
                  [FORM_ERROR]: "Invalid project per country setting",
                };
              }

              if (checkForDuplicates(values.projectsPerCountry)) {
                return {
                  [FORM_ERROR]: "Multiple country entries",
                };
              }

              const result = await createNewsletterCampaign({
                variables: {
                  input: {
                    name: values.name,
                    dynamicContent: {
                      storiesCount: values.storiesCount,
                      projectsCount: values.projectsCount,
                      topCollections: values.topCollections,
                      otherCollections: values.otherCollections,
                    },
                    countriesAmount: values.projectsPerCountry.map((item: TCampaignCountry) => ({
                      countryCode: item.countryCode,
                      amount: item.amount,
                    })),
                  },
                },
              });

              if (result && result.data && result.data.createNewsletterCampaign) {
                notification.success({
                  message: "Success!",
                  description: "Campaign saved successfully",
                });

                navigate("/campaigns/list");
              }
            }}
            validate={validate(campaignValidationSchema)}
            keepDirtyOnReinitialize={true}
          >
            {({ handleSubmit, submitting, submitError }) => (
              <NewsletterCampaignForm handleSubmit={handleSubmit} submitting={submitting} submitError={submitError} />
            )}
          </Form>
        </div>
      </div>
    </DocumentTitle>
  );
}
