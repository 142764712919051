import { Col, Grid, RowWrap } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import upperFirst from "lodash/fp/upperFirst";
import moment from "moment";
import numeral from "numeral";
import React, { Fragment } from "react";
import { ICompanyAccountBillingInfoQuery } from "../../../queries/__generated__/CompanyAccountBillingInfoQuery.generated";
import { IInvoiceStatus } from "../../../../types/types";
import { IStripeInvoiceFieldsFragment } from "../../../queries/fragments/__generated__/StripeInvoiceFields.generated";

interface IPaymentHistoryProps {
  company: NonNullable<ICompanyAccountBillingInfoQuery["company"]>;
}

export default function PaymentHistory({ company }: IPaymentHistoryProps) {
  return (
    <div>
      <Grid>
        <RowWrap>
          <Col width={[8 / 12]} mx="auto">
            <div
              css={{
                padding: 24,
                backgroundColor: palette.white,
                border: "solid 0.5px #e6e8ea",
              }}
            >
              <h1
                css={{
                  margin: 0,
                  color: "#212121",
                  fontWeight: 600,
                  fontSize: 20,
                }}
              >
                Invoices
              </h1>

              <hr
                css={{
                  width: "100%",
                  marginTop: 20,
                  border: "none",
                  borderBottom: `solid 1px #e6e8ea`,
                }}
              />

              <table
                css={{
                  boxSizing: "border-box",
                  width: "100%",
                  borderCollapse: "collapse",
                  "> thead > tr > th": {
                    boxSizing: "border-box",
                    padding: 12,
                    color: "#949195",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: 1.33,
                    letterSpacing: 0.6,
                  },
                  "& > tbody > tr > td": {
                    boxSizing: "border-box",
                    padding: 12,
                    color: "#949195",
                    fontSize: 14,
                    lineHeight: 1.33,
                    letterSpacing: 0.6,
                    textAlign: "left",
                  },
                }}
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Payment Method</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Receipt/Pay</th>
                  </tr>
                </thead>

                <tbody>
                  {company.invoices.map((invoice) => {
                    const isPartiallyRefunded =
                      invoice.amountRefunded > 0 && invoice.amountRefunded !== invoice.amount && invoice.amount !== 0;
                    const isCompletelyRefunded = invoice.amountRefunded === invoice.amount && invoice.amount !== 0;
                    const isRefunded = isPartiallyRefunded || isCompletelyRefunded;

                    const invoiceItems = invoice.items.map((invoiceItem) => {
                      return (
                        <tr key={invoiceItem.id}>
                          <td colSpan={1}>&nbsp;</td>
                          <td colSpan={3}>{invoiceItem.description || "Invoice Item"}</td>
                          <td colSpan={2}>&nbsp;</td>
                        </tr>
                      );
                    });

                    return (
                      <Fragment key={invoice.friendlyId}>
                        <tr>
                          <td>{invoice.friendlyId}</td>

                          <td>{invoice.paidAt ? moment(invoice.paidAt).format("MM/DD/YYYY") : "N/A"}</td>
                          <td>{paymentMethod(invoice.card)}</td>
                          <td
                            css={{
                              fontWeight: 500,
                              color: palette.darkGray,
                            }}
                          >
                            {isPartiallyRefunded ? (
                              <span>
                                {invoice.currency === "USD" ? "$" : null}
                                {numeral(invoice.amountRefunded / 100).format("0,00.00")}
                                of
                                {invoice.currency === "USD" ? "$" : null}{" "}
                                {numeral(invoice.amount / 100).format("0,00.00")}
                              </span>
                            ) : (
                              <span
                                css={{
                                  textDecoration: isCompletelyRefunded ? "line-through" : undefined,
                                }}
                              >
                                {invoice.currency === "USD" ? "$" : null}
                                {numeral(invoice.amount / 100).format("0,00.00")}
                              </span>
                            )}
                          </td>

                          <td>
                            {invoice.status === IInvoiceStatus.Paid && isRefunded && <span>Refunded</span>}
                            {invoice.status === IInvoiceStatus.Paid && !isRefunded && <span>Paid</span>}
                            {invoice.status === IInvoiceStatus.Void && <span>Voided</span>}
                            {invoice.status === IInvoiceStatus.Uncollectible && <span>Uncollectible</span>}
                            {invoice.status === IInvoiceStatus.Open && <span>Open</span>}
                            {invoice.status === IInvoiceStatus.Draft && <span>Draft</span>}
                          </td>

                          <td>
                            <a
                              href={invoice.receiptUrl || ""}
                              rel="noopener noreferrer"
                              target="_blank"
                              title={`Receipt for Charge ${invoice.id}`}
                              css={{
                                color: palette.darkSkyBlue,
                                fontWeight: 600,
                                textDecoration: "none",
                              }}
                            >
                              {invoice.status === IInvoiceStatus.Open ? "Pay" : "View"}
                            </a>
                          </td>
                        </tr>
                        {invoiceItems}
                      </Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>
        </RowWrap>
      </Grid>
    </div>
  );
}

function paymentMethod(card: IStripeInvoiceFieldsFragment["card"]) {
  if (card) {
    return `${upperFirst(card.brand)} ending **** ${card.lastFourDigits}`;
  }

  return "N/A";
}
