import React from "react";
import { IGovernmentUser, IVendorUser, IPendingUser, ICompany } from "../../../../types/types";
import ProfilePicture from "@govlaunch/profile-picture";
import * as palette from "@govlaunch/palette";

export type TStoryAuthor =
  | ({ __typename: "PendingUser" } & Pick<
      IPendingUser,
      "_id" | "slug" | "isSiteAdmin" | "fullName" | "jobTitle" | "avatar"
    >)
  | ({ __typename: "VendorUser" } & Pick<
      IVendorUser,
      "_id" | "slug" | "isSiteAdmin" | "fullName" | "jobTitle" | "title" | "avatar"
    >)
  | ({ __typename: "GovernmentUser" } & Pick<
      IGovernmentUser,
      "_id" | "slug" | "isSiteAdmin" | "fullName" | "jobTitle" | "title" | "avatar"
    >);

export type TStoryVendorAuthor = { __typename: "Company" } & Pick<ICompany, "_id" | "name" | "logo" | "slug">;

export type TCoauthor = {
  __typename: "GovernmentUser" | "VendorUser" | "PendingUser" | "Company";
  _id: any;
  firstName: string | null | undefined;
  slug: string;
  isSiteAdmin: boolean | null | undefined;
  isLimitedSiteAdmin: boolean | null | undefined;
  fullName: string | null | undefined;
  jobTitle: string | null | undefined;
  title: string | null | undefined;
  avatar: string | null | undefined;
  logo: string | null | undefined;
  name: string | null | undefined;
};

export function parseTCoauthorFromTStoryAuthor(author: TStoryAuthor): TCoauthor {
  if (author.__typename === "VendorUser" || author.__typename === "GovernmentUser") {
    return {
      __typename: author.__typename,
      _id: author._id,
      firstName: null,
      slug: author.slug,
      isSiteAdmin: author.isSiteAdmin,
      isLimitedSiteAdmin: null,
      fullName: author.fullName,
      jobTitle: author.fullName,
      title: author.title,
      avatar: author.avatar,
      logo: author.avatar,
      name: author.fullName,
    };
  }

  return {
    __typename: author.__typename,
    _id: author._id,
    firstName: null,
    slug: author.slug,
    isSiteAdmin: author.isSiteAdmin,
    isLimitedSiteAdmin: null,
    fullName: author.fullName,
    jobTitle: author.fullName,
    title: null,
    avatar: author.avatar,
    logo: author.avatar,
    name: author.fullName,
  };
}

interface IStoryAuthorCardProps {
  author: TCoauthor;
}

export default function StoryAuthorCard({ author }: IStoryAuthorCardProps) {
  // eslint-disable-next-line
  const authorTitle = author.isSiteAdmin
    ? "Co-founder at Govlaunch"
    : author.__typename !== "PendingUser"
    ? author.title || author.jobTitle
    : author.jobTitle;

  return (
    <div
      css={{
        background: "#fff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div
        css={{
          marginRight: 10,
          paddingTop: 5,
          paddingLeft: 5,
        }}
      >
        <ProfilePicture size={40} image={author.avatar} name={author.fullName} />
      </div>

      <p
        css={{
          fontSize: 16,
          color: palette.text,
          lineHeight: "20px",
          margin: 0,
        }}
      >
        {author.fullName}
      </p>
      <span
        css={{
          marginRight: 5,
        }}
      >
        ,
      </span>
      <p
        css={{
          fontSize: 14,
          color: palette.mediumGray,
          lineHeight: "20px",
          margin: 0,
        }}
      >
        {authorTitle}
      </p>
    </div>
  );
}
