import React from "react";
import { Mutation } from "react-apollo";
import { Popconfirm } from "antd";
import * as palette from "@govlaunch/palette";
import ArchiveSponsorship from "../../../mutations/ArchiveSponsorship";
import {
  IArchiveSponsorshipMutation,
  IArchiveSponsorshipMutationVariables,
} from "../../../mutations/__generated__/ArchiveSponsorship.generated";

interface IArchiveProps {
  id: any;
  name: string;
  refetch: () => any;
}

export default function Archive({ id, name, refetch }: IArchiveProps) {
  return (
    <Mutation<IArchiveSponsorshipMutation, IArchiveSponsorshipMutationVariables>
      mutation={ArchiveSponsorship}
      refetchQueries={["SponsorshipsCount"]}
    >
      {archive => (
        <li>
          <Popconfirm
            title={`Are you sure you want to archive ${name}?`}
            onConfirm={() => {
              archive({
                variables: {
                  id: id,
                },
              }).then(() => {
                return refetch();
              });
            }}
          >
            <span
              css={{
                cursor: "pointer",
                color: palette.red,
              }}
              role="button"
            >
              Archive
            </span>
          </Popconfirm>
        </li>
      )}
    </Mutation>
  );
}
