import gql from "graphql-tag";

export default gql`
  query GetAllImages($filter: ImagesFilterInput!, $sortBy: String, $limit: Int = 20, $cursor: Cursor) {
    images(filter: $filter, sortBy: $sortBy, limit: $limit, cursor: $cursor) {
      items {
        _id
        url
        alt
        source
        sourceType
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor
      }
    }
  }
`;
