import gql from "graphql-tag";
import LandingPageConfigFields from "./fragments/LandingPageConfigFields";

export default gql`
  query LandingPageConfig($id: ObjectId!) {
    landingPageConfig(id: $id) {
      ...LandingPageConfigFields
    }
  }
  ${LandingPageConfigFields}
`;
