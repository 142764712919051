import gql from "graphql-tag";
import SolutionFieldsFragment from "../fragments/SolutionFieldsFragment";

export default gql`
  mutation ToggleArchiveOnSolution($solutionId: ObjectId!) {
    toggleArchiveOnSolution(solutionId: $solutionId) {
      ...SolutionFields
    }
  }

  ${SolutionFieldsFragment}
`;
