import React, { useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router";
import { parseState } from "../../utils/tablesUtils";
import TablePagination, { DEFAULT_CURSOR, ITablePaginationState } from "../../components/table/TablePagination";
import { useQuery } from "react-apollo";
import {
  IGetAllLandingPagesQuery,
  IGetAllLandingPagesQueryVariables,
} from "../../queries/__generated__/GetAllLandingPages.generated";
import GetAllLandingPages from "../../queries/GetAllLandingPages";
import { calculateAndEncodeCursor } from "../../utils/encodeCursor";
import TableAddStateToQueryString from "../../components/table/TableAddStateToQueryString";
import TableOnSearch from "../../components/table/TableOnSearch";
import LandingPageTemplatesTable from "./LandingPageTemplatesTable";

interface IManageLandingPagesProps {
  search: string;
}

interface ILandingPagesState {
  cursor: ITablePaginationState;
}

export default function ManageLandingPages({ search }: IManageLandingPagesProps) {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<ILandingPagesState>(initialFiltersState);

  const { data, loading } = useQuery<IGetAllLandingPagesQuery, IGetAllLandingPagesQueryVariables>(GetAllLandingPages, {
    variables: {
      search,
      cursor: calculateAndEncodeCursor(state.cursor),
    },
    fetchPolicy: "network-only",
  });

  const templates = (data && data.allLandingPageConfigs && data.allLandingPageConfigs.items) || [];
  const totalCount =
    data && data.allLandingPageConfigs && data.allLandingPageConfigs.pageInfo
      ? data.allLandingPageConfigs.pageInfo.totalCount || 0
      : 0;

  return (
    <div>
      {typeof history !== "undefined" && history && (
        <TableAddStateToQueryString tableState={state} historyOrigin="/landingPage" query={query} />
      )}
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
                page: 1,
                size: state.cursor.size,
              },
            });
          }
        }}
      />
      <LandingPageTemplatesTable
        templates={templates}
        loading={loading}
        footer={() => (
          <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
        )}
      />
    </div>
  );
}
