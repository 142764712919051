import styled from "@emotion/styled";
import { Button, Col, Grid, RowWrap } from "@govlaunch/core";
import * as Yup from "yup";
import * as palette from "@govlaunch/palette";
import { Input, Modal, notification } from "antd";
import React, { useState } from "react";
import { Mutation, useMutation } from "react-apollo";
import { Field, Form } from "react-final-form";
import EditVendorCustomLimits from "../../../mutations/EditVendorCustomLimits";
import SetSubscriptionAsOverdue from "../../../mutations/SetSubscriptionAsOverdue";
import {
  IEditVendorCustomLimitsMutation,
  IEditVendorCustomLimitsMutationVariables,
} from "../../../mutations/__generated__/EditVendorCustomLimits.generated";
import {
  ISetSubscriptionAsOverdueMutation,
  ISetSubscriptionAsOverdueMutationVariables,
} from "../../../mutations/__generated__/SetSubscriptionAsOverdue.generated";
import { ICompanyAccountBillingInfoQuery } from "../../../queries/__generated__/CompanyAccountBillingInfoQuery.generated";
import validate from "../../../validate";
import {
  IResetVendorCustomLimitsMutation,
  IResetVendorCustomLimitsMutationVariables,
} from "../../../mutations/__generated__/ResetVendorCustomLimits.generated";
import ResetVendorCustomLimits from "../../../mutations/ResetVendorCustomLimits";
import { UsersCanAccessAccountPageToggleRow } from "./UsersCanAccessAccountPage";
import { UsersCanRequestContentTranslationToggleRow } from "./UsersCanRequestContentTranslation";

interface ICurrentPlanAndUsageProps {
  company: NonNullable<ICompanyAccountBillingInfoQuery["company"]>;
  refetch: () => any;
}

export default function CurrentPlanAndUsage({ company, refetch }: ICurrentPlanAndUsageProps) {
  const isOverdue = company.subscription && company.subscription.__typename === "OverdueSubscription";
  const [isEditing, setIsEditing] = useState(false);
  const canToggleInviteGovernments = getCanToggleInviteGovernments(company);
  const canToggleSearchAccess = getCanToggleSearchAccess(company);
  // this button is only for testing purposes
  // we're removing this from QA env as well so that people do not get confused about it
  // const showSetAsOverdueButton = true;

  const [resetVendorCustomLimits] = useMutation<
    IResetVendorCustomLimitsMutation,
    IResetVendorCustomLimitsMutationVariables
  >(ResetVendorCustomLimits, {
    refetchQueries: ["CompanyAccountBillingInfo"],
  });

  return (
    <>
      <Mutation<IEditVendorCustomLimitsMutation, IEditVendorCustomLimitsMutationVariables>
        mutation={EditVendorCustomLimits}
      >
        {(editVendorCustomLimits) => (
          <Form
            validate={validate(computeValidationSchema(company))}
            initialValues={{
              teamMembers: company.plan.limits.teamMembers,
              products: company.plan.limits.products,
              enrichedProducts: company.plan.limits.enrichedProducts,
              groups: company.plan.limits.groups,
              inviteGovernments: company.plan.limits.inviteGovernments,
              searchAccess: company.plan.limits.searchAccess,
            }}
            onSubmit={async (values: {
              teamMembers: any;
              products: any;
              enrichedProducts: any;
              groups: any;
              inviteGovernments: any;
              searchAccess: any;
            }) => {
              await editVendorCustomLimits({
                variables: {
                  vendorId: company._id,
                  customLimits: {
                    teamMembers: Number(values.teamMembers),
                    products: Number(values.products),
                    enrichedProducts: Number(values.enrichedProducts),
                    groups: Number(values.groups),
                    inviteGovernments: Boolean(values.inviteGovernments),
                    searchAccess: Boolean(values.searchAccess),
                  },
                },
              });

              refetch();

              setIsEditing(false);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid>
                  <RowWrap>
                    <Col width={[1, 1, 1, 8 / 12]} mx="auto">
                      <div
                        css={{
                          padding: 24,
                          backgroundColor: palette.white,
                          border: "solid 0.5px #e6e8ea",
                        }}
                      >
                        <div
                          css={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            css={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <h1
                              css={{
                                margin: 0,
                                color: "#212121",
                                fontWeight: 600,
                                fontSize: 20,
                                alignItems: "center",
                              }}
                            >
                              Account
                            </h1>
                            {company.hasCustomLimits && (
                              <span
                                css={{
                                  color: palette.primary,
                                  fontWeight: 600,
                                  fontSize: 12,
                                  padding: 8,
                                }}
                              >
                                Using custom limits
                              </span>
                            )}
                          </div>

                          <div
                            css={{
                              display: "grid",
                              gridAutoFlow: "column",
                              columnGap: 12,
                            }}
                          >
                            {isEditing ? (
                              <>
                                <Button
                                  size="xsmall"
                                  type="submit"
                                  onClick={(e: any) => {
                                    if (!window.confirm("Are you sure?")) {
                                      e.preventDefault();
                                    }
                                  }}
                                >
                                  Save
                                </Button>
                                <Button
                                  size="xsmall"
                                  color="gray"
                                  textColor="#fff"
                                  type="button"
                                  onClick={() => setIsEditing(false)}
                                >
                                  Cancel
                                </Button>
                              </>
                            ) : (
                              <Button
                                size="xsmall"
                                onClick={(e: React.SyntheticEvent) => {
                                  e.preventDefault();
                                  setIsEditing(true);
                                }}
                              >
                                Edit
                              </Button>
                            )}

                            {company.hasCustomLimits && (
                              <Button
                                size="xsmall"
                                onClick={(e: React.SyntheticEvent) => {
                                  e.preventDefault();

                                  const usage = {
                                    teamMembers: company.planUsage.teamMembers,
                                    products: company.planUsage.products,
                                    enrichedProducts: company.planUsage.enrichedProducts,
                                    groups: company.planUsage.groups,
                                  };
                                  const limits = {
                                    teamMembers: company.originalPlan.limits.teamMembers,
                                    products: company.originalPlan.limits.products,
                                    enrichedProducts: company.originalPlan.limits.enrichedProducts,
                                    groups: company.originalPlan.limits.groups,
                                  };

                                  if (checkUsage(usage, limits)) {
                                    Modal.warning({
                                      title: "Warning",
                                      content:
                                        "This vendor has settings higher than the default limits. Please remove extra team members, groups, or products before resetting.",
                                    });
                                  } else {
                                    resetVendorCustomLimits({
                                      variables: {
                                        vendorId: company._id,
                                      },
                                    }).then(() => {
                                      notification.open({
                                        type: "success",
                                        message: "Changes saved!",
                                        description: `All your changes were saved`,
                                      });
                                    });
                                  }
                                }}
                              >
                                Reset
                              </Button>
                            )}

                            {process.env.GOVLAUNCH_ENV !== "production" &&
                              !isOverdue &&
                              company.plan.name !== "Basic" &&
                              company.plan.name !== "Disruptor" && (
                                <Mutation<ISetSubscriptionAsOverdueMutation, ISetSubscriptionAsOverdueMutationVariables>
                                  mutation={SetSubscriptionAsOverdue}
                                  refetchQueries={["CompanyAccountBillingInfo"]}
                                >
                                  {(setAsOverdue, { loading }) => (
                                    <div
                                      css={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <Button
                                        disabled={loading}
                                        size="xsmall"
                                        onClick={() =>
                                          setAsOverdue({
                                            variables: {
                                              vendorSlug: company.slug,
                                            },
                                          })
                                        }
                                      >
                                        {loading ? "Loading ..." : "Set as Overdue"}
                                      </Button>

                                      {loading && (
                                        <p
                                          css={{
                                            marginTop: 3,
                                            fontSize: 12,
                                          }}
                                        >
                                          This operation may take 20~50 seconds
                                        </p>
                                      )}
                                    </div>
                                  )}
                                </Mutation>
                              )}
                          </div>
                        </div>

                        <hr
                          css={{
                            width: "100%",
                            marginTop: 20,
                            border: "none",
                            borderBottom: `solid 1px #e6e8ea`,
                          }}
                        />

                        <div
                          css={{
                            paddingTop: 20,
                          }}
                        >
                          <div
                            css={{
                              display: "grid",
                              gridTemplateColumns: "max-content 1fr",
                              gridColumnGap: 40,
                              gridRowGap: 30,
                            }}
                          >
                            <ItemTitle>Current Plan</ItemTitle>

                            <div
                              css={{
                                display: "grid",
                                gridRowGap: 10,
                              }}
                            >
                              <strong
                                css={{
                                  fontSize: 16,
                                  fontWeight: 600,
                                  color: palette.text,
                                }}
                              >
                                {company.plan.name}
                              </strong>
                            </div>

                            <ItemTitle>Team Members</ItemTitle>

                            <div>
                              {isEditing ? (
                                <Field name="teamMembers">
                                  {({ input, meta }) => (
                                    <FieldWrapper>
                                      <UsageCapLine usage={company.planUsage.teamMembers} />
                                      <Input
                                        type="number"
                                        style={{
                                          width: 80,
                                        }}
                                        {...input}
                                      />
                                      {company.hasCustomLimits && (
                                        <em>
                                          (default: <strong>{company.originalPlan.limits.teamMembers}</strong>)
                                        </em>
                                      )}
                                      <FieldError meta={meta} />
                                    </FieldWrapper>
                                  )}
                                </Field>
                              ) : (
                                <UsageCapLine
                                  usage={company.planUsage.teamMembers}
                                  cap={company.plan.limits.teamMembers}
                                  defaultLimit={
                                    company.hasCustomLimits ? company.originalPlan.limits.teamMembers : null
                                  }
                                />
                              )}
                            </div>

                            <ItemTitle>Products</ItemTitle>

                            {isEditing ? (
                              <Field name="products">
                                {({ input, meta }) => (
                                  <FieldWrapper>
                                    <UsageCapLine usage={company.planUsage.products} />
                                    <Input
                                      type="number"
                                      style={{
                                        width: 80,
                                      }}
                                      {...input}
                                    />
                                    <FieldError meta={meta} />
                                    {company.hasCustomLimits && (
                                      <em>
                                        (default: <strong>{company.originalPlan.limits.products}</strong>)
                                      </em>
                                    )}
                                  </FieldWrapper>
                                )}
                              </Field>
                            ) : (
                              <UsageCapLine
                                usage={company.planUsage.products}
                                cap={company.plan.limits.products}
                                defaultLimit={company.hasCustomLimits ? company.originalPlan.limits.products : null}
                              />
                            )}

                            <ItemTitle>Maker Products</ItemTitle>

                            {isEditing ? (
                              <Field name="enrichedProducts">
                                {({ input, meta }) => (
                                  <FieldWrapper>
                                    <UsageCapLine usage={company.planUsage.enrichedProducts} />
                                    <Input
                                      type="number"
                                      style={{
                                        width: 80,
                                      }}
                                      {...input}
                                    />
                                    {company.hasCustomLimits && (
                                      <em>
                                        (default: <strong>{company.originalPlan.limits.enrichedProducts}</strong>)
                                      </em>
                                    )}
                                    <FieldError meta={meta} />
                                  </FieldWrapper>
                                )}
                              </Field>
                            ) : (
                              <UsageCapLine
                                usage={company.planUsage.enrichedProducts}
                                cap={company.plan.limits.enrichedProducts}
                                defaultLimit={
                                  company.hasCustomLimits ? company.originalPlan.limits.enrichedProducts : null
                                }
                              />
                            )}

                            <ItemTitle>Groups</ItemTitle>

                            {isEditing ? (
                              <Field name="groups">
                                {({ input, meta }) => (
                                  <FieldWrapper>
                                    <UsageCapLine usage={company.planUsage.groups} />
                                    <Input
                                      type="number"
                                      style={{
                                        width: 80,
                                      }}
                                      {...input}
                                    />
                                    {company.hasCustomLimits && (
                                      <em>
                                        (default: <strong>{company.originalPlan.limits.groups}</strong>)
                                      </em>
                                    )}
                                    <FieldError meta={meta} />
                                  </FieldWrapper>
                                )}
                              </Field>
                            ) : (
                              <UsageCapLine
                                usage={company.planUsage.groups}
                                cap={company.plan.limits.groups}
                                defaultLimit={company.hasCustomLimits ? company.originalPlan.limits.groups : null}
                              />
                            )}

                            <ItemTitle>Sharing Hub</ItemTitle>

                            <div
                              css={{
                                display: "grid",
                                gridColumnGap: 12,
                                gridTemplateColumns: "repeat(2, max-content)",
                                alignItems: "center",
                                color: palette.text,
                                fontSize: 16,
                                "& > strong": {
                                  fontWeight: 600,
                                },
                              }}
                            >
                              {company.plan.limits.sharingPage ? (
                                <strong>Included</strong>
                              ) : (
                                <strong>Not available</strong>
                              )}

                              {company.planUsage.sharingPage ? (
                                <span
                                  css={{
                                    padding: "4px 12px",
                                    color: palette.disruptiveGreen,
                                    fontWeight: 600,
                                    fontSize: 14,
                                    backgroundColor: palette.lightestGreen,
                                    borderRadius: 12,
                                  }}
                                >
                                  Enabled
                                </span>
                              ) : (
                                <span
                                  css={{
                                    padding: "4px 12px",
                                    color: palette.red,
                                    fontWeight: 600,
                                    fontSize: 14,
                                    backgroundColor: palette.lightRed,
                                    borderRadius: 12,
                                  }}
                                >
                                  Disabled
                                </span>
                              )}
                            </div>

                            <ItemTitle>Invite Govts.</ItemTitle>

                            {isEditing ? (
                              <div>
                                <Field name="inviteGovernments" type="checkbox">
                                  {({ input }) => (
                                    <div
                                      css={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        css={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input {...input} disabled={!canToggleInviteGovernments} />
                                        <label
                                          css={{
                                            marginLeft: 8,
                                          }}
                                        >
                                          Enable Invite Governments
                                        </label>
                                      </div>

                                      {!canToggleInviteGovernments && (
                                        <span
                                          css={{
                                            marginTop: 8,
                                            fontSize: 12,
                                            color: "#949195",
                                          }}
                                        >
                                          You cannot disable if the user's plan includes the Invite Governments
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </div>
                            ) : (
                              <div
                                css={{
                                  display: "grid",
                                  gridColumnGap: 12,
                                  gridTemplateColumns: "repeat(2, max-content)",
                                  alignItems: "center",
                                  color: palette.text,
                                  fontSize: 16,
                                  "& > strong": {
                                    fontWeight: 600,
                                  },
                                }}
                              >
                                {company.plan.limits.inviteGovernments ? (
                                  <strong>Included</strong>
                                ) : (
                                  <strong>Not available</strong>
                                )}
                              </div>
                            )}

                            <ItemTitle>Search Access</ItemTitle>

                            {isEditing ? (
                              <div>
                                <Field name="searchAccess" type="checkbox">
                                  {({ input }) => (
                                    <div
                                      css={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        css={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input {...input} disabled={!canToggleSearchAccess} />
                                        <label
                                          css={{
                                            marginLeft: 8,
                                          }}
                                        >
                                          Enable Search Access
                                        </label>
                                      </div>

                                      {!canToggleSearchAccess && (
                                        <span
                                          css={{
                                            marginTop: 8,
                                            fontSize: 12,
                                            color: "#949195",
                                          }}
                                        >
                                          You cannot disable if the user's plan includes the Search Access
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </div>
                            ) : (
                              <div
                                css={{
                                  display: "grid",
                                  gridColumnGap: 12,
                                  gridTemplateColumns: "repeat(2, max-content)",
                                  alignItems: "center",
                                  color: palette.text,
                                  fontSize: 16,
                                  "& > strong": {
                                    fontWeight: 600,
                                  },
                                }}
                              >
                                {company.plan.limits.searchAccess ? (
                                  <strong>Included</strong>
                                ) : (
                                  <strong>Not available</strong>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </RowWrap>
                </Grid>
              </form>
            )}
          </Form>
        )}
      </Mutation>
      <div>
        <Grid>
          <RowWrap>
            <Col width={[1, 1, 1, 8 / 12]} mx="auto">
              <div
                css={{
                  padding: 24,
                  backgroundColor: palette.white,
                  border: "solid 0.5px #e6e8ea",
                }}
              >
                <div
                  css={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <h1
                      css={{
                        margin: 0,
                        color: "#212121",
                        fontWeight: 600,
                        fontSize: 20,
                        alignItems: "center",
                      }}
                    >
                      Settings
                    </h1>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: 5,
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                      fontStyle: "italic",
                      color: palette.darkGray,
                    }}
                  >
                    * Changes made to the settings take affect immediately
                  </span>
                </div>
                <hr
                  css={{
                    width: "100%",
                    marginTop: 20,
                    border: "none",
                    borderBottom: `solid 1px #e6e8ea`,
                  }}
                />
                <UsersCanAccessAccountPageToggleRow
                  usersCanAccessAccountPage={company.usersCanAccessAccountPage}
                  vendorId={company._id}
                />
                <UsersCanRequestContentTranslationToggleRow
                  suggestContentTranslation={company.usersCanRequestContentTranslation}
                  vendorId={company._id}
                />
              </div>
            </Col>
          </RowWrap>
        </Grid>
      </div>
    </>
  );
}

interface IUsageCapLineProps {
  usage: number;
  cap?: number;
  defaultLimit?: number | null;
}

function UsageCapLine({ usage, cap, defaultLimit }: IUsageCapLineProps) {
  return (
    <div
      css={{
        fontSize: 16,
        color: palette.text,
        "& > strong": {
          fontWeight: 600,
        },
      }}
    >
      <strong>{usage}</strong>
      <span> of </span>
      {cap !== undefined && <strong>{cap}</strong>}
      {defaultLimit !== null && defaultLimit !== undefined && (
        <em
          css={{
            fontSize: 14,
          }}
        >
          {" "}
          (default: <strong>{defaultLimit}</strong>)
        </em>
      )}
    </div>
  );
}

const ItemTitle = styled.div({
  fontSize: 16,
  fontWeight: 600,
  color: "#949195",
});

const FieldWrapper = styled.div({
  display: "grid",
  alignItems: "center",
  justifyContent: "flex-start",
  gridColumnGap: 8,
  gridAutoFlow: "column",
});

/**
 * Basic accounts can have this value changed, but if it's a paid plan just if the plan doesn't
 * include the invite governments
 */
function getCanToggleInviteGovernments(company: NonNullable<ICompanyAccountBillingInfoQuery["company"]>) {
  if (company.plan.name === "Basic") {
    return true;
  }

  return !company.originalPlan.limits.inviteGovernments;
}

function getCanToggleSearchAccess(company: NonNullable<ICompanyAccountBillingInfoQuery["company"]>) {
  if (company.plan.name === "Basic" || company.plan.name === "Makers") {
    return true;
  }

  return !company.originalPlan.limits.searchAccess;
}

function FieldError({ meta }: IFieldErrorProps) {
  if (meta.touched && (meta.error || meta.submitError)) {
    return (
      <div
        css={{
          marginLeft: 10,
          color: palette.red,
          textTransform: "uppercase",
          fontSize: 12,
        }}
      >
        {meta.error || meta.submitError}
      </div>
    );
  } else {
    return null;
  }
}

interface IFieldErrorProps {
  meta: Partial<{
    touched: boolean;
    error: any;
    submitError: any;
  }>;
}

function computeValidationSchema(company: NonNullable<ICompanyAccountBillingInfoQuery["company"]>) {
  const { limits } = company.originalPlan;

  return Yup.object({
    teamMembers: Yup.number().min(
      limits.teamMembers,
      `Must be equal or greater than the original plan limit ${limits.teamMembers}`,
    ),
    products: Yup.number().min(
      limits.products,
      `Must be equal or greater than the original plan limit ${limits.products}`,
    ),
    enrichedProducts: Yup.number().min(
      limits.enrichedProducts,
      `Must be equal or greater than the original plan limit ${limits.enrichedProducts}`,
    ),
    groups: Yup.number().min(limits.groups, `Must be equal or greater than the original plan limit ${limits.groups}`),
  });
}

type TUsageData = {
  teamMembers: number;
  products: number;
  enrichedProducts: number;
  groups: number;
};

function checkUsage(usage: TUsageData, limits: TUsageData) {
  if (usage.teamMembers > limits.teamMembers) {
    return true;
  }

  if (usage.products > limits.products) {
    return true;
  }

  if (usage.enrichedProducts > limits.enrichedProducts) {
    return true;
  }

  if (usage.groups > limits.groups) {
    return true;
  }

  return false;
}
