import React from "react";
import { Menu } from "antd";
import * as palette from "@govlaunch/palette";

interface IPopoverTitleProps {
  title: string;
}

export default function PopoverTitle({ title }: IPopoverTitleProps) {
  return (
    <>
      <Menu.Item
        key={`collapsed-title-${title}`}
        onClick={(e) => {
          e.domEvent.preventDefault();
          e.domEvent.stopPropagation();
          e.domEvent.defaultPrevented = true;
        }}
        disabled={true}
        css={{
          backgroundColor: palette.white,
          color: "#000 !important",
          cursor: "default",
          maxHeight: 30,
          fontWeight: "bold",
        }}
      >
        <span>{title}</span>
      </Menu.Item>
      <Menu.Divider key={`collapsed-title-divider-${title}`} />
    </>
  );
}
