import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { parseState } from "../../../utils/tablesUtils";
import { useQuery } from "react-apollo";
import AllUsersQuery from "../../../queries/AllUsersQuery";
import { IAllUsersQuery, IAllUsersQueryVariables } from "../../../queries/__generated__/AllUsersQuery.generated";
import queryString from "query-string";
import getSortBy from "../utils/tableUtils";
import UsersTable from "./UsersTable";
import TableAddStateToQueryString from "../../../components/table/TableAddStateToQueryString";
import fp from "lodash/fp";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import TablePagination, { DEFAULT_CURSOR, ITablePaginationState } from "../../../components/table/TablePagination";
import { SorterResult } from "antd/lib/table/interface";
import TableTitle from "../../../components/table/TableTitle";
import TableOnSearch from "../../../components/table/TableOnSearch";

export interface IUsersTableState {
  sorter?: SorterResult<any>;
  role: any;
  plan: any;
  cursor: ITablePaginationState;
}

interface IManageUsersProps {
  filter?: string | null;
  filterByTag?: string | null;
  government?: string | null;
  company?: string | null;
  search: string | null;
  historyOrigin?: string;
}

export default function ManageUsers({
  filter,
  filterByTag = null,
  government = null,
  company = null,
  search = null,
  historyOrigin = "/users",
}: IManageUsersProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    role: null,
    plan: null,
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<IUsersTableState>(initialFiltersState);

  const { data, loading, refetch } = useQuery<IAllUsersQuery, IAllUsersQueryVariables>(AllUsersQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: initialFiltersState.role || filter,
      government,
      company,
      search,
      plan: initialFiltersState.plan || null,
      tags: filterByTag ? [filterByTag] : null,
      sortBy: initialFiltersState.sorter ? getSortBy(initialFiltersState.sorter) : "",
      cursor: calculateAndEncodeCursor(state.cursor),
    },
  });

  const users = (data && data.allUsers && data.allUsers.items) || [];
  const totalCount = data && data.allUsers && data.allUsers.pageInfo ? data.allUsers.pageInfo.totalCount || 0 : 0;

  return (
    <>
      <TableAddStateToQueryString tableState={state} historyOrigin={historyOrigin} query={query} />
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
 page: 1, size: state.cursor.size 
},
            });
          }
        }}
      />
      <UsersTable
        title={() => (
          <TableTitle
            title="Users"
            count={totalCount}
            loading={loading}
            onResetFilters={() => {
              setState({
                role: null,
                plan: null,
                cursor: {
 page: 1, size: state.cursor.size 
},
              });

              navigate(`${historyOrigin}?tab=${query.tab || ""}&search=${search}`);
            }}
          />
        )}
        footer={() => (
          <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
        )}
        loading={loading}
        users={users}
        filter={state.role || filter}
        onChange={(_, filters: any, sorter) => {
          if (Array.isArray(sorter)) {
            return;
          }

          setState({
            role: fp.first(filters.role) || filter || null,
            plan: fp.first(filters["company.plan"]) || null,
            sorter,
            cursor: {
 page: 1, size: state.cursor.size 
},
          });
        }}
        onDeleteUser={() => {
          refetch();
        }}
        onBanUser={() => {
          refetch();
        }}
        state={state}
        sorter={true}
      />
    </>
  );
}
