import { IGetSponsorshipQuery } from "../../../queries/__generated__/GetSponsorship.generated";

export default function convertValuesToInitialVariables(sponsorship: NonNullable<IGetSponsorshipQuery["sponsorship"]>) {
  return {
    _id: sponsorship._id,
    slug: sponsorship.slug,
    name: sponsorship.name,
    logo: sponsorship.logo,
    link: sponsorship.link,
  };
}
