import React, { MouseEventHandler } from "react";
import DelayedHover from "../DelayedHover";
import Popover from "@govlaunch/popover";

interface ITooltipProps {
  children: (arg: any) => any;
  [key: string]: any;
}

export default function Tooltip({ children, ...props }: ITooltipProps) {
  return (
    <DelayedHover ms={100}>
      {({
        hover,
        onMouseEnter,
        onMouseLeave,
      }: {
        hover: boolean;
        onMouseEnter: MouseEventHandler;
        onMouseLeave: MouseEventHandler;
      }) => (
        <Popover
          placement="top"
          isOpen={hover}
          boxStyle={{
            zIndex: 1050,
          }}
          {...props}
        >
          {({ innerRef }: any) =>
            children({
              hover,
              bind: {
                onMouseEnter,
                onMouseLeave,
              },
              innerRef,
            })
          }
        </Popover>
      )}
    </DelayedHover>
  );
}
