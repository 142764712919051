import * as Yup from "yup";
import { ensureFilledString } from "../../../ensureFills";
import isValidUrl, { isValidUrlYup } from "../../../utils/isValidUrl";

export default Yup.object({
  name: Yup.string().transform(ensureFilledString).required("can't be blank"),
  billingName: Yup.string().nullable(),
  description: Yup.string().transform(ensureFilledString).required("can't be blank"),
  yearFounded: Yup.number()
    .required("can't be blank")
    .min(1500, "invalid year")
    .max(new Date().getFullYear(), "invalid year"),
  site: Yup.string().transform(ensureFilledString).required("can't be blank").test("url", "invalid url", isValidUrlYup),
  contact: Yup.string().test({
    message: "contact must be a url or email",
    test: (value) => {
      if (!value) {
        return true;
      }

      const emailSchema = Yup.string().email();

      const validUrl = isValidUrl(value);
      let validEmail;

      try {
        emailSchema.validateSync(value);

        validEmail = true;
      } catch (e) {
        validEmail = false;
      }

      return validEmail || validUrl;
    },
  }),
  socialMedia: Yup.object({
    linkedin: Yup.string().url("invalid URL").nullable(),
    twitter: Yup.string().url("invalid URL").nullable(),
    facebook: Yup.string().url("invalid URL").nullable(),
    instagram: Yup.string().url("invalid URL").nullable(),
    youtube: Yup.string().url("invalid URL").nullable(),
  }).nullable(),
});
