import { Checkbox } from "antd";
import React from "react";
import { Field, FormSpy } from "react-final-form";

export default function TagPublishField() {
  return (
    <FormSpy
      subscription={{
        values: true,
      }}
    >
      {({ values }) => {
        const isDisabled =
          values.types.includes("PROJECT") || values.types.includes("PRODUCT") || values.types.includes("STORY");

        return (
          <Field name="publish" type="checkbox" defaultValue={0} disabled={isDisabled}>
            {({ input }) => (
              <div
                css={{
                  borderTop: "1px solid rgb(232, 232, 232)",
                  paddingTop: 12,
                  marginTop: 12,
                }}
              >
                <Checkbox disabled={isDisabled} {...input}>
                  Publish Collection
                </Checkbox>
              </div>
            )}
          </Field>
        );
      }}
    </FormSpy>
  );
}
