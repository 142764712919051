import React from "react";
import * as palette from "@govlaunch/palette";
import { Field } from "react-final-form";
import { Checkbox } from "./SubscribedToNewsletter";

export default function SubscribedToCampaign() {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <label
          css={{
            fontWeight: "bold",
            fontSize: 14,
            color: palette.mediumGray,
            margin: 0,
          }}
          htmlFor="subscribedToCampaign"
        >
          Govlaunch Updates
        </label>
      </div>
      <Field name="subscribedToCampaign" type="checkbox">
        {({ input }) => (
          <div
            css={{
              display: "flex",
              alignItems: "flex-start",
              margin: 4,
            }}
          >
            <div
              css={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox id="subscribedToCampaign" type="checkbox" {...input} />
            </div>
          </div>
        )}
      </Field>
    </div>
  );
}
