import { SearchAlgoliaIndex } from "@govlaunch/algolia";
import { Input, LoadingIndicator } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import Downshift from "downshift";
import { debounce, pick, uniqBy } from "lodash/fp";
import React, { memo, ChangeEvent, FocusEvent } from "react";
import { Value } from "react-powerplug";

interface IVendorsInputProps {
  onChange: (event?: ChangeEvent<HTMLElement>) => void;
  onBlur: (event?: FocusEvent<HTMLElement>) => void;
  onFocus: (event?: FocusEvent<HTMLElement>) => void;
  value: any;
}

interface ISearchAlgoliaChildren {
  onChange: (value: string) => any;
  loading: boolean;
  results?: IAlgoliaCompany[];
}

interface IAlgoliaCompany {
  _id: string;
  objectID: string;
  name: string;
  slug: string;
  description?: string;
  logo: string;
}

function VendorsInput({ value, onChange, onBlur, onFocus }: IVendorsInputProps) {
  return (
    <SearchAlgoliaIndex
      fetchOnMount={false}
      index="companies"
      params={{
        hitsPerPage: 5,
        restrictSearchableAttributes: ["name"],
        ...buildIgnoreFilters(value),
      }}
    >
      {({ onChange: setSearchQuery, loading, results }: ISearchAlgoliaChildren) => {
        return (
          <Value initial="" onChange={debounce(300, setSearchQuery)}>
            {({ value: inputValue, set: setInputValue }) => (
              <Downshift
                itemToString={vendor => {
                  if (vendor) {
                    return vendor._id;
                  } else {
                    return null;
                  }
                }}
                inputValue={inputValue}
                onChange={selectedVendor => {
                  setInputValue("");

                  const nextVendors = uniqBy(vendor => vendor._id || vendor.objectID, [
                    ...value,
                    {
                      ...pick(["_id", "name", "logo", "description"], selectedVendor),
                      addedAt: Date.now(),
                    },
                  ]);

                  onChange(nextVendors as any);
                }}
                stateReducer={(_, changes) => {
                  switch (changes.type) {
                    case Downshift.stateChangeTypes.changeInput:
                      return {
                        ...changes,
                        highlightedIndex: null,
                      };
                    default:
                      return changes;
                  }
                }}
                onInputValueChange={(inputValue, changes) => {
                  if ((changes as any).type === Downshift.stateChangeTypes.changeInput) {
                    setInputValue(inputValue);
                  }
                }}
              >
                {({ getInputProps, getItemProps, highlightedIndex, isOpen }) => (
                  <div>
                    <div
                      css={{
                        position: "relative",
                      }}
                    >
                      <Input
                        {...getInputProps({
                          onBlur,
                          onFocus,
                        })}
                        size="small"
                        placeholder="Type a vendor name…"
                        maxLength={30}
                      />

                      {loading && (
                        <div
                          css={{
                            position: "absolute",
                            top: "50%",
                            transform: "translateY(-50%)",
                            right: 10,
                          }}
                        >
                          <LoadingIndicator />
                        </div>
                      )}
                    </div>

                    {isOpen && results && results.length > 0 && (
                      <div
                        css={{
                          marginTop: 10,
                        }}
                      >
                        <div
                          css={{
                            boxShadow: "0 6px 10px 0 rgba(225, 225, 225, 0.5)",
                            border: `solid 1px ${palette.lightestSealBlue}`,
                            backgroundColor: palette.white,
                            borderRadius: 5,
                            padding: 10,
                          }}
                        >
                          <div
                            css={{
                              display: "grid",
                              gridRowGap: 10,
                            }}
                          >
                            {uniqBy(vendor => vendor.objectID || vendor._id, results).map((vendor, index) => {
                              const isSelected = highlightedIndex === index;

                              return (
                                <div
                                  key={vendor.objectID || vendor._id}
                                  css={{
                                    display: "grid",
                                    gridTemplateAreas: '"logo name" "logo description"',
                                    gridTemplateColumns: "auto 1fr",
                                    gridColumnGap: 10,
                                    gridRowGap: 0,
                                    padding: 5,
                                    cursor: "pointer",
                                  }}
                                  {...getItemProps({
                                    item: vendor,
                                    index,
                                  })}
                                >
                                  <div
                                    css={{
                                      gridArea: "logo",
                                      display: "inline-flex",
                                    }}
                                  >
                                    <ProfilePicture name={vendor.name} image={vendor.logo || ""} size={32} />
                                  </div>

                                  <span
                                    css={{
                                      gridArea: "name",
                                      color: isSelected ? palette.primary : palette.sealBlue,
                                      fontSize: 14,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {vendor.name}
                                  </span>

                                  <span
                                    css={{
                                      gridArea: "description",
                                      color: palette.sealBlue,
                                      fontSize: 12,
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {vendor.description}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Downshift>
            )}
          </Value>
        );
      }}
    </SearchAlgoliaIndex>
  );
}

function buildIgnoreFilters(vendors: IAlgoliaCompany[]) {
  const query = vendors
    .map(vendor => vendor._id)
    .map(_id => {
      return `objectID:${_id}`;
    })
    .join(" AND NOT ");

  if (vendors.length > 0) {
    return {
      facets: ["objectID"],
      filters: `NOT ${query}`,
    };
  } else {
    return null;
  }
}

export default memo(VendorsInput);
