import React from "react";
import { Mutation } from "react-apollo";
import AddSponsorship from "../../../mutations/AddSponsorship";
import {
  IAddSponsorshipMutation,
  IAddSponsorshipMutationVariables,
} from "../../../mutations/__generated__/AddSponsorship.generated";
import { Form } from "react-final-form";
import validationSchema from "./validationSchema";
import validate from "../../../validate";
import convertValuesToVariables from "./convertValuesToVariables";
import SponsorshipForm from "./SponsorshipForm";
import { notification } from "antd";
import { useNavigate } from "react-router";

export default function AddSponsorshipForm() {
  const navigate = useNavigate();

  return (
    <Mutation<IAddSponsorshipMutation, IAddSponsorshipMutationVariables> mutation={AddSponsorship}>
      {(addSponsorship) => {
        return (
          <Form
            keepDirtyOnReinitialize={true}
            validate={validate(validationSchema)}
            onSubmit={(values) => {
              return addSponsorship({
                variables: {
                  sponsorship: convertValuesToVariables(values),
                },
              }).then(() => {
                notification.open({
                  type: "success",
                  message: "Sponsorship added!",
                  description: `Sponsorship added successfully!`,
                });

                navigate(`/sponsorships`);
              });
            }}
          >
            {({ handleSubmit, submitting }) => (
              <SponsorshipForm submitting={submitting} onSubmit={handleSubmit} submitButtonText="Add Sponsorship" />
            )}
          </Form>
        );
      }}
    </Mutation>
  );
}
