/* eslint-disable react/display-name */
import { Tabs } from "antd";
import queryString from "query-string";
import React, { HTMLProps } from "react";
import DocumentTitle from "react-document-title";
import UsersCount from "./UsersCount";
import { useLocation, useNavigate } from "react-router";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import UsersWithSearch from "./tables/UsersWithSearch";
import { MetaBaseQuestion } from "../../components/MetaBaseLink";
import TablePageHeader from "../../components/table/TablePageHeader";

const { TabPane } = Tabs;

export default function Users() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = query.tab || "pending";

  return (
    <DocumentTitle title="Users | Admin">
      <>
        <TablePageHeader
          title="Users"
          titleIcon={faUserFriends}
          subTitle="Manage all Users"
          metabaseQuestion={MetaBaseQuestion.Users}
        />
        <UsersCount>
          {({
            refetch,
            governmentUsersCount,
            vendorUsersCount,
            pendingUsersCount,
            verifiedCommunityUsersCount,
            innovatorsCount,
            disruptorsCount,
            citizensCount,
            blockedCount,
          }) => (
            <Tabs
              animated={false}
              onChange={(tab) => {
                navigate(`/users?tab=${tab}`);
                refetch();
              }}
              activeKey={tab as string}
              size="middle"
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
            >
              <TabPane tab={pendingUsersCount ? `Pending (${pendingUsersCount})` : "Pending"} key="pending">
                {tab === "pending" && (
                  <TabContent>
                    <UsersWithSearch filter="PENDING_USERS" />
                  </TabContent>
                )}
              </TabPane>

              <TabPane
                tab={
                  verifiedCommunityUsersCount
                    ? `Community Verified (${verifiedCommunityUsersCount})`
                    : "Community Verified"
                }
                key="communityVerified"
              >
                {tab === "communityVerified" && (
                  <TabContent>
                    <UsersWithSearch filter="VERIFIED_COMMUNITY_USERS" />
                  </TabContent>
                )}
              </TabPane>

              <TabPane
                tab={governmentUsersCount ? `Government Users (${governmentUsersCount})` : "Government Users"}
                key="governments"
              >
                {tab === "governments" && (
                  <TabContent>
                    <UsersWithSearch filter="GOVERNMENT" government={query.government as string} />
                  </TabContent>
                )}
              </TabPane>

              <TabPane tab={vendorUsersCount ? `Vendor Users (${vendorUsersCount})` : "Vendor Users"} key="vendors">
                {tab === "vendors" && (
                  <TabContent>
                    <UsersWithSearch filter="VENDOR" company={query.company as string} />
                  </TabContent>
                )}
              </TabPane>

              <TabPane tab={innovatorsCount ? `Innovators (${innovatorsCount})` : "Innovators"} key="innovators">
                {tab === "innovators" && (
                  <TabContent>
                    <UsersWithSearch filter="INNOVATORS" />
                  </TabContent>
                )}
              </TabPane>

              <TabPane tab={disruptorsCount ? `Disruptors (${disruptorsCount})` : "Disruptors"} key="disruptors">
                {tab === "disruptors" && (
                  <TabContent>
                    <UsersWithSearch filter="DISRUPTORS" />
                  </TabContent>
                )}
              </TabPane>

              <TabPane tab={citizensCount ? `Citizens (${citizensCount})` : "Citizens"} key="citizens">
                {tab === "citizens" && (
                  <TabContent>
                    <UsersWithSearch filter="CITIZENS" />
                  </TabContent>
                )}
              </TabPane>

              <TabPane tab={blockedCount ? `Blocked (${blockedCount})` : "Blocked"} key="blocked">
                {tab === "blocked" && (
                  <TabContent>
                    <UsersWithSearch filter="BLOCKED" />
                  </TabContent>
                )}
              </TabPane>
            </Tabs>
          )}
        </UsersCount>
      </>
    </DocumentTitle>
  );
}

function TabContent(props: HTMLProps<HTMLDivElement>) {
  return (
    <div
      css={{
        padding: 20,
      }}
      {...props}
    />
  );
}
