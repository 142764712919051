import React from "react";
import * as palette from "@govlaunch/palette";

interface IFieldErrorProps {
  meta: Partial<{
    touched: boolean;
    error: any;
    submitError: any;
  }>;
}

export default function FieldError({ meta }: IFieldErrorProps) {
  if (meta.touched && (meta.error || meta.submitError)) {
    return (
      <div
        css={{
          marginLeft: 10,
          color: palette.red,
          textTransform: "uppercase",
          fontSize: 12,
        }}
      >
        {meta.error || meta.submitError}
      </div>
    );
  } else {
    return null;
  }
}
