import React from "react";
import { Link } from "react-router-dom";

interface ISubMenuItemProps {
  title: string;
  href: string;
  children?: React.ReactElement;
  collapsed: boolean;
}

export default function SubMenuItem({ title, collapsed, href, children }: ISubMenuItemProps) {
  return (
    <li
      css={{
 height: 40 
}}
      key={`sub-menu-li-${title}`}
      onClick={() => {
        if (collapsed) {
          document.getElementById("hiddenMenuItem")?.click();
        }
      }}
    >
      <Link
        key={`sub-menu-link-${title}`}
        to={href}
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          height: "100%",
          width: "100%",
          color: "#000",
          paddingLeft: collapsed ? 15 : 60,
        }}
      >
        <span>{title}</span>
        {children}
      </Link>
    </li>
  );
}
