import React from "react";
import { Field } from "react-final-form";
import { Input } from "@govlaunch/core/form";
import { Margin } from "../../../spacings";
import FieldValidationError from "../../../components/form/FieldValidationError";
import CharacterLimitIndicator from "../../../components/form/CharacterLimitIndicator";

export function TitleField() {
  return (
    <div
      css={{
        display: "grid",
        columnGap: 10,
        gridTemplateColumns: "1fr",
        alignItems: "start",
      }}
    >
      <Field name="title">
        {({ input, meta }) => (
          <div>
            <Input {...input} maxLength={101} placeholder="Type a title for your project…" size="small" padding={10} />

            <Margin mt={10}>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <FieldValidationError meta={meta} />
                </div>

                <CharacterLimitIndicator
                  maxLength={100}
                  description={input.value}
                  css={{
                    marginLeft: "auto",
                  }}
                />
              </div>
            </Margin>
          </div>
        )}
      </Field>
    </div>
  );
}
