import React from "react";
import * as palette from "@govlaunch/palette";
import RemoveButton from "./RemoveButton";

interface ILinkCardProps {
  url: string;
  title: string;
  favicon: string | null;
  description: string;
  siteName: string;
  onRemove?: Function | null;
}

export default function LinkCard({ url, title, favicon, description, siteName, onRemove = null }: ILinkCardProps) {
  return (
    <div
      css={{
        background: palette.washGray,
        borderRadius: 4,
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.17)",
        position: "relative",
      }}
    >
      {onRemove && (
        <RemoveButton
          onClick={() => onRemove(url)}
          css={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        />
      )}

      <a
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        css={{
          display: "block",
          padding: 24,
        }}
      >
        <div>
          <div
            css={{
              display: "flex",
            }}
          >
            {favicon && (
              <img
                src={favicon}
                width={30}
                height={30}
                css={{
                  marginRight: 8,
                }}
              />
            )}

            <div>
              <p
                css={{
                  margin: 0,
                  fontWeight: 500,
                  fontSize: 16,
                  color: palette.text,
                  letterSpacing: 0.57,
                }}
              >
                {title}
              </p>
              <p
                css={{
                  margin: "0 0 0 0",
                  fontSize: 12,
                  color: palette.darkGray,
                }}
              >
                {siteName}
              </p>
            </div>
          </div>

          <p
            css={{
              margin: "16px 0 0",
              fontSize: 14,
              letterSpacing: 0.5,
              color: palette.text,
            }}
          >
            {description}
          </p>
        </div>
      </a>
    </div>
  );
}
