import gql from "graphql-tag";
import GovernmentProjectFields from "../queries/fragments/GovernmentProjectFields";
import GroupProjectFields from "../queries/fragments/GroupProjectFields";

export default gql`
  mutation CreateProjectForGroup($project: ProjectInput!, $group: ObjectId!) {
    createProjectForGroup(project: $project, group: $group) {
      _id
      ...GovernmentProjectFields
      ...GroupProjectFields
    }
  }

  ${GovernmentProjectFields}
  ${GroupProjectFields}
`;
