import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import React from "react";
import { ICompany } from "../../../../types/types";

interface IVendorItemProps {
  vendor: Pick<ICompany, "_id" | "name" | "logo" | "description">;
  onRemove?: (vendor: Pick<ICompany, "_id" | "name" | "logo" | "description">) => any;
  style?: React.CSSProperties;
}

function VendorItem({ vendor, onRemove, ...props }: IVendorItemProps) {
  return (
    <div
      {...props}
      css={{
        display: "grid",
        gridTemplateAreas: '"vendor remove"',
        gridTemplateColumns: "auto 1fr",
        gridColumnGap: onRemove ? 10 : 0,
        gridRowGap: 2.5,
        alignItems: "center",
        backgroundColor: "rgba(219, 236, 247, 0.5)",
        borderRadius: 60,
        padding: "10px 15px",
      }}
    >
      <div
        css={{
          gridArea: "vendor",
          display: "grid",
          gridTemplateAreas: '"logo name" "logo description"',
          gridTemplateColumns: "auto 1fr",
          gridColumnGap: 10,
          gridRowGap: 2.5,
          alignItems: "center",
          "&:focus": {
            outline: 0,
          },
        }}
      >
        <ProfilePicture
          image={vendor.logo || ""}
          name={vendor.name}
          size={28}
          css={{
            userSelect: "none",
            gridArea: "logo",
          }}
        />

        <div
          css={{
            gridArea: "name",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontSize: 14,
            fontWeight: 600,
            color: palette.primary,
            lineHeight: 1,
          }}
        >
          {vendor.name}
        </div>

        <div
          css={{
            gridArea: "description",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontSize: 12,
            color: palette.primary,
            lineHeight: 1,
          }}
        >
          {vendor.description}
        </div>
      </div>

      {onRemove && (
        <svg
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            onRemove(vendor);
          }}
          viewBox="0 0 117 117"
          width={28}
          height={28}
          css={{
            gridArea: "remove",
            cursor: "pointer",
            marginLeft: "auto",
            "&:hover": {
              opacity: 0.8,
            },
          }}
        >
          <g fill="#FF7171" fillRule="evenodd">
            <path
              fillRule="nonzero"
              d="M58.5 116.4c31.9 0 57.9-26 57.9-57.9S90.4.6 58.5.6.6 26.6.6 58.5s26 57.9 57.9 57.9zm0-107.7c27.4 0 49.8 22.3 49.8 49.8 0 27.5-22.3 49.8-49.8 49.8-27.5 0-49.8-22.4-49.8-49.8S31.1 8.7 58.5 8.7z"
            />
            <path d="M31.8 62h53.3c2.3 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1H31.8c-2.3 0-4.1 1.8-4.1 4.1s1.9 4.1 4.1 4.1z" />
          </g>
        </svg>
      )}
    </div>
  );
}

export default VendorItem;
