import { faScroll } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import * as palette from "@govlaunch/palette";

interface IAddStoryContentButtonProps {
  buttonText?: string;
  onClick: () => void;
  fullHeight?: boolean;
}

export default function AddStoryContentButton({
  buttonText = "Add Content",
  onClick,
  fullHeight,
}: IAddStoryContentButtonProps) {
  return (
    <div
      css={{
        borderRadius: 4,
        height: fullHeight ? "100%" : undefined,
        marginTop: !fullHeight ? "12px" : undefined,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        css={{
          width: "80px",
          height: "80px",
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            opacity: 0.8,
          },
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={faScroll}
          color={palette.primary}
          style={{
            width: "32px",
            height: "32px",
          }}
        />
        <div
          css={{
            marginTop: 8,
            fontSize: 12,
            color: palette.primary,
          }}
        >
          {buttonText}
        </div>
      </div>
    </div>
  );
}
