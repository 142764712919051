import gql from "graphql-tag";

export default gql`
  query AutoCompleteGovernments($searchQuery: String!, $limit: Int!) {
    autoCompleteGovernments(searchQuery: $searchQuery, limit: $limit, scopes: []) {
      _id
      name
      slug
      logo
    }
  }
`;
