import gql from "graphql-tag";

export default gql`
  query GetPlanById($planId: ObjectId!) {
    getPlanById(planId: $planId) {
      _id
      name
      displayName
      tier
      stripePlanId
      limits {
        products
        enrichedProducts
        groups
        groupInvitations
        teamMembers
        projectsPerMonth
        enrichedProfile
        productTags
        sharingPage
        searchAccess
        inviteGovernments
      }
      vendorCount
    }
  }
`;
