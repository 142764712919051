import { useMutation } from "react-apollo";
import React from "react";
import AddPlan from "../../mutations/AddPlan";
import { Form } from "react-final-form";
import { IPlanTier } from "../../../types/types";
import validate from "../../validate";
import planValidationSchema from "./planValidationSchema";
import { notification } from "antd";
import PlanForm from "./PlanForm";
import Modal from "antd/lib/modal/Modal";
import { IAddPlanMutation, IAddPlanMutationVariables } from "../../mutations/__generated__/AddPlan.generated";
import { FORM_ERROR } from "final-form";
import { defaultPlanLimitsDecorator } from "./defaultPlanLimits";

interface IAddPlanModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AddPlanModal({ isOpen, onClose }: IAddPlanModalProps) {
  const [addPlan] = useMutation<IAddPlanMutation, IAddPlanMutationVariables>(AddPlan, {
    refetchQueries: ["GetAllPlans"],
  });

  return (
    <Modal title="Add Plan" visible={isOpen} onCancel={onClose} footer={null} width={600}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Form
          keepDirtyOnReinitialize={true}
          initialValues={{
            name: "",
            displayName: "",
            tier: IPlanTier.Makerspro,
            stripePlanId: null,
            products: 20,
            enrichedProducts: 10,
            productTags: 5,
            projectsPerMonth: 20,
            groups: 3,
            groupInvitations: 10,
            teamMembers: 10,
            enrichedProfile: true,
            sharingPage: true,
            searchAccess: true,
            inviteGovernments: true,
          }}
          decorators={[defaultPlanLimitsDecorator] as any}
          validate={validate(planValidationSchema)}
          onSubmit={async (values) => {
            try {
              const planLimits = {
                products: values.products,
                enrichedProducts: values.enrichedProducts,
                groups: values.groups,
                groupInvitations: values.groupInvitations,
                teamMembers: values.teamMembers,
                productTags: values.productTags,
                projectsPerMonth: values.projectsPerMonth,
                enrichedProfile: values.enrichedProfile,
                sharingPage: values.sharingPage,
                searchAccess: values.searchAccess,
                inviteGovernments: values.inviteGovernments,
              };

              const result = await addPlan({
                variables: {
                  plan: {
                    name: values.name,
                    displayName: values.displayName,
                    tier: values.tier,
                    stripePlanId: values.stripePlanId,
                    limits: planLimits,
                  },
                },
              });

              if (result && result.data) {
                notification.success({
                  message: "Success!",
                  description: "Plan saved successfully",
                });

                onClose();
              }
            } catch (e) {
              if (/No such price/i.test(e.message)) {
                return {
                  [FORM_ERROR]: "Stripe Plan ID doesn't exist in Stripe",
                };
              }

              if (/Another plan with ID/i.test(e.message)) {
                return {
                  [FORM_ERROR]: "Another plan with the same stripe plan ID already exists",
                };
              }

              return {
                [FORM_ERROR]: "Could not save plan",
              };
            }
          }}
        >
          {({ submitting, handleSubmit, submitError }) => (
            <PlanForm handleSubmit={handleSubmit} submitting={submitting} submitError={submitError} addMode={true} />
          )}
        </Form>
      </div>
    </Modal>
  );
}
