export const isWord = (mimeType: string) => {
  return ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(
    mimeType,
  );
};

export const isPdf = (mimeType: string) => {
  return "application/pdf" === mimeType;
};

export const isSheet = (mimeType: string) => {
  return ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"].includes(
    mimeType,
  );
};

export const isImage = (mimeType: string) => {
  return ["image/jpeg", "image/jpg", "image/png", "image/gif"].includes(mimeType);
};
