import gql from "graphql-tag";

export default gql`
  query VendorsCount {
    vendorsCount {
      allCount
      activeCount
      inactiveCount
      disruptorsCount
      archivedCount
    }
  }
`;
