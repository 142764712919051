import * as palette from "@govlaunch/palette";
import React from "react";
import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import CharacterLimitIndicator from "../../../components/form/CharacterLimitIndicator";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { Margin } from "../../../spacings";
import { IFormFieldProps } from "./FormFieldProps";

export default function TextareaField({
  fieldName,
  label,
  placeholder,
  characterLimit,
  showCharacterCount,
  ariaLabel,
  ...props
}: IFormFieldProps) {
  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: palette.mediumGray,
                margin: 0,
              }}
            >
              {label}
            </label>

            <FieldValidationError meta={meta} />
          </div>

          <Margin mt={8}>
            <TextareaAutosize
              css={{
                "::placeholder": {
                  color: palette.sealBlue,
                },
                ":focus": {
                  borderColor: palette.primary,
                },
                fontSize: 14,
                border: `1px solid ${palette.lightestGray}`,
                borderRadius: 5,
                padding: "10px 10px",
                width: "100%",
                outline: 0,
                resize: "none",
              }}
              maxLength={characterLimit}
              minRows={2}
              maxRows={6}
              aria-label={ariaLabel || placeholder || ""}
              placeholder={placeholder}
              {...input}
              {...props}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
            />
          </Margin>

          {characterLimit && showCharacterCount && (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 8,
              }}
            >
              <CharacterLimitIndicator
                maxLength={characterLimit}
                description={input.value}
                css={{
                  marginLeft: "auto",
                }}
              />
            </div>
          )}
        </div>
      )}
    </Field>
  );
}
