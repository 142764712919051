import gql from "graphql-tag";

export default gql`
  query SponsorshipsCount {
    sponsorshipsCount {
      allCount
      archivedCount
    }
  }
`;
