import React from "react";
import { Tabs } from "antd";
import DocumentTitle from "react-document-title";
import DetailsTab from "./tabs/DetailsTab";
import GetGovernmentQuery from "../../queries/GetGovernmentQuery";
import { Query } from "react-apollo";
import GovernmentMembersTab from "./tabs/GovernmentMembersTab";
import ProjectsTab from "./tabs/ProjectsTab";
import StoriesTab from "./tabs/StoriesTab";
import ProductsTab from "./tabs/ProductsTab";
import PostsTab from "./tabs/PostsTab";
import {
  IGetGovernmentQuery,
  IGetGovernmentQueryVariables,
} from "../../queries/__generated__/GetGovernmentQuery.generated";
import { NetworkStatus } from "apollo-client";
import VerifiedProjectsTab from "./tabs/VerifiedProjectsTab";
import TabContent from "../../components/TabContent";
import { useNavigate, useParams } from "react-router";

export default function Government() {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <DocumentTitle title="Edit Government | Admin">
      <Query<IGetGovernmentQuery, IGetGovernmentQueryVariables>
        query={GetGovernmentQuery}
        notifyOnNetworkStatusChange={true}
        variables={{
          slug: params.slug || "",
          projectsCursor: null,
          storiesCursor: null,
        }}
      >
        {({ data, networkStatus, refetch, fetchMore }) => {
          const loading = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables;
          const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

          if (loading || !data) {
            return null;
          }

          const { government } = data;

          if (!government) {
            return null;
          }

          return (
            <Tabs
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
              activeKey={params.tab || "details"}
              onChange={(tab) => navigate(`/governments/${government.slug}${tab !== "details" ? `/${tab}` : ""}`)}
            >
              <Tabs.TabPane tab="Details" key="details">
                <TabContent>
                  <DetailsTab government={government} />
                </TabContent>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Members" key="members">
                <TabContent>
                  <GovernmentMembersTab government={government} refetch={refetch} />
                </TabContent>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Projects" key="projects">
                <TabContent>
                  <ProjectsTab
                    government={government}
                    refetch={refetch}
                    loading={isFetchingMore}
                    fetchMore={fetchMore}
                  />
                </TabContent>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Verified Projects" key="verifiedProjects">
                <TabContent>
                  <VerifiedProjectsTab
                    government={government}
                    refetch={refetch}
                    loading={isFetchingMore}
                    fetchMore={fetchMore}
                  />
                </TabContent>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Stories" key="stories">
                <TabContent>
                  <StoriesTab
                    government={government}
                    refetch={refetch}
                    loading={isFetchingMore}
                    fetchMore={fetchMore}
                  />
                </TabContent>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Products" key="products">
                <TabContent>
                  <ProductsTab government={government} />
                </TabContent>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Posts" key="posts">
                <TabContent>
                  <PostsTab government={government} refetch={refetch} />
                </TabContent>
              </Tabs.TabPane>
            </Tabs>
          );
        }}
      </Query>
    </DocumentTitle>
  );
}
