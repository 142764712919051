import { BackTop } from "antd";
import React from "react";
import DocumentTitle from "react-document-title";
import PageIcon from "../../icons/PageIcon";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import ManageProjectVerifications from "./projectsVerification/ManageProjectVerifications";

export default function ProjectVerification() {
  return (
    <DocumentTitle title="Project Verification | Admin">
      <>
        <div
          css={{
            padding: 24,
            borderBottom: "1px solid rgb(232, 232, 232)",
          }}
        >
          <h3
            css={{
              margin: 0,
              fontWeight: 700,
              fontSize: 24,
              lineHeight: 1.33,
            }}
          >
            <PageIcon icon={faCheckSquare} /> Project Verification Requests
          </h3>

          <p
            css={{
              margin: 0,
              lineHeight: 1.33,
            }}
          >
            Use the tabs below to manage Government's Project Verification requests on behalf of users.
          </p>
        </div>

        <BackTop />

        <ManageProjectVerifications />
      </>
    </DocumentTitle>
  );
}
