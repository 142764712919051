import { Button, Modal } from "antd";
import React, { ReactElement } from "react";
import { Mutation } from "react-apollo";
import { Form as FinalForm } from "react-final-form";
import { Toggle } from "react-powerplug";
import { IUser } from "../../../types/types";
import RemoveUserVerification from "../../mutations/RemoveUserVerification";
import RemoveCitizenVerificationMutation from "../../mutations/RemoveCitizenVerification";
import {
  IRemoveCitizenVerificationMutation,
  IRemoveCitizenVerificationMutationVariables,
} from "../../mutations/__generated__/RemoveCitizenVerification.generated";
import {
  IRemoveVerificationMutation,
  IRemoveVerificationMutationVariables,
} from "../../mutations/__generated__/RemoveUserVerification.generated";
import VerificationForm from "./VerificationForm";
import { VerificationType } from "./VerifyUser";

interface IRemoveVerificationProps {
  user: Pick<IUser, "_id" | "email" | "fullName" | "linkedinUrl" | "linkedinId">;
  children: (toggle: Function) => ReactElement | null;
}

function RemoveVerification({ user, children }: IRemoveVerificationProps) {
  return (
    <Mutation<IRemoveVerificationMutation, IRemoveVerificationMutationVariables> mutation={RemoveUserVerification}>
      {(removeVerification) => (
        <Toggle initial={false}>
          {({ on, toggle }) => (
            <>
              <FinalForm
                onSubmit={async (values) => {
                  await removeVerification({
                    variables: {
                      userId: user._id,
                      explanation: values.explanation,
                      attachments: values.attachments,
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: ["AllUsers", "UsersCount"],
                  });
                }}
                initialValues={{
                  explanation: "",
                  attachments: [],
                }}
              >
                {({ submitting, handleSubmit }) => (
                  <Modal
                    title={`Remove ${user.fullName} verification`}
                    visible={on}
                    onCancel={toggle}
                    footer={[
                      <Button key="back" onClick={toggle}>
                        Cancel
                      </Button>,
                      <Button key="submit" danger={true} loading={submitting} onClick={handleSubmit}>
                        {submitting ? "Please wait..." : "Remove"}
                      </Button>,
                    ]}
                  >
                    <VerificationForm
                      verificationType={VerificationType.Community}
                      user={user}
                      onSubmit={handleSubmit}
                    />
                  </Modal>
                )}
              </FinalForm>

              {children(toggle)}
            </>
          )}
        </Toggle>
      )}
    </Mutation>
  );
}

export default RemoveVerification;

interface IRemoveCitizenVerificationProps {
  user: Pick<IUser, "_id" | "email" | "fullName" | "linkedinUrl" | "linkedinId">;
  children: (toggle: Function) => ReactElement | null;
  onComplete?: () => any;
}

export function RemoveCitizenVerification({ user, children, onComplete }: IRemoveCitizenVerificationProps) {
  return (
    <Mutation<IRemoveCitizenVerificationMutation, IRemoveCitizenVerificationMutationVariables>
      mutation={RemoveCitizenVerificationMutation}
    >
      {(removeCitizenVerification) => (
        <Toggle initial={false}>
          {({ on, toggle }) => (
            <>
              <FinalForm
                onSubmit={async () => {
                  await removeCitizenVerification({
                    variables: {
                      userId: user._id,
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: ["AllUsers", "UsersCount"],
                  });

                  if (onComplete && typeof onComplete === "function") {
                    onComplete();
                  }

                  toggle();
                }}
              >
                {({ submitting, handleSubmit }) => (
                  <Modal
                    title={`Remove ${user.fullName} citizen verification`}
                    visible={on}
                    onCancel={toggle}
                    footer={[
                      <Button key="back" onClick={toggle}>
                        Cancel
                      </Button>,
                      <Button
                        key="submit"
                        danger={true}
                        loading={submitting}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        {submitting ? "Please wait..." : "Remove"}
                      </Button>,
                    ]}
                  >
                    <VerificationForm verificationType={VerificationType.Citizen} user={user} onSubmit={handleSubmit} />
                  </Modal>
                )}
              </FinalForm>

              {children(toggle)}
            </>
          )}
        </Toggle>
      )}
    </Mutation>
  );
}
