import gql from "graphql-tag";

export default gql`
  mutation UpdateImageAlt($imageId: ObjectId!, $alt: String!) {
    updateImageAlt(imageId: $imageId, alt: $alt) {
      _id
      url
      alt
      source
      sourceType
    }
  }
`;
