import { Radio, RadioChangeEvent } from "antd";
import React from "react";
import { Field } from "react-final-form";
import { ISelectFieldProps } from "./FormFieldProps";

export default function RadioGroupField({ fieldName, options }: ISelectFieldProps) {
  return (
    <Field name={fieldName}>
      {({ input }) => (
        <Radio.Group value={input.value} onChange={(e: RadioChangeEvent) => input.onChange(e.target.value)}>
          {options.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      )}
    </Field>
  );
}
