import React from "react";
import * as palette from "@govlaunch/palette";

interface ILinkIconProps extends React.SVGProps<any> {
  fillColor?: string | undefined;
}

export default function LinkIcon({ fillColor, ...props }: ILinkIconProps) {
  if (!fillColor) {
    fillColor = palette.primary;
  }

  return (
    <svg viewBox="0 0 482.8 482.8" width="1em" height="1em" {...props}>
      <path
        fill={fillColor}
        d="M255.2 209.3c-5.3 5.3-5.3 13.8 0 19.1 21.9 21.9 21.9 57.5 0 79.4l-115 115c-21.9 21.9-57.5 21.9-79.4 0l-17.3-17.3c-21.9-21.9-21.9-57.5 0-79.4l115-115c5.3-5.3 5.3-13.8 0-19.1s-13.8-5.3-19.1 0l-115 115C8.7 322.7 0 343.6 0 365.8c0 22.2 8.6 43.1 24.4 58.8l17.3 17.3c16.2 16.2 37.5 24.3 58.8 24.3s42.6-8.1 58.8-24.3l115-115c32.4-32.4 32.4-85.2 0-117.6-5.2-5.3-13.8-5.3-19.1 0z"
      />
      <path
        fill={fillColor}
        d="M458.5 58.2l-17.3-17.3c-32.4-32.4-85.2-32.4-117.6 0l-115 115c-32.4 32.4-32.4 85.2 0 117.6 5.3 5.3 13.8 5.3 19.1 0s5.3-13.8 0-19.1c-21.9-21.9-21.9-57.5 0-79.4l115-115c21.9-21.9 57.5-21.9 79.4 0l17.3 17.3c21.9 21.9 21.9 57.5 0 79.4l-115 115c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4s6.9-1.3 9.5-4l115-115c15.7-15.7 24.4-36.6 24.4-58.8 0-22.2-8.6-43.1-24.3-58.8z"
      />
    </svg>
  );
}
