import gql from "graphql-tag";
import AboutPageConfigFields from "../fragments/AboutPageConfigFields";

export default gql`
  query AllAboutPageConfigs {
    allAboutPageConfigs {
      ...AboutPageConfigFields
    }
  }

  ${AboutPageConfigFields}
`;
