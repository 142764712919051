import React from "react";
import { Tabs } from "antd";
import queryString from "query-string";
import DocumentTitle from "react-document-title";
import TabContent from "../../components/TabContent";
import VendorsCount from "./VendorsCount";
import VendorsWithSearch from "./tables/VendorsWithSearchWithRouter";
import { IVendorsTabFilter } from "./tables/VendorsTable";
import { useLocation, useNavigate } from "react-router";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { MetaBaseQuestion } from "../../components/MetaBaseLink";
import TablePageHeader from "../../components/table/TablePageHeader";

export default function AllVendors() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = query.tab || "all";

  return (
    <DocumentTitle title="Vendors | Admin">
      <>
        <TablePageHeader
          title="Vendors"
          titleIcon={faBuilding}
          subTitle="Manage all Vendors"
          metabaseQuestion={MetaBaseQuestion.Vendors}
        />
        <VendorsCount>
          {({ allCount, activeCount, inactiveCount, disruptorsCount, archivedCount }) => (
            <Tabs
              animated={false}
              onChange={(tab) => navigate(`/vendors?tab=${tab}`)}
              activeKey={tab as string}
              size="middle"
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
            >
              <Tabs.TabPane tab={allCount ? `All (${allCount})` : "All"} key="all">
                {tab === "all" && (
                  <TabContent>
                    <VendorsWithSearch tab={IVendorsTabFilter.ALL} />
                  </TabContent>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={allCount ? `Active (${activeCount})` : "Active"} key="active">
                {tab === "active" && (
                  <TabContent>
                    <VendorsWithSearch tab={IVendorsTabFilter.ACTIVE} sortBy="activatedAt:descending" />
                  </TabContent>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={inactiveCount ? `Inactive (${inactiveCount})` : "Inactive"} key="inactive">
                {tab === "inactive" && (
                  <TabContent>
                    <VendorsWithSearch tab={IVendorsTabFilter.INACTIVE} />
                  </TabContent>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={disruptorsCount ? `Disruptors (${disruptorsCount})` : "Disruptors"} key="disruptors">
                {tab === "disruptors" && (
                  <TabContent>
                    <VendorsWithSearch tab={IVendorsTabFilter.DISRUPTORS} />
                  </TabContent>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={archivedCount ? `Archived (${archivedCount})` : "Archived"} key="archived">
                {tab === "archived" && (
                  <TabContent>
                    <VendorsWithSearch tab={IVendorsTabFilter.ARCHIVED} />
                  </TabContent>
                )}
              </Tabs.TabPane>
            </Tabs>
          )}
        </VendorsCount>
      </>
    </DocumentTitle>
  );
}
