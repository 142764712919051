import React from "react";
import { Mutation } from "react-apollo";
import { IMutationChangeOnboardingStatusArgs, IProcessStatusType } from "../../../../../types/types";
import ChangeOnboardingStatus from "../../../../mutations/ChangeOnboardingStatus";
import { IChangeOnboardingStatusMutation } from "../../../../mutations/__generated__/ChangeOnboardingStatus.generated";
import * as palette from "@govlaunch/palette";
import { Button } from "@govlaunch/core";
import { Popconfirm } from "antd";

interface IChangOnboardingStatusMutationsProps {
  userId: any;
  newStatus: IProcessStatusType;
  buttonLabel: string;
  buttonColor?: string;
  buttonBorder?: string;
  buttonTextColor?: string;
  confirmationMessage?: string;
  refetch: () => any;
}

export default function ChangeOnboardingStatusMutation({
  userId,
  buttonLabel,
  buttonColor,
  buttonBorder,
  buttonTextColor,
  newStatus,
  confirmationMessage,
  refetch,
}: IChangOnboardingStatusMutationsProps) {
  return (
    <Mutation<IChangeOnboardingStatusMutation, IMutationChangeOnboardingStatusArgs>
      onCompleted={() => {}}
      variables={{
        userId: userId,
        newStatus: newStatus,
      }}
      mutation={ChangeOnboardingStatus}
      refetchQueries={["OnboardingsCount"]}
      awaitRefetchQueries={true}
    >
      {(changeOnboardingStatus) => {
        if (!confirmationMessage) {
          return (
            <Button
              css={{
                marginRight: 5,
                background: buttonColor || palette.blue,
                border: buttonBorder || "none",
                color: buttonTextColor || palette.white,
                fontWeight: 500,
              }}
              type="button"
              onClick={async () => {
                await changeOnboardingStatus();
                refetch();
              }}
            >
              {buttonLabel}
            </Button>
          );
        } else {
          return (
            <Popconfirm
              title={confirmationMessage}
              onConfirm={async () => {
                await changeOnboardingStatus();
                refetch();
              }}
            >
              <Button
                css={{
                  marginRight: 5,
                  background: buttonColor || palette.blue,
                  border: buttonBorder || "none",
                  color: buttonTextColor || palette.white,
                  fontWeight: 500,
                }}
                type="button"
              >
                {buttonLabel}
              </Button>
            </Popconfirm>
          );
        }
      }}
    </Mutation>
  );
}
