import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, notification } from "antd";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Mutation } from "react-apollo";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import { IUserRole, IUserSearchScope } from "../../../../types/types";
import EditableVendorMembers from "../EditableVendorMembers";
import EditVendorMembers from "../graphql/mutations/EditVendorMembers";
import {
  IEditVendorMembersMutation,
  IEditVendorMembersMutationVariables,
} from "../graphql/mutations/__generated__/EditVendorMembers.generated";
import { TVendor, TVendorMember } from "../types";
import UserAutoComplete from "../UserAutoComplete";

interface IEditVendorMembersModalProps {
  visible: boolean;
  vendor: TVendor;
  initialMemberAdditions?: TVendorMember[];
  displayBackButton?: boolean;
  onSaveChanges?: () => void;
  onRequestClose?: () => void;
  onRequestBack?: () => void;
}

export default function EditVendorMembersModal({
  visible,
  vendor,
  displayBackButton,
  initialMemberAdditions = [],
  onSaveChanges,
  onRequestClose,
  onRequestBack,
}: IEditVendorMembersModalProps) {
  const linkToVendor = (
    <Link to={`/vendors/${vendor.slug}/members`}>
      <strong>{vendor.name}</strong>
    </Link>
  );

  const title = <>Manage {linkToVendor} members</>;

  const members: TVendorMember[] = vendor.members ? vendor.members : [];

  const safeInitialMembers = members.concat(
    initialMemberAdditions
      .filter((candidate) => {
        return !members.some((member) => {
          return member._id === candidate._id;
        });
      })
      .map((member, index) => {
        return {
          ...member,
          // if there are no vendor members, make sure that the 1st addition will come as owner.
          role: members.length === 0 && index === 0 ? "COMPANY_OWNER" : "COMPANY_USER",
        };
      }),
  );

  return (
    <Mutation<IEditVendorMembersMutation, IEditVendorMembersMutationVariables>
      mutation={EditVendorMembers}
      refetchQueries={["AllUsers", "OnboardingsCount", "UsersCount", "ListOnboardings"]}
      awaitRefetchQueries={true}
    >
      {(editVendorMembers) => (
        <Form
          initialValues={{
            members: safeInitialMembers,
          }}
          onSubmit={async ({ members }) => {
            try {
              await editVendorMembers({
                variables: {
                  vendorId: vendor._id,
                  members: members.map((member: any) => {
                    return {
                      userId: member._id,
                      role: member.role === "COMPANY_OWNER" ? IUserRole.Owner : IUserRole.User,
                    };
                  }),
                },
              });

              if (onSaveChanges) {
                onSaveChanges();
              }

              notification.open({
                type: "success",
                message: "Success!",
                description: <>Team of <strong>{vendor.name}</strong> has been modified</>,
              });
            } catch (err) {
              return {
                [FORM_ERROR]: err.message,
              };
            }
          }}
        >
          {({ submitting, submitError, handleSubmit }) => (
            <Modal width={720} title={title} visible={visible} onCancel={onRequestClose} footer={null}>
              <form onSubmit={handleSubmit}>
                <Field<TVendorMember[]> name="members">
                  {({ input }) => (
                    <UserAutoComplete
                      scopes={[IUserSearchScope.Pending, IUserSearchScope.Vendor, IUserSearchScope.Citizen]}
                      onSelect={(selectedUser) => {
                        const alreadyInMembers = input.value.some((candidate) => {
                          return candidate._id === selectedUser._id;
                        });

                        const isFirstMember = input.value.length === 0;

                        if (!alreadyInMembers) {
                          input.onChange(
                            input.value.concat({
                              ...selectedUser,
                              role: isFirstMember ? "COMPANY_OWNER" : "COMPANY_USER",
                            }),
                          );
                        }
                      }}
                    />
                  )}
                </Field>

                <div
                  css={{
                    margin: "24px 0",
                  }}
                >
                  <EditableVendorMembers vendor={vendor} />
                </div>

                {submitError && (
                  <Alert
                    message={<strong>Couldn't save changes</strong>}
                    description={`There was an error when saving the changes: ${submitError
                      .replace("GraphQL error:", "")
                      .trim()}`}
                    type="error"
                    closable={true}
                  />
                )}

                <div
                  css={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  {displayBackButton ? (
                    <div
                      css={{
                        textAlign: "left",
                      }}
                    >
                      <Button
                        key="submit"
                        type="dashed"
                        size="large"
                        icon={<ArrowLeftOutlined />}
                        onClick={() => {
                          if (onRequestBack) {
                            onRequestBack();
                          } else if (onRequestClose) {
                            onRequestClose();
                          }
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  ) : (
                    <div />
                  )}

                  <div
                    css={{
                      textAlign: "right",
                    }}
                  >
                    <Button
                      key="submit"
                      type="primary"
                      size="large"
                      icon={<SaveOutlined />}
                      htmlType="submit"
                      loading={submitting}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Modal>
          )}
        </Form>
      )}
    </Mutation>
  );
}
