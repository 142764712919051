import gql from "graphql-tag";

export default gql`
  mutation AddCollection($input: AddCollectionInput!) {
    addCollection(input: $input) {
      _id
      name
      slug
      tags {
        _id
        name
        slug
      }
      createdAt
      updatedAt
    }
  }
`;
