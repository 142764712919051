import React from "react";
import { Spin } from "antd";

export default function CenteredLoader() {
  return (
    <div
      css={{
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "50%",
        left: "50%",
      }}
    >
      <Spin size="large" />
    </div>
  );
}
