import gql from "graphql-tag";

export default gql`
  mutation publishStory($storyId: ObjectId!) {
    publishStory(storyId: $storyId) {
      _id
      title
      slug
      introduction
    }
  }
`;
