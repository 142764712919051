import * as palette from "@govlaunch/palette";

import ProgressiveImage from "react-progressive-image";
import PropTypes from "prop-types";
import React from "react";
import ProfilePicture from "@govlaunch/profile-picture";
import { IProduct } from "../../../../types/types";

interface IProductItemProps {
  product: IProduct;
  onRemove?: (product: IProduct) => any;
  style?: React.CSSProperties;
}

export default function ProductItem({ product, onRemove, ...props }: IProductItemProps) {
  return (
    <ProgressiveImage src={product.logo as string} placeholder="">
      {(src: string) => (
        <div
          {...props}
          css={{
            display: "grid",
            gridTemplateAreas: '"product remove"',
            gridTemplateColumns: "auto 1fr",
            gridColumnGap: onRemove ? 10 : 0,
            gridRowGap: 2.5,
            alignItems: "center",
            backgroundColor: "rgba(219, 236, 247, 0.5)",
            borderRadius: 60,
            padding: "10px 15px",
          }}
        >
          <div
            css={{
              gridArea: "product",
              display: "grid",
              gridTemplateAreas: '"logo name" "logo description"',
              gridTemplateColumns: "auto 1fr",
              gridColumnGap: src ? 10 : 0,
              gridRowGap: 2.5,
              alignItems: "center",
              "&:focus": {
                outline: 0,
              },
            }}
          >
            <ProfilePicture
              image={src}
              name={product.name}
              size={28}
              css={{
                userSelect: "none",
                gridArea: "logo",
              }}
            />

            <div
              css={{
                gridArea: "name",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                fontSize: 14,
                fontWeight: 600,
                color: palette.primary,
                lineHeight: 1,
              }}
            >
              {product.name}
            </div>

            <div
              css={{
                gridArea: "description",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                fontSize: 12,
                color: palette.primary,
                lineHeight: 1,
              }}
            >
              {product.description}
            </div>
          </div>

          {onRemove && (
            <svg
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                onRemove(product);
              }}
              viewBox="0 0 117 117"
              width={28}
              height={28}
              css={{
                gridArea: "remove",
                cursor: "pointer",
                marginLeft: "auto",
                "&:hover": {
                  opacity: 0.8,
                },
              }}
            >
              <g fill="#FF7171" fillRule="evenodd">
                <path
                  fillRule="nonzero"
                  d="M58.5 116.4c31.9 0 57.9-26 57.9-57.9S90.4.6 58.5.6.6 26.6.6 58.5s26 57.9 57.9 57.9zm0-107.7c27.4 0 49.8 22.3 49.8 49.8 0 27.5-22.3 49.8-49.8 49.8-27.5 0-49.8-22.4-49.8-49.8S31.1 8.7 58.5 8.7z"
                />
                <path d="M31.8 62h53.3c2.3 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1H31.8c-2.3 0-4.1 1.8-4.1 4.1s1.9 4.1 4.1 4.1z" />
              </g>
            </svg>
          )}
        </div>
      )}
    </ProgressiveImage>
  );
}

ProductItem.defaultProps = {
  onRemove: null,
};

ProductItem.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    logo: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  onRemove: PropTypes.func,
};
