import React from "react";
import { Field } from "react-final-form";
import FieldValidationError from "../../../../components/form/FieldValidationError";
import TagsSelect from "../../../tags/TagsSelect";

export default function AwardsField() {
  return (
    <Field name="awards">
      {({ input, meta }) => (
        <div>
          <TagsSelect {...input} type="AWARD" placeholder="Type to add awards... (optional)" />

          <FieldValidationError meta={meta} />
        </div>
      )}
    </Field>
  );
}
