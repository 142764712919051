import gql from "graphql-tag";
import GovernmentMemberFragmentFields from "../fragments/GovernmentMemberFragmentFields";

export default gql`
  mutation EditGovernmentMembers($governmentId: ObjectId!, $members: [UserWithRoleInput!]!) {
    editGovernmentMembers(governmentId: $governmentId, members: $members) {
      _id
      members {
        ...GovernmentMemberFragmentFields
      }
      owner {
        ...GovernmentMemberFragmentFields
      }
    }
  }

  ${GovernmentMemberFragmentFields}
`;
