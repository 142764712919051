import gql from "graphql-tag";
import UserFields from "./fragments/UserFields";

export default gql`
  query Groups(
    $search: String
    $tags: [ObjectId!]
    $filter: GroupsFilter
    $filterByKind: GroupKind
    $sort: String
    $limit: Int = 15
    $cursor: Cursor
  ) {
    groups(
      search: $search
      tags: $tags
      filter: $filter
      filterByKind: $filterByKind
      sort: $sort
      limit: $limit
      cursor: $cursor
    ) {
      items {
        _id
        slug
        name
        logo
        kind
        membersCount
        about
        website
        visibility
        deletedAt
        owner {
          _id
          name
          slug
        }
        deletedBy {
          ... on PendingUser {
            _id
            slug
            fullName
          }
          ... on VendorUser {
            _id
            slug
            fullName
          }
          ... on GovernmentUser {
            _id
            slug
            fullName
          }
        }
        location
        owner {
          _id
          name
          logo
          isDisruptor
        }
        previousOwner {
          _id
          slug
          name
          logo
          isDisruptor
        }
        governments {
          _id
          slug
          name
          logo
        }
        vendors {
          _id
          slug
          name
          logo
        }
        members {
          items {
            ...UserFields
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }

  ${UserFields}
`;
