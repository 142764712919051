import gql from "graphql-tag";

export default gql`
  query GetPostsByVendor($vendorId: ObjectId!, $sort: String, $cursor: Cursor, $limit: Int = 20) {
    postsByVendor(vendorId: $vendorId, sort: $sort, cursor: $cursor, limit: $limit) {
      items {
        ... on VendorPost {
          _id
          body
          audience
          featuredAt
          createdAt
          deletedAt
          author {
            ... on PendingUser {
              _id
              fullName
            }
            ... on GovernmentUser {
              _id
              fullName
            }
            ... on VendorUser {
              _id
              fullName
            }
          }
          attachments {
            mimeType
            filename
            sizeInBytes
            url
          }
          links {
            addedAt
            description
            title
            favicon
            siteName
            url
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
