import React from "react";

export default function CampaignPreviewHeader() {
  return (
    <table
      cellSpacing="0"
      cellPadding="0"
      style={{
        borderCollapse: "collapse",
        borderSpacing: 0,
        width: "100%",
      }}
    >
      <tbody>
        <tr>
          <td
            valign="top"
            align="center"
            style={{
              padding: 0,
              margin: 0,
              width: "600px",
            }}
          >
            <table
              cellSpacing="0"
              cellPadding="0"
              style={{
                borderCollapse: "collapse",
                borderSpacing: 0,
                backgroundColor: "#4f4f4f",
                width: "100%",
              }}
              role="presentation"
            >
              <tbody>
                <tr>
                  <td
                    align="center"
                    style={{
                      padding: "5px",
                      margin: 0,
                      fontSize: "0px",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://govlaunch.com"
                      style={{
                        WebkitTextSizeAdjust: "none",
                        //@ts-ignore
                        msTextSizeAdjust: "none",
                        textDecoration: "none",
                        color: "#086BEF",
                        fontSize: "14px",
                      }}
                    >
                      <img
                        src="https://mcusercontent.com/88b300557f9ef581c3b0d7bf5/images/f4f2b143-b6d7-5848-ae59-e34939c899c9.png"
                        alt="New innovation stories from the Govlaunch community"
                        width="235px"
                        style={{
                          display: "block",
                          border: 0,
                          outline: "none",
                          textDecoration: "none",
                          width: "235px",
                        }}
                        title="New innovation stories from the Govlaunch community"
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td
                    align="center"
                    style={{
                      padding: 0,
                      margin: 0,
                      paddingBottom: "10px",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        WebkitTextSizeAdjust: "none",
                        //@ts-ignore
                        msTextSizeAdjust: "none",
                        lineHeight: "18px",
                        color: "#ffffff",
                        fontSize: "12px",
                      }}
                    >
                      <em>The wiki for local government innovation</em>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
