import gql from "graphql-tag";

export default gql`
  mutation AssignUser($userId: ObjectId!, $vendorId: ObjectId, $governmentId: ObjectId, $type: String!) {
    assignUser(userId: $userId, vendorId: $vendorId, governmentId: $governmentId, type: $type) {
      ... on PendingUser {
        _id
        firstName
        fullName
        avatar
        jobTitle
        email
      }
      ... on GovernmentUser {
        _id
        role
        firstName
        fullName
        avatar
        jobTitle
        email
        isInnovator
        government {
          _id
          name
          logo
          isInnovator
        }
      }
      ... on VendorUser {
        _id
        firstName
        fullName
        avatar
        jobTitle
        email
        isDisruptor
        company {
          _id
          name
          logo
          isDisruptor
        }
      }
    }
  }
`;
