import React from "react";
import * as palette from "@govlaunch/palette";
import DocumentTitle from "react-document-title";
import NewProjectForm from "./NewProjectForm";

export default function NewProjectPage() {
  return (
    <DocumentTitle title="New Project | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
          overflow: "auto"
        }}
      >
        <NewProjectForm />
      </div>
    </DocumentTitle>
  );
}
