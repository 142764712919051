import * as Yup from "yup";

export default Yup.object().shape({
  name: Yup.string().required(),
  logo: Yup.string()
    .url()
    .nullable(),
  link: Yup.string()
    .url()
    .nullable(),
});
