import { Table, TableProps } from "antd";
import React from "react";
import * as palette from "@govlaunch/palette";
import { IGetExternalLinksQuery } from "../../../queries/__generated__/GetExternalLinks.generated";
import moment from "moment";
import { Link } from "react-router-dom";
import ExternalLinksTableActions from "./ExternalLinksTableActions";

type TExternalPageLink = NonNullable<NonNullable<IGetExternalLinksQuery["externalLinks"]>["items"]>[0];

interface IExternalLinksTableProps {
  externalLinks: TExternalPageLink[];
  loading?: boolean;
  footer?: TableProps<any>["footer"];
}

export default function ExternalLinksTable({ externalLinks, loading, footer }: IExternalLinksTableProps) {
  return (
    <Table<TExternalPageLink>
      dataSource={externalLinks}
      bordered={true}
      rowKey="_id"
      loading={loading}
      pagination={false}
      style={{
        backgroundColor: palette.white,
      }}
      scroll={{
        x: true,
      }}
      footer={footer}
      columns={[
        {
          title: "Title",
          dataIndex: "title",
          render: (_, item) => <Link to={`/externalLinks/${item._id}`}>{item.title}</Link>,
        },
        {
          title: "Url",
          dataIndex: "url",
        },
        {
          title: "Source",
          dataIndex: "source",
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          render: (date) => moment(date).format("MM/DD/YYYY"),
        },
        {
          title: "Actions",
          render: (_, link) => {
            return <ExternalLinksTableActions externalLinkId={link._id} />;
          },
        },
      ]}
    />
  );
}
