import { Col, RowWrap } from "@govlaunch/core";
import React from "react";
import { Form, FormSpy } from "react-final-form";
import * as Yup from "yup";
import { ITagType } from "../../../types/types";
import { Margin } from "../../spacings";
import validate from "../../validate";
import SocialMediaFields from "../form/fields/SocialMediaFields";
import AboutField from "./fields/AboutField";
import CertificationsField from "./fields/CertificationsField";
import IntegrationsField from "./fields/IntegrationsField";
import LanguagesCountField from "./fields/LanguagesCountField";
import UrlField from "../form/fields/UrlField";
import LogoField from "../form/fields/LogoField";
import NewsworthyField from "../form/fields/NewsworthyField";
import ScreenshotsField from "./fields/ScreenshotsField";
import VideosField from "./fields/VideosField";
import { isValidUrlYup } from "../../utils/isValidUrl";
import SolutionsField from "./fields/SolutionsField";
import TextareaField from "../form/fields/TextareaField";
import TextField from "../form/fields/TextField";
import RequestTranslationSettings from "./fields/RequestTranslationSettings";
import { CSSReset, TagsField, theme, ThemeProvider } from "@govlaunch/web";
import Section from "../form/Section";
import SectionHeader from "../form/SectionHeader";
import SectionBody from "../form/SectionBody";
import ProductGovernments from "./fields/ProductGovernments";
import FormGrid from "../form/FormGrid";
import HighlightsInputField from "../form/fields/HighlightsInputField";
import SectionSplitter from "../form/SectionSplitter";
import FormSubmitSection from "../form/FormSubmitSection";
import ResourcesField from "../form/fields/ResourcesField";
import VendorSelectField from "./fields/VendorSelectField";
import MobileResponsiveField from "./fields/MobileResponsiveField";
import CheckboxGroupField from "../form/fields/CheckboxGroupField";
import NumberField from "../form/fields/NumberField";

interface IProductFormProps extends React.HTMLAttributes<HTMLFormElement> {
  onSubmit: (values: any) => any;
  initialValues?: any;
  productSlug?: string;
  defaultVendorSearch?: string;
  integrations?: any;
  tagsLimit?: number;
  submitButtonText?: string;
}

const ProductForm = ({
  onSubmit,
  initialValues,
  defaultVendorSearch,
  productSlug,
  submitButtonText,
  ...props
}: IProductFormProps) => {
  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Form
        keepDirtyOnReinitialize={true}
        onSubmit={onSubmit}
        validate={validate(validationSchema())}
        initialValues={initialValues}
        subscription={{
          submitting: true,
          submitError: true,
        }}
        {...props}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <FormGrid>
              <FormSubmitSection
                backButtonText="All Products"
                backButtonLocation="/products"
                discardChangesLocation="/products"
                submitButtonText={submitButtonText}
                submitting={submitting}
                submitError={submitError}
              />

              <RowWrap>
                <Col width={[1, 1, 1, 8 / 12]}>
                  <SectionSplitter>
                    <Section>
                      <SectionHeader>The Basics</SectionHeader>

                      <SectionBody>
                        <TextField fieldName="name" label="Title" placeholder="Type product name..." />
                        <TextareaField
                          fieldName="description"
                          label="Description"
                          placeholder="Type short description (140 chars)..."
                          ariaLabel="Product description"
                          characterLimit={140}
                        />
                        <UrlField label="Site" fieldName="link" />
                        <VendorSelectField initialVendor={defaultVendorSearch} />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Product Details</SectionHeader>
                      <SectionBody>
                        <UrlField label="Pricing" placeholder="Pricing page URL (http://)" fieldName="pricingPage" />
                        <UrlField label="Contact" placeholder="Contact page URL (http://)" fieldName="contactPage" />
                        <UrlField label="Free Trial" placeholder="Trial page URL (http://)" fieldName="freeTrial" />
                        <TextField
                          label="Open Source"
                          fieldName="openSource"
                          placeholder="Open source website (Github, Bitbucket, GitLab)"
                        />
                        <div
                          css={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <NumberField
                            label="Release Year"
                            fieldName="releasedYear"
                            //maxValue={new Date().getFullYear()}
                            //minValue={1500}
                          />
                          <LanguagesCountField />
                        </div>
                        <CheckboxGroupField
                          label="Assistive Technologies Compatibility"
                          fieldName="assistiveTechnologiesCompatibility"
                          options={[
                            {
                              value: "SCREEN_READER",
                              label: "Screen Reader",
                            },
                            {
                              value: "SCREEN_MAGNIFICATION",
                              label: "Screen Magnification",
                            },
                            {
                              value: "TEXT_READER",
                              label: "Text Reader",
                            },
                            {
                              value: "SPEECH_INPUT",
                              label: "Speech Input",
                            },
                            {
                              value: "ALTERNATIVE_INPUT_DEVICE",
                              label: "Alternative Input Device",
                            },
                          ]}
                        />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Regulatory Compliance or Certifications</SectionHeader>
                      <SectionBody>
                        <CertificationsField />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Deployment</SectionHeader>
                      <SectionBody>
                        <div
                          css={{
                            display: "grid",
                            gridRowGap: 16,
                          }}
                        >
                          <MobileResponsiveField />
                          <CheckboxGroupField
                            label="Supported Browsers"
                            fieldName="browsers"
                            // 2022-01-05 Stoyan Prokopov: For some reason we show "Microsoft Edge" and store "Edge" in the MongoDB schema
                            // Will have to use value/label pairs for that reason
                            options={[
                              {
                                value: "Chrome",
                                label: "Chrome",
                              },
                              {
                                value: "Firefox",
                                label: "Firefox",
                              },
                              {
                                value: "Internet Explorer",
                                label: "Internet Explorer",
                              },
                              {
                                value: "Edge",
                                label: "Microsoft Edge",
                              },
                              {
                                value: "Safari",
                                label: "Safari",
                              },
                              {
                                value: "Opera",
                                label: "Opera",
                              },
                            ]}
                            toggleAllOption={{
                              value: "All Browsers",
                              label: "All Browsers",
                            }}
                          />

                          <CheckboxGroupField
                            label="Supported Platforms"
                            fieldName="platforms"
                            options={[
                              {
                                value: "Windows",
                                label: "Windows",
                              },
                              {
                                value: "Linux",
                                label: "Linux",
                              },
                              {
                                value: "Mac",
                                label: "Mac",
                              },
                            ]}
                            toggleAllOption={{
                              value: "All Platforms",
                              label: "All Platforms",
                            }}
                          />

                          <UrlField label="App Store" fieldName="appStore" placeholder="App store page URL (http://)" />

                          <UrlField
                            label="Google PlayStore"
                            fieldName="playStore"
                            placeholder="Google PlayStore page URL (http://)"
                          />
                        </div>
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Highlights</SectionHeader>
                      <SectionBody>
                        <HighlightsInputField />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader
                        css={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        About the Product
                      </SectionHeader>
                      <SectionBody>
                        <AboutField />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Videos</SectionHeader>
                      <SectionBody>
                        <VideosField />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Screenshots</SectionHeader>
                      <FormSpy
                        subscription={{
                          values: true,
                        }}
                      >
                        {({ values }) => (
                          <SectionBody
                            css={{
                              padding: values.screenshots.length > 0 ? "15px 0 0 0" : 15,
                            }}
                          >
                            <ScreenshotsField />
                          </SectionBody>
                        )}
                      </FormSpy>
                    </Section>

                    <Section>
                      <SectionHeader>Newsworthy</SectionHeader>
                      <SectionBody>
                        <NewsworthyField fieldName="newsworthy" />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Marketplace Integrations</SectionHeader>
                      <SectionBody>
                        <IntegrationsField />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Service and Product Integrations</SectionHeader>
                      <SectionBody>
                        <SolutionsField />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Request translation settings</SectionHeader>
                      <SectionBody>
                        <RequestTranslationSettings />
                      </SectionBody>
                    </Section>
                  </SectionSplitter>
                </Col>

                <Col width={[1, 1, 1, 4 / 12]}>
                  <SectionSplitter>
                    <Section>
                      <SectionHeader>Logo</SectionHeader>
                      <SectionBody>
                        <FormSpy
                          subscription={{
                            values: true,
                          }}
                        >
                          {({ values }) => (
                            <div>
                              <LogoField name={values.name || "A B"} />
                            </div>
                          )}
                        </FormSpy>
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Product Tags</SectionHeader>

                      <SectionBody>
                        <TagsField fieldName="tags" type={[ITagType.Product, ITagType.Custom]} />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Social Media</SectionHeader>
                      <SectionBody>
                        <SocialMediaFields />
                      </SectionBody>
                    </Section>

                    <Section>
                      <SectionHeader>Resources</SectionHeader>

                      <SectionBody>
                        <ResourcesField />
                      </SectionBody>
                    </Section>
                  </SectionSplitter>
                </Col>
              </RowWrap>

              <FormSpy
                subscription={{
                  values: true,
                }}
              >
                {({ values }) => (
                  <>
                    {values._id && (
                      <RowWrap>
                        <Col width={1}>
                          <Margin mt={24}>
                            <Section>
                              <SectionHeader>Governments using</SectionHeader>
                              <SectionBody>
                                <ProductGovernments initialValues={initialValues} productSlug={productSlug} />
                              </SectionBody>
                            </Section>
                          </Margin>
                        </Col>
                      </RowWrap>
                    )}
                  </>
                )}
              </FormSpy>
            </FormGrid>
          </form>
        )}
      </Form>
    </ThemeProvider>
  );
};

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Can't be blank"),
    description: Yup.string().required("Can't be blank"),
    company: Yup.string().required("Can't be blank"),
    link: Yup.string().test("url", "invalid url", isValidUrlYup).required("Can't be blank"),
    pricingPage: Yup.string().url("Invalid URL. Tip: make sure it starts with http:// or https://").nullable(),
    contactPage: Yup.string().url("Invalid URL. Tip: make sure it starts with http:// or https://").nullable(),
    freeTrial: Yup.string().url("Invalid URL. Tip: make sure it starts with http:// or https://").nullable(),
    openSource: Yup.string().url("Invalid URL. Tip: make sure it starts with http:// or https://").nullable(),
    tags: Yup.array().min(1, "Please select at least one tag"),
    releasedYear: Yup.number().min(1500, "Invalid year").max(new Date().getFullYear(), "Invalid year").nullable(),
    socialMedia: Yup.object().shape({
      twitter: Yup.string().url("Invalid URL").nullable(),
      youtube: Yup.string().url("Invalid URL").nullable(),
      facebook: Yup.string().url("Invalid URL").nullable(),
      linkedin: Yup.string().url("Invalid URL").nullable(),
      instagram: Yup.string().url("Invalid URL").nullable(),
    }),
  });

export default ProductForm;
