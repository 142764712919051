import { SlateEditor } from "@govlaunch/editor";
import * as palette from "@govlaunch/palette";
import { message } from "antd";
import React from "react";
import { Field } from "react-final-form";
import EmbedProjectModalButton from "./EmbedProjectModalButton";

export default function ProjectSummaryField() {
  return (
    <div
      css={{
        position: "relative",
      }}
    >
      <Field name="body">
        {({ input }) => (
          <>
            <SlateEditor
              value={input.value}
              onChange={input.onChange}
              minContentHeight={300}
              placeholder="This is where you can give more information about your project or initiative..."
              containerStyle={{
                borderRadius: 5,
                backgroundColor: palette.white,
              }}
              extraToolbarIcons={<EmbedProjectModalButton />}
              onEmailSubscribe={() => {
                message.warning("You cannot subscribe for a newsletter from the Admin");
              }}
            />
          </>
        )}
      </Field>
    </div>
  );
}
