import gql from "graphql-tag";

export default gql`
  query AutoCompleteCollections($searchQuery: String!) {
    autoCompleteCollections(searchQuery: $searchQuery) {
      _id
      name
    }
  }
`;
