import * as palette from "@govlaunch/palette";
import { Popconfirm, Table, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";
import { IUser, IPost } from "../../../types/types";
import DeletePost from "../../mutations/DeletePost";
import { IDeletePostMutation, IDeletePostMutationVariables } from "../../mutations/__generated__/DeletePost.generated";
import TogglePostFeatured from "../../mutations/TogglePostFeatured";
import {
  ITogglePostFeaturedMutation,
  ITogglePostFeaturedMutationVariables,
} from "../../mutations/__generated__/TogglePostFeatured.generated";
import { Margin } from "../../spacings";
import { getToken } from "../../auth/token";
import getGovlaunchBaseUrl from "../../utils/getGovlaunchBaseUrl";
import PostFormModal from "./PostFormModal";
import { convertPostToInitialValues } from "./utils";
import notUndefinedOrNull from "../../utils/notUndefinedOrNull";
import TableRowActions from "../../components/table/TableRowActions";

interface IPostsTable {
  posts: Array<IPost>;
  loading?: boolean;
  onRemovePost?: () => any;
  onChange?: any;
}

const PDF_URL = "https://faiwz63r94.execute-api.us-west-2.amazonaws.com/production";

function PostsTable({ posts, loading = false, onRemovePost, onChange }: IPostsTable) {
  const [editingPost, setEditingPost] = useState(null);

  const areAllArchived = posts.every((post) => {
    return post.deletedAt !== null;
  });

  return (
    <>
      <Table
        scroll={{
          x: true,
        }}
        rowKey="_id"
        loading={loading}
        bordered={true}
        pagination={false}
        dataSource={posts}
        onChange={onChange}
        style={{
          backgroundColor: palette.white,
        }}
        columns={[
          {
            key: "body",
            title: "Name",
            dataIndex: "name",
            sorter: true,
            render: (_: any, post: IPost) => (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Margin
                  ml={5}
                  css={{
                    overflow: "hidden",
                    width: 300,
                    textOverflow: "ellipsis",
                  }}
                >
                  <a
                    href="#"
                    title={post.body}
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: 300,
                    }}
                    onClick={() => setEditingPost(post as any)}
                  >
                    {post.body}
                  </a>
                  {post.featuredAt && <span>🌟</span>}
                </Margin>
              </div>
            ),
          },
          {
            title: "Entity",
            render: (_: any, post: IPost) => {
              if (post.__typename === "GovernmentPost") {
                return post.government.name;
              } else if (post.__typename === "GroupPost") {
                return post.group.name;
              }

              return null;
            },
          },
          {
            title: "Tags",
            filters: [
              {
                text: "Group",
                value: "GroupPost",
              },
              {
                text: "Government",
                value: "GovernmentPost",
              },
            ],
            dataIndex: "__typename",
            onFilter: (value: string | number | boolean, post: IPost) => {
              return post.__typename === value;
            },
            filterMultiple: false,
            render: (_: any, post: IPost) => (
              <span>
                {post.__typename === "GovernmentPost" && <Tag color="cyan">Government</Tag>}
                {post.__typename === "GroupPost" && <Tag color="purple">Group</Tag>}
              </span>
            ),
          },
          {
            title: "Audience",
            dataIndex: "audience",
            render: (audience: string) => {
              return audience === "COMMUNITY" ? "Community" : "Public";
            },
          },
          {
            title: "Author",
            dataIndex: "author",
            render: (author: any) => {
              return <Link to={`/users?search=${author._id}&tab=${getUserTab(author)}`}>{author.fullName}</Link>;
            },
          },
          {
            title: "Created",
            sorter: (a: IPost, b: IPost) => {
              return moment(a.createdAt).toDate().getTime() - moment(b.createdAt).toDate().getTime();
            },
            render: (post: IPost) => {
              return moment(post.createdAt).format("MM/DD/YYYY");
            },
          },
          areAllArchived
            ? {
                title: "Archived At",
                sorter: (a: IPost, b: IPost) => {
                  return moment(a.deletedAt).toDate().getTime() - moment(b.deletedAt).toDate().getTime();
                },
                render: (post: IPost) => {
                  return moment(post.deletedAt).format("MM/DD/YYYY");
                },
              }
            : null,
          areAllArchived
            ? {
                title: "Archived By",
                render: (post: IPost) => {
                  if (post.deletedBy) {
                    return <Link to={`/users/${post.deletedBy.slug}`}>{post.deletedBy.fullName}</Link>;
                  } else {
                    return "N/A";
                  }
                },
              }
            : null,
          {
            title: "Actions",
            dataIndex: "_id",
            render: (_: any, post: IPost) => {
              return <PostActions post={post} onRemovePost={onRemovePost} />;
            },
          },
        ].filter(notUndefinedOrNull)}
      />

      {editingPost && (
        <PostFormModal
          isOpen={true}
          initialValues={convertPostToInitialValues(editingPost)}
          onRequestClose={() => setEditingPost(null)}
        />
      )}
    </>
  );
}

function getUserTab(user: IUser) {
  if (user.__typename === "GovernmentUser") {
    return "governments";
  }

  if (user.__typename === "VendorUser") {
    return "vendors";
  }

  return "pending";
}

function PostActions({ post, onRemovePost }: { post: IPost; onRemovePost?: () => any }) {
  return (
    <TableRowActions title="Group Actions">
      {({ onClick }: any) => (
        <>
          {post.deletedAt ? null : (
            <Mutation<ITogglePostFeaturedMutation, ITogglePostFeaturedMutationVariables>
              mutation={TogglePostFeatured}
              variables={{
                id: post._id,
              }}
              optimisticResponse={{
                __typename: "Mutation" as any,
                togglePostFeatured: {
                  __typename: post.__typename as any,
                  _id: post._id,
                  featuredAt: post.featuredAt ? null : new Date(),
                },
              }}
            >
              {(toggle) => (
                <li>
                  <a
                    href="#"
                    css={{
                      color: post.featuredAt ? palette.red : palette.primary,
                    }}
                    onClick={onClick(() => toggle())}
                  >
                    {post.featuredAt ? "Remove featured" : "Set featured"}
                  </a>
                </li>
              )}
            </Mutation>
          )}

          <li>
            <a
              href={`${PDF_URL}?url=${getGovlaunchBaseUrl()}/posts/${post._id}?media=print&cookies=${encodeURIComponent(
                JSON.stringify({
                  token: getToken(),
                }),
              )}&download=true`}
              target="_blank"
              rel="noreferrer noopener"
              css={{
                color: post.featuredAt ? palette.red : palette.primary,
              }}
            >
              Export to PDF
            </a>
          </li>

          {post.deletedAt ? null : (
            <li>
              <Mutation<IDeletePostMutation, IDeletePostMutationVariables>
                mutation={DeletePost}
                refetchQueries={["AllPosts"]}
                variables={{
                  id: post._id,
                }}
              >
                {(removePost) => (
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                      return removePost().then(() => {
                        return onRemovePost ? onRemovePost() : null;
                      });
                    }}
                  >
                    <a
                      href="#"
                      css={{
                        color: palette.red,
                      }}
                    >
                      Archive
                    </a>
                  </Popconfirm>
                )}
              </Mutation>
            </li>
          )}
        </>
      )}
    </TableRowActions>
  );
}

export default PostsTable;
