import * as palette from "@govlaunch/palette";
import React from "react";
import CheckboxField from "../form/fields/CheckboxField";
import { ThemeProvider, theme, CSSReset } from "@govlaunch/web";
import Toolbar from "./form/Toolbar";
import MainFields from "./form/MainFields";

interface IProjectFormProps {
  handleSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => any;
  submitting: boolean;
  withGovernment?: boolean;
  submitButtonText?: string;
  titleText?: string;
  showVerifiedCheckbox?: boolean;
  submitError?: any;
}

export default function ProjectForm({
  handleSubmit,
  submitting,
  withGovernment = true,
  submitButtonText = "Publish",
  titleText = "Create Project",
  showVerifiedCheckbox = true,
  submitError,
}: IProjectFormProps) {
  return (
    <form onSubmit={handleSubmit}>
      <ThemeProvider theme={theme}>
        <CSSReset />
        <div
          css={{
            width: 680,
            padding: 16,
            backgroundColor: palette.white,
            border: `solid 1px ${palette.lightSealBlue}`,
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 16,
              alignItems: "center",
            }}
          >
            <h3
              css={{
                margin: 0,
                color: palette.darkGray,
                fontWeight: 500,
                fontSize: 21,
              }}
            >
              {titleText}
            </h3>

            {showVerifiedCheckbox && (
              <CheckboxField
                fieldName="verified"
                label="Verify"
                labelChecked="Verified"
                colorChecked={palette.greenSuccess}
                css={{
                  marginLeft: "auto",
                }}
              />
            )}
          </div>

          <div
            css={{
              display: "grid",
              rowGap: 16,
            }}
          >
            <MainFields withGovernment={withGovernment} />

            <Toolbar submitting={submitting} submitButtonText={submitButtonText} submitError={submitError} />
          </div>
        </div>
      </ThemeProvider>
    </form>
  );
}
