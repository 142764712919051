/* eslint-disable react/display-name */
import { Tabs } from "antd";
import queryString from "query-string";
import React from "react";
import DocumentTitle from "react-document-title";
import { IProductFilter } from "../../../types/types";
import ProductsCount from "./ProductsCount";
import { useLocation, useNavigate } from "react-router";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { ProductsWithSearch } from "./tables/ProductsWithSearch";
import TablePageHeader from "../../components/table/TablePageHeader";
import { MetaBaseQuestion } from "../../components/MetaBaseLink";

export default function AllProducts() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = query.tab || "all";

  return (
    <DocumentTitle title="All Products | Admin">
      <div>
        <TablePageHeader
          title="Products"
          titleIcon={faBoxOpen}
          subTitle="All Products"
          metabaseQuestion={MetaBaseQuestion.Products}
        />
        <ProductsCount>
          {({
            allCount,
            disruptorsCount,
            archivedCount,
            refetch,
          }: {
            allCount: number;
            disruptorsCount: number;
            archivedCount: number;
            refetch: () => any;
          }) => (
            <Tabs
              animated={false}
              onChange={(tab) => {
                navigate(`/products?tab=${tab}`);
                refetch();
              }}
              activeKey={tab as string}
              size="middle"
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
            >
              <Tabs.TabPane tab={allCount ? `All (${allCount})` : "All"} key="all">
                {tab === "all" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <ProductsWithSearch filter={IProductFilter.All} refetchCount={refetch} />
                  </div>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={disruptorsCount ? `Disruptors (${disruptorsCount})` : "Disruptors"} key="disruptors">
                {tab === "disruptors" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <ProductsWithSearch filter={IProductFilter.Disruptors} refetchCount={refetch} />
                  </div>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={archivedCount ? `Archived (${archivedCount})` : "Archived"} key="archived">
                {tab === "archived" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <ProductsWithSearch filter={IProductFilter.Archived} refetchCount={refetch} />
                  </div>
                )}
              </Tabs.TabPane>
            </Tabs>
          )}
        </ProductsCount>
      </div>
    </DocumentTitle>
  );
}
