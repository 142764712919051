import React from "react";
import { Mutation } from "react-apollo";
import { IFeaturedTargetType } from "../../types/types";
import ToggleFeaturedStory from "./ToggleFeaturedStory";
import {
  IToggleFeaturedStoryMutation,
  IToggleFeaturedStoryMutationVariables,
} from "./__generated__/ToggleFeaturedStory.generated";

interface IChildrenFunc {
  toggle: () => any;
}

interface IToggleFeaturedProps {
  targetType: IFeaturedTargetType;
  targetId: any;
  featured: boolean;
  children: (arg: IChildrenFunc) => any;
}

export default function ToggleFeatured({ targetType, targetId, featured, children }: IToggleFeaturedProps) {
  return (
    <Mutation<IToggleFeaturedStoryMutation, IToggleFeaturedStoryMutationVariables>
      optimisticResponse={{
        __typename: "Mutation",
        toggleFeatured: {
          __typename: "Story",
          _id: targetId,
          featured: !featured,
        },
      }}
      variables={{
        targetType,
        targetId,
      }}
      mutation={ToggleFeaturedStory}
    >
      {(toggle) =>
        children({
          toggle,
        })
      }
    </Mutation>
  );
}
