import gql from "graphql-tag";

export default gql`
  fragment StoryFieldsAdmin on Story {
    _id
    title
    slug
    status
    audience
    featured
    horizontalCover
    verticalCover
    lastPublishedAt
    modifiedAt
    createdAt
    archivedAt
    archivedBy {
      ... on PendingUser {
        _id
        slug
        fullName
      }
      ... on VendorUser {
        _id
        slug
        fullName
      }
      ... on GovernmentUser {
        _id
        slug
        fullName
      }
    }
    government {
      _id
      logo
      slug
      name
      state
      city {
        _id
        name
        country
      }
    }
    author {
      ... on PendingUser {
        _id
        slug
        isSiteAdmin
        fullName
        jobTitle
        avatar
      }
      ... on VendorUser {
        _id
        slug
        isSiteAdmin
        fullName
        title
        avatar
        jobTitle
        company {
          _id
          slug
          name
          logo
        }
      }
      ... on GovernmentUser {
        _id
        slug
        isSiteAdmin
        fullName
        title
        avatar
        isInnovator
        jobTitle
        government {
          _id
          slug
          name
          logo
        }
      }
    }
    tags {
      _id
      slug
      name
    }
  }
`;
