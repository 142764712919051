import React from "react";
import { useQuery } from "react-apollo";
import TrendingCollections from "../../../../queries/TrendingCollections";
import {
  ITrendingCollectionsQuery,
  ITrendingCollectionsQueryVariables,
} from "../../../../queries/__generated__/TrendingCollections.generated";
import { IBlockLayout, ICollectionsBlockData } from "../blockProps";
import CenteredLoadingSpinner from "../CenteredLoadingSpinner";
import CollectionsAsRows from "./CollectionsAsRows";
import CollectionsAsTags from "./CollectionsAsTags";

interface ITrendingCollectionsBlockDataProps {
  block: IBlockLayout;
}

export default function TrendingCollectionsBlockData({ block }: ITrendingCollectionsBlockDataProps) {
  const { data, loading } = useQuery<ITrendingCollectionsQuery, ITrendingCollectionsQueryVariables>(
    TrendingCollections,
    {
      variables: {
        limit: block.blockData.itemLimit || 10,
      },
      fetchPolicy: "network-only",
    },
  );

  if (!data || loading) {
    return <CenteredLoadingSpinner />;
  }

  const collections = data && data.trendingCollections ? data.trendingCollections || [] : [];
  const displayAsTags = (block.blockData as ICollectionsBlockData).displayAsTags;

  return (
    <>
      {displayAsTags ? (
        <CollectionsAsTags collections={collections} />
      ) : (
        <CollectionsAsRows collections={collections} />
      )}
    </>
  );
}
