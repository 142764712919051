import React from "react";
import { Field } from "react-final-form";
import { IProjectAttachmentItem } from "../../../../../types/types";
import nullthrows from "../../nullthrows";
import AttachmentItem from "../../rich/AttachmentItem";
import * as palette from "@govlaunch/palette";
import fp from "lodash/fp";

export default function AttachmentsField() {
  return (
    <Field name="attachments">
      {({ input }) => {
        const attachments = nullthrows(input.value);

        if (attachments.length === 0) {
          return null;
        }

        return (
          <>
            <h5
              css={{
                margin: 0,
                fontWeight: 600,
                fontSize: 16,
                color: palette.darkGray,
              }}
            >
              Attachments
            </h5>

            <div
              css={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: 10,
                rowGap: 10,
              }}
            >
              {input.value.map((attachment: IProjectAttachmentItem) => {
                return (
                  <AttachmentItem
                    key={attachment.url!}
                    attachment={attachment}
                    onRemove={(attachment: IProjectAttachmentItem) =>
                      input.onChange(fp.reject(attachment, input.value))
                    }
                  />
                );
              })}
            </div>
          </>
        );
      }}
    </Field>
  );
}
