import gql from "graphql-tag";

export default gql`
  mutation SetVendorCreditAmount($vendorId: ObjectId!, $amount: Float!) {
    setVendorCreditAmount(vendorId: $vendorId, amount: $amount) {
      _id
      canEarnCreditsUntil
      earnableCreditAmount
    }
  }
`;
