import React, { ChangeEvent } from "react";
import { IHeaderBannerData } from "./headerBannerProps";
import * as palette from "@govlaunch/palette";
import { Input } from "@govlaunch/core";
import EditBlockColorPicker from "../blockslayout/EditBlockColorPicker";

interface IHeaderBannerButtonSettingsProps {
  data: IHeaderBannerData;
  setData: React.Dispatch<React.SetStateAction<IHeaderBannerData>>;
  buttonField: string;
}

export default function HeaderBannerButtonSettings({ data, setData, buttonField }: IHeaderBannerButtonSettingsProps) {
  return (
    <>
      <label
        css={{
          fontWeight: 500,
          fontSize: 14,
          color: palette.mediumGray,
        }}
      >
        Button text
      </label>

      <Input
        css={{
          marginTop: "8px",
          height: 42,
          borderRadius: 5,
          border: `1px solid ${palette.lightestGray}`,
          fontSize: 14,
          letterSpacing: 0.5,
          fontWeight: 500,
          padding: "5px 10px",
          width: "100%",
          outline: 0,
          "::placeholder": {
            color: palette.sealBlue,
          },
          ":focus": {
            borderColor: palette.primary,
          },
        }}
        placeholder="Enter button text..."
        value={data[`${buttonField}Text` as keyof IHeaderBannerData] || ""}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setData({
            ...data,
            [`${buttonField}Text`]: event.target.value,
          });
        }}
      />

      <div
        css={{
          marginTop: 8,
        }}
      >
        <label
          css={{
            fontWeight: 500,
            fontSize: 14,
            color: palette.mediumGray,
          }}
        >
          Button href
        </label>
      </div>

      <Input
        css={{
          marginTop: "8px",
          height: 42,
          borderRadius: 5,
          border: `1px solid ${palette.lightestGray}`,
          fontSize: 14,
          letterSpacing: 0.5,
          fontWeight: 500,
          padding: "5px 10px",
          width: "100%",
          outline: 0,
          "::placeholder": {
            color: palette.sealBlue,
          },
          ":focus": {
            borderColor: palette.primary,
          },
        }}
        placeholder="E.g. https://google.com or /signin"
        value={data[`${buttonField}Href` as keyof IHeaderBannerData] || ""}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setData({
            ...data,
            [`${buttonField}Href`]: event.target.value,
          });
        }}
      />

      <div
        css={{
          marginTop: "8px",
        }}
      >
        <EditBlockColorPicker
          data={data}
          setData={setData}
          fieldName={`${buttonField}BackgroundColor`}
          label="Background color"
          placeholder="Pick color..."
        />
      </div>

      <div
        css={{
          marginTop: "8px",
        }}
      >
        <EditBlockColorPicker
          data={data}
          setData={setData}
          fieldName={`${buttonField}TextColor`}
          label="Text color"
          placeholder="Pick color..."
        />
      </div>

      <div
        css={{
          marginTop: "8px",
        }}
      >
        <EditBlockColorPicker
          data={data}
          setData={setData}
          fieldName={`${buttonField}BorderColor`}
          label="Border color"
          placeholder="Pick color..."
        />
      </div>

      <div
        css={{
          marginTop: "8px",
        }}
      >
        <EditBlockColorPicker
          data={data}
          setData={setData}
          fieldName={`${buttonField}HoverColor`}
          label="Hover color"
          placeholder="Pick color..."
        />
      </div>
    </>
  );
}
