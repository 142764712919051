import React from "react";
import DocumentTitle from "react-document-title";
import * as palette from "@govlaunch/palette";
import NewExternalLinkForm from "./NewExternalLinkForm";

export default function NewExternalLinkPage() {
  return (
    <DocumentTitle title="New External Link | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
        }}
      >
        <NewExternalLinkForm />
      </div>
    </DocumentTitle>
  );
}
