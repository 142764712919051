import React from "react";
import { Form as FinalForm, Field } from "react-final-form";
import * as Yup from "yup";
import * as palette from "@govlaunch/palette";
import { Margin } from "../../spacings";
import { Filestack, Button } from "@govlaunch/core";
import validate from "../../validate";
import DocumentIcon from "../../components/icons/DocumentIcon";
import LinkIcon from "../../components/icons/LinkIcon";
import ResourceFieldError from "./ResourceFieldError";
import FontIcon from "../../components/icons/FontIcon";
import TextareaAutosize from "react-textarea-autosize";
import { isValidUrlYup } from "../../utils/isValidUrl";

interface IResourceForm {
  onSubmit: (values: any) => any;
  onClose: () => any;
  allowLinks?: boolean;
  allowDocuments?: boolean;
  allowText?: boolean;
}

export default function ResourceForm({
  onSubmit,
  onClose,
  allowLinks = true,
  allowDocuments = true,
  allowText = false,
}: IResourceForm) {
  return (
    <FinalForm
      keepDirtyOnReinitialize={true}
      initialValues={{
        type: "link",
      }}
      onSubmit={onSubmit}
      validate={validateResourceForm}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div
            css={{
              width: 350,
              padding: 15,
              marginTop: 10,
            }}
          >
            <Field name="type">
              {({ input }) => (
                <div
                  css={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: 100,
                    margin: "0 auto",
                  }}
                >
                  {allowLinks && (
                    <div
                      css={{
                        position: "relative",
                        display: "flex",
                        cursor: "pointer",
                      }}
                      onClick={() => input.onChange("link")}
                    >
                      <LinkIcon width={24} height={24} fillColor={palette.primary} />
                      {values.type === "link" && (
                        <div
                          css={{
                            height: 2,
                            width: "100%",
                            position: "absolute",
                            bottom: -4,
                            left: 0,
                            background: palette.primary,
                          }}
                        />
                      )}
                    </div>
                  )}

                  {allowDocuments && (
                    <div
                      css={{
                        position: "relative",
                        display: "flex",
                        cursor: "pointer",
                      }}
                      onClick={() => input.onChange("download")}
                    >
                      <DocumentIcon width={24} height={24} fillColor={palette.primary} />
                      {values.type === "download" && (
                        <div
                          css={{
                            height: 2,
                            width: "100%",
                            position: "absolute",
                            bottom: -4,
                            left: 0,
                            background: palette.primary,
                          }}
                        />
                      )}
                    </div>
                  )}

                  {allowText && (
                    <div
                      css={{
                        position: "relative",
                        display: "flex",
                        cursor: "pointer",
                      }}
                      onClick={() => input.onChange("text")}
                    >
                      <FontIcon width={24} height={24} fillColor={palette.primary} />
                      {values.type === "text" && (
                        <div
                          css={{
                            height: 2,
                            width: "100%",
                            position: "absolute",
                            bottom: -4,
                            left: 0,
                            background: palette.primary,
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </Field>

            <div
              css={{
                paddingTop: 15,
                paddingBottom: 15,
              }}
            >
              <Field name="title">
                {({ input, meta }) => (
                  <>
                    <input
                      css={{
                        height: 42,
                        borderRadius: 5,
                        border: `1px solid ${palette.lightestGray}`,
                        fontSize: 14,
                        padding: "5px 10px",
                        width: "100%",
                        outline: 0,
                        "::placeholder": {
                          color: palette.sealBlue,
                        },
                        ":focus": {
                          borderColor: palette.primary,
                        },
                      }}
                      placeholder="Title"
                      {...input}
                    />
                    <ResourceFieldError meta={meta} />
                  </>
                )}
              </Field>

              <Margin mt={10}>
                {values.type === "download" && (
                  <Field name="url">
                    {({ input, meta }) => (
                      <>
                        <Filestack
                          onSuccess={({ filesUploaded }: any) => input.onChange(filesUploaded[0].url)}
                          customRender={({ onPick }: { onPick: any }) => (
                            <Button color={palette.primary} textColor="#fff" size="xsmall" onClick={onPick}>
                              Upload File
                            </Button>
                          )}
                        />
                        {input.value && (
                          <Margin mt={5}>
                            <a
                              css={{
                                fontSize: 12,
                                color: palette.primary,
                              }}
                              href={input.value}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {input.value}
                            </a>
                          </Margin>
                        )}

                        <ResourceFieldError meta={meta} />
                      </>
                    )}
                  </Field>
                )}

                {values.type === "link" && (
                  <Field name="url">
                    {({ input, meta }) => (
                      <>
                        <input
                          css={{
                            height: 42,
                            borderRadius: 5,
                            border: `1px solid ${palette.lightestGray}`,
                            fontSize: 14,
                            padding: "5px 10px",
                            width: "100%",
                            outline: 0,
                            "::placeholder": {
                              color: palette.sealBlue,
                            },
                            ":focus": {
                              borderColor: palette.primary,
                            },
                          }}
                          type="url"
                          placeholder="http://"
                          {...input}
                        />
                        <ResourceFieldError meta={meta} />
                      </>
                    )}
                  </Field>
                )}

                {values.type === "text" && (
                  <Field name="body">
                    {({ input, meta }) => (
                      <>
                        <TextareaAutosize
                          {...input}
                          minRows={2}
                          css={{
                            resize: "none",
                            width: "100%",
                            outline: 0,
                            borderRadius: 5,
                            border: `1px solid ${palette.lightestGray}`,
                            padding: "5px 10px",
                            fontSize: 16,
                            "::placeholder": {
                              color: palette.sealBlue,
                            },
                            ":focus": {
                              borderColor: palette.primary,
                            },
                          }}
                          placeholder="Enter text"
                        />
                        <ResourceFieldError meta={meta} />
                      </>
                    )}
                  </Field>
                )}
              </Margin>
            </div>

            <div
              css={{
                display: "flex",
              }}
            >
              <Button color={palette.primary} type="submit" textColor="#fff">
                Save
              </Button>
              <div
                css={{
                  width: 15,
                }}
              />
              <Button color={palette.primary} theme="secondary" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      )}
    </FinalForm>
  );
}

function validateResourceForm(values: any) {
  if (values.type === "download") {
    return validate(
      Yup.object().shape({
        title: Yup.string().required("Can't be blank"),
        url: Yup.string().test("url", "invalid url", isValidUrlYup).required("Can't be blank"),
      }),
    )(values);
  }

  if (values.type === "link") {
    return validate(
      Yup.object().shape({
        title: Yup.string().required("Can't be blank"),
        url: Yup.string().test("url", "invalid url", isValidUrlYup).required("Can't be blank"),
      }),
    )(values);
  }

  return validate(
    Yup.object().shape({
      title: Yup.string().required("Can't be blank"),
      body: Yup.string().required("Can't be blank"),
    }),
  )(values);
}
