import React from "react";
import * as palette from "@govlaunch/palette";
import { Select } from "antd";
import { theme } from "@govlaunch/web";
import notUndefinedOrNull from "../../../utils/notUndefinedOrNull";

interface IEditBlockColorPickerProps {
  data: any;
  setData: any;
  fieldName: string;
  label: string;
  placeholder: string;
  defaultValue?: string;
}

export default function EditBlockColorPicker({
  data,
  setData,
  fieldName,
  label,
  placeholder,
  defaultValue,
}: IEditBlockColorPickerProps) {
  return (
    <>
      <label
        css={{
          marginTop: "16px",
          fontWeight: 500,
          fontSize: 14,
          color: palette.mediumGray,
        }}
      >
        {label}
      </label>
      <div
        css={{
          marginTop: "8px",
          borderRadius: 5,
          padding: "4px 0px 4px 0px",
          border: `1px solid ${palette.lightestGray}`,
          ":focus-within": {
            borderColor: palette.primary,
          },
        }}
      >
        <Select
          //@ts-ignore
          value={data[fieldName] || defaultValue}
          onChange={(value) => {
            setData({
              ...data,
              [fieldName]: value,
            });
          }}
          style={{
            borderRadius: 5,
            fontSize: 14,
            width: "100%",
          }}
          bordered={false}
          placeholder={placeholder}
        >
          {Object.keys(theme.colors)
            .map((colorKey) => {
              if (typeof (theme.colors as any)[colorKey] !== "string") {
                return null;
              }

              const colorHex = (theme.colors as any)[colorKey];

              if (colorHex === "currentColor") {
                return null;
              }

              return (
                <Select.Option value={colorHex} key={colorKey}>
                  <div
                    css={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      css={{
                        marginRight: 8,
                        display: "inline-block",
                        width: 25,
                        height: 25,
                        background: colorHex,
                        border: `1px solid ${palette.lightestGray}`,
                        borderRadius: 5,
                      }}
                    />

                    <span>{colorHex}</span>
                  </div>
                </Select.Option>
              );
            })
            .filter(notUndefinedOrNull)}

          {Object.keys(theme.colors)
            .filter((colorKey) => {
              return !["blackAlpha", "whiteAlpha", "currentColor"].includes(colorKey);
            })
            .map((colorKey) => {
              if (typeof (theme.colors as any)[colorKey] === "string") {
                return null;
              }

              return (
                <Select.OptGroup key={colorKey} label={colorKey}>
                  {Object.keys((theme.colors as any)[colorKey]).map((colorShade) => {
                    const colorHex = (theme.colors as any)[colorKey][colorShade];

                    return (
                      <Select.Option value={colorHex} key={colorHex}>
                        <div
                          css={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            css={{
                              marginRight: 8,
                              display: "inline-block",
                              width: 25,
                              height: 25,
                              background: colorHex,
                              border: `1px solid ${palette.lightestGray}`,
                              borderRadius: 5,
                            }}
                          />
                          <span>
                            {colorKey}.{colorShade}
                          </span>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select.OptGroup>
              );
            })
            .filter(notUndefinedOrNull)}
        </Select>
      </div>
    </>
  );
}
