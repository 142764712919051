import { Input, LoadingIndicator } from "@govlaunch/core";
import React, { useRef, useState } from "react";

import PropTypes from "prop-types";
import useUnfurl from "../../../hooks/useUnfurl";

interface IUnfurl {
  title: string;
  description: string;
  siteName: string;
  favicon: string;
  url: string;
}

interface INewsworthyInput {
  onChange: (value: IUnfurl) => any;
  placeholder?: string;
  autoFocus?: boolean;
}

export default function NewsworthyInput({ onChange }: INewsworthyInput) {
  const [url, setUrl] = useState("");
  const inputRef = useRef<HTMLInputElement>();

  const [loading] = useUnfurl(
    url,
    (unfurl) => {
      (inputRef.current as HTMLInputElement).value = "";
      onChange(unfurl);
    },
    () => {
      (inputRef.current as HTMLInputElement).value = "";
    },
  );

  return (
    <div
      css={{
        position: "relative",
      }}
    >
      <Input
        innerRef={inputRef}
        disabled={loading}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            setUrl((event.target as HTMLInputElement).value);
          }
        }}
        placeholder="Enter a URL..."
        css={{
          paddingRight: loading ? 40 : 10,
        }}
      />

      {loading && (
        <div
          css={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: 10,
          }}
        >
          <LoadingIndicator />
        </div>
      )}
    </div>
  );
}

NewsworthyInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};
