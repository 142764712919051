import { AnchorButton, Box, Flex } from "@govlaunch/web";
import React from "react";
import { IHeaderBannerData } from "./headerBannerProps";

interface IHeaderBannerFieldContentProps {
  data: IHeaderBannerData;
}

export default function HeaderBannerFieldContent({ data }: IHeaderBannerFieldContentProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "318px",
        width: "100%",
        position: "relative",
      }}
    >
      {data.backgroundImage && data.backgroundImage.url && (
        <img
          src={data.backgroundImage.url}
          css={{
            width: "100%",
            position: "absolute",
          }}
          alt={data.backgroundImage.alt}
        />
      )}
      <div
        css={{
          width: "100%",
          height: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          zIndex: 99,
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        {(data.title || data.subtitle || data.leftButtonText || data.rightButtonText) && (
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              padding: "10px",
            }}
          >
            {data.title && (
              <div
                css={{
                  color: data.titleColor || "#FFFFFF",
                  fontWeight: 700,
                  fontSize: "32px",
                  lineHeight: "40px",
                }}
              >
                {data.title}
              </div>
            )}
            {data.subtitle && (
              <div
                css={{
                  marginTop: data.title ? "20px" : undefined,
                  color: data.subtitleColor || "#FFFFFF",
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "24px",
                }}
              >
                {data.subtitle}
              </div>
            )}
            {(data.leftButtonText || data.rightButtonText) && (
              <Flex
                display="flex"
                wrap="wrap"
                direction={["column", "column", "column", "row"]}
                align={["center", "center", "center", "unset"]}
              >
                <Box mt={["40px", "40px", "40px", "40px"]} order={[2, 2, 2, 1]}>
                  {data.leftButtonText && (
                    <AnchorButton
                      variant="primary"
                      bg={data.leftButtonBackgroundColor}
                      color={data.leftButtonTextColor || "#ffffff"}
                      borderColor={data.leftButtonBorderColor}
                      _hover={{
                        bg: data.leftButtonHoverColor,
                        borderColor: data.leftButtonHoverColor,
                      }}
                      mr={data.rightButtonText ? "24px" : 0}
                      minWidth="250px"
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {data.leftButtonText}
                    </AnchorButton>
                  )}
                  {data.rightButtonText && (
                    <AnchorButton
                      variant="primary"
                      bg={data.rightButtonBackgroundColor}
                      color={data.rightButtonTextColor || "#ffffff"}
                      borderColor={data.rightButtonBorderColor}
                      _hover={{
                        bg: data.rightButtonHoverColor,
                        borderColor: data.rightButtonHoverColor,
                      }}
                      minWidth="250px"
                      whiteSpace="pre-wrap"
                      textAlign="center"
                    >
                      {data.rightButtonText}
                    </AnchorButton>
                  )}
                </Box>
              </Flex>
            )}
          </div>
        )}
        {data.foregroundImage && data.foregroundImage.url && (
          <img
            src={data.foregroundImage.url}
            alt={data.foregroundImage.alt}
            css={{
              height: "244px",
            }}
          />
        )}
      </div>
    </div>
  );
}
