import { LoadingIndicator } from "@govlaunch/core";
import React from "react";
import { useQuery } from "react-apollo";
import BuildNewsletterCampaignData from "./graphql/BuildNewsletterCampaignData";
import {
  IBuildNewsletterCampaignDataQuery,
  IBuildNewsletterCampaignDataQueryVariables,
} from "./graphql/__generated__/BuildNewsletterCampaignData.generated";
import CampaignPreviewCollections from "./preview/CampaignPreviewCollections";
import CampaignPreviewFollowButtons from "./preview/CampaignPreviewFollowButtons";
import CampaignPreviewFooter from "./preview/CampaignPreviewFooter";
import CampaignPreviewHeader from "./preview/CampaignPreviewHeader";
import CampaignPreviewHeaderLinks from "./preview/CampaignPreviewHeaderLinks";
import CampaignPreviewProjects from "./preview/CampaignPreviewProjects";
import CampaignPreviewStories from "./preview/CampaignPreviewStories";

interface ICampaignContentPreviewProps {
  preview?: boolean;
  campaignId: string;
}

export default function CampaignContentPreview({ preview = true, campaignId }: ICampaignContentPreviewProps) {
  const { data, loading } = useQuery<IBuildNewsletterCampaignDataQuery, IBuildNewsletterCampaignDataQueryVariables>(
    BuildNewsletterCampaignData,
    {
      variables: {
        campaignId,
      },
      fetchPolicy: "network-only",
    },
  );

  if (loading || !data || !data.buildNewsletterCampaignData) {
    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: "600px",
        }}
      >
        {!preview &&
          `<!--[if gte mso 9]>
			      <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
				      <v:fill type="tile" color="#ffffff"></v:fill>
			      </v:background>
		    <![endif]--> `}

        <div
          style={{
            width: "100%",
            fontFamily: "helvetica, 'helvetica neue', arial, verdana, sans-serif",
            WebkitTextSizeAdjust: "100%",
            //@ts-ignore
            msTextSizeAdjust: "100%",
            padding: 0,
            margin: 0,
          }}
        >
          <div
            className="es-wrapper-color"
            style={{
              backgroundColor: "#FFFFFF",
            }}
          >
            <table
              className="es-wrapper"
              cellSpacing="0"
              cellPadding="0"
              style={{
                borderCollapse: "collapse",
                borderSpacing: "0px",
                padding: 0,
                margin: 0,
                width: "100%",
                backgroundRepeat: "repeat",
                backgroundPosition: "center top",
                backgroundColor: "#FFFFFF",
              }}
            >
              <tbody>
                <tr>
                  <td
                    valign="top"
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <table
                      className="es-content"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: "0px",
                        tableLayout: "fixed",
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            align="center"
                            style={{
                              padding: 0,
                              margin: 0,
                              backgroundColor: "#FFFFFF",
                            }}
                          >
                            <table
                              className="es-content-body"
                              cellSpacing="0"
                              cellPadding="0"
                              style={{
                                borderCollapse: "collapse",
                                borderSpacing: "0px",
                                backgroundColor: "#f9f9f9",
                                width: "600px",
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="left"
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <CampaignPreviewHeader />
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="left"
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      backgroundColor: "#086bef",
                                    }}
                                  >
                                    <CampaignPreviewHeaderLinks />
                                  </td>
                                </tr>
                                <CampaignPreviewStories stories={data.buildNewsletterCampaignData.stories} />
                                <CampaignPreviewProjects
                                  projects={data.buildNewsletterCampaignData.projects}
                                  totalCount={data.buildNewsletterCampaignData.totalProjectsCount}
                                />
                                <CampaignPreviewCollections
                                  collections={data.buildNewsletterCampaignData.collections}
                                />
                                <CampaignPreviewFollowButtons preview={preview} />
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <CampaignPreviewFooter preview={preview} />
          </div>
        </div>
      </div>
    </div>
  );
}
