import { MultiTagSelector, useAlgoliaSearch } from "@govlaunch/web";
import React from "react";
import { Field } from "react-final-form";

interface ITagAlgoliaHit {
  objectID: string;
  name: string;
}

interface ITagItem {
  value: string;
  label: string;
}

export default function AwardsField() {
  const { setSearchState, results } = useAlgoliaSearch<ITagAlgoliaHit>({
    initialSearchState: {
      index: "tags",
      facetFilters: "types:AWARD",
    },
  });

  const items: ITagItem[] = results
    ? results.hits.slice(0, 5).map((hit) => ({
        value: hit.objectID,
        label: hit.name,
      }))
    : [];

  return (
    <Field name="awards">
      {({ input, meta }) => (
        <MultiTagSelector
          id="awards"
          selectedTags={input.value}
          tags={items}
          onChange={input.onChange}
          onBlur={() => input.onBlur()}
          placeholder="Type an award name... "
          onInputValueChange={(query) => {
            setSearchState((currentState) => ({
              ...currentState,
              query,
            }));
          }}
          tagProps={{
            variant: "rounded",
          }}
          inputProps={{
            invalid: meta.error && meta.touched,
          }}
          bg="teal.100"
        />
      )}
    </Field>
  );
}
