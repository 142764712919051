import React from "react";
import { IGetTagBySlugQuery } from "../../../../queries/__generated__/GetTagBySlug.generated";
import GovernmentsWithSearch from "../../../governments/tables/GovernmentsWithSearch";
import { IGovernmentsFilter } from "../../../governments/tables/ManageGovernments";

interface ITagGovernmentsTabProps {
  tag: IGetTagBySlugQuery["tag"];
}

export default function TagGovernmentsTab({ tag }: ITagGovernmentsTabProps) {
  if (!tag) {
    return null;
  }

  return (
    <div
      css={{
        padding: 20,
      }}
    >
      <GovernmentsWithSearch
        filter={IGovernmentsFilter.All}
        filterByTag={tag._id}
        historyOrigin={`/tags/${tag.slug}/governments`}
      />
    </div>
  );
}
