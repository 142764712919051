import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import { Table } from "antd";
import moment from "moment";
import React from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import VendorCredits from "../../queries/VendorCredits";
import { IVendorCreditsQuery, IVendorCreditsQueryVariables } from "../../queries/__generated__/VendorCredits.generated";
import notUndefinedOrNull from "../../utils/notUndefinedOrNull";

export default function VendorCreditsTable() {
  return (
    <Query<IVendorCreditsQuery, IVendorCreditsQueryVariables>
      fetchPolicy="network-only"
      query={VendorCredits}
      variables={{}}
    >
      {({ data, loading }) => {
        if (loading) {
          return null;
        }
        if (!data) {
          return null;
        }
        return (
          <>
            <Table
              rowKey="_id"
              loading={loading}
              bordered={true}
              pagination={false}
              dataSource={data.allVendorCredits || []}
              columns={[
                {
                  key: "government",
                  title: "Government",
                  dataIndex: "name",
                  render: (_: string, { government }: IVendorCreditsQuery["allVendorCredits"][0]) => (
                    <div
                      css={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ProfilePicture
                        size={32}
                        image={government.logo}
                        innovator={government.isInnovator}
                        name={government.name}
                        css={{
                          whiteSpace: "nowrap",
                        }}
                      />

                      <Spacing left={5}>
                        <Link to={`/governments/${government.slug}`}>{government.city.name}</Link>
                      </Spacing>
                    </div>
                  ),
                },
                {
                  key: "vendor",
                  title: "Vendor",
                  render: (_: string, { vendor }: IVendorCreditsQuery["allVendorCredits"][0]) => (
                    <div
                      css={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ProfilePicture
                        size={32}
                        image={vendor.logo}
                        name={vendor.name}
                        css={{
                          whiteSpace: "nowrap",
                        }}
                      />

                      <Spacing left={5}>
                        <Link to={`/vendors/${vendor.slug}`}>{vendor.name}</Link>
                      </Spacing>
                    </div>
                  ),
                },
                {
                  key: "creditAmount",
                  title: "Amount",
                  render: ({ amount }: IVendorCreditsQuery["allVendorCredits"][0]) => {
                    return `$${Math.abs(amount / 100).toFixed(2)}`;
                  },
                },
                {
                  title: "Date",
                  key: "date",
                  render: ({ createdAt }: IVendorCreditsQuery["allVendorCredits"][0]) =>
                    moment(createdAt).format("MM/DD/YYYY"),
                },
              ].filter(notUndefinedOrNull)}
            />
          </>
        );
      }}
    </Query>
  );
}
