import gql from "graphql-tag";

export default gql`
  query AllVendorsQuery($filter: AllVendorsFilterInput!, $limit: Int = 20) {
    allVendors(filter: $filter, limit: $limit) {
      items {
        _id
        name
        slug
        logo
      }
    }
  }
`;
