import gql from "graphql-tag";

export default gql`
  fragment StripeChargeFields on Charge {
    id
    friendlyId
    status
    paidAt
    amount
    currency
    amountRefunded
    receiptUrl
    card {
      brand
      lastFourDigits
      expirationMonth
      expirationYear
    }
  }
`;
