import React from "react";
import { IBulkTaggingModifyStrategy } from "../../../../types/types";
import { IGovernmentProjectFieldsFragment } from "../../../queries/fragments/__generated__/GovernmentProjectFields.generated";
import { IGroupProjectFieldsFragment } from "../../../queries/fragments/__generated__/GroupProjectFields.generated";
import { IVendorProjectFieldsFragment } from "../../../queries/fragments/__generated__/VendorProjectFields.generated";
import * as palette from "@govlaunch/palette";
import { arrayUnique } from "../../../utils/arrayUtils";

interface IBulkTaggingFormValidationMessageProps {
  action: IBulkTaggingModifyStrategy;
  invalidProjects: Array<IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment>;
}

export default function BulkTaggingFormValidationMessage({
  action,
  invalidProjects,
}: IBulkTaggingFormValidationMessageProps) {
  return (
    <div css={{
 color: palette.red 
}}
    >
      {action === IBulkTaggingModifyStrategy.Add && (
        <span
          css={{
 color: palette.orange 
}}
        >{`The following ${invalidProjects.length} projects will exceed max number of tags: `}</span>
      )}
      {action === IBulkTaggingModifyStrategy.Overwrite && (
        <span css={{
 color: palette.orange 
}}
        >You can select up to 6 tags</span>
      )}
      {action === IBulkTaggingModifyStrategy.Remove && (
        <span
          css={{
 color: palette.orange 
}}
        >{`The following ${invalidProjects.length} projects will be left with no tags: `}</span>
      )}
      <ul css={{
 listStyle: "none", display: "flex", flexDirection: "column" 
}}
      >
        {invalidProjects.map((project) => (
          <li
            key={project._id}
            css={{
              backgroundColor: palette.white,
              borderRadius: 16,
              paddingLeft: 5,
              paddingRight: 5,
              margin: 2,
              color: palette.mediumGray,
              fontSize: 12,
            }}
          >
            {project.title}
          </li>
        ))}
      </ul>
    </div>
  );
}

export interface IValidateProjectsWithTagsResult {
  isValid: boolean;
  invalidProjects: Array<IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment>;
}

export function validateProjectsWithTags(
  action: IBulkTaggingModifyStrategy,
  projects: Array<IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment>,
  tags: any[],
): IValidateProjectsWithTagsResult {
  const invalidProjects: (
    | IGovernmentProjectFieldsFragment
    | IGroupProjectFieldsFragment
    | IVendorProjectFieldsFragment
  )[] = [];

  switch (action) {
    case IBulkTaggingModifyStrategy.Add:
      projects.forEach((project) => {
        if (arrayUnique(project.tags.map((p) => p._id).concat(tags.map((t) => t.value))).length > 6) {
          invalidProjects.push(project);
        }
      });
      break;
    case IBulkTaggingModifyStrategy.Overwrite:
      if (tags.length > 6) {
        return {
 isValid: false, invalidProjects: [] 
};
      }
      break;
    case IBulkTaggingModifyStrategy.Remove:
      projects.forEach((project) => {
        if (project.tags.filter((tag) => !tags.map((t) => t.value).includes(tag._id)).length === 0) {
          invalidProjects.push(project);
        }
      });
      break;
  }

  return {
 isValid: invalidProjects.length === 0, invalidProjects: invalidProjects 
};
}
