import * as palette from "@govlaunch/palette";
import { Col, Grid, RowWrap } from "@govlaunch/core";
import { EditorState, convertFromRaw } from "draft-js";
import { Spacing, SpacingBetween } from "@govlaunch/spacers";
import DocumentTitle from "react-document-title";
import { EditorWithToolbars } from "@govlaunch/editor";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import { MediaGallery } from "@govlaunch/gallery";
import ProfilePicture from "@govlaunch/profile-picture";
import { Query } from "react-apollo";
import React from "react";
import getGovlaunchBaseUrl from "../../utils/getGovlaunchBaseUrl";
import noop from "lodash/fp/noop";
import GetReportQuery from "../../queries/GetReport";
import { IGetReportQuery, IGetReportQueryVariables } from "../../queries/__generated__/GetReport.generated";

export default function Report() {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <DocumentTitle title="Report | Admin">
      <Query<IGetReportQuery, IGetReportQueryVariables>
        fetchPolicy="network-only"
        variables={{
          slug: params.slug || "",
        }}
        query={GetReportQuery}
      >
        {({ data, loading }) => {
          if (loading || !data) {
            return null;
          }

          const report = data.reportBySlug;

          if (!report) {
            navigate("/reports");
            return null;
          }

          return (
            <DocumentTitle title={`Admin | ${report.title}`}>
              <div>
                <div
                  css={{
                    padding: 20,
                    borderBottom: "1px solid rgb(232, 232, 232)",
                  }}
                >
                  <h2
                    css={{
                      fontWeight: 600,
                      fontSize: 24,
                      margin: 0,
                    }}
                  >
                    {report.title}
                  </h2>

                  <div>
                    <Link to="/reports">All Reports</Link>
                    {" • "}
                    <Link to={`/reports/${report.slug}/edit`}>Edit</Link>
                  </div>

                  <div
                    css={{
                      display: "flex",
                      marginTop: 10,
                    }}
                  >
                    <input
                      css={{
                        padding: 5,
                        borderRadius: 5,
                        fontFamily: "Monospace",
                        fontSize: 14,
                        color: palette.mediumGray,
                        border: `dashed 1px ${palette.darkSkyLightBlue}`,
                        width: "100%",
                      }}
                      readOnly={true}
                      value={`${getGovlaunchBaseUrl("innovators")}/reports/${report.slug}`}
                      onClick={(event: any) => {
                        event.target.select();
                      }}
                    />
                  </div>
                </div>

                <div
                  css={{
                    backgroundColor: palette.washGray,
                    padding: 20,
                    borderBottom: "1px solid rgb(232, 232, 232)",
                  }}
                >
                  <Grid>
                    <RowWrap>
                      <Col mx="auto" width={[1, 1, 1, 10 / 12]}>
                        <div
                          css={{
                            textAlign: "left",
                          }}
                        >
                          <h1
                            css={{
                              fontWeight: "bold",
                              margin: 0,
                            }}
                          >
                            {report.title}
                          </h1>

                          <p
                            css={{
                              margin: 0,
                              marginTop: 5,
                            }}
                          >
                            {report.description}
                          </p>
                        </div>
                      </Col>
                    </RowWrap>

                    {report.governments && report.governments.length > 0 && (
                      <RowWrap>
                        <Col mx="auto" width={[1, 1, 1, 10 / 12]} pt={[20, 20, 20, 20]}>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <SpacingBetween right={10}>
                              {report.governments.map((government) => (
                                <div
                                  key={government._id}
                                  css={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ProfilePicture
                                    size={34}
                                    name={government.name}
                                    image={government.logo}
                                    innovator={government.isInnovator}
                                  />

                                  <Spacing
                                    left={5}
                                    inline={true}
                                    flex={true}
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <span
                                      css={{
                                        fontWeight: 500,
                                        fontSize: 14,
                                        color: palette.text,
                                      }}
                                    >
                                      {government.name}
                                    </span>

                                    <span
                                      css={{
                                        fontSize: 12,
                                        color: palette.darkSkyLightBlue,
                                        marginLeft: 5,
                                      }}
                                    >
                                      <CheckIcon />
                                    </span>
                                  </Spacing>
                                </div>
                              ))}
                            </SpacingBetween>
                          </div>
                        </Col>
                      </RowWrap>
                    )}

                    {report.images && report.images.length > 0 && (
                      <RowWrap>
                        <Col
                          mx="auto"
                          width={[1, 1, 1, 10 / 12]}
                          pt={[20, 20, 20, 20]}
                          style={{
                            height: 520,
                          }}
                        >
                          <MediaGallery
                            readOnly={true}
                            medias={report.images.map((image) => {
                              return {
                                url: image,
                                type: "IMAGE",
                              };
                            })}
                          />
                        </Col>
                      </RowWrap>
                    )}

                    <RowWrap>
                      <Col mx="auto" width={[1, 1, 1, 10 / 12]} pt={[20, 20, 20, 20]}>
                        <div
                          css={{
                            backgroundColor: palette.white,
                            borderRadius: 4,
                            border: `solid 1px ${palette.lightestGray}`,
                            padding: 20,
                          }}
                        >
                          <EditorWithToolbars
                            editorState={EditorState.createWithContent(convertFromRaw(report.details))}
                            readOnly={true}
                            onChange={noop}
                          />
                        </div>
                      </Col>
                    </RowWrap>
                  </Grid>
                </div>
              </div>
            </DocumentTitle>
          );
        }}
      </Query>
    </DocumentTitle>
  );
}

const CheckIcon = (props: React.SVGProps<any>) => (
  <svg viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
