import React from "react";

interface IStripeLinkProps {
  stripeCustomerId: string;
}

export default function StripeLnk({ stripeCustomerId }: IStripeLinkProps) {
  const url = createStripeLink(stripeCustomerId);

  return (
    <a href={url} target="_blank" rel="noreferrer noopener">
      Link to Stripe
    </a>
  );
}

function createStripeLink(stripeCustomerId: string) {
  if (process.env.GOVLAUNCH_ENV === "production") {
    return `https://dashboard.stripe.com/customers/${stripeCustomerId}`;
  }

  return `https://dashboard.stripe.com/test/customers/${stripeCustomerId}`;
}
