import React, { FunctionComponent, ReactElement } from "react";

import { useLocation } from "react-router";
import queryString from "query-string";
import TableSearch from "../../../components/table/TableSearch";
import ManageExternalLinks from "./ManageExternalLinks";

export const ExternalLinksWithSearch: FunctionComponent = (): ReactElement => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div
        css={{
          marginBottom: 20,
        }}
      >
        <TableSearch historyOrigin="/externalLinks" query={query} inputPlaceholder="Search for external links..." />
      </div>
      <ManageExternalLinks search={query.search as string} />
    </>
  );
};
