import React, { SVGProps } from "react";
import Popover from "@govlaunch/popover";
import { useToggle } from "react-use";
import * as palette from "@govlaunch/palette";
import { Chevron } from "@govlaunch/core";

export type IValue = "public" | "private" | "community";

interface IPrivacySelectProps {
  value: IValue;
  onChange: (value: IValue) => void;
  disabled?: boolean;
}

interface IMenuProps {
  value: IValue;
  onChange: (value: IValue) => void;
}

function Menu({ value, onChange }: IMenuProps) {
  return (
    <div
      css={{
        background: palette.white,
        width: 300,
        borderRadius: 5,
      }}
    >
      <ul
        css={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          "& > li": {
            position: "relative",
          },

          "& > li a": {
            display: "flex",
            alignItems: "center",
            padding: "12px",
            paddingRight: 36,
            cursor: "pointer",
          },

          "& > li a:hover": {
            background: palette.lightestGray,
          },
        }}
      >
        <li>
          <a onClick={() => onChange("public")}>
            {value === "public" && (
              <CheckIcon
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: 16,
                }}
              />
            )}

            <div
              css={{
                marginRight: 12,
              }}
            >
              <WorldIcon />
            </div>

            <div>
              <div
                css={{
                  color: palette.text,
                  fontWeight: 600,
                  fontSize: 16,
                  marginBottom: 4,
                }}
              >
                Public
              </div>

              <div
                css={{
                  color: palette.mediumGray,
                  fontSize: 14,
                }}
              >
                Anybody can view this Project
              </div>
            </div>
          </a>
        </li>
        <li>
          {value === "community" && (
            <CheckIcon
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: 16,
              }}
            />
          )}

          <a onClick={() => onChange("community")}>
            <div
              css={{
                marginRight: 12,
              }}
            >
              <CommunityIcon />
            </div>

            <div>
              <div
                css={{
                  color: palette.text,
                  fontWeight: 600,
                  fontSize: 16,
                  marginBottom: 4,
                }}
              >
                Community
              </div>

              <div
                css={{
                  color: palette.mediumGray,
                  fontSize: 14,
                }}
              >
                Only the Govlaunch community can view this Project
              </div>
            </div>
          </a>
        </li>
        <li>
          {value === "private" && (
            <CheckIcon
              style={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                right: 16,
              }}
            />
          )}

          <a onClick={() => onChange("private")}>
            <div
              css={{
                marginRight: 12,
              }}
            >
              <LockIcon />
            </div>

            <div>
              <div
                css={{
                  color: palette.text,
                  fontWeight: 600,
                  fontSize: 16,
                  marginBottom: 4,
                }}
              >
                Private
              </div>

              <div
                css={{
                  color: palette.mediumGray,
                  fontSize: 14,
                }}
              >
                Only you and your Government can view this Project
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default function PrivacySelect({ value, onChange, disabled }: IPrivacySelectProps) {
  const [isOpen, toggle] = useToggle(false);

  return (
    <Popover
      backgroundColor={palette.white}
      boxStyle={{
        zIndex: 1061,
        boxShadow: "0 10px 40px 0 rgba(34, 35, 40, 0.2)",
      }}
      arrowColor={palette.white}
      isOpen={isOpen}
      onClickOutside={toggle}
      render={() => (
        <Menu
          value={value}
          onChange={selectedValue => {
            onChange(selectedValue);

            setTimeout(toggle, 200);
          }}
        />
      )}
    >
      {({ innerRef }: any) => (
        <button
          disabled={disabled}
          ref={innerRef}
          onClick={toggle}
          type="button"
          css={{
            background: "#fff",
            border: `1px solid ${palette.lightestGray}`,
            borderRadius: 4,
            height: 30,
            padding: "0 12px",
            outline: 0,
            display: "flex",
            alignItems: "center",
            ":disabled": {
              opacity: 0.6,
            },
            ":hover": {
              background: palette.washGray,
            },
          }}
        >
          {value === "public" && <WorldIcon />}
          {value === "community" && <CommunityIcon />}
          {value === "private" && <LockIcon />}

          <span
            css={{
              marginLeft: 4,
              color: palette.text,
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            {`${value.charAt(0).toUpperCase()}${value.slice(1)}`}
          </span>
          <Chevron
            direction="bottom"
            css={{
              marginLeft: 4,
            }}
          />
        </button>
      )}
    </Popover>
  );
}

const WorldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 511.999 511.999" width={16} height={16} {...props}>
    <g fill="#03aa6f">
      <path d="M441.374 79.519l-26.485 17.656-44.141-8.828-26.485-8.828-35.313 8.828-26.485-8.828 17.656-35.313h35.313l34.607-17.303a252.853 252.853 0 0171.333 52.616zM238.325 44.206l-8.828 26.485-26.485 8.828-26.485 44.141-44.141 26.485-61.797 8.828v26.485l17.656 17.656v35.313L61.762 220.77l-26.485-17.656-15.361-46.171A255.855 255.855 0 01160.284 18.604l25.072 16.774 52.969 8.828zM255.982 291.395l-8.828 44.141-35.313 35.313v26.485l-35.313 26.484v44.141l-26.484-8.828-17.657-44.141v-88.282l-44.141-8.828-17.656-35.313v-26.485l17.656-17.656 26.485-26.485 17.656 35.313h61.797l26.485 44.141zM479.335 130.987c48.193 85.978 42.702 191.996-14.125 272.526l-23.836-23.836v-35.313l-17.656-35.313-17.656-35.313v-35.313l-26.485-17.656-35.313 8.828-61.797-26.485-8.828-61.797 26.485-26.485h52.969l17.656 26.485 52.969 8.828 52.969-17.656 2.648-1.5z" />
    </g>
    <path
      d="M308.951 88.347l35.313-8.828 26.485 8.828 44.141 8.828 26.485-17.656a252.12 252.12 0 0137.961 51.468l-2.648 1.501-52.969 17.656-52.969-8.828-17.656-26.485h-52.969l-26.485 26.485 8.828 61.797 61.797 26.485 35.313-8.828 26.485 17.656v35.313l17.656 35.313 17.656 35.313v35.313l23.836 23.836c-81.528 115.526-241.275 143.079-356.792 61.55-98.108-69.249-134.913-197.373-88.503-308.122l15.361 46.171 26.485 17.656 26.485 17.656-17.657 17.657v26.485l17.656 35.313 44.141 8.828v88.282l17.656 44.141 26.485 8.828v-44.141l35.313-26.485v-26.485l35.313-35.313 8.828-44.141h-35.313l-26.485-44.141h-61.797l-17.656-35.313-26.485 26.485v-35.313L70.59 185.457v-26.485l61.797-8.828 44.141-26.485 26.485-44.141 26.485-8.828 8.828-26.485-52.969-8.828-25.072-16.774a256.035 256.035 0 01209.758 8.299l-34.607 17.303h-35.313l-17.656 35.313 26.484 8.829z"
      fill="#86daf1"
    />
  </svg>
);

const CommunityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 80.13 80.13" width={16} height={16} {...props}>
    <path d="M48.355 17.922c3.705 2.323 6.303 6.254 6.776 10.817a11.69 11.69 0 004.966 1.112c6.491 0 11.752-5.261 11.752-11.751 0-6.491-5.261-11.752-11.752-11.752-6.429.002-11.644 5.169-11.742 11.574zm-7.699 24.062c6.491 0 11.752-5.262 11.752-11.752s-5.262-11.751-11.752-11.751c-6.49 0-11.754 5.262-11.754 11.752s5.264 11.751 11.754 11.751zm4.985.801h-9.972c-8.297 0-15.047 6.751-15.047 15.048v12.195l.031.191.84.263c7.918 2.474 14.797 3.299 20.459 3.299 11.059 0 17.469-3.153 17.864-3.354l.785-.397h.084V57.833c.003-8.297-6.747-15.048-15.044-15.048zm19.443-12.132h-9.895a14.483 14.483 0 01-4.47 10.088c7.375 2.193 12.771 9.032 12.771 17.11v3.758c9.77-.358 15.4-3.127 15.771-3.313l.785-.398h.084V45.699c0-8.296-6.75-15.046-15.046-15.046zm-45.049-.8c2.299 0 4.438-.671 6.25-1.814a14.544 14.544 0 015.467-9.276c.012-.22.033-.438.033-.66 0-6.491-5.262-11.752-11.75-11.752-6.492 0-11.752 5.261-11.752 11.752 0 6.488 5.26 11.75 11.752 11.75zm10.554 10.888a14.492 14.492 0 01-4.467-10.032c-.367-.027-.73-.056-1.104-.056h-9.971C6.75 30.653 0 37.403 0 45.699v12.197l.031.188.84.265c6.352 1.983 12.021 2.897 16.945 3.185v-3.683c.002-8.078 5.396-14.915 12.773-17.11z" />
  </svg>
);

const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 512 512" width={16} height={16} {...props}>
    <path
      d="M437.333 192h-32v-42.667C405.333 66.99 338.344 0 256 0S106.667 66.99 106.667 149.333V192h-32A10.66 10.66 0 0064 202.667v266.667C64 492.865 83.135 512 106.667 512h298.667C428.865 512 448 492.865 448 469.333V202.667A10.66 10.66 0 00437.333 192zm-96 0H170.667v-42.667C170.667 102.281 208.948 64 256 64s85.333 38.281 85.333 85.333V192z"
      fill="#303c42"
    />
    <path
      d="M128 149.333c0-70.583 57.417-128 128-128s128 57.417 128 128V192h-21.333v-42.667c0-58.813-47.854-106.667-106.667-106.667S149.333 90.521 149.333 149.333V192H128v-42.667z"
      fill="#f2f2f2"
    />
    <path
      d="M426.667 469.333c0 11.76-9.573 21.333-21.333 21.333H106.667c-11.76 0-21.333-9.573-21.333-21.333v-256h341.333v256z"
      fill="#ffca28"
    />
    <path
      d="M128 469.333v-256H85.333v256c0 11.76 9.573 21.333 21.333 21.333H144c-8.82.001-16-9.572-16-21.333z"
      opacity={0.2}
      fill="#fff"
    />
    <path
      d="M384 213.333v256c0 11.76-7.18 21.333-16 21.333h37.333c11.76 0 21.333-9.573 21.333-21.333v-256H384z"
      opacity={0.1}
    />
    <path
      d="M230.792 354.313l-6.729 60.51a10.67 10.67 0 0010.604 11.844h42.667a10.67 10.67 0 0010.604-11.844l-6.729-60.51c10.927-7.948 17.458-20.521 17.458-34.313 0-23.531-19.135-42.667-42.667-42.667S213.333 296.469 213.333 320c0 13.792 6.532 26.365 17.459 34.313z"
      fill="#303c42"
    />
    <path
      d="M256 298.667c11.76 0 21.333 9.573 21.333 21.333 0 8.177-4.646 15.5-12.125 19.125a10.673 10.673 0 00-5.958 10.781l6.167 55.427h-18.833l6.167-55.427c.5-4.49-1.885-8.802-5.958-10.781-7.479-3.625-12.125-10.948-12.125-19.125-.001-11.76 9.572-21.333 21.332-21.333z"
      fill="#5c6671"
    />
    <path
      d="M247.716 340.001c-1.434-2.866-2.383-5.987-2.383-9.335 0-11.76 9.573-21.333 21.333-21.333 3.12 0 6.034.749 8.708 1.958-3.349-7.406-10.734-12.625-19.375-12.625-11.76 0-21.333 9.573-21.333 21.333 0 8.177 4.646 15.5 12.125 19.125.402.196.558.639.925.877zM261.927 373.965l-2.48-22.288c-.701-.608-1.125-1.466-1.988-1.885-2.096-1.016-3.9-2.376-5.512-3.911.385 1.323.962 2.599.803 4.026l-6.167 55.427h11.853l3.491-31.369z"
      opacity={0.1}
    />
    <linearGradient
      id="prefix__a"
      gradientUnits="userSpaceOnUse"
      x1={-43.622}
      y1={637.269}
      x2={-23.837}
      y2={628.043}
      gradientTransform="matrix(21.3333 0 0 -21.3333 996.333 13791.667)"
    >
      <stop offset={0} stopColor="#fff" stopOpacity={0.2} />
      <stop offset={1} stopColor="#fff" stopOpacity={0} />
    </linearGradient>
    <path
      d="M437.333 192h-32v-42.667C405.333 66.99 338.344 0 256 0S106.667 66.99 106.667 149.333V192h-32A10.66 10.66 0 0064 202.667v266.667C64 492.865 83.135 512 106.667 512h298.667C428.865 512 448 492.865 448 469.333V202.667A10.66 10.66 0 00437.333 192zm-96 0H170.667v-42.667C170.667 102.281 208.948 64 256 64s85.333 38.281 85.333 85.333V192z"
      fill="url(#prefix__a)"
    />
  </svg>
);

const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 448.8 448.8" width={16} height={16} fill={palette.darkGray} {...props}>
    <path d="M142.8 323.85L35.7 216.75 0 252.45l142.8 142.8 306-306-35.7-35.7z" />
  </svg>
);
