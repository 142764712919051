import gql from "graphql-tag";

export default gql`
  mutation EditExternalLink($id: ObjectId!, $data: ExternalLinkInput!) {
    editExternalLink(id: $id, data: $data) {
      _id
      title
      url
      source
    }
  }
`;
