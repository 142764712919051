import React from "react";

export default function PlusIcon(props: React.SVGProps<any>) {
  return (
    <svg viewBox="0 0 15 15" width={15} height={15} {...props}>
      <path
        d="M13.929 6.429H8.57V1.07a1.072 1.072 0 0 0-2.142 0V6.43H1.07a1.072 1.072 0 0 0 0 2.142H6.43v5.358a1.072 1.072 0 0 0 2.142 0V8.57h5.358a1.072 1.072 0 0 0 0-2.142z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}
