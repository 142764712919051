import { notification } from "antd";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Mutation } from "react-apollo";
import { useNavigate } from "react-router";
import EditProduct from "../../../mutations/EditProduct";
import SetProductOnGovernmentsStack from "../../../mutations/SetProductOnGovernmentsStack";
import {
  IEditProductMutation,
  IEditProductMutationVariables,
} from "../../../mutations/__generated__/EditProduct.generated";
import {
  ISetProductOnGovernmentsStackMutation,
  ISetProductOnGovernmentsStackMutationVariables,
} from "../../../mutations/__generated__/SetProductOnGovernmentsStack.generated";
import GetProductsQuery from "../../../queries/GetProductsQuery";
import VendorQuery from "../../../queries/VendorQuery";
import { IProductQuery } from "../../../queries/__generated__/ProductQuery.generated";
import ProductForm from "../ProductForm";
import { convertProductToInitialValues, convertValuesToVariables } from "../utils";

interface IProductDetailsTabProps {
  product: IProductQuery["product"];
}

const ProductDetailsTab = ({ product }: IProductDetailsTabProps) => {
  const navigate = useNavigate();

  if (!product) {
    return null;
  }

  return (
    <Mutation<IEditProductMutation, IEditProductMutationVariables> mutation={EditProduct}>
      {(editProduct) => (
        <Mutation<ISetProductOnGovernmentsStackMutation, ISetProductOnGovernmentsStackMutationVariables>
          refetchQueries={[
            {
              query: GetProductsQuery,
            },
            {
              query: VendorQuery,
              variables: {
                slug: product.company ? product.company.slug : "",
              },
            },
          ]}
          mutation={SetProductOnGovernmentsStack}
        >
          {(setProductOnGovernments) => (
            <ProductForm
              defaultVendorSearch={product && product.company ? product.company.name : ""}
              initialValues={convertProductToInitialValues(product)}
              integrations={product.integrations}
              productSlug={product.slug}
              tagsLimit={product.company ? product.company.plan.limits.productTags : 1}
              onSubmit={async (values) => {
                const { company } = values;
                let result = null;

                try {
                  result = await editProduct({
                    variables: {
                      id: product._id,
                      product: convertValuesToVariables(values),
                      company,
                    },
                  });
                } catch (e) {
                  if (/name already taken/i.test(e.message)) {
                    return {
                      [FORM_ERROR]: "Name already taken",
                    };
                  }

                  return {
                    [FORM_ERROR]: e.message,
                  };
                }

                await setProductOnGovernments({
                  variables: {
                    addingGovernments: values.addingGovernments.map(({ _id }: { _id: string }) => _id),
                    removingGovernments: values.removingGovernments.map(({ _id }: { _id: string }) => _id),
                    productId: product._id,
                  },
                });

                if (result && result.data && result.data.editProduct && result.data.editProduct.slug) {
                  notification.open({
                    type: "success",
                    message: "Changes saved!",
                    description: `All your changes on ${product.name} were saved`,
                  });

                  navigate(`/products/${result.data.editProduct.slug}`);
                }
              }}
              submitButtonText="Save Changes"
            />
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default ProductDetailsTab;
