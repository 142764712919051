import { Button } from "antd";
import React, { useRef, useState } from "react";
import DocumentTitle from "react-document-title";
import CampaignContentPreview from "./CampaignContentPreview";
import * as palette from "@govlaunch/palette";
import EmailRenderWrapper from "./preview/EmailRenderWrapper";
import { useParams } from "react-router";

export default function CampaignContentPreviewPage() {
  const params = useParams();
  const copyRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLTextAreaElement>(null);
  const codeRef = useRef<HTMLDivElement>(null);
  const [isCopied, setIsCopied] = useState(false);
  const campaignId = params.id;

  return (
    <DocumentTitle title="Preview Campaign">
      <>
        <textarea
          ref={textRef}
          style={{
            opacity: 0,
            position: "absolute",
            top: "-200px",
          }}
        />
        <div
          ref={codeRef}
          style={{
            opacity: 0,
            position: "absolute",
            top: "-20000px",
          }}
        >
          <EmailRenderWrapper preview={false} campaignId={campaignId || ""} />
        </div>
        <div
          css={{
            borderBottom: "1px solid rgb(232, 232, 232)",
            padding: 20,
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3
            css={{
              fontWeight: 700,
              fontSize: 24,
              margin: 0,
            }}
          >
            Preview Campaign 🔎
          </h3>
          <div
            css={{
              marginLeft: "auto",
            }}
          >
            <div
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {isCopied && (
                <div
                  css={{
                    marginRight: "8px",
                    color: palette.greenSuccess,
                    fontWeight: 600,
                  }}
                >
                  Copied!
                </div>
              )}
              <Button
                onClick={() => {
                  if (codeRef && codeRef.current && textRef && textRef.current) {
                    const code = codeRef.current.innerHTML;
                    textRef.current.innerHTML = code;
                    const textCode = textRef.current.value;
                    navigator.clipboard.writeText(textCode);
                    setIsCopied(true);
                  }
                }}
              >
                Copy HTML
              </Button>
            </div>
          </div>
        </div>

        <div
          css={{
            paddingTop: "20px",
          }}
          ref={copyRef}
        >
          <CampaignContentPreview campaignId={campaignId || ""} />
        </div>
      </>
    </DocumentTitle>
  );
}
