import * as Yup from "yup";
import * as palette from "@govlaunch/palette";
import { Field, Form as FinalForm } from "react-final-form";
import { Spacing, SpacingBetween } from "@govlaunch/spacers";
import glamorous, { Div, Form, Img, Input, Span } from "glamorous";
import { Button } from "antd";
import { DisruptorsEditor } from "@govlaunch/editor";
import { FieldArray } from "react-final-form-arrays";
import Filestack from "../../Filestack";
import { Hover } from "react-powerplug";
import { MultiGovernmentSelect } from "@govlaunch/select";
import React from "react";
import { SearchAlgoliaIndex } from "@govlaunch/algolia";
import TextareaAutosize from "react-textarea-autosize";
import arrayMutators from "final-form-arrays";
import validate from "../../validate";
import CheckboxGroup from "antd/lib/checkbox/Group";
import { useSelfie } from "../auth/Selfie";
import { IGovernment, Maybe, Scalars } from "../../../types/types";

type IReportFormOutput = {
  title: Maybe<Scalars["String"]>;
  description: Maybe<Scalars["String"]>;
  details: Maybe<Scalars["JSON"]>;
  images: Maybe<Array<Scalars["String"]>>;
  governments: Maybe<Array<{ __typename: "Government" } & Pick<IGovernment, "_id" | "name" | "logo" | "isInnovator">>>;
  locations: Maybe<Array<Scalars["String"]>>;
};

interface IReportFormProps {
  initialValues: IReportFormOutput;
  submitButtonText: string;
  onSubmit: (values: IReportFormOutput) => any;
}

interface IAlgoliaReportsSearch {
  results: IGovernment[];
  searchValue: string;
  onChange: (value: string) => any;
}

interface IFileUploaded {
  filename: string;
  mimetype: string;
  size: string | number;
  url: string;
}

export default function ReportForm({ initialValues, submitButtonText, onSubmit }: IReportFormProps) {
  const user = useSelfie();
  const validationSchema = Yup.object({
    title: Yup.string().required("Can't be blank"),
    description: Yup.string().required("Can't be blank"),
    locations: Yup.array().required("Select at least one location"),
  });

  return (
    <FinalForm<IReportFormOutput>
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      validate={validate(validationSchema)}
    >
      {({ handleSubmit, values, submitting, submitError }) => (
        <Form maxWidth={1024} onSubmit={handleSubmit}>
          <SpacingBetween top={20}>
            <Field name="title">
              {({ input, meta }) => (
                <Div>
                  <Spacing
                    bottom={5}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Span fontWeight={700} fontSize={18}>
                      Title
                    </Span>

                    <FieldError meta={meta} />
                  </Spacing>

                  <Input
                    {...input}
                    type="text"
                    placeholder="Durham sidesteps rasonware attacks"
                    fontSize={14}
                    width="100%"
                    padding={10}
                    borderRadius={4}
                    outline={0}
                    css={{
                      border: `solid 1px ${palette.lightestGray}`,
                      "&:focus": {
                        border: `solid 1px ${palette.primary}`,
                      },
                    }}
                  />
                </Div>
              )}
            </Field>

            <Field name="description">
              {({ input, meta }) => (
                <Div>
                  <Spacing
                    bottom={5}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Span fontWeight={700} fontSize={18}>
                      Description
                    </Span>

                    <FieldError meta={meta} />
                  </Spacing>

                  <Textarea
                    {...input}
                    placeholder="The city of Durham withstood at least two ransonware attacks in 2016 thanks to the city's 90-day file backups and robust firewalls"
                    minRows={2}
                    maxRows={3}
                    css={{
                      width: "100%",
                      fontSize: 14,
                      padding: 10,
                      outline: 0,
                      borderRadius: 4,
                      margin: 0,
                      border: `solid 1px ${palette.lightestGray}`,
                      "&:focus": {
                        border: `solid 1px ${palette.primary}`,
                      },
                    }}
                  />
                </Div>
              )}
            </Field>

            <Field name="governments">
              {({ input }) => (
                <Div>
                  <Spacing bottom={5}>
                    <Span fontWeight={700} fontSize={18}>
                      Governments
                    </Span>
                  </Spacing>

                  <SearchAlgoliaIndex index="governments" user={user}>
                    {({
                      results: governments,
                      searchValue: inputValue,
                      onChange: onInputValueChange,
                    }: IAlgoliaReportsSearch) => (
                      <MultiGovernmentSelect
                        governments={input.value}
                        selectableGovernments={governments}
                        inputValue={inputValue}
                        onInputValueChange={onInputValueChange}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        onChange={input.onChange}
                      />
                    )}
                  </SearchAlgoliaIndex>
                </Div>
              )}
            </Field>

            <FieldArray name="images">
              {({ fields }) => (
                <Div>
                  <Spacing bottom={5}>
                    <Span fontWeight={700} fontSize={18}>
                      Images
                    </Span>
                  </Spacing>

                  <Div display="flex" alignItems="center" justifyContent="space-between">
                    {fields.map((name, index) => {
                      return (
                        <Filestack
                          key={name}
                          onSuccess={({ filesUploaded }: { filesUploaded: IFileUploaded[] }) => {
                            fields.remove(index);
                            fields.insert(index, filesUploaded[0].url);
                          }}
                          customRender={({ onPick }: any) => (
                            <Hover key={name}>
                              {({ bind, hovered }) =>
                                values.images && values.images[index] ? (
                                  <Div
                                    {...bind}
                                    width={140}
                                    height={140}
                                    borderRadius={12}
                                    cursor="pointer"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    position="relative"
                                    overflow="hidden"
                                  >
                                    <Img
                                      src={values.images[index]}
                                      width="100%"
                                      height="100%"
                                      borderRadius={12}
                                      objectFit="cover"
                                    />

                                    {hovered && (
                                      <Div
                                        position="absolute"
                                        top={0}
                                        left={0}
                                        width="100%"
                                        height="100%"
                                        backgroundColor="rgba(0, 0, 0, 0.3)"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <Button
                                          htmlType="button"
                                          size="small"
                                          danger={true}
                                          onClick={() => {
                                            fields.remove(index);
                                            fields.insert(index, null);
                                          }}
                                        >
                                          Remove
                                        </Button>
                                      </Div>
                                    )}
                                  </Div>
                                ) : (
                                  <Div
                                    {...bind}
                                    onClick={onPick}
                                    width={140}
                                    height={140}
                                    borderRadius={12}
                                    border={`dashed 1px ${palette.lightestGray}`}
                                    cursor="pointer"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <BrokenImage width={32} height={32} fill={hovered ? "#000" : "#999"} />
                                  </Div>
                                )
                              }
                            </Hover>
                          )}
                        />
                      );
                    })}
                  </Div>
                </Div>
              )}
            </FieldArray>

            <Field name="details">
              {({ input }) => (
                <Div>
                  <Spacing bottom={5}>
                    <Span fontWeight={700} fontSize={18}>
                      Details
                    </Span>
                  </Spacing>

                  <Div fontSize={14}>
                    <DisruptorsEditor
                      editorState={input.value}
                      onChange={input.onChange}
                      readOnly={false}
                      minContentHeight={300}
                      placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint maiores itaque, error voluptatum eum saepe nemo odio earum ad explicabo!"
                    />
                  </Div>
                </Div>
              )}
            </Field>

            <Field name="locations">
              {({ input, meta }) => (
                <Div>
                  <Spacing bottom={5}>
                    <Span fontWeight={700} fontSize={18}>
                      Publish on
                    </Span>

                    <FieldError meta={meta} />
                  </Spacing>

                  <Div>
                    <CheckboxGroup
                      {...input}
                      options={[
                        {
                          label: "Innovators",
                          value: "innovators",
                        },
                        {
                          label: "Govlaunch",
                          value: "govlaunch",
                        },
                      ]}
                    />
                  </Div>
                </Div>
              )}
            </Field>

            <Div>
              <Button htmlType="submit" type="primary" size="large" block={true} disabled={submitting}>
                {submitting ? "Please wait..." : submitButtonText || "Submit"}
              </Button>
            </Div>

            {submitError && (
              <Spacing top={10}>
                <Div backgroundColor={palette.lightRed} padding={10} color={palette.red} borderRadius={4}>
                  {submitError}
                </Div>
              </Spacing>
            )}
          </SpacingBetween>
        </Form>
      )}
    </FinalForm>
  );
}

interface IFieldErrorProps {
  meta: Partial<{
    touched: boolean;
    error: any;
    submitError: any;
  }>;
}

function FieldError({ meta }: IFieldErrorProps) {
  if (meta.touched && (meta.error || meta.submitError)) {
    return (
      <Div marginLeft="auto" color={palette.red} textTransform="uppercase" fontSize={12}>
        {meta.error || meta.submitError}
      </Div>
    );
  } else {
    return null;
  }
}

const Textarea = glamorous(TextareaAutosize)({
  resize: "none",
});

interface IBrokenImageProps extends React.SVGProps<any> {
  fill: string | undefined;
}

const BrokenImage = ({ fill, ...props }: IBrokenImageProps) => (
  <svg viewBox="0 0 50 50" width={50} height={50} {...props}>
    <g fill={fill} fillRule="nonzero">
      <path d="M16.5 7a5.506 5.506 0 0 0-5.5 5.5c0 3.033 2.467 5.5 5.5 5.5s5.5-2.467 5.5-5.5S19.533 7 16.5 7zm0 8.8c-1.82 0-3.3-1.48-3.3-3.3 0-1.82 1.48-3.3 3.3-3.3 1.82 0 3.3 1.48 3.3 3.3 0 1.82-1.48 3.3-3.3 3.3z" />
      <path d="M50 25.378V12.5c0-.302-.12-.59-.333-.804L38.303.333A1.135 1.135 0 0 0 37.5 0H4.545A4.55 4.55 0 0 0 0 4.545v10.228c0 .176.041.35.12.508l2.07 4.143-2.142 7.144a1.154 1.154 0 0 0-.048.326v18.56A4.55 4.55 0 0 0 4.545 50h6.591c.237 0 .467-.074.66-.212l4.253-3.037 7.367 3.157a1.132 1.132 0 0 0 .67.07l10.767-2.153 1.844 1.842c.213.213.501.333.803.333h7.955A4.55 4.55 0 0 0 50 45.455v-3.41c0-.176-.041-.35-.12-.508l-4.267-8.533 4.225-7.04c.106-.177.162-.38.162-.586zM38.636 3.88l7.484 7.484H40.91a2.276 2.276 0 0 1-2.273-2.273V3.88zm9.091 38.434v3.14a2.276 2.276 0 0 1-2.273 2.273H37.97l-.64-.64 1.666-.555a1.136 1.136 0 1 0-.72-2.155l-3.339 1.113-10.95 2.19-6.101-2.614a1.129 1.129 0 0 0-.037-1.551 1.136 1.136 0 0 0-1.607 0l-1.071 1.07-4.399 3.142H4.545a2.276 2.276 0 0 1-2.272-2.273V40.91h44.752l.702 1.405zm-1.839-3.678H2.273v-4.075L12.5 24.334l7.151 7.151a1.136 1.136 0 1 0 1.607-1.607l-2.038-2.037L30.682 16.38l13.91 13.91-1.248 2.08c-.007.01-.005.025-.011.036-.009.015-.024.024-.032.04l-1.136 2.273a1.136 1.136 0 1 0 2.033 1.016l.12-.24 1.57 3.141zm1.839-13.573l-1.93 3.218-14.312-14.312a1.136 1.136 0 0 0-1.607 0L17.614 26.234l-4.31-4.31a1.136 1.136 0 0 0-1.607 0l-9.424 9.424V27.06l2.196-7.322L6.7 15.28a1.136 1.136 0 1 0-2.034-1.017L3.41 16.777l-1.136-2.273V4.545a2.276 2.276 0 0 1 2.272-2.272h31.818V9.09a4.55 4.55 0 0 0 4.546 4.545h6.818v11.427z" />
    </g>
  </svg>
);
