import gql from "graphql-tag";

export default gql`
  query AutoCompleteStories($searchQuery: String!, $limit: Int = 10) {
    autoCompleteStories(searchQuery: $searchQuery, limit: $limit) {
      _id
      slug
      title
      introduction
      medias {
        type
        url
        alt
      }
      createdAt
      updatedAt
      tags {
        _id
        name
        slug
      }
    }
  }
`;
