import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { Input } from "@govlaunch/core";
import { IFormFieldProps } from "./FormFieldProps";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { Tooltip } from "antd";
import InformationIcon from "../../../components/icons/InformationIcon";

export default function HorizontalTextField({
  fieldName,
  label,
  placeholder,
  headerColor,
  disabled,
  inputWidth,
  tooltip,
  labelWidth,
  uppercase,
  ...props
}: IFormFieldProps) {
  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <label
                css={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: headerColor || palette.mediumGray,
                  width: labelWidth || undefined,
                }}
              >
                {label}
              </label>
              {tooltip && (
                <Tooltip title={tooltip}>
                  <div>
                    <InformationIcon
                      height="16px"
                      width="16px"
                      fillColor={palette.mediumGray}
                      css={{
                        marginLeft: 4,
                        marginTop: 6,
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </div>

            <Input
              css={{
                height: 42,
                borderRadius: 5,
                border: `1px solid ${palette.lightestGray}`,
                fontSize: 14,
                padding: "5px 10px",
                width: inputWidth ? inputWidth : "100%",
                outline: 0,
                "::placeholder": {
                  color: palette.sealBlue,
                },
                ":focus": {
                  borderColor: palette.primary,
                },
                ":disabled": {
                  backgroundColor: "white",
                  color: "rgba(0, 0, 0, 0.25)",
                },
                textTransform: uppercase ? "uppercase" : undefined,
                "::-webkit-input-placeholder": {
                  /* WebKit browsers */ textTransform: "none",
                },
                ":-moz-placeholder": {
                  /* Mozilla Firefox 4 to 18 */ textTransform: "none",
                },
                "::-moz-placeholder": {
                  /* Mozilla Firefox 19+ */ textTransform: "none",
                },
                // @ts-ignore
                ":-ms-input-placeholder": {
                  /* Internet Explorer 10+ */ textTransform: "none",
                },
                // @ts-ignore
                "::placeholder": {
                  /* Recent browsers */ textTransform: "none",
                },
              }}
              placeholder={placeholder}
              {...input}
              {...props}
              disabled={disabled}
            />
          </div>

          <FieldValidationError
            meta={meta}
            css={{
              marginTop: 8,
            }}
          />
        </div>
      )}
    </Field>
  );
}
