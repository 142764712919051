import React from "react";
import { IPodcastBlockData, IBlockLayout, TBlockLayoutData } from "../blockProps";
import BlockLayoutContainer from "../../blockslayout/BlockLayoutContainer";
import BlockLayoutHeader from "../../blockslayout/BlockLayoutHeader";
import AddPodcastModalButton from "./AddPodcasModalButton";
import { extractSrc } from "./extractSrc";
import BlockContentTitle from "../BlockContentTitle";
import BlockLayoutHeaderSettings from "../../blockslayout/BlockLayoutHeaderSettings";

interface IPodcastBlockProps {
  block: IBlockLayout;
  saveBlockData: (block: IBlockLayout, data: TBlockLayoutData) => void;
  onRemoveBlock: () => void;
}

export default function PodcastBlock({ block, saveBlockData, onRemoveBlock }: IPodcastBlockProps) {
  const blockContent = (block.blockData as IPodcastBlockData) || {
    podcastSrc: "",
  };
  return (
    <BlockLayoutContainer block={block}>
      <BlockLayoutHeader block={block} onRemoveBlock={onRemoveBlock} showDragnDrop={true}>
        <BlockLayoutHeaderSettings block={block} saveBlockData={saveBlockData} />
      </BlockLayoutHeader>
      <div
        css={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
      >
        {block.blockData["titleVisible"] && <BlockContentTitle block={block} />}
        {blockContent.podcastSrc ? (
          <PodcastEmbed podcastSrc={blockContent.podcastSrc} />
        ) : (
          <AddPodcastModalButton block={block} saveBlockData={saveBlockData} />
        )}
      </div>
    </BlockLayoutContainer>
  );
}

interface IPodcastEmbedProps {
  podcastSrc: string;
}

function PodcastEmbed({ podcastSrc }: IPodcastEmbedProps) {
  return (
    <div
      css={{
        width: "100%",
        height: "100%",
      }}
    >
      <iframe
        src={extractSrc(podcastSrc)}
        frameBorder="0"
        css={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
}
