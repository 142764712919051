import gql from "graphql-tag";

export default gql`
  query ListAllTags($search: String, $archivedOnly: Boolean = false, $filterByTagType: TagType) {
    listAllTags(search: $search, archivedOnly: $archivedOnly, filterByTagType: $filterByTagType) {
      _id
      name
      slug
      types
      createdAt
      deletedAt
      deletedBy {
        ... on PendingUser {
          _id
          slug
          fullName
        }
        ... on VendorUser {
          _id
          slug
          fullName
        }
        ... on GovernmentUser {
          _id
          slug
          fullName
        }
      }
      relatedTags {
        _id
        name
      }
      aggregations {
        productsCount
        vendorsCount
        projectsCount
        storiesCount
        mentionsCount
        awardsCount
        usersCount
        groupsCount
      }
    }
  }
`;
