import gql from "graphql-tag";

export default gql`
  query GetNewsletterReceivers($search: String, $mode: GovlaunchEnvironment, $limit: Int = 20, $cursor: Cursor) {
    newsletterReceivers(search: $search, mode: $mode, limit: $limit, cursor: $cursor) {
      items {
        ... on PendingUser {
          _id
          fullName
          slug
          email
          newsletterFrequency
          newsletterLastSentDate
          createdAt
        }
        ... on VendorUser {
          _id
          fullName
          slug
          email
          newsletterFrequency
          newsletterLastSentDate
          createdAt
        }
        ... on GovernmentUser {
          _id
          fullName
          slug
          email
          newsletterFrequency
          newsletterLastSentDate
          createdAt
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor
      }
    }
  }
`;
