import gql from "graphql-tag";
import VendorFields from "../queries/fragments/VendorFields";

export default gql`
  mutation EditVendor($id: ObjectId!, $vendor: VendorInput!) {
    editVendor(id: $id, vendor: $vendor) {
      _id
      ...VendorFields
    }
  }

  ${VendorFields}
`;
