import React from "react";
import { Field } from "react-final-form";
import CertificationsInput from "../inputs/CertificationsInput";

export default function CertificationsField() {
  return (
    <Field name="certifications">
      {({ input }) => (
        <CertificationsInput
          {...input}
          maxItems={5}
          characterLimit={50}
          css={{
            background: "#fff",
          }}
        />
      )}
    </Field>
  );
}
