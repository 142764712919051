import React from "react";
import * as palette from "@govlaunch/palette";
import { ImageUploadModal } from "@govlaunch/web";
import { getDefaultBlockContentByType, IBlockLayout, IImageBlockData, TBlockLayoutData } from "./blockProps";
import BlockLayoutContainer from "../blockslayout/BlockLayoutContainer";
import BlockLayoutHeader from "../blockslayout/BlockLayoutHeader";
import ImageSelectButton from "../ImageSelectButton";
import { IBlockLayoutType } from "../../../../types/types";
import BlockLayoutHeaderSettings from "../blockslayout/BlockLayoutHeaderSettings";
import BlockContentTitle from "./BlockContentTitle";

interface IImageBlockProps {
  block: IBlockLayout;
  saveBlockData: (block: IBlockLayout, blokData: TBlockLayoutData) => void;
  onRemoveBlock: () => void;
}

export default function ImageBlock({ block, saveBlockData, onRemoveBlock }: IImageBlockProps) {
  const blockContent = (block.blockData as IImageBlockData) || getDefaultBlockContentByType(IBlockLayoutType.Image);

  return (
    <BlockLayoutContainer block={block}>
      <BlockLayoutHeader block={block} onRemoveBlock={onRemoveBlock} showDragnDrop={true}>
        <BlockLayoutHeaderSettings block={block} saveBlockData={saveBlockData} />
      </BlockLayoutHeader>
      {block.blockData["titleVisible"] && <BlockContentTitle block={block} />}
      <div
        css={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
      >
        <ImageUploadModal
          imageData={blockContent}
          onSave={(imageData) => {
            saveBlockData(block, {
              ...block.blockData,
              ...imageData,
            });
          }}
        >
          {({ setOpen }) => (
            <div
              css={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setOpen(true)}
            >
              {blockContent.url && (
                <img
                  css={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    border: `1px solid ${palette.lightestGray}`,
                    cursor: "pointer",
                  }}
                  src={blockContent.url}
                  alt={blockContent.alt}
                />
              )}
              {!blockContent.url && <ImageSelectButton />}
            </div>
          )}
        </ImageUploadModal>
      </div>
    </BlockLayoutContainer>
  );
}
