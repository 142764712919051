import gql from "graphql-tag";

export default gql`
  query OnboardingsCount {
    onboardingProcessStatusCounts {
      requiredCount
      additionalInformationRequiredCount
      pendingVerificationCount
      verifiedCount
    }
  }
`;
