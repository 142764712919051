import ProfilePicture from "@govlaunch/profile-picture";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Select, Tag, Table } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import React from "react";
import { Field } from "react-final-form";
import { Link } from "react-router-dom";
import { TVendor, TVendorMember } from "./types";

const { Column } = Table;
interface IEditableVendorMembersProps {
  vendor: TVendor;
}

export default function EditableVendorMembers({ vendor }: IEditableVendorMembersProps) {
  if (!vendor.members) {
    return null;
  }

  const memberIds = vendor.members.map(({ _id }) => _id);

  const changeRoles = (members: TVendorMember[], member: TVendorMember, newRole: "COMPANY_OWNER" | "COMPANY_USER") => {
    return members.map((candidate) => {
      if (candidate._id !== member._id && newRole === "COMPANY_OWNER") {
        return {
          ...candidate,
          role: "COMPANY_USER",
        };
      }

      if (candidate._id === member._id) {
        return {
          ...candidate,
          role: newRole,
        };
      }

      return candidate;
    });
  };

  return (
    <Field<TVendorMember[]> name="members">
      {({ input }) => {
        let paginationConfig: TablePaginationConfig | false = false;

        if (input.value.length > 10) {
          paginationConfig = {
            pageSize: 10,
          };
        }

        return (
          <Table
            dataSource={input.value}
            bordered={true}
            size="middle"
            rowKey="_id"
            pagination={paginationConfig}
            scroll={{
              x: true,
            }}
          >
            <Column<TVendorMember>
              title="Name"
              dataIndex="fullName"
              render={(_, member) => (
                <div
                  css={{
                    display: "inline-grid",
                    gridColumnGap: 12,
                    gridTemplateColumns: "repeat(3, max-content)",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture size={28} image={member.avatar} name={member.fullName} />

                  <Link to={`/users/${member.slug}`}>
                    <span>{member.fullName}</span>
                  </Link>

                  {!memberIds.includes(member._id) && <Tag color="magenta">New</Tag>}
                </div>
              )}
            />

            <Column<TVendorMember>
              title="Role"
              dataIndex="role"
              width="20%"
              render={(role, member) => {
                return (
                  <Select<"COMPANY_OWNER" | "COMPANY_USER">
                    value={role}
                    disabled={input.value.length <= 1}
                    onChange={(newRole) => {
                      const members = input.value as TVendorMember[];
                      input.onChange(changeRoles(members, member, newRole));
                    }}
                  >
                    <Select.Option value="COMPANY_OWNER">Owner</Select.Option>
                    <Select.Option value="COMPANY_USER">User</Select.Option>
                  </Select>
                );
              }}
            />

            <Column<TVendorMember>
              title="Remove"
              dataIndex="_id"
              width="20%"
              align="center"
              render={(_, member) => {
                return (
                  <Button
                    danger={true}
                    icon={<DeleteOutlined />}
                    shape="round"
                    onClick={() => {
                      const members = input.value as TVendorMember[];
                      const filteredMembers = members.filter((candidate) => candidate._id !== member._id);

                      // if it's the last remaining member, make sure that he will be set to `COMPANY_OWNER`.
                      if (filteredMembers.length === 1) {
                        input.onChange(
                          filteredMembers.map((member) => {
                            return {
                              ...member,
                              role: "COMPANY_OWNER",
                            };
                          }),
                        );

                        return;
                      }

                      // after removing users, ensure that an owner will be kept.
                      const hasOwner = filteredMembers.some((candidate) => candidate.role === "COMPANY_OWNER");

                      if (!hasOwner) {
                        input.onChange(
                          filteredMembers.map((member, index) => {
                            return {
                              ...member,
                              role: index === 0 ? "COMPANY_OWNER" : "COMPANY_USER",
                            };
                          }),
                        );

                        return;
                      }

                      input.onChange(filteredMembers);
                    }}
                  >
                    Remove
                  </Button>
                );
              }}
            />
          </Table>
        );
      }}
    </Field>
  );
}
