/* eslint-disable no-console */
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import WatchedMutationLink from "apollo-link-watched-mutation";
import introspectionQueryResultData from "../data/fragmentTypes.json";
import { IProject, ITag } from "../types/types";
import { dropToken, getToken, hasToken } from "./auth/token";

const cache = new InMemoryCache({
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  }),
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();

  if (!token) {
    return {};
  }

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const TOKEN_ERROR_REGEX = /(invalid signature|invalid token|jwt expired|bad authorization header format)/i;

export default new ApolloClient({
  connectToDevTools: process.env.NODE_ENV !== "production",
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, forward, operation }) => {
      if (graphQLErrors && graphQLErrors.length > 0) {
        const hasTokenError = graphQLErrors.some((error) => {
          return TOKEN_ERROR_REGEX.test(error.message);
        });

        if (hasTokenError) {
          dropToken();
          return forward(operation);
        }
      }

      if (networkError && networkError.message && TOKEN_ERROR_REGEX.test(networkError.message)) {
        dropToken();
        return forward(operation);
      }

      return forward(operation);
    }),
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        if (!hasToken()) {
          window.location.href = "/";
          return;
        }

        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(`[GQL] message: ${message}, location: ${locations}, path: ${path}`);
        });
      }

      if (networkError) {
        console.log(`[Network Error]: ${networkError}`);
      }
    }),
    new WatchedMutationLink(cache, {
      CreateProjectForVendor: {
        SearchProjects: ({ mutation, query }: any) => {
          return {
            ...query.result,
            searchProjects: {
              ...query.result.searchProjects,
              items: [mutation.result.data.createProjectForVendor].concat(query.result.searchProjects.items),
            },
          };
        },
      },

      CreateProjectForGovernment: {
        SearchProjects: ({ mutation, query }: any) => {
          return {
            ...query.result,
            searchProjects: {
              ...query.result.searchProjects,
              items: [mutation.result.data.createProjectForGovernment].concat(query.result.searchProjects.items),
            },
          };
        },
      },
      CreateProjectForGroup: {
        SearchProjects: ({ mutation, query }: any) => {
          return {
            ...query.result,
            searchProjects: {
              ...query.result.searchProjects,
              items: [mutation.result.data.createProjectForGroup].concat(query.result.searchProjects.items),
            },
          };
        },
      },
      ArchiveProject: {
        ProjectBySlug: ({ mutation, query }: any) => {
          if (query.result.projectBySlug && query.result.projectBySlug._id === mutation.variables.projectId) {
            return {
              ...query.result,
              projectBySlug: null,
            };
          } else if (query.result.projectBySlug) {
            return {
              ...query.result,
              projectBySlug: {
                ...query.result.projectBySlug,
                moreFromGovernment: query.result.projectBySlug.moreFromGovernment.filter((project: IProject) => {
                  return project._id !== mutation.variables.projectId;
                }),
                relatedProjects: query.result.projectBySlug.relatedProjects.filter((project: IProject) => {
                  return project._id !== mutation.variables.projectId;
                }),
              },
            };
          }
        },
        SearchProjects: ({ mutation, query }: any) => {
          return {
            ...query.result,
            searchProjects: {
              ...query.result.searchProjects,
              items: query.result.searchProjects.items.filter((project: IProject) => {
                return project._id !== mutation.variables.projectId;
              }),
            },
          };
        },
      },
      RemoveTag: {
        ListAllTags: ({ mutation, query }: any) => {
          return {
            ...query.result,
            listAllTags: query.result.listAllTags.filter((tag: ITag) => {
              return tag._id !== mutation.variables.tagId;
            }),
          };
        },
      },
      ArchiveProduct: {
        AllProducts: ({ mutation, query }: any) => {
          return {
            ...query.result,
            products: {
              ...query.result.products,
              items: query.result.products.items.filter((candidate: any) => {
                return candidate._id !== mutation.variables.productId;
              }),
            },
          };
        },
      },
    }),
    authLink.concat(
      new HttpLink({
        uri: `${process.env.API_URL}`,
        fetchOptions: {
          credentials: "include",
        },
        credentials: "same-origin",
      }),
    ),
  ]),
  cache,
});
