import gql from "graphql-tag";

export default gql`
  query Selfie {
    selfie {
      ... on GovernmentUser {
        _id
        slug
        role
        firstName
        fullName
        avatar
        isInnovator
        email
        projectsCount
        isSiteAdmin
        isLimitedSiteAdmin
        government {
          _id
          name
          slug
          projectsCount
          membersCount
        }
        algoliaKeys {
          default
          projects
        }
      }
      ... on VendorUser {
        _id
        slug
        role
        firstName
        fullName
        avatar
        email
        isSiteAdmin
        isLimitedSiteAdmin
        company {
          _id
          name
          slug
        }
        algoliaKeys {
          default
          projects
        }
      }
      ... on PendingUser {
        _id
        slug
        role
        firstName
        fullName
        avatar
        email
        isSiteAdmin
        isLimitedSiteAdmin
        algoliaKeys {
          default
          projects
        }
      }
    }
  }
`;
