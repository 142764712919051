import React from "react";
import { IGetSponsorshipQuery } from "../../../queries/__generated__/GetSponsorship.generated";
import VendorsWithSearch from "../../vendors/tables/VendorsWithSearchWithRouter";

interface ISponsorshipVendorsTabProps {
  sponsorship: NonNullable<IGetSponsorshipQuery["sponsorship"]>;
}

export default function SponsorshipVendorsTab({ sponsorship }: ISponsorshipVendorsTabProps) {
  return (
    <div
      css={{
        padding: 20,
      }}
    >
      <VendorsWithSearch sponsorship={sponsorship._id} historyOrigin={`/sponsorships/${sponsorship.slug}/vendors`} />
    </div>
  );
}
