import React from "react";
import { useQuery } from "react-apollo";
import GetUpcomingEvents from "../../../../queries/GetUpcomingEvents";
import {
  IGetUpcomingEventsQuery,
  IGetUpcomingEventsQueryVariables,
} from "../../../../queries/__generated__/GetUpcomingEvents.generated";
import { DEFAULT_PROJECTS_ITEM_LIMIT, IBlockLayout } from "../blockProps";
import CenteredLoadingSpinner from "../CenteredLoadingSpinner";
import moment from "moment";
import { Flex, Text } from "@govlaunch/web";

interface IEventsTableRowsProps {
  block: IBlockLayout;
}

export default function EventsTableRows({ block }: IEventsTableRowsProps) {
  const { data, loading } = useQuery<IGetUpcomingEventsQuery, IGetUpcomingEventsQueryVariables>(GetUpcomingEvents, {
    variables: {
      limit: block.blockData.itemLimit || DEFAULT_PROJECTS_ITEM_LIMIT,
    },
    fetchPolicy: "network-only",
  });

  const events = data && data.getUpcomingEvents && data.getUpcomingEvents.length > 0 ? data.getUpcomingEvents : [];

  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {(!data || loading) && <CenteredLoadingSpinner />}

      {events.map((event) => (
        <Event key={event._id} event={event} />
      ))}
    </div>
  );
}

interface IEventProps {
  event: IGetUpcomingEventsQuery["getUpcomingEvents"][0];
}

function Event({ event }: IEventProps) {
  return (
    <Flex
      width="100%"
      direction={["column", "column", "column", "row"]}
      alignItems={["left", "left", "left", "center"]}
      py={["20px", "20px", "20px", "0px"]}
      minHeight="80px"
      overflow="hidden"
      borderBottom="1px solid"
      borderBottomColor="gray.100"
    >
      <Text
        width={["100%", "100%", "100%", "25%"]}
        color="gray.600"
        lineHeight="24px"
        fontWeight={[400, 400, 400, 600]}
        fontSize="16px"
      >
        {moment(event.startDate).format("MMM Do, YYYY")}
      </Text>
      <Text
        width={["100%", "100%", "100%", "50%"]}
        lineHeight={["21.3px", "21.3px", "21.3px", "24px"]}
        fontWeight={600}
        fontSize="16px"
        px={["0px", "0px", "0px", "8px"]}
        py={["10px", "10px", "10px", "0px"]}
      >
        {event.title}
      </Text>
      <Text
        width={["100%", "100%", "100%", "25%"]}
        color="gray.600"
        lineHeight={["21.3px", "21.3px", "21.3px", "24px"]}
        fontWeight={[400, 400, 400, 600]}
        fontSize="16px"
      >
        {event.location}
      </Text>
    </Flex>
  );
}
