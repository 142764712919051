import {
  IGovernmentVerificationRequestSortBy,
  IGovernmentVerificationRequestSortByDirection,
  IGovernmentVerificationRequestStatus,
} from "../../../../types/types";
import { IAllGovernmentVerificationRequestsQueryVariables } from "../../../queries/__generated__/AllGovernmentVerificationRequests.generated";
import { TQueryVariablesParameters } from "../projectsVerification/types";

export function translateTabToStatus(tab: string) {
  if (tab === "pending") {
    return IGovernmentVerificationRequestStatus.Pending;
  }

  if (tab === "verified") {
    return IGovernmentVerificationRequestStatus.Verified;
  }

  if (tab === "declined") {
    return IGovernmentVerificationRequestStatus.Declined;
  }

  if (tab === "cancelled") {
    return IGovernmentVerificationRequestStatus.Cancelled;
  }

  return null;
}

export function getQueryVariables({
  searchQuery,
  searchTarget,
  tab,
  cursor,
  sorter,
}: TQueryVariablesParameters): IAllGovernmentVerificationRequestsQueryVariables {
  let searchVariables: Pick<IAllGovernmentVerificationRequestsQueryVariables, "searchQuery" | "searchTarget"> = {
    searchQuery: null,
    searchTarget: null,
  };

  if (searchQuery && searchQuery.trim()) {
    searchVariables = {
      ...searchVariables,
      searchQuery: searchQuery.trim(),
      searchTarget,
    };
  }

  return {
    ...searchVariables,
    filterByStatus: translateTabToStatus(tab),
    filterByGovernment: null,
    cursor,
    sortBy: IGovernmentVerificationRequestSortBy.Created,
    sortDirection: IGovernmentVerificationRequestSortByDirection.Descending,
    ...sorter,
    limit: 25,
  };
}
