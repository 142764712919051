import gql from "graphql-tag";

export default gql`
  mutation ToggleVendorCreditsEligibility($vendorId: ObjectId!) {
    toggleVendorCreditsEligibility(vendorId: $vendorId) {
      _id
      canEarnCreditsUntil
    }
  }
`;
