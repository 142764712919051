import { Table, Tabs } from "antd";
import queryString from "query-string";
import React, { useCallback, useState } from "react";
import { useQuery } from "react-apollo";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";
import VisibilitySensor from "react-visibility-sensor";
import { ICollectionItemType } from "../../../types/types";
import TabContent from "../../components/TabContent";
import GroupsTable from "../groups/paginated/GroupsTable";
import ProductsTable from "../products/tables/ProductsTable";
import ProjectsTable from "../projects/tables/ProjectsTable";
import StoriesTable from "../stories/paginated/StoriesTable";
import CollectionBySlug from "./graphql/queries/CollectionBySlug";
import {
  ICollectionBySlugQuery,
  ICollectionBySlugQueryVariables,
} from "./graphql/queries/__generated__/CollectionBySlug.generated";
import CompactGovernmentsTable from "../governments/CompactGovernmentsTable";
import CollectionDetailsTab from "./CollectionDetailsTab";
import * as palette from "@govlaunch/palette";
import DocumentTitle from "react-document-title";
import { filterInUnionByTypename, filterInUnionByTypenames } from "../../utils/filterUtils";
import TablePagination, { DEFAULT_CURSOR } from "../../components/table/TablePagination";
import { calculateAndEncodeCursor } from "../../utils/encodeCursor";

const CollectionItemTypeFromTab: Record<string, ICollectionItemType> = {
  projects: ICollectionItemType.Project,
  stories: ICollectionItemType.Story,
  products: ICollectionItemType.Product,
  groups: ICollectionItemType.Group,
  governments: ICollectionItemType.Government,
};

export default function CollectionProfile() {
  const [tablesState, setTablesState] = useState<{ cursor: { page: number; size: number } }>({
    cursor: DEFAULT_CURSOR,
  });

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = Array.isArray(query.tab) ? query.tab[0] : query.tab || "details";
  const { data, loading, refetch, fetchMore } = useQuery<ICollectionBySlugQuery, ICollectionBySlugQueryVariables>(
    CollectionBySlug,
    {
      variables: {
        slug: params.slug || "",
        filterByItemType: CollectionItemTypeFromTab[tab || "details"] || ICollectionItemType.Project,
        cursor: calculateAndEncodeCursor(tablesState.cursor),
      },
    },
  );
  const changeTab = useCallback((tab: string) => {
    if (tab === "active") {
      navigate("/collections");
    } else {
      navigate(`/collections/${params.slug}?tab=${tab}`);
    }

    setTablesState({
 cursor: DEFAULT_CURSOR 
});
  }, []);

  if (loading && !data) {
    return <Table loading={true} />;
  }

  if (!data?.collectionBySlug) {
    return <Navigate to="/collections" />;
  }

  const collection = data.collectionBySlug;

  const projects = filterInUnionByTypenames(collection.content.items, [
    "GovernmentProject",
    "VendorProject",
    "GroupProject",
  ]);
  const stories = filterInUnionByTypename(collection.content.items, "Story");
  const products = filterInUnionByTypename(collection.content.items, "Product");
  const groups = filterInUnionByTypename(collection.content.items, "Group");
  const governments = filterInUnionByTypename(collection.content.items, "Government");

  const totalCount = data.collectionBySlug.content.totalCount;

  const tabContent = (
    <>
      {tab === "projects" && (
        <ProjectsTable
          projects={projects}
          loading={loading}
          noFilters={true}
          enableSorting={false}
          footer={() => (
            <TablePagination
              loading={loading}
              totalCount={totalCount}
              tableState={tablesState}
              setTableState={setTablesState}
            />
          )}
        />
      )}
      {tab === "stories" && collection.content.items.filter((item) => item.__typename === "Story") && (
        <StoriesTable
          refetch={refetch}
          noSorters={true}
          noFilters={true}
          stories={stories.map((story) => ({
            ...story,
            government: story.storyGovernment,
            status: story.storyStatus,
          }))}
          loading={loading}
          footer={() => (
            <TablePagination
              loading={loading}
              totalCount={totalCount}
              tableState={tablesState}
              setTableState={setTablesState}
            />
          )}
        />
      )}
      {tab === "groups" && (
        <GroupsTable
          groups={groups}
          archivedOnly={false}
          loading={loading}
          noSorters={true}
          footer={() => (
            <TablePagination
              loading={loading}
              totalCount={totalCount}
              tableState={tablesState}
              setTableState={setTablesState}
            />
          )}
        />
      )}
      {tab === "products" && (
        <ProductsTable
          search=""
          sorter={false}
          noFilters={true}
          products={products.map((product) => ({
            ...product,
            status: null,
          }))}
          loading={loading}
          footer={() => (
            <TablePagination
              loading={loading}
              totalCount={totalCount}
              tableState={tablesState}
              setTableState={setTablesState}
            />
          )}
        />
      )}
      {tab === "governments" && (
        <CompactGovernmentsTable
          governments={governments}
          footer={() => (
            <TablePagination
              loading={loading}
              totalCount={totalCount}
              tableState={tablesState}
              setTableState={setTablesState}
            />
          )}
        />
      )}

      <VisibilitySensor
        active={Boolean(!loading && collection.content.pageInfo.hasNextPage)}
        partialVisibility={true}
        onChange={(isActive) => {
          if (!isActive) {
            return;
          }

          if (!data.collectionBySlug?.content.items) {
            return;
          }

          fetchMore({
            variables: {
              cursor: collection.content.pageInfo.cursor,
            },
            updateQuery: (current, { fetchMoreResult }) => {
              if (!fetchMoreResult || !fetchMoreResult.collectionBySlug || !current.collectionBySlug) {
                return {
                  __typename: "Query",
                  collectionBySlug: current.collectionBySlug,
                };
              }

              return {
                __typename: "Query",
                collectionBySlug: {
                  ...current.collectionBySlug,
                  content: {
                    ...fetchMoreResult.collectionBySlug.content,
                    items: current.collectionBySlug.content.items.concat(
                      fetchMoreResult.collectionBySlug.content.items,
                    ),
                  },
                },
              };
            },
          });
        }}
      />
    </>
  );

  return (
    <DocumentTitle title={`Admin | Edit • ${collection.name}`}>
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
        }}
      >
        <Tabs
          animated={false}
          onChange={(tab) => changeTab(tab)}
          activeKey={tab || "details"}
          size="middle"
          tabBarStyle={{
            margin: 0,
            paddingRight: 20,
            paddingLeft: 20,
            userSelect: "none",
            backgroundColor: palette.white,
          }}
        >
          <Tabs.TabPane tab="Details" key="details">
            {tab === "details" && <CollectionDetailsTab collection={collection} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab={`Projects (${collection.contentCounters.projectsCount})`} key="projects">
            {tab === "projects" && <TabContent>{tabContent}</TabContent>}
          </Tabs.TabPane>
          <Tabs.TabPane tab={`Stories (${collection.contentCounters.storiesCount})`} key="stories">
            {tab === "stories" && <TabContent>{tabContent}</TabContent>}
          </Tabs.TabPane>
          <Tabs.TabPane tab={`Products (${collection.contentCounters.productsCount})`} key="products">
            {tab === "products" && <TabContent>{tabContent}</TabContent>}
          </Tabs.TabPane>
          <Tabs.TabPane tab={`Groups (${collection.contentCounters.groupsCount})`} key="groups">
            {tab === "groups" && <TabContent>{tabContent}</TabContent>}
          </Tabs.TabPane>
          <Tabs.TabPane tab={`Governments (${collection.contentCounters.governmentsCount})`} key="governments">
            {tab === "governments" && <TabContent>{tabContent}</TabContent>}
          </Tabs.TabPane>
        </Tabs>
      </div>
    </DocumentTitle>
  );
}
