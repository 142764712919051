import { faRocket } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import DocumentTitle from "react-document-title";
import TablePageHeader from "../../components/table/TablePageHeader";
import { LandingPagesWithSearch } from "./LandingPagesWithSearch";

export default function LandingPages() {
  return (
    <DocumentTitle title="Landing Page Templates | Admin">
      <>
        <TablePageHeader title="Landing Pages" titleIcon={faRocket} subTitle="All landing page templates" />
        <div
          css={{
            padding: 20,
          }}
        >
          <LandingPagesWithSearch />
        </div>
      </>
    </DocumentTitle>
  );
}
