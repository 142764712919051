import React from "react";
import { useMutation } from "react-apollo";
import { useNavigate } from "react-router";
import AddCountryTimezone from "../../mutations/AddCountryTimezone";
import {
  IAddCountryTimezoneMutation,
  IAddCountryTimezoneMutationVariables,
} from "../../mutations/__generated__/AddCountryTimezone.generated";
import * as palette from "@govlaunch/palette";
import { Form } from "react-final-form";
import countryTimezoneSchema from "./countryTimezoneSchema";
import validate from "../../validate";
import { notification } from "antd";
import { FORM_ERROR } from "final-form";
import CountryTimezoneForm from "./CountryTimezoneForm";

interface INewCountryTimezoneFormProps {
  countryCode?: string;
}

export default function NewCountryTimezoneForm({ countryCode }: INewCountryTimezoneFormProps) {
  const navigate = useNavigate();
  const [addCountryTimezone] = useMutation<IAddCountryTimezoneMutation, IAddCountryTimezoneMutationVariables>(
    AddCountryTimezone,
  );

  return (
    <div
      css={{
        maxWidth: 600,
        border: `1px solid ${palette.lightestGray}`,
        backgroundColor: palette.white,
        padding: 16,
      }}
    >
      <Form
        keepDirtyOnReinitialize={true}
        validate={validate(countryTimezoneSchema)}
        initialValues={{
          countryCode: countryCode || "",
          countryName: "",
          timezone: "+00:00",
        }}
        onSubmit={async (values) => {
          try {
            const result = await addCountryTimezone({
              variables: {
                data: {
                  countryCode: values.countryCode.toUpperCase(),
                  countryName: values.countryName,
                  timezone: values.timezone,
                },
              },
            });

            if (result && result.data && result.data.addCountryTimezone) {
              notification.success({
                message: "Success!",
                description: "Country timezone added successfully",
              });

              navigate("/newsletter/timezones");
            }
          } catch (e) {
            return {
              [FORM_ERROR]: "Could not save timezone setting",
            };
          }
        }}
      >
        {({ submitting, handleSubmit, submitError }) => (
          <CountryTimezoneForm submitting={submitting} handleSubmit={handleSubmit} submitError={submitError} />
        )}
      </Form>
    </div>
  );
}
