import React from "react";
import CheckboxField from "../form/fields/CheckboxField";
import TextField from "../form/fields/TextField";
import FormSubmitSection from "../form/FormSubmitSection";
import Section from "../form/Section";
import SectionBody from "../form/SectionBody";
import SectionHeader from "../form/SectionHeader";
import SectionSplitter from "../form/SectionSplitter";
import BlocksLayoutField from "./blockslayout/BlocksLayoutField";
import FrontendSizedGrid from "./FrontendSizedGrid";
import HeaderBannerField from "./headerbanner/HeaderBannerField";
import { PreviewButton } from "./tableactions/PreviewButton";
import * as palette from "@govlaunch/palette";
import { FormSpy, useForm } from "react-final-form";

interface ILandingPageFormProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  submitting: boolean;
  submitError?: string;
  submitButtonText?: string;
  configId?: string;
}

export default function LandingPageForm({
  onSubmit,
  submitting,
  submitError,
  submitButtonText = "Save",
  configId,
}: ILandingPageFormProps) {
  const form = useForm();
  return (
    <form onSubmit={onSubmit}>
      <FrontendSizedGrid>
        <FormSubmitSection
          backButtonText="All Templates"
          backButtonLocation="/landingPage"
          discardChangesLocation="/landingPage"
          submitButtonText={submitButtonText}
          submitting={submitting}
          submitError={submitError}
          previewButton={
            <PreviewButton
              saveOnClick={async () => {
                await form.submit();
              }}
              configId={configId}
              text="Save & Preview"
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `1px solid ${palette.darkGray}`,
                borderRadius: 5,
                height: "36px",
                background: palette.white,
                marginLeft: "12px",
              }}
            />
          }
          sideMargin={10}
        />
        <SectionSplitter>
          <Section
            css={{
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <SectionHeader>Template name</SectionHeader>

            <SectionBody>
              <TextField
                fieldName="name"
                label="Template name"
                placeholder="Use this field to help identifiying your templates"
              />
              <div
                css={{
                  display: "flex",
                  flexDireciton: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CheckboxField fieldName="active" label="Make this template active?" />
              </div>
            </SectionBody>
          </Section>

          <Section
            css={{
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <SectionHeader>Grid options</SectionHeader>
            <SectionBody>
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <CheckboxField fieldName="compact" label="Compact vertically" />

                <CheckboxField fieldName="allowOverlap" label="Overlap blocks (experimental)" />
              </div>
            </SectionBody>
          </Section>

          <Section
            css={{
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <HeaderBannerField />
          </Section>

          <FormSpy
            subscription={{
              values: true,
            }}
          >
            {({ values }) => <BlocksLayoutField compact={values.compact} allowOverlap={values.allowOverlap} />}
          </FormSpy>
        </SectionSplitter>
      </FrontendSizedGrid>
    </form>
  );
}
