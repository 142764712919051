import { Modal, ModalClose } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import moment from "moment";
import React, { ReactElement, useState } from "react";
import { ICompanyAccountBillingInfoQuery } from "../../../queries/__generated__/CompanyAccountBillingInfoQuery.generated";

interface IBalanceTransactionsProps {
  transactions: NonNullable<ICompanyAccountBillingInfoQuery["company"]>["balanceTransactions"];
  children: (toggleOpen: () => void) => ReactElement;
}

export default function BalanceTransactions({ transactions, children }: IBalanceTransactionsProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Modal isOpen={isOpen} ariaHideApp={false} onRequestClose={() => setIsOpen(false)}>
        <div
          css={{
            display: "grid",
            gridRowGap: 20,
            width: 480,
            padding: 20,
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <h2
              css={{
                fontWeight: "bold",
                margin: 0,
              }}
            >
              Balance
            </h2>

            <ModalClose
              onClick={() => setIsOpen(false)}
              style={{
                position: "relative",
                top: 0,
                right: 0,
                marginLeft: "auto",
                cursor: "pointer",
              }}
            />
          </div>

          <div>
            <table
              css={{
                width: "100%",
                "& th, td": {
                  textAlign: "left",
                },
              }}
            >
              <thead>
                <tr
                  css={{
                    "& th": {
                      color: palette.darkGray,
                    },
                  }}
                >
                  <th>Description</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </thead>

              <tbody>
                {transactions.length === 0 && (
                  <tr>
                    <td
                      css={{
                        color: palette.darkGray,
                      }}
                      colSpan={3}
                    >
                      No entries available.
                    </td>
                  </tr>
                )}

                {transactions.map((transaction, index) => {
                  if (transaction.type === "applied_to_invoice") {
                    return (
                      <tr key={`${index}/${transaction.type}`}>
                        <td
                          css={{
                            color: palette.darkGray,
                          }}
                        >
                          Applied to Invoice
                        </td>
                        <td
                          css={{
                            color: palette.darkGray,
                          }}
                        >
                          {moment(transaction.createdAt).format("MMMM DD, YYYY")}
                        </td>
                        <td
                          css={{
                            color: transaction.amount < 0 ? palette.greenSuccess : palette.red,
                          }}
                        >
                          {formatDollarAmount(transaction.amount)}
                        </td>
                      </tr>
                    );
                  }

                  if (transaction.type === "adjustment" && transaction.government) {
                    return (
                      <tr key={`${index}/${transaction.type}`}>
                        <td
                          css={{
                            color: palette.darkGray,
                          }}
                        >
                          {transaction.description}
                        </td>
                        <td
                          css={{
                            color: palette.darkGray,
                          }}
                        >
                          {moment(transaction.createdAt).format("MMMM DD, YYYY")}
                        </td>
                        <td
                          css={{
                            color: transaction.amount < 0 ? palette.greenSuccess : palette.red,
                          }}
                        >
                          {formatDollarAmount(transaction.amount)}
                        </td>
                      </tr>
                    );
                  }

                  return (
                    <tr key={`${index}/${transaction.type}`}>
                      <td
                        css={{
                          color: palette.darkGray,
                        }}
                      >
                        {transaction.description || "N/A"}
                      </td>
                      <td
                        css={{
                          color: palette.darkGray,
                        }}
                      >
                        {moment(transaction.createdAt).format("MMMM DD, YYYY")}
                      </td>
                      <td
                        css={{
                          color: transaction.amount < 0 ? palette.greenSuccess : palette.red,
                        }}
                      >
                        {formatDollarAmount(transaction.amount)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>

      {children(() => setIsOpen(true))}
    </>
  );
}

function formatDollarAmount(amount: number) {
  if (amount > 0) {
    return `-$${Math.abs(amount / 100).toFixed(2)}`;
  }

  return `$${Math.abs(amount / 100).toFixed(2)}`;
}
