import gql from "graphql-tag";
import AboutPageConfigFields from "../fragments/AboutPageConfigFields";

export default gql`
  query GetAboutPageById($configId: ObjectId!) {
    getAboutPageById(configId: $configId) {
      ...AboutPageConfigFields
    }
  }

  ${AboutPageConfigFields}
`;
