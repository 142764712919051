/* eslint-disable react/display-name */
import { Tabs } from "antd";
import queryString from "query-string";
import React from "react";
import DocumentTitle from "react-document-title";
import { IProcessStatusType } from "../../../types/types";
import OnboardingsCount from "./OnboardingsCount";
import { useLocation, useNavigate } from "react-router";
import TabContent from "../../components/TabContent";
import OnboardingsWithSearch from "./tables/OnboardingsWithSearch";
import { faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import TablePageHeader from "../../components/table/TablePageHeader";
import { MetaBaseQuestion } from "../../components/MetaBaseLink";

const { TabPane } = Tabs;

export default function Onboardings() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = query.tab || "pending";

  return (
    <DocumentTitle title="Onboardings | Admin">
      <>
        <TablePageHeader
          title="Onboardings"
          titleIcon={faPlaneDeparture}
          subTitle="Manage Onboardings"
          metabaseQuestion={MetaBaseQuestion.Onboardings}
        />

        <OnboardingsCount>
          {({
            refetch,
            requiredCount,
            additionalInformationRequiredCount,
            pendingVerificationCount,
            verifiedCount,
          }) => (
            <Tabs
              animated={false}
              onChange={(tab) => {
                navigate(`/onboardings?tab=${tab}`);
                refetch();
              }}
              activeKey={tab as string}
              size="middle"
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
            >
              <TabPane tab={requiredCount ? `Required (${requiredCount})` : "Required"} key="required">
                {tab === "required" && (
                  <TabContent>
                    <OnboardingsWithSearch filterByProcessStatus={IProcessStatusType.Required} />
                  </TabContent>
                )}
              </TabPane>

              <TabPane
                tab={
                  additionalInformationRequiredCount
                    ? `Unverifiable (${additionalInformationRequiredCount})`
                    : "Unverifiable"
                }
                key="unverifiable"
              >
                {tab === "unverifiable" && (
                  <TabContent>
                    <OnboardingsWithSearch filterByProcessStatus={IProcessStatusType.AdditionalInformationRequired} />
                  </TabContent>
                )}
              </TabPane>

              <TabPane
                tab={
                  pendingVerificationCount
                    ? `Pending verification (${pendingVerificationCount})`
                    : "Pending verification"
                }
                key="pendingVerification"
              >
                {tab === "pendingVerification" && (
                  <TabContent>
                    <TabContent>
                      <OnboardingsWithSearch filterByProcessStatus={IProcessStatusType.PendingVerification} />
                    </TabContent>
                  </TabContent>
                )}
              </TabPane>

              <TabPane tab={verifiedCount ? `Finalized (${verifiedCount})` : "Finalized"} key="verified">
                {tab === "verified" && (
                  <TabContent>
                    <OnboardingsWithSearch filterByProcessStatus={IProcessStatusType.Verified} />
                  </TabContent>
                )}
              </TabPane>
            </Tabs>
          )}
        </OnboardingsCount>
      </>
    </DocumentTitle>
  );
}
