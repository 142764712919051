import React from "react";

export default function AllStoriesLinkIcon(props: React.SVGProps<any>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <circle cx="20" cy="20" r="18.75" fill="white" stroke="#3F4A5A" strokeWidth="2.5" />
      <path
        d="M29.8715 10.7695L19.9997 12.5644L10.1279 10.7695C9.58944 10.7695 9.23047 11.1285 9.23047 11.667V29.6157C9.23047 30.1541 9.58944 30.5131 10.1279 30.5131L19.9997 32.308L29.8715 30.5131C30.41 30.5131 30.7689 30.1541 30.7689 29.6157V11.667C30.7689 11.1285 30.41 10.7695 29.8715 10.7695ZM19.1023 30.3336L11.0253 28.7183V26.9234L19.1023 28.7183V30.3336ZM19.1023 27.8208L11.0253 26.0259V12.5644L19.1023 14.1798V27.8208ZM28.9741 28.7183L20.8971 30.3336V28.7183L28.9741 26.9234V28.7183ZM28.9741 26.0259L20.8971 27.8208V14.1798L28.9741 12.5644V26.0259Z"
        fill="#3F4A5A"
      />
      <path d="M27.1793 23.3335L22.6921 24.2309V25.1284L27.1793 24.2309V23.3335Z" fill="#3F4A5A" />
      <path d="M27.1793 20.6411L22.6921 21.5385V22.436L27.1793 21.5385V20.6411Z" fill="#3F4A5A" />
      <path d="M27.1793 17.9487L22.6921 18.8462V19.7436L27.1793 18.8462V17.9487Z" fill="#3F4A5A" />
      <path d="M27.1793 15.2568L22.6921 16.1543V17.0517L27.1793 16.1543V15.2568Z" fill="#3F4A5A" />
      <path d="M17.3077 24.2309L12.8206 23.3335V24.2309L17.3077 25.1284V24.2309Z" fill="#3F4A5A" />
      <path d="M17.3077 21.5385L12.8206 20.6411V21.5385L17.3077 22.436V21.5385Z" fill="#3F4A5A" />
      <path d="M17.3077 18.8462L12.8206 17.9487V18.8462L17.3077 19.7436V18.8462Z" fill="#3F4A5A" />
      <path d="M17.3077 16.1543L12.8206 15.2568V16.1543L17.3077 17.0517V16.1543Z" fill="#3F4A5A" />
    </svg>
  );
}
