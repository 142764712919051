import React from "react";
import { Mutation } from "react-apollo";
import ArchiveProject from "../../../mutations/ArchiveProject";
import {
  IArchiveProjectMutation,
  IArchiveProjectMutationVariables,
} from "../../../mutations/__generated__/ArchiveProject.generated";
import * as palette from "@govlaunch/palette";
import { notification } from "antd";

interface IArchiveProjectActionProps {
  projectId: any;
  onDeleteProject?: any;
}

export default function ArchiveProjectAction({ projectId, onDeleteProject }: IArchiveProjectActionProps) {
  return (
    <Mutation<IArchiveProjectMutation, IArchiveProjectMutationVariables>
      variables={{
        projectId: projectId,
      }}
      mutation={ArchiveProject}
      refetchQueries={["SearchProjects", "ProjectsCount"]}
    >
      {(archiveProject, { loading }) => (
        <span
          onClick={() => {
            if (window.confirm("Do you want to archive this project?")) {
              archiveProject().then(() => {
                if (onDeleteProject) {
                  onDeleteProject();
                }

                notification.open({
                  type: "success",
                  message: "Project archived!",
                  description: `Project has been archived!`,
                });
              });
            }
          }}
          role="button"
          aria-label="Archive this project"
          css={{
            color: palette.red,
            fontSize: 14,
            fontWeight: 500,
            cursor: "pointer",
            pointerEvents: loading ? "none" : "all",
            "&:hover": {
              opacity: 0.8,
            },
          }}
        >
          {loading ? "Please wait...." : "Archive this project"}
        </span>
      )}
    </Mutation>
  );
}
