import gql from "graphql-tag";
import LandingPageConfigFields from "./fragments/LandingPageConfigFields";

export default gql`
  query GetAllLandingPages($search: String, $cursor: Cursor) {
    allLandingPageConfigs(search: $search, cursor: $cursor) {
      items {
        ...LandingPageConfigFields
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }

  ${LandingPageConfigFields}
`;
