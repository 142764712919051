import { useMutation } from "react-apollo";
import { notification } from "antd";
import React from "react";
import { Form } from "react-final-form";
import ForceSubscribeToPlan from "../../../mutations/ForceSubscribeToPlan";
import {
  IForceSubscribeToPlanMutation,
  IForceSubscribeToPlanMutationVariables,
} from "../../../mutations/__generated__/ForceSubscribeToPlan.generated";

import ManualSubscriptionForm, { SubscriptionType } from "./ManualSubscriptionForm";
import { IVendorQuery } from "../../../queries/__generated__/VendorQuery.generated";
import validate from "../../../validate";
import forceSubscriptionValidationSchema from "./forceSubscriptionValidationSchema";
import { IPlanTier } from "../../../../types/types";
import { FORM_ERROR } from "final-form";

interface IManualSubscriptionProps {
  vendor: NonNullable<IVendorQuery["company"]>;
  refetch: () => void;
}

export default function ManualSubscription({ vendor, refetch }: IManualSubscriptionProps) {
  const [forceSubscribeToPlan] = useMutation<IForceSubscribeToPlanMutation, IForceSubscribeToPlanMutationVariables>(
    ForceSubscribeToPlan,
    {
      onCompleted: () => {
        refetch();
      },
    },
  );

  const initialValues = {
    subscriptionType:
      vendor.plan.tier === IPlanTier.Makers || vendor.plan.tier === IPlanTier.Makerspro
        ? SubscriptionType.ChangePlan
        : SubscriptionType.NoStripeCustomer,
    stripePlanId: "",
    stripeSubscriptionId: null,
    customerId: null,
    daysUntilDue: 30,
    billingName: vendor.owner?.fullName || "",
    email: vendor.owner?.email || "",
    freeSubscription: false,
    sendInvoice: true,
    trialPeriodDays: 0,
  };

  return (
    <Form
      validate={validate(forceSubscriptionValidationSchema)}
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          const result = await forceSubscribeToPlan({
            variables: {
              data: {
                vendorId: vendor._id,
                stripePlanId: values.stripePlanId,
                customerId: values.customerId,
                stripeSubscriptionId: values.stripeSubscriptionId,
                daysUntilDue: values.daysUntilDue,
                billingName: values.billingName,
                email: values.email,
                freeSubscription: values.freeSubscription,
                sendInvoice: values.sendInvoice,
                trialPeriodDays: values.trialPeriodDays,
              },
            },
          });

          if (result && result.data && result.data.forceSubscribeToPlan) {
            notification.success({
              message: "Vendor subscribed!",
              description: "Vendor subscribed successfully",
            });
          }
        } catch (e) {
          if (/Plan does not match/i.test(e.message)) {
            return {
              [FORM_ERROR]: "Plan ID does not match the stripe customer plan",
            };
          }

          return {
            [FORM_ERROR]: "Could not subscribe vendor",
          };
        }
      }}
    >
      {({ handleSubmit, submitting, values, submitError }) => (
        <ManualSubscriptionForm
          vendor={vendor}
          handleSubmit={handleSubmit}
          submitting={submitting}
          formValues={values}
          submitError={submitError}
          refetch={refetch}
        />
      )}
    </Form>
  );
}
