import gql from "graphql-tag";
import CollectionSponsoringSaleFields from "../queries/fragments/CollectionSponsoringSaleFields";

export default gql`
  mutation ArchiveCollectionSponsoringSale($saleId: ObjectId!) {
    archiveCollectionSponsoringSale(saleId: $saleId) {
      ...CollectionSponsoringSaleFields
    }
  }

  ${CollectionSponsoringSaleFields}
`;
