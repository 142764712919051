import * as palette from "@govlaunch/palette";
import { Table } from "antd";
import uniqBy from "lodash/fp/uniqBy";
import startCase from "lodash/fp/startCase";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { ITag } from "../../../../types/types";
import { IGovernmentProjectFieldsFragment } from "../../../queries/fragments/__generated__/GovernmentProjectFields.generated";
import notUndefinedOrNull from "../../../utils/notUndefinedOrNull";
import FlagWithTooltip from "../../governments/FlagWithTooltip";
import { IProjectsTableProps, TProjectFields } from "./ProjectsTable";

export default function BasicProjectsTable({
  projects,
  footer,
  onChange,
  title,
  loading = false,
  ...props
}: IProjectsTableProps) {
  return (
    <Table
      {...props}
      pagination={false}
      rowKey="_id"
      bordered={true}
      dataSource={projects}
      onChange={onChange}
      title={title}
      loading={loading}
      footer={footer}
      scroll={{
        x: true,
      }}
      style={{
        backgroundColor: palette.white,
      }}
      columns={[
        {
          title: "Title",
          dataIndex: "title",
          render: (title: string, project: TProjectFields) => (
            <>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  css={{
                    width: 24,
                    height: 24,
                    backgroundImage: `url(${project.icon})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                  }}
                />

                <Link
                  to={`/projects/${project.slug}`}
                  css={{
                    marginLeft: 8,
                    color: palette.primary,
                    cursor: "pointer",
                  }}
                >
                  {title}
                </Link>
              </div>
              <div
                css={{
                  display: "flex",
                  justifyContent: "flex-start",
                  fontSize: 12,
                  fontStyle: "italic",
                  color: palette.mediumGray,
                }}
              >
                {project._id}
              </div>
            </>
          ),
        },
        {
          title: "Type",
          dataIndex: "__typename",
          render: (__typename: string) => {
            switch (__typename) {
              case "VendorProject":
                return "Vendor";
              case "GroupProject":
                return "Group";
              case "GovernmentProject":
                return "Government";
              default:
                return "N/A";
            }
          },
        },
        {
          title: "Audience",
          dataIndex: "audience",
          render: (audience: string) => startCase(audience),
        },
        {
          title: "Status",
          dataIndex: "status",
          render: (status: any) => (status ? <ProjectStatus status={status} /> : "No Status"),
        },
        {
          title: "Author",
          dataIndex: "author",
          columnKey: "author",
          key: "author",
          sorter: false,
          render: (author: any) => {
            if (!author) {
              return "N/A";
            }

            return <Link to={`/users/${author.slug}`}>{author ? author.fullName || "N/A" : "N/A"}</Link>;
          },
        },
        {
          title: "Country",
          dataIndex: "government",
          key: "country",
          render: (government: IGovernmentProjectFieldsFragment["government"]) => {
            if (!government) {
              return <span>N/A</span>;
            }

            return <FlagWithTooltip country={government.city.country} width={24} height={18} />;
          },
        },
        {
          title: "Tags",
          dataIndex: "tags",
          render: (tags: ITag[]) => {
            return (
              <div
                css={{
                  display: "grid",
                  justifyContent: "center",
                  rowGap: 8,
                }}
              >
                {uniqBy((tag) => tag._id, tags).map((tag) => {
                  return (
                    <span
                      key={tag._id}
                      css={{
                        display: "flex",
                        justifySelf: "center",
                        padding: "5px 8px",
                        color: palette.darkGray,
                        fontSize: 12,
                        whiteSpace: "nowrap",
                        backgroundColor: "rgba(219, 236, 247, 0.5)",
                        borderRadius: 10,
                      }}
                    >
                      {tag.name}
                    </span>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: "Created",
          render: ({ createdAt }: { createdAt: Date }) => moment(createdAt).format("MM/DD/YYYY"),
        },
      ].filter(notUndefinedOrNull)}
    />
  );
}

BasicProjectsTable.propTypes = {
  projects: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  search: PropTypes.string,
  onDeleteProject: PropTypes.func,
  onChange: PropTypes.func,
};

BasicProjectsTable.defaultProps = {
  loading: false,
  search: "",
  onDeleteProject: null,
  onChange: null,
};

interface IProjectStatusProps {
  status: "ideation" | "planning" | "inProgress" | "complete";
}

const ProjectStatus = ({ status }: IProjectStatusProps) => {
  let statusText, color;

  if (status === "ideation") {
    statusText = "Ideation";
    color = "#609FD4";
  }

  if (status === "planning" || status === "inProgress") {
    statusText = status === "planning" ? "Planning" : "In Progress";
    color = "#E6AC5F";
  }

  if (status === "complete") {
    statusText = "Complete";
    color = "#5FBD8C";
  }

  return (
    <div
      css={{
        fontWeight: "bold",
        fontSize: 14,
        display: "inline-flex",
        borderRadius: 16,
        color,
        whiteSpace: "nowrap",
      }}
    >
      {statusText}
    </div>
  );
};

ProjectStatus.propTypes = {
  status: PropTypes.oneOf(["ideation", "planning", "inProgress", "complete"]).isRequired,
};
