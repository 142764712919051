import React from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import TableSearch from "../../components/table/TableSearch";
import ManageImages from "./ManageImages";
import { IImagesQueryTab } from "../../../types/types";

interface IImagesWithSearchProps {
  tab: IImagesQueryTab;
}

export default function ImagesWithSearch({ tab }: IImagesWithSearchProps) {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div css={{
 marginBottom: 20 
}}
      >
        <TableSearch historyOrigin="/images" query={query} inputPlaceholder="Search for images..." />
      </div>
      <ManageImages search={query.search as string} tab={tab} />
    </>
  );
}
