import React from "react";
import * as palette from "@govlaunch/palette";

export default function SectionHeader({ children, props }: any) {
  return (
    <div
      css={{
        borderBottom: `1px solid ${palette.lightestGray}`,
        padding: 15,
        textTransform: "uppercase",
        fontSize: 12,
        color: palette.darkGray,
        letterSpacing: 0.5,
        fontWeight: 500,
      }}
      {...props}
    >
      {children}
    </div>
  );
}
