import React from "react";
import DocumentTitle from "react-document-title";
import * as palette from "@govlaunch/palette";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router";
import NewCountryTimezoneForm from "./NewCountryTimezoneForm";

export default function NewCountryTimezonePage() {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <DocumentTitle title="Timezone | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
          padding: 20,
          overflow: "auto",
        }}
      >
        <div
          css={{
            paddingBottom: 8,
          }}
        >
          <a
            css={{
              color: palette.primary,
              cursor: "pointer",
            }}
            onClick={() => navigate("/newsletter/timezones")}
          >
            <span css={{
 marginRight: 5 
}}
            >
              <FontAwesomeIcon icon={faClock} size="sm" />
            </span>
            All Timezones
          </a>
        </div>

        <NewCountryTimezoneForm countryCode={params.countryCode} />
      </div>
    </DocumentTitle>
  );
}
