import React from "react";
import { Mutation } from "react-apollo";
import AddVendorMutation from "../../../mutations/AddVendor";
import { IAddVendorMutation, IAddVendorMutationVariables } from "../../../mutations/__generated__/AddVendor.generated";
import { Form } from "react-final-form";
import validate from "../../../validate";
import VendorForm from "./VendorForm";
import convertValuesToVariables from "./convertValuesToVariables";
import validationSchema from "./validationSchema";
import { notification } from "antd";
import { useNavigate } from "react-router";

export default function AddVendorForm() {
  const navigate = useNavigate();

  return (
    <Mutation<IAddVendorMutation, IAddVendorMutationVariables> mutation={AddVendorMutation}>
      {(addVendor) => {
        return (
          <Form
            subscription={{
              submitting: true,
              submitError: true,
            }}
            initialValues={{
              newsworthy: [],
              highlights: [],
              resources: [],
            }}
            keepDirtyOnReinitialize={true}
            validate={validate(validationSchema)}
            onSubmit={async (values) => {
              return addVendor({
                variables: {
                  vendor: convertValuesToVariables(values),
                },
              }).then((mutationResult) => {
                if (
                  mutationResult &&
                  mutationResult.data &&
                  mutationResult.data.addVendor &&
                  mutationResult.data.addVendor.slug
                ) {
                  notification.open({
                    type: "success",
                    message: "Vendor added!",
                    description: `Vendor added successfully!`,
                  });

                  navigate(`/vendors/${mutationResult.data.addVendor.slug}`);
                }
              });
            }}
          >
            {({ handleSubmit, submitting, submitError }) => (
              <VendorForm
                submitting={submitting}
                onSubmit={handleSubmit}
                submitButtonText="Create Vendor"
                submitError={submitError}
              />
            )}
          </Form>
        );
      }}
    </Mutation>
  );
}
