import React, { ReactNode } from "react";
import * as palette from "@govlaunch/palette";
import Tooltip from "../../../components/Tooltip";
import { ISponsorship } from "../../../../types/types";

type Sponsorship = { __typename: "Sponsorship" } & Pick<ISponsorship, "name" | "link" | "logo">;

interface ISponsorshipBadgesProps {
  sponsorships: Sponsorship[];
  vendorLogoSize: number;
}

export default function SponsorshipBadges({ sponsorships, vendorLogoSize }: ISponsorshipBadgesProps) {
  return (
    <div>
      {sponsorships.slice(0, 4).map((sponsorship, index) => {
        return (
          <Tooltip
            key={`sponsorship/${index}`}
            backgroundColor={palette.lightestGray}
            render={() => {
              return (
                <div
                  css={{
                    padding: 8,
                    color: palette.text,
                    fontSize: 14,
                  }}
                >
                  {sponsorship.name}
                </div>
              );
            }}
          >
            {({ innerRef, bind }) => {
              return (
                <BadgePlaceholder
                  innerRef={innerRef}
                  size={vendorLogoSize}
                  index={index}
                  link={sponsorship.link}
                  {...bind}
                >
                  {sponsorship.logo ? (
                    <Badge size={vendorLogoSize} logo={sponsorship.logo} />
                  ) : (
                    <div>{`${sponsorship.name.charAt(0)}`}</div>
                  )}
                </BadgePlaceholder>
              );
            }}
          </Tooltip>
        );
      })}
    </div>
  );
}

interface IBadgePlaceholderProps {
  innerRef: (ref: ReactNode) => void;
  index: number;
  size: number;
  children: ReactNode;
  link: string | null;
}

function BadgePlaceholder({ innerRef, index, size, children, link, ...props }: IBadgePlaceholderProps) {
  return (
    <>
      {link ? (
        <a href={link || ""} target="_blank" rel="noopener noreferrer">
          <div
            ref={innerRef}
            css={{
              display: "flex",
              position: "absolute",
              ...getBadgePlaceholderSize(size),
              ...getPosition(size, index),
              backgroundColor: palette.white,
              ":hover": {
                backgroundColor: palette.lightestGray,
              },
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "100%",
              zIndex: 2 + index,
            }}
            {...props}
          >
            {children}
          </div>
        </a>
      ) : (
        <div
          ref={innerRef}
          css={{
            display: "flex",
            position: "absolute",
            ...getBadgePlaceholderSize(size),
            ...getPosition(size, index),
            backgroundColor: palette.white,
            ":hover": {
              backgroundColor: palette.lightestGray,
              cursor: "pointer",
            },
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100%",
            zIndex: 2 + index,
          }}
          {...props}
        >
          {children}
        </div>
      )}
    </>
  );
}

function getBadgePlaceholderSize(size: number) {
  return {
    height: size / 2 + 4,
    width: size / 2 + 4,
  };
}

function getBadgeSize(size: number) {
  return {
    height: size / 2,
    width: size / 2,
  };
}

//TODO: figure out a function in the case the index is greater than 4, ergo more than 4 badges
function getPosition(size: number, index: number) {
  if (index === 0) {
    return {
      right: -size / 6,
      bottom: -size / 6,
    };
  }
  if (index === 1) {
    return {
      left: -size / 6,
      top: -size / 6,
    };
  }
  if (index === 2) {
    return {
      left: -size / 6,
      bottom: -size / 6,
    };
  }
  if (index === 3) {
    return {
      right: -size / 6,
      top: -size / 6,
    };
  }

  return {
    right: -size / 6,
    bottom: -size / 6,
  };
}

interface IBadgeProps {
  size: number;
  logo: string;
}

function Badge({ size, logo }: IBadgeProps) {
  return (
    <img
      {...getBadgeSize(size)}
      src={logo}
      css={{
        userSelect: "none",
        borderRadius: "100%",
        objectFit: "contain",
      }}
    />
  );
}
