import React from "react";
import { IVendorQuery } from "../../../queries/__generated__/VendorQuery.generated";
import GroupsTable from "../../groups/GroupsTable";

interface IVendorGroupsTab {
  vendor: NonNullable<IVendorQuery["company"]>;
}

export default function VendorGroupsTab({ vendor }: IVendorGroupsTab) {
  return (
    <div
      css={{
        padding: 20,
      }}
    >
      <GroupsTable archivedOnly={false} loading={false} groups={vendor.groups} />
    </div>
  );
}
