import gql from "graphql-tag";

export default gql`
  mutation SetStoryCoverImages($story: ObjectId!, $verticalCover: String) {
    setStoryCoverImages(story: $story, verticalCover: $verticalCover) {
      _id
      verticalCover
    }
  }
`;
