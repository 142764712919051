import * as palette from "@govlaunch/palette";
import { CSSReset, theme, ThemeProvider, LoadingSpinner } from "@govlaunch/web";
import React from "react";
import { Query } from "react-apollo";
import DocumentTitle from "react-document-title";
import StoryBySlugQuery from "../../queries/StoryBySlugQuery";
import StoryPage from "./StoryPage";
import { IStoryBySlugQuery, IStoryBySlugQueryVariables } from "../../queries/__generated__/StoryBySlugQuery.generated";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import AllStoriesLinkIcon from "../../components/icons/AllStoriesLinkIcon";

export default function Story() {
  const params = useParams();

  return (
    <div
      css={{
        backgroundColor: palette.washGray,
        height: "100%",
      }}
    >
      <div
        css={{
          marginBottom: 8,
          fontSize: 18,
          paddingTop: 16,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <AllStoriesLinkIcon fill="" />
        <Link to="/stories">
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              css={{
                marginLeft: 8,
              }}
            >
              All Stories
            </div>
          </div>
        </Link>
        <div
          css={{
            marginLeft: 8,
          }}
        >
          {" • "}
        </div>

        <Link
          to={`/stories/${params.slug}/edit`}
          css={{
            marginLeft: 8,
          }}
        >
          Edit
        </Link>
      </div>

      <ThemeProvider theme={theme}>
        <CSSReset />

        <DocumentTitle title="Story | Admin">
          <Query<IStoryBySlugQuery, IStoryBySlugQueryVariables>
            fetchPolicy="network-only"
            query={StoryBySlugQuery}
            variables={{
              slug: params.slug || "",
            }}
          >
            {({ data, loading }) => {
              if (loading) {
                return (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <LoadingSpinner />
                  </div>
                );
              }

              if (!data || !data.storyBySlug) {
                return null;
              }

              const story = data.storyBySlug;

              return (
                <DocumentTitle title={`Admin | ${story.title}`}>
                  <StoryPage story={story} />
                </DocumentTitle>
              );
            }}
          </Query>
        </DocumentTitle>
      </ThemeProvider>
    </div>
  );
}
