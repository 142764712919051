import React from "react";

interface IConfigPageHeaderProps {
  title: any;
  subtitle: string;
}

export default function ConfigPageHeader({ title, subtitle }: IConfigPageHeaderProps) {
  return (
    <div
      css={{
        padding: 20,
        borderBottom: "1px solid rgb(232, 232, 232)",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div>
        <h3
          css={{
            fontWeight: 700,
            fontSize: 24,
            margin: 0,
          }}
        >
          {title}
        </h3>

        <p
          css={{
            margin: 0,
          }}
        >
          {subtitle}
        </p>
      </div>
    </div>
  );
}
