import gql from "graphql-tag";

export default gql`
  mutation ChangeStoryAuthor($storyId: ObjectId, $authorId: ObjectId) {
    changeStoryAuthor(storyId: $storyId, authorId: $authorId) {
      _id
      author {
        ... on PendingUser {
          _id
          fullName
          jobTitle
          avatar
        }
        ... on VendorUser {
          _id
          fullName
          title
          avatar
        }
        ... on GovernmentUser {
          _id
          fullName
          title
          avatar
        }
      }
    }
  }
`;
