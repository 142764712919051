import React from "react";
import { Mutation } from "react-apollo";
import ToggleVerifiedProject from "../../../mutations/ToggleVerifiedProject";
import {
  IToggleVerifiedProjectMutation,
  IToggleVerifiedProjectMutationVariables,
} from "../../../mutations/__generated__/ToggleVerifiedProject.generated";
import { notification } from "antd";
import * as palette from "@govlaunch/palette";

interface IToggleVerifyProjectActionProps {
  projectId: any;
  verified: boolean;
}

export default function ToggleVerifyProjectAction({ projectId, verified }: IToggleVerifyProjectActionProps) {
  return (
    <Mutation<IToggleVerifiedProjectMutation, IToggleVerifiedProjectMutationVariables>
      mutation={ToggleVerifiedProject}
      refetchQueries={["SearchProjects", "ProjectsCount"]}
      variables={{
        projectId: projectId,
      }}
    >
      {(toggleVerified) => (
        <a
          href="#"
          css={{
            color: palette.blue,
          }}
          onClick={() =>
            toggleVerified().then((result) => {
              if (result.data) {
                notification.open({
                  type: "success",
                  message: "Project saved!",
                  description: `Project has been ${result.data?.toggleVerified.verified ? "verified" : "unverified"}!`,
                });
              }
            })
          }
        >
          {verified ? "Mark as Pending" : "Verify"}
        </a>
      )}
    </Mutation>
  );
}
