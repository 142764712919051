import gql from "graphql-tag";

export default gql`
  query GetNewsletterCampaign($campaignId: ObjectId!) {
    getNewsletterCampaign(campaignId: $campaignId) {
      _id
      name
      dynamicContent {
        storiesCount
        projectsCount
        topCollections
        otherCollections
      }
      countries {
        countryCode
        amount
      }
    }
  }
`;
