import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { Margin } from "../../../spacings";
import CharacterLimitIndicator from "../../../components/form/CharacterLimitIndicator";
import { INumberFieldProps } from "./FormFieldProps";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { InputNumber } from "antd";

export default function NumberField({
  fieldName,
  label,
  placeholder,
  characterLimit,
  showCharacterCount,
  headerColor,
  disabled,
  minValue,
  maxValue,
  ...props
}: INumberFieldProps) {
  return (
    <Field name={fieldName} type="number">
      {({ input, meta }) => (
        <div
          css={{
            width: "100%",
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: headerColor || palette.mediumGray,
                margin: 0,
              }}
            >
              {label}
            </label>

            <FieldValidationError meta={meta} />
          </div>
          <Margin mt={8}>
            <InputNumber
              size="large"
              placeholder={placeholder}
              css={{
                width: "100%",
              }}
              {...input}
              {...props}
              disabled={disabled}
              min={minValue}
              max={maxValue}
            />
          </Margin>

          {characterLimit && showCharacterCount && (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 8,
              }}
            >
              <CharacterLimitIndicator
                maxLength={characterLimit}
                description={input.value}
                css={{
                  marginLeft: "auto",
                }}
              />
            </div>
          )}
        </div>
      )}
    </Field>
  );
}
