import styled from "@emotion/styled";

export const FieldWrapper = styled.div({
    display: "block",
    alignItems: "center",
    justifyContent: "flex-start",
    gridColumnGap: 8,
    gridAutoFlow: "column",
    marginBottom: 20,
  });
  
  