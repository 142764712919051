import React, { useState } from "react";
import { Alert, Spin, Tabs } from "antd";
import { NetworkStatus } from "apollo-client";
import { Query } from "react-apollo";
import TabContent from "../../../components/TabContent";
import AllGovernmentVerificationRequests from "../../../queries/AllGovernmentVerificationRequests";
import {
  IAllGovernmentVerificationRequestsQuery,
  IAllGovernmentVerificationRequestsQueryVariables,
} from "../../../queries/__generated__/AllGovernmentVerificationRequests.generated";
import ProjectVerificationRequestsSearch from "../projectsVerification/ProjectVerificationRequestsSearch";
import { getQueryVariables } from "../projectsVerification/utils";
import ProjectVerificationRequestsTable from "../projectsVerification/ProjectVerificationRequestsTable";
import TablePagination, { DEFAULT_CURSOR, ITablePaginationState } from "../../../components/table/TablePagination";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import {
  IGovernmentVerificationRequestSearchTarget,
  IGovernmentVerificationRequestsMetadata,
} from "../../../../types/types";
import { TProjectVerificationTableSorter } from "./types";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";

interface IManageProjectVerificationsState {
  sorter: TProjectVerificationTableSorter | null;
  cursor: ITablePaginationState;
}

export default function ManageProjectVerifications() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = Array.isArray(query.status) ? query.status[0] : query.status || "all";

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchTarget, setSearchTarget] = useState<IGovernmentVerificationRequestSearchTarget>(
    IGovernmentVerificationRequestSearchTarget.Government,
  );

  const [state, setState] = useState<IManageProjectVerificationsState>({
 sorter: null, cursor: DEFAULT_CURSOR 
});

  return (
    <Query<IAllGovernmentVerificationRequestsQuery, IAllGovernmentVerificationRequestsQueryVariables>
      query={AllGovernmentVerificationRequests}
      fetchPolicy="network-only"
      variables={getQueryVariables({
        tab: tab || "all",
        searchQuery,
        searchTarget,
        cursor: calculateAndEncodeCursor(state.cursor),
        sorter: state.sorter,
      })}
    >
      {({ data, loading, networkStatus }) => {
        if (loading && networkStatus !== NetworkStatus.setVariables) {
          return (
            <div
              css={{
                padding: 24,
              }}
            >
              <Spin size="large" />
            </div>
          );
        }

        if (!data || !data.allGovernmentVerificationRequests) {
          return (
            <div
              css={{
                padding: 24,
              }}
            >
              <Alert message="No data found :(" type="info" />
            </div>
          );
        }

        const { metadata, items } = data.allGovernmentVerificationRequests;
        const totalCount = getTotalCount(tab || "all", metadata);

        const tabContent = (
          <TabContent>
            <ProjectVerificationRequestsSearch
              searchQuery={searchQuery}
              searchTarget={searchTarget}
              onSearch={setSearchQuery}
              onChangeSearchTarget={setSearchTarget}
            />

            <div
              css={{
                marginTop: 24,
              }}
            >
              <ProjectVerificationRequestsTable
                loading={loading}
                items={items}
                onChange={({ sorter }) => {
                  setState({
 sorter, cursor: {
 page: 1, size: state.cursor.size 
} 
});
                }}
                footer={() => (
                  <TablePagination
                    loading={loading}
                    totalCount={totalCount}
                    tableState={state}
                    setTableState={setState}
                  />
                )}
              />
            </div>
          </TabContent>
        );

        return (
          <>
            <Tabs
              animated={false}
              onChange={(tab) => {
                navigate(`/governments/projectVerification?status=${tab}`);
                setState({
 sorter: null, cursor: {
 page: 1, size: state.cursor.size 
} 
});
              }}
              activeKey={tab || "all"}
              size="middle"
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
            >
              <Tabs.TabPane tab={`All (${metadata.totalCount})`} key="all">
                {tab === "all" && tabContent}
              </Tabs.TabPane>

              <Tabs.TabPane tab={`Pending (${metadata.pendingCount})`} key="pending">
                {tab === "pending" && tabContent}
              </Tabs.TabPane>

              <Tabs.TabPane tab={`Verified (${metadata.verifiedCount})`} key="verified">
                {tab === "verified" && tabContent}
              </Tabs.TabPane>

              <Tabs.TabPane tab={`Declined (${metadata.declinedCount})`} key="declined">
                {tab === "declined" && tabContent}
              </Tabs.TabPane>

              <Tabs.TabPane tab={`Cancelled (${metadata.cancelledCount})`} key="cancelled">
                {tab === "cancelled" && (
                  <TabContent>
                    <div>
                      <ProjectVerificationRequestsSearch
                        searchQuery={searchQuery}
                        searchTarget={searchTarget}
                        onSearch={setSearchQuery}
                        onChangeSearchTarget={setSearchTarget}
                      />

                      <div
                        css={{
                          marginTop: 24,
                          marginBottom: 24,
                        }}
                      >
                        <ProjectVerificationRequestsTable
                          loading={loading}
                          items={items}
                          onChange={() => {}}
                          footer={() => (
                            <TablePagination
                              loading={loading}
                              totalCount={totalCount}
                              tableState={state}
                              setTableState={setState}
                            />
                          )}
                        />
                      </div>

                      <Alert
                        message="Reminder"
                        description={
                          <div>
                            <p>
                              Below is one of the reasons for a Verification request to be <strong>Cancelled</strong>:
                            </p>

                            <ul>
                              <li>The Project was removed</li>
                              <li>The Project was edited removing the Government</li>
                              <li>A site Administrator has verified the Project on behalf of the Government</li>
                              <li>The Project was changed to PRIVATE</li>
                            </ul>
                          </div>
                        }
                        type="info"
                        showIcon={true}
                      />
                    </div>
                  </TabContent>
                )}
              </Tabs.TabPane>
            </Tabs>
          </>
        );
      }}
    </Query>
  );
}

function getTotalCount(
  tab: string,
  metadata: {
    __typename: "GovernmentVerificationRequestsMetadata";
  } & Pick<
    IGovernmentVerificationRequestsMetadata,
    "totalCount" | "pendingCount" | "verifiedCount" | "declinedCount" | "cancelledCount"
  >,
) {
  switch (tab) {
    case "all":
      return metadata.totalCount;
    case "pending":
      return metadata.pendingCount;
    case "verified":
      return metadata.verifiedCount;
    case "declined":
      return metadata.declinedCount;
    case "cancelled":
      return metadata.cancelledCount;
    default:
      return 0;
  }
}
