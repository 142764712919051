import { IBlockLayoutType } from "../../../../types/types";
import { getBlockKey, getDefaultBlockContentByType } from "../blocks/blockProps";

export default function getDefaultBlocksLayout() {
  return [
    {
      i: getBlockKey(),
      x: 0,
      y: 0,
      w: 8,
      h: 29,
      blockData: getDefaultBlockContentByType(IBlockLayoutType.ExternalLinks),
    },
    {
      i: getBlockKey(),
      x: 0,
      y: 31,
      w: 8,
      h: 40,
      blockData: getDefaultBlockContentByType(IBlockLayoutType.NewProjects),
    },
    {
      i: getBlockKey(),
      x: 0,
      y: 73,
      w: 8,
      h: 64,
      blockData: getDefaultBlockContentByType(IBlockLayoutType.FeaturedStories),
    },
    {
      i: getBlockKey(),
      x: 8,
      y: 0,
      w: 8,
      h: 15,
      blockData: getDefaultBlockContentByType(IBlockLayoutType.StatCounters),
    },
    {
      i: getBlockKey(),
      x: 8,
      y: 17,
      w: 8,
      h: 13,
      blockData: getDefaultBlockContentByType(IBlockLayoutType.Podcast),
    },
    {
      i: getBlockKey(),
      x: 8,
      y: 32,
      w: 8,
      h: 15,
      blockData: getDefaultBlockContentByType(IBlockLayoutType.Advertisement),
    },
    {
      i: getBlockKey(),
      x: 8,
      y: 49,
      w: 8,
      h: 44,
      blockData: getDefaultBlockContentByType(IBlockLayoutType.TrendingGovernments),
    },
    {
      i: getBlockKey(),
      x: 8,
      y: 95,
      w: 8,
      h: 28,
      blockData: getDefaultBlockContentByType(IBlockLayoutType.TrendingCollections),
    },
    {
      i: getBlockKey(),
      x: 8,
      y: 126,
      w: 8,
      h: 24,
      blockData: getDefaultBlockContentByType(IBlockLayoutType.Events),
    },
  ];
}
