import { getOr } from "lodash/fp";

export default function convertProjectToVariables(project: any) {
  const outcome = getOr(null, "outcome.value", project);
  const outcomeStatement = getOr("", "outcomeStatement", project).trim();

  return {
    title: project.title,
    description: project.description,
    subtitle: project.subtitle || "",
    audience: (project.audience || "public").toUpperCase(),
    tags: (project.tags || []).map(({ value }: { value: string }) => value),
    awards: (project.awards || []).map(({ _id, objectID }: { _id: string; objectID: string }) => _id || objectID),
    newsworthy: project.newsworthy || [],
    products: project.products || [],
    outsideProducts: project.outsideProducts || [],
    attachments: project.attachments || [],
    links: project.links || [],
    vendors: project.vendors || [],
    groups: (project.groups || []).map(({ _id }: { _id: string }) => _id),
    outcome,
    outcomeStatement: outcome && outcomeStatement ? outcomeStatement : null,
    locations: project.locations ? project.locations.map(({ _id }: { _id: string }) => _id) : [],
    status: getOr(null, "status.value", project),
  };
}
