import gql from "graphql-tag";

export default gql`
  query GovernmentsCount($filterByCountry: String) {
    governmentsCount(filterByCountry: $filterByCountry) {
      allCount
      activeCount
      innovatorsCount
      inactiveCount
      sharingCount
    }
  }
`;
