import { notification } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import { Form } from "react-final-form";
import validate from "../../validate";
import UpdateAboutPageConfigMutation from "./graphql/mutations/UpdateAboutPageConfigMutation";
import {
  IUpdateAboutPageConfigMutation,
  IUpdateAboutPageConfigMutationVariables,
} from "./graphql/mutations/__generated__/UpdateAboutPageConfigMutation.generated";
import AllAboutPageConfigsQuery from "./graphql/queries/AllAboutPageConfigsQuery";
import { IGetAboutPageByIdQuery } from "./graphql/queries/__generated__/GetAboutPageByIdQuery.generated";
import AboutPageConfigForm from "./AboutPageConfigForm";
import { convertAboutPageConfigToInitialValues, convertValuesToVariables } from "./utils";
import validationSchema from "./validationSchema";

interface IEditAboutPageConfigFormProps {
  aboutPageConfig: NonNullable<IGetAboutPageByIdQuery["getAboutPageById"]>;
}

export default function EditAboutPageConfigForm({ aboutPageConfig }: IEditAboutPageConfigFormProps) {
  const [updateConfig] = useMutation<IUpdateAboutPageConfigMutation, IUpdateAboutPageConfigMutationVariables>(
    UpdateAboutPageConfigMutation,
    {
      refetchQueries: [
        {
          query: AllAboutPageConfigsQuery,
        },
      ],
    },
  );

  return (
    <Form
      keepDirtyOnReinitialize={true}
      subscription={{
        submitting: true,
        submitError: true,
      }}
      initialValues={convertAboutPageConfigToInitialValues(aboutPageConfig)}
      validate={validate(validationSchema)}
      onSubmit={async (values) => {
        const result = await updateConfig({
          variables: {
            configId: aboutPageConfig._id,
            ...convertValuesToVariables(values),
          },
        });

        if (result && result.data && result.data.updateAboutPageConfig) {
          notification.success({
            message: "About page template saved!",
            description: `About page template saved successfully!`,
          });
        }
      }}
    >
      {({ handleSubmit, submitting, submitError }) => (
        <AboutPageConfigForm
          submitting={submitting}
          onSubmit={handleSubmit}
          submitButtonText="Save Changes"
          submitError={submitError}
          configId={aboutPageConfig._id}
        />
      )}
    </Form>
  );
}
