import React from "react";
import { Layout, Menu } from "antd";
import * as palette from "@govlaunch/palette";
import SubMenu from "antd/lib/menu/SubMenu";
import SuggestionsCount from "../products/SuggestionsCount";
import UsersCount from "../users/UsersCount";
import OnboardingsCount from "../onboarding/OnboardingsCount";
import {
  AboutPageIcon,
  BillingIcon,
  CampaingsIcon,
  CollectionsIcon,
  EventsIcon,
  ExternalLinksIcon,
  GovernmentsIcon,
  GroupsIcon,
  ImagesIcon,
  LandingPageIcon,
  NewsletterIcon,
  PodcastIcon,
  PostsIcon,
  ProductsIcon,
  ProjectsIcon,
  ReportsIcon,
  ScheduleIcon,
  SettingsIcon,
  SponsorshipIcon,
  StoriesIcon,
  TagsIcon,
  UsersIcon,
  VendorsIcon,
} from "../../icons/MenuIcons";
import MenuCollapseTrigger from "./MenuCollapseTrigger";
import { Div, Span } from "glamorous";
import { Link } from "react-router-dom";
import GovlaunchLogo, { GovlaunchIcon } from "../../GovlaunchLogo";
import PopoverTitle from "./PopoverTitle";
import MainMenuItem from "./MainMenuItem";
import SubMenuItem from "./SubMenuItem";
import { useSelfie } from "../auth/Selfie";

const { Sider } = Layout;

interface IDashboardMenuProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  isDesktop: boolean;
}

export default function DashboardMenu({ collapsed, setCollapsed, isDesktop }: IDashboardMenuProps) {
  const selfie = useSelfie();
  return (
    <Sider
      trigger={<MenuCollapseTrigger collapsed={collapsed} setCollapsed={setCollapsed} />}
      collapsible={true}
      collapsed={collapsed}
      collapsedWidth={50}
      theme="light"
      width={250}
      css={{
        "&::-webkit-scrollbar": {
          width: 1,
        },
        "&::-webkit-scrollbar-track": {
          background: palette.lightGray,
        },
        "&::-webkit-scrollbar-thumb": {
          background: palette.lightGray,
          borderRadius: 1,
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: palette.blue,
        },
        overflowX: "hidden",
        overflowY: "auto",
        position: "fixed",
        left: 0,
        borderRight: "1px solid",
        borderColor: palette.lightGray,
        height: "100%",
        maxHeight: "100vh",
        zIndex: 999,
      }}
    >
      <Link
        to="/"
        style={{
          display: "flex",
          justifyContent: "center",
          maxHeight: 90,
          paddingTop: isDesktop ? 12 : 5,
          paddingBottom: collapsed ? 0 : 20,
          borderRight: "1px solid #e8e8e8",
        }}
      >
        {collapsed && (
          <span
            css={{
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            <GovlaunchIcon color="#015bee" />
          </span>
        )}
        {!collapsed && <GovlaunchLogo color="#015bee" />}
      </Link>

      <Menu
        theme="light"
        mode="inline"
        style={{
          paddingBottom: 45,
        }}
        activeKey="undefined"
        triggerSubMenuAction="click"
      >
        {selfie && selfie.isSiteAdmin && (
          <SubMenu key="users-sub-menu" icon={<UsersIcon collapsed={collapsed} />} title={collapsed ? "" : "Users"}>
            {collapsed && <PopoverTitle title="Users" />}
            <SubMenuItem title="All" href="/users" collapsed={collapsed}>
              <UsersCount>
                {({ pendingUsersCount }) => (
                  <Div
                    display="inline"
                    position="relative"
                    css={{
                      height: "50%",
                    }}
                  >
                    {pendingUsersCount > 0 && (
                      <Span
                        position="absolute"
                        top={-10}
                        right={-20}
                        borderRadius="100%"
                        backgroundColor="#fe442f"
                        color={palette.white}
                        width={20}
                        height={20}
                        fontSize={10}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {pendingUsersCount}
                      </Span>
                    )}
                  </Div>
                )}
              </UsersCount>
            </SubMenuItem>

            <SubMenuItem title="Onboarding" href="/onboardings?tab=pendingVerification" collapsed={collapsed}>
              <OnboardingsCount>
                {({ pendingVerificationCount }) => (
                  <Div
                    display="inline"
                    position="relative"
                    css={{
                      height: "50%",
                    }}
                  >
                    {pendingVerificationCount > 0 && (
                      <Span
                        position="absolute"
                        top={-10}
                        right={-20}
                        borderRadius="100%"
                        backgroundColor="#fe442f"
                        color={palette.white}
                        width={20}
                        height={20}
                        fontSize={10}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {pendingVerificationCount}
                      </Span>
                    )}
                  </Div>
                )}
              </OnboardingsCount>
            </SubMenuItem>

            <SubMenuItem title="Duplicated" href="/duplicatedUsers" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && (
          <SubMenu
            key="governments-sub-menu"
            icon={<GovernmentsIcon collapsed={collapsed} />}
            title={collapsed ? "" : "Governments"}
          >
            {collapsed && <PopoverTitle title="Governments" />}
            <SubMenuItem title="All" href="/governments" collapsed={collapsed} />

            {selfie.isSiteAdmin && (
              <>
                <SubMenuItem title="New" href="/governments/new" collapsed={collapsed} />
                <SubMenuItem title="Users" href="/users?tab=governments" collapsed={collapsed} />
                <SubMenuItem
                  title="Project Verification"
                  href="/governments/projectVerification"
                  collapsed={collapsed}
                />
                <SubMenuItem title="Upload" href="/governments/upload" collapsed={collapsed} />
              </>
            )}
          </SubMenu>
        )}

        <SubMenu key="vendors-sub-menu" icon={<VendorsIcon collapsed={collapsed} />} title={collapsed ? "" : "Vendors"}>
          {collapsed && <PopoverTitle title="Vendors" />}
          <SubMenuItem title="All" href="/vendors" collapsed={collapsed} />
          <SubMenuItem title="New" href="/vendors/new" collapsed={collapsed} />
          <SubMenuItem title="Users" href="/users?tab=vendors" collapsed={collapsed} />
        </SubMenu>

        <SubMenu
          key="products-sub-menu"
          icon={<ProductsIcon collapsed={collapsed} />}
          title={collapsed ? "" : "Products"}
        >
          {collapsed && <PopoverTitle title="Products" />}
          <SubMenuItem title="All" href="/products" collapsed={collapsed} />
          <SubMenuItem title="New" href="/products/new" collapsed={collapsed} />

          {selfie && selfie.isSiteAdmin && (
            <SubMenuItem title="Invitations" href="/products/invitations" collapsed={collapsed} />
          )}

          {selfie && selfie.isSiteAdmin && (
            <SubMenuItem title="Solutions" href="/products/solutions" collapsed={collapsed} />
          )}

          <SubMenuItem title="Suggestions" href="/suggestions" collapsed={collapsed}>
            <SuggestionsCount>
              {({ pendingCount }) => (
                <Div
                  display="inline"
                  position="relative"
                  css={{
                    height: "50%",
                  }}
                >
                  {pendingCount > 0 && (
                    <Span
                      position="absolute"
                      top={-10}
                      right={-20}
                      borderRadius="100%"
                      backgroundColor="#fe442f"
                      color={palette.white}
                      width={20}
                      height={20}
                      fontSize={10}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {pendingCount}
                    </Span>
                  )}
                </Div>
              )}
            </SuggestionsCount>
          </SubMenuItem>
        </SubMenu>

        {selfie && selfie.isSiteAdmin && (
          <SubMenu
            key="reports-sub-menu"
            icon={<ReportsIcon collapsed={collapsed} />}
            title={collapsed ? "" : "Reports"}
          >
            {collapsed && <PopoverTitle title="Reports" />}
            <SubMenuItem title="All" href="/reports" collapsed={collapsed} />
            <SubMenuItem title="New" href="/reports/new" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu
            key="stories-sub-menu"
            icon={<StoriesIcon collapsed={collapsed} />}
            title={collapsed ? "" : "Stories"}
          >
            {collapsed && <PopoverTitle title="Stories" />}
            <SubMenuItem title="All" href="/stories" collapsed={collapsed} />
            <SubMenuItem title="New" href="/stories/compose" collapsed={collapsed} />
          </SubMenu>
        )}

        <SubMenu
          key="projects-sub-menu"
          icon={<ProjectsIcon collapsed={collapsed} />}
          title={collapsed ? "" : "Projects"}
        >
          {collapsed && <PopoverTitle title="Projects" />}
          <SubMenuItem title="All" href="/projects" collapsed={collapsed} />
          <SubMenuItem title="New" href="/projects/New" collapsed={collapsed} />
          <SubMenuItem title="Bulk Tagging" href="/projects/bulkActions" collapsed={collapsed} />
        </SubMenu>

        {selfie && selfie.isSiteAdmin && (
          <SubMenu key="groups-sub-menu" icon={<GroupsIcon collapsed={collapsed} />} title={collapsed ? "" : "Groups"}>
            {collapsed && <PopoverTitle title="Groups" />}
            <SubMenuItem title="All" href="/groups" collapsed={collapsed} />
            <SubMenuItem title="New" href="/groups/new" collapsed={collapsed} />
            <SubMenuItem title="Invitations" href="/groups/invitations" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu key="posts-sub-menu" icon={<PostsIcon collapsed={collapsed} />} title={collapsed ? "" : "Posts"}>
            {collapsed && <PopoverTitle title="Posts" />}
            <SubMenuItem title="All" href="/posts" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu key="tags-sub-menu" icon={<TagsIcon collapsed={collapsed} />} title={collapsed ? "" : "Tags"}>
            {collapsed && <PopoverTitle title="Tags" />}
            <SubMenuItem title="All" href="/tags" collapsed={collapsed} />
            <SubMenuItem title="New" href="/tags/new" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu
            key="collections-sub-menu"
            icon={<CollectionsIcon collapsed={collapsed} />}
            title={collapsed ? "" : "Collections"}
          >
            {collapsed && <PopoverTitle title="Collections" />}
            <SubMenuItem title="All" href="/collections" collapsed={collapsed} />
            <SubMenuItem title="New" href="/collections/new" collapsed={collapsed} />
            <SubMenuItem title="Sponsorships" href="/collections/sponsorships" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu
            key="billing-sub-menu"
            icon={<BillingIcon collapsed={collapsed} />}
            title={collapsed ? "" : "Billing"}
          >
            {collapsed && <PopoverTitle title="Billing" />}
            <SubMenuItem title="Plans" href="/billing/plans" collapsed={collapsed} />
            <SubMenuItem title="New" href="/billing/plans/new" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu
            key="external-links-sub-menu"
            icon={<ExternalLinksIcon collapsed={collapsed} />}
            title={collapsed ? "" : "External Links"}
          >
            {collapsed && <PopoverTitle title="External Links" />}
            <SubMenuItem title="External Links" href="/externalLinks" collapsed={collapsed} />
            <SubMenuItem title="New" href="/externalLinks/new" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu
            key="events-links-sub-menu"
            icon={<EventsIcon collapsed={collapsed} />}
            title={collapsed ? "" : "Events"}
          >
            {collapsed && <PopoverTitle title="Events" />}
            <SubMenuItem title="Events" href="/events" collapsed={collapsed} />
            <SubMenuItem title="New" href="/events/new" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu
            key="landing-page-sub-menu"
            icon={<LandingPageIcon collapsed={collapsed} />}
            title={collapsed ? "" : "Landing Page"}
          >
            {collapsed && <PopoverTitle title="Landing Page" />}
            <SubMenuItem title="Templates" href="/landingPage" collapsed={collapsed} />
            <SubMenuItem title="New" href="/landingPage/new" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu
            key="about-page-sub-menu"
            icon={<AboutPageIcon collapsed={collapsed} />}
            title={collapsed ? "" : "About Page"}
          >
            {collapsed && <PopoverTitle title="About Page" />}
            <SubMenuItem title="Templates" href="/aboutPage" collapsed={collapsed} />
            <SubMenuItem title="New" href="/aboutPage/new" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu
            key="sponsorships-sub-menu"
            icon={<SponsorshipIcon collapsed={collapsed} />}
            title={collapsed ? "" : "Sponsorships"}
          >
            {collapsed && <PopoverTitle title="Sponsorships" />}
            <SubMenuItem collapsed={collapsed} title="All" href="/sponsorships" />
            <SubMenuItem collapsed={collapsed} title="New" href="/sponsorships/new" />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <MainMenuItem
            collapsed={collapsed}
            icon={<PodcastIcon collapsed={collapsed} />}
            title="Podcast Page"
            href="/podcast"
          />
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu
            key="capmaign-sub-menu"
            icon={<CampaingsIcon collapsed={collapsed} />}
            title={collapsed ? "" : "Campaigns"}
          >
            {collapsed && <PopoverTitle title="Campaigns" />}
            <SubMenuItem title="List" href="/campaigns/list" collapsed={collapsed} />
            <SubMenuItem title="New" href="/campaigns/new" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu
            key="newsletter-sub-menu-item"
            icon={<NewsletterIcon collapsed={collapsed} />}
            title={collapsed ? "" : "Newsletter"}
          >
            {collapsed && <PopoverTitle title="Newsletter" />}
            <SubMenuItem title="Receivers" href="/newsletter/receivers" collapsed={collapsed} />
            <SubMenuItem title="Settings" href="/newsletter/settings" collapsed={collapsed} />
            <SubMenuItem title="Timezones" href="/newsletter/timezones" collapsed={collapsed} />
            <SubMenuItem title="New Timezone" href="/newsletter/timezones/new" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <SubMenu
            key="images-sub-menu-item"
            title={collapsed ? "" : "Images"}
            icon={<ImagesIcon collapsed={collapsed} />}
          >
            {collapsed && <PopoverTitle title="Images" />}
            <SubMenuItem title="All Images" href="/images" collapsed={collapsed} />
          </SubMenu>
        )}

        {selfie && selfie.isSiteAdmin && (
          <MainMenuItem
            collapsed={collapsed}
            icon={<SettingsIcon collapsed={collapsed} />}
            title="Global Settings"
            href="/globalSettings"
          />
        )}

        {selfie &&
          selfie.isSiteAdmin &&
          (process.env.GOVLAUNCH_ENV == "development" || process.env.GOVLAUNCH_ENV === "qa") && (
            <MainMenuItem
              collapsed={collapsed}
              icon={<ScheduleIcon collapsed={collapsed} />}
              title="Scheduled Jobs"
              href="/scheduledJobs"
            />
          )}
        {/* Ugly way to hide the submenu popover */}
        <Menu.Item
          key="hiddenMenuItem"
          id="hiddenMenuItem"
          css={{
            height: "0px !important",
          }}
        />
      </Menu>
    </Sider>
  );
}
