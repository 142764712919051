import React from "react";

interface IFieldValueProps {
  value: string | null | undefined;
  display?: string;
  isUrl?: boolean;
}

export function FieldValue({ value, display, isUrl }: IFieldValueProps) {
  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        marginBottom: 10,
      }}
    >
      <span
        css={{
          width: "100%",
          height: 42,
          padding: "6px 0px",
          fontSize: 14,
          lineHeight: 1.333,
          outline: 0,
          display: display || "block",
        }}
      >
        {typeof isUrl !== undefined && isUrl ? (
          typeof value !== undefined && value ? (
            <a href={value} target="_blank" rel="noopener noreferrer">
              {value}
            </a>
          ) : (
            "N/A"
          )
        ) : typeof value !== undefined && value ? (
          value
        ) : (
          "N/A"
        )}
      </span>
    </div>
  );
}
