import React from "react";
import { ILandingPageConfig } from "../../../../types/types";
import TableRowActions from "../../../components/table/TableRowActions";
import DeleteButton from "./DeleteButton";
import DuplicateButton from "./DuplicateButton";
import EditButton from "./EditButton";
import PreviewButton from "./PreviewButton";
import ToggleActivationButton from "./ToggleActivationButton";

interface ILandingPageTemplatesTableActionsProps {
  config: ILandingPageConfig;
  totalCount: number;
}

export default function LandingPageTemplatesTableActions({
  config,
  totalCount,
}: ILandingPageTemplatesTableActionsProps) {
  return (
    <TableRowActions>
      {({ onClick }: any) => (
        <>
          <EditButton configId={config._id} />

          <PreviewButton configId={config._id} />

          <DuplicateButton configId={config._id} onClick={onClick} />

          <ToggleActivationButton configId={config._id} onClick={onClick} active={config.active} />

          {totalCount > 1 && !config.active && <DeleteButton configId={config._id} onClick={onClick} />}
        </>
      )}
    </TableRowActions>
  );
}
