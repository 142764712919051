import React from "react";
import * as palette from "@govlaunch/palette";
import { Section } from "glamorous";
import SubscribedToNewsletter from "./SubscribedToNewsletter";
import NewsletterFrequencyField from "./NewsletterFrequencyField";
import SubscribedToCampaign from "./SubscribedToCampaign";
import { FormSpy } from "react-final-form";

export default function UserEmailSubscriptionSettings() {
  return (
    <div
      css={{
        display: "grid",
        gridRowGap: 24,
      }}
    >
      <Section>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              paddingBottom: 16,
              borderBottom: `1px solid ${palette.lightestGray}`,
            }}
          >
            <label
              css={{
                fontWeight: "bold",
                fontSize: 14,
                color: palette.mediumGray,
                margin: 0,
              }}
              htmlFor="subscribeToNewsletter"
            >
              Get Updates
            </label>
            <SubscribedToNewsletter
              css={{
                alignSelf: "flex-end",
              }}
            />
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              paddingTop: 16,
              paddingBottom: 16,
              borderBottom: `1px solid ${palette.lightestGray}`,
            }}
          >
            <FormSpy
              subscription={{
                values: true,
              }}
            >
              {({ values }) => <NewsletterFrequencyField subscribed={values.subscribedToNewsletter} />}
            </FormSpy>
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              paddingTop: 16,
            }}
          >
            <SubscribedToCampaign />
          </div>
        </div>
      </Section>
    </div>
  );
}
