import React, { FunctionComponent, ReactElement } from "react";

import { useLocation } from "react-router";
import queryString from "query-string";
import TableSearch from "../../components/table/TableSearch";
import ManageLandingPages from "./ManageLandingPages";

export const LandingPagesWithSearch: FunctionComponent = (): ReactElement => {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div
        css={{
          marginBottom: 20,
        }}
      >
        <TableSearch historyOrigin="/landingPage" query={query} inputPlaceholder="Search for templates..." />
      </div>
      <ManageLandingPages search={query.search as string} />
    </>
  );
};
