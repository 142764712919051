import gql from "graphql-tag";

export default gql`
  query BuildNewsletterCampaignData($campaignId: ObjectId!) {
    buildNewsletterCampaignData(campaignId: $campaignId) {
      viewOnlineLink
      totalProjectsCount
      stories {
        title
        introduction
        coverImage {
          url
          type
        }
        podcastUrl
        url
      }
      projects {
        flag
        countryCode
        rawTitle
        url
        title
      }
      collections {
        name
        slug
        url
        tags
        itemsCount
      }
    }
  }
`;
