import ProfilePicture from "@govlaunch/profile-picture";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Select, Tag } from "antd";
import Table, { TablePaginationConfig } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import React from "react";
import { Field } from "react-final-form";
import { Link } from "react-router-dom";
import { TGovernment, TGovernmentMember } from "./types";

interface IEditableGovernmentMembersProps {
  government: TGovernment;
}

export default function EditableGovernmentMembers({ government }: IEditableGovernmentMembersProps) {
  const memberIds = government.members.map(({ _id }) => _id);

  const changeRoles = (members: TGovernmentMember[], member: TGovernmentMember, newRole: "GOV_OWNER" | "GOV_USER") => {
    return members.map((candidate) => {
      if (candidate._id !== member._id && newRole === "GOV_OWNER") {
        return {
          ...candidate,
          role: "GOV_USER",
        };
      }

      if (candidate._id === member._id) {
        return {
          ...candidate,
          role: newRole,
        };
      }

      return candidate;
    });
  };

  return (
    <Field<TGovernmentMember[]> name="members">
      {({ input }) => {
        let paginationConfig: TablePaginationConfig | false = false;

        if (government.members.length > 10) {
          paginationConfig = {
            pageSize: 10,
          };
        }

        return (
          <Table
            dataSource={input.value}
            bordered={true}
            size="middle"
            rowKey="_id"
            pagination={paginationConfig}
            scroll={{
              x: true,
            }}
          >
            <Column<TGovernmentMember>
              title="Name"
              dataIndex="fullName"
              render={(_, member) => (
                <div
                  css={{
                    display: "inline-grid",
                    gridColumnGap: 12,
                    gridTemplateColumns: "repeat(3, max-content)",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture size={28} image={member.avatar} name={member.fullName} />

                  <Link to={`/users/${member.slug}`}>
                    <span>{member.fullName}</span>
                  </Link>

                  {!memberIds.includes(member._id) && <Tag color="magenta">New</Tag>}
                </div>
              )}
            />

            <Column<TGovernmentMember>
              title="Role"
              dataIndex="role"
              width="20%"
              render={(role, member) => {
                return (
                  <Select<"GOV_OWNER" | "GOV_USER">
                    value={role}
                    onChange={(newRole) => {
                      const members = input.value as TGovernmentMember[];
                      input.onChange(changeRoles(members, member, newRole));
                    }}
                  >
                    <Select.Option value="GOV_OWNER">Owner</Select.Option>
                    <Select.Option value="GOV_USER">User</Select.Option>
                  </Select>
                );
              }}
            />

            <Column<TGovernmentMember>
              title="Remove"
              dataIndex="_id"
              width="20%"
              align="center"
              render={(_, member) => {
                return (
                  <Button
                    danger={true}
                    icon={<DeleteOutlined />}
                    shape="round"
                    onClick={() => {
                      const members = input.value as TGovernmentMember[];
                      const filteredMembers = members.filter((candidate) => candidate._id !== member._id);

                      input.onChange(filteredMembers);
                    }}
                  >
                    Remove
                  </Button>
                );
              }}
            />
          </Table>
        );
      }}
    </Field>
  );
}
