import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as palette from "@govlaunch/palette";
import { Spin, notification } from "antd";
import React from "react";
import { Query } from "react-apollo";
import DocumentTitle from "react-document-title";
import { Navigate, useNavigate, useParams } from "react-router";
import ProjectBySlugQuery from "../../../queries/ProjectBySlugQuery";
import {
  IProjectBySlugQuery,
  IProjectBySlugQueryVariables,
} from "../../../queries/__generated__/ProjectBySlugQuery.generated";
import EditProject from "../EditProject";

export default function ProjectBySlug() {
  const navigate = useNavigate();
  const params = useParams();

  if (!params.slug) {
    return <Navigate to="/projects" />;
  }

  return (
    <div
      css={{
        height: "100%",
        background: palette.washGray,
      }}
    >
      <div
        css={{
          padding: 15,
        }}
      >
        <Query<IProjectBySlugQuery, IProjectBySlugQueryVariables>
          query={ProjectBySlugQuery}
          fetchPolicy="network-only"
          variables={{
            slug: params.slug || "",
          }}
        >
          {({ data, loading }) => {
            if (loading) {
              return (
                <div
                  css={{
                    textAlign: "center",
                    paddingTop: 16,
                  }}
                >
                  <Spin size="large" />
                </div>
              );
            }

            if (!data) {
              return <Navigate to="/projects" />;
            }

            const { projectBySlug } = data;

            if (!projectBySlug) {
              return <Navigate to="/projects" />;
            }

            return (
              <DocumentTitle title={`${projectBySlug.title} | Admin`}>
                <div>
                  <div
                    css={{
                      paddingBottom: 8,
                    }}
                  >
                    <a
                      css={{
                        color: palette.primary,
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/projects")}
                    >
                      <span
                        css={{
                          marginRight: 5,
                        }}
                      >
                        <FontAwesomeIcon icon={faLessThan} size="sm" />
                      </span>
                      All Projects
                    </a>
                  </div>

                  <EditProject
                    project={projectBySlug}
                    onSubmit={(savedProject: any) => {
                      notification.success({
                        message: "Project saved!",
                        description: `Project saved successfully!`,
                      });

                      navigate(`/projects?search=${savedProject.title}`);
                    }}
                  />
                </div>
              </DocumentTitle>
            );
          }}
        </Query>
      </div>
    </div>
  );
}
