import React from "react";
import { Query } from "react-apollo";
import fp from "lodash/fp";
import GetSponsorshipsCount from "../../queries/GetSponsorshipsCount";
import {
  ISponsorshipsCountQuery,
  ISponsorshipsCountQueryVariables,
} from "../../queries/__generated__/GetSponsorshipsCount.generated";

export default function SponsorshipsCount({ children }: any) {
  return (
    <Query<ISponsorshipsCountQuery, ISponsorshipsCountQueryVariables> query={GetSponsorshipsCount}>
      {({ data, loading, refetch }) => {
        if (!data) {
          return null;
        }

        return children({
          ...mapCounts(data.sponsorshipsCount, loading),
          refetch,
        });
      }}
    </Query>
  );
}

const mapCounts = (counts: any, loading: boolean) => {
  return fp.mapValues(count => {
    if (typeof count === "number") {
      return count;
    }

    if (loading) {
      return null;
    } else {
      return count;
    }
  })(counts);
};
