import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Typography } from "antd";
import Column from "antd/lib/table/Column";
import moment from "moment";
import React from "react";
import { useQuery } from "react-apollo";
import { useTitle } from "react-use";
import PageIcon from "../../icons/PageIcon";
import AllAboutPageConfigsQuery from "./graphql/queries/AllAboutPageConfigsQuery";
import {
  IAllAboutPageConfigsQuery,
  IAllAboutPageConfigsQueryVariables,
} from "./graphql/queries/__generated__/AllAboutPageConfigsQuery.generated";
import AboutPageConfigsListActions from "./AboutPageConfigsListActions";
import { Link } from "react-router-dom";

type TAboutPageTemplate = NonNullable<IAllAboutPageConfigsQuery["allAboutPageConfigs"]>[0];

export default function AboutPageConfigsList() {
  useTitle("About Page | Admin");

  const { data, loading } = useQuery<IAllAboutPageConfigsQuery, IAllAboutPageConfigsQueryVariables>(
    AllAboutPageConfigsQuery,
  );

  return (
    <>
      <div
        css={{
          padding: 20,
          borderBottom: "1px solid rgb(232, 232, 232)",
        }}
      >
        <h3
          css={{
            fontWeight: 700,
            fontSize: 24,
            margin: 0,
          }}
        >
          <PageIcon icon={faHome} /> About Page
        </h3>

        <p
          css={{
            margin: 0,
          }}
        >
          Templates
        </p>
      </div>

      <div
        css={{
          padding: 16,
        }}
      >
        <Table<TAboutPageTemplate>
          dataSource={data?.allAboutPageConfigs || []}
          loading={loading}
          bordered={true}
          rowKey="_id"
        >
          <Column
            title="Template name"
            dataIndex="name"
            render={(name: string, template: TAboutPageTemplate) => (
              <Link to={`/aboutPage/${template._id}/edit`}>{name}</Link>
            )}
          />
          <Column<IAllAboutPageConfigsQuery["allAboutPageConfigs"][0]>
            title="Active?"
            dataIndex="active"
            render={(active) => (
              <Typography.Text
                css={{
                  color: active ? "green" : "red",
                }}
              >
                {active ? "Yes" : "No"}
              </Typography.Text>
            )}
          />
          <Column dataIndex="createdAt" title="Created At" render={(createdAt) => moment(createdAt).fromNow()} />

          <Column<IAllAboutPageConfigsQuery["allAboutPageConfigs"][0]>
            title="Author"
            dataIndex="createdBy"
            render={(user) => {
              if (user) {
                return user.fullName;
              }

              return "N/A";
            }}
          />

          <Column<IAllAboutPageConfigsQuery["allAboutPageConfigs"][0]>
            title="Actions"
            dataIndex="_id"
            render={(configId, config) => <AboutPageConfigsListActions configId={configId} config={config} />}
          />
        </Table>
      </div>
    </>
  );
}
