import gql from "graphql-tag";

export default gql`
  mutation SetVendorCreditsEligibilityDate($vendorId: ObjectId!, $canEarnCreditsUntil: String!) {
    setVendorCreditsEligibilityDate(vendorId: $vendorId, canEarnCreditsUntil: $canEarnCreditsUntil) {
      _id
      canEarnCreditsUntil
      earnableCreditAmount
    }
  }
`;
