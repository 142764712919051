import gql from "graphql-tag";
import StoryFields from "./fragments/StoryFields";
import GovernmentProjectFields from "./fragments/GovernmentProjectFields";
import GroupProjectFields from "./fragments/GroupProjectFields";
import VendorProjectFields from "./fragments/VendorProjectFields";
import CompanyPlanFields from "./fragments/CompanyPlanFields";
import SolutionFieldsFragment from "../features/solutions/graphql/fragments/SolutionFieldsFragment";

export default gql`
  query Product($slug: String!) {
    product(slug: $slug) {
      _id
      name
      slug
      link
      thumbnail
      description
      about
      status
      logo
      isDisruptor
      canBeDisruptor
      inViewerStack
      viewerDidBookmark
      screenshots {
        url
        alt
      }
      integrations {
        _id
        name
        slug
        logo
      }
      solutions {
        ...SolutionFields
      }
      outsideIntegrations {
        _id
        name
        url
      }
      resources {
        ... on LinkResource {
          url
          title
          addedAt
        }
        ... on DownloadResource {
          url
          title
          filename
          addedAt
        }
        ... on TextResource {
          title
          body
          addedAt
        }
      }
      siblings {
        _id
        name
        slug
        logo
        isDisruptor
        inViewerStack
      }
      newsworthy {
        url
        siteName
        favicon
        title
        description
      }
      tags {
        _id
        name
      }
      company {
        _id
        name
        logo
        slug
        yearFounded
        description
        address
        isDisruptor
        site
        createdAt
        viewerIsMember
        plan {
          ...CompanyPlanFields
        }
      }
      alternatives {
        _id
        name
        description
        logo
        slug
      }

      languagesOffered
      assistiveTechnologiesCompatibility
      releasedYear
      releasedYearTimeAgo
      certifications
      freeTrial {
        linkHref
      }
      humanReadableReleasedYear
      pricingPage
      contactPage
      highlights
      socialMedia {
        linkedin
        twitter
        facebook
        instagram
        youtube
      }
      videos
      openSource {
        linkHref
      }
      deployment {
        __typename
        ... on WebProductDeployment {
          mobileResponsive
          supportedBrowsers
        }
        ... on DesktopProductDeployment {
          platforms
        }
        ... on AppStoreProductDeployment {
          linkHref
        }
        ... on PlayStoreProductDeployment {
          linkHref
        }
      }
      createdAt
      updatedAt
      projects {
        _id
        ...GovernmentProjectFields
        ...GroupProjectFields
        ...VendorProjectFields
      }
      stories {
        _id
        ...StoryFieldsAdmin
      }
      allowRequestTranslation
    }
  }

  ${StoryFields}
  ${GovernmentProjectFields}
  ${GroupProjectFields}
  ${VendorProjectFields}
  ${CompanyPlanFields}
  ${SolutionFieldsFragment}
`;
