import { IBaseTypeaheadItem, SimpleTypeaheadItem, Typeahead, useAlgoliaSearch } from "@govlaunch/web";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import FieldValidationError, { hasFieldValidationError } from "../../../../components/form/FieldValidationError";

interface IGovernmentAlgoliaHit {
  objectID: string;
  name: string;
  logo: string;
}

export default function GovernmentField() {
  const { setSearchState, results, loading } = useAlgoliaSearch<IGovernmentAlgoliaHit>({
    initialSearchState: {
      index: "governments",
    },
  });

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setSearchState((current) => ({
      ...current,
      query: inputValue,
    }));
  }, [inputValue]);

  const items: IBaseTypeaheadItem[] = results
    ? results.hits.slice(0, 5).map((government) => ({
        value: government.objectID,
        label: government.name,
        logo: government.logo || null,
      }))
    : [];

  return (
    <Field name="government" allowNull={true}>
      {({ input, meta }) => {
        return (
          <>
            <Typeahead
              renderItem={SimpleTypeaheadItem}
              items={items}
              onChange={(selectedItem) => input.onChange(selectedItem)}
              selectedItem={input.value}
              selectedItemChanged={(previous, current) => {
                return !isEqual(previous, current);
              }}
              loading={loading}
              menuProps={{
                position: "absolute",
              }}
              inputProps={{
                onBlur: input.onBlur,
                onFocus: input.onFocus,
              }}
              placeholder="Search for a Government..."
              onInputValueChange={setInputValue}
            />

            {hasFieldValidationError(meta) && (
              <div
                css={{
                  marginTop: 10,
                }}
              >
                <FieldValidationError meta={meta} />
              </div>
            )}
          </>
        );
      }}
    </Field>
  );
}
