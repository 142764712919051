import React, { useState } from "react";
import { useQuery } from "react-apollo";
import TrendingGovernments from "../../../../queries/TrendingGovernments";
import {
  ITrendingGovernmentsQuery,
  ITrendingGovernmentsQueryVariables,
} from "../../../../queries/__generated__/TrendingGovernments.generated";
import BlockLayoutContainer from "../../blockslayout/BlockLayoutContainer";
import BlockLayoutHeader from "../../blockslayout/BlockLayoutHeader";
import { IBlockLayout, IGovernmentsBlockData, TBlockLayoutData } from "../blockProps";
import CenteredLoadingSpinner from "../CenteredLoadingSpinner";
import * as palette from "@govlaunch/palette";
import {
  ITopInnovatorGovernmentsQuery,
  ITopInnovatorGovernmentsQueryVariables,
} from "../../../../queries/__generated__/TopInnovatorGovernments.generated";
import TopInnovatorGovernments from "../../../../queries/TopInnovatorGovernments";
import { Avatar, Box, CountryFlag, Flex, SimpleGrid, TCountryCode } from "@govlaunch/web";
import FollowGovernmentIcon from "../../../../components/icons/FollowGovernmentIcon";
import BlockLayoutHeaderSettings from "../../blockslayout/BlockLayoutHeaderSettings";

interface ITrendingGovernmentsBlockProps {
  block: IBlockLayout;
  onRemoveBlock: () => void;
  saveBlockData: (block: IBlockLayout, blockData: TBlockLayoutData) => void;
}

export default function TrendingGovernmentsBlock({
  block,
  onRemoveBlock,
  saveBlockData,
}: ITrendingGovernmentsBlockProps) {
  return (
    <BlockLayoutContainer block={block}>
      <BlockLayoutHeader title="Trending/Innovator Governments" block={block} onRemoveBlock={onRemoveBlock}>
        <BlockLayoutHeaderSettings block={block} saveBlockData={saveBlockData} />
      </BlockLayoutHeader>
      <GovernmentsBlock blockData={block.blockData as IGovernmentsBlockData} />
    </BlockLayoutContainer>
  );
}

interface IGovernmentsBlockProps {
  blockData: IGovernmentsBlockData;
}

enum GovernmentsTab {
  TRENDING = "TRENDING",
  INNOVATORS = "INNOVATORS",
}

function GovernmentsBlock({ blockData }: IGovernmentsBlockProps) {
  const [tab, setTab] = useState<GovernmentsTab>(GovernmentsTab.TRENDING);

  return (
    <Flex width="100%" px="8px" flexDirection="column">
      <SimpleGrid
        width="100%"
        columns={2}
        fontSize="24px"
        fontWeight={600}
        lineHeight="32px"
        alignItems="center"
        justifyItems="center"
      >
        <Box
          width="100%"
          cursor="pointer"
          onClick={() => setTab(GovernmentsTab.TRENDING)}
          pb="6px"
          borderBottom={tab === GovernmentsTab.TRENDING ? `2px solid ${palette.primary}` : "1px solid #ADB6C3"}
          textAlign="center"
        >
          {blockData.title}
        </Box>
        <Box
          width="100%"
          cursor="pointer"
          onClick={() => setTab(GovernmentsTab.INNOVATORS)}
          pb="6px"
          borderBottom={tab === GovernmentsTab.INNOVATORS ? `2px solid ${palette.primary}` : "1px solid #ADB6C3"}
          textAlign="center"
        >
          {blockData.secondTitle}
        </Box>
      </SimpleGrid>
      <Box mt="40px" width="100%" px="12px">
        {tab === GovernmentsTab.TRENDING && <TrendingGovernmentsTab blockData={blockData} />}
        {tab === GovernmentsTab.INNOVATORS && <InnovatorGovernmentsTab blockData={blockData} />}
      </Box>
    </Flex>
  );
}

function TrendingGovernmentsTab({ blockData }: IGovernmentsBlockProps) {
  const { data, loading } = useQuery<ITrendingGovernmentsQuery, ITrendingGovernmentsQueryVariables>(
    TrendingGovernments,
    {
      variables: {
        limit: blockData.itemLimit || 10,
      },
      fetchPolicy: "network-only",
    },
  );

  if (loading || !data) {
    return <CenteredLoadingSpinner />;
  }

  const governments = data && data.trendingGovernments ? data.trendingGovernments || [] : [];

  return (
    <SimpleGrid width="100%" height="100%" columns={1} alignItems="center" spacing="20px">
      {governments.map((government) => (
        <div key={government._id}>
          <GovernmentRow government={government} />
        </div>
      ))}
    </SimpleGrid>
  );
}

function InnovatorGovernmentsTab({ blockData }: IGovernmentsBlockProps) {
  const { data, loading } = useQuery<ITopInnovatorGovernmentsQuery, ITopInnovatorGovernmentsQueryVariables>(
    TopInnovatorGovernments,
    {
      variables: {
        limit: blockData.itemLimit || 10,
      },
      fetchPolicy: "network-only",
    },
  );

  if (loading || !data) {
    return <CenteredLoadingSpinner />;
  }

  const governments = data && data.topInnovatorGovernments ? data.topInnovatorGovernments || [] : [];

  return (
    <SimpleGrid width="100%" height="100%" columns={1} alignItems="center" spacing="20px">
      {governments.map((government) => (
        <div key={government._id}>
          <GovernmentRow government={government} />
        </div>
      ))}
    </SimpleGrid>
  );
}

interface IGovernmentRowProps {
  government: NonNullable<ITrendingGovernmentsQuery["trendingGovernments"]>[0];
}

function GovernmentRow({ government }: IGovernmentRowProps) {
  return (
    <SimpleGrid width="100%" columns={1} cursor="pointer">
      <Flex flexDirection="row" alignItems="center">
        <Flex flexDirection="row" alignItems="center">
          <Avatar size="sm" name={government.name} src={government.logo} />
          <Flex flexDirection="column" ml="8px">
            <Box fontSize="16px" fontWeight={600} lineHeight="21px">
              {government.name}
            </Box>
            <Flex flexDirection="row" mt="4px">
              <CountryFlag width={20} height={12} countryCode={government.city.country as TCountryCode} />
              <Box fontSize="12px" fontWeight={400} lineHeight="16px" color="blue.600" ml="4px">
                {getCountryFriendlyName(government.city.country)}
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection="row" alignItems="center" justifyContent="center" ml="auto">
          <FollowGovernmentIcon />
        </Flex>
      </Flex>
    </SimpleGrid>
  );
}

function getCountryFriendlyName(country: string) {
  switch (country) {
    case "US":
      return "United States";
    case "CA":
      return "Canada";
    case "NZ":
      return "New Zealand";
    case "AU":
      return "Australia";
    case "ZA":
      return "South Africa";
    case "GB":
      return "Great Britain";
    case "IE":
      return "Ireland";
    case "FI":
      return "Finland";
    case "SE":
      return "Sweden";
    case "BR":
      return "Brazil";
    case "MX":
      return "Mexico";
    case "AR":
      return "Argentina";
    case "DE":
      return "Germany";
    case "DK":
      return "Denmark";
    case "IT":
      return "Italy";
    case "NL":
      return "Netherlands";
    case "AT":
      return "Austria";
    case "BE":
      return "Belgium";
    case "LI":
      return "Lithuania";
    case "ES":
      return "Spain";
    case "FR":
      return "France";
    case "CH":
      return "Switzerland";
  }
}
