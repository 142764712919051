import gql from "graphql-tag";
import LandingPageEventFields from "./fragments/LandingPageEventFields";

export default gql`
  query GetLandingPageEvents($search: String, $limit: Int = 20, $cursor: Cursor, $sort: FieldOrderSortInput) {
    landingPageEvents(search: $search, limit: $limit, cursor: $cursor, sort: $sort) {
      items {
        ...LandingPageEventFields
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }

  ${LandingPageEventFields}
`;
