import PropTypes from "prop-types";
import { Query } from "react-apollo";
import React, { useContext } from "react";
import { Value } from "react-powerplug";
import merge from "lodash/fp/merge";
import noop from "lodash/fp/noop";
import SelfieQuery from "../../queries/SelfieQuery";
import { ISelfieQuery, ISelfieQueryVariables } from "../../queries/__generated__/SelfieQuery.generated";

type TUser = NonNullable<ISelfieQuery["selfie"]>;

export const SelfieContext = React.createContext<{
  loading: boolean;
  user: TUser | null;
  load: (user: TUser | null) => void;
}>({
  loading: false,
  user: null,
  load: noop,
});

interface ISelfieProviderProps {
  children: any;
}

export function SelfieProvider({ children }: ISelfieProviderProps) {
  return (
    <Query<ISelfieQuery, ISelfieQueryVariables> query={SelfieQuery}>
      {({ data, loading }) => {
        if (loading || !data) {
          return null;
        }

        return (
          <Value<TUser | null> initial={null}>
            {({ value, set }) => (
              <SelfieContext.Provider
                value={{
                  loading,
                  user: data.selfie ? merge(data.selfie, value) : value,
                  load: user => set(user),
                }}
              >
                {children}
              </SelfieContext.Provider>
            )}
          </Value>
        );
      }}
    </Query>
  );
}

SelfieProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const Selfie = SelfieContext.Consumer;

export function useSelfie() {
  const { user } = useContext(SelfieContext);
  return user;
}

export function useSafeSelfie() {
  const { user } = useContext(SelfieContext);
  if (!user) {
    throw new Error("Couldn't locate an authenticated user");
  }
  return user;
}
