import React from "react";
import { useQuery } from "react-apollo";
import DocumentTitle from "react-document-title";
import { useParams } from "react-router";
import GetAboutPageByIdQuery from "./graphql/queries/GetAboutPageByIdQuery";
import {
  IGetAboutPageByIdQuery,
  IGetAboutPageByIdQueryVariables,
} from "./graphql/queries/__generated__/GetAboutPageByIdQuery.generated";
import * as palette from "@govlaunch/palette";
import { LoadingIndicator } from "@govlaunch/core";
import EditAboutPageConfigForm from "./EditAboutPageConfigForm";

export default function EditAboutPageConfigPage() {
  const params = useParams();

  const { data, loading } = useQuery<IGetAboutPageByIdQuery, IGetAboutPageByIdQueryVariables>(GetAboutPageByIdQuery, {
    variables: {
      configId: params.configId,
    },
  });

  return (
    <DocumentTitle title="About Page Edit | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
        }}
      >
        {loading && (
          <div>
            <LoadingIndicator />
          </div>
        )}

        {(!data || !data.getAboutPageById) && !loading && <div>About page template not found</div>}

        {data && data.getAboutPageById && <EditAboutPageConfigForm aboutPageConfig={data.getAboutPageById} />}
      </div>
    </DocumentTitle>
  );
}
