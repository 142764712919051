import { Table, TableProps, Typography } from "antd";
import React from "react";
import { IGetAllLandingPagesQuery } from "../../queries/__generated__/GetAllLandingPages.generated";
import * as palette from "@govlaunch/palette";
import moment from "moment";
import { ILandingPageConfig, ILandingPageConfigBlock } from "../../../types/types";
import { getBlockTitle } from "./blocks/blockProps";
import { Link } from "react-router-dom";
import LandingPageTemplatesTableActions from "./tableactions/LandingPageTemplatesTableActions";
import { uniqBy } from "lodash/fp";

type TLandingPageTemplate = NonNullable<NonNullable<IGetAllLandingPagesQuery["allLandingPageConfigs"]>["items"]>[0];

interface ILandingPageTemplatesTableProps {
  templates: TLandingPageTemplate[];
  loading?: boolean;
  footer?: TableProps<any>["footer"];
}

export default function LandingPageTemplatesTable({ templates, loading, footer }: ILandingPageTemplatesTableProps) {
  return (
    <Table<TLandingPageTemplate>
      dataSource={templates}
      bordered={true}
      rowKey="_id"
      loading={loading}
      pagination={false}
      style={{
        backgroundColor: palette.white,
      }}
      scroll={{
        x: true,
      }}
      footer={footer}
      columns={[
        {
          title: "Name",
          dataIndex: "name",
          render: (_, template) => <Link to={`/landingPage/${template._id}`}>{template.name}</Link>,
        },
        {
          title: "Active?",
          dataIndex: "active",
          width: "100px",
          render: (active) => (
            <Typography.Text
              css={{
                color: active ? "green" : "red",
              }}
            >
              {active ? "Yes" : "No"}
            </Typography.Text>
          ),
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          render: (createdAt) => moment(createdAt).format("MM/DD/YYYY"),
        },
        {
          title: "Author",
          dataIndex: "createdBy",
          render: (user) => {
            if (user) {
              return user.fullName;
            }

            return "N/A";
          },
        },
        {
          title: "Updated At",
          dataIndex: "updatedAt",
          render: (updatedAt) => moment(updatedAt).format("MM/DD/YYYY"),
        },
        {
          title: "Blocks",
          dataIndex: "blocks",
          render: (blocks: ILandingPageConfigBlock[]) => {
            return (
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {uniqBy((block) => block.blockData.type, blocks)
                  .slice(0, 6)
                  .map((block) => (
                    <div
                      key={block.i}
                      css={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "5px 10px 10px 5px",
                        border: `1px solid ${palette.lightGray}`,
                        borderRadius: 2,
                        "&:not(:first-of-type)": {
                          marginLeft: 4,
                        },
                      }}
                    >
                      {getBlockTitle(block.blockData.type)}
                    </div>
                  ))}
                {blocks.length > 6 && (
                  <div
                    css={{
                      "&:not(:first-of-type)": {
                        marginLeft: 4,
                      },
                      color: palette.primary,
                      fontWeight: 600,
                    }}
                  >
                    +{blocks.length - 6}
                  </div>
                )}
              </div>
            );
          },
        },
        {
          title: "Actions",
          render: (_, template) => {
            return (
              <LandingPageTemplatesTableActions config={template as ILandingPageConfig} totalCount={templates.length} />
            );
          },
        },
      ]}
    />
  );
}
