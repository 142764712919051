import { AlgoliaContext } from "@govlaunch/web";
import React from "react";
import { ApolloProvider, useMutation } from "react-apollo";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import algoliaKeys from "./algolia/algoliaKeys";
import apolloClient from "./apolloClient";
import AuthenticationToken from "./AuthenticationToken";
import Dashboard from "./Dashboard";
import { Selfie, SelfieProvider } from "./features/auth/Selfie";
import Login from "./Login";
import AdminSignIn from "./mutations/AdminSignIn";
import { IAdminSignInMutation, IAdminSignInMutationVariables } from "./mutations/__generated__/AdminSignIn.generated";
import Noop from "./Noop";
import OAuth, { IOAuthResponseData } from "./OAuth";
import Playground from "./Playground";

const messages = {};

const Admin = () => (
  <AlgoliaContext.Provider
    value={{
      appId: algoliaKeys.appId,
      apiKey: algoliaKeys.apiKey,
      environment: process.env.ALGOLIA_ENV || process.env.GOVLAUNCH_ENV || "development",
    }}
  >
    <ApolloProvider client={apolloClient}>
      <IntlProvider locale="en" defaultLocale="en" messages={messages}>
        <BrowserRouter>
          <AuthenticationToken>
            {({ set: setToken, has: hasToken, drop: dropToken }) => {
              if (hasToken) {
                return (
                  <SelfieProvider>
                    <Routes>
                      <Route path="/logout" element={<LogoutRouteBody dropToken={dropToken} />} />
                      <Route path="/playground" element={<Playground />} />
                      <Route path="/*" element={<Dashboard />} />
                    </Routes>
                  </SelfieProvider>
                );
              }

              return (
                <Routes>
                  <Route path="/noop" element={<Noop />} />
                  <Route path="/" element={<OAuthMutation setToken={setToken} />} />
                </Routes>
              );
            }}
          </AuthenticationToken>
        </BrowserRouter>
      </IntlProvider>
    </ApolloProvider>
  </AlgoliaContext.Provider>
);

function LogoutRouteBody({ dropToken }: any) {
  setTimeout(dropToken);

  return (
    <Selfie>
      {({ load }) => {
        load(null);
        return <Navigate to="/" />;
      }}
    </Selfie>
  );
}

interface IOauthMutationProps {
  setToken: (token: string) => any;
}

function OAuthMutation({ setToken }: IOauthMutationProps) {
  const [adminSignIn, { error: apiError }] = useMutation<IAdminSignInMutation, IAdminSignInMutationVariables>(
    AdminSignIn,
    {
      onCompleted: ({ adminSignIn: token }: any) => {
        if (token) {
          setToken(token);
        }
      },
    },
  );

  return (
    <OAuth
      url={process.env.ADMIN_OAUTH_URL || ""}
      onSuccess={(payload: IOAuthResponseData) => {
        adminSignIn({
          variables: {
            oauthToken: payload.token,
          },
        });
      }}
    >
      {({ start, error: oauthError, loading }: any) => (
        <Login onLogin={start} loggingIn={loading} error={oauthError || apiError} />
      )}
    </OAuth>
  );
}

export default Admin;
