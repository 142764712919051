import React from "react";
import { IVendorQuery } from "../../../queries/__generated__/VendorQuery.generated";
import ManualSubscription from "./ManualSubscription";

interface IVendorSubscriptionTabProps {
  vendor: NonNullable<IVendorQuery["company"]>;
  refetch: () => void;
}

export default function VendorSubscriptionTab({ vendor, refetch }: IVendorSubscriptionTabProps) {
  return (
    <div
      css={{
        paddingTop: 20,
      }}
    >
      <ManualSubscription vendor={vendor} refetch={refetch} />
    </div>
  );
}
