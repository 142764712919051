import { DeleteOutlined } from "@ant-design/icons";
import ProfilePicture from "@govlaunch/profile-picture";
import { Button, Tag } from "antd";
import Table, { TablePaginationConfig } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import React from "react";
import { Field } from "react-final-form";
import { Link } from "react-router-dom";
import { TGroup, TGroupMember } from "./types";

interface IEditableGroupMembersProps {
  group: TGroup;
}

export default function EditableGroupMembers({ group }: IEditableGroupMembersProps) {
  const members = group.members.items || [];
  const memberIds = (group.members.items || []).map(({ _id }) => _id);

  return (
    <Field<TGroupMember[]> name="members">
      {({ input }) => {
        let paginationConfig: TablePaginationConfig | false = false;

        if (members.length > 10) {
          paginationConfig = {
            pageSize: 10,
          };
        }

        return (
          <Table dataSource={input.value} bordered={true} size="middle" rowKey="_id" pagination={paginationConfig}>
            <Column<TGroupMember>
              title="Name"
              dataIndex="fullName"
              render={(_, member) => (
                <div
                  css={{
                    display: "inline-grid",
                    gridColumnGap: 12,
                    gridTemplateColumns: "repeat(3, max-content)",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture size={28} image={member.avatar} name={member.fullName} />

                  <Link to={`/users/${member.slug}`}>
                    <span>{member.fullName}</span>
                  </Link>

                  {!memberIds.includes(member._id) && <Tag color="magenta">New</Tag>}
                </div>
              )}
            />

            <Column<TGroupMember>
              title="Remove"
              dataIndex="_id"
              width="20%"
              align="center"
              render={(_, member) => {
                return (
                  <Button
                    danger={true}
                    icon={<DeleteOutlined />}
                    shape="round"
                    onClick={() => {
                      const members = input.value as TGroupMember[];
                      const filteredMembers = members.filter((candidate) => candidate._id !== member._id);

                      input.onChange(filteredMembers);
                    }}
                  >
                    Remove
                  </Button>
                );
              }}
            />
          </Table>
        );
      }}
    </Field>
  );
}
