import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Modal, notification } from "antd";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Mutation } from "react-apollo";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import EditableGroupVendors from "../EditableGroupVendors";
import EditGroupVendors from "../graphql/mutations/EditGroupVendors";
import {
  IEditGroupVendorsMutation,
  IEditGroupVendorsMutationVariables,
} from "../graphql/mutations/__generated__/EditGroupVendors.generated";
import { TGroup, TGroupVendor } from "../types";
import VendorAutoComplete from "../VendorAutoComplete";

interface IEditGroupVendorsModalProps {
  visible: boolean;
  group: TGroup;
  displayBackButton?: boolean;
  onSaveChanges?: () => void;
  onRequestClose?: () => void;
  onRequestBack?: () => void;
}

export default function EditGroupVendorsModal({
  visible,
  group,
  displayBackButton,
  onSaveChanges,
  onRequestClose,
  onRequestBack,
}: IEditGroupVendorsModalProps) {
  const linkToGroup = (
    <Link to={`/groups/${group.slug}`}>
      <strong>{group.name}</strong>
    </Link>
  );

  const title = <>Manage {linkToGroup} vendors</>;

  return (
    <Mutation<IEditGroupVendorsMutation, IEditGroupVendorsMutationVariables>
      mutation={EditGroupVendors}
      refetchQueries={[]}
      awaitRefetchQueries={true}
    >
      {(editGroupVendors) => (
        <Form
          initialValues={{
            vendors: group.vendors,
          }}
          onSubmit={async ({ vendors }) => {
            try {
              await editGroupVendors({
                variables: {
                  groupId: group._id,
                  vendors: vendors.map((vendor: any) => vendor._id),
                },
              });

              if (onSaveChanges) {
                onSaveChanges();
              }

              notification.open({
                type: "success",
                message: "Success!",
                description: <>Vendors of <strong>{group.name}</strong> has been modified</>,
              });
            } catch (err) {
              return {
                [FORM_ERROR]: err.message,
              };
            }
          }}
        >
          {({ submitting, submitError, handleSubmit }) => (
            <Modal width={720} title={title} visible={visible} onCancel={onRequestClose} footer={null}>
              <form
                onSubmit={handleSubmit}
                css={{
                  display: "grid",
                  gridRowGap: 24,
                }}
              >
                <Field<TGroupVendor[]> name="vendors">
                  {({ input }) => (
                    <VendorAutoComplete
                      onSelect={(selectedItem) => {
                        const alreadyInVendors = input.value.some((candidate) => {
                          return candidate._id === selectedItem._id;
                        });

                        if (!alreadyInVendors) {
                          input.onChange(input.value.concat(selectedItem));
                        }
                      }}
                    />
                  )}
                </Field>

                <EditableGroupVendors group={group} />

                {submitError && (
                  <Alert
                    message={<strong>Couldn't save changes</strong>}
                    description={`There was an error when saving the changes: ${submitError
                      .replace("GraphQL error:", "")
                      .trim()}`}
                    type="error"
                    closable={true}
                  />
                )}

                <div
                  css={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  {displayBackButton ? (
                    <div
                      css={{
                        textAlign: "left",
                      }}
                    >
                      <Button
                        key="submit"
                        type="dashed"
                        size="large"
                        icon={<ArrowLeftOutlined />}
                        onClick={() => {
                          if (onRequestBack) {
                            onRequestBack();
                          } else if (onRequestClose) {
                            onRequestClose();
                          }
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  ) : (
                    <div />
                  )}

                  <div
                    css={{
                      textAlign: "right",
                    }}
                  >
                    <Button
                      key="submit"
                      type="primary"
                      size="large"
                      icon={<SaveOutlined />}
                      htmlType="submit"
                      loading={submitting}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Modal>
          )}
        </Form>
      )}
    </Mutation>
  );
}
