import { Layout } from "antd";
import React, { useState } from "react";
import DashboardMenu from "./features/dashboard/DashboardMenu";
import DashboardHeader from "./features/dashboard/DashboardHeader";
import DashboardContent from "./features/dashboard/DashboardContent";
import useIsDesktop from "./hooks/useIsDesktop";

export default function Dashboard() {
  const isDesktop = useIsDesktop();
  const [collapsed, setCollapsed] = useState(!isDesktop);

  return (
    <Layout
      hasSider={true}
      style={{
        minHeight: "100vh",
      }}
    >
      <DashboardMenu collapsed={collapsed} setCollapsed={setCollapsed} isDesktop={isDesktop} />
      <Layout
        style={{
          background: "#fff",
        }}
      >
        <DashboardHeader isDesktop={isDesktop} />
        <DashboardContent menuCollapsed={collapsed} />
      </Layout>
    </Layout>
  );
}
