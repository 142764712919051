import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { INewsletterFrequency } from "../../../../types/types";
import { Checkbox } from "./SubscribedToNewsletter";
import styled from "@emotion/styled";

interface INewsletterFrequencyProps {
  subscribed: boolean | null;
}

export default function NewsletterFrequencyField({ subscribed }: INewsletterFrequencyProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <label
        css={{
          fontWeight: "bold",
          fontSize: 14,
          color: palette.mediumGray,
          margin: 0,
        }}
      >
        Personal Roundup
      </label>
      <div
        css={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          css={{
            color: palette.darkGray,
          }}
        >
          Frequency
        </div>
        <div
          css={{
            marginLeft: 8,
          }}
        >
          <Field
            name="newsletterFrequency"
            type="radio"
            value={INewsletterFrequency.Weekly}
            component={RadioboxField}
            disabled={!subscribed}
          >
            Weekly
          </Field>
        </div>
        <div
          css={{
            marginLeft: 8,
          }}
        >
          <Field
            name="newsletterFrequency"
            type="radio"
            value={INewsletterFrequency.Biweekly}
            component={RadioboxField}
            disabled={!subscribed}
          >
            Biweekly
          </Field>
        </div>
        <div
          css={{
            marginLeft: 8,
          }}
        >
          <Field
            name="newsletterFrequency"
            type="radio"
            value={INewsletterFrequency.Monthly}
            component={RadioboxField}
            disabled={!subscribed}
          >
            Monthly
          </Field>
        </div>
      </div>
    </div>
  );
}

interface IRadioboxFieldProps {
  input: any;
  meta: any;
  disabled?: boolean;
  children?: any;
}

export function RadioboxField({ input, meta, disabled = false, children }: IRadioboxFieldProps) {
  const hasError = meta.touched && (!!meta.error || !!meta.submitError);
  const Error = styled("span")({
    position: "absolute",
    bottom: 8,
    marginBottom: 3,
    left: 150,
    fontSize: 12,
    color: palette.red,
  });

  return (
    <label
      css={{
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Checkbox type="checkbox" {...input} disabled={disabled} />

      <div
        css={{
          margin: 0,
          marginLeft: 10,
          fontWeight: "normal",
          fontSize: 14,
          marginTop: 1,
        }}
      >
        {children}
      </div>

      {hasError && <Error>{meta.error || meta.submitError}</Error>}
    </label>
  );
}
