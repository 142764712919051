import React from "react";
import { Mutation } from "react-apollo";
import RestoreProjectById from "../../../mutations/RestoreProjectById";
import {
  IRestoreProjectByIdMutation,
  IRestoreProjectByIdMutationVariables,
} from "../../../mutations/__generated__/RestoreProjectById.generated";
import { notification, Popconfirm } from "antd";
import * as palette from "@govlaunch/palette";

interface IRestoreProjectActionProps {
  projectId: any;
}

export default function RestoreProjectAction({ projectId }: IRestoreProjectActionProps) {
  return (
    <Mutation<IRestoreProjectByIdMutation, IRestoreProjectByIdMutationVariables>
      mutation={RestoreProjectById}
      refetchQueries={["SearchProjects", "ProjectsCount"]}
      variables={{
        projectId: projectId,
      }}
    >
      {(restoreProject) => (
        <Popconfirm
          title="Are you sure?"
          onConfirm={() =>
            restoreProject().then(() => {
              notification.open({
                type: "success",
                message: "Project restored!",
                description: `Project has been restored!`,
              });
            })
          }
        >
          <a
            href="#"
            css={{
              color: palette.blue,
            }}
          >
            Restore
          </a>
        </Popconfirm>
      )}
    </Mutation>
  );
}
