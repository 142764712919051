import React, { useState } from "react";
import * as palette from "@govlaunch/palette";
import { Button } from "@govlaunch/core";
import { useToggle } from "react-use";
import ManageCollaboratorsModal from "./ManageCollaboratorsModal";
import { Field } from "react-final-form";
import FieldValidationError from "../../../components/form/FieldValidationError";
import Collaborator from "../rich/Collaborator";
import { IStoryBySlugQuery } from "../../../queries/__generated__/StoryBySlugQuery.generated";

export default function StoryCollaborators({initialCollaborators,}: {
  initialCollaborators: NonNullable<IStoryBySlugQuery["storyBySlug"]>["collaborators"];
}) {
  const [showCollaboratorsModal, toggleCollaboratorsModal] = useToggle(false);
  const [collaborators, setCollaborators] = useState(initialCollaborators);
  const hasCollaborators = (collaborators || []).length > 0;

  return (
    <Field name="collaborators">
      {({ input, meta }) => (
        <>
          <div
            css={{
              height: "100%",
              display: "flex",
              background: "#fff",
              flexDirection: "column",
            }}
          >
            <div
              css={{
                background: "#fff",
                borderRadius: 5,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                css={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  rowGap: 20,
                }}
              >
                {(collaborators || []).map((collaborator) => (
                  <Collaborator collaborator={collaborator} key={collaborator._id} />
                ))}
              </div>

              {!hasCollaborators && (
                <Button
                  size="xsmall"
                  color={palette.primary}
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    toggleCollaboratorsModal();
                  }}
                >
                  Manage Collaborators
                </Button>
              )}

              {hasCollaborators && (
                <Button
                  size="xsmall"
                  color={palette.primary}
                  css={{
                    marginTop: 16,
                    width: 80,
                    alignSelf: "center",
                  }}
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    toggleCollaboratorsModal();
                  }}
                >
                  Edit
                </Button>
              )}

              <FieldValidationError meta={meta} />
            </div>
          </div>

          <ManageCollaboratorsModal
            isOpen={showCollaboratorsModal}
            onRequestClose={toggleCollaboratorsModal}
            collaborators={collaborators}
            onChangeCollaborators={({ collaborators }) => {
              setCollaborators(collaborators);
              input.onChange(collaborators);
              toggleCollaboratorsModal();
            }}
          />
        </>
      )}
    </Field>
  );
}
