import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import { Table } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { IGroupsQuery } from "../../../queries/__generated__/GroupsQuery.generated";
import { Margin } from "../../../spacings";
import notUndefinedOrNull from "../../../utils/notUndefinedOrNull";
import GroupActions from "../GroupActions";
import startCase from "lodash/fp/startCase";
import { TableProps } from "antd/lib/table";
import { IGroupsTableState } from "../tables/ManageGroups";

type TGroup = NonNullable<NonNullable<IGroupsQuery["groups"]>["items"]>[0];

interface IGroupsTableProps {
  archivedOnly: boolean;
  loading: boolean;
  groups: TGroup[];
  onChange?: (pagination: any, filters: any, sorter: any) => any;
  state?: IGroupsTableState;
  noSorters?: boolean;
  title?: TableProps<any>["title"];
  footer?: TableProps<any>["footer"];
}

export default function GroupsTable({
  archivedOnly,
  loading,
  groups,
  onChange,
  state,
  noSorters = false,
  title,
  footer,
}: IGroupsTableProps) {
  return (
    <Table
      style={{
        backgroundColor: "#fff",
      }}
      scroll={{
        x: true,
      }}
      rowKey="_id"
      loading={loading}
      bordered={true}
      pagination={false}
      dataSource={groups}
      onChange={onChange}
      title={title}
      footer={footer}
      columns={[
        {
          key: "name",
          title: "Name",
          dataIndex: "name",
          sorter: !noSorters,
          sortOrder: state && state.sortColumnKey === "name" ? state.sortColumnOrder : undefined,
          render: (_: any, group: TGroup) => (
            <div
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ProfilePicture
                size={32}
                image={group.logo}
                name={group.name}
                css={{
                  whiteSpace: "nowrap",
                }}
              />

              <Margin ml={5}>
                <Link
                  to={`/groups/${group.slug}`}
                  style={{
                    wordBreak: "break-word",
                  }}
                >
                  {group.name}
                </Link>
              </Margin>
            </div>
          ),
        },
        {
          key: "kind",
          title: "Kind",
          dataIndex: "kind",
          render: (kind: string) => startCase((kind || "Not Available").toLowerCase()),
        },
        {
          key: "visibility",
          title: "Visibility",
          dataIndex: "visibility",
          render: (visibility: string) => startCase(visibility.toLowerCase()),
          sorter: !noSorters,
          sortOrder: state && state.sortColumnKey === "visibility" ? state.sortColumnOrder : undefined,
        },
        {
          key: "governments",
          title: "Governments",
          dataIndex: "governments",
          sorter: !noSorters,
          sortOrder: state && state.sortColumnKey === "governments" ? state.sortColumnOrder : undefined,
          render: (governments: TGroup["governments"]) => governments.length,
        },
        {
          key: "vendors",
          title: "Vendors",
          dataIndex: "vendors",
          sorter: !noSorters,
          sortOrder: state && state.sortColumnKey === "vendors" ? state.sortColumnOrder : undefined,
          render: (vendors: any) => vendors.length,
        },

        {
          title: "Owner",
          render: (group: TGroup) => {
            if (!group.owner) {
              return "N/A";
            }

            return (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ProfilePicture
                  size={32}
                  image={group.owner.logo}
                  name={group.owner.name}
                  disruptor={group.owner.isDisruptor}
                  css={{
                    whiteSpace: "nowrap",
                  }}
                />

                <Spacing left={10}>
                  <Link to={`/vendors/${group.owner.slug}`}>{group.owner.name}</Link>
                </Spacing>
              </div>
            );
          },
        },

        {
          title: "Previous Owner",
          render: (group: TGroup) => {
            if (!group.previousOwner) {
              return "N/A";
            }

            return (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ProfilePicture
                  size={32}
                  image={group.previousOwner.logo}
                  name={group.previousOwner.name}
                  disruptor={group.previousOwner.isDisruptor}
                  css={{
                    whiteSpace: "nowrap",
                  }}
                />

                <Spacing left={10}>
                  <Link to={`/vendors/${group.previousOwner.slug}`}>{group.previousOwner.name}</Link>
                </Spacing>
              </div>
            );
          },
        },

        {
          title: "Individual Members",
          dataIndex: "membersCount",
        },
        archivedOnly
          ? {
              title: "Archived At",
              render: (group: TGroup) => {
                return moment(group.deletedAt).format("MM/DD/YYYY");
              },
            }
          : null,
        archivedOnly
          ? {
              title: "Archived By",
              render: (group: TGroup) => {
                if (group.deletedBy) {
                  return <Link to={`/users/${group.deletedBy.slug}`}>{group.deletedBy.fullName}</Link>;
                } else {
                  return "N/A";
                }
              },
            }
          : null,
        {
          title: "Actions",
          dataIndex: "_id",
          render: (_: any, group: TGroup) => <GroupActions group={group} groups={groups} />,
        },
      ].filter(notUndefinedOrNull)}
    />
  );
}
