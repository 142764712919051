import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router";
import TableSearch from "../../../components/table/TableSearch";
import ManageCollections from "./ManageCollections";

interface ICollectionsWithSearchProps {
  tab: string;
  refetchCounters: any;
  historyOrigin?: string;
}

export default function CollectionsWithSearch({
  tab,
  refetchCounters,
  historyOrigin = "/collections",
}: ICollectionsWithSearchProps) {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div css={{
 marginBottom: 20 
}}
      >
        <TableSearch historyOrigin={historyOrigin} query={query} inputPlaceholder="Search for collections..." />
      </div>

      <ManageCollections
        searchText={(query.search as string) || ""}
        tab={tab}
        refetchCounters={refetchCounters}
        historyOrigin={historyOrigin}
      />
    </>
  );
}
