import PropTypes from "prop-types";
import React from "react";
import { Mutation } from "react-apollo";
import { Form, FormProps } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as Yup from "yup";
import EditProjectMutation from "../../mutations/EditProjectMutation";
import {
  IEditProjectMutation,
  IEditProjectMutationVariables,
} from "../../mutations/__generated__/EditProjectMutation.generated";
import { IGovernmentProjectFieldsFragment } from "../../queries/fragments/__generated__/GovernmentProjectFields.generated";
import { IGroupProjectFieldsFragment } from "../../queries/fragments/__generated__/GroupProjectFields.generated";
import { IVendorProjectFieldsFragment } from "../../queries/fragments/__generated__/VendorProjectFields.generated";
import validate from "../../validate";
import ProjectForm from "./ProjectForm";
import convertProjectToVariables from "./utils/convertProjectToVariables";

type TProject = IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment;

interface IEditProjectProps {
  project: TProject;
  onSubmit: (project: TProject) => void;
}

export default function EditProject({ project, onSubmit }: IEditProjectProps & FormProps) {
  const projectSchema = Yup.object().shape({
    title: Yup.string().max(100, "Oops! Your title needs to be more concise.").required("Can't be blank"),
    description: Yup.string().max(500, "Oops! Your description needs to be more concise.").required("Can't be blank"),
    subtitle: Yup.string().max(60, "Oops! Your subtitle needs to be more concise."),
    tags: Yup.array().min(1, "You should select at least 1 tags"),
  });

  return (
    <Mutation<IEditProjectMutation, IEditProjectMutationVariables> mutation={EditProjectMutation}>
      {(editProject) => (
        <Form
          keepDirtyOnReinitialize={true}
          initialValues={convertProjectToInitialValues(project)}
          validate={validate(projectSchema)}
          onSubmit={async (values) => {
            try {
              const editProjectResult = await editProject({
                variables: {
                  projectId: project._id,
                  project: convertProjectToVariables(values),
                  verified: values.verified,
                },
              });

              if (editProjectResult && editProjectResult.data) {
                onSubmit(editProjectResult.data.editProject);
              }
            } catch (e) {
              if (/You can select up to 6 tags/i.test(e.message)) {
                return {
                  [FORM_ERROR]: "You can select up to 6 tags",
                };
              }

              return {
                [FORM_ERROR]: "Couldn't save, try again later.",
              };
            }
          }}
        >
          {({ submitting, handleSubmit, submitError }) => (
            <ProjectForm
              submitting={submitting}
              handleSubmit={handleSubmit}
              submitButtonText="Save"
              titleText="Edit Project"
              withGovernment={false}
              submitError={submitError}
            />
          )}
        </Form>
      )}
    </Mutation>
  );
}

EditProject.propTypes = {
  project: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};

EditProject.defaultProps = {
  onSubmit: null,
};

function convertProjectToInitialValues(
  project: IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment,
) {
  return {
    title: project.title,
    description: project.description,
    subtitle: project.subtitle || "",
    audience: project.audience,
    icon: project.icon,
    newsworthy: project.newsworthy,
    products: project.products,
    outsideProducts: project.outsideProducts,
    tags: project.tags.map((tag) => ({
      value: tag._id,
      label: tag.name,
    })),
    awards: project.awards,
    attachments: project.attachments,
    links: project.links,
    locations: project.locations,
    status: translateProjectStatus(project.status),
    vendors: project.vendors,
    groups: project.groups,
    outcome: translateProjectOutcome(project.outcome),
    outcomeStatement: project.outcomeStatement || "",
    verified: project.verified || false,
  };
}

function translateProjectStatus(projectStatus: string | null) {
  if (!projectStatus) {
    return null;
  } else {
    return PROJECT_STATUSES.find(({ value }) => value === projectStatus) || null;
  }
}

function translateProjectOutcome(projectOutcome: string | null) {
  if (!projectOutcome) {
    return null;
  } else {
    return PROJECT_OUTCOMES.find(({ value }) => value === projectOutcome) || null;
  }
}

const PROJECT_STATUSES = [
  {
    value: "ideation",
    label: "Ideation",
  },
  {
    value: "planning",
    label: "Planning",
  },
  {
    value: "inProgress",
    label: "In Progress",
  },
  {
    value: "complete",
    label: "Complete",
  },
];

const PROJECT_OUTCOMES = [
  {
    value: "success",
    label: "Succeeded",
  },
  {
    value: "failed",
    label: "Failed",
  },
];
