import gql from "graphql-tag";
import LandingPageEventFields from "../queries/fragments/LandingPageEventFields";

export default gql`
  mutation EditLandingPageEvent($id: ObjectId!, $data: LandingPageEventInput!) {
    editLandingPageEvent(id: $id, data: $data) {
      ...LandingPageEventFields
    }
  }

  ${LandingPageEventFields}
`;
