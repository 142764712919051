import { Query } from "react-apollo";
import React from "react";
import fp from "lodash/fp";
import GetProductsCount from "../../queries/GetProductsCountQuery";
import {
  IProductsCountQuery,
  IProductsCountQueryVariables,
} from "../../queries/__generated__/GetProductsCountQuery.generated";

export default function ProductsCount({ children }: any) {
  return (
    <Query<IProductsCountQuery, IProductsCountQueryVariables> query={GetProductsCount}>
      {({ data, loading, refetch }) => {
        if (!data) {
          return null;
        }

        return children({
          ...mapCounts(data.productsCount, loading),
          refetch,
        });
      }}
    </Query>
  );
}

const mapCounts = (counts: any, loading: boolean) => {
  return fp.mapValues(count => {
    if (typeof count === "number") {
      return count;
    }

    if (loading) {
      return null;
    } else {
      return count;
    }
  })(counts);
};
