import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { IMediaType } from "../../../../../types/types";
import StoriesByIds from "../../../../queries/StoriesByIds";
import {
  IStoriesByIdsQuery,
  IStoriesByIdsQueryVariables,
} from "../../../../queries/__generated__/StoriesByIds.generated";
import StoriesAutoCompleteModal from "../../StoriesAutoCompleteModal";
import AddStoryContentButton from "./AddStoryContentButton";
import BlockLayoutContainer from "../../blockslayout/BlockLayoutContainer";
import BlockLayoutHeader from "../../blockslayout/BlockLayoutHeader";
import { IFeaturedStoriesData, IBlockLayout, TBlockLayoutData } from "../blockProps";
import EditFeaturedStoriesContentButton from "./EditFeaturedStoriesContentButton";
import CenteredLoadingSpinner from "../CenteredLoadingSpinner";
import { Box, Flex, Image, SimpleGrid } from "@govlaunch/web";
import BlockContentTitle from "../BlockContentTitle";
import BlockLayoutHeaderSettings from "../../blockslayout/BlockLayoutHeaderSettings";

interface IFeaturedStoriesBlockProps {
  block: IBlockLayout;
  saveBlockData: (block: IBlockLayout, data: TBlockLayoutData) => void;
  onRemoveBlock: () => void;
}

export default function FeaturedStoriesBlock({ block, saveBlockData, onRemoveBlock }: IFeaturedStoriesBlockProps) {
  const [isOpen, setIsOpen] = useState(false);
  const content = block.blockData as IFeaturedStoriesData;

  return (
    <BlockLayoutContainer block={block}>
      <BlockLayoutHeader block={block} onRemoveBlock={onRemoveBlock}>
        <BlockHeaderButtons block={block} saveBlockData={saveBlockData} onClick={() => setIsOpen(true)} />
      </BlockLayoutHeader>
      <div
        css={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {block.blockData["titleVisible"] && <BlockContentTitle block={block} />}
        {content && content.storyIds && content.storyIds.length > 0 && (
          <CurrentFeaturedStories storyIds={content.storyIds} />
        )}
        {(!content || !content.storyIds || content.storyIds.length === 0) && (
          <AddStoryContentButton
            buttonText="Select Stories"
            onClick={() => setIsOpen(true)}
            fullHeight={!content || !content.storyIds || content.storyIds.length === 0}
          />
        )}
        <StoriesAutoCompleteModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          block={block}
          saveBlockData={saveBlockData}
        />
      </div>
    </BlockLayoutContainer>
  );
}

interface IBlockHeaderButtonsProps {
  block: IBlockLayout;
  saveBlockData: (block: IBlockLayout, data: TBlockLayoutData) => void;
  onClick: () => void;
}

function BlockHeaderButtons({ block, saveBlockData, onClick }: IBlockHeaderButtonsProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <EditFeaturedStoriesContentButton onClick={onClick} />
      <BlockLayoutHeaderSettings block={block} saveBlockData={saveBlockData} />
    </div>
  );
}

interface ICurrentFeaturedStoriesProps {
  storyIds: string[];
}

function CurrentFeaturedStories({ storyIds }: ICurrentFeaturedStoriesProps) {
  const { data, loading } = useQuery<IStoriesByIdsQuery, IStoriesByIdsQueryVariables>(StoriesByIds, {
    variables: {
      ids: storyIds,
    },
  });

  if (!data || loading) {
    return <CenteredLoadingSpinner />;
  }

  const stories = data.storiesByIds || [];

  return (
    <SimpleGrid width="100%" columns={1} spacing="40px">
      {stories.map((story, index) => (
        <div key={story._id}>
          <StoryRow story={story} isLast={index === stories.length - 1} />
        </div>
      ))}
    </SimpleGrid>
  );
}

type TStory = NonNullable<IStoriesByIdsQuery["storiesByIds"]>[0];

interface IStoryRowProps {
  story: TStory;
  isLast?: boolean;
}

function StoryRow({ story, isLast = false }: IStoryRowProps) {
  const image = (story.medias || []).find((media) => media.type?.toUpperCase() === IMediaType.Image);
  const tags = story.tags || [];

  return (
    <Flex
      width="100%"
      flexDirection={["column", "column", "row", "row"]}
      borderBottom={isLast ? undefined : "1px solid"}
      borderColor={isLast ? undefined : "gray.100"}
      pb="40px"
      fontFamily="'proxima-nova', Helvetica, Arial, sans-serif"
    >
      <Image
        display={["block", "block", "none", "none"]}
        maxWidth={["100%", "100%", "192px", "192px"]}
        maxHeight={["160px", "160px", "160px", "160px"]}
        objectFit="cover"
        src={image ? image.url : "/static/story/story-card-placeholder.png"}
        alt={image ? image.alt || "" : ""}
      />
      <Flex flexDirection="column" mt={["12px", "12px", "0px", "0px"]} mr={["0px", "0px", "12px", "12px"]}>
        <Box
          fontWeight={[700, 700, 600, 600]}
          fontSize={["18px", "18px", "24px", "24px"]}
          lineHeight={["24px", "24px", "32px", "32px"]}
          color="blue.700"
        >
          {story.title}
        </Box>
        <Box display={["none", "none", "block", "block"]} fontWeight={400} fontSize="14px" lineHeight="20px" mt="6px">
          {story.introduction}
        </Box>
        <Flex flexDirection="row" mt={["12px", "12px", "30px", "30px"]} alignItems="center">
          {tags.slice(0, 2).map((tag) => (
            <div
              key={tag._id}
              css={{
                "&:not(:first-of-type)": {
                  marginLeft: "8px",
                },
              }}
            >
              <StoryRowTag name={tag.name} />
            </div>
          ))}
          {tags.length > 2 && (
            <Box fontWeight={900} fontSize="12px" lineHeight="19px" color="blue.700" ml="12px" py="4px">
              +{tags.length - 2}
            </Box>
          )}
        </Flex>
      </Flex>
      <Image
        ml="auto"
        display={["none", "none", "block", "block"]}
        maxWidth={["84px", "84px", "192px", "192px"]}
        maxHeight={["84px", "84px", "160px", "160px"]}
        objectFit="cover"
        src={image ? image.url : "/static/story/story-card-placeholder.png"}
        alt={image ? image.alt || "" : ""}
      />
    </Flex>
  );
}

interface IStoryRowTagProps {
  name: string;
}

function StoryRowTag({ name }: IStoryRowTagProps) {
  return (
    <Box
      fontWeight={400}
      fontSize="12px"
      lineHeight="16px"
      color="gray.700"
      px="8px"
      py="4px"
      border="1px solid"
      borderColor="gray.300"
      borderRadius={2}
      textTransform="uppercase"
    >
      {name}
    </Box>
  );
}
