import { DeleteOutlined } from "@ant-design/icons";
import ProfilePicture from "@govlaunch/profile-picture";
import { Button, Tag } from "antd";
import Table, { TablePaginationConfig } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import React from "react";
import { Field } from "react-final-form";
import { Link } from "react-router-dom";
import { TGroup, TGroupGovernment } from "./types";

interface IEditableGroupGovernmentsProps {
  group: TGroup;
}

export default function EditableGroupGovernments({ group }: IEditableGroupGovernmentsProps) {
  const governments = group.governments || [];
  const governmentIds = governments.map(({ _id }) => _id);

  return (
    <Field<TGroupGovernment[]> name="governments">
      {({ input }) => {
        let paginationConfig: TablePaginationConfig | false = false;

        if (governments.length > 10) {
          paginationConfig = {
            pageSize: 10,
          };
        }

        return (
          <Table dataSource={input.value} bordered={true} size="middle" rowKey="_id" pagination={paginationConfig}>
            <Column<TGroupGovernment>
              title="Name"
              dataIndex="name"
              render={(_, government) => (
                <div
                  css={{
                    display: "inline-grid",
                    gridColumnGap: 12,
                    gridTemplateColumns: "repeat(3, max-content)",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture size={28} image={government.logo} name={government.name} />

                  <Link to={`/governments/${government.slug}`}>
                    <span>{government.name}</span>
                  </Link>

                  {!governmentIds.includes(government._id) && <Tag color="magenta">New</Tag>}
                </div>
              )}
            />

            <Column<TGroupGovernment>
              title="Remove"
              dataIndex="_id"
              width="20%"
              align="center"
              render={(_, government) => {
                return (
                  <Button
                    danger={true}
                    icon={<DeleteOutlined />}
                    shape="round"
                    onClick={() => {
                      const governments = input.value as TGroupGovernment[];
                      const filtered = governments.filter((candidate) => candidate._id !== government._id);

                      input.onChange(filtered);
                    }}
                  >
                    Remove
                  </Button>
                );
              }}
            />
          </Table>
        );
      }}
    </Field>
  );
}
