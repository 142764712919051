import { CopyOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import DuplicateLandingPageConfig from "../../../mutations/DuplicateLandingPageConfig";
import {
  IDuplicateLandingPageConfigMutation,
  IDuplicateLandingPageConfigMutationVariables,
} from "../../../mutations/__generated__/DuplicateLandingPageConfig.generated";

interface IDuplicateButtonProps {
  configId: string;
  onClick: (args: any) => any;
}

export default function DuplicateButton({ configId, onClick }: IDuplicateButtonProps) {
  const [duplicateConfig] = useMutation<
    IDuplicateLandingPageConfigMutation,
    IDuplicateLandingPageConfigMutationVariables
  >(DuplicateLandingPageConfig);

  return (
    <li>
      <Button
        size="middle"
        type="link"
        icon={<CopyOutlined />}
        onClick={onClick(() => {
          duplicateConfig({
            variables: {
              id: configId,
            },
            refetchQueries: ["GetAllLandingPages"],
          }).then(
            () => {
              notification.open({
                type: "success",
                message: "Template duplicated!",
                description: `Template duplicated successfully!`,
              });
            },
            () => {
              notification.error({
                type: "error",
                message: "Duplication failed!",
                description: `Template duplication was not successful!`,
              });
            },
          );
        })}
      >
        Duplicate
      </Button>
    </li>
  );
}
