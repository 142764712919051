import { faHistory } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import DocumentTitle from "react-document-title";
import ConfigPageHeader from "../../components/ConfigPageHeader";
import PageIcon from "../../icons/PageIcon";
import CollectionContentUpdateButton from "../collections/CollectionContentUpdateButton";
import SetCountriesOnEntitiesButton from "../newsletter/SetCountriesOnEntitesButton";
import EnrichMakersProductsButton from "../products/EnrichMakersProductsButton";

export default function ScheduledJobs() {
  return (
    <DocumentTitle title="Scheduled Jobs | Admin">
      <>
        <ConfigPageHeader
          title={
            <>
              <PageIcon icon={faHistory} flip="horizontal" />
              Scheduled Jobs
            </>
          }
          subtitle="Run scheduled jobs"
        />
        <div
          css={{
            padding: 16,
            display: "flex",
            flexDirection: "column",
            maxWidth: 360,
            rowGap: 8,
          }}
        >
          <CollectionContentUpdateButton />
          <SetCountriesOnEntitiesButton />
          <EnrichMakersProductsButton />
        </div>
      </>
    </DocumentTitle>
  );
}
