import gql from "graphql-tag";

export default gql`
  mutation AddGovernment($payload: AddGovernmentInput!) {
    addGovernment(payload: $payload) {
      _id
      slug
    }
  }
`;
