import * as palette from "@govlaunch/palette";
import React, { ReactElement } from "react";

type Meta = Partial<{
  touched: boolean;
  error: any;
  submitError: any;
}>;

interface IFieldValidationErrorProps {
  meta: Meta;
  css?: any;
  [key: string]: any;
}

export default function FieldValidationError({ meta, css, ...props }: IFieldValidationErrorProps): ReactElement | null {
  if (hasFieldValidationError(meta)) {
    return (
      <div
        css={{
          marginLeft: "auto",
          color: palette.red,
          textTransform: "uppercase",
          fontSize: 12,
          ...css,
        }}
        {...props}
      >
        {meta.error || meta.submitError}
      </div>
    );
  }

  return null;
}

export function hasFieldValidationError(meta: Meta) {
  return meta.touched && (meta.error || meta.submitError);
}
