import { EditorState, convertToRaw } from "draft-js";
import DocumentTitle from "react-document-title";
import { FORM_ERROR } from "final-form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Mutation } from "react-apollo";
import React from "react";
import ReportForm from "./ReportForm";
import AddReport from "../../mutations/AddReport";
import { IAddReportMutation, IAddReportMutationVariables } from "../../mutations/__generated__/AddReport.generated";

export default function NewReport() {
  const navigate = useNavigate();

  return (
    <DocumentTitle title="New Report | Admin">
      <div>
        <div
          css={{
            padding: 20,
            paddingTop: 20,
            paddingBottom: 20,
            borderBottom: "1px solid rgb(232, 232, 232)",
          }}
        >
          <h2
            css={{
              fontWeight: 600,
              fontSize: 24,
              margin: 0,
            }}
          >
            New Report
          </h2>

          <Link to="/reports">All Reports</Link>
        </div>

        <div
          css={{
            padding: 20,
          }}
        >
          <Mutation<IAddReportMutation, IAddReportMutationVariables>
            onCompleted={({ addReport }) => {
              navigate(`/reports/${addReport!.slug}`);
            }}
            mutation={AddReport}
          >
            {(addReport) => (
              <ReportForm
                initialValues={{
                  title: "",
                  description: "",
                  images: ["", "", "", "", "", ""],
                  details: EditorState.createEmpty(),
                  governments: [],
                  locations: [],
                }}
                onSubmit={(values) => {
                  return addReport({
                    variables: {
                      report: {
                        title: values.title,
                        description: values.description,
                        images: values.images ? values.images.filter(Boolean) : null,
                        details: convertToRaw(values.details.getCurrentContent()),
                        governments: values.governments
                          ? values.governments.map((government) => {
                              return (government as any)._id;
                            })
                          : null,
                        locations: values.locations ? values.locations.filter(Boolean) : null,
                      },
                    },
                  })
                    .then(() => null)
                    .catch((error: any) => {
                      return {
                        [FORM_ERROR]: error.message,
                      };
                    });
                }}
                submitButtonText="Create Report"
              />
            )}
          </Mutation>
        </div>
      </div>
    </DocumentTitle>
  );
}
