import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import { useNavigate } from "react-router";
import TableRowActions from "../../../components/table/TableRowActions";
import DeleteExternalLink from "../../../mutations/DeleteExternalLink";
import {
  IDeleteExternalLinkMutation,
  IDeleteExternalLinkMutationVariables,
} from "../../../mutations/__generated__/DeleteExternalLink.generated";

interface IExternalLinkTableActionsProps {
  externalLinkId: string;
}

export default function ExternalLinksTableActions({ externalLinkId }: IExternalLinkTableActionsProps) {
  const navigate = useNavigate();

  const [deleteLink] = useMutation<IDeleteExternalLinkMutation, IDeleteExternalLinkMutationVariables>(
    DeleteExternalLink,
    {
      refetchQueries: ["GetExternalLinks"],
    },
  );

  return (
    <TableRowActions>
      {({ onClick }: any) => (
        <>
          <li>
            <Button
              size="middle"
              type="link"
              icon={<EditOutlined />}
              onClick={() => navigate(`/externalLinks/${externalLinkId}`)}
            >
              Edit
            </Button>
          </li>
          <li>
            <Popconfirm
              title="Are you sure?"
              onConfirm={onClick(() => {
                deleteLink({
                  variables: {
                    id: externalLinkId,
                  },
                  refetchQueries: ["GetExternalLinks"],
                });
              })}
            >
              <Button
                size="middle"
                css={{
                  color: "red",
                }}
                type="link"
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </Popconfirm>
          </li>
        </>
      )}
    </TableRowActions>
  );
}
