import React from "react";
import { useToggle } from "react-use";
import { Field } from "react-final-form";
import { Button, Popover, Radio } from "antd";
import DotdotdotIcon from "../../../components/icons/DotdotdotIcon";
import RadioButton from "../form/fields/RadioButton";
import * as palette from "@govlaunch/palette";

interface ISubmitButtonProps {
  disabled: boolean;
  submitting: boolean;
  submitText: string;
}

export default function SubmitButton({ disabled, submitting, submitText }: ISubmitButtonProps) {
  const [showActionsMenu, toggleShowActionsMenu] = useToggle(false);

  return (
    <div
      css={{
        marginTop: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        disabled={disabled}
        css={{
          userSelect: "none",
          margin: 5,
          width: 130,
        }}
      >
        {submitting ? "Please wait..." : submitText}
      </Button>

      <Popover
        visible={showActionsMenu}
        onVisibleChange={toggleShowActionsMenu}
        trigger="click"
        placement="bottomLeft"
        content={
          <Field name="action">
            {({ input }) => (
              <ul>
                <Radio.Group defaultValue="add" name="action" buttonStyle="solid">
                  <RadioButton action="add" input={input} onClick={toggleShowActionsMenu} />
                  <RadioButton action="overwrite" input={input} onClick={toggleShowActionsMenu} />
                  <RadioButton action="remove" input={input} onClick={toggleShowActionsMenu} />
                </Radio.Group>
              </ul>
            )}
          </Field>
        }
      >
        <Button
          size="large"
          css={{
            userSelect: "none",
            margin: 5,
            backgroundColor: palette.white,
            color: palette.darkGray,
            ":hover": {
              backgroundColor: palette.washGray,
              borderColor: palette.lightGray,
              color: palette.darkGray,
            },
          }}
        >
          <DotdotdotIcon />
        </Button>
      </Popover>
    </div>
  );
}
