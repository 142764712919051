import { Tabs } from "antd";
import queryString from "query-string";
import React from "react";
import DocumentTitle from "react-document-title";
import TabContent from "../../components/TabContent";
import { COUNTRIES_WITH_THE, getCountryNameByIsoCode } from "./Countries";
import GovernmentsCount from "./GovernmentsCount";
import VendorCreditsTable from "./VendorCreditsTable";
import { useLocation, useNavigate } from "react-router";
import CountriesList from "./tables/CountriesList";
import GovernmentsWithSearch from "./tables/GovernmentsWithSearch";
import { IGovernmentsFilter } from "./tables/ManageGovernments";
import MetaBaseLink, { getFilteredGovLink } from "../../components/MetaBaseLink";

export default function Governments() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = query.tab || "active";

  if (!query.country || Array.isArray(query.country)) {
    return <CountriesList />;
  }

  const selectedCountryName = getCountryNameByIsoCode(query.country);
  const selectedCountryCode = query.country;

  return (
    <DocumentTitle title={selectedCountryName ? `${selectedCountryName} | Governments | Admin` : "Governments | Admin"}>
      <>
        <div
          css={{
            padding: 20,
            borderBottom: "1px solid rgb(232, 232, 232)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div css={{
 display: "flex", flexDirection: "row", alignItems: "center" 
}}
          >
            <img
              src={`https://static.govlaunch.com/flags/squared/${selectedCountryCode}.svg`}
              width={18}
              height={18}
              css={{
 marginTop: 3 
}}
            />

            <h3
              css={{
                margin: 0,
                marginLeft: 8,
                fontWeight: 700,
                fontSize: 24,
              }}
            >
              Governments of {COUNTRIES_WITH_THE.includes(query.country) ? "the " : ""}
              {selectedCountryName}
            </h3>
          </div>
          <div>
            <MetaBaseLink question={getFilteredGovLink(selectedCountryCode)} />
          </div>
        </div>

        <GovernmentsCount filterByCountry={selectedCountryCode}>
          {({ allCount, activeCount, innovatorsCount, inactiveCount, sharingCount, refetch }) => (
            <Tabs
              animated={false}
              onChange={(tab) => {
                navigate(`/governments?country=${selectedCountryCode}&tab=${tab}`);
                refetch();
              }}
              activeKey={tab as string}
              size="middle"
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
            >
              <Tabs.TabPane tab={allCount ? `All (${allCount})` : "All"} key="all">
                {tab === "all" && (
                  <TabContent>
                    <GovernmentsWithSearch filterByCountry={selectedCountryCode} filter={IGovernmentsFilter.All} />
                  </TabContent>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={activeCount ? `Active (${activeCount})` : "Active"} key="active">
                {tab === "active" && (
                  <TabContent>
                    <GovernmentsWithSearch
                      filterByCountry={selectedCountryCode}
                      filter={IGovernmentsFilter.Active}
                      sortBy="activatedAt:desc"
                    />
                  </TabContent>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={innovatorsCount ? `Innovators (${innovatorsCount})` : "Innovators"} key="innovators">
                {tab === "innovators" && (
                  <TabContent>
                    <GovernmentsWithSearch
                      filterByCountry={selectedCountryCode}
                      filter={IGovernmentsFilter.Innovators}
                    />
                  </TabContent>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={inactiveCount ? `Inactive (${inactiveCount})` : "Inactive"} key="inactive">
                {tab === "inactive" && (
                  <TabContent>
                    <GovernmentsWithSearch filterByCountry={selectedCountryCode} filter={IGovernmentsFilter.Inactive} />
                  </TabContent>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab={sharingCount ? `Sharing (${sharingCount})` : "Sharing"} key="sharing">
                {tab === "sharing" && (
                  <TabContent>
                    <GovernmentsWithSearch filterByCountry={selectedCountryCode} filter={IGovernmentsFilter.Sharing} />
                  </TabContent>
                )}
              </Tabs.TabPane>

              <Tabs.TabPane tab="Referrals" key="referrals">
                {tab === "referrals" && (
                  <TabContent>
                    <VendorCreditsTable />
                  </TabContent>
                )}
              </Tabs.TabPane>
            </Tabs>
          )}
        </GovernmentsCount>
      </>
    </DocumentTitle>
  );
}
