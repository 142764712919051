import React from "react";

const SectionBody = ({ children, ...props }: any) => {
  return (
    <div
      css={{
        padding: 16,
        display: "grid",
        gridRowGap: 20,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default SectionBody;
