import * as palette from "@govlaunch/palette";
import { ApolloConsumer, Mutation } from "react-apollo";
import { Modal, Popconfirm } from "antd";
import AddNewProduct from "./AddNewProduct";
import GetOutsideProductsQuery from "../../queries/GetOutsideProductsQuery";
import { IGetOutsideProductsQuery } from "../../queries/__generated__/GetOutsideProductsQuery.generated";
import React from "react";
import SuggestionsCount from "./SuggestionsCount";
import { Toggle } from "react-powerplug";
import { IOutsideProductStatus } from "../../../types/types";
import IgnoreOutsideProduct from "../../mutations/IgnoreOutsideProduct";
import {
  IIgnoreOutsideProductMutation,
  IIgnoreOutsideProductMutationVariables,
} from "../../mutations/__generated__/IgnoreOutsideProduct.generated";
import DenyOutsideProduct from "../../mutations/DenyOutsideProduct";
import {
  IDenyOutsideProductMutation,
  IDenyOutsideProductMutationVariables,
} from "../../mutations/__generated__/DenyOutsideProduct.generated";
import TableRowActions from "../../components/table/TableRowActions";

interface ISuggestionVariables {
  search: string;
  status: IOutsideProductStatus;
}

interface ISuggestionActionsProps {
  suggestion: any;
  variables: ISuggestionVariables;
}

export default function SuggestionActions({ suggestion, variables }: ISuggestionActionsProps) {
  return (
    <TableRowActions title="Suggestion Actions">
      {({ onClick }: any) => (
        <>
          <li>
            <Toggle>
              {({ on: isOpen, toggle }) => (
                <>
                  <Modal
                    visible={isOpen}
                    onCancel={toggle}
                    footer={null}
                    title="Assign suggestion to product"
                    width={1200}
                  >
                    <SuggestionsCount>
                      {({ refetch }) => (
                        <ApolloConsumer>
                          {(client) => {
                            return (
                              <AddNewProduct
                                initialValues={{
                                  name: suggestion.name || "",
                                  link: suggestion.url || "",
                                }}
                                onAdd={() => {
                                  toggle();
                                  refetch();

                                  const data = client.cache.readQuery({
                                    query: GetOutsideProductsQuery,
                                    variables,
                                  });

                                  const { dataResults } = data as any;

                                  client.cache.writeQuery({
                                    query: GetOutsideProductsQuery,
                                    variables,
                                    data: {
                                      ...dataResults,
                                      products: {
                                        ...dataResults.products,
                                        items: dataResults.products.items.filter(
                                          ({ _id }: { _id: string }) => suggestion._id !== _id,
                                        ),
                                      },
                                    },
                                  });
                                }}
                                outsideProducts={[suggestion._id]}
                              />
                            );
                          }}
                        </ApolloConsumer>
                      )}
                    </SuggestionsCount>
                  </Modal>

                  <a
                    href="#"
                    onClick={onClick(toggle)}
                    role="button"
                    css={{
                      cursor: "pointer",
                    }}
                  >
                    Add to Product
                  </a>
                </>
              )}
            </Toggle>
          </li>

          <li>
            <Mutation<IIgnoreOutsideProductMutation, IIgnoreOutsideProductMutationVariables>
              mutation={IgnoreOutsideProduct}
              variables={{
                outsideProductId: suggestion._id,
              }}
              update={(proxy) => {
                const data = proxy.readQuery<IGetOutsideProductsQuery>({
                  query: GetOutsideProductsQuery,
                  variables,
                });

                if (!data || !data.products || !data.products.items) {
                  return null;
                }

                proxy.writeQuery({
                  query: GetOutsideProductsQuery,
                  data: {
                    products: {
                      __typename: "OutsideProducts",
                      items: data.products.items.map((product) =>
                        product._id === suggestion._id
                          ? {
                              ...product,
                              status: "IGNORED",
                            }
                          : product,
                      ),
                    },
                  },
                });
              }}
            >
              {(ignore) => (
                <Popconfirm title="Are you sure?" onConfirm={onClick(ignore)}>
                  <a
                    href="#"
                    css={{
                      cursor: "pointer",
                    }}
                    role="button"
                  >
                    Ignore
                  </a>
                </Popconfirm>
              )}
            </Mutation>
          </li>

          <li>
            <Mutation<IDenyOutsideProductMutation, IDenyOutsideProductMutationVariables>
              mutation={DenyOutsideProduct}
              variables={{
                outsideProductId: suggestion._id,
              }}
              update={(proxy) => {
                const data = proxy.readQuery<IGetOutsideProductsQuery>({
                  query: GetOutsideProductsQuery,
                  variables,
                });

                if (!data || !data.products || !data.products.items) {
                  return null;
                }

                proxy.writeQuery({
                  query: GetOutsideProductsQuery,
                  data: {
                    products: {
                      __typename: "OutsideProducts",
                      items: data.products.items.map((product) =>
                        product._id === suggestion._id
                          ? {
                              ...product,
                              status: "DENIED",
                            }
                          : product,
                      ),
                    },
                  },
                });
              }}
            >
              {(deny) => (
                <Popconfirm title="Are you sure?" onConfirm={onClick(deny)}>
                  <a
                    href="#"
                    role="button"
                    css={{
                      color: palette.red,
                      cursor: "pointer",
                    }}
                  >
                    Reject
                  </a>
                </Popconfirm>
              )}
            </Mutation>
          </li>
        </>
      )}
    </TableRowActions>
  );
}
