import React from "react";
import { TImageData } from "@govlaunch/web";
import HeaderBannerFieldModalImage from "./HeaderBannerFieldModalImage";
import ReactTextareaAutosize from "react-textarea-autosize";
import EditBlockColorPicker from "../blockslayout/EditBlockColorPicker";
import * as palette from "@govlaunch/palette";
import { IHeaderBannerData } from "./headerBannerProps";

interface IHeaderBannerMainSettingsProps {
  data: IHeaderBannerData;
  setData: React.Dispatch<React.SetStateAction<IHeaderBannerData>>;
}

export default function HeaderBannerMainSettings({ data, setData }: IHeaderBannerMainSettingsProps) {
  return (
    <>
      <label
        css={{
          fontWeight: 500,
          fontSize: 14,
          color: palette.mediumGray,
        }}
      >
        Title
      </label>
      <ReactTextareaAutosize
        css={{
          marginTop: "8px",
          "::placeholder": {
            color: palette.sealBlue,
          },
          ":focus": {
            borderColor: palette.primary,
          },
          fontSize: 14,
          border: `1px solid ${palette.lightestGray}`,
          borderRadius: 5,
          padding: "5px 10px",
          width: "100%",
          outline: 0,
          resize: "none",
        }}
        minRows={2}
        maxRows={4}
        placeholder="Enter header banner title..."
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        onChange={(event) => {
          setData({
            ...data,
            title: event.target.value,
          });
        }}
        value={data.title || ""}
      />

      <EditBlockColorPicker
        data={data}
        setData={setData}
        fieldName="titleColor"
        label="Title Color"
        placeholder="Set title color"
      />

      <label
        css={{
          marginTop: "16px",
          fontWeight: 500,
          fontSize: 14,
          color: palette.mediumGray,
        }}
      >
        Subtitle
      </label>
      <ReactTextareaAutosize
        css={{
          marginTop: "8px",
          "::placeholder": {
            color: palette.sealBlue,
          },
          ":focus": {
            borderColor: palette.primary,
          },
          fontSize: 14,
          border: `1px solid ${palette.lightestGray}`,
          borderRadius: 5,
          padding: "5px 10px",
          width: "100%",
          outline: 0,
          resize: "none",
        }}
        minRows={3}
        maxRows={6}
        placeholder="Enter header banner subtitle..."
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        onChange={(event) => {
          setData({
            ...data,
            subtitle: event.target.value,
          });
        }}
        value={data.subtitle || ""}
      />

      <EditBlockColorPicker
        data={data}
        setData={setData}
        fieldName="subtitleColor"
        label="Subitle Color"
        placeholder="Set subtitle color"
      />

      <div
        css={{
          marginTop: "16px",
        }}
      >
        <HeaderBannerFieldModalImage
          data={
            data.backgroundImage || {
              url: "",
              alt: "",
            }
          }
          onSave={(imageData: TImageData) => {
            setData({
              ...data,
              backgroundImage: imageData,
            });
          }}
          label="Background Image"
          field="backgroundImage"
        />
      </div>
      <div
        css={{
          marginTop: "16px",
        }}
      >
        <HeaderBannerFieldModalImage
          data={
            data.foregroundImage || {
              url: "",
              alt: "",
            }
          }
          onSave={(imageData: TImageData) => {
            setData({
              ...data,
              foregroundImage: imageData,
            });
          }}
          label="Foreground Image"
          field="foregroundImage"
        />
      </div>
    </>
  );
}
