import React from "react";

export default function CampaignPreviewHeaderLinks() {
  const searchUrl = getSearchProjectsLink();
  const podcastUrl = getPodcastLink();

  return (
    <table
      cellSpacing="0"
      cellPadding="0"
      role="presentation"
      style={{
        borderCollapse: "collapse",
        borderSpacing: 0,
        width: "100%",
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              padding: 0,
              margin: 0,
            }}
          >
            <table
              className="es-menu"
              cellPadding="0"
              cellSpacing="0"
              role="presentation"
              style={{
                borderCollapse: "collapse",
                borderSpacing: 0,
                width: "100%",
              }}
            >
              <tbody>
                <tr className="links-images-left">
                  <td
                    id="esd-menu-id-0"
                    align="center"
                    valign="top"
                    style={{
                      margin: 0,
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      border: 0,
                      width: "33.33%",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="*|ARCHIVE|*"
                      style={{
                        textDecoration: "none",
                        display: "block",
                        color: "#FFFFFF",
                        fontSize: "12px",
                      }}
                    >
                      <img
                        src="https://mcusercontent.com/88b300557f9ef581c3b0d7bf5/images/edd2c5c9-e2da-8a3e-662e-b7e26cd75ee7.png"
                        alt="View Online"
                        title="View Online"
                        height="14"
                        style={{
                          display: "inline-block !important",
                          border: 0,
                          outline: "none",
                          textDecoration: "none",
                          paddingRight: "5px",
                          verticalAlign: "middle",
                        }}
                      />
                      View Online
                    </a>
                  </td>
                  <td
                    id="esd-menu-id-1"
                    align="center"
                    valign="top"
                    style={{
                      margin: 0,
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      borderLeft: "1px solid #ffffff",
                      width: "33.33%",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={searchUrl}
                      style={{
                        WebkitTextSizeAdjust: "none",
                        //@ts-ignore
                        msTextSizeAdjust: "none",
                        textDecoration: "none",
                        display: "block",
                        color: "#FFFFFF",
                        fontSize: "12px",
                      }}
                    >
                      <img
                        src="https://mcusercontent.com/88b300557f9ef581c3b0d7bf5/images/2e74d010-7bdb-b25c-540f-9ba8406a7b04.png"
                        alt="Search Govlaunch"
                        title="Search Govlaunch"
                        height="14"
                        style={{
                          display: "inline-block !important",
                          border: 0,
                          outline: "none",
                          textDecoration: "none",
                          paddingRight: "5px",
                          verticalAlign: "middle",
                        }}
                      />
                      Search Govlaunch
                    </a>
                  </td>
                  <td
                    id="esd-menu-id-2"
                    align="center"
                    valign="top"
                    style={{
                      margin: 0,
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      borderLeft: "1px solid #ffffff",
                      width: "33.33%",
                    }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={podcastUrl}
                      style={{
                        WebkitTextSizeAdjust: "none",
                        //@ts-ignore
                        msTextSizeAdjust: "none",
                        textDecoration: "none",
                        display: "block",
                        color: "#FFFFFF",
                        fontSize: "12px",
                      }}
                    >
                      <img
                        src="https://mcusercontent.com/88b300557f9ef581c3b0d7bf5/images/e711ec23-e364-1cd1-1f49-eddf69b0545e.png"
                        alt="Podcast"
                        title="Podcast"
                        height="14"
                        style={{
                          display: "inline-block !important",
                          border: 0,
                          outline: "none",
                          textDecoration: "none",
                          paddingRight: "5px",
                          verticalAlign: "middle",
                        }}
                      />
                      Podcast
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export function getSearchProjectsLink() {
  if (process.env.GOVLAUNCH_ENV === "production") {
    return "https://www.govlaunch.com/projects";
  }

  if (process.env.GOVLAUNCH_ENV === "qa") {
    return "https://qa.govlaunch.com/projects";
  }

  return "https://localhost:3000/projects";
}

function getPodcastLink() {
  if (process.env.GOVLAUNCH_ENV === "production") {
    return "https://www.govlaunch.com/podcast";
  }

  if (process.env.GOVLAUNCH_ENV === "qa") {
    return "https://qa.govlaunch.com/podcast";
  }

  return "https://localhost:3000/podcast";
}
