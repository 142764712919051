import { Popover } from "antd";
import React from "react";
import { Toggle } from "react-powerplug";
import DotdotdotIcon from "../icons/DotdotdotIcon";

interface ITableRowActionsProps {
  title?: string;
  children: any;
}

export default function TableRowActions({ title, children, ...props }: ITableRowActionsProps) {
  return (
    <Toggle>
      {({ on, toggle }) => (
        <Popover
          title={title || "Actions"}
          placement="leftBottom"
          {...props}
          visible={on}
          trigger="click"
          onVisibleChange={toggle}
          content={
            <ul
              css={{
                margin: 0,
                padding: 0,
                listStyle: "none",
                "> li:not(:first-of-type)": {
                  marginTop: 10,
                },
              }}
            >
              {children({
                toggle,
                onClick: (callback: any) => (event: any) => {
                  if (event && event.preventDefault) {
                    event.preventDefault();
                    callback();
                    toggle();
                  }
                },
              })}
            </ul>
          }
        >
          <span
            role="button"
            css={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DotdotdotIcon />
          </span>
        </Popover>
      )}
    </Toggle>
  );
}
