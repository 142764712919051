import gql from "graphql-tag";

export default gql`
  mutation RestoreStory($storyId: ObjectId!) {
    restoreStory(storyId: $storyId) {
      _id
      status
      archivedAt
    }
  }
`;
