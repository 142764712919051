import { CSSReset, theme, ThemeProvider } from "@govlaunch/web";
import { notification } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import { Form } from "react-final-form";
import { useNavigate } from "react-router";
import CreateExternalLink from "../../../mutations/CreateExternalLink";
import {
  ICreateExternalLinkMutation,
  ICreateExternalLinkMutationVariables,
} from "../../../mutations/__generated__/CreateExternalLink.generated";
import validate from "../../../validate";
import ExternalLinkForm from "./ExternalLinkForm";
import externalLinkValidationSchema from "./externalLinkValidationSchema";

export default function NewExternalLinkForm() {
  const navigate = useNavigate();
  const [createExternalLink] = useMutation<ICreateExternalLinkMutation, ICreateExternalLinkMutationVariables>(
    CreateExternalLink,
  );

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Form
        keepDirtyOnReinitialize={true}
        initialValues={{
          title: "",
          url: "",
          source: "",
        }}
        validate={validate(externalLinkValidationSchema)}
        onSubmit={async (values) => {
          const result = await createExternalLink({
            variables: {
              data: {
                title: values.title,
                url: values.url,
                source: values.source,
              },
            },
          });

          if (result && result.data && result.data.createExternalLink) {
            notification.success({
              message: "Success!",
              description: "External link saved successfully",
            });

            navigate("/externalLinks");
          }
        }}
        subscription={{
          submitting: true,
          submitError: true,
        }}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <ExternalLinkForm onSubmit={handleSubmit} submitting={submitting} submitError={submitError} />
        )}
      </Form>
    </ThemeProvider>
  );
}
