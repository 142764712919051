import { Col, RowWrap } from "@govlaunch/core";
import React from "react";
import TextField from "../../form/fields/TextField";
import UrlField from "../../form/fields/UrlField";
import FormGrid from "../../form/FormGrid";
import FormSubmitSection from "../../form/FormSubmitSection";
import Section from "../../form/Section";
import SectionBody from "../../form/SectionBody";

interface IExternalLinkFormProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  submitting: boolean;
  submitError?: string;
  submitButtonText?: string;
}

export default function ExternalLinkForm({
  onSubmit,
  submitting,
  submitError,
  submitButtonText = "Save",
}: IExternalLinkFormProps) {
  return (
    <form onSubmit={onSubmit}>
      <FormGrid>
        <FormSubmitSection
          backButtonText="All external links"
          backButtonLocation="/externalLinks"
          discardChangesLocation="/externalLinks"
          submitButtonText={submitButtonText}
          submitting={submitting}
          submitError={submitError}
        />

        <RowWrap>
          <Col width={[1, 1, 1, 1]}>
            <Section>
              <SectionBody>
                <TextField fieldName="title" label="Title" placeholder="Type title..." />
                <UrlField fieldName="url" label="Link URL" placeholder="https://www.sample.com" />
                <TextField fieldName="source" label="Source" placeholder="Example: Washington Post" />
              </SectionBody>
            </Section>
          </Col>
        </RowWrap>
      </FormGrid>
    </form>
  );
}
