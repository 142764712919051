import gql from "graphql-tag";

export default gql`
  query SuggestionsCount {
    outsideProductsCount {
      pendingCount
      addedCount
      ignoredCount
      deniedCount
    }
  }
`;
