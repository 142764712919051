import gql from "graphql-tag";

export default gql`
  query DuplicatedUsers($search: String, $limit: Int = 20, $cursor: Cursor) {
    duplicatedUsers(search: $search, limit: $limit, cursor: $cursor) {
      items {
        _id
        userId
        slug
        fullName
        email
        avatar
        intercomId
        duplicatedUsers {
          userId
          slug
          fullName
          email
          avatar
          intercomId
        }
        role
        verified
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor
      }
    }
  }
`;
