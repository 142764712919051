import gql from "graphql-tag";

export default gql`
  query AutoCompletePaidProducts($searchQuery: String!, $filterByVendor: ObjectId) {
    autoCompleteProducts(
      searchQuery: $searchQuery
      scopes: [DISRUPTOR, MAKERS, VENDOR_IS_DISRUPTOR]
      filterByVendor: $filterByVendor
      limit: 10
    ) {
      _id
      name
      logo
    }
  }
`;
