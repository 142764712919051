import React from "react";
import { Form, Select } from "antd";
import { theme } from "@govlaunch/web";
import notUndefinedOrNull from "../utils/notUndefinedOrNull";
import { FormItemProps } from "antd/lib/form";

export default function ColorPicker(props: Omit<FormItemProps, "children">) {
  return (
    <Form.Item {...props}>
      <Select>
        {Object.keys(theme.colors)
          .map((colorKey) => {
            if (typeof (theme.colors as any)[colorKey] !== "string") {
              return null;
            }

            const colorHex = (theme.colors as any)[colorKey];

            if (colorHex === "currentColor") {
              return null;
            }

            return (
              <Select.Option value={colorHex} key={colorKey}>
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    css={{
                      marginRight: 8,
                      display: "inline-block",
                      width: 25,
                      height: 25,
                      background: colorHex,
                    }}
                  />

                  <span>{colorHex}</span>
                </div>
              </Select.Option>
            );
          })
          .filter(notUndefinedOrNull)}

        {Object.keys(theme.colors)
          .filter((colorKey) => {
            return !["blackAlpha", "whiteAlpha", "currentColor"].includes(colorKey);
          })
          .map((colorKey) => {
            if (typeof (theme.colors as any)[colorKey] === "string") {
              return null;
            }

            return (
              <Select.OptGroup key={colorKey} label={colorKey}>
                {Object.keys((theme.colors as any)[colorKey]).map((colorShade) => {
                  const colorHex = (theme.colors as any)[colorKey][colorShade];

                  return (
                    <Select.Option value={colorHex} key={colorHex}>
                      <div
                        css={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          css={{
                            marginRight: 8,
                            display: "inline-block",
                            width: 25,
                            height: 25,
                            background: colorHex,
                          }}
                        />
                        <span>
                          {colorKey}.{colorShade}
                        </span>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select.OptGroup>
            );
          })
          .filter(notUndefinedOrNull)}
      </Select>
    </Form.Item>
  );
}
