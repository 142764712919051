import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { Margin } from "../../../spacings";

interface IEditableFieldProps {
  label: string;
  name: string;
}

export default function EditableField({ label, name }: IEditableFieldProps) {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <div>
          <div>
            <label
              css={{
                fontWeight: "bold",
                fontSize: 14,
                color: palette.mediumGray,
                margin: 0,
              }}
            >
              {label}
            </label>
          </div>

          <Margin mt={10}>
            <input
              css={{
                width: "100%",
                height: 42,
                padding: "6px 12px",
                fontSize: 14,
                border: `solid 1px ${palette.lightestGray}`,
                borderRadius: 6,
                outline: 0,
                ":focus": {
                  borderColor: palette.primary,
                },
                "::placeholder": {
                  color: palette.sealBlue,
                  textTransform: "capitalize",
                },
              }}
              {...input}
            />
          </Margin>

          {meta && meta.error && meta.touched && (
            <div
              css={{
                color: palette.red,
                fontSize: 12,
                textTransform: "uppercase",
                marginTop: 4,
              }}
            >
              {meta.error}
            </div>
          )}
        </div>
      )}
    </Field>
  );
}
