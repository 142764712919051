import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router";

interface IEditButtonProps {
  configId: string;
}

export default function EditButton({ configId }: IEditButtonProps) {
  const navigate = useNavigate();

  return (
    <li>
      <Button size="middle" type="link" icon={<EditOutlined />} onClick={() => navigate(`/landingPage/${configId}`)}>
        Edit
      </Button>
    </li>
  );
}
