import React from "react";
import { Field } from "react-final-form";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { Margin } from "../../../spacings";
import * as palette from "@govlaunch/palette";
import { IFormFieldProps } from "./FormFieldProps";
import { DatePicker } from "antd";
import moment from "moment";

interface IDateFieldProps extends IFormFieldProps {
  dateLimit?: Date;
}

export default function DateField({ fieldName, label, dateLimit, ...props }: IDateFieldProps) {
  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div {...props}>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: palette.mediumGray,
                margin: 0,
              }}
            >
              {label}
            </label>

            <FieldValidationError meta={meta} />
          </div>
          <Margin mt={8}>
            <DatePicker
              defaultValue={input.value ? moment(input.value) : undefined}
              onChange={(date) => {
                if (date) {
                  input.onChange(date.toDate());
                }
              }}
              disabledDate={(current) => {
                if (!current || !dateLimit) {
                  return false;
                }
                const customDate = moment(dateLimit).format("YYYY-MM-DD");
                return current && current < moment(customDate, "YYYY-MM-DD");
              }}
            />
          </Margin>
        </div>
      )}
    </Field>
  );
}
