import gql from "graphql-tag";

export default gql`
  query ImagesCount {
    imagesCount {
      allCount
      noAltCount
      withAltCount
      placeholderCount
      archivedCount
    }
  }
`;
