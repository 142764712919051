import fp, { pick } from "lodash/fp";
import prefixWithHttpIfNecessary from "../../../utils/prefixWithHttpIfNecessary";
import isValidUrl from "../../../utils/isValidUrl";

export default function convertValuesToVariables(values: any): any {
  return {
    name: values.name || null,
    billingName: values.billingName || null,
    description: values.description || null,
    yearFounded: values.yearFounded ? Number(values.yearFounded) : null || null,
    site: prefixWithHttpIfNecessary(values.site),
    headquarters: values.headquarters || null,
    slogan: values.slogan || null,
    contactPage: getContactPage(values.contact),
    businessEmail: getContactEmail(values.contact),
    highlights: fp.uniq(values.highlights.map(fp.trim).filter(fp.identity)) || null,
    newsworthy: values.newsworthy || null,
    logo: values.logo || null,
    socialMedia: fp.pick(["facebook", "instagram", "linkedin", "twitter", "youtube"], values.socialMedia) || null,
    numberOfEmployees: fp.get("numberOfEmployees.value", values) || null,
    cover: null,
    address: values.address || null,
    resources: values.resources.map(pick(["type", "url", "title", "body"])),
    sponsorships: values.sponsorships,
  };
}

function getContactPage(contact: string | null) {
  return contact && isValidUrl(contact) ? contact : null;
}

function getContactEmail(contact: string | null) {
  return contact && contact.includes("@") ? contact : null;
}
