import gql from "graphql-tag";
import GovernmentProjectFields from "../../../../queries/fragments/GovernmentProjectFields";
import GroupProjectFields from "../../../../queries/fragments/GroupProjectFields";
import UserFields from "../../../../queries/fragments/UserFields";
import VendorProjectFields from "../../../../queries/fragments/VendorProjectFields";
import { CompactGovernmentsTableQueryFields } from "../../../governments/CompactGovernmentsTable";

export default gql`
  query CollectionBySlug($slug: String!, $filterByItemType: CollectionItemType!, $cursor: String) {
    collectionBySlug(slug: $slug) {
      _id
      name
      status
      slug
      featuredAt
      description
      tags {
        _id
        name
        slug
      }
      packaging
      createdAt
      updatedAt
      sponsoring
      sponsoringVendors {
        _id
        name
        slug
      }
      sponsoringProducts {
        _id
        name
        slug
      }
      adminStats {
        usersSavedCount
      }
      displayOptions {
        cardTitleFontColorHex
        cardBackgroundColorHex
      }
      contentCounters {
        projectsCount
        groupsCount
        productsCount
        storiesCount
        governmentsCount
      }
      content(filterByItemType: $filterByItemType, cursor: $cursor) {
        items {
          ...GovernmentProjectFields
          ...GroupProjectFields
          ...VendorProjectFields
          ... on Story {
            _id
            title
            slug
            storyStatus: status
            audience
            featured
            horizontalCover
            verticalCover
            lastPublishedAt
            modifiedAt
            createdAt
            archivedAt
            archivedBy {
              ... on PendingUser {
                _id
                slug
                fullName
              }
              ... on VendorUser {
                _id
                slug
                fullName
              }
              ... on GovernmentUser {
                _id
                slug
                fullName
              }
            }
            storyGovernment: government {
              _id
              logo
              slug
              name
              state
              city {
                _id
                name
                country
              }
            }
            author {
              ... on PendingUser {
                _id
                slug
                isSiteAdmin
                fullName
                jobTitle
                avatar
              }
              ... on VendorUser {
                _id
                slug
                isSiteAdmin
                fullName
                title
                avatar
                jobTitle
                company {
                  _id
                  slug
                  name
                  logo
                }
              }
              ... on GovernmentUser {
                _id
                slug
                isSiteAdmin
                fullName
                title
                avatar
                isInnovator
                jobTitle
                government {
                  _id
                  slug
                  name
                  logo
                }
              }
            }
            tags {
              _id
              slug
              name
            }
          }
          ... on Group {
            _id
            slug
            name
            logo
            kind
            membersCount
            about
            website
            visibility
            deletedAt
            kind
            owner {
              _id
              name
              slug
            }
            deletedBy {
              ... on PendingUser {
                _id
                slug
                fullName
              }
              ... on VendorUser {
                _id
                slug
                fullName
              }
              ... on GovernmentUser {
                _id
                slug
                fullName
              }
            }
            location
            owner {
              _id
              name
              logo
              isDisruptor
            }
            previousOwner {
              _id
              slug
              name
              logo
              isDisruptor
            }
            governments {
              _id
              name
              slug
              logo
            }
            vendors {
              _id
              name
              slug
              logo
            }
            members {
              items {
                ...UserFields
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
          ... on Product {
            _id
            slug
            name
            logo
            isDisruptor
            storiesCount
            projectsCount
            governmentsUsingCount
            canBeDisruptor
            tier
            archivedAt
            archivedBy {
              ... on PendingUser {
                _id
                slug
                fullName
              }
              ... on VendorUser {
                _id
                slug
                fullName
              }
              ... on GovernmentUser {
                _id
                slug
                fullName
              }
            }
            featuredAt
            createdAt
            updatedAt
            company {
              _id
              name
              slug
              logo
              isDisruptor
              archivedAt
            }
            tags {
              _id
              name
            }
          }
          ... on Government {
            _id
            ...CompactGovernmentsTableQueryFields
          }
        }
        pageInfo {
          cursor
          hasNextPage
        }
        totalCount
      }
    }
  }

  ${GovernmentProjectFields}
  ${GroupProjectFields}
  ${VendorProjectFields}
  ${UserFields}
  ${CompactGovernmentsTableQueryFields}
`;
