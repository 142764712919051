import { Button } from "antd";
import React from "react";
import { useField } from "react-final-form";
import FilestackUploadField from "../../form/fields/FilestackUploadField";
import * as palette from "@govlaunch/palette";
import TextField from "../../form/fields/TextField";
import { Margin } from "../../../spacings";

type TLogo = {
  imageUrl: string;
  url: string;
  description: string;
};

export default function LogosField() {
  const { input: logoUrls } = useField<TLogo[]>("logoUrls");
  return (
    <div
      css={{
        marginTop: 16,
        display: "flex",
        flexDirection: "row",
        alignItems: "top",
        flexWrap: "wrap",
      }}
    >
      {(logoUrls.value || []).map((logo, index: number) => {
        return (
          <div
            key={index}
            css={{
              marginTop: 8,
              marginRight: 24,
              display: "inline-flex",
              flexDirection: "column",
              alignItems: "left",
              border: `1px solid ${palette.lightestGray}`,
              borderRadius: 5,
              padding: 12,
              width: "180px",
            }}
          >
            <FilestackUploadField
              fieldName={`logoUrls[${index}].imageUrl`}
              uploadButtonText="Upload logo image"
              imageHeight={100}
              imageWidth={172}
              rounded={true}
              centered={true}
              removeButtonOnImage={true}
            />
            <Margin mt={8}>
              <TextField fieldName={`logoUrls[${index}].url`} placeholder="Paste a URL" />
            </Margin>
            <Margin mt={8}>
              <TextField fieldName={`logoUrls[${index}].description`} placeholder="Image description" />
            </Margin>
            <Margin mt={8}>
              <Button
                danger={true}
                type="primary"
                onClick={() =>
                  logoUrls.onChange((logoUrls.value || []).filter((maybeLogo: TLogo) => maybeLogo !== logo))
                }
              >
                Remove logo
              </Button>
            </Margin>
          </div>
        );
      })}
      {(logoUrls.value || []).length < 5 && (
        <div css={{
 display: "inline-flex", flexDirection: "column", marginTop: 8 
}}
        >
          <Button
            onClick={() =>
              logoUrls.onChange(
                (logoUrls.value || []).concat({
                  imageUrl: "",
                  url: "",
                  description: "",
                }),
              )
            }
          >
            {(logoUrls.value || []).length === 0 ? "Add logo" : "+"}
          </Button>
        </div>
      )}
    </div>
  );
}
