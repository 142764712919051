import React from "react";
import { useQuery } from "react-apollo";
import GetGovlaunchStatsCount from "../../../../queries/GetGovlaunchStatsCount";
import {
  IGetGovlaunchStatsCountQuery,
  IGetGovlaunchStatsCountQueryVariables,
} from "../../../../queries/__generated__/GetGovlaunchStatsCount.generated";
import BlockLayoutContainer from "../../blockslayout/BlockLayoutContainer";
import BlockLayoutHeader from "../../blockslayout/BlockLayoutHeader";
import { IBlockLayout, TBlockLayoutData, IStatCounterData } from "../blockProps";
import CenteredLoadingSpinner from "../CenteredLoadingSpinner";
import StatCountersWidget from "./StatCountersWidget";
import ProjectIcon from "../../../../components/icons/ProjectIcon";
import GovernmentIcon from "../../../../components/icons/GovernmentIcon";
import ProductIcon from "../../../../components/icons/ProductIcon";
import StoriesIcon from "../../../../components/icons/StoriesIcon";
import VendorsIcon from "../../../../components/icons/VendorsIcon";
import { SimpleGrid } from "@govlaunch/web";
import BlockContentTitle from "../BlockContentTitle";
import BlockLayoutHeaderSettings from "../../blockslayout/BlockLayoutHeaderSettings";

interface IStatCountersBlockProps {
  block: IBlockLayout;
  onRemoveBlock: () => void;
  saveBlockData: (block: IBlockLayout, blockData: TBlockLayoutData) => void;
}

export default function StatCountersBlock({ block, onRemoveBlock, saveBlockData }: IStatCountersBlockProps) {
  return (
    <BlockLayoutContainer block={block}>
      <BlockLayoutHeader block={block} onRemoveBlock={onRemoveBlock}>
        <BlockLayoutHeaderSettings block={block} saveBlockData={saveBlockData} />
      </BlockLayoutHeader>
      {block.blockData["titleVisible"] && <BlockContentTitle block={block} />}
      <StatCountersBlockContent block={block} />
    </BlockLayoutContainer>
  );
}

interface IStatCountersBlockContentProps {
  block: IBlockLayout;
}

function StatCountersBlockContent({ block }: IStatCountersBlockContentProps) {
  const settings = block.blockData as IStatCounterData;
  const { data, loading } = useQuery<IGetGovlaunchStatsCountQuery, IGetGovlaunchStatsCountQueryVariables>(
    GetGovlaunchStatsCount,
    {
      variables: {
        settings: {
          countProjects: settings.showProjects,
          countProducts: settings.showProducts,
          countGovernments: settings.showGovernments,
          countStories: settings.showStories,
          countVendors: settings.showVendors,
        },
      },
    },
  );

  if (!data || loading) {
    return (
      <StatCountersBlockContentContainer backgroundColor={settings.backgroundColor || "#E9F3FE"}>
        <CenteredLoadingSpinner />
      </StatCountersBlockContentContainer>
    );
  }

  return (
    <StatCountersBlockContentContainer backgroundColor={settings.backgroundColor || "#E9F3FE"}>
      {settings.showProjects && (
        <StatCountersWidget
          count={data.govlaunchStatsCount.projectsCount}
          countColor={settings.countersColor}
          label="Projects"
          labelColor={settings.labelsColor}
          widgetColor={settings.widgetColor}
          icon={<ProjectIcon />}
        />
      )}
      {settings.showGovernments && (
        <StatCountersWidget
          count={data.govlaunchStatsCount.governmentsCount}
          countColor={settings.countersColor}
          label="Governments"
          labelColor={settings.labelsColor}
          widgetColor={settings.widgetColor}
          icon={<GovernmentIcon />}
        />
      )}
      {settings.showProducts && (
        <StatCountersWidget
          count={data.govlaunchStatsCount.productsCount}
          countColor={settings.countersColor}
          label="Products"
          labelColor={settings.labelsColor}
          widgetColor={settings.widgetColor}
          icon={<ProductIcon />}
        />
      )}
      {settings.showStories && (
        <StatCountersWidget
          count={data.govlaunchStatsCount.storiesCount}
          countColor={settings.countersColor}
          label="Stories"
          labelColor={settings.labelsColor}
          widgetColor={settings.widgetColor}
          icon={<StoriesIcon />}
        />
      )}
      {settings.showVendors && (
        <StatCountersWidget
          count={data.govlaunchStatsCount.vendorsCount}
          countColor={settings.countersColor}
          label="Vendors"
          labelColor={settings.labelsColor}
          widgetColor={settings.widgetColor}
          icon={<VendorsIcon />}
        />
      )}
    </StatCountersBlockContentContainer>
  );
}

interface IStatCountersBlockContentContainerProps {
  backgroundColor: string;
  children: any;
}

function StatCountersBlockContentContainer({ backgroundColor, children }: IStatCountersBlockContentContainerProps) {
  return (
    <SimpleGrid width="100%" columns={[1, 2, 3, 3]} spacing={["20px", "20px", "20px", "20px"]} bg={backgroundColor}>
      {children}
    </SimpleGrid>
  );
}
