import { faCheck, faEdit, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import * as palette from "@govlaunch/palette";
import { Tooltip } from "antd";
import { useMutation } from "react-apollo";
import {
  IUpdateImageAltMutation,
  IUpdateImageAltMutationVariables,
} from "../../mutations/__generated__/UpdateImageAlt.generated";
import UpdateImageAlt from "../../mutations/UpdateImageAlt";
import TextareaAutosize from "react-textarea-autosize";

interface IImageAltColumnProps {
  url: string;
  alt: string | null;
  imageId: any;
}

export default function ImageAltColumn({ url, alt, imageId }: IImageAltColumnProps) {
  const [isEditting, setIsEditting] = useState(false);
  const showEdit = ![
    "https://s3.amazonaws.com/static.govlaunch.com/resources/gallery/01.png",
    "https://s3.amazonaws.com/static.govlaunch.com/resources/gallery/02.png",
    "https://s3.amazonaws.com/static.govlaunch.com/resources/gallery/03.png",
    "https://s3.amazonaws.com/static.govlaunch.com/resources/gallery/04.png",
    "https://s3.amazonaws.com/static.govlaunch.com/resources/gallery/05.png",
    "https://s3.amazonaws.com/static.govlaunch.com/resources/gallery/06.png",
  ].includes(url);

  if (!isEditting) {
    return (
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>{alt}</div>
        {showEdit && (
          <div
            css={{
              marginLeft: 16,
            }}
          >
            <EditButton setEditting={setIsEditting} />
          </div>
        )}
      </div>
    );
  }

  return <ImageAltColumnEdit alt={alt} imageId={imageId} setEditting={setIsEditting} />;
}

interface IImageAltColumnEditProps {
  alt: string | null;
  imageId: any;
  setEditting: React.Dispatch<React.SetStateAction<boolean>>;
}

function ImageAltColumnEdit({ alt, imageId, setEditting }: IImageAltColumnEditProps) {
  const [state, setState] = useState<string>(alt || "");
  const [updateImageAlt] = useMutation<IUpdateImageAltMutation, IUpdateImageAltMutationVariables>(UpdateImageAlt, {
    refetchQueries: ["GetAllImages", "ImagesCount"],
  });

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <TextareaAutosize
        value={state}
        onChange={(event) => setState(event.target.value)}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key !== "Enter") {
            return;
          }

          if (!state.trim()) {
            return;
          }

          updateImageAlt({
            variables: {
              imageId,
              alt: state.trim(),
            },
          });
          setEditting(false);
        }}
        minRows={1}
        maxRows={4}
        css={{
          height: 42,
          borderRadius: 5,
          border: `1px solid ${palette.lightestGray}`,
          fontSize: 14,
          padding: "5px 10px",
          width: "100%",
          outline: 0,
          "::placeholder": {
            color: palette.sealBlue,
          },
          ":focus": {
            borderColor: palette.primary,
          },
          resize: "none",
        }}
      />
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 16,
        }}
      >
        <SaveButton
          onSave={async () =>
            updateImageAlt({
              variables: {
                imageId,
                alt: state,
              },
            })
          }
          setEditting={setEditting}
          disabled={!state.trim()}
        />
        <CancelButton setEditting={setEditting} />
      </div>
    </div>
  );
}

interface IEditButtonProps {
  setEditting: React.Dispatch<React.SetStateAction<boolean>>;
}

function EditButton({ setEditting }: IEditButtonProps) {
  return (
    <Tooltip title="Edit">
      <div
        onClick={() => setEditting(true)}
        css={{
          cursor: "pointer",
          border: `1px solid ${palette.orange}`,
          borderRadius: 4,
          padding: "8px 8px 8px 12px",
          "&:hover": {
            opacity: 0.8,
          },
        }}
      >
        <FontAwesomeIcon icon={faEdit} color={palette.orange} size="lg" />
      </div>
    </Tooltip>
  );
}

function CancelButton({ setEditting }: IEditButtonProps) {
  return (
    <Tooltip title="Cancel">
      <div
        onClick={() => setEditting(false)}
        css={{
          cursor: "pointer",
          border: `1px solid ${palette.red}`,
          borderRadius: 4,
          padding: "8px 12px 8px 12px",
          "&:hover": {
            opacity: 0.8,
          },
          marginLeft: 8,
        }}
      >
        <FontAwesomeIcon icon={faWindowClose} color={palette.red} size="lg" />
      </div>
    </Tooltip>
  );
}

interface ISaveButtonProps {
  onSave: any;
  setEditting: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

function SaveButton({ onSave, setEditting, disabled = false }: ISaveButtonProps) {
  return (
    <Tooltip title={disabled ? "Alt cannot be empty" : "Save"}>
      <div
        onClick={() => {
          if (disabled) {
            return;
          }

          onSave();
          setEditting(false);
        }}
        css={{
          cursor: "pointer",
          border: `1px solid ${disabled ? palette.mediumGray : palette.greenSuccess}`,
          borderRadius: 4,
          padding: "8px 12px 8px 12px",
          "&:hover": {
            opacity: 0.8,
          },
        }}
      >
        <FontAwesomeIcon icon={faCheck} color={disabled ? palette.mediumGray : palette.greenSuccess} size="lg" />
      </div>
    </Tooltip>
  );
}
