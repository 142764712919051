import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import { Table, TableProps, Tooltip } from "antd";
import { Div } from "glamorous";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { IAllVendorsQuery } from "../../../queries/__generated__/GetAllVendors.generated";
import notUndefinedOrNull from "../../../utils/notUndefinedOrNull";
import VendorActions from "../VendorActions";
//import { SorterResult } from "antd/lib/table/interface";
import { IPlanTier } from "../../../../types/types";
import { getFilteredValue } from "../../../utils/tablesUtils";
import { ITableStateProps } from "./ManageVendors";

/*
function getSortBy(sorter: SorterResult<TVendor>): string | null {
  if (sorter && sorter.columnKey && sorter.order) {
    const direction = sorter.order === "descend" ? "descending" : "ascending";

    if (sorter.columnKey === "name") {
      return `slug:${direction}`;
    }

    if (sorter.columnKey === "productsCount") {
      return `productsCount:${direction}`;
    }

    if (sorter.columnKey === "allProjectsCount") {
      return `allProjectsCount:${direction}`;
    }

    if (sorter.columnKey === "allStoriesCount") {
      return `allStoriesCount:${direction}`;
    }

    if (sorter.columnKey === "membersCount") {
      return `membersCount:${direction}`;
    }

    if (sorter.columnKey === "activatedAt") {
      return `activatedAt:${direction}`;
    }

    if (sorter.columnKey === "tier") {
      return `computed.topProductTier:${direction}`;
    }

    if (sorter.columnKey === "hasCustomLimits") {
      return `customLimits:${direction}`;
    }
  }

  return null;
}
*/

export enum IVendorsTabFilter {
  ALL = "ALL",
  DISRUPTORS = "DISRUPTORS",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
}

type TVendor = NonNullable<NonNullable<IAllVendorsQuery["allVendors"]>["items"]>[0];

type TPlan = {
  name: string;
  isSelectable: boolean;
  stripePlanId: string | null | undefined;
};

type TSubscription = {
  stripeStatus: string;
};

enum IStripeStatus {
  ACTIVE = "active",
  CANCELLED = "cancelled",
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  TRIALING = "trialing",
  PAST_DUE = "past_due",
  UNPAID = "unpaid",
}

interface IVendorsProps<T> {
  allVendors: T[];
  tab: IVendorsTabFilter;
  historyOrigin?: string;
  loading: boolean;
  state: ITableStateProps;
  footer?: TableProps<any>["footer"];
  title?: TableProps<any>["title"];
  onChange: (_: any, filters: any, sorter: any) => void;
  refetch: () => void;
}

export function VendorsTable({
  allVendors,
  tab,
  historyOrigin,
  loading,
  title,
  footer,
  onChange,
  state,
  refetch,
}: IVendorsProps<TVendor>) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        rowGap: 10,
      }}
    >
      <Table<TVendor>
        rowKey="_id"
        loading={loading}
        bordered={true}
        pagination={false}
        scroll={{
          x: true,
        }}
        title={title}
        footer={footer}
        dataSource={allVendors}
        onChange={onChange}
        columns={[
          {
            key: "name",
            title: "Name",
            dataIndex: "name",
            sorter: false,
            //sortOrder: state.sorter && state.sorter.columnKey === "name" ? state.sorter.order : undefined,
            render: (_: any, vendor: TVendor) => (
              <Div display="flex" alignItems="center">
                <ProgressiveImage src={vendor.logo || ""} placeholder="">
                  {(src: string, loading: boolean) => (
                    <ProfilePicture
                      size={32}
                      image={loading ? null : src}
                      disruptor={vendor.isDisruptor}
                      name={vendor.name}
                      css={{
                        whiteSpace: "nowrap",
                      }}
                    />
                  )}
                </ProgressiveImage>

                <Spacing left={5}>
                  <Link to={`${historyOrigin}/${vendor.slug}`}>{vendor.name}</Link>
                </Spacing>
              </Div>
            ),
          },
          {
            title: "Owner",
            dataIndex: "owner",
            filters: [
              {
                text: "Owned",
                value: "owned",
              },
              {
                text: "Not Owned",
                value: "not_owned",
              },
            ],
            filterMultiple: false,
            filteredValue: getFilteredValue(state.hasOwner !== null ? (state.hasOwner ? "owned" : "not_owned") : null),
            render: (owner: TVendor["owner"]) => {
              return owner ? (
                <Div display="flex" alignItems="center">
                  <Spacing left={5}>
                    <Link to={`/users/${owner.slug}`}>{owner.fullName}</Link>
                  </Spacing>
                </Div>
              ) : (
                "Not owned"
              );
            },
          },
          {
            title: "# of Products",
            dataIndex: "productsCount",
            key: "productsCount",
            sorter: false,
            //sortOrder:
            //  state.sorter && state.sorter.columnKey === "productsCount" ? state.sorter.order : undefined,
            width: 100,
          },
          {
            title: "# of Projects",
            dataIndex: "allProjectsCount",
            key: "allProjectsCount",
            sorter: false,
            //sortOrder:
            //  state.sorter && state.sorter.columnKey === "allProjectsCount"
            //    ? state.sorter.order
            //    : undefined,
            width: 100,
          },
          {
            title: "# of Stories",
            dataIndex: "allStoriesCount",
            key: "allStoriesCount",
            sorter: false,
            //sortOrder:
            //  state.sorter && state.sorter.columnKey === "allStoriesCount" ? state.sorter.order : undefined,
            width: 100,
          },
          {
            key: "membersCount",
            title: "# of Users",
            dataIndex: "membersCount",
            sorter: false,
            //sortOrder:
            //  state.sorter && state.sorter.columnKey === "membersCount" ? state.sorter.order : undefined,
            width: 100,
          },
          {
            title: "Tier",
            key: "tier",
            dataIndex: "tier",
            filters: [
              {
                text: "Tier 4",
                value: "4",
              },
              {
                text: "Tier 3",
                value: "3",
              },
              {
                text: "Tier 2",
                value: "2",
              },
              {
                text: "Tier 1",
                value: "1",
              },
              {
                text: "No tier",
                value: "0",
              },
            ],
            filteredValue: state.inTiers,
            sorter: false,
            //sortOrder: state.sorter && state.sorter.columnKey === "tier" ? state.sorter.order : undefined,
          },
          {
            title: "Account",
            dataIndex: "plan",
            sorter: false,
            render: (plan: TPlan) => {
              if (!plan) {
                return "N/A";
              }

              if (plan.name === "Basic") {
                return (
                  <span
                    css={{
                      wordBreak: "break-word",
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    {plan.name}
                  </span>
                );
              }

              if (!plan.isSelectable && plan.stripePlanId) {
                return (
                  <Tooltip title={getGrandfatheredPlanTooltip(plan.stripePlanId)}>
                    <span
                      css={{
                        wordBreak: "break-word",
                        color: plan.name === "Disruptor" ? palette.darkSkyBlue : palette.greenSuccess,
                        fontWeight: "bold",
                        cursor: "pointer",
                        fontSize: 12,
                      }}
                    >
                      {plan.name} 💡
                    </span>
                  </Tooltip>
                );
              }

              return (
                <span
                  css={{
                    wordBreak: "break-word",
                    color: plan.name === "Disruptor" ? palette.darkSkyBlue : palette.greenSuccess,
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  {plan.name}
                </span>
              );
            },
            filters: [
              {
                text: "Basic",
                value: IPlanTier.Basic,
              },
              {
                text: "Makers",
                value: IPlanTier.Makers,
              },
              {
                text: "Makers Pro",
                value: IPlanTier.Makerspro,
              },
              {
                text: "Disruptors",
                value: IPlanTier.Disruptor,
              },
              {
                text: "On Trial",
                value: "on_trial",
              },
              {
                text: "Free",
                value: "free",
              },
            ],
            filteredValue: getFilteredValue(state.plan),
            filterMultiple: false,
          },
          {
            key: "subscription",
            title: "Subscription Status",
            dataIndex: "subscription",
            render: (subscription: TSubscription) => friendlyStatusName(subscription),
            sorter: false,
            filters: [
              {
                text: "Active",
                value: IStripeStatus.ACTIVE,
              },
              {
                text: "Incomplete",
                value: IStripeStatus.INCOMPLETE,
              },
              {
                text: "Incomplete Expired",
                value: IStripeStatus.INCOMPLETE_EXPIRED,
              },
              {
                text: "Trialing",
                value: IStripeStatus.TRIALING,
              },
              {
                text: "Past Due",
                value: IStripeStatus.PAST_DUE,
              },
              {
                text: "Unpaid",
                value: IStripeStatus.UNPAID,
              },
            ],
            filteredValue: getFilteredValue(state.stripeStatus),
            filterMultiple: false,
          },
          {
            title: "Is Sponsoring",
            key: "hasActiveSponsorships",
            dataIndex: "hasActiveSponsorships",
            render: (hasActiveSponsorships: boolean) => {
              if (!hasActiveSponsorships) {
                return (
                  <span
                    css={{
                      color: palette.red,
                    }}
                  >
                    No
                  </span>
                );
              }

              return (
                <span
                  css={{
                    color: palette.greenSuccess,
                  }}
                >
                  Yes
                </span>
              );
            },
          },
          {
            title: "Custom Limits",
            key: "hasCustomLimits",
            dataIndex: "hasCustomLimits",
            filters: [
              {
                text: "Yes",
                value: "has_custom_limits",
              },
              {
                text: "No",
                value: "no_custom_limits",
              },
            ],
            filteredValue: getFilteredValue(
              state.hasCustomLimits !== null
                ? state.hasCustomLimits
                  ? "has_custom_limits"
                  : "no_custom_limits"
                : null,
            ),
            filterMultiple: false,
            sorter: false,
            //sortOrder:
            //  state.sorter && state.sorter.columnKey === "hasCustomLimits" ? state.sorter.order : undefined,
            render: (hasCustomLimits: boolean) => {
              if (!hasCustomLimits) {
                return (
                  <span
                    css={{
                      color: palette.red,
                    }}
                  >
                    No
                  </span>
                );
              }

              return (
                <span
                  css={{
                    color: palette.greenSuccess,
                  }}
                >
                  Yes
                </span>
              );
            },
          },
          tab !== IVendorsTabFilter.ACTIVE && tab !== IVendorsTabFilter.INACTIVE
            ? {
                key: "active",
                title: "Active",
                render: (vendor: TVendor) => {
                  if (vendor.activatedAt) {
                    return (
                      <Tooltip title={moment(vendor.activatedAt).format("MM/DD/YYYY")}>
                        <span
                          css={{
                            color: palette.greenSuccess,
                          }}
                        >
                          Yes
                        </span>
                      </Tooltip>
                    );
                  }

                  return (
                    <span
                      css={{
                        color: palette.red,
                      }}
                    >
                      No
                    </span>
                  );
                },
                filters: [
                  {
                    text: "Active",
                    value: "active",
                  },
                  {
                    text: "Not Active",
                    value: "not_active",
                  },
                ],
                filterMultiple: false,
              }
            : null,
          tab === IVendorsTabFilter.ACTIVE
            ? {
                title: "Activated",
                key: "activatedAt",
                sorter: false,
                //sortOrder:
                //  state.sorter && state.sorter.columnKey === "activatedAt" ? state.sorter.order : undefined,
                render: (vendor: TVendor) =>
                  vendor.activatedAt ? moment(vendor.activatedAt).format("MM/DD/YYYY") : "N/A",
              }
            : null,
          tab === IVendorsTabFilter.ARCHIVED
            ? {
                title: "Archived At",
                render: (vendor: TVendor) => moment(vendor.archivedAt).format("MM/DD/YYYY"),
              }
            : null,
          tab === IVendorsTabFilter.ARCHIVED
            ? {
                title: "Archived By",
                render: (vendor: TVendor) => {
                  if (vendor.archivedBy) {
                    return <Link to={`${historyOrigin}/${vendor.archivedBy.slug}`}>{vendor.archivedBy.fullName}</Link>;
                  }

                  return "N/A";
                },
              }
            : null,
          {
            title: "Actions",
            render: (vendor: TVendor) => {
              return <VendorActions vendor={vendor} refetch={refetch} />;
            },
          },
        ].filter(notUndefinedOrNull)}
      />
    </div>
  );
}

VendorsTable.propTypes = {
  tab: PropTypes.string.isRequired,
  sortBy: PropTypes.string,
};

VendorsTable.defaultProps = {
  sortBy: null,
};

function getGrandfatheredPlanTooltip(stripePlanId: string) {
  if (stripePlanId === "plan-makers-monthly") {
    return "Old Makers Monthly";
  }

  if (stripePlanId === "plan-makers-annually") {
    return "Old Makers Annually";
  }

  return "Custom plan";
}

function friendlyStatusName(subscription: TSubscription | null | undefined) {
  if (!subscription) {
    return "N/A";
  }

  if (subscription.stripeStatus === IStripeStatus.ACTIVE) {
    return (
      <span
        css={{
          fontWeight: "bold",
          color: palette.greenSuccess,
        }}
      >
        Active
      </span>
    );
  }

  if (subscription.stripeStatus === IStripeStatus.CANCELLED) {
    return (
      <span
        css={{
          fontWeight: "bold",
          color: palette.red,
        }}
      >
        Cancelled
      </span>
    );
  }

  if (subscription.stripeStatus === IStripeStatus.INCOMPLETE) {
    return "Incomplete";
  }

  if (subscription.stripeStatus === IStripeStatus.INCOMPLETE_EXPIRED) {
    return "Incomplete Expired";
  }

  if (subscription.stripeStatus === IStripeStatus.PAST_DUE) {
    return (
      <span
        css={{
          fontWeight: "bold",
          color: palette.red,
        }}
      >
        Past Due
      </span>
    );
  }

  if (subscription.stripeStatus === IStripeStatus.TRIALING) {
    return (
      <span
        css={{
          fontWeight: "bold",
          color: palette.blue,
        }}
      >
        Trialing
      </span>
    );
  }

  if (subscription.stripeStatus === IStripeStatus.UNPAID) {
    return (
      <span
        css={{
          fontWeight: "bold",
          color: palette.red,
        }}
      >
        Unpaid
      </span>
    );
  }

  return "N/A";
}
