import React from "react";
import { useQuery } from "react-apollo";
import DocumentTitle from "react-document-title";
import { useNavigate, useParams } from "react-router";
import GetTimezoneByCountryCode from "../../queries/GetTimezoneByCountryCode";
import {
  IGetTimezoneByCountryCodeQuery,
  IGetTimezoneByCountryCodeQueryVariables,
} from "../../queries/__generated__/GetTimezoneByCountryCode.generated";
import * as palette from "@govlaunch/palette";
import { LoadingIndicator } from "@govlaunch/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import EditCountryTimezoneForm from "./EditCountryTimezoneForm";

export default function EditCountryTimezonePage() {
  const navigate = useNavigate();
  const params = useParams();

  const { data, loading } = useQuery<IGetTimezoneByCountryCodeQuery, IGetTimezoneByCountryCodeQueryVariables>(
    GetTimezoneByCountryCode,
    {
      variables: {
        countryCode: params.countryCode || "",
      },
    },
  );

  return (
    <DocumentTitle title="Timezone | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
          padding: 20,
          overflow: "auto",
        }}
      >
        {loading && (
          <div>
            <LoadingIndicator />
          </div>
        )}

        <div
          css={{
            paddingBottom: 8,
          }}
        >
          <a
            css={{
              color: palette.primary,
              cursor: "pointer",
            }}
            onClick={() => navigate("/newsletter/timezones")}
          >
            <span css={{
 marginRight: 5 
}}
            >
              <FontAwesomeIcon icon={faClock} size="sm" />
            </span>
            All Timezones
          </a>
        </div>

        {(!data || !data.getTimezoneByCountryCode) && (
          <div css={{
 color: palette.red 
}}
          >Country settings not found</div>
        )}

        {data && data.getTimezoneByCountryCode && (
          <EditCountryTimezoneForm countryTimezone={data.getTimezoneByCountryCode} />
        )}
      </div>
    </DocumentTitle>
  );
}
