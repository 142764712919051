import React from "react";
import { IAboutContentType } from "../../../../types/types";
import FilestackUploadField from "../../form/fields/FilestackUploadField";
import SelectField from "../../form/fields/SelectField";
import TextareaField from "../../form/fields/TextareaField";
import UrlField from "../../form/fields/UrlField";

interface IVideoContentFieldProps {
  fieldName: string;
  videoContentType: IAboutContentType;
  showSizeField?: boolean;
  sizeFieldName?: string;
  recommendedImageDimensions?: string;
}

export default function VideoContentField({
  fieldName,
  videoContentType,
  showSizeField,
  sizeFieldName,
  recommendedImageDimensions,
}: IVideoContentFieldProps) {
  if (videoContentType === IAboutContentType.Image) {
    return (
      <FilestackUploadField
        fieldName={fieldName}
        label="Heading Banner Content"
        recommendedDimensions={recommendedImageDimensions}
      />
    );
  }

  if (videoContentType === IAboutContentType.Video) {
    return (
      <>
        <UrlField fieldName={fieldName} label="Video url" placeholder="Paste video URL..." />
        {showSizeField && sizeFieldName && (
          <SelectField
            fieldName={sizeFieldName || "bannerContentSize"}
            label="Video size"
            options={[
              {
                value: "small",
                label: "Small",
              },
              {
                value: "large",
                label: "large",
              },
            ]}
            defaultValue="small"
          />
        )}
      </>
    );
  }

  return <TextareaField fieldName={fieldName} label="Simplecast code" placeholder="Paste simplecast embed code" />;
}
