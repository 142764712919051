import React from "react";
import { Field } from "react-final-form";
import { ITagType } from "../../../../types/types";
import xor from "lodash/xor";
import { ITagFormValues } from "./TagForm";
import { Checkbox } from "antd";
import FieldValidationError from "../../../components/form/FieldValidationError";

interface ITagTypesFieldProps {
  values: ITagFormValues;
}

export default function TagTypesField({ values }: ITagTypesFieldProps) {
  return (
    <Field name="types">
      {({ input, meta }) => (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              css={{
                fontSize: 12,
                fontWeight: 500,
                letterSpacing: 0.5,
                textTransform: "uppercase",
                display: "flex",
              }}
            >
              Types
            </label>

            <FieldValidationError meta={meta} />
          </div>

          <div
            css={{
              display: "grid",
              gridColumnGap: 12,
              gridTemplateColumns: "repeat(6, max-content)",
              "> label": {
                display: "inline-flex",
                alignItems: "center",
                userSelect: "none",
                WebkitUserSelect: "none",
              },
              "& label > input": {
                marginRight: 6,
              },
              marginTop: 10,
            }}
          >
            <div>
              <Checkbox
                checked={input.value.includes("PROJECT")}
                onChange={() => input.onChange(xor(input.value, ["PROJECT"]))}
                disabled={values.types.includes(ITagType.Award) || values.types.includes(ITagType.Custom)}
              >
                Project
              </Checkbox>
            </div>

            <div>
              <Checkbox
                checked={input.value.includes("PRODUCT")}
                onChange={() => input.onChange(xor(input.value, ["PRODUCT"]))}
                disabled={values.types.includes(ITagType.Award) || values.types.includes(ITagType.Custom)}
              >
                Product
              </Checkbox>
            </div>

            <div>
              <Checkbox
                checked={input.value.includes("STORY")}
                onChange={() => input.onChange(xor(input.value, ["STORY"]))}
                disabled={values.types.includes(ITagType.Award) || values.types.includes(ITagType.Custom)}
              >
                Story
              </Checkbox>
            </div>

            <div>
              <Checkbox
                checked={input.value.includes("CUSTOM")}
                onChange={() => {
                  input.onChange(input.value.includes("CUSTOM") ? xor(input.value, ["CUSTOM"]) : ["CUSTOM"]);
                }}
              >
                Custom
              </Checkbox>
            </div>

            <div>
              <Checkbox
                checked={input.value.includes("DEPARTMENT")}
                onChange={() => {
                  input.onChange(xor(input.value, ["DEPARTMENT"]));
                }}
                disabled={values.types.includes(ITagType.Award) || values.types.includes(ITagType.Custom)}
              >
                Department
              </Checkbox>
            </div>

            <div>
              <Checkbox
                checked={input.value.includes("AWARD")}
                onChange={() => {
                  input.onChange(input.value.includes("AWARD") ? xor(input.value, ["AWARD"]) : ["AWARD"]);
                }}
              >
                Award
              </Checkbox>
            </div>
          </div>
        </div>
      )}
    </Field>
  );
}
