import React from "react";
import { useQuery } from "react-apollo";
import TopCollections from "../../../../queries/TopCollections";
import {
  ITopCollectionsQuery,
  ITopCollectionsQueryVariables,
} from "../../../../queries/__generated__/TopCollections.generated";
import { IBlockLayout, ICollectionsBlockData } from "../blockProps";
import CenteredLoadingSpinner from "../CenteredLoadingSpinner";
import CollectionsAsRows from "./CollectionsAsRows";
import CollectionsAsTags from "./CollectionsAsTags";

interface ITopCollectionsBlockDataProps {
  block: IBlockLayout;
}

export default function TopCollectionsBlockData({ block }: ITopCollectionsBlockDataProps) {
  const { data, loading } = useQuery<ITopCollectionsQuery, ITopCollectionsQueryVariables>(TopCollections, {
    variables: {
      limit: block.blockData.itemLimit || 10,
    },
    fetchPolicy: "network-only",
  });

  if (!data || loading) {
    return <CenteredLoadingSpinner />;
  }

  const collections = data && data.topCollections ? data.topCollections || [] : [];
  const displayAsTags = (block.blockData as ICollectionsBlockData).displayAsTags;

  return (
    <>
      {displayAsTags ? (
        <CollectionsAsTags collections={collections} />
      ) : (
        <CollectionsAsRows collections={collections} />
      )}
    </>
  );
}
