import { Table, TableProps } from "antd";
import React from "react";
import { IGetLandingPageEventsQuery } from "../../../queries/__generated__/GetLandingPageEvents.generated";
import * as palette from "@govlaunch/palette";
import { Link } from "react-router-dom";
import moment from "moment";
import EventsTableActions from "./EventsTableActions";
import { ITablePaginationState } from "../../../components/table/TablePagination";
import { SortOrder } from "antd/lib/table/interface";

type TEvent = NonNullable<NonNullable<IGetLandingPageEventsQuery["landingPageEvents"]>["items"]>[0];

export interface ILandingPageEventsTableState {
  sortColumnKey: string;
  sortColumnOrder: SortOrder;
  cursor: ITablePaginationState;
}

interface ILandingPageEventsTableProps {
  events: TEvent[];
  loading?: boolean;
  footer?: TableProps<any>["footer"];
  onChange?: any;
  state?: ILandingPageEventsTableState;
}

export default function LandingPageEventsTable({
  events,
  loading,
  footer,
  onChange,
  state,
}: ILandingPageEventsTableProps) {
  return (
    <Table<TEvent>
      dataSource={events}
      bordered={true}
      rowKey="_id"
      loading={loading}
      pagination={false}
      style={{
        backgroundColor: palette.white,
      }}
      scroll={{
        x: true,
      }}
      footer={footer}
      onChange={onChange}
      columns={[
        {
          title: "Title",
          dataIndex: "title",
          render: (_, item) => <Link to={`/events/${item._id}`}>{item.title}</Link>,
        },
        {
          title: "Start Date",
          dataIndex: "startDate",
          sorter: true,
          sortOrder: state && state.sortColumnKey === "startDate" ? state.sortColumnOrder : undefined,
          key: "startDate",
          render: (startDate) => moment(startDate).format("MM/DD/YYYY"),
        },
        {
          title: "Location",
          dataIndex: "location",
        },
        {
          title: "Created At",
          dataIndex: "createdAt",
          sorter: true,
          sortOrder: state && state.sortColumnKey === "createdAt" ? state.sortColumnOrder : undefined,
          key: "createdAt",
          render: (createdAt) => moment(createdAt).format("MM/DD/YYYY"),
        },
        {
          title: "Actions",
          render: (_, event) => {
            return <EventsTableActions eventId={event._id} />;
          },
        },
      ]}
    />
  );
}
