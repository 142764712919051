import gql from "graphql-tag";

export default gql`
  mutation ToggleAboutPageConfigActivation($configId: ObjectId!) {
    toggleAboutPageConfigActivation(configId: $configId) {
      _id
      active
    }
  }
`;
