import React, { useState } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import { parseState } from "../../../utils/tablesUtils";
import TablePagination, { DEFAULT_CURSOR } from "../../../components/table/TablePagination";
import DuplicatedUsersTable, { IDuplicatedUsersTableState } from "./DuplicatedUsersTable";
import { useQuery } from "react-apollo";
import {
  IDuplicatedUsersQuery,
  IDuplicatedUsersQueryVariables,
} from "../../../queries/__generated__/GetDuplicatedUsers.generated";
import GetDuplicatedUsers from "../../../queries/GetDuplicatedUsers";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import TableAddStateToQueryString from "../../../components/table/TableAddStateToQueryString";
import TableOnSearch from "../../../components/table/TableOnSearch";

interface IManageDuplicatedUsersProps {
  search: string | null;
  historyOrigin?: string;
}

export default function ManageDuplicatedUsers({
  search,
  historyOrigin = "/duplicatedUsers",
}: IManageDuplicatedUsersProps) {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<IDuplicatedUsersTableState>(initialFiltersState);

  const { data, loading } = useQuery<IDuplicatedUsersQuery, IDuplicatedUsersQueryVariables>(GetDuplicatedUsers, {
    variables: {
      search,
      limit: 10,
      cursor: calculateAndEncodeCursor(state.cursor),
    },
    fetchPolicy: "network-only",
  });

  const users = (data && data.duplicatedUsers && data.duplicatedUsers.items) || [];
  const totalCount =
    data && data.duplicatedUsers && data.duplicatedUsers.pageInfo ? data.duplicatedUsers.pageInfo.totalCount || 0 : 0;

  return (
    <>
      <TableAddStateToQueryString tableState={state} historyOrigin={historyOrigin} query={query} />
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
                page: 1,
                size: state.cursor.size,
              },
            });
          }
        }}
      />
      <div
        css={{
          maxWidth: 960,
        }}
      >
        <DuplicatedUsersTable
          users={users}
          loading={loading}
          state={state}
          footer={() => (
            <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
          )}
        />
      </div>
    </>
  );
}
