import React from "react";
import * as palette from "@govlaunch/palette";
import PropTypes from "prop-types";

interface IResourceFieldErrorProps {
  meta: Partial<{
    touched: boolean;
    error: any;
    submitError: any;
  }>;
}

export default function ResourceFieldError({ meta }: IResourceFieldErrorProps) {
  const error = meta.error || meta.submitError;

  if (!meta.touched || !error) {
    return null;
  }

  return (
    <p
      css={{
        margin: "5px 0 0 0",
        color: palette.red,
        fontSize: 11,
        textTransform: "uppercase",
      }}
    >
      {error}
    </p>
  );
}

ResourceFieldError.propTypes = {
  meta: PropTypes.object.isRequired,
};
