import * as palette from "@govlaunch/palette";
import { Spacing, SpacingBetween } from "@govlaunch/spacers";
import { Button } from "antd";
import { A, Div, Strong, Textarea } from "glamorous";
import React, { HTMLProps } from "react";
import { Field } from "react-final-form";
import { IAttachmentInput, IUser } from "../../../types/types";
import Filestack from "../../Filestack";
import AttachmentIcon from "../../components/icons/AttachmentIcon";
import { VerificationType } from "./VerifyUser";

interface IVerificationFormProps {
  user: Pick<IUser, "_id" | "email" | "fullName" | "linkedinUrl" | "linkedinId">;
  verificationType: VerificationType;
}

const VerificationForm = ({
  user,
  verificationType,
  ...props
}: IVerificationFormProps & HTMLProps<HTMLFormElement>) => (
  <form {...props}>
    <Spacing bottom={10}>
      <Strong>Email</Strong>
      <div>{user.email || "N/A"}</div>
    </Spacing>

    {user.linkedinUrl ? (
      <Spacing bottom={10}>
        <Strong>LinkedIn</Strong>
        <A href={user.linkedinUrl} rel="noopener noreferrer" target="_blank">
          {user.linkedinUrl}
        </A>
      </Spacing>
    ) : (
      <Spacing bottom={10}>
        <Strong>LinkedIn</Strong>
        <div>{user.linkedinId || "N/A"}</div>
      </Spacing>
    )}

    {verificationType !== VerificationType.Citizen && (
      <>
        <Strong>Explanation (optional)</Strong>

        <Field name="explanation">
          {({ input }) => (
            <Spacing top={5}>
              <Textarea
                {...input}
                width="100%"
                border={`solid 1px ${palette.lightestGray}`}
                borderRadius={4}
                resize="none"
                outline="none"
                padding={5}
                css={{
                  "&:focus": {
                    border: `solid 1px ${palette.primary}`,
                  },
                }}
                placeholder={`Type the reasons you're verifying ${user.fullName}`}
              />
            </Spacing>
          )}
        </Field>

        <Field<IAttachmentInput[]> name="attachments">
          {({ input }) => (
            <>
              <Strong>Attachments (optional)</Strong>

              {input.value.length > 0 && (
                <Spacing top={5}>
                  <SpacingBetween top={10}>
                    {input.value.map(({ url, filename, type }: any) => (
                      <A
                        key={url!}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={url!}
                        borderRadius={4}
                        backgroundColor={palette.white}
                        padding={5}
                        border={`solid 1px ${palette.sealBlue}`}
                        display="flex"
                        alignItems="center"
                      >
                        {filename}

                        <Div marginLeft="auto">
                          <AttachmentIcon type={type || ""} width={18} height={18} />
                        </Div>
                      </A>
                    ))}
                  </SpacingBetween>
                </Spacing>
              )}

              <Spacing top={10}>
                <Filestack
                  options={{
                    fromSources: ["local_file_system"],
                    maxFiles: 10,
                  }}
                  onSuccess={({ filesUploaded }: any) => {
                    input.onChange([
                      ...input.value,
                      ...filesUploaded.map((file: any) => {
                        return {
                          url: file.url,
                          size: file.size,
                          type: file.mimetype,
                          filename: file.filename,
                        };
                      }),
                    ]);
                  }}
                  customRender={({ onPick }: any) => (
                    <Button onClick={onPick} htmlType="button" size="small">
                      Add
                    </Button>
                  )}
                />
              </Spacing>
            </>
          )}
        </Field>
      </>
    )}
  </form>
);

export default VerificationForm;
