export default function prefixWithHttpIfNecessary(withoutHttp: string, secure?: boolean): string | null {
  if (!withoutHttp) {
    return null;
  }

  if (withoutHttp.trim().length === 0) {
    return null;
  }

  if (withoutHttp.toLowerCase().startsWith("http")) {
    return withoutHttp;
  }

  if (secure) {
    return `https://${withoutHttp}`;
  } else {
    return `http://${withoutHttp}`;
  }
}
