import React from "react";
import { Field } from "react-final-form";
import { SearchAlgoliaIndex } from "@govlaunch/algolia";
import { StoryProductSelect } from "@govlaunch/select";
import { useSelfie } from "../../auth/Selfie";

export default function IntegrationsField() {
  const user = useSelfie();

  return (
    <Field name="integrations">
      {({ input }) => (
        <SearchAlgoliaIndex index="products" user={user}>
          {({ results: products, searchValue: inputValue, onChange: onInputValueChange }: any) => (
            <StoryProductSelect
              autoFocus={false}
              products={Array.isArray(input.value) ? input.value : []}
              inputValue={inputValue}
              onInputValueChange={onInputValueChange}
              selectableProducts={products}
              onChange={input.onChange}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
              css={{
                minWidth: "auto",
                zIndex: 999,
              }}
            />
          )}
        </SearchAlgoliaIndex>
      )}
    </Field>
  );
}
