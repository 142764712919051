import { getOr, pick } from "lodash/fp";
import {
  IWebProductDeployment,
  IDesktopProductDeployment,
  IAppStoreProductDeployment,
  IPlayStoreProductDeployment,
} from "../../../types/types";
import omit from "lodash/fp/omit";

export function convertProductToInitialValues(product: any) {
  const outsideIntegrations = (product.outsideIntegrations || []).map((outsideProduct: any) => {
    return {
      ...outsideProduct,
      _id: `@${outsideProduct._id}`,
    };
  });

  const webDeployment = product.deployment.find(
    ({ __typename }: { __typename: string }) => __typename === "WebProductDeployment",
  );
  const desktopDeployment = product.deployment.find(
    ({ __typename }: { __typename: string }) => __typename === "DesktopProductDeployment",
  );
  const appStoreDeployment = product.deployment.find(
    ({ __typename }: { __typename: string }) => __typename === "AppStoreProductDeployment",
  );
  const playStoreDeployment = product.deployment.find(
    ({ __typename }: { __typename: string }) => __typename === "PlayStoreProductDeployment",
  );

  return {
    addingGovernments: [],
    removingGovernments: [],
    company: product.company && product.company._id,
    governments: product.governments,
    _id: product._id,
    name: product.name,
    description: product.description,
    link: product.link,
    logo: product.logo,
    about: product.about,
    resources: (product.resources || []).map((resource: any) => {
      if (resource.__typename === "TextResource") {
        return {
          ...resource,
          type: "text",
        };
      }

      if (resource.__typename === "DownloadResource") {
        return {
          ...resource,
          type: "download",
        };
      }

      return {
        ...resource,
        type: "link",
      };
    }),
    integrations: (product.integrations || []).concat(outsideIntegrations as []),
    solutions: product.solutions.map((solution: any) => {
      return {
        label: solution.name,
        value: solution._id,
      };
    }),
    mobileResponsive: webDeployment ? (webDeployment as IWebProductDeployment).mobileResponsive : false,
    browsers: webDeployment ? (webDeployment as IWebProductDeployment).supportedBrowsers : [],
    platforms: desktopDeployment ? (desktopDeployment as IDesktopProductDeployment).platforms : [],
    pricingPage: product.pricingPage,
    tags: product.tags.map((tag: any) => ({
      value: tag._id,
      label: tag.name,
    })),
    contactPage: product.contactPage,
    freeTrial: product.freeTrial ? product.freeTrial.linkHref : null,
    appStore: appStoreDeployment ? (appStoreDeployment as IAppStoreProductDeployment).linkHref : null,
    playStore: playStoreDeployment ? (playStoreDeployment as IPlayStoreProductDeployment).linkHref : null,
    releasedYear: product.releasedYear,
    certifications: product.certifications,
    highlights: product.highlights,
    assistiveTechnologiesCompatibility: product.assistiveTechnologiesCompatibility,
    newsworthy: product.newsworthy,
    screenshots: product.screenshots,
    videos: product.videos,
    openSource: product.openSource ? product.openSource.linkHref : null,
    allowRequestTranslation: product.allowRequestTranslation,
    socialMedia: {
      twitter: getOr(null, "socialMedia.twitter", product),
      facebook: getOr(null, "socialMedia.facebook", product),
      linkedin: getOr(null, "socialMedia.linkedin", product),
      youtube: getOr(null, "socialMedia.youtube", product),
      instagram: getOr(null, "socialMedia.instagram", product),
    },
    languagesOffered: product.languagesOffered
      ? {
          value: product.languagesOffered,
          label: product.languagesOffered,
        }
      : null,
  };
}

export function convertValuesToVariables(values: any): any {
  const integrations = values.integrations
    ? values.integrations
        .filter(({ _id }: { _id: string }) => _id && !_id.startsWith("@"))
        .map(({ _id }: { _id: string }) => _id)
    : [];
  const outsideIntegrations = values.integrations
    ? values.integrations.filter(({ _id }: { _id: string }) => !_id || _id.startsWith("@")).map(pick(["name", "url"]))
    : [];

  const deployments = [];

  if (values.appStore) {
    deployments.push({
      type: "APP_STORE",
      linkHref: values.appStore,
    });
  }

  if (values.playStore) {
    deployments.push({
      type: "PLAY_STORE",
      linkHref: values.playStore,
    });
  }

  if (values.browsers.length > 0 || values.mobileResponsive) {
    deployments.push({
      type: "WEB",
      supportedBrowsers: values.browsers,
      mobileResponsive: values.mobileResponsive || false,
    });
  }

  if (values.platforms.length > 0) {
    deployments.push({
      type: "DESKTOP",
      platforms: values.platforms,
    });
  }

  return {
    name: values.name,
    description: values.description,
    link: prefixWithHttpIfNecessary(values.link || null),
    logo: values.logo,
    about: values.about,
    screenshots: values.screenshots.map(omit(["__typename"])),
    pricingPage: values.pricingPage || null,
    contactPage: values.contactPage || null,
    freeTrial: values.freeTrial || null,
    assistiveTechnologiesCompatibility: values.assistiveTechnologiesCompatibility,
    socialMedia: values.socialMedia,
    highlights: values.highlights,
    certifications: values.certifications,
    openSource: values.openSource || null,
    deployment: deployments,
    tags: values.tags.map(({ value }: any) => value),
    videos: values.videos,
    integrations,
    solutions: values.solutions.map(({ value }: any) => value),
    resources: values.resources.map(pick(["type", "url", "title", "body"])),
    outsideIntegrations,
    newsworthy: values.newsworthy ? values.newsworthy.filter(({ url }: { url: any }) => url) : [],
    releasedYear: values.releasedYear ? values.releasedYear.toString() : null,
    languagesOffered: values.languagesOffered ? values.languagesOffered.value : null,
    allowRequestTranslation: values.allowRequestTranslation,
  };
}

function prefixWithHttpIfNecessary(withoutHttp: string) {
  if (!withoutHttp) {
    return null;
  }

  if (withoutHttp.trim().length === 0) {
    return null;
  }

  if (withoutHttp.startsWith("http")) {
    return withoutHttp;
  }

  return `http://${withoutHttp}`;
}
