import React from "react";
import { INewsletterCollection } from "../../../../types/types";
import CampaignPreviewCollectionCard from "./CampaignPreviewCollectionCard";

interface ICampaignPreviewCollectionsProps {
  collections: Array<INewsletterCollection>;
}

export default function CampaignPreviewCollections({ collections }: ICampaignPreviewCollectionsProps) {
  const collectionsLink = getColectionsLink();
  const collectionsLineA = collections.slice(0, 3);
  const collectionsLineB = collections.slice(3, 6);

  return (
    <tr>
      <td
        align="left"
        style={{
          margin: 0,
          paddingTop: "20px",
          paddingBottom: "20px",
          paddingLeft: "6px",
        }}
      >
        <table
          cellPadding="0"
          cellSpacing="0"
          style={{
            borderCollapse: "collapse",
            borderSpacing: "0px",
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td
                align="center"
                valign="top"
                style={{
                  padding: "0px",
                  margin: "0px",
                  width: "100%",
                }}
              >
                <table
                  cellPadding="0"
                  cellSpacing="0"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0px",
                    borderRadius: "4px",
                    width: "100%",
                  }}
                  role="presentation"
                >
                  <tbody>
                    <tr>
                      <td
                        align="left"
                        style={{
                          padding: 0,
                          margin: 0,
                          paddingTop: "5px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <h1
                          style={{
                            margin: 0,
                            lineHeight: "24px",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            color: "#444444",
                            textAlign: "center",
                          }}
                        >
                          Recommended Collections
                        </h1>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="center"
                        style={{
                          margin: 0,
                          paddingBottom: "10px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <p
                          style={{
                            display: "inline",
                            margin: 0,
                            WebkitTextSizeAdjust: "none",
                            //@ts-ignore
                            msTextSizeAdjust: "none",
                            lineHeight: "17px",
                            color: "#666666",
                            fontSize: "11px",
                          }}
                        >
                          Curated projects, products and stories searchable by category.
                        </p>{" "}
                        <a
                          href={collectionsLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "inline",
                            margin: 0,
                            WebkitTextSizeAdjust: "none",
                            //@ts-ignore
                            msTextSizeAdjust: "none",
                            textDecoration: "none",
                            fontSize: "11px",
                            lineHeight: "17px",
                          }}
                        >
                          See more
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="center"
                        style={{
                          margin: 0,
                          padding: 0,
                          paddingTop: "5px",
                        }}
                      >
                        <table
                          cellPadding="0"
                          cellSpacing="0"
                          style={{
                            borderCollapse: "separate",
                            borderSpacing: "0px",
                            width: "100%",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                align="center"
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  height: "100%",
                                }}
                              >
                                {collectionsLineA.map((collection, index) => (
                                  <div
                                    key={`collection_${index}`}
                                    style={{
                                      display: "inline-block",
                                    }}
                                  >
                                    <CampaignPreviewCollectionCard collection={collection} />
                                  </div>
                                ))}
                              </td>
                            </tr>
                            {collectionsLineB && collectionsLineA.length > 0 && (
                              <tr>
                                <td
                                  align="center"
                                  style={{
                                    margin: 0,
                                    padding: 0,
                                    height: "100%",
                                    marginTop: "10px",
                                  }}
                                >
                                  {collectionsLineB.map((collection, index) => (
                                    <div
                                      key={`collection_${index + 3}`}
                                      style={{
                                        display: "inline-block",
                                      }}
                                    >
                                      <CampaignPreviewCollectionCard collection={collection} />
                                    </div>
                                  ))}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}

function getColectionsLink() {
  if (process.env.GOVLAUNCH_ENV === "production") {
    return "https://www.govlaunch.com/collections";
  }

  if (process.env.GOVLAUNCH_ENV === "qa") {
    return "https://qa.govlaunch.com/collections";
  }

  return "https://localhost:3000/collections";
}
