import gql from "graphql-tag";

export default gql`
  fragment AboutPageConfigFields on AboutPageConfig {
    _id
    name
    active

    createdAt
    createdBy {
      ... on PendingUser {
        _id
        fullName
      }
      ... on VendorUser {
        _id
        fullName
      }
      ... on GovernmentUser {
        _id
        fullName
      }
    }

    config {
      headingText
      headingTextFontColor
      subtitleText
      subtitleTextFontColor
      bannerBackgroundSrc
      bannerBackgroundDescription
      bannerContentType
      bannerContentText
      bannerContentSize
      bannerContentDescription

      boxesSectionBackgroundColor
      leftButtonText
      leftButtonBackgroundColor
      leftButtonHoverColor
      leftButtonBorderColor
      leftButtonTextColor
      leftButtonHref

      rightButtonText
      rightButtonBackgroundColor
      rightButtonHoverColor
      rightButtonBorderColor
      rightButtonTextColor
      rightButtonHref

      leftBox {
        ... on TextSideContent {
          iconDescription
          iconSrc
          title
          titleFontColor
          text
          textFontColor
          textHref
          textHrefHoverColor
          width
          height
        }
        ... on CarouselSideContent {
          images {
            src
            alt
          }
          text
          textHref
          textHrefHoverColor
          buttonText
          buttonFontColor
          buttonBackgroundColor
          buttonHref
        }
      }
      rightBox {
        ... on TextSideContent {
          iconDescription
          iconSrc
          title
          titleFontColor
          text
          textFontColor
          textHref
          textHrefHoverColor
          width
          height
        }
        ... on CarouselSideContent {
          images {
            src
            alt
          }
          text
          textHref
          textHrefHoverColor
          buttonText
          buttonFontColor
          buttonBackgroundColor
          buttonHref
        }
      }

      videoSectionTitle
      videoSectionBackground
      videoSectionFontColor
      videoSectionContentType
      videoSectionContentText

      joinSectionBackground
      joinSectionTitle
      joinSectionTitleFontColor
      joinSectionSubtitle
      joinSectionSubtitleFontColor
      joinSectionButtonText
      joinSectionButtonHref
      joinSectionButtonColor
      joinSectionButtonFontColor

      logoSectionBackgroundColor
      logoUrls {
        imageUrl
        url
        description
      }
    }
  }
`;
