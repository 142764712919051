import gql from "graphql-tag";

export default gql`
  mutation EditPost($id: ObjectId!, $post: PostInput!) {
    editPost(id: $id, post: $post) {
      ... on GovernmentPost {
        _id
        body
        audience
        featuredAt
        government {
          _id
          name
        }
        attachments {
          mimeType
          filename
          sizeInBytes
          url
        }
        links {
          addedAt
          description
          title
          favicon
          siteName
          url
        }
      }
      ... on GroupPost {
        _id
        body
        audience
        featuredAt
        group {
          _id
          name
        }
        attachments {
          mimeType
          filename
          sizeInBytes
          url
        }
        links {
          addedAt
          description
          title
          favicon
          siteName
          url
        }
      }
    }
  }
`;
