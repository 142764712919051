import * as palette from "@govlaunch/palette";

import { Button, Filestack, Input } from "@govlaunch/core";
import React, { useState } from "react";

import PropTypes from "prop-types";

interface IFileUploaded {
  filename: string;
  mimetype: string;
  size: string | number;
  url: string;
}

interface IFileProps {
  title: string;
  mimeType: string;
  size: string | number;
  url: string;
}

interface IAttachmentInputProps {
  onChange: (values: IFileProps) => any;
}

export default function AttachmentInput({ onChange }: IAttachmentInputProps) {
  const [title, setTitle] = useState("");

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: 10,
      }}
    >
      <Input
        placeholder="Title (optional)"
        value={title}
        onChange={(event: React.SyntheticEvent) => setTitle((event.target as HTMLInputElement).value)}
      />

      <Filestack
        onSuccess={({ filesUploaded }: { filesUploaded: IFileUploaded[] }) => {
          const file = filesUploaded[0];

          // clear the input...
          setTitle("");

          onChange({
            title: title || file.filename,
            mimeType: file.mimetype,
            size: file.size,
            url: file.url,
          });
        }}
        customRender={({ onPick }: { onPick: React.SyntheticEvent }) => (
          <Button color={palette.innovatorBlue} theme="secondary" onClick={onPick}>
            Upload your file
          </Button>
        )}
      />
    </div>
  );
}

AttachmentInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};
