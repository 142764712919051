import gql from "graphql-tag";

export default gql`
  query AllGroupRequests {
    allGroupRequests {
      _id
      createdAt
      status
      ... on GovernmentGroupRequest {
        government {
          _id
          name
        }
        possibleGovernments {
          _id
          name
        }
      }

      ... on VendorGroupRequest {
        vendor {
          _id
          name
        }
        possibleVendors {
          _id
          name
        }
      }
      group {
        _id
        name
        logo
        deletedAt
      }
      inviter {
        ... on PendingUser {
          _id
          slug
          fullName
          avatar
          email
        }
        ... on GovernmentUser {
          _id
          slug
          fullName
          avatar
          email
        }
        ... on VendorUser {
          _id
          slug
          fullName
          avatar
          email
        }
      }
      respondedBy {
        ... on PendingUser {
          _id
          fullName
          avatar
          email
        }
        ... on GovernmentUser {
          _id
          fullName
          avatar
          email
        }
        ... on VendorUser {
          _id
          fullName
          avatar
          email
        }
      }
    }
  }
`;
