import { first } from "lodash";
import React, { useState } from "react";
import { useQuery } from "react-apollo";
import AllVendorsQuery from "../../../queries/GetAllVendors";
import { IAllVendorsQuery, IAllVendorsQueryVariables } from "../../../queries/__generated__/GetAllVendors.generated";
//import { SorterResult } from "antd/lib/table/interface";
import queryString from "query-string";
import TableAddStateToQueryString from "../../../components/table/TableAddStateToQueryString";
import { parseState } from "../../../utils/tablesUtils";
import { useLocation, useNavigate } from "react-router";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import { IVendorsTabFilter, VendorsTable } from "./VendorsTable";
import TablePagination, { DEFAULT_CURSOR, ITablePaginationState } from "../../../components/table/TablePagination";
import TableTitle from "../../../components/table/TableTitle";
import TableOnSearch from "../../../components/table/TableOnSearch";

export type TVendor = NonNullable<NonNullable<IAllVendorsQuery["allVendors"]>["items"]>[0];

export interface ITableStateProps {
  //sorter?: SorterResult<TVendor>;
  tab: IVendorsTabFilter;
  plan: string | null;
  planId: string | null;
  stripeStatus: string | null;
  hasOwner: boolean | null;
  active: boolean | null;
  inTiers: number[] | null;
  hasCustomLimits: boolean | null;
  cursor: ITablePaginationState;
}

interface IManageVendorsProps {
  search: string;
  tab: IVendorsTabFilter;
  plan?: string | null;
  planId?: string | null;
  sortBy?: string;
  sponsorship?: any | null;
  historyOrigin?: string;
}

export default function ManageVendors({
  search,
  tab,
  plan,
  planId,
  sortBy,
  sponsorship,
  historyOrigin = "/vendors",
}: IManageVendorsProps) {
  const navigate = useNavigate();

  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    tab: tab,
    plan: plan || null,
    planId: planId || null,
    stripeStatus: null,
    hasOwner: null,
    active: null,
    inTiers: null,
    hasCustomLimits: null,
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<ITableStateProps>(initialFiltersState);

  const { data, loading, refetch } = useQuery<IAllVendorsQuery, IAllVendorsQueryVariables>(AllVendorsQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        search: search,
        tab: state.tab,
        plan: state.plan,
        planId: state.planId,
        stripeStatus: state.stripeStatus,
        hasOwner: state.hasOwner,
        active: state.active,
        inTiers: state.inTiers,
        sponsorships: sponsorship ? [sponsorship] : null,
        hasCustomLimits: state.hasCustomLimits,
      },
      //sortBy: state.sorter ? getSortBy(state.sorter) : sortBy || "",
      sortBy: sortBy || "",
      cursor: calculateAndEncodeCursor(state.cursor),
    },
  });

  const allVendors = data && data.allVendors && data.allVendors.items ? data.allVendors.items : [];
  const totalCount = data && data.allVendors && data.allVendors.pageInfo ? data.allVendors.pageInfo.totalCount || 0 : 0;

  return (
    <>
      {typeof history !== "undefined" && history && (
        <TableAddStateToQueryString tableState={state} historyOrigin={historyOrigin} query={query} />
      )}
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
 page: 1, size: state.cursor.size 
},
            });
          }
        }}
      />
      <VendorsTable
        allVendors={allVendors}
        tab={tab}
        historyOrigin={historyOrigin}
        loading={loading}
        title={() => (
          <TableTitle
            title="Vendors"
            count={totalCount}
            loading={loading}
            onResetFilters={() => {
              setState({
                tab: tab,
                plan: null,
                planId: null,
                stripeStatus: null,
                hasOwner: null,
                active: null,
                inTiers: null,
                hasCustomLimits: null,
                cursor: {
 page: 1, size: state.cursor.size 
},
              });

              navigate(`${historyOrigin}?tab=${query.tab || ""}&search=${search}`);
            }}
          />
        )}
        footer={() => (
          <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
        )}
        onChange={(_, filters) => {
          let hasOwner: boolean | null = null;
          let plan: string | null = null;
          let isActive: boolean | null = null;
          let inTiers: number[] | null = null;
          let hasCustomLimits: boolean | null = null;
          let stripeStatus: string | null = null;

          //if (Array.isArray(sorter)) {
          //  return;
          //}

          if (first(filters.owner) === "owned") {
            hasOwner = true;
          }

          if (first(filters.owner) === "not_owned") {
            hasOwner = false;
          }

          if (first(filters.plan)) {
            plan = String(first(filters.plan));
          }

          if (first(filters.subscription)) {
            stripeStatus = String(first(filters.subscription));
          }

          if (first((filters as any).active) === "active") {
            isActive = true;
          }

          if (first((filters as any).active) === "not_active") {
            isActive = false;
          }

          if (filters.tier && Array.isArray(filters.tier) && filters.tier.length > 0) {
            inTiers = filters.tier.map((tier: { toString: () => string }) => {
              return parseInt(tier.toString(), 10);
            });
          }

          if (first(filters.hasCustomLimits) === "has_custom_limits") {
            hasCustomLimits = true;
          }

          if (first(filters.hasCustomLimits) === "no_custom_limits") {
            hasCustomLimits = false;
          }

          setState({
            //sorter: sorter,
            tab: tab,
            plan: plan,
            planId: planId || null,
            stripeStatus,
            hasOwner: hasOwner,
            active: isActive,
            inTiers,
            hasCustomLimits,
            cursor: {
 page: 1, size: state.cursor.size 
},
          });
        }}
        state={state}
        refetch={refetch}
      />
    </>
  );
}
