import gql from "graphql-tag";

export default gql`
  query AutoCompleteGroups($searchQuery: String!, $limit: Int!) {
    autoCompleteGroups(searchQuery: $searchQuery, limit: $limit) {
      _id
      name
      slug
      logo
    }
  }
`;
