import React from "react";
import DocumentTitle from "react-document-title";
import TablePageHeader from "../../../components/table/TablePageHeader";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import DuplicatedUsersSearch from "./DuplicatedUsersSearch";
import { Tabs } from "antd";

export default function DuplicatedUsersPage() {
  return (
    <DocumentTitle title="Duplicated Users | Admin">
      <div>
        <TablePageHeader title="Duplicated Users" titleIcon={faClone} />
        <Tabs
          activeKey="All"
          animated={false}
          size="middle"
          tabBarStyle={{
            paddingLeft: 20,
            paddingRight: 20,
            margin: 0,
          }}
        >
          <Tabs.TabPane tab="All" key="All">
            <div
              css={{
                padding: 20,
              }}
            >
              <DuplicatedUsersSearch />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </DocumentTitle>
  );
}
