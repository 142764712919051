import { Input, Tabs } from "antd";
import React from "react";
import DocumentTitle from "react-document-title";
import queryString from "query-string";
import { IGovlaunchEnvironment } from "../../../types/types";
import { Spacing } from "@govlaunch/spacers";
import NewsletterReceiversTable from "./NewsletterReceiversTable";
import CollectionContentUpdateButton from "../collections/CollectionContentUpdateButton";
import { useLocation, useNavigate } from "react-router";
import PageIcon from "../../icons/PageIcon";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import useIsDesktop from "../../hooks/useIsDesktop";

export default function NewsletterReceivers() {
  const location = useLocation();
  const navigate = useNavigate();
  const query = queryString.parse(location.search);
  const tab = Array.isArray(query.tab) ? query.tab[0] : query.tab || IGovlaunchEnvironment.Production;
  const isDesktop = useIsDesktop();

  return (
    <DocumentTitle title="Newsletter | Receivers">
      <div>
        <div
          css={{
            padding: 20,
            borderBottom: "1px solid rgb(232, 232, 232)",
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
            alignItems: "flex-start",
            overflow: "hidden",
          }}
        >
          <h3
            css={{
              fontWeight: 700,
              fontSize: 24,
              margin: 0,
            }}
          >
            <PageIcon icon={faMailBulk} /> Newsletter Receivers
          </h3>
          {(process.env.GOVLAUNCH_ENV == "development" || process.env.GOVLAUNCH_ENV === "qa") && (
            <div
              css={{
                marginLeft: "auto",
              }}
            >
              <CollectionContentUpdateButton />
            </div>
          )}
        </div>

        <Tabs
          animated={false}
          onChange={(tab) => {
            navigate(`/newsletter/receivers?tab=${tab}`);
          }}
          activeKey={tab || "All"}
          size="middle"
          tabBarStyle={{
            paddingLeft: 20,
            paddingRight: 20,
            margin: 0,
          }}
        >
          <Tabs.TabPane tab="All" key={IGovlaunchEnvironment.Production}>
            <div
              css={{
                padding: 20,
              }}
            >
              {tab === IGovlaunchEnvironment.Production && (
                <NewsletterReceiversSearch mode={IGovlaunchEnvironment.Production} />
              )}
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Govlaunch" key={IGovlaunchEnvironment.Qa}>
            <div
              css={{
                padding: 20,
              }}
            >
              {tab === IGovlaunchEnvironment.Qa && <NewsletterReceiversSearch mode={IGovlaunchEnvironment.Qa} />}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </DocumentTitle>
  );
}

interface INewsletterReceiversSearchProps {
  mode: IGovlaunchEnvironment;
}

export function NewsletterReceiversSearch({ mode }: INewsletterReceiversSearchProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const setSearchTerm = (searchTerm: string) => {
    const nextQuery = queryString.stringify({
      ...query,
      search: searchTerm.trim() || undefined,
    });

    navigate(`/newsletter/receivers?${nextQuery}`);
  };

  return (
    <>
      <Spacing bottom={20}>
        <Input.Search
          size="large"
          placeholder="Search for receivers..."
          autoComplete="off"
          onSearch={setSearchTerm}
          style={{
            fontSize: 14,
          }}
        />
      </Spacing>

      <NewsletterReceiversTable search={query.search as string} mode={mode} />
    </>
  );
}
