import gql from "graphql-tag";

export default gql`
  mutation EditGroupVendors($groupId: ObjectId!, $vendors: [ObjectId!]!) {
    editGroupVendors(groupId: $groupId, vendors: $vendors) {
      _id
      vendors {
        _id
        slug
        name
      }
    }
  }
`;
