import * as palette from "@govlaunch/palette";
import { Popover as AntdPopover, Tooltip } from "antd";
import React from "react";
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";
import { useToggle } from "react-use";
import { IFeaturedTargetType } from "../../../../types/types";
import DotdotdotIcon from "../../../components/icons/DotdotdotIcon";
import ArchiveStory from "../../../mutations/ArchiveStory";
import ChangeStoryAuthorMutation from "../../../mutations/ChangeStoryAuthor";
import PublishStory from "../../../mutations/PublishStory";
import RestoreStory from "../../../mutations/RestoreStory";
import ToggleFeatured from "../../../mutations/ToggleFeatured";
import UnpublishStory from "../../../mutations/UnpublishStory";
import {
  IArchiveStoryMutation,
  IArchiveStoryMutationVariables,
} from "../../../mutations/__generated__/ArchiveStory.generated";
import {
  IChangeStoryAuthorMutation,
  IChangeStoryAuthorMutationVariables,
} from "../../../mutations/__generated__/ChangeStoryAuthor.generated";
import {
  IPublishStoryMutation,
  IPublishStoryMutationVariables,
} from "../../../mutations/__generated__/PublishStory.generated";
import {
  IRestoreStoryMutation,
  IRestoreStoryMutationVariables,
} from "../../../mutations/__generated__/RestoreStory.generated";
import {
  IUnpublishStoryMutation,
  IUnpublishStoryMutationVariables,
} from "../../../mutations/__generated__/UnpublishStory.generated";
import { IStoryFieldsAdminFragment } from "../../../queries/fragments/__generated__/StoryFields.generated";
import GetStoriesQuery from "../../../queries/GetStoriesQuery";
import { IGetStoriesQueryVariables } from "../../../queries/__generated__/GetStoriesQuery.generated";
import ChangeStoryAuthor from "../ChangeStoryAuthor";

export default function StoriesTableActions({
  story,
  refetch,
}: {
  story: IStoryFieldsAdminFragment;
  refetch: (variables?: IGetStoriesQueryVariables) => any;
}) {
  const [showChangeStoryAuthor, toggleChangeStoryAuthor] = useToggle(false);
  const [showPopover, toggleShowPopover] = useToggle(false);

  return (
    <>
      <AntdPopover
        visible={showPopover}
        trigger="click"
        title="Actions"
        placement="rightBottom"
        onVisibleChange={toggleShowPopover}
        content={
          <ul
            css={{
              margin: 0,
              padding: 0,
              listStyle: "none",
              "> li:not(:first-of-type)": {
                marginTop: 10,
              },
            }}
          >
            {story.archivedAt ? (
              <>
                {!story.author && (
                  <li>
                    <a
                      onClick={(event) => {
                        event.preventDefault();
                        toggleChangeStoryAuthor();
                        toggleShowPopover();
                      }}
                    >
                      Assign Author
                    </a>
                  </li>
                )}

                <li>
                  <Mutation<IRestoreStoryMutation, IRestoreStoryMutationVariables>
                    mutation={RestoreStory}
                    variables={{
                      storyId: story._id,
                    }}
                    refetchQueries={["StoriesCount", "GetStories"]}
                  >
                    {(restore) => (
                      <Tooltip placement="top" title={!story.author ? <span>Please assign an owner first</span> : null}>
                        <span>
                          <a
                            href="#"
                            onClick={(event) => {
                              event.preventDefault();
                              restore().then(() => {
                                return refetch();
                              });
                            }}
                            css={{
                              disabled: !story.author,
                            }}
                          >
                            Restore
                          </a>
                        </span>
                      </Tooltip>
                    )}
                  </Mutation>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to={`/stories/${story.slug}/edit`}>Edit</Link>
                </li>

                <li>
                  <a
                    onClick={(event) => {
                      event.preventDefault();
                      toggleChangeStoryAuthor();
                      toggleShowPopover();
                    }}
                  >
                    Change Author
                  </a>
                </li>

                <ToggleFeatured
                  targetType={IFeaturedTargetType.Story}
                  targetId={story._id}
                  featured={story.featured || false}
                >
                  {({ toggle }: { toggle: () => void }) => (
                    <li>
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          toggle();
                        }}
                      >
                        {story.featured ? "Remove featured" : "Set featured"}
                      </a>
                    </li>
                  )}
                </ToggleFeatured>

                {story.status === "PUBLISHED" && (
                  <Mutation<IUnpublishStoryMutation, IUnpublishStoryMutationVariables> mutation={UnpublishStory}>
                    {(unpublish) => (
                      <li>
                        <a
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            unpublish({
                              variables: {
                                storyId: story._id,
                              },
                            });
                          }}
                          css={{
                            color: palette.red,
                          }}
                        >
                          Unpublish
                        </a>
                      </li>
                    )}
                  </Mutation>
                )}

                {(story.status === "UNPUBLISHED" || story.status === "PROPOSED") && (
                  <Mutation<IPublishStoryMutation, IPublishStoryMutationVariables> mutation={PublishStory}>
                    {(publish) => (
                      <li>
                        <a
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            publish({
                              variables: {
                                storyId: story._id,
                              },
                              refetchQueries: [
                                {
                                  query: GetStoriesQuery,
                                },
                              ],
                            });
                          }}
                          css={{
                            color: palette.greenSuccess,
                            "&:hover": {
                              color: palette.greenSuccess,
                            },
                          }}
                        >
                          Publish
                        </a>
                      </li>
                    )}
                  </Mutation>
                )}

                <Mutation<IArchiveStoryMutation, IArchiveStoryMutationVariables>
                  mutation={ArchiveStory}
                  refetchQueries={["StoriesCount", "GetStories"]}
                >
                  {(archive) => (
                    <li>
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          archive({
                            variables: {
                              storyId: story._id,
                            },
                          }).then(() => {
                            return refetch();
                          });
                        }}
                        css={{
                          color: palette.red,
                          "&:hover": {
                            color: palette.red,
                          },
                        }}
                      >
                        Archive
                      </a>
                    </li>
                  )}
                </Mutation>
              </>
            )}
          </ul>
        }
      >
        <span
          role="button"
          css={{
            cursor: "pointer",
          }}
        >
          <DotdotdotIcon />
        </span>
      </AntdPopover>

      <Mutation<IChangeStoryAuthorMutation, IChangeStoryAuthorMutationVariables> mutation={ChangeStoryAuthorMutation}>
        {(changeStoryAuthor) => (
          <ChangeStoryAuthor
            author={story.author}
            isOpen={showChangeStoryAuthor}
            onRequestClose={toggleChangeStoryAuthor}
            onStoryAuthorChange={({ author }) => {
              changeStoryAuthor({
                variables: {
                  storyId: story._id,
                  authorId: author._id,
                },
              });
            }}
          />
        )}
      </Mutation>
    </>
  );
}
