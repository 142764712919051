import React from "react";
import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import CharacterLimitIndicator from "../../../../components/form/CharacterLimitIndicator";
import FieldValidationError from "../../../../components/form/FieldValidationError";
import { Margin } from "../../../../spacings";
import * as palette from "@govlaunch/palette";

export default function DescriptionField() {
  return (
    <Field name="description">
      {({ input, meta }) => (
        <div>
          <TextareaAutosize
            {...input}
            minRows={3}
            placeholder="Add a description for your project"
            css={{
              padding: 10,
              outline: 0,
              fontSize: 14,
              borderRadius: 4,
              resize: "none",
              width: "100%",
              border: `solid 1px ${palette.lightestGray}`,
              "&:focus": {
                border: `solid 1px ${palette.primary}`,
              },
              "&::placeholder": {
                color: palette.sealBlue,
                userSelect: "none",
              },
            }}
          />

          <Margin mt={10}>
            <div
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <FieldValidationError meta={meta} />
              </div>

              <CharacterLimitIndicator
                maxLength={500}
                description={input.value}
                css={{
                  marginLeft: "auto",
                }}
              />
            </div>
          </Margin>
        </div>
      )}
    </Field>
  );
}
