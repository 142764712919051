import React from "react";
import * as palette from "@govlaunch/palette";
import DocumentTitle from "react-document-title";
import NewTagForm from "./NewTagForm";

export default function NewTagPage() {
  return (
    <DocumentTitle title="New Tag | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
          overflow: "auto"
        }}
      >
        <NewTagForm />
      </div>
    </DocumentTitle>
  );
}
