import gql from "graphql-tag";
import VendorFields from "./fragments/VendorFields";

export default gql`
  query Vendor($slug: String!) {
    company(slug: $slug) {
      _id
      ...VendorFields
    }
  }

  ${VendorFields}
`;
