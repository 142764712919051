import { Button, notification } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import RunSetCountriesOnEntities from "../../mutations/RunSetCountriesOnEntities";
import {
  IRunSetContriesOnEntitiesMutation,
  IRunSetContriesOnEntitiesMutationVariables,
} from "../../mutations/__generated__/RunSetCountriesOnEntities.generated";

export default function SetCountriesOnEntitiesButton() {
  const [runSetCountriesOnEntities, { loading }] = useMutation<
    IRunSetContriesOnEntitiesMutation,
    IRunSetContriesOnEntitiesMutationVariables
  >(RunSetCountriesOnEntities);

  return (
    <Button
      loading={loading}
      onClick={async () => {
        const result = await runSetCountriesOnEntities();

        if (result && result.data && result.data.runSetCountriesOnEntities) {
          notification.success({
            message: "Success!",
            description: "Countries on entities updated!",
          });
        } else {
          notification.error({
            message: "Error!",
            description: "Something went wrong!",
          });
        }
      }}
    >
      Set Countries on Entities
    </Button>
  );
}
