import React from "react";
import { useMutation } from "react-apollo";
import AddTagMutation from "../../mutations/AddTag";
import { IAddTagMutation, IAddTagMutationVariables } from "../../mutations/__generated__/AddTag.generated";
import AddCollectionMutation from "../collections/graphql/mutations/AddCollectionMutation";
import {
  IAddCollectionMutation,
  IAddCollectionMutationVariables,
} from "../collections/graphql/mutations/__generated__/AddCollectionMutation.generated";
import {
  ICreateGroupMutation,
  ICreateGroupMutationVariables,
} from "../../mutations/__generated__/CreateGroup.generated";
import CreateGroup from "../../mutations/CreateGroup";
import TogglePublishedOnCollection from "../collections/graphql/mutations/TogglePublishedOnCollection";
import {
  ITogglePublishedOnCollectionMutation,
  ITogglePublishedOnCollectionMutationVariables,
} from "../collections/graphql/mutations/__generated__/TogglePublishedOnCollection.generated";
import TagForm, { ITagFormValues } from "./profile/TagForm";
import { convertTagFormValuesToVariables } from "./convertTagFormValuesToVariables";
import { notification } from "antd";
import { ICollectionSponsoring, IGroupKind, IGroupVisibility, ITagType } from "../../../types/types";
import getGovlaunchBaseUrl from "../../utils/getGovlaunchBaseUrl";
import * as palette from "@govlaunch/palette";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

export default function NewTagForm() {
  const navigate = useNavigate();

  const [addTag] = useMutation<IAddTagMutation, IAddTagMutationVariables>(AddTagMutation, {
    refetchQueries: ["ListAllTags"],
  });

  const [addCollection] = useMutation<IAddCollectionMutation, IAddCollectionMutationVariables>(AddCollectionMutation, {
    refetchQueries: ["ListAllCollections"],
  });

  const [createGroup] = useMutation<ICreateGroupMutation, ICreateGroupMutationVariables>(CreateGroup);

  const [togglePublishedOnCollection] = useMutation<
    ITogglePublishedOnCollectionMutation,
    ITogglePublishedOnCollectionMutationVariables
  >(TogglePublishedOnCollection, {
    refetchQueries: ["ListAllCollections", "CollectionsCountersQuery"],
  });

  const addCollectionProcess = async (name: string, tagId: any) => {
    const response = await addCollection({
      variables: {
        input: {
          name: name,
          tags: [tagId],
          description: "",
          sponsoring: ICollectionSponsoring.None,
          sponsoringProducts: [],
          sponsoringVendors: [],
          displayOptions: {
            cardBackgroundColorHex: "#086BE5",
          },
          isFeatured: false,
        },
      },
    });

    if (response && response.data && response.data.addCollection) {
      notification.success({
        message: "Collection added!",
        description: `Collection added successfully!`,
      });

      return response.data.addCollection._id;
    } else {
      notification.error({
        message: "Could not add tag!",
      });

      return null;
    }
  };

  const addGroupProcess = async (collectionName: string, tagTypes: ITagType[], tagId: any) => {
    const createGroupResponse = await createGroup({
      variables: {
        group: {
          name: collectionName,
          vendor: null,
          logo: null,
          awards: tagTypes.includes(ITagType.Award) ? [tagId] : [],
          members: [],
          newsworthy: [],
          products: [],
          outsideProducts: [],
          resources: [],
          socialMedia: null,
          location: "Govlaunch",
          website: process.env.NODE_ENV === "production" ? getGovlaunchBaseUrl() : "https://govlaunch.com",
          about: ` To discuss anything and everything related to ${collectionName}`,
          tags: tagTypes.includes(ITagType.Award) ? [] : [tagId],
          visibility: IGroupVisibility.Public,
          kind: IGroupKind.Discussion,
        },
      },
    });

    if (createGroupResponse && createGroupResponse.data && createGroupResponse.data.createGroup) {
      notification.success({
        message: "Group added!",
        description: `Group added successfully!`,
      });

      return true;
    } else {
      notification.error({
        message: "Could not add group!",
      });

      return false;
    }
  };

  return (
    <div
      css={{
        padding: 15,
      }}
    >
      <div
        css={{
          paddingBottom: 8,
        }}
      >
        <a
          css={{
            color: palette.primary,
            cursor: "pointer",
          }}
          onClick={() => navigate("/tags")}
        >
          <span css={{
 marginRight: 5 
}}
          >
            <FontAwesomeIcon icon={faLessThan} size="sm" />
          </span>
          All Tags
        </a>
      </div>

      <div
        css={{
          maxWidth: 600,
          border: `1px solid ${palette.lightestGray}`,
          backgroundColor: palette.white,
        }}
      >
        <TagForm
          onSubmit={async (values: ITagFormValues) => {
            try {
              const addTagResult = await addTag({
                variables: convertTagFormValuesToVariables(values),
              });

              if (addTagResult && addTagResult.data && addTagResult.data.addTag) {
                notification.success({
                  message: "Tag added!",
                  description: `Tag added successfully!`,
                });
              } else {
                notification.error({
                  message: "Could not add tag!",
                });

                return;
              }

              if (
                !addTagResult.data.addTag.types.some((type: ITagType) =>
                  ["PRODUCT", "STORY", "PROJECT", "CUSTOM", "AWARD"].includes(type),
                )
              ) {
                return;
              }

              const addedCollectionId = await addCollectionProcess(
                addTagResult.data.addTag.name,
                addTagResult.data.addTag._id,
              );

              if (!addedCollectionId) {
                return;
              }

              await addGroupProcess(
                addTagResult.data.addTag.name,
                addTagResult.data.addTag.types,
                addTagResult.data.addTag._id,
              );

              if (!values.publish) {
                await togglePublishedOnCollection({
                  variables: {
                    collectionId: addedCollectionId,
                  },
                });
              }

              navigate(`/tags?search=${addTagResult.data.addTag.name}`);
            } catch (err) {
              notification.error({
                message: "Could not add tag!",
                description: err.message.substring('GraphQL error: '.length),
              });
            }
          }}
        />
      </div>
    </div>
  );
}
