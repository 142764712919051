import gql from "graphql-tag";

export default gql`
  mutation ArchiveNewsletterCampaign($id: ObjectId!) {
    archiveNewsletterCampaign(id: $id) {
      _id
      name
      dynamicContent {
        storiesCount
        projectsCount
      }

      countries {
        countryCode
        amount
      }
      archivedAt
    }
  }
`;
