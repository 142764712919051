import { Filestack } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import Popover from "@govlaunch/popover";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing } from "@govlaunch/spacers";
import { Button, Table } from "antd";
import { TableProps } from "antd/lib/table";
import { startCase } from "lodash";
import moment from "moment";
import React from "react";
import { Mutation } from "react-apollo";
import { Toggle } from "react-powerplug";
import { Link } from "react-router-dom";
import { IStory, IStoryAudience, IStoryStatus, ITag } from "../../../../types/types";
import SetStoryCoverImages from "../../../mutations/SetStoryCoverImages";
import {
  ISetStoryCoverImagesMutation,
  ISetStoryCoverImagesMutationVariables,
} from "../../../mutations/__generated__/SetStoryCoverImages.generated";
import { IStoryFieldsAdminFragment } from "../../../queries/fragments/__generated__/StoryFields.generated";
import notUndefinedOrNull from "../../../utils/notUndefinedOrNull";
import { getFilteredValue } from "../../../utils/tablesUtils";
import Countries from "../../governments/Countries";
import FlagWithTooltip from "../../governments/FlagWithTooltip";
import TagPill from "../../tags/TagPill";
import { IStoriesTableState } from "../tables/ManageStories";
import StoriesTableActions from "./StoriesTableActions";

type TTag = Pick<ITag, "_id" | "name" | "slug">;

interface IStoriesTableProps {
  refetch: (variables: any) => any;
  renderStoryLink?: (story: Pick<IStory, "_id" | "slug">) => any;
  stories: IStoryFieldsAdminFragment[];
  onChange?: (pagination: any, filters: any, sorter: any) => any;
  loading?: boolean;
  state?: IStoriesTableState;
  footer?: TableProps<any>["footer"];
  title?: () => any;
  noFilters?: boolean;
  noSorters?: boolean;
}

export default function StoriesTable({
  stories,
  refetch,
  renderStoryLink,
  onChange,
  loading,
  state,
  title,
  noSorters = false,
  noFilters = false,
  ...props
}: IStoriesTableProps) {
  const areAllArchived =
    stories.length > 0 &&
    stories.every((story) => {
      return story.archivedAt !== null;
    });

  return (
    <Table<IStoryFieldsAdminFragment>
      {...props}
      pagination={false}
      loading={loading}
      rowKey="_id"
      bordered={true}
      dataSource={stories || []}
      style={{
        backgroundColor: palette.white,
      }}
      scroll={{
        x: true,
      }}
      onChange={onChange}
      title={title}
      columns={[
        {
          title: "Title",
          dataIndex: "title",
          render: (title: string, story: IStoryFieldsAdminFragment) => (
            <Link to={renderStoryLink ? renderStoryLink(story) : `/stories/${story.slug}`}>
              {title}
              {story.featured && " ✨"}

              {story.audience === "community" && " 🔑"}
              {story.audience === "public" && " 🌎"}
            </Link>
          ),
        },
        {
          title: "Audience",
          filters: !noFilters
            ? [
                {
                  text: "Public (Audience)",
                  value: IStoryAudience.Public,
                },
                {
                  text: "Community (Audience)",
                  value: IStoryAudience.Community,
                },
                {
                  text: "Featured",
                  value: "featured",
                },
              ]
            : undefined,
          filterMultiple: false,
          filteredValue: getFilteredValue(state?.audience),
          dataIndex: "audience",
          render: (audience: string) => startCase(audience),
        },
        {
          title: "Author",
          dataIndex: "author",
          render: (author: IStoryFieldsAdminFragment["author"]) => {
            if (!author) {
              return null;
            }

            return (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Link to={`/users/${author.slug}`}>{author.fullName}</Link>
              </div>
            );
          },
        },
        {
          title: "Country",
          dataIndex: "government",
          key: "country",
          filterMultiple: false,
          filters: !noFilters
            ? [
                {
                  text: "No country",
                  value: "n/a",
                },
                ...Countries.map((country) => {
                  return {
                    text: country.name,
                    value: country.code,
                  };
                }),
              ]
            : undefined,
          filteredValue: getFilteredValue(state?.country),
          render: (government: IStoryFieldsAdminFragment["government"]) => {
            if (!government) {
              return <span>N/A</span>;
            }

            return <FlagWithTooltip country={government.city.country} width={24} height={18} />;
          },
        },
        {
          title: "Government",
          dataIndex: "government",
          render: (government: IStoryFieldsAdminFragment["government"]) => {
            if (!government) {
              return <span>N/A</span>;
            }

            return (
              <Link
                to={`/governments/${government.slug}`}
                css={{
                  display: "grid",
                  gridColumnGap: 8,
                  gridTemplateColumns: "repeat(2, max-content)",
                  alignItems: "center",
                }}
              >
                <ProfilePicture size={24} image={government.logo || ""} name={government.name} />

                <span>{government.name}</span>
              </Link>
            );
          },
        },
        {
          title: "Vertical Cover",
          render: (_: any, story: IStoryFieldsAdminFragment) => {
            return (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {story.verticalCover && (
                  <Toggle initial={false}>
                    {({ toggle, on }) => (
                      <>
                        <Popover
                          isOpen={on}
                          onClickOutside={toggle}
                          placement="bottom"
                          render={() => (
                            <div
                              css={{
                                padding: 10,
                              }}
                            >
                              <img
                                css={{
                                  width: 200,
                                  height: 300,
                                  objectFit: "cover",
                                  borderRadius: 2,
                                  cursor: "pointer",
                                }}
                                src={story.verticalCover || ""}
                                onClick={toggle}
                              />
                            </div>
                          )}
                        >
                          {({ innerRef }: { innerRef: React.RefObject<any> }) => (
                            <Spacing inline={true} flex={true} right={10}>
                              <img
                                ref={innerRef}
                                src={story.verticalCover || ""}
                                css={{
                                  width: 50,
                                  height: 50,
                                  borderRadius: 8,
                                  cursor: "pointer",
                                }}
                                onClick={toggle}
                              />
                            </Spacing>
                          )}
                        </Popover>
                      </>
                    )}
                  </Toggle>
                )}

                <Mutation<ISetStoryCoverImagesMutation, ISetStoryCoverImagesMutationVariables>
                  mutation={SetStoryCoverImages}
                >
                  {(setStoryCoverImages) => {
                    return (
                      <div
                        css={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Filestack
                          onSuccess={({ filesUploaded }: any) =>
                            setStoryCoverImages({
                              variables: {
                                story: story._id,
                                verticalCover: filesUploaded[0].url,
                              },
                              optimisticResponse: {
                                __typename: "Mutation",
                                setStoryCoverImages: {
                                  __typename: "Story",
                                  _id: story._id,
                                  verticalCover: filesUploaded[0].url,
                                },
                              },
                            })
                          }
                          customRender={({ onPick }: any) => (
                            <Button onClick={onPick} htmlType="button" size="small">
                              {story.verticalCover ? "Change" : "Upload"}
                            </Button>
                          )}
                        />

                        {story.verticalCover && (
                          <Spacing top={5}>
                            <Button
                              onClick={() => {
                                setStoryCoverImages({
                                  variables: {
                                    story: story._id,
                                    verticalCover: null,
                                  },
                                  optimisticResponse: {
                                    __typename: "Mutation",
                                    setStoryCoverImages: {
                                      __typename: "Story",
                                      _id: story._id,
                                      verticalCover: "",
                                    },
                                  },
                                });
                              }}
                              htmlType="button"
                              danger={true}
                              size="small"
                            >
                              Remove
                            </Button>
                          </Spacing>
                        )}
                      </div>
                    );
                  }}
                </Mutation>
              </div>
            );
          },
        },
        {
          title: "Tags",
          dataIndex: "tags",
          render: (tags: IStoryFieldsAdminFragment["tags"]) => (
            <div
              css={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {tags.map((tag: TTag) => {
                return <TagPill key={tag._id} name={tag.name} />;
              })}
            </div>
          ),
        },
        {
          title: "Status",
          dataIndex: "status",
          filters: !noFilters
            ? [
                {
                  text: "Draft",
                  value: IStoryStatus.Proposed,
                },
                {
                  text: "Approved",
                  value: IStoryStatus.Approved,
                },
                {
                  text: "Published",
                  value: IStoryStatus.Published,
                },
                {
                  text: "Unpublished",
                  value: IStoryStatus.Unpublished,
                },
              ]
            : undefined,
          filterMultiple: false,
          filteredValue: getFilteredValue(state?.status),
          key: "status",
          sorter: !noSorters ? true : false,
          sortOrder: state && state.sortColumnKey === "status" ? state.sortColumnOrder : undefined,
          render: (status: string) => {
            if (status === "UNPUBLISHED") {
              return (
                <span
                  css={{
                    color: palette.red,
                    fontWeight: 400,
                  }}
                >
                  Unpublished
                </span>
              );
            } else if (status === "PUBLISHED") {
              return (
                <span
                  css={{
                    color: palette.greenSuccess,
                    fontWeight: 400,
                  }}
                >
                  Published
                </span>
              );
            } else if (status === "PROPOSED") {
              return (
                <span
                  css={{
                    color: palette.red,
                    fontWeight: 400,
                  }}
                >
                  Draft
                </span>
              );
            } else {
              return status;
            }
          },
        },
        {
          title: "Created At",
          key: "createdAt",
          sorter: !noSorters ? true : false,
          sortOrder: state && state.sortColumnKey === "createdAt" ? state.sortColumnOrder : undefined,
          render: (story: IStoryFieldsAdminFragment) => {
            return moment(story.modifiedAt || story.createdAt).format("MM/DD/YYYY");
          },
        },
        areAllArchived
          ? {
              title: "Archived At",
              render: (story: IStoryFieldsAdminFragment) => moment(story.archivedAt).format("MM/DD/YYYY"),
            }
          : null,
        areAllArchived
          ? {
              title: "Archived By",
              render: (story: IStoryFieldsAdminFragment) => {
                if (story.archivedBy) {
                  return <Link to={`/users/${story.archivedBy.slug}`}>{story.archivedBy.fullName}</Link>;
                }

                return "N/A";
              },
            }
          : null,
        {
          title: "Actions",
          render: (story: IStoryFieldsAdminFragment) => <StoriesTableActions story={story} refetch={refetch} />,
        },
      ].filter(notUndefinedOrNull)}
    />
  );
}
