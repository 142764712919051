import * as palette from "@govlaunch/palette";
import React, { ReactNode } from "react";

interface ILinkPaddingButtonProps {
  onClick: any;
  children: ReactNode;
}

export default function LinkPaddingsButton({ onClick, children }: ILinkPaddingButtonProps) {
  return (
    <div
      css={{
        width: "42px",
        height: "42px",
        background: palette.lightestGray,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
          opacity: 0.8,
        },
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
