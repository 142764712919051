import gql from "graphql-tag";
import VendorFields from "./fragments/VendorFields";

export default gql`
  query AllVendors($filter: AllVendorsFilterInput!, $sortBy: String, $cursor: Cursor, $limit: Int = 25) {
    allVendors(filter: $filter, sortBy: $sortBy, cursor: $cursor, limit: $limit) {
      items {
        _id
        ...VendorFields

        productsCount
        allProjectsCount
        allStoriesCount
        membersCount
        activatedAt
        archivedAt
        archivedBy {
          ... on PendingUser {
            _id
            slug
            fullName
          }
          ... on GovernmentUser {
            _id
            slug
            fullName
          }
          ... on VendorUser {
            _id
            slug
            fullName
          }
        }
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor
      }
    }
  }

  ${VendorFields}
`;
