import gql from "graphql-tag";
import StripeChargeFields from "./StripeChargeFields";

export default gql`
  fragment CompanySubscription on Company {
    _id
    subscription {
      ... on ActiveSubscription {
        _id
        nextPaymentDate
        nextPaymentAmount
        subscribedAt
        lastCharge {
          ...StripeChargeFields
        }
        collectionMethod
        trialPeriod
        freeSubscription
        pausePaymentCollection
      }
      ... on OverdueSubscription {
        _id
        willCancelAt
        nextPaymentAttempt
        nextPaymentAmount
        lastCharge {
          ...StripeChargeFields
        }
        pausePaymentCollection
      }
      ... on ScheduledToCancelSubscription {
        _id
        willCancelAt
        requestedCancellationAt
        lastCharge {
          ...StripeChargeFields
        }
        pausePaymentCollection
      }
    }
  }

  ${StripeChargeFields}
`;
