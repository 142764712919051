import React from "react";
import DocumentTitle from "react-document-title";
import * as palette from "@govlaunch/palette";
import NewPlanForm from "./NewPlanForm";

export default function NewPlanPage() {
  return (
    <DocumentTitle title="New Plan | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
          overflow: "auto",
        }}
      >
        <NewPlanForm />
      </div>
    </DocumentTitle>
  );
}
