import { PlusCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Input } from "antd";
import Form from "antd/lib/form";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { useMutation } from "react-apollo";
import AddSolutionMutation from "./graphql/mutations/AddSolutionMutation";
import {
  IAddSolutionMutation,
  IAddSolutionMutationVariables,
} from "./graphql/mutations/__generated__/AddSolutionMutation.generated";

const AddSolution = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const [addSolution, { loading, error }] = useMutation<IAddSolutionMutation, IAddSolutionMutationVariables>(
    AddSolutionMutation,
    {
      onError: () => {},
      onCompleted: () => setVisible(false),
      refetchQueries: ["AllSolutions"],
      awaitRefetchQueries: true,
    },
  );

  return (
    <>
      <Modal title="Add Solution" visible={visible} onOk={() => form.submit()} onCancel={() => setVisible(false)}>
        <Form
          form={form}
          layout="vertical"
          name="addSolution"
          onFinish={async () => {
            const values = form.getFieldsValue(true);

            await addSolution({
              variables: {
                name: values.name,
              },
            });

            return null;
          }}
          initialValues={{
            name: "",
          }}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Name is required",
              },
            ]}
            label="Name"
            name="name"
          >
            <Input maxLength={36} />
          </Form.Item>
        </Form>

        {error && (
          <div
            css={{
              marginTop: 12,
            }}
          >
            <Alert
              message="Error"
              description={error?.message.replace("GraphQL error:", "").trim()}
              type="error"
              showIcon={true}
            />
          </div>
        )}
      </Modal>

      <Button
        size="large"
        type="dashed"
        icon={<PlusCircleOutlined />}
        loading={loading}
        onClick={() => setVisible(true)}
      >
        Add Solution
      </Button>
    </>
  );
};

export default AddSolution;
