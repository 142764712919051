import React from "react";
import { Field } from "react-final-form";
import FieldValidationError from "../../../components/form/FieldValidationError";
import { Margin } from "../../../spacings";
import * as palette from "@govlaunch/palette";
import { Select } from "@govlaunch/select";
import { Maybe } from "../../../../types/types";

interface ILanguagesItems {
  value: Maybe<string>;
  label: string;
}

export default function LanguagesCountField() {
  return (
    <Field name="languagesOffered" type="number" allowNull={true}>
      {({ input, meta }) => {
        const items: ILanguagesItems[] = [
          {
            label: "1",
            value: "1",
          },
          {
            label: "2",
            value: "2",
          },
          {
            label: "3",
            value: "3",
          },
          {
            label: "4",
            value: "4",
          },
          {
            label: "More than 5",
            value: "More than 5",
          },
        ];

        if (input.value) {
          items.unshift({
            value: null,
            label: "None",
          });
        }

        return (
          <div
            css={{
              width: "100%",
              marginLeft: 24,
            }}
          >
            <div
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <label
                css={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: palette.mediumGray,
                  margin: 0,
                }}
              >
                Number of languages available
              </label>

              <FieldValidationError meta={meta} />
            </div>
            <Margin mt={8}>
              <Select {...input} items={items} placeholder="Select number of languages" />
            </Margin>
          </div>
        );
      }}
    </Field>
  );
}
