import { EditOutlined, PoweroffOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, notification, Popconfirm, Table, Typography } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import Column from "antd/lib/table/Column";
import { NetworkStatus } from "apollo-client";
import fp from "lodash/fp";
import capitalize from "lodash/fp/capitalize";
import moment from "moment";
import queryString from "query-string";
import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { Link } from "react-router-dom";
import { ICollectionSponsoringPaymentSchedule, ICollectionSponsoringType } from "../../../types/types";
import AddCollectionSponsoring from "./AddCollectionSponsoring";
import { IListCollectionSponsoringSalesQuery } from "./graphql/queries/__generated__/ListCollectionSponsoringSales.generated";
import ArchiveCollectionSponsoringSale from "./graphql/mutations/ArchiveCollectionSponsoringSale";
import {
  IArchiveCollectionSponsoringSaleMutation,
  IArchiveCollectionSponsoringSaleMutationVariables,
} from "./graphql/mutations/__generated__/ArchiveCollectionSponsoringSale.generated";
import UpdateCollectionSponsoring from "./UpdateCollectionSponsoring";
import { NavigateFunction, useLocation } from "react-router";

interface ICollectionSponsorshipsTabContentProps {
  sponsorings: IListCollectionSponsoringSalesQuery["listAllCollectionSponsoringSales"];
  networkStatus: NetworkStatus;
  tab: string;
  navigate: NavigateFunction;
}

export default function CollectionSponsorshipsTabContent({
  sponsorings,
  networkStatus,
  tab,
  navigate,
}: ICollectionSponsorshipsTabContentProps) {
  const [editingSponsoring, setEditingSponsoring] = useState<
    IListCollectionSponsoringSalesQuery["listAllCollectionSponsoringSales"][0] | null
  >(null);

  const [archiveSponsoring] = useMutation<
    IArchiveCollectionSponsoringSaleMutation,
    IArchiveCollectionSponsoringSaleMutationVariables
  >(ArchiveCollectionSponsoringSale, {
    refetchQueries: ["ListCollectionSponsoringSales", "AllCollectionSponsoringSalesCounts"],
  });

  const location = useLocation();
  const query = queryString.parse(location.search);
  const setSearchTerm = (searchTerm: string) => {
    const nextQuery = queryString.stringify({
      ...query,
      search: searchTerm.trim() || undefined,
    });

    navigate(`/collections/sponsorships?${nextQuery}`);
  };

  const searchQuery = (query.search as string) || "";
  const searchRegex = new RegExp(fp.escapeRegExp(searchQuery), "i");
  const filteredSponsorings = sponsorings.filter((sponsoring) => searchRegex.test(sponsoring.description));

  return (
    <div>
      <div
        css={{
          marginBottom: 20,
        }}
      >
        <AddCollectionSponsoring />
      </div>
      <div
        css={{
          marginBottom: 20,
        }}
      >
        <Input.Search
          size="large"
          placeholder="Search for projects..."
          autoComplete="off"
          onSearch={setSearchTerm}
          style={{
            fontSize: 14,
          }}
        />
      </div>

      <Table
        rowKey="_id"
        dataSource={filteredSponsorings}
        loading={networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables}
        bordered={true}
        scroll={{
          x: true,
        }}
      >
        <Column dataIndex="description" title="Description" />
        <Column
          dataIndex="vendor"
          title="Vendor"
          render={(vendor) => (
            <Link to={`/vendors/${vendor._id}`}>
              <Avatar src={vendor.logo} /> {vendor.name}
            </Link>
          )}
        />
        <Column
          dataIndex="type"
          title="Type"
          render={(type) => {
            if (type === ICollectionSponsoringType.Curated) {
              return "Curated";
            }

            if (type === ICollectionSponsoringType.Sponsored) {
              return "Custom Collection";
            }

            return "N/A";
          }}
        />
        <Column dataIndex="sponsorshipLevel" title="Level" render={(level: string) => capitalize(level)} />
        <Column
          dataIndex="includedCollections"
          title="Collection"
          render={(
            collections: IListCollectionSponsoringSalesQuery["listAllCollectionSponsoringSales"][0]["includedCollections"],
          ) => {
            if (!collections) {
              return <strong>None</strong>;
            }

            if (collections.length === 0) {
              return <strong>None</strong>;
            }

            return collections.map((collection) => collection.name).join(", ");
          }}
        />
        <Column dataIndex="price" title="Price ($)" render={(price) => `$${(price / 100).toFixed(2)}`} />
        <Column<IListCollectionSponsoringSalesQuery["listAllCollectionSponsoringSales"][0]>
          dataIndex="expirationDate"
          title="Expiration / Renew"
          render={(expirationDate, sponsoring) => {
            if (sponsoring.__typename === "LumpSumCollectionSponsoringSale") {
              return moment(expirationDate).format("MM/DD/Y");
            } else {
              return sponsoring.renewsAt
                ? moment(sponsoring.renewsAt).format("MM/DD/Y")
                : sponsoring.cancelsAt
                ? `Cancels on ${moment(sponsoring.cancelsAt).format("MM/DD/Y")}`
                : "N/A";
            }
          }}
        />
        <Column
          dataIndex="isActive"
          title="Active?"
          render={(isActive) =>
            isActive ? (
              <Typography.Text type="success">Yes</Typography.Text>
            ) : (
              <Typography.Text type="danger">No</Typography.Text>
            )
          }
        />
        <Column
          dataIndex="paymentSchedule"
          title="Payment"
          render={(paymentSchedule) => {
            if (paymentSchedule === ICollectionSponsoringPaymentSchedule.LumpSum) {
              return "Lump Sum";
            }

            return capitalize(paymentSchedule);
          }}
        />
        <Column
          dataIndex={["stripeState", "latestInvoiceStatus"]}
          title="Latest Invoice"
          render={(invoiceStatus) => {
            if (invoiceStatus === "open") {
              return <Typography.Text type="warning">Open</Typography.Text>;
            }

            if (invoiceStatus === "paid") {
              return <Typography.Text type="success">Paid</Typography.Text>;
            }

            if (invoiceStatus === "void") {
              return <Typography.Text type="danger">Void</Typography.Text>;
            }

            return invoiceStatus;
          }}
        />
        {tab !== "archived" && (
          <Column
            dataIndex={["stripeState", "latestInvoiceHostedPayUrl"]}
            title="Pay URL"
            render={(url) => {
              return url ? (
                <a href={url} target="_blank" rel="noreferrer noopener">
                  Invoice
                </a>
              ) : (
                "N/A"
              );
            }}
          />
        )}
        {tab === "archived" && (
          <>
            <Column dataIndex="archivedAt" title="Archived At" />
            <Column
              dataIndex="archivedBy"
              title="Archived By"
              render={(
                archivedBy: IListCollectionSponsoringSalesQuery["listAllCollectionSponsoringSales"][0]["archivedBy"],
              ) => {
                return archivedBy?.fullName || "N/A";
              }}
            />
          </>
        )}

        <Column<IListCollectionSponsoringSalesQuery["listAllCollectionSponsoringSales"][0]>
          dataIndex="_id"
          title="Actions"
          render={(_, sponsoring) => (
            <ButtonGroup>
              <Button
                onClick={() => setEditingSponsoring(sponsoring)}
                icon={<EditOutlined />}
                disabled={sponsoring.archivedAt}
              >
                Edit
              </Button>
              {!sponsoring.archivedAt &&
                !(sponsoring.__typename === "LumpSumCollectionSponsoringSale" && sponsoring.isActive) && (
                  <Popconfirm
                    title="Are you sure? This action cannot be reverted"
                    onConfirm={() => {
                      archiveSponsoring({
                        variables: {
                          saleId: sponsoring._id,
                        },
                      }).then((result) => {
                        if (result.data && result.data.archiveCollectionSponsoringSale) {
                          notification.open({
                            type: "success",
                            message: "Collection sponsorship archived!",
                          });
                        }
                      });
                    }}
                  >
                    <Button danger={true} icon={<PoweroffOutlined />}>
                      Archive
                    </Button>
                  </Popconfirm>
                )}
            </ButtonGroup>
          )}
        />
      </Table>

      {editingSponsoring && (
        <UpdateCollectionSponsoring sponsoring={editingSponsoring} onClose={() => setEditingSponsoring(null)} />
      )}
    </div>
  );
}
