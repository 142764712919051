import * as Yup from "yup";
import { IPlanTier } from "../../../types/types";

export default Yup.object().shape({
  name: Yup.string().required("Can't be blank"),
  displayName: Yup.string().required("Can't be blank"),
  tier: Yup.string()
    .oneOf([IPlanTier.Basic, IPlanTier.Makers, IPlanTier.Makerspro, IPlanTier.Disruptor])
    .required("Can't be blank"),
  stripePlanId: Yup.string()
    .nullable()
    .when("tier", {
      is: (value: IPlanTier) => value === IPlanTier.Makers || value === IPlanTier.Makerspro,
      then: Yup.string().required("Can't be blank"),
      otherwise: Yup.string().nullable(),
    }),
  products: Yup.number().required("Can't be blank").min(1, "Minimum of 1"),
  enrichedProducts: Yup.number().required("Can't be blank"),
  groups: Yup.number().required("Can't be blank"),
  groupInvitations: Yup.number().required("Can't be blank").min(0, "Minimum of 0"),
  teamMembers: Yup.number().required("Can't be blank").min(1, "Minimum of 1"),
  productTags: Yup.number().required("Can't be blank").min(1, "Minimum of 1"),
  projectsPerMonth: Yup.number().required("Can't be blank").min(1, "Minimum of 1"),
});
