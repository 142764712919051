import gql from "graphql-tag";
import OnboardingFields from "./fragments/OnboardingFields";

export default gql`
  query ListOnboardings(
    $filterByProcessStatus: ProcessStatusType = REQUIRED
    $search: String
    $sortBy: String
    $cursor: Cursor
  ) {
    listOnboardings(filterByProcessStatus: $filterByProcessStatus, search: $search, sortBy: $sortBy, cursor: $cursor) {
      items {
        ...OnboardingFields
        user {
          __typename
          ... on PendingUser {
            _id
            fullName
            slug
            verified
          }
          ... on VendorUser {
            _id
            fullName
            slug
            isDisruptor
          }
          ... on GovernmentUser {
            _id
            fullName
            slug
            isInnovator
            government {
              _id
              slug
              name
              logo
              isInnovator
            }
          }
        }
      }
      pageInfo {
        totalCount
      }
    }
  }

  ${OnboardingFields}
`;
