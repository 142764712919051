import React, { ReactElement } from "react";
import { Query } from "react-apollo";
import GetGovernmentsCountQuery from "../../queries/GetGovernmentsCountQuery";
import {
  IGovernmentsCountQuery,
  IGovernmentsCountQueryVariables,
} from "../../queries/__generated__/GetGovernmentsCountQuery.generated";

interface IGovernmentsCountProps {
  filterByCountry?: string;
  children: (
    props: NonNullable<Exclude<IGovernmentsCountQuery["governmentsCount"], "__typename">> & {
      loading: boolean;
      refetch: () => void;
    },
  ) => ReactElement | null;
}

export default function GovernmentsCount({ filterByCountry, children }: IGovernmentsCountProps) {
  return (
    <Query<IGovernmentsCountQuery, IGovernmentsCountQueryVariables>
      query={GetGovernmentsCountQuery}
      variables={{
        filterByCountry: filterByCountry || null,
      }}
    >
      {({ data, loading, refetch }) => {
        if (!data || loading) {
          return null;
        }
        if (!data.governmentsCount) {
          return null;
        }

        return children({
          ...data.governmentsCount,
          loading,
          refetch,
        });
      }}
    </Query>
  );
}
