import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faBoxes,
  faBoxOpen,
  faBuilding,
  faCalendarPlus,
  faChartBar,
  faClipboard,
  faCogs,
  faDraftingCompass,
  faEnvelopeOpen,
  faExternalLinkAlt,
  faHandshake,
  faHistory,
  faHome,
  faImages,
  faInfo,
  faLandmark,
  faMailBulk,
  faMoneyBillAlt,
  faPodcast,
  faScroll,
  faTags,
  faUserFriends,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface IMenuItemIconProps {
  iconName: IconDefinition;
  collapsed?: boolean;
  flip?: "horizontal" | "vertical" | "both" | undefined;
}

function MenuItemIcon({ iconName, collapsed = true, flip }: IMenuItemIconProps) {
  return (
    <div
      css={
        collapsed
          ? {
              display: "flex",
              justifyContent: "center",
              fontSize: 21,
              paddingTop: 10,
            }
          : {
              display: "flex",
              justifyContent: "center",
              fontsize: 16,
              width: 25,
              marginRight: 3,
            }
      }
    >
      <FontAwesomeIcon icon={iconName} flip={flip} />
    </div>
  );
}

interface IMenuIconProps {
  collapsed?: boolean;
}

export function UsersIcon({ collapsed = true }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faUserFriends} />;
}

export function GovernmentsIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faLandmark} />;
}

export function VendorsIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faBuilding} />;
}

export function ProductsIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faBoxOpen} />;
}

export function ReportsIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faChartBar} />;
}

export function StoriesIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faScroll} />;
}

export function ProjectsIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faDraftingCompass} />;
}

export function GroupsIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faUsers} />;
}

export function PostsIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faClipboard} />;
}
export function TagsIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faTags} />;
}

export function CollectionsIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faBoxes} />;
}

export function BillingIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faMoneyBillAlt} />;
}

export function ExternalLinksIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faExternalLinkAlt} />;
}

export function EventsIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faCalendarPlus} />;
}

export function LandingPageIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faHome} />;
}

export function AboutPageIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faInfo} />;
}

export function SponsorshipIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faHandshake} />;
}

export function PodcastIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faPodcast} />;
}

export function CampaingsIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faEnvelopeOpen} />;
}

export function NewsletterIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faMailBulk} />;
}

export function SettingsIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faCogs} />;
}

export function ScheduleIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faHistory} flip="horizontal" />;
}

export function ImagesIcon({ collapsed }: IMenuIconProps) {
  return <MenuItemIcon collapsed={collapsed} iconName={faImages} />;
}
