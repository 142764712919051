import React from "react";
import * as palette from "@govlaunch/palette";
import * as Types from "../../../../types/types";
import VendorCurrentPlan from "./VendorCurrentPlan";
import { IVendorQuery } from "../../../queries/__generated__/VendorQuery.generated";
import StripeLnk from "../makers/StripeLink";

interface IManualSubscriptionHeaderProps {
  title: string;
  plan: { __typename: "Plan" } & Pick<Types.IPlan, "_id" | "name" | "tier" | "stripePlanId" | "isSelectable">;
  subscription: NonNullable<IVendorQuery["company"]>["subscription"];
  stripeCustomerId?: string | null;
}

export default function ManualSubscriptionHeader({
  title,
  plan,
  subscription,
  stripeCustomerId,
}: IManualSubscriptionHeaderProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `1px solid ${palette.lightestGray}`,
        padding: 15,
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          css={{
            textTransform: "uppercase",
            fontSize: 12,
            color: palette.darkGray,
            letterSpacing: 0.5,
            fontWeight: 500,
          }}
        >
          {title}
        </div>
        <VendorCurrentPlan plan={plan} subscription={subscription} />
      </div>
      {stripeCustomerId && <StripeLnk stripeCustomerId={stripeCustomerId} />}
    </div>
  );
}
