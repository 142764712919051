import gql from "graphql-tag";

export default gql`
  mutation EditGovernment($governmentId: ObjectId!, $payload: EditGovernmentInput!) {
    editGovernment(governmentId: $governmentId, payload: $payload) {
      _id
      slug
      site
      budget
      currency
      city {
        _id
        name
        population
        type
        state
        stateName
        governmentType
        county
        country
        zip
      }
      awards {
        _id
        name
      }
      resources {
        ... on LinkResource {
          title
          url
        }
        ... on TextResource {
          title
          body
        }
        ... on DownloadResource {
          title
          url
          filename
        }
      }
    }
  }
`;
