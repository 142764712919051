import gql from "graphql-tag";

export default gql`
  mutation ToggleCollectionPublicContentAvailable($collectionId: ObjectId!) {
    toggleCollectionPublicContentAvailable(collectionId: $collectionId) {
      _id
      publicContentAvailable
    }
  }
`;
