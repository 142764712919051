import gql from "graphql-tag";

export default gql`
  mutation AddReport($report: ReportInput!) {
    addReport(report: $report) {
      _id
      slug
    }
  }
`;
