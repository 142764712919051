import { LoadingIndicator } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { CSSReset, theme, ThemeProvider } from "@govlaunch/web";
import React from "react";
import { Query, useMutation } from "react-apollo";
import DocumentTitle from "react-document-title";
import EditStoryMutation from "../../mutations/EditStory";
import { IEditStoryMutation, IEditStoryMutationVariables } from "../../mutations/__generated__/EditStory.generated";
import StoryBySlugQuery from "../../queries/StoryBySlugQuery";
import { IStoryBySlugQuery, IStoryBySlugQueryVariables } from "../../queries/__generated__/StoryBySlugQuery.generated";
import ScrollToTopOnMount from "../../ScrollToTopOnMount";
import convertStoryToInitialValues from "./convertStoryToInitialValues";
import { convertValuesToEditStoryVariables } from "./convertValuesToVariables";
import StoryComposer from "./StoryComposer";
import { notification } from "antd";
import { Navigate, useNavigate, useParams } from "react-router";

interface IEditStoryProps {
  successUrl?: string;
}

export default function EditStory({ successUrl }: IEditStoryProps) {
  const navigate = useNavigate();
  const params = useParams();
  const [editStory] = useMutation<IEditStoryMutation, IEditStoryMutationVariables>(EditStoryMutation);

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />

      <DocumentTitle title="Edit Story | Admin">
        <Query<IStoryBySlugQuery, IStoryBySlugQueryVariables>
          fetchPolicy="network-only"
          query={StoryBySlugQuery}
          variables={{
            slug: params.slug || "",
          }}
        >
          {({ data, loading }) => {
            if (loading || !data) {
              return (
                <div
                  css={{
                    padding: 20,
                  }}
                >
                  <LoadingIndicator />
                </div>
              );
            }

            const story = data.storyBySlug;

            if (!story) {
              return <Navigate to="/stories" />;
            }

            return (
              <DocumentTitle title={`Admin | Edit • ${story.title}`}>
                <ScrollToTopOnMount>
                  <div
                    css={{
                      backgroundColor: palette.washGray,
                      borderBottom: "1px solid rgb(232, 232, 232)",
                    }}
                  >
                    <StoryComposer
                      initialValues={convertStoryToInitialValues(story)}
                      onSubmit={async (values) => {
                        const { data } = await editStory({
                          variables: convertValuesToEditStoryVariables(values),
                        });

                        if (data) {
                          notification.open({
                            type: "success",
                            message: "Story successfully saved!",
                            description: `${data.editStory.title} was saved!`,
                          });

                          navigate(successUrl || `/stories/${data.editStory.slug}`);
                        }
                      }}
                      submitButtonText="Save Changes"
                      storySlug={data.storyBySlug?.slug}
                    />
                  </div>
                </ScrollToTopOnMount>
              </DocumentTitle>
            );
          }}
        </Query>
      </DocumentTitle>
    </ThemeProvider>
  );
}
