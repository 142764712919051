import * as palette from "@govlaunch/palette";

import React from "react";
import numeral from "numeral";
import { IProjectAttachmentItem } from "../../../../types/types";

interface IAttachmentItemProps {
  attachment: IProjectAttachmentItem;
  onRemove: (attachment: IProjectAttachmentItem) => any;
  [key: string]: any;
}

export default function AttachmentItem({ attachment, onRemove, ...props }: IAttachmentItemProps) {
  return (
    <div
      {...props}
      css={{
        display: "grid",
        gridTemplateAreas: '"anchor remove"',
        gridTemplateColumns: "auto 1fr",
        gridColumnGap: 10,
        gridRowGap: 2.5,
        alignItems: "center",
        backgroundColor: "rgba(219, 236, 247, 0.5)",
        borderRadius: 60,
        padding: "10px 15px",
      }}
    >
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={attachment.url as string}
        css={{
          gridArea: "anchor",
          display: "grid",
          gridTemplateAreas: '"icon title" "icon description"',
          gridTemplateColumns: "auto 1fr",
          gridColumnGap: 10,
          gridRowGap: 2.5,
          alignItems: "center",
          "&:focus": {
            outline: 0,
          },
        }}
      >
        <div
          css={{
            gridArea: "icon",
            display: "inline-flex",
          }}
        >
          <AttachmentIcon mimeType={attachment.mimeType as string} />
        </div>

        <div
          css={{
            gridArea: "title",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontSize: 14,
            fontWeight: 600,
            color: palette.primary,
            lineHeight: 1,
          }}
        >
          {attachment.title}
        </div>

        <div
          css={{
            gridArea: "description",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontSize: 12,
            color: palette.primary,
            lineHeight: 1,
          }}
        >
          {numeral(attachment.size).format("0.00b")}
        </div>
      </a>

      {onRemove && (
        <svg
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            onRemove(attachment);
          }}
          viewBox="0 0 117 117"
          width={28}
          height={28}
          css={{
            marginLeft: "auto",
            gridArea: "remove",
            cursor: "pointer",
            "&:hover": {
              opacity: 0.8,
            },
          }}
        >
          <g fill="#FF7171" fillRule="evenodd">
            <path
              fillRule="nonzero"
              d="M58.5 116.4c31.9 0 57.9-26 57.9-57.9S90.4.6 58.5.6.6 26.6.6 58.5s26 57.9 57.9 57.9zm0-107.7c27.4 0 49.8 22.3 49.8 49.8 0 27.5-22.3 49.8-49.8 49.8-27.5 0-49.8-22.4-49.8-49.8S31.1 8.7 58.5 8.7z"
            />
            <path d="M31.8 62h53.3c2.3 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1H31.8c-2.3 0-4.1 1.8-4.1 4.1s1.9 4.1 4.1 4.1z" />
          </g>
        </svg>
      )}
    </div>
  );
}

function AttachmentIcon({ mimeType }: { mimeType: string }) {
  if (mimeType === "application/pdf") {
    return (
      <svg viewBox="0 0 481.882 481.882" width={28} height={28}>
        <path
          d="M60.235 28.913C60.235 12.943 73.178 0 89.148 0h212.028l120.471 101.195v351.774c0 15.97-12.943 28.913-28.913 28.913H89.148c-15.97 0-28.913-12.943-28.913-28.913V28.913z"
          fill="#ef5350"
        />
        <path d="M301.176 0v72.282c0 15.97 12.943 28.913 28.913 28.913h91.558" fill="#d32f2f" />
        <g fill="#fff">
          <path d="M150.904 305.536H139.61v34.643h-25.005v-104.38h32.986c28.469 0 44.732 11.144 44.732 34.04-.007 24.561-16.722 35.697-41.419 35.697zm-3.011-51.968h-8.282v33.89h9.186c11.294 0 17.769-5.429 17.769-17.626 0-10.986-6.174-16.264-18.673-16.264zM231.77 340.179h-28.318v-104.38h26.511c26.82 0 52.412 8.584 52.412 51.674.001 42.451-24.553 52.706-50.605 52.706zm1.8-86.31h-5.12v68.239h5.12c13.704 0 22.889-7.379 22.889-34.65.015-27.851-9.78-33.589-22.889-33.589zM325.888 253.568v27.422h36.45v17.92h-36.45v41.269h-25.156v-104.38h68.834l-2.56 17.769h-41.118z" />
        </g>
      </svg>
    );
  } else if (mimeType.startsWith("image")) {
    return (
      <svg viewBox="0 0 58 58" width={28} height={28}>
        <path fill="#c3e1ed" stroke="#e7eced" strokeWidth={2} strokeMiterlimit={10} d="M1 7h56v44H1z" />
        <circle cx={16} cy={17.569} r={6.569} fill="#ed8a19" />
        <path
          fill="#1a9172"
          d="M56 36.111L55 35 43 24 32.5 35.5l5.483 5.483L42 45h14zM2 49h24l-4.017-4.017-10.966-10.966L2 41.956z"
        />
        <path fill="#6b5b4b" d="M2 45h54v5H2z" />
        <path fill="#25ae88" d="M37.983 40.983L27.017 30.017 10 45h32z" />
      </svg>
    );
  } else {
    return (
      <svg viewBox="0 0 501.551 501.551" width={28} height={28}>
        <path
          d="M149.943 0L43.363 107.624v373.029c0 11.494 9.404 20.898 20.898 20.898H437.29c11.494 0 20.898-9.404 20.898-20.898V20.898C458.188 9.404 448.784 0 437.29 0H149.943z"
          fill="#f2f2f2"
        />
        <path d="M149.943 86.727V0L43.363 107.624h86.727c11.494 0 19.853-9.404 19.853-20.897z" fill="#cdd6e0" />
        <path fill="#84dbff" d="M246.073 74.188h159.869v190.171H246.073z" />
        <g fill="#cdd6e0">
          <path d="M92.473 156.735h123.298v26.122H92.473zM92.473 238.237h123.298v26.122H92.473zM92.473 318.694h316.604v26.122H92.473zM92.473 400.196h316.604v26.122H92.473z" />
        </g>
      </svg>
    );
  }
}
