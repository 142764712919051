import { LoadingIndicator } from "@govlaunch/core";
import React from "react";
import { Query } from "react-apollo";
import { IUserFieldsFragment } from "../../../queries/fragments/__generated__/UserFields.generated";
import GetOnboardingData from "../../../queries/GetOnboardingData";
import {
  IOnboardingQuery,
  IOnboardingQueryVariables,
} from "../../../queries/__generated__/GetOnboardingData.generated";
import OnboardingHeader from "./OnboardingHeader";

interface IUserOnboardingTabProps {
  user: IUserFieldsFragment;
  refetchUser: () => any;
}

export default function UserOnboardingTab({ user, refetchUser }: IUserOnboardingTabProps) {
  return (
    <Query<IOnboardingQuery, IOnboardingQueryVariables>
      query={GetOnboardingData}
      variables={{
        userId: user._id,
      }}
    >
      {({ data, loading, refetch }) => {
        if (loading || !data) {
          return (
            <div
              css={{
                padding: 20,
              }}
            >
              <LoadingIndicator />
            </div>
          );
        }

        return (
          <OnboardingHeader
            user={user}
            onboarding={data.onboarding}
            refetch={async () => {
              await refetchUser();
              await refetch();
            }}
          />
        );
      }}
    </Query>
  );
}
