import React from "react";
import * as palette from "@govlaunch/palette";
import DocumentTitle from "react-document-title";
import { Form } from "react-final-form";
import campaignValidationSchema from "./campaignValidationSchema";
import validate from "../../validate";
import NewsletterCampaignForm from "./NewsletterCampaignForm";
import { useQuery , useMutation } from "react-apollo";
import GetNewsletterCampaign from "./graphql/GetNewsletterCampaign";
import {
  IGetNewsletterCampaignQuery,
  IGetNewsletterCampaignQueryVariables,
} from "./graphql/__generated__/GetNewsletterCampaign.generated";
import { convertCampaignToIntialVariables } from "./convertCampaignToInitialVariables";

import EditNewsletterCampaignMutation from "./graphql/EditNewsletterCampaignMutation";
import {
  IEditNewsletterCampaignMutation,
  IEditNewsletterCampaignMutationVariables,
} from "./graphql/__generated__/EditNewsletterCampaignMutation.generated";
import ListNewsletterCampaignsQuery from "./graphql/ListNewsletterCampaignsQuery";
import { TCampaignCountry } from "./ProjectsPerCountryField";
import { notification } from "antd";
import { FORM_ERROR } from "final-form";
import { checkForDuplicates, validateProjectsPerCountry } from "./validateProjectsPerCountry";
import { useNavigate, useParams } from "react-router";

export default function EditNewsletterCampaign() {
  const params = useParams();
  const navigate = useNavigate();
  const campaignId = params.id;

  const [editNewsletterCampaign] = useMutation<
    IEditNewsletterCampaignMutation,
    IEditNewsletterCampaignMutationVariables
  >(EditNewsletterCampaignMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ListNewsletterCampaignsQuery,
      },
    ],
  });

  const { data, loading } = useQuery<IGetNewsletterCampaignQuery, IGetNewsletterCampaignQueryVariables>(
    GetNewsletterCampaign,
    {
      variables: {
        campaignId,
      },
      fetchPolicy: "network-only",
    },
  );

  if (!data || loading || !data.getNewsletterCampaign) {
    return null;
  }

  const campaign = data.getNewsletterCampaign;

  return (
    <DocumentTitle title="Edit Campaign">
      <div css={{
overflow: "auto"
}}
      >
        <div
          css={{
            borderBottom: "1px solid rgb(232, 232, 232)",
            padding: 20,
          }}
        >
          <h3
            css={{
              fontWeight: 700,
              fontSize: 24,
              margin: 0,
            }}
          >
            Edit Campaign
          </h3>
        </div>

        <div
          css={{
            background: palette.washGray,
            paddingTop: "30px",
            minHeight: "100vh",
          }}
        >
          <Form
            initialValues={convertCampaignToIntialVariables(campaign)}
            onSubmit={async (values) => {
              if (validateProjectsPerCountry(values.projectsPerCountry)) {
                return {
                  [FORM_ERROR]: "Invalid project per country setting",
                };
              }

              if (checkForDuplicates(values.projectsPerCountry)) {
                return {
                  [FORM_ERROR]: "Multiple country entries",
                };
              }

              const result = await editNewsletterCampaign({
                variables: {
                  id: campaign._id,
                  input: {
                    name: values.name,
                    dynamicContent: {
                      storiesCount: values.storiesCount,
                      projectsCount: values.projectsCount,
                      topCollections: values.topCollections,
                      otherCollections: values.otherCollections,
                    },
                    countriesAmount: values.projectsPerCountry.map((item: TCampaignCountry) => ({
                      countryCode: item.countryCode,
                      amount: item.amount,
                    })),
                  },
                },
              });

              if (result && result.data && result.data.editNewsletterCampaign) {
                notification.success({
                  message: "Success!",
                  description: "Campaign saved successfully",
                });

                navigate("/campaigns/list");
              }
            }}
            validate={validate(campaignValidationSchema)}
            keepDirtyOnReinitialize={true}
          >
            {({ handleSubmit, submitting, submitError }) => (
              <NewsletterCampaignForm handleSubmit={handleSubmit} submitting={submitting} submitError={submitError} />
            )}
          </Form>
        </div>
      </div>
    </DocumentTitle>
  );
}
