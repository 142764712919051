import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";

interface IOption {
  value: any;
  label: string;
}

interface ICheckboxGroupFieldProps {
  label: string;
  fieldName: string;
  options: IOption[];
  toggleAllOption?: IOption;
}

export default function CheckboxGroupField({ label, fieldName, options, toggleAllOption }: ICheckboxGroupFieldProps) {
  const maxValues = options.length;
  return (
    <div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <label
          css={{
            fontWeight: 500,
            fontSize: 14,
            color: palette.mediumGray,
            margin: 0,
          }}
        >
          {label}
        </label>
      </div>

      <Field name={fieldName}>
        {({ input }) => (
          <div
            css={{
              marginTop: 8,
              display: "grid",
              gridRowGap: 8,
              gridColumnGap: 8,
            }}
          >
            {options.map((option, index) => (
              <label key={`${option.value}_${index}`}>
                <input
                  type="checkbox"
                  disabled={toggleAllOption && input.value.includes(toggleAllOption.value)}
                  checked={
                    input.value.includes(option.value) ||
                    (toggleAllOption && input.value.includes(toggleAllOption.value))
                  }
                  onChange={toggleCheckboxGroupFieldValue(
                    option.value,
                    maxValues,
                    input,
                    toggleAllOption && toggleAllOption.value,
                  )}
                />
                <span
                  css={{
                    marginLeft: 4,
                    color: palette.sealBlue,
                    fontSize: 13,
                    textTransform: "uppercase",
                  }}
                >
                  {option.label}
                </span>
              </label>
            ))}

            {toggleAllOption && (
              <label>
                <input
                  type="checkbox"
                  checked={input.value.includes(toggleAllOption.value)}
                  onChange={() =>
                    input.onChange(!input.value.includes(toggleAllOption.value) ? [toggleAllOption.value] : [])
                  }
                />
                <span
                  css={{
                    marginLeft: 4,
                    color: palette.sealBlue,
                    fontSize: 13,
                    textTransform: "uppercase",
                  }}
                >
                  {toggleAllOption.label}
                </span>
              </label>
            )}
          </div>
        )}
      </Field>
    </div>
  );
}

function toggleCheckboxGroupFieldValue(value: string, maxValues: number, input: any, toggleAllValue?: string) {
  return () => {
    if (input.value.includes(value)) {
      return input.onChange(input.value.filter((maybeValue: string) => maybeValue !== value));
    }

    if (toggleAllValue && input.value.length === maxValues - 1) {
      return input.onChange([toggleAllValue]);
    }

    return input.onChange(input.value.concat(value));
  };
}
