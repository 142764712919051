import { Mutation } from "react-apollo";
import PropTypes from "prop-types";
import React from "react";
import SetProfileCoverMutation from "../../mutations/SetProfileCoverMutation";
import {
  ISetProfileCoverMutation,
  ISetProfileCoverMutationVariables,
} from "../../mutations/__generated__/SetProfileCoverMutation.generated";
import { IProfileCoverTarget } from "../../../types/types";

interface ISetProfileCoverProps {
  entityType: "Government" | "Company" | "VendorUser" | "GovernmentUser" | "PendingUser";
  targetId: string;
  children: (setProfileCover: ISetProfileCover) => any;
}

interface ISetProfileCover {
  onSetProfileCover: (originalSource: string) => any;
  onSetRepositionedCover: (originalSource: string, repositionedSource: any, repositionState: any) => any;
  onRemoveCover: () => any;
}

export default function SetProfileCover({ entityType, targetId, children }: ISetProfileCoverProps) {
  return (
    <Mutation<ISetProfileCoverMutation, ISetProfileCoverMutationVariables> mutation={SetProfileCoverMutation}>
      {setProfileCover =>
        children({
          onSetProfileCover: originalSource => {
            setProfileCover({
              variables: {
                targetId,
                targetType: getTargetType(entityType) as IProfileCoverTarget,
                cover: {
                  original: originalSource,
                  repositioned: null,
                  reposition: null,
                },
              },
              optimisticResponse: {
                __typename: "Mutation",
                setProfileCover: {
                  __typename: entityType as any,
                  _id: targetId,
                  cover: {
                    __typename: "Cover",
                    original: originalSource,
                    repositioned: null,
                  },
                },
              },
            });
          },
          onSetRepositionedCover: (originalSource, repositionedSource, repositionState) => {
            setProfileCover({
              variables: {
                targetId,
                targetType: getTargetType(entityType) as IProfileCoverTarget,
                cover: {
                  original: originalSource,
                  repositioned: repositionedSource,
                  reposition: repositionState,
                },
              },
              optimisticResponse: {
                __typename: "Mutation",
                setProfileCover: {
                  __typename: entityType as any,
                  _id: targetId,
                  cover: {
                    __typename: "Cover",
                    original: originalSource,
                    repositioned: repositionedSource,
                  },
                },
              },
            });
          },
          onRemoveCover: () =>
            setProfileCover({
              variables: {
                targetId,
                targetType: getTargetType(entityType) as IProfileCoverTarget,
                cover: null,
              },
              optimisticResponse: {
                __typename: "Mutation",
                setProfileCover: {
                  __typename: entityType as any,
                  _id: targetId,
                  cover: {
                    __typename: "Cover",
                    original: null,
                    repositioned: null,
                  },
                },
              },
            }),
        })
      }
    </Mutation>
  );
}

SetProfileCover.propTypes = {
  entityType: PropTypes.oneOf(["Government", "Company", "VendorUser", "GovernmentUser"]).isRequired,
  targetId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

function getTargetType(entityType: "Government" | "Company" | "VendorUser" | "GovernmentUser" | "PendingUser") {
  if (entityType === "VendorUser" || entityType === "GovernmentUser") {
    return "USER";
  } else if (entityType === "Company") {
    return "COMPANY";
  } else if (entityType === "Government") {
    return "GOVERNMENT";
  } else {
    // should never happen... but ¯\_(ツ)_/¯
    throw new Error("Invalid entityType provided");
  }
}
