import gql from "graphql-tag";

export default gql`
  fragment SolutionFields on Solution {
    _id
    name
    slug
    status
    createdAt
    updatedAt
    archivedAt
    submittedBy {
      ... on PendingUser {
        _id
        slug
        firstName
        lastName
      }
      ... on VendorUser {
        _id
        slug
        firstName
        lastName
      }
      ... on GovernmentUser {
        _id
        slug
        firstName
        lastName
      }
    }
    originProduct {
      _id
      name
      slug
    }
  }
`;
