import getOr from "lodash/fp/getOr";
import queryString from "query-string";
import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { IProjectType } from "../../../../types/types";
import SearchProjectsQuery from "../../../queries/SearchProjectsQuery";
import {
  ISearchProjectsQuery,
  ISearchProjectsQueryVariables,
} from "../../../queries/__generated__/SearchProjectsQuery.generated";
import ProjectsTable from "./ProjectsTable";
import TableAddStateToQueryString from "../../../components/table/TableAddStateToQueryString";
import { useLocation, useNavigate } from "react-router";
import { parseState } from "../../../utils/tablesUtils";
import { SortOrder } from "antd/lib/table/interface";
import TablePagination, { DEFAULT_CURSOR, ITablePaginationState } from "../../../components/table/TablePagination";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import TableOnSearch from "../../../components/table/TableOnSearch";
import { Button } from "antd";
import * as palette from "@govlaunch/palette";
import ProjectsBulkActionsButton from "../bulk/ProjectsBulkActions";
import { IVendorProjectFieldsFragment } from "../../../queries/fragments/__generated__/VendorProjectFields.generated";
import { IGovernmentProjectFieldsFragment } from "../../../queries/fragments/__generated__/GovernmentProjectFields.generated";
import { IGroupProjectFieldsFragment } from "../../../queries/fragments/__generated__/GroupProjectFields.generated";

export interface IProjectsTableState {
  sortColumnKey: string;
  sortColumnOrder: SortOrder;
  audience: string | null | undefined;
  status: string[] | null;
  country: string | null;
  verified: boolean | null;
  cursor: ITablePaginationState;
}

interface IManageProjectsProps {
  search: string | null;
  archivedOnly?: boolean;
  filterByType: IProjectType | null;
  filterByTag?: any | null;
  userCreated?: boolean;
  historyOrigin: string;
}

export default function ManageProjects({
  search,
  archivedOnly,
  filterByType,
  filterByTag,
  userCreated,
  historyOrigin,
}: IManageProjectsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    sortColumnKey: "",
    sortColumnOrder: "ascend",
    audience: null,
    status: null,
    country: null,
    verified: null,
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<IProjectsTableState>(initialFiltersState);
  const [projectsSelection, setProjectsSelection] = useState<
    Array<IGovernmentProjectFieldsFragment | IGroupProjectFieldsFragment | IVendorProjectFieldsFragment>
  >([]);

  const { data, loading, refetch } = useQuery<ISearchProjectsQuery, ISearchProjectsQueryVariables>(
    SearchProjectsQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        title: (query.search as string) || "",
        tags: filterByTag ? [filterByTag] : null,
        audience: initialFiltersState.audience || null,
        status: initialFiltersState.status || null,
        sort:
          initialFiltersState.sortColumnKey && initialFiltersState.sortColumnOrder
            ? {
                sortField: initialFiltersState.sortColumnKey,
                sortOrder: initialFiltersState.sortColumnOrder === "ascend" ? "asc" : "desc",
              }
            : null,
        archivedOnly: archivedOnly ? archivedOnly : null,
        userCreated: userCreated ? userCreated : null,
        verified:
          typeof initialFiltersState.verified !== "undefined" && initialFiltersState.verified !== null
            ? initialFiltersState.verified
            : null,
        filterByType,
        country: initialFiltersState.country || null,
        cursor: calculateAndEncodeCursor(state.cursor),
      },
    },
  );

  const projects = getOr([], "searchProjects.items", data);
  const totalCount =
    data && data.searchProjects && data.searchProjects.pageInfo ? data.searchProjects.pageInfo.totalCount || 0 : 0;

  return (
    <>
      <TableAddStateToQueryString tableState={state} historyOrigin={historyOrigin} query={query} />
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
 page: 1, size: state.cursor.size 
},
            });
          }
        }}
      />
      <ProjectsTable
        loading={loading}
        selection={projectsSelection}
        setSelection={setProjectsSelection}
        title={() => (
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div css={{
 display: "flex", flexDirection: "column" 
}}
            >
              <strong>{loading || typeof totalCount === "undefined" ? totalCount : `Projects (${totalCount})`}</strong>
              {projectsSelection.length > 0 && (
                <span css={{
 fontSize: 12, fontStyle: "italic", color: palette.mediumGray 
}}
                >
                  {`${projectsSelection.length} projects selected`}
                  <span css={{
 marginRight: 10 
}}
                  >
                    <Button
                      type="link"
                      css={{
 fontSize: 12, fontStyle: "italic" 
}}
                      onClick={() => {
                        setProjectsSelection([]);
                      }}
                    >
                      Clear Selection
                    </Button>
                  </span>
                </span>
              )}
            </div>
            <span>
              {projectsSelection.length > 0 && (
                <span css={{
 marginRight: 10 
}}
                >
                  <ProjectsBulkActionsButton
                    projects={projectsSelection}
                    onArchiveProjects={() => {
                      setProjectsSelection([]);
                      refetch();
                    }}
                    refetch={() => {
                      setProjectsSelection([]);
                      refetch();
                    }}
                  />
                </span>
              )}
              <Button
                onClick={() => {
                  setState({
                    sortColumnKey: "",
                    sortColumnOrder: "ascend",
                    audience: null,
                    status: null,
                    country: null,
                    verified: null,
                    cursor: {
 page: 1, size: state.cursor.size 
},
                  });

                  navigate(`${historyOrigin}?tab=${query.tab || ""}&search=${search}`);
                }}
              >
                Reset Filters
              </Button>
            </span>
          </div>
        )}
        showingArchived={archivedOnly}
        userCreated={userCreated}
        state={state}
        footer={() => (
          <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
        )}
        projects={projects}
        onChange={(_pagination: any, filter: any, sorter: any) => {
          let audience: string | null = null;
          let status: string[] | null = null;
          let country: string | null = null;
          let verified: boolean | null = null;

          if (filter.audience && ["public", "private", "community"].includes(filter.audience[0])) {
            audience = filter.audience[0];
          }

          if (filter.status && Array.isArray(filter.status) && filter.status.length > 0) {
            status = filter.status;
          }

          if (filter.country) {
            country = filter.country[0];
          }

          if (filter.verified && filter.verified[0] === "true") {
            verified = true;
          }

          if (filter.verified && filter.verified[0] === "false") {
            verified = false;
          }

          setState({
            sortColumnKey: sorter.columnKey,
            sortColumnOrder: sorter.order,
            audience,
            status,
            country,
            verified,
            cursor: {
 page: 1, size: state.cursor.size 
},
          });
        }}
      />
    </>
  );
}
