import React from "react";
import { Field } from "react-final-form";
import GovernmentInput from "../../rich/GovernmentInput";
import GroupInput from "../../rich/GroupInput";
import TypeOptions from "../../rich/TypeOptions";
import VendorInput from "../../rich/VendorInput";

export default function ProjectTypeField() {
  return (
    <Field name="entity">
      {({ input: entityInput }) => (
        <>
          <TypeOptions input={entityInput} />

          {entityInput.value === "government" && (
            <Field name="government">
              {({ input, meta }) => <GovernmentInput government={input.value} meta={meta} {...input} />}
            </Field>
          )}

          {entityInput.value === "group" && (
            <Field name="group">{({ input, meta }) => <GroupInput group={input.value} meta={meta} {...input} />}</Field>
          )}

          {entityInput.value === "vendor" && (
            <Field name="vendor">
              {({ input, meta }) => <VendorInput vendor={input.value} meta={meta} {...input} />}
            </Field>
          )}
        </>
      )}
    </Field>
  );
}
