import gql from "graphql-tag";

export default gql`
  mutation ToggleUserBan($userId: ObjectId!) {
    toggleUserBan(userId: $userId) {
      ... on GovernmentUser {
        _id
        bannedAt
      }
      ... on VendorUser {
        _id
        bannedAt
      }
      ... on PendingUser {
        _id
        bannedAt
      }
    }
  }
`;
