import React from "react";
import * as palette from "@govlaunch/palette";

export default function Resource({
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      css={{
        padding: 15,
        display: "flex",
        alignItems: "center",
        position: "relative",
        cursor: "pointer",
        borderBottom: `1px solid ${palette.lightestGray}`,
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.01)",
        },
      }}
      {...props}
    >
      {children}
    </div>
  );
}
