import React from "react";
import { IViewOnboardingFormProps } from "./Interfaces";
import * as palette from "@govlaunch/palette";
import getGovlaunchBaseUrl from "../../../utils/getGovlaunchBaseUrl";
import { Spacing } from "@govlaunch/spacers";
import ProfilePicture from "@govlaunch/profile-picture";
import OnboardingForm from "./OnboardingForm";

export default function OnboardingHeader({ user, onboarding, refetch }: IViewOnboardingFormProps) {
  return (
    <>
      <div
        id="header"
        css={{
          minWidth: 900,
          marginBottom: 10,
        }}
      >
        <div
          css={{
            padding: 20,
            backgroundColor: palette.white,
            borderTop: `solid 1px ${palette.lightSealBlue}`,
            borderBottom: `solid 1px ${palette.lightSealBlue}`,
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Spacing inline={true} flex={true} right={10}>
              <ProfilePicture
                image={user.avatar}
                size={48}
                name={user.fullName}
                innovator={user.__typename === "GovernmentUser" ? user.isInnovator : false}
                disruptor={user.__typename === "VendorUser" ? user.isDisruptor : false}
              />
            </Spacing>

            <div>
              <h2
                css={{
                  margin: 0,
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {user.fullName}
              </h2>

              <a href={`${getGovlaunchBaseUrl("portal")}/@${user.slug}`} target="_blank" rel="noopener noreferrer">
                View on Govlaunch
              </a>
            </div>
          </div>
        </div>
      </div>
      <OnboardingForm user={user} onboarding={onboarding} refetch={refetch} />
    </>
  );
}
