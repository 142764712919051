import * as palette from "@govlaunch/palette";
import { Popconfirm, Button as ButtonAnt } from "antd";
import React from "react";
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";
import ArchiveProduct from "../../mutations/ArchiveProduct";
import {
  IArchiveProductMutation,
  IArchiveProductMutationVariables,
} from "../../mutations/__generated__/ArchiveProduct.generated";
import RestoreProduct from "../../mutations/RestoreProduct";
import {
  IRestoreProductMutation,
  IRestoreProductMutationVariables,
} from "../../mutations/__generated__/RestoreProduct.generated";
import ToggleDisruptor from "../../mutations/ToggleDisruptor";
import { IAllProductsQuery, IAllProductsQueryVariables } from "../../queries/__generated__/GetProductsQuery.generated";
import VendorQuery from "../../queries/VendorQuery";
import { IVendorQuery } from "../../queries/__generated__/VendorQuery.generated";
import { useSelfie } from "../auth/Selfie";
import { ToggleFeatured } from "./mutations";
import notUndefinedOrNull from "../../utils/notUndefinedOrNull";
import TableRowActions from "../../components/table/TableRowActions";

type TProduct = NonNullable<NonNullable<IAllProductsQuery["products"]>["items"]>[0];

interface IProductActionsProps {
  product: TProduct;
  variables: IAllProductsQueryVariables;
  editUrl?: string;
  vendorSlug?: string;
}

export default function ProductActions({ product, editUrl, vendorSlug }: IProductActionsProps) {
  const selfie = useSelfie();

  if (product.archivedAt) {
    if (product.company && product.company.archivedAt) {
      return null;
    }

    return (
      <TableRowActions title="Product Actions">
        {() => (
          <>
            <li>
              <Mutation<IRestoreProductMutation, IRestoreProductMutationVariables>
                variables={{
                  slug: product.slug || "",
                }}
                optimisticResponse={{
                  __typename: "Mutation",
                  restoreProduct: {
                    ...product,
                    __typename: "Product",
                    archivedAt: null,
                  },
                }}
                mutation={RestoreProduct}
              >
                {(restore) => (
                  <a href="#" onClick={() => restore()}>
                    Restore
                  </a>
                )}
              </Mutation>
            </li>
          </>
        )}
      </TableRowActions>
    );
  }

  return (
    <TableRowActions title="Product Actions">
      {({ onClick }: any) => (
        <>
          {selfie && selfie.isSiteAdmin && (
            <>
              <ToggleDisruptor entityId={product._id} entityType="product">
                {(toggleDisruptor: any) => (
                  <li>
                    <ButtonAnt
                      type="link"
                      css={{
                        padding: 0,
                        border: 0,
                        height: "auto",
                        color: product.isDisruptor ? palette.red : undefined,
                      }}
                      onClick={onClick(() => toggleDisruptor(product))}
                      disabled={!product.canBeDisruptor}
                    >
                      {product.isDisruptor ? "Remove Disruptor" : "Set Disruptor"}
                    </ButtonAnt>

                    {!product.canBeDisruptor && (
                      <p
                        css={{
                          fontSize: 12,
                        }}
                      >
                        Only basic vendors can become a Disruptor
                      </p>
                    )}
                  </li>
                )}
              </ToggleDisruptor>

              <ToggleFeatured>
                {(toggle: (arg: TProduct) => any) => (
                  <li>
                    <a
                      href="#"
                      onClick={onClick(() => toggle(product))}
                      css={{
                        color: product.featuredAt ? palette.red : undefined,
                      }}
                    >
                      {product.featuredAt ? "Remove featured" : "Set Featured"}
                    </a>
                  </li>
                )}
              </ToggleFeatured>
            </>
          )}

          {product.company && (
            <li>
              <Link to={`/products?search=${product.company._id}`}>Search Siblings</Link>
            </li>
          )}

          <li>
            <Link to={editUrl || `/products/${product.slug}`}>Edit</Link>
          </li>

          <li>
            <Mutation<IArchiveProductMutation, IArchiveProductMutationVariables>
              mutation={ArchiveProduct}
              refetchQueries={[
                "Products",
                "ProductsCount",
                vendorSlug
                  ? {
                      query: VendorQuery,
                      variables: {
                        slug: vendorSlug,
                      },
                    }
                  : null,
              ].filter(notUndefinedOrNull)}
              awaitRefetchQueries={true}
              variables={{
                productId: product._id,
              }}
              update={(proxy) => {
                // try {
                //   const data = proxy.readQuery<IAllProductsQuery>({
                //     query: GetProductsQuery,
                //     variables,
                //   });

                //   if (!data || !data.products || !data.products.items) {
                //     return null;
                //   }

                //   proxy.writeQuery({
                //     query: GetProductsQuery,
                //     data: {
                //       products: {
                //         __typename: "Products",
                //         items: data.products.items.map(item =>
                //           item._id === product._id
                //             ? {
                //                 ...item,
                //                 archivedAt: new Date(),
                //               }
                //             : item,
                //         ),
                //       },
                //     },
                //   });
                //   // eslint-disable-next-line
                // } catch (e) {}

                if (vendorSlug) {
                  const companyData = proxy.readQuery<IVendorQuery>({
                    query: VendorQuery,
                    variables: {
                      slug: vendorSlug,
                    },
                  });

                  if (!companyData || !companyData.company || !companyData.company.products) {
                    return null;
                  }

                  proxy.writeQuery({
                    query: VendorQuery,
                    data: {
                      ...companyData,
                      company: {
                        ...companyData.company,
                        products: companyData.company.products.map((item) =>
                          item._id === product._id
                            ? {
                                ...item,
                                archivedAt: new Date(),
                              }
                            : item,
                        ),
                      },
                    },
                  });
                }
              }}
            >
              {(archiveProduct, { loading }) => (
                <Popconfirm title="Are you sure?" onConfirm={onClick(archiveProduct)}>
                  <span
                    css={{
                      cursor: "pointer",
                      color: palette.red,
                    }}
                    role="button"
                  >
                    {loading ? "Archiving..." : "Archive"}
                  </span>
                </Popconfirm>
              )}
            </Mutation>
          </li>
        </>
      )}
    </TableRowActions>
  );
}
