import React from "react";
import { IProcessStatusType, IWorkPlaceType } from "../../../../types/types";
import { IUserFieldsFragment } from "../../../queries/fragments/__generated__/UserFields.generated";
import { IOnboardingDetails } from "./Interfaces";
import * as palette from "@govlaunch/palette";
import ForceOnboardingMutation from "./mutations/ForceOnboardingMutation";
import ResetOnboardingMutation from "./mutations/ResetOnboardingMutation";
import ChangeOnboardingStatusMutation from "./mutations/ChangeOnboardingStatusMutation";
import FinalizeOnboardingMutation from "./mutations/FinalizeOnboardingMutation";

interface IViewOnboardingControlsProps {
  user: IUserFieldsFragment;
  onboarding: IOnboardingDetails | null;
  refetch: () => any;
  updateState?: (newWorkingFor: IWorkPlaceType | undefined | null) => void;
}

export default function OnboardingControls({ user, onboarding, refetch, updateState }: IViewOnboardingControlsProps) {
  return (
    <div
      css={{
        display: "flex",
        margin: 15,
        maxWidth: 875,
        justifyContent: "space-between",
      }}
    >
      {!onboarding && <ForceOnboardingMutation userId={user._id} refetch={refetch} />}

      {onboarding && (
        <div
          css={{
            display: "flex",
          }}
        >
          <FinalizeOnboardingMutation user={user} onboarding={onboarding} refetch={refetch} />
        </div>
      )}
      <div
        css={{
          display: "flex",
        }}
      >
        {onboarding && onboarding.processStatus !== IProcessStatusType.Verified && (
          <ResetOnboardingMutation userId={user._id} refetch={refetch} updateState={updateState} />
        )}
        {onboarding &&
          (onboarding.processStatus === IProcessStatusType.Required ||
            onboarding.processStatus === IProcessStatusType.PendingVerification) && (
            <ChangeOnboardingStatusMutation
              userId={user._id}
              buttonLabel="Mark as Unverifiable"
              buttonBorder="2px solid"
              buttonColor={palette.lightestGray}
              buttonTextColor={palette.red}
              newStatus={IProcessStatusType.AdditionalInformationRequired}
              confirmationMessage="Do you want to mark the user as Unverifiable?"
              refetch={refetch}
            />
          )}
      </div>
    </div>
  );
}
