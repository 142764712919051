import gql from "graphql-tag";

export default gql`
  mutation ToggleVerifiedProject($projectId: ObjectId!) {
    toggleVerified(projectId: $projectId) {
      _id
      verified
      verifiedBy {
        ... on PendingUser {
          _id
          slug
          fullName
        }
        ... on VendorUser {
          _id
          slug
          fullName
        }
        ... on GovernmentUser {
          _id
          slug
          fullName
        }
      }
    }
  }
`;
