import gql from "graphql-tag";

export default gql`
  mutation ToggleFeaturedReport($id: ObjectId!) {
    toggleFeatured(targetId: $id, targetType: REPORT) {
      ... on Report {
        _id
        featured
      }
    }
  }
`;
