import { notification } from "antd";
import { FORM_ERROR } from "final-form";
import React from "react";
import { useMutation } from "react-apollo";
import { Form } from "react-final-form";
import { useNavigate } from "react-router";
import EditPlanMutation from "../../mutations/EditPlanMutation";
import {
  IEditPlanMutation,
  IEditPlanMutationVariables,
} from "../../mutations/__generated__/EditPlanMutation.generated";
import { IGetPlanByIdQuery } from "../../queries/__generated__/GetPlanById.generated";
import validate from "../../validate";
import PlanForm from "./PlanForm";
import planValidationSchema from "./planValidationSchema";
import * as palette from "@govlaunch/palette";

interface IEditPlanFormProps {
  plan: NonNullable<IGetPlanByIdQuery["getPlanById"]>;
}

export default function EditPlanForm({ plan }: IEditPlanFormProps) {
  const navigate = useNavigate();
  const [editPlan] = useMutation<IEditPlanMutation, IEditPlanMutationVariables>(EditPlanMutation, {
    refetchQueries: ["GetAllPlans"],
  });

  return (
    <div
      css={{
        maxWidth: 600,
        border: `1px solid ${palette.lightestGray}`,
        backgroundColor: palette.white,
        padding: 16,
      }}
    >
      <Form
        keepDirtyOnReinitialize={true}
        initialValues={{
          name: plan.name,
          displayName: plan.displayName,
          tier: plan.tier,
          stripePlanId: plan.stripePlanId,
          products: plan.limits.products,
          enrichedProducts: plan.limits.enrichedProducts,
          productTags: plan.limits.productTags,
          projectsPerMonth: plan.limits.projectsPerMonth,
          groups: plan.limits.groups,
          groupInvitations: plan.limits.groupInvitations,
          teamMembers: plan.limits.teamMembers,
          enrichedProfile: plan.limits.enrichedProfile,
          sharingPage: plan.limits.sharingPage,
          searchAccess: plan.limits.searchAccess,
          inviteGovernments: plan.limits.inviteGovernments,
        }}
        validate={validate(planValidationSchema)}
        onSubmit={async (values) => {
          try {
            const planLimits = {
              products: values.products,
              enrichedProducts: values.enrichedProducts,
              groups: values.groups,
              groupInvitations: values.groupInvitations,
              teamMembers: values.teamMembers,
              productTags: values.productTags,
              projectsPerMonth: values.projectsPerMonth,
              enrichedProfile: values.enrichedProfile,
              sharingPage: values.sharingPage,
              searchAccess: values.searchAccess,
              inviteGovernments: values.inviteGovernments,
            };

            const result = await editPlan({
              variables: {
                planId: plan._id,
                plan: {
                  name: values.name,
                  displayName: values.displayName,
                  tier: values.tier,
                  stripePlanId: values.stripePlanId,
                  limits: planLimits,
                },
              },
            });

            if (result && result.data) {
              notification.success({
                message: "Success!",
                description: "Plan saved successfully",
              });

              navigate("/billing/plans");
            }
          } catch (e) {
            if (/No such price/i.test(e.message)) {
              return {
                [FORM_ERROR]: "Stripe Plan ID doesn't exist in Stripe",
              };
            }

            return {
              [FORM_ERROR]: "Could not save plan",
            };
          }
        }}
      >
        {({ submitting, handleSubmit, submitError }) => (
          <PlanForm handleSubmit={handleSubmit} submitting={submitting} submitError={submitError} isEditting={true} />
        )}
      </Form>
    </div>
  );
}
