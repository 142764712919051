import React from "react";
import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import * as palette from "@govlaunch/palette";

export default function AboutField() {
  return (
    <Field name="about">
      {({ input }) => (
        <div>
          <TextareaAutosize
            {...input}
            placeholder="Tell a bit more about the product"
            css={{
              padding: 10,
              outline: 0,
              minHeight: 80,
              fontSize: 14,
              borderRadius: 4,
              resize: "none",
              width: "100%",
              border: `solid 1px ${palette.lightestGray}`,
              "&:focus": {
                border: `solid 1px ${palette.primary}`,
              },
              "&::placeholder": {
                color: palette.sealBlue,
                userSelect: "none",
              },
            }}
          />
        </div>
      )}
    </Field>
  );
}
