import { CSSReset, theme, ThemeProvider } from "@govlaunch/web";
import { notification } from "antd";
import React from "react";
import { useMutation } from "react-apollo";
import { Form } from "react-final-form";
import { useNavigate } from "react-router";
import EditExternalLink from "../../../mutations/EditExternalLink";
import {
  IEditExternalLinkMutation,
  IEditExternalLinkMutationVariables,
} from "../../../mutations/__generated__/EditExternalLink.generated";
import { IGetExternalLinkByIdQuery } from "../../../queries/__generated__/GetExternalLinkById.generated";
import validate from "../../../validate";
import ExternalLinkForm from "./ExternalLinkForm";
import externalLinkValidationSchema from "./externalLinkValidationSchema";

type TExternalLink = NonNullable<IGetExternalLinkByIdQuery["getExternalLinkById"]>;

interface IEditExternalLinkFormProps {
  externalLink: TExternalLink;
}

export default function EditExternalLinkForm({ externalLink }: IEditExternalLinkFormProps) {
  const navigate = useNavigate();
  const [editExternalLink] = useMutation<IEditExternalLinkMutation, IEditExternalLinkMutationVariables>(
    EditExternalLink,
  );

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Form
        keepDirtyOnReinitialize={true}
        initialValues={{
          title: externalLink.title,
          url: externalLink.url,
          source: externalLink.source,
        }}
        validate={validate(externalLinkValidationSchema)}
        onSubmit={async (values) => {
          const result = await editExternalLink({
            variables: {
              id: externalLink._id,
              data: {
                title: values.title,
                url: values.url,
                source: values.source,
              },
            },
          });

          if (result && result.data && result.data.editExternalLink) {
            notification.success({
              message: "Success!",
              description: "Exernal link saved successfully",
            });

            navigate("/externalLinks");
          }
        }}
        subscription={{
          submitting: true,
          submitError: true,
        }}
      >
        {({ handleSubmit, submitting, submitError }) => (
          <ExternalLinkForm onSubmit={handleSubmit} submitting={submitting} submitError={submitError} />
        )}
      </Form>
    </ThemeProvider>
  );
}
