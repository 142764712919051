import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import { parseState } from "../../utils/tablesUtils";
import TablePagination, { DEFAULT_CURSOR } from "../../components/table/TablePagination";
import GetAllImages from "../../queries/GetAllImages";
import { useQuery } from "react-apollo";
import { IGetAllImagesQuery, IGetAllImagesQueryVariables } from "../../queries/__generated__/GetAllImages.generated";
import { calculateAndEncodeCursor } from "../../utils/encodeCursor";
import { IImagesQueryTab } from "../../../types/types";
import TableAddStateToQueryString from "../../components/table/TableAddStateToQueryString";
import TableOnSearch from "../../components/table/TableOnSearch";
import ImagesTable, { IImagesTableState } from "./ImagesTable";
import TableTitle from "../../components/table/TableTitle";

interface IManageImagesProps {
  search: string | null;
  tab: IImagesQueryTab;
  historyOrigin?: string;
}

export default function ManageImages({ search, tab, historyOrigin = "/images" }: IManageImagesProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<IImagesTableState>(initialFiltersState);

  const { data, loading } = useQuery<IGetAllImagesQuery, IGetAllImagesQueryVariables>(GetAllImages, {
    variables: {
      filter: {
        search,
        tab,
      },
      sortBy: null,
      limit: 10,
      cursor: calculateAndEncodeCursor(state.cursor),
    },
    fetchPolicy: "network-only",
  });

  const images = (data && data.images && data.images.items) || [];
  const totalCount = data && data.images && data.images.pageInfo ? data.images.pageInfo.totalCount || 0 : 0;

  return (
    <>
      <TableAddStateToQueryString tableState={state} historyOrigin={historyOrigin} query={query} />
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
 page: 1, size: state.cursor.size 
},
            });
          }
        }}
      />
      <ImagesTable
        images={images}
        loading={loading}
        state={state}
        title={() => (
          <TableTitle
            title="Images"
            count={totalCount}
            loading={loading}
            onResetFilters={() => {
              setState({
                cursor: {
 page: 1, size: state.cursor.size 
},
              });

              navigate(`${historyOrigin}?tab=${query.tab || IImagesQueryTab.All}&search=${search || ""}`);
            }}
          />
        )}
        footer={() => (
          <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
        )}
      />
    </>
  );
}
