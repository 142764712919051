import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

const STORAGE_TOKEN_KEY = "adminToken";

function hasToken() {
  const hasTokenInStorage = Cookies.get(STORAGE_TOKEN_KEY);

  if (hasTokenInStorage) {
    try {
      jwtDecode(getToken() || "");
      return true;
    } catch (err) {
      return false;
    }
  }

  return false;
}

function getToken() {
  return Cookies.get(STORAGE_TOKEN_KEY) || null;
}

function setToken(tokenString: string) {
  return Cookies.set(STORAGE_TOKEN_KEY, tokenString, {
    domain: process.env.AUTH_COOKIE_DOMAIN,
  });
}

function dropToken() {
  Cookies.remove(STORAGE_TOKEN_KEY, {
    domain: process.env.AUTH_COOKIE_DOMAIN,
  });
}

export {
 hasToken, setToken, getToken, dropToken
};
