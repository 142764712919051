import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { IFormFieldProps } from "./FormFieldProps";
import { Checkbox } from "antd";

interface ICheckboxFieldProps extends IFormFieldProps {
  colorChecked?: string;
  labelChecked?: string;
}

export default function CheckboxField({ fieldName, label, colorChecked, labelChecked, ...props }: ICheckboxFieldProps) {
  if (!labelChecked) {
    labelChecked = label;
  }

  if (!colorChecked) {
    colorChecked = palette.mediumGray;
  }

  return (
    <Field name={fieldName}>
      {({ input }) => (
        <Checkbox
          onChange={input.onChange}
          checked={input.value}
          css={{
            color: input.value ? colorChecked : palette.text,
            fontWeight: input.value ? 700 : 500,
          }}
          {...props}
        >
          {input.value ? labelChecked : label}
        </Checkbox>
      )}
    </Field>
  );
}
