import React, { useState, useRef } from "react";
import { Input, LoadingIndicator } from "@govlaunch/core";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import VimeoThumbnail from "../VimeoThumbnail";
import getYoutubeThumbnail from "../../../utils/getYoutubeThumbnail";
import prefixWithHttpIfNecessary from "../../../utils/prefixWithHttpIfNecessary";
import PlusIcon from "../../../components/icons/PlusIcon";

export default function VideosField() {
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  return (
    <Field name="videos">
      {({ input }) => {
        const addItem = () => {
          setLoading(true);
          const url: string = (inputRef.current as HTMLInputElement).value.trim();
          const prefixedUrl = prefixWithHttpIfNecessary(url);

          if (
            prefixedUrl &&
            isValidUrl(prefixedUrl) &&
            (prefixedUrl.includes("youtube.com") || prefixedUrl.includes("vimeo.com")) &&
            !input.value.includes(prefixedUrl)
          ) {
            input.onChange(input.value.concat(prefixedUrl));

            (inputRef.current as HTMLInputElement).value = "";
          }

          setLoading(false);
        };

        return (
          <div>
            <div
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                css={{
                  position: "relative",
                  width: "95%",
                }}
              >
                <Input
                  innerRef={inputRef}
                  disabled={input.value.length >= 2}
                  placeholder="Enter YouTube or Vimeo URL (maximum of 2)"
                  onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.key !== "Enter") {
                      return;
                    }

                    event.preventDefault();
                    event.stopPropagation();
                    addItem();
                  }}
                  css={{
                    backgroundColor: palette.white,
                    border: `solid 1px ${palette.lightestGray}`,
                    fontSize: 14,
                    height: 48,
                    paddingLeft: 20,
                    paddingRight: 20,
                    letterSpacing: 0.5,
                    color: palette.mediumGray,
                    lineHeight: 1.14,
                    outline: "none",
                    borderRadius: 4,
                    "&:focus": {
                      border: `solid 1px ${palette.primary}`,
                    },
                  }}
                />

                {loading && (
                  <div
                    css={{
                      position: "absolute",
                      top: "50%",
                      right: 20,
                      transform: "translateY(-50%)",
                    }}
                  >
                    <LoadingIndicator />
                  </div>
                )}
              </div>
              <button
                css={{
                  width: "5%",
                  height: 48,
                  textAlign: "center",
                  color: palette.sealBlue,
                  fontSize: 13,
                  cursor: "pointer",
                  marginLeft: 10,
                  border: `solid 1px ${palette.lightestGray}`,
                  borderRadius: 4,
                  backgroundColor: palette.white,
                  "&:hover": {
                    backgroundColor: palette.lightestGray,
                  },
                  "&:focus": {
                    border: `solid 1px ${palette.primary}`,
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                type="button"
                aria-label="Add Newsworthy item"
                onClick={(event) => {
                  event.preventDefault();
                  addItem();
                }}
              >
                <PlusIcon />
              </button>
            </div>

            <div
              css={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, 93px)",
                gridColumnGap: 12,
                marginTop: input.value.length > 0 ? 16 : 0,
              }}
            >
              {input.value.map((url: string) => {
                if (url.includes("youtube.com")) {
                  return <YoutubeThumbnail key={url} input={input} url={url} />;
                }

                if (url.includes("vimeo.com")) {
                  return <VimeoThumbnail input={input} url={url} key={url} />;
                }

                return null;
              })}
            </div>
          </div>
        );
      }}
    </Field>
  );
}

interface IYoutubeThumbnailProps {
  input: any;
  url: string;
}

function YoutubeThumbnail({ input, url }: IYoutubeThumbnailProps) {
  return (
    <div
      css={{
        width: 93,
        height: 93,
        display: "inline-block",
        borderRadius: 5,
        position: "relative",
      }}
    >
      <div
        css={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          opacity: 0.6,
          display: "flex",
        }}
      >
        <svg width={24} height={18} viewBox="0 0 24 18">
          <g fill="#FFF" fillRule="nonzero">
            <path d="M12 17.851c-1.172 0-2.345-.05-3.509-.152l-5.294-.46A3.48 3.48 0 0 1 0 13.752V4.253A3.48 3.48 0 0 1 3.197.766l5.294-.46a40.513 40.513 0 0 1 7.018 0l5.294.46A3.482 3.482 0 0 1 24 4.253v9.499a3.48 3.48 0 0 1-3.197 3.487l-5.294.46a40.235 40.235 0 0 1-3.509.152zm0-16.697c-1.144 0-2.287.049-3.422.148l-5.294.46A2.488 2.488 0 0 0 1 4.253v9.499c0 1.307.982 2.377 2.284 2.491l5.294.46c2.271.197 4.573.197 6.844 0l5.294-.46A2.486 2.486 0 0 0 23 13.752V4.253a2.487 2.487 0 0 0-2.284-2.491l-5.294-.46A39.802 39.802 0 0 0 12 1.154z" />
            <path d="M9.5 14.003a.501.501 0 0 1-.5-.5v-9a.501.501 0 0 1 .77-.42l7 4.5a.499.499 0 0 1 0 .84l-7 4.5a.498.498 0 0 1-.27.08zm.5-8.584v7.168l5.575-3.584L10 5.419z" />
          </g>
        </svg>
      </div>

      <button
        type="button"
        css={{
          cursor: "pointer",
          top: -5,
          right: -5,
          type: "button",
          position: "absolute",
          width: 25,
          height: 25,
          lineHeight: "20px",
          background: palette.red,
          color: "#fff",
          border: 0,
          borderRadius: "100%",
          outline: 0,
        }}
        onClick={() => input.onChange(input.value.filter((maybe: string | null) => maybe !== url))}
      >
        &times;
      </button>

      <img
        src={getYoutubeThumbnail(url)}
        css={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: 5,
          border: `1px solid ${palette.lightestGray}`,
        }}
      />
    </div>
  );
}

function isValidUrl(maybe: string) {
  try {
    // URL throws if the given url is malformed
    new URL(maybe);
    return true;
  } catch (err) {
    return false;
  }
}
