import React from "react";
import PostsTable from "../../posts/PostsTable";
import { IGetGovernmentQuery } from "../../../queries/__generated__/GetGovernmentQuery.generated";
import { IPost } from "../../../../types/types";

interface IPostsTabProps {
  government: IGetGovernmentQuery["government"];
  refetch: () => any;
}

const PostsTab = ({ government, refetch }: IPostsTabProps) => {
  if (!government) {
    return null;
  }

  return (
    <div>
      <PostsTable posts={government.posts as IPost[]} onRemovePost={refetch} />
    </div>
  );
};

export default PostsTab;
