import queryString from "query-string";
import React from "react";
import { IProjectType } from "../../../../types/types";
import TableSearch from "../../../components/table/TableSearch";
import { useLocation } from "react-router";
import ManageProjects from "./ManageProjects";

interface IProjectsWithSearchProps {
  archivedOnly?: boolean;
  filterByType?: IProjectType | null;
  filterByTag?: any | null;
  historyOrigin?: string;
  userCreated?: boolean;
}

export default function ProjectsWithSearch({
  archivedOnly = false,
  filterByType = null,
  filterByTag = null,
  historyOrigin = "/projects",
  userCreated = false,
}: IProjectsWithSearchProps) {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <>
      <div css={{
 marginBottom: 20 
}}
      >
        <TableSearch historyOrigin={historyOrigin} query={query} inputPlaceholder="Search for projects..." />
      </div>

      <ManageProjects
        search={query.search as string}
        archivedOnly={archivedOnly}
        filterByTag={filterByTag}
        filterByType={filterByType}
        userCreated={userCreated}
        historyOrigin={historyOrigin}
      />
    </>
  );
}
