import * as palette from "@govlaunch/palette";
import { Popconfirm } from "antd";
import React from "react";
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";
import { useToggle } from "react-use";
import TableRowActions from "../../components/table/TableRowActions";
import RemoveGroup from "../../mutations/RemoveGroup";
import RestoreGroupById from "../../mutations/RestoreGroupById";
import {
  IRemoveGroupMutation,
  IRemoveGroupMutationVariables,
} from "../../mutations/__generated__/RemoveGroup.generated";
import {
  IRestoreGroupByIdMutation,
  IRestoreGroupByIdMutationVariables,
} from "../../mutations/__generated__/RestoreGroupById.generated";
import { IGroupsQuery } from "../../queries/__generated__/GroupsQuery.generated";
import EditGroupGovernmentsModal from "../teamManagement/modals/EditGroupGovernmentsModal";
import EditGroupMembersModal from "../teamManagement/modals/EditGroupMembersModal";
import EditGroupVendorsModal from "../teamManagement/modals/EditGroupVendorsModal";

type TGroup = NonNullable<NonNullable<IGroupsQuery["groups"]>["items"]>[0];

interface IGroupActionsProps {
  group: TGroup;
  groups: TGroup[];
}

export default function GroupActions({ group }: IGroupActionsProps) {
  const [showTeamBuilder, toggleTeamBuilder] = useToggle(false);
  const [showGovernmentsBuilder, toggleGovernmentsBuilder] = useToggle(false);
  const [showVendorsBuilder, toggleVendorsBuilder] = useToggle(false);

  return (
    <>
      <EditGroupMembersModal
        visible={showTeamBuilder}
        group={group}
        onRequestClose={toggleTeamBuilder}
        initialMemberAdditions={[]}
      />

      <EditGroupVendorsModal visible={showVendorsBuilder} group={group} onRequestClose={toggleVendorsBuilder} />

      <EditGroupGovernmentsModal
        visible={showGovernmentsBuilder}
        group={group}
        onRequestClose={toggleGovernmentsBuilder}
      />

      <TableRowActions title="Group Actions">
        {({ onClick }: any) => (
          <>
            <li>
              <Link to={`/groups/${group.slug}`}>Edit</Link>
            </li>

            <li>
              <a href="#" onClick={onClick(toggleTeamBuilder)}>
                Assign Members
              </a>
            </li>

            <li>
              <a href="#" onClick={onClick(toggleGovernmentsBuilder)}>
                Assign Governments
              </a>
            </li>

            <li>
              <a href="#" onClick={onClick(toggleVendorsBuilder)}>
                Assign Vendors
              </a>
            </li>

            {group.deletedAt ? (
              <li>
                <Mutation<IRestoreGroupByIdMutation, IRestoreGroupByIdMutationVariables>
                  mutation={RestoreGroupById}
                  refetchQueries={["Groups"]}
                  variables={{
                    groupId: group._id,
                  }}
                >
                  {(restoreGroup) => (
                    <Popconfirm title="Are you sure?" onConfirm={() => restoreGroup()}>
                      <a
                        href="#"
                        css={{
                          color: palette.blue,
                        }}
                      >
                        Restore
                      </a>
                    </Popconfirm>
                  )}
                </Mutation>
              </li>
            ) : (
              <li>
                <Mutation<IRemoveGroupMutation, IRemoveGroupMutationVariables>
                  mutation={RemoveGroup}
                  refetchQueries={["Groups"]}
                  variables={{
                    id: group._id,
                  }}
                >
                  {(removeGroup) => (
                    <Popconfirm
                      title="Are you sure? You will also cancel all group invitations!"
                      onConfirm={() => removeGroup()}
                    >
                      <a
                        href="#"
                        css={{
                          color: palette.red,
                        }}
                      >
                        Archive
                      </a>
                    </Popconfirm>
                  )}
                </Mutation>
              </li>
            )}
          </>
        )}
      </TableRowActions>
    </>
  );
}
