import { Mobile } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { CloseButton, ImageUploadModal } from "@govlaunch/web";
import React, { useState } from "react";
import { Field } from "react-final-form";
import { IMediaType } from "../../../../../types/types";
import getYoutubeThumbnail from "../../../../utils/getYoutubeThumbnail";

export default function MediasField() {
  const [state, setState] = useState(0);

  return (
    <Field name="medias">
      {({ input }) => (
        <div
          css={{
            borderRadius: 5,
            border: `1px solid ${palette.lightestGray}`,
            background: "#fff",
          }}
        >
          <Mobile>
            {(isMobile: boolean) => (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  padding: 15,
                  flexDirection: isMobile ? "column" : "row",
                }}
              >
                <div css={{
 width: "110px" 
}}
                >
                  <ImageUploadModal
                    uploadButtonText="Add Images"
                    onSave={(imageData) =>
                      input.onChange(
                        (input.value || []).concat({
                          type: IMediaType.Image,
                          url: imageData.url,
                          alt: imageData.alt,
                        }),
                      )
                    }
                  />
                </div>

                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginTop: isMobile ? 15 : 0,
                    marginBottom: isMobile ? 15 : 0,
                  }}
                >
                  <div
                    css={{
                      width: 25,
                      background: palette.mediumGray,
                      height: 1,
                    }}
                  />
                  <span
                    css={{
                      fontSize: 12,
                      color: palette.mediumGray,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    OR
                  </span>
                  <div
                    css={{
                      width: 25,
                      background: palette.mediumGray,
                      height: 1,
                    }}
                  />
                </div>
                <input
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();

                      if (
                        /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/.test(
                          (event.target as HTMLInputElement).value,
                        )
                      ) {
                        input.onChange(
                          input.value.concat({
                            type: "YOUTUBE",
                            url: (event.target as HTMLInputElement).value,
                          }),
                        );

                        (event.target as HTMLInputElement).value = "";
                      }
                    }
                  }}
                  css={{
                    height: 42,
                    "::placeholder": {
                      color: palette.sealBlue,
                    },
                    ":focus": {
                      borderColor: palette.primary,
                    },
                    borderRadius: 5,
                    border: `1px solid ${palette.lightestGray}`,
                    fontSize: 14,
                    padding: "5px 10px",
                    flex: 1,
                    outline: 0,
                    width: isMobile ? "100%" : "auto",
                  }}
                  placeholder="Paste youtube link"
                />
              </div>
            )}
          </Mobile>

          {input.value.length > 0 && (
            <div
              css={{
                padding: 15,
                paddingTop: 0,
                paddingBottom: 5,
              }}
            >
              {input.value.map((media: any, index: number) => {
                if (media.type === "IMAGE") {
                  return (
                    <div
                      //eslint-disable-next-line
                      key={index}
                      css={{
                        width: 93,
                        height: 93,
                        display: "inline-block",
                        marginBottom: 10,
                        marginRight: 10,
                        borderRadius: 5,
                        position: "relative",
                      }}
                    >
                      <CloseButton
                        size="sm"
                        position="absolute"
                        right={-5}
                        top={-5}
                        color="white"
                        bg="red.500"
                        borderRadius={25}
                        _hover={{
 bg: "red.600" 
}}
                        onClick={() => input.onChange(input.value.filter((maybeMedia: any) => maybeMedia !== media))}
                      />
                      <ImageUploadModal
                        imageData={{
 url: media.url || "", alt: media.alt || "" 
}}
                        onSave={(imageData) => {
                          const medias = input.value || [];
                          medias[index].url = imageData.url;
                          medias[index].alt = imageData.alt;
                          input.onChange(medias);
                          // stupid hack to make it redraw the image map, since changing an individual element doesn't trigger that
                          setState(state + 1);
                        }}
                      >
                        {({ setOpen }) => (
                          <img
                            css={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: 5,
                              border: `1px solid ${palette.lightestGray}`,
                              cursor: "pointer",
                            }}
                            src={media.url}
                            alt={media.alt}
                            onClick={() => setOpen(true)}
                          />
                        )}
                      </ImageUploadModal>
                    </div>
                  );
                }

                if (media.type === "YOUTUBE") {
                  return (
                    <div
                      //eslint-disable-next-line
                      key={index}
                      css={{
                        width: 93,
                        height: 93,
                        display: "inline-block",
                        marginBottom: 10,
                        marginRight: 10,
                        borderRadius: 5,
                        position: "relative",
                      }}
                    >
                      <div
                        css={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                          opacity: 0.6,
                          display: "flex",
                        }}
                      >
                        <Youtube />
                      </div>
                      <CloseButton
                        size="sm"
                        position="absolute"
                        right={-5}
                        top={-5}
                        color="white"
                        bg="red.500"
                        borderRadius={25}
                        _hover={{
 bg: "red.600" 
}}
                        onClick={() => input.onChange(input.value.filter((maybeMedia: any) => maybeMedia !== media))}
                      />
                      <img
                        css={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: 5,
                          border: `1px solid ${palette.lightestGray}`,
                        }}
                        src={getYoutubeThumbnail(media.url)}
                      />
                    </div>
                  );
                }

                return null;
              })}
            </div>
          )}
        </div>
      )}
    </Field>
  );
}

const Youtube = (props: React.SVGProps<any>) => (
  <svg width={24} height={18} viewBox="0 0 24 18" {...props}>
    <g fill="#FFF" fillRule="nonzero">
      <path d="M12 17.851c-1.172 0-2.345-.05-3.509-.152l-5.294-.46A3.48 3.48 0 0 1 0 13.752V4.253A3.48 3.48 0 0 1 3.197.766l5.294-.46a40.513 40.513 0 0 1 7.018 0l5.294.46A3.482 3.482 0 0 1 24 4.253v9.499a3.48 3.48 0 0 1-3.197 3.487l-5.294.46a40.235 40.235 0 0 1-3.509.152zm0-16.697c-1.144 0-2.287.049-3.422.148l-5.294.46A2.488 2.488 0 0 0 1 4.253v9.499c0 1.307.982 2.377 2.284 2.491l5.294.46c2.271.197 4.573.197 6.844 0l5.294-.46A2.486 2.486 0 0 0 23 13.752V4.253a2.487 2.487 0 0 0-2.284-2.491l-5.294-.46A39.802 39.802 0 0 0 12 1.154z" />
      <path d="M9.5 14.003a.501.501 0 0 1-.5-.5v-9a.501.501 0 0 1 .77-.42l7 4.5a.499.499 0 0 1 0 .84l-7 4.5a.498.498 0 0 1-.27.08zm.5-8.584v7.168l5.575-3.584L10 5.419z" />
    </g>
  </svg>
);
