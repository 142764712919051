import { Checkbox } from "antd";
import React from "react";
import { IStatCounterData, TBlockLayoutData } from "../blockProps";
import * as palette from "@govlaunch/palette";

interface IWidgetCheckboxSettingsProps {
  data: TBlockLayoutData;
  setData: React.Dispatch<React.SetStateAction<TBlockLayoutData>>;
}

export default function WidgetCheckboxSettings({ data, setData }: IWidgetCheckboxSettingsProps) {
  const starCountersData = data as IStatCounterData;

  return (
    <>
      <label
        css={{
          marginTop: "16px",
          fontWeight: 500,
          fontSize: 14,
          color: palette.mediumGray,
        }}
      >
        Display Counters
      </label>
      <div
        css={{
          marginLeft: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Checkbox
          onChange={(event) =>
            setData({
              ...data,
              showProjects: event.target.checked,
            })
          }
          checked={starCountersData.showProjects}
          css={{
            marginLeft: "8px",
            marginTop: "16px",
            fontWeight: 500,
            fontSize: 14,
            color: palette.mediumGray,
          }}
        >
          Projects
        </Checkbox>

        <Checkbox
          onChange={(event) =>
            setData({
              ...data,
              showProducts: event.target.checked,
            })
          }
          checked={starCountersData.showProducts}
          css={{
            marginTop: "16px",
            fontWeight: 500,
            fontSize: 14,
            color: palette.mediumGray,
          }}
        >
          Products
        </Checkbox>

        <Checkbox
          onChange={(event) =>
            setData({
              ...data,
              showGovernments: event.target.checked,
            })
          }
          checked={starCountersData.showGovernments}
          css={{
            marginTop: "16px",
            fontWeight: 500,
            fontSize: 14,
            color: palette.mediumGray,
          }}
        >
          Governments
        </Checkbox>

        <Checkbox
          onChange={(event) =>
            setData({
              ...data,
              showStories: event.target.checked,
            })
          }
          checked={starCountersData.showStories}
          css={{
            marginTop: "16px",
            fontWeight: 500,
            fontSize: 14,
            color: palette.mediumGray,
          }}
        >
          Stories
        </Checkbox>

        <Checkbox
          onChange={(event) =>
            setData({
              ...data,
              showVendors: event.target.checked,
            })
          }
          checked={starCountersData.showVendors}
          css={{
            marginTop: "16px",
            fontWeight: 500,
            fontSize: 14,
            color: palette.mediumGray,
          }}
        >
          Vendors
        </Checkbox>
      </div>
    </>
  );
}
