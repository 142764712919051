import gql from "graphql-tag";

export default gql`
  query AllGovernmentVerificationRequests(
    $searchQuery: String
    $searchTarget: GovernmentVerificationRequestSearchTarget
    $filterByGovernment: ObjectId
    $filterByStatus: GovernmentVerificationRequestStatus
    $cursor: Cursor
    $sortBy: GovernmentVerificationRequestSortBy = CREATED
    $sortDirection: GovernmentVerificationRequestSortByDirection = DESCENDING
    $limit: Int = 25
  ) {
    allGovernmentVerificationRequests(
      searchQuery: $searchQuery
      searchTarget: $searchTarget
      filterByGovernment: $filterByGovernment
      filterByStatus: $filterByStatus
      cursor: $cursor
      sortBy: $sortBy
      sortDirection: $sortDirection
      limit: $limit
    ) {
      metadata {
        totalCount
        pendingCount
        verifiedCount
        declinedCount
        cancelledCount
      }
      items {
        ... on ProjectGovernmentVerificationRequest {
          _id
          status
          createdAt
          respondedAt
          respondedBy {
            ... on GovernmentUser {
              _id
              slug
              fullName
              avatar
            }
            ... on VendorUser {
              _id
              slug
              fullName
              avatar
            }
            ... on PendingUser {
              _id
              slug
              fullName
              avatar
            }
          }
          project {
            _id
            slug
            title
            audience
            author {
              ... on GovernmentUser {
                _id
                slug
                fullName
                avatar
              }
              ... on VendorUser {
                _id
                slug
                fullName
                avatar
              }
              ... on PendingUser {
                _id
                slug
                fullName
                avatar
              }
            }
            ... on GroupProject {
              group {
                _id
                slug
                name
                logo
              }
            }
            ... on VendorProject {
              vendor {
                _id
                slug
                name
                logo
              }
            }
          }
          government {
            _id
            slug
            name
            logo
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }
`;
