import gql from "graphql-tag";

export default gql`
  query GetTimezoneByCountryCode($countryCode: String!) {
    getTimezoneByCountryCode(countryCode: $countryCode) {
      _id
      countryCode
      countryName
      timezone
    }
  }
`;
