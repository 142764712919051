import gql from "graphql-tag";

export default gql`
  query AutoCompleteProjects($searchQuery: String!, $limit: Int = 10) {
    autoCompleteProjects(searchQuery: $searchQuery, limit: $limit) {
      _id
      slug
      title
      description
      createdAt
      updatedAt
      countryCode
    }
  }
`;
