import gql from "graphql-tag";

export default gql`
  mutation UpdateNewsletterSettings($input: NewsletterInput!) {
    updateNewsletterSettings(input: $input) {
      subject
      collections
      storiesPerCollection
      projectsPerCollection
      hourToSend
      maxProjects
    }
  }
`;
