import "pure-react-carousel/dist/react-carousel.es.css";
import React from "react";
import { FormSpy } from "react-final-form";
import RadioGroupField from "../../form/fields/RadioGroupField";
import CarouselSideContentFields from "./CarouselSideContentFields";
import TextSideContentFields from "./TextSideContentFields";
import * as palette from "@govlaunch/palette";
import { Margin } from "../../../spacings";

interface ISideContentFormFieldsProps {
  side: "leftBox" | "rightBox";
}

export default function SideContentFormFields({ side }: ISideContentFormFieldsProps) {
  return (
    <div css={{
 display: "flex", flexDirection: "column" 
}}
    >
      <label
        css={{
          fontSize: 20,
          color: palette.darkGray,
          letterSpacing: 0.5,
          fontWeight: 600,
        }}
      >
        {side === "leftBox" ? "Left" : "Right"}
      </label>

      <Margin mt={16}>
        <RadioGroupField
          fieldName={`${side}.type`}
          options={[
            {
 value: "text", label: "Text" 
},
            {
 value: "carousel", label: "Carousel" 
},
          ]}
          defaultValue="text"
        />
      </Margin>

      <FormSpy
        subscription={{
          values: true,
        }}
      >
        {({ values }) => {
          if (values[`${side}`].type === "carousel") {
            return <CarouselSideContentFields side={side} />;
          }

          return <TextSideContentFields side={side} />;
        }}
      </FormSpy>
    </div>
  );
}
