import { LoadingIndicator } from "@govlaunch/core";
import React from "react";
import { useQuery } from "react-apollo";
import DocumentTitle from "react-document-title";
import { useNavigate, useParams } from "react-router";
import GetPlanById from "../../queries/GetPlanById";
import { IGetPlanByIdQuery, IGetPlanByIdQueryVariables } from "../../queries/__generated__/GetPlanById.generated";
import EditPlanForm from "./EditPlanForm";
import * as palette from "@govlaunch/palette";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

export default function EditPlanPage() {
  const navigate = useNavigate();
  const params = useParams();

  const { data, loading } = useQuery<IGetPlanByIdQuery, IGetPlanByIdQueryVariables>(GetPlanById, {
    variables: {
      planId: params.id,
    },
  });

  return (
    <DocumentTitle title="Plan | Admin">
      <div
        css={{
          backgroundColor: palette.washGray,
          minHeight: "100vh",
          padding: 15,
          overflow: "auto",
        }}
      >
        {loading && (
          <div>
            <LoadingIndicator />
          </div>
        )}

        {(!data || !data.getPlanById) && <div>Plan not found</div>}

        <div
          css={{
            paddingBottom: 8,
          }}
        >
          <a
            css={{
              color: palette.primary,
              cursor: "pointer",
            }}
            onClick={() => navigate("/billing/plans")}
          >
            <span css={{
 marginRight: 5 
}}
            >
              <FontAwesomeIcon icon={faLessThan} size="sm" />
            </span>
            All Plans
          </a>
        </div>

        {data && data.getPlanById && <EditPlanForm plan={data.getPlanById} />}
      </div>
    </DocumentTitle>
  );
}
