import { CountryFlag } from "@govlaunch/web";
import { Tooltip } from "antd";
import React from "react";
import { getCountryNameByIsoCode } from "./Countries";

interface IFlagWithTooltipProps {
  country: string;
  width?: number;
  height?: number;
}

export default function FlagWithTooltip({ country, width, height }: IFlagWithTooltipProps) {
  return (
    <Tooltip title={getCountryNameByIsoCode(country)}>
      <CountryFlag countryCode={country as any} width={width} height={height} />
    </Tooltip>
  );
}
