import { Tabs } from "antd";
import queryString from "query-string";
import React from "react";
import { Query } from "react-apollo";
import PostsTable from "./PostsTable";
import DocumentTitle from "react-document-title";
import AllPostsQuery from "../../queries/AllPostsQuery";
import { IAllPostsQuery, IAllPostsQueryVariables } from "../../queries/__generated__/AllPostsQuery.generated";
import { IPost } from "../../../types/types";
import { useLocation, useNavigate } from "react-router";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { MetaBaseQuestion } from "../../components/MetaBaseLink";
import TablePageHeader from "../../components/table/TablePageHeader";

export default function Posts() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = Array.isArray(query.tab) ? query.tab[0] : query.tab || "all";

  return (
    <DocumentTitle title="Posts | Admin">
      <>
        <TablePageHeader
          title="Posts"
          titleIcon={faClipboard}
          subTitle="All Posts"
          metabaseQuestion={MetaBaseQuestion.Posts}
        />
        <Query<IAllPostsQuery, IAllPostsQueryVariables>
          fetchPolicy="network-only"
          query={AllPostsQuery}
          notifyOnNetworkStatusChange={true}
        >
          {({ data, loading }) => {
            const allCount = data && data.allPosts ? data.allPosts.filter((post) => post.deletedAt === null).length : 0;
            const archivedCount =
              data && data.allPosts ? data.allPosts.filter((post) => post.deletedAt !== null).length : 0;

            return (
              <Tabs
                animated={false}
                onChange={(tab) => {
                  navigate(`/posts?tab=${tab}`);
                }}
                activeKey={tab || "all"}
                size="middle"
                tabBarStyle={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  margin: 0,
                }}
              >
                <Tabs.TabPane tab={allCount ? `All (${allCount})` : "All"} key="all">
                  {tab === "all" && (
                    <div
                      css={{
                        padding: 20,
                      }}
                    >
                      <PostsTable
                        loading={loading}
                        posts={
                          data && data.allPosts
                            ? (data.allPosts.filter((post) => post.deletedAt === null) as IPost[])
                            : []
                        }
                      />
                    </div>
                  )}
                </Tabs.TabPane>

                <Tabs.TabPane tab={`Archived (${archivedCount})`} key="archived">
                  {tab === "archived" && (
                    <div
                      css={{
                        padding: 20,
                      }}
                    >
                      <PostsTable
                        loading={loading}
                        posts={data && data.allPosts ? (data.allPosts.filter((post) => post.deletedAt) as IPost[]) : []}
                      />
                    </div>
                  )}
                </Tabs.TabPane>
              </Tabs>
            );
          }}
        </Query>
      </>
    </DocumentTitle>
  );
}
