import React from "react";
import * as palette from "@govlaunch/palette";
import { Field } from "react-final-form";
import FieldValidationError from "./FieldValidationError";
import { Margin } from "../../spacings";
import SponsorshipsFieldSelect from "./SponsorshipsFieldSelect";

export default function SponsorshipsField() {
  return (
    <Field name="sponsorships">
      {({ input, meta }) => (
        <div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: palette.mediumGray,
                margin: 0,
              }}
            >
              Sponsorships
            </label>

            <FieldValidationError meta={meta} />
          </div>

          <Margin mt={10}>
            <SponsorshipsFieldSelect
              sponsorships={input.value || []}
              onChange={input.onChange}
              onFocus={input.onFocus}
              onBlur={input.onBlur}
            />
          </Margin>
        </div>
      )}
    </Field>
  );
}
