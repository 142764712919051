import React from "react";
import { IGetTagBySlugQuery } from "../../../../queries/__generated__/GetTagBySlug.generated";
import { ProductsWithSearch } from "../../../products/tables/ProductsWithSearch";

interface ITagProductsTab {
  tag: IGetTagBySlugQuery["tag"];
}

export default function TagProductsTab({ tag }: ITagProductsTab) {
  if (!tag) {
    return null;
  }

  return (
    <div
      css={{
        padding: 20,
      }}
    >
      <ProductsWithSearch filterByTag={tag._id} historyOrigin={`/tags/${tag.slug}/products`} />
    </div>
  );
}
