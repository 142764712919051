import React, { useEffect, useState } from "react";
import { ToolbarItems } from "./ToolbarFields";
import ToolbarItem from "./ToolbarItem";
import { getActiveToolbarItem } from "./utils";
import {
  GovernmentsIcon,
  ProductsIcon,
  VendorsIcon,
  GroupsIcon,
  LinksIcon,
  AttachmentsIcon,
  NewsworthyIcon,
  StatusIcon,
  OutcomeIcon,
} from "@govlaunch/project";

interface IToolbarControlsProps {
  onChange: (value: ToolbarItems | null) => void;
}

export default function ToolbarControls({ onChange }: IToolbarControlsProps) {
  const [activeItem, setActiveItem] = useState<ToolbarItems | null>(null);

  useEffect(() => {
    onChange(activeItem);
  }, [activeItem]);

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "repeat(10, 1fr)",
        columnGap: 10,
      }}
    >
      <ToolbarItem
        tooltip="Governments Involved"
        active={activeItem === ToolbarItems.Locations}
        onClick={() => setActiveItem(getActiveToolbarItem(activeItem, ToolbarItems.Locations))}
      >
        <GovernmentsIcon />
      </ToolbarItem>

      <ToolbarItem
        tooltip="Products"
        active={activeItem === ToolbarItems.Products}
        onClick={() => setActiveItem(getActiveToolbarItem(activeItem, ToolbarItems.Products))}
      >
        <ProductsIcon />
      </ToolbarItem>

      <ToolbarItem
        tooltip="Vendors"
        active={activeItem === ToolbarItems.Vendors}
        onClick={() => setActiveItem(getActiveToolbarItem(activeItem, ToolbarItems.Vendors))}
      >
        <VendorsIcon />
      </ToolbarItem>

      <ToolbarItem
        tooltip="Groups"
        active={activeItem === ToolbarItems.Groups}
        onClick={() => setActiveItem(getActiveToolbarItem(activeItem, ToolbarItems.Groups))}
      >
        <GroupsIcon />
      </ToolbarItem>

      <ToolbarItem
        tooltip="Links"
        active={activeItem === ToolbarItems.Links}
        onClick={() => setActiveItem(getActiveToolbarItem(activeItem, ToolbarItems.Links))}
      >
        <LinksIcon />
      </ToolbarItem>

      <ToolbarItem
        tooltip="Attachments"
        active={activeItem === ToolbarItems.Attachments}
        onClick={() => setActiveItem(getActiveToolbarItem(activeItem, ToolbarItems.Attachments))}
      >
        <AttachmentsIcon />
      </ToolbarItem>

      <ToolbarItem
        tooltip="Newsworthy"
        active={activeItem === ToolbarItems.Newsworthy}
        onClick={() => setActiveItem(getActiveToolbarItem(activeItem, ToolbarItems.Newsworthy))}
      >
        <NewsworthyIcon />
      </ToolbarItem>

      <ToolbarItem
        tooltip="Status"
        active={activeItem === ToolbarItems.Status}
        onClick={() => setActiveItem(getActiveToolbarItem(activeItem, ToolbarItems.Status))}
      >
        <StatusIcon />
      </ToolbarItem>

      <ToolbarItem
        tooltip="Outcome"
        active={activeItem === ToolbarItems.Outcome}
        onClick={() => setActiveItem(getActiveToolbarItem(activeItem, ToolbarItems.Outcome))}
      >
        <OutcomeIcon />
      </ToolbarItem>
    </div>
  );
}
