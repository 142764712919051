import * as palette from "@govlaunch/palette";
import { Spin, Tabs } from "antd";
import React from "react";
import { Query } from "react-apollo";
import DocumentTitle from "react-document-title";
import UserBySlugQuery from "../../../queries/UserBySlugQuery";
import { IUserBySlugQuery, IUserBySlugQueryVariables } from "../../../queries/__generated__/UserBySlugQuery.generated";
import ViewUser from "../ViewUser";
import UserProjectsTab from "../tabs/UserProjectsTab";
import UserGroupsTab from "../tabs/UserGroupsTab";
import { Navigate, useNavigate, useParams } from "react-router";
import UserOnboardingTab from "../onboarding/UserOnboardingTab";

export default function UserBySlug() {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <Query<IUserBySlugQuery, IUserBySlugQueryVariables>
      query={UserBySlugQuery}
      variables={{
        slug: params.slug || "",
        projectsCursor: null,
      }}
    >
      {({ data, loading, fetchMore, refetch }) => {
        if (loading) {
          return (
            <div
              css={{
                padding: 40,
              }}
            >
              <Spin size="large" />
            </div>
          );
        }

        if (!data || !data.user) {
          return <Navigate to="/users" />;
        }

        const { user } = data;

        return (
          <div
            css={{
              backgroundColor: palette.washGray,
              height: "100%",
            }}
          >
            <DocumentTitle title={`${data.user.fullName} | Admin`}>
              <Tabs
                tabBarStyle={{
                  backgroundColor: palette.white,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
                activeKey={params.tab || "details"}
                onChange={(tab) => navigate(`/users/${user.slug}${tab !== "details" ? `/${tab}` : ""}`)}
              >
                <Tabs.TabPane tab="Details" key="details">
                  <ViewUser user={data.user} />
                </Tabs.TabPane>

                <Tabs.TabPane tab="Onboarding" key="onboarding">
                  <UserOnboardingTab user={data.user} refetchUser={refetch} />
                </Tabs.TabPane>

                <Tabs.TabPane tab="Projects" key="projects">
                  <div
                    css={{
                      paddingBottom: 40,
                    }}
                  >
                    <UserProjectsTab user={user} loading={loading} fetchMore={fetchMore} refetch={refetch} />
                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Groups" key="groups">
                  <div
                    css={{
                      paddingBottom: 40,
                    }}
                  >
                    <UserGroupsTab user={user} />
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </DocumentTitle>
          </div>
        );
      }}
    </Query>
  );
}
