import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import * as palette from "@govlaunch/palette";

export default function ImageSelectButton() {
  return (
    <div
      css={{
        width: "80px",
        height: "80px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          opacity: 0.8,
        },
        cursor: "pointer",
      }}
    >
      <FontAwesomeIcon
        icon={faFileImage}
        color={palette.primary}
        style={{
          width: "40px",
          height: "40px",
        }}
      />
      <div
        css={{
          marginTop: 8,
          fontSize: 12,
          color: palette.primary,
        }}
      >
        Select image
      </div>
    </div>
  );
}
