import gql from "graphql-tag";

export default gql`
  mutation EditProduct($id: ObjectId!, $product: ProductInput!, $company: ObjectId) {
    editProduct(id: $id, product: $product, company: $company) {
      _id
      status
      slug
      logo
      name
      description
      link
      governmentsUsingCount
      isDisruptor
      tags {
        _id
        name
      }
    }
  }
`;
