import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, Table, Tabs } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import Column from "antd/lib/table/Column";
import moment from "moment";
import queryString from "query-string";
import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { useTitle } from "react-use";
import { ISolutionStatus } from "../../../types/types";
import TabContent from "../../components/TabContent";
import AddSolution from "./AddSolution";
import EditSolutionName from "./EditSolutionName";
import ApproveSolutionMutation from "./graphql/mutations/ApproveSolutionMutation";
import ToggleArchiveOnSolutionMutation from "./graphql/mutations/ToggleArchiveOnSolutionMutation";
import {
  IApproveSolutionMutation,
  IApproveSolutionMutationVariables,
} from "./graphql/mutations/__generated__/ApproveSolutionMutation.generated";
import {
  IToggleArchiveOnSolutionMutation,
  IToggleArchiveOnSolutionMutationVariables,
} from "./graphql/mutations/__generated__/ToggleArchiveOnSolutionMutation.generated";
import AllSolutionsQuery from "./graphql/queries/AllSolutionsQuery";
import {
  IAllSolutionsQuery,
  IAllSolutionsQueryVariables,
} from "./graphql/queries/__generated__/AllSolutionsQuery.generated";

function getStatusFilter(tab: string) {
  if (tab === "submitted") {
    return ISolutionStatus.Submitted;
  }

  if (tab === "archived") {
    return ISolutionStatus.Archived;
  }

  return ISolutionStatus.Approved;
}

export default function Solutions() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = Array.isArray(query.tab) ? query.tab[0] : query.tab || "approved";

  const [searchText, setSearchText] = useState(() => {
    return Array.isArray(query.search) ? query.search[0] : query.search || "";
  });

  useTitle("Product Solutions | Admin");

  const { data, refetch } = useQuery<IAllSolutionsQuery, IAllSolutionsQueryVariables>(AllSolutionsQuery, {
    fetchPolicy: "network-only",
    variables: {
      searchText,
      filterByStatus: getStatusFilter(tab || "approved"),
    },
  });

  const [toggleArchiveOnSolutionMutation] = useMutation<
    IToggleArchiveOnSolutionMutation,
    IToggleArchiveOnSolutionMutationVariables
  >(ToggleArchiveOnSolutionMutation, {
    refetchQueries: ["AllSolutions"],
    awaitRefetchQueries: true,
  });

  const [approveSolution] = useMutation<IApproveSolutionMutation, IApproveSolutionMutationVariables>(
    ApproveSolutionMutation,
    {
      refetchQueries: ["AllSolutions"],
      awaitRefetchQueries: true,
    },
  );

  const changeTab = useCallback((tab: string) => {
    navigate(`/products/solutions?tab=${tab}`);
    refetch();
  }, []);

  const performSearch = useCallback((searchText: string) => {
    if (searchText === "") {
      navigate(`/products/solutions?tab=${tab}&search=${encodeURIComponent(searchText)}`);
    } else {
      navigate(`/products/solutions?tab=${tab}`);
    }

    setSearchText(searchText);
  }, []);

  const tabContent = (
    <>
      <AddSolution />

      <Input.Search
        size="large"
        placeholder="Search for solutions..."
        autoComplete="off"
        defaultValue={searchText || ""}
        onSearch={(search) => performSearch(search)}
        css={{
          marginTop: 24,
        }}
      />

      <div
        css={{
          marginTop: 24,
        }}
      >
        <Table
          dataSource={data?.allSolutions || []}
          bordered={true}
          size="middle"
          rowKey="_id"
          pagination={{
            defaultPageSize: 10,
          }}
        >
          <Column<IAllSolutionsQuery["allSolutions"][0]>
            title="Name"
            dataIndex="name"
            render={(name) => <div>{name}</div>}
          />

          <Column<IAllSolutionsQuery["allSolutions"][0]>
            title="Product"
            dataIndex="originProduct"
            render={(_, solution) => {
              if (solution.originProduct) {
                return (
                  <Link to={`/products/${solution.originProduct.slug}`}>
                    {solution.originProduct ? solution.originProduct.name : "N/A"}
                  </Link>
                );
              }

              return "N/A";
            }}
          />

          <Column<IAllSolutionsQuery["allSolutions"][0]>
            title="Submitted By"
            dataIndex="submittedBy"
            render={(_, solution) => {
              if (solution.submittedBy) {
                return (
                  <Link to={`/users/${solution.submittedBy.slug}`}>
                    {solution.submittedBy.firstName} {solution.submittedBy.lastName}
                  </Link>
                );
              }

              return "N/A";
            }}
          />

          <Column<IAllSolutionsQuery["allSolutions"][0]>
            title="Created At"
            dataIndex="createdAt"
            render={(_, solution) => {
              return moment(solution.createdAt).format("MM/DD/YYYY");
            }}
          />

          <Column<IAllSolutionsQuery["allSolutions"][0]>
            title="Actions"
            dataIndex="_id"
            width="20%"
            align="center"
            render={(_, solution) => {
              if (solution.status === ISolutionStatus.Archived) {
                return (
                  <Button
                    size="middle"
                    type="primary"
                    onClick={() => {
                      toggleArchiveOnSolutionMutation({
                        variables: {
                          solutionId: solution._id,
                        },
                      });
                    }}
                  >
                    Unarchive
                  </Button>
                );
              }

              return (
                <ButtonGroup>
                  {solution.status === ISolutionStatus.Submitted && (
                    <Button
                      size="middle"
                      type="link"
                      icon={<CheckOutlined />}
                      onClick={() => {
                        approveSolution({
                          variables: {
                            solutionId: solution._id,
                          },
                        });
                      }}
                    >
                      Approve
                    </Button>
                  )}

                  {solution.status === ISolutionStatus.Approved && (
                    <EditSolutionName>
                      {({ setSolution: editSolution }) => (
                        <Button
                          size="middle"
                          type="link"
                          icon={<EditOutlined />}
                          onClick={() => editSolution(solution)}
                        >
                          Rename
                        </Button>
                      )}
                    </EditSolutionName>
                  )}

                  <Button
                    size="middle"
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      if (confirm("Are you sure?")) {
                        toggleArchiveOnSolutionMutation({
                          variables: {
                            solutionId: solution._id,
                          },
                        });
                      }
                    }}
                  >
                    Remove
                  </Button>
                </ButtonGroup>
              );
            }}
          />
        </Table>
      </div>
    </>
  );

  return (
    <div>
      <div
        css={{
          padding: 24,
          borderBottom: "1px solid rgb(232, 232, 232)",
        }}
      >
        <h3
          css={{
            margin: 0,
            fontWeight: 700,
            fontSize: 24,
            lineHeight: 1.33,
          }}
        >
          Solutions
        </h3>
      </div>

      <Tabs
        animated={false}
        onChange={(tab) => changeTab(tab)}
        activeKey={tab || "approved"}
        size="middle"
        tabBarStyle={{
          margin: 0,
          paddingRight: 24,
          paddingLeft: 24,
          userSelect: "none",
        }}
      >
        <Tabs.TabPane tab="Approved" key="approved">
          {tab === "approved" && <TabContent>{tabContent}</TabContent>}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Submitted" key="submitted">
          {tab === "submitted" && <TabContent>{tabContent}</TabContent>}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Archived" key="archived">
          {tab === "archived" && <TabContent>{tabContent}</TabContent>}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
