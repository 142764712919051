import { ITagType } from "../../../types/types";

export function convertTagFormValuesToVariables(values: any) {
  return {
    name: values.name,
    types: values.types,
    relatedTags: values.types.some((type: ITagType) => type === ITagType.Department)
      ? values.relatedTags.map(({ _id, objectID }: any) => _id || objectID)
      : null,
  };
}
