import ProfilePicture from "@govlaunch/profile-picture";
import { CheckOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Table } from "antd";
import { ModalProps } from "antd/lib/modal";
import Column from "antd/lib/table/Column";
import React, { ChangeEvent, FC } from "react";
import { Configure, connectInfiniteHits, Hit, HitsProvided, SearchBoxProvided } from "react-instantsearch-core";
import { connectSearchBox, Index, InstantSearch } from "react-instantsearch-dom";
import { ICompany, IGovernment, ICity } from "../../../../types/types";
import algoliaClient from "../../../algoliaClient";
import getIndexName from "../../../getIndexName";

type TSelectedEntity = {
  _id: string;
  slug: string;
};

interface IBatchVendorAndGovernmentSearchProps {
  title?: ModalProps["title"] | null;
  visible: boolean;
  onSelectVendor: (object: TSelectedEntity) => void;
  onSelectGovernment: (object: TSelectedEntity) => void;
  onRequestClose?: () => void;
  defaultSearchRefinement?: string;
}

export default function BatchVendorAndGovernmentSearch({
  title,
  visible,
  onSelectVendor,
  onSelectGovernment,
  onRequestClose,
  defaultSearchRefinement,
}: IBatchVendorAndGovernmentSearchProps) {
  return (
    <Modal
      width={720}
      title={title || "Search Vendors and Governments"}
      visible={visible}
      onCancel={onRequestClose}
      footer={null}
    >
      <InstantSearch searchClient={algoliaClient} indexName={getIndexName("companies")}>
        <Configure hitsPerPage={3} />

        <div>
          <SearchInput defaultRefinement={defaultSearchRefinement} />

          <Index indexName={getIndexName("companies")}>
            <div
              css={{
                margin: "24px 0",
              }}
            >
              <h3
                css={{
                  margin: 0,
                  marginBottom: 12,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Vendors
              </h3>

              <SearchResults
                onClick={(vendor) => {
                  onSelectVendor({
                    _id: vendor._id || vendor.objectID,
                    slug: vendor.slug,
                  });
                }}
              />
            </div>
          </Index>

          <Index indexName={getIndexName("governments")}>
            <div>
              <h3
                css={{
                  margin: 0,
                  marginBottom: 12,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Governments
              </h3>

              <SearchResults
                onClick={(government) => {
                  onSelectGovernment({
                    _id: government._id || government.objectID,
                    slug: government.slug,
                  });
                }}
              />
            </div>
          </Index>
        </div>
      </InstantSearch>
    </Modal>
  );
}

export const SearchInput = connectSearchBox(({ currentRefinement, refine }: SearchBoxProvided) => {
  return (
    <Input.Search
      value={currentRefinement}
      size="large"
      placeholder="Type vendors or governments names..."
      onChange={(event: ChangeEvent<HTMLInputElement>) => refine(event.target.value)}
    />
  );
});

type TVendor = Pick<ICompany, "_id" | "name" | "slug" | "logo"> & Hit;
type TGovernment = Pick<IGovernment, "_id" | "name" | "slug" | "logo" | "city"> & Hit;
type TSearchResultHit = TVendor | TGovernment;

interface ISearchResultsProps extends Partial<HitsProvided<TSearchResultHit>> {
  onClick: (entity: TSearchResultHit) => void;
  refineNext?: () => void;
  hasMore?: boolean;
}

export function StatelessSearchResults({ hits, refineNext, hasMore, onClick }: ISearchResultsProps) {
  return (
    <Table
      dataSource={hits}
      bordered={true}
      size="small"
      rowKey="_id"
      pagination={false}
      scroll={{
        x: true,
      }}
      footer={() => (
        <Button onClick={refineNext} disabled={!hasMore}>
          Show more
        </Button>
      )}
    >
      <Column<TSearchResultHit>
        title="Name"
        dataIndex="name"
        width="70%"
        render={(_, entity) => (
          <div
            css={{
              display: "flex",
            }}
          >
            <ProfilePicture size={28} image={entity.logo} name={entity.name} />
            <span
              css={{
                margin: "0 8px",
              }}
            >
              {entity.name}
            </span>{" "}
            {entity.city && (
              <div
                css={{
                  marginLeft: 4,
                }}
              >
                <GovernmentDescription city={entity.city as ICity} />
              </div>
            )}
          </div>
        )}
      />

      <Column<TSearchResultHit>
        title="Select"
        dataIndex="_id"
        width="30%"
        align="center"
        render={(_, entity) => (
          <Button type="primary" size="small" shape="round" icon={<CheckOutlined />} onClick={() => onClick(entity)}>
            Select
          </Button>
        )}
      />
    </Table>
  );
}

const SearchResults: FC<ISearchResultsProps> = connectInfiniteHits(StatelessSearchResults as any) as any;

interface IGovernmentDescriptionProps {
  city: ICity;
}

function GovernmentDescription({ city }: IGovernmentDescriptionProps) {
  return (
    <span
      css={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        marginLeft: -8,
      }}
    >
      {city.county ? `${city.type} in ${city.county}` : `${city.type}`}
    </span>
  );
}
