import gql from "graphql-tag";

export default gql`
  mutation UpdateTagById($tagId: ObjectId!, $name: String!, $types: [TagType!]!, $relatedTags: [ObjectId!]) {
    updateTagById(tagId: $tagId, name: $name, types: $types, relatedTags: $relatedTags) {
      _id
      name
      types
      relatedTags {
        _id
        name
      }
    }
  }
`;
