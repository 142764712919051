import gql from "graphql-tag";

export default gql`
  query ProductsCount {
    productsCount {
      allCount
      disruptorsCount
      archivedCount
    }
  }
`;
