import { Filestack, Loader } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing, SpacingBetween } from "@govlaunch/spacers";
import { Button, Input, InputNumber, notification, Select, Spin } from "antd";
import debounce from "lodash/debounce";
import fp from "lodash/fp";
import uniqBy from "lodash/fp/uniqBy";
import PropTypes from "prop-types";
import React from "react";
import { Mutation, Query, useQuery, useMutation } from "react-apollo";
import DocumentTitle from "react-document-title";
import { Field, Form as FinalForm } from "react-final-form";
import { Value } from "react-powerplug";
import { Link } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import * as Yup from "yup";
import * as Types from "../../../../types/types";
import EditGovernment from "../../../mutations/EditGovernment";
import SetGovernmentLogo from "../../../mutations/SetGovernmentLogo";
import SetGovernmentProductStack from "../../../mutations/SetGovernmentProductStack";
import {
  IEditGovernmentMutation,
  IEditGovernmentMutationVariables,
} from "../../../mutations/__generated__/EditGovernment.generated";
import {
  ISetGovernmentLogoMutation,
  ISetGovernmentLogoMutationVariables,
} from "../../../mutations/__generated__/SetGovernmentLogo.generated";
import {
  ISetGovernmentProductStackMutation,
  ISetGovernmentProductStackMutationVariables,
} from "../../../mutations/__generated__/SetGovernmentProductStack.generated";
import GetProductsQuery from "../../../queries/GetProductsQuery";
import { IGetGovernmentQuery } from "../../../queries/__generated__/GetGovernmentQuery.generated";
import {
  IAllProductsQuery,
  IAllProductsQueryVariables,
} from "../../../queries/__generated__/GetProductsQuery.generated";
import { Margin } from "../../../spacings";
import validate from "../../../validate";
import Cover, { GOVERNMENTS_PLACEHOLDER_COVER, makeImaginaryUrls } from "../../profile/Cover";
import SetProfileCover from "../../profile/SetProfileCover";
import TagsSelectContainer from "../../tags/TagsSelect";
import Countries from "../Countries";
import CURRENCIES from "../Currencies";
import GovernmentTypesQuery from "../graphql/queries/GovernmentTypesQuery";
import {
  IGovernmentTypesQuery,
  IGovernmentTypesQueryVariables,
} from "../graphql/queries/__generated__/GovernmentTypesQuery.generated";
import { useNavigate } from "react-router";

interface IDetailsTabProps {
  government: IGetGovernmentQuery["government"];
}

export default function DetailsTab({ government }: IDetailsTabProps) {
  const navigate = useNavigate();

  const { data: governmentTypesQueryData, loading: isGovernmentTypesLoading } = useQuery<
    IGovernmentTypesQuery,
    IGovernmentTypesQueryVariables
  >(GovernmentTypesQuery);

  const [editGovernment] = useMutation<IEditGovernmentMutation, IEditGovernmentMutationVariables>(EditGovernment, {
    onCompleted: () => {
      notification.open({
        type: "success",
        message: "Changes saved!",
        description: "All your changes were saved",
      });
    },
    onError: () => {
      notification.open({
        type: "error",
        message: "Failed to save",
        description: "Failed to save changes",
      });
    },
  });
  const [setGovernmentStack] = useMutation<
    ISetGovernmentProductStackMutation,
    ISetGovernmentProductStackMutationVariables
  >(SetGovernmentProductStack);

  const validationSchema = Yup.object({
    name: Yup.string().required("can't be blank"),
    state: Yup.string().required("can't be blank"),
    stateName: Yup.string().required("can't be blank"),
    // type: Yup.string().required("can't be blank"),
    site: Yup.string().url("invalid url"),
    budget: Yup.number()
      .moreThan(-1, "needs to be at least 0")
      .lessThan(1000000000000000, "too many dollars")
      .required("can't be blank"),
    population: Yup.number()
      .moreThan(-1, "needs to be at least 0")
      .lessThan(1000000000000000, "too many people")
      .required("can't be blank"),
  });

  if (!government) {
    return null;
  }

  if (isGovernmentTypesLoading) {
    return (
      <div
        css={{
          padding: 20,
        }}
      >
        <Loader centered={false} />
      </div>
    );
  }

  return (
    <DocumentTitle title={`Admin | Edit • ${government.name}`}>
      <div
        css={{
          backgroundColor: palette.white,
          border: `1px solid ${palette.lightestGray}`,
        }}
      >
        <div
          css={{
            borderBottom: "1px solid rgb(232, 232, 232)",
            padding: 20,
          }}
        >
          <h3
            css={{
              fontWeight: 700,
              fontSize: 24,
              margin: 0,
            }}
          >
            {government.name} 📝
          </h3>

          <p
            css={{
              margin: 0,
              color: palette.sealBlue,
              fontStyle: "italic",
            }}
          >
            {government.slug} / {government._id}
          </p>

          <Link to="/governments">All Governments</Link>
        </div>

        <FinalForm
          keepDirtyOnReinitialize={true}
          validate={validate(validationSchema)}
          onSubmit={async (values) => {
            const editGovernmentMutationResult = await editGovernment({
              variables: {
                governmentId: government._id,
                payload: {
                  name: values.name || "",
                  type: values.type || "",
                  governmentType: values.governmentType || "",
                  state: values.state || "",
                  stateName: values.stateName || "",
                  county: values.county,
                  country: Countries.find((c) => c.name === values.country)?.code || "",
                  zipCode: values.zipCode,
                  currency: values.currency || "USD",
                  site: values.site,
                  budget: values.budget,
                  population: values.population,
                  resources: values.resources as any,
                  awards: values.awards.map(({ _id, objectID }: any) => _id || objectID),
                },
              },
            });

            await setGovernmentStack({
              variables: {
                governmentId: government._id,
                productIds: values.products,
              },
            });

            if (
              editGovernmentMutationResult &&
              editGovernmentMutationResult.data &&
              editGovernmentMutationResult.data.editGovernment
            ) {
              navigate(`/governments/${editGovernmentMutationResult.data.editGovernment.slug}`);
            }

            return null;
          }}
          initialValues={{
            name: government.city.name,
            type: government.city.type,
            governmentType: government.city.governmentType,
            state: government.city.state,
            stateName: government.city.stateName || government.city.fullState,
            county: government.city.county,
            country: Countries.find((c) => c.code === government.city.country)?.name || "",
            zipCode: government.city.zip,
            site: government.site,
            budget: government.budget,
            population: government.city.population,
            currency: government.currency,
            products: government.products ? government.products.map(({ _id }) => _id) : null,
            awards: government.awards,
            resources: government.resources
              ? government.resources
                  .map((resource) => {
                    if (resource.__typename === "LinkResource") {
                      return {
                        type: "link",
                        title: resource.title,
                        url: resource.url,
                      };
                    } else if (resource.__typename === "TextResource") {
                      return {
                        type: "text",
                        title: resource.title,
                        body: resource.body,
                      };
                    } else if (resource.__typename === "DownloadResource") {
                      return {
                        type: "download",
                        title: resource.title,
                        filename: resource.filename,
                        url: resource.url,
                      };
                    }

                    return null;
                  })
                  .filter(Boolean)
              : null,
          }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div
                css={{
                  padding: 20,
                }}
              >
                <SpacingBetween top={10}>
                  <div>
                    <span
                      css={{
                        fontSize: 12,
                        fontWeight: 500,
                        letterSpacing: 0.5,
                        textTransform: "uppercase",
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      Logo
                    </span>

                    <div
                      css={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <ProfilePicture
                        size={120}
                        name={government.name}
                        image={government.logo}
                        border={`solid 1px ${palette.lightestGray}`}
                        style={{
                          borderRadius: government.logo ? null : 12,
                        }}
                      />

                      <Spacing left={10}>
                        <Mutation<ISetGovernmentLogoMutation, ISetGovernmentLogoMutationVariables>
                          mutation={SetGovernmentLogo}
                        >
                          {(setGovernmentLogo) => {
                            return (
                              <div
                                css={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Filestack
                                  onSuccess={({ filesUploaded }: any) =>
                                    setGovernmentLogo({
                                      variables: {
                                        governmentId: government._id,
                                        logo: filesUploaded[0].url,
                                      },
                                      optimisticResponse: {
                                        __typename: "Mutation",
                                        setGovernmentLogo: {
                                          __typename: "Government",
                                          _id: government._id,
                                          logo: filesUploaded[0].url,
                                        },
                                      },
                                    })
                                  }
                                  customRender={({ onPick }: any) => (
                                    <div
                                      css={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <SpacingBetween top={10}>
                                        <Button onClick={onPick} htmlType="button" size="small">
                                          Change
                                        </Button>

                                        {government.logo && (
                                          <Button
                                            onClick={() => {
                                              setGovernmentLogo({
                                                variables: {
                                                  governmentId: government._id,
                                                  logo: "",
                                                },
                                                optimisticResponse: {
                                                  __typename: "Mutation",
                                                  setGovernmentLogo: {
                                                    __typename: "Government",
                                                    _id: government._id,
                                                    logo: "",
                                                  },
                                                },
                                              });
                                            }}
                                            htmlType="button"
                                            danger={true}
                                            size="small"
                                          >
                                            Remove
                                          </Button>
                                        )}
                                      </SpacingBetween>
                                    </div>
                                  )}
                                />
                              </div>
                            );
                          }}
                        </Mutation>
                      </Spacing>
                    </div>
                  </div>

                  <div>
                    <span
                      css={{
                        fontSize: 12,
                        fontWeight: 500,
                        letterSpacing: 0.5,
                        textTransform: "uppercase",
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      Cover
                    </span>

                    <Spacing top={10}>
                      <SetProfileCover entityType="Government" targetId={government._id}>
                        {({ onSetProfileCover, onSetRepositionedCover, onRemoveCover }) => (
                          <div
                            css={{
                              position: "relative",
                            }}
                          >
                            <Cover
                              height={245}
                              alt={`${government.name}'s cover`}
                              originalSource={fp.getOr(null, "cover.original", government)}
                              repositionedSource={fp.getOr(null, "cover.repositioned", government)}
                              placeholderSource={GOVERNMENTS_PLACEHOLDER_COVER}
                              onReposition={(originalSource, cropState) => {
                                const repositionedSource = makeImaginaryUrls(originalSource, cropState);

                                onSetRepositionedCover(originalSource, repositionedSource.cropped, cropState);
                              }}
                              onChange={onSetProfileCover}
                              onRemove={onRemoveCover}
                              readOnly={false}
                              containerProps={{
                                style: {
                                  borderRadius: 5,
                                },
                              }}
                              imageProps={{
                                style: {
                                  borderRadius: 5,
                                },
                              }}
                            />
                          </div>
                        )}
                      </SetProfileCover>
                    </Spacing>
                  </div>

                  <SpacingBetween top={10}>
                    <Field name="name">
                      {({ input, meta }) => (
                        <div>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: 0.5,
                                textTransform: "uppercase",
                                display: "flex",
                              }}
                            >
                              Name
                            </span>

                            <FieldError meta={meta} />
                          </div>

                          <Spacing top={10}>
                            <Input size="large" {...input} defaultValue="" />
                          </Spacing>
                        </div>
                      )}
                    </Field>

                    {false && (
                      <Field name="type">
                        {({ input, meta }) => (
                          <div>
                            <div
                              css={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                css={{
                                  fontSize: 12,
                                  fontWeight: 500,
                                  letterSpacing: 0.5,
                                  textTransform: "uppercase",
                                  display: "flex",
                                }}
                              >
                                Type
                              </span>

                              <FieldError meta={meta} />
                            </div>

                            <Spacing top={10}>
                              <Input size="large" {...input} defaultValue="" placeholder="Borough, Village, City" />
                            </Spacing>
                          </div>
                        )}
                      </Field>
                    )}

                    <Field name="governmentType">
                      {({ input, meta }) => (
                        <div>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: 0.5,
                                textTransform: "uppercase",
                                display: "flex",
                              }}
                            >
                              Government Type
                            </span>

                            <FieldError meta={meta} />
                          </div>

                          <Spacing top={10}>
                            <Select {...input} placeholder="Select a government type">
                              {governmentTypesQueryData?.governmentTypes.map((governmentType) => {
                                return (
                                  <Select.Option key={governmentType} value={governmentType}>
                                    {governmentType}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Spacing>
                        </div>
                      )}
                    </Field>

                    <Field name="country">
                      {({ input, meta }) => (
                        <div>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: 0.5,
                                textTransform: "uppercase",
                                display: "flex",
                              }}
                            >
                              Country
                            </span>

                            <FieldError meta={meta} />
                          </div>

                          <Spacing top={10}>
                            <Select {...input} placeholder="Select a country" showSearch={true}>
                              {Countries.map(({ code, name }) => {
                                return (
                                  <Select.Option key={code} value={name}>
                                    {name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Spacing>
                        </div>
                      )}
                    </Field>

                    <Field name="state">
                      {({ input, meta }) => (
                        <div>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: 0.5,
                                textTransform: "uppercase",
                                display: "flex",
                              }}
                            >
                              State
                            </span>

                            <FieldError meta={meta} />
                          </div>

                          <Spacing top={10}>
                            <Input size="large" {...input} defaultValue="" placeholder="CA" />
                          </Spacing>
                        </div>
                      )}
                    </Field>

                    <Field name="stateName">
                      {({ input, meta }) => (
                        <div>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: 0.5,
                                textTransform: "uppercase",
                                display: "flex",
                              }}
                            >
                              State Name
                            </span>

                            <FieldError meta={meta} />
                          </div>

                          <Spacing top={10}>
                            <Input size="large" {...input} defaultValue="" placeholder="California" />
                          </Spacing>
                        </div>
                      )}
                    </Field>

                    <Field name="county">
                      {({ input, meta }) => (
                        <div>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: 0.5,
                                textTransform: "uppercase",
                                display: "flex",
                              }}
                            >
                              County
                            </span>

                            <FieldError meta={meta} />
                          </div>

                          <Spacing top={10}>
                            <Input size="large" {...input} defaultValue="" />
                          </Spacing>
                        </div>
                      )}
                    </Field>

                    <Field name="zipCode">
                      {({ input, meta }) => (
                        <div>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: 0.5,
                                textTransform: "uppercase",
                                display: "flex",
                              }}
                            >
                              Zip / Postal Code
                            </span>

                            <FieldError meta={meta} />
                          </div>

                          <Spacing top={10}>
                            <Input size="large" {...input} defaultValue="" placeholder="00000" />
                          </Spacing>
                        </div>
                      )}
                    </Field>

                    <Field name="site">
                      {({ input, meta }) => (
                        <div>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: 0.5,
                                textTransform: "uppercase",
                                display: "flex",
                              }}
                            >
                              Site
                            </span>

                            <FieldError meta={meta} />
                          </div>

                          <Spacing top={10}>
                            <Input
                              {...input}
                              size="large"
                              defaultValue={government.site ? government.site : undefined}
                            />
                          </Spacing>
                        </div>
                      )}
                    </Field>

                    <Field name="currency">
                      {({ input, meta }) => (
                        <div>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: 0.5,
                                textTransform: "uppercase",
                                display: "flex",
                              }}
                            >
                              Currency
                            </span>

                            <FieldError meta={meta} />
                          </div>

                          <Spacing top={10}>
                            <Select {...input} placeholder="Select a currency">
                              {CURRENCIES.map(({ code, name }) => {
                                return (
                                  <Select.Option key={code} value={code}>
                                    {name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Spacing>
                        </div>
                      )}
                    </Field>

                    <Field name="budget">
                      {({ input, meta }) => (
                        <div>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: 0.5,
                                textTransform: "uppercase",
                                display: "flex",
                              }}
                            >
                              Budget
                            </span>

                            <FieldError meta={meta} />
                          </div>

                          <Spacing top={10}>
                            <InputNumber
                              {...input}
                              size="large"
                              style={{
                                width: "100%",
                              }}
                              formatter={(value) => `💵 ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              parser={(value) => parseFloat(value ? value.replace(/([^\d]+)/g, "") : "")}
                            />
                          </Spacing>
                        </div>
                      )}
                    </Field>

                    <Field name="population">
                      {({ input, meta }) => (
                        <div>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                letterSpacing: 0.5,
                                textTransform: "uppercase",
                                display: "flex",
                              }}
                            >
                              Population
                            </span>

                            <FieldError meta={meta} />
                          </div>

                          <Spacing top={10}>
                            <InputNumber
                              {...input}
                              size="large"
                              style={{
                                width: "100%",
                              }}
                              defaultValue={
                                government.city && government.city.population ? government.city.population : undefined
                              }
                              formatter={(value) => `👪 ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              parser={(value) => parseInt(value ? value.replace(/([^\d]+)/g, "") : "")}
                            />
                          </Spacing>
                        </div>
                      )}
                    </Field>
                  </SpacingBetween>

                  <div>
                    <span
                      css={{
                        fontSize: 12,
                        fontWeight: 500,
                        letterSpacing: 0.5,
                        textTransform: "uppercase",
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      Product Stack
                    </span>

                    <Spacing top={10}>
                      <Field name="products">
                        {({ input }) => (
                          <Value initial="">
                            {({ value: searchQuery, set: onSetSearchQuery }) => (
                              <Value initial="" onChange={debounce(onSetSearchQuery, 200)}>
                                {({ set: onSearch }) => (
                                  <Query<IAllProductsQuery, IAllProductsQueryVariables>
                                    query={GetProductsQuery}
                                    variables={{
                                      search: searchQuery,
                                      filter: null,
                                      sort: null,
                                      cursor: null,
                                      inTiers: null,
                                      tags: null,
                                    }}
                                  >
                                    {({ data, loading }) => {
                                      const products =
                                        data && data.products && data.products.items ? data.products.items : [];
                                      const governmentProducts = government.products || [];

                                      return (
                                        <Select
                                          size="large"
                                          showSearch={true}
                                          value={input.value}
                                          placeholder="Select products"
                                          filterOption={false}
                                          allowClear={true}
                                          mode="multiple"
                                          notFoundContent={loading ? <Spin /> : null}
                                          onSearch={onSearch}
                                          onChange={input.onChange}
                                        >
                                          {uniqBy("_id", [...products, ...governmentProducts]).map((product) => (
                                            <Select.Option key={product._id.toString()} value={product._id}>
                                              <div
                                                css={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <Spacing right={10} inline={true} flex={true}>
                                                  <ProfilePicture size={15} image={product.logo} name={product.name} />
                                                </Spacing>
                                                <span>{product.name}</span>
                                              </div>
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      );
                                    }}
                                  </Query>
                                )}
                              </Value>
                            )}
                          </Value>
                        )}
                      </Field>
                    </Spacing>
                  </div>

                  <Margin mt={24}>
                    <div>
                      <p
                        css={{
                          margin: 0,
                          fontSize: 12,
                          fontWeight: 500,
                          letterSpacing: 0.5,
                          textTransform: "uppercase",
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        Awards
                      </p>
                      <Field name="awards">{({ input }) => <TagsSelectContainer {...input} type="AWARD" />}</Field>
                    </div>
                  </Margin>

                  <Resources />
                </SpacingBetween>
              </div>

              <div
                css={{
                  padding: 20,
                  borderTop: "1px solid rgb(232, 232, 232)",
                }}
              >
                <SpacingBetween right={10}>
                  <Button htmlType="submit" loading={submitting} disabled={submitting} size="large" type="primary">
                    Save Changes
                  </Button>
                </SpacingBetween>
              </div>
            </form>
          )}
        </FinalForm>
      </div>
    </DocumentTitle>
  );
}

function Resources() {
  return (
    <Field name="resources">
      {({ input }) => (
        <div>
          <Spacing bottom={10}>
            <div
              css={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                css={{
                  fontSize: 12,
                  fontWeight: 500,
                  letterSpacing: 0.5,
                  textTransform: "uppercase",
                  display: "flex",
                }}
              >
                Resources
              </span>
            </div>
          </Spacing>

          <div
            css={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <SpacingBetween right={10}>
              {input.value.map((resource: Types.IResourceInput, index: number) => {
                return (
                  <div
                    // eslint-disable-next-line
                    key={index}
                    css={{
                      border: `dashed 1px ${palette.lightestGray}`,
                      padding: 20,
                      borderRadius: 4,
                      maxWidth: 300,
                      minWidth: 200,
                    }}
                  >
                    <h2
                      css={{
                        margin: 0,
                      }}
                    >
                      {`${resource.title} `}
                      <span
                        role="button"
                        onClick={() =>
                          input.onChange(
                            input.value.filter((_: Types.IResourceInput, maybe: number) => maybe !== index),
                          )
                        }
                        css={{
                          color: palette.red,
                          cursor: "pointer",
                        }}
                      >
                        &times;
                      </span>
                    </h2>

                    {resource.type === "link" && (
                      <a href={resource.url ? resource.url : undefined} target="_blank" rel="noopener noreferrer">
                        {resource.url}
                      </a>
                    )}

                    {resource.type === "download" && (
                      <a href={`${resource.url}?dl=true`} download={resource.filename}>
                        {resource.filename}
                      </a>
                    )}

                    {resource.type === "text" && (
                      <div
                        css={{
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {resource.body}
                      </div>
                    )}
                  </div>
                );
              })}
            </SpacingBetween>
          </div>

          <div
            css={{
              marginTop: input.value.length > 0 ? 10 : 0,
            }}
          >
            <ResourceForm onSubmit={(resource: string) => input.onChange(input.value.concat(resource))} />
          </div>
        </div>
      )}
    </Field>
  );
}

// TODO: Warning: validateDOMNesting(...): <form> cannot appear as a descendant of <form>.
function ResourceForm(props: any) {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("can't be blank"),
  });

  return (
    <FinalForm
      mutators={{
        setFilename: (args, state, utils) => {
          utils.changeValue(state, "filename", () => args[0]);
        },
      }}
      validate={validate(validationSchema)}
      initialValues={{
        type: "link",
      }}
      {...props}
    >
      {({ handleSubmit, values, form }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
            form.reset();
          }}
          css={{
            border: `dashed 1px ${palette.lightestGray}`,
            padding: 20,
            borderRadius: 4,
            flex: 1,
          }}
        >
          <Spacing bottom={10}>
            {values.type === "link" && <span>Link</span>}
            {values.type === "download" && <span>Download</span>}
            {values.type === "text" && <span>Text</span>}
          </Spacing>

          <Field name="type">
            {({ input }) => (
              <Select {...input}>
                <Select.Option value="link">Link</Select.Option>
                <Select.Option value="download">Download</Select.Option>
                <Select.Option value="text">Text</Select.Option>
              </Select>
            )}
          </Field>

          <div
            css={{
              marginTop: 10,
            }}
          >
            <Field name="title">{({ input }) => <Input {...input} size="large" placeholder="Title" />}</Field>
          </div>

          <div
            css={{
              marginTop: 10,
            }}
          >
            {values.type === "link" && (
              <Field name="url">{({ input }) => <Input size="large" placeholder="https://" {...input} />}</Field>
            )}

            {values.type === "download" && (
              <Field name="url">
                {({ input }) => {
                  return (
                    <>
                      {values.filename && (
                        <span
                          css={{
                            display: "flex",
                            marginBottom: 10,
                          }}
                        >
                          {values.filename}
                        </span>
                      )}

                      <div>
                        <Filestack
                          onSuccess={({ filesUploaded }: any) => {
                            input.onChange(filesUploaded[0].url);
                            form.mutators.setFilename(filesUploaded[0].url);
                          }}
                          options={{
                            fromSources: ["local_file_system"],
                          }}
                          customRender={({ onPick }: any) => (
                            <>
                              <Button type="primary" onClick={onPick}>
                                Upload file
                              </Button>
                            </>
                          )}
                        />
                      </div>
                    </>
                  );
                }}
              </Field>
            )}

            {values.type === "text" && (
              <Field name="body">
                {({ input }) => (
                  <TextareaAutosize
                    {...input}
                    minRows={2}
                    css={{
                      resize: "none",
                      width: "100%",
                      outline: 0,
                      borderRadius: 4,
                      border: `1px solid #d9d9d9`,
                      padding: "6px 11px",
                      fontSize: 16,
                    }}
                  />
                )}
              </Field>
            )}
          </div>

          <div
            css={{
              marginTop: 10,
            }}
          >
            <Button htmlType="submit">Add</Button>

            <Spacing inline={true} flex={true} marginLeft={10}>
              <Button danger={true} onClick={() => form.reset()}>
                Clear
              </Button>
            </Spacing>
          </div>
        </form>
      )}
    </FinalForm>
  );
}

interface IFieldErrorProps {
  meta: Partial<{
    touched: boolean;
    error: any;
    submitError: any;
  }>;
}

function FieldError({ meta }: IFieldErrorProps) {
  if (meta.touched && (meta.error || meta.submitError)) {
    return (
      <div
        css={{
          marginLeft: 10,
          color: palette.red,
          textTransform: "uppercase",
          fontSize: 12,
        }}
      >
        {meta.error || meta.submitError}
      </div>
    );
  } else {
    return null;
  }
}

FieldError.propTypes = {
  meta: PropTypes.object.isRequired,
};
