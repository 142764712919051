import gql from "graphql-tag";

export default gql`
  query NewsletterSettings {
    getNewsletterSettings {
      subject
      collections
      storiesPerCollection
      projectsPerCollection
      hourToSend
      maxProjects
    }
  }
`;
