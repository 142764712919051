import React from "react";
import { Hover } from "react-powerplug";
import Popover from "@govlaunch/popover";
import * as palette from "@govlaunch/palette";

interface IToolbarItemProps extends React.HTMLAttributes<HTMLDivElement> {
  active: boolean;
  tooltip: string;
}


export default function ToolbarItem({ active, tooltip, ...props }: IToolbarItemProps) {
  return (
    <Hover>
      {({ hovered, bind }) => (
        <Popover
          isOpen={hovered}
          placement="top"
          render={() => {
            return (
              <div
                css={{
                  padding: 8,
                  color: palette.white,
                  fontSize: 12,
                  fontWeight: "bold",
                }}
              >
                {tooltip}
              </div>
            );
          }}
          borderColor="transparent"
          backgroundColor="#333333"
          boxStyle={{
            boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.2)",
            zIndex: 9999,
          }}
        >
          {({ innerRef }: { innerRef: string }) => {
            return (
              <div
                {...props}
                {...bind}
                ref={innerRef}
                css={{
                  height: 35,
                  width: 35,
                  borderRadius: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: active ? "#b9e2fd" : palette.lightestBlue,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: active ? "#b9e2fd" : palette.lightBlue,
                  },
                }}
              />
            );
          }}
        </Popover>
      )}
    </Hover>
  );
}
