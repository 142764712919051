import gql from "graphql-tag";

export default gql`
  mutation AddCountryTimezone($data: CountryTimezoneInput!) {
    addCountryTimezone(data: $data) {
      _id
      countryCode
      countryName
      timezone
    }
  }
`;
