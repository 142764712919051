import { LoadingIndicator } from "@govlaunch/core";
import { Modal } from "antd";
import React from "react";

interface ILoadingStateModalProps {
  title: string;
  visible: boolean;
  onRequestClose?: () => void;
}

export default function LoadingStateModal({ title, visible, onRequestClose }: ILoadingStateModalProps) {
  return (
    <Modal width={720} title={title} visible={visible} onCancel={onRequestClose} footer={null}>
      <div
        css={{
          textAlign: "center",
        }}
      >
        <LoadingIndicator />
      </div>
    </Modal>
  );
}
