import * as palette from "@govlaunch/palette";

import React, { memo, FunctionComponent } from "react";

import Downshift from "downshift";
import { Input } from "@govlaunch/core/form";
import { LoadingIndicator } from "@govlaunch/core";
import { Margin } from "../../spacings";
import ProfilePicture from "@govlaunch/profile-picture";
import ProgressiveImage from "react-progressive-image";
import { SearchAlgoliaIndex } from "@govlaunch/algolia";
import { uniqBy } from "lodash/fp";
import { useSelfie } from "../auth/Selfie";
import { ICompany } from "../../../types/types";
import FieldValidationError from "../../components/form/FieldValidationError";

interface IVendorInputProps {
  vendor: Pick<ICompany, "_id" | "name" | "logo">;
  onChange: any;
  onBlur?: any;
  onFocus?: any;
  meta: Partial<{
    touched: boolean;
    error: any;
    submitError: any;
  }>;
}

const VendorInput: FunctionComponent<IVendorInputProps> = ({ vendor, onChange, onBlur, onFocus, meta }) => {
  const user = useSelfie();

  if (vendor) {
    return (
      <div>
        <ProgressiveImage src={vendor.logo || ""} placeholder="">
          {(src: string) => (
            <div
              css={{
                display: "grid",
                gridTemplateAreas: '"vendor remove"',
                gridTemplateColumns: "auto 1fr",
                gridColumnGap: 10,
                gridRowGap: 2.5,
                alignItems: "center",
                backgroundColor: "rgba(219, 236, 247, 0.5)",
                borderRadius: 60,
                padding: "10px 15px",
              }}
            >
              <div
                css={{
                  gridArea: "vendor",
                  display: "grid",
                  gridTemplateAreas: '"logo name"',
                  gridTemplateColumns: "auto 1fr",
                  gridColumnGap: 10,
                  gridRowGap: 2.5,
                  alignItems: "center",
                  "&:focus": {
                    outline: 0,
                  },
                }}
              >
                <ProfilePicture
                  image={src}
                  name={vendor.name}
                  size={28}
                  css={{
                    userSelect: "none",
                    gridArea: "logo",
                  }}
                />

                <div
                  css={{
                    gridArea: "name",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: 14,
                    fontWeight: 600,
                    color: palette.primary,
                    lineHeight: 1,
                  }}
                >
                  {vendor.name}
                </div>
              </div>

              <div
                css={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <svg
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onChange(null);
                  }}
                  viewBox="0 0 117 117"
                  width={28}
                  height={28}
                  css={{
                    gridArea: "remove",
                    cursor: "pointer",
                    "&:hover": {
                      opacity: 0.8,
                    },
                  }}
                >
                  <g fill="#FF7171" fillRule="evenodd">
                    <path
                      fillRule="nonzero"
                      d="M58.5 116.4c31.9 0 57.9-26 57.9-57.9S90.4.6 58.5.6.6 26.6.6 58.5s26 57.9 57.9 57.9zm0-107.7c27.4 0 49.8 22.3 49.8 49.8 0 27.5-22.3 49.8-49.8 49.8-27.5 0-49.8-22.4-49.8-49.8S31.1 8.7 58.5 8.7z"
                    />
                    <path d="M31.8 62h53.3c2.3 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1H31.8c-2.3 0-4.1 1.8-4.1 4.1s1.9 4.1 4.1 4.1z" />
                  </g>
                </svg>
              </div>
            </div>
          )}
        </ProgressiveImage>
      </div>
    );
  }

  return (
    <SearchAlgoliaIndex
      fetchOnMount={false}
      index="companies"
      params={{
        hitsPerPage: 40,
      }}
      user={user}
    >
      {({
        searchValue,
        onChange: setSearchQuery,
        loading,
        results,
      }: {
        searchValue: string;
        onChange: any;
        loading: boolean;
        results: ICompany[];
      }) => {
        return (
          <Downshift
            itemToString={(vendor) => {
              if (vendor) {
                return vendor._id || vendor.ObjectID;
              } else {
                return null;
              }
            }}
            inputValue={searchValue}
            onChange={(selectedVendor) => {
              setSearchQuery("");
              onChange(selectedVendor);
            }}
            stateReducer={(_, changes) => {
              switch (changes.type) {
                case Downshift.stateChangeTypes.changeInput:
                  return {
                    ...changes,
                    highlightedIndex: null,
                  };
                default:
                  return changes;
              }
            }}
            onInputValueChange={(inputValue, changes) => {
              if ((changes as any).type === Downshift.stateChangeTypes.changeInput) {
                setSearchQuery(inputValue);
              }
            }}
          >
            {({ getInputProps, getItemProps, highlightedIndex, isOpen }) => (
              <div>
                <div
                  css={{
                    position: "relative",
                  }}
                >
                  <div
                    css={{
                      position: "relative",
                    }}
                  >
                    <Input
                      {...getInputProps({
                        onBlur,
                        onFocus,
                      })}
                      size="small"
                      placeholder="Type a vendor name…"
                    />

                    {loading && (
                      <div
                        css={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 10,
                        }}
                      >
                        <LoadingIndicator />
                      </div>
                    )}
                  </div>

                  {isOpen && results.length > 0 && (
                    <Margin
                      mt={4}
                      css={{
                        position: "absolute",
                        width: "100%",
                      }}
                    >
                      <div
                        css={{
                          maxHeight: 200,
                          overflowY: "scroll",
                          boxShadow: "0 6px 10px 0 rgba(225, 225, 225, 0.5)",
                          border: `solid 1px ${palette.lightestSealBlue}`,
                          backgroundColor: palette.white,
                          borderRadius: 5,
                          padding: 10,
                        }}
                      >
                        <div
                          css={{
                            display: "grid",
                            gridRowGap: 10,
                          }}
                        >
                          {uniqBy((vendor) => vendor._id || (vendor as any).ObjectID, results).map((vendor, index) => {
                            const isSelected = highlightedIndex === index;

                            return (
                              <div
                                key={vendor._id || (vendor as any).ObjectID}
                                css={{
                                  display: "grid",
                                  gridTemplateAreas: '"logo name" "logo description"',
                                  gridTemplateColumns: "auto 1fr",
                                  gridColumnGap: 10,
                                  gridRowGap: 0,
                                  padding: 5,
                                  cursor: "pointer",
                                }}
                                {...getItemProps({
                                  item: vendor,
                                  index,
                                })}
                              >
                                <div
                                  css={{
                                    gridArea: "logo",
                                    display: "inline-flex",
                                  }}
                                >
                                  <ProgressiveImage src={vendor.logo || ""} placeholder="">
                                    {(src: string) => <ProfilePicture name={vendor.name} image={src} size={32} />}
                                  </ProgressiveImage>
                                </div>

                                <span
                                  css={{
                                    gridArea: "name",
                                    color: isSelected ? palette.primary : palette.sealBlue,
                                    fontSize: 14,
                                    fontWeight: 600,
                                  }}
                                >
                                  {vendor.name}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Margin>
                  )}
                </div>

                <FieldValidationError meta={meta} />
              </div>
            )}
          </Downshift>
        );
      }}
    </SearchAlgoliaIndex>
  );
};

export default memo(VendorInput);
