import { useQuery } from "react-apollo";
import GetImagesCount from "../../queries/GetImagesCount";
import { IImagesCountQuery, IImagesCountQueryVariables } from "../../queries/__generated__/GetImagesCount.generated";
import { ReactElement } from "react";

interface IImagesCountRenderProps {
  allCount: number;
  noAltCount: number;
  withAltCount: number;
  placeholderCount: number;
  archivedCount: number;
  refetch: Function;
  loading: boolean;
}

interface IImagesCountProps {
  children: (prop: IImagesCountRenderProps) => ReactElement;
}

export default function ImagesCount({ children }: IImagesCountProps) {
  const { data, loading, refetch } = useQuery<IImagesCountQuery, IImagesCountQueryVariables>(GetImagesCount, {
    fetchPolicy: "network-only",
  });

  if (!data || !data.imagesCount) {
    return children({
      allCount: 0,
      noAltCount: 0,
      withAltCount: 0,
      placeholderCount: 0,
      archivedCount: 0,
      refetch,
      loading,
    });
  }

  return children({
    ...data.imagesCount,
    refetch,
    loading,
  });
}
