import React, { useState } from "react";
import { IBlockLayout, TBlockLayoutData, IPodcastBlockData } from "../blockProps";
import * as palette from "@govlaunch/palette";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "antd";
import ReactTextareaAutosize from "react-textarea-autosize";
import { Button } from "@govlaunch/web";

interface IAddPodcastModalButtonProps {
  block: IBlockLayout;
  saveBlockData: (block: IBlockLayout, data: TBlockLayoutData) => void;
}

export default function AddPodcastModalButton({ block, saveBlockData }: IAddPodcastModalButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      css={{
        borderRadius: 4,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <AddPodcastButton onClick={() => setIsOpen(true)} />
      <PodcastInputModal isOpen={isOpen} onClose={() => setIsOpen(false)} block={block} saveBlockData={saveBlockData} />
    </div>
  );
}

interface IAddPodcastButonProps {
  onClick: () => void;
}

function AddPodcastButton({ onClick }: IAddPodcastButonProps) {
  return (
    <div
      css={{
        width: "80px",
        height: "80px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          opacity: 0.8,
        },
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faCode}
        color={palette.primary}
        style={{
          width: "40px",
          height: "40px",
        }}
      />
      <div
        css={{
          marginTop: 8,
          fontSize: 12,
          color: palette.primary,
        }}
      >
        Embed Code
      </div>
    </div>
  );
}

interface IPodcastInputModalProps {
  isOpen: boolean;
  onClose: () => void;
  block: IBlockLayout;
  saveBlockData: (block: IBlockLayout, blockData: TBlockLayoutData) => void;
}

function PodcastInputModal({ isOpen, onClose, block, saveBlockData }: IPodcastInputModalProps) {
  return (
    <Modal title="Embed Code" visible={isOpen} onCancel={onClose} footer={null} width={600}>
      <PodcastInput block={block} saveBlockData={saveBlockData} onClose={onClose} />
    </Modal>
  );
}

interface IPodcastInputProps {
  block: IBlockLayout;
  saveBlockData: (block: IBlockLayout, blockData: TBlockLayoutData) => void;
  onClose: () => void;
}

function PodcastInput({ block, saveBlockData, onClose }: IPodcastInputProps) {
  const [data, setData] = useState((block.blockData as IPodcastBlockData).podcastSrc || "");

  return (
    <div
      css={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ReactTextareaAutosize
        css={{
          "::placeholder": {
            color: palette.sealBlue,
          },
          ":focus": {
            borderColor: palette.primary,
          },
          fontSize: 14,
          border: `1px solid ${palette.lightestGray}`,
          borderRadius: 5,
          padding: "10px 10px",
          width: "100%",
          outline: 0,
          resize: "none",
        }}
        minRows={4}
        maxRows={6}
        placeholder="Add your code here..."
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        onChange={(event) => {
          setData(event.target.value);
        }}
        value={data}
      />
      <Button
        marginTop="20px"
        variant="primary"
        width={100}
        height="36px"
        marginLeft={2}
        onClick={() => {
          //const embedSrc = extractSrc(data);
          saveBlockData(block, {
            ...block.blockData,
            podcastSrc: data,
          });
          onClose();
        }}
      >
        Apply
      </Button>
    </div>
  );
}
