import React from "react";
import DocumentTitle from "react-document-title";
import { palette } from "@govlaunch/web";
import { Table, Divider } from "antd";
import { faLandmark } from "@fortawesome/free-solid-svg-icons";
import { sum } from "lodash";
import { Link } from "react-router-dom";
import { getCountryNameByIsoCode } from "../Countries";
import { useQuery } from "react-apollo";
import CenteredLoader from "../../../CenteredLoader";
import GovernmentCountriesBreakdownQuery from "../graphql/queries/GovernmentCountriesBreakdownQuery";
import {
  IGovernmentCountriesBreakdownQuery,
  IGovernmentCountriesBreakdownQueryVariables,
} from "../graphql/queries/__generated__/GovernmentCountriesBreakdownQuery.generated";
import TablePageHeader from "../../../components/table/TablePageHeader";
import { MetaBaseQuestion } from "../../../components/MetaBaseLink";

export default function CountriesList() {
  const { data: governmentCountriesBreakdownData, loading: isGovernmentCountriesBreakdownLoading } = useQuery<
    IGovernmentCountriesBreakdownQuery,
    IGovernmentCountriesBreakdownQueryVariables
  >(GovernmentCountriesBreakdownQuery);

  if (isGovernmentCountriesBreakdownLoading) {
    return <CenteredLoader />;
  }

  return (
    <DocumentTitle title="Governments | Admin">
      <>
        <TablePageHeader title="Select Country" titleIcon={faLandmark} metabaseQuestion={MetaBaseQuestion.Governments} />
        <div
          css={{
            padding: 20,
          }}
        >
          <Table
            rowKey="isoCountryCode"
            loading={false}
            size="small"
            bordered={true}
            pagination={false}
            dataSource={governmentCountriesBreakdownData?.governmentCountriesBreakdown || []}
            scroll={{
              x: true,
            }}
            title={() => {
              const breakdowns = governmentCountriesBreakdownData?.governmentCountriesBreakdown || [];
              // Grand totals
              const totalOfGovernments = sum(breakdowns.map((node) => node.totalOfGovernments));
              const totalOfActiveGovernments = sum(breakdowns.map((node) => node.totalOfActiveGovernments));
              const totalOfInactiveGovernments = sum(breakdowns.map((node) => node.totalOfInactiveGovernments));

              return (
                <div
                  css={{
                    padding: 4,
                  }}
                >
                  <strong>
                    Countries ({(governmentCountriesBreakdownData?.governmentCountriesBreakdown || []).length})
                  </strong>

                  <Divider
                    style={{
                      margin: "12px 0px",
                    }}
                  />

                  <div
                    css={{
                      display: "grid",
                      maxWidth: 300,
                      gridTemplateColumns: "repeat(3, 1fr)",
                      "& > div": {
                        display: "grid",
                        rowGap: 2,
                      },
                    }}
                  >
                    <div>
                      <strong>{totalOfGovernments}</strong>
                      <span>Total</span>
                    </div>
                    <div
                      css={{
                        color: palette.teal[800],
                      }}
                    >
                      <strong>{totalOfActiveGovernments}</strong>
                      <span>Active</span>
                    </div>
                    <div
                      css={{
                        color: palette.gray[500],
                      }}
                    >
                      <strong>{totalOfInactiveGovernments}</strong>
                      <span>Inactive</span>
                    </div>
                  </div>
                </div>
              );
            }}
            columns={[
              {
                key: "name",
                title: "Name",
                dataIndex: "isoCountryCode",
                width: 300,
                render: (_, option) => {
                  const countryName = getCountryNameByIsoCode(option.isoCountryCode);

                  return (
                    <Link to={`/governments?country=${option.isoCountryCode}&tab=active`}>
                      <div
                        css={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, max-content)",
                          columnGap: 12,
                        }}
                      >
                        <img
                          src={`https://static.govlaunch.com/flags/squared/${option.isoCountryCode}.svg`}
                          width={18}
                          height={18}
                        />

                        <span>{countryName || option.isoCountryCode}</span>
                      </div>
                    </Link>
                  );
                },
              },
              {
                key: "totalOfGovernments",
                title: "Total / Active / Inactive",
                dataIndex: "totalOfGovernments",
                render: (_, option) => {
                  return (
                    <div
                      css={{
                        display: "grid",
                        maxWidth: 300,
                        gridTemplateColumns: "repeat(3, 1fr)",
                        "& > div": {
                          display: "grid",
                          rowGap: 2,
                        },
                      }}
                    >
                      <div>
                        <strong>{option.totalOfGovernments}</strong>
                        <span>Total</span>
                      </div>
                      <div
                        css={{
                          color: palette.teal[800],
                        }}
                      >
                        <strong>{option.totalOfActiveGovernments}</strong>
                        <span>Active</span>
                      </div>
                      <div
                        css={{
                          color: palette.gray[500],
                        }}
                      >
                        <strong>{option.totalOfInactiveGovernments}</strong>
                        <span>Inactive</span>
                      </div>
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
      </>
    </DocumentTitle>
  );
}
