import gql from "graphql-tag";

export default gql`
  query AllGovernments(
    $search: String
    $filter: String
    $hasOwner: Boolean
    $type: [String!]
    $sortBy: String
    $cursor: Cursor
    $limit: Int = 25
    $state: [String!]
    $country: String
    $tags: [ObjectId!]
  ) {
    allGovernments(
      search: $search
      filter: $filter
      sortBy: $sortBy
      limit: $limit
      hasOwner: $hasOwner
      type: $type
      cursor: $cursor
      state: $state
      country: $country
      tags: $tags
    ) {
      items {
        _id
        slug
        name
        logo
        isInnovator
        enableSharing
        storiesCount
        projectsCount
        city {
          _id
          name
          state
          country
          stateName: state(full: true)
          type
          county
        }
        products {
          _id
        }
        owner {
          _id
          avatar
          fullName
          isInnovator
        }
        members {
          _id
          slug
          fullName
          email
          role
          avatar
        }
        activatedAt
      }
      pageInfo {
        totalCount
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;
