import * as palette from "@govlaunch/palette";
import { Query } from "react-apollo";
import { Tabs } from "antd";
import DocumentTitle from "react-document-title";
import React from "react";
import ScrollToTopOnMount from "../../ScrollToTopOnMount";
import ProductQuery from "../../queries/ProductQuery";
import ProductDetailsTab from "./tabs/ProductDetailsTab";
import ProductProjectsTab from "./tabs/ProductProjectsTab";
import ProductStoriesTab from "./tabs/ProductStoriesTab";
import { IProductQuery, IProductQueryVariables } from "../../queries/__generated__/ProductQuery.generated";
import { useSelfie } from "../auth/Selfie";
import TabContent from "../../components/TabContent";
import { useNavigate, useParams } from "react-router";
import { LoadingIndicator } from "@govlaunch/core";

const Product: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const selfie = useSelfie();

  return (
    <ScrollToTopOnMount>
      <DocumentTitle title="Edit Product | Admin">
        <Query<IProductQuery, IProductQueryVariables>
          notifyOnNetworkStatusChange={true}
          fetchPolicy="network-only"
          variables={{
            slug: params.slug || "",
          }}
          query={ProductQuery}
        >
          {({ data, loading, refetch }) => {
            if (loading || !data || !data.product) {
              return (
                <div css={{
 padding: 15 
}}
                >
                  <LoadingIndicator />
                </div>
              );
            }

            const { product } = data;

            if (!product) {
              return null;
            }

            return (
              <div
                css={{
                  backgroundColor: palette.washGray,
                  minHeight: "100vh",
                }}
              >
                <Tabs
                  tabBarStyle={{
                    backgroundColor: palette.white,
                    paddingLeft: 20,
                    paddingRight: 20,
                    margin: 0,
                  }}
                  activeKey={params.tab || "details"}
                  onChange={(tab) => navigate(`/products/${params.slug}${tab !== "details" ? `/${tab}` : ""}`)}
                >
                  <Tabs.TabPane key="details" tab="Details">
                    <ProductDetailsTab product={product} />
                  </Tabs.TabPane>

                  <Tabs.TabPane key="projects" tab="Projects">
                    <TabContent>
                      <ProductProjectsTab product={product} refetch={refetch} />
                    </TabContent>
                  </Tabs.TabPane>

                  {selfie && selfie.isSiteAdmin && (
                    <Tabs.TabPane key="stories" tab="Stories">
                      <TabContent>
                        <ProductStoriesTab product={product} refetch={refetch} />
                      </TabContent>
                    </Tabs.TabPane>
                  )}
                </Tabs>
              </div>
            );
          }}
        </Query>
      </DocumentTitle>
    </ScrollToTopOnMount>
  );
};

export default Product;
