import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Spacing, SpacingBetween } from "@govlaunch/spacers";
import { notification, Popconfirm, Popover, Table, Tag } from "antd";
import { TableProps } from "antd/lib/table";
import { SorterResult, TableCurrentDataSource, TablePaginationConfig } from "antd/lib/table/interface";
import moment from "moment";
import React from "react";
import { Mutation } from "react-apollo";
import { Toggle } from "react-powerplug";
import { Link } from "react-router-dom";
import { IProcessStatusType } from "../../../../types/types";
import DotdotdotIcon from "../../../components/icons/DotdotdotIcon";
import { ToggleDisruptor, ToggleInnovator } from "../../../mutations";
import DeleteUser from "../../../mutations/DeleteUser";
import ToggleUserBan from "../../../mutations/ToggleUserBan";
import {
  IDeleteUserMutation,
  IDeleteUserMutationVariables,
} from "../../../mutations/__generated__/DeleteUser.generated";
import {
  IToggleUserBanMutation,
  IToggleUserBanMutationVariables,
} from "../../../mutations/__generated__/ToggleUserBan.generated";
import { IUserFieldsFragment } from "../../../queries/fragments/__generated__/UserFields.generated";
import { IAllUsersQuery } from "../../../queries/__generated__/AllUsersQuery.generated";
import { getFilteredValue } from "../../../utils/tablesUtils";
import Impersonate from "../../debugging/Impersonate";
import AssignUserFlow from "../../teamManagement/AssignUserFlow";
import RemoveVerification, { RemoveCitizenVerification } from "../RemoveUserVerification";
import SignInAttemptsTable from "../SignInAttemptsTable";
import VerifyUser from "../VerifyUser";
import { IUsersTableState } from "./ManageUsers";
import UserTableName from "./UserTableName";

interface IUsersTableProps<T> {
  loading?: boolean | null;
  users: T[];
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, any[] | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>,
  ) => void;
  filter?: string | null;
  onDeleteUser: () => void;
  onBanUser: () => void;
  sorter?: boolean;
  state?: IUsersTableState;
  footer?: TableProps<any>["footer"];
  title?: TableProps<any>["title"];
}

const UsersTable = ({
  loading = null,
  users,
  footer,
  onChange = () => {},
  filter = null,
  onDeleteUser,
  onBanUser,
  state,
  sorter = false,
  title,
}: IUsersTableProps<IUserFieldsFragment>) => {
  return (
    <Table<IUserFieldsFragment>
      rowKey="_id"
      loading={loading || false}
      onChange={onChange}
      style={{
        backgroundColor: palette.white,
      }}
      scroll={{
        x: true,
      }}
      footer={footer}
      title={title}
      dataSource={users}
      columns={[
        {
          title: "Name",
          dataIndex: "fullName",
          sorter,
          key: "fullName",
          sortOrder: state && state.sorter && state.sorter.columnKey === "fullName" ? state.sorter.order : undefined,
          render: (_, user) => <UserTableName user={user} filter={filter} />,
        },
        {
          title: "Job Title",
          dataIndex: "jobTitle",
          width: 200,
        },
        {
          title: "Tags",
          dataIndex: "role",
          filters: getTagsFilter(filter),
          filterMultiple: false,
          filteredValue: getTagsFilteredValue(state?.role),
          render: (_, user) => (
            <span>
              {user.role === "GOV_OWNER" && <Tag color="geekblue">Government Owner</Tag>}
              {user.role === "GOV_USER" && <Tag color="cyan">Government Member</Tag>}
              {user.role === "COMPANY_USER" && <Tag color="blue">Company User</Tag>}
              {user.role === "COMPANY_OWNER" && <Tag color="geekblue">Company Owner</Tag>}
              {user.__typename === "GovernmentUser" && user.isInnovator && <Tag color="geekblue">Innovator</Tag>}
              {user.__typename === "VendorUser" && user.isDisruptor && <Tag color="geekblue">Disruptor</Tag>}
              {user.__typename === "PendingUser" && user.groupRequest && <Tag color="orange">Group Request</Tag>}
              {user.__typename === "PendingUser" && user.productStackRequest && (
                <Tag color="purple">Product Stack Request</Tag>
              )}
              {user.__typename === "PendingUser" && user.isUnverifiable && <Tag color="warning">Unverifiable</Tag>}
              {user.__typename === "PendingUser" &&
                user.onboarding &&
                user.onboarding.processStatus === IProcessStatusType.PendingVerification && (
                  <Link to={`/users/${user.slug}/onboarding`}>
                    <Tag color="magenta">Onboarding Completed</Tag>
                  </Link>
                )}
              {user.__typename === "PendingUser" && user.isDuplicated && (
                <Link to={`/duplicatedUsers?search=${user.fullName}`}>
                  <Tag color="warning">Possible Duplicate</Tag>
                </Link>
              )}
            </span>
          ),
        },
        {
          title: "Account",
          dataIndex: ["company", "plan"],
          sorter: false,
          render: (plan) => {
            if (!plan) {
              return "N/A";
            }

            if (plan.name === "Basic") {
              return (
                <span
                  css={{
                    wordBreak: "break-word",
                    fontWeight: "bold",
                  }}
                >
                  {plan.name}
                </span>
              );
            }

            return (
              <span
                css={{
                  wordBreak: "break-word",
                  color: plan.name === "Disruptor" ? palette.darkSkyBlue : palette.greenSuccess,
                  fontWeight: "bold",
                }}
              >
                {plan.name}
              </span>
            );
          },
          filters: [
            {
              text: "Basic",
              value: "Basic",
            },
            {
              text: "Makers",
              value: "Makers",
            },
            {
              text: "Makers Pro",
              value: "Makers Pro",
            },
            {
              text: "Disruptors",
              value: "Disruptors",
            },
          ],
          filterMultiple: false,
          filteredValue: getFilteredValue(state?.plan),
        },
        {
          title: "Assigned To",
          render: (user) => {
            if (user.__typename === "PendingUser") {
              return "Not assigned";
            } else if (user.__typename === "VendorUser") {
              return (
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture
                    size={32}
                    image={user.company.logo}
                    name={user.company.name}
                    disruptor={user.company.isDisruptor}
                    css={{
                      whiteSpace: "nowrap",
                    }}
                  />

                  <Spacing left={10}>
                    <Link to={`/vendors/${user.company.slug}`}>{user.company.name}</Link>
                  </Spacing>
                </div>
              );
            } else if (user.__typename === "GovernmentUser") {
              return (
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture
                    size={32}
                    image={user.government.logo}
                    name={user.government.name}
                    innovator={user.government.isInnovator}
                    css={{
                      whiteSpace: "nowrap",
                    }}
                  />

                  <Spacing left={10}>
                    <Link to={`/governments/${user.government.slug}`}>{user.government.name}</Link>
                  </Spacing>
                </div>
              );
            }
          },
        },
        {
          title: "SSO Provider",
          render: (user) => {
            return (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {user.lastSignInProvider}
              </div>
            );
          },
        },
        {
          title: "Groups",
          render: (user) => {
            if (user.groups.length >= 2) {
              return "Multiple";
            }

            if (user.groups.length === 1) {
              const group = user.groups[0] as NonNullable<
                NonNullable<IAllUsersQuery["allUsers"]>["items"]
              >[0]["groups"][0];

              return (
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture
                    size={32}
                    image={group.logo}
                    name={group.name}
                    css={{
                      whiteSpace: "nowrap",
                    }}
                  />

                  <Spacing left={10}>
                    <Link to={`/groups/${group.slug}`}>{group.name}</Link>
                  </Spacing>
                </div>
              );
            }

            return "None";
          },
        },
        {
          title: "Joined",
          dataIndex: "createdAt",
          render: (createdAt) => {
            const diffInDays = moment().diff(moment(createdAt), "days");

            if (diffInDays >= 180) {
              return moment(createdAt).format("MMMM DD, YYYY");
            }

            return moment(createdAt).fromNow();
          },
          key: "createdAt",
          sorter,
          sortOrder: state && state.sorter && state.sorter.columnKey === "createdAt" ? state.sorter.order : undefined,
        },
        {
          title: "Seen",
          dataIndex: "lastSignInAt",
          render: (lastSignInAt) => {
            if (!lastSignInAt) {
              return "N/A";
            }

            return moment(lastSignInAt).fromNow();
          },
          key: "lastSignInAt",
          sorter,
          sortOrder:
            state && state.sorter && state.sorter.columnKey === "lastSignInAt" ? state.sorter.order : undefined,
        },
        {
          title: "Actions",
          render: (user) => {
            return (
              <Toggle>
                {({ on, toggle }) => (
                  <Popover
                    visible={on}
                    trigger="click"
                    title="User Actions"
                    placement="rightBottom"
                    onVisibleChange={toggle}
                    content={
                      <ul
                        css={{
                          margin: 0,
                          padding: 0,
                          listStyle: "none",
                          "> li:not(:first-of-type)": {
                            marginTop: 10,
                          },
                        }}
                      >
                        <li>
                          <Link
                            to={`/users/${user.slug}`}
                            onClick={() => {
                              toggle();
                            }}
                          >
                            Profile
                          </Link>
                        </li>

                        {user.__typename === "PendingUser" && user.role !== "CITIZEN" && !user.verified && (
                          <li>
                            <VerifyUser user={user}>
                              {(toggleVerify) => (
                                <a
                                  href="#"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    toggleVerify();
                                    toggle();
                                  }}
                                >
                                  Verify
                                </a>
                              )}
                            </VerifyUser>
                          </li>
                        )}

                        {user.__typename === "PendingUser" && user.verified && (
                          <li>
                            <RemoveVerification user={user}>
                              {(toggleRemoveVerification) => (
                                <a
                                  href="#"
                                  css={{
                                    color: palette.red,
                                  }}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    toggleRemoveVerification();
                                    toggle();
                                  }}
                                >
                                  Remove Verification
                                </a>
                              )}
                            </RemoveVerification>
                          </li>
                        )}

                        {user.role === "CITIZEN" && (
                          <li>
                            <RemoveCitizenVerification user={user}>
                              {(toggleRemoveCitizenVerification) => (
                                <a
                                  href="#"
                                  css={{
                                    color: palette.red,
                                  }}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    toggleRemoveCitizenVerification();
                                    toggle();
                                  }}
                                >
                                  Remove Citizen Verification
                                </a>
                              )}
                            </RemoveCitizenVerification>
                          </li>
                        )}
                        {user.__typename === "GovernmentUser" && (
                          <li>
                            <Link to={`/users?government=${user.government._id}&tab=governments`} onClick={toggle}>
                              Relationships
                            </Link>
                          </li>
                        )}

                        {user.__typename === "GovernmentUser" && (
                          <ToggleInnovator entityId={user._id} entityType="user">
                            {(toggle) => (
                              <li>
                                <a
                                  href="#"
                                  css={{
                                    color: user.isInnovator ? palette.red : undefined,
                                  }}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    toggle();
                                  }}
                                >
                                  {user.isInnovator ? "Remove Innovator" : "Set Innovator"}
                                </a>
                              </li>
                            )}
                          </ToggleInnovator>
                        )}

                        {user.__typename === "GovernmentUser" && (
                          <li>
                            <Link to={`/governments/${user.government.slug}`} onClick={toggle}>
                              View Government
                            </Link>
                          </li>
                        )}

                        {user.__typename === "VendorUser" && (
                          <ToggleDisruptor entityId={user._id} entityType="user">
                            {(toggle) => (
                              <li>
                                <a
                                  href="#"
                                  css={{
                                    color: user.isDisruptor ? palette.red : undefined,
                                  }}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    toggle(user);
                                  }}
                                >
                                  {user.isDisruptor ? "Remove Disruptor" : "Set Disruptor"}
                                </a>
                              </li>
                            )}
                          </ToggleDisruptor>
                        )}

                        {user.__typename === "PendingUser" && (
                          <Toggle>
                            {({ on: isOpen, toggle: toggleAssignFlow }) => (
                              <>
                                <li>
                                  <a
                                    href="#"
                                    onClick={() => {
                                      toggleAssignFlow();
                                      toggle();
                                    }}
                                  >
                                    Assign
                                  </a>
                                </li>

                                <AssignUserFlow
                                  isVisible={isOpen}
                                  initialMemberAdditions={[user]}
                                  onRequestClose={toggleAssignFlow}
                                />
                              </>
                            )}
                          </Toggle>
                        )}

                        <Impersonate>
                          {(impersonate) => (
                            <li>
                              <a
                                href="#"
                                onClick={(event) => {
                                  event.preventDefault();
                                  impersonate(user);
                                }}
                              >
                                Impersonate
                              </a>
                            </li>
                          )}
                        </Impersonate>

                        {user.__typename === "VendorUser" && (
                          <li>
                            <Link to={`/users?company=${user.company?._id}&tab=vendors`} onClick={toggle}>
                              Relationships
                            </Link>
                          </li>
                        )}

                        <Mutation<IDeleteUserMutation, IDeleteUserMutationVariables>
                          onCompleted={onDeleteUser}
                          variables={{
                            userId: user._id,
                          }}
                          mutation={DeleteUser}
                          onError={(err) => {
                            if (/A Vendor must have at least one owner/.test(err.message)) {
                              notification.open({
                                type: "error",
                                message: "Hold on!",
                                description: `${user.fullName} cannot be deleted. A vendor must have at least one owner`,
                              });
                            }
                          }}
                        >
                          {(deleteUser) => (
                            <li>
                              <Popconfirm
                                placement="topRight"
                                title={`Are sure you want to archive ${user.fullName}?`}
                                onConfirm={() => {
                                  deleteUser();
                                  toggle();
                                }}
                                okText="Yes"
                                cancelText="No"
                              >
                                <a
                                  href="#"
                                  css={{
                                    color: palette.red,
                                  }}
                                >
                                  Archive
                                </a>
                              </Popconfirm>
                            </li>
                          )}
                        </Mutation>

                        <Mutation<IToggleUserBanMutation, IToggleUserBanMutationVariables>
                          onCompleted={onBanUser}
                          variables={{
                            userId: user._id,
                          }}
                          mutation={ToggleUserBan}
                        >
                          {(banUser) => (
                            <li>
                              {user.bannedAt ? (
                                <Popconfirm
                                  placement="topRight"
                                  title={`Are sure you want to unblock ${user.fullName}?`}
                                  onConfirm={() => {
                                    banUser();
                                    toggle();
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <a
                                    href="#"
                                    css={{
                                      color: palette.red,
                                    }}
                                  >
                                    Unblock
                                  </a>
                                </Popconfirm>
                              ) : (
                                <Popconfirm
                                  placement="topRight"
                                  title={`Are sure you want to block ${user.fullName}?`}
                                  onConfirm={() => {
                                    banUser();
                                    toggle();
                                  }}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <a
                                    href="#"
                                    css={{
                                      color: palette.red,
                                    }}
                                  >
                                    Block
                                  </a>
                                </Popconfirm>
                              )}
                            </li>
                          )}
                        </Mutation>
                      </ul>
                    }
                  >
                    <span
                      role="button"
                      css={{
                        cursor: "pointer",
                      }}
                    >
                      <DotdotdotIcon />
                    </span>
                  </Popover>
                )}
              </Toggle>
            );
          },
        },
      ]}
      pagination={false}
      bordered={true}
      expandedRowRender={(user) => {
        return (
          <div
            css={{
              padding: 10,
            }}
          >
            <SpacingBetween top={20}>
              {user.__typename === "PendingUser" && user.productStackRequest && (
                <>
                  <h3
                    css={{
                      fontWeight: 600,
                    }}
                  >
                    ✉️ Sign Up Reference (1)
                  </h3>

                  <div
                    css={{
                      border: `1px solid ${palette.lightestGray}`,
                      background: "#fff",
                      padding: 12,
                    }}
                  >
                    <p
                      css={{
                        margin: "0 0 8px",
                      }}
                    >
                      <span
                        css={{
                          fontWeight: 700,
                        }}
                      >
                        Invited by:{" "}
                      </span>
                      {user.productStackRequest.inviter.fullName} - {user.productStackRequest.inviter.email}
                    </p>
                    <p
                      css={{
                        margin: "0 0 8px",
                      }}
                    >
                      <span
                        css={{
                          fontWeight: 700,
                        }}
                      >
                        As request to add product <strong>{user.productStackRequest.product.name}</strong> from vendor{" "}
                        <strong>{user.productStackRequest.vendor.name}</strong>
                      </span>
                    </p>
                    <p
                      css={{
                        margin: 0,
                      }}
                    >
                      <span
                        css={{
                          fontWeight: 700,
                        }}
                      >
                        As member of one of these governments:{" "}
                      </span>

                      <ul>
                        {user.productStackRequest.possibleGovernments.map((government) => (
                          <li key={government._id}>{government.name}</li>
                        ))}
                      </ul>
                    </p>
                  </div>
                </>
              )}

              {user.__typename === "PendingUser" && user.groupRequest && (
                <>
                  <h3
                    css={{
                      fontWeight: 600,
                    }}
                  >
                    ✉️ Sign Up Reference (1)
                  </h3>

                  <div
                    css={{
                      border: `1px solid ${palette.lightestGray}`,
                      background: "#fff",
                      padding: 12,
                    }}
                  >
                    <p
                      css={{
                        margin: "0 0 8px",
                      }}
                    >
                      <span
                        css={{
                          fontWeight: 700,
                        }}
                      >
                        Invited by:{" "}
                      </span>
                      {user.groupRequest.inviter.fullName} - {user.groupRequest.inviter.email}
                    </p>
                    <p
                      css={{
                        margin: "0 0 8px",
                      }}
                    >
                      <span
                        css={{
                          fontWeight: 700,
                        }}
                      >
                        To join Group:{" "}
                      </span>

                      {user.groupRequest.group.name}
                    </p>
                    <p
                      css={{
                        margin: 0,
                      }}
                    >
                      <span
                        css={{
                          fontWeight: 700,
                        }}
                      >
                        As member of one of these{" "}
                        {user.groupRequest.__typename === "GovernmentGroupRequest" ? "Governments" : "Vendors"}:{" "}
                      </span>

                      <ul>
                        {user.groupRequest.__typename === "GovernmentGroupRequest" &&
                          user.groupRequest.possibleGovernments.map((government) => (
                            <li key={government._id}>{government.name}</li>
                          ))}

                        {user.groupRequest.__typename === "VendorGroupRequest" &&
                          user.groupRequest.possibleVendors.map((vendor) => <li key={vendor._id}>{vendor.name}</li>)}
                      </ul>
                    </p>
                  </div>
                </>
              )}

              {user.signInAttempts && user.signInAttempts.length > 0 && (
                <>
                  <h3
                    css={{
                      fontWeight: 600,
                    }}
                  >
                    📌 Sign In Attempts ({user.signInAttempts.length})
                  </h3>
                  <SignInAttemptsTable attempts={user.signInAttempts} />
                </>
              )}
            </SpacingBetween>
            {user.__typename === "PendingUser" && user.previousCompany && (
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 15,
                  height: 33,
                }}
              >
                <h3
                  css={{
                    fontWeight: 600,
                    marginRight: "1.5em",
                    paddingTop: 3,
                  }}
                >
                  📌 Previously Assigned
                </h3>
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture
                    size={32}
                    image={user.previousCompany.logo}
                    name={user.previousCompany.name}
                    css={{
                      whiteSpace: "nowrap",
                    }}
                  />

                  <Spacing left={10}>
                    <Link to={`/vendors/${user.previousCompany.slug}`}>{user.previousCompany.name}</Link>
                  </Spacing>
                </div>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

function getTagsFilter(filter: string | null) {
  if (!filter) {
    return [];
  }

  if (["GOVERNMENT", "GOV_OWNER", "GOV_USER"].includes(filter)) {
    return [
      {
        text: "Government Owner",
        value: "GOV_OWNER",
      },
      {
        text: "Government Member",
        value: "GOV_USER",
      },
    ];
  }

  if (["VENDOR", "VENDOR_OWNER", "VENDOR_USER"].includes(filter)) {
    return [
      {
        text: "Vendor Owner",
        value: "VENDOR_OWNER",
      },
      {
        text: "Vendor Member",
        value: "VENDOR_USER",
      },
    ];
  }

  if (["PENDING_USERS", "UNVERIFIABLE_PENDING_USERS"].includes(filter)) {
    return [
      {
        text: "Unverifiable",
        value: "UNVERIFIABLE_PENDING_USERS",
      },
    ];
  }

  return [];
}

function getTagsFilteredValue(filteredValue: string) {
  if (["GOV_OWNER", "GOV_USER", "VENDOR_OWNER", "VENDOR_USER", "UNVERIFIABLE_PENDING_USERS"].includes(filteredValue)) {
    return getFilteredValue(filteredValue);
  }

  return null;
}

export default UsersTable;
