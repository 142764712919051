export function getUserType(role: string, verified: boolean) {
  if (["GOV_OWNER", "GOV_USER"].includes(role || "")) {
    return "Government User";
  }

  if (["COMPANY_OWNER", "COMPANY_USER"].includes(role || "")) {
    return "Vendor User";
  }

  if (role === "CITIZEN") {
    return "Citizen";
  }

  if (verified) {
    return "Community Verified";
  }

  return "Pending User";
}
