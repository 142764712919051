import base64 from "base64-url";
import { ITablePaginationState } from "../components/table/TablePagination";

export default function encodeCursor(offset: number) {
  return base64.encode(
    JSON.stringify({
      offset: offset,
    }),
  );
}

export function calculateAndEncodeCursor({ page, size }: ITablePaginationState) {
  return base64.encode(
    JSON.stringify({
      offset: (page - 1) * size,
      limit: size,
    }),
  );
}
