import * as palette from "@govlaunch/palette";
import React from "react";
import { Mutation } from "react-apollo";
import { useToggle } from "react-use";
import Switch from "react-switch";
import ToggleUsersCanRequestContentTranslation from "../../../mutations/ToggleUsersCanRequestContentTranslation";
import { IMutationToggleUsersCanRequestContentTranslationArgs } from "../../../../types/types";
import { IToggleUsersCanRequestContentTranslationMutation } from "../../../mutations/__generated__/ToggleUsersCanRequestContentTranslation.generated";
import { ItemTitle } from "./UsersCanAccessAccountPage";

interface IUsersCanRequestContentTranslationProps {
  suggestContentTranslation: boolean;
  vendorId: any;
}

export function UsersCanRequestContentTranslationToggleRow({
  suggestContentTranslation,
  vendorId,
}: IUsersCanRequestContentTranslationProps) {
  return (
    <div
      css={{
        paddingTop: 20,
      }}
    >
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "max-content 1fr",
          gridColumnGap: 40,
          gridRowGap: 30,
        }}
      >
        <ItemTitle>Enable Translation of Vendor's Profile Page</ItemTitle>
        <div>
          <ToggleUsersCanRequestContentTranslationMutation
            suggestContentTranslation={suggestContentTranslation}
            vendorId={vendorId}
          />
        </div>
      </div>
    </div>
  );
}

export const ToggleUsersCanRequestContentTranslationMutation = ({
  suggestContentTranslation,
  vendorId,
}: IUsersCanRequestContentTranslationProps) => {
  const [usersCanRequestContentTranslation, toggleUsersCanRequestContentTranslation] = useToggle(
    suggestContentTranslation,
  );

  return (
    <Mutation<IToggleUsersCanRequestContentTranslationMutation, IMutationToggleUsersCanRequestContentTranslationArgs>
      onCompleted={() => {}}
      variables={{
        vendorId: vendorId,
        requestContentTranslationAllowed: usersCanRequestContentTranslation,
      }}
      mutation={ToggleUsersCanRequestContentTranslation}
    >
      {(toggleAccessToTranslation) => (
        <Switch
          uncheckedIcon={
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: palette.darkGray,
                paddingRight: 10,
              }}
            >
              No
            </div>
          }
          checkedIcon={
            <div
              css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "#fff",
                paddingLeft: 10,
              }}
            >
              Yes
            </div>
          }
          handleDiameter={22}
          height={26}
          width={65}
          onChange={async (checked) => {
            toggleUsersCanRequestContentTranslation(checked);
            await toggleAccessToTranslation({
              variables: {
                vendorId: vendorId,
                requestContentTranslationAllowed: checked,
              },
            });
          }}
          checked={usersCanRequestContentTranslation}
          onColor={palette.greenSuccess}
          offColor={palette.lightGray}
        />
      )}
    </Mutation>
  );
};
