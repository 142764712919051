import React, { Component, MouseEventHandler } from "react";
import jsonp from "jsonp-promise";
import * as palette from "@govlaunch/palette";
import { LoadingIndicator } from "@govlaunch/core";

interface IVimeoThumbnailProps {
  url: string;
  input?: any;
  onClick?: MouseEventHandler;
}

export default class VimeoThumbnail extends Component<IVimeoThumbnailProps> {
  public static defaultProps = {
    input: null,
    onClick: null,
  };

  state = {
    loading: false,
    thumbnailUrl: null,
  };

  componentDidMount() {
    const vimeoId = this.props.url.replace("https://vimeo.com/", "");

    this.setState({
      loading: true,
    });

    jsonp(`https://vimeo.com/api/v2/video/${vimeoId}.json`).promise.then(([response]: any[]) => {
      this.setState({
        loading: false,
        thumbnailUrl: response.thumbnail_small,
      });
    });
  }

  render() {
    const { input, onClick } = this.props;
    const { thumbnailUrl, loading } = this.state;

    return (
      <div
        onClick={onClick}
        css={{
          width: 93,
          height: 93,
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          border: `1px solid ${palette.lightestGray}`,
          borderRadius: 5,
          position: "relative",
        }}
      >
        <div
          css={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            opacity: 0.6,
            display: "flex",
          }}
        >
          <svg width={24} height={18} viewBox="0 0 24 18">
            <g fill="#FFF" fillRule="nonzero">
              <path d="M12 17.851c-1.172 0-2.345-.05-3.509-.152l-5.294-.46A3.48 3.48 0 0 1 0 13.752V4.253A3.48 3.48 0 0 1 3.197.766l5.294-.46a40.513 40.513 0 0 1 7.018 0l5.294.46A3.482 3.482 0 0 1 24 4.253v9.499a3.48 3.48 0 0 1-3.197 3.487l-5.294.46a40.235 40.235 0 0 1-3.509.152zm0-16.697c-1.144 0-2.287.049-3.422.148l-5.294.46A2.488 2.488 0 0 0 1 4.253v9.499c0 1.307.982 2.377 2.284 2.491l5.294.46c2.271.197 4.573.197 6.844 0l5.294-.46A2.486 2.486 0 0 0 23 13.752V4.253a2.487 2.487 0 0 0-2.284-2.491l-5.294-.46A39.802 39.802 0 0 0 12 1.154z" />
              <path d="M9.5 14.003a.501.501 0 0 1-.5-.5v-9a.501.501 0 0 1 .77-.42l7 4.5a.499.499 0 0 1 0 .84l-7 4.5a.498.498 0 0 1-.27.08zm.5-8.584v7.168l5.575-3.584L10 5.419z" />
            </g>
          </svg>
        </div>

        {input && (
          <button
            type="button"
            css={{
              cursor: "pointer",
              top: -5,
              right: -5,
              type: "button",
              position: "absolute",
              width: 25,
              height: 25,
              lineHeight: "20px",
              background: palette.red,
              color: "#fff",
              border: 0,
              borderRadius: "100%",
              outline: 0,
            }}
            onClick={() => input.onChange(input.value.filter((maybe: string) => maybe !== this.props.url))}
          >
            &times;
          </button>
        )}

        {loading ? (
          <LoadingIndicator />
        ) : (
          <img
            src={thumbnailUrl || ""}
            css={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: 5,
            }}
          />
        )}
      </div>
    );
  }
}
