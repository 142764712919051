import gql from "graphql-tag";

export default gql`
  mutation Verify($userId: ObjectId!, $explanation: String, $attachments: [AttachmentInput!]!) {
    verify(userId: $userId, explanation: $explanation, attachments: $attachments) {
      ... on PendingUser {
        _id
        verified
      }
      ... on VendorUser {
        _id
        verified
      }
      ... on GovernmentUser {
        _id
        verified
      }
    }
  }
`;
