import { Layout } from "react-grid-layout";
import { IBlockLayoutType } from "../../../../types/types";

export interface IBlockLayoutDataBase {
  type: IBlockLayoutType;
  title: string;
  titleVisible: boolean;
  itemLimit?: number;
  backgroundColor?: string;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
}

export interface IImageBlockData extends IBlockLayoutDataBase {
  url: string;
  alt: string;
}

export interface IImageLinkBlockData extends IImageBlockData {
  href: string;
}

export interface IPodcastBlockData extends IBlockLayoutDataBase {
  podcastSrc: string;
}

export interface IFeaturedStoriesData extends IBlockLayoutDataBase {
  storyIds: string[];
}

export interface IStatCounterData extends IBlockLayoutDataBase {
  labelsColor: string;
  countersColor: string;
  widgetColor: string;
  showProjects: boolean;
  showProducts: boolean;
  showGovernments: boolean;
  showStories: boolean;
  showVendors: boolean;
}

export interface IGovernmentsBlockData extends IBlockLayoutDataBase {
  secondTitle: string;
}

export interface ICollectionsBlockData extends IBlockLayoutDataBase {
  secondTitle: string;
  displayAsTags: boolean;
}

export type TBlockLayoutData =
  | IBlockLayoutDataBase
  | IImageBlockData
  | IImageLinkBlockData
  | IPodcastBlockData
  | IFeaturedStoriesData
  | IStatCounterData
  | IGovernmentsBlockData
  | ICollectionsBlockData;

// database equivalent is ILandingPageConfigBlock
export interface IBlockLayout extends Layout {
  blockData: TBlockLayoutData;
}

export function getBlockKey() {
  return Math.random().toString(36).substring(2, 10);
}

export const DEFAULT_PROJECTS_ITEM_LIMIT = 10;
export const DEFAULT_STORIES_ITEM_LIMIT = 3;

export const BLOCK_TYPE_SELECT_OPTIONS = [
  {
    value: IBlockLayoutType.Image,
    label: "Image",
  },
  {
    value: IBlockLayoutType.ExternalLinks,
    label: "What are we reading?",
  },
  {
    value: IBlockLayoutType.NewProjects,
    label: "New Projects",
  },
  {
    value: IBlockLayoutType.FeaturedStories,
    label: "Featured Stories",
  },
  {
    value: IBlockLayoutType.StatCounters,
    label: "Currently on Govlaunch",
  },
  {
    value: IBlockLayoutType.Podcast,
    label: "Podcast",
  },
  {
    value: IBlockLayoutType.Advertisement,
    label: "Advertisement",
  },
  {
    value: IBlockLayoutType.TrendingGovernments,
    label: "Trending Governments",
  },
  {
    value: IBlockLayoutType.TrendingCollections,
    label: "Trending Collections",
  },
  {
    value: IBlockLayoutType.Events,
    label: "Upcoming events",
  },
  {
    value: IBlockLayoutType.EmptySpaceBlock,
    label: "Empty Space",
  },
];

export function getBlockTitle(type: IBlockLayoutType) {
  const result = BLOCK_TYPE_SELECT_OPTIONS.find((block) => block.value === type);

  if (!result) {
    return "Empty Block";
  }

  return result.label;
}

export function getDefaultItemLimit(type: IBlockLayoutType) {
  if (type === IBlockLayoutType.NewProjects) {
    return DEFAULT_PROJECTS_ITEM_LIMIT;
  }

  if (type === IBlockLayoutType.TrendingCollections) {
    return DEFAULT_PROJECTS_ITEM_LIMIT;
  }

  if (type === IBlockLayoutType.TrendingGovernments) {
    return DEFAULT_PROJECTS_ITEM_LIMIT;
  }

  if (type === IBlockLayoutType.Events) {
    return DEFAULT_PROJECTS_ITEM_LIMIT;
  }

  if (type === IBlockLayoutType.FeaturedStories) {
    return DEFAULT_STORIES_ITEM_LIMIT;
  }

  return undefined;
}

export function appendPlusBlock(blocks: IBlockLayout[]): IBlockLayout[] {
  let maxY = 0;
  let maxX = 0;
  let h = 1;

  blocks.forEach((block) => {
    if (block.y > maxY) {
      maxY = block.y;
      maxX = block.x;
      h = block.h;
    }
  });

  return blocks.concat({
    i: getBlockKey(),
    x: maxX,
    y: maxY + h,
    w: 3,
    h: 12,
    isResizable: false,
    blockData: getDefaultBlockContentByType(IBlockLayoutType.PlusBlock),
  });
}

export function stripBlockLayoutFields(block: IBlockLayout | undefined | null) {
  if (!block) {
    return {
      blockData: {
        title: getBlockTitle(IBlockLayoutType.EmptySpaceBlock),
        titleVisible: false,
        type: IBlockLayoutType.EmptySpaceBlock,
      },
    };
  }

  return {
    blockData: block.blockData,
  };
}

export function getDefaultBlockContentByType(type: IBlockLayoutType) {
  switch (type) {
    case IBlockLayoutType.Image:
      return {
        type: IBlockLayoutType.Image,
        title: getBlockTitle(IBlockLayoutType.Image),
        titleVisible: false,
        url: "",
        alt: "",
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      };
    case IBlockLayoutType.Advertisement:
      return {
        type: IBlockLayoutType.Advertisement,
        title: getBlockTitle(IBlockLayoutType.Advertisement),
        titleVisible: false,
        url: "",
        alt: "",
        href: "",
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      };
    case IBlockLayoutType.Podcast:
      return {
        type: IBlockLayoutType.Podcast,
        title: getBlockTitle(IBlockLayoutType.Podcast),
        titleVisible: false,
        podcastSrc: "",
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      };
    case IBlockLayoutType.FeaturedStories:
      return {
        type: IBlockLayoutType.FeaturedStories,
        title: getBlockTitle(IBlockLayoutType.FeaturedStories),
        titleVisible: true,
        itemLimit: getDefaultItemLimit(IBlockLayoutType.FeaturedStories),
        storyIds: [],
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      };
    case IBlockLayoutType.NewProjects:
      return {
        type: IBlockLayoutType.NewProjects,
        title: getBlockTitle(IBlockLayoutType.NewProjects),
        titleVisible: true,
        itemLimit: getDefaultItemLimit(IBlockLayoutType.NewProjects),
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      };
    case IBlockLayoutType.StatCounters:
      return {
        type: IBlockLayoutType.StatCounters,
        title: getBlockTitle(IBlockLayoutType.StatCounters),
        titleVisible: true,
        itemLimit: 10,
        labelsColor: "#000000",
        countersColor: "#054799",
        widgetColor: "#ffffff",
        showProjects: true,
        showGovernments: true,
        showProducts: true,
        showStories: false,
        showVendors: false,
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      };
    case IBlockLayoutType.TrendingGovernments:
      return {
        type: IBlockLayoutType.TrendingGovernments,
        title: getBlockTitle(IBlockLayoutType.TrendingGovernments),
        titleVisible: true,
        itemLimit: getDefaultItemLimit(IBlockLayoutType.NewProjects),
        secondTitle: "Top Innovators",
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      };
    case IBlockLayoutType.TrendingCollections:
      return {
        type: IBlockLayoutType.TrendingCollections,
        title: getBlockTitle(IBlockLayoutType.TrendingCollections),
        titleVisible: true,
        itemLimit: getDefaultItemLimit(IBlockLayoutType.TrendingCollections),
        secondTitle: "Top Collections",
        displayAsTags: true,
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      };
    case IBlockLayoutType.ExternalLinks: {
      return {
        type: IBlockLayoutType.ExternalLinks,
        title: getBlockTitle(IBlockLayoutType.ExternalLinks),
        titleVisible: true,
        itemLimit: getDefaultItemLimit(IBlockLayoutType.NewProjects),
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      };
    }
    case IBlockLayoutType.Events: {
      return {
        type: IBlockLayoutType.Events,
        title: getBlockTitle(IBlockLayoutType.Events),
        titleVisible: true,
        itemLimit: getDefaultItemLimit(IBlockLayoutType.Events),
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      };
    }
    default:
      return {
        type,
        title: getBlockTitle(type),
        titleVisible: false,
        backgroundColor: "transparent",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
      };
  }
}

export function createBlockSettings(blockData: TBlockLayoutData) {
  const defaultData = getDefaultBlockContentByType(blockData.type);

  return {
    ...defaultData,
    ...blockData,
  };
}
