import gql from "graphql-tag";

export default gql`
  query GetGroupsCount {
    groupsCount {
      allCount
      archivedCount
      vendorCount
      discussionCount
      communityCount
    }
  }
`;
