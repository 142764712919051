import React from "react";
import { Field } from "react-final-form";
import nullthrows from "../../nullthrows";
import * as palette from "@govlaunch/palette";
import { IProjectNewsworthyItem } from "../../../../../types/types";
import NewsworthyItem from "../../rich/NewsworthyItem";
import fp from "lodash/fp";

export default function NewsworthyField() {
  return (
    <Field name="newsworthy">
      {({ input }) => {
        const newsworthy = nullthrows(input.value);

        if (newsworthy.length === 0) {
          return null;
        }

        return (
          <>
            <h5
              css={{
                margin: 0,
                fontWeight: 600,
                fontSize: 16,
                color: palette.darkGray,
              }}
            >
              Newsworthy
            </h5>

            <div
              css={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: 10,
                rowGap: 10,
              }}
            >
              {input.value.map((newsworthy: IProjectNewsworthyItem) => {
                return (
                  <NewsworthyItem
                    key={newsworthy.url!}
                    newsworthy={newsworthy}
                    onRemove={(newsworthy: IProjectNewsworthyItem) =>
                      input.onChange(fp.reject(newsworthy, input.value))
                    }
                  />
                );
              })}
            </div>
          </>
        );
      }}
    </Field>
  );
}
