import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface IResetContentButtonProps {
  onClick: () => any;
}

export default function HeaderBannerFieldResetButton({ onClick }: IResetContentButtonProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "32px",
        height: "32px",
        "&:hover": {
          opacity: 0.8,
        },
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faRedo}
        color="#aaaaaa"
        style={{
          width: "14px",
          height: "14px",
        }}
        flip="horizontal"
      />
    </div>
  );
}
