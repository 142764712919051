import React from "react";
import EventsTableRows from "./EventsTableRows";
import { IBlockLayout } from "../blockProps";

interface IEventsBlockContentsProps {
  block: IBlockLayout;
}

export default function EventsBlockContents({ block }: IEventsBlockContentsProps) {
  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        fontFamily: "'proxima-nova', Helvetica, Arial, sans-serif",
      }}
    >
      <EventsTableRows block={block} />
    </div>
  );
}
