import { SendOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import getGovlaunchBaseUrl from "../../../utils/getGovlaunchBaseUrl";

interface IPreviewButtonProps {
  configId?: string;
  text?: string;
  saveOnClick?: () => Promise<void>;
}

export default function ListPreviewButton({ configId }: IPreviewButtonProps) {
  return (
    <li>
      <PreviewButton configId={configId} />
    </li>
  );
}

export function PreviewButton({ configId, text = "Preview", saveOnClick, ...props }: IPreviewButtonProps) {
  return (
    <Button
      disabled={!configId}
      size="middle"
      type="link"
      icon={<SendOutlined />}
      htmlType="button"
      onClick={async () => {
        if (!configId) {
          return;
        }

        if (saveOnClick) {
          await saveOnClick();
        }

        window.open(`${getGovlaunchBaseUrl()}/landingPage?configId=${configId}`, "_blank");
      }}
      target="_blank"
      rel="noreferrer noopener"
      {...props}
    >
      {text}
    </Button>
  );
}
