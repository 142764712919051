import gql from "graphql-tag";

export default gql`
  mutation EditReport($id: ObjectId, $report: ReportInput) {
    editReport(id: $id, report: $report) {
      _id
      slug
    }
  }
`;
