import gql from "graphql-tag";

export default gql`
  mutation ToggleReportArchived($id: ObjectId!) {
    toggleReportArchived(id: $id) {
      _id
      archivedAt
    }
  }
`;
