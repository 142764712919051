import { Input, LoadingIndicator, Mobile } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import React, { useRef, useState } from "react";
import { Field } from "react-final-form";
import { INewsworthyItem } from "../../../../types/types";
import PlusIcon from "../../../components/icons/PlusIcon";
import RelatedUrlCard from "../../../components/newsworthy/RelatedUrlCard";
import RelatedUrlCardScrollbars from "../../../components/newsworthy/RelatedUrlCardScrollbars";
import useUnfurl from "../../../hooks/useUnfurl";
import isValidUrl from "../../../utils/isValidUrl";
import prefixWithHttpIfNecessary from "../../../utils/prefixWithHttpIfNecessary";

interface INewsworthyFieldProps {
  fieldName: string;
}

export default function NewsworthyField({ fieldName }: INewsworthyFieldProps) {
  return (
    <Mobile>
      {(isMobile: boolean) => (
        <Field name={fieldName}>
          {({ input }) => (
            <>
              <div>
                <NewsworthyInput
                  onChange={(item) => {
                    // If the URL is already added - skip
                    if (input.value.some(({ url }: IUnfurl) => url === item.url)) {
                      return;
                    }
                    if (input.value && input.value.length > 0) {
                      input.onChange([item].concat(input.value));
                    } else {
                      input.onChange([item]);
                    }
                  }}
                />
              </div>

              {input.value && Array.isArray(input.value) && input.value.length > 0 && (
                <div
                  css={{
                    paddingBottom: 15,
                    display: "grid",
                    position: "relative",
                  }}
                >
                  <RelatedUrlCardScrollbars visible={!isMobile}>
                    <div
                      css={{
                        display: "grid",
                        flexWrap: "wrap",
                        gridRowGap: 16,
                        gridColumnGap: isMobile ? undefined : 16,
                        gridTemplateColumns: isMobile ? undefined : `repeat(${input.value.length}, 320px)`,
                        marginBottom: 10,
                      }}
                    >
                      {input.value.map(({ url, title, siteName, description, favicon }: INewsworthyItem) => {
                        return (
                          <div key={url || ""}>
                            <RelatedUrlCard
                              url={url || ""}
                              title={title || ""}
                              siteName={siteName || ""}
                              description={description || ""}
                              favicon={favicon || ""}
                              onRemove={() => {
                                input.onChange(
                                  input.value.filter(({ url: maybe }: INewsworthyItem) => {
                                    return url !== maybe;
                                  }),
                                );
                              }}
                              style={{
                                width: isMobile ? "100%" : 320,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </RelatedUrlCardScrollbars>
                </div>
              )}
            </>
          )}
        </Field>
      )}
    </Mobile>
  );
}

interface IUnfurl {
  title: string;
  description: string;
  siteName: string;
  favicon: string;
  url: string;
}

interface INewsworthyInputProps {
  onChange: (value: IUnfurl) => any;
}

const NewsworthyInput: React.FunctionComponent<INewsworthyInputProps> = ({ onChange }) => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const [unfurling] = useUnfurl(
    url,
    (unfurl) => {
      (inputRef.current as HTMLInputElement).value = "";
      onChange(unfurl);
      setLoading(false);
    },
    () => {},
  );

  const addItem = () => {
    const url: string = (inputRef.current as HTMLInputElement).value.trim();
    const prefixedUrl = prefixWithHttpIfNecessary(url);

    if (prefixedUrl && isValidUrl(prefixedUrl)) {
      setLoading(true);
      setUrl(prefixedUrl);
    } else {
      setLoading(false);
    }
  };

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        css={{
          position: "relative",
          width: "95%",
        }}
      >
        <Input
          innerRef={inputRef}
          onKeyDown={(event: React.KeyboardEvent) => {
            if (event.key !== "Enter") {
              return;
            }

            event.preventDefault();
            event.stopPropagation();
            addItem();
          }}
          placeholder="Enter a URL..."
          css={{
            backgroundColor: palette.white,
            border: `solid 1px ${palette.lightestGray}`,
            fontSize: 14,
            height: 48,
            paddingLeft: 20,
            paddingRight: 20,
            letterSpacing: 0.5,
            color: palette.mediumGray,
            lineHeight: 1.14,
            outline: "none",
            borderRadius: 4,
            "&:focus": {
              border: `solid 1px ${palette.primary}`,
            },
          }}
        />

        {(unfurling || loading) && (
          <div
            css={{
              position: "absolute",
              top: "50%",
              right: 20,
              transform: "translateY(-50%)",
            }}
          >
            <LoadingIndicator />
          </div>
        )}
      </div>
      <button
        css={{
          width: "5%",
          height: 48,
          textAlign: "center",
          color: palette.sealBlue,
          fontSize: 13,
          cursor: "pointer",
          marginLeft: 10,
          border: `solid 1px ${palette.lightestGray}`,
          borderRadius: 4,
          backgroundColor: palette.white,
          "&:hover": {
            backgroundColor: palette.lightestGray,
          },
          "&:focus": {
            border: `solid 1px ${palette.primary}`,
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        type="button"
        aria-label="Add Newsworthy item"
        onClick={(event) => {
          event.preventDefault();
          addItem();
        }}
      >
        <PlusIcon />
      </button>
    </div>
  );
};
