import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface IPageIconProps {
  icon: IconDefinition;
  flip?: "horizontal" | "vertical" | "both" | undefined;
}

export default function PageIcon({ icon, flip }: IPageIconProps) {
  return (
    <span
      css={{
        marginRight: 5,
      }}
    >
      <FontAwesomeIcon icon={icon} flip={flip} />
    </span>
  );
}
