import gql from "graphql-tag";

export default gql`
  mutation RestoreProjectById($projectId: ObjectId!) {
    restoreProject(projectId: $projectId) {
      _id
      archivedAt
    }
  }
`;
