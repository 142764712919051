import React, { SVGProps } from "react";

const DocumentToolbarIcon = (props: SVGProps<any>) => (
  <svg width={23} height={20} {...props}>
    <g fill="none">
      <path fill="#BCA98A" d="M17.565 1H5.435C4.642 1 4 1.62 4 2.386V9h15V2.386C19 1.621 18.358 1 17.565 1z" />
      <path fill="#E0CFAF" d="M18.373 2H4.626C3.728 2 3 2.776 3 3.733V12h17V3.733C20 2.776 19.272 2 18.373 2z" />
      <path fill="#FFF5D7" d="M19.182 6H3.818C2.814 6 2 6.776 2 7.733V16h19V7.733C21 6.776 20.186 6 19.182 6z" />
      <path
        fill="#BA7E56"
        d="M23 12.592v5.816c0 .88-.662 1.592-1.48 1.592H2.48C1.662 20 1 19.287 1 18.408v-5.816C1 11.712 1.662 11 2.479 11h3.005c.479 0 .898.346 1.023.843l.43 1.714c.126.498.545.843 1.024.843h8.078c.479 0 .898-.345 1.023-.843l.431-1.714c.125-.497.544-.843 1.023-.843h3.005c.817 0 1.479.713 1.479 1.592z"
      />
      <path
        fill="#000"
        d="M22.658 16.875a.342.342 0 00.342-.343v-3.888a1.85 1.85 0 00-1.5-1.818V7.36c0-.875-.507-1.633-1.24-1.995V4.35c0-.828-.508-1.538-1.226-1.835v-.767c0-.964-.781-1.748-1.74-1.748H5.47c-.96 0-1.74.784-1.74 1.748v.767A1.986 1.986 0 002.505 4.35v1.015a2.225 2.225 0 00-1.24 1.995v3.527A1.852 1.852 0 000 12.644v5.506C0 19.17.826 20 1.842 20h19.316C22.174 20 23 19.17 23 18.15v-.246a.342.342 0 10-.683 0v.246c0 .642-.52 1.164-1.16 1.164H1.843a1.162 1.162 0 01-1.16-1.164v-5.506c0-.642.52-1.164 1.159-1.164H4.89c.33 0 .622.224.708.544l.437 1.623A1.42 1.42 0 007.403 14.7h8.195c.638 0 1.2-.432 1.367-1.052l.437-1.623a.735.735 0 01.708-.544h3.048c.639 0 1.159.522 1.159 1.164v3.888c0 .19.153.343.341.343zM3.188 4.35c0-.716.58-1.298 1.292-1.298h1.366a.342.342 0 00.342-.343.342.342 0 00-.342-.343H4.48l-.066.002v-.62A1.06 1.06 0 015.471.686h11.822a1.06 1.06 0 011.057 1.062v.62l-.065-.002H7.213a.342.342 0 000 .686h11.072c.712 0 1.291.582 1.291 1.298v.807a2.208 2.208 0 00-.289-.02H3.477a2.3 2.3 0 00-.289.02V4.35zm13.554 7.495l-.437 1.623a.735.735 0 01-.708.545H7.403a.735.735 0 01-.708-.545l-.437-1.623a1.42 1.42 0 00-1.368-1.051H1.948V7.36c0-.847.686-1.536 1.53-1.536h15.81c.842 0 1.528.689 1.528 1.536v3.434H18.11c-.639 0-1.2.432-1.368 1.051z"
      />
      <path
        fill="#000"
        d="M10.665 8h-6.33C4.15 8 4 8.224 4 8.5s.15.5.335.5h6.33c.185 0 .335-.224.335-.5s-.15-.5-.335-.5zM7.72 9H4.28c-.154 0-.28.224-.28.5s.126.5.28.5h3.44c.154 0 .28-.224.28-.5S7.874 9 7.72 9z"
      />
    </g>
  </svg>
);

export default DocumentToolbarIcon;
