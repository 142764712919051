import gql from "graphql-tag";

export default gql`
  mutation EditNewsletterCampaign($id: ObjectId!, $input: NewsletterCampaignInput!) {
    editNewsletterCampaign(id: $id, input: $input) {
      _id
      name
      dynamicContent {
        storiesCount
        projectsCount
      }
      countries {
        countryCode
        amount
      }
      archivedAt
    }
  }
`;
