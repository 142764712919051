import gql from "graphql-tag";

export default gql`
  query GovernmentsUsingProduct($productSlug: String!, $cursor: Cursor, $search: String) {
    product(slug: $productSlug) {
      _id
      governmentsUsingCount
      governments(limit: 30, cursor: $cursor, search: $search) {
        items {
          _id
          slug
          name
          logo
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;
