import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import FieldValidationError, { hasFieldValidationError } from "../../../components/form/FieldValidationError";
import { Select, Tooltip } from "antd";
import InformationIcon from "../../../components/icons/InformationIcon";
import { ISelectFieldProps } from "./FormFieldProps";

interface IHorizontalSelectFieldProps extends ISelectFieldProps {
  inputWidth?: number;
  tooltip?: string;
}

export default function HorizontalSelectField({
  fieldName,
  label,
  headerColor,
  options,
  inputWidth,
  addOption,
  tooltip,
  placeholder,
  disabled,
  ...props
}: IHorizontalSelectFieldProps) {
  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div
          css={{
            display: "grid",
            gridTemplateColumns: `1fr ${inputWidth}px`,
            rowGap: 8,
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <label
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: headerColor || palette.mediumGray,
              }}
            >
              {label}
            </label>
            {tooltip && (
              <Tooltip title={tooltip}>
                <InformationIcon
                  height="16px"
                  width="16px"
                  fillColor={palette.mediumGray}
                  css={{
                    marginLeft: 4,
                    marginTop: 2,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            )}
          </div>

          <div
            css={{
              borderRadius: 5,
              border: `1px solid ${palette.lightestGray}`,
              ":focus-within": {
                borderColor: palette.primary,
              },
            }}
          >
            <Select
              defaultValue={input.value}
              value={input.value}
              style={{
                borderRadius: 5,
                fontSize: 14,
              }}
              onChange={input.onChange}
              size="large"
              bordered={false}
              placeholder={placeholder}
              {...props}
              disabled={disabled}
            >
              {options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>

          {(hasFieldValidationError(meta) || addOption) && (
            <>
              <div>&nbsp;</div>
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  css={{
                    marginLeft: 2,
                  }}
                >
                  {addOption}
                </div>
                <FieldValidationError meta={meta} />
              </div>
            </>
          )}
        </div>
      )}
    </Field>
  );
}
