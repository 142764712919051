export function ensureFilledString(maybeFilled: string | null) {
  if (!maybeFilled) {
    return "";
  }

  if (maybeFilled.trim().length === 0) {
    return "";
  }

  return maybeFilled;
}

export function ensureFilledArray(maybeFilled: any[] | null) {
  if (!maybeFilled) {
    return [];
  }

  return maybeFilled;
}
