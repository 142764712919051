import { ObservableQuery } from "apollo-client";
import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import {
  IGetGovernmentQuery,
  IGetGovernmentQueryVariables,
} from "../../../queries/__generated__/GetGovernmentQuery.generated";
import ProjectsTable from "../../projects/tables/ProjectsTable";

type TQueryFields = ObservableQuery<IGetGovernmentQuery, IGetGovernmentQueryVariables>;

interface IVerifiedProjectsTabProps {
  government: IGetGovernmentQuery["government"];
  loading: boolean;
  fetchMore: TQueryFields["fetchMore"];
  refetch: TQueryFields["refetch"];
}

export default function VerifiedProjectsTab({ government, refetch, loading, fetchMore }: IVerifiedProjectsTabProps) {
  if (
    !government ||
    !government.verifiedProjects ||
    !government.verifiedProjects.items ||
    !government.verifiedProjects.pageInfo
  ) {
    return null;
  }

  return (
    <div>
      <ProjectsTable enableSorting={false} projects={government.verifiedProjects.items} onDeleteProject={refetch} />

      <VisibilitySensor
        active={Boolean(!loading && government.verifiedProjects.pageInfo.hasNextPage)}
        partialVisibility={true}
        onChange={(isActive) => {
          if (!isActive) {
            return;
          }

          if (
            !government.verifiedProjects ||
            !government.verifiedProjects.pageInfo ||
            !government.verifiedProjects.pageInfo.endCursor
          ) {
            return;
          }

          fetchMore({
            variables: {
              projectsCursor: government.verifiedProjects.pageInfo.endCursor,
            },
            updateQuery: (current, { fetchMoreResult }) => {
              if (
                !current.government ||
                !current.government.verifiedProjects ||
                !current.government.verifiedProjects.items
              ) {
                return current;
              }

              if (
                !fetchMoreResult ||
                !fetchMoreResult.government ||
                !fetchMoreResult.government.verifiedProjects ||
                !fetchMoreResult.government.verifiedProjects.items
              ) {
                return current;
              }

              return {
                __typename: "Query",
                government: {
                  ...current.government,
                  projects: {
                    ...fetchMoreResult.government.verifiedProjects,
                    items: [
                      ...current.government.verifiedProjects.items,
                      ...fetchMoreResult.government.verifiedProjects.items,
                    ],
                  },
                },
              };
            },
          });
        }}
      />
    </div>
  );
}
