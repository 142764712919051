import * as palette from "@govlaunch/palette";

import { A, Div, H2, Span } from "glamorous";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Mutation, Query } from "react-apollo";
import { ToggleArchived, ToggleFeatured, TogglePublished } from "./mutations";

import DocumentTitle from "react-document-title";
import { FORM_ERROR } from "final-form";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import React from "react";
import ReportForm from "./ReportForm";
import moment from "moment";
import GetReport from "../../queries/GetReport";
import { IGetReportQuery, IGetReportQueryVariables } from "../../queries/__generated__/GetReport.generated";
import EditReportMutation from "../../mutations/EditReport";
import { IEditReportMutation, IEditReportMutationVariables } from "../../mutations/__generated__/EditReport.generated";

export default function EditReport() {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <DocumentTitle title="Edit Report | Admin">
      <Query<IGetReportQuery, IGetReportQueryVariables>
        fetchPolicy="network-only"
        variables={{
          slug: params.slug || "",
        }}
        query={GetReport}
      >
        {({ data, loading }) => {
          if (loading || !data) {
            return null;
          }

          const report = data.reportBySlug;

          if (!report) {
            navigate("/reports");
            return null;
          }

          return (
            <DocumentTitle title={`Admin | Edit • ${report.title}`}>
              <>
                <Div padding={40} paddingTop={20} paddingBottom={20} borderBottom="1px solid rgb(232, 232, 232)">
                  <H2 fontWeight={600} fontSize={24} margin={0}>
                    Edit Report
                  </H2>

                  <Div>
                    <Link to="/reports">All Reports</Link>
                  </Div>

                  <Div>
                    <Span
                      fontSize={12}
                      fontWeight={500}
                      textTransform="uppercase"
                      color={report.unpublishedAt ? palette.red : palette.greenSuccess}
                      borderBottom="dashed 1px"
                    >
                      {report.unpublishedAt ? "Unpublished" : "Published"}
                    </Span>
                    {report.featured ? " + " : " • "}
                    {report.featured && (
                      <>
                        <Span fontSize={12} fontWeight={500} textTransform="uppercase" borderBottom="dashed 1px">
                          Featured
                        </Span>
                        {" • "}
                      </>
                    )}
                    <ToggleFeatured>
                      {(toggle) => (
                        <A
                          href="#"
                          textDecoration="none"
                          onClick={(event) => {
                            event.preventDefault();
                            toggle(report);
                          }}
                        >
                          {report.featured ? "Remove featured" : "Set featured"}
                        </A>
                      )}
                    </ToggleFeatured>
                    {" • "}
                    <ToggleArchived>
                      {(toggle) => (
                        <A
                          href="#"
                          color={palette.red}
                          textDecoration="none"
                          onClick={(event) => {
                            event.preventDefault();
                            toggle(report);
                          }}
                        >
                          {report.archivedAt ? "Unarchive" : "Archive"}
                        </A>
                      )}
                    </ToggleArchived>
                    {" • "}
                    <TogglePublished>
                      {(toggle) => (
                        <A
                          href="#"
                          textDecoration="none"
                          color={report.unpublishedAt ? palette.greenSuccess : palette.red}
                          css={{
                            "&:hover": {
                              color: report.unpublishedAt ? palette.greenSuccess : palette.red,
                            },
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            toggle(report);
                          }}
                        >
                          {report.unpublishedAt ? "Publish" : "Unpublish"}
                        </A>
                      )}
                    </TogglePublished>

                    {" • "}
                    <Span color={palette.darkSkyLightBlue} fontSize={12} textTransform="uppercase">
                      {report.updatedAt ? `Updated ${moment(report.updatedAt).fromNow()}` : ""}
                    </Span>
                  </Div>
                </Div>

                <Div padding={40}>
                  <Mutation<IEditReportMutation, IEditReportMutationVariables>
                    onCompleted={({ editReport }) => {
                      if (editReport) {
                        navigate(`/reports/${editReport.slug}`);
                      }
                    }}
                    mutation={EditReportMutation}
                  >
                    {(editReport) => (
                      <ReportForm
                        initialValues={{
                          title: report.title,
                          description: report.description,
                          images: report.images
                            ? report.images.concat(["", "", "", "", "", ""]).slice(0, 6)
                            : ["", "", "", "", "", ""],
                          details: EditorState.createWithContent(convertFromRaw(report.details)),
                          governments: report.governments,
                          locations: report.locations,
                        }}
                        onSubmit={(values) => {
                          return editReport({
                            variables: {
                              id: report._id,
                              report: {
                                title: values.title,
                                description: values.description,
                                images: values.images ? values.images.filter(Boolean) : null,
                                details: convertToRaw(values.details.getCurrentContent()),
                                governments: values.governments
                                  ? values.governments.map((government) => {
                                      return (government as any)._id;
                                    })
                                  : null,
                                locations: values.locations ? values.locations.filter(Boolean) : null,
                              },
                            },
                          })
                            .then(() => null)
                            .catch((error) => {
                              return {
                                [FORM_ERROR]: error.message,
                              };
                            });
                        }}
                        submitButtonText="Edit Report"
                      />
                    )}
                  </Mutation>
                </Div>
              </>
            </DocumentTitle>
          );
        }}
      </Query>
    </DocumentTitle>
  );
}
