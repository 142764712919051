import gql from "graphql-tag";

export default gql`
  mutation ToggleUpvote($targetId: ObjectId!, $targetType: UpvoteTargetType!) {
    toggleUpvote(targetId: $targetId, targetType: $targetType) {
      ... on Product {
        _id
        viewerDidUpvote
        upvotesCount
      }
      ... on Story {
        _id
        viewerDidUpvote
        upvotesCount
      }
      ... on Project {
        _id
        viewerDidUpvote
        upvotesCount
      }
      ... on Comment {
        _id
        viewerDidUpvote
        upvotesCount
      }
      ... on GovernmentPost {
        _id
        viewerDidUpvote
        upvotesCount
      }
      ... on GroupPost {
        _id
        viewerDidUpvote
        upvotesCount
      }
    }
  }
`;
