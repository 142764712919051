import React, { useState } from "react";
import queryString from "query-string";
import { Input } from "antd";
import { useNavigate } from "react-router";

interface ITableSearchProps {
  historyOrigin: string;
  query: queryString.ParsedQuery<string>;
  inputPlaceholder: string;
}

export default function TableSearch({ historyOrigin, query, inputPlaceholder }: ITableSearchProps) {
  const navigate = useNavigate();
  const setSearchTerm = (searchTerm: string) => {
    const nextQuery = queryString.stringify({
      ...query,
      search: searchTerm || undefined,
    });

    navigate(`${historyOrigin}?${nextQuery}`);
  };

  const [search, setSearch] = useState((query.search as string) || "");

  return (
    <Input.Search
      size="large"
      placeholder={inputPlaceholder}
      autoComplete="off"
      allowClear={true}
      onSearch={setSearchTerm}
      style={{
        fontSize: 14,
      }}
      value={search}
      onChange={(event) => {
        setSearch(event.target.value);
      }}
    />
  );
}
