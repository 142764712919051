import { Input } from "@govlaunch/core";
import React, { ChangeEvent } from "react";
import * as palette from "@govlaunch/palette";
import { DEFAULT_PROJECTS_ITEM_LIMIT } from "../blocks/blockProps";

interface IItemLimitInputProps {
  data: any;
  setData: any;
  label?: string;
  subTitle?: string;
  placeholder: string;
  fieldName: string;
}

export default function ItemLimitInput({
  label,
  subTitle,
  placeholder,
  fieldName,
  data,
  setData,
  ...props
}: IItemLimitInputProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
      }}
      {...props}
    >
      {label && (
        <label
          css={{
            fontWeight: 500,
            fontSize: 14,
            color: palette.mediumGray,
          }}
        >
          {label}
        </label>
      )}

      {subTitle && (
        <label
          css={{
            fontWeight: 500,
            fontSize: 12,
            color: palette.mediumGray,
            fontStyle: "italic",
          }}
        >
          {subTitle}
        </label>
      )}

      <Input
        css={{
          marginTop: "8px",
          height: 42,
          borderRadius: 5,
          border: `1px solid ${palette.lightestGray}`,
          fontSize: 14,
          letterSpacing: 0.5,
          fontWeight: 500,
          padding: "5px 10px",
          width: "100%",
          outline: 0,
          "::placeholder": {
            color: palette.sealBlue,
          },
          ":focus": {
            borderColor: palette.primary,
          },
          maxWidth: "120px",
        }}
        placeholder={placeholder}
        value={data[fieldName] || DEFAULT_PROJECTS_ITEM_LIMIT}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setData({
            ...data,
            [fieldName]: Number(event.target.value),
          });
        }}
      />
    </div>
  );
}
