import PropTypes from "prop-types";
import { Component } from "react";
import { hasToken, getToken, setToken, dropToken } from "./auth/token";

interface IChildrenState {
  set: (tokenString: string) => any;
  drop: () => any;
  has: boolean;
  token: string | null;
}

interface IAuthenticationTokenProps {
  children: (arg: IChildrenState) => any;
}

export default class AuthenticationToken extends Component<IAuthenticationTokenProps> {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  state = {
    hasToken: hasToken(),
  };

  handleSet = (tokenString: string) => {
    setToken(tokenString);

    this.setState({
      hasToken: true,
    });
  };

  handleDrop = () => {
    dropToken();

    this.setState({
      hasToken: false,
    });
  };

  render() {
    const { hasToken } = this.state;
    const { children } = this.props;

    return children({
      set: this.handleSet,
      drop: this.handleDrop,
      has: hasToken,
      token: getToken(),
    });
  }
}
