import {
  IBaseTypeaheadItem,
  IMultiTypeaheadProps,
  MultiTypeahead,
  Tag,
  TagAvatar,
  TagCloseButton,
  TagText,
  useAlgoliaSearch,
} from "@govlaunch/web";
import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import FieldValidationError, { hasFieldValidationError } from "../../../../components/form/FieldValidationError";

interface IProductAlgoliaHit {
  objectID: string;
  name: string;
  logo: string;
}

export default function ProductsInvolvedField() {
  const { setSearchState, results } = useAlgoliaSearch<IProductAlgoliaHit>({
    initialSearchState: {
      index: "products",
    },
  });

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setSearchState((current) => ({
      ...current,
      query: inputValue,
    }));
  }, [inputValue]);

  const items: IProductItem[] = results
    ? results.hits.slice(0, 5).map((product) => ({
        value: product.objectID,
        label: product.name,
        logo: product.logo || null,
      }))
    : [];

  return (
    <Field name="products" allowNull={true}>
      {({ input, meta }) => {
        return (
          <>
            <MultiProductSelector
              onChange={input.onChange}
              selectedProducts={input.value}
              products={items}
              menuProps={{
                position: "absolute",
              }}
              inputProps={{
                onBlur: input.onBlur,
                onFocus: input.onFocus,
              }}
              placeholder="Search for products..."
              onInputValueChange={setInputValue}
            />

            {hasFieldValidationError(meta) && (
              <div
                css={{
                  marginTop: 10,
                }}
              >
                <FieldValidationError meta={meta} />
              </div>
            )}
          </>
        );
      }}
    </Field>
  );
}

interface IProductItem {
  value: string;
  label: string;
  logo: string | null;
}

interface IMultiGovernmentSelectorProps<T extends IBaseTypeaheadItem>
  extends Omit<IMultiTypeaheadProps<T>, "renderSelectedItem" | "renderItem" | "items" | "selectedItems"> {
  selectedProducts: T[];
  products: T[];
}

function MultiProductSelector({
  onChange,
  selectedProducts,
  products,
  ...props
}: IMultiGovernmentSelectorProps<IProductItem>) {
  return (
    <MultiTypeahead<IProductItem>
      items={products}
      selectedItems={selectedProducts}
      onChange={onChange}
      renderSelectedItem={({ item, onRemove }) => (
        <Tag selected={true}>
          <TagAvatar name={item.label} src={item.logo} />
          <TagText>{item.label}</TagText> <TagCloseButton onClick={() => onRemove(item)} />
        </Tag>
      )}
      renderItem={({ item, active }) => (
        <Tag active={active}>
          <TagAvatar name={item.label} src={item.logo} />
          <TagText>{item.label}</TagText>
        </Tag>
      )}
      {...props}
    />
  );
}
