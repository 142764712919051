import gql from "graphql-tag";

export default gql`
  mutation ToggleInnovator($entityId: ObjectId, $entityType: String) {
    toggleInnovator(entityId: $entityId, entityType: $entityType) {
      ... on Government {
        _id
        isInnovator
      }
      ... on GovernmentUser {
        _id
        isInnovator
      }
    }
  }
`;
