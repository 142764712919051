import React from "react";
import { useAlgoliaSearch } from "@govlaunch/web";
import Form from "antd/lib/form";
import { Select } from "antd";
import { uniqBy } from "lodash/fp";
import { ITag } from "../../../types/types";

interface ITagAlgoliaHit {
  _id: string;
  objectID: string;
  name: string;
}

interface ICollectionTagSelectProps {
  initialTags: Pick<ITag, "_id" | "name" | "slug">[];
}

export default function CollectionTagSelect({ initialTags }: ICollectionTagSelectProps) {
  const { setSearchState, results } = useAlgoliaSearch<ITagAlgoliaHit>({
    initialSearchState: {
      index: "tags",
      // https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
      // why [[]]? because it's an OR conditional
      facetFilters: [["types:PROJECT", "types:STORY", "types:PRODUCT", "types:CUSTOM", "types:AWARD"]],
    },
  });

  return (
    <Form.Item
      noStyle={true}
      shouldUpdate={(currentValues, nextValues) => currentValues.tags.length !== nextValues.tags.length}
    >
      {({ getFieldValue }) => (
        <Form.Item
          name="tags"
          label="Tags"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            mode="multiple"
            filterOption={false}
            open={getFieldValue("tags").length === 5 ? false : undefined}
            onSearch={(query) => {
              setSearchState((currentState) => ({
                ...currentState,
                query,
              }));
            }}
          >
            {uniqBy(
              "_id",
              (results?.hits || [])
                .map(({ objectID, name }) => ({
                  _id: objectID,
                  name,
                }))
                .concat(initialTags || []),
            ).map((tag) => (
              <Select.Option value={tag._id} title={tag.name} key={tag._id}>
                {tag.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Form.Item>
  );
}
