import PropTypes from "prop-types";
import { getToken } from "./auth/token";

interface IChildrenState {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
}

interface IAdminProfileProps {
  children: (arg: IChildrenState) => any;
}

export default function AdminProfile({ children }: IAdminProfileProps) {
  const jwt = getToken();

  if (!jwt) {
    return null;
  }

  const parts = jwt.split(".");

  if (parts.length !== 3) {
    return null;
  }

  const payload = JSON.parse(window.atob(parts[1]));

  const { firstName, lastName, email } = payload.user;

  return children({
    firstName: firstName,
    lastName: lastName,
    fullName: `${firstName} ${lastName}`.trim(),
    email,
  });
}

AdminProfile.propTypes = {
  children: PropTypes.func.isRequired,
};
