import React from "react";
import { Link } from "react-router-dom";
import ProfilePicture from "@govlaunch/profile-picture";
import { Query } from "react-apollo";
import {
  IGetGovernmentByIdQuery,
  IGetGovernmentByIdQueryVariables,
} from "../../../../queries/__generated__/GetGovernmentById.generated";
import GetGovernmentById from "../../../../queries/GetGovernmentById";
import { Spacing } from "@govlaunch/spacers";

interface IGovernmentByIdProps {
  governmentId: string | null | undefined;
  displayNA: boolean;
}

export default function GovernmentById({ governmentId, displayNA }: IGovernmentByIdProps) {
  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      {governmentId && governmentId.length === 24 && (
        <Query<IGetGovernmentByIdQuery, IGetGovernmentByIdQueryVariables>
          query={GetGovernmentById}
          variables={{
            governmentId: governmentId,
          }}
        >
          {({ data, loading }) => {
            if (loading || !data || !data.governmentById) {
              return <span>N/A</span>;
            }

            return (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ProfilePicture
                  size={28}
                  image={data.governmentById.logo}
                  name={data.governmentById.name}
                  css={{
                    whiteSpace: "nowrap",
                  }}
                />

                <Spacing left={10}>
                  <Link to={`/governments/${data.governmentById.slug}`}>{data.governmentById.name}</Link>
                </Spacing>
              </div>
            );
          }}
        </Query>
      )}
      {displayNA && !governmentId && <span>N/A</span>}
    </div>
  );
}
