import React from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import BlockLayout from "./BlockLayout";
import { Field } from "react-final-form";
import { appendPlusBlock, IBlockLayout, TBlockLayoutData, stripBlockLayoutFields } from "../blocks/blockProps";
import { IBlockLayoutType } from "../../../../types/types";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface IBlocksLayoutFieldProps {
  compact?: boolean;
  allowOverlap?: boolean;
}

export default function BlocksLayoutField({ compact, allowOverlap }: IBlocksLayoutFieldProps) {
  return (
    <Field<IBlockLayout[]> name="blocks">
      {({ input }) => {
        const mergeBlocksById = (array1: IBlockLayout[], array2: Layout[]) => {
          const result = array2.map((item2) => ({
            ...item2,
            ...stripBlockLayoutFields(array1.find((item1) => item2.i === item1.i && item1)),
          }));

          input.onChange(result);
        };

        const onRemoveBlock = (i: string) => {
          input.onChange((input.value || []).filter((block) => block.i !== i));
        };

        const onAddBlock = (block: IBlockLayout) => {
          input.onChange(
            appendPlusBlock(
              (input.value || []).filter((block) => block.blockData.type !== IBlockLayoutType.PlusBlock).concat(block),
            ),
          );
        };

        const saveBlockData = (block: IBlockLayout, blockData: TBlockLayoutData) => {
          const blockIndex = (input.value || []).findIndex((b) => b.i === block.i);

          if (blockIndex === -1) {
            return;
          }

          const blockElements = Array.from(input.value || []);
          blockElements[blockIndex].blockData = blockData;
          input.onChange(blockElements);
        };

        return (
          <ResponsiveGridLayout
            className="layout"
            layouts={{
              lg: input.value || [],
            }}
            breakpoints={{
              lg: 1024,
              md: 768,
              sm: 640,
              xs: 480,
            }}
            cols={{
              lg: 16,
              md: 16,
              sm: 16,
              xs: 16,
            }}
            onLayoutChange={(currentLayout) => {
              mergeBlocksById(input.value || [], currentLayout);
            }}
            rowHeight={8} //px
            margin={[8, 8]}
            compactType={compact ? "vertical" : null}
            allowOverlap={allowOverlap}
          >
            {(input.value || []).map((block) => (
              <div key={block.i}>
                <BlockLayout
                  block={block}
                  onRemoveBlock={onRemoveBlock}
                  onAddBlock={onAddBlock}
                  saveBlockData={saveBlockData}
                />
              </div>
            ))}
          </ResponsiveGridLayout>
        );
      }}
    </Field>
  );
}
