import { Tabs } from "antd";
import queryString from "query-string";
import React from "react";
import DocumentTitle from "react-document-title";
import { IProjectType } from "../../../types/types";
import ProjectsCount from "./ProjectsCount";
import { useLocation, useNavigate } from "react-router";
import { faDraftingCompass } from "@fortawesome/free-solid-svg-icons";
import ProjectsWithSearch from "./tables/ProjectsWithSearch";
import { MetaBaseQuestion } from "../../components/MetaBaseLink";
import TablePageHeader from "../../components/table/TablePageHeader";

const { TabPane } = Tabs;

export default function Projects() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const tab = query.tab || "all";

  return (
    <DocumentTitle title="Projects | Admin">
      <>
        <TablePageHeader
          title="Projects"
          titleIcon={faDraftingCompass}
          subTitle="All Projects"
          metabaseQuestion={MetaBaseQuestion.Projects}
        />
        <ProjectsCount>
          {({
            allCount,
            vendorProjectsCount,
            groupProjectsCount,
            governmentProjectsCount,
            userCreatedCount,
            archivedCount,
            refetch,
          }) => (
            <Tabs
              animated={false}
              onChange={(tab) => {
                navigate(`/projects?tab=${tab}`);
                refetch();
              }}
              activeKey={tab.toString()}
              size="middle"
              tabBarStyle={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 0,
              }}
            >
              <TabPane tab={`All (${allCount})`} key="all">
                {tab === "all" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <ProjectsWithSearch />
                  </div>
                )}
              </TabPane>

              <TabPane tab={`Government (${governmentProjectsCount})`} key="governmentProjects">
                {tab === "governmentProjects" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <ProjectsWithSearch filterByType={IProjectType.GovernmentProject} />
                  </div>
                )}
              </TabPane>

              <TabPane tab={`Group (${groupProjectsCount})`} key="groupProjects">
                {tab === "groupProjects" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <ProjectsWithSearch filterByType={IProjectType.GroupProject} />
                  </div>
                )}
              </TabPane>

              <TabPane tab={`Vendor (${vendorProjectsCount})`} key="vendorProjects">
                {tab === "vendorProjects" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <ProjectsWithSearch filterByType={IProjectType.VendorProject} />
                  </div>
                )}
              </TabPane>

              <TabPane tab={`User-created (${userCreatedCount})`} key="user-created">
                {tab === "user-created" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <ProjectsWithSearch userCreated={true} />
                  </div>
                )}
              </TabPane>

              <TabPane tab={`Archived (${archivedCount})`} key="archived">
                {tab === "archived" && (
                  <div
                    css={{
                      padding: 20,
                    }}
                  >
                    <ProjectsWithSearch archivedOnly={true} />
                  </div>
                )}
              </TabPane>
            </Tabs>
          )}
        </ProjectsCount>
      </>
    </DocumentTitle>
  );
}
