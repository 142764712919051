import gql from "graphql-tag";

export default gql`
  query GetOutsideProducts($search: String, $status: OutsideProductStatus) {
    products: outsideProducts(search: $search, status: $status) {
      items {
        _id
        name
        status
        url
        product {
          _id
          name
          slug
        }
        createdAt
        addedBy {
          ... on PendingUser {
            _id
            fullName
            slug
          }
          ... on GovernmentUser {
            _id
            fullName
            title
            slug
          }
          ... on VendorUser {
            _id
            fullName
            title
            slug
          }
        }
        addedOn {
          ... on Government {
            _id
            slug
            name
          }
          ... on Story {
            _id
            title
            slug
          }
          ... on Product {
            _id
            slug
            name
          }
        }
      }
    }
  }
`;
