import React from "react";
import ProductsTable from "../../products/tables/ProductsTable";
import { IGroupFieldsFragment } from "../../../queries/fragments/__generated__/GroupFields.generated";

interface IGroupProductsTab {
  group: IGroupFieldsFragment;
}

const GroupProductsTab: React.FunctionComponent<IGroupProductsTab> = ({ group }) => {
  return (
    <div
      css={{
        padding: "0 15px",
      }}
    >
      <ProductsTable
        search={null}
        products={group.products}
        renderProductLink={(product) => `/groups/${group.slug}/products/${product.slug}`}
        sorter={false}
      />
    </div>
  );
};

export default GroupProductsTab;
