import React from "react";
import { IAllAboutPageConfigsQuery } from "./graphql/queries/__generated__/AllAboutPageConfigsQuery.generated";
import { Button, notification, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined, PoweroffOutlined, SendOutlined, CopyOutlined } from "@ant-design/icons";
import getGovlaunchBaseUrl from "../../utils/getGovlaunchBaseUrl";
import { useMutation } from "react-apollo";
import RemoveAboutPageConfig from "./graphql/mutations/RemoveAboutPageConfig";
import ToggleAboutPageConfigActivation from "./graphql/mutations/ToggleAboutPageConfigActivation";
import {
  IRemoveAboutPageConfigMutation,
  IRemoveAboutPageConfigMutationVariables,
} from "./graphql/mutations/__generated__/RemoveAboutPageConfig.generated";
import {
  IToggleAboutPageConfigActivationMutation,
  IToggleAboutPageConfigActivationMutationVariables,
} from "./graphql/mutations/__generated__/ToggleAboutPageConfigActivation.generated";
import AllAboutPageConfigsQuery from "./graphql/queries/AllAboutPageConfigsQuery";
import TableRowActions from "../../components/table/TableRowActions";
import DuplicateAboutPageConfig from "./graphql/mutations/DuplicateAboutPageConfig";
import {
  IDuplicateAboutPageConfigMutation,
  IDuplicateAboutPageConfigMutationVariables,
} from "./graphql/mutations/__generated__/DuplicateAboutPageConfig.generated";
import { useNavigate } from "react-router";

interface IAboutPageConfigsListActionsProps {
  configId: any;
  config: IAllAboutPageConfigsQuery["allAboutPageConfigs"][0];
}

export default function AboutPageConfigsListActions({ configId, config }: IAboutPageConfigsListActionsProps) {
  const navigate = useNavigate();
  const [removeConfig] = useMutation<IRemoveAboutPageConfigMutation, IRemoveAboutPageConfigMutationVariables>(
    RemoveAboutPageConfig,
  );

  const [toggleActivation] = useMutation<
    IToggleAboutPageConfigActivationMutation,
    IToggleAboutPageConfigActivationMutationVariables
  >(ToggleAboutPageConfigActivation);

  const [duplicateConfig] = useMutation<IDuplicateAboutPageConfigMutation, IDuplicateAboutPageConfigMutationVariables>(
    DuplicateAboutPageConfig,
  );

  return (
    <TableRowActions>
      {({ onClick }: any) => (
        <>
          <li>
            <Button
              size="middle"
              type="link"
              icon={<EditOutlined />}
              onClick={() => navigate(`/aboutPage/${configId}/edit`)}
            >
              Edit
            </Button>
          </li>
          <li>
            <Button
              size="middle"
              type="link"
              icon={<SendOutlined />}
              htmlType="button"
              onClick={() => {
                window.open(`${getGovlaunchBaseUrl()}/about?previewId=${configId}`, "_blank");
              }}
              target="_blank"
              rel="noreferrer noopener"
            >
              Preview
            </Button>
          </li>
          <li>
            <Button
              size="middle"
              type="link"
              icon={<CopyOutlined />}
              onClick={onClick(() => {
                duplicateConfig({
                  variables: {
                    configId,
                  },
                  refetchQueries: [
                    {
                      query: AllAboutPageConfigsQuery,
                    },
                  ],
                }).then(
                  () => {
                    notification.open({
                      type: "success",
                      message: "Template duplicated!",
                      description: `Template duplicated successfully!`,
                    });
                  },
                  () => {
                    notification.error({
                      type: "error",
                      message: "Duplication failed!",
                      description: `Template duplication was not successful!`,
                    });
                  },
                );
              })}
            >
              Duplicate
            </Button>
          </li>
          <li>
            <Popconfirm
              title="Are you sure?"
              onConfirm={onClick(() => {
                removeConfig({
                  variables: {
                    configId,
                  },
                  refetchQueries: [
                    {
                      query: AllAboutPageConfigsQuery,
                    },
                  ],
                });
              })}
            >
              <Button size="middle" danger={true} type="link" icon={<DeleteOutlined />}>
                Remove
              </Button>
            </Popconfirm>
          </li>
          <li>
            <Popconfirm
              title="Are you sure?"
              onConfirm={onClick(() => {
                toggleActivation({
                  variables: {
                    configId,
                  },
                  refetchQueries: [
                    {
                      query: AllAboutPageConfigsQuery,
                    },
                  ],
                });
              })}
            >
              <Button
                size="middle"
                css={{
                  color: config.active ? "orange" : "green",
                }}
                type="link"
                icon={<PoweroffOutlined />}
              >
                {config.active ? "Set Inactive" : "Set Active"}
              </Button>
            </Popconfirm>
          </li>
        </>
      )}
    </TableRowActions>
  );
}
