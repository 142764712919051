import gql from "graphql-tag";

export default gql`
  query AutoCompleteVendors($searchQuery: String!, $limit: Int!) {
    autoCompleteVendors(searchQuery: $searchQuery, limit: $limit, scopes: []) {
      _id
      name
      slug
      logo
    }
  }
`;
