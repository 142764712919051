export const TIMEZONES = [
  {
    code: "UTC",
    description: "(UTC) Universal Coordinated Time",
  },
  {
    code: "ECT",
    description: "(UTC+01:00) European Central Time",
  },
  {
    code: "EET",
    description: "(UTC+02:00) Eastern European Time",
  },
  {
    code: "ART",
    description: "(UTC+02:00) (Arabic) Egypt Standard Time",
  },
  {
    code: "EAT",
    description: "(UTC+03:00) Eastern African Time",
  },
  {
    code: "MET",
    description: "(UTC+03:30) Middle East Time",
  },
  {
    code: "NET",
    description: "(UTC+04:00) Near East Time",
  },
  {
    code: "PLT",
    description: "(UTC+05:00) Pakistan Lahore Time",
  },
  {
    code: "IST",
    description: "(UTC+05:30) India Standard Time",
  },
  {
    code: "BST",
    description: "(UTC+06:00) Bangladesh Standard Time",
  },
  {
    code: "VST",
    description: "(UTC+07:00) Vietnam Standard Time",
  },
  {
    code: "CTT",
    description: "(UTC+08:00) China Taiwan Time",
  },
  {
    code: "JST",
    description: "(UTC+09:00) Japan Standard Time",
  },
  {
    code: "ACT",
    description: "(UTC+09:30) Australia Central Time",
  },
  {
    code: "AET",
    description: "(UTC+10:00) Australia Eastern Time",
  },
  {
    code: "SST",
    description: "(UTC+11:00) Solomon Standard Time",
  },
  {
    code: "NST",
    description: "(UTC+12:00) New Zealand Standard Time",
  },
  {
    code: "MIT",
    description: "(UTC-11:00) Midway Islands Time",
  },
  {
    code: "HST",
    description: "(UTC-10:00) Hawaii Standard Time",
  },
  {
    code: "AST",
    description: "(UTC-09:00) Alaska Standard Time",
  },
  {
    code: "PST",
    description: "(UTC-08:00) Pacific Standard Time",
  },
  {
    code: "PNT",
    description: "(UTC-07:00) Phoenix Standard Time",
  },
  {
    code: "MST",
    description: "(UTC-07:00) Mountain Standard Time",
  },
  {
    code: "CST",
    description: "(UTC-06:00) Central Standard Time",
  },
  {
    code: "EST",
    description: "(UTC-05:00) Eastern Standard Time",
  },
  {
    code: "IET",
    description: "(UTC-05:00) Indiana Eastern Standard Time",
  },
  {
    code: "PRT",
    description: "(UTC-04:00) Puerto Rico and US Virgin Islands Time",
  },
  {
    code: "CNT",
    description: "(UTC-03:30) Canada Newfoundland Time",
  },
  {
    code: "AGT",
    description: "(UTC-03:00) Argentina Standard Time",
  },
  {
    code: "BET",
    description: "(UTC-03:00) Brazil Eastern Time",
  },
  {
    code: "CAT",
    description: "(UTC-1:00) Central African Time",
  },
];
