import { useCounter } from "react-use";

export default function useShowMoreCounter(total: number, limit: number) {
  const [count, { inc: increment }] = useCounter(1);

  return {
    offset: Math.min(count * limit, total),
    hasMore: count * limit < total,
    showMore: () => increment(1),
  };
}
