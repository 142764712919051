import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { Margin } from "../../../spacings";
import { Select } from "antd";

interface IEditableDropdownFieldProps {
  label: string;
  name: string;
  selectOptions: ISelectOption[];
  showSearch?: boolean;
}

interface ISelectOption {
  label: string;
  value: string;
}

export default function EditableDropdownField({
  name,
  label,
  selectOptions,
  showSearch = false,
}: IEditableDropdownFieldProps) {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <div>
          <div>
            <label
              css={{
                fontWeight: "bold",
                fontSize: 14,
                color: palette.mediumGray,
                margin: 0,
              }}
            >
              {label}
            </label>
          </div>
          <Margin mt={10}>
            <Select
              allowClear={true}
              showSearch={showSearch}
              {...input}
              bordered={false}
              css={{
                margin: 0,
                height: 42,
                fontSize: 14,
                border: `solid 1px ${palette.lightestGray}`,
                borderRadius: 6,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              placeholder={label}
            >
              {selectOptions.map((itemData) => {
                return (
                  <Select.Option value={itemData.value} title={itemData.label} key={itemData.label}>
                    {itemData.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Margin>

          {meta && meta.error && meta.touched && (
            <div
              css={{
                color: palette.red,
                fontSize: 12,
                textTransform: "uppercase",
                marginTop: 4,
              }}
            >
              {meta.error}
            </div>
          )}
        </div>
      )}
    </Field>
  );
}
