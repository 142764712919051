import React, { useState } from "react";
import first from "lodash/fp/first";
import PropTypes from "prop-types";
import { useQuery } from "react-apollo";
import GetProductsQuery from "../../../queries/GetProductsQuery";
import {
  IAllProductsQuery,
  IAllProductsQueryVariables,
} from "../../../queries/__generated__/GetProductsQuery.generated";
import ProductsTable from "./ProductsTable";
import { SortOrder } from "antd/lib/table/interface";
import TableAddStateToQueryString from "../../../components/table/TableAddStateToQueryString";
import { parseState } from "../../../utils/tablesUtils";
import { IProductFilter } from "../../../../types/types";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";
import TablePagination, { DEFAULT_CURSOR, ITablePaginationState } from "../../../components/table/TablePagination";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import TableTitle from "../../../components/table/TableTitle";
import TableOnSearch from "../../../components/table/TableOnSearch";

interface IProductsProps {
  search: string;
  filter: IProductFilter;
  sortBy: string;
  filterByTag?: any;
  historyOrigin?: string;
}

interface IProductsState {
  sortColumnKey: string;
  sortColumnOrder: SortOrder;
  filter: IProductFilter;
  inTiers: number[] | null;
  tag: any;
  cursor: ITablePaginationState;
}

export default function ManageProducts({
  search,
  filter,
  sortBy,
  filterByTag,
  historyOrigin = "/products",
}: IProductsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    sortColumnKey: "",
    sortColumnOrder: "ascend",
    filter: filter,
    inTiers: null,
    tag: filterByTag ? filterByTag : null,
    cursor: DEFAULT_CURSOR,
  });

  const [state, setState] = useState<IProductsState>(initialFiltersState);

  const { data, loading } = useQuery<IAllProductsQuery, IAllProductsQueryVariables>(GetProductsQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      search,
      filter,
      sort: initialFiltersState.sorter ? getSortBy(initialFiltersState.sorter) : "",
      inTiers: initialFiltersState.inTiers || null,
      tags: filterByTag ? [filterByTag] : null,
      cursor: calculateAndEncodeCursor(state.cursor),
    },
  });

  const products = (data && data.products && data.products.items) || [];
  const totalCount = data && data.products && data.products.pageInfo ? data.products.pageInfo.totalCount || 0 : 0;

  return (
    <div>
      {typeof history !== "undefined" && history && (
        <TableAddStateToQueryString tableState={state} historyOrigin={historyOrigin} query={query} />
      )}
      <TableOnSearch
        search={search}
        onSearch={() => {
          if (state.cursor.page !== 1) {
            setState({
              ...state,
              cursor: {
 page: 1, size: state.cursor.size 
},
            });
          }
        }}
      />
      <ProductsTable
        products={products}
        loading={loading}
        filter={filter}
        state={state}
        search={search}
        sortBy={sortBy}
        title={() => (
          <TableTitle
            title="Products"
            count={totalCount}
            loading={loading}
            onResetFilters={() => {
              setState({
                sortColumnKey: "",
                sortColumnOrder: "ascend",
                filter: IProductFilter.All,
                inTiers: null,
                tag: null,
                cursor: {
 page: 1, size: state.cursor.size 
},
              });

              navigate(`${historyOrigin}?tab=${query.tab || ""}&search=${search}`);
            }}
          />
        )}
        footer={() => (
          <TablePagination loading={loading} totalCount={totalCount} tableState={state} setTableState={setState} />
        )}
        onChange={(_: any, filters: any, sorter: any) => {
          setState({
            sortColumnKey: sorter.columnKey,
            sortColumnOrder: sorter.order,
            filter: first(filters.isDisruptor) || IProductFilter.All,
            inTiers:
              filters.tier && Array.isArray(filters.tier) ? filters.tier.map((tier: any) => parseInt(tier, 10)) : null,
            tag: filterByTag ? filterByTag : null,
            cursor: {
 page: 1, size: state.cursor.size 
},
          });
        }}
      />
    </div>
  );
}

ManageProducts.propTypes = {
  search: PropTypes.string,
  filter: PropTypes.string,
  sortBy: PropTypes.string,
};

ManageProducts.defaultProps = {
  search: "",
  filter: "",
  sortBy: "",
};

function getSortBy(sorter: any) {
  if (sorter && sorter.columnKey) {
    const direction = sorter.order === "descend" ? "descending" : "ascending";

    if (sorter.columnKey === "createdAt") {
      return `_id:${direction}`;
    }

    if (sorter.columnKey === "name") {
      return `slug:${direction}`;
    }

    if (sorter.columnKey === "company.name") {
      return `company.slug:${direction}`;
    }

    if (sorter.columnKey === "governmentsUsingCount") {
      return `governmentsUsingCount:${direction}`;
    }

    if (sorter.columnKey === "tier") {
      return `computed.tier:${direction}`;
    }
  }

  return null;
}
