import gql from "graphql-tag";

export default gql`
  mutation SetProductOnGovernmentsStack(
    $addingGovernments: [ObjectId]
    $removingGovernments: [ObjectId]
    $productId: ObjectId
  ) {
    setProductOnGovernmentStack(
      addingGovernments: $addingGovernments
      removingGovernments: $removingGovernments
      productId: $productId
    ) {
      _id
    }
  }
`;
