import { Spacing } from "@govlaunch/spacers";
import React, { useState } from "react";
import { Query } from "react-apollo";
import { Field } from "react-final-form";
import AllGovernmentsQuery from "../../../queries/AllGovernmentsQuery";
import {
  IAllGovernmentsQuery,
  IAllGovernmentsQueryVariables,
} from "../../../queries/__generated__/AllGovernmentsQuery.generated";
import * as palette from "@govlaunch/palette";
import { LoadingIndicator } from "@govlaunch/core";
import ProfilePicture from "@govlaunch/profile-picture";
import { IGovernment } from "../../../../types/types";
import GovernmentsUsingProductQuery from "../../../queries/GovernmentsUsingProductQuery";
import {
  IGovernmentsUsingProductQuery,
  IGovernmentsUsingProductQueryVariables,
} from "../../../queries/__generated__/GovernmentsUsingProductQuery.generated";
import { Margin } from "../../../spacings";

interface IProductGovernmentsProps {
  initialValues?: any;
  productSlug?: string;
}

export default function ProductGovernments({ initialValues, productSlug }: IProductGovernmentsProps) {
  const [search, setSearch] = useState("");

  return (
    <Field name="removingGovernments">
      {({ input: removingInput }) => (
        <Field name="addingGovernments">
          {({ input }) => (
            <Query<IAllGovernmentsQuery, IAllGovernmentsQueryVariables>
              query={AllGovernmentsQuery}
              notifyOnNetworkStatusChange={true}
              fetchPolicy="network-only"
              variables={{
                search,
                filter: null,
                hasOwner: null,
                type: null,
                sortBy: null,
                cursor: null,
                state: null,
                country: null,
                tags: null,
              }}
            >
              {({ data, loading: loadingAllGovernments }) => {
                const governments =
                  loadingAllGovernments || !data || !data.allGovernments || !data.allGovernments.items
                    ? []
                    : data.allGovernments.items.filter(
                        (government) =>
                          government.products &&
                          !government.products.find(({ _id }) => _id === initialValues._id) &&
                          !input.value.find(({ _id }: { _id: string }) => _id === government._id),
                      );

                return (
                  <Spacing top={10}>
                    <div
                      css={{
                        border: `1px solid ${palette.lightestGray}`,
                        borderRadius: 5,
                        backgroundColor: "white",
                      }}
                    >
                      <div
                        css={{
                          borderBottom: `1px solid ${palette.lightestGray}`,
                          padding: 15,
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Search for governments"
                          css={{
                            fontSize: 14,
                            width: "100%",
                            color: palette.sealBlue,
                            background: "#fff",
                            border: 0,
                            outline: 0,
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode == 13) {
                              e.preventDefault();

                              setSearch((e.target as HTMLInputElement).value);
                            }
                          }}
                        />
                      </div>
                      <div
                        css={{
                          padding: 15,
                        }}
                      >
                        {loadingAllGovernments && <LoadingIndicator />}

                        {!loadingAllGovernments && governments.length === 0 && (
                          <p
                            css={{
                              margin: 0,
                              color: palette.darkGray,
                              fontSize: 14,
                            }}
                          >
                            Sorry, no results found
                          </p>
                        )}

                        {!loadingAllGovernments &&
                          governments.map((government) => {
                            return (
                              <div
                                key={government._id}
                                css={{
                                  display: "inline-flex",
                                  marginRight: 15,
                                  marginBottom: 15,
                                  width: 200,
                                }}
                              >
                                <Spacing right={10} inline={true} flex={true}>
                                  <ProfilePicture image={government.logo} name={government.name} size={40} />
                                </Spacing>
                                <div
                                  css={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                    overflow: "hidden",
                                  }}
                                >
                                  <p
                                    css={{
                                      margin: 0,
                                      fontWeight: 500,
                                      color: palette.text,
                                      fontSize: 13,
                                      whiteSpace: "nowrap",
                                      width: "100%",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {government.name}
                                  </p>
                                  <button
                                    css={{
                                      border: 0,
                                      outline: 0,
                                      background: "none",
                                      padding: 0,
                                      fontWeight: 500,
                                      color: palette.greenSuccess,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => input.onChange(input.value.concat(government))}
                                    type="button"
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      {(removingInput.value.length > 0 || input.value.length > 0) && (
                        <div
                          css={{
                            padding: "15px 15px 0",
                            borderTop: `1px solid ${palette.lightestGray}`,
                          }}
                        >
                          <p
                            css={{
                              color: palette.text,
                              margin: "0 0 15px 0",
                            }}
                          >
                            Changes to be committed
                          </p>

                          {input.value.map((government: IGovernment) => {
                            return (
                              <div
                                key={government._id}
                                css={{
                                  display: "inline-flex",
                                  marginRight: 15,
                                  marginBottom: 15,
                                  width: 180,
                                }}
                              >
                                <Spacing right={10} inline={true} flex={true}>
                                  <ProfilePicture image={government.logo} name={government.name} size={40} />
                                </Spacing>
                                <div
                                  css={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                    overflow: "hidden",
                                  }}
                                >
                                  <p
                                    css={{
                                      margin: 0,
                                      fontWeight: 500,
                                      color: palette.text,
                                      fontSize: 13,
                                      whiteSpace: "nowrap",
                                      width: "100%",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {government.name}
                                  </p>
                                  <div>
                                    <span
                                      css={{
                                        fontSize: 12,
                                        color: palette.darkGray,
                                        fontWeight: 500,
                                      }}
                                    >
                                      Added &bull;{" "}
                                    </span>
                                    <button
                                      css={{
                                        border: 0,
                                        outline: 0,
                                        background: "none",
                                        padding: 0,
                                        fontWeight: 500,
                                        color: palette.red,
                                        cursor: "pointer",
                                      }}
                                      type="button"
                                      onClick={() =>
                                        input.onChange(
                                          input.value.filter(({ _id }: { _id: string }) => _id !== government._id),
                                        )
                                      }
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          {removingInput.value.map((government: IGovernment) => {
                            return (
                              <div
                                key={government._id}
                                css={{
                                  display: "inline-flex",
                                  marginRight: 15,
                                  marginBottom: 15,
                                  width: 180,
                                }}
                              >
                                <Spacing right={10} inline={true} flex={true}>
                                  <ProfilePicture image={government.logo} name={government.name} size={40} />
                                </Spacing>
                                <div
                                  css={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                    overflow: "hidden",
                                  }}
                                >
                                  <p
                                    css={{
                                      margin: 0,
                                      fontWeight: 500,
                                      color: palette.text,
                                      fontSize: 13,
                                      whiteSpace: "nowrap",
                                      width: "100%",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {government.name}
                                  </p>
                                  <div>
                                    <span
                                      css={{
                                        fontSize: 12,
                                        color: palette.darkGray,
                                        fontWeight: 500,
                                      }}
                                    >
                                      Removed &bull;{" "}
                                    </span>
                                    <button
                                      css={{
                                        border: 0,
                                        outline: 0,
                                        background: "none",
                                        padding: 0,
                                        fontWeight: 500,
                                        color: palette.greenSuccess,
                                        cursor: "pointer",
                                      }}
                                      type="button"
                                      onClick={() => {
                                        removingInput.onChange(
                                          removingInput.value.filter(
                                            ({ _id }: { _id: string }) => _id !== government._id,
                                          ),
                                        );
                                      }}
                                    >
                                      Put back
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}

                      {productSlug && (
                        <Query<IGovernmentsUsingProductQuery, IGovernmentsUsingProductQueryVariables>
                          query={GovernmentsUsingProductQuery}
                          notifyOnNetworkStatusChange={true}
                          fetchPolicy="network-only"
                          variables={{
                            productSlug,
                            cursor: null,
                            search,
                          }}
                        >
                          {({ loading, data, fetchMore }) => {
                            return (
                              <div
                                css={{
                                  borderTop: `1px solid ${palette.lightestGray}`,
                                }}
                              >
                                <p
                                  css={{
                                    color: palette.text,
                                    margin: 15,
                                  }}
                                >
                                  {data && data.product && <strong>{data.product.governmentsUsingCount}</strong>}{" "}
                                  Governments using this product
                                </p>

                                {loading && (
                                  <Margin ml={15} mb={15}>
                                    <LoadingIndicator />
                                  </Margin>
                                )}

                                {!loading && data && data.product && data.product.governments && (
                                  <>
                                    <Margin ml={15}>
                                      {(data.product.governments.items || [])
                                        .filter(
                                          (government) =>
                                            !removingInput.value.find(
                                              ({ _id }: { _id: string }) => _id === government._id,
                                            ),
                                        )
                                        .map((government) => {
                                          return (
                                            <div
                                              key={government._id}
                                              css={{
                                                display: "inline-flex",
                                                marginRight: 15,
                                                marginBottom: 15,
                                                width: 200,
                                              }}
                                            >
                                              <Spacing right={10} inline={true} flex={true}>
                                                <ProfilePicture
                                                  image={government.logo}
                                                  name={government.name}
                                                  size={40}
                                                />
                                              </Spacing>
                                              <div
                                                css={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  justifyContent: "center",
                                                  alignItems: "flex-start",
                                                  overflow: "hidden",
                                                }}
                                              >
                                                <p
                                                  css={{
                                                    margin: 0,
                                                    fontWeight: 500,
                                                    color: palette.text,
                                                    fontSize: 13,
                                                    whiteSpace: "nowrap",
                                                    width: "100%",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {government.name}
                                                </p>
                                                <button
                                                  css={{
                                                    border: 0,
                                                    outline: 0,
                                                    background: "none",
                                                    padding: 0,
                                                    fontWeight: 500,
                                                    color: palette.red,
                                                    cursor: "pointer",
                                                  }}
                                                  type="button"
                                                  onClick={() => {
                                                    removingInput.onChange(removingInput.value.concat(government));
                                                  }}
                                                >
                                                  Remove
                                                </button>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </Margin>

                                    {!loading &&
                                      data.product.governments.pageInfo &&
                                      data.product.governments.pageInfo.hasNextPage && (
                                        <div>
                                          <button
                                            onClick={() => {
                                              fetchMore({
                                                variables: {
                                                  search,
                                                  cursor:
                                                    data &&
                                                    data.product &&
                                                    data.product.governments &&
                                                    data.product.governments.pageInfo &&
                                                    data.product.governments.pageInfo.endCursor,
                                                },
                                                updateQuery: (current: any, { fetchMoreResult }: any) => {
                                                  return {
                                                    ...current,
                                                    product: {
                                                      ...current.product,
                                                      governments: {
                                                        ...fetchMoreResult.product.governments,
                                                        items: current.product.governments.items.concat(
                                                          fetchMoreResult.product.governments.items,
                                                        ),
                                                      },
                                                    },
                                                  };
                                                },
                                              });
                                            }}
                                            type="button"
                                            css={{
                                              width: "100%",
                                              padding: "15px 0",
                                              textAlign: "center",
                                              textTransform: "uppercase",
                                              border: 0,
                                              borderTop: `1px solid ${palette.lightestGray}`,
                                              color: palette.sealBlue,
                                              fontWeight: 500,
                                              fontSize: 13,
                                              disabled: loading ? loading : undefined,
                                              cursor: "pointer",
                                            }}
                                          >
                                            {loading ? <LoadingIndicator /> : "Show More"}
                                          </button>
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            );
                          }}
                        </Query>
                      )}
                    </div>
                  </Spacing>
                );
              }}
            </Query>
          )}
        </Field>
      )}
    </Field>
  );
}
