import gql from "graphql-tag";
import SolutionFieldsFragment from "../fragments/SolutionFieldsFragment";

export default gql`
  mutation ApproveSolution($solutionId: ObjectId!) {
    approveSolution(solutionId: $solutionId) {
      ...SolutionFields
    }
  }

  ${SolutionFieldsFragment}
`;
