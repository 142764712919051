import gql from "graphql-tag";

export default gql`
  mutation TogglePublishedOnCollection($collectionId: ObjectId!) {
    togglePublishedOnCollection(collectionId: $collectionId) {
      _id
      publishedAt
    }
  }
`;
