import React from "react";
import { Field } from "react-final-form";
import * as palette from "@govlaunch/palette";
import { Margin } from "../../../spacings";
import SocialMedialUrlInput from "./SocialMedialUrlInput";
import FieldValidationError from "../../../components/form/FieldValidationError";

interface ISocialMediaFieldProps {
  label: string;
  fieldName: string;
  placeholder: string;
  icon: React.ReactNode;
}

export default function SocialMediaField({ label, fieldName, placeholder, icon }: ISocialMediaFieldProps) {
  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              css={{
                marginRight: 8,
              }}
            >
              {icon}
            </span>

            <label
              css={{
                fontWeight: 500,
                fontSize: 14,
                color: palette.mediumGray,
                margin: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              {label}
            </label>

            <FieldValidationError meta={meta} />
          </div>
          <Margin mt={8}>
            <SocialMedialUrlInput placeholder={placeholder} input={input} />
          </Margin>
        </div>
      )}
    </Field>
  );
}
