import ProfilePicture from "@govlaunch/profile-picture";
import { AutoComplete, Input } from "antd";
import debounce from "lodash/debounce";
import React, { useState } from "react";
import { Query } from "react-apollo";
import { IUserSearchScope } from "../../../types/types";
import AutoCompleteUsers from "./graphql/queries/AutoCompleteUsers";
import {
  IAutoCompleteUsersQuery,
  IAutoCompleteUsersQueryVariables,
} from "./graphql/queries/__generated__/AutoCompleteUsers.generated";

export type TSelectedUser = NonNullable<IAutoCompleteUsersQuery["autoCompleteUsers"]>[0];

interface IUserAutoCompleteProps {
  scopes?: IUserSearchScope[];
  onSelect: (selectedUser: TSelectedUser) => void;
}

export default function UserAutoComplete({ onSelect, scopes = [] }: IUserAutoCompleteProps) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");

  const onSearch = debounce((searchText) => setSearchQuery(searchText), 300);

  return (
    <Query<IAutoCompleteUsersQuery, IAutoCompleteUsersQueryVariables>
      query={AutoCompleteUsers}
      notifyOnNetworkStatusChange={true}
      fetchPolicy="network-only"
      skip={searchQuery === ""}
      variables={{
        searchQuery,
        scopes,
        limit: 8,
      }}
    >
      {({ loading, data }) => {
        const searchResultsArray = !data || !data.autoCompleteUsers ? [] : data.autoCompleteUsers;

        const options = searchResultsArray.map((item) => {
          return {
            value: item._id,
            label: (
              <div
                css={{
                  display: "inline-grid",
                  gridColumnGap: 12,
                  gridTemplateColumns: "repeat(2, max-content)",
                  alignItems: "center",
                }}
              >
                <ProfilePicture size={32} image={item.avatar} name={item.fullName} />

                <div>
                  <div>
                    <strong>{item.fullName}</strong>
                  </div>

                  <div>
                    <span
                      css={{
                        fontSize: 13,
                      }}
                    >
                      {item.email}
                    </span>
                  </div>
                </div>
              </div>
            ),
          };
        });

        return (
          <AutoComplete
            options={searchQuery === "" ? [] : options}
            onSearch={onSearch}
            onSelect={(selectedUserId: any) => {
              const selectedUser = searchResultsArray.find((candidate) => {
                return candidate._id === selectedUserId;
              });

              if (selectedUser) {
                onSelect(selectedUser);
                setSearchQuery("");
                setInputValue("");
              }
            }}
            value={inputValue}
          >
            <Input.Search
              size="large"
              spellCheck={false}
              loading={loading}
              placeholder="Type to search users..."
              onChange={(e) => setInputValue(e.target.value)}
            />
          </AutoComplete>
        );
      }}
    </Query>
  );
}
