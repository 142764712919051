import { DeleteOutlined } from "@ant-design/icons";
import ProfilePicture from "@govlaunch/profile-picture";
import { Button, Tag } from "antd";
import Table, { TablePaginationConfig } from "antd/lib/table";
import Column from "antd/lib/table/Column";
import React from "react";
import { Field } from "react-final-form";
import { Link } from "react-router-dom";
import { TGroup, TGroupVendor } from "./types";

interface IEditableGroupVendorsProps {
  group: TGroup;
}

export default function EditableGroupVendors({ group }: IEditableGroupVendorsProps) {
  const vendors = group.vendors || [];
  const vendorIds = vendors.map(({ _id }) => _id);

  return (
    <Field<TGroupVendor[]> name="vendors">
      {({ input }) => {
        let paginationConfig: TablePaginationConfig | false = false;

        if (vendors.length > 10) {
          paginationConfig = {
            pageSize: 10,
          };
        }

        return (
          <Table dataSource={input.value} bordered={true} size="middle" rowKey="_id" pagination={paginationConfig}>
            <Column<TGroupVendor>
              title="Name"
              dataIndex="name"
              render={(_, vendor) => (
                <div
                  css={{
                    display: "inline-grid",
                    gridColumnGap: 12,
                    gridTemplateColumns: "repeat(3, max-content)",
                    alignItems: "center",
                  }}
                >
                  <ProfilePicture size={28} image={vendor.logo} name={vendor.name} />

                  <Link to={`/vendors/${vendor.slug}`}>
                    <span>{vendor.name}</span>
                  </Link>

                  {!vendorIds.includes(vendor._id) && <Tag color="magenta">New</Tag>}
                </div>
              )}
            />

            <Column<TGroupVendor>
              title="Remove"
              dataIndex="_id"
              width="20%"
              align="center"
              render={(_, vendor) => {
                return (
                  <Button
                    danger={true}
                    icon={<DeleteOutlined />}
                    shape="round"
                    onClick={() => {
                      const vendors = input.value as TGroupVendor[];
                      const filtered = vendors.filter((candidate) => candidate._id !== vendor._id);

                      input.onChange(filtered);
                    }}
                  >
                    Remove
                  </Button>
                );
              }}
            />
          </Table>
        );
      }}
    </Field>
  );
}
