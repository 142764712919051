import { Alert, Input } from "antd";
import Form from "antd/lib/form";
import Modal from "antd/lib/modal/Modal";
import React, { ReactElement, useState } from "react";
import { useMutation } from "react-apollo";
import UpdateSolutionNameMutation from "./graphql/mutations/UpdateSolutionNameMutation";
import {
  IUpdateSolutionNameMutation,
  IUpdateSolutionNameMutationVariables,
} from "./graphql/mutations/__generated__/UpdateSolutionNameMutation.generated";
import { IAllSolutionsQuery } from "./graphql/queries/__generated__/AllSolutionsQuery.generated";

interface IEditSolutionNameChildrenState {
  setSolution: (solution: IAllSolutionsQuery["allSolutions"][0]) => void;
}

interface IEditSolutionNameProps {
  children: (state: IEditSolutionNameChildrenState) => ReactElement;
}

const EditSolutionName = ({ children }: IEditSolutionNameProps) => {
  const [form] = Form.useForm();
  const [solution, setSolution] = useState<IAllSolutionsQuery["allSolutions"][0] | null>(null);

  const [updateSolutionName, { error }] = useMutation<
    IUpdateSolutionNameMutation,
    IUpdateSolutionNameMutationVariables
  >(UpdateSolutionNameMutation, {
    onError: () => {},
    onCompleted: () => setSolution(null),
  });

  return (
    <>
      {solution && (
        <Modal title="Edit Solution" visible={true} onOk={() => form.submit()} onCancel={() => setSolution(null)}>
          <Form
            form={form}
            layout="vertical"
            name="updateSolution"
            onFinish={async () => {
              const values = form.getFieldsValue(true);

              await updateSolutionName({
                variables: {
                  solutionId: solution._id,
                  name: values.name,
                },
              });

              return null;
            }}
            initialValues={{
              name: solution.name,
            }}
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
              label="Name"
              name="name"
            >
              <Input />
            </Form.Item>
          </Form>

          {error && (
            <div
              css={{
                marginTop: 12,
              }}
            >
              <Alert
                message="Error"
                description={error?.message.replace("GraphQL error:", "").trim()}
                type="error"
                showIcon={true}
              />
            </div>
          )}
        </Modal>
      )}

      {children({
        setSolution,
      })}
    </>
  );
};

export default EditSolutionName;
