/* eslint-disable react/display-name */
import * as palette from "@govlaunch/palette";
import { Mutation, Query } from "react-apollo";
import { Popover, Table } from "antd";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { Toggle } from "react-powerplug";
import moment from "moment";
import { upperFirst } from "lodash/fp";
import GetReportsQuery from "../../queries/GetReportsQuery";
import { IGetReportsQuery, IGetReportsQueryVariables } from "../../queries/__generated__/GetReportsQuery.generated";
import ToggleFeturedReport from "../../mutations/ToggleFeaturedReport";
import {
  IToggleFeaturedReportMutation,
  IToggleFeaturedReportMutationVariables,
} from "../../mutations/__generated__/ToggleFeaturedReport.generated";
import ToggleReportArchived from "../../mutations/ToggleReportArchived";
import {
  IToggleReportArchivedMutation,
  IToggleReportArchivedMutationVariables,
} from "../../mutations/__generated__/ToggleReportArchived.generated";
import ToggleReportPublished from "../../mutations/ToggleReportPublished";
import {
  IToggleReportPublishedMutation,
  IToggleReportPublishedMutationVariables,
} from "../../mutations/__generated__/ToggleReportPublished.generated";
import PageIcon from "../../icons/PageIcon";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import DotdotdotIcon from "../../components/icons/DotdotdotIcon";

export default function Reports() {
  return (
    <DocumentTitle title="Reports | Admin">
      <div css={{
overflow: "auto"
}}
      >
        <div
          css={{
            padding: 15,
          }}
        >
          <h3
            css={{
              fontWeight: 700,
              fontSize: 24,
              margin: 0,
            }}
          >
            <PageIcon icon={faChartBar} /> Reports
          </h3>

          <Link to="/reports/new">New Report</Link>
        </div>

        <Query<IGetReportsQuery, IGetReportsQueryVariables> fetchPolicy="network-only" query={GetReportsQuery}>
          {({ data, loading }) => {
            if (loading || !data || !data.allReports || !data.allReports.items) {
              return null;
            }

            return (
              <Table
                bordered={true}
                rowKey="_id"
                loading={loading}
                dataSource={data.allReports.items}
                columns={[
                  {
                    title: "Title",
                    dataIndex: "slug",
                    filters: [
                      {
                        text: "Featured?",
                        value: "featured",
                      },
                    ],
                    onFilter: (value, record) => {
                      if (value === "featured") {
                        return Boolean(record.featured);
                      } else {
                        return true;
                      }
                    },
                    sorter: (a, b) => a.title.localeCompare(b.title),
                    render: (slug, report) => {
                      return (
                        <Link to={`/reports/${slug}`}>{report.featured ? <>{report.title} 🌟</> : report.title}</Link>
                      );
                    },
                  },
                  {
                    title: "Description",
                    dataIndex: "description",
                    render: (description) => {
                      if (description.length > 60) {
                        return `${description.slice(0, 60)}...`;
                      }

                      return description;
                    },
                  },
                  {
                    title: "Status",
                    dataIndex: "unpublishedAt",
                    filters: [
                      {
                        text: "Published",
                        value: "published",
                      },
                      {
                        text: "Unpublished",
                        value: "unpublished",
                      },
                    ],
                    filterMultiple: false,
                    onFilter: (value, report) => {
                      if (value === "published") {
                        return report.unpublishedAt === null;
                      } else if (value === "unpublished") {
                        return report.unpublishedAt !== null;
                      }

                      return true;
                    },
                    render: (unpublishedAt) => {
                      if (unpublishedAt) {
                        return (
                          <span
                            css={{
                              color: palette.red,
                              display: "block",
                              minWidth: 85,
                            }}
                          >
                            Unpublished
                          </span>
                        );
                      } else {
                        return (
                          <span
                            css={{
                              color: palette.greenSuccess,
                              display: "block",
                              minWidth: 85,
                            }}
                          >
                            Published
                          </span>
                        );
                      }
                    },
                  },
                  {
                    title: "Location",
                    dataIndex: "locations",
                    filters: [
                      {
                        text: "Govlaunch",
                        value: "govlaunch",
                      },
                      {
                        text: "Innovators",
                        value: "innovators",
                      },
                      {
                        text: "Both",
                        value: "both",
                      },
                    ],
                    filterMultiple: false,
                    onFilter: (value, report) => {
                      if (!report || !report.locations) {
                        return false;
                      }

                      if (value === "both") {
                        return report.locations.length === 2;
                      }

                      if (report.locations.length === 1) {
                        if (value === "govlaunch") {
                          return report.locations.includes("govlaunch");
                        } else if (value === "innovators") {
                          return report.locations.includes("innovators");
                        }
                      }

                      return false;
                    },
                    render: (locations) => {
                      return locations.map(upperFirst).join(" and ");
                    },
                  },
                  {
                    title: "Created",
                    dataIndex: "createdAt",
                    render: (createdAt) => {
                      return moment(createdAt).fromNow();
                    },
                  },
                  {
                    title: "Actions",
                    render: (_, report) => {
                      return <ReportActions report={report} />;
                    },
                  },
                ]}
                pagination={false}
              />
            );
          }}
        </Query>
      </div>
    </DocumentTitle>
  );
}

function ReportActions({ report }: any) {
  const isPublished = report.unpublishedAt === null;

  return (
    <Toggle>
      {({ on, toggle }) => (
        <Popover
          visible={on}
          trigger="click"
          title="Report Actions"
          placement="rightBottom"
          onVisibleChange={toggle}
          content={
            <ul
              css={{
                margin: 0,
                padding: 0,
                listStyle: "none",
                "> li:not(:first-of-type)": {
                  marginTop: 10,
                },
              }}
            >
              <li>
                <Link to={`/reports/${report.slug}/edit`}>Edit</Link>
              </li>

              <Mutation<IToggleFeaturedReportMutation, IToggleFeaturedReportMutationVariables>
                mutation={ToggleFeturedReport}
              >
                {(toggleFeatured) => (
                  <li>
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        toggleFeatured({
                          optimisticResponse: {
                            __typename: "Mutation",
                            toggleFeatured: {
                              __typename: "Report",
                              _id: report._id,
                              featured: !report.featured,
                            },
                          },
                          variables: {
                            id: report._id,
                          },
                        });
                      }}
                    >
                      {report.featured ? "Remove featured" : "Set featured"}
                    </a>
                  </li>
                )}
              </Mutation>

              <Mutation<IToggleReportArchivedMutation, IToggleReportArchivedMutationVariables>
                mutation={ToggleReportArchived}
              >
                {(toggleArchived) => (
                  <li>
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();

                        toggleArchived({
                          refetchQueries: [
                            {
                              query: GetReportsQuery,
                            },
                          ],
                          variables: {
                            id: report._id,
                          },
                        });
                      }}
                      color={palette.red}
                      css={{
                        "&:hover": {
                          color: palette.red,
                        },
                      }}
                    >
                      Archive
                    </a>
                  </li>
                )}
              </Mutation>

              <Mutation<IToggleReportPublishedMutation, IToggleReportPublishedMutationVariables>
                mutation={ToggleReportPublished}
              >
                {(togglePublished) => (
                  <li>
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        togglePublished({
                          optimisticResponse: {
                            __typename: "Mutation",
                            toggleReportPublished: {
                              __typename: "Report",
                              _id: report._id,
                              unpublishedAt: new Date().toISOString(),
                            },
                          },
                          variables: {
                            id: report._id,
                          },
                        });
                      }}
                      color={isPublished ? palette.red : palette.greenSuccess}
                      css={{
                        "&:hover": {
                          color: isPublished ? palette.red : palette.greenSuccess,
                        },
                      }}
                    >
                      {isPublished ? "Unpublish" : "Publish"}
                    </a>
                  </li>
                )}
              </Mutation>
            </ul>
          }
        >
          <span
            role="button"
            css={{
              cursor: "pointer",
            }}
          >
            <DotdotdotIcon />
          </span>
        </Popover>
      )}
    </Toggle>
  );
}

ReportActions.propTypes = {
  report: PropTypes.object.isRequired,
};
