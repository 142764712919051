import React from "react";
import { useMutation } from "react-apollo";
import CreateProjectForGovernment from "../../mutations/CreateProjectForGovernment";
import {
  ICreateProjectForGovernmentMutation,
  ICreateProjectForGovernmentMutationVariables,
} from "../../mutations/__generated__/CreateProjectForGovernment.generated";
import CreateProjectForGroup from "../../mutations/CreateProjectForGroup";
import {
  ICreateProjectForGroupMutation,
  ICreateProjectForGroupMutationVariables,
} from "../../mutations/__generated__/CreateProjectForGroup.generated";
import CreateProjectForVendor from "../../mutations/CreateProjectForVendor";
import {
  ICreateProjectForVendorMutation,
  ICreateProjectForVendorMutationVariables,
} from "../../mutations/__generated__/CreateProjectForVendor.generated";
import { Form } from "react-final-form";
import { defaultIcons } from "./projectIcons";
import * as Yup from "yup";
import validate from "../../validate";
import { get } from "lodash/fp";
import convertProjectToVariables from "./utils/convertProjectToVariables";
import { FORM_ERROR } from "final-form";
import ProjectForm from "./ProjectForm";
import { notification } from "antd";
import * as palette from "@govlaunch/palette";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";

export default function NewProjectForm() {
  const navigate = useNavigate();

  const [createProjectForGovernment] = useMutation<
    ICreateProjectForGovernmentMutation,
    ICreateProjectForGovernmentMutationVariables
  >(CreateProjectForGovernment);

  const [createProjectForGroup] = useMutation<ICreateProjectForGroupMutation, ICreateProjectForGroupMutationVariables>(
    CreateProjectForGroup,
  );

  const [createProjectForVendor] = useMutation<
    ICreateProjectForVendorMutation,
    ICreateProjectForVendorMutationVariables
  >(CreateProjectForVendor);

  return (
    <div
      css={{
        padding: 15,
      }}
    >
      <div
        css={{
          paddingBottom: 8,
        }}
      >
        <a
          css={{
            color: palette.primary,
            cursor: "pointer",
          }}
          onClick={() => navigate("/projects")}
        >
          <span css={{
 marginRight: 5 
}}
          >
            <FontAwesomeIcon icon={faLessThan} size="sm" />
          </span>
          All Projects
        </a>
      </div>

      <Form
        keepDirtyOnReinitialize={true}
        initialValues={{
          government: null,
          group: null,
          vendor: null,
          audience: "community",
          entity: "government",
          icon: defaultIcons[0],
          newsworthy: [],
          products: [],
          tags: [],
          awards: [],
          attachments: [],
          links: [],
          status: null,
          vendors: [],
          outcome: null,
          outcomeStatement: "",
        }}
        validate={validate(projectSchema)}
        onSubmit={async (values) => {
          try {
            if (values.entity === "group" && values.group) {
              const mutationResult = await createProjectForGroup({
                variables: {
                  group: get("group._id", values),
                  project: convertProjectToVariables(values),
                },
              });

              if (
                mutationResult &&
                mutationResult.data &&
                mutationResult.data.createProjectForGroup &&
                mutationResult.data.createProjectForGroup._id
              ) {
                notification.success({
                  message: "Success!",
                  description: "Project created successfully",
                });

                navigate(`/projects`);
              }
            }

            if (values.entity === "government" && values.government) {
              const mutationResult = await createProjectForGovernment({
                variables: {
                  government: get("government._id", values),
                  project: convertProjectToVariables(values),
                },
              });

              if (
                mutationResult &&
                mutationResult.data &&
                mutationResult.data.createProjectForGovernment &&
                mutationResult.data.createProjectForGovernment._id
              ) {
                notification.success({
                  message: "Success!",
                  description: "Project created successfully",
                });

                navigate(`/projects`);
              }
            }

            if (values.entity === "vendor" && values.vendor) {
              const mutationResult = await createProjectForVendor({
                variables: {
                  vendor: get("vendor._id", values),
                  project: convertProjectToVariables(values),
                },
              });

              if (
                mutationResult &&
                mutationResult.data &&
                mutationResult.data.createProjectForVendor &&
                mutationResult.data.createProjectForVendor._id
              ) {
                notification.success({
                  message: "Success!",
                  description: "Project created successfully",
                });

                navigate(`/projects`);
              }
            }
          } catch (e) {
            if (/You can select up to 6 tags/i.test(e.message)) {
              return {
                [FORM_ERROR]: "You can select up to 6 tags",
              };
            }

            return {
              [FORM_ERROR]: "Couldn't save, try again later.",
            };
          }
        }}
      >
        {({ submitting, handleSubmit, submitError }) => (
          <ProjectForm submitting={submitting} handleSubmit={handleSubmit} submitError={submitError} />
        )}
      </Form>
    </div>
  );
}

const projectSchema = Yup.object().shape({
  title: Yup.string().max(100, "Oops! Your title needs to be more concise.").required("Can't be blank"),
  description: Yup.string().max(500, "Oops! Your description needs to be more concise.").required("Can't be blank"),
  tags: Yup.array().min(1, "Please select at least one tag"),
  government: Yup.object()
    .nullable()
    .when("entity", {
      is: "government",
      then: (schema: any) => schema.required("Can't be blank"),
      otherwise: (schema: any) => schema.nullable(),
    }),
  group: Yup.object()
    .nullable()
    .when("entity", {
      is: "group",
      then: (schema: any) => schema.required("Can't be blank"),
      otherwise: (schema: any) => schema.nullable(),
    }),
  vendor: Yup.object()
    .nullable()
    .when("entity", {
      is: "vendor",
      then: (schema: any) => schema.required("Can't be blank"),
      otherwise: (schema: any) => schema.nullable(),
    }),
});
