export const LANGUAGES = [
  {
    code: "en-US",
    language: "English (United States)",
  },
  {
    code: "pt-BR",
    language: "Portuguese (Brazil)",
  },
  {
    code: "bg",
    language: "Bulgarian",
  },
];
