import gql from "graphql-tag";

export default gql`
  mutation AddSponsorship($sponsorship: SponsorshipInput!) {
    addSponsorship(sponsorship: $sponsorship) {
      _id
      slug
      name
      logo
      link
    }
  }
`;
