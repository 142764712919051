import React, { ReactElement } from "react";
import { Box, Flex, SvgBox } from "@govlaunch/web";

interface IStatCounterWidgetProps {
  count: number;
  countColor: string;
  label: string;
  labelColor: string;
  widgetColor: string;
  icon?: ReactElement | null;
}

export default function StatCounterWidget({
  count,
  countColor,
  label,
  labelColor,
  widgetColor,
  icon,
}: IStatCounterWidgetProps) {
  return (
    <Box
      width={["100%", "100%", "100%", "100%"]}
      height={["100%", "100%", "150px", "150px"]}
      background={widgetColor}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="4px"
      position="relative"
      cursor="pointer"
    >
      {icon && (
        <Box position="absolute" top={["4px", "4px", "8px"]} left={["4px", "4px", "8px"]}>
          {icon}
        </Box>
      )}

      <SvgBox
        width="9px"
        height="22px"
        viewBox="0 0 9 22"
        fill="none"
        position="absolute"
        right={2}
        top={["50%", "50%", "42.5%"]}
        transform="translateY(-50%)"
      >
        <path
          d="M1.88867 19.75L6.77756 11.2244L1.88867 2.25"
          stroke="#086BE5"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgBox>
      <Flex width="100%" height="100%" flexDirection="column" alignItems="center" justifyContent="center">
        <Box fontWeight={700} fontSize="32px" lineHeight="40px" color={countColor}>
          {count}
        </Box>
        <Box fontWeight={400} fontSize="16px" lineHeight="21px" color={labelColor}>
          {label}
        </Box>
      </Flex>
    </Box>
  );
}
