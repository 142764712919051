import React from "react";
import { Form, Button, Popconfirm, Input, notification } from "antd";
import DocumentTitle from "react-document-title";
import { useMutation, useQuery } from "react-apollo";
import {
  IResetPodcastPageConfigMutation,
  IResetPodcastPageConfigMutationVariables,
} from "../../mutations/__generated__/ResetPodcastPageConfig.generated";
import ResetPodcastPageConfig from "../../mutations/ResetPodcastPageConfig";
import omit from "lodash/fp/omit";
import {
  IPodcastPageConfigQuery,
  IPodcastPageConfigQueryVariables,
} from "../../queries/__generated__/PodcastPageConfigQuery.generated";
import PodcastPageConfigQuery from "../../queries/PodcastPageConfigQuery";
import getGovlaunchBaseUrl from "../../utils/getGovlaunchBaseUrl";
import {
  ISetPodcastPageConfigMutation,
  ISetPodcastPageConfigMutationVariables,
} from "../../mutations/__generated__/SetPodcastPageConfig.generated";
import SetPodcastPageConfig from "../../mutations/SetPodcastPageConfig";
import { IPodcastPageConfigInput, IPodcastPageConfig } from "../../../types/types";
import { Flex, Box, Grid } from "@govlaunch/web";
import PodcastPageLinks from "./PodcastPageLinks";
import ColorPicker from "../../components/ColorPicker";
import prefixWithHttpIfNecessary from "../../utils/prefixWithHttpIfNecessary";
import ConfigPageHeader from "../../components/ConfigPageHeader";
import PageIcon from "../../icons/PageIcon";
import { faPodcast } from "@fortawesome/free-solid-svg-icons";

export default function PodcastPageConfig() {
  const [form] = Form.useForm();
  const [resetPageConfig] = useMutation<IResetPodcastPageConfigMutation, IResetPodcastPageConfigMutationVariables>(
    ResetPodcastPageConfig,
    {
      onCompleted: (result) => {
        if (!result) {
          return;
        }

        form.setFieldsValue(omit(["__typename"], result.resetPodcastPageConfig));
      },
    },
  );

  const { data, loading } = useQuery<IPodcastPageConfigQuery, IPodcastPageConfigQueryVariables>(
    PodcastPageConfigQuery,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    },
  );
  const [setPodcastPageConfig] = useMutation<ISetPodcastPageConfigMutation, ISetPodcastPageConfigMutationVariables>(
    SetPodcastPageConfig,
  );

  return (
    <DocumentTitle title="Podcast Page | Admin">
      <div
        css={{
          overflow: "auto",
        }}
      >
        <ConfigPageHeader
          title={
            <>
              <PageIcon icon={faPodcast} /> Podcast
            </>
          }
          subtitle="Configure podcast page variables"
        />

        <div
          css={{
            padding: 16,
          }}
        >
          {data && data.podcastPage && !loading && (
            <Form
              layout="vertical"
              form={form}
              name="podcast"
              onFinish={async () => {
                const values = form.getFieldsValue(true);
                const config = convertValuesToPodcastConfig(values);

                if (config === null) {
                  notification.open({
                    type: "error",
                    message: "Unable to save data!",
                    description: `Some of the data that was entered was invalid`,
                  });

                  return;
                }

                await setPodcastPageConfig({
                  variables: {
                    config,
                  },
                });

                notification.open({
                  type: "success",
                  message: "Podcast options saved!",
                  description: `All your changes on the podcast options were saved`,
                });
              }}
              initialValues={convertValuesToInitialVariables(data.podcastPage)}
            >
              <Grid gridTemplateColumns="400px 400px" alignItems="center" gridColumnGap={2}>
                <Form.Item
                  label="Header"
                  name="headerText"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input placeholder="Type podcast header..." />
                </Form.Item>

                <ColorPicker
                  label="Header Font Color"
                  name="headerTextColor"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  style={{
                    minWidth: 240,
                    maxWidth: 400,
                  }}
                />
              </Grid>

              <Form.Item
                label="Text"
                name="description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea autoSize={true} placeholder="Type podcast description..." />
              </Form.Item>
              <Form.Item
                label="Simplecast embed"
                name="podcast"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{
                  maxWidth: 700,
                }}
              >
                <Input.TextArea autoSize={true} placeholder="Type the podcast iframe..." />
              </Form.Item>

              <Grid gridTemplateColumns="400px 400px" alignItems="center" gridColumnGap={2}>
                <ColorPicker
                  label="Background Color"
                  name="backgroundColor"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  style={{
                    minWidth: 240,
                    maxWidth: 400,
                  }}
                />

                <ColorPicker
                  label="Text Color"
                  name="descriptionColor"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  style={{
                    minWidth: 240,
                    maxWidth: 400,
                  }}
                />
              </Grid>

              <PodcastPageLinks form={form} />

              <Flex flexDirection="row" alignItems="center" mt={16}>
                <Button htmlType="submit" size="large" type="primary" loading={loading}>
                  Submit
                </Button>

                <Box ml={2}>
                  <Button
                    type="dashed"
                    size="large"
                    onClick={async () => {
                      await form.submit();

                      window.open(`${getGovlaunchBaseUrl()}/podcast`, "_blank");
                    }}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Save & Preview
                  </Button>
                </Box>

                <Box ml={2}>
                  <Popconfirm
                    title="Are you sure you want to reset podcast page options to their default values?"
                    placement="bottom"
                    onConfirm={() => {
                      resetPageConfig();

                      notification.open({
                        type: "success",
                        message: "Podcast options reset!",
                        description: `All your changes on the podcast options were reset`,
                      });
                    }}
                  >
                    <Button size="large">Reset</Button>
                  </Popconfirm>
                </Box>
              </Flex>
            </Form>
          )}
        </div>
      </div>
    </DocumentTitle>
  );
}

function convertValuesToInitialVariables(podcast: IPodcastPageConfig) {
  return {
    headerText: podcast.headerText,
    headerTextColor: podcast.headerTextColor,
    backgroundColor: podcast.backgroundColor,
    description: podcast.description,
    descriptionColor: podcast.descriptionColor,
    podcast: `<iframe height="${podcast.podcastHeight}" width="100%" frameborder="no" scrolling="no" seamless src="${podcast.podcastSrc}"></iframe>`,
    twitter: podcast.twitter,
    facebook: podcast.facebook,
    instagram: podcast.instagram,
    youtube: podcast.youtube,
    apple: podcast.apple,
    spotify: podcast.spotify,
    googleplay: podcast.googleplay,
    soundcloud: podcast.soundcloud,
    deezer: podcast.deezer,
    iheartradio: podcast.iheartradio,
    overcast: podcast.overcast,
    stitcher: podcast.stitcher,
    pandora: podcast.pandora,
    amazonmusic: podcast.amazonmusic,
  };
}

function convertValuesToPodcastConfig(values: any): IPodcastPageConfigInput | null {
  const parser = new DOMParser();
  const code = parser.parseFromString(values.podcast, "text/html");

  const iframe = code.querySelector("iframe");

  if (!iframe) {
    return null;
  }

  const iframeSrc = iframe.getAttribute("src");

  if (!iframeSrc || !iframeSrc.includes("player.simplecast.com")) {
    return null;
  }

  const height = parseFloat(iframe.getAttribute("height") || "200");

  return {
    headerText: values.headerText,
    headerTextColor: values.headerTextColor,
    backgroundColor: values.backgroundColor,
    description: values.description,
    descriptionColor: values.descriptionColor,
    podcastSrc: prefixWithHttpIfNecessary(iframeSrc),
    podcastHeight: height,
    twitter: prefixWithHttpIfNecessary(values.twitter),
    facebook: prefixWithHttpIfNecessary(values.facebook),
    instagram: prefixWithHttpIfNecessary(values.instagram),
    youtube: prefixWithHttpIfNecessary(values.youtube),
    apple: prefixWithHttpIfNecessary(values.apple),
    spotify: prefixWithHttpIfNecessary(values.spotify),
    googleplay: prefixWithHttpIfNecessary(values.googleplay),
    soundcloud: prefixWithHttpIfNecessary(values.soundcloud),
    deezer: prefixWithHttpIfNecessary(values.deezer),
    iheartradio: prefixWithHttpIfNecessary(values.iheartradio),
    overcast: prefixWithHttpIfNecessary(values.overcast),
    stitcher: prefixWithHttpIfNecessary(values.stitcher),
    pandora: prefixWithHttpIfNecessary(values.pandora),
    amazonmusic: prefixWithHttpIfNecessary(values.amazonmusic),
  };
}
