import gql from "graphql-tag";
import SolutionFieldsFragment from "../fragments/SolutionFieldsFragment";

export default gql`
  mutation UpdateSolutionName($solutionId: ObjectId!, $name: String!) {
    updateSolutionName(solutionId: $solutionId, name: $name) {
      ...SolutionFields
    }
  }

  ${SolutionFieldsFragment}
`;
