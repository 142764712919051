import getOr from "lodash/fp/getOr";
import queryString from "query-string";
import React, { useState } from "react";
import { useQuery } from "react-apollo";
import TableAddStateToQueryString from "../../../components/table/TableAddStateToQueryString";
import AllGovernmentsQuery from "../../../queries/AllGovernmentsQuery";
import {
  IAllGovernmentsQuery,
  IAllGovernmentsQueryVariables,
} from "../../../queries/__generated__/AllGovernmentsQuery.generated";
import { parseState } from "../../../utils/tablesUtils";
import { NavigateFunction, useLocation, useNavigate } from "react-router";
import GovernmentsTable, { TGovernment } from "./GovernmentsTable";
import TablePagination, { DEFAULT_CURSOR, ITablePaginationState } from "../../../components/table/TablePagination";
import { calculateAndEncodeCursor } from "../../../utils/encodeCursor";
import TableTitle from "../../../components/table/TableTitle";
import TableOnSearch from "../../../components/table/TableOnSearch";

export enum IGovernmentsFilter {
  All = "ALL",
  Active = "ACTIVE",
  Innovators = "INNOVATORS",
  Inactive = "INACTIVE",
  Sharing = "SHARING",
}

export interface IGovernmentTableFilters {
  owner: ["NOT_OWNED" | "OWNED"];
  state: string[];
  tags: ["SHARING" | "NOT_OWNED" | "INNOVATORS"];
  governmentType: string[];
}

export interface IGovernmentsTableState {
  filters: IGovernmentTableFilters | null;
  sorter: IGovernmentTableSorter | null;
  cursor: ITablePaginationState;
}

interface IGovernmentTableSorter {
  columnKey: string;
  order: "ascend" | "descend";
}

interface IGovernmentsProps {
  search: string;
  filter: IGovernmentsFilter;
  filterByCountry?: string;
  sortBy?: string;
  filterByTag?: any;
  navigate?: NavigateFunction;
  historyOrigin?: string;
}

function convertFiltersAndSorterIntoQueryVariables(
  tab: IGovernmentsFilter,
  filters: IGovernmentTableFilters | null,
  sorter: IGovernmentTableSorter | null,
): Partial<IAllGovernmentsQueryVariables> {
  let hasOwner: boolean | null = null;
  let filter: string | null = tab;

  if (filters) {
    if (Array.isArray(filters.tags) && filters.tags.includes("NOT_OWNED")) {
      hasOwner = false;
    }

    // "Owner" filter takes precedence over tags' filter.
    if (Array.isArray(filters.owner)) {
      hasOwner = filters.owner[0] === "OWNED";
    }

    if (Array.isArray(filters.tags)) {
      filter = filters.tags[0];
    }
  }

  return {
    state: filters && Array.isArray(filters.state) && filters.state.length > 0 ? filters.state : null,
    hasOwner,
    type:
      filters && Array.isArray(filters.governmentType) && filters.governmentType.length > 0
        ? filters.governmentType
        : null,
    filter,
    sortBy: getSortBy(sorter),
  };
}

export default function ManageGovernments({
  search,
  filter,
  filterByCountry,
  sortBy,
  filterByTag,
  historyOrigin = "/governments",
}: IGovernmentsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const initialFiltersState = parseState(query.state as string, {
    filters: null,
    sortBy: null,
    cursor: DEFAULT_CURSOR,
  });

  const [tableSorterAndFilters, setTableSorterAndFilters] = useState<IGovernmentsTableState>(initialFiltersState);

  let queryVariables: IAllGovernmentsQueryVariables = {
    search,
    filter,
    type: null,
    sortBy: sortBy || "",
    hasOwner: null,
    cursor: null,
    state: null,
    country: filterByCountry || "",
    tags: filterByTag ? [filterByTag] : null,
  };

  if (tableSorterAndFilters) {
    queryVariables = {
      ...queryVariables,
      ...convertFiltersAndSorterIntoQueryVariables(filter, tableSorterAndFilters.filters, tableSorterAndFilters.sorter),
      cursor: calculateAndEncodeCursor(tableSorterAndFilters.cursor),
    };
  }

  const { data, loading } = useQuery<IAllGovernmentsQuery, IAllGovernmentsQueryVariables>(AllGovernmentsQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: queryVariables,
  });

  const governments: TGovernment[] = getOr([], "allGovernments.items", data);
  const totalCount =
    data && data.allGovernments && data.allGovernments.pageInfo ? data.allGovernments.pageInfo.totalCount || 0 : 0;

  return (
    <>
      {typeof history !== "undefined" && history && (
        <TableAddStateToQueryString tableState={tableSorterAndFilters} historyOrigin={historyOrigin} query={query} />
      )}
      <TableOnSearch
        search={search}
        onSearch={() => {
          setTableSorterAndFilters({
            ...tableSorterAndFilters,
            cursor: {
              page: 1,
              size: tableSorterAndFilters.cursor.size,
            },
          });
        }}
      />
      <GovernmentsTable
        governments={governments}
        filterByCountry={filterByCountry}
        loading={loading}
        state={tableSorterAndFilters}
        title={() => (
          <TableTitle
            title="Governments"
            count={totalCount}
            loading={loading}
            onResetFilters={() => {
              setTableSorterAndFilters({
                filters: null,
                sorter: null,
                cursor: {
                  page: 1,
                  size: tableSorterAndFilters.cursor.size,
                },
              });

              const country = query.country ? `&country=${query.country}` : "";
              navigate(`${historyOrigin}?tab=${query.tab || ""}${country}&search=${search}`);
            }}
          />
        )}
        footer={() => (
          <TablePagination
            loading={loading}
            totalCount={totalCount}
            tableState={tableSorterAndFilters}
            setTableState={setTableSorterAndFilters}
          />
        )}
        onChange={(_, filters, sorter) => {
          setTableSorterAndFilters({
            filters: filters as any as IGovernmentTableFilters,
            sorter: sorter as IGovernmentTableSorter,
            cursor: {
              page: 1,
              size: tableSorterAndFilters?.cursor.size || 10,
            },
          });
        }}
      />
    </>
  );
}

function getSortBy(sorter: IGovernmentTableSorter | null) {
  if (sorter && sorter.columnKey && sorter.order) {
    const direction = sorter.order === "descend" ? "descending" : "ascending";

    if (sorter.columnKey === "name") {
      return `slug:${direction}`;
    }

    if (sorter.columnKey === "state") {
      return `state:${direction}`;
    }

    if (sorter.columnKey === "activatedAt") {
      return `activatedAt:${direction}`;
    }

    if (sorter.columnKey === "storiesCount") {
      return `storiesCount:${direction}`;
    }

    if (sorter.columnKey === "projectsCount") {
      return `projectsCount:${direction}`;
    }
  }

  return "activatedAt:desc";
}
