import gql from "graphql-tag";

export default gql`
  query GetAllPlans {
    allPlans {
      _id
      name
      displayName
      tier
      stripePlanId
      limits {
        products
        enrichedProducts
        groups
        groupInvitations
        teamMembers
        projectsPerMonth
        enrichedProfile
        productTags
        sharingPage
        searchAccess
        inviteGovernments
      }
      vendorCount
    }
  }
`;
