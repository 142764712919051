import React from "react";
import * as palette from "@govlaunch/palette";
import { Margin } from "../../../spacings";
import { Field } from "react-final-form";
import TagsSelectContainer from "../../tags/TagsSelect";

export default function DepartmentField() {
  return (
    <div>
      <div>
        <label
          css={{
            fontWeight: "bold",
            fontSize: 14,
            color: palette.mediumGray,
            margin: 0,
          }}
        >
          Department
        </label>
      </div>

      <Margin mt={10}>
        <div
          css={{
            width: "100%",
          }}
        >
          <Field name="department">
            {({ input }) => (
              <TagsSelectContainer
                onChange={(tags) => {
                  if (tags.length > 0) {
                    input.onChange(tags[tags.length - 1]);
                  } else {
                    input.onChange(null);
                  }
                }}
                onFocus={input.onFocus}
                onBlur={input.onBlur}
                placeholder={input.value ? " " : "What department do you work in?"}
                value={input.value ? [input.value] : []}
                type="DEPARTMENT"
              />
            )}
          </Field>
        </div>
      </Margin>
    </div>
  );
}
