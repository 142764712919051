import React from "react";
import { Col, Grid, RowWrap } from "@govlaunch/core";
import Section from "../../form/Section";
import { useQuery } from "react-apollo";
import { IGetAllPlansQuery, IGetAllPlansQueryVariables } from "../../../queries/__generated__/GetAllPlans.generated";
import GetAllPlans from "../../../queries/GetAllPlans";
import { IPlanTier } from "../../../../types/types";
import { IVendorQuery } from "../../../queries/__generated__/VendorQuery.generated";
import ManualSubscriptionHeader from "./ManualSubscriptionHeader";
import AddPlanLink from "../../billing/AddPlanLink";
import { Button } from "antd";
import HorizontalSelectField from "../../form/fields/HorizontalSelectField";
import HorizontalTextField from "../../form/fields/HorizontalTextField";
import HorizontalNumberField from "../../form/fields/HorizontalNumberField";
import SectionBody from "../../form/SectionBody";
import LabelWithCheckboxField from "../../form/fields/LabelWithCheckboxField";
import * as palette from "@govlaunch/palette";
import CancelSubscriptionButton from "./CancelSubscriptionButton";

export enum CustomerExists {
  NewCustomer = "NEW_CUSTOMER",
  ExistingCustomer = "EXISTING_CUSTOMER",
}

export enum SubscriptionExists {
  NewSubscription = "NEW_SUBSCRIPTION",
  ExistingSubscription = "EXISTING_SUBSCRIPTION",
}

export enum CustomStripeCustomer {
  OwnerIsCustomer = "OWNER_IS_CUSTOMER",
  CustomCustomer = "CUSTOM_CUSTOMER",
}

export enum SubscriptionType {
  NoStripeCustomer = "NO_STRIPE_CUSTOMER",
  StripeCustomerExists = "STRIPE_CUSTOMER_EXISTS",
  StripeCustomerExistsWithSubscription = "STRIPE_CUSTOMER_EXISTS_WITH_SUBSCRIPTION",
  ChangePlan = "CHANGE_PLAN",
}

interface IManualSubscriptionFormProps {
  vendor: NonNullable<IVendorQuery["company"]>;
  handleSubmit: (arg: any) => any;
  submitting: boolean;
  formValues: any;
  submitError?: string;
  refetch: () => void;
}

export default function ManualSubscriptionForm({
  vendor,
  handleSubmit,
  submitting,
  formValues,
  submitError,
  refetch,
}: IManualSubscriptionFormProps) {
  const { data } = useQuery<IGetAllPlansQuery, IGetAllPlansQueryVariables>(GetAllPlans, {
    fetchPolicy: "network-only",
  });

  const planOptions = preparePlanOptions(data, vendor.plan.tier, vendor.plan.stripePlanId);
  const subscriptionOptions = prepareSubscriptionOptions(vendor.plan.tier);

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid>
        <RowWrap>
          <Col width={[1, 1, 1, 8 / 12]} mx="auto">
            <Section>
              <ManualSubscriptionHeader
                title="Manual Subscription"
                plan={vendor.plan}
                subscription={vendor.subscription}
                stripeCustomerId={vendor.stripeCustomerId}
              />
              <SectionBody>
                <HorizontalSelectField
                  fieldName="subscriptionType"
                  label="Subscription Type"
                  options={subscriptionOptions}
                  inputWidth={560}
                />

                <HorizontalSelectField
                  fieldName="stripePlanId"
                  label="Plan"
                  placeholder="Choose a plan..."
                  options={planOptions}
                  addOption={<AddPlanLink />}
                  inputWidth={560}
                />

                {formValues.subscriptionType !== SubscriptionType.StripeCustomerExistsWithSubscription &&
                  formValues.subscriptionType !== SubscriptionType.ChangePlan && (
                    <>
                      <LabelWithCheckboxField fieldName="sendInvoice" label="Send Invoice" labelWidth={136} />

                      <HorizontalNumberField
                        fieldName="daysUntilDue"
                        label="Payment Due"
                        postTitle="days after invoice sent"
                        minValue={1}
                        labelWidth={136}
                      />
                    </>
                  )}

                {(formValues.subscriptionType === SubscriptionType.NoStripeCustomer ||
                  formValues.subscriptionType === SubscriptionType.StripeCustomerExists) && (
                  <LabelWithCheckboxField fieldName="freeSubscription" label="Subscribe For Free" labelWidth={136} />
                )}

                {formValues.subscriptionType !== SubscriptionType.StripeCustomerExistsWithSubscription &&
                  formValues.subscriptionType !== SubscriptionType.ChangePlan &&
                  !formValues.freeSubscription &&
                  formValues.sendInvoice && (
                    <HorizontalNumberField
                      fieldName="trialPeriodDays"
                      label="Free Trial Period"
                      postTitle="days"
                      minValue={0}
                      labelWidth={136}
                    />
                  )}

                {formValues.subscriptionType !== SubscriptionType.NoStripeCustomer &&
                  formValues.subscriptionType !== SubscriptionType.ChangePlan && (
                    <HorizontalTextField
                      fieldName="customerId"
                      label="Customer ID"
                      placeholder="Example: cus_JdkHV1WKPLFBUs"
                      inputWidth={560}
                    />
                  )}

                {formValues.subscriptionType === SubscriptionType.StripeCustomerExistsWithSubscription && (
                  <HorizontalTextField
                    fieldName="stripeSubscriptionId"
                    label="Subscription ID"
                    placeholder="Example: sub_JdkOMMGCH56AD7"
                    inputWidth={560}
                  />
                )}

                {formValues.subscriptionType === SubscriptionType.NoStripeCustomer && (
                  <>
                    <HorizontalTextField
                      fieldName="billingName"
                      label="Customer Name"
                      placeholder="Example: John Smith"
                      inputWidth={560}
                    />

                    <HorizontalTextField
                      fieldName="email"
                      label="Email"
                      placeholder="john@example.com"
                      inputWidth={560}
                    />
                  </>
                )}

                <div
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 24,
                    alignItems: "center",
                  }}
                >
                  {vendor.plan.tier !== IPlanTier.Basic &&
                    vendor.subscription &&
                    (vendor.subscription.__typename === "ActiveSubscription" ||
                      vendor.subscription.__typename === "OverdueSubscription") && (
                      <CancelSubscriptionButton subscriptionId={vendor.subscription._id} refetch={refetch} />
                    )}

                  <div
                    css={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "auto",
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      disabled={submitting}
                      css={{
                        userSelect: "none",
                        width: 160,
                        marginLeft: 16,
                      }}
                    >
                      {submitting ? "Please wait..." : "Subscribe"}
                    </Button>
                  </div>
                </div>
                <div
                  css={{
                    justifySelf: "flex-end",
                    color: palette.red,
                  }}
                >
                  {submitError}
                </div>
              </SectionBody>
            </Section>
          </Col>
        </RowWrap>
      </Grid>
    </form>
  );
}

function preparePlanOptions(
  data: IGetAllPlansQuery | undefined,
  tier: IPlanTier,
  vendorStripePlanId: string | null | undefined,
) {
  if (tier !== IPlanTier.Basic && vendorStripePlanId) {
    return data && data.allPlans && data.allPlans.length > 0
      ? data.allPlans
          .filter((plan) => {
            return (
              (plan.tier === IPlanTier.Makers || plan.tier === IPlanTier.Makerspro) &&
              plan.stripePlanId !== vendorStripePlanId
            );
          })
          .map((plan) => {
            return {
              value: plan.stripePlanId || "",
              label: plan.name,
            };
          })
      : [];
  }

  return data && data.allPlans && data.allPlans.length > 0
    ? data.allPlans
        .filter((plan) => {
          return plan.tier !== IPlanTier.Basic && plan.tier !== IPlanTier.Disruptor && plan.stripePlanId;
        })
        .map((plan) => {
          return {
            value: plan.stripePlanId || "",
            label: plan.name,
          };
        })
    : [];
}

function prepareSubscriptionOptions(tier: IPlanTier) {
  if (tier !== IPlanTier.Basic) {
    return [
      {
        label: "Change Plan",
        value: SubscriptionType.ChangePlan,
      },
    ];
  }

  return [
    {
      label: "Stripe Customer does not exist",
      value: SubscriptionType.NoStripeCustomer,
    },
    {
      label: "Stripe Customer exists / No Subscription",
      value: SubscriptionType.StripeCustomerExists,
    },
    {
      label: "Stripe Customer exists / Subscription Exists",
      value: SubscriptionType.StripeCustomerExistsWithSubscription,
    },
  ];
}
