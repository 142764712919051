import React from "react";
import * as palette from "@govlaunch/palette";
import debounce from "lodash/debounce";
import fp from "lodash/fp";
import { Modal, Button } from "@govlaunch/core";
import { Margin } from "../../../spacings";
import { Table, Input } from "antd";
import { NameColumn, PlainEmailColumn, CurrentlyAssignedToColumn } from "../../../components/table/UsersTableColumns";
import { Value, List } from "react-powerplug";
import SyntheticUsersTable from "../../governments/SyntheticUsersTable";
import ProfilePicture from "@govlaunch/profile-picture";
import { Field } from "react-final-form";
import { IStoryBySlugQuery } from "../../../queries/__generated__/StoryBySlugQuery.generated";

type TCollaborator = NonNullable<IStoryBySlugQuery["storyBySlug"]>["collaborators"][0];

interface IManageCollaboratorsModal {
  collaborators: TCollaborator[];
  isOpen: boolean;
  onRequestClose: () => any;
  onChangeCollaborators: (props: { collaborators: TCollaborator[] }) => any;
}

export default function ManageCollaboratorsModal({
  collaborators,
  isOpen,
  onRequestClose,
  onChangeCollaborators,
}: IManageCollaboratorsModal) {
  const collaboratorsById = fp.keyBy("_id", collaborators);

  return (
    <Field name="author">
      {({ input }) => {
        const authorId = fp.get("value._id", input);

        return (
          <Modal isOpen={isOpen} onRequestClose={onRequestClose} ariaHideApp={false}>
            <div
              css={{
                minWidth: 900,
                maxWidth: 900,
              }}
            >
              <div
                css={{
                  padding: 20,
                  borderBottom: `solid 1px ${palette.lightSealBlue}`,
                }}
              >
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h2
                    css={{
                      margin: 0,
                      fontWeight: 700,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Story Collaborators
                  </h2>

                  <span
                    role="button"
                    onClick={onRequestClose}
                    css={{
                      cursor: "pointer",
                      marginLeft: "auto",
                      color: palette.sealBlue,
                      fontSize: 21,
                      fontWeight: "lighter",
                    }}
                  >
                    &times;
                  </span>
                </div>
              </div>

              <CollaboratorsList>
                {({ addUser, addedUsers, isAdded, removeUser, removedUsers, isRemoved, pull, clear }) => (
                  <div
                    css={{
                      display: "grid",
                    }}
                  >
                    {(collaborators || []).length > 0 && (
                      <div
                        css={{
                          padding: 20,
                          paddingBottom: 0,
                        }}
                      >
                        <h3
                          css={{
                            margin: 0,
                            color: palette.darkGray,
                          }}
                        >
                          Current collaborators →
                        </h3>

                        <Margin mt={10}>
                          <Table
                            rowKey="_id"
                            dataSource={collaborators}
                            pagination={false}
                            size="small"
                            columns={[
                              NameColumn,
                              PlainEmailColumn,
                              CurrentlyAssignedToColumn,
                              {
                                title: "Remove",
                                align: "left",
                                render: (collaborator) => {
                                  if (isRemoved(collaborator._id)) {
                                    return (
                                      <div>
                                        {"Removed • "}
                                        <a href="#" onClick={preventDefault(() => pull(collaborator._id))}>
                                          Restore
                                        </a>
                                      </div>
                                    );
                                  }

                                  return (
                                    <Button
                                      size="xsmall"
                                      theme="secondary"
                                      color="danger"
                                      onClick={() => removeUser(collaborator)}
                                    >
                                      Remove
                                    </Button>
                                  );
                                },
                              },
                            ]}
                          />
                        </Margin>
                      </div>
                    )}

                    <div
                      css={{
                        padding: 20,
                      }}
                    >
                      <h3
                        css={{
                          margin: 0,
                          color: palette.darkGray,
                        }}
                      >
                        All Users →
                      </h3>

                      <Margin mt={10}>
                        <Value initial="">
                          {({ value: searchTerm, set: setSearchTerm }) => (
                            <Value initial="" onChange={debounce(setSearchTerm, 1200)}>
                              {({ value, set: setValue }) => {
                                return (
                                  <>
                                    <Margin mb={20}>
                                      <Input.Search
                                        size="large"
                                        placeholder="Search for users..."
                                        autoComplete="off"
                                        value={value}
                                        onChange={(event) => setValue(event.target.value)}
                                        style={{
                                          fontSize: 14,
                                        }}
                                      />
                                    </Margin>

                                    <SyntheticUsersTable
                                      limit={5}
                                      pagination={false}
                                      sortBy="createdAt:descending"
                                      search={searchTerm}
                                      columns={[
                                        NameColumn,
                                        PlainEmailColumn,
                                        CurrentlyAssignedToColumn,
                                        {
                                          title: "#",
                                          align: "left",
                                          render: (user: TCollaborator) => {
                                            if (isAdded(user._id)) {
                                              return (
                                                <div>
                                                  {"Added • "}
                                                  <a href="#" onClick={preventDefault(() => pull(user._id))}>
                                                    Put back
                                                  </a>
                                                </div>
                                              );
                                            }

                                            if (user._id === authorId) {
                                              return "Current Author";
                                            }

                                            if (collaboratorsById[user._id]) {
                                              return "Already in collaborators";
                                            }

                                            return (
                                              <Button size="xsmall" theme="secondary" onClick={() => addUser(user)}>
                                                Add
                                              </Button>
                                            );
                                          },
                                        },
                                      ]}
                                    />
                                  </>
                                );
                              }}
                            </Value>
                          )}
                        </Value>
                      </Margin>
                    </div>

                    {(addedUsers.length > 0 || removedUsers.length > 0) && (
                      <>
                        <div
                          css={{
                            padding: 20,
                            borderTop: `solid 1px ${palette.lightSealBlue}`,
                          }}
                        >
                          <div
                            css={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <h3
                              css={{
                                margin: 0,
                                color: palette.darkGray,
                              }}
                            >
                              Change summary
                            </h3>

                            <div
                              css={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "auto",
                              }}
                            >
                              <div
                                css={{
                                  display: "grid",
                                  gridAutoFlow: "column",
                                  gridColumnGap: 5,
                                }}
                              >
                                {removedUsers.length > 0 && (
                                  <span
                                    css={{
                                      width: 32,
                                      height: 32,
                                      borderRadius: 20,
                                      fontSize: 10,
                                      padding: 5,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      border: `solid 1px ${palette.red}`,
                                      color: palette.red,
                                    }}
                                  >
                                    -{removedUsers.length}
                                  </span>
                                )}

                                {addedUsers.length > 0 && (
                                  <span
                                    css={{
                                      width: 32,
                                      height: 32,
                                      borderRadius: 20,
                                      fontSize: 10,
                                      padding: 5,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      border: `solid 1px ${palette.greenBlue}`,
                                      color: palette.greenBlue,
                                    }}
                                  >
                                    +{addedUsers.length}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <TeamBuilderChangeSummary addedUsers={addedUsers} removedUsers={removedUsers} />
                        </div>

                        <div
                          css={{
                            padding: 20,
                            borderTop: `solid 1px ${palette.lightSealBlue}`,
                            display: "flex",
                          }}
                        >
                          <div
                            css={{
                              display: "grid",
                              gridAutoFlow: "column",
                              gridColumnGap: 5,
                            }}
                          >
                            <Button
                              theme="primary"
                              color="success"
                              onClick={() => {
                                const removedUserIds = (removedUsers || []).map(fp.get("_id"));

                                onChangeCollaborators({
                                  collaborators: [...(collaborators || []), ...(addedUsers || [])].filter(
                                    (collaborator) => {
                                      return !removedUserIds.includes(collaborator._id);
                                    },
                                  ),
                                });
                              }}
                            >
                              Confirm Changes
                            </Button>

                            <Button theme="secondary" color="warning" onClick={clear}>
                              Undo Changes
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </CollaboratorsList>
            </div>
          </Modal>
        );
      }}
    </Field>
  );
}

interface ICollaboratorsListProps {
  addUser: (value: TCollaborator) => void;
  addedUsers: ReadonlyArray<TCollaborator>;
  removeUser: (value: TCollaborator) => void;
  removedUsers: ReadonlyArray<TCollaborator>;
  isRemoved: (_id: string) => boolean;
  isAdded: (_id: string) => boolean;
  pull: (_id: string) => void;
  clear: () => void;
}

function CollaboratorsList({ children }: { children: (props: ICollaboratorsListProps) => React.ReactElement }) {
  const compareSameId = (_id: string) => (user: TCollaborator) => user._id === _id;

  return (
    <List<TCollaborator> initial={[]}>
      {({ push: removeUser, pull: pullFromRemoved, list: removedUsers, set: setRemovedUsers }) => (
        <List<TCollaborator> initial={[]}>
          {({ push: addUser, pull: pullFromAdded, list: addedUsers, set: setAddedUsers }) =>
            children({
              addUser,
              addedUsers,
              removeUser,
              removedUsers,
              isRemoved: (_id: string) => removedUsers.some(compareSameId(_id)),
              isAdded: (_id: string) => addedUsers.some(compareSameId(_id)),
              pull: (_id: string) => {
                pullFromRemoved(compareSameId(_id));
                pullFromAdded(compareSameId(_id));
              },
              clear: () => {
                setRemovedUsers([]);
                setAddedUsers([]);
              },
            })
          }
        </List>
      )}
    </List>
  );
}

function preventDefault(callback: (event: React.SyntheticEvent) => any) {
  return (event: React.SyntheticEvent) => {
    event.preventDefault();
    callback(event);
  };
}

function TeamBuilderChangeSummary({
  addedUsers,
  removedUsers,
}: {
  addedUsers: ReadonlyArray<TCollaborator>;
  removedUsers: ReadonlyArray<TCollaborator>;
}) {
  return (
    <div>
      {addedUsers.length > 0 && (
        <>
          <Margin mt={5}>
            <h5
              css={{
                fontSize: 14,
                fontWeight: 600,
                margin: 0,
              }}
            >
              Will be added...
            </h5>
          </Margin>

          <div
            css={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {addedUsers.map((user) => {
              return (
                <div
                  key={user._id}
                  css={{
                    marginTop: 5,
                    marginRight: 5,
                  }}
                >
                  <InlinePicturePill name={user.fullName || ""} image={user.avatar || ""} size={24} />
                </div>
              );
            })}
          </div>
        </>
      )}

      {removedUsers.length > 0 && (
        <>
          <Margin mt={5}>
            <h5
              css={{
                fontSize: 14,
                fontWeight: 600,
                margin: 0,
              }}
            >
              Will be removed...
            </h5>
          </Margin>

          <div
            css={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {removedUsers.map((user) => {
              return (
                <div
                  key={user._id}
                  css={{
                    marginTop: 5,
                    marginRight: 5,
                  }}
                >
                  <InlinePicturePill name={user.fullName || ""} image={user.avatar || ""} size={24} />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

const InlinePicturePill = ({ name, image, size }: { name: string; image: string; size: number }) => (
  <div
    css={{
      display: "inline-flex",
      alignItems: "center",
      borderRadius: 40,
      backgroundColor: "#f1f1f1",
      padding: 5,
    }}
  >
    <ProfilePicture
      size={size}
      image={image}
      name={name}
      css={{
        whiteSpace: "nowrap",
      }}
    />

    <Margin ml={5}>
      <span>{name}</span>
    </Margin>
  </div>
);
