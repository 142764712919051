import gql from "graphql-tag";
import UserFields from "./fragments/UserFields";

export default gql`
  query SearchUsers($limit: Int, $search: String, $filter: String) {
    allUsers(search: $search, filter: $filter, limit: $limit) {
      items {
        ...UserFields
      }
    }
  }

  ${UserFields}
`;
