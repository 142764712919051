import gql from "graphql-tag";
import GovernmentProjectFields from "./fragments/GovernmentProjectFields";
import GroupProjectFields from "./fragments/GroupProjectFields";
import VendorProjectFields from "./fragments/VendorProjectFields";

export default gql`
  query SearchProjects(
    $title: String
    $tags: [String!]
    $audience: String
    $status: [String!]
    $cursor: Cursor
    $sort: FieldOrderSortInput
    $archivedOnly: Boolean
    $userCreated: Boolean
    $verified: Boolean
    $filterByType: ProjectType
    $country: String
    $limit: Int = 30
  ) {
    searchProjects(
      title: $title
      tags: $tags
      audience: $audience
      status: $status
      limit: $limit
      cursor: $cursor
      sort: $sort
      filterByType: $filterByType
      archivedOnly: $archivedOnly
      userCreated: $userCreated
      verified: $verified
      country: $country
    ) {
      items {
        _id
        ...GovernmentProjectFields
        ...GroupProjectFields
        ...VendorProjectFields
      }
      pageInfo {
        totalCount
        hasNextPage
        endCursor
      }
    }
  }

  ${GovernmentProjectFields}
  ${GroupProjectFields}
  ${VendorProjectFields}
`;
