import React from "react";
import * as palette from "@govlaunch/palette";
import { Margin } from "../../../spacings";

interface IReadOnlyFieldProps {
  label: string;
  value: string;
}

export default function ReadOnlyField({ label, value }: IReadOnlyFieldProps) {
  return (
    <div>
      <div>
        <label
          css={{
            fontWeight: "bold",
            fontSize: 14,
            color: palette.mediumGray,
            margin: 0,
          }}
        >
          {label}
        </label>
      </div>

      <Margin mt={10}>
        <div
          css={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/^https?/.test(value) ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={value}
              css={{
                width: "100%",
                height: 42,
                padding: "6px 0px",
                fontSize: 14,
                lineHeight: 1.333,
                outline: 0,
              }}
            >
              {value}
            </a>
          ) : (
            <span
              css={{
                width: "100%",
                height: 42,
                padding: "6px 0px",
                fontSize: 14,
                lineHeight: 1.333,
                outline: 0,
              }}
            >
              {value}
            </span>
          )}
        </div>
      </Margin>
    </div>
  );
}
