import * as palette from "@govlaunch/palette";
import { SpacingBetween } from "@govlaunch/spacers";
import { Alert, Button } from "antd";
import React from "react";
import { Field, Form as FinalForm, FormSpy } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { ITag } from "../../../../types/types";
import validate from "../../../validate";
import TextField from "../../form/fields/TextField";
import TagValidationSchema from "../TagValidationSchema";
import RelatedTagsField from "./RelatedTagsField";
import TagPublishField from "./TagPublishField";
import TagTypesField from "./TagTypesField";

export interface ITagFormValues {
  name: string;
  types: ITag["types"];
  publish: boolean;
  relatedTags: any[];
}

interface ITagFormProps {
  onSubmit: any;
  initialValues?: ITagFormValues;
  showPublishField?: boolean;
  submitLabel?: string;
}

interface IWhenFieldChangesProps {
  field: string;
  becomes: (newValue: any) => boolean;
  set: string;
  to: (newValue: any) => any;
}

const WhenFieldChanges = ({ field, becomes, set, to }: IWhenFieldChangesProps) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } },
    ) => (
      <FormSpy subscription={{}}>
        {() => (
          <OnChange name={field}>
            {(value: any) => {
              if (becomes(value)) {
                onChange(to(value));
              }
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);

export default function TagForm({
  onSubmit,
  initialValues,
  showPublishField = true,
  submitLabel = "Add",
}: ITagFormProps) {
  return (
    <FinalForm<ITagFormValues>
      keepDirtyOnReinitialize={true}
      validate={validate(TagValidationSchema)}
      onSubmit={onSubmit}
      initialValues={
        initialValues || {
          name: "",
          types: [],
          publish: false,
          relatedTags: [],
        }
      }
    >
      {({ handleSubmit, submitting, values, submitError }) => (
        <form onSubmit={handleSubmit}>
          <WhenFieldChanges
            field="types"
            becomes={(value: string[]) => {
              if (Array.isArray(value)) {
                return value.includes("PROJECT") || value.includes("PRODUCT") || value.includes("STORY");
              }
              return false;
            }}
            set="publish"
            to={() => true}
          />

          <div
            css={{
              padding: 20,
              "& > div:not(:first-of-type)": {
                marginTop: 10,
              },
            }}
          >
            <TextField label="Name" fieldName="name" placeholder="Type tag name..." headerColor={palette.text} />

            <TagTypesField values={values} />

            {showPublishField && <TagPublishField />}

            <FormSpy
              subscription={{
                values: true,
              }}
            >
              {({ values }) => <RelatedTagsField tagTypes={values.types} />}
            </FormSpy>
          </div>

          {submitError && (
            <div
              css={{
                padding: 20,
                paddingTop: 0,
              }}
            >
              <Alert message="Could not save tag data" description={submitError} type="error" showIcon={true} />
            </div>
          )}

          <div
            css={{
              padding: 20,
              borderTop: "1px solid rgb(232, 232, 232)",
            }}
          >
            <SpacingBetween right={10}>
              <Button htmlType="submit" loading={submitting} disabled={submitting} size="large" type="primary">
                {submitLabel}
              </Button>
            </SpacingBetween>
          </div>
        </form>
      )}
    </FinalForm>
  );
}
