import * as palette from "@govlaunch/palette";

import React, { memo, FunctionComponent } from "react";

import Downshift from "downshift";
import { Input } from "@govlaunch/core/form";
import { LoadingIndicator } from "@govlaunch/core";
import { Margin } from "../../../spacings";
import ProfilePicture from "@govlaunch/profile-picture";
import ProgressiveImage from "react-progressive-image";
import { SearchAlgoliaIndex } from "@govlaunch/algolia";
import { uniqBy } from "lodash/fp";
import { useSelfie } from "../../auth/Selfie";
import { IGovernment, ICity } from "../../../../types/types";
import FieldValidationError from "../../../components/form/FieldValidationError";

interface IGovernmentInputProps {
  government: IGovernment;
  onChange: any;
  onBlur?: any;
  onFocus?: any;
  meta: Partial<{
    touched: boolean;
    error: any;
    submitError: any;
  }>;
}

const GovernmentInput: FunctionComponent<IGovernmentInputProps> = ({ government, onChange, onBlur, onFocus, meta }) => {
  const user = useSelfie();

  if (government) {
    return (
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "0.5fr",
        }}
      >
        <ProgressiveImage src={government.logo || ""} placeholder="">
          {(src: string) => (
            <div
              css={{
                display: "grid",
                gridTemplateAreas: '"government remove"',
                gridTemplateColumns: "auto 1fr",
                gridColumnGap: 10,
                gridRowGap: 2.5,
                alignItems: "center",
                backgroundColor: "rgba(219, 236, 247, 0.5)",
                borderRadius: 60,
                padding: "10px 15px",
              }}
            >
              <div
                css={{
                  gridArea: "government",
                  display: "grid",
                  gridTemplateAreas: '"logo name" "logo description"',
                  gridTemplateColumns: "auto 1fr",
                  gridColumnGap: 10,
                  gridRowGap: 2.5,
                  alignItems: "center",
                  "&:focus": {
                    outline: 0,
                  },
                }}
              >
                <ProfilePicture
                  image={src}
                  name={government.name}
                  size={28}
                  css={{
                    userSelect: "none",
                    gridArea: "logo",
                  }}
                />

                <div
                  css={{
                    gridArea: "name",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: 14,
                    fontWeight: 600,
                    color: palette.primary,
                    lineHeight: 1,
                  }}
                >
                  {government.city.name}
                </div>

                <div
                  css={{
                    gridArea: "description",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    fontSize: 12,
                    color: palette.primary,
                    lineHeight: 1,
                  }}
                >
                  {government.city.state}
                </div>
              </div>

              <div
                css={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <svg
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onChange(null);
                  }}
                  viewBox="0 0 117 117"
                  width={28}
                  height={28}
                  css={{
                    gridArea: "remove",
                    cursor: "pointer",
                    "&:hover": {
                      opacity: 0.8,
                    },
                  }}
                >
                  <g fill="#FF7171" fillRule="evenodd">
                    <path
                      fillRule="nonzero"
                      d="M58.5 116.4c31.9 0 57.9-26 57.9-57.9S90.4.6 58.5.6.6 26.6.6 58.5s26 57.9 57.9 57.9zm0-107.7c27.4 0 49.8 22.3 49.8 49.8 0 27.5-22.3 49.8-49.8 49.8-27.5 0-49.8-22.4-49.8-49.8S31.1 8.7 58.5 8.7z"
                    />
                    <path d="M31.8 62h53.3c2.3 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1H31.8c-2.3 0-4.1 1.8-4.1 4.1s1.9 4.1 4.1 4.1z" />
                  </g>
                </svg>
              </div>
            </div>
          )}
        </ProgressiveImage>
      </div>
    );
  }

  return (
    <SearchAlgoliaIndex
      fetchOnMount={false}
      index="governments"
      params={{
        hitsPerPage: 40,
      }}
      user={user}
    >
      {({
        searchValue,
        onChange: setSearchQuery,
        loading,
        results,
      }: {
        searchValue: string;
        onChange: any;
        loading: boolean;
        results: IGovernment[];
      }) => {
        return (
          <Downshift
            itemToString={(government) => {
              if (government) {
                return government._id || government.ObjectID;
              } else {
                return null;
              }
            }}
            inputValue={searchValue}
            onChange={(selectedGovernment) => {
              setSearchQuery("");
              onChange(selectedGovernment);
            }}
            stateReducer={(_, changes) => {
              switch (changes.type) {
                case Downshift.stateChangeTypes.changeInput:
                  return {
                    ...changes,
                    highlightedIndex: null,
                  };
                default:
                  return changes;
              }
            }}
            onInputValueChange={(inputValue, changes) => {
              if ((changes as any).type === Downshift.stateChangeTypes.changeInput) {
                setSearchQuery(inputValue);
              }
            }}
          >
            {({ getInputProps, getItemProps, highlightedIndex, isOpen }) => (
              <div>
                <div
                  css={{
                    position: "relative",
                  }}
                >
                  <div
                    css={{
                      position: "relative",
                    }}
                  >
                    <Input
                      {...getInputProps({
                        onBlur,
                        onFocus,
                      })}
                      size="small"
                      placeholder="Type a government name…"
                    />

                    {loading && (
                      <div
                        css={{
                          position: "absolute",
                          top: "50%",
                          transform: "translateY(-50%)",
                          right: 10,
                        }}
                      >
                        <LoadingIndicator />
                      </div>
                    )}
                  </div>

                  {isOpen && results.length > 0 && (
                    <Margin
                      mt={4}
                      css={{
                        position: "absolute",
                        width: "100%",
                      }}
                    >
                      <div
                        css={{
                          maxHeight: 300,
                          overflowY: "scroll",
                          boxShadow: "0 6px 10px 0 rgba(225, 225, 225, 0.5)",
                          border: `solid 1px ${palette.lightestSealBlue}`,
                          backgroundColor: palette.white,
                          borderRadius: 5,
                          padding: 10,
                        }}
                      >
                        <div
                          css={{
                            display: "grid",
                            gridRowGap: 10,
                          }}
                        >
                          {uniqBy((government) => government._id || (government as any).ObjectID, results).map(
                            (government, index) => {
                              const isSelected = highlightedIndex === index;

                              return (
                                <div
                                  key={government._id || (government as any).ObjectID}
                                  css={{
                                    display: "grid",
                                    gridTemplateAreas: '"logo name" "logo description"',
                                    gridTemplateColumns: "auto 1fr",
                                    gridColumnGap: 10,
                                    gridRowGap: 0,
                                    padding: 5,
                                    cursor: "pointer",
                                  }}
                                  {...getItemProps({
                                    item: government,
                                    index,
                                  })}
                                >
                                  <div
                                    css={{
                                      gridArea: "logo",
                                      display: "inline-flex",
                                    }}
                                  >
                                    <ProgressiveImage src={government.logo || ""} placeholder="">
                                      {(src: string) => (
                                        <ProfilePicture name={government.city.name} image={src} size={32} />
                                      )}
                                    </ProgressiveImage>
                                  </div>

                                  <span
                                    css={{
                                      gridArea: "name",
                                      color: isSelected ? palette.primary : palette.sealBlue,
                                      fontSize: 14,
                                      fontWeight: 600,
                                    }}
                                  >
                                    {government.city.name}
                                  </span>

                                  <GovernmentDescription city={government.city} />
                                </div>
                              );
                            },
                          )}
                        </div>
                      </div>
                    </Margin>
                  )}
                </div>

                <FieldValidationError meta={meta} />
              </div>
            )}
          </Downshift>
        );
      }}
    </SearchAlgoliaIndex>
  );
};

export default memo(GovernmentInput);

interface IGovernmentDescriptionProps {
  city: ICity;
}

function GovernmentDescription({ city }: IGovernmentDescriptionProps) {
  return (
    <span
      css={{
        gridArea: "description",
        color: palette.sealBlue,
        fontSize: 12,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      {city.county ? `${city.state}, ${city.type} in ${city.county}` : `${city.state}, ${city.type}`}
    </span>
  );
}
