import React, { ChangeEvent, useState } from "react";
import * as palette from "@govlaunch/palette";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faUnlink } from "@fortawesome/free-solid-svg-icons";
import LinkPaddingsButton from "./LinkPaddingsButton";

interface IPaddingsControlProps {
  data: any;
  setData: any;
}

export default function PaddingsControl({ data, setData }: IPaddingsControlProps) {
  const [linked, setLinked] = useState(() => isLockedByDefault(data));

  return (
    <div
      css={{
        marginTop: "16px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <label
        css={{
          fontWeight: 500,
          fontSize: 14,
          color: palette.mediumGray,
        }}
      >
        Paddings (px)
      </label>
      <div
        css={{
          marginTop: "8px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <PaddingInput label="top" fieldName="paddingTop" data={data} setData={setData} linked={linked} />
        <PaddingInput label="bottom" fieldName="paddingBottom" data={data} setData={setData} linked={linked} />
        <PaddingInput label="left" fieldName="paddingLeft" data={data} setData={setData} linked={linked} />
        <PaddingInput label="right" fieldName="paddingRight" data={data} setData={setData} linked={linked} />

        <LinkPaddingsButton
          onClick={() => {
            setLinked(!linked);
          }}
        >
          {linked ? (
            <FontAwesomeIcon
              icon={faLink}
              style={{
                width: 22,
                height: 22,
              }}
              color={palette.blue}
            />
          ) : (
            <FontAwesomeIcon
              icon={faUnlink}
              style={{
                width: 22,
                height: 22,
              }}
              color={palette.blue}
            />
          )}
        </LinkPaddingsButton>
      </div>
    </div>
  );
}

function isLockedByDefault(data: any) {
  const paddingTop: number = data["paddingTop"] || 0;
  const paddingBottom: number = data["paddingBottom"] || 0;
  const paddingLeft: number = data["paddingLeft"] || 0;
  const paddingRight: number = data["paddingRight"] || 0;

  const sum = paddingTop + paddingBottom + paddingLeft + paddingRight;
  const areEquals = sum === paddingTop * 4;

  if (areEquals) {
    return true;
  }

  return false;
}

interface IPaddingInputProps {
  label: string;
  fieldName: string;
  data: any;
  setData: any;
  linked?: boolean;
}

function PaddingInput({ label, fieldName, data, setData, linked }: IPaddingInputProps) {
  const handleOnChange = (fieldName: string, value: number) => {
    if (linked) {
      return {
        paddingTop: value,
        paddingBottom: value,
        paddingLeft: value,
        paddingRight: value,
      };
    }

    if (!linked) {
      return {
        [fieldName]: value,
      };
    }
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "60px",
      }}
    >
      <input
        type="number"
        css={{
          height: 42,
          border: `1px solid ${palette.lightestGray}`,
          fontSize: 14,
          letterSpacing: 0.5,
          fontWeight: 500,
          padding: "5px 10px",
          outline: 0,
          "::placeholder": {
            color: palette.sealBlue,
          },
          ":focus": {
            borderColor: palette.primary,
          },
          width: "60px",
        }}
        placeholder="px"
        //@ts-ignore
        value={data[fieldName] || 0}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const value = parseInt(event.target.value || "") || 0;

          setData({
            ...data,
            ...handleOnChange(fieldName, value),
          });
        }}
      />
      <div
        css={{
          fontWeight: 500,
          fontSize: 10,
          color: palette.mediumGray,
          textTransform: "uppercase",
        }}
      >
        {label}
      </div>
    </div>
  );
}
