import { Query } from "react-apollo";
import React, { ReactElement } from "react";
import StoriesCountQuery from "../../queries/StoriesCountQuery";
import {
  IStoriesCountQuery,
  IStoriesCountQueryVariables,
} from "../../queries/__generated__/StoriesCountQuery.generated";

interface IStoriesCountChildProps {
  allStories: number;
  archivedStories: number;
  refetch: Function;
  loading: boolean;
}

interface IStoriesCountProps {
  children: (props: IStoriesCountChildProps) => ReactElement;
}

export default function StoriesCount({ children }: IStoriesCountProps) {
  return (
    <Query<IStoriesCountQuery, IStoriesCountQueryVariables> query={StoriesCountQuery}>
      {({ data, loading, refetch }) => {
        if (!data) {
          return children({
            allStories: 0,
            archivedStories: 0,
            refetch: () => {},
            loading: false,
          });
        }

        return children({
          ...data.storiesCount,
          loading,
          refetch,
        });
      }}
    </Query>
  );
}
