import { ObservableQuery } from "apollo-client";
import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import {
  IGetGovernmentQuery,
  IGetGovernmentQueryVariables,
} from "../../../queries/__generated__/GetGovernmentQuery.generated";
import ProjectsTable from "../../projects/tables/ProjectsTable";

type TQueryFields = ObservableQuery<IGetGovernmentQuery, IGetGovernmentQueryVariables>;

interface IProjectsTabProps {
  government: IGetGovernmentQuery["government"];
  loading: boolean;
  fetchMore: TQueryFields["fetchMore"];
  refetch: TQueryFields["refetch"];
}

export default function ProjectsTab({ government, refetch, loading, fetchMore }: IProjectsTabProps) {
  if (!government || !government.projects || !government.projects.items || !government.projects.pageInfo) {
    return null;
  }

  return (
    <div>
      <ProjectsTable
        enableSorting={false}
        hideGovernmentColumn={true}
        projects={government.projects.items}
        onDeleteProject={refetch}
      />

      <VisibilitySensor
        active={Boolean(!loading && government.projects.pageInfo.hasNextPage)}
        partialVisibility={true}
        onChange={isActive => {
          if (!isActive) {
            return;
          }

          if (!government.projects || !government.projects.pageInfo || !government.projects.pageInfo.endCursor) {
            return;
          }

          fetchMore({
            variables: {
              projectsCursor: government.projects.pageInfo.endCursor,
            },
            updateQuery: (current, { fetchMoreResult }) => {
              if (!current.government || !current.government.projects || !current.government.projects.items) {
                return current;
              }

              if (
                !fetchMoreResult ||
                !fetchMoreResult.government ||
                !fetchMoreResult.government.projects ||
                !fetchMoreResult.government.projects.items
              ) {
                return current;
              }

              return {
                __typename: "Query",
                government: {
                  ...current.government,
                  projects: {
                    ...fetchMoreResult.government.projects,
                    items: [...current.government.projects.items, ...fetchMoreResult.government.projects.items],
                  },
                },
              };
            },
          });
        }}
      />
    </div>
  );
}
