import gql from "graphql-tag";
import UserFields from "./fragments/UserFields";

export default gql`
  query AllUsers(
    $filter: String = "ALL"
    $government: ObjectId
    $company: ObjectId
    $search: String
    $sortBy: String
    $plan: String
    $tags: [ObjectId!]
    $cursor: Cursor
  ) {
    allUsers(
      filter: $filter
      search: $search
      government: $government
      company: $company
      sortBy: $sortBy
      plan: $plan
      tags: $tags
      cursor: $cursor
    ) {
      items {
        ...UserFields
      }
      pageInfo {
        hasNextPage
        endCursor
        totalCount
      }
    }
  }

  ${UserFields}
`;
