import React from "react";
import { IVendorsTabFilter } from "./VendorsTable";
import queryString from "query-string";
import TableSearch from "../../../components/table/TableSearch";
import { useLocation } from "react-router";
import ManageVendors from "./ManageVendors";

interface IVendorsWithSearchProps {
  historyOrigin?: string;
  tab?: IVendorsTabFilter | null;
  sponsorship?: any | null;
  [key: string]: any;
}

export default function VendorsWithSearch({
  historyOrigin = "/vendors",
  tab = IVendorsTabFilter.ALL,
  sponsorship,
  ...props
}: IVendorsWithSearchProps) {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const plan = (query.plan as string) || null;
  const planId = (query.planId as string) || null;

  return (
    <>
      <div css={{
 marginBottom: 20 
}}
      >
        <TableSearch historyOrigin={historyOrigin} query={query} inputPlaceholder="Search for vendors..." />
      </div>

      <ManageVendors
        tab={tab || IVendorsTabFilter.ALL}
        search={(query.search as string) || ""}
        sponsorship={sponsorship}
        plan={plan}
        planId={planId}
        {...props}
      />
    </>
  );
}
