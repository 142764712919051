import React from "react";
import { Button, LoadingIndicator } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import TextField from "../../form/fields/TextField";
import UrlField from "../../form/fields/UrlField";
import { Link } from "react-router-dom";
import SponsorshipLogoPreviewField from "../../form/fields/SponsorshipLogoPreviewField";
import { FormSpy } from "react-final-form";
import { useNavigate } from "react-router";
import PageIcon from "../../../icons/PageIcon";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";

interface ISponsorshipFormProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  submitting: boolean;
  submitButtonText?: string;
}

export default function SponsorshipForm({ onSubmit, submitting, submitButtonText }: ISponsorshipFormProps) {
  const navigate = useNavigate();

  return (
    <form onSubmit={onSubmit}>
      <div
        css={{
          borderBottom: "1px solid rgb(232, 232, 232)",
          padding: 20,
        }}
      >
        <h3
          css={{
            fontWeight: 700,
            fontSize: 24,
            margin: 0,
          }}
        >
          Add Sponsorship <PageIcon icon={faHandshake} />
        </h3>

        <Link to="/sponsorships">All Sponsorships</Link>
      </div>

      <div
        css={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          rowGap: 10,
          maxWidth: 800,
        }}
      >
        <FormSpy
          subscription={{
            values: true,
          }}
        >
          {({ values }) => <SponsorshipLogoPreviewField name={values.name || "X"} link={values.link} />}
        </FormSpy>
        <TextField fieldName="name" label="Name" />
        <UrlField fieldName="link" label="Link" placeholder="Sponsorship Page (https://)" />

        <div
          css={{
            display: "flex",
            flexDirection: "row",
            marginTop: 20,
          }}
        >
          <Button
            size="large"
            color={palette.greenSuccess}
            textColor="#fff"
            type="submit"
            flex={1}
            width="100%"
            disabled={submitting}
            css={{
              ":disabled": {
                background: "rgba(0, 0, 0, 0.2)",
              },
              minWidth: 240,
            }}
          >
            {submitButtonText}
            {submitting && (
              <div
                css={{
                  marginLeft: 10,
                }}
              >
                <LoadingIndicator />
              </div>
            )}
          </Button>

          <Button
            onClick={() => navigate("/sponsorships")}
            color={palette.red}
            theme="secondary"
            size="large"
            type="button"
            flex={1}
            width="100%"
            css={{
              marginLeft: 8,
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
}
