import { Button, Table, Pagination } from "antd";
import Column from "antd/lib/table/Column";
import React from "react";
import { useQuery } from "react-apollo";
import { INewsletterFrequency, IGovlaunchEnvironment } from "../../../types/types";
import GetNewsletterReceivers from "../../queries/GetNewsletterReceivers";
import {
  IGetNewsletterReceiversQuery,
  IGetNewsletterReceiversQueryVariables,
} from "../../queries/__generated__/GetNewsletterReceivers.generated";
import { Link } from "react-router-dom";
import moment from "moment";
import Impersonate from "../debugging/Impersonate";
import { ForceSendNewsletterLink } from "../users/ForceSendNewsletterButton";
import * as palette from "@govlaunch/palette";
import { State } from "react-powerplug";

import encodeCursor from "../../utils/encodeCursor";

interface INewsletterReceiversTableProps {
  search?: string;
  mode: IGovlaunchEnvironment;
}

type TNewsletterReceiver = NonNullable<NonNullable<IGetNewsletterReceiversQuery["newsletterReceivers"]>["items"]>[0];

type TNewsletterReceiversTableState = {
  limit: number | null;
  page: number | null;
};

const DEFAULT_NEWSLETTER_RECEIVERS_TABLE_LIMIT = 20;

export default function NewsletterReceiversTable({ search, mode }: INewsletterReceiversTableProps) {
  const { data, loading, refetch } = useQuery<IGetNewsletterReceiversQuery, IGetNewsletterReceiversQueryVariables>(
    GetNewsletterReceivers,
    {
      variables: {
        search: search || "",
        mode: mode,
        limit: DEFAULT_NEWSLETTER_RECEIVERS_TABLE_LIMIT,
        cursor: null,
      },
      fetchPolicy: "network-only",
    },
  );

  const users =
    data && data.newsletterReceivers && data.newsletterReceivers.items ? data.newsletterReceivers.items : [];
  const totalCount =
    data &&
    data.newsletterReceivers &&
    data.newsletterReceivers.pageInfo &&
    data.newsletterReceivers.pageInfo.totalCount
      ? data.newsletterReceivers.pageInfo.totalCount
      : 0;

  return (
    <State<TNewsletterReceiversTableState>
      initial={{
        limit: DEFAULT_NEWSLETTER_RECEIVERS_TABLE_LIMIT,
        page: 1,
      }}
    >
      {({ state, setState }) => (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            rowGap: 10,
          }}
        >
          <Table<TNewsletterReceiver>
            loading={loading}
            rowKey="_id"
            bordered={true}
            pagination={false}
            scroll={{
              x: true,
            }}
            dataSource={users}
            title={() => {
              return <strong>{loading ? "Receivers" : `Receivers (${totalCount})`}</strong>;
            }}
          >
            <Column
              dataIndex="fullName"
              title="Name"
              render={(fullName: string, user: TNewsletterReceiver) => (
                <Link to={`/users/${user.slug}`}>{fullName}</Link>
              )}
            />
            <Column
              dataIndex="_id"
              title="Type"
              render={(_, user: TNewsletterReceiver) => {
                if (user.__typename === "GovernmentUser") {
                  return "Government";
                }

                if (user.__typename === "VendorUser") {
                  return "Vendor";
                }

                return "Pending";
              }}
            />
            <Column dataIndex="email" title="Email" />
            <Column dataIndex="newsletterFrequency" title="Frequency" />
            <Column
              dataIndex="newsletterLastSentDate"
              title="Last Sent Date"
              render={(date) => {
                if (!date) {
                  return "N/A";
                }

                return moment(date).format("MM/DD/YYYY");
              }}
            />
            <Column
              dataIndex="newsletterLastSentDate"
              title="Next Sent Date"
              render={(_date, user: TNewsletterReceiver) => {
                const lastSentDate = user.newsletterLastSentDate || user.createdAt;

                if (user.newsletterFrequency === INewsletterFrequency.Weekly) {
                  return moment(lastSentDate).add(1, "w").format("MM/DD/YYYY");
                }

                if (user.newsletterFrequency === INewsletterFrequency.Biweekly) {
                  return moment(lastSentDate).add(2, "w").format("MM/DD/YYYY");
                }

                return moment(lastSentDate).add(1, "M").format("MM/DD/YYYY");
              }}
            />
            <Column
              dataIndex="_id"
              title="Email"
              render={(userId) => (
                <div
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.API_URL}/newsletter-user-preview?userId=${userId}&version=2`}
                  >
                    Preview
                  </a>

                  {mode === IGovlaunchEnvironment.Qa && (
                    <div
                      css={{
                        marginLeft: 8,
                        border: `solid 1px ${palette.blue}`,
                        borderRadius: 10,
                        padding: 4,
                        "&:hover": {
                          opacity: 0.8,
                        },
                      }}
                    >
                      <ForceSendNewsletterLink userId={userId} />
                    </div>
                  )}
                </div>
              )}
            />
            <Column
              dataIndex="_id"
              title="Actions"
              render={(_, user: TNewsletterReceiver) => (
                <div
                  css={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Impersonate>
                    {(impersonate) => (
                      <Button
                        onClick={(event) => {
                          event.preventDefault();
                          impersonate(user);
                        }}
                      >
                        Impersonate
                      </Button>
                    )}
                  </Impersonate>
                </div>
              )}
            />
          </Table>
          <Pagination
            css={{
              alignSelf: "flex-end",
            }}
            defaultPageSize={DEFAULT_NEWSLETTER_RECEIVERS_TABLE_LIMIT}
            showSizeChanger={true}
            showQuickJumper={true}
            total={totalCount}
            // range variable shows incorrect values when changing tabs
            showTotal={(total) => {
              const page = state.page || 1;
              const limit = state.limit || 0;

              return `${(page - 1) * limit}-${Math.min(page * limit + limit, total)} of ${total} items`;
            }}
            current={state.page || 1}
            onChange={(page, pageSize) => {
              setState({
                limit: pageSize,
                page,
              });

              refetch({
                search: search || "",
                mode: mode,
                cursor: encodeCursor((page - 1) * (pageSize || 0)),
                limit: pageSize,
              });
            }}
            onShowSizeChange={(_current: number, size: number) => {
              setState({
                limit: size,
                page: 0,
              });

              refetch({
                search: search || "",
                mode: mode,
                cursor: encodeCursor(0 * (size || 0)),
                limit: size,
              });
            }}
          />
        </div>
      )}
    </State>
  );
}
