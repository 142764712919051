import * as palette from "@govlaunch/palette";
import { Tabs } from "antd";
import React from "react";
import { Query } from "react-apollo";
import DocumentTitle from "react-document-title";
import { IVerificationInput } from "../../../types/types";
import VendorQuery from "../../queries/VendorQuery";
import { IVendorQuery, IVendorQueryVariables } from "../../queries/__generated__/VendorQuery.generated";
import VendorDetailsTab from "./tabs/VendorDetailsTab";
import VendorMembersTab from "./tabs/VendorMembersTab";
import VendorPostsTab from "./tabs/VendorPostsTab";
import VendorProductsTab from "./tabs/VendorProductsTab";
import VendorProjectsTab from "./tabs/VendorProjectsTab";
import VendorStoriesTab from "./tabs/VendorStoriesTab";
import VendorGroupsTab from "./tabs/VendorGroupsTab";
import VendorAccountTab from "./tabs/VendorAccountTab";
import MentionedProjectsTab from "./tabs/MentionedProjectsTab";
import VendorSubscriptionTab from "./tabs/VendorSubscriptionTab";
import { useNavigate, useParams } from "react-router";

export default function Vendor() {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <DocumentTitle title="Vendor | Admin">
      <Query<IVendorQuery, IVendorQueryVariables>
        query={VendorQuery}
        variables={{
          slug: params.slug || "",
        }}
      >
        {({ data, loading, refetch }) => {
          if (loading || !data) {
            return null;
          }

          const vendor = data.company;

          if (!vendor) {
            return null;
          }

          return (
            <DocumentTitle title={`Admin | Edit • ${vendor.name}`}>
              <div
                css={{
                  backgroundColor: palette.washGray,
                  minHeight: "100vh",
                }}
              >
                <Tabs
                  tabBarStyle={{
                    backgroundColor: palette.white,
                    paddingLeft: 20,
                    paddingRight: 20,
                    margin: 0,
                  }}
                  activeKey={params.tab || "details"}
                  onChange={(tab) => navigate(`/vendors/${vendor.slug}${tab !== "details" ? `/${tab}` : ""}`)}
                >
                  <Tabs.TabPane tab="Details" key="details">
                    <VendorDetailsTab vendor={vendor} />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Members" key="members">
                    <VendorMembersTab vendor={vendor} refetch={refetch} />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Projects" key="projects">
                    <VendorProjectsTab vendorSlug={vendor.slug} verification={IVerificationInput.None} />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Verified Projects" key="verifiedProjects">
                    <VendorProjectsTab vendorSlug={vendor.slug} verification={IVerificationInput.Verified} />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Mentioned Projects" key="mentionedProjects">
                    <MentionedProjectsTab vendorSlug={vendor.slug} verification={IVerificationInput.None} />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Stories" key="stories">
                    <VendorStoriesTab vendorId={vendor._id} />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Products" key="products">
                    <VendorProductsTab vendor={vendor} />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Posts" key="posts">
                    <VendorPostsTab vendorId={vendor._id} />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Groups" key="groups">
                    <VendorGroupsTab vendor={vendor} />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Account" key="account">
                    <VendorAccountTab vendor={vendor} />
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Subscription" key="subscription">
                    <VendorSubscriptionTab vendor={vendor} refetch={refetch} />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </DocumentTitle>
          );
        }}
      </Query>
    </DocumentTitle>
  );
}
