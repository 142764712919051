import React from "react";
import BlockLayoutContainer from "../../blockslayout/BlockLayoutContainer";
import BlockLayoutHeader from "../../blockslayout/BlockLayoutHeader";
import BlockLayoutHeaderSettings from "../../blockslayout/BlockLayoutHeaderSettings";
import BlockContentTitle from "../BlockContentTitle";
import { IBlockLayout, TBlockLayoutData } from "../blockProps";
import EventsBlockContents from "./EventsBlockContents";

interface IEventsBlockProps {
  block: IBlockLayout;
  onRemoveBlock: () => void;
  saveBlockData: (block: IBlockLayout, blockData: TBlockLayoutData) => void;
}

export default function EventsBlock({ block, onRemoveBlock, saveBlockData }: IEventsBlockProps) {
  return (
    <BlockLayoutContainer block={block}>
      <BlockLayoutHeader block={block} onRemoveBlock={onRemoveBlock}>
        <BlockLayoutHeaderSettings block={block} saveBlockData={saveBlockData} />
      </BlockLayoutHeader>
      {block.blockData["titleVisible"] && <BlockContentTitle block={block} />}
      <EventsBlockContents block={block} />
    </BlockLayoutContainer>
  );
}
