import { MultiGovernmentSelector, useAlgoliaSearch } from "@govlaunch/web";
import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import FieldValidationError, { hasFieldValidationError } from "../../../../components/form/FieldValidationError";

interface IGovernmentAlgoliaHit {
  objectID: string;
  name: string;
  logo: string;
}

interface IGovernmentItem {
  value: string;
  label: string;
  logo: string | null;
}

export default function GovernmentsInvolvedField() {
  const { setSearchState, results } = useAlgoliaSearch<IGovernmentAlgoliaHit>({
    initialSearchState: {
      index: "governments",
    },
  });

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setSearchState((current) => ({
      ...current,
      query: inputValue,
    }));
  }, [inputValue]);

  const items: IGovernmentItem[] = results
    ? results.hits.slice(0, 5).map((government) => ({
        value: government.objectID,
        label: government.name,
        logo: government.logo || null,
      }))
    : [];

  return (
    <Field name="locations" allowNull={true}>
      {({ input, meta }) => {
        return (
          <>
            <MultiGovernmentSelector
              onChange={input.onChange}
              selectedGovernments={input.value}
              governments={items}
              menuProps={{
                position: "absolute",
              }}
              inputProps={{
                onBlur: input.onBlur,
                onFocus: input.onFocus,
              }}
              placeholder="Search for governments..."
              onInputValueChange={setInputValue}
            />

            {hasFieldValidationError(meta) && (
              <div
                css={{
                  marginTop: 10,
                }}
              >
                <FieldValidationError meta={meta} />
              </div>
            )}
          </>
        );
      }}
    </Field>
  );
}
