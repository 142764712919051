import { Radio } from "antd";
import { startCase } from "lodash";
import React from "react";
import { FieldInputProps } from "react-final-form";
import * as palette from "@govlaunch/palette";

interface IRadioButtonProps {
  action: string;
  input: FieldInputProps<any, HTMLElement>;
  onClick: () => void;
}

export default function RadioButton({ action, input, onClick }: IRadioButtonProps) {
  return (
    <li css={{
 display: input.value === action ? "none" : "block" 
}}
    >
      <Radio.Button
        css={{
 marginBottom: 5, width: "100%", color: palette.mediumGray, fontWeight: 400 
}}
        checked={input.value === action}
        onChange={() => input.onChange(action)}
        value={action}
        onClick={onClick}
      >
        {`${startCase(action)} tags`}
      </Radio.Button>
    </li>
  );
}
