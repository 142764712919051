import gql from "graphql-tag";

export default gql`
  query AllPosts {
    allPosts {
      ... on GovernmentPost {
        _id
        body
        audience
        featuredAt
        createdAt
        deletedAt
        deletedBy {
          ... on PendingUser {
            _id
            slug
            fullName
          }
          ... on GovernmentUser {
            _id
            slug
            fullName
          }
          ... on VendorUser {
            _id
            slug
            fullName
          }
        }
        author {
          ... on PendingUser {
            _id
            fullName
          }
          ... on GovernmentUser {
            _id
            fullName
          }
          ... on VendorUser {
            _id
            fullName
          }
        }
        government {
          _id
          name
        }
        attachments {
          mimeType
          filename
          sizeInBytes
          url
        }
        links {
          addedAt
          description
          title
          favicon
          siteName
          url
        }
      }
      ... on GroupPost {
        _id
        body
        audience
        featuredAt
        createdAt
        deletedAt
        author {
          ... on PendingUser {
            _id
            fullName
          }
          ... on GovernmentUser {
            _id
            fullName
          }
          ... on VendorUser {
            _id
            fullName
          }
        }
        group {
          _id
          name
        }
        attachments {
          mimeType
          filename
          sizeInBytes
          url
        }
        links {
          addedAt
          description
          title
          favicon
          siteName
          url
        }
      }
      ... on VendorPost {
        _id
        body
        audience
        featuredAt
        createdAt
        deletedAt
        author {
          ... on PendingUser {
            _id
            fullName
          }
          ... on GovernmentUser {
            _id
            fullName
          }
          ... on VendorUser {
            _id
            fullName
          }
        }
        attachments {
          mimeType
          filename
          sizeInBytes
          url
        }
        links {
          addedAt
          description
          title
          favicon
          siteName
          url
        }
      }
    }
  }
`;
